import {FETCH_ZONEMAP_DATA_PENDING, FETCH_ZONEMAP_DATA_SUCCESS, FETCH_ZONEMAP_DATA_ERROR, UPLOAD_ZONEMAP_PENDING, UPLOAD_ZONEMAP_SUCCESS, UPLOAD_ZONEMAP_ERROR,
    SAVE_ZONEMAP_TRANSACTION_PENDING, SAVE_ZONEMAP_TRANSACTION_SUCCESS, SAVE_ZONEMAP_TRANSACTION_ERROR,RESET_TO_ZONEMAP_INITIALSTATE
    } from '../../store/zonemap/ZoneMap.ActionTypes';

const initialState = {
    zoneMapData:[],
    pending:false,
    error:null,
    uploadZoneMapSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(zoneMapData, zones) {
    if(zoneMapData && Array.isArray(zoneMapData)){
        zoneMapData.forEach((value) => {
            if(zones && Array.isArray(zones)){
                zones.forEach((zone) => {
                    if (zone.code == value.ZONE_CODE) {
                        value['ZONE_NAME'] = zone.name;
                    }
                });
            }        
            if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
                value['ZONE_NAME'] = "";
            }
        })
    }
    return zoneMapData;
}

export default function ZoneMapReducer (state=initialState,action){
    switch(action.type){
        case FETCH_ZONEMAP_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_ZONEMAP_DATA_SUCCESS:
            return Object.assign({},state,{
                zoneMapData: fetchZones(action.data.zoneMapData, action.data.zones),
                uploadZoneMapSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_ZONEMAP_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_ZONEMAP_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadZoneMapSuccess: null
            });
        case UPLOAD_ZONEMAP_SUCCESS:
            return Object.assign({}, state, {
                zoneMapData: fetchZones(action.data.zoneMapData, action.data.zones),
                pending: false,
                error: null,
                uploadZoneMapSuccess: true
            });
        case UPLOAD_ZONEMAP_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadZoneMapSuccess: false
            });
        case SAVE_ZONEMAP_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_ZONEMAP_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                zoneMapData: fetchZones(action.data.zoneMapData, action.data.zones),
                transactionSaveSuccess: true
            });
        case SAVE_ZONEMAP_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_ZONEMAP_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}