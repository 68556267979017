export const FETCH_CARCLASSGROUP_DATA_PENDING = 'FETCH_CARCLASSGROUP_DATA_PENDING';
export const FETCH_CARCLASSGROUP_DATA_SUCCESS = 'FETCH_CARCLASSGROUP_DATA_SUCCESS';
export const FETCH_CARCLASSGROUP_DATA_ERROR = 'FETCH_CARCLASSGROUP_DATA_ERROR';

// export const UPLOAD_CARCLASSGROUP_PENDING = 'UPLOAD_CARCLASSGROUP_PENDING';
// export const UPLOAD_CARCLASSGROUP_SUCCESS = 'UPLOAD_CARCLASSGROUP_SUCCESS';
// export const UPLOAD_CARCLASSGROUP_ERROR = 'UPLOAD_CARCLASSGROUP_ERROR';

export const SAVE_CARCLASSGROUP_TRANSACTION_PENDING = 'SAVE_CARCLASSGROUP_TRANSACTION_PENDING';
export const SAVE_CARCLASSGROUP_TRANSACTION_SUCCESS = 'SAVE_CARCLASSGROUP_TRANSACTION_SUCCESS';
export const SAVE_CARCLASSGROUP_TRANSACTION_ERROR = 'SAVE_CARCLASSGROUP_TRANSACTION_ERROR';

export const SAVE_CARGROUP_TRANSACTION_PENDING = 'SAVE_CARGROUP_TRANSACTION_PENDING';
export const SAVE_CARGROUP_TRANSACTION_SUCCESS = 'SAVE_CARGROUP_TRANSACTION_SUCCESS';
export const SAVE_CARGROUP_TRANSACTION_ERROR = 'SAVE_CARGROUP_TRANSACTION_ERROR';

export const FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING = 'FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING';
export const FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS = 'FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS';
export const FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR = 'FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR';

export const RESET_TO_CARCLASSGROUP_INITIALSTATE = 'RESET_TO_CARCLASSGROUP_INITIALSTATE';


export const SET_ADMIN_CARCLASSGROUP_FILTER_POPUP_OPEN='SET_ADMIN_CARCLASSGROUP_FILTER_POPUP_OPEN';
export const SET_ADMIN_CARCLASSGROUP_FILTER_PARAMS='SET_ADMIN_CARCLASSGROUP_FILTER_PARAMS';
export const SET_ADMIN_CARCLASSGROUP_FILTER_VIEW='SET_ADMIN_CARCLASSGROUP_FILTER_VIEW';
export const SET_ADMIN_CARCLASSGROUP_FILTER_RECORDS='SET_ADMIN_CARCLASSGROUP_FILTER_RECORDS';
export const SET_ADMIN_CARCLASSGROUP_FILTER_APPLY='SET_ADMIN_CARCLASSGROUP_FILTER_APPLY';
export const SET_ADMIN_CARCLASSGROUP_FILTER_CLEAR='SET_ADMIN_CARCLASSGROUP_FILTER_CLEAR';
export const SET_ADMIN_CARCLASSGROUP_SEARCH_VIEW='SET_ADMIN_CARCLASSGROUP_SEARCH_VIEW';
export const SET_ADMIN_CARCLASSGROUP_SEARCH_TEXT='SET_ADMIN_CARCLASSGROUP_SEARCH_TEXT';
export const SET_ADMIN_CARCLASSGROUP_SEARCH_RECORDS='SET_ADMIN_CARCLASSGROUP_SEARCH_RECORDS';
export const SET_ADMIN_CARCLASSGROUP_DELETE_FILTER='SET_ADMIN_CARCLASSGROUP_DELETE_FILTER';
export const SET_ADMIN_CARCLASSGROUP_DELETE_FILTER_RECORDS='SET_ADMIN_CARCLASSGROUP_DELETE_FILTER_RECORDS';

// export const SET_CARCLASSGROUP_EDITABLE='SET_CARCLASSGROUP_EDITABLE';