import {
    FETCH_SCHEDULE_DATA_PENDING, FETCH_SCHEDULE_DATA_SUCCESS, FETCH_SCHEDULE_DATA_ERROR, SAVE_SCHEDULE_TRANSACTION_PENDING,
    SAVE_SCHEDULE_TRANSACTION_SUCCESS, SAVE_SCHEDULE_TRANSACTION_ERROR, FETCH_SCHEDULES_FILTER_DATA_PENDING,FETCH_SCHEDULES_FILTER_DATA_SUCCESS,FETCH_SCHEDULES_FILTER_DATA_ERROR, SET_SCHEDULES_FILTER_PARAMS, FETCH_SCHEDULES_FILTER_CATEGORIES,
    UPLOAD_SCHEDULES_PENDING,UPLOAD_SCHEDULES_SUCCESS,UPLOAD_SCHEDULES_ERROR,RESET_TO_SCHEDULES_INITIALSTATE
} from '../../store/schedules/Schedules.ActionTypes';
import filterColumnDefs from '../../data/schedulesColumns';

const initialState = {
    schedules: [],
    pending: false,
    error: null,
    transactionSaveSuccess: null,
    filterParams: null,
    uploadSchedulesSuccess: null
}

function fetchDropDownValues(scheduleData, element) {
    var data = scheduleData.map(item => item[element.colDef])
        .filter((value, index, self) => self.indexOf(value) == index);
    if (element.colDef === "spi_code") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "program_year") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
           }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "acceptable_zones") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
                obj['label'] = val;
                obj['value'] = val;
                element.options.push(obj);
            }    
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "car_class_code") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "model_group") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "model_year") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef == "manufacturer_group") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef == "program_type") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label > b.label) { return -1; }
            if (a.label < b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    }
    return element;
}

function fetchFilterValues(filterColumnDefs, data) {
    var schedulesData = data;
    var filterData = filterColumnDefs().data;
    filterData.forEach(element => {
        if (element.type == "dropdown")
            fetchDropDownValues(schedulesData, element);
    });
    return filterData;
}

export default function schedulesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SCHEDULE_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_SCHEDULE_DATA_SUCCESS:
            return Object.assign({}, state, {
                //schedulesFilterSearchKey:'',
                schedules: action.data,
                pending: false,
                error: null,
                uploadSchedulesSuccess: null
            });
        case FETCH_SCHEDULE_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SAVE_SCHEDULE_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_SCHEDULE_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                schedules: action.data,
                transactionSaveSuccess: true,
                schedulesFilterSearchKey:''
            });
        case SAVE_SCHEDULE_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case FETCH_SCHEDULES_FILTER_CATEGORIES:
            return Object.assign({}, state, {
                pending: false,
                categories: action.data,
                error: null
            });
        case FETCH_SCHEDULES_FILTER_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_SCHEDULES_FILTER_DATA_SUCCESS:
            var actionData = fetchFilterValues(filterColumnDefs, action.data);
            return Object.assign({}, state, {
                data: actionData,
                pending: false,
                error: null
            });
        case FETCH_SCHEDULES_FILTER_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SET_SCHEDULES_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterParams: action.data
            });
        case UPLOAD_SCHEDULES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadSchedulesSuccess: null
            });
        case UPLOAD_SCHEDULES_SUCCESS:
            return Object.assign({}, state, {
                schedules: action.data,
                pending: false,
                error: null,
                uploadSchedulesSuccess: true
            });
        case UPLOAD_SCHEDULES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadSchedulesSuccess: false
            });
        case RESET_TO_SCHEDULES_INITIALSTATE:
            return initialState;
        default:
            return state;
    }
}
