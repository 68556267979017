import moment from "moment";
import { GridNumericColumns, INVALID_FILE_COLUMN_MISMATCH, SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP, cellEditors, pollTimeoutInterval } from "../Constants";
import { customCloneObject } from "./AdjustmentsUtils";
import { getUserManagementData } from "../services/admin/usermanagement/UserManagementService";


// A function to safely compare two strings, mainly implemented for duplicate rows comparision 
// If anyone param is nullish it returns false
// If no param is nullish then it compares lowercase of both params and returns the result.

export const safeCaseInsensitiveCompare = (value1, value2) => {
    if(!value1 || !value2){
        return false;
    }
    return (value1.toLowerCase() === value2.toLowerCase());
}

// A function to return a safe empty value to insert in excel cell.
export const safeExcelValue = (value) => {
    if(value!=0 && !value){
        return '';
    }
    return (value);
}

export const safeExcelDate = (value, format) => {
    return value ? moment(new Date(value)).utc().format(format) : ''
}


// A function to return a ISO string of a date.
export const getDateISOString = (date) => {
   let isDateValid = (date instanceof Date && !isNaN(date));
    if(!date || !isDateValid){
        return '';
    }
    return date.toISOString().slice(0,10)+'T00:00:00';
}

let planRunningStatuses=["building model","optimization queued","optimizing","apply adjustments","sales adjustments running","vin dep process running","pulling data","ready","optimization complete","optimization complete as sub-optim","optimization complete as optimal","optimization complete unknown status","adjustment complete","editing adjustments","adjustments modified","sales adjustments ready","vin dep process ready"]

//A function to disable editing for running plan
export const isPlanningStatusRunning = (selectedurlHasMainInputs, plansDataById) =>{
  if(selectedurlHasMainInputs == true){
    return false;
  }
  else if(selectedurlHasMainInputs == false && planRunningStatuses.includes(plansDataById[0].status.toLowerCase())){
    return true;
  }
    return false;
}

export const stringFormatter = (value)=>{
    return (value).replace(/"/g,'\"\"');
}

export const checkDate=(date1,date2)=>{

 if(date1 && date2){
    ////This is the old IF CLAUSE
    //if(moment(date1,true).format('MMM-YY') === moment(date2,true).format('MMM-YY')){

    ////To check the data
    // console.log(`${date1}|${date2}|${date1.substr(0,4)}===${date2.substr(0,4)} && ${date1.substr(5,2)}===${date2.substr(5,2)}`)

    ////The code to check for unexpected date formats
    ////The actual IF CLAUSE assumes YYYY-MM-DD..... format while che,cking equality
    // if(!date1.substr(0,10).match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/) || !date2.substr(0,10).match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/)){
    //     console.log("ALERT ! Unexpected date format", date1, date2);
    // }

    //Actual IF CLAUSE
    if(date1.substr(0,4)===date2.substr(0,4) && date1.substr(5,2)===date2.substr(5,2)){
        return true
    }else{
        return false
    } 
 } else{
     return false
 }
}

export const checkFullDate=(date1,date2)=>{
    if(date1 && date2){
        if(new Date(date1).getTime() === new Date(date2).getTime()){
            return true
        }else{
            return false
        } 
    } else{
        return false
    }
 }

export const RegExArrayMatch=(array, key)=>{
    const matcher = new RegExp(`${key}`, 'gi');
    return array.filter(word => word.match(matcher));
  }

  export const serverSideSearchColumnsMatch=(gridName,columns,searchText)=>{
    let searchColumnsIn=[]
    let columnHeaders=Object.keys(columns)
    if(isNaN(searchText)){
        columnHeaders.map((cname)=>{
            if(typeof(columns[cname][0])!='object'){
                if(RegExArrayMatch(columns[cname],searchText).length>0){
                    searchColumnsIn.push(cname)
                }
            }
        })
    } else {
        searchColumnsIn.push(...GridNumericColumns[gridName])
    }
    return searchColumnsIn;
  }

export const isStringAlphaNumeric = (value)=>{
 let Exp = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]+$/i; //Expects atleast one letter and at least one digit
 if(!value.match(Exp))
    return false
 else
    return true;
}

function SortingDropdownValues(dimension, dataType, sortColumn) {
    if (dataType == 'object') {
        return { ...dimension, body: { ...dimension.body,data: dimension.body.data.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn])) } }
    }
    else {
        return { ...dimension, body: { ...dimension.body,data: dimension.body.data.sort((a, b) => a.localeCompare(b)) } }
    }
}

export const checkAndLoadDimention= async (dimensionName,client)=>{

    let capitalForm=dimensionName.toUpperCase(dimensionName);    
    if(!sessionStorage.getItem(capitalForm)){//! false
        switch(dimensionName){
            case "programType":
                let promiseprogramtypeResponse = await client.apis.dimensions.getprogramtype();                                                
                sessionStorage.setItem("PROGRAMTYPE",JSON.stringify(SortingDropdownValues(promiseprogramtypeResponse,typeof(promiseprogramtypeResponse.body.data[0]),'name')));                                 
                break;
            case "manufacturer":
                let promiseGetManufacturerGroup = await client.apis.dimensions.getAllManufacturerGroups();
                sessionStorage.setItem("MANUFACTURER",JSON.stringify(SortingDropdownValues(promiseGetManufacturerGroup,typeof(promiseGetManufacturerGroup.body.data[0]),'name')));                 
                break;
            case "adminManufacturer":
                let promiseGetAdminManufacturerGroup = await client.apis.dimensions.getAllManufacturerGroups({"isAdmin":true});
                sessionStorage.setItem("ADMINMANUFACTURER",JSON.stringify(promiseGetAdminManufacturerGroup)); 
                break;
            case "carClassCode":
                let promiseGetCarClasses = await client.apis.dimensions.getAllCarClasses();
                sessionStorage.setItem("CARCLASSCODE",JSON.stringify(SortingDropdownValues(promiseGetCarClasses,typeof(promiseGetCarClasses.body.data[0]),'name')));
                break;
            case "carGroup":
                let promiseGetAllCarsGroup = await client.apis.dimensions.getAllCarsGroup();
                sessionStorage.setItem("CARGROUP",JSON.stringify(SortingDropdownValues(promiseGetAllCarsGroup,typeof(promiseGetAllCarsGroup.body.data[0]),'name')));              
                break;
            case "adminCarGroup":
                let promiseGetAllAdminCarsGroup = await client.apis.dimensions.getAllCarsGroup({"isAdmin":true});
                sessionStorage.setItem("ADMINCARGROUP",JSON.stringify(SortingDropdownValues(promiseGetAllAdminCarsGroup,typeof(promiseGetAllAdminCarsGroup.body.data[0]),'name')));              
                break;
            case "carclassAndgroup":
                let upgradeCarGroupResponse = await client.apis.dimensions.getAllCarclassAndgroup();
                sessionStorage.setItem("CARCLASSANDGROUP",JSON.stringify(SortingDropdownValues(upgradeCarGroupResponse,typeof(upgradeCarGroupResponse.body.data[0]),'car_class')));
                sessionStorage.setItem("CARCLASSANDGROUP_CLEAN_MAP",JSON.stringify(createMapFromCarClassCarGroup(SortingDropdownValues(upgradeCarGroupResponse,typeof(upgradeCarGroupResponse.body.data[0]),'car_class'))));
                break;
            case "admincarclassAndgroup":
                let upgradeAdminCarGroupResponse = await client.apis.dimensions.getAllCarclassAndgroup({"isAdmin":true});
                sessionStorage.setItem("ADMINCARCLASSANDGROUP",JSON.stringify(SortingDropdownValues(upgradeAdminCarGroupResponse,typeof(upgradeAdminCarGroupResponse.body.data[0]),'car_class')));
                break;
            case "modelGroup":
                let promiseGetAllModelGroups = await client.apis.dimensions.getAllModelGroups();
                sessionStorage.setItem("MODELGROUP",JSON.stringify(SortingDropdownValues(promiseGetAllModelGroups,typeof(promiseGetAllModelGroups.body.data[0]),'name')));
                break;
            case "adminModelGroup":
                let promiseGetAllAdminModelGroups = await client.apis.dimensions.getAllModelGroups({"isAdmin":true})
                sessionStorage.setItem("ADMINMODELGROUP",JSON.stringify(SortingDropdownValues(promiseGetAllAdminModelGroups,typeof(promiseGetAllAdminModelGroups.body.data[0]),'name')));
                break;
            case "zone":
                let promisegetZones = await client.apis.dimensions.getZone();                
                sessionStorage.setItem("ZONE",JSON.stringify(SortingDropdownValues(promisegetZones,typeof(promisegetZones.body.data[0]),'name')));
                break;
            case "acceptablezones":
                let promisegetAcceptableZones = await client.apis.dimensions.getAcceptableZones();
                sessionStorage.setItem("ACCEPTABLEZONES",JSON.stringify(SortingDropdownValues(promisegetAcceptableZones,typeof(promisegetAcceptableZones.body.data[0]),'name')));
                break;
            case "pool":
                let promisePoolResponse = await client.apis.dimensions.getPool();
                sessionStorage.setItem("POOL",JSON.stringify(SortingDropdownValues(promisePoolResponse,typeof(promisePoolResponse.body.data[0]),'code')));
                break;
            case "adminpool":
                let promiseAdminPoolResponse = await client.apis.dimensions.getPool({"isAdmin":true});
                sessionStorage.setItem("ADMINPOOL",JSON.stringify(SortingDropdownValues(promiseAdminPoolResponse,typeof(promiseAdminPoolResponse.body.data[0]),'code')));
                break;
            case "BU":
                let promiseBUResponse = await client.apis.dimensions.getBUs();
                sessionStorage.setItem("BU",JSON.stringify(SortingDropdownValues(promiseBUResponse,typeof(promiseBUResponse.body.data[0]),'name')));
                break;
            case "constrainttype":
                let promiseconstrainttypeResponse = await client.apis.dimensions.getconstrainttype();
                sessionStorage.setItem("CONSTRAINTTYPE",JSON.stringify(SortingDropdownValues(promiseconstrainttypeResponse,typeof(promiseconstrainttypeResponse.body.data[0]),'name')));
                break;
            case "AllCargroupCarClass":
                let promiseAllCargroupCarClassResponse = await client.apis.dimensions.getAllCargroupCarClass();
                sessionStorage.setItem("ALLCARGROUPCARCLASS",JSON.stringify( SortingDropdownValues(promiseAllCargroupCarClassResponse,typeof(promiseAllCargroupCarClassResponse.body.data[0]),'car_group')));
                sessionStorage.setItem("ALLCARGROUPCARCLASS_CLEAN_MAP",JSON.stringify(createMapFromCarGroupsCarClass( SortingDropdownValues(promiseAllCargroupCarClassResponse,typeof(promiseAllCargroupCarClassResponse.body.data[0]),'car_group'))));
                break;
            case "AllManufacturergroupModelgroup":
                let promiseAllManufacturergroupModelgroupResponse = await client.apis.dimensions.getAllModelAndManufacturerGroup();
                sessionStorage.setItem("ALLMANUFACTURERGROUPMODELGROUP",JSON.stringify( SortingDropdownValues(promiseAllManufacturergroupModelgroupResponse,typeof(promiseAllManufacturergroupModelgroupResponse.body.data[0]),'model_group')));
                sessionStorage.setItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP",JSON.stringify(createMapFromManufacturergroupModelgroup( SortingDropdownValues(promiseAllManufacturergroupModelgroupResponse,typeof(promiseAllManufacturergroupModelgroupResponse.body.data[0]),'model_group'))));
                break;
            case "LastCacheUpdatedAt":
                let promiseLastCacheUpdatedAt= await client.apis.admin.getDbCurrentTime()
                sessionStorage.setItem("LASTCACHEUPDATEDAT",JSON.stringify(moment(promiseLastCacheUpdatedAt.body.data.current_time).utc().format("YYYY-MM-DD HH:mm:ss")));
                break;
            case SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP:
                let emailNameMap=await getEmailNameMap();//Updates the session
                break;
            default:
                console.log("Invalid Dimention Name ", dimensionName);
                return null;

        }
    }else{
        
    }

    return JSON.parse(sessionStorage.getItem(capitalForm));
    
}

//Used in Filters
export const isWordWithoutSpecialCharacterAndSpaces=(word)=>{
    try{
        if(word.includes("+") || word.includes("*") || word.includes("(") 
        || word.includes(")") || word.includes("+") || word.includes("?") || word.includes("\"")){
            return false;
        }
        // Hyphen '-' is to be allowed
        const re1 = new RegExp("[\\w\\-]+");
        return re1.test(word);//Normal Word (may contain hyphen), will return true
    }catch(error){
        console.log(`${word} not supported`);
        return false;
    }
}

//Used for Admin Sections: CAR-CLASS-GROUP
export const isWordWithoutSpecialCharacterSpaceAllowed=(word)=>{
    try{
        let spChars = RegExp(/[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/);
        if(!(spChars.test(word))){
            return false;
        }else {
            return true;
        }
    }catch(error){
        console.log(`${word} not supported`);
        return false;
    }
}

export const isColumnEditable = (params)=>{
 let planRunningStatuses=["building model","optimization queued","optimizing","pulling data","apply adjustments","sales adjustments running","vin dep process running","solving model"];
 return !(planRunningStatuses.includes(params && params.data.status.toLowerCase()));
 }
 export const createMapFromCarClassCarGroup=(responseFromApi)=>{

    let carClassCarGroupMap={};

    let mainObject=responseFromApi? (responseFromApi.body?(responseFromApi.body.data?responseFromApi.body.data:[]):[]):[];

    mainObject.forEach(carClassGroupEntry=>{

        if(carClassGroupEntry.car_class){

            carClassCarGroupMap[carClassGroupEntry.car_class.trim()]=carClassGroupEntry.car_class_car_group;

        }

    });

    return carClassCarGroupMap;

}
export const createMapFromCarGroupsCarClass=(responseFromApi)=>{
    let carGroupCarClassMap={};
    let mainObject=responseFromApi? (responseFromApi.body?(responseFromApi.body.data?responseFromApi.body.data:[]):[]):[];
    mainObject.forEach(carClassGroupMap=>{
        if(carClassGroupMap.car_group){
            carGroupCarClassMap[carClassGroupMap.car_group.trim()]=carClassGroupMap.car_class;
        }
    });
    return carGroupCarClassMap;
}

export const createMapFromManufacturergroupModelgroup = (responseFromApi)=>{
 let modalGroupManufacturerMap={};
 let mainObject=responseFromApi? (responseFromApi.body?(responseFromApi.body.data?responseFromApi.body.data:[]):[]):[]; 
 mainObject.forEach(modalGroupMap=>{
    if(modalGroupMap.model_group){
        modalGroupManufacturerMap[modalGroupMap.model_group.trim()]=modalGroupMap.manufacturer_group;
     }
    });
    return modalGroupManufacturerMap;
}

export const minifyTransaction=(transactionlist)=>{
    // let startDate=new Date();
    // console.log("transactionlist==",transactionlist , );
    let newTransactionList=[];
    for(let i=0;i<transactionlist.length;i++){
        // console.log("got obj");
        
        //METHOD that I was USING
        // let obj=JSON.parse(JSON.stringify(transactionlist[i]));
        // obj.data.validations=[];
        // obj.data.colDef=[];

        //METHOD that is STANDARD
        let obj={
            ...transactionlist[i],
            data:{
                ...transactionlist[i].data,
                validations:[],
                colDef:[]
            }
        };

        newTransactionList.push(obj);
    }
    // console.log("newTransactionList==",newTransactionList);
    // let endDate=new Date();
    // let seconds = Math.abs(endDate.getTime() - startDate.getTime())/1000;
    // console.log("seconds==",seconds);
    return newTransactionList;
}

export const getManufacturerBasedOnModel=(model)=>{   
    let mapping =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
    return mapping[model];
}

//Compares two filter OBJECTs
//Returns true or false, 
export const areFiltersEqual=(filter1, filter2)=>{
    //console.log"areFiltersEqual: Inside areFiltersEqual");
    //console.log"areFiltersEqual: filter1==",filter1);
    //console.log"areFiltersEqual: filter2==",filter2);
    if((!filter1 && filter2) || (filter1 && !filter2) || (!filter1 && !filter2)){
        //console.log"areFiltersEqual: Any one is not an object")
        return false;
    }else {
        //console.log"areFiltersEqual: Filters are defined");
        let filter1Keys = Object.keys(filter1);
        let filter2Keys = Object.keys(filter2);
        if(filter1Keys.length!==filter2Keys.length){
            //console.log"areFiltersEqual: Different Key sets")
            return false; 
        }
        //console.log"areFiltersEqual: Filters are of same length");
        for(let x=0;x<filter1Keys.length;x++){
            let key=filter1Keys[x];
            let filter1KeyData=filter1[key];
            let filter2KeyData=filter2[key];
            //console.log"areFiltersEqual: Validating data for ",key);
            if(filter1KeyData!=="" && filter2KeyData!=="" && !(filter1KeyData && filter2KeyData)){
                //console.log"areFiltersEqual: Any one is not an object")
                return false;
            }else if(typeof filter1KeyData!==typeof filter2KeyData){
                //Comparing two data types, Arrays are objects, Strings are Strings
                //console.log"areFiltersEqual: Type does not match for ",key);
                return false;
            }else {
                //console.log"areFiltersEqual: Data is defined and of same type for ",key);
                if(typeof filter1KeyData==="string"){
                    //console.log"areFiltersEqual: Comparing two strings for ",key);
                    //Comparing two strings                    
                    if(filter1KeyData!==filter2KeyData){
                        //console.log"areFiltersEqual: Data does not match for ",key);
                        return false;
                    }
                }else{
                    //console.log"areFiltersEqual: Comparing two Arrays for ",key);
                    // filter1KeyData is an Array
                    if(filter1KeyData.length!==filter2KeyData.length){
                        //The length of array do not match
                        //console.log"areFiltersEqual: Array length does not match for ",key);
                        return false;
                    }else if(typeof filter1KeyData[0]==="string" ){ //typeof can return a lot of things, We are interested only in Arrays or Objects
                        // This is an array of Strings
                        //console.log"areFiltersEqual: Comparing String Arrays for ",key);
                        for(let i=0;i<filter1KeyData.length;i++){
                            if(typeof filter1KeyData[i]==="string"){
                                if(filter1KeyData[i]!==filter2KeyData[i]){
                                    //console.log"areFiltersEqual: Array data does not match for ",key);
                                    return false;
                                }
                            }else{
                                //Date object
                                //console.log"Date Object 1==",filter1KeyData[i]);
                                //console.log"Date Object 2==",filter2KeyData[i]);
                                if(filter1KeyData[i] && (filter1KeyData[i].getMonth()!==filter2KeyData[i].getMonth() || filter1KeyData[i].getFullYear()!==filter2KeyData[i].getFullYear())){
                                    //console.log"areFiltersEqual: Array data date object does not match for ",key);
                                    return false;
                                }
                            }
                        }           
                    }else{
                        // This is an array of Objects
                        //console.log"areFiltersEqual: Comparing Object Arrays for ",key);
                        for(let i=0;i<filter1KeyData.length;i++){
                            if(filter1KeyData[i].label!==filter2KeyData[i].label || filter1KeyData[i].value!==filter2KeyData[i].value){
                                //console.log"areFiltersEqual: Array object data does not match for ",key);
                                return false;
                            }
                        } 
                        //console.log"areFiltersEqual: Comparison completed for ",key);
                    }
                }
            }
          
        };
    }
    //console.log"areFiltersEqual: Filter is a match");
    return true;
}

export const deepCloneFilterObject=(filter)=>{
    let newFilter={};
    let filterKeys = Object.keys(filter);
    filterKeys.forEach(key=>{
        if(!filter[key]){
            newFilter[key]=null;
        }else if(typeof filter[key]==="string"){
            newFilter[key]=filter[key];
        }else{

            //Type of Array
            newFilter[key]=[];
            filter[key].forEach(value=>{
                if(typeof value==="string"){
                    newFilter[key].push(value);
                }else if(value instanceof Date){
                    newFilter[key].push(new Date(value.getTime()));
                }else{
                    newFilter[key].push({...value});
                }
            });

        }
    });

    return newFilter;
}

export const removeUnderscores=(str)=>{
    return str.replaceAll(/_/g," ");
}

export const timer = ms => new Promise(res => setTimeout(res, ms));

//download functionality
let csvFields=[];
let csvStr;
let d;
const getCsvColumnNames=(grid,gridcolumndef)=>{
 let dateFields=[];
 let fileName=grid.replace(/grid/gi,''); // gridName is replaced with capital 'G'(Grid) this is for new planInputs 2.0 and will be removed after completion
    gridcolumndef.columnDefs.map(i => {
        if (i.field && i.field.toLowerCase()!=="updated_by" && i.field.toLowerCase()!=="updated_on") {
            csvFields.push(i.field)
        }
        if (i.cellEditor && ((i.cellEditor === cellEditors.dateEditor) || (i.cellEditor === 'fullDateEditor'))) {
            dateFields.push(i.field)
        }
    })
//  csvFields = csvFields.filter(function (val) {
//  return val != null && val !='';
//  });
return {
    csvFields:csvFields,
    fileName:fileName,
    dateFields:dateFields
    };
}

function JsonToCSV(JsonArray, JsonFields,dateFields) {
 csvStr = JsonFields.join(",") + "\n";
 JsonArray = JsonArray.filter(function (key,val) {
    return   key !== "ID" && key!=="status" && key!=="validations" && key!="version"
    });
JsonArray.map(o=>{
getCsvData(o,dateFields)
})
return csvStr;
}

function printValue(data){
 return safeExcelValue(data)
}

function getCsvData(data,dateFields){
 let temp=[];
 for(var i=0;i<csvFields.length;i++){
    d=Object.getOwnPropertyDescriptor(data,csvFields[i]).value;
    // if(csvFields[i]==='MONTH' || csvFields[i]==='START_DATE' || csvFields[i]==='ONRENT_DATE'){
    //   d=safeExcelDate(d,'DD-MMM-YY')  
    // }
    if(dateFields.includes(csvFields[i])){
        d=safeExcelDate(d,'DD-MMM-YY')
    }
    temp.push(d)
 }
 temp = temp.join(',');
 csvStr+=printValue(temp)+'\n'
 return csvStr
}

//module name can be rf/planid/main
export const downloadData=(data,grid,gridcolumndef,downloadCSV,moduleName)=>{ 
 let gridData=getCsvColumnNames(grid,gridcolumndef);
 let data1 = JsonToCSV(data,gridData.csvFields,gridData.dateFields);
 let currentdate = new Date();
 let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
 downloadCSV(data1, `${gridData.fileName}-${moduleName}_${dateStr}.csv`);
 csvFields=[]
 csvStr=null
}

export const translateErrorCodes=(errorName)=>{
    switch(errorName){
        case INVALID_FILE_COLUMN_MISMATCH: return 'The Columns in the uploaded files does not match with the expected column set';
        default: 
            console.error("Unexpected Error: ",errorName)
            return errorName;
    }
}

export const createParameterMap=(parameterResponse)=>{
    // console.log("coreParameterResponse==>",parameterResponse);

    if(parameterResponse){
        let parametersMap={};
        parameterResponse.forEach(item=>{
            parametersMap[item.PARAMETER_NAME]=item.PARAMETER_VALUE;
        });

        return parametersMap;
    }


    return parameterResponse
}

export async function getEmailNameMap(){
    let nameBasedOnEmailMap = {}
    let myResult = await getUserManagementData(); 
    nameBasedOnEmailMap = {};
    myResult.forEach(element => nameBasedOnEmailMap[element.email] = element.name);
    sessionStorage.setItem(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,JSON.stringify(nameBasedOnEmailMap));
}

export function getNameBasedOnEmail(email,params){
    // console.log("EMAIL getNameBasedOnEmail==>",email);
    // console.log("params getNameBasedOnEmail==>",params);

    if(!email){
        return "";
    }

    let nameBasedOnEmailMap = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP));    
    if(nameBasedOnEmailMap){
        let name = nameBasedOnEmailMap[email];
        if(name)
            return name;
    }
    return email;
}

var entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;',
    "/": '&#x2F;'
};

var reverseEntityMap = {
    "&amp;"    :"&",
    "&lt;"     :"<",
    "&gt;"     :">",
    '&quot;'   :'"',
    '&#39;'    :"'",
    '&#x2F;'   :"/"    
};

export function escapeHtml(string) {
    if(!string) {
        return null;
    }

    let newString= String(string.trim()).replace(/[&<>"'\/]/g, function (s) {
        return entityMap[s];
    });
    // console.log(`escapeHtml(${string})==>`,newString);
    return newString
}

export function unEscapeHtml(string) {
    if(!string) {
        return null;
    }
    // console.log("Input string==>",string);
    let newString= String(string.trim()).replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;/g, function (s) {
        // console.log("s==>",s);
        return reverseEntityMap[s];
    });
    // console.log(`unEscapeHtml(${string})==>`,newString);
    return newString
}

export function customStringComparator(string1,string2){
    if(string1 && string2){
        return string1.localeCompare(string2)
    }

    return -1;
}

export function customDateComparator(date1,date2){
    if(date1 && date2){
        if (date1 > date2) {
            return 1;
        } else if (date1 < date2) {
            return -1;
        } else {
            return 0;
        }
    }

    return -1;
}

export function customUpdatedByComparator(valueA, valueB, nodeA, nodeB, isDescending) {
    // console.log("nodeA==>",nodeA);
    // console.log("nodeB==>",nodeB);
    // console.log("isDescending==>",isDescending);
    let tempA=getNameBasedOnEmail(nodeA.data.updated_by);
    let tempB=getNameBasedOnEmail(nodeB.data.updated_by);
    if((tempA===null || tempA===undefined) && (tempB===null || tempB===undefined)){
        return 0;
    }else if(tempA===null || tempB===null || tempA===undefined || tempB===undefined){
        return isDescending?1:-1;
    }else{
        return tempA.toLowerCase().localeCompare(tempB.toLowerCase());
    }
}

export function getDropdownValuesForYear(){ // this function is used to get dropdown values for year columns from current year (-10 years to +5 years)
    let years;
    let year = new Date().getFullYear()
    years = Array.apply(0, Array(10)).map(function (index, key) { return { "label": `${(year - 1) - key}`, "value": `${(year - 1) - key}` }; })
    years = [...years, ...Array.apply(0, Array(6)).map(function (index, key) { return { "label": `${year + key}`, "value": `${year + key}` }; })]
    years = [...years, {"label":"ALL","value":"ALL"},{"label": "Blanks", "value": "Blanks" }].sort((a, b) => { return a.value - b.value })

    return years
}

export function customNumericSortComparator(valueA, valueB) { 
    if(Number(valueA)==Number(valueB)){
       return 0;
   } else if(Number(valueA)>Number(valueB)) {
       return 1;
   } else {
      return -1
   }
}

export function cleanFilterParams(filterParams){
    // console.log("filterParams===>",filterParams);
    let newFilterparams={};
    Object.keys(filterParams).forEach(field=>{
        if(Array.isArray(filterParams[field]) && filterParams[field].length>0){
            let tempArr=[];
            filterParams[field].forEach(obj=>{
                tempArr.push(obj.value);
            });
            newFilterparams[field]=tempArr;
        }
    });

    return newFilterparams;
}

//Checks if filter object is empty
//Returns true or false, 
export const isFilterEmpty=(filter)=>{
    // console.log("isFilterEmpty: Inside isFilterEmpty:: ", filter);
    if(filter){
        // console.log("isFilterEmpty: Filters are defined");
        let filterKeys = Object.keys(filter);
        // console.log("isFilterEmpty: filterKeys==>",filterKeys);
        if(filterKeys.length===0){
            // console.log("isFilterEmpty: No Keys available")
            return true; 
        }
        for(let i=0;i<filterKeys.length;i++){
            let key=filterKeys[i];
            let data=filter[key];
            // console.log("isFilterEmpty: Validating data for ",key);
            if(!data){//If data is null or undefined then skip the present iteration and go to next key
                continue;
            }

            if(typeof data==="string" && data!==""){
                // console.log("isFilterEmpty: data is of type String and it is not null");
                return false;
            }else if(typeof data==="object"){
                if(Array.isArray(data)){
                    // console.log("isFilterEmpty: data is an array");
                    if(data.length>0){
                        // console.log("isFilterEmpty: data has elements");
                        return false;
                    }                    
                }else{
                    // console.log("isFilterEmpty: data is an object");
                    if(Object.keys(data).length>0){
                        // console.log("isFilterEmpty: data has some properties");
                        return false;
                    }
                }
            }else {
                // console.log("isFilterEmpty: data is numeric");
                //Data is numeric
                if(data!==0){
                    // console.log("isFilterEmpty: data is nonzero");
                    return false;
                }
            }
          
        };
    }

    return true;
}

export const allPlanSorter=(p1,p2)=>{
    return parseInt(p2.id)-parseInt(p1.id); //Example: 100-200=-100, it means that p1 should come before p2. -ve means p1 should come before p2 & +ve means that p2 should come before p1
}