const filterColumnDefs = () => {
    return ({
        "data": [
            {
                "colDef": "program_year",
                "displayName": "Program Year",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "program_type",
                "displayName": "Types",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "acceptable_zones",
                "displayName": "Zones",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "allocation_month",
                "displayName": "Allocation Month",
                "type": "datepicker",
                 "options": [],
            },
            {
                "colDef": "car_class_code",
                "displayName": "Class",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "model_group",
                "displayName": "Model",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "model_year",
                "displayName": "Model Year",
                "type": "dropdown",
                "options": [],

            },
            {
                "colDef": "manufacturer_group",
                "displayName": "Manufacturer",
                "type": "dropdown",
                "options": [],

            },

            {
                "colDef": "min_schedule",
                "displayName": "Min Schedule",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "max_schedule",
                "displayName": "Max Schedule",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "spi_code",
                "displayName": "SPI Code",
                "type": "dropdown",
                "options": [],
            },
        ]
    });
}

export default filterColumnDefs;