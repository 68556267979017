import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import IconFilter from "../../assets/images/icon_filter.svg";
import IconGrid from "../../assets/images/icon_grid.svg";
import IconGridInActive from "../../assets/images/icon_grid_inactive.svg";
import IconActionEdit from "../../assets/images/icon_action_edit.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../assets/images/icon_action_download.svg";

import SearchBar from "../searchbar/SearchBarComponent";
import { NttDropdown } from '../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import './ActionBar.css';
import { fetchFilterData, setFilterParameters, fetchProgramData,uploadProgramData, fetchFilterConstraints, cloneProgramFilterMap, fetchProgramDropdownValues } from '../../store/programs/Programs.Actions';
import DialogBox from "../dialog_component/CustomDialog";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import InputFilter from './filter_components/input_filter/InputFilter';
import DatePickerFilter from './filter_components/datepicker_filter/DatePickerFilter';
import { Button, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import RangeFilter from '../constraintsactionbar/filter_components/range_filter/Constraint_RangeFilter';
import DropdownFilter from '../constraintsactionbar/filter_components/dropdown_filter/constraint_DropdownFilter';
import CustomPopOver from "../../core-components/Popover/CustomPopOver";
import EditIcon from "../../assets/images/icon_edit.svg";
import StatusIcon from "../../assets/images/icons_status_icons_completed.svg";
import { NttLabel } from '../../core-components/Label/NttLabel';
import DeleteIcon from '../../assets/images/icon_trash.svg';
import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';

import { safeExcelValue, safeExcelDate, areFiltersEqual, deepCloneFilterObject } from '../../utils/utils';
import { populateGrid2, validateGrid } from '../../store/MultiGrid/MultiGrid.Actions';
import {searchRecord} from "../../store/programs/Programs.Actions";
import ChipsSection, { prepareChipList } from '../filterchips/chips';
import { CHIP_OVER_LIMIT_FLAG } from '../../Constants';
import SingleButtonDialogComponent from '../Dialogcomponent/SingleButtonDialogComponent';


const ActionBar = (props) => {
  const { options, actionView, onViewChanged, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, selectedRows, data, downloadCSV,
        // filterParams, 
        transactions, programsData, programgridcoldef, filterSearchKey,isFiltered,
        setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists  } = props;
  let [disabled, setDisabled] = useState(false);
  let [currentView, setCurrentView] = useState(actionView);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  // var filterCat = { "PROGRAM_YEAR": [], "PROGRAM_TYPE": [], "ACCEPTABLE_ZONES": [], "CAR_CLASS_CODE": [], "MODEL_GROUP": [], "MODEL_YEAR": [], "NET_CAPITAL_COST": "", "BEGIN_DATE": ["between"], "END_DATE": ["between"], "MIN_AGE": ["between"], "MAX_AGE": ["between"], "DEPRECIATION_COST": "", "MIN_MILEAGE": ["between"], "MAX_MILEAGE": ["between"], "INCENTIVE": "", "ADD_VOLUME_MIN": ["between"], "ADD_VOLUME_MAX": ["between"], "SPI_CODE": [], "MANUFACTURER_GROUP": [], "START_MILEAGE": "", "BUY_STATUS": [] };
  // const [filterArray, setFilterArray] = useState(cloneProgramFilterMap());
  const [tempFilterArray, setTempFilterArray] = useState(cloneProgramFilterMap());
  const [popUp, setPopUp] = useState(null);
  const theme = createMuiTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(-1);
  const [filterClassName, setFilterClassName] = useState('filterCustomButton');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsFilterSearchKey,setFlterSearchKey] = useState('');
  const [showDialog,setShowDialog]=useState(false)
  const filterPopOverOpen = Boolean(filterAnchorEl);
  const filterId = filterPopOverOpen ? 'simple-popover' : undefined;
  let message = "Are you sure that you want to delete selected rows?"
  let title = "Delete Row";
  let deletetext = "Delete";
  let canceltext = "Cancel";
  
  

  theme.typography.body2 = {
    fontSize: '14px'
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: '180vh',
      maxHeight: '180vh',
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDropdown, setSelectedDropdown] = React.useState("between");
  const [selectedFromDate, setSelectedFromDate] = React.useState();
  const [selectedToDate, setSelectedToDate] = React.useState();
  const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
  const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
  const [chipList, setChipList] = useState([]);


  const filterParams = useSelector((state) => {
    // console.log("Filter updated ...",state.rootProgramsReducer.programsReducer.filterParams);
    return state.rootProgramsReducer.programsReducer.filterParams;
  });

  var filterData = useSelector((state, shallowEqual) => {
    return state.rootProgramsReducer.programsReducer.data;
  });

  var filterCategories = useSelector((state, shallowEqual) => {
    return state.rootProgramsReducer.programsReducer.categories;
  });

  const masterDataUpdateState = useSelector((state)=>{
    return state.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  })

  const loadFilterData = useCallback(async (programsData) => {
    try {
      await dispatch(fetchFilterData(programsData));
    } catch (err) {
      console.error('got error while loading dealer Branch details');
    }
  }, [dispatch]);

  const loadFilterParams = useCallback(async (tempFilterArray) => {
    try {
        dispatch(setFilterParameters(tempFilterArray))
    } catch (err) {
        console.error('got error while loading filterparams');
    }
}, [dispatch]);

  const loadFilterCategories = useCallback(async () => {
    try {
      await dispatch(fetchFilterConstraints());
    } catch (err) {
      console.error('got error while loading dealer Branch details');
    }
  }, [dispatch]);

  const switchView = (index) => {
    if (index == 1) {
      setCurrentView("accordion");
      return onViewChanged("grid");
    } else {
      setCurrentView("grid");
      return onViewChanged("accordion");
    }
  }
  
  const handleChange = (event) => {
    switchView();
  };

  const handleEditClick = (e, selected) => {
    setAnchorEl(null);
    if (actionView == "grid") {
      setCurrentView("accordion");
      setSelectedIndex(1);
      return onViewChanged("grid");
    } else {
      setCurrentView("grid");
      setSelectedIndex(2);
      return onViewChanged("accordion");
    }
  };

  const handleDropDownValueChange = (event, displayName, colDef) => {
    setSelectedValue(event);
    var filterData = { ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key === colDef) {
        if (Array.isArray(filterData[key])) {
          filterData[key] = event;
        } else {
          if (event[0])
            filterData[key] = event[0].value;
          else
            filterData[key] = event.target.value;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const onDateDropDownValueChanged = (e, colDef) => {
    tempFilterArray[colDef][0] = e.target.value;
    setSelectedDropdown(e.target.value);
  }

  const onDateRangeChanged = (e, colDef, dateField) => {
    var date = new Date(e);
    if (dateField == "from")
      setSelectedFromDate(date);
    else if (dateField == "to")
      setSelectedToDate(date);
    var filterData = { ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key === colDef) {
        if (filterData[colDef][0] === "between") {
          if (filterData[key].length === 3) {
            if (dateField === "from") {
              filterData[key][1] = date;
            }
            if (dateField === "to") {
              filterData[key][2] = date;
            }
          } else {
            if (filterData[key].length == 2 && dateField == "from")
              filterData[key][1] = date;
            else if (filterData[key].length == 2 && dateField == "to")
              filterData[key][2] = date;
            else
              filterData[key].push(date);
          }
        } else {
          if (filterData[key].length == 1)
            filterData[key].push(date);
          else
            filterData[key][1] = date;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const onRangeDropDownValueChanged = (e, colDef) => {
    let filterData = { ...tempFilterArray };
    filterData[colDef][0] = e.target.value;
    setTempFilterArray(filterData);
    setSelectedDropdown(e.target.value);
  }

  const onValueRangeChanged = (e, colDef, rangeField) => {
    var range = e.target.value;
    if (rangeField == "from")
      setSelectedFromRangeValue(range);
    else if (rangeField == "to")
      setSelectedToRangeValue(range);
    var filterData = { ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key == colDef) {
        if (filterData[colDef][0] == "between") {
          if (filterData[key].length === 3) {
            if (rangeField === "from") {
              filterData[key][1] = range;
            }
            if (rangeField === "to") {
              filterData[key][2] = range;
            }
          } else {
            // if (filterData[key].length == 1 && rangeField == "to") {
            //   filterData[key].push("0");
            // }
            // filterData[key].push(range);
            if (filterData[key].length == 1 && rangeField == "to") {
              filterData[key][1] = 0;
              filterData[key][2] = range;
            }
            else if (rangeField == "from") {
              filterData[key][1] = range;
            }
            else {
              filterData[key][2] = range;
            }
          }
        } else {
          if (filterData[key].length == 1)
            filterData[key].push(range);
          else
            filterData[key][1] = range;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const getSelectedValue = (colDef) => {
    if (tempFilterArray !== undefined) {
      return tempFilterArray[colDef];
    }
  }

  const applyFilter = () => {
    // console.log("Applying Filter...");
    setOpen(false);
    setFlterSearchKey("");
    setIsSearchBarValueExists(false);
    // setFilterArray({...tempFilterArray});
    // console.log("INSIDE APPLY FILTER==",tempFilterArray);
    loadFilterParams({...tempFilterArray});
    setIsFiltered(true);
  }

  const applyFilterImmediately = (filterParams) => {
    // console.log("Applying Filter...");
    setOpen(false);
    // setFilterArray({...tempFilterArray});
    // console.log("INSIDE APPLY FILTER==",filterParams);
    loadFilterParams({...filterParams});
    setIsFiltered(true);
  }
/*isSearched parameter can be true and false 
We are utilizing this because clearFilter function is utilized in many places but for some situation
only we want the fetch api to be called so whenevr isSearched is false api call will happen
*/
  const clearFilter = (isSearched) => {
    // console.log("Clearing Filter...");
    //setOpen(false);
    if(!isSearched){
    dispatch(fetchProgramData(selectedPlanId));
  }
    // setFilterArray(cloneProgramFilterMap());
    setTempFilterArray(cloneProgramFilterMap());
    setSelectedFilterIndex(-1);
    loadFilterParams(null);
    setActiveIndex(0);
    setIsFiltered(false);
  }

  const fetchDialogContent = () => {
    if (filterData !== undefined && filterData !== null && filterData.length > 0) {
      return (<div className="flexContainer">
        {filterData.map((fields, index) => {
          return (<div className="filterMain">
            {(fields.type === "dropdown") ?
              (<DropdownFilter displayName={fields.displayName} options={fields.options}
                theme={theme} selectedValue={getSelectedValue(fields.colDef)}
                onValueChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}>
              </DropdownFilter>) :
              (fields.type === "input") ?
                (<InputFilter selectedValue={getSelectedValue(fields.colDef)}
                  displayName={fields.displayName} theme={theme}
                  onTextChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}></InputFilter>) :
                (fields.type === "datepicker") ?
                  (<DatePickerFilter displayName={fields.displayName} theme={theme}
                    selectedDate={tempFilterArray[fields.colDef]}
                    onFromDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "from")}
                    onToDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "to")}
                    onValueChanged={(e) => onDateDropDownValueChanged(e, fields.colDef)}>
                  </DatePickerFilter>) :
                  (fields.type === "range") ?
                    (<RangeFilter displayName={fields.displayName} theme={theme}
                      selectedRangeValue={tempFilterArray[fields.colDef]}
                      onFromValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "from")}
                      onToValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "to")}
                      onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.colDef)}
                    >
                    </RangeFilter>) :
                    <div></div>}</div>);
        }
        )}</div>);
    }
  }

  const handleClose = (e) => {
    setOpen(false);
    setTempFilterArray(filterParams?filterParams:cloneProgramFilterMap());
  };

  const changeHandler = async (event) => {
    if (event.target.value !== '') {
      const fileToUpload = event.target.files[0];
      event.target.value = '';
      dispatch(uploadProgramData(selectedPlanId, fileToUpload));
    }
  };

  const downloadHandler = async (event) => {
    let JsonFields = ["PROGRAM_YEAR","MANUFACTURER_GROUP","SPI_CODE","PROGRAM_TYPE","MODEL_GROUP","MODEL_YEAR","CAR_CLASS_CODE","BEGIN_DATE","END_DATE","ACCEPTABLE_ZONES","MIN_AGE","MAX_AGE","MIN_MILEAGE","MAX_MILEAGE","NET_CAPITAL_COST","DEPRECIATION_COST","INCENTIVE","ADD_VOLUME_MIN","ADD_VOLUME_MAX","START_MILEAGE","BUY_STATUS"];
    let filteredData = filterRecords(programsData, filterParams);
    let data1 = JsonToCSV(filteredData, JsonFields);
    let currentdate = new Date();
    let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
    downloadCSV(data1, `programs-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`);
  };

  function JsonToCSV(JsonArray, JsonFields) {
    var csvStr = JsonFields.join(",") + "\n";

    JsonArray.forEach(element => {
      let program_year = safeExcelValue(element.PROGRAM_YEAR);
      let manufacturer_group_code = safeExcelValue(element.MANUFACTURER_GROUP);
      let spi_code = safeExcelValue(element.SPI_CODE);
      let program_type = safeExcelValue(element.PROGRAM_TYPE);
      let model_group = safeExcelValue(element.MODEL_GROUP);
      let model_year = safeExcelValue(element.MODEL_YEAR);
      let car_class_code = safeExcelValue(element.CAR_CLASS_CODE);
      let begin_date = safeExcelDate(element.BEGIN_DATE, 'DD-MMM-YY');
      let end_date = safeExcelDate(element.END_DATE, 'DD-MMM-YY');
      let acceptable_zone = safeExcelValue(element.ACCEPTABLE_ZONES);
      let min_age = safeExcelValue(element.MIN_AGE);
      let max_age = safeExcelValue(element.MAX_AGE);
      let min_milage = safeExcelValue(element.MIN_MILEAGE);
      let max_milage = safeExcelValue(element.MAX_MILEAGE);
      let net_capital_cost = safeExcelValue(element.NET_CAPITAL_COST);
      let depreciation_cost = safeExcelValue(element.DEPRECIATION_COST);
      let incentive = safeExcelValue(element.INCENTIVE);
      let add_volume_min = safeExcelValue(element.ADD_VOLUME_MIN);
      let add_volumn_max = safeExcelValue(element.ADD_VOLUME_MAX);
      let start_milage = safeExcelValue(element.START_MILEAGE);
      let buy_status = safeExcelValue(element.BUY_STATUS);
      csvStr += program_year + ',' + manufacturer_group_code + ',' + spi_code + ',' + program_type + ',' + model_group + ',' + model_year + ',' + car_class_code + ',' + begin_date + ',' + end_date + ',' + acceptable_zone + ',' + min_age + ',' + max_age + ',' + min_milage + ',' + max_milage + ',' + net_capital_cost + ',' + depreciation_cost + ',' + incentive + ',' + add_volume_min + ',' + add_volumn_max + ',' + start_milage + ',' + buy_status + "\n";
    })
    return csvStr;
  }

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handlePopOverClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    return switchView(index);
  };

  const switchViewComponent = () => {
    return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
      <Button id={id} onClick={(e) => handlePopOverClick(e)} endIcon={<ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon>}>View</Button>
      <CustomPopOver
        className="actiobarPopOver"
        popOverId="popoverId"
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <List>
          <ListItem button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}>
            <img src={selectedIndex === 1 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
            <NttLabel theme={theme} labelVariant="body2">
              Group by Year {'>'} Manufacturer {'>'} Program</NttLabel>
          </ListItem>
          <Divider />
          <ListItem button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}>
            <img src={selectedIndex === 2 ? StatusIcon : EditIcon} className="imageSpacing"></img>
            <NttLabel theme={theme} labelVariant="body2">
              Table View</NttLabel>
          </ListItem>
        </List>
      </CustomPopOver>
    </div>);
  }

  useEffect(() => {
    if (actionView === "grid")
      setSelectedIndex(2);
    else
      setSelectedIndex(1);
  }, [actionView]);

  const handleClickOpen = (props)=>{
    if(!bulkRowDeleteIconDisabled){
      setOpenDeleteDialog(true);
    }
  }

  const handleCloseDeleteDialog =()=>{
    setOpenDeleteDialog(false);
  }

  const handleDeleteSelectedRows =()=>{
    setOpenDeleteDialog(false);
    deleteSelectedRows()
  }

  const selectFilterType = (type) => {
    if (type == 'Custom Filter') {
        loadFilterData(programsData);
        setOpen(true);
    } else if(type == 'Clear Filter'){
        setOpen(false);
        // setFilterArray(cloneProgramFilterMap());
        setTempFilterArray(cloneProgramFilterMap());
        setSelectedFilterIndex(-1);
        loadFilterParams(null);
        setActiveIndex(0);
    }
};

const handleFilterPopOverClick = (e) => {
  setFilterClassName('filterSecondaryButton');
  setFilterAnchorEl(e.currentTarget);
}

const handleFilterPopOverClose = () => {
  setFilterClassName('filterPrimaryButton');
  setFilterAnchorEl(null);
};

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);

function filterRecords(array, filterparams) {
 // console.log("Inside filterRecords : INPUT===",filterparams);
  if (filterparams == null) {
    return array;
}else{
  const filterKeys = Object.keys(filterparams);
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filterparams[key] || !filterparams[key].length) return true;
            if (key === "BEGIN_DATE" || key === "END_DATE") {
                if (filterparams[key].length === 1) {
                    return true;
                }
                else {
                    if (item[key] == undefined && item.month) {
                        let d1 = new Date(filterparams[key][1]);
                        let d2 = new Date(item.month.split('T')[0]);
                        let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                        let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                        let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                        let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                        if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                            let d3 = new Date(filterparams[key][2]);
                            let NoTimeDate2 = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                            let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                            return itemValue >= startDate && itemValue <= endDate;
                        } else if (filterparams[key][0] === "less") {
                            return itemValue <= startDate;
                        } else if (filterparams[key][0] === "greater") {
                            return itemValue >= startDate;
                        }
                    }
                    else {
                        let d1 = new Date(filterparams[key][1]);
                        let d2 = new Date(item[key].split('T')[0]);
                        let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                        let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                        let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                        let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                        if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                            let d3 = new Date(filterparams[key][2]);
                            let NoTimeDate2 = d3.getFullYear() + '/' + (d3.getMonth() + 1);
                            let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                            return itemValue >= startDate && itemValue <= endDate;
                        } else if (filterparams[key][0] === "less") {
                            return itemValue <= startDate;
                        } else if (filterparams[key][0] === "greater") {
                            return itemValue >= startDate;
                        }
                    }
                }
            }
            if (typeof filterparams[key] === 'object' && key !== "BEGIN_DATE" && key !== "END_DATE" && 
            key !== "MIN_AGE" && key !== "MAX_AGE" && 
            key !== "ADD_VOLUME_MIN" && key !== "ADD_VOLUME_MAX" && 
            key !== "MIN_MILEAGE" && key !== "MAX_MILEAGE") {
                if (filterparams[key].length == 0) {
                    return true;
                }
                else {
                  if(key && filterparams[key][0].value==='Blanks'){
                      return ((item[key]===null) || (item[key]===undefined)) 
                  }else{
                      return (filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) == undefined ? filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) : filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])).value);
                  }
              }
            }
            if ((key === "MIN_AGE" || key === "MAX_AGE")) {
                if (filterparams[key].length === 1) {
                    return true;
                } else {
                    let startValue = parseInt(filterparams[key][1], 0);
                    let itemValue = parseInt(item[key], 0);
                    if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                        let endValue = parseInt(filterparams[key][2], 0);
                        if(filterparams[key][1] !=="" && filterparams[key][2] !== ""){
                            return item[key] >= startValue && itemValue <= endValue;
                        }else{
                            return true;
                        }
                    } else if (filterparams[key][0] === "less") {
                        if (filterparams[key][1] !== "") {
                            return itemValue <= startValue;;
                        } else {
                            return true;
                        } 
                    } else if (filterparams[key][0] === "greater") {
                        if(filterparams[key][1] !== ""){
                            return itemValue >= startValue;
                        }else {
                            return true;
                        } 
                    }
                }
            }  if ((key === "ADD_VOLUME_MIN" || key === "ADD_VOLUME_MAX")) {
                if (filterparams[key].length === 1) {
                    return true;
                } else {
                    let startValue = parseInt(filterparams[key][1], 0);
                    let itemValue = parseInt(item[key], 0);
                    if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                        let endValue = parseInt(filterparams[key][2], 0);
                        if(filterparams[key][1] !== "" && filterparams[key][2] !== ""){
                            return itemValue >= startValue && itemValue <= endValue;
                        }else{
                            return true;
                        } 
                    } else if (filterparams[key][0] === "less") {
                        if(filterparams[key][1] !== ""){
                            return itemValue <= startValue;
                        }else{
                            return true;
                        }
                    } else if (filterparams[key][0] === "greater") {
                        if(filterparams[key][1] !== ""){
                        return itemValue >= startValue;
                        }else{
                            return true;
                        }
                    }
                }
            } if ((key === "MIN_MILEAGE" || key === "MAX_MILEAGE")) {
                if (filterparams[key].length === 1) {
                    if(filterparams[key][0] === "blanks"){
                      return ((item[key]===null) || (item[key]===undefined) || (item[key]==="")) 
                    }else{
                      return true;
                    }
                } else {
                    let startValue = parseInt(filterparams[key][1], 0);
                    let itemValue = parseInt(item[key], 0);
                    if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                        let endValue = parseInt(filterparams[key][2], 0);
                        if(filterparams[key][1] !== "" && filterparams[key][2] !== ""){
                            return itemValue >= startValue && itemValue <= endValue;
                        }else{
                            return true;
                        }
                    } else if (filterparams[key][0] === "less") {
                        if(filterparams[key][1] !== ""){
                            return itemValue <= startValue;
                        }else{
                            return true;
                        }
                    } else if (filterparams[key][0] === "greater") {
                        if(filterparams[key][1] !== ""){
                            return itemValue >= startValue;
                        }else{
                            return true;
                        }
                    } else if (filterparams[key][0] === "blanks") {
                        return ((itemValue===null) || (itemValue===undefined) || (itemValue==="")) 
                    }
                }
            } if (typeof filterparams[key] === 'string') {
                return getValue(filterparams[key]) == getValue(item[key]);
            }
        });
    });
}  
}

const applyTransactions = (programsData, transactions) => {
  let deepcopyProgramsData = programsData.map(o => { return { ...o }; })
  transactions.map((value1) => {
      deepcopyProgramsData.map((value2, index) => {
          if (value1.type !== 'delete' && value1.type !== 'add' && value1.type !== 'copy' && (value2.ID == value1.data.ID)) {
              deepcopyProgramsData[index] = value1.data;
          } else if (value1.type == 'delete' && (value2.ID == value1.id)) {
              deepcopyProgramsData.splice(index, 1)
          }
      });
      if (value1.type == 'add' || value1.type == 'copy') { 
          if (value1.data !== undefined) {  // If data is undefined,app is braking while filter and clear filter.That's why adding condition.But here we loose added row on filter if it is blank.
              deepcopyProgramsData.push(value1.data);
          }
      }
  });
  return deepcopyProgramsData;
}

useEffect(()=>{
  if(masterDataUpdateState){
    //This dispatch and useEffect is triggered only if master cache data is been updated by admin 
    //masterDataUpdateState is at redux state for handling true/false if master cache data has been updated
    //Calling of DropDownService is used to push last updated cache master data to there respective dropdowns
      dispatch(fetchProgramDropdownValues(data,programgridcoldef,masterDataUpdateState));
      setShowDialog(true)
  }
},[masterDataUpdateState])

useLayoutEffect(() => {
  if (transactions.length == 0) {
    if(isFiltered==true||isSearchBarValueExists==true){
      dispatch(fetchProgramData(selectedPlanId));
    }
  } else if (transactions.length > 0) {
      var currentdata = applyTransactions(programsData, transactions);
      var filteredCurrentData = filterRecords(currentdata, filterParams);
      var filteredData = filterRecords(programsData, filterParams);
      dispatch(populateGrid2("programsgrid", filteredData, filteredCurrentData, programsFilterSearchKey, programgridcoldef.checkValue, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
  }
  setTempFilterArray(filterParams?filterParams:cloneProgramFilterMap());

  //Chip Management
  let chipListTemp=prepareChipList(filterParams,programgridcoldef.columnDefs,handleChipRemove);
  //console.log("chipListTemp===",chipListTemp);
  setChipList(chipListTemp);
  if(chipListTemp.length===0){
    setSelectedFilterIndex(-1);
  }
  
  // console.log("UseEffect on filterParams, programsFilterSearchKey...");
}, [filterParams, programsFilterSearchKey])

useLayoutEffect(() => {
  if (programsData.length > 0) {
      var filteredData = filterRecords(programsData, filterParams);
      dispatch(populateGrid2("programsgrid", filteredData, filteredData, programsFilterSearchKey, programgridcoldef.checkValue, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
  }
  // console.log("UseEffect on programsData...");
}, [programsData])

  const filterComponent = () => {
    return (<div className={(filterPopOverOpen || selectedFilterIndex === -1) ? 'filterCustomButton' : filterClassName} aria-describedby={id}>
        <Button id={id} onClick={(e) => handleFilterPopOverClick(e)} startIcon={<img src={IconFilter} />} endIcon={(selectedFilterIndex !== -1) ? <ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon> : ''}>
            {selectedFilterIndex === 1 ? 'Custom Filter' :(selectedFilterIndex === 2) ? 'Clear Filter' : ''}
        </Button>
        <CustomPopOver
            className="filterPopOver"
            popOverId="filterPopoverId"
            open={filterPopOverOpen}
            anchorEl={filterAnchorEl}
            onClose={handleFilterPopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <List>
                <ListItem button
                    selected={selectedFilterIndex === 1}
                    onClick={(event) => handleFilterListItemClick(event, 1)}>
                    <img src={(selectedFilterIndex === 1) ? StatusIcon : ''} className="imageSpacing"></img>
                    <NttLabel theme={theme} labelVariant="body2">
                        Custom Filter</NttLabel>
                </ListItem>
                <Divider />
                <ListItem button
                    selected={selectedFilterIndex === 2}
                    onClick={(event) => handleFilterListItemClick(event, 2)}>
                    <img src={selectedFilterIndex === 2 ? StatusIcon : ''} className="imageSpacing"></img>
                    <NttLabel theme={theme} labelVariant="body2">
                        Clear Filter</NttLabel>
                </ListItem>
            </List>
        </CustomPopOver>
    </div>);
}

const handleFilterListItemClick = (event, index) => {
  setFilterClassName('filterPrimaryButton');
  setSelectedFilterIndex(index);
  setFilterAnchorEl(null);
  var selectedValue;
  if (index === 1)
      selectedValue = "Custom Filter";
  else if (index === 2)
  selectedValue = "Clear Filter";
  return selectFilterType(selectedValue);
};

// const onSearchValueChanged =(e)=>{
//   setFlterSearchKey(e.target.value)
//   if(e.target.value===''){
//     setIsSearchBarValueExists(false);
//   }else{
//     setIsSearchBarValueExists(true);
//   }
//   dispatch(searchRecord(e.target.value));
// }

const onSearchValueChanged = (e) => {
  if (e.target.value === "" && programsFilterSearchKey!='') {
    setIsSearchBarValueExists(false);
    setFlterSearchKey(e.target.value);
    dispatch(fetchProgramData(selectedPlanId));
  }
};

const onKeyEnterSearch = (e) => {
  setFlterSearchKey(e.target.value);
  if (e.target.value != "") {
    clearFilter(true);
    setFlterSearchKey(e.target.value);
    setIsSearchBarValueExists(true);
  } else {
    setIsSearchBarValueExists(false);
  }
  dispatch(searchRecord(e.target.value));
};
const handleChipRemove = (param,value) => {// Only removes from filter
  // console.log(`Params=${param} and Value=${value}`)
  var filterData = deepCloneFilterObject(filterParams);
  // console.log(`filterData=`,filterData);
  if(CHIP_OVER_LIMIT_FLAG!==value && 
      (param === "PROGRAM_YEAR" ||
       param === "PROGRAM_TYPE" ||
       param === "ACCEPTABLE_ZONES" ||
       param === "CAR_CLASS_CODE" ||
       param === "MODEL_GROUP" ||
       param === "MODEL_YEAR" ||
       param === "SPI_CODE" ||
       param === "MANUFACTURER_GROUP" ||
       param === "BUY_STATUS")
  ){
    // console.log("In handleChipRemove filterData[param]===",filterData[param]);
    filterData[param] = filterData[param].filter(item=>item.value!==value);
    // console.log("In handleChipRemove After Update filterData[param]===",filterData[param]);
  }else if(CHIP_OVER_LIMIT_FLAG===value){
    filterData[param] = []
  }else if(param === "BEGIN_DATE" || 
           param === "END_DATE" || 
           param === "MIN_AGE" || 
           param === "MAX_AGE" || 
           param === "MIN_MILEAGE" || 
           param === "MAX_MILEAGE" || 
           param === "ADD_VOLUME_MIN" || 
           param === "ADD_VOLUME_MAX" ){
    filterData[param]=["between"];
  }else {
    filterData[param]="";
  }
  
  applyFilterImmediately(filterData);
}

const handleDialogClose=()=>{
  setShowDialog(false);
}

  return (
    <div className={actionView == "grid" ? "mainHeader": "fixedMainHeader"}>
      <div className="filterStyle">
      <div className="programsfilterMargin">{filterComponent()}</div>
         {/* <SearchBar onSearchValueChanged ={onSearchValueChanged}></SearchBar> */}
          <SearchBar onSearchValueChanged={onSearchValueChanged} onEnterSearch={true} onKeyEnterSearch={onKeyEnterSearch} filterSearchText={filterSearchKey} showActiveIconOnEnter={isSearchBarValueExists}></SearchBar>
      </div>
      
      {options && programgridcoldef.defaultColDef.editable?
        <div className="saveButtons">
          <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
          </Button></div>
          <div className="primaryButton">
            <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
            </Button></div>
          <div className="primaryButton">
            {/* Disable Add + buttton if plan status running  */}
            <Button disabled={!programgridcoldef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>Add +
            </Button>
          </div>
          <div className="primaryButton">
            {/* Disable delete delete button if plan is running  */}
            <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || !programgridcoldef.defaultColDef.editable}>
            Delete
           </Button>
          </div>
        </div> :
        (<div className="actionButtons">
          {(currentView !== "grid" && actionView !== "grid") ? <img src={IconActionEdit} className="imageIcon" onClick={(e) => handleEditClick(e)}></img> :
            <img src={IconGrid} className="imageIcon" onClick={(e) => handleEditClick(e)}></img>}
          {/* <img src={IconActionUpload} className="imageIcon" ></img> */}
          <label>
            {/* Disable upload file button if plan status is running  */}
            <input disabled={!programgridcoldef.defaultColDef.editable} type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
            <img src={IconActionUpload} className="imageIcon"></img>
          </label>
          <div className='imageIcon'>
            <img src={IconActionDownload} onClick={() => downloadHandler()} />
          </div>
          {switchViewComponent()}
          {(currentView === "grid" || actionView === "grid") ?
          <div style={{display:'flex'}}>
            <div className="primaryButton">
              {/* Disable Add + button if plan status is running */}
              <Button disabled={!programgridcoldef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>
                Add +
            </Button></div>
            <div className="primaryButton">
              {/* Disable Delete button if plan status is running */}
              <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || !programgridcoldef.defaultColDef.editable}>
             Delete
            </Button>
            </div>
            </div>
            : <div></div>}
        </div>)}
        <ChipsSection chipList={chipList} handleClearFilter={clearFilter}/>
      <div>
        <DialogBox classes={{ paper: classes.dialogPaper }}
          open={open} handleClose={(e) => handleClose(e)} handlePositiveBtnClick={(e) => applyFilter(e)} handleNegativeBtnClick={(e) => clearFilter(false)}
          dialogContent={fetchDialogContent()} title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter">
        </DialogBox>
        <DeleteDialogBox open={openDeleteDialog}  handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
          dialogMessage={message} title={title} action={deletetext}
          canceltext={canceltext}>
        </DeleteDialogBox>
        <SingleButtonDialogComponent open={showDialog} handleOk={handleDialogClose}
          dialogMessage={"Master Data Has Been Updated!!"} title={"Alert!!"} action="Ok">
        </SingleButtonDialogComponent>
      </div>
    </div>
  )
}

export default ActionBar;