import * as CONSTANTS from '../../../../../Constants';
import { formatDateToOne } from '../../../../../utils/formatters/valueFormatter';

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Pool.field, headerName: CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Pool.headerName, unSortIcon: true,
        cellEditor: "autoCompleteEditor",filterType:"dropdown",filterOptions:[],cellClassRules: cellClassRules(CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Pool.name), tooltipValueGetter: params => {
            return toolTipUI(params,CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Pool.name)
        }
    },
    {
        field: CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Month.field, headerName: CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Month.headerName, 
        cellEditor: 'dateEditor',filterType:"datepicker",valueFormatter: params => formatDateToOne(params.data.MONTH),unSortIcon: true,cellClassRules: cellClassRules(CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Month.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Month.name)
        }
    },
    {
        field: CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Count.field, headerName: CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Count.headerName,
        type: 'numericColumn',comparator: customCountSortComparator,filterType:"input",cellEditor: 'numericEditor',unSortIcon: true,cellClassRules: cellClassRules(CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Count.name), tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.MainRFInputTncColumns.MainRFInputTncIceColumns.Count.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer:'MainRFInputsActionRenderer',
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function customCountSortComparator(valueA, valueB) {
     if(Number(valueA)==Number(valueB)){
        return 0;
    } else if(Number(valueA)>Number(valueB)) {
        return 1;
    } else {
       return -1
    }
}

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW" || (params.data.row_validation_status && params.data.row_validation_status.includes("D"))) {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
