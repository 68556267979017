import {FETCH_MOVE_CONSTRAINT_DATA_PENDING,FETCH_MOVE_CONSTRAINT_DATA_SUCCESS,FETCH_MOVE_CONSTRAINT_DATA_ERROR,
FETCH_MOVE_CONSTRAINT_DROPDOWN_PENDING, FETCH_MOVE_CONSTRAINT_DROPDOWN_SUCCESS, FETCH_MOVE_CONSTRAINT_DROPDOWN_ERROR,
SAVE_MOVE_CONSTRAINTS_TRANSACTION_PENDING,SAVE_MOVE_CONSTRAINTS_TRANSACTION_SUCCESS,SAVE_MOVE_CONSTRAINTS_TRANSACTION_ERROR,
UPLOAD_MOVE_CONSTRAINTS_PENDING,UPLOAD_MOVE_CONSTRAINTS_SUCCESS,UPLOAD_MOVE_CONSTRAINTS_ERROR,RESET_TO_MOVE_CONSTRAINTS_INITIALSTATE} from './MoveConstraints.ActionTypes';
import {fetchMoveConstraintsDropdownService, getAllMoveConstraints, saveMoveConstraintTransactions, uploadMoveConstraintsCSV} from '../../services/moveconstraints/moveconstraintsservice';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchMoveConstraintsData =(planId)=>{
    return async dispatch =>{
        try{
            dispatch(serviceActionPending(FETCH_MOVE_CONSTRAINT_DATA_PENDING));
            let response = await getAllMoveConstraints(planId);
            if (response) {
                dispatch(serviceActionSuccess(FETCH_MOVE_CONSTRAINT_DATA_SUCCESS, response));
            }
        }catch(e){
            dispatch(serviceActionError(FETCH_MOVE_CONSTRAINT_DATA_ERROR, "Unknown error"));
        }
    }
}

export const fetchMoveConstraintDropdownValues = (data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_MOVE_CONSTRAINT_DROPDOWN_PENDING));
            //let response = await getAllMoveConstraints(planid);
            let response =await fetchMoveConstraintsDropdownService()
            dispatch(serviceActionSuccess(FETCH_MOVE_CONSTRAINT_DROPDOWN_SUCCESS, response));
             //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
             if (validateTransactionsIfMasterDataChanged) {
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("moveconstraintsGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later
                dispatch(validateGrid("moveconstraintsgrid",data,coldef.validationFn,coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } catch (e) {
            dispatch(serviceActionError(FETCH_MOVE_CONSTRAINT_DROPDOWN_ERROR, "Unknown error"));
        }
    }
}

export const saveMoveConstraintsTransactions = (planid, transactionList) =>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MOVE_CONSTRAINTS_TRANSACTION_PENDING));
            await saveMoveConstraintTransactions(planid, transactionList);
            let constraintsData = await getAllMoveConstraints(planid);
            if (constraintsData) {
                dispatch(serviceActionSuccess(SAVE_MOVE_CONSTRAINTS_TRANSACTION_SUCCESS, constraintsData));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_MOVE_CONSTRAINTS_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const uploadMoveConstraintsData = (planId, files) =>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_MOVE_CONSTRAINTS_PENDING));
            let uploadResp = await uploadMoveConstraintsCSV(planId, files);
            let constraintData = await getAllMoveConstraints(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500')
            {
                dispatch(serviceActionSuccess(UPLOAD_MOVE_CONSTRAINTS_SUCCESS, constraintData));
            }
            else{
                dispatch(serviceActionError(UPLOAD_MOVE_CONSTRAINTS_ERROR, "MoveConstraints upload failed"));
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_MOVE_CONSTRAINTS_ERROR, "MoveConstraints upload failed"));
        }
    }
}

export const resetMoveConstraints = ()=>{
    return dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MOVE_CONSTRAINTS_INITIALSTATE));
    }
}

function serviceActionPending(type){
    return{
        type: type,

    }

}
function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }

}
function serviceActionError(type,error){
    return {
        type:type,
        error:error
    }
}