import {FETCH_ROLE_MANAGEMENT_DATA_PENDING, FETCH_ROLE_MANAGEMENT_DATA_SUCCESS,FETCH_ROLE_MANAGEMENT_DATA_ERROR} from "./RoleManagement.ActionTypes";

const initialState = {
    pending: false,
    error: null,
    roleManagementData:[]
}

export default function roleManagementReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ROLE_MANAGEMENT_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_ROLE_MANAGEMENT_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                roleManagementData: action.data,
                error: null
            });
        case FETCH_ROLE_MANAGEMENT_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.data.error
            });
        default:
            return state;
    }
}

