import React from 'react';
import AccordionView from '../../components/accordionview/AccordionView';

function AccordionContainer(props) {
    const { data, currentView, tabIndex, planid } = props;
    let accordionData = [];
    let accordiongrouping = {};
    if (currentView == 'accordion') {
        window.parent.document.body.style.overflow = "auto";
    }

    accordiongrouping = data.reduce((r, a) => {
        r[a.PROGRAM_YEAR] = r[a.PROGRAM_YEAR] || {};
        r[a.PROGRAM_YEAR][a.MANUFACTURER_GROUP] = r[a.PROGRAM_YEAR][a.MANUFACTURER_GROUP] || {};
        r[a.PROGRAM_YEAR][a.MANUFACTURER_GROUP][a.SPI_CODE] = r[a.PROGRAM_YEAR][a.MANUFACTURER_GROUP][a.SPI_CODE] || [];
        r[a.PROGRAM_YEAR][a.MANUFACTURER_GROUP][a.SPI_CODE].push(a);
        return r;
    }, Object.create(null));
    let groupedData1 = Object.entries(accordiongrouping).reverse();

    groupedData1.map((value, index) => {
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]);
        groupedData2.map((value1, ind) => {
            let obj1 = {}, obj3 = {};
            obj1['header'] = '';
            obj1['children'] = [];
            obj1['header'] = value1[0];
            obj['children'].push(obj1);
            Object.keys(value1[1]).map((val, index) => {
                let obj2 = {};
                obj2['header'] = '';
                obj2['children'] = [];
                obj3['content'] = [];
                Object.values(value1[1]).map((val1, ind) => {
                    if (val1[0].SPI_CODE == val) {
                        let programtype = '';
                        let buystatus = '';
                        if (val1[0].PROGRAM_TYPE !== undefined && val1[0].PROGRAM_TYPE !== null) {
                            programtype = val1[0].PROGRAM_TYPE.toUpperCase();
                        }
                        if (val1[0].BUY_STATUS !== undefined & val1[0].BUY_STATUS !== null) {
                            buystatus = val1[0].BUY_STATUS.toUpperCase();
                        }
                        obj2['header'] = val + ' ' + programtype + ' ' + buystatus;
                        obj3['content'].push(val1);
                        obj2['children'].push(obj3);
                        obj1['children'].push(obj2);
                    }
                })
            })
        })
        accordionData.push(obj);
    })

    return (
        <div>
            {accordionData != undefined && accordionData.length > 0 ? <AccordionView planid={planid} accordionData={accordionData} contentcomponent={props.AccordionContent} tabIndex={tabIndex}></AccordionView> :
                <div className="norecords">No Rows To Show</div>}
        </div>
    )
}
export default AccordionContainer;