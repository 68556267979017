export const FETCH_ADDITIONALCOST_DATA_PENDING = 'FETCH_ADDITIONALCOST_DATA_PENDING';
export const FETCH_ADDITIONALCOST_DATA_SUCCESS = 'FETCH_ADDITIONALCOST_DATA_SUCCESS';
export const FETCH_ADDITIONALCOST_DATA_ERROR = 'FETCH_ADDITIONALCOST_DATA_ERROR';
export const UPLOAD_ADDITIONALCOST_PENDING = 'UPLOAD_ADDITIONALCOST_PENDING';
export const UPLOAD_ADDITIONALCOST_SUCCESS = 'UPLOAD_ADDITIONALCOST_SUCCESS';
export const UPLOAD_ADDITIONALCOST_ERROR = 'UPLOAD_ADDITIONALCOST_ERROR';
export const SAVE_ADDITIONALCOST_TRANSACTION_PENDING = 'SAVE_ADDITIONALCOST_TRANSACTION_PENDING';
export const SAVE_ADDITIONALCOST_TRANSACTION_SUCCESS = 'SAVE_ADDITIONALCOST_TRANSACTION_SUCCESS';
export const SAVE_ADDITIONALCOST_TRANSACTION_ERROR = 'SAVE_ADDITIONALCOST_TRANSACTION_ERROR';
export const FETCH_ADDITIONALCOST_DROPDOWNVALUES_PENDING = 'FETCH_ADDITIONALCOST_DROPDOWNVALUES_PENDING';
export const FETCH_ADDITIONALCOST_DROPDOWNVALUES_SUCCESS = 'FETCH_ADDITIONALCOST_DROPDOWNVALUES_SUCCESS';
export const FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR = 'FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR';
export const RESET_TO_ADDITIONALCOST_INITIALSTATE = 'RESET_TO_ADDITIONALCOST_INITIALSTATE';
export const SET_ADDITIONALCOST_EDITABLE='SET_ADDITIONALCOST_EDITABLE';