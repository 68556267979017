import {
    FILTER_SEARCH_UPGRADEPATH_DATA, FETCH_UPGRADEPATH_DATA_PENDING, FETCH_UPGRADEPATH_DATA_SUCCESS, FETCH_UPGRADEPATH_DATA_ERROR,
    GET_PATHS_GROUPBY_DATA, FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_PENDING, FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_SUCCESS, FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_ERROR,
    PATH_SAVE_TRANSACTION_PENDING, PATH_SAVE_TRANSACTION_SUCCESS, PATH_SAVE_TRANSACTION_ERROR,
    DELET_UPGRADEPATH_DATA_PENDING, DELET_UPGRADEPATH_DATA_SUCCESS, DELET_UPGRADEPATH_DATA_ERROR, UPLOAD_UPGRADEPATH_PENDING, UPLOAD_UPGRADEPATH_SUCCESS, UPLOAD_UPGRADEPATH_ERROR,
}
    from "../../store/upgradepaths/UpgradeTemplates.ActionTypes";
import { fetchUpgradePathsData, fetchCarClassGroupData, saveUpgradePaths, deleteUpgradePath, uploadUpgradePathsCSV } from "../../services/upgradepaths/UpgradeTemplatesService";
import { validateGrid } from "../MultiGrid/MultiGrid.Actions";
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from "../topbar/AllPlans.ActionTypes";

export const fetchUpgradePaths = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_UPGRADEPATH_DATA_PENDING));
            let upgradePathsData = await fetchUpgradePathsData(planid);
            if (upgradePathsData) {
                dispatch(serviceActionSuccess(FETCH_UPGRADEPATH_DATA_SUCCESS, upgradePathsData));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_UPGRADEPATH_DATA_ERROR, "Unknown error"));
        }
    }
}

export const fetchUpgradeCarClassgroup = (data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_PENDING));
            let upgradePathCarGroupData = await fetchCarClassGroupData();
            if (upgradePathCarGroupData) {
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_SUCCESS, upgradePathCarGroupData));
                //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values             
                if(validateTransactionsIfMasterDataChanged){
                    if(isNewArchitecture){ // this if is for new architecture
                        dispatch(newValidateGrid("upgradepathsGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                    }
                    else{ // this else is for old architecture and will be removed later
                    dispatch(validateGrid("upgradepathsgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                    }
                }
                dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));         
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_ERROR, "Unknown error"));
        }
    }
}

export const searchUpgradePathRecord = (searchParameters) => {
    return serviceActionSuccess(FILTER_SEARCH_UPGRADEPATH_DATA, searchParameters);
}

export const groupByPaths = (sortData) => {
    return serviceActionSuccess(GET_PATHS_GROUPBY_DATA, sortData);
}

export const deleteRow = (planid,id) =>{
    return async dispatch => {
        dispatch(serviceActionPending(DELET_UPGRADEPATH_DATA_PENDING));
        let res = await deleteUpgradePath(planid,id);
        if (res && res.body.data && res.body.data.length == 0) {   
            let upgradePathData = await fetchUpgradePathsData(planid)
            if (upgradePathData) {
                dispatch(serviceActionSuccess(DELET_UPGRADEPATH_DATA_SUCCESS, upgradePathData));
            }
            else {
                dispatch(serviceActionError(DELET_UPGRADEPATH_DATA_ERROR, upgradePathData));
            }
        } else {
            dispatch(serviceActionError(DELET_UPGRADEPATH_DATA_ERROR, res.response));
        }
    }
}

export const saveUpgradePathTranctions = (planid, transactionList) =>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(PATH_SAVE_TRANSACTION_PENDING));
            await saveUpgradePaths(planid, transactionList);
            let upgradePathData = await fetchUpgradePathsData(planid);
            if (upgradePathData) {
                dispatch(serviceActionSuccess(PATH_SAVE_TRANSACTION_SUCCESS, upgradePathData));
            }
        } catch (_) {
            dispatch(serviceActionError(PATH_SAVE_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const uploadUpgradePathsData = (planId, files) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_UPGRADEPATH_PENDING));
            let uploadResp = await uploadUpgradePathsCSV(planId, files);
            let upgradePathData = await fetchUpgradePathsData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500')
                dispatch(serviceActionSuccess(UPLOAD_UPGRADEPATH_SUCCESS, {"upgradepath": upgradePathData, "filterBy": false}));
            else
                dispatch(serviceActionError(UPLOAD_UPGRADEPATH_ERROR, "UPGRADEPATH upload failed"));
           
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_UPGRADEPATH_ERROR, "UPGRADEPATH upload failed"));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, allplans) {
    return {
        type: type,
        data: allplans
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}