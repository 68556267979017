import {getTncIceData,getTncIceDropdownService,saveTransactions,uploadTncIceCSV} from '../../../../services/revenuefunction/mainrfinputs/tncice/TncIceService';
import { SetRFError, SetRFPending } from '../../RevenueFunction.Actions';
import {FETCH_MAINRFINPUTS_TNCICE_DATA_PENDING,FETCH_MAINRFINPUTS_TNCICE_DATA_SUCCESS,FETCH_MAINRFINPUTS_TNCICE_DATA_ERROR,FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_PENDING,FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_SUCCESS,FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_ERROR,
        SAVE_TNCICE_TRANSACTION_PENDING,SAVE_TNCICE_TRANSACTION_SUCCESS,SAVE_TNCICE_TRANSACTION_ERROR,RESET_TO_TNCICE_INITIALSTATE,UPLOAD_TNCICEDATA_PENDING,UPLOAD_TNCICEDATA_SUCCESS,UPLOAD_TNCICEDATA_ERROR} from "./TncIce.ActionTypes";

export const fetchMainRFInputTncData = (rfid)=>{
    return async dispatch=>{
        try{
            dispatch(serviceActionPending(FETCH_MAINRFINPUTS_TNCICE_DATA_PENDING))
            dispatch(SetRFPending(true))
            let tncIceData=await getTncIceData(rfid);
            if(tncIceData){
                dispatch(serviceActionSuccess(FETCH_MAINRFINPUTS_TNCICE_DATA_SUCCESS,tncIceData))
                dispatch(SetRFPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MAINRFINPUTS_TNCICE_DATA_ERROR))
                dispatch(SetRFError("Unknown error"))
            }
        }
        catch(e){
            dispatch(serviceActionError(FETCH_MAINRFINPUTS_TNCICE_DATA_ERROR))
            dispatch(SetRFError("Unknown error"))
        }
    }
}

export const fetchTncIceDropDownValues = ()=>{
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_PENDING));
        let tncIceDropdownData = await getTncIceDropdownService();
        if (tncIceDropdownData) {
            dispatch(serviceActionSuccess(FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_SUCCESS, tncIceDropdownData));
        } else {
            dispatch(serviceActionError(FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_ERROR, tncIceDropdownData));
        }
    }
}

export const tncIcesaveTransactionData = (rfid,tabname,transactionList) =>{
    return async dispatch =>{
        try{
          dispatch(serviceActionPending(SAVE_TNCICE_TRANSACTION_PENDING));
          //await saveTransactions(rfid,tabname,transactionList);
          let tncIceData = await getTncIceData(rfid);
          if (tncIceData) {
            dispatch(serviceActionSuccess(SAVE_TNCICE_TRANSACTION_SUCCESS, {"tncIceData": tncIceData, "filterBy": false}));
          }
        }catch(e){
            dispatch(serviceActionError(SAVE_TNCICE_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const uploadTncIceData=(rfid,tabName,file)=>{
 return async dispatch => {
    try {
        dispatch(serviceActionPending(UPLOAD_TNCICEDATA_PENDING));
        let uploadResp = await uploadTncIceCSV(rfid,tabName,file);
        let tncIceData = await getTncIceData(rfid);
        if(uploadResp.body!==null && uploadResp.name !== 'Error' && uploadResp.data.length>0)
            dispatch(serviceActionSuccess(UPLOAD_TNCICEDATA_SUCCESS, {"tncIceData": tncIceData, "filterBy": false}));
        else
            dispatch(serviceActionError(UPLOAD_TNCICEDATA_ERROR, "TNCICE DATA upload failed"));
        
    } catch (e) {
        console.error("Exception", e);
        dispatch(serviceActionError(UPLOAD_TNCICEDATA_ERROR, "TNCICE DATA upload failed"));
    }
}
}

export const resetTncIce = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_TNCICE_INITIALSTATE))
    }
  }

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}