import React, { useState } from 'react';
import SchedulesActionBar from "../../components/schedulesactionbar/SchedulesActionBar";
import MultiGridUI from "../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const SchedulesTabPanel = (props) => {
    const { schedulesData, handleSwitchView, currentView, showSaveOption,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        gridReducer, schedulesGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        scheduleTransactions, currentData, filteredData, filterParams, tabIndex, downloadCSV,
        isFiltered, setIsFiltered, isSearchBarValueExists, setIsSearchBarValueExists, userMessage, newArchitecture, gridName,pending,loadGridData } = props;

    return (
        <div>
            {newArchitecture ? (<MainPlanInputsMultiGridUI reducer={gridReducer}
                gridName={gridName}
                //currentView={currentView}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={schedulesGridColDef}
                rowEditType={'single'}
                suppressclickEdit={!schedulesGridColDef.defaultColDef.editable|| pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                isPaginationRequired={true}
                loadGridData={loadGridData}
            />) : (
                <>
                    <SchedulesActionBar
                        options={showSaveOption}
                        bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                        onViewChanged={handleSwitchView}
                        onAddNewRowClick={handleAddNewRow}
                        actionView={currentView}
                        handleSaveCancelDisabled={handleSaveCancelDisabled}
                        onSaveClicked={handleSaveClicked}
                        onCancelClicked={handleCancelClicked}
                        selectedPlanId={selectedPlanId}
                        deleteSelectedRows={deleteSelectedRows}
                        selectedRows={selectedRows}
                        transactions={scheduleTransactions}
                        currentData={currentData}
                        schedulesData={schedulesData}
                        schedulesGridColDef={schedulesGridColDef}
                        filterParams={filterParams}
                        filterData={filteredData}
                        tabIndex={tabIndex}
                        data={gridReducer.currentData}
                        downloadCSV={downloadCSV}
                        isFiltered={isFiltered}
                        setIsFiltered={setIsFiltered}
                        isSearchBarValueExists={isSearchBarValueExists}
                        setIsSearchBarValueExists={setIsSearchBarValueExists}
                    />
                    <MultiGridUI reducer={gridReducer}
                        gridName={"schedulesgrid"}
                        currentView={currentView}
                        refreshCells={refreshCells}
                        onRowEditingStarted={handleRowEditingStarted}
                        onRowEditingStopped={handleRowEditingStopped}
                        onCellEditingStopped={handleCellEditingStopped}
                        gridColDef={schedulesGridColDef}
                        rowEditType={'single'}
                        suppressclickEdit={!schedulesGridColDef.defaultColDef.editable}
                        deleteSelectedRows={deleteSelectedRows}
                        handleMultipleRowDelete={handleMultipleRowDelete}
                        userMessage={userMessage}
                    />
                </>
            )}

        </div>
    )

}

export default SchedulesTabPanel;