export const FETCH_MILEAGEDEFAULT_DATA_PENDING = 'FETCH_MILEAGEDEFAULT_DATA_PENDING';
export const FETCH_MILEAGEDEFAULT_DATA_SUCCESS = 'FETCH_MILEAGEDEFAULT_DATA_SUCCESS';
export const FETCH_MILEAGEDEFAULT_DATA_ERROR = 'FETCH_MILEAGEDEFAULT_DATA_ERROR';
export const UPLOAD_MILEAGEDEFAULT_PENDING = 'UPLOAD_MILEAGEDEFAULT_PENDING';
export const UPLOAD_MILEAGEDEFAULT_SUCCESS = 'UPLOAD_MILEAGEDEFAULT_SUCCESS';
export const UPLOAD_MILEAGEDEFAULT_ERROR = 'UPLOAD_MILEAGEDEFAULT_ERROR';
export const SAVE_MILEAGEDEFAULT_TRANSACTION_PENDING = 'SAVE_MILEAGEDEFAULT_TRANSACTION_PENDING';
export const SAVE_MILEAGEDEFAULT_TRANSACTION_SUCCESS = 'SAVE_MILEAGEDEFAULT_TRANSACTION_SUCCESS';
export const SAVE_MILEAGEDEFAULT_TRANSACTION_ERROR = 'SAVE_MILEAGEDEFAULT_TRANSACTION_ERROR';
export const FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_PENDING = 'FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_PENDING';
export const FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_SUCCESS = 'FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_SUCCESS';
export const FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR = 'FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR';
export const RESET_TO_MILEAGEDEFAULT_INITIALSTATE = 'RESET_TO_MILEAGEDEFAULT_INITIALSTATE';
export const SET_MILEAGEDEFAULT_EDITABLE='SET_MILEAGEDEFAULT_EDITABLE';
