import React, { useEffect, useState } from 'react';
import btn_detailed_view from '../../../assets/images/btn-detailed-view.svg';
import { TextFieldInput } from '../../../core-components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import {getRfDetailsByRfId, saveDescription} from '../../../store/revenuefunction/allrfs/AllRFs.Actions';
import { rfids } from '../../../Constants';
import { useHistory, useLocation } from 'react-router-dom';
import { unEscapeHtml } from '../../../utils/utils';
const CUEMSG_HIT_ENTER_TO_SAVE = 'Hit Enter to save.';
const CUEMSG_ENTER_DESCRIPTION = 'Nothing to save.';

function RfInputsHeaderSection(props) {
const{handleSetTabValue,rfIdFromUrl} = props;
const history = useHistory();
 const dispatch = useDispatch();
 const [disableInput, setDisableInput] = useState(true);
 const [saveDescriptionMsg, setSaveDescription] = useState(false);
 const [descriptionChanging, setDescriptionChanging] = useState(false);
 const [descriptionvalue, setDescriptionValue] = useState('');
 const [cueMessage, setCueMessage] = useState(CUEMSG_HIT_ENTER_TO_SAVE);
 const [visible, setVisible] = useState(false);
 const [saveUpdatedResponse, setSaveUpdatedResponse] = useState(false);
 const [delay, setDelay] = useState(800);

 var saveResponse = useSelector((state) => {
    return state.rootAllRFsReducer.allRFsReducer.saveResponse;
});

const rfDetailsById = useSelector((state) => {
    return state.rootAllRFsReducer.allRFsReducer.rfDetailsById;
  });

    const onDescriptionChanged = (e) => {
        setDescriptionValue(e.target.value);
        setDescriptionChanging(true);
        if (e.target.value.trim() !== "") {
            setCueMessage(CUEMSG_HIT_ENTER_TO_SAVE);
        }
        else {
            setCueMessage(CUEMSG_ENTER_DESCRIPTION);
        }
    }
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (descriptionvalue !== null && descriptionvalue.trim() !== '') {
                setSaveDescription(true);
                setDisableInput(true);
                dispatch(saveDescription(rfIdFromUrl, descriptionvalue));
                setDescriptionChanging(false);
            }
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (saveResponse !== undefined && saveResponse == 'success' && (saveDescriptionMsg == true)) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
                setDisableInput(false);
                setSaveDescription(false);
            }, delay);
            setSaveUpdatedResponse(!saveUpdatedResponse);
        }

    }, [saveResponse, saveDescriptionMsg])

 function handleNavigation(){
  let url = "/revenuefunction/";
  handleSetTabValue();
  history.push({
  pathname: url,
  });
 }

 useEffect(()=>{
 if(rfDetailsById){
 setDescriptionValue(rfDetailsById.DESCRIPTION)
 }
 },[rfDetailsById])

    return (
        <div>
          <div className='flex'><img src={btn_detailed_view} className="ml-6 cursorpoint" onClick={(e) => handleNavigation()}></img><label className='rfcodestyle'>{rfDetailsById?rfDetailsById.RF_CODE:''}</label></div>
        <div className='flex rfheaders ml-6 mt-5'>
            <div className='display-grid'>
                <label className='typelable'>Type</label>
                <label className='selectedrfid'>{rfDetailsById?rfDetailsById.TYPE:''}</label>
            </div>
            <div className='rfdescriptionwidth display-grid rfleftmargin'>
                <div className='styleflex'>
                    <label className='detailslable'>Description</label>
                    {/* {(saveDescriptionMsg == true && visible) ? <label className='savewidth'>Saving...</label> : (descriptionChanging ? <label className='savewidth'>{cueMessage}</label> : '')} */}
                </div>
                <TextFieldInput
                    type="text"
                    id="iddescription"
                    value={unEscapeHtml(descriptionvalue)}
                    variant="outlined"
                    defaultValue={unEscapeHtml(descriptionvalue)}
                    onTextChanged={(e) => onDescriptionChanged(e)}
                    handleKeyDown={handleKeyDown}
                    inputDisabled={disableInput}
                    multiline
                    inputProps={{
                        style: {
                            color: "black"
                        }
                      }}
                />
            </div>
            <div className='rfleftmargin display-grid'>
                <label className='typelable'>Pool Months</label>
                <label className='selectedrfid'>{(rfDetailsById && rfDetailsById.POOL==true)? <p className='fontfam'>&#x0FC;</p> : <p>-</p> }</label>
            </div>
            <div className='rfleftmargin display-grid'>
                <label className='typelable'>Pieces</label>
                <label className='selectedrfid'>{(rfDetailsById && rfDetailsById.PIECES)? <p>{rfDetailsById.PIECES}</p> : <p>-</p> }</label>
            </div>
            </div>
        </div>
    )
}

export default RfInputsHeaderSection;