export const FETCH_MAINRFINPUTS_TNCEV_DATA_PENDING = 'FETCH_MAINRFINPUTS_TNCEV_DATA_PENDING';
export const FETCH_MAINRFINPUTS_TNCEV_DATA_SUCCESS = 'FETCH_MAINRFINPUTS_TNCEV_DATA_SUCCESS';
export const FETCH_MAINRFINPUTS_TNCEV_DATA_ERROR = 'FETCH_MAINRFINPUTS_TNCEV_DATA_ERROR';
export const FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_PENDING = "FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_PENDING";
export const FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_SUCCESS = "FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_SUCCESS";
export const FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_ERROR = "FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_ERROR";
export const SAVE_TNCEV_TRANSACTION_PENDING = "SAVE_TNCEV_TRANSACTION_PENDING";
export const SAVE_TNCEV_TRANSACTION_SUCCESS = "SAVE_TNCEV_TRANSACTION_SUCCESS";
export const SAVE_TNCEV_TRANSACTION_ERROR = "SAVE_TNCEV_TRANSACTION_ERROR";
export const RESET_TO_TNCEV_INITIALSTATE = "RESET_TO_TNCEV_INITIALSTATE";
export const UPLOAD_TNCEV_PENDING = 'UPLOAD_TNCEV_PENDING';
export const UPLOAD_TNCEV_SUCCESS = 'UPLOAD_TNCEV_SUCCESS';
export const UPLOAD_TNCEV_ERROR = 'UPLOAD_TNCEV_ERROR';