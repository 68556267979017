import { combineReducers } from 'redux';
import allocatedReducer from './Allocated.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import allocatedColumnDefinitionReducer from "./Allocated.ColumnDef.Reducer";

const rootAllocatedReducer = combineReducers({
  allocatedReducer
    , allocatedGridReducer: createNamedWrapperReducer(multiGridUIReducer, "allocatedgrid")
    , allocatedColumnDefinitionReducer
})

export default rootAllocatedReducer;