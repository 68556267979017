import React from "react";

import { Switch, Route } from "react-router-dom";
import InputsPage from "../containers/inputs/InputsPage";
import AllPlansPage from "../containers/allplans/AllPlansPage";
import PlanInputsPage from "../containers/planinputs/PlanInputsPage";
import AdjustmentsPage from "../containers/adjustments/AdjustmentsPage";
import AdminPage from '../containers/admin/AdminPage';
import RevenueFunction from "../containers/revenuefunction/RevenueFunction";
import MainPlanInputsPage from "../containers/new_inputs/MainPlanInputsPage";
import SalesAndFinancePage from "../containers/sales_finance/SalesAndFinancePage";
import './Route.css';

const Routes = () => {
 return(
  <div  className="routeDimensions">
    <Switch>
      {/* <Route path="/:planid" exact>
      </Route> */}
      <Route path="/" component={AllPlansPage} exact />
      {/* <Route path="/allplans/" component={AllPlansPage} exact /> */}
      <Route path="/maininputs" component={InputsPage} exact>
      </Route>
       {/* this path is for new version and will be removed after acceptance */}
      <Route path="/newmaininputs" component={MainPlanInputsPage} exact></Route>
      <Route path="/inputs" component={InputsPage} exact>
      </Route>
      <Route path="/newplan/:planid/inputs" component={InputsPage} exact>
      </Route>
      <Route path="/plan/:planid/dashboard" exact>
      </Route>
      <Route path="/plan/:planid/inputs" component={InputsPage} exact>
      </Route>
      <Route path="/plan/:planid/forecasts" exact>
      </Route>
      <Route path="/dashboard/:planid" exact>
      </Route>
       {/* this path is for new version and will be removed after acceptance */}
      <Route path="/plan/:planid/newplaninputs" component={PlanInputsPage} exact></Route>
      <Route path="/plan/:planid/planinputs" component={PlanInputsPage} exact> 
      </Route>
      <Route path="/plan/:planid/adjustments" component={AdjustmentsPage} exact> 
      </Route>
      <Route path="/admin" component={AdminPage} exact> 
      </Route>
      <Route path='/revenuefunction' component={RevenueFunction} exact>
      </Route>
      <Route path='/revenuefunction/:rfid/rfinputs' component={RevenueFunction} exact>
      </Route>
      <Route path='/plan/:planid/salesandfinance' component={SalesAndFinancePage} exact></Route>
    </Switch>
  </div>
 )};
export default Routes;
