import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import './MainPlanInputsActionBar.css'
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconFilter from "../../assets/images/icon_filter.svg";
import IconActionDownload from '../../assets/images/icon_action_download.svg';
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import SearchBar from '../searchbar/SearchBar';
import DialogBox from '../Dialogcomponent/DialogComponent';
import SingleButtonDialogComponent from '../Dialogcomponent/SingleButtonDialogComponent';
import { Button } from '@material-ui/core';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { fetchProgramDropdownValues } from "../../store/programs/Programs.Actions";
import { fetchSchedulesDropdownValues } from '../../store/schedules/Schedules.Actions';
import { fetchAllocatedDropdownValues } from '../../store/allocated/Allocated.Actions';
import { deleteAllGridsData, setGridErrorViewStatus, setGridFilterParams, setGridFilterViewStatus, setGridSearchParams, setGridSearchViewStatus, uploadGridData } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { fetchUpgradeCarClassgroup } from '../../store/upgradepaths/UpgradePaths.Actions';
import { fetchConstraintsOptions } from '../../store/constraints/Constraints.Actions';
import { fetchMoveConstraintDropdownValues } from '../../store/moveconstraints/MoveConstraints.Actions';
import FilterComponent from '../new_filter_components/FilterComponent';
import FilterChips from '../new_filterchips/FilterChips';
import DeleteAllComponent from '../deleteallrecords_component/DeleteAllComponent';
import S3UploadDownloadComponent from '../s3_upload_download_component/S3UploadDownloadComponent';
import { MessageNotificationValues } from '../../Constants';
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { downloadData } from '../../utils/utils';
import { fetchDrownValuesByGridName } from './MainPlanInputsActionBarCaseStatementObjects';
import ErrorComponent from '../new_error_button_component/ErrorComponent';
import SearchComponent from '../new_search_component/SearchComponent';
import TopLevelFilterComponent from '../top_level_filter_component/TopLevelFilterComponent';

const MainPlainInputsActionBar = (props) => {
  //loadGridDataForFilterParams,loadGridDataForErrorView ,loadGridDataForSearchParam  ====>we might delete this later
  const { showOption, onCancelClicked, onSaveClicked, deleteSelectedRows, handleSaveCancelDisabled, gridColDefReducer, gridData, gridName, bulkRowDeleteIconDisabled, onAddNewRowClick, gridReducer, pending, selectedPlanId, gridUserPageno, initializeFilterParamsAndViewStatus, downloadCSV,loadGridData} = props;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [FileToUpload, SetFileToUpload] = useState(null);
  const [fileNameToDownload, setFileNameToDownload] = useState(null);
  const dispatch = useDispatch();
  let message = "Are you sure that you want to delete selected rows?"
  let title = "Delete Row";
  let deletetext = "Delete";
  let canceltext = "Cancel";

  const masterDataUpdateState = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  });

  const gridFilterViewStatus = useSelector((state) => {
    return { ...state.PaginationGridReducer.gridFilterView };
  },shallowEqual);

  const gridTotalRowCount = useSelector((state) => {
    return state.PaginationGridReducer.gridTotalRowCount;
  });

  const gridErrorViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridErrorView};
  },shallowEqual);

  const gridSearchView = useSelector((state)=>{
    return {...state.PaginationGridReducer.gridSearchView};
  },shallowEqual)

  const callGridFetchApi = useSelector((state)=>{
    return state.PaginationGridReducer.callGridFetchApi;
  })

  useEffect(() => {
    if (masterDataUpdateState) {
      //This dispatch and useEffect is triggered only if master cache data is been updated by admin 
      //masterDataUpdateState is at redux state for handling true/false if master cache data has been updated
      //Calling of DropDownService is used to push last updated cache master data to there respective dropdowns
      setShowDialog(true)
      
      if(fetchDrownValuesByGridName[gridName]){
        dispatch(fetchDrownValuesByGridName[gridName](gridReducer.currentData, gridColDefReducer, masterDataUpdateState,true))
       }
      // switch (gridName) {
      //   case 'programsGrid':
      //     dispatch(fetchProgramDropdownValues(gridReducer.currentData, gridColDefReducer, masterDataUpdateState, true))
      //     break;
      //   case 'schedulesGrid':
      //     dispatch(fetchSchedulesDropdownValues(gridReducer.currentData, gridColDefReducer, masterDataUpdateState, true));
      //     break;
      //   case 'allocatedGrid':
      //     dispatch(fetchAllocatedDropdownValues(gridReducer.currentData, gridColDefReducer, masterDataUpdateState, true));
      //     break;
      //   case 'upgradepathsGrid':
      //     dispatch(fetchUpgradeCarClassgroup(gridReducer.currentData, gridColDefReducer, masterDataUpdateState, true));
      //     break;
      //   case 'constraintsGrid':
      //     dispatch(fetchConstraintsOptions(gridReducer.currentData, gridColDefReducer, masterDataUpdateState, true));
      //     break;
      //   case 'moveconstraintsGrid':
      //     dispatch(fetchMoveConstraintDropdownValues(gridReducer.currentData, gridColDefReducer, masterDataUpdateState, true));
      //     break;
      // }
    }
  }, [masterDataUpdateState])


  const handleClickOpen = (props) => {
    if (!bulkRowDeleteIconDisabled) {
      setOpenDeleteDialog(true);
    }
  }
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleDialogClose = () => {
    setShowDialog(false);
  }

  const handleDeleteSelectedRows = () => {
    setOpenDeleteDialog(false);
    deleteSelectedRows(gridName)
  }

  const deleteAllRecords = useCallback((deleteParams) => {
    try {
      dispatch(deleteAllGridsData(selectedPlanId, gridName, deleteParams,loadGridData))
    } catch (e) {
      console.error('got error while deleting all records');
    }
  }, [callGridFetchApi])

  const changeHandler = async (event) => {
    if (event.target.value !== '') {
      const fileToUpload = event.target.files[0];
      event.target.value = '';
      if (gridName !== '') {
        SetFileToUpload(fileToUpload)
      }
    }
  };

  const downloadHandler = async (event) => {
    if (gridName !== '') {
      let plan = selectedPlanId ? selectedPlanId : 'main'
      if (gridTotalRowCount <= 2000) {
        dispatch(addNotificationMessage(MessageNotificationValues.Severity.success, 'Please wait your file is getting ready!!'));
        downloadData(gridData, gridName, gridColDefReducer, downloadCSV, plan)
      } else {
        let currentDate = new Date();
        let dateStr = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}_${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
        let downloadFilename = `${gridName.replace(/grid/gi, '')}-${plan}_${dateStr}.csv`
        setFileNameToDownload(downloadFilename)
      }
    }
  }

  const changeErrorMode = useCallback((gridErrorView) => {
    dispatch(setGridSearchParams(gridName,null));
    dispatch(setGridSearchViewStatus(gridName,false));
    dispatch(setGridFilterParams(gridName, gridColDefReducer, true))
    dispatch(setGridFilterViewStatus(gridName, false));
    dispatch(setGridErrorViewStatus(gridName, gridErrorView));
   // loadGridDataForErrorView(gridErrorView);
  }, [gridName]);

  const DeleteDialogBox = useMemo(() => (
    <DialogBox open={openDeleteDialog} handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
      dialogMessage={message} title={title} action={deletetext}
      canceltext={canceltext}>
    </DialogBox>
  ), [openDeleteDialog]);

  const SingleButtonDialogBox = useMemo(() => (
    <SingleButtonDialogComponent open={showDialog} handleOk={handleDialogClose}
      dialogMessage={"Master Data Has Been Updated!!"} title={"Alert!!"} action="Ok">
    </SingleButtonDialogComponent>
  ), [showDialog])

  const filterComponentDisplay = useMemo(() => {
    if (gridName != '' && gridName != 'parametersGrid') {
      return <FilterComponent gridColumnDefs={gridColDefReducer} 
     // loadGridDataForFilterParams={loadGridDataForFilterParams} 
      initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus} hasPendingTransactions={showOption}/>
    }
  }, [gridName,showOption])

  const filterChipsDisplayComponent = useMemo(() => {
    if (gridName != '') {
      return <FilterChips gridColumnDefs={gridColDefReducer} 
    //  loadGridDataForFilterParams={loadGridDataForFilterParams}
       gridName={gridName} hasPendingTransactions={showOption}/>
    }
  }, [gridName,showOption])

  const topLevelFilterComponentDisplay = useMemo(() => {
    if (gridName === 'constraintsGrid') {
      return <TopLevelFilterComponent hasPendingTransactions={showOption} selectedId={selectedPlanId}/>
    }
  }, [gridName, showOption])

  const errorComponentDisplay = useMemo(() => {
    if (gridName != '') {
      return <ErrorComponent isButtonRequired={
        ((gridName != 'upgradetemplatesGrid' && gridName != 'parametersGrid') &&
          ((gridFilterViewStatus[gridName] || gridErrorViewStatus[gridName] || gridSearchView[gridName]) ||
            (gridData && gridData.length > 0))) ? true : false} isDisabled={false}
        changeErrorMode={changeErrorMode} hasPendingTransactions={showOption}/>
    }
  }, [gridName, gridData,showOption]);

 
  const searchComponentDisplay = useMemo(() => {
    if (gridName != '') {
      return <SearchComponent isButtonRequired={
        ((gridName != 'upgradetemplatesGrid')) ? true : false}
        //loadGridDataForSearchParam={loadGridDataForSearchParam}
        onEnterSearch={true} gridColumnDefs={gridColDefReducer} hasPendingTransactions={showOption}/>
    }
  }, [gridName,showOption])

  const deleteAllButtonComponent = useMemo(() => {
    if (gridName != '') {
      return <DeleteAllComponent isButtonRequired={((gridName != 'upgradetemplatesGrid' && gridName != 'parametersGrid') && gridColDefReducer.defaultColDef.editable && (gridData && gridData.length>0)) ? true : false} deleteAllRecords={deleteAllRecords} />
    }
  }, [callGridFetchApi,gridData])

  const s3UploadDownloadComponent = useMemo(() => {
    if (gridName != '') {
      return <S3UploadDownloadComponent
        selectedId={selectedPlanId}
        loadGridData={loadGridData}
        // showProgress={showProgress} 
        // setShowProgress={setShowProgress}
        fileNameToDownload={fileNameToDownload}
        setFileNameToDownload={setFileNameToDownload}
        FileToUpload={FileToUpload}
        SetFileToUpload={SetFileToUpload}
        gridName={gridName}
        //userMessageFunction={userMessage}
        gridColumnDefs={gridColDefReducer.columnDefs}
        //selectedTabName={selectedTableName}
        isNewArchitecture={true}
      />
    }
  }, [callGridFetchApi, FileToUpload, fileNameToDownload])

  return (
    <div className="mainHeader">
      {topLevelFilterComponentDisplay}
      <div className="filterStyle">
        {filterComponentDisplay}
        <div className="searchbar">
           {searchComponentDisplay} 
        </div>
         {errorComponentDisplay} 
        <>
          {s3UploadDownloadComponent}
        </>
      </div>
      {(showOption) ?
        <div className="saveButtons">
          <div className="cancelButton"><Button
            onClick={(e) => { onCancelClicked(gridName) }}
          >Cancel
          </Button></div>
          <div className="primaryButton">
            <Button
              disabled={handleSaveCancelDisabled}
              onClick={(e) => onSaveClicked(gridName)}
            >Save changes
            </Button></div>
          {(gridName !== 'upgradetemplatesGrid') ? <><div className="primaryButton">
            {/* Disable Add + buttton if plan status running  */}
            <Button
              disabled={!gridColDefReducer.defaultColDef.editable || gridErrorViewStatus[gridName]}
              onClick={() => onAddNewRowClick(gridName,gridReducer.currentData.length)}
            >Add +
            </Button>
          </div>
          {(gridName !== 'parametersGrid') ?
            <div className="primaryButton">
              {/* Disable delete delete button if plan is running  */}
              <Button
                onClick={handleClickOpen}
                disabled={bulkRowDeleteIconDisabled || !gridColDefReducer.defaultColDef.editable}
              >
                Delete
              </Button>
            </div>: ''}</> : ''}
          {deleteAllButtonComponent}
        </div>

        :
        (<div className="actionButtons">
          {(gridName !== 'parametersGrid' && gridName !== 'upgradetemplatesGrid') ? <div className='flex'>
          
                {!gridErrorViewStatus[gridName] ?
                  <label>
                    <input disabled={!gridColDefReducer.defaultColDef.editable} type="file" accept=".csv" name="file"
                      onChange={changeHandler}
                      style={{ "display": "none" }} />
                    <img src={IconActionUpload} className="imageIcon"></img>
                  </label>
                  : ''}
             
            <div className='imageIcon cursorpoint'>
              <img src={IconActionDownload}
                onClick={() => downloadHandler()}
              />
            </div></div> : ''}
          {/* {switchViewComponent()} */}
          {/* {!hideActions?  */}
          {(gridName !== 'upgradetemplatesGrid') ? <div style={{ display: 'flex' }}>
            <div className="primaryButton">
              <Button
                disabled={!gridColDefReducer.defaultColDef.editable || pending || gridErrorViewStatus[gridName]}
                onClick={() => onAddNewRowClick(gridName,gridReducer.currentData.length)}
              >
                Add +
              </Button></div>
            {(gridName !== 'parametersGrid') ?
            <div className="primaryButton">
              <Button
                onClick={handleClickOpen}
                disabled={bulkRowDeleteIconDisabled || !gridColDefReducer.defaultColDef.editable}
              >
                Delete
              </Button>
            </div>
            :''}
            {deleteAllButtonComponent}
          </div>
            : ''}
        </div>
        )
      }
      {filterChipsDisplayComponent}
      {DeleteDialogBox}
      {SingleButtonDialogBox}
    </div>
  )
}
export default React.memo(MainPlainInputsActionBar);