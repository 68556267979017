import{
    localeFormatter
} from "../../../../utils/formatters/valueFormatter";
export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: 'DIVISIONS', headerName: 'Divisions', cellEditor: 'autoCompleteEditor',
        type: "dropdown",
        unSortIcon: true, minWidth: 100,
        cellClassRules: cellClassRules("DIVISIONS"),
        cellEditorParams:{options:['US']},
        valueFormatter: params => {
            return params.data.DIVISIONS;
        },
        tooltipValueGetter: params => {
            return toolTipUI(params, "DIVISIONS")
        }
    },
    {
        field: 'car_class_car_group', headerName: 'Car Group', cellEditor: 'autoCompletePopupEditor',
        type: "dropdown",
        unSortIcon: true, minWidth: 250,
        cellClassRules: cellClassRules("car_class_car_group"),
        tooltipValueGetter: params => {
            return toolTipUI(params, "car_class_car_group")
        }
    },
    {
        field: 'car_class_code', headerName: 'Car Class', cellEditor: 'freeSoloAutoCompleteEditor',
        type: "dropdown",
        unSortIcon: true, minWidth: 100,
        cellClassRules: cellClassRules("car_class_code"),
        tooltipValueGetter: params => {
            return toolTipUI(params, "car_class_code")
        }
    },
    {
        field: 'tnc_eligible',
        headerName: 'TNC Eligible',
        type: "dropdown",
        minWidth: 110,
        unSortIcon: true,
        cellEditor: 'autoCompleteEditor',
        valueFormatter: params=>localeFormatter(params.data.tnc_eligible),
        cellClassRules: cellClassRules("tnc_eligible"),
        tooltipValueGetter: (params) => {
          return toolTipUI(params, "tnc_eligible");
        }
      },
      {
        field:'archived',
        headerName:'Archived',
        type: "dropdown",
        minWidth:110,
        unSortIcon:true,
        cellEditor: 'autoCompleteEditor',
        cellClassRules: cellClassRules("archived"),
        tooltipValueGetter: (params) => {
          return toolTipUI(params, "archived");
        }
      },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "CarClassGroupActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
