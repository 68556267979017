import { combineReducers } from 'redux';
import templatesReducer from './UpgradeTemplates.Reducer';
import {createNamedWrapperReducer} from '../util';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import templatesColumnDefReducer from './UpgradeTemplates.ColumnDef.Reducer.js';

const rootUpgradeTemplatesReducer = combineReducers({
    templatesReducer,
    templatesGridReducer: createNamedWrapperReducer(multiGridUIReducer, "upgradetemplatesgrid"),
    templatesColumnDefReducer
})

export default rootUpgradeTemplatesReducer;