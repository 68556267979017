import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { ADMIN_ACCESS_REVOKED_ERROR_CODE, pollTimeoutInterval } from '../../../Constants';
import { getRoleInfo } from '../../topbar/AllPlansService';
import { minifyTransaction } from '../../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function savePlnaManagementTransactions(transactionList){
  const userNToken=await getAccessTokenNUserDetailsAfterAuth();

  const accessToken=userNToken.accessToken;
  const userEmail = userNToken.email;

  const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

  let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
  if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let response=await client.apis.admin.bulkTransactPlan( null,{ requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });
    
    // console.log(`response=${response}`);
    // console.log(`response=${JSON.stringify(response)}`);
    // console.log(`response.body=${response.body}`);
    if(response.body && response.body.statusCode==='500' && response.body.body){
      let body=JSON.parse(response.body.body);
      throw new Error(body.error);
    }
    
    return response;
  } else {
    throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
  }
}

export async function initiateDeletePlanService(planId){
  let response = null;
  try{
    // console.log("Invoked initiateDeletePlanService...");
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    // console.log("Getting Job ID...");
    const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
    // console.log("responseWithJobId=",responseWithJobId);
    const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
    // console.log("Job_id=",jobId);
  
    response = await client.apis.admin.startDeletePlanImmediately( {planid:planId},{ requestBody: { "updatedBy": userEmail,"jobId":jobId} });
    // console.log("startDeletePlanImmediately Reponse=====",response);
    // console.log("Started delete of plan =====",planId);

    if(!response.body.data || !response.body.data.MessageId 
      || !response.body.data.ResponseMetadata || !response.body.data.ResponseMetadata.RequestId){
          console.log("ERROR startDeletePlanImmediately Resonse=====",response);

          if(response.body.statusCode==="500" && response.body.body){
              let body=JSON.parse(response.body.body);
              throw new Error(body.error);
          }else{
              throw new Error("Failed to initiate process");
          }          
    }

    return jobId;
  }catch(e){
    console.error("Exception",e.message);

    if(e.statusCode===500 && e.response.body.error){
        throw new Error(e.response.body.error);
    }else{
        throw new Error("Failed to initiate process");
    }
  }
}

export async function checkStatusOfDeletePlanJob(jobId,planId){
  // console.log(`Invoked checkStatusOfDeletePlanJob with jobId=${jobId},planId=${planId}`);
  const accessToken = await getAccessTokenAfterAuth();

  const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

  

  // console.log("Checking deletion status of plan =====",planId);
  let resp = await client.apis.admin.getStatusOfPlanDeletion( {planid:planId,jobid:jobId},null);
  // console.log("resp==",resp);
  let rows=resp.body.data.rows;
  if(rows && Array.isArray(rows)){
    for(let i=0;i<rows.length;i++){//Expected rows only 1 due to query, so only 1-round loop
      // console.log("row.status===",rows[i]);
      if(rows[i].status==="ERROR"){
        throw new Error(rows[i].message); //Exit the function with Exception
      }else{
        return rows[i].status;
      }
    }
    
  }
}