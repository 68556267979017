export const FETCH_SALVAGERATE_DATA_PENDING = 'FETCH_SALVAGERATE_DATA_PENDING';
export const FETCH_SALVAGERATE_DATA_SUCCESS = 'FETCH_SALVAGERATE_DATA_SUCCESS';
export const FETCH_SALVAGERATE_DATA_ERROR = 'FETCH_SALVAGERATE_DATA_ERROR';
export const UPLOAD_SALVAGERATE_PENDING = 'UPLOAD_SALVAGERATE_PENDING';
export const UPLOAD_SALVAGERATE_SUCCESS = 'UPLOAD_SALVAGERATE_SUCCESS';
export const UPLOAD_SALVAGERATE_ERROR = 'UPLOAD_SALVAGERATE_ERROR';
export const SAVE_SALVAGERATE_TRANSACTION_PENDING = 'SAVE_SALVAGERATE_TRANSACTION_PENDING';
export const SAVE_SALVAGERATE_TRANSACTION_SUCCESS = 'SAVE_SALVAGERATE_TRANSACTION_SUCCESS';
export const SAVE_SALVAGERATE_TRANSACTION_ERROR = 'SAVE_SALVAGERATE_TRANSACTION_ERROR';
export const FETCH_SALVAGERATE_DROPDOWNVALUES_PENDING = 'FETCH_SALVAGERATE_DROPDOWNVALUES_PENDING';
export const FETCH_SALVAGERATE_DROPDOWNVALUES_SUCCESS = 'FETCH_SALVAGERATE_DROPDOWNVALUES_SUCCESS';
export const FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR = 'FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR';
export const RESET_TO_SALVAGERATE_INITIALSTATE = 'RESET_TO_SALVAGERATE_INITIALSTATE';
export const SET_SALVAGERATE_EDITABLE='SET_SALVAGERATE_EDITABLE';