import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { minifyTransaction } from '../../utils/utils';

export async function getAllSalesFinanceErrorStatus(planid){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const promiseGetBvDepOverrideTabValidationStatus =  client.apis.adjustments.getBvDepOverRideTabErrorStatus({"planid":planid?planid:1});
        
        const responsesArray = await Promise.all([
            promiseGetBvDepOverrideTabValidationStatus
        ]);

        const [
            bvDepOverrideTabStatusResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "bvdepoverridegrid": bvDepOverrideTabStatusResponse !=undefined ? bvDepOverrideTabStatusResponse : true,
        }) 
    }
    catch (error) {
       console.error(error);
       throw 'Unable to fetch the error status for all sales & finance tabs, Please try again!!';
   }
   }