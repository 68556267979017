import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconActionDownload from '../../../assets/images/icon_action_download.svg';
import IconActionUpload from "../../../assets/images/icon_action_upload.svg";
import { makeStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { Button, Input,MenuItem } from '@material-ui/core';
import CustomPopOver from "../../../core-components/Popover/CustomPopOver";
import { downloadData } from '../../../utils/utils';
import { populateGrid2 } from '../../../store/Sales_FinanceMultiGrid/SalesAndFinanceMultiGrid.Actions';
import { salesAndFinanceTabs, safids, MessageNotificationValues} from '../../../Constants';
import { NttDropdown } from '../../../core-components/Dropdown/Dropdown';
import {setRFIndex} from '../../../store/revenuefunction/RevenueFunction.Actions';
import NttTab from '../../tabs/NttTab';
import NttTabs from '../../tabs/NttTabs';
import DeleteDialogBox from '../../Dialogcomponent/DialogComponent';
import {deleteAllGridsData, resetAllGridTabStatus, resetGridState, setGridErrorViewStatus, setGridFilterParams, setGridFilterViewStatus, setGridSearchParams, setGridSearchViewStatus, uploadGridData,validateAllGridsData } from '../../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import FilterComponent from '../../new_filter_components/FilterComponent';
import DeleteAllComponent from '../../deleteallrecords_component/DeleteAllComponent';
import S3UploadDownloadComponent from '../../s3_upload_download_component/S3UploadDownloadComponent';
import SearchComponent from '../../new_search_component/SearchComponent';
import ErrorComponent from '../../new_error_button_component/ErrorComponent';
import { addNotificationMessage } from '../../../store/topbar/AllPlans.Actions';
import {ResetMultiGridState} from "../../../store/Sales_FinanceMultiGrid/SalesAndFinanceMultiGrid.Actions"
import './SalesAndFinanceActionBar.css';
import { cancelSalesAdjustmentChanges, toggleExpandAll } from '../../../store/salesandfinance/SalesAndFinance.Actions';
import icon_collapse_all from "../../../assets/images/icon_collapse_all.png";
import icon_expand_all from "../../../assets/images/icon_expand_all.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={'simple-tabpanel-${index}'}
          aria-labelledby={'simple-tab-${index}'}
          {...other}
      >
          {value === index && (
              <Box p={3}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const tabStyle = {
  default_tab: {
    borderRadius: "4px",
    color: "#000000",
    background: "#ffff",
    fontSize: 14,
    textTransform: 'none',
  },
  active_tab: {
    background: "#ffed9b",
    borderColor: "#ffff",
    borderRadius: "4px",
    color: "#000000",
    fontSize: 14,
    textTransform: 'none'
  },
  defaultError_tab: {
  borderRadius: "4px",
    color: "red",
    background: "#ffff",
    fontSize: 14,
    textTransform: 'none',
    fontWeight: "bold",
  },
  activeError_tab: {
  background: "#ffcccc",
  borderColor: "#ffff",
  borderRadius: "4px",
  color: "#ff0000",
  fontSize: 14,
  textTransform: 'none',
  fontWeight: "bold",
  },
  tabItemContainer: {
    margin: "50px",
    display: "flex",
    flexWrap: "wrap",
  },
  copyInProgressIndicator:{
  borderRadius: "4px",
  color: "#000000",
  background: "#ffff",
  fontSize: 14,
  textTransform: 'none',
  animation: "copyinprogress_sub 2s linear infinite"
  },
  copyInProgressIndicator_active:{
  background: "#ffed9b",
  borderColor: "#ffff",
  borderRadius: "4px",
  color: "#000000",
  fontSize: 14,
  textTransform: 'none',
  animation: "copyinprogress_sub_active 2s linear infinite"
  }
}
const SalesAndFinanceActionBar = (props) => {
  const { gridData, selectedPlanId,pending,salesAndFinanceGrid,showOption, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, deleteSelectedRows, bulkRowDeleteIconDisabled, downloadCSV,salesAndFinanceInputsGridColDefReducer,salesAndFinanceInputsGrid,filterSearchKey,onTableNameChange,onTypeNameChange,typeName,selectedTableName,setGenerateRfProcessClicked,hideActions,userMessage,loadGridDataForFilterParams,loadGridDataForErrorView,initializeFilterParamsAndViewStatus,gridFilterUserSelectedParams,loadGridDataForSearchParam,loadGridData } = props;

  const [value, setValue] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;
  //const [salesAndFinanceValue, setsalesAndFinanceValue] = useState(typeName!=null?typeName:salesAndFinanceDropdownValues[0]);
  const [salesAndFinanceInputTabs, setSalesAndFinanceInputTabs] = useState(salesAndFinanceTabs);
  const[FileToUpload,SetFileToUpload]=useState(null);
  const[fileNameToDownload,setFileNameToDownload]=useState(null);
  const[searchText,setSearchText] = useState('');
  let sfIdFromUrl = window.location.href.split("/")[5];
  const theme=useTheme();
  const dispatch = useDispatch();

  let message = "Are you sure that you want to delete selected rows?"
  let title = "Delete Row";
  let deletetext = "Delete";
  let canceltext = "Cancel";

  const ITEM_HEIGHT = 48;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.9,
        width: 250,
      },
    },
  };

  theme.typography.body2 = {
    fontSize: '14px'
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: '180vh',
      maxHeight: '180vh',
    }
  }));
  const highlightAllTabsErrorStatus = useSelector((state)=>{
    return state.PaginationGridReducer.gridTabErrorStatus;
  })

  const gridName=useSelector((state)=>{
    return state.PaginationGridReducer.gridName
  })

  const gridUserPageno=useSelector((state)=>{
    return state.PaginationGridReducer.gridUserPageno;
  })

  const gridFilterViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridFilterView};
  });
  const gridSearchViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridSearchView};
  });
  const gridErrorViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridErrorView};
  });

  const gridTotalRowCount = useSelector((state) => {
    return state.PaginationGridReducer.gridTotalRowCount;
  });

  const expandAll=useSelector((state)=>{
    return state.rootSalesAndFinanceReducer.salesAndFinanceReducer.expandAll;
  }) ;

  const salesTabEditing=useSelector((state)=>{
    return state.rootSalesAndFinanceReducer.salesAndFinanceReducer.editing;
  });

  let plansDataById = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.plansDataById;
  });

  const getStyle = (isActive, tabKey,index,gridname) => {
    if(highlightAllTabsErrorStatus[tabKey.gridName]===true){
      return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
    } else {
      return isActive ? tabStyle.active_tab : tabStyle.default_tab
    }
  }

  const handleChangeTab = (e, index) => {
    setValue(index);
    dispatch(setRFIndex(index))
    if(index==0){
        onTableNameChange(salesAndFinanceTabs[index].tabTitle);
      } else if(index==1){
        onTableNameChange(salesAndFinanceTabs[index].tabTitle);
      }else if(index==2){
        onTableNameChange(salesAndFinanceTabs[index].tabTitle);
      }
} 

  const onsalesAndFinanceValueValueChanged = (e) => {
    onTypeNameChange(e.target.value);
    dispatch(resetAllGridTabStatus());
    dispatch(resetGridState(false));
    setSalesAndFinanceInputTabs(salesAndFinanceTabs[e.target.value]);
  }

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };


  const handleClickOpen = (props) => {
    if (!bulkRowDeleteIconDisabled) {
        setOpenDeleteDialog(true);
    }
  }
  const handleCloseDeleteDialog =()=>{
    setOpenDeleteDialog(false);
  }

  const handleDeleteSelectedRows =()=>{
    setOpenDeleteDialog(false);
    deleteSelectedRows()
  }

  const changeHandler = async (event) => {
    if (event.target.value !== '') {
      const fileToUpload = event.target.files[0];
      event.target.value = '';
      if (gridName !== '') {
        SetFileToUpload(fileToUpload)
      }
    }
  };

  const switchViewComponent = () => {
    return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
      <Button id={id} endIcon={<ArrowDropDownIcon fontSize="13px" disabled={hideActions}></ArrowDropDownIcon>}>View</Button>
      <CustomPopOver
        className="actiobarPopOver"
        popOverId="popoverId"
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
      </CustomPopOver>
    </div>);
  }

  const activateGenerateRfPlanProcess=()=>{
    setGenerateRfProcessClicked(true)
    dispatch(validateAllGridsData(safids[0][typeName],typeName,'RF'))
  }

  const changeErrorMode=useCallback((gridErrorView)=>{

    dispatch(setGridSearchParams(gridName,null));
    dispatch(setGridSearchViewStatus(gridName,false));
    dispatch(setGridFilterParams(gridName, salesAndFinanceInputsGridColDefReducer, true))
    dispatch(setGridFilterViewStatus(gridName, false));
    dispatch(setGridErrorViewStatus(gridName, gridErrorView));
  },[gridName]);

  const deleteAllRecords=useCallback((deleteParams,filterViewStatus,errorView,searchViewStatus,searchText)=>{
    try{
      dispatch(deleteAllGridsData(sfIdFromUrl,gridName,deleteParams,loadGridData));
      dispatch(resetGridState(false));
      dispatch(ResetMultiGridState());
    }catch(e){
      console.error('got error while deleting all records');
    }
  },[gridName])

  const downloadHandler = async (event) => {
    if (gridName !== '') {
      let plan = selectedPlanId ? selectedPlanId : 'main'
      if (gridTotalRowCount <= 2000) {
        dispatch(addNotificationMessage(MessageNotificationValues.Severity.success, 'Please wait your file is getting ready!!'));
        downloadData(gridData, gridName, salesAndFinanceInputsGridColDefReducer, downloadCSV, plan)
      } else {
        let currentDate = new Date();
        let dateStr = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}_${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
        let downloadFilename = `${gridName.replace(/grid/gi, '')}-${plan}_${dateStr}.csv`
        setFileNameToDownload(downloadFilename)
      }
    }
  }

  const filterComponentDisplay=useMemo(()=>{
    if(gridName!='' && gridName==='bvdepoverridegrid'){
      return <FilterComponent disabled = {true} gridColumnDefs={salesAndFinanceInputsGridColDefReducer} loadGridDataForFilterParams={loadGridDataForFilterParams} initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus} hasPendingTransactions={showOption}/>
    }
  },[gridName,salesAndFinanceInputsGridColDefReducer,showOption])

  const searchComponentDisplay=useMemo(()=>{
    if(gridName!='' && gridName==='bvdepoverridegrid'){
      return <SearchComponent disabled = {true} isButtonRequired={(salesAndFinanceGrid!=="salesGrid" && salesAndFinanceGrid!=="saleschannelGrid")?true:false} setSearchText={setSearchText} searchText={searchText} loadGridDataForSearchParam={loadGridDataForSearchParam} onEnterSearch={false} gridColumnDefs={salesAndFinanceInputsGridColDefReducer} hasPendingTransactions={showOption}></SearchComponent>
    }
  },[gridName,salesAndFinanceInputsGridColDefReducer,showOption])

  const errorComponentDisplay=useMemo(()=>{
    if(gridName!='salesgrid'){
      let isButtonReqd=true;
      if(!(gridFilterViewStatus[gridName]||gridSearchViewStatus[gridName]||gridErrorViewStatus[gridName]) && gridTotalRowCount==0){ // gridTotalRowCount is a String '0' and not integer 0
        isButtonReqd=false;
      }
      return <ErrorComponent isButtonRequired={(salesAndFinanceGrid!=="salesGrid" && salesAndFinanceGrid!=="saleschannelGrid") && isButtonReqd?true:false} isDisabled={false} changeErrorMode={changeErrorMode} hasPendingTransactions={showOption}/>
    }
  },[gridName,showOption,gridTotalRowCount]);

  const deleteAllButtonComponent=useMemo(()=>{
    let isButtonReqd=true;
    if(gridTotalRowCount==0 || plansDataById[0].status.toLowerCase()=='sales adjustments ready' || plansDataById[0].status.toLowerCase()=='vin dep process ready'){ // gridTotalRowCount is a String '0' and not integer 0
      isButtonReqd=false;
    }
    // else if(!salesAndFinanceInputsGridColDefReducer.defaultColDef.editable){
    //   isButtonReqd=false;
    // } //while implementing edit uncomment this line
    if(gridName!=''){
      return <DeleteAllComponent isButtonRequired={(salesAndFinanceGrid!='salesGrid' && salesAndFinanceGrid!='saleschannelGrid' && isButtonReqd)?true:false} deleteAllRecords={deleteAllRecords} searchText={searchText}/>
    }
  },[gridName,gridFilterViewStatus[gridName],gridSearchViewStatus[gridName],gridErrorViewStatus[gridName],gridTotalRowCount,plansDataById])

  const switchExpandallComponent = () =>{
    if(gridName==='salesgrid'){
      if(!expandAll){
        return (
          <button className="expandAllCollapseAllButton"
              onClick={(e) => handleExpandAll(e)}>
              <img src={icon_expand_all}></img>
              <span>Expand All</span>
          </button>
        );
      }else{
        return (
          <button className="expandAllCollapseAllButton"
              onClick={(e) => handleExpandAll(e)}>
              <img src={icon_collapse_all} ></img>
              <span>Collapse All</span>
          </button>
        );
      }
    }
  }

  const handleExpandAll = (event) => {
    dispatch(toggleExpandAll());
  }
  const handleCancelSalesTabEdit = (event) => {
    dispatch(cancelSalesAdjustmentChanges());
  }


  const s3UploadDownloadComponent=useMemo(()=>{
    if(gridName!=''){
      return <S3UploadDownloadComponent 
                // showProgress={showProgress} 
                // setShowProgress={setShowProgress}
                loadGridData={loadGridData}
                fileNameToDownload={fileNameToDownload}
                setFileNameToDownload={setFileNameToDownload} 
                FileToUpload={FileToUpload} 
                SetFileToUpload={SetFileToUpload} 
                gridName={gridName} 
                //userMessageFunction={userMessage}
                gridColumnDefs={salesAndFinanceInputsGridColDefReducer.columnDefs}
                selectedTabName={selectedTableName}
                isNewArchitecture={true}
                selectedId={selectedPlanId}
            />
    }
  },[gridName,FileToUpload,fileNameToDownload])
  //console.log("salesAndFinanceValue===>",salesAndFinanceValue);
  return (
    <div className="AllBumainSalesAndFinanceHeader">
      <div className="filterStyle">     
          {filterComponentDisplay}
          {switchExpandallComponent()}
          <div style={{marginRight:'0%',marginTop:'8px'}}>
            {searchComponentDisplay}
          </div>
          {errorComponentDisplay}
          <>
            {s3UploadDownloadComponent}
          </>
      </div>
      {(salesTabEditing && gridName==='salesgrid')?
        <div className="saveButtons">
          <div className="cancelButton">
            <Button onClick={(e) => handleCancelSalesTabEdit(e)}>Cancel</Button>
          </div>
        </div>
      :''}
      {(showOption && !hideActions)?
        <div className="saveButtons">
          <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
          </Button></div>
          <div className="primaryButton">
            <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
            </Button></div>
          <div className="primaryButton">
            {/* Disable Add + buttton if plan status running  */}
            { gridName==='bvdepoverridegrid'?<Button disabled={!salesAndFinanceInputsGridColDefReducer.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>Add +
            </Button>:''}
          </div>
          <div className="primaryButton">
            {/* Disable delete delete button if plan is running  */}
            { gridName==='bvdepoverridegrid'?<Button onClick={() => handleClickOpen()} disabled={true}>
            Delete
           </Button>:''}
          </div>
          {deleteAllButtonComponent}
        </div> :
        (<div className="actionButtons">
          {/* {(currentView !== "grid" && actionView !== "grid") ? <img src={IconActionEdit} className="imageIcon" onClick={(e) => handleEditClick(e)}></img> :
            <img src={IconGrid} className="imageIcon" onClick={(e) => handleEditClick(e)}></img>} */}
          {(gridName==='bvdepoverridegrid')?<div className='flex'>
            {!hideActions? 
              <>
                {!gridErrorViewStatus[gridName]  || salesAndFinanceGrid!=="salesGrid" && salesAndFinanceGrid!=="saleschannelGrid"? 
                    <label>
                        <input 
                        //disabled={!salesAndFinanceInputsGridColDefReducer.defaultColDef.editable} 
                        disabled={gridErrorViewStatus[gridName] || plansDataById[0].status.toLowerCase()=='sales adjustments ready' || plansDataById[0].status.toLowerCase()=='vin dep process ready'}
                        type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
                        <img src={IconActionUpload} className="imageIcon cursorpoint"></img>
                    </label>
                :''} 
              </>
            :''}
          <div className='imageIcon cursorpoint'>
            <img src={IconActionDownload} 
            onClick={() => downloadHandler()} 
            />
          </div></div>:''}
          {/* {switchViewComponent()} */}
          {!hideActions?<div style={{display:'flex'}}>
            <div className="primaryButton">
              { gridName==='bvdepoverridegrid'?<Button disabled={true || !salesAndFinanceInputsGridColDefReducer.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>
                Add +
            </Button>:''}</div>
            <div className="primaryButton">
             { gridName==='bvdepoverridegrid'?<Button onClick={() => handleClickOpen()} disabled={true || !salesAndFinanceInputsGridColDefReducer.defaultColDef.editable}>
             Delete
            </Button>:''}
            </div>
            {deleteAllButtonComponent}
            </div>:''}
        </div>)}
        <DeleteDialogBox open={openDeleteDialog}  handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
          dialogMessage={message} title={title} action={deletetext}
          canceltext={canceltext}>
        </DeleteDialogBox>
    </div>
  )
}

export default SalesAndFinanceActionBar;