export const FETCH_BVDEPOVERRIDE_DATA_PENDING = 'FETCH_BVDEPOVERRIDE_DATA_PENDING';
export const FETCH_BVDEPOVERRIDE_DATA_SUCCESS = 'FETCH_BVDEPOVERRIDE_DATA_SUCCESS';
export const FETCH_BVDEPOVERRIDE_DATA_ERROR = 'FETCH_BVDEPOVERRIDE_DATA_ERROR';
export const UPLOAD_BVDEPOVERRIDE_PENDING = 'UPLOAD_BVDEPOVERRIDE_PENDING';
export const UPLOAD_BVDEPOVERRIDE_SUCCESS = 'UPLOAD_BVDEPOVERRIDE_SUCCESS';
export const UPLOAD_BVDEPOVERRIDE_ERROR = 'UPLOAD_BVDEPOVERRIDE_ERROR';
export const SAVE_BVDEPOVERRIDE_TRANSACTION_PENDING = 'SAVE_BVDEPOVERRIDE_TRANSACTION_PENDING';
export const SAVE_BVDEPOVERRIDE_TRANSACTION_SUCCESS = 'SAVE_BVDEPOVERRIDE_TRANSACTION_SUCCESS';
export const SAVE_BVDEPOVERRIDE_TRANSACTION_ERROR = 'SAVE_BVDEPOVERRIDE_TRANSACTION_ERROR';
export const FETCH_BVDEPOVERRIDE_DROPDOWNVALUES_PENDING = 'FETCH_BVDEPOVERRIDE_DROPDOWNVALUES_PENDING';
export const FETCH_BVDEPOVERRIDE_DROPDOWNVALUES_SUCCESS = 'FETCH_BVDEPOVERRIDE_DROPDOWNVALUES_SUCCESS';
export const FETCH_BVDEPOVERRIDE_DROPDOWNVALUES_ERROR = 'FETCH_BVDEPOVERRIDE_DROPDOWNVALUES_ERROR';
export const RESET_TO_BVDEPOVERRIDE_INITIALSTATE = 'RESET_TO_BVDEPOVERRIDE_INITIALSTATE';
export const SET_BVEPOVERRIDE_EDITABLE = 'SET_BVEPOVERRIDE_EDITABLE';