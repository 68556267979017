import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useComponentWillMount } from './../utils';
import EditIcon from "../../../../assets/images/icon_edit.svg";
import UndoIcon from "../../../../assets/images/icon_undo.svg";
import './TemplateActionsRenderer.css';
import { revertRow } from '../../../../store/MultiGrid/MultiGrid.Actions'
// this below revert is from MainPlanInputsMultiGrid.Action file
import { revertRow as newRevertRow } from '../../../../store/MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export default (props) => {
    let [editing, setEditing] = useState(false);
    let [edited, setEdited] = useState(false);
    let [disabled, setDisabled] = useState(false);
    let [editdisabled, setEditDisabled] = useState(false);
    const dispatch = useDispatch();
    let isDeleteClicked = false;
    let isEditCLicked = false;
    //Plan Edit Disabled useSelector
    let ifPlanEditDisabled = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
    });
    const templatesGridColumnDef = useSelector((state) => {
        return state.rootUpgradeTemplatesReducer.templatesColumnDefReducer;
      })
    var templatesGridReducer = useSelector((state) => {
        return state.rootUpgradeTemplatesReducer.templatesGridReducer;
      });
    const gridName = useSelector((state) => {
        return state.PaginationGridReducer.gridName
      })
      
    useEffect(()=>{
        templatesGridReducer.transactions.map((transacData)=>{
            if(transacData.type!='add' && transacData.type!='copy'){
            if(props.data.ID==transacData.data.ID){
               setEdited(true)
            }  
        } 
        // else if(transacData.type=='copy'){
        //  if(props.data.ID==transacData.data.ID && transacData.data.status===''){
        //     // console.log(transacData)
        //         setEdited(true)
        //         setEditing(false);
        //      }
        // }
        })
      },[templatesGridReducer.transactions])

    // custom hook
    // useComponentWillMount(() => {
    //     let editingCells = props.api.getEditingCells();
    //     if (editingCells.length !== 0) {
    //         setDisabled(true);
    //     }
    // });

    useEffect(() => {
        if (props.data) {

        }
        if (props.data.status === "ADD_ROW" && Object.keys(props.data).length <= 2) {
            startEditing();
        }
        props.api.addEventListener('cellValueChanged', onCellValueEditStarted);
        props.api.addEventListener('cellEditingStopped', onCellValueEditStopped);

        return () => {
            props.api.removeEventListener('cellValueChanged', onCellValueEditStarted);
            props.api.removeEventListener('cellEditingStopped', onCellValueEditStopped);
        };
    }, [editing, edited]);


    function onCellValueEditStarted(params) {
        if(!ifPlanEditDisabled && templatesGridColumnDef.defaultColDef.editable){
        if (props.node === params.node) {
            setEditing(true);
            setEdited(false);
            isEditCLicked = true;
        } else {
            setDisabled(true);
        }
    }
    }

    function onCellValueEditStopped(params) {
        if (isDeleteClicked) {
            setEditing(true);
        }
        // else if(JSON.stringify(props.data) !== JSON.stringify(props.node.data)) {
        //     setEditing(false);
        //     setEdited(true);
        // }
        else if (props.node === params.node) {
            setEditing(false);
            if ((JSON.stringify(props.data) !== JSON.stringify(props.node.data)) || isEditCLicked) {
                setEdited(true);
            }
            else
                setEdited(false);
        } else {
            setDisabled(true);
        }
        // params.api.refreshCells({
        //         force: true,
        //         rowNodes: [params.node] // pass rowNode that was edited
        //     });

    }

    function startEditing(params) {
        // Plan status is running should disable editing
        if(!ifPlanEditDisabled && templatesGridColumnDef.defaultColDef.editable){
        setEditDisabled(true);
        props.api.startEditingCell({
            rowIndex: props.node.rowIndex,
            colKey: props.api.getColumnDefs()[1].colId
        });
        }
    }

    function revertChanges() {      
       if(gridName === "upgradetemplatesGrid")
        {       
        props.api.stopEditing(true);
        dispatch(newRevertRow("upgradetemplatesGrid", props.node.rowIndex, props.data.ID, props.data, templatesGridColumnDef.validationFn,  templatesGridColumnDef.businessValidationFn));
        setEdited(false); 
        }else{
    // this else block  will be removed once all grids are moved to new Architecture   
        props.api.stopEditing(true);
        dispatch(revertRow("upgradetemplatesgrid", props.node.rowIndex, props.data.ID, props.data, templatesGridColumnDef.validationFn,  templatesGridColumnDef.businessValidationFn));
        setEdited(false);
        }
    }

    return (
        <div>
            {edited && !ifPlanEditDisabled && templatesGridColumnDef.defaultColDef.editable?
                <img src={UndoIcon} onClick={revertChanges} className='tempreverticon'></img>
                :
                (<img src={EditIcon} onClick={startEditing} className='tempediticon'></img>)
            }
        </div>
    )
}
