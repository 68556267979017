
import { columnDefs, defaultColDef } from "./upgrade_paths_grid_data/UpgradePathsColumnDefs";
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import {
    FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_PENDING, FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_SUCCESS, FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_ERROR, SET_CARCLASSGROUP_EDITABLE,
}
    from "./UpgradeTemplates.ActionTypes";
import { UpgradePathsColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkType: function(value){
        return{
        cost:{type:checkTypeCurrency(value,'cost')},
        ultimate_choice:{type:checkTypePercentage(value,'ultimate_choice')}
        }
    },
    checkValue: function(value){
        return{
            value:checkValueType(value)
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            upgrade_zone_template_id: { error: checkIfValidZone(data.upgrade_zone_template_id, UpgradePathsColumns.ZoneTemp.headerName) },
            ultimate_choice: { error: checkIfValidUltimateChoice(data.ultimate_choice, UpgradePathsColumns.UltimateChoice.headerName) },
            from_cc: { error: checkIfValidFromcc(data.from_cc, data, UpgradePathsColumns.FromClass.headerName,initialState.columnDefs.findIndex(itm=>itm.field===UpgradePathsColumns.FromClass.field)) },
            to_cc: { error: checkIfValidTocc(data.to_cc, data, UpgradePathsColumns.ToClass.headerName,initialState.columnDefs.findIndex(itm=>itm.field===UpgradePathsColumns.ToClass.field)) },
            cost: { error: checkIfValidCost(data.cost, UpgradePathsColumns.Cost.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    },
    pending:false,
    upgradePathCarGroupData: [],
    error:null
}

function checkValueType(value){
    if(value!=="ID" && value!=="version" && value!=="updated_on" && value!=="ADD_VOLUME_MAX" && value!=="id" && value!=="constraint_table" && value!=='plan_id' && value!=='zone_code'){
        return true;
    }else{
        return false;
    }
}

function checkTypeCurrency(value1,value2){
    if(value1==value2){
        return 'currency';
    }
}

function checkTypePercentage(value1,value2){
    if(value1==value2){
     return 'percentage';
    }
}

function checkIfValidUltimateChoice(value, name) {
    if (value === undefined || value === '' || value === null){
        return name + " cannot be empty";
    }
    if(value<0){
        return name + " cannot be less than zero";
    }
    if(value>1){
        return name + " cannot be greater than one";
    }
    return null;
}

function checkIfValidFromcc(ccvalue, data, name, index) {
    if (ccvalue === undefined || ccvalue === '' || ccvalue === null) {
        return name + " cannot be empty";
    }
    else
        if (initialState.columnDefs[index].cellEditorParams !== undefined) {
            let value = ccvalue.car_class ? ccvalue.car_class : ccvalue;
            let ccdata = data.to_cc ? data.to_cc.car_class ? data.to_cc.car_class : data.to_cc : '';
            if (!(initialState.columnDefs[index].cellEditorParams.options.some(u => u.car_class === value))) {
                return name + " " + value + " no-longer available";
            }
            //else if (ccdata == value) {
            //         return name + " cannot be same as to class";
            //     }
            // }
            else if ((data.to_cc === ccvalue.car_class) || (data.to_cc === ccvalue)) {
                return name + " cannot be same as to class";
            }
        }
    return null;
}

function checkIfValidTocc(ccvalue, data, name, index) {
    if (ccvalue === undefined || ccvalue === '' || ccvalue === null) {
        return name + " cannot be empty";
    }
    else
        if (initialState.columnDefs[index].cellEditorParams !== undefined) {
            let value = ccvalue.car_class ? ccvalue.car_class : ccvalue;
            let ccdata = data.from_cc ? data.from_cc.car_class ? data.from_cc.car_class : data.from_cc : '';
            if (!(initialState.columnDefs[index].cellEditorParams.options.some(u => u.car_class === value))) {
                return name + " " + value + " no-longer available";
            }
            //else if (ccdata == value) {
            //         return name + " cannot be same as from class";
            //     }
            // }
            else if ((data.from_cc === ccvalue.car_class) || (data.from_cc === ccvalue)) {
                return name + " cannot be same as from class";
            }
        }
    return null;
}

function checkIfValidCost(value, name) {
    if (value === undefined || value === '' || value === null) {
        return name + " cannot be empty";
    }
    return null;
}

function checkIfValidZone(value, name) {
    if (value === undefined || value === '' || value === null) {
        return name + " cannot be empty";
    }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    let to_cc ='',from_cc='';
    let itm_from_cc = '', itm_to_cc = '';
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if(typeof(itm2.to_cc)=='object'){
                to_cc=itm2.to_cc!==null?itm2.to_cc.car_class:'';
            }else{
                to_cc=itm2.to_cc;
            }
            if(typeof(itm2.from_cc)=='object'){
                from_cc=itm2.from_cc!==null?itm2.from_cc.car_class:'';
            }else{
                from_cc=itm2.from_cc;
            }
            if(typeof(itm.to_cc)=='object') {
                itm_to_cc = itm.to_cc!==null?itm.to_cc.car_class:'';
            } else {
                itm_to_cc = itm.to_cc;
            }
            if(typeof(itm.from_cc)=='object'){
                itm_from_cc=itm.from_cc!==null?itm.from_cc.car_class:'';
            }else{
                itm_from_cc=itm.from_cc;
            }
            if (itm.upgrade_zone_template_id === itm2.upgrade_zone_template_id && safeCaseInsensitiveCompare(itm_from_cc, from_cc) && safeCaseInsensitiveCompare(itm_to_cc, to_cc)) {
                unique = false;
                if(itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if(itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function setColumnDefParams(columnDefs, data) {
    let zoneTempOptions = ['COLD', 'HOT'];
    columnDefs.map((column) => {
        if (column.field === UpgradePathsColumns.FromClass.field) {
            let from_ccFilterData = []
            data.adminCarGroup.map((val) => {//Note: The label mentioned below is assigned to show both the car group and car class values in the filter options of the dropdown.
                from_ccFilterData.push({ "label": val.car_class + ' ' + val.car_class_car_group, "value": val.car_class })
            })
            from_ccFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.carGroup }
            column.filterOptions = from_ccFilterData;
        }
        if (column.field === UpgradePathsColumns.ToClass.field) {
            let to_ccFilterData = []
            data.adminCarGroup.map((val) => {//Note: The label mentioned below is assigned to show both the car group and car class values in the filter options of the dropdown.
                to_ccFilterData.push({ "label": val.car_class + ' ' + val.car_class_car_group, "value": val.car_class })
            })
            to_ccFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.carGroup }
            column.filterOptions = to_ccFilterData;
        }
        if (column.field === UpgradePathsColumns.ZoneTemp.field) {
            let zoneTempFilterData=[]
            zoneTempFilterData.push({"label": 'COLD', "value": 'COLD'})
            zoneTempFilterData.push({"label": 'HOT', "value": 'HOT'})            
            zoneTempFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: zoneTempOptions }
            column.filterOptions = zoneTempFilterData;            
        }
        if (column.field === UpgradePathsColumns.UpdatedBy.field) {
            let userKeys = Object.keys(data.users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": data.users[userData], "value": data.users[userData] })
            })
            column.filterOptions = usersFilterData
        }
        if(column.colId==='pathactions'){
             // this if condition changes cellrenderer when new version
            if(JSON.parse(localStorage.getItem("newArchitecture"))){
                column.cellRenderer='MainPlanInputsActionRenderer'
            }
            else{
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                column.cellRenderer='UpgradePathActionsRenderer'
            }
        }
    });
    return columnDefs;
}

export default function upgradePathsColumnDefReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_SUCCESS:
            return Object.assign({}, state, {
                upgradePathCarGroupData: setColumnDefParams(state.columnDefs, action.data),
                pending: false,
            });
        case FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending: false
            });
        case SET_CARCLASSGROUP_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}