import React from 'react';
import AllocatedActionBar from "../../components/allocatedactionbar/AllocatedActionBar";
import MultiGridUI from "../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const AllocatedTabPanel = (props) => {
    const { allocatedData, handleSwitchView, currentView, showSaveOption, downloadCSV,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        gridReducer, allocatedGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows, filterParams, filterData, transactions,
        isFiltered, setIsFiltered, isSearchBarValueExists, setIsSearchBarValueExists, userMessage, newArchitecture, gridName ,pending,loadGridData} = props;
    return (
        <div>
            {newArchitecture ? (<MainPlanInputsMultiGridUI reducer={gridReducer}
                gridName={gridName}
                //currentView={currentView}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={allocatedGridColDef}
                rowEditType={'single'}
                suppressclickEdit={!allocatedGridColDef.defaultColDef.editable||pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={true} />) : (<>
                    <AllocatedActionBar
                        options={showSaveOption}
                        bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                        onViewChanged={handleSwitchView}
                        onAddNewRowClick={handleAddNewRow}
                        actionView={currentView}
                        handleSaveCancelDisabled={handleSaveCancelDisabled}
                        onSaveClicked={handleSaveClicked}
                        onCancelClicked={handleCancelClicked}
                        selectedPlanId={selectedPlanId}
                        deleteSelectedRows={deleteSelectedRows}
                        selectedRows={selectedRows}
                        downloadCSV={downloadCSV}
                        data={gridReducer.currentData}
                        filterParams={filterParams}
                        allocatedData={allocatedData}
                        allocatedGridColDef={allocatedGridColDef}
                        filterData={filterData}
                        transactions={transactions}
                        isFiltered={isFiltered}
                        setIsFiltered={setIsFiltered}
                        isSearchBarValueExists={isSearchBarValueExists}
                        setIsSearchBarValueExists={setIsSearchBarValueExists}
                    />
                    <MultiGridUI reducer={gridReducer}
                        gridName={"allocatedgrid"}
                        currentView={currentView}
                        refreshCells={refreshCells}
                        onRowEditingStarted={handleRowEditingStarted}
                        onRowEditingStopped={handleRowEditingStopped}
                        onCellEditingStopped={handleCellEditingStopped}
                        gridColDef={allocatedGridColDef}
                        rowEditType={'single'}
                        suppressclickEdit={!allocatedGridColDef.defaultColDef.editable}
                        deleteSelectedRows={deleteSelectedRows}
                        handleMultipleRowDelete={handleMultipleRowDelete}
                        userMessage={userMessage} />
                </>)}

        </div>
    )

}

export default AllocatedTabPanel;