import React from 'react';
import MultiGridUI from "../../components/grid/MultiGridUI";
import { useSelector } from 'react-redux';
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const ManualVinDeletesTabPanel = (props)=>{
    const{manualVinDeletesGridReducer,manualVinDeletesCurrentData,manualVinDeletesGridColumnDef,manualVinDeletesPageNo,
        manualVinDeletesTransactions,currentView,refreshCells,handleRowEditingStarted,handleRowEditingStopped,
        handleCellEditingStopped,handleMultipleRowDelete,userMessage,gridReducer,gridName,selectedPlanId,
        deleteSelectedRows,newArchitecture,pending,loadGridData} = props;
 return(
     <div>
        {newArchitecture ?(<MainPlanInputsMultiGridUI reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={manualVinDeletesGridColumnDef}
            rowEditType={'single'}
            suppressclickEdit={!manualVinDeletesGridColumnDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            loadGridData={loadGridData}
            isPaginationRequired={true}/>):
         (<MultiGridUI 
            reducer={manualVinDeletesGridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'manualvindeletesgrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={manualVinDeletesGridColumnDef}
            suppressclickEdit={!manualVinDeletesGridColumnDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            manualVinDeletesTransactions={manualVinDeletesTransactions}
            userMessage={userMessage}
            gridpageno={manualVinDeletesPageNo}
            paginationRequired={true}
             />
            )}
     </div>
 )
}

export default ManualVinDeletesTabPanel;