import React from "react";
import { useSelector } from "react-redux";
import { checkIfFreezMonth } from "../../../utils/AdjustmentsUtils";
import ReadOnlyCellComponent from "./ReadOnlyCellComponent";
import './ReadOnlyCellComponent.css';

/**
 * @typedef CellData
 * @type {{
 *  current:number,
 *  adjustment:number,
 *  original:number,
 *  year:number
 * }}
 */

/**
 * 
 * @param {{startFleetValue:number,arrayObject:[*]}} param0 
 * @returns 
 */
const EndFleetRowComponent = (props) => {
    const{ selectedVal,startFleetValue, arrayObject, isExpanded, planStatus, addStartFleet, isOfTypeEndFleet, filterParams, zonename, accordionContent,filterRecord,currentHeader } = props;
    let freezeCpd  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.freezeCpd;
    });

    /**
     * @type {[{
     * data:CellData,
     * summary:boolean
     * }]}
     */
    let returnObj = [];

    let currentValueStartFleetForNextCell = ((!startFleetValue) ? 0: startFleetValue);
    let originalValueStartFleetForNextCell = ((!startFleetValue) ? 0: startFleetValue);
    let adjustmentValueStartFleetForNextCell = 0;
    /**
     * 
     * @param {[*]} array 
     * @param {number} year 
     * @returns {number}
     */
    function aggregateAdjustment(array, year){
        return array.filter(o=>o.year === year).reduce((agg,curr)=>curr.adjustment.value+agg,0);
    }

    /**
     * 
     * @param {[*]} array 
     * @param {number} year 
     * @returns {number}
     */
    function aggregateCurrent(array,year){
        return array.filter(o=>o.year === year).reduce((agg,curr)=>curr.current.value+agg,0);
    }

    /**
     * 
     * @param {[*]} array 
     * @param {number} year 
     * @returns {number}
     */
     function aggregateOriginal(array,year){
        return array.filter(o=>o.year === year).reduce((agg,curr)=>curr.original.value+agg,0);
    }

 //Function to return the summary cell value for Total value of this and next cell year 
    function getZoneSummaryValue(temp_obj){
        let svalue;
        svalue = {  //build a summary cell object for this year
            "current":temp_obj.current.value ,
            "adjustment":temp_obj.adjustment==undefined?0:temp_obj.adjustment.value,
            "original":temp_obj.original.value,
            "year":temp_obj.year
            , "fnlVal": temp_obj.fnlVal
            , "adjVar": temp_obj.adjVar

        };
        return svalue
    }

    // if(selectedVal==="Zone>Type"){
        let currentDataObj
        let temp_arrayObject=[] ,i=0;
    for(let index = 0; index < arrayObject.length; index++){
        let ele=arrayObject[index];
        currentDataObj = {
            "current": ele.current.value 
            , "adjustment":ele.adjustment==undefined?0:ele.adjustment.value
            , "original": ele.original.value
            , "year": ele.year
            , "activity_type": currentHeader
            , "fnlVal": ele.fnlVal
            , "adjVar": ele.adjVar ,
            "isFreezMonth":checkIfFreezMonth(ele.year,ele.month,freezeCpd)
        }
        if(index!=0 && arrayObject[index].year!=(arrayObject[index+1]===undefined?0:arrayObject[index+1].year)){
            temp_arrayObject.push({...arrayObject[index],'index':index})
            returnObj.push({data: currentDataObj});
        }
        else{
            returnObj.push({data: currentDataObj});
        }
    }
    for(let v=0;v<temp_arrayObject.length;v++){
        let zonesummaryValue;let temp_obj;
        temp_obj=temp_arrayObject[v]
        zonesummaryValue = getZoneSummaryValue(temp_obj)  //build a summary cell object for this year   
        returnObj.splice(i,0,{"data":zonesummaryValue,"summary":true}); //Push summary cell
        // returnObj.push({data: currentDataObj});
        i=temp_arrayObject[v]['index']+(v+2)
    }
    
    // }else{
    // for (let index = 0; index < arrayObject.length; index++) {
    //     const element = arrayObject[index];
    //     /**
    //      * @type {CellData}
    //      */
    //     const currentDataObj = {
    //         "current": element.current.value +((index===0 && !addStartFleet)? 0: currentValueStartFleetForNextCell) //calculate end-fleet values by adding start fleet to activity number
    //         , "adjustment": element.adjustment.value + adjustmentValueStartFleetForNextCell // calculate end-fleet values by adding start fleet to current month value
    //         , "original": element.original.value +((index===0 && !addStartFleet)? 0: originalValueStartFleetForNextCell) //calculate end-fleet values by adding start fleet to activity number
    //         , "year": element.year
    //         , "activity_type": currentHeader,
    //           "isFreezMonth":checkIfFreezMonth(element.year,element.month,freezeCpd)
    //     }
    //     if(index === 0 || arrayObject[index-1].year !== arrayObject[index].year){  //if at the beginning or if the year has changed
    //         /**@type {CellData} */
    //         const summaryValue = {  //build a summary cell object for this year
    //             "current": aggregateCurrent(arrayObject,currentDataObj.year) + (!addStartFleet ? 0:currentValueStartFleetForNextCell),
    //             "adjustment": aggregateAdjustment(arrayObject,currentDataObj.year) + adjustmentValueStartFleetForNextCell,
    //             "original": aggregateOriginal(arrayObject,currentDataObj.year) + (!addStartFleet ? 0:originalValueStartFleetForNextCell),
    //             "year":currentDataObj.year
    //             ,"activity_type": currentHeader
    //         };

    //         };

    //         returnObj.push({"data":summaryValue,"summary":true}); //Push summary cell

    //         returnObj.push({data: currentDataObj}); //add current cell object
    //     }
    //     else{
    //         returnObj.push({data: currentDataObj});
    //     }

    //     currentValueStartFleetForNextCell = currentDataObj.current;
    //     originalValueStartFleetForNextCell = currentDataObj.original;
    //     adjustmentValueStartFleetForNextCell = currentDataObj.adjustment;
    // }
    // }
    return returnObj.map((o)=>{
        if(o.summary){
            return (<ReadOnlyCellComponent type='summaryStack' data={o.data} isExpanded={isExpanded} planStatus={planStatus} isOfTypeEndFleet={isOfTypeEndFleet} currentHeader={currentHeader} filterParams={filterParams}
            zonename={zonename} filterRecord={filterRecord}></ReadOnlyCellComponent>);
        }
        else{
            return (<ReadOnlyCellComponent data={o.data} isExpanded={isExpanded} planStatus={planStatus} isOfTypeEndFleet={isOfTypeEndFleet} currentHeader={currentHeader} filterParams={filterParams}
                zonename={zonename} filterRecord={filterRecord}></ReadOnlyCellComponent>);
        }
    });
}

export default EndFleetRowComponent;


