export const FETCH_ALLOCATED_DATA_PENDING = 'FETCH_ALLOCATED_DATA_PENDING';
export const FETCH_ALLOCATED_DATA_SUCCESS = 'FETCH_ALLOCATED_DATA_SUCCESS';
export const FETCH_ALLOCATED_DATA_ERROR = 'FETCH_ALLOCATED_DATA_ERROR';
export const FETCH_ALLOCATED_DROPDOWNVALUES_PENDING = 'FETCH_ALLOCATED_DROPDOWNVALUES_PENDING';
export const FETCH_ALLOCATED_DROPDOWNVALUES_SUCCESS = 'FETCH_ALLOCATED_DROPDOWNVALUES_SUCCESS';
export const FETCH_ALLOCATED_DROPDOWNVALUES_ERROR = 'FETCH_ALLOCATED_DROPDOWNVALUES_ERROR';
export const SAVE_ALLOCATED_TRANSACTION_PENDING = 'SAVE_ALLOCATED_TRANSACTION_PENDING';
export const SAVE_ALLOCATED_TRANSACTION_SUCCESS = 'SAVE_ALLOCATED_TRANSACTION_SUCCESS';
export const SAVE_ALLOCATED_TRANSACTION_ERROR = 'SAVE_ALLOCATED_TRANSACTION_ERROR';
export const UPLOAD_ALLOCATED_PENDING = 'UPLOAD_ALLOCATED_PENDING';
export const UPLOAD_ALLOCATED_SUCCESS = 'UPLOAD_ALLOCATED_SUCCESS';
export const UPLOAD_ALLOCATED_ERROR = 'UPLOAD_ALLOCATED_ERROR';
export const SET_ALLOCATED_FILTER_PARAMS = 'SET_ALLOCATED_FILTER_PARAMS';
export const FETCH_ALLOCATED_FILTER_DATA_PENDING = 'FETCH_ALLOCATED_FILTER_DATA_PENDING';
export const FETCH_ALLOCATED_FILTER_DATA_SUCCESS = 'FETCH_ALLOCATED_FILTER_DATA_SUCCESS';
export const FETCH_ALLOCATED_FILTER_DATA_ERROR = 'FETCH_ALLOCATED_FILTER_DATA_ERROR';
export const RESET_TO_ALLOCATED_INITIALSTATE = 'RESET_TO_ALLOCATED_INITIALSTATE';
export const SET_ALLOCATED_EDITABLE='SET_ALLOCATED_EDITABLE';