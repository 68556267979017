import {dateFormatter, currencyFormatter, valueFormatter,updatedOnDateFormatter} from "../../../utils/formatters/valueFormatter";
import { getUserManagementData } from "../../../services/admin/usermanagement/UserManagementService";
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP, ProgramsColumns, cellEditors } from "../../../Constants";
import { customDateComparator, customNumericSortComparator, customStringComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: ProgramsColumns.ProgramYear.field, 
        headerName: ProgramsColumns.ProgramYear.headerName, 
        minWidth: 100, unSortIcon: true, cellEditor: cellEditors.numericEditor,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.ProgramYear.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.ProgramYear.name)
        }
    },
    {
        field: ProgramsColumns.ManufacturerGroup.field, 
        headerName: ProgramsColumns.ManufacturerGroup.headerName,
         cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.ManufacturerGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.ManufacturerGroup.name)
        },
        cellEditorParams:(params)=>{
            let options =  getModelgroupBasedOnManufacturerGroup(params);
            return options;
          }
    },
    {
        field: ProgramsColumns.SpiCode.field, 
        headerName: ProgramsColumns.SpiCode.headerName, 
        cellEditor: cellEditors.freeSoloAutoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.SpiCode.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.SpiCode.name)
        }
    },
    {
        field: ProgramsColumns.ProgramType.field,
        headerName: ProgramsColumns.ProgramType.headerName,
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.ProgramType.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.ProgramType.name)
        }
    },
    {
        field: ProgramsColumns.ModelGroup.field, 
        headerName: ProgramsColumns.ModelGroup.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.ModelGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.ModelGroup.name)
        }
    },
    {
        field: ProgramsColumns.ModelYear.field, 
        headerName: ProgramsColumns.ModelYear.headerName,
        minWidth: 120, unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.ModelYear.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.ModelYear.name)
        }
    },
    {
        field: ProgramsColumns.CarClassCode.field, 
        headerName: ProgramsColumns.CarClassCode.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.CarClassCode.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.CarClassCode.name)
        }
    },
    {
        field: ProgramsColumns.BeginDate.field, 
        headerName: ProgramsColumns.BeginDate.headerName, 
        cellEditor: cellEditors.dateEditor, unSortIcon: true,
        valueFormatter: params => dateFormatter(params.data.BEGIN_DATE),
        filterType:"datepicker",
        cellClassRules: cellClassRules(ProgramsColumns.BeginDate.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.BeginDate.name)
        }
    },
    {
        field: ProgramsColumns.EndDate.field, 
        headerName: ProgramsColumns.EndDate.headerName, 
        cellEditor: cellEditors.dateEditor, unSortIcon: true,
        valueFormatter: params => dateFormatter(params.data.END_DATE),
        filterType:"datepicker",
        cellClassRules: cellClassRules(ProgramsColumns.EndDate.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.EndDate.name)
        }
    },
    {
        field: ProgramsColumns.AcceptableZones.field, 
        headerName: ProgramsColumns.AcceptableZones.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.AcceptableZones.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.AcceptableZones.name)
        }
    },
    {
        field: ProgramsColumns.MinAge.field, 
        headerName: ProgramsColumns.MinAge.headerName, 
        minWidth: 110, unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator,filterType:"range", 
        cellClassRules: cellClassRules(ProgramsColumns.MinAge.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.MinAge.name)
        }
    },
    {
        field: ProgramsColumns.MaxAge.field, 
        headerName: ProgramsColumns.MaxAge.headerName, 
        minWidth: 110, unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, filterType:"range",
        cellClassRules: cellClassRules(ProgramsColumns.MaxAge.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.MaxAge.name)
        }
    },
    {
        field: ProgramsColumns.MinMileage.field, 
        headerName: ProgramsColumns.MinMileage.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.MIN_MILEAGE),
        filterType:"range",
        cellClassRules: cellClassRules(ProgramsColumns.MinMileage.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.MinMileage.name)
        }
    },
    {
        field: ProgramsColumns.MaxMileage.field, 
        headerName: ProgramsColumns.MaxMileage.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.MAX_MILEAGE),
        filterType:"range",
        cellClassRules: cellClassRules(ProgramsColumns.MaxMileage.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.MaxMileage.name)
        }
    },
    {
        field: ProgramsColumns.NetCapitalCost.field, 
        headerName: ProgramsColumns.NetCapitalCost.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => currencyFormatter(params.data.NET_CAPITAL_COST, "$"),
        filterType:"input", 
        cellClassRules: cellClassRules(ProgramsColumns.NetCapitalCost.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.NetCapitalCost.name)
        }
    },
    {
        field: ProgramsColumns.DepreciationCost.field, 
        headerName: ProgramsColumns.DepreciationCost.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => currencyFormatter(params.data.DEPRECIATION_COST, "$"),
        filterType:"input",
        cellClassRules: cellClassRules(ProgramsColumns.DepreciationCost.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.DepreciationCost.name)
        }
    },
    {
        field: ProgramsColumns.Incentive.field, 
        headerName: ProgramsColumns.Incentive.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => currencyFormatter(params.data.INCENTIVE, "$"),
        filterType:"input",
        cellClassRules: cellClassRules(ProgramsColumns.Incentive.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.Incentive.name)
        }
    },
    {
        field: ProgramsColumns.AddVolumeMin.field, 
        headerName: ProgramsColumns.AddVolumeMin.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.ADD_VOLUME_MIN),
        filterType:"range",
        cellClassRules: cellClassRules(ProgramsColumns.AddVolumeMin.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.AddVolumeMin.name)
        }
    },
    {
        field: ProgramsColumns.AddVolumeMax.field, 
        headerName: ProgramsColumns.AddVolumeMax.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.ADD_VOLUME_MAX),
        filterType:"range", 
        cellClassRules: cellClassRules(ProgramsColumns.AddVolumeMax.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.AddVolumeMax.name)
        }
    },
    {
        field: ProgramsColumns.StartMileage.field, 
        headerName: ProgramsColumns.StartMileage.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.START_MILEAGE),
        filterType:"input",
        cellClassRules: cellClassRules(ProgramsColumns.StartMileage.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.StartMileage.name)
        }
    },
    {
        field: ProgramsColumns.BuyStatus.field, 
        headerName: ProgramsColumns.BuyStatus.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.BuyStatus.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.BuyStatus.name)
        }
    },
    {
        field: ProgramsColumns.UpdatedByName.field, 
        headerName: ProgramsColumns.UpdatedByName.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(ProgramsColumns.UpdatedByName.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.UpdatedByName.name)
        }
    },
    {
        field: ProgramsColumns.UpdatedOn.field, 
        headerName: ProgramsColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(ProgramsColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ProgramsColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        pinnedRowCellRenderer: "saveActionRenderer",
        cellRenderer:"actionsRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field]) {
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                //console.log("Print the params !!!!4: " + index);
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
function getModelgroupBasedOnManufacturerGroup(params){   
    let allManufacturergroupModelgroup =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
    let trimmedModalGroup=params.data.MODEL_GROUP?params.data.MODEL_GROUP.trim():'';
    let options=allManufacturergroupModelgroup[trimmedModalGroup]?allManufacturergroupModelgroup[trimmedModalGroup]:[];
    return {'options':options};
  }
   
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
