import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import icon_delete from "../../assets/images/icon_trash.svg";
import icon_detailsview from '../../assets/images/buttons-detailed-view.svg';
import "./AccordionView.css";
import "./UpgradePathAccordionContent.css";
import DialogBox from "../Dialogcomponent/DialogComponent";
import { valueFormatter, dateFormatter, currencyFormatter, percentageFormatter } from "../../utils/formatters/valueFormatter";
import { deleteRow } from '../../store/upgradepaths/UpgradePaths.Actions';

const UpgradePathAccordionContent = (props) => {
    const { data, planid, templateValue, mainData } = props;
    const [open, setOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    let locatioName = location.pathname;
    let message = "Are you sure that you want to delete this row?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";

    function handleClickOpen(deleterowData) {
        setSelectedRowData(deleterowData);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function deleteSelectedRow() {
        try {
            dispatch(deleteRow(planid, selectedRowData.ID));
            handleClose()
        } catch (err) {
            console.error('got error while deleting data');
        }
    };

    if (data != undefined && data.length > 0) {
        return (
            <div>
                {data.map((content, index) => {
                    return (
                        <div className="indent pathcontenticons">
                            <span className="pathcontentpanel">
                                <span className='toccstyle'>{valueFormatter(content.to_cc)}</span>
                                <span className='toccgroup'>{valueFormatter(content.to_cc_group)}</span>
                                <span className='pathcost'>{currencyFormatter(content.cost.toString(), "$")}</span>
                                <span className='pathchoice'>{percentageFormatter(content.ultimate_choice.toString(), "%")}</span>
                            </span>
                            <span onClick={() => handleClickOpen(content)} className='pathdeleteicon'><img src={icon_delete}></img>
                            </span>
                            <span className='patharrowicon' >
                                {mainData.map((value, index) => {
                                    if (value.header1 == content.to_cc) {
                                        return (
                                            <a href={'#' + locatioName + '/' + content.upgrade_zone_template_id + '_' + content.to_cc} className='anchoestyle'>
                                                <img src={icon_detailsview}></img>
                                            </a>
                                        )
                                    }
                                })}
                            </span>
                            <div className='upgradecontentdialog'>
                                <DialogBox open={open} handleClose={handleClose} handleYes={deleteSelectedRow}
                                    dialogMessage={message} title={title} action={deletetext} styles={"styles"}
                                    canceltext={canceltext}
                                >
                                </DialogBox>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return (
            <div></div>
        )

    }


}
export default UpgradePathAccordionContent;