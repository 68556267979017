import {
    FETCH_ALLOCATED_DATA_PENDING, FETCH_ALLOCATED_DATA_SUCCESS, FETCH_ALLOCATED_DATA_ERROR, SAVE_ALLOCATED_TRANSACTION_PENDING,
    SAVE_ALLOCATED_TRANSACTION_SUCCESS, SAVE_ALLOCATED_TRANSACTION_ERROR, UPLOAD_ALLOCATED_SUCCESS, UPLOAD_ALLOCATED_PENDING, UPLOAD_ALLOCATED_ERROR,
    SET_ALLOCATED_FILTER_PARAMS,FETCH_ALLOCATED_FILTER_DATA_PENDING,FETCH_ALLOCATED_FILTER_DATA_SUCCESS,FETCH_ALLOCATED_FILTER_DATA_ERROR,RESET_TO_ALLOCATED_INITIALSTATE
} from '../../store/allocated/Allocated.ActionTypes';
import filterColumnDefs from '../../data/allocatedColumns';

const initialState = {
    allocated: [],
    pending: false,
    error: null,
    transactionSaveSuccess: null,
    data:[],
    allocatedUploadSuccess: null,
}

function fetchDropDownValues(allocatedData, element) {
    var data = allocatedData.map(item => item[element.colDef])
        .filter((value, index, self) => self.indexOf(value) == index);
    if (element.colDef === "spi_code") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    }  else if (element.colDef === "pool") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "car_class_code") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "model_group") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "model_year") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef == "manufacturer_group") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label > b.label) { return -1; }
            if (a.label < b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    }
    return element;
}

function fetchFilterValues(filterColumnDefs, data) {
    var allocatedData = data;
    var filterData = filterColumnDefs().data;
    filterData.forEach(element => {
        if (element.type == "dropdown")
            fetchDropDownValues(allocatedData, element);
    });
    return filterData;
}

export default function allocatedReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALLOCATED_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_ALLOCATED_DATA_SUCCESS:
            return Object.assign({}, state, {
                allocated: action.data,
                pending: false,
                error: null,
                allocatedUploadSuccess: null
            });
        case FETCH_ALLOCATED_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SAVE_ALLOCATED_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_ALLOCATED_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                allocated: action.data,
                transactionSaveSuccess: true,
            });
        case SAVE_ALLOCATED_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case UPLOAD_ALLOCATED_SUCCESS:
            return Object.assign({}, state, {
                allocated: action.data,
                allocatedUploadSuccess: true,
                pending: false,
                error: null
            });
        case UPLOAD_ALLOCATED_PENDING:
            return Object.assign({}, state, {
                pending: true,
                allocatedUploadSuccess: null,
                error:null
            });
        case UPLOAD_ALLOCATED_ERROR:
            return Object.assign({}, state, {
                pending: false,
                allocatedUploadSuccess: false,
                error: action.error
            });

        case FETCH_ALLOCATED_FILTER_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_ALLOCATED_FILTER_DATA_SUCCESS:
            var actionData = fetchFilterValues(filterColumnDefs, action.data);
            return Object.assign({}, state, {
                data: actionData,
                pending: false,
                error: null
            });
        case FETCH_ALLOCATED_FILTER_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SET_ALLOCATED_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterParams: action.data
            });
        case RESET_TO_ALLOCATED_INITIALSTATE:
            return initialState;
        default:
            return state;
    }
}
