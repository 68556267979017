import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAdditionalCostData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseadditionalCostResponse = await client.apis.plans.getAllAdditionalCost({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });

        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const responsesArray = await Promise.all([promiseadditionalCostResponse])
            const [additionalCostResponse] = responsesArray.map(o => o.body.data);
            return ({
                "gridData": additionalCostResponse.rows, "rowcount": additionalCostResponse.rowCount
            })
        }
        else {
            //this else condition executes when it is older version
            //TODO XM-385 checkAndLoadDimention provides cached promise
            const zonesPromise = checkAndLoadDimention("zone", client);

            const responsesArray = await Promise.all([promiseadditionalCostResponse, zonesPromise]);

            const [additionalCostResponse, zones] = responsesArray.map(o => o.body.data);
            return ({
                "additionalCostData": additionalCostResponse, "zones": zones
            });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for AdditionalCosts tab'
    }
}

export  async function uploadAdditionalCostCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadAdditionalCost({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for AdditionalCosts tab, Please try again!!');
        //return {'Error':'uploadAdditionalCostCSV failed'}
    }
}

export async function saveAdditionalCostTransactions(planid, transactionList){

    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactadditionalcost({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchAdditionalCostDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

 //   const additionalCostResponseData =  client.apis.plans.getAllAdditionalCost({ "planid": planId ? planId : 'master' });
    const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseGetAdminManufacturerGroup =  checkAndLoadDimention("adminManufacturer", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
        //  additionalCostResponseData,
          promiseGetManufacturerGroup,
          promiseZoneResponse,
          promiseGetAdminManufacturerGroup
    ]);

    const [
         // additionalCostResponse,
          manufacturerGroupResponse,
          zones,
          adminManufacturerGroups,
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "additionalCostData":additionalCostResponse,
        "manufacturer_group": manufacturerGroupResponse,
        "zones": zones,
        "adminManufacturerGroup":adminManufacturerGroups,
        "users":promiseUserManagementEmailAndIdMap
    })  
}

export async function getAdditionalCostFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseAdditionalCostFilterResponse = await client.apis.plans.getAdditionalCostFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseAdditionalCostFilterResponse]);
        const [additionalCostResponse] = responsesArray.map(o => o.body.data);

        return({
            "gridData": additionalCostResponse.rows,"rowcount":additionalCostResponse.rows[0]?additionalCostResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'AdditionalCosts tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllAdditionalCostData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const additionalCostDeleteResponse = await client.apis.plans.deleteAllAdditionalCostRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });
        if(additionalCostDeleteResponse.obj && additionalCostDeleteResponse.obj.statusCode !== '500'){
            return additionalCostDeleteResponse;
        }
        else{
            throw Error();
        }  
    }
    catch (error) {
        console.error(error);
        throw 'AdditionalCosts tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}

export async function insertS3UploadAdditionalCostCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedAdditionalCostData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for AdditionalCosts tab, Please try again!!');
    }
}

export async function getAdditionalCostTabErrorStatus(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseAdditionalCostResponse = await client.apis.plans.getAdditionalCostTabErrorStatus({"planid":planid?planid:1});
    if(promiseAdditionalCostResponse.obj && promiseAdditionalCostResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseAdditionalCostResponse]);
    const [additionalCostTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return additionalCostTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the AdditionalCosts tab error status, Please try again!!';
   }
   }

   export async function additionalCostTabValidationService(planid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.plans.validateAdditionalCost({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate AdditionalCosts tab, Please try again!!'
    }
   }

   export async function getAdditionalCostErrorData(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAdditionalCostErrorResponse = await client.apis.plans.getAdditionalCostErrorRecords({"planid":planid?planid:1 ,'pageno':pageno});
        const responsesArray = await Promise.all([promiseAdditionalCostErrorResponse]);
        const [AdditionalCostResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": AdditionalCostResponse.rows, "rowcount": AdditionalCostResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch error data in AdditionalCosts tab, Please try again!!'
     }
}

export async function getAdditionalCostSearchData(planid, pageno, searchParams) {
    try {
            const accessToken = await getAccessTokenAfterAuth();
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
            const promiseAdditionalCostSearchResponse = await client.apis.plans.getAdditionalCostSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno},{requestBody: {'searchParams':searchParams }});
            const responsesArray = await Promise.all([promiseAdditionalCostSearchResponse]);
            const [additionalCostSearchResponse] = responsesArray.map(o => o.body.data);
            return ({
               "gridData": additionalCostSearchResponse.rows,"rowcount":additionalCostSearchResponse.rows[0]?additionalCostSearchResponse.rows[0].rowcount:0
            });   
    }
    catch (error) {
        console.error(error);
        throw 'Unable to search data in AdditionalCosts tab, Please try again!!'
    }
}