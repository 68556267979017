import {getAdminPoolData, savePoolTransactions, fetchPoolDropdownService} from '../../../services/admin/metadata_pool_management/PoolsManagementService';
import {FETCH_METADATA_POOL_DATA_PENDING, FETCH_METADATA_POOL_DATA_SUCCESS, FETCH_METADATA_POOL_DATA_ERROR, SAVE_METADATA_POOL_TRANSACTION_SUCCESS, SAVE_METADATA_POOL_TRANSACTION_ERROR,
    FETCH_METADATA_POOL_DROPDOWNVALUES_PENDING,FETCH_METADATA_POOL_DROPDOWNVALUES_SUCCESS,FETCH_METADATA_POOL_DROPDOWNVALUES_ERROR, RESET_TO_METADATA_POOL_INITIALSTATE, SAVE_METADATA_POOL_TRANSACTION_PENDING} from '../metadata_pool_management/PoolManagement.ActionTypes';


export const fetchAdminPoolData = () =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_METADATA_POOL_DATA_PENDING));    
            let response = await getAdminPoolData(); 
            if(response){
                dispatch(serviceActionSuccess(FETCH_METADATA_POOL_DATA_SUCCESS,response));
            }else{
                dispatch(serviceActionError(FETCH_METADATA_POOL_DATA_ERROR,"Unknown error"));
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_METADATA_POOL_DATA_ERROR,e.message));
        }
    }

}

export const savePoolTransactionsData = (transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_METADATA_POOL_TRANSACTION_PENDING));

            await savePoolTransactions(transactionList);

            dispatch(serviceActionPending(FETCH_METADATA_POOL_DROPDOWNVALUES_PENDING));

            let response = await getAdminPoolData();
            let poolsData = await fetchPoolDropdownService();
            if(response && poolsData){
                dispatch(serviceActionSuccess(FETCH_METADATA_POOL_DATA_SUCCESS,response));
                dispatch(serviceActionSuccess(SAVE_METADATA_POOL_TRANSACTION_SUCCESS,response));
                dispatch(serviceActionSuccess(FETCH_METADATA_POOL_DROPDOWNVALUES_SUCCESS, poolsData));
            }else{
                dispatch(serviceActionError(FETCH_METADATA_POOL_DATA_ERROR,"Unknown error"));
                dispatch(serviceActionError(FETCH_METADATA_POOL_DROPDOWNVALUES_ERROR, poolsData));
            }        
        } catch (e) {
            console.log("Exception",e);
            console.log("Exception",e.message);
            dispatch(serviceActionError(SAVE_METADATA_POOL_TRANSACTION_ERROR, e.message));

        }
    }
}
export const fetchPoolDropdownValues = () => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_METADATA_POOL_DROPDOWNVALUES_PENDING));
        let poolsDropdownData = await fetchPoolDropdownService();
        if (poolsDropdownData) {
            dispatch(serviceActionSuccess(FETCH_METADATA_POOL_DROPDOWNVALUES_SUCCESS, poolsDropdownData));
        } else {
            dispatch(serviceActionError(FETCH_METADATA_POOL_DROPDOWNVALUES_ERROR, poolsDropdownData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_METADATA_POOL_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetPool = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_METADATA_POOL_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}