export const FETCH_ALLPLANS_DATA_PENDING = 'FETCH_ALLPLANS_DATA_PENDING';
export const FETCH_ALLPLANS_DATA_SUCCESS = 'FETCH_ALLPLANS_DATA_SUCCESS';
export const FETCH_ALLPLANS_DATA_ERROR = 'FETCH_ALLPLANS_DATA_ERROR';
export const FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_PENDING = 'FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_PENDING';
export const FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS = 'FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS';
export const FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR = 'FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR';
export const GET_ALLPLANS_TABLE_DATA = 'GET_ALLPLANS_TABLE_DATA';
export const SAVE_DESCRIPTION_PENDING = 'SAVE_DESCRIPTION_PENDING';
export const SAVE_DESCRIPTION_SUCCESS = 'SAVE_DESCRIPTION_SUCCESS';
export const SAVE_DESCRIPTION_ERROR = 'SAVE_DESCRIPTION_ERROR';
export const FETCH_ZONERF_DATA_PENDING = 'FETCH_ZONERF_DATA_PENDING';
export const FETCH_ZONERF_DATA_SUCCESS = 'FETCH_ZONERF_DATA_SUCCESS';
export const FETCH_ZONERF_DATA_ERROR = 'FETCH_ZONERF_DATA_ERROR';
export const FETCH_POOLRF_DATA_PENDING = 'FETCH_POOLRF_DATA_PENDING';
export const FETCH_POOLRF_DATA_SUCCESS = 'FETCH_POOLRF_DATA_SUCCESS';
export const FETCH_POOLRF_DATA_ERROR = 'FETCH_POOLRF_DATA_ERROR';
export const FETCH_NEW_PLANID_PENDING = 'FETCH_NEW_PLANID_PENDING';
export const FETCH_NEW_PLANID_SUCCESS = 'FETCH_NEW_PLANID_SUCCESS';
export const FETCH_NEW_PLANID_ERROR = 'FETCH_NEW_PLANID_ERROR';
export const FETCH_PLANBYID_PENDING = 'FETCH_PLANBYID_PENDING';
export const FETCH_PLANBYID_SUCCESS = 'FETCH_PLANBYID_SUCCESS';
export const FETCH_PLANBYID_ERROR = 'FETCH_PLANBYID_ERROR';
export const FETCH_STARTRUN_PENDING = 'FETCH_STARTRUN_PENDING';
export const FETCH_STARTRUN_SUCCESS = 'FETCH_STARTRUN_SUCCESS';
export const FETCH_STARTRUN_ERROR = 'FETCH_STARTRUN_ERROR';
export const SAVE_ZONE_PENDING = 'SAVE_ZONE_PENDING';
export const SAVE_ZONE_SUCCESS = 'SAVE_ZONE_SUCCESS';
export const SAVE_ZONE_ERROR = 'SAVE_ZONE_ERROR';
export const SAVE_POOL_PENDING = 'SAVE_POOL_PENDING';
export const SAVE_POOL_SUCCESS = 'SAVE_POOL_SUCCESS';
export const SAVE_POOL_ERROR = 'SAVE_POOL_ERROR';
export const COPYPLAN_WITH_OUTPUT_PENDING = 'COPYPLAN_WITH_OUTPUT_PENDING';
export const COPYPLAN_WITH_OUTPUT_SUCCESS = 'COPYPLAN_WITH_OUTPUT_SUCCESS';
export const COPYPLAN_WITH_OUTPUT_ERROR = 'COPYPLAN_WITH_OUTPUT_ERROR';
export const FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_PENDING = 'FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_PENDING';
export const FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_SUCCESS = 'FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_SUCCESS';
export const FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_ERROR = 'FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_ERROR';
export const FETCH_STATUS_UPlOAD_FILE_INSERT = 'FETCH_STATUS_UPlOAD_FILE_INSERT';
export const FETCH_STATUS_UPlOAD_FILE_INSERT_DELETE = 'FETCH_STATUS_UPlOAD_FILE_INSERT_DELETE';
export const FETCH_INSERT_STATUS_UPlOAD_FILE_STATUS_CHECK = 'FETCH_INSERT_STATUS_UPlOAD_FILE_STATUS_CHECK';
export const SET_ZONERF_POOLRF = 'SET_ZONERF_POOLRF';
export const MARK_TABS_TO_BE_COPIED_TO_MAIN='MARK_TABS_TO_BE_COPIED_TO_MAIN';
export const COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS="COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS";
export const COPY_PLAN_TO_MAIN_INPUTS_SUCCESS="COPY_PLAN_TO_MAIN_INPUTS_SUCCESS";
export const COPY_PLAN_TO_MAIN_INPUTS_FAILURE="COPY_PLAN_TO_MAIN_INPUTS_FAILURE";
export const SET_STATUS_COPY_PLAN_TO_MAIN_INPUTS_PROGRESS='SET_STATUS_COPY_PLAN_TO_MAIN_INPUTS_PROGRESS';
export const FETCH_PLANBYID_ONREFRESH_PENDING = 'FETCH_PLANBYID_ONREFRESH_PENDING';
export const FETCH_PLANBYID_ONREFRESH_SUCCESS ='FETCH_PLANBYID_ONREFRESH_SUCCESS';
export const FETCH_PLANBYID_ONREFRESH_ERROR ='FETCH_PLANBYID_ONREFRESH_ERROR';
export const UPDATE_ADJUSTMENTS_EDIT_STATUS_PENDING = "UPDATE_ADJUSTMENTS_EDIT_STATUS_PENDING";
export const UPDATE_ADJUSTMENTS_EDIT_STATUS_SUCCESS = 'UPDATE_ADJUSTMENTS_EDIT_STATUS_SUCCESS';
export const UPDATE_ADJUSTMENTS_EDIT_STATUS_ERROR = 'UPDATE_ADJUSTMENTS_EDIT_STATUS_ERROR';
export const CHECK_IF_PLAN_EDIT_IS_DISABLED = 'CHECK_IF_PLAN_EDIT_IS_DISABLED';
export const GET_ROLES_IN_PROGRESS = 'GET_ROLES_IN_PROGRESS';
export const GET_ROLES_IN_PROGRESS_SUCCESS = 'GET_ROLES_IN_PROGRESS_SUCCESS';
export const GET_ROLES_IN_PROGRESS_FAILURE = 'GET_ROLES_IN_PROGRESS_FAILURE';
export const FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING='FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING';
export const FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS='FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS';
export const SET_GRIDNAME_IN_INSERT_PROGRESS_ARRAY='SET_GRIDNAME_IN_INSERT_PROGRESS_ARRAY'
export const DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY='DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY';
export const ADD_NOTIFICATION_MESSAGE='ADD_NOTIFICATION_MESSAGE';
export const REMOVE_NOTIFICATION_MESSAGE='REMOVE_NOTIFICATION_MESSAGE';
export const TOGGLE_MENUBAR_SIZE='TOGGLE_MENUBAR_SIZE';