const addMoveCostFields = () => {
    return ({
        "data": [
            {
                "colDef": "from_zone",
                "displayName": "From Zone",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "to_zone",
                "displayName": "To Zone",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "from_pool",
                "displayName": "From Pool",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "to_pool",
                "displayName": "To Pool",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "car_class",
                "displayName": "Car Class",
                "type": "dropdown",
                "options": [],

            },
            {
                "colDef": "car_group",
                "displayName": "Car Group",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "allowed",
                "displayName": "Allowed",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "bu",
                "displayName": "BU",
                "type": "dropdown",
                "options": [],
            },{
                "colDef": "move_cost",
                "displayName": "Move Cost",
                "type": "range",
                "options": [],
            },]
    });
}

export default addMoveCostFields;