import { combineReducers } from 'redux';
import parametersReducer from './Parameters.Reducer';
import {createNamedWrapperReducer} from '../util';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import parametersColumnDefReducer from './Parameters.ColumnDef.Reducer';

const rootParametersReducer = combineReducers({
    parametersReducer,
    parametersGridReducer: createNamedWrapperReducer(multiGridUIReducer, "parametersgrid"),
    parametersColumnDefReducer
})

export default rootParametersReducer;