export const FETCH_TEMPLATES_DATA_PENDING = 'FETCH_TEMPLATES_DATA_PENDING';
export const FETCH_TEMPLATES_DATA_SUCCESS = 'FETCH_TEMPLATES_DATA_SUCCESS';
export const FETCH_TEMPLATES_DATA_ERROR = 'FETCH_TEMPLATES_DATA_ERROR';
export const FILTER_SEARCH_TEMPLATE_DATA = 'FILTER_SEARCH_TEMPLATE_DATA';
export const FILTER_SEARCH_UPGRADEPATH_DATA = 'FILTER_SEARCH_UPGRADEPATH_DATA';
export const FETCH_UPGRADEPATH_DATA_PENDING = 'FETCH_UPGRADEPATH_DATA_PENDING';
export const FETCH_UPGRADEPATH_DATA_SUCCESS = 'FETCH_UPGRADEPATH_DATA_SUCCESS';
export const FETCH_UPGRADEPATH_DATA_ERROR = 'FETCH_UPGRADEPATH_DATA_ERROR';
export const GET_PATHS_GROUPBY_DATA = 'GET_PATHS_GROUPBY_DATA';
export const GET_SORTED_DATA = 'GET_SORTED_DATA';
export const SAVE_TEMPLATE_TRANSACTION_PENDING = 'SAVE_TEMPLATE_TRANSACTION_PENDING';
export const SAVE_TEMPLATE_TRANSACTION_SUCCESS = 'SAVE_TEMPLATE_TRANSACTION_SUCCESS';
export const SAVE_TEMPLATE_TRANSACTION_ERROR = 'SAVE_TEMPLATE_TRANSACTION_ERROR';
export const FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_PENDING = 'FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_PENDING';
export const FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_SUCCESS = 'FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_SUCCESS';
export const FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_ERROR = 'FETCH_CARCLASSGROUP_FOR_UPGRADEPATHS_DATA_ERROR';
export const PATH_SAVE_TRANSACTION_PENDING = 'PATH_SAVE_TRANSACTION_PENDING';
export const PATH_SAVE_TRANSACTION_SUCCESS = 'PATH_SAVE_TRANSACTION_SUCCESS';
export const PATH_SAVE_TRANSACTION_ERROR = 'PATH_SAVE_TRANSACTION_ERROR';
export const DELET_UPGRADEPATH_DATA_PENDING = 'DELET_UPGRADEPATH_DATA_PENDING';
export const DELET_UPGRADEPATH_DATA_SUCCESS = 'DELET_UPGRADEPATH_DATA_SUCCESS';
export const DELET_UPGRADEPATH_DATA_ERROR = 'DELET_UPGRADEPATH_DATA_ERROR';
export const SET_UPGRADEPATH_EDITABLE='SET_UPGRADEPATH_EDITABLE';
export const SET_TEMPLATES_EDITABLE='SET_TEMPLATES_EDITABLE';
export const SET_CARCLASSGROUP_EDITABLE='SET_CARCLASSGROUP_EDITABLE';
export const UPLOAD_UPGRADEPATH_PENDING='UPLOAD_UPGRADEPATH_PENDING';
export const UPLOAD_UPGRADEPATH_SUCCESS='UPLOAD_UPGRADEPATH_SUCCESS';
export const UPLOAD_UPGRADEPATH_ERROR='UPLOAD_UPGRADEPATH_ERROR';

