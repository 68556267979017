import { deleteProgram } from "../../services/programs/ProgramsService";

export const EDIT_ROW = 'EDIT_ROW';
export const EDIT_ROW_MULTIPLE = 'EDIT_ROW_MULTIPLE';
export const ADD_ROW = 'ADD_ROW';
export const COPY_ROW = 'COPY_ROW';
export const DELETE_ROW = 'DELETE_ROW';
export const DELETE_ROW_EDIT = 'DELETE_ROW_EDIT';
export const REVERT_ROW = 'REVERT_ROW';
export const POPULATE_GRID = 'POPULATE_GRID';
export const VALIDATE_ROW = 'VALIDATE_ROW';
export const REFRESH_GRID = 'REFRESH_GRID';
export const VALIDATE_GRID = 'VALIDATE_GRID';
export const CLEAN_TRASACTIONS = 'CLEAN_TRASACTIONS';
export const POPULATE_GRID2 = 'POPULATE_GRID2';
export const UPDATE_IS_ROW_INVALID = 'UPDATE_IS_ROW_INVALID';
export const RESET_MULTI_GRID_STATE = 'RESET_MULTI_GRID_STATE'

export const validateRow = (gridname, data, businessValidationFn)=>{
    return {
        name:gridname,
        type:VALIDATE_ROW,
        data:{
            data,
            businessValidationFn
        }
    }
}

export const validateGrid = (gridname, data, validationFn, businessValidationFn,validateTransactions)=>{
    return {
        name:gridname,
        type:VALIDATE_GRID,
        data:{
            data,
            validationFn,
            businessValidationFn,
            validateTransactions
        }
    }
}

export const populateGrid = (gridname, data, filterSearchKey, checkType, checkValue, checkIfRequiredFn)=>{
    return {
        name:gridname,
        type:POPULATE_GRID,
        data:data,
        filterSearchKey:filterSearchKey,
        checkType:checkType,
        checkValue:checkValue,
        checkIfRequiredFn:checkIfRequiredFn,
    }
}

export const populateGrid2 = (gridname, originalDta,currentData,filterSearchKey,checkValue,validationFn, businessValidationFn,uploadState)=>{
    return {
        name:gridname,
        type:POPULATE_GRID2,
        originalData:originalDta,
        currentData:currentData,
        filterSearchKey:filterSearchKey,
        checkValue:checkValue,
        data:{
            validationFn,
            businessValidationFn
        },
        uploadState:uploadState
        }
}

export const completeEditRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:EDIT_ROW,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}

export const completeEditMultipleRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:EDIT_ROW_MULTIPLE,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}

export const addNewRow = (gridname,rowindex,data)=>{
    return {
        name:gridname,
        type:ADD_ROW,
        data:{rowindex,data}
    }
}

export const copyNewRow = (gridname,rowindex,data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:COPY_ROW,
        data:{rowindex,data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}


export const deleteRow = (gridname,id)=>{
    return {
        name:gridname,
        type:DELETE_ROW,
        data:{id}
    }
}

export const deleteRowEditMode = (gridname,id)=>{
    return {
        name:gridname,
        type:DELETE_ROW_EDIT,
        data:{id}
    }
}

export const revertRow = (gridname,rowindex,rowID, data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:REVERT_ROW,
        data:{rowindex,rowID, data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}

export const refreshGridData = (gridName) => {
    return {
        name: gridName,
        type: REFRESH_GRID
    }
}

export const cleanTransactions = (gridName)=>{
    return {
        name: gridName,
        type: CLEAN_TRASACTIONS
    }
}

export const ResetMultiGridState = ()=>{
    return {
        type: RESET_MULTI_GRID_STATE
    }
}