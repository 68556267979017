import { combineReducers } from 'redux';
import constraintsReducer from './Constraints.Reducer';
import {createNamedWrapperReducer} from '../util';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import constraintsColumnDefReducer from './Constraints.ColumnDef.Reducer';

const rootConstraintsReducer = combineReducers({
    constraintsReducer,
    constraintsGridReducer: createNamedWrapperReducer(multiGridUIReducer, "constraintsgrid"),
    constraintsColumnDefReducer
})

export default rootConstraintsReducer;