export const FETCH_DEPRATE_DATA_PENDING = 'FETCH_DEPRATE_DATA_PENDING';
export const FETCH_DEPRATE_DATA_SUCCESS = 'FETCH_DEPRATE_DATA_SUCCESS';
export const FETCH_DEPRATE_DATA_ERROR = 'FETCH_DEPRATE_DATA_ERROR';
export const UPLOAD_DEPRATE_PENDING = 'UPLOAD_DEPRATE_PENDING';
export const UPLOAD_DEPRATE_SUCCESS = 'UPLOAD_DEPRATE_SUCCESS';
export const UPLOAD_DEPRATE_ERROR = 'UPLOAD_DEPRATE_ERROR';
export const SAVE_DEPRATE_TRANSACTION_PENDING = 'SAVE_DEPRATE_TRANSACTION_PENDING';
export const SAVE_DEPRATE_TRANSACTION_SUCCESS = 'SAVE_DEPRATE_TRANSACTION_SUCCESS';
export const SAVE_DEPRATE_TRANSACTION_ERROR = 'SAVE_DEPRATE_TRANSACTION_ERROR';
export const FETCH_DEPRATE_DROPDOWNVALUES_PENDING = 'FETCH_DEPRATE_DROPDOWNVALUES_PENDING';
export const FETCH_DEPRATE_DROPDOWNVALUES_SUCCESS = 'FETCH_DEPRATE_DROPDOWNVALUES_SUCCESS';
export const FETCH_DEPRATE_DROPDOWNVALUES_ERROR = 'FETCH_DEPRATE_DROPDOWNVALUES_ERROR';
export const RESET_TO_DEPRATE_INITIALSTATE = 'RESET_TO_DEPRATE_INITIALSTATE';
export const SET_DEPRATE_EDITABLE='SET_DEPRATE_EDITABLE';