import React from "react";
import AllCellsInAYearComponent from './AllCellsInAYearComponent';

const ReadOnlyRowComponent = (props) => {
    const{arrayObject, isExpanded,content, planStatus, filterParams, zonename, accordionContent,filterRecord,currentHeader}=props;
    function allDataFilteredByYear(year) {
        let filterByYear = arrayObject.filter((o) => o.current.year == year)
        return filterByYear;
    }
    
    function calculateNumberOfMonthInEachYear(arr) {
        const yearsInNumberOfPlanMonths = [];
        let remainingMonths = 0;
        let currentYear;
        return arr.map(element => {
            let yearsIndex = yearsInNumberOfPlanMonths.map(function (e) {
                return e.year;
            }).indexOf(element.current.year);
            if (element.current.cpd.year == element.current.year) {
                currentYear = element.current.cpd.year;
                if ((element.current.cpd.month + element.current.num_plan_months) > 12) {
                    remainingMonths = 12 - element.current.cpd.month + 1;
                } else {
                    remainingMonths = element.current.num_plan_months;
                }
                if (yearsIndex < 0) {
                    yearsInNumberOfPlanMonths.push({
                        "year": currentYear,
                        "startMonth": element.current.cpd.month,
                        "numOfMonthsInYear": remainingMonths,
                    })
                } else {
                    yearsInNumberOfPlanMonths[yearsIndex] = {
                        "year": currentYear,
                        "startMonth": element.current.cpd.month,
                        "numOfMonthsInYear": remainingMonths,
                    }
                }
            } else if (element.current.year > element.current.cpd.year) {
                if (element.current.cpd.month + element.current.num_plan_months > 12) {
                    let planMonths = element.current.num_plan_months - remainingMonths;
                    if (planMonths > 12) {
                        planMonths = 12;
                        remainingMonths = element.current.num_plan_months - planMonths - remainingMonths;
                    }
                    if (yearsIndex < 0) {
                        yearsInNumberOfPlanMonths.push({
                            "year": element.current.year,
                            "startMonth": 1,
                            "numOfMonthsInYear": planMonths,
                        });
                    } else {
                        yearsInNumberOfPlanMonths[yearsIndex] = {
                            "year": element.current.year,
                            "startMonth": 1,
                            "numOfMonthsInYear": remainingMonths,
                        }
                    }
                }
            }
            return yearsInNumberOfPlanMonths;
        });
    }

    let yearsInPlanMonths = calculateNumberOfMonthInEachYear(arrayObject);
    return yearsInPlanMonths[0].map((obj, ix) => {
        return <AllCellsInAYearComponent year={obj.year}
            startMonth={obj.startMonth}
            numPlanMonths={obj.numOfMonthsInYear}
            data={allDataFilteredByYear(obj.year)}
            isExpanded={isExpanded}
            planStatus = {planStatus}
            content={content}
            filterParams={filterParams}
            zonename={zonename}
            accordionContent={accordionContent}
            arrayObject={arrayObject}
            filterRecord={filterRecord}
            currentHeader={currentHeader}>
        </AllCellsInAYearComponent>
    });
}

export default ReadOnlyRowComponent;


   