import { FETCH_GRID_DATA_ERROR, FETCH_GRID_DATA_PENDING, FETCH_GRID_DATA_SUCCESS, RESET_TO_GRID_DATA_INITIALSTATE, SAVE_GRID_DATA_TRANSACTION_ERROR, SAVE_GRID_DATA_TRANSACTION_PENDING, SAVE_GRID_DATA_TRANSACTION_SUCCESS, SET_GRID_DATA_USER_PAGE_VALUE, SET_GRID_NAME, UPLOAD_GRID_DATA_ERROR, UPLOAD_GRID_DATA_PENDING, UPLOAD_GRID_DATA_SUCCESS,SET_TABS_VALIDATION_STATUS,
        SET_VALIDATE_GRIDDATA_PENDING,SET_VALIDATE_GRIDDATA_ERROR,SET_VALIDATE_GRIDDATA_SUCCESS, SET_GRID_FILTER_PARAMS_OPTIONS_ERROR, SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS, SET_GRID_FILTER_PARAMS_OPTIONS_PENDING, SET_GRID_DATA_FILTER_VIEW_STATUS, RESET_TABS_VALIDATION_STATUS, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_PENDING, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_SUCCESS, FETCH_ALL_DIMENSIONS_DATA_ERROR, SET_GRID_DATA_ERROR_VIEW_STATUS,
        SET_GRID_SEARCH_TEXT_SUCCESS, SET_GRID_DATA_SEARCH_VIEW_STATUS, FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_SUCCESS, 
        DELETE_ALL_GRID_DATA_ERROR,
        DELETE_ALL_GRID_DATA_SUCCESS,
        DELETE_ALL_GRID_DATA_PENDING } from "./PaginationGrid.ActionTypes";

const initialState = {
    pending:false,
    gridErrorMessage:null,
    gridTransactionSaveSuccess:null,
    gridName:'',
    gridData:[],   
    // gridErrorData:[],
    // gridFilterData:[],
    gridTotalRowCount:0,
    gridUserPageno:1,
    gridUploadStatus:null,
    gridErrorView:{},
    gridFilterView:{},
    gridSearchView:{},
    gridUserSearchParams:{},
    uploadGridSuccess:null,
    gridFilterUserSelectedParams:{},
    gridTabErrorStatus:{},
    gridTopLevelFilterOptions:[],
    callGridFetchApi:null,
    gridDeleteAllDataSuccess:null,
    gridChangesNotifierWithMessage:null // This state is used to trigger fetchGridApi based on viewstatus(filter,search,error) to get records
}

export default function PaginationGridReducer (state=initialState,action){
    switch(action.type){
        case FETCH_GRID_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                gridData:[],
                gridTransactionSaveSuccess:null,
                gridUploadStatus: null,
                gridDeleteAllDataSuccess:null,
                gridErrorMessage:null,
                callGridFetchApi:null
            });
        case FETCH_GRID_DATA_SUCCESS:
            return Object.assign({},state,{  
                gridData:action.data.gridData,
                gridTotalRowCount:action.data.rowcount,
                pending:false
            });
        case FETCH_GRID_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                gridTotalRowCount:0,//on fetching grid data error setting gridTotalRowCount as 0 , making pages to 1 as there is no grid data
                gridErrorMessage:action.error
            });
        case SAVE_GRID_DATA_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                gridTransactionSaveSuccess: null
            });
        case SAVE_GRID_DATA_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                gridTransactionSaveSuccess: true,
                // gridData:action.data.gridData,
                // gridTotalRowCount:action.data.rowcount,
                gridUserPageno:action.data,
                pending: false
            });
        case SAVE_GRID_DATA_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                gridErrorMessage: action.error,
                gridTransactionSaveSuccess: false
            });
        case DELETE_ALL_GRID_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                gridDeleteAllDataSuccess: null
            });
        case DELETE_ALL_GRID_DATA_SUCCESS:
            return Object.assign({}, state, {
                gridDeleteAllDataSuccess: true,
                gridUserPageno:action.data,
                pending: false
            });
        case DELETE_ALL_GRID_DATA_ERROR:
            return Object.assign({},state,{
                pending: false,
                gridErrorMessage: action.error,
                gridDeleteAllDataSuccess: false
            })
        case UPLOAD_GRID_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                gridUploadStatus: null
            });
        case UPLOAD_GRID_DATA_SUCCESS:
            return Object.assign({}, state, {
                //gridData:action.data.gridData,
                //gridTotalRowCount:action.data.rowcount,
                gridUserPageno:1, //on upload as the row data get shuffeled,manually making pageno as 1
                gridErrorMessage:null,
                gridUploadStatus: true,
                pending: false
            });
        case UPLOAD_GRID_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                gridErrorMessage: action.error,
                gridUploadStatus: false
            });
        case SET_GRID_NAME:
            return Object.assign({},state,{
                gridName:action.data,
                gridUploadStatus: null,
                gridErrorMessage:null,
                callGridFetchApi: true
            });
        case SET_GRID_DATA_USER_PAGE_VALUE:
            return Object.assign({},state,{
                gridUserPageno:action.data
            });
        case SET_TABS_VALIDATION_STATUS:
            return Object.assign({},state,{
                gridTabErrorStatus:{...state.gridTabErrorStatus,...action.data},
            });
        case RESET_TABS_VALIDATION_STATUS:
            return Object.assign({},state,{
                gridTabErrorStatus:{}
            });
        case SET_VALIDATE_GRIDDATA_SUCCESS:
            return Object.assign({},state,{
                pending:false
            })
        case SET_VALIDATE_GRIDDATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case SET_VALIDATE_GRIDDATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                gridErrorMessage: action.error
            });
        case SET_GRID_FILTER_PARAMS_OPTIONS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                gridErrorMessage: null
            });
        case SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                gridUserPageno:1,
                gridFilterUserSelectedParams:{...state.gridFilterUserSelectedParams,...action.data},
                gridErrorMessage: null
            });
        case SET_GRID_FILTER_PARAMS_OPTIONS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                gridErrorMessage: action.error
            });
        case SET_GRID_DATA_FILTER_VIEW_STATUS:
            return Object.assign({}, state, {
                gridFilterView:{...state.gridFilterView,...action.data},
                callGridFetchApi: true
            });
        case SET_GRID_DATA_ERROR_VIEW_STATUS:
            return Object.assign({}, state, {
                gridErrorView:{...state.gridErrorView,...action.data},
                callGridFetchApi: true
            });
        case FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_SUCCESS:
            return Object.assign({},state,{
                gridTopLevelFilterOptions:action.data,
            });
        case RESET_TO_GRID_DATA_INITIALSTATE:
            if(action.data){
                return initialState;
            } else {
                return  Object.assign({}, state, {
                    initialState:{
                                    ...initialState,
                                    'gridFilterUserSelectedParams':state.gridFilterUserSelectedParams,
                                    'gridFilterView':state.gridFilterView,
                                    'gridSearchView':state.gridSearchView,
                                    'gridUserSearchParams':state.gridUserSearchParams,
                                    'gridErrorView':state.gridErrorView,
                                }
                });
            }
        case FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_PENDING:
            return Object.assign({},state,{
                pending:true
            })
        case FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_SUCCESS:
            return Object.assign({},state,{
                pending:false
            })
        case FETCH_ALL_DIMENSIONS_DATA_ERROR:
            return Object.assign({},state,{
                pending: false,
                gridErrorMessage: action.error
            })
        case SET_GRID_SEARCH_TEXT_SUCCESS:
            return Object.assign({}, state, {
                gridUserSearchParams:{...state.gridUserSearchParams,...action.data},
                gridErrorMessage: null
            });
        case SET_GRID_DATA_SEARCH_VIEW_STATUS:
            return Object.assign({}, state, {
                gridUserPageno:1,
                gridSearchView:{...state.gridSearchView,...action.data},
                callGridFetchApi: true
            });
        default:
            return state
    }
}