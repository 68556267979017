import React, { useState } from "react";
import { useSelector } from "react-redux";
import { checkIfFreezMonth } from "../../../utils/AdjustmentsUtils";
import EditableRowComponent from "./EditableRowComponent";

const EditableSectionRowsComponent = (props) => {
    let freezeCpd  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.freezeCpd;
    });
    const { accordionContent,isExpanded,adjDisable, planid,selectedVal, handleGridSaveBehavior, content, planStatus, filterParams, zonename,filterRecord,isOfTypeEndFleet,currentHeader} = props;
    if (accordionContent) {
        var arrayObject = accordionContent.currentValues.map((obj, ix) => {
            return{
                "activity_type": obj.activity_type,
                "year": obj.year,
                "current": obj,
                "adjustment": accordionContent.adjustments[ix],
                "original": accordionContent.originalValues[ix],
                "fnlVal": accordionContent.fnlValues?accordionContent.fnlValues[ix]:null,
                "adjVar": accordionContent.adjVarValues?accordionContent.adjVarValues[ix]:null,
                "isFreezMonth":checkIfFreezMonth(obj.year,obj.month,freezeCpd)
            }
        });
        return <EditableRowComponent arrayObject={arrayObject} adjDisable={adjDisable} selectedVal={selectedVal} isExpanded={isExpanded} planid={planid} handleGridSaveBehavior={handleGridSaveBehavior} content={content} planStatus={planStatus} 
        filterParams={filterParams} accordionContent={accordionContent} zonename={zonename} filterRecord={filterRecord} isOfTypeEndFleet={isOfTypeEndFleet} currentHeader={currentHeader}></EditableRowComponent>
    } else {
        return (
            <div></div>
        )
    }
};
export default EditableSectionRowsComponent