import {FETCH_SALVAGERATE_DATA_PENDING, FETCH_SALVAGERATE_DATA_SUCCESS, FETCH_SALVAGERATE_DATA_ERROR, UPLOAD_SALVAGERATE_PENDING, UPLOAD_SALVAGERATE_SUCCESS, UPLOAD_SALVAGERATE_ERROR,
    SAVE_SALVAGERATE_TRANSACTION_PENDING, SAVE_SALVAGERATE_TRANSACTION_SUCCESS, SAVE_SALVAGERATE_TRANSACTION_ERROR,RESET_TO_SALVAGERATE_INITIALSTATE
    } from '../../store/salvagerate/SalvageRate.ActionTypes';

const initialState = {
    salvageRateData:[],
    pending:false,
    error:null,
    uploadSalvageRateSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(salvageRateData, zones) {
    salvageRateData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
        if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
            value['ZONE_NAME'] = "";
        }
    })
    return salvageRateData;
}

export default function SalvageRateReducer (state=initialState,action){
    switch(action.type){
        case FETCH_SALVAGERATE_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_SALVAGERATE_DATA_SUCCESS:
            return Object.assign({},state,{
                salvageRateData: fetchZones(action.data.salvageRateData, action.data.zones),
                uploadSalvageRateSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_SALVAGERATE_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_SALVAGERATE_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadSalvageRateSuccess: null
            });
        case UPLOAD_SALVAGERATE_SUCCESS:
            return Object.assign({}, state, {
                salvageRateData: fetchZones(action.data.salvageRateData, action.data.zones),
                pending: false,
                error: null,
                uploadSalvageRateSuccess: true
            });
        case UPLOAD_SALVAGERATE_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadSalvageRateSuccess: false
            });
        case SAVE_SALVAGERATE_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_SALVAGERATE_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                salvageRateData: fetchZones(action.data.salvageRateData, action.data.zones),
                transactionSaveSuccess: true
            });
        case SAVE_SALVAGERATE_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_SALVAGERATE_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}