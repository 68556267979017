import React from 'react';
import './InputFilter.css';
import { NttLabel } from '../../../../core-components/Label/NttLabel';
import { TextFieldInput } from '../../../../core-components/Input/Input';


const InputFilter = (props) => {
    const { selectedValue, displayName, theme, onTextChanged } = props;
    return (<span className="inputFields"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel>
        <TextFieldInput
            type="text"
            id="BasicOutline"
            value={(selectedValue === undefined) ? "" : selectedValue}
            variant="outlined"
            defaultValue={(selectedValue === undefined) ? "" : selectedValue}
            onTextChanged={(e) => onTextChanged(e)}
        /></span>);
}

export default InputFilter;