import React, { useState,useEffect,useRef,forwardRef,useImperativeHandle } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import './ToggleButtonComponent.css'
import { useSelector } from 'react-redux';


export default forwardRef((props, ref) => {
  const inputRef = useRef();
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(props.value);
  const [disableToggle,setDisableToggle] = useState(true);
  
  const onButtonClick = (selectedvalue) => {
    if (selectedvalue == 'Hot') {
      setSelected('Cold')
    } else if (selectedvalue == 'Cold') {
      setSelected('Hot')
    } else if (selectedvalue == '' || selectedvalue== null) {
      setSelected('Hot')
    }
  }
  // If plan is running editing should be disabled
  let ifPlanEditDisabled = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
  });
  function onChangeHandler(e, value) {
      setValue(value);
  }

  function onInputChangeHandler(e, inputValue) {
      setInputValue(inputValue);
  }

  useImperativeHandle(ref, () => {
      return {
          getValue: () => {
              return selected;
          },
          afterGuiAttached: () => {
              setValue(props.value)
          }
      };
  });

  return (
    <ToggleButton
      onChange={() => onButtonClick(selected)}
      disabled = {ifPlanEditDisabled} 
      ref={inputRef}
      className={selected == 'Hot' ? 'hotstyle' : selected == 'Cold' ? 'coldstyle' : 'emptyvaluestyle'}
    >
      {selected}
    </ToggleButton>
  );
})