import { SET_RF_ERROR_VALUE, SET_RF_INDEX_VALUE, SET_RF_PENDING_VALUE } from "./RevenueFunction.ActionTypes";

const initialState = {
    indexvalue: 0,
    pending: false,
    error: null
}

export default function RevenueFunctionReducer(state = initialState, action) {
    switch (action.type) {

        case SET_RF_INDEX_VALUE:
            return Object.assign({}, state, {
                indexvalue: action.data
            });
        case SET_RF_PENDING_VALUE:
            return Object.assign({}, state, {
                pending: action.data,
                error: null
            });
        case SET_RF_ERROR_VALUE:
            return Object.assign({}, state, {
                pending: false,
                error: action.data
            });
        default:
            return state;
    }
}
