import { DriveEta } from "@material-ui/icons";
import React, { useState } from "react";
import './AllBUAccordionContent.css';


const AllBUAccordionTitle = (props) => {
    const { data, planid, planStatus} = props;
    if (data != undefined && data.length > 0) {
        let data1 = [... data]
        let isAdjComplete = (planStatus && planStatus.toLowerCase() == 'adjustment complete')
        if(isAdjComplete){
            data1.splice(1,0,'Adjustment Variance');
        }
        else {
            data1.splice(data1.indexOf('Original Value'), 1);
        }
        return (
            <div className="allBuTitle">
                {data1.map((content) => {
                    return (
                        <div className="buContent">
                            {content}
                        </div>
                    )
                })
                }
            </div>
        )
    } else {
        return (
            <div></div>
        )

    }
}
export default AllBUAccordionTitle;