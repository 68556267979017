import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../../../utils/utils';

export async function getCoreRevenueData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
     const promisecoreRevenueResponse=  client.apis.revenuefunction.getAllRFCoreRevenue({ "rfid": rfid,'pageno':pageno });
     const responsesArray = await Promise.all([promisecoreRevenueResponse]);
     const [coreRevenueResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": coreRevenueResponse.rows,"rowcount":coreRevenueResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreRevenueData failed'}
   }
   }
export async function getCoreRevenueFilterData(rfid,pageno,filterparams){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncRevenueFilterResponse = await client.apis.revenuefunction.getRFTncRevenueFilterRecords({'pageno':pageno},{requestBody:{'rfid':rfid,'filterparams':filterparams}});
    const responsesArray = await Promise.all([promisetncRevenueFilterResponse]);
    const [tncRevenueResponse] = responsesArray.map(o => o.body.data);
    //return tncRevenueResponse.obj.data;
    return ({
        "gridData": tncRevenueResponse.rows,"rowcount":tncRevenueResponse.rows[0]?tncRevenueResponse.rows[0].rowcount:0
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncRevenueFilterData failed'}
   }
}
export async function getCoreRevenueDropdownService(){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseZoneResponse =  checkAndLoadDimention("zone", client);
        const promisePoolResponse =  checkAndLoadDimention("pool", client);
        const promiseCarClassCodeResponse =  checkAndLoadDimention("carClassCode", client);
        const responsesArray = await Promise.all([
                promiseZoneResponse,
                promiseCarClassCodeResponse, 
                promisePoolResponse,
        ]);

        const [
                zoneResponse,
                carClassCodeResponse,
                poolResponse 
        ] = responsesArray.map(o => o.body.data);
        return ({
            "zones":zoneResponse,
            "carClasses": carClassCodeResponse,
            "pools": poolResponse,
        })  
    }catch(error){

    }
}
