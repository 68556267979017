import {
    fetchAllPlans, deletePlanRow, callSaveDescription, fetchZoneRfData,
    fetchPoolRfData, callSaveZoneRf, callSavePoolRf, AddNewPlanService, CallGetPlanByIdService,
    startPlanRunService, CallCopyPlanWithOutput, fetchAllPlansWithDimensions, CallCopyPlanToMainInputs,updateAdjustmentsEditStatus, getRoleInfo, getCopyPlanToMainInputsStatus,getCreatePlanStatus, fetchLastUpdatedCacheTime
} from '../../services/topbar/AllPlansService';
import {
    FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_PENDING, FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS, FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR,
    FETCH_ALLPLANS_DATA_PENDING, FETCH_ALLPLANS_DATA_SUCCESS, FETCH_ALLPLANS_DATA_ERROR,
    GET_ALLPLANS_TABLE_DATA, SAVE_DESCRIPTION_PENDING, SAVE_DESCRIPTION_SUCCESS, SAVE_DESCRIPTION_ERROR,
    FETCH_ZONERF_DATA_PENDING, FETCH_ZONERF_DATA_SUCCESS, FETCH_ZONERF_DATA_ERROR, FETCH_POOLRF_DATA_PENDING,
    FETCH_POOLRF_DATA_SUCCESS, FETCH_POOLRF_DATA_ERROR, FETCH_NEW_PLANID_PENDING, FETCH_NEW_PLANID_SUCCESS, FETCH_NEW_PLANID_ERROR,
    FETCH_PLANBYID_PENDING, FETCH_PLANBYID_SUCCESS, FETCH_PLANBYID_ERROR, FETCH_STARTRUN_PENDING, FETCH_STARTRUN_SUCCESS,
    FETCH_STARTRUN_ERROR, SAVE_ZONE_PENDING, SAVE_ZONE_SUCCESS, SAVE_ZONE_ERROR, SAVE_POOL_PENDING, SAVE_POOL_SUCCESS, SAVE_POOL_ERROR,
    COPYPLAN_WITH_OUTPUT_PENDING,COPYPLAN_WITH_OUTPUT_SUCCESS,COPYPLAN_WITH_OUTPUT_ERROR,FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_PENDING,FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_SUCCESS,FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_ERROR, FETCH_STATUS_UPlOAD_FILE_INSERT, FETCH_STATUS_UPlOAD_FILE_INSERT_DELETE, FETCH_INSERT_STATUS_UPlOAD_FILE_STATUS_CHECK,
    SET_ZONERF_POOLRF,
    MARK_TABS_TO_BE_COPIED_TO_MAIN,
    COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS,
    COPY_PLAN_TO_MAIN_INPUTS_FAILURE,
    COPY_PLAN_TO_MAIN_INPUTS_SUCCESS,
    SET_STATUS_COPY_PLAN_TO_MAIN_INPUTS_PROGRESS,
    FETCH_PLANBYID_ONREFRESH_PENDING,FETCH_PLANBYID_ONREFRESH_SUCCESS,FETCH_PLANBYID_ONREFRESH_ERROR,
    UPDATE_ADJUSTMENTS_EDIT_STATUS_PENDING,UPDATE_ADJUSTMENTS_EDIT_STATUS_SUCCESS,UPDATE_ADJUSTMENTS_EDIT_STATUS_ERROR, CHECK_IF_PLAN_EDIT_IS_DISABLED, GET_ROLES_IN_PROGRESS, GET_ROLES_IN_PROGRESS_SUCCESS, GET_ROLES_IN_PROGRESS_FAILURE, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS, SET_GRIDNAME_IN_INSERT_PROGRESS_ARRAY,
    ADD_NOTIFICATION_MESSAGE,REMOVE_NOTIFICATION_MESSAGE, TOGGLE_MENUBAR_SIZE
} from './AllPlans.ActionTypes';

import { v4 } from "uuid";
import {fetchPlanOptimizationOutput} from "../../services/output/Service.output";
import { FetchUploadInsertStatus } from '../../services/uploadinsertstatus/insertstatus';
import { SET_PROGRAM_EDITABLE } from '../programs/Programs.ActionTypes';
import { SET_SCHEDULES_EDITABLE } from '../schedules/Schedules.ActionTypes';
import { SET_ALLOCATED_EDITABLE } from '../allocated/Allocated.ActionTypes';
import { SET_CONSTRAINT_EDITABLE } from '../constraints/Constraints.ActionTypes';
import { SET_MOVE_COST_EDITABLE } from '../movecost/MoveCost.ActionTypes';
import { SET_CARCLASSGROUP_EDITABLE, SET_TEMPLATES_EDITABLE } from '../upgradepaths/UpgradeTemplates.ActionTypes';
import { SET_AGEMILEAGE_EDITABLE } from '../agemileage/AgeMileage.ActionTypes';
import { SET_ADDITIONALCOST_EDITABLE } from '../additionalcost/AdditionalCost.ActionTypes';
import { SET_ADDSINVALIDPOOLS_EDITABLE } from '../addsinvalidpools/AddsInvalidPools.ActionTypes';
import { SET_MAXDELETES_EDITABLE } from '../maxdeletes/MaxDeletes.ActionTypes';
import { SET_MILEAGEDEFAULT_EDITABLE } from '../mileagedefault/MileageDefault.ActionTypes';
import { SET_SALVAGERATE_EDITABLE } from '../salvagerate/SalvageRate.ActionTypes';
import { SET_TARGETZONEPOOL_EDITABLE } from '../targetzonepool/TargetZonePool.ActionTypes';
import { SET_DEPRATE_EDITABLE } from '../deprate/DepRate.ActionTypes';
import { SET_MILEAGETABLE_EDITABLE } from '../mileagetable/MileageTable.ActionTypes';
import { SET_DIVERSIONS_EDITABLE } from '../diversions/Diversions.ActionTypes';
import { SET_PARAMETERS_EDITABLE } from '../parameters/Parameters.ActionTypes';
import { ADDITIONAL_COST, ADDS_INVALID_POOLS, AGE_MILEAGE, ALLOCATED, CONSTRAINT, DEP_RATE, DIVERSIONS, MAX_DEL_ZONE, MILEAGE_DEFAULT, MILEAGE_TABLE, MOVE_CONSTRAINT, MOVE_COST, PARAMETERS, pollTimeoutInterval, PROGRAM, SALVAGE_RATE, SCHEDULE, TARGET_ZONE, UPGRADE_PATH } from '../../Constants';

export const fetchAllPlansData = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_PENDING));
            let allplansData = await fetchAllPlansWithDimensions();
            if (allplansData) {
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS, allplansData));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
            }
        } catch (e) {
            console.log(`Failed to fetch All Plans Data because of Error : ${e.message}`);
            dispatch(serviceActionError(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR, "Unknown error"));
        }
    }
}

export const UpdateMasterDataCache = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING));
            let updatedLogsData = await fetchLastUpdatedCacheTime(sessionStorage.getItem('LASTCACHEUPDATEDAT'));
            let isMasterDataUpdated=false
            if (updatedLogsData && updatedLogsData.length>0) {
                isMasterDataUpdated=true
                updatedLogsData.map((val)=>{
                    if(val.table_name==='car_class_group'){
                        sessionStorage.removeItem('CARCLASSCODE')
                        sessionStorage.removeItem('ALLCARGROUPCARCLASS')
                        sessionStorage.removeItem('CARGROUP')
                        sessionStorage.removeItem('CARCLASSANDGROUP')
                        sessionStorage.removeItem('ALLCARGROUPCARCLASS_CLEAN_MAP')
                        sessionStorage.removeItem('ADMINCARCLASSANDGROUP')
                        sessionStorage.removeItem('ADMINCARGROUP')
                    } else if(val.table_name==='model_mfr_oem'){
                        sessionStorage.removeItem('MODELGROUP')
                        sessionStorage.removeItem('MANUFACTURER')
                        sessionStorage.removeItem('ALLMANUFACTURERGROUPMODELGROUP')
                        sessionStorage.removeItem('ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP')
                        sessionStorage.removeItem('ADMINMODELGROUP')
                        sessionStorage.removeItem('ADMINMANUFACTURER')
                    } else if(val.table_name==='pools'){
                        sessionStorage.removeItem('POOL')
                        sessionStorage.removeItem('ADMINPOOL')
                    } else if(val.table_name==='acceptablezones'){
                        sessionStorage.removeItem('ACCEPTABLEZONES');
                    } 
                })
                sessionStorage.removeItem('LASTCACHEUPDATEDAT')
            }
                let allplansData = await fetchAllPlansWithDimensions(true);
                if(allplansData){
                     dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,isMasterDataUpdated));
                }
        } catch (e) {
            console.log(e)
            dispatch(serviceActionError(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR, "Unknown error"));
        }
    }
}
export const deletePlan = (planid) => {
    return async dispatch => {
        let res = await deletePlanRow(planid);
        if (res == 1) {
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans()
            if (allPlansData) {
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
            }
            else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        } else {
            dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, res.response));
        }
    }
}

export const saveDescription = (planid, description) => {
    return async dispatch => {
        dispatch(serviceActionPending(SAVE_DESCRIPTION_PENDING));
        let resp = await callSaveDescription(planid, description);
        if (resp.length == 0) {
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans()
            if (allPlansData) {
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                dispatch(serviceActionSuccess(SAVE_DESCRIPTION_SUCCESS, 'success'));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                dispatch(getPlanBySelectedPlanId(planid));
            }
            else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        }
        else {
            dispatch(serviceActionError(SAVE_DESCRIPTION_ERROR, resp));
        }
    }

}

export const saveZoneRf = (planid, name) => {
    return async dispatch => {
        dispatch(serviceActionPending(SAVE_ZONE_PENDING));
        let resp = await callSaveZoneRf(planid, name);
        if (resp == 'Update success' || resp == 'Create success') {
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans()
            if (allPlansData) {
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                dispatch(serviceActionSuccess(SAVE_ZONE_SUCCESS, 'success'));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                dispatch(getPlanBySelectedPlanId(planid));
            }
            else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        } else {
            dispatch(serviceActionError(SAVE_ZONE_ERROR, resp));
        }
    }

}

export const savePoolRf = (planid, name) => {
    return async dispatch => {
        dispatch(serviceActionPending(SAVE_POOL_PENDING));
        let resp = await callSavePoolRf(planid, name);
        if (resp == 'Update success' || resp == 'Create success') {
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans()
            if (allPlansData) {
                dispatch(serviceActionSuccess(SAVE_POOL_SUCCESS, 'success'));
                dispatch(serviceActionSuccess(SAVE_DESCRIPTION_SUCCESS, 'success'));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                dispatch(getPlanBySelectedPlanId(planid));
            }
            else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        } else {
            dispatch(serviceActionError(SAVE_POOL_ERROR, resp));
        }
    }

}

export const getZoneRfData = (planid) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_ZONERF_DATA_PENDING));
        let zonerfdata = await fetchZoneRfData(planid);
        if (zonerfdata) {
            dispatch(serviceActionSuccess(FETCH_ZONERF_DATA_SUCCESS, zonerfdata));
        }
        else {
            dispatch(serviceActionError(FETCH_ZONERF_DATA_ERROR, zonerfdata));
        }
    }
}

export const getPoolRfData = (planid) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_POOLRF_DATA_PENDING));
        let poolrfData = await fetchPoolRfData(planid);
        if (poolrfData) {
            dispatch(serviceActionSuccess(FETCH_POOLRF_DATA_SUCCESS, poolrfData));
        }
        else {
            dispatch(serviceActionError(FETCH_POOLRF_DATA_ERROR, poolrfData));
        }
    }
}

export const activateCreatePlan = (planid)=>{
    return async dispatch =>{
        try{
            dispatch(serviceActionSuccess(FETCH_NEW_PLANID_PENDING,{jobId:0}));
            let createResponse=await AddNewPlanService(planid);
            dispatch(serviceActionSuccess(FETCH_NEW_PLANID_PENDING,{jobId:createResponse.jobId}));
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_NEW_PLANID_ERROR,e.message));
        }
    }
}

export const updateCreatePlanStatus = (jobId) =>{
    return async dispatch=>{
        try{
        let response=await getCreatePlanStatus(jobId);
        if(response.status==='SUCCESS'){
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans();
            if (allPlansData) {
                let newPlanid = JSON.parse(response.message).planid;
                dispatch(serviceActionSuccess(FETCH_NEW_PLANID_SUCCESS,{'status':response.status,'planid':newPlanid}));
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                dispatch(getPlanBySelectedPlanId(newPlanid));
            }else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        }else{
            //console.log("else part response.status",response);
        }
      }
        catch(e){
            console.log("Exception",e.message);
            dispatch(serviceActionError(FETCH_NEW_PLANID_ERROR,'Error occured while creating a new plan'));
        }
    }
}

export const AddNewPlanAction = (planid) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_NEW_PLANID_PENDING));
        let newPlanId = await AddNewPlanService(planid);
        if (newPlanId) {
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans()
            if (allPlansData) {
                dispatch(serviceActionSuccess(FETCH_NEW_PLANID_SUCCESS, newPlanId));
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                dispatch(getPlanBySelectedPlanId(newPlanId));
            }
            else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        }
        else {
            dispatch(serviceActionError(FETCH_NEW_PLANID_ERROR, newPlanId));
        }
    }
}

export const getPlanBySelectedPlanId = (planid) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_PLANBYID_PENDING));
        let planByIdResponse = await CallGetPlanByIdService(planid);
        if (planByIdResponse) {
            dispatch(serviceActionSuccess(FETCH_PLANBYID_SUCCESS, planByIdResponse));
            dispatch(serviceActionSuccess(SET_ZONERF_POOLRF, planByIdResponse));
        } else {
            dispatch(serviceActionSuccess(FETCH_PLANBYID_ERROR, planByIdResponse));
        }
    }
}
//Check the status of a plan 
export const updatePlanEditDisabled = (value) => {
    return async dispatch =>{
        dispatch(serviceActionSuccess(CHECK_IF_PLAN_EDIT_IS_DISABLED, value));

    }
}
export const startPlanRun = (planid) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_STARTRUN_PENDING));
        let startRunResponse = await startPlanRunService(planid);
        if (startRunResponse == 'Success') {
            dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
            let allPlansData = await fetchAllPlans()
            if (allPlansData) {
                dispatch(serviceActionSuccess(FETCH_STARTRUN_SUCCESS, startRunResponse));
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                dispatch(gatAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                dispatch(getPlanBySelectedPlanId(planid));
            }
            else {
                dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
            }
        } else {
            dispatch(serviceActionSuccess(FETCH_STARTRUN_ERROR, startRunResponse));
        }
    }
}

export const copyPlanWithOutput = (copiedFrom)=>{
    return async dispatch => {
        try{
        dispatch(serviceActionPending(COPYPLAN_WITH_OUTPUT_PENDING));
        let data = await CallCopyPlanWithOutput(copiedFrom);
        if (data) {
            dispatch(serviceActionPending(FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_PENDING));
            let allPlansData = await fetchAllPlans();
            if(allPlansData){
                dispatch(serviceActionSuccess(COPYPLAN_WITH_OUTPUT_SUCCESS, data));
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_SUCCESS, allPlansData));
            } else{
                dispatch(serviceActionError(FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_ERROR, "Unknown error"))
            }    
        } 
       } catch(e) {
            console.log(`Failed to copy Plan With Output because of Error : ${e.message}`);
            dispatch(serviceActionSuccess(COPYPLAN_WITH_OUTPUT_ERROR, "Unknown error"));
        }
    }    
}

export const SetUploadFileInsert = (planid,filename) =>{
    return async dispatch =>{
        try{ 
            let data = await FetchUploadInsertStatus(planid,filename);
            if(data.StatusData!=undefined){
            dispatch(serviceActionSuccess(FETCH_STATUS_UPlOAD_FILE_INSERT, data));
            }else{
                let data={'StatusData':{'plan_id':planid?planid:1,'filename':filename}}
                dispatch(serviceActionSuccess(FETCH_STATUS_UPlOAD_FILE_INSERT, data));
            }
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const setinsertStatusCheckFlag = (data) =>{
    return async dispatch =>{
        try{
           dispatch(serviceActionSuccess(FETCH_INSERT_STATUS_UPlOAD_FILE_STATUS_CHECK, data));
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const RemoveuploadedFileInsertStatus = (data) =>{
    return async dispatch =>{
        try{
           dispatch(serviceActionSuccess(FETCH_STATUS_UPlOAD_FILE_INSERT_DELETE, data));
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const loadPlanDataById = (planid) =>{
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_PLANBYID_ONREFRESH_PENDING));
        let planByIdResponse = await CallGetPlanByIdService(planid);
        if (planByIdResponse) {
            dispatch(serviceActionSuccess(FETCH_PLANBYID_ONREFRESH_SUCCESS, planByIdResponse));
        } else {
            dispatch(serviceActionSuccess(FETCH_PLANBYID_ONREFRESH_ERROR, planByIdResponse));
        }
    }
}

export const AddGridNameToInsertProgress = (gridName) =>{
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GRIDNAME_IN_INSERT_PROGRESS_ARRAY, gridName));
    }
}

export const setEditAdjustmentsStatus = (planid,planStatus)=>{
    return async dispatch =>{
        try{
          dispatch(serviceActionPending(UPDATE_ADJUSTMENTS_EDIT_STATUS_PENDING));
          let response = await updateAdjustmentsEditStatus(planid,planStatus);
          if(response){
            let planByIdResponse = await CallGetPlanByIdService(planid);
            if (planByIdResponse) {
                dispatch(serviceActionSuccess(FETCH_PLANBYID_ONREFRESH_SUCCESS, planByIdResponse));
                let allPlansData = await fetchAllPlans()
                if (allPlansData) {
                    dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                }
            }
              dispatch(serviceActionSuccess(UPDATE_ADJUSTMENTS_EDIT_STATUS_SUCCESS));
          }
        }
        catch(_){
            dispatch(serviceActionError(UPDATE_ADJUSTMENTS_EDIT_STATUS_ERROR,'Unknown Error'));
        }
    }
}

export const addNotificationMessage=(severity,message)=>{
    return async dispatch=>{
        let messageNotification={id:v4(),severity:severity,message:message}
        dispatch(serviceActionSuccess(ADD_NOTIFICATION_MESSAGE, messageNotification));
    }
}
export const removeNotificationMessage=(id)=>{
    return async dispatch=>{
        dispatch(serviceActionSuccess(REMOVE_NOTIFICATION_MESSAGE, id));
    }
}
function gatAllPlansTableData(type) {
    return {
        type: type
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, allplans) {
    return {
        type: type,
        data: allplans
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}

export const markTabsToBeCopiedToMain=(tabsToBeCopiedToMain)=>{
    return async dispatch =>{
        try{
           dispatch(serviceActionSuccess(MARK_TABS_TO_BE_COPIED_TO_MAIN, [...tabsToBeCopiedToMain]));
        } 
        catch(e){
            console.error(e);
        }
    }
}

export const setStatusCopyPlanToMainInputsInProgress=(tabsToBeCopiedToMain)=>{
    return async dispatch =>{
        try{
           dispatch(serviceActionSuccess(COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS));
        } 
        catch(e){
            console.error(e);
        }
    }
}

export const activateCopyToMainInputs=(planid,tabsToBeCopiedToMain)=>{
    return async dispatch =>{
        try{
            dispatch(serviceActionSuccess(COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS,{jobId:0}));
            dispatchEditableStatusForGrids(dispatch,tabsToBeCopiedToMain,false);
            let copyResponse=await CallCopyPlanToMainInputs(planid,tabsToBeCopiedToMain);
            dispatch(serviceActionSuccess(COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS,{jobId:copyResponse.jobId}));
        } 
        catch(e){
            console.error(e);
            console.log("ERROR from  CallCopyPlanToMainInputs",e.name,e.message);
            dispatch(serviceActionError(COPY_PLAN_TO_MAIN_INPUTS_FAILURE,e.message));
            dispatchEditableStatusForGrids(dispatch,tabsToBeCopiedToMain,true);
        }
    }
}

export const updateTabsToBeCopiedToMainInputs=(planId,jobId,tabsToBeCopiedToMain)=>{
    return async dispatch =>{
        // console.log("Entering In updateTabsToBeCopiedToMainInputs...");
        let tempArr=[...tabsToBeCopiedToMain];
        if(tempArr.length>0){
            let completedArr=[];
            let resp=await getCopyPlanToMainInputsStatus(planId,jobId);
            // console.log("resp==",resp);
            if(resp.rows && Array.isArray(resp.rows)){
                resp.rows.forEach(row=>{
                    // console.log("row.status===",row.status);
                    if(row.status==="SUCCESS" || row.status==="ERROR"){
                        let idx=tempArr.indexOf(row.tabs);
                        tempArr.splice(idx,1);
                        completedArr.push(row.tabs);
                    }
                });
            }
            // console.log("tempArr, completedArr ==",tempArr, completedArr );
            dispatch(serviceActionSuccess(MARK_TABS_TO_BE_COPIED_TO_MAIN, [...tempArr]));
            dispatchEditableStatusForGrids(dispatch,completedArr,true);

            
            if(tempArr.length===0){
                dispatch(serviceActionSuccess(COPY_PLAN_TO_MAIN_INPUTS_SUCCESS));
            }
        }
        // console.log("Exiting From updateTabsToBeCopiedToMainInputs...");
    }
}

function dispatchEditableStatusForGrids(dispatch,tabsToBeCopiedToMain,isEditable){
    if(tabsToBeCopiedToMain && tabsToBeCopiedToMain.length>0){
        tabsToBeCopiedToMain.forEach(tab=>{
            // console.log(`Calling dispatch to SET ${tab} EDITABLE to ${isEditable}....)`);
            //In case of any new grid, we need to updated this section
            switch(tab){
                case PROGRAM            : dispatch(serviceActionSuccess(SET_PROGRAM_EDITABLE,isEditable));break;
                case SCHEDULE           : dispatch(serviceActionSuccess(SET_SCHEDULES_EDITABLE,isEditable));break;
                case ALLOCATED          : dispatch(serviceActionSuccess(SET_ALLOCATED_EDITABLE,isEditable));break;
                case CONSTRAINT         : dispatch(serviceActionSuccess(SET_CONSTRAINT_EDITABLE,isEditable));break;
                case MOVE_CONSTRAINT    : dispatch(serviceActionSuccess(SET_MOVE_COST_EDITABLE,isEditable));break;
                case UPGRADE_PATH       : dispatch(serviceActionSuccess(SET_CARCLASSGROUP_EDITABLE,isEditable));dispatch(serviceActionSuccess(SET_TEMPLATES_EDITABLE,isEditable));break;
                case AGE_MILEAGE        : dispatch(serviceActionSuccess(SET_AGEMILEAGE_EDITABLE,isEditable));break;
                case ADDITIONAL_COST    : dispatch(serviceActionSuccess(SET_ADDITIONALCOST_EDITABLE,isEditable));break;
                case ADDS_INVALID_POOLS : dispatch(serviceActionSuccess(SET_ADDSINVALIDPOOLS_EDITABLE,isEditable));break;
                case MAX_DEL_ZONE       : dispatch(serviceActionSuccess(SET_MAXDELETES_EDITABLE,isEditable));break;
                case MILEAGE_DEFAULT    : dispatch(serviceActionSuccess(SET_MILEAGEDEFAULT_EDITABLE,isEditable));break;
                case MOVE_COST          : dispatch(serviceActionSuccess(SET_MOVE_COST_EDITABLE,isEditable));break;
                case SALVAGE_RATE       : dispatch(serviceActionSuccess(SET_SALVAGERATE_EDITABLE,isEditable));break;
                case TARGET_ZONE        : dispatch(serviceActionSuccess(SET_TARGETZONEPOOL_EDITABLE,isEditable));break;
                case DEP_RATE           : dispatch(serviceActionSuccess(SET_DEPRATE_EDITABLE,isEditable));break;
                case MILEAGE_TABLE      : dispatch(serviceActionSuccess(SET_MILEAGETABLE_EDITABLE,isEditable));break;
                case DIVERSIONS         : dispatch(serviceActionSuccess(SET_DIVERSIONS_EDITABLE,isEditable));break;
                case PARAMETERS         : dispatch(serviceActionSuccess(SET_PARAMETERS_EDITABLE,isEditable));break;
                default:
                    console.log(`${tab} is an invalid tab`);
            }
        });
    }
}

export const initializeRoleAction=(name,email,preferred_username)=>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(GET_ROLES_IN_PROGRESS,true));
        try{
            let rolesDataResponse=await getRoleInfo(name,email,preferred_username);
            // console.log("rolesDataResponse==",rolesDataResponse);
            if((rolesDataResponse.error===undefined || rolesDataResponse.error===null) && rolesDataResponse.length>0){
                // console.log("GET_ROLES_IN_PROGRESS_SUCCESS");
                dispatch(serviceActionSuccess(GET_ROLES_IN_PROGRESS_SUCCESS,rolesDataResponse[0]));
            } else{
                // console.log("GET_ROLES_IN_PROGRESS_FAILURE");
                dispatch(serviceActionSuccess(GET_ROLES_IN_PROGRESS_FAILURE,rolesDataResponse.error));
            }
        } 
        catch(e){
            console.log(`Failed to get Roles because of Error : ${e.message}`);
            console.log(e);
            dispatch(serviceActionSuccess(GET_ROLES_IN_PROGRESS_FAILURE));
        }
        dispatch(serviceActionSuccess(GET_ROLES_IN_PROGRESS,false));
    }
}

export const toggleMenubarSize=()=>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(TOGGLE_MENUBAR_SIZE));
    }
}
