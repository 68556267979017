import { USER } from '../../Constants';
import {
    FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_PENDING, FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS, FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR,
    FETCH_ALLPLANS_DATA_PENDING, FETCH_ALLPLANS_DATA_SUCCESS, FETCH_ALLPLANS_DATA_ERROR, GET_ALLPLANS_TABLE_DATA,
    SAVE_DESCRIPTION_PENDING, SAVE_DESCRIPTION_SUCCESS, SAVE_DESCRIPTION_ERROR, FETCH_ZONERF_DATA_PENDING, FETCH_ZONERF_DATA_SUCCESS, FETCH_ZONERF_DATA_ERROR, FETCH_POOLRF_DATA_PENDING,
    FETCH_POOLRF_DATA_SUCCESS, FETCH_POOLRF_DATA_ERROR, FETCH_NEW_PLANID_PENDING, FETCH_NEW_PLANID_SUCCESS, FETCH_NEW_PLANID_ERROR,
    FETCH_PLANBYID_PENDING, FETCH_PLANBYID_SUCCESS, FETCH_PLANBYID_ERROR, FETCH_STARTRUN_PENDING, FETCH_STARTRUN_SUCCESS,
    FETCH_STARTRUN_ERROR, SAVE_ZONE_PENDING, SAVE_ZONE_SUCCESS, SAVE_ZONE_ERROR, SAVE_POOL_PENDING, SAVE_POOL_SUCCESS, SAVE_POOL_ERROR,
    COPYPLAN_WITH_OUTPUT_PENDING,COPYPLAN_WITH_OUTPUT_SUCCESS,COPYPLAN_WITH_OUTPUT_ERROR,FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_PENDING,FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_SUCCESS,
    FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_ERROR,
    FETCH_STATUS_UPlOAD_FILE_INSERT,
    FETCH_STATUS_UPlOAD_FILE_INSERT_DELETE,
    FETCH_INSERT_STATUS_UPlOAD_FILE_STATUS_CHECK,SET_ZONERF_POOLRF, MARK_TABS_TO_BE_COPIED_TO_MAIN as MARK_TABS_TO_BE_COPIED_TO_MAIN, COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS, COPY_PLAN_TO_MAIN_INPUTS_SUCCESS, COPY_PLAN_TO_MAIN_INPUTS_FAILURE, SET_STATUS_COPY_PLAN_TO_MAIN_INPUTS_PROGRESS,
    FETCH_PLANBYID_ONREFRESH_PENDING,FETCH_PLANBYID_ONREFRESH_SUCCESS,FETCH_PLANBYID_ONREFRESH_ERROR,
    UPDATE_ADJUSTMENTS_EDIT_STATUS_PENDING,UPDATE_ADJUSTMENTS_EDIT_STATUS_SUCCESS,UPDATE_ADJUSTMENTS_EDIT_STATUS_ERROR, CHECK_IF_PLAN_EDIT_IS_DISABLED, GET_ROLES_IN_PROGRESS, GET_ROLES_IN_PROGRESS_SUCCESS, GET_ROLES_IN_PROGRESS_FAILURE, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS, SET_GRIDNAME_IN_INSERT_PROGRESS_ARRAY, DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY,
    ADD_NOTIFICATION_MESSAGE,REMOVE_NOTIFICATION_MESSAGE, TOGGLE_MENUBAR_SIZE
}
    from './AllPlans.ActionTypes';

const initialState = {
    pending: false,
    allplans: [],
    allPlansTableData: [],
    saveResponse: '',
    zoneRfData: [],
    poolRfData: [],
    newPlanId: '',
    newplanFlag: false,
    plansDataById: [],
    selectedPlanId: '',
    UploadedFiles:[],
    insertStatusCheckFlag:null,
    error: null,
    ZoneRf:'',
    poolRf:'',
    notificationMessages:[],
    tabsToBeCopiedToMain:[],
    copyPlanToMainInputsInProgress:false,
    ifPlanEditDisabled:false,
    userRoleName: "",
    userRoleAccesses: {},
    userRoleError: '',
    jobId:0,
    createPlanJobId:0,
    planCreateSuccessful:null,
    isMasterDataUpdated:false,
    uploadedDataInsertProgressGrids:[],
    menuMinimized:true
}

function createData(id, description, time, datetime, runtime, status, zonerf, poolrf) {
    return { id, description, time, datetime, runtime, status, zonerf, poolrf };
}

function getAllPlansTableData(allplans) {
    const rows = allplans.map((item, index) => {
        return createData(item.id, item.description, item.time, item.datetime, item.runtime, item.status, item.zonerf, item.poolrf);
    })
    return rows;
}

export default function allPlansReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALLPLANS_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ALLPLANS_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                newplanFlag: false,
                allplans: action.data
            });
        case FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS:
            //TODO XM-385 option: 2 we can save zones here (action.data.zones)

            return Object.assign({}, state, {
                pending: false,
                newplanFlag: false,
                allplans: action.data.allPlans
            });
        case FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING: 
        return Object.assign({}, state, {
            pending: true,
            isMasterDataUpdated:false
        });
        case FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS :
            return Object.assign({}, state, {
                pending: false,
                isMasterDataUpdated:action.data
            });
        case FETCH_ALLPLANS_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case GET_ALLPLANS_TABLE_DATA:
            var tableData = getAllPlansTableData(state.allplans);
            return Object.assign({}, state, {
                allPlansTableData: tableData,
                newplanFlag: false
            });
        case SAVE_DESCRIPTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case SAVE_DESCRIPTION_SUCCESS:
            return Object.assign({}, state, {
                saveResponse: action.data,
                newplanFlag: false,
                pending: false
            });
        case SAVE_DESCRIPTION_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending: false
            });
        case SAVE_ZONE_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case SAVE_ZONE_SUCCESS:
            return Object.assign({}, state, {
                saveResponse: action.data,
                newplanFlag: false,
                error: null,
                pending: false
            });
        case SAVE_ZONE_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending: false
            });
        case SAVE_POOL_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case SAVE_POOL_SUCCESS:
            return Object.assign({}, state, {
                saveResponse: action.data,
                error: null,
                newplanFlag: false,
                pending: false
            });
        case SAVE_POOL_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending: false
            });
        case FETCH_ZONERF_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ZONERF_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                zoneRfData: action.data,
                planCreateSuccessful:null
            });
        case FETCH_ZONERF_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                planCreateSuccessful:null
            });
        case FETCH_POOLRF_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_POOLRF_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                poolRfData: action.data,
                planCreateSuccessful:null
            });
        case FETCH_POOLRF_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case FETCH_NEW_PLANID_PENDING:
            return Object.assign({}, state, {
                pending: false,
                createPlanJobId:action.data.jobId
            })
        case FETCH_NEW_PLANID_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                newplanFlag: true,
                newPlanId: action.data.planid,
                createPlanJobId:0,
                planCreateSuccessful:true,
            })
        case FETCH_NEW_PLANID_ERROR:
            return Object.assign({}, state, {
                error: action.error?action.error:null,
                pending: false,
                createPlanJobId:0,
                planCreateSuccessful:false
            });
    
        case FETCH_PLANBYID_PENDING:
            return Object.assign({}, state, {
                pending: true
            })
        case FETCH_PLANBYID_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                plansDataById: action.data
            });
        case SET_ZONERF_POOLRF:
            return Object.assign({}, state, {
                pending: false,
                ZoneRf:action.data[0].zonerf,
                poolRf:action.data[0].poolrf
            });

        case FETCH_PLANBYID_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        case COPYPLAN_WITH_OUTPUT_PENDING:
            return Object.assign({}, state, {
                pending: true
            })
        case COPYPLAN_WITH_OUTPUT_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                newPlanId: action.data
            })
        case COPYPLAN_WITH_OUTPUT_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        case FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                newplanFlag: false,
                allplans: action.data
            });
        case FETCH_ALLPLANS_ON_COPYWITH_OUTPUT_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case FETCH_STATUS_UPlOAD_FILE_INSERT:
            return Object.assign({}, state, {
                UploadedFiles:[...state.UploadedFiles,{'planid':action.data.StatusData.plan_id,'filename':action.data.StatusData.filename,'status':action.data.StatusData.insertstatus}]
                ,insertStatusCheckFlag:false            
            });
        case FETCH_INSERT_STATUS_UPlOAD_FILE_STATUS_CHECK:
                return Object.assign({}, state, {
                    insertStatusCheckFlag:action.data            
                });
        case FETCH_STATUS_UPlOAD_FILE_INSERT_DELETE:
               let data=state.UploadedFiles
               data.splice(0,1)
            return Object.assign({}, state, {
                    UploadedFiles:data,
                    insertStatusCheckFlag:'done'
            });
        case MARK_TABS_TO_BE_COPIED_TO_MAIN:
            //console.log("MARK_TABS_TO_BE_COPIED_TO_MAIN===",action.data);
            return Object.assign({}, state, {
                tabsToBeCopiedToMain:action.data
            });
        case COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS:
           // console.log("Activating COPY_PLAN_TO_MAIN_INPUTS_IN_PROGRESS===");
            return Object.assign({}, state, {
                copyPlanToMainInputsInProgress:true,
                pending: false,
                jobId:action.data.jobId
            });
        case COPY_PLAN_TO_MAIN_INPUTS_SUCCESS:
            return Object.assign({}, state, {
                tabsToBeCopiedToMain:[],
                copyPlanToMainInputsInProgress:'completed',
                pending: false,
                jobId:0
            });
        case COPY_PLAN_TO_MAIN_INPUTS_FAILURE:
            // console.log("INPUT Action==",action);
            return Object.assign({}, state, {
                tabsToBeCopiedToMain:[],
                copyPlanToMainInputsInProgress:'failed',
                pending: false,
                jobId:0,
                error: action.error?action.error:null
            });
        case SET_GRIDNAME_IN_INSERT_PROGRESS_ARRAY:
            return Object.assign({},state,{
                uploadedDataInsertProgressGrids:[...state.uploadedDataInsertProgressGrids,action.data]
            })
        case DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY:
            var index = state.uploadedDataInsertProgressGrids.indexOf(action.data);
            if (index !== -1) {
                state.uploadedDataInsertProgressGrids.splice(index, 1);
            }
            return Object.assign({},state,{
                uploadedDataInsertProgressGrids:state.uploadedDataInsertProgressGrids
            })
        case FETCH_PLANBYID_ONREFRESH_PENDING:
            return Object.assign({}, state, {
                pending: true
            })
        case FETCH_PLANBYID_ONREFRESH_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                plansDataById: action.data
            });
        case FETCH_PLANBYID_ONREFRESH_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
        case UPDATE_ADJUSTMENTS_EDIT_STATUS_PENDING:
            return Object.assign({}, state, {
                pending: true
            })
        case UPDATE_ADJUSTMENTS_EDIT_STATUS_SUCCESS:
            return Object.assign({}, state, {
                pending: false
            });
        case UPDATE_ADJUSTMENTS_EDIT_STATUS_ERROR:
            return Object.assign({}, state, {
                error: action.error
            });
         case CHECK_IF_PLAN_EDIT_IS_DISABLED:
            return Object.assign({}, state, {
                ifPlanEditDisabled: action.data
            });
        case GET_ROLES_IN_PROGRESS:
            // console.log("GET_ROLES_IN_PROGRESS called....");
            return Object.assign({}, state, {
                pending: action.data
            });
        case GET_ROLES_IN_PROGRESS_SUCCESS:
            // console.log("action.data SUCCESS==",action.data);
            return Object.assign({}, state, {
                userRoleName: action.data.role_name?action.data.role_name:USER,
                userRoleAccesses:action.data.accesslist,
                userRoleError:initialState.userRoleError
            });
        case GET_ROLES_IN_PROGRESS_FAILURE:
            // console.log("action.data FAILURE==",action.data);
            return Object.assign({}, state, {
                userRoleName: initialState.userRoleName,
                userRoleAccesses:USER,
                userRoleError:(action.data?action.data.body:"Failed to check user roles")
            });
            case ADD_NOTIFICATION_MESSAGE:
                return Object.assign({},state,{
                    notificationMessages:state.notificationMessages.concat(action.data)
                });
            case REMOVE_NOTIFICATION_MESSAGE:
                return Object.assign({},state,{
                    notificationMessages:state.notificationMessages.filter(msg=>msg.id!=action.data)
                })
            case TOGGLE_MENUBAR_SIZE:
                return Object.assign({}, state, {
                    menuMinimized: !state.menuMinimized
                });
        default:
            return state;
    }

}