import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../../../utils/utils';
import { pollTimeoutInterval } from '../../../../Constants';

export async function getTncEvData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncEvResponse = await client.apis.revenuefunction.getRFTncEv({'pageno':pageno},{requestBody:{'rfid':rfid}});
    const responsesArray = await Promise.all([promisetncEvResponse]);
    const [tncEvResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": tncEvResponse.rows,"rowcount":tncEvResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncEvData failed'}
   }
   }

   export async function getTncEvDropdownService(){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
     const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
     const promisePoolResponse =  await checkAndLoadDimention("pool", client);
     const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
     const responsesArray = await Promise.all([
         promisePoolResponse
        , promiseAdminPoolResponse
    ]);

    const [
         pool,adminPoolResponse
    ] = responsesArray.map(o => o.body.data);

    return ({
        "pool": pool,
        "adminpools":adminPoolResponse
    })
    }
    catch(error){

    }
}

export async function saveRfTncEvTransactions(rfid,transactionList,tabname){
    try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.revenuefunction.bulktransactrftncnc({"rfid":rfid},  {requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) ,"tabName":tabname}});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
    }catch(error){

    }
}

export async function uploadTncEvCSV(rfid,tabName,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.revenuefunction.uploadRfTnc({"rfid":rfid},{
            requestBody: {
                "updatedBy":userEmail,
                "tabName":tabName,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'uploadProgramCSV failed'}
    }
}

export async function tncEvTabValidationService(rfid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.revenuefunction.validateRFTncEv({"rfid":rfid},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       return {'Error':'tncevvalidationservice failed'}
    }
   }

   export async function getTncEvTabErrorStatus(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncEvResponse = await client.apis.revenuefunction.getRFTncEvTabErrorStatus({'rfid':rfid});
    const responsesArray = await Promise.all([promisetncEvResponse]);
    const [tncEvTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return tncEvTabErrorStatusResponse;
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncEvTabErrorStatus failed'}
   }
   }

export async function getTncEvFilterData(rfid,pageno,filterparams){
 try{
    const accessToken = await getAccessTokenAfterAuth();
    
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    const promisetncEvFilterResponse = await client.apis.revenuefunction.getRFTncEvFilterRecords({'pageno':pageno},{requestBody:{'rfid':rfid,'filterparams':filterparams}});
    const responsesArray = await Promise.all([promisetncEvFilterResponse]);
    const [tncEvResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": tncEvResponse.rows,"rowcount":tncEvResponse.rows[0]?tncEvResponse.rows[0].rowcount:0
    });
    }
    catch (error) {
        console.error(error);
        return {'Error':'getTncEvFilterData failed'}
    }
}

export async function deleteAllTncEvData(rfid,params){
    try{
            const userNToken=await getAccessTokenNUserDetailsAfterAuth();
            const accessToken=userNToken.accessToken;
            const userEmail = userNToken.email;
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
            
            let promiseRfTncEvDeleteResponse = await client.apis.revenuefunction.deleteAllRfTncEvRecords({ "rfid": rfid },{requestBody: {"updatedBy":userEmail,...params}});
            return promiseRfTncEvDeleteResponse;
        } 
        catch (error) {
            console.error(error);
            return {'Error':'deleteAllTncEvData failed'}
        }
       }
export async function insertS3UploadTncEvCSV(rfid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        // console.log("Before getting new ID....");
        const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
        // console.log("After getting new ID....",responseWithJobId);
        const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
        // console.log("New Job ID....",jobId);
        let response = null;
        try{
            response = await client.apis.revenuefunction.insertS3UploadedRfTncEvData({"rfid":rfid},{
                requestBody: {
                    "updatedBy":userEmail,
                    "gridName":gridname.replace('grid',''),
                    "fileName":filename,
                    "jobId":jobId
                }
            });
        }catch(e){
            // console.log("e.message==>", e.message);
            let tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
            let status=tempResponse.body.data.rows[0].status;
            let operationStarted=tempResponse.body.data.rows[0].created_on;
            // let startTime=
            while(status!=='SUCCESS' && status!=='ERROR'){
                if((new Date().getMilliseconds()-(new Date(operationStarted)).getMilliseconds())>900000){
                    status='TIMEOUT';
                    break;
                }
                // console.log(`Waiting for ${pollTimeoutInterval} seconds...`);
                setTimeout(()=>{},pollTimeoutInterval);//Pause the time
                // console.log(`Getting the status....`);
                tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
                // console.log(`tempResponse===`,tempResponse);
                status=tempResponse.body.data.rows[0].status;
                // console.log(`status===`,status);
            }
            response = {
                obj:{
                    statusCode: status==="SUCCESS"?'200':'500',
                    body:status==="ERROR"?JSON.stringify({error:tempResponse.obj.data.rows[0].message}):status==="TIMEOUT"?JSON.stringify({error:"Timed Out"}):''
                }
            }
            // console.log("Operation completed...");
        }
        // console.log("insertS3UploadTncEvCSV===>",response);
        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'insert of uploaded tnc ev CSV failed'}
    }
}

export async function getTncEvErrorData(rfid,pageno){
    try{
       const accessToken = await getAccessTokenAfterAuth();
       
       const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
       
       const promisetncEvErrorResponse = await client.apis.revenuefunction.getRFTncEvErrorRecords({'pageno':pageno,'rfid':rfid},null);
       const responsesArray = await Promise.all([promisetncEvErrorResponse]);
       const [tncEvResponse] = responsesArray.map(o => o.body.data);
       return ({
           "gridData": tncEvResponse.rows,"rowcount":tncEvResponse.rows[0]?tncEvResponse.rows[0].rowcount:0
       });
       }
       catch (error) {
           console.error(error);
           return {'Error':'getTncEvErrorData failed'}
       }
   }

export async function getTncEvSearchData(rfid,pageno,searchParams){
     const accessToken = await getAccessTokenAfterAuth();
 
     const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
     const promiseTncEvSearchResponse =  client.apis.revenuefunction.getRFTncEvSearchRecords({ 'rfid':rfid,'pageno':pageno==0 || pageno==undefined?0:pageno},{requestBody: {"searchParams": searchParams } });
     const responsesArray = await Promise.all([promiseTncEvSearchResponse]);
 
     const [tncEvSearchResponse] = responsesArray.map(o => o.body.data);
     return ({
         "gridData": tncEvSearchResponse.rows,"rowcount":tncEvSearchResponse.rows[0]?tncEvSearchResponse.rows[0].rowcount:0
     });
   }