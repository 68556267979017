import {
    FETCH_PROGRAM_DATA_PENDING, FETCH_PROGRAM_DATA_SUCCESS,
    FETCH_PROGRAM_DATA_ERROR, 
    UPDATE_VALUE_SUCCESS,FETCH_FILTER_DATA,
    APPLY_FILTER_SUCCESS, FILTER_SEARCH_PROGRAM_DATA,
    FETCH_FILTER_CATEGORIES_SUCCESS,
    UPLOAD_PROGRAMS_PENDING,UPLOAD_PROGRAMS_SUCCESS,UPLOAD_PROGRAMS_ERROR,
    SAVE_TRANSACTION_PENDING, SAVE_TRANSACTION_SUCCESS, SAVE_TRANSACTION_ERROR,RESET_TO_PROGRSMS_INITIALSTATE,
    SET_PROGRAMS_FILTER_PARAMS
} from "./Programs.ActionTypes";
import filterColumnDefs from '../../data/filterColumns';

const initialState = {
    pending: false,
    programs: {"programs": [], "filterBy": false},
    data: [],
    error: null,
    filterSearchData: [],
    filterFlag: false,
    programFilterSearchKey:"",
    transactionSaveSuccess:null,
    uploadProgramsSuccess: null,
    filterParams: null,
}

function fetchDropDownValues(programData, element) {
    var data = programData.map(item => item[element.colDef]).filter((value, index, self) => self.indexOf(value) == index);
    if (element.colDef === "MANUFACTURER_GROUP") {
            data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "SPI_CODE") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "PROGRAM_TYPE") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "ACCEPTABLE_ZONES") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "CAR_CLASS_CODE") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "MODEL_GROUP") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "MODEL_YEAR") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef == "PROGRAM_YEAR") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label > b.label) { return -1; }
            if (a.label < b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef == "BUY_STATUS") {
        data.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    }

    return element;
}

function fetchFilterValues(filterColumnDefs,data) {
    var programData = data;
    var filterData = filterColumnDefs().data;
    filterData.forEach(element => {
        if (element.type == "dropdown")
            fetchDropDownValues(programData, element);
    });
    return filterData;
}

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);

function fetchFilteredProgramData(array, filters) {
    const filterKeys = Object.keys(filters);
    var filterData = array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true;
            if (key === "BEGIN_DATE" || key === "END_DATE") {
                if (filters[key].length === 1)
                    return true;

                var startDate = new Date(filters[key][1]).setHours(0, 0, 0, 0);
                var itemValue = new Date(item[key]).setUTCHours(12, 0, 0, 0);
                if (filters[key][0] === "between" || filters[key][0] === undefined) {
                    var endDate = new Date(filters[key][2]).setHours(0, 0, 0, 0);
                    return (itemValue >= startDate && itemValue <= endDate);
                } else if (filters[key][0] === "less") {
                    return (itemValue <= startDate);
                } else if (filters[key][0] === "greater") {
                    return (itemValue >= startDate);
                }
            }
            if (typeof filters[key] === 'object' && (key !== "BEGIN_DATE" && key !== "END_DATE")) {
                return filters[key].find(filter => getValue(filter) === getValue(item[key]));
            }
            if (key === "MIN_AGE" || key === "ADD_VOLUME_MIN" || key === "MIN_MILEAGE") {
                var itemValue = parseInt(item[key], 0);
                var filterValue = parseInt(filters[key], 0);
                return itemValue >= filterValue;
            } if (key === "MAX_AGE" || key === "ADD_VOLUME_MAX" || key === "MAX_MILEAGE") {
                var itemValue = parseInt(item[key], 0);
                var filterValue = parseInt(filters[key], 0);
                return itemValue <= filterValue;
            } if (typeof filters[key] === 'string') {
                return getValue(filters[key]) === getValue(item[key]);
            }
        });
    });
    return filterData;
}

const checkName = (name, str) => {
    var pattern = str.split("").map((x) => {
        return `(?=.*${x})`
    }).join("");
    var regex = new RegExp(`${pattern}`, "g")
    return name.match(regex);
}

function fetchSearchValues(searchkey, programData) {
    let results = getSearchValues(searchkey, programData.programs);
    return results;
}
function getSearchValues(searchkey, programData) {
    return programData.filter((item, i) => {
        let itemkeys = Object.keys(programData[i]);
        let entries = Object.entries(programData[i]);
        return entries.find((val, ind) => {
         if(val[0] !== "ID" && val[0] !== "version" && val[0] !== "updated_on" && val[0] !== "ADD_VOLUME_MAX" && val[0]!=='BEGIN_DATE' && val[0]!=='END_DATE' && val[0]!=='updated_by'){
            if (!searchkey.includes('$') &&!searchkey.includes(',')) {
                if(val[1]!==null && val[1]!='' && val[1]!==undefined)
                    return (val[1].toString().toLowerCase().includes(searchkey.toLowerCase()))
            }
            else if (searchkey.includes('$') && (val[0] == 'NET_CAPITAL_COST' || val[0] == 'DEPRECIATION_COST' || val[0] == 'INCENTIVE') && val[1]!==null && val[1]!='' && val[1]!==undefined) {
                if (searchkey.split('$')[0] == '') {
                    let searchkey1 = searchkey.split('$')[1];
                    if(searchkey1.includes(',')){
                        return (val[1].toString().toLowerCase().startsWith(searchkey1.toString().replace(/,/g, '')))
                    }
                    else {
                        return (val[1].toString().toLowerCase().startsWith(searchkey1.toLowerCase()))
                    }
                }
            }
            else if(searchkey.includes(',') &&!searchkey.includes('$') && val[1]!==null && val[1]!='' && val[1]!==undefined){
                return (val[1].toString().startsWith(searchkey.toString().replace(/,/g, '')))
            }
         }
        })
    })
}


export default function programsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PROGRAM_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_PROGRAM_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                filterFlag:false,
                programFilterSearchKey:"",
                programs: action.data,
                error: null,
                uploadProgramsSuccess: null
            });
        case FETCH_PROGRAM_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case UPDATE_VALUE_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                programs: action.data,
                error:null
            });
        case FETCH_FILTER_DATA:
            var actionData = fetchFilterValues(filterColumnDefs,state.programs.programs);
            return Object.assign({}, state, {
                data: actionData,
            });
        case APPLY_FILTER_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                programs: fetchFilteredProgramData(state.programs, action.data),
                error:null
            })
        case FILTER_SEARCH_PROGRAM_DATA:
            var searchData = fetchSearchValues(action.data, state.programs);
            return Object.assign({}, state, {
                programFilterSearchKey:action.data,
                filterFlag: true,
                filterSearchData: searchData,
                error:null
            });
        case FETCH_FILTER_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                categories: action.data,
                error:null
            });
        case UPLOAD_PROGRAMS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadProgramsSuccess: null
            });
        case UPLOAD_PROGRAMS_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                filterFlag:false,
                programFilterSearchKey:"",
                programs: action.data,
                error:null,
                uploadProgramsSuccess: true
            });
        case UPLOAD_PROGRAMS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadProgramsSuccess: false
            });
        case SAVE_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                programs: action.data,
                transactionSaveSuccess: true,
                filterFlag:false,
                programFilterSearchKey:"",
            });
        case SAVE_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_PROGRSMS_INITIALSTATE:
            return initialState;
        case SET_PROGRAMS_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterParams: action.data
            });
        default:
            return state;
    }
}

