import {
    FETCH_GROUP_BY_TYPE_ZONE, FETCH_SALES_ADJUSTMENT_DATA_ERROR, FETCH_PLAN_ADJUSTMENT_DATA_ONUPDATE_ERROR, FETCH_PLAN_ADJUSTMENT_DATA_ONUPDATE_PENDING, FETCH_PLAN_ADJUSTMENT_DATA_ONUPDATE_SUCCESS, FETCH_SALES_ADJUSTMENT_DATA_PENDING, FETCH_SALES_ADJUSTMENT_DATA_SUCCESS, FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING, FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS, FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR, SET_DATA_WITH_ADJUSTEMENT_VALUE, ADJUSTMENTS_ROW_ACTION, ADJUSTMENTS_SET_LEVEL,
    FETCH_ADJUSTMENTS_FILTER_DATA, SET_TABALL_FILTER_PARAMS, SET_CORETAB_FILTER_PARAMS, SET_COREICETAB_FILTER_PARAMS,SET_COREELECTAB_FILTER_PARAMS,SET_TNCTAB_FILTER_PARAMS,SET_TNCICETAB_FILTER_PARAMS,SET_TNCELECTAB_FILTER_PARAMS,
    SET_CARGOCTAB_FILTER_PARAMS,SET_ADJUSTMENTS_EXPANDALL_FLAGS, SET_BU_GROUP_VIEW_TYPE, SET_ACCORDIAN_CLICK,
    SET_SALES_ACCORDIAN_CLICK,
    SET_SALES_ZONE_ACCORDIAN_CLICK,
    TOGGLE_EXAPND_ALL_IN_SALES,
    ENABLE_EDIT_ACTIVITY,
    UPDATE_SALES_ADJUSTMENT_ACTIVITY,
    CANCEL_EDIT_ACTIVITY} from "./SalesAndFinance.ActionTypes";
import {fillEmptyZones,customCloneObject, getClonedFilterCatObject,checkIfFreezMonth } from "../../utils/AdjustmentsUtils";
import filterColumnDefs from '../../data/adjustmentsFilterColumns';
import {ADJLEVEL, AllTabActivitiy, BuActivityMap,BUEngineNames, OperationalEndFleetActivity, SalesTabActivity, TYPE_ZONE_STR} from "../../Constants";
import { ActivityTypeNode } from "./SalesAndFinance.Objects";



const initialState = {
    pending: false,
    editing: false,
    isSaveClicked:false,
    freezeCpd:[],
    adjlevel: ADJLEVEL.NONE,
    editableArrayObject:[],
    planOutputs: [],
    planAdjustmentOutputs: [],
    zones:[],
    error: null,
    presentableData: {},
    expandAll:false,
    transactionMap:{}
}

function clonePresentableData(presentableData){
    // console.log("OLD Presentatble Data==>", presentableData);
    let newPresentableData={};

    Object.keys(presentableData)
          .forEach(activityType=>presentableData[activityType] instanceof ActivityTypeNode?newPresentableData[activityType]=presentableData[activityType].copyIntoNew():newPresentableData[activityType]=[...presentableData[activityType]]);

    // console.log("Cloned Presentatble Data==>", newPresentableData);
    return newPresentableData;
}

function handleAccordianClick(state,action){
    let newPresentableData=clonePresentableData(state.presentableData);
    newPresentableData[action.data.activity].expanded=action.data.expanded;
    return Object.assign({}, state, {
        presentableData: newPresentableData,
    }); 
}

function handleZoneAccordianClick(state,action){
    let newPresentableData=clonePresentableData(state.presentableData);
    newPresentableData[action.data.activity].zones[action.data.zone].expanded=action.data.expanded;
    return Object.assign({}, state, {
        presentableData: newPresentableData,
    }); 
}

function handleToggleExpandAll(state,action){
    let newPresentableData=clonePresentableData(state.presentableData);
    let activityTypes=Object.keys(SalesTabActivity);
    activityTypes.forEach((activityType)=>{
        newPresentableData[activityType].expanded=!state.expandAll;
    })
    return Object.assign({}, state, {
        presentableData: newPresentableData,
        expandAll: !state.expandAll
    }); 
}

function handleEnableEdit(state,action){
    let newPresentableData=clonePresentableData(state.presentableData);
    if(action.data.zone==="ALL"){
        newPresentableData[action.data.activityType].isEditEnabled=true;
        Object.keys(newPresentableData[action.data.activityType].zones).forEach(key=>{
            newPresentableData[action.data.activityType].zones[key].isEditEnabled=false;
            newPresentableData[action.data.activityType].zones[key].isEditDisabled=true;
        })
    }else{
        newPresentableData[action.data.activityType].zones[action.data.zone].isEditEnabled=true;
        newPresentableData[action.data.activityType].isEditEnabled=false;
        newPresentableData[action.data.activityType].isEditDisabled=true;
    }
    
    return Object.assign({}, state, {
        presentableData: newPresentableData,
        editing: true
    }); 
}

function handleCancelSalesEdit(state,action){
    let newPresentableData=clonePresentableData(state.presentableData);
    Object.keys(newPresentableData).forEach(key=>{
        if(key!=='MONTH' && key!=='YEAR'){
            newPresentableData[key].isEditEnabled=false;
            Object.keys(newPresentableData[key].zones).forEach(zoneKey=>{
                newPresentableData[key].zones[zoneKey].isEditEnabled=false;
            });
        }
    });
    return Object.assign({}, state, {
        presentableData: newPresentableData,
        transactionMap: [],
        editing: false
    }); 
}

function handleSalesAdjustment(state,action){
    let newTransactionMap={...state.transactionMap};
    newTransactionMap[action.data.activity+"-"+action.data.zone+"-"+action.data.index]=action.data.value;

    return Object.assign({}, state, {
        transactionMap: newTransactionMap,
    }); 
}

export default function salesAndFinanceReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SALES_ADJUSTMENT_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_SALES_ADJUSTMENT_DATA_SUCCESS:
            // console.log("presentableData==>",action.data.presentableData);
            let newPresentableData=clonePresentableData(action.data.presentableData);
            // console.log("In FETCH_SALES_ADJUSTMENT_DATA_SUCCESS:: newPresentableData==>",newPresentableData);
            // Object.keys(state.presentableData).forEach(key=>{
            //     if(key!=='MONTH' && key!=='YEAR'){
            //         if(state.presentableData[key].expanded===true){
            //             newPresentableData[key].expanded=true;
            //         }
            //         Object.keys(state.presentableData[key].zones).forEach(zoneKey=>{
            //             if(state.presentableData[key].zones[zoneKey].expanded===true){
            //                 newPresentableData[key].zones[zoneKey].expanded=true;
            //             }
            //         });
            //     }
            // });

            return Object.assign({}, state, {
                pending: false,
                editing: false,
                planAdjustmentOutputs: newPresentableData,
                pools: action.data.planAdjustmentData.pools,
                zones: action.data.planAdjustmentData.zones.filter(v => v.name != 'ALL'),
                presentableData: action.data.presentableData,
                accordianClicks:initialState.accordianClicks,
                freezeCpd:action.data.parameters,
                transactionMap: [],
            });
        case FETCH_SALES_ADJUSTMENT_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SET_SALES_ACCORDIAN_CLICK:
            return handleAccordianClick(state,action);
        case SET_SALES_ZONE_ACCORDIAN_CLICK:
            return handleZoneAccordianClick(state,action);
        case TOGGLE_EXAPND_ALL_IN_SALES:
            return handleToggleExpandAll(state,action);
        case ENABLE_EDIT_ACTIVITY:
            return handleEnableEdit(state,action);
        case CANCEL_EDIT_ACTIVITY:
            return handleCancelSalesEdit(state,action);
        case UPDATE_SALES_ADJUSTMENT_ACTIVITY:
            return handleSalesAdjustment(state,action);
        default:
            return state;
    }
}



