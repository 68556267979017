import React from "react";
import ReadOnlyCellComponent from "./ReadOnlyCellComponent";
import './ReadOnlyCellComponent.css';

const AllCellsInAYearComponent = (props) => {
    const { data, isExpanded, content, planStatus, filterParams,zonename,filterRecord,currentHeader} = props;
    let aggregateObj = { "current": 0, "adjustment": 0, "original": 0, "activity_type":'',"fnlVal":0,"adjVar":0 };
    function calculateAggregateValue(data) {
        if (data) {
            data.map((element) => {
                aggregateObj.current = aggregateObj.current + element.current.value;
                aggregateObj.adjustment = aggregateObj.adjustment + ((element.adjustment && element.adjustment != null) ? element.adjustment.value : 0);
                aggregateObj.original = aggregateObj.original + element.original.value;
                aggregateObj.activity_type = element.activity_type;
                // aggregateObj.fnlVal=element.fnlVal;
                // aggregateObj.adjVar=element.adjVar;
                aggregateObj.fnlVal=aggregateObj.fnlVal+((element.adjustment && element.adjustment != null) ? element.adjustment.value+element.original.value : element.original.value);
                aggregateObj.adjVar=aggregateObj.adjVar+((element.adjustment && element.adjustment != null) ? element.adjustment.value+element.original.value-element.current.value : element.original.value-element.current.value);
            })
        }
        return aggregateObj;
    }

    return data.map((element, index) => {
        return <div>
            {(index == 0) ? (<div className="rowStyle">
                <ReadOnlyCellComponent type='summaryStack' data={calculateAggregateValue(data)} isExpanded={isExpanded} content={content} planStatus = {planStatus} filterParams={filterParams} zonename={zonename} filterRecord={filterRecord} currentHeader={currentHeader}></ReadOnlyCellComponent>
                <ReadOnlyCellComponent data={element} isExpanded={isExpanded} content={content} planStatus = {planStatus} filterParams={filterParams} zonename={zonename} filterRecord={filterRecord} currentHeader={currentHeader}></ReadOnlyCellComponent>
            </div>) : <ReadOnlyCellComponent data={element} isExpanded={isExpanded} content={content} planStatus = {planStatus} filterParams={filterParams} zonename={zonename} filterRecord={filterRecord} currentHeader={currentHeader}></ReadOnlyCellComponent>}
        </div>
    })
}

export default AllCellsInAYearComponent;


