import React from 'react';
import ParametersActionBar from "../../components/parametersactionbar/ParametersActionBar";
import MultiGridUI from "../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const ParametersTabPanel = (props) => {
    const { showSaveOption,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, 
        handleRowEditingStopped, gridReducer, parametersGridColDef, refreshCells,
        handleSaveCancelDisabled, handleCellEditingStopped,userMessage,
        deleteSelectedRows,newArchitecture,gridName,handleMultipleRowDelete,selectedPlanId,pending,loadGridData} = props;
    
    return (
        <div>
            {newArchitecture ? (<MainPlanInputsMultiGridUI 
                reducer={gridReducer}
                gridName={gridName}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={parametersGridColDef}
                rowEditType={'single'}
                suppressclickEdit={!parametersGridColDef.defaultColDef.editable||pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={false} />) : (<>

            <ParametersActionBar onAddNewRowClick={handleAddNewRow}
                options={showSaveOption}
                onSaveClicked={handleSaveClicked}
                onCancelClicked={handleCancelClicked} 
                gridColDef={parametersGridColDef} 
                handleSaveCancelDisabled={handleSaveCancelDisabled}/>
            <MultiGridUI reducer={gridReducer}
                gridName={"parametersgrid"}
                currentView={'grid'}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={parametersGridColDef} 
                rowEditType={'single'}
                suppressclickEdit={!parametersGridColDef.defaultColDef.editable}
                userMessage={userMessage}/>
                </>)}
        </div>
    )
}

export default ParametersTabPanel;