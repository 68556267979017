
import { getAllRoles } from '../../../services/admin/rolemanagement/RoleManagementService';
import {getUserManagementData, saveUserManagementTransactions} from '../../../services/admin/usermanagement/UserManagementService';
import {FETCH_USER_MANAGEMENT_DATA_PENDING, FETCH_USER_MANAGEMENT_DATA_SUCCESS,FETCH_USER_MANAGEMENT_DATA_ERROR, FETCH_ROLE_DROPDOWN_PENDING, FETCH_ROLE_DROPDOWN_SUCCESS, FETCH_ROLE_DROPDOWN_ERROR, SAVE_ROLE_MANAGEMENT_TRANSACTION_PENDING, SAVE_ROLE_MANAGEMENT_TRANSACTION_SUCCESS, SAVE_ROLE_MANAGEMENT_TRANSACTION_ERROR} from "./UserManagement.ActionTypes";

export const fetchUserManagementData = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_USER_MANAGEMENT_DATA_PENDING));
            dispatch(serviceActionPending(FETCH_ROLE_DROPDOWN_PENDING));

            let allRoles=await getAllRoles();
            let userManagementData = await getUserManagementData();


            if (allRoles) {
                dispatch(serviceActionSuccess(FETCH_ROLE_DROPDOWN_SUCCESS, allRoles.rows));
            }
            else
            {
                dispatch(serviceActionError(FETCH_ROLE_DROPDOWN_ERROR, "Unknown error"));
            }
            if (userManagementData) {
                dispatch(serviceActionSuccess(FETCH_USER_MANAGEMENT_DATA_SUCCESS, userManagementData));
            }
            else
            {
                dispatch(serviceActionError(FETCH_USER_MANAGEMENT_DATA_ERROR, "Unknown error"));
            }
        } catch (e) {
            console.error(e);
            dispatch(serviceActionError(FETCH_USER_MANAGEMENT_DATA_ERROR, "Unknown error"));
        }
    }
}
export const saveUserManagement= (transactionList)=>{
    return async dispatch => {
        try {
            // console.log("saveUserManagement=", transactionList);
            dispatch(serviceActionPending(SAVE_ROLE_MANAGEMENT_TRANSACTION_PENDING));
            await saveUserManagementTransactions(transactionList);
            let userManagementData = await getUserManagementData();
            if (userManagementData) {
                dispatch(serviceActionSuccess(SAVE_ROLE_MANAGEMENT_TRANSACTION_SUCCESS, userManagementData));         
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_ROLE_MANAGEMENT_TRANSACTION_ERROR, e.message));
        }
    }
}
function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}