import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import Switch from '@material-ui/core/Switch';


export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState(props.value?(props.value===0?false:true):false);

    function onToggleChange(e,val){
    if(val){
        setValue(1)
    }else{ 
        setValue(0)
    }
     }

    function inputHandler(e) {
        setValue(e.target.value);
    }
    

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            }
        };
    });

    return (
       
        <Switch 
        defaultChecked={selected}
        color='primary'
        size='small'
        onChange={(e,val)=>onToggleChange(e,val)}
      /> 

    )
})