import React, { useState } from 'react';
import './UpgradePathZoneTempRenderer.css';
import icon_hot from '../../../../assets/images/icon_son.svg';
import icon_cold from '../../../../assets/images/icon_cold.svg';

function UpgradePathZoneTempRenderer(params) {
    return (
        <div>
            <span>{params.value == 'HOT' ? <img src={icon_hot} className='hotcoldicon'></img>
                : params.value == 'COLD' ? <img src={icon_cold} className='hotcoldicon'></img> : ''}
            </span>
            <span className='pathtempstyle'>{params.value}</span>
        </div>
    );
}

export default UpgradePathZoneTempRenderer;