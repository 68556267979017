import React, { useEffect, useState } from 'react'
import './RangeFilter.css';
import { NttDropdown } from '../../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import { NttLabel } from '../../../core-components/Label/NttLabel';
import Input from "@material-ui/core/Input";
import { TextField } from '@material-ui/core';

const RangeFilter = (props) => {
    const { displayName, theme, fieldName, userSelectedValue, onValueChanged,isFilterCleared } = props;
    const [userValue, setUserValue] = useState(userSelectedValue);

    // Note:- Here below in handleFromValueChange,handleToValueChange,onHandleDropdownValueCHange function 
    // we are slicing the value to selected indexes because 
    // example :- ['between','fromValue','toValue']
    // If we observe above example 0th index has user selected option like between,equal,greater etc
    // 1st index always will have from value
    // 2nd index always will have to value 

    const handleFromValueChange = function (e) {
        if (e.target.value == "" && (userValue[2] == "" || userValue[2] == undefined)) { // checking the fromValue and toValue if both are empty then adding only between
            setUserValue([...userValue.slice(0, 1)])
        }
        else {
            setUserValue([...userValue.slice(0, 1), e.target.value, ...userValue.slice(2)])
        }
    }

    const handleToValueChange = function (e) {
        if (userValue[1] == undefined) {// checking the fromValue when user enters toValue directly without entering formValue
            setUserValue([...userValue.slice(0, 1),"0", e.target.value]);
        }
        else if (e.target.value == "" && userValue[1] == "") {// checking the fromValue and toValue if both are empty then adding only between
            setUserValue([...userValue.slice(0, 1)])
        }
        else {
            setUserValue([...userValue.slice(0, 2), e.target.value]);
        }
    }

    const handleFieldOnBlur = (e, index) => {// this function executes when input field losses it focus and checks if any incomplete('.','-', only these strings without number next or before to it) value exists in the field, if so clearing those values
        if (!userValue[index]) {
            e.target.value=''
        }
    }

    const onHandleDropdownValueCHange = (e) => {
        setUserValue([e.target.value, ...userValue.slice(1, 3)]);
    }

    useEffect(() => {
        if (userValue.length > 0) {
            //note:sending true to get whether the field is datepicker,rangeFilter or not
            onValueChanged(fieldName, userValue, true)
        }
    }, [userValue])

    useEffect(() => {
        if (userSelectedValue.length == 0 || JSON.stringify(userSelectedValue)!=JSON.stringify(userValue)) {
            setUserValue(['between'])
        }
    }, [userSelectedValue,isFilterCleared])

    const ITEM_HEIGHT = 48;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: 250,
            },
        },
    };

    return (<div className="newrangemargin"><span className="newdropdownFilter"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel> <div className='newrangeinput'><NttDropdown
        selectClassName="dropdownStyle"
        formControlClassName="dropdownStyle"
        inputLabelId="demo-mutiple-checkbox-label"
        selectLabelId="demo-mutiple-checkbox-label"
        selectId="demo-mutiple-checkbox"
        selectVariant="outlined"
        selectValue={(userValue[0] === undefined) ? "between" : userValue[0]}
        selectOnchange={(e) => { onHandleDropdownValueCHange(e) }}
        selectCustomInput={<Input />}
        selectMenuProps={MenuProps}
    >
        <MenuItem value="between">is between</MenuItem>
        <MenuItem value="greater">is greater than</MenuItem>
        <MenuItem value="less">is less than</MenuItem>
        <MenuItem value="equal">is equal</MenuItem>
        <MenuItem value="blanks">is blank</MenuItem>
    </NttDropdown>
            {userValue[0] != 'blanks' ? <TextField
                type="number"
                id="BasicOutline"
                value={!userValue[1] ? "" : userValue[1]}
                variant="outlined"
                onChange={(e) => handleFromValueChange(e)}
                onBlur={(e)=>handleFieldOnBlur(e,1)}
            /> : <div></div>}
            {(userValue[0] === "between" || userValue[0] === undefined) && userValue[0] != 'blanks' && userValue[0] != 'equal' ?
                <span className='displayflx'> <label className='newandtext'>and</label>
                    <TextField
                        type="number"
                        id="BasicOutline"
                        value={!userValue[2] ? "" : userValue[2]}
                        variant="outlined"
                        onBlur={(e)=>handleFieldOnBlur(e,2)}
                        onChange={(e) => handleToValueChange(e)} />
                </span> : <div></div>}
        </div>
    </span>
    </div>);

}
export default RangeFilter;