const constraintFilterColumnDefs = () => {
    return ({
        "data": [

            {
                "colDef": "constraint_type",
                "displayName": "Types",
                "type": "dropdown",
                "options": [],
            },

            {
                "colDef": "zone_name",
                "displayName": "Zones",
                "type": "dropdown",
                "options": [],
            },

            {
                "colDef": "pool",
                "displayName": "Pools",
                "type": "dropdown",
                "options": [],
            },

            {
                "colDef": "from_date",
                "displayName": "From",
                "type": "datepicker",
                "options": [],
            },
            {
                "colDef": "to_date",
                "displayName": "To",
                "type": "datepicker",
                "options": [],
            },
            {
                "colDef": "min",
                "displayName": "Min",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "max",
                "displayName": "Max",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "mileage_start",
                "displayName": "Min Mileage",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "mileage_end",
                "displayName": "Max Mileage",
                "type": "range",
                "options": [],
            },


            {
                "colDef": "bu",
                "displayName": "BU",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "car_group_name",
                "displayName": "Car Groups",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "program_type",
                "displayName": "Programs",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "spi_code",
                "displayName": "SPI Codes",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "manufacturer_group_code",
                "displayName": "Manufacturers",
                "type": "dropdown",
                "options": [],
            }
        ]
    });
}

export default constraintFilterColumnDefs;