import { columnDefs, defaultColDef } from "../allrfs/allrfs_grid_data/AllRFsColumnDefs";
import {} from "./AllRFs.ActionTypes";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            //value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
        }
    },
    businessValidationFn: function (currentData,transactions) {
        //var duplicateData = arrUnique(currentData, transactions);
        return currentData;
    }
}

function arrUnique(data,transactions) {
    
}

export default function columnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}