import React, { useState, forwardRef, useImperativeHandle,useEffect, useRef} from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './AutoCompleteEditor.css';

export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');

    function onChangeHandler(e, value) {
        setValue(value);
    }

    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
    }

    useEffect(o => {
        setTimeout(function () {
            if (inputRef && inputRef.current
                && inputRef.current.children.length > 0
                && inputRef.current.children[0].children.length > 0) {
                inputRef.current.children[0].children[0].select();
                inputRef.current.children[0].children[0].focus();
            }
        }, 100);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                if(inputValue){
                    return inputValue.trim();
                }
                return inputValue;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            }
        };
    });

    function onHighlightChangeHandler(e, option, reason) {
        if (option !== null && reason === 'keyboard'){
            setValue(option);
            setInputValue(option);
        }
    }
    function getOptions(props){
        if(props.column.colId){
            for(let i=0;i<props.column.gridOptionsWrapper.gridOptions.columnDefs.length;i++){
                if(props.column.gridOptionsWrapper.gridOptions.columnDefs[i].field===props.column.colId){
                    return props.column.gridOptionsWrapper.gridOptions.columnDefs[i].cellEditorParams.options?props.column.gridOptionsWrapper.gridOptions.columnDefs[i].cellEditorParams.options:props.options;
                }
            }
        }

        return [];
    }
    return (
        <Autocomplete
            //options={props.options ? props.options : []}
            options={getOptions(props)}
            value={value}
            onChange={onChangeHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            onHighlightChange={onHighlightChangeHandler}
            closeIcon=''
            renderInput={(params) => (
                <TextField
                    ref={inputRef}
                    {...params}
                    style={{ padding: '5px 0' }} />
            )}
        />
    );
})
