import { isFilterEmpty } from '../../../utils/utils';
import {FETCH_MODELMFROEM_DATA_PENDING, FETCH_MODELMFROEM_DATA_SUCCESS, FETCH_MODELMFROEM_DATA_ERROR,
    SAVE_MODELMFROEM_TRANSACTION_PENDING, SAVE_MODELMFROEM_TRANSACTION_SUCCESS,RESET_TO_MODELMFROEM_INITIALSTATE, SAVE_MODELMFROEM_TRANSACTION_ERROR, SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_ERROR, SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_SUCCESS, SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_PENDING, SET_ADMIN_MODELMFROEM_FILTER_PARAMS, SET_ADMIN_MODELMFROEM_FILTER_VIEW, SET_ADMIN_MODELMFROEM_SEARCH_RECORDS, SET_ADMIN_MODELMFROEM_FILTER_RECORDS, SET_ADMIN_MODELMFROEM_DELETE_FILTER_RECORDS, SET_ADMIN_MODELMFROEM_SEARCH_VIEW, SET_ADMIN_MODELMFROEM_SEARCH_TEXT, SET_ADMIN_MODELMFROEM_DELETE_FILTER, SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN, SET_ADMIN_MODELMFROEM_FILTER_CLEAR
    } from '../metadata_modelmfroem/ModelMfrOem.ActionTypes';

const initialState = {
    modelMfrOemData:[],
    pending:false,
    error:null,
    // uploadModelMfrOemSuccess:null,
    transactionSaveSuccess:null,
    filterParameters:{},
    filteredData:[],
    filterView:false,
    searchView:false,
    deleteFilter: 0, //When deleteFilter=0, deleted=false, When deleteFilter=1, deleted=true, When deleteFilter=2, not to filter with delete
    filterPopupOpen:false
}



export default function ModelMfrOemReducer (state=initialState,action){
    switch(action.type){
        case FETCH_MODELMFROEM_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_MODELMFROEM_DATA_SUCCESS:
            return Object.assign({},state,{
                modelMfrOemData: action.data.modelMfrOemData,
                // uploadModelMfrOemSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_MODELMFROEM_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        // case UPLOAD_MODELMFROEM_PENDING:
        //     return Object.assign({}, state, {
        //         pending: true,
        //         error:null,
        //         uploadModelMfrOemSuccess: null
        //     });
        // case UPLOAD_MODELMFROEM_SUCCESS:
        //     return Object.assign({}, state, {
        //         modelMfrOemData: fetchZones(action.data.modelMfrOemData, action.data.zones),
        //         pending: false,
        //         error: null,
        //         uploadModelMfrOemSuccess: true
        //     });
        // case UPLOAD_MODELMFROEM_ERROR:
        //     return Object.assign({}, state, {
        //         pending: false,
        //         error: action.error,
        //         uploadModelMfrOemSuccess: false
        //     });
        case SAVE_MODELMFROEM_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_MODELMFROEM_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                modelMfrOemData: action.data.modelMfrOemData,
                transactionSaveSuccess: true
            });
        case SAVE_MODELMFROEM_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
            });
        case SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SET_ADMIN_MODELMFROEM_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterParameters: {...state.filterParameters, ...action.data},
            });
        case SET_ADMIN_MODELMFROEM_FILTER_VIEW:
            if(isFilterEmpty(state.filterParameters)){
                console.log("Filter was empty")
                return Object.assign({}, state, {
                    filterView: false,
                    filterPopupOpen: false,
                    searchView: false
                });
            }else{
                console.log("Filter is not empty")
                return Object.assign({}, state, {
                    filterView: action.data===true,
                    filterPopupOpen: false,
                    searchView: false
                });
            }            
        case SET_ADMIN_MODELMFROEM_SEARCH_RECORDS:
        case SET_ADMIN_MODELMFROEM_FILTER_RECORDS:
        case SET_ADMIN_MODELMFROEM_DELETE_FILTER_RECORDS:
            return Object.assign({}, state, {
                filteredData: action.data
            });
        case SET_ADMIN_MODELMFROEM_SEARCH_VIEW:
            // console.log("SET_ADMIN_MODELMFROEM_SEARCH_VIEW action.data=",action.data);
            let filterParam={...state.filterParameters};
            if(action.data===false){
                delete filterParam.search;
            }
            return Object.assign({}, state, {
                searchView: action.data===true,
                filterParameters: filterParam,
                filterView: false
            });
        case SET_ADMIN_MODELMFROEM_SEARCH_TEXT:
            // console.log("SET_ADMIN_MODELMFROEM_SEARCH_TEXT action.data=",action.data);
            if(action.data!==''){
                return Object.assign({}, state, {
                    filterParameters: {...state.filterParameters, search:action.data},
                });
            }else{
                let filterParam={...state.filterParameters};
                delete filterParam.search;
                return Object.assign({}, state, {
                    filterParameters: filterParam,
                });
            }
        case SET_ADMIN_MODELMFROEM_DELETE_FILTER:
            return Object.assign({}, state, {
                deleteFilter: (action.data===0 || action.data===1 || action.data===2)?action.data:state.deleteFilter,                
            });
        case SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN:
            // console.log("SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN invoked");
            return Object.assign({}, state, {
                filterPopupOpen: action.data===true
            });
        case SET_ADMIN_MODELMFROEM_FILTER_CLEAR:
            // console.log("In SET_ADMIN_MODELMFROEM_FILTER_CLEAR: ",action.data );
            return Object.assign({}, state, {
                //filterPopupOpen: false,
                filterView:false,
                filterParameters: action.data
            });
            
        case RESET_TO_MODELMFROEM_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}