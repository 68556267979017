import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import IconFilter from "../../assets/images/icon_filter.svg";
import IconGrid from "../../assets/images/icon_grid.svg";
import IconGridInActive from "../../assets/images/icon_grid_inactive.svg";
import IconActionEdit from "../../assets/images/icon_action_edit.svg";
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../assets/images/icon_action_download.svg";
import SearchBar from "../searchbar/SearchBarComponent";
import { NttDropdown } from '../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import './ConstraintsActionBar.css';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { NttLabel } from '../../core-components/Label/NttLabel';
import InputFilter from '../actionbar/filter_components/input_filter/InputFilter';
import { Divider } from '@material-ui/core';
import { fetchConstrantsFields, uploadConstraintsData, fetchConstraintData, setFilterParameters,fetchConstraintsOptions } from '../../store/constraints/Constraints.Actions';
import { getConstraintTypes, fetchConstraintFilterData } from '../../store/constraints/Constraints.Actions';
import DatePickerFilter from './filter_components/datepicker_filter/constraint_DatePickerFilter';
import RangeFilter from './filter_components/range_filter/Constraint_RangeFilter';
import DropdownFilter from './filter_components/dropdown_filter/constraint_DropdownFilter';
import ConstraintCustomDialog from '../dialog_component/ConstraintCustomDialog';
import { uploadConstraintsCSV } from "./../../services/constraints/constraintsservice";
import { UPLOAD_CONSTRAINTS_ERROR } from '../../store/constraints/Constraints.ActionTypes';
import CustomPopOver from "../../core-components/Popover/CustomPopOver";
import EditIcon from "../../assets/images/icon_edit.svg";
import StatusIcon from "../../assets/images/icons_status_icons_completed.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '../../assets/images/icon_trash.svg';
import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';
import { safeExcelValue, safeExcelDate,stringFormatter, removeUnderscores } from '../../utils/utils';
import { populateGrid2, validateGrid } from '../../store/MultiGrid/MultiGrid.Actions';
import {searchConstraintRecord} from "../../store/constraints/Constraints.Actions";
import SingleButtonDialogComponent from '../Dialogcomponent/SingleButtonDialogComponent';

const ConstraintsActionBar = (props) => {
    const { options, constraintsData, actionView, onViewChanged, constraintTypeSelected, downloadCSV, data,
        constraintType, onAddNewRowClick, onSaveClicked, onCancelClicked, constrainttypes,
        selectedPlanId, handleSaveCancelDisabled, bulkRowDeleteIconDisabled, deleteSelectedRows, constraintsGridColDef,
        transactions, filterSearchKey, filterParams, constraintsWholeData,constraintsUploadState,
        isFiltered,setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists  } = props;
    const [currentView, setCurrentView] = useState('Type>Zone');
    const theme = createMuiTheme();
    const [buttonactive, setButtonActive] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [rowCount, setRowCount] = useState(1);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedZone, setSelectedZone] = useState([]);
    const [selectedPool, setSelectedPool] = useState([]);
    const [selectedCarGroup, setSelectedCarGroup] = useState([]);
    const [selectedBu, setSelectedBu] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState([]);
    const [selectedMin, setSelectedMin] = useState([]);
    const [selectedMax, setSelectedMax] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [open, setOpen] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const currentYear = new Date().getFullYear();
    const previousYear = new Date().getFullYear() - 1;
    var filterCat = { "constraint_type": [], "zone_name": [], "pool": [], "from_date": ['between'], "to_date": ['between'], "min": ['between'], "max": ['between'], "mileage_start": ['between'], "mileage_end": ['between'], "bu": [], "car_group_name": [], "program_type": [], "spi_code": [], "manufacturer_group_code": [],
                      "Current_Year":'',"Last_Year":'' };
    const [filterArray, setFilterArray] = useState(filterCat);
    const [tempFilterArray, setTempFilterArray] = useState(filterArray);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = React.useState();
    const [selectedToDate, setSelectedToDate] = React.useState();
    const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
    const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
    const [selectedDropdown, setSelectedDropdown] = React.useState("between");
    const [anchorEl, setAnchorEl] = useState(null);
    const popOverOpen = Boolean(anchorEl);
    const id = popOverOpen ? 'simple-popover' : undefined;
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopOverOpen = Boolean(filterAnchorEl);
    const filterId = filterPopOverOpen ? 'simple-popover' : undefined;
    const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(-1);
    const [filterClassName, setFilterClassName] = useState('filterCustomButton');
    const [showDialog,setShowDialog]=useState(false)
    let message = "Are you sure that you want to delete selected rows?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";
    const dispatch = useDispatch();
    var selectedType;

    theme.typography.body2 = {
        fontSize: '14px'
    };

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));
    const classes = useStyles();

    var addConstraintsData = useSelector((state, shallowEqual) => {
        return state.rootConstraintsReducer.constraintsReducer.dropdownData;
    });

    var isConstraintsAccordionInEditMode = useSelector((state, shallowEqual) => {
        return state.rootConstraintsReducer.constraintsReducer.isAccordionDataInEditMode;
    });

    const masterDataUpdateState = useSelector((state)=>{
        return state.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
      })


    useEffect(() => {
        if(masterDataUpdateState){
            //This dispatch and useEffect is triggered only if master cache data is been updated by admin 
            //masterDataUpdateState is at redux state for handling true/false if master cache data has been updated
                //Calling of DropDownService is used to push last updated cache master data to there respective dropdowns
              dispatch(fetchConstraintsOptions(data,constraintsGridColDef,masterDataUpdateState));
              setShowDialog(true)
             }
        },[masterDataUpdateState])

    const handleDialogClose=()=>{
        setShowDialog(false);
    }

    const switchView = (selected) => {
        if (selected !== "grid") {
            setCurrentView("accordion");
            return onViewChanged("grid", selected);
        } else {
            setCurrentView("grid");
            return onViewChanged("accordion", selected);
        }
    }

    const handleChange = (event) => {
        switchView(event.target.value);
        setCurrentView(event.target.value);
    };

    const handleEditClick = (e, selected) => {
        if (selected === "grid")
            setSelectedIndex(4);
        else
            setSelectedIndex(1);
        setAnchorEl(null);
        switchView(selected);
        setCurrentView(selected);
    };

    const loadConstraintsFields = useCallback(async () => {
        try {
            await dispatch(fetchConstrantsFields());
        } catch (err) {
            console.error('got error while loading dealer Branch details');
        }
    }, [dispatch]);

    const loadFilterData = useCallback(async (constraintsWholeData) => {
        try {
            await dispatch(fetchConstraintFilterData(constraintsWholeData));
        } catch (err) {
            console.error('got error while loading dealer Branch details');
        }
    }, [dispatch]);

    var filterData = useSelector((state, shallowEqual) => {
        return state.rootConstraintsReducer.constraintsReducer.constraintFilterData;
    });

    const selectFilterType = (type) => {
        if (type == 'Custom Filter') {
            loadFilterData(constraintsWholeData);
            setOpen(true);
        } else if(type == 'Clear Filter'){
            setOpen(false);
            setFilterArray(filterCat);
            setTempFilterArray(filterCat);
            setFilterType('');
            setSelectedFilterIndex(-1);
            loadFilterParams(null);
            setActiveIndex(0);
        }
        else {
            if(type== 'Current Year'){
                tempFilterArray.Current_Year=currentYear;
                tempFilterArray.Last_Year='';
                if(tempFilterArray.to_date.length>1){
                    tempFilterArray.to_date.splice(1);
                }if(tempFilterArray.from_date.length>1){
                    tempFilterArray.from_date.splice(1);
                }
                onDateRangeChanged(currentYear+"/1","from_date","from",currentYear+"/12",type);
            }else{
                tempFilterArray.Last_Year=previousYear;
                tempFilterArray.Current_Year='';
                if(tempFilterArray.from_date.length>1){
                    tempFilterArray.from_date.splice(1);
                }if(tempFilterArray.to_date.length>1){
                    tempFilterArray.to_date.splice(1);
                }
                onDateRangeChanged(previousYear+"/1","to_date","to",previousYear+"/12",type);
            }
            loadFilterParams({ ...tempFilterArray });
        }
        setFilterType(type);
    };

    const handleClose = (e) => {
        setOpen(false);
        setFilterType('');
        setTempFilterArray(filterArray);
    };

    const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);

    function filterRecords(array, filterparams) {
        if (filterparams == null) {
            return array;
        } else {
            const filterKeys = Object.keys(filterparams);
            return array.filter(item => {
                // validates all filter criteria
                return filterKeys.every(key => {
                    // ignores an empty filter
                    if(key === 'Current_Year'){
                        if(filterparams[key]!=='' && item.to_date!==null && item.from_date!==null){
                            let d1 = new Date(item.from_date.split('T')[0]);
                            let d2 = new Date(item.to_date.split('T')[0]);
                        return(filterparams[key]==d1.getFullYear() || filterparams[key]==d2.getFullYear());
                        }
                    }
                    else if(key === 'Last_Year' && item.to_date!==null && item.from_date!==null){
                        if(filterparams[key]!==''){
                            let d1 = new Date(item.from_date.split('T')[0]);
                            let d2 = new Date(item.to_date.split('T')[0]);
                        return((filterparams[key]==d1.getFullYear()) || (filterparams[key]==d2.getFullYear()));
                        }
                    }
                    if (!filterparams[key].length) return true;
                    if (key === "from_date" || key === "to_date") {
                        if (filterparams[key].length === 1) {
                            return true;
                        }
                        else {
                            if (item[key] == undefined && item.month) {
                                let d1 = new Date(filterparams[key][1]);
                                let d2 = new Date(item.month.split('T')[0]);
                                let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                                let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                                let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                                let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                                if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                    let d3 = new Date(filterparams[key][2]);
                                    let NoTimeDate2 = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                                    let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                                    return itemValue >= startDate && itemValue <= endDate;
                                } else if (filterparams[key][0] === "less") {
                                    return itemValue <= startDate;
                                } else if (filterparams[key][0] === "greater") {
                                    return itemValue >= startDate;
                                }
                            }
                            else if(item[key]!==null){
                                let d1 = new Date(filterparams[key][1]);
                                let d2 = new Date(item[key].split('T')[0]);
                                let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                                let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1); //Date received from database is always in UTC
                                let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                                let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                                if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                    let d3 = new Date(filterparams[key][2]);
                                    let NoTimeDate2 = d3.getFullYear() + '/' + (d3.getMonth() + 1);
                                    let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                                    return itemValue >= startDate && itemValue <= endDate;
                                } else if (filterparams[key][0] === "less") {
                                    return itemValue <= startDate;
                                } else if (filterparams[key][0] === "greater") {
                                    return itemValue >= startDate;
                                }
                            }
                        }
                    }
                    if (typeof filterparams[key] === 'object' && (key !== "from_date" && key !== "to_date" &&
                        key !== "min" && key !== "max" && key !== 'mileage_start' && key !== 'mileage_end')) {
                        if (filterparams[key].length == 0) {
                            return true;
                        }
                        else {
                            if(key==='car_group_name' && filterparams[key][0].value==='Blanks'){
                                return ((item[key]===null) || (item[key]===undefined)) 
                            }else{
                                return (filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) == undefined ? filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) : filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])).value);
                            }
                        }
                    }
                    if (key === 'mileage_start' || key === 'mileage_end') {
                        if (filterparams[key].length === 1) {  
                            if(filterparams[key][0] === 'blanks'){
                              let itemValue = item[key];
                              return (itemValue===null || itemValue===undefined || itemValue==='')
                            }else {
                                return true;
                             } 
                        } else {
                            let startValue = parseInt(filterparams[key][1], 0);
                            let itemValue = parseInt(item[key], 0);
                            if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                let endValue = parseInt(filterparams[key][2], 0);
                                if (filterparams[key][1] !== "" || filterparams[key][2] !== "") {
                                    return item[key] >= startValue && itemValue <= endValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "less") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue <= startValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "greater") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue >= startValue;
                                } else {
                                    return true;
                                }
                            }
                        }
                    }
                    if (key === "min" || key === "max") {
                        if (filterparams[key].length === 1) {
                            return true;
                        } else {
                            let startValue = parseInt(filterparams[key][1], 0);
                            let itemValue = parseInt(item[key], 0);
                            if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                let endValue = parseInt(filterparams[key][2], 0);
                                if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
                                    return item[key] >= startValue && itemValue <= endValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "less") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue <= startValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "greater") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue >= startValue;
                                } else {
                                    return true;
                                }
                            }
                        }
                    }
                });
            });
        }
    }

    const applyTransactions = (constraintsData, transactions) => {
        let deepcopyConstraintsData = constraintsData.map(o => { return { ...o }; })
        transactions.map((value1) => {
            deepcopyConstraintsData.map((value2, index) => {
                if (value1.type !== 'delete' && value1.type !== 'add' && value1.type !== 'copy' && (value2.ID == value1.data.ID)) {
                    deepcopyConstraintsData[index] = value1.data;
                } else if (value1.type == 'delete' && (value2.ID == value1.id)) {
                    deepcopyConstraintsData.splice(index, 1)
                }
            });
            if (value1.type == 'add' || value1.type == 'copy') { 
                if (value1.data !== undefined) {  // If data is undefined,app is braking while filter and clear filter.That's why adding condition.But here we loose added row on filter if it is blank.
                    deepcopyConstraintsData.push(value1.data);
                }
            }
        });
        return deepcopyConstraintsData;
    }

    useEffect(() => {
        if (transactions.length == 0) {
            if (isFiltered == true || isSearchBarValueExists == true) {
                dispatch(fetchConstraintData(selectedPlanId));
            }
        } else if (transactions.length > 0) {
            var currentdata = applyTransactions(constraintsData, transactions);
            var filteredCurrentData = filterRecords(currentdata, filterParams);
            var filteredData = filterRecords(constraintsData, filterParams);
            dispatch(populateGrid2("constraintsgrid", filteredData, filteredCurrentData, filterSearchKey, constraintsGridColDef.checkValue, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn, constraintsUploadState));
        }
    }, [filterParams, filterSearchKey]);

    useEffect(() => {
        if (constraintsData.length > 0) {
            var filteredData = filterRecords(constraintsData, filterParams);
            dispatch(populateGrid2("constraintsgrid", filteredData, filteredData, filterSearchKey, constraintsGridColDef.checkValue, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn,constraintsUploadState));
        }
    }, [constraintsData])

    useEffect(() => {
        dispatch(getConstraintTypes(constraintsData));
    }, [constraintsData]);

    useEffect(() => {
        if (constraintType === 'ALL') {
            setActiveIndex(0);
            selectedType = constraintType;
            constraintTypeSelected(constraintType);
        }
    }, [constraintType]);

    useEffect(() => {
        if (actionView === "grid")
            setSelectedIndex(4);
        else
            setSelectedIndex(1);
    }, [actionView]);

    const onConstraintTypeClick = (e, type) => {
    setIsFiltered(true);
    setIsSearchBarValueExists(false);
    dispatch(searchConstraintRecord(""));
    if (tempFilterArray.constraint_type.length > 0) {
        tempFilterArray.constraint_type.splice(0);
        if(type =='ALL'){
            constrainttypes.map((val)=>{
                let obj1={};
                  if(val[0]!=='ALL'){ //All is not part of constraint_type
                      obj1['label'] = val[0];
                      obj1['value'] = val[0];
                      tempFilterArray.constraint_type.push(obj1);
                  }
              })
        }else{
            let obj = {};
            obj['label'] = type;
            obj['value'] = type;
            tempFilterArray.constraint_type.push(obj);
        }
    }else{
        let obj = {};
        obj['label'] = type;
        obj['value'] = type;
        tempFilterArray.constraint_type.push(obj);
    }
        setTempFilterArray({ ...tempFilterArray });
        loadFilterParams({ ...tempFilterArray });
        selectedType = type;
        constraintTypeSelected(type);
        if(tempFilterArray.constraint_type.length>1){
            setActiveIndex(0);
        }else{
            if(tempFilterArray.constraint_type[0].value=='ADDS'){
                setActiveIndex(1);
            }else if(tempFilterArray.constraint_type[0].value=='CORE_TO_TNC'){
                setActiveIndex(2);
            }else if(tempFilterArray.constraint_type[0].value=='DELETES'){
                setActiveIndex(3);
            }else if(tempFilterArray.constraint_type[0].value=='FLEET'){
                setActiveIndex(4);
            }else if(tempFilterArray.constraint_type[0].value=='NET G/L'){
                setActiveIndex(5);
            }
        }
    }

    const handleAddClick = (e) => {
        return onAddNewRowClick();
    }

    const addMultipleRows = (event) => {
        return onAddNewRowClick();
    }


    const getSelectedValue = (colDef) => {
        if (tempFilterArray !== undefined) {
            return tempFilterArray[colDef];
        }
    }

    const handleDropDownValueChange = (event, displayName, colDef) => {
        setSelectedValue(event);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (Array.isArray(filterData[key])) {
                    filterData[key] = event;
                } else {
                    filterData[key] = event[0].value;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const onDateRangeChanged = (value1, colDef, dateField,value2,type) => {
        if(type && type=='Current Year' || type=='Last Year'){
            let dateValue1 = new Date(value1);
            let dateValue2 = new Date(value2);
            var filterData = { ...tempFilterArray };
            filterData['from_date'].push(dateValue1);
            filterData['from_date'].push(dateValue2);
            filterData['to_date'].push(dateValue1);
            filterData['to_date'].push(dateValue2);
            // if(dateField=='from'){
                setSelectedFromDate(dateValue1);
            // }else{
                setSelectedToDate(dateValue2);
            //}
        }else{
            var date = new Date(value1);
        if (dateField == "from")
            setSelectedFromDate(date);
        else if (dateField == "to")
            setSelectedToDate(date);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (filterData[colDef][0] === "between") {
                    if (filterData[key].length === 3) {
                        if (dateField === "from") {
                            filterData[key][1] = date;
                        }
                        if (dateField === "to") {
                            filterData[key][2] = date;
                        }
                    } else {
                        if (filterData[key].length == 2 && dateField == "from")
                            filterData[key][1] = date;
                        else if (filterData[key].length == 2 && dateField == "to")
                            filterData[key][2] = date;
                        else
                            filterData[key].push(date);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(date);
                    else
                        filterData[key][1] = date;
                }
            }
            return filterData;
        });
        }
        setTempFilterArray(filterData);
    }

    const onDateDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onRangeDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onValueRangeChanged = (e, colDef, rangeField) => {
        var range = e.target.value;
        if (rangeField == "from")
            setSelectedFromRangeValue(range);
        else if (rangeField == "to")
            setSelectedToRangeValue(range);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key == colDef) {
                if (filterData[colDef][0] == "between") {
                    if (filterData[key].length === 3) {
                        if (rangeField === "from") {
                            filterData[key][1] = range;
                        }
                        if (rangeField === "to") {
                            filterData[key][2] = range;
                        }
                    } else {
                        // if (filterData[key].length == 1 && rangeField == "to") {
                        //     filterData[key].push("0");
                        // }
                        // filterData[key].push(range);
                        if (filterData[key].length == 1 && rangeField == "to") {
                            filterData[key][1] = 0;
                            filterData[key][2] = range;
                          }
                          else if (rangeField == "from") {
                            filterData[key][1] = range;
                          }
                          else {
                            filterData[key][2] = range;
                          }
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(range);
                    else
                        filterData[key][1] = range;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const fetchDialogContent = () => {
        if (filterData !== undefined && filterData.length > 0) {
            return (<div className="flexContainer">
                {/* <label className='presets'>Presets</label> */}
                {/* <div className="presetdropdown">
                    <NttDropdown
                        color="primary"
                        selectId="demo-simple-select"
                        selectLabelId="demo-simple-select-label"
                        selectValue={filterType}
                        selectOnchange={(event) => selectFilterType(event)}
                        selectClassName="viewdropdown"
                        placeholder="--Select a Presets Filter--"
                    >
                        <MenuItem value="Custom Filter">{"Custom Filter..."}</MenuItem>
                    </NttDropdown>
                </div> */}
                {/* <div className='botomborder'></div> */}
                {filterData.map((fields, index) => {
                    return (<div className="filterMain"  key={index}>
                        {(fields.type === "dropdown") ?
                            (<DropdownFilter displayName={fields.displayName} options={fields.options}
                                theme={theme} selectedValue={getSelectedValue(fields.colDef)}
                                onValueChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}>
                            </DropdownFilter>) :
                            (fields.type === "input") ?
                                (<InputFilter selectedValue={getSelectedValue(fields.colDef)}
                                    displayName={fields.displayName} theme={theme}
                                    onTextChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}></InputFilter>) :
                                (fields.type === "datepicker") ?
                                    (<DatePickerFilter displayName={fields.displayName} theme={theme}
                                        selectedDate={tempFilterArray[fields.colDef]}
                                        onFromDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "from")}
                                        onToDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "to")}
                                        onValueChanged={(e) => onDateDropDownValueChanged(e, fields.colDef)}>
                                    </DatePickerFilter>) :
                                    (fields.type === "range") ?
                                        (<RangeFilter displayName={fields.displayName} theme={theme}
                                            selectedRangeValue={tempFilterArray[fields.colDef]}
                                            onFromValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "from")}
                                            onToValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "to")}
                                            onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.colDef)}
                                        >
                                        </RangeFilter>) :
                                        <div></div>}</div>);
                }
                )}</div>);
        }
    }

    const loadFilterParams = useCallback(async (tempFilterArray) => {
        try {
            dispatch(setFilterParameters(tempFilterArray))
        } catch (err) {
            console.error('got error while loading filterparams');
        }
    }, [dispatch]);

  const checkIfTempFilterArrayIsEmpty= (tempArray)=>{
    if(tempArray.bu.length==0 && tempArray.car_group_name.length==0 && tempArray.constraint_type.length==0 && tempArray.from_date.length==1 && tempArray.manufacturer_group_code.length==0 && tempArray.max.length==1 
         && (tempArray.mileage_end.length==1 && tempArray.mileage_end[0]!=='blanks') && (tempArray.mileage_start.length==1 && tempArray.mileage_start[0]!=='blanks') && tempArray.min.length==1 && tempArray.pool.length==0 && tempArray.program_type.length==0 && tempArray.spi_code.length==0 
         && tempArray.to_date.length==1 && tempArray.zone_name.length==0){
             return 0;
         }else{
             return 1;
         }
  }

    const applyFilter = (event) => {
        setOpen(false);
        setIsSearchBarValueExists(false);
        dispatch(searchConstraintRecord(""));
        setFilterArray({ ...tempFilterArray });
        let isTempFilterArrayEmpty = checkIfTempFilterArrayIsEmpty(tempFilterArray);
        if(isTempFilterArrayEmpty==0){
            loadFilterParams(null);
            setSelectedFilterIndex(-1);
            setIsFiltered(false);
        }else{
            loadFilterParams({ ...tempFilterArray });
            setIsFiltered(true);
        }
        setFilterType('');
        if(tempFilterArray.constraint_type.length>1){
            setActiveIndex(0);
        }else if(tempFilterArray.constraint_type.length>0){
            if(tempFilterArray.constraint_type[0].value=='ADDS'){
                setActiveIndex(1);
            }else if(tempFilterArray.constraint_type[0].value=='CORE_TO_TNC'){
                setActiveIndex(2);
            }else if(tempFilterArray.constraint_type[0].value=='DELETES'){
                setActiveIndex(3);
            }else if(tempFilterArray.constraint_type[0].value=='FLEET'){
                setActiveIndex(4);
            }else if(tempFilterArray.constraint_type[0].value=='NET G/L'){
                setActiveIndex(5);
            }
        }else{
            setActiveIndex(0);
        }
    }

    const clearFilter = (event) => {
        //setOpen(false);
        setFilterArray(filterCat);
        setTempFilterArray(filterCat);
        setFilterType('');
        setSelectedFilterIndex(-1);
        loadFilterParams(null);
        setActiveIndex(0);
    }

    const changeHandler = async (event) => {
        if (event.target.value !== '') {
            const fileToUpload = event.target.files[0];
            event.target.value = '';
            dispatch(uploadConstraintsData(selectedPlanId, fileToUpload));
        }
    };

    const downloadHandler = async (event) => {
        let JsonFields = ["constraint_table", "constraint_type", "zone_name", "pool", "from_date", "to_date", "min", "max", "bu", "program_type", "spi_code", "manufacturer_group_code", "car_group_name", "mileage_start", "mileage_end", "notes"]
        let filteredData = filterRecords(constraintsData, filterParams);
        let downloadData = JsonToCSV(filteredData, JsonFields);
        let currentdate = new Date();
        let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
        downloadCSV(downloadData, `constraints-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`);
    };

    function JsonToCSV(JsonArray, JsonFields) {
        var csvStr = JsonFields.join(",") + "\n";

        JsonArray.forEach(element => {
            let constraint_table = safeExcelValue(element.constraint_table);
            let constraint_type = safeExcelValue(element.constraint_type);
            let zone = safeExcelValue(element.zone_name);
            let Pool_code = safeExcelValue(element.pool);
            let from_date = safeExcelDate(element.from_date, 'DD-MMM-YY');
            let to_date = safeExcelDate(element.to_date, 'DD-MMM-YY');
            let min = safeExcelValue(element.min);
            let max = safeExcelValue(element.max);
            let bu = safeExcelValue(element.bu);
            let program_type = safeExcelValue(element.program_type);
            let spi_code = safeExcelValue(element.spi_code);
            let manufacturer_group_code = safeExcelValue(element.manufacturer_group_code);
            let car_group_name = safeExcelValue(element.car_group_name);
            let mileage_start = safeExcelValue(element.mileage_start);
            let mileage_end = safeExcelValue(element.mileage_end);
            let notes = stringFormatter(safeExcelValue(element.notes));
            csvStr += constraint_table + ',' + constraint_type + ',' + zone + ',' + Pool_code + ',' + from_date + ',' + to_date + ',' + min + ',' + max + ',' + bu + ',' + program_type + ',' + spi_code + ',' + manufacturer_group_code + ',' + car_group_name + ',' + mileage_start + ',' + mileage_end +  ',"' + notes +'"\n';
        })
        return csvStr;
    }

    const handleFilterPopOverClose = () => {
        setFilterClassName('filterPrimaryButton');
        setFilterAnchorEl(null);
    };

    const handleFilterPopOverClick = (e) => {
        setFilterClassName('filterSecondaryButton');
        setFilterAnchorEl(e.currentTarget);
    }

    const handleFilterListItemClick = (event, index) => {
        setFilterClassName('filterPrimaryButton');
        setSelectedFilterIndex(index);
        setFilterAnchorEl(null);
        var selectedValue;
        if (index === 1)
            selectedValue = "Custom Filter";
        else if (index === 2)
            selectedValue = "Current Year";
        else if (index === 3)
            selectedValue = "Last Year";
        else if (index === 4)
        selectedValue = "Clear Filter";
        return selectFilterType(selectedValue);
    };
    const onSearchValueChanged =(e)=>{
        let searchKey = e.target.value;
        if(searchKey==''){
            setIsSearchBarValueExists(false);
           dispatch(searchConstraintRecord(searchKey));
        }
    }
     
     const onKeyEnterSearch = (e) => {
        if (e.target.value != "" && e.target.value != "$") {
            clearFilter();
          setIsSearchBarValueExists(true);
        } else {
          setIsSearchBarValueExists(false);
        }
        dispatch(searchConstraintRecord(e.target.value));
        
      };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handlePopOverClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        var selectedValue;
        if (index === 1)
            selectedValue = "Type>Zone";
        else if (index === 2)
            selectedValue = "Type>BU>Zone";
        else if (index === 3)
            selectedValue = "Zone>Type";
        else if (index === 4)
            selectedValue = "grid";
        return switchView(selectedValue);
    };

    const switchViewComponent = () => {
        return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
            <Button id={id} onClick={(e) => handlePopOverClick(e)} endIcon={<ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon>}>View</Button>
            <CustomPopOver
                className="actiobarPopOver"
                popOverId="popoverId"
                open={popOverOpen}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <List>
                    <ListItem button
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}>
                        <img src={selectedIndex === 1 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Type {'>'} Zone</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}>
                        <img src={selectedIndex === 2 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Type {'>'} BU {'>'} Zone</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}>
                        <img src={selectedIndex === 3 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Zone {'>'} Type</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}>
                        <img src={selectedIndex === 4 ? StatusIcon : EditIcon} className="imageSpacing"></img>

                        <NttLabel theme={theme} labelVariant="body2">
                            Table View</NttLabel>
                    </ListItem>
                </List>
            </CustomPopOver>
        </div>);
    }

    const filterComponent = () => {
        return (<div className={(filterPopOverOpen || selectedFilterIndex === -1) ? 'filterCustomButton' : filterClassName} aria-describedby={id}>
            <Button id={id} onClick={(e) => handleFilterPopOverClick(e)} startIcon={<img src={IconFilter} />} endIcon={(selectedFilterIndex !== -1) ? <ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon> : ''}>
                {selectedFilterIndex === 1 ? 'Custom Filter' : (selectedFilterIndex === 2) ? 'Current Year' : (selectedFilterIndex === 3) ? 'Last Year' :(selectedFilterIndex === 4) ? 'Clear Filter' : ''}
            </Button>
            <CustomPopOver
                className="filterPopOver"
                popOverId="filterPopoverId"
                open={filterPopOverOpen}
                anchorEl={filterAnchorEl}
                onClose={handleFilterPopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <List>
                    <ListItem button
                        selected={selectedFilterIndex === 1}
                        onClick={(event) => handleFilterListItemClick(event, 1)}>
                        <img src={(selectedFilterIndex === 1) ? StatusIcon : ''} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Custom Filter</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedFilterIndex === 2}
                        onClick={(event) => handleFilterListItemClick(event, 2)}>
                        <img src={selectedFilterIndex === 2 ? StatusIcon : ''} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Current Year</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedFilterIndex === 3}
                        onClick={(event) => handleFilterListItemClick(event, 3)}>
                        <img src={selectedFilterIndex === 3 ? StatusIcon : ''} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Last Year</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedFilterIndex === 4}
                        onClick={(event) => handleFilterListItemClick(event, 4)}>
                        <img src={selectedFilterIndex === 4 ? StatusIcon : ''} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Clear Filter</NttLabel>
                    </ListItem>
                </List>
            </CustomPopOver>
        </div>);
    }

    const handleClickOpen = (props) => {
        if (!bulkRowDeleteIconDisabled) {
            setOpenDeleteDialog(true);
        }
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteSelectedRows = () => {
        setOpenDeleteDialog(false);
        deleteSelectedRows()
    }

    return (
        <div aria-disabled={isConstraintsAccordionInEditMode} className={(isConstraintsAccordionInEditMode) ? 'is-disabled' : ''} style={{ marginTop: '-36px' }}>
            <div className={actionView == 'grid' ? "constraintactionbar" : "fixedConstraintActionBar "}>
                <div className="constrainttypes">
                    {constrainttypes.map((type, index) => {
                        return (
                            <div className="typebuttons" key={index}>
                                <Button key={index} className={activeIndex === index ? 'btnactive' : ''} onClick={(e) => onConstraintTypeClick(e, type[0], index)} disabled={options}><span className={activeIndex === index ? "types activefontstyle" : 'types'}>{removeUnderscores(type[0])}</span><span className={activeIndex === index ? "typecount activefontstyle" : 'typecount'}>{type[1]}</span></Button>
                            </div>
                        )
                    })}
                </div>
                <div className="constraintactions">
                    <div className="constraintfilterStyle">
                        {/* <NttDropdown
                        color="primary"
                        selectId="demo-simple-select"
                        selectLabelId="demo-simple-select-label"
                        selectValue={filterType}
                        selectOnchange={(event) => selectFilterType(event)}
                        selectClassName="viewdropdown"
                    //inputLabel="Select"
                    >
                        <MenuItem value="Custom Filter">{"Custom Filter..."}</MenuItem>
                        <MenuItem value="Current Year">{"Current Year"}</MenuItem>
                        <MenuItem value="Last Year">{"Last Year"}</MenuItem>
                    </NttDropdown> */}
                        <div className="filterMargin">{filterComponent()}</div>
                        {/* <ConstraintsSearchBar 
                                isSearchBarValueExists={isSearchBarValueExists}
                                setIsSearchBarValueExists={setIsSearchBarValueExists}
                        ></ConstraintsSearchBar> */}
                           <SearchBar onSearchValueChanged={onSearchValueChanged} onEnterSearch={true} onKeyEnterSearch={onKeyEnterSearch} filterSearchText={filterSearchKey} showActiveIconOnEnter={isSearchBarValueExists}></SearchBar>
                    </div>
                    {/* Disable cancel save and add + buttons when status is running  */}
                    {options && constraintsGridColDef.defaultColDef.editable? 
                        <div className="saveButtons">
                            <div className="cancelButton">
                                <Button onClick={(e) => onCancelClicked(e)}>Cancel</Button>
                            </div>
                            <div className="primaryButton">
                                <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes</Button>
                            </div>
                            <div className="primaryButton">
                                <Button disabled={ !constraintsGridColDef.defaultColDef.editable} id={id} onClick={(e) => handleAddClick(e)}>+ Add</Button>
                                {/* <Popover id={id}
                                open={popOverOpen}
                                anchorEl={anchorEl}
                                onClose={handlePopOverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <List component="nav" aria-label="secondary mailbox folder">
                                    <ListItem
                                        button
                                        selected={selectedIndex === 5}
                                        onClick={(event) => handleListItemClick(event, 5)}
                                    >
                                        <ListItemText disableTypography
                                            primary={<NttLabel theme={theme} labelVariant="body2">New Constraint</NttLabel>} />
                                    </ListItem>
                                </List>
                            </Popover> */}
                            </div>
                            <div className="primaryButton">
                                <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !constraintsGridColDef.defaultColDef.editable)}>
                                    Delete
                                </Button>
                            </div>
                        </div> :
                        <div className="actionButtons">
                            {(currentView !== "grid" && actionView !== "grid") ? <img src={IconActionEdit} className="imageIcon" onClick={(e) => handleEditClick(e, 'grid')}></img> :
                                <img src={IconGrid} className="imageIcon" onClick={(e) => handleEditClick(e, 'Type>Zone')}></img>}
                            <label>
                                <input disabled={ !constraintsGridColDef.defaultColDef.editable} type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
                                <img src={IconActionUpload} className="imageIcon"></img>
                            </label>
                            <div className='imageIcon'>
                                <img src={IconActionDownload} onClick={() => downloadHandler()} />
                            </div>
                            {/* <img src={IconActionDownload} className="imageIcon" ></img> */}

                            {/* <NttDropdown
                            color="primary"
                            selectId="demo-simple-select"
                            selectLabelId="demo-simple-select-label"
                            selectValue={currentView}
                            selectOnchange={(event) => handleChange(event)}
                            selectClassName="viewdropdown"
                        >
                            <MenuItem value="Type>Zone">{"Group by Type > Zone"}</MenuItem>
                            <MenuItem value="Type>BU>Zone">{"Group by Type > BU > Zone"}</MenuItem>
                            <MenuItem value="Zone>Type">{"Group by Zone > Type"}</MenuItem>
                            <MenuItem value="grid">Table View</MenuItem>
                        </NttDropdown> */}
                            <div>
                                {switchViewComponent()}
                            </div>
                            {(currentView === "grid" || actionView === "grid") ?
                                <div style={{ display: 'flex' }}>
                                    <div className="primaryButton">
                                        <Button disabled={ !constraintsGridColDef.defaultColDef.editable} id={id} onClick={(e) => handleAddClick(e)}>+ Add</Button>
                                        {/* <Popover id={id}
                                open={popOverOpen}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <List component="nav" aria-label="secondary mailbox folder">
                                    <ListItem
                                        button
                                        selected={selectedIndex === 5}
                                        onClick={(event) => handleListItemClick(event, 5)}
                                    >
                                        <ListItemText disableTypography
                                            primary={<NttLabel theme={theme} labelVariant="body2">New Constraint</NttLabel>} />
                                    </ListItem>
                                </List>
                            </Popover> */}
                                    </div>
                                    <div className="primaryButton">
                                        <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !constraintsGridColDef.defaultColDef.editable)}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                                : <div></div>}
                        </div>}
                </div>
                <div>
                    <ConstraintCustomDialog classes={{ paper: classes.dialogPaper }}
                        className='constraintdialog'
                        open={open} handleClose={(e) => handleClose(e)}
                        dialogContent={fetchDialogContent()}
                        handlePositiveBtnClick={(e) => applyFilter(e)}
                        handleNegativeBtnClick={(e) => clearFilter(false)}
                        title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter" styles={"styles"}>
                    </ConstraintCustomDialog>
                    <DeleteDialogBox open={openDeleteDialog} handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
                        dialogMessage={message} title={title} action={deletetext}
                        canceltext={canceltext}>
                    </DeleteDialogBox>
                    <SingleButtonDialogComponent open={showDialog} handleOk={handleDialogClose}
                      dialogMessage={"Master Data Has Been Updated!!"} title={"Alert!!"} action="Ok">
                    </SingleButtonDialogComponent>
                </div>
            </div>
        </div>
    );
}

export default ConstraintsActionBar;