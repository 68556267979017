import React, { useEffect, useMemo, useState } from 'react'
import './DatePickerFilter.css';
import { NttDropdown } from '../../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import { NttLabel } from '../../../core-components/Label/NttLabel';
import Input from "@material-ui/core/Input";
import DateFnsUtils from "@date-io/date-fns";
import Clear from '@material-ui/icons/Clear';
import moment from "moment";
import {
    MuiPickersUtilsProvider, DatePicker,
    KeyboardDatePicker} from '@material-ui/pickers';

const DatePickerFilter = (props) => {
    const { displayName, theme,fieldName, isFullDate, userSelectedValue, onValueChanged,isFilterCleared } = props;

    const [userValue,setUserValue]=useState(userSelectedValue);

    // Note:- Here below in handleFromDateChange,handleToDateChange,onHandleDropdownValueCHange,handleClearDate function 
    // we are slicing the value to selected indexes because 
    // example :- ['between','fromdate','todate']
    // If we observe above example 0th index has user selected option like between,equal,greater etc
    // 1st index always will have fromdate value
    // 2nd index always will have todate value 

    const handleFromDateChange = function(e){
        let monthYear;
        if(isFullDate){
            monthYear=`${e.getFullYear()}-${("0" + (e.getMonth()+1)).slice(-2)}-${("0" + (e.getDate())).slice(-2)}`;
        }  else {
            monthYear=`${e.getFullYear()}-${("0" + (e.getMonth()+1)).slice(-2)}`;
        }         
        setUserValue([...userValue.slice(0,1),monthYear,...userValue.slice(2)])
    }

    const handleToDateChange = function(e){
        let monthYear;
        if(isFullDate){
            monthYear=`${e.getFullYear()}-${("0" + (e.getMonth()+1)).slice(-2)}-${("0" + (e.getDate())).slice(-2)}`;
        }  else {
            monthYear=`${e.getFullYear()}-${("0" + (e.getMonth()+1)).slice(-2)}`;
        }  
        
        if(userValue[1] == undefined) {// checking the fromValue when user enters toValue directly without entering formValue
            setUserValue([...userValue.slice(0,1),'',monthYear]);
        }
        else{
            setUserValue([...userValue.slice(0,2),monthYear]);
        }
    }

    const handleClearDate=function(index){//here index value can be 1 or 2 (1-> fromdate and 2-> todate).
        if(!userValue[1]||!userValue[2]){//this condition will be true if any of the feild value (fromdate or todate) is empty then it will slice to index 0
            setUserValue([...userValue.slice(0,1)])
        }
        else{//this condition will be true if both the fields has value(fromdate and todate), then at that index it will set as empty string('').
            setUserValue(prevState=>{prevState.splice(index,1,'');
            return [...prevState]
        })//so by using splicing method of array, removing value and inserting empty string in the particular index
        // here prevState is nothing but the existing state value, modifying it and then setting a new value to the state
        }
    }

    const onHandleDropdownValueCHange=(e)=>{
        setUserValue([e.target.value,...userValue.slice(1,3)]);
    }

    useEffect(()=>{
        if(userValue.length>0){
            //note:sending true to get whether the field is datepicker,rangeFilter or not
            onValueChanged(fieldName,userValue,true)
        }
    },[userValue])

    useEffect(()=>{
        if(userSelectedValue.length==0 || JSON.stringify(userSelectedValue)!=JSON.stringify(userValue)){
            setUserValue(['between'])
        }
    },[userSelectedValue,isFilterCleared])

    const ITEM_HEIGHT = 48;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: 250,
            },
        },
    };

    return (<div className="datesFields"><span className="dropdownFilter"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel> <NttDropdown
        selectClassName="dropdownStyle"
        formControlClassName="dropdownStyle"
        inputLabelId="demo-mutiple-checkbox-label"
        selectLabelId="demo-mutiple-checkbox-label"
        selectId="demo-mutiple-checkbox"
        selectVariant="outlined"
        selectValue={(userValue[0] === undefined) ? "between" : userValue[0]}
        selectOnchange={(e)=>{onHandleDropdownValueCHange(e)}}
        selectCustomInput={<Input />}
        selectMenuProps={MenuProps}
    >
            <MenuItem value="between">is between</MenuItem>
            <MenuItem value="greater">is greater than</MenuItem>
            <MenuItem value="less">is less than</MenuItem>
            <MenuItem value="equal">is equal</MenuItem>
            <MenuItem value="blanks">is blank</MenuItem>
        </NttDropdown></span>

        {userValue[0]!='blanks'?<div className='datePickerContainer'><MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className="muiDatePicker"
                    margin="normal"
                    id="date-picker-dialog"
                    format={isFullDate?"dd-MMM-yy":"MMM-yy"}
                    value={!userValue[1] ? null : moment(userValue[1]).utc()}
                    onChange={(e)=>{handleFromDateChange(e)}}
                    orientation="landscape"
                    views={isFullDate?["year", "month","date"]:["year", "month"]}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    variant='inline'
                    autoOk={true}
                />
            </MuiPickersUtilsProvider>
            {(userValue[1])?<Clear onClick={()=>handleClearDate(1)}/>:""}</div>:<div></div>}
        {(userValue[0] === "between" || userValue[0] === undefined) && userValue[0]!='blanks' && userValue[0]!='equal' ? 
        <span> <label className='andtext'>and</label>
       <div class='datePickerContainer'><MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className="muiDatePicker"
                    margin="normal"
                    id="date-picker-dialog"
                    format={isFullDate?"dd-MMM-yy":"MMM-yy"}
                    value={!userValue[2] ? null : moment(userValue[2]).utc()}
                    onChange={(e)=>{handleToDateChange(e)}}
                    orientation="landscape"
                    views={isFullDate?["year", "month","date"]:["year", "month"]}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    variant='inline'
                    autoOk={true}
                />
            </MuiPickersUtilsProvider>{(userValue[2])?<Clear onClick={()=>handleClearDate(2)}/>:""}</div></span> : <div></div>}
        </div>);
}

export default DatePickerFilter;