import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { checkAndLoadDimention, createParameterMap, minifyTransaction } from '../../../../utils/utils';
import { PARAMETERS_SESSION_STORAGE_NAME } from '../../../../Constants';

export async function getCoreDemandData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisecoreDemandResponse = client.apis.revenuefunction.getRfCoreDemand({'pageno':pageno},{requestBody:{'rfid':rfid}});
    
    const responsesArray = await Promise.all([promisecoreDemandResponse]);
    const [coreDemandResponse] = responsesArray.map(o => o.body.data);

     //Based on clients requirement removing the DEMAND_BY_CAR_CLASS validation 
    //Keeping below code for future reference incase if they want this again 

    //const promisecoreParameterResponse = client.apis.revenuefunction.getRFParameters({},{requestBody:{'rfid':rfid}});
    //const responsesArray = await Promise.all([promisecoreDemandResponse,promisecoreParameterResponse]);

    //const [coreDemandResponse,coreParameterResponse] = responsesArray.map(o => o.body.data);

    //Setting the Core Parameters in session so that it is available from vaidatiuon reducers
    //sessionStorage.setItem(PARAMETERS_SESSION_STORAGE_NAME,JSON.stringify(createParameterMap(coreParameterResponse)));

    return ({
        "gridData": coreDemandResponse.rows,"rowcount":coreDemandResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreDemandData failed'}
   }
   }

export async function getCoreDemandDropdownService(){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisePoolResponse =  checkAndLoadDimention("pool", client);
        const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
        const promiseCarClassCodeResponse =  checkAndLoadDimention("carClassCode", client);
        const promiseGetAdminCarClasses =  checkAndLoadDimention("admincarclassAndgroup", client);
        const responsesArray = await Promise.all([
                promiseCarClassCodeResponse, 
                promisePoolResponse,
                promiseAdminPoolResponse,
                promiseGetAdminCarClasses
        ]);

        const [
                carClassCodeResponse,
                poolResponse,
                adminPoolResponse,
                adminCarClasses
        ] = responsesArray.map(o => o.body.data);
        //let adminCarClassCodeResponse=carClassCodeResponse.concat(responsesArray[0].body.deletedData);

        return ({
            "carClasses": carClassCodeResponse,
            "adminCarClasses": adminCarClasses,
            "pools": poolResponse,
            "adminPools": adminPoolResponse,
        })  
    }catch(error){

    }
}

export async function saveRfCoreDemandTransactions(rfid,transactionList,tabname){
    try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.revenuefunction.bulktransactrfcoredemand({"rfid":rfid},  {requestBody: { "updatedBy": userEmail,"tabName":tabname,"transactions": minifyTransaction(transactionList)}});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
    }catch(error){

    }
}

export async function uploadCoreDemandCSV(rfid,tabName,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.revenuefunction.uploadRfCoreDemand({"rfid":rfid},{
            requestBody: {
                "updatedBy":userEmail,
                "tabName":tabName,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error("Failed to upload due to: ", error.message);
        return {'Error':'uploadProgramCSV failed'}
    }
}

export async function coreDemandTabValidationService(rfid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.revenuefunction.validateRFCoreDemand({"rfid":rfid},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
    //    console.log("validateRFCoreDemand==>",response);
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       return {'Error':'coredemandvalidationservice failed'}
    }
   }

export async function getCoreDemandFilterData(rfid,pageno,filterparams){
 try{
    const accessToken = await getAccessTokenAfterAuth();
    
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    const promisecoredemandFilterResponse = await client.apis.revenuefunction.getRFCoreDemandFilterRecords({'pageno':pageno},{requestBody:{'rfid':rfid,'filterparams':filterparams}});
    const responsesArray = await Promise.all([promisecoredemandFilterResponse]);
    const [coreDemandResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": coreDemandResponse.rows,"rowcount":coreDemandResponse.rows[0]?coreDemandResponse.rows[0].rowcount:0
    });
    }
    catch (error) {
        console.error(error);
        return {'Error':'getcoreDemandFilterData failed'}
    }
}

export async function getCoreDemandTabErrorStatus(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisecoreDemandResponse = await client.apis.revenuefunction.getRFCoreDemandTabErrorStatus({'rfid':rfid});
    const responsesArray = await Promise.all([promisecoreDemandResponse]);
    const [coreDemandTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return coreDemandTabErrorStatusResponse;
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreDemandTabErrorStatus failed'}
   }
}

export async function deleteAllCoreDemandData(rfid,params){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
       const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
       
       let promiseRfCoreDemandDeleteResponse = await client.apis.revenuefunction.deleteAllRfCoreDemandRecords({ "rfid": rfid },{requestBody: {"updatedBy":userEmail,...params}});
       return promiseRfCoreDemandDeleteResponse;
    } 
    catch (error) {
       console.error(error);
       return {'Error':'deleteAllCoreDemandData failed'}
    }
   }


export async function insertS3UploadCoreDemandCSV(rfid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.revenuefunction.insertS3UploadedRfCoreDemandData({"rfid":rfid},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace('grid',''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'insert of uploaded core demand CSV failed'}
    }
}

export async function getCoreDemandErrorData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisecoreDemandErrorResponse = await client.apis.revenuefunction.getRFCoreDemandErrorRecords({'pageno':pageno,'rfid':rfid},null);
    
    const responsesArray = await Promise.all([promisecoreDemandErrorResponse]);
    
    const [coreDemandResponse] = responsesArray.map(o => o.body.data);
    
    //return coreDemandResponse.obj.data;
    return ({
        "gridData": coreDemandResponse.rows,"rowcount":coreDemandResponse.rows[0]?coreDemandResponse.rows[0].rowcount:0
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreDemandErrorData failed'}
   }
}

export async function getCoreDemandSearchData(rfid,pageno,searchParams){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseCoreDemandSearchResponse =  client.apis.revenuefunction.getRFCoreDemandSearchRecords({ 'rfid':rfid,'pageno':pageno==0 || pageno==undefined?0:pageno},{requestBody: {"searchParams": searchParams } });
    const responsesArray = await Promise.all([promiseCoreDemandSearchResponse]);

    const [coreDemandSearchResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": coreDemandSearchResponse.rows,"rowcount":coreDemandSearchResponse.rows[0]?coreDemandSearchResponse.rows[0].rowcount:0
    });
}