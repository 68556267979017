import { combineReducers } from 'redux';
import MoveCostReducer from './MoveCost.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import moveCostColumnDefinitionReducer from "./MoveCost.ColumnDef.Reducer";

const rootMoveCostReducer = combineReducers({
    MoveCostReducer
    , moveCostGridReducer: createNamedWrapperReducer(multiGridUIReducer, "movecostgrid")
    , moveCostColumnDefinitionReducer
})

export default rootMoveCostReducer;