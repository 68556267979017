import { columnDefs, defaultColDef } from "./AddsInvalidPools_grid_data/AddsInvalidPoolsColumnDefs";
import { FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_PENDING,FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_SUCCESS,FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR, SET_ADDSINVALIDPOOLS_EDITABLE } from '../addsinvalidpools/AddsInvalidPools.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import { AddsInvalidPoolsColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            MANUFACTURER_GROUP: { error: checkforManufacturer(data.MANUFACTURER_GROUP,data.POOL,  AddsInvalidPoolsColumns.ManufacturerGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field=== AddsInvalidPoolsColumns.ManufacturerGroup.field)) },
            POOL: { error: checkforPool(data.POOL,data.MANUFACTURER_GROUP, AddsInvalidPoolsColumns.Pool.headerName,initialState.columnDefs.findIndex(itm=>itm.field===AddsInvalidPoolsColumns.Pool.field)) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkforManufacturer(value1,value2, name,index) {
    if (value1 === undefined || value1 === "" || value1 === null) {
        return "ManufacturerGroup cannot be empty";
    }
    else if(value1==='ALL' && value2==='ALL'){
        return 'Both ManufacturerGroup and  Pool cannot be ALL'
    }
    else if(initialState.columnDefs[index].cellEditorParams !== undefined){
        let allManufacturerGroup =sessionStorage.getItem("MANUFACTURER") ? (JSON.parse(sessionStorage.getItem("MANUFACTURER"))).body.data:[];
        if (allManufacturerGroup && allManufacturerGroup.length && (allManufacturerGroup.indexOf(value1) == -1)) {
            return name + " " + value1 + " no-longer available";
        }
    }
    return null;
}
function checkforPool(value1,value2, name,data) {
    if (value1 === undefined || value1 === "" || value1 === null) {
        return "Pool cannot be empty";
    }else
    if(value1==='ALL' && value2==='ALL'){
        return 'Both ManufacturerGroup and  Pool cannot be ALL'
    }else if(value1==='ALL'){
        return name + " cannot be ALL";
    }else
    if (initialState.columnDefs[data].cellEditorParams !== undefined) {
        if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value1) == -1) {
            return name +" "+value1+" no-longer available";
        }
    }
    return null;
}

function OptionalValue() {
    return null;
}


function validateAddsInvalidPoolsFields( max, displayName) {
    if ( max === undefined ||  max === '' ||  max === null) {
        return displayName + ' cannot be empty';
    }
   else if (max < 0) {
            return 'Please enter valid ' + displayName
        }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.MANUFACTURER_GROUP, itm2.MANUFACTURER_GROUP) &&
            safeCaseInsensitiveCompare(itm.POOL,itm2.POOL)) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function fetchDropdownValueOthers(manufacturerGroup, adminManufacturerGroup,element) {
    let manufacturerGroupFilterData = [];
    adminManufacturerGroup.map((val) => {
        manufacturerGroupFilterData.push({ "label": val.code, "value": val.code })
    })
    manufacturerGroupFilterData.push({"label":"Blanks","value":"Blanks"})
    element.cellEditorParams = { options: manufacturerGroup }
    element.filterOptions = manufacturerGroupFilterData;
}
function fetchDropdownsForPools(pool,adminpool, element) {
    var poolData = [],poolsFilterData = [];
    poolData.push("ALL")
    pool.forEach(val => {
        if (val.code != 'ALL')
            poolData.push(val.code)
    });
    adminpool.forEach(val=>{
        poolsFilterData.push({"label":val.code,"value":val.code})
  })
  poolsFilterData.push({"label":"Blanks","value":"Blanks"})  
    element.cellEditorParams = { options: poolData }
    element.filterOptions=poolsFilterData
}
function fetchFilterValues( manufacturerGroup,pool,adminManufacturerGroup,adminpool,users,columns) {
    columns.forEach(element => {
        if (element.field === AddsInvalidPoolsColumns.Pool.field) {
            fetchDropdownsForPools(pool,adminpool, element);
        } else if (element.field === AddsInvalidPoolsColumns.ManufacturerGroup.field) {
            fetchDropdownValueOthers(manufacturerGroup,adminManufacturerGroup,element);
        } else if(element.field === AddsInvalidPoolsColumns.UpdatedBy.field) {
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'AddsInvalidPoolsActionRenderer'
            }
        }
    });

    return columns;
}

export default function addsInvalidPoolsColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(
                    action.data.manufacturer_group,
                    action.data.pool,action.data.adminManufacturerGroup,action.data.adminpool,action.data.users, state.columnDefs)
            });
        case FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_ADDSINVALIDPOOLS_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}