import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './SalesAndFinanceAccordionContainer.css';
// import { fetchGroupByTypeOrZone, fetchGroupByYear } from '../../store/salesandfinance/SalesAndFinance.Actions';
import SalesAdjustmentView from '../../components/salesandfinance/accordion_view/SalesAdjustmentView';
import { ScrollSync } from 'react-scroll-sync';
// import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';


function SalesAndFinanceAccordionContainer(props) {
    const { accordionData,  plansDataById, zones} = props;

    return (
        <ScrollSync>
            <div className='width100percent'>
                {accordionData && Object.keys(accordionData).length && plansDataById && plansDataById.length>0 ?
                    <SalesAdjustmentView accordionData={accordionData} plansDataById={plansDataById} zones={zones}/>
                    :
                    <div className="norecords">No Records To Show</div>
                } 
            </div>
        </ScrollSync>
    )
}

export default SalesAndFinanceAccordionContainer;