import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import PlanDEtailsStyle from './PlanInputDetailsMinimized.css';
import { TextFieldInput } from '../../core-components/Input/Input';
import { NttDropdown } from '../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import InputPage from '../../containers/inputs/InputsPage';
import { saveDescription, saveZoneRf, savePoolRf, getPlanBySelectedPlanId, getZoneRfData, getPoolRfData } from "../../store/topbar/AllPlans.Actions";
import { Tooltip } from '@material-ui/core';
import MainPlanInputsPage from '../../containers/new_inputs/MainPlanInputsPage';
import { escapeHtml, unEscapeHtml } from '../../utils/utils';
const CUEMSG_HIT_ENTER_TO_SAVE = 'Hit Enter to save.';
const CUEMSG_ENTER_DESCRIPTION = 'Nothing to save.';
export default function PlanInputDetails(props) {
    const {
        selectedRowData,
        selectedPlanId,
        zoneRfData, poolRfData,
        isMinimized, setIsMinimized
    } = props;

    let ZoneRf = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ZoneRf;
    });

    let poolRf = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.poolRf;
    });

    
    let zoneRfTemp=zoneRfData && zoneRfData.length?zoneRfData.find(value=>value.name === ZoneRf):null;
    let poolRfTemp=poolRfData && poolRfData.length?poolRfData.find(value=>value.name === poolRf):null;
    let isCopiedFromEnabled=selectedRowData.copied_from !== 'master';
    return (
        <Tooltip title="Click to Open" placement='bottom' arrow>
        <div className={isCopiedFromEnabled?'plandetailsminimized_withcopiedfrom':'plandetailsminimized'} onClick={()=>setIsMinimized(!isMinimized)}>
                <div className='descriptionelements'><b>Description:</b> {unEscapeHtml(selectedRowData.description)}</div>
                <div className='rfelements'><b>Zone RF:</b> {ZoneRf}: {zoneRfTemp?zoneRfTemp.description:''}</div>
                <div className='rfelements'><b>Pool RF:</b> {poolRf}: {poolRfTemp?poolRfTemp.description:''}</div>
            </div>
        </Tooltip>
    )
}