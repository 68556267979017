import { columnDefs, defaultColDef } from "./schedules_grid_data/SchedulesColumnDefs";
import { FETCH_SCHEDULES_DROPDOWNVALUES_PENDING, FETCH_SCHEDULES_DROPDOWNVALUES_SUCCESS, FETCH_SCHEDULES_DROPDOWNVALUES_ERROR, SET_SCHEDULES_EDITABLE } from '../schedules/Schedules.ActionTypes';
import { checkDate, getManufacturerBasedOnModel, safeCaseInsensitiveCompare,getDropdownValuesForYear } from "../../utils/utils";
import { SchedulesColumns, maximumDate, minimumDate} from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            program_year: { error: checkIfValidYear(data.program_year, SchedulesColumns.ProgramYear.headerName) },
            program_type: { error: checkIfValueEmpty(data.program_type, SchedulesColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueEmpty(data.spi_code, SchedulesColumns.SpiCode.headerName) },
            model_year: { error: checkIfValidYear(data.model_year, SchedulesColumns.ModelYear.headerName) },
            model_group: { error: checkIfValueEmpty(data.model_group, SchedulesColumns.ModelGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===SchedulesColumns.ModelGroup.field)) },
            manufacturer_group: { error: checkIfValueEmpty(data.manufacturer_group, SchedulesColumns.ManufacturerGroup.headerName, data.model_group) },
            car_class_code: { error: checkIfValueEmpty(data.car_class_code, SchedulesColumns.CarClassCode.headerName,initialState.columnDefs.findIndex(itm=>itm.field===SchedulesColumns.CarClassCode.field)) },
            min_schedule: { error: validateMinMaxFields(data.min_schedule, data.max_schedule, SchedulesColumns.MinSchedule.headerName,SchedulesColumns.MaxSchedule.headerName) },
            max_schedule: { error: validateMinMaxFields(data.min_schedule, data.max_schedule, SchedulesColumns.MinSchedule.headerName,SchedulesColumns.MaxSchedule.headerName) },
            allocation_month: { error: checkIfValueEmpty(data.allocation_month,SchedulesColumns.AllocationMonth.headerName, data.allocation_month) },
            acceptable_zones: { error: checkIfValueEmpty(data.acceptable_zones, SchedulesColumns.AcceptableZones.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id'  && value !== 'allocation_month' && value!=='updated_by'&& value != 'updated_by_name') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name, data) {
    if (name === SchedulesColumns.CarClassCode.headerName || name === SchedulesColumns.ModelGroup.headerName) {
        if (value === undefined || value === "" || value === null) {
            return name + " " + "cannot be empty";
        } else if (initialState.columnDefs[data].cellEditorParams !== undefined) {
            if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                return name + " " + value + " no-longer available";
            }
        }
    }
    if (name === SchedulesColumns.ManufacturerGroup.headerName) {
        if (!data) {
            return "Please select a Model group"; //First Modal group" should be selected before selecting a manufacturer group
        } else if (value === undefined || value === "" || value === null) {
            return name + " " + "cannot be empty";
        }
        else {
            let manArray=getManufacturerBasedOnModel(data);
            if((manArray && manArray.length && manArray.indexOf(value)===-1) || (manArray===undefined)){
                return `${value} is not a valid Manufacturer for Model ${data}`;
            }
        }
    }
    if(name===SchedulesColumns.AllocationMonth.headerName){
        // var itemValue = parseInt(value, 0);
        // if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
        //     return name + " should be in +5 or -10 range from the current year"
        // }
    
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        }
        else if (value == 'Invalid date') {
            return name + " " + "cannot be invalid date";
        }
        else if (!moment(value).isBetween(minimumDate, maximumDate, 'year', '[]')) {
            return name + " should be in +5 or -10 range from the current year"
        }
    }
    return null;
}

function checkIfValidYear(value, name) {
    if (value === undefined || value === '' || value === null)
        return name + " cannot be empty";
    else {
        var itemValue = parseInt(value, 0);
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10))
            return name + " should be in +5 or -10 range from the current year"
    }
    return null;
}

function validateMinMaxFields(min, max, minDisplayName, maxDisplayName) {

    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Fields cannot be empty';
    }
    if (parseInt(min) > parseInt(max)) {
        return minDisplayName + ' should be less than ' + maxDisplayName
    }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.spi_code, itm2.spi_code) && safeCaseInsensitiveCompare(itm.car_class_code, itm2.car_class_code) &&
                safeCaseInsensitiveCompare(itm.model_group, itm2.model_group) && safeCaseInsensitiveCompare(itm.program_type, itm2.program_type) &&
                (itm.model_year === itm2.model_year) && (itm.program_year === itm2.program_year) && checkDate(itm.allocation_month,itm2.allocation_month)
                && safeCaseInsensitiveCompare(itm.manufacturer_group, itm2.manufacturer_group) ) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === SchedulesColumns.ModelYear.field) {
            column.filterOptions = getDropdownValuesForYear()
        }
        if (column.field === SchedulesColumns.ProgramYear.field) {
            column.filterOptions = getDropdownValuesForYear()
        }
        if (column.field === SchedulesColumns.ProgramType.field) {
            let programTypeFilterData = [];
            data.programType.map((val) => {
                programTypeFilterData.push({ "label": val, "value": val })
            })
            programTypeFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.programType }
            column.filterOptions = programTypeFilterData
        }
        if (column.field ===  SchedulesColumns.SpiCode.field) {
            let spiCodeFilterData = [];
            data.spiCode.map((val) => {
                spiCodeFilterData.push({ "label": val, "value": val })
            })
            spiCodeFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.spiCode }
            column.filterOptions = spiCodeFilterData;
        }
        if (column.field === SchedulesColumns.ModelGroup.field) {
            let modelGroupFilterData = [];
            data.adminModelGroup.map((val) => {
                modelGroupFilterData.push({ "label": val, "value": val })
            })
            modelGroupFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.modelGroup }
            column.filterOptions = modelGroupFilterData;
        }
        if (column.field === SchedulesColumns.CarClassCode.field) {
            let carClassCodeFilterData = [];
            data.adminCarClass.map((val) => {
                carClassCodeFilterData.push({ "label": val.car_class, "value": val.car_class })
            })
            carClassCodeFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.carClassCode }
            column.filterOptions = carClassCodeFilterData;
        }
        if (column.field === SchedulesColumns.AcceptableZones.field) {
            let zonesFilterData = [];
            data.zone.map((val) => {
                zonesFilterData.push({ "label": val, "value": val })
            })
            zonesFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.cellEditorParams = { options: data.zone }
            column.filterOptions = zonesFilterData;
        }
        if (column.field === SchedulesColumns.ManufacturerGroup.field) {
            let manufacturerFilterData = [];
            data.adminManufacturerGroup.map((val) => {
                    manufacturerFilterData.push({ "label": val.code, "value": val.code })
            })
            manufacturerFilterData.push({ "label": "Blanks", "value": "Blanks" })
            column.filterOptions = manufacturerFilterData;
        }
        if (column.field == SchedulesColumns.UpdatedBy.field) {
            let userKeys = Object.keys(data.users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": data.users[userData], "value": data.users[userData] })
            })
            column.filterOptions = usersFilterData
        }
        if (column.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                column.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                column.cellRenderer = 'SchedulesActionRenderer'
            }
        }
    });
    return columnDefs;
}

export default function schedulesColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SCHEDULES_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_SCHEDULES_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_SCHEDULES_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_SCHEDULES_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}