export const FETCH_PLAN_MANAGEMENT_DROPDOWN_PENDING = 'FETCH_PLAN_MANAGEMENT_DROPDOWN_PENDING';
export const FETCH_PLAN_MANAGEMENT_DROPDOWN_SUCCESS = 'FETCH_PLAN_MANAGEMENT_DROPDOWN_SUCCESS';
export const FETCH_PLAN_MANAGEMENT_DROPDOWN_ERROR = 'FETCH_PLAN_MANAGEMENT_DROPDOWN_ERROR';

export const SAVE_PLAN_MANAGEMENT_TRANSACTION_PENDING = 'SAVE_PLAN_MANAGEMENT_TRANSACTION_PENDING';
export const SAVE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS = 'SAVE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS';
export const SAVE_PLAN_MANAGEMENT_TRANSACTION_ERROR = 'SAVE_PLAN_MANAGEMENT_TRANSACTION_ERROR';

export const DELETE_PLAN_MANAGEMENT_TRANSACTION_PENDING = 'DELETE_PLAN_MANAGEMENT_TRANSACTION_PENDING';
export const DELETE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS = 'DELETE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS';
export const DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR = 'DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR';
export const DELETE_PLAN_MANAGEMENT_TRANSACTION_IN_PROGRESS='DELETE_PLAN_MANAGEMENT_TRANSACTION_IN_PROGRESS';