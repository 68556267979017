import React, { useState, useEffect, useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './MainPlanInputsPage.css';
import PropTypes from "prop-types";
import NttTab from "../../components/tabs/NttTab"
import NttTabs from "../../components/tabs/NttTabs";
import { ADDITIONAL_COST, ADDS_INVALID_POOLS, AGE_MILEAGE, ALLOCATED, CONSTRAINT, DEP_RATE, DIVERSIONS, MAX_DEL_ZONE, MILEAGE_DEFAULT, MILEAGE_TABLE, MOVE_CONSTRAINT, MOVE_COST, PARAMETERS, planInputSubTablesData, planInputTabData as tabsData, PROGRAM, SALVAGE_RATE, SCHEDULE, TARGET_ZONE, upgradeButtons, UPGRADE_PATH, UPGRADE_TEMPLATE, MessageNotificationValues, planAssumptionTabsForMainInputs, PlanInputsTabStyle } from '../../Constants';
import { UpdateMasterDataCache, fetchGridData, fetchGridTabErrorStatus, fetchMultiTabErrorStatusBasedOnModule, resetGridState, saveGridTransactionData, setGridFilterParams, setGridFilterViewStatus, setGridName, setGridUserPageNumber, validateAllGridsData, validateSingleGridsData } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { addNewRow, populateGrid, populateGrid2, validateRow, refreshGridData, cleanTransactions, validateGrid, deleteRow, deleteRowEditMode, ResetMultiGridState } from '../../store/MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { fetchProgramDropdownValues } from "../../store/programs/Programs.Actions";
import MainPlanInputsTabPanel from './MainPlanInputsTabPanel';
import { addNotificationMessage, updatePlanEditDisabled } from '../../store/topbar/AllPlans.Actions';
import { isPlanningStatusRunning } from '../../utils/utils';
import { fetchSchedulesDropdownValues } from '../../store/schedules/Schedules.Actions';
import { fetchAllocatedDropdownValues } from '../../store/allocated/Allocated.Actions';
import { fetchMoveConstraintDropdownValues } from '../../store/moveconstraints/MoveConstraints.Actions';
import { fetchConstraintsOptions } from '../../store/constraints/Constraints.Actions';
import { Button } from '@material-ui/core';
import { fetchUpgradeCarClassgroup } from '../../store/upgradepaths/UpgradePaths.Actions';
import { fetchParametersDropDownData } from '../../store/parameters/Parameters.Actions';

const MainPlanInputsPage = (props) => {
  const { selectedPlanId, saveUpdatedResponse} = props
  //const [value, setValue] = useState(0);
  const [showSaveOption, setShowSaveOption] = useState(false);
  const dispatch = useDispatch();

  const value = useRef(0)
  let selectedurlHasMainInputs = window.location.href.indexOf("maininputs") > -1;

  const pending = useSelector((state) => {
    return state.PaginationGridReducer.pending;
  });

  let plansDataById = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.plansDataById;
  });

  let ifPlanEditDisabled = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
  });

  const copyToMainInputsStatus = useSelector((state) => {
    let copyPlanToMainInputsInProgress = state.rootAllPlansReducer.allPlansReducer.copyPlanToMainInputsInProgress;
    let tabsToBeCopiedToMain = state.rootAllPlansReducer.allPlansReducer.tabsToBeCopiedToMain;
    if (copyPlanToMainInputsInProgress) {
      return tabsToBeCopiedToMain;
    } else {
      return [];
    }
  });

  function updateGridColDefs(gridname, colDef) {
    if (copyToMainInputsStatus.some(tabName => tabName === gridname) || ifPlanEditDisabled) {
      colDef.defaultColDef.editable = false;
    } else {
      colDef.defaultColDef.editable = true;
    }
    return colDef;
  }

  const ProgramGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(PROGRAM, state.ProgramscolumnDefinitionReducer)
  });

  const schedulesGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(SCHEDULE, state.schedulesColumnDefinitionReducer)
  })

  const allocatedGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(ALLOCATED, state.allocatedColumnDefinitionReducer)
  })

  const constraintsGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(CONSTRAINT, state.constraintsColumnDefReducer)
  })

  const moveConstraintsGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(MOVE_CONSTRAINT, state.moveConstraintsColumnDefReducer)
  });

  const upgradePathsGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(UPGRADE_PATH, state.upgradePathsColumnDefReducer)
  })
  const upgradetemplateGridColDefReducer = useSelector((state) => {
    return state.upgradetemplateColumnDefReducer;
  })

  const parametersGridColDefReducer = useSelector((state) => {
    return updateGridColDefs(PARAMETERS, state.parametersColumnDefReducer);
  });

  const gridName = useSelector((state) => {
    return state.PaginationGridReducer.gridName
  })

  const gridData = useSelector((state) => {
    return state.PaginationGridReducer.gridData;
  });

  const GridReducer = useSelector((state) => {
    return state.inputsMultiGridUIReducer
  });

  const masterDataUpdateState = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  });

  const gridFilterUserSelectedParams = useSelector((state) => {
    return state.PaginationGridReducer.gridFilterUserSelectedParams;
  });

  const gridFilterViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridFilterView};
  },shallowEqual);

  const gridUserPageno = useSelector((state) => {
    return state.PaginationGridReducer.gridUserPageno;
  });

  const gridErrorViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridErrorView};
  },shallowEqual);

  const gridSearchViewStatus = useSelector((state)=>{
    return {...state.PaginationGridReducer.gridSearchView};
  },shallowEqual)

  const gridUserSearchParams = useSelector((state) => {
    return {...state.PaginationGridReducer.gridUserSearchParams};
  },shallowEqual);

  const highlightAllTabsErrorStatus = useSelector((state) => {
    return state.PaginationGridReducer.gridTabErrorStatus;
  })

  const callGridFetchApi = useSelector((state)=>{
    return state.PaginationGridReducer.callGridFetchApi;
  })

  const [GridColDefReducer, setGridColDefReducer] = useState(ProgramGridColDefReducer);
 
  const loadGridData = useCallback(async (isValidationRequired, manualPageNo) => {
    try {
      if (isValidationRequired) {// if this condition is true then validateSingleGridsData action will be dispatched instead of fetchGridTabErrorStatus
        await dispatch(validateSingleGridsData(gridName, selectedPlanId));
        dispatch(fetchGridData(selectedPlanId, gridName, manualPageNo ? manualPageNo : gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName], gridErrorViewStatus[gridName], gridUserSearchParams[gridName], gridSearchViewStatus[gridName]))
      }
      else {// this condition will be dispatched when isValidationRequired param is not defined or when it is false
        await Promise.all([dispatch(fetchGridTabErrorStatus(selectedPlanId, gridName)),
        dispatch(fetchGridData(selectedPlanId, gridName, manualPageNo ? manualPageNo : gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName], gridErrorViewStatus[gridName], gridUserSearchParams[gridName], gridSearchViewStatus[gridName]))])

      }
    } catch (err) {
      console.error('got error while loading griddata');
    }
  }, [gridUserPageno, callGridFetchApi]);

  const initializeFilterParamsAndViewStatus = useCallback((gridname,GridColDefReducer) => {
    try {
      if(gridname!='' && !gridFilterUserSelectedParams[gridname]){
        dispatch(setGridFilterParams(gridname,GridColDefReducer,true))
        dispatch(setGridFilterViewStatus(gridname,false));
      }
    } catch (err) {
        console.error('got error while setting filterparams & filterview status');
    }
  }, [dispatch]);

  // const loadGridDataForFilterParams = useCallback(async(filterParams,filterStatus) => {
  //   try {
  //     dispatch(fetchGridData(selectedPlanId, gridName, gridUserPageno,filterParams,filterStatus));
  //     dispatch(fetchGridTabErrorStatus(selectedPlanId,gridName))
  //   } catch (err) {
  //       console.error('got error while loading filtered griddata');
  //   }
  // }, [gridFilterViewStatus]);

  // const loadGridDataForErrorView = useCallback(async (errorView) => {
  //   try {
  //     dispatch(fetchGridData(selectedPlanId, gridName, gridUserPageno,{},false,errorView));
  //     dispatch(fetchGridTabErrorStatus(selectedPlanId, gridName))
  //   } catch (err) {
  //     console.error('got error while loading grid errors');
  //   }
  // }, [gridErrorViewStatus]);

  // const loadGridDataForSearchParam = useCallback(async(searchParams,searchStatus)=>{
  //   try{
  //     dispatch(fetchGridData(selectedPlanId, gridName,gridUserPageno,{},false,false,searchParams,searchStatus));
  //     dispatch(fetchGridTabErrorStatus(selectedPlanId, gridName))
  //   }
  //   catch(err){
  //     console.error('got error while loading searched griddata');
  //   }
  // },[gridSearchViewStatus])

  const isPlanAssumptionInvalid=(isActive)=>{
    const isInvalid = planAssumptionTabsForMainInputs.some((val)=>{
        if(highlightAllTabsErrorStatus[val.gridName] === true){
          return true;
        }
    })
    if(isInvalid){
      return isActive ? PlanInputsTabStyle.activeError_tab : PlanInputsTabStyle.defaultError_tab
    } else {
      return isActive ? PlanInputsTabStyle.active_tab : PlanInputsTabStyle.default_tab
    }
  }

  const getStyle = (isActive, tabKey, index, gridname) => {
  //this if condition is to display the yellow animation on the tabs.
    if(planInputSubTablesData[index].some(tabName=>copyToMainInputsStatus.includes(tabName))){
      return isActive ? PlanInputsTabStyle.copyInProgressIndicator_active :  PlanInputsTabStyle.copyInProgressIndicator
    }
     
    if (tabKey.gridName==='planassumptionsGrid'){
      return isPlanAssumptionInvalid(isActive);
    }

    if (highlightAllTabsErrorStatus[tabKey.gridName] === true) {
      return isActive ? PlanInputsTabStyle.activeError_tab : PlanInputsTabStyle.defaultError_tab
    } else {
      return isActive ? PlanInputsTabStyle.active_tab : PlanInputsTabStyle.default_tab
    }
  }

  const handleChange = useCallback(async (event, newValue, isPlanChanged) => {
    if (value.current != newValue || isPlanChanged) {
      //setValue(newValue)
      value.current = newValue
      dispatch(resetGridState(false));
      dispatch(ResetMultiGridState())
      await dispatch(UpdateMasterDataCache())
      dispatch(setGridUserPageNumber(1))
      switch (newValue) {
        case 0:
          await dispatch(fetchProgramDropdownValues());
          setGridColDefReducer(ProgramGridColDefReducer);
          dispatch(setGridName('programsGrid'));
          break;
        case 1:
          await dispatch(fetchSchedulesDropdownValues());
          setGridColDefReducer(schedulesGridColDefReducer);
          dispatch(setGridName('schedulesGrid'));
          break;
        case 2:
          await dispatch(fetchAllocatedDropdownValues());
          setGridColDefReducer(allocatedGridColDefReducer);
          dispatch(setGridName('allocatedGrid'));
          break;
        case 3:
          await dispatch(fetchConstraintsOptions());
          setGridColDefReducer(constraintsGridColDefReducer);
          dispatch(setGridName('constraintsGrid'));
          break;
        case 4:
          await dispatch(fetchMoveConstraintDropdownValues());
          setGridColDefReducer(moveConstraintsGridColDefReducer);
          dispatch(setGridName('moveconstraintsGrid'));
          break;

        case 5:
          if (gridName === 'upgradepathsGrid') {
            setGridColDefReducer(upgradetemplateGridColDefReducer);
            dispatch(setGridName('upgradetemplatesGrid'));
          }
          else {
            await dispatch(fetchUpgradeCarClassgroup());
            setGridColDefReducer(upgradePathsGridColDefReducer);
            dispatch(setGridName('upgradepathsGrid'));
          }
          break;
          // case 6://plan assumptions
          // await dispatch(fetchMoveConstraintDropdownValues());
          // setGridColDefReducer(moveConstraintsGridColDefReducer);
          // dispatch(setGridName('moveconstraintsGrid'));
          // break;
        case 7:
          await dispatch(fetchParametersDropDownData(selectedPlanId));
          setGridColDefReducer(parametersGridColDefReducer);
          dispatch(setGridName('parametersGrid'));
          break;
        default:
          break;
      }
    }
  }, [gridName])


  useEffect(() => {
    let planninngtesting = isPlanningStatusRunning(selectedurlHasMainInputs, plansDataById);
    dispatch(updatePlanEditDisabled(planninngtesting));
  }, [ifPlanEditDisabled, plansDataById]);

  useEffect(() => {
    dispatch(fetchMultiTabErrorStatusBasedOnModule(selectedPlanId,gridName,'MAIN_AND_PLAN-INPUTS'))
    handleChange("", 0, true)
    return () => {
      // this is to set newArchitecture as false when unmounting the inputs page and will be removed after acceptance
      localStorage.setItem("newArchitecture",false)
      dispatch(resetGridState(true));
    }
  }, [])

  // useEffect(() => {
  //   if (gridName != "") {
  //     loadGridData();
  //   }
  // }, [gridUserPageno])

  useEffect(() => {
    if (callGridFetchApi == true) {
     loadGridData();
    }
  }, [callGridFetchApi])//this useeffect triggers when there is a change in gridName,gridFilterViewStatus,gridErrorViewStatus,gridSearchViewStatus.

  useEffect(() => {
    if ((gridData && GridColDefReducer && gridName != '')) {
      dispatch(refreshGridData())
      dispatch(populateGrid2(gridName, gridData, gridData, '', GridColDefReducer.checkValue, GridColDefReducer.validationFn, GridColDefReducer.businessValidationFn,GridColDefReducer.checkIfRequiredFn));
      dispatch(validateGrid(gridName, gridData, GridColDefReducer.validationFn, GridColDefReducer.businessValidationFn));
    }
  }, [gridData]);

  useEffect(() => {
    if (GridReducer.transactions.length > 0) {
      setShowSaveOption(true)
    } else {
      setShowSaveOption(false);
    }
  }, [GridReducer.transactions]);


  return (
    <div className={selectedurlHasMainInputs ? "margin" : "marginleft"}>
      {selectedurlHasMainInputs ? <h2 className="headertext">Main Inputs</h2> : ""}
      <div>
        <NttTabs value={value.current} onChange={handleChange} style={PlanInputsTabStyle.tabItemContainer} indicatorColor='string'>
          {tabsData.map((tabInfo, index) => (
            <NttTab label={tabInfo.tabTitle}
              id={`simple-tab-${index}`}
              key={tabInfo.tabTitle}
              style={getStyle(value.current === index, tabInfo, index,gridName)}
              disabled={showSaveOption || pending}
            />
          ))}
        </NttTabs></div>
      {(value.current === 5) ?
        <div className="upgradeButtons">

          {upgradeButtons.map((type, index) => {
            return (
              <div className="upgradebtnstyle">
                <Button key={index}
                  //className={upgradeButtonSelected === type ? 'btnactive' : 'btninactive'}                 
                  className={gridName === type.tabInfo ? 'btnactive' : 'btninactive'}
                  onClick={(e) => handleChange(e, 5, gridName !== type.tabInfo)}
                >{type.tabTitle}</Button>
              </div>
            )
          })}
        </div> : ''}
      <MainPlanInputsTabPanel tabsData={tabsData}
        value={value.current}
        pending={pending}
        gridColDefReducer={GridColDefReducer}
        setGridColDefReducer={setGridColDefReducer}
        gridData={gridData}
        gridUserPageno={gridUserPageno}
        // gridReducer={GridReducer}
        setShowSaveOption={setShowSaveOption}
        showSaveOption={showSaveOption}
        selectedPlanId={selectedPlanId}
        gridName={gridName} loadGridData={loadGridData} 
        initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}
        //loadGridDataForFilterParams={loadGridDataForFilterParams}    
        //loadGridDataForErrorView={loadGridDataForErrorView}        
        //loadGridDataForSearchParam={loadGridDataForSearchParam}                 
        />
    </div>
  )
};
export default React.memo(MainPlanInputsPage);