import moment from "moment";
import { maximumDate, minimumDate } from "../../../../Constants";
import { checkDate, safeCaseInsensitiveCompare } from "../../../../utils/utils";
import { columnDefs, defaultColDef } from "../../mainrfinputs/tncice/tncice_grid_data/TncIceColumnDefs";
import {FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_PENDING,FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_SUCCESS,FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_ERROR} from "../tncice/TncIce.ActionTypes";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues:[],
    checkValue: function (value) {
        return {
            //value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            POOL: { error: checkIfValueEmpty(data.POOL, "POOL" ,initialState.columnDefs.findIndex(itm=>itm.field==='POOL')) },
            MONTH: { error: checkIfValueEmpty(data.MONTH, "MONTH") },
            COUNT: { error: checkIfValueEmpty(data.COUNT, "COUNT") },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
    var unique = true;
    cleaned.forEach(function (itm2) {
        if (safeCaseInsensitiveCompare(itm.POOL, itm2.POOL) && checkDate(itm.MONTH, itm2.MONTH)) {
            unique = false;
            if(itm.rowStatus == "ADD_ROW"){
                itm.rowStatus = "ERROR_NEW_ROW";
            }
            else if(itm2.rowStatus == "ADD_ROW"){
                itm2.rowStatus = "ERROR_NEW_ROW"
            }
            if(itm.rowStatus == "COPY_ROW"){
                itm.rowStatus = "ERROR_COPY_ROW";
                itm2.rowStatus = "ERROR_ROW";
            }
                else if(itm2.rowStatus == "COPY_ROW"){ //in case of multiple copy of rows
                itm2.rowStatus = "ERROR_COPY_ROW"
            }
            else {
                itm.rowStatus = "ERROR_ROW";
                itm2.rowStatus = "ERROR_ROW";
            }
            duplicate.push(itm);
            duplicate.push(itm2);
        }
    });
    transactions.forEach((itm1,key)=>{
        if(itm1.data){
            if((itm.ID===itm1.data.ID) && (transactions[key].data.rowStatus == "ERROR_COPY_ROW" || transactions[key].data.rowStatus == "ERROR_ROW") && itm.rowStatus==''){
            transactions[key].data.rowStatus=itm.rowStatus
            }
        }
        })
    if (unique && itm.rowStatus != "ADD_ROW") {
        itm.rowStatus = "";
        cleaned.push(itm);
    }
});
return duplicate;   
}

function checkIfValueEmpty(value, fiedName,data) {
    if (value === undefined || value === "" || value === null) {
        return fiedName +" "+"cannot be empty";
    }else if(fiedName==='COUNT'){
        if(!Number.isInteger(Number(value))){
            return fiedName +" "+"cannot have decimal values";
        }else if(value==0 || value<0){
            return fiedName +" "+"should be greater than 0";
        }else if(value.length>10){
            return fiedName +" "+"cannot have more than 10 digits";
        }else{
            return null;
        }
    }else if(fiedName==="MONTH"){
        // var itemValue = parseInt(value, 0);
        // if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
        //     return fiedName + " should be in +5 or -10 range from the current year"
        // }
        if(value == 'Invalid date'){
            return fiedName +" "+"cannot be invalid date";
        }
        else if(!moment(value).isBetween(minimumDate, maximumDate,'year','[]')){
            return fiedName + " should be in +5 or -10 range from the current year"
        }
        else{
            return null;
    }
    } else if(fiedName==="POOL"){
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
            if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                return fiedName +" "+value+" no-longer available";
            } else return null
        }
    }else{
        return null;
    }
}

function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = [];
    if(pools && pools.length>0){
    pools.forEach(val => {
        if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        }
    });
  }
  
  if(adminpools && adminpools.length>0){
    adminpools.forEach(val=>{
        poolFilterData.push({"label":val.code,"value":val.code})
      })
      poolFilterData.push({"label":"Blanks","value":"Blanks"})
  }

    element.cellEditorParams = { options: poolData }
    element.filterOptions=poolFilterData
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === "POOL") {
            fetchDropdownsForPools(data.pool,data.adminpools, column)
        }
    });
    return columnDefs;
}

export default function RFTncIcecolumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues:setColumnDefParams(state.columnDefs, [])
            });
        case FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_MAINRFINPUTS_TNCICE_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}