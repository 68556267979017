import { AgeMileageColumns, cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter, valueFormatter, localeFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: AgeMileageColumns.CarGroup.field, headerName: AgeMileageColumns.CarGroup.headerName, cellEditor: cellEditors.autoCompleteEditor,filterType:"dropdown",filterOptions:[],
        unSortIcon: true, 
        cellClassRules: cellClassRules(AgeMileageColumns.CarGroup.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.CarGroup.name)
        }
    }, 
    {
        field: AgeMileageColumns.ZoneName.field, headerName: AgeMileageColumns.ZoneName.headerName, cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(AgeMileageColumns.ZoneName.name), tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.ZoneName.name)
        }
    },
    {
        field: AgeMileageColumns.ProgramType.field, headerName: AgeMileageColumns.ProgramType.headerName, cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(AgeMileageColumns.ProgramType.name), tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.ProgramType.name)
        }
    },
    {
        field: AgeMileageColumns.MinAge.field, headerName: AgeMileageColumns.MinAge.headerName, unSortIcon: true, cellEditor: cellEditors.numericEditor,filterType:"input",
        type: 'numericColumn',
        valueFormatter: params => {
            return localeFormatter(params.data.MIN_AGE);
        },
        cellClassRules: cellClassRules(AgeMileageColumns.MinAge.name), tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.MinAge.name)
        }
    },
    {
        field: AgeMileageColumns.MaxAge.field, headerName: AgeMileageColumns.MaxAge.headerName, unSortIcon: true, cellEditor: cellEditors.numericEditor,filterType:"input",
        type: 'numericColumn',
        valueFormatter: params => {
            return localeFormatter(params.data.MAX_AGE);
        },
        cellClassRules: cellClassRules(AgeMileageColumns.MaxAge.name), tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.MaxAge.name)
        }
    },
    {
        field: AgeMileageColumns.MinMileage.field, headerName: AgeMileageColumns.MinMileage.headerName, minWidth: 110, unSortIcon: true,filterType:"input",
        cellEditor: cellEditors.numericEditor, 
        type: 'numericColumn',
        valueFormatter: params => {
                return localeFormatter(params.data.MIN_MILEAGE);
        },
        cellClassRules: cellClassRules(AgeMileageColumns.MinMileage.name), tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.MinMileage.name)
        }
    },
    {
        field: AgeMileageColumns.MaxMileage.field, headerName: AgeMileageColumns.MaxMileage.headerName, minWidth: 110, unSortIcon: true,filterType:"input",
        cellEditor: cellEditors.numericEditor, 
        type: 'numericColumn',
        valueFormatter: params => {
                return localeFormatter(params.data.MAX_MILEAGE);
        },
        cellClassRules: cellClassRules(AgeMileageColumns.MaxMileage.name), tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.MaxMileage.name)
        }
    },{
        field: AgeMileageColumns.UpdatedBy.field, 
        headerName: AgeMileageColumns.UpdatedBy.headerName, 
        editable: false, 
        unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(AgeMileageColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.UpdatedBy.name)
        }
    },
    {
        field: AgeMileageColumns.UpdatedOn.field,
        headerName: AgeMileageColumns.UpdatedOn.headerName,
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, 
        cellClassRules: cellClassRules(AgeMileageColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, AgeMileageColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "AgeMileageActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
