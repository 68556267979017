import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../assets/images/icon_action_download.svg";
import './AllocatedActionBar.css';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import RangeFilter from '../filter_components/range_filter/RangeFilter';
import DropdownFilter from '../filter_components/dropdown_filter/DropdownFilter';
import InputFilter from '../filter_components/input_filter/InputFilter';
import DatePickerFilter from '../filter_components/datepicker_filter/DatePickerFilter';
import { populateGrid2 } from '../../store/MultiGrid/MultiGrid.Actions'
import DialogBox from "../dialog_component/CustomDialog";
import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';
import IconFilter from "../../assets/images/icon_filter.svg";
import SearchBar from "../searchbar/SearchBarComponent";
import { uploadAllocatedData, fetchFilterData, setFilterParameters, fetchAllocatedData,fetchAllocatedDropdownValues } from '../../store/allocated/Allocated.Actions';
import { safeExcelValue, safeExcelDate} from '../../utils/utils';
import SingleButtonDialogComponent from '../Dialogcomponent/SingleButtonDialogComponent';


const AllocatedActionBar = (props) => {
    const { options, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, downloadCSV, data, filterParams, allocatedData, allocatedGridColDef, filterData, transactions,
            isFiltered,setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists  } = props;
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const theme = createMuiTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const popOverOpen = Boolean(anchorEl);
    const id = popOverOpen ? 'simple-popover' : undefined;
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [selectedDropdown, setSelectedDropdown] = React.useState("between");
    const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
    const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
    const [selectedFromDate, setSelectedFromDate] = React.useState();
    const [selectedToDate, setSelectedToDate] = React.useState();
    var filterCat = { "car_class_code": [], "model_group": [], "model_year": [], "count": "", "requested_delivery_month": ["between"], "manufacturer_group": [], "spi_code": [], "pool": [] };
    const [filterArray, setFilterArray] = useState(filterCat);
    const [tempFilterArray, setTempFilterArray] = useState(filterArray);
    const [filterSearchKey,setiFlterSearchKey] = useState('');
    const [showDialog,setShowDialog]=useState(false)
    let message = "Are you sure that you want to delete selected rows?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";

    //editable donwload 
    useEffect(()=>{
        clearFilter(true)
      },[])
      
    theme.typography.body2 = {
        fontSize: '14px'
    };

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    const changeHandler = async (event) => {
        if (event.target.value !== '') {
            const fileToUpload = event.target.files[0];
            event.target.value = '';
            dispatch(uploadAllocatedData(selectedPlanId, fileToUpload));
        }
    };

    const downloadHandler = async (event) => {
        let JsonFields = ["spi_code", "manufacturer_group", "car_class_code", "model_group", "pool", "model_year","requested_delivery_month","count"]
        let filteredData = filterRecords(allocatedData, filterParams);
        let data1 = JsonToCSV(filteredData, JsonFields);
        let currentdate = new Date();
        let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
        downloadCSV(data1, `allocated-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`);
    };

    function JsonToCSV(JsonArray, JsonFields) {
        var csvStr = JsonFields.join(",") + "\n";

        JsonArray.forEach(element => {
            let spi_code = safeExcelValue(element.spi_code);
            let manufacturer_group = safeExcelValue(element.manufacturer_group);
            let car_class_code = safeExcelValue(element.car_class_code);
            let model_group = safeExcelValue(element.model_group);
            let pool = safeExcelValue(element.pool);
            let requested_delivery_month = safeExcelDate(element.requested_delivery_month, '01-MMM-YY');
            let model_year = safeExcelValue(element.model_year);
            let count = safeExcelValue(element.count);
            csvStr += spi_code + ',' + manufacturer_group + ',' + car_class_code + ',' + model_group + ',' + pool + ',' + model_year + ',' + requested_delivery_month + ',' + count + "\n";
        })
        return csvStr;
    }

    const handleClickOpen = (props) => {
        if (!bulkRowDeleteIconDisabled) {
            setOpenDeleteDialog(true);
        }
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteSelectedRows = () => {
        setOpenDeleteDialog(false);
        deleteSelectedRows()
    }

    const masterDataUpdateState = useSelector((state)=>{
        return state.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
      })

    const loadFilterData = useCallback(async () => {
        try {
            await dispatch(fetchFilterData(selectedPlanId));
        } catch (err) {
            console.error('got error while loading filter data');
        }
    }, [dispatch]);

    const loadFilterParams = useCallback(async (tempFilterArray) => {
        try {
            dispatch(setFilterParameters(tempFilterArray))
        } catch (err) {
            console.error('got error while loading filterparams');
        }
    }, [dispatch]);

    const handleFilterClick = (event) => {
        loadFilterData();
        setOpen(true);
    };
/*isSearched parameter can be true and false 
We are utilizing this because clearFilter function is utilized in many places but for some situation
only we want the fetch api to be called so whenevr isSearched is false api call will happen
*/
    const clearFilter = (isSearched) => {
        loadFilterParams(null);
        if(!isSearched){
       dispatch(fetchAllocatedData(selectedPlanId));
        }
        setFilterArray(filterCat);
        setTempFilterArray(filterCat);
        //setOpen(false);
        setIsFiltered(false);
    }

    const applyFilter = (event) => {
        setOpen(false);
        setiFlterSearchKey("");
        setIsSearchBarValueExists(false);
        setFilterArray({ ...tempFilterArray });
        loadFilterParams({ ...tempFilterArray });
        setIsFiltered(true);
    }

    const handleClose = (e) => {
        setOpen(false);
        setTempFilterArray(filterArray);
    };

    const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);

    function filterRecords(array, filterparams) {
        if (filterparams == null) {
            return array;
        } else {
            const filterKeys = Object.keys(filterparams);
            return array.filter(item => {
                // validates all filter criteria
                return filterKeys.every(key => {
                    // ignores an empty filter
                    if (!filterparams[key].length) return true;
                    if (typeof filterparams[key] === 'object' && key !== "count" && key !== "requested_delivery_month") {
                        if (filterparams[key].length == 0) {
                            return true;
                        }
                        else {
                            if(key && filterparams[key][0].value==='Blanks'){
                                return ((item[key]===null) || (item[key]===undefined)) 
                            }else{
                                return (filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) == undefined ? filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) : filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])).value);
                            }
                        }
                    }
                    if (key === "requested_delivery_month") {
                        if (filterparams[key].length === 1) {
                            return true;
                        }
                        else {
                            let d1 = new Date(filterparams[key][1]);
                            let d2 = new Date(item[key].split('T')[0]);
                            let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                            let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                            let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                            let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                            if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                let d3 = new Date(filterparams[key][2]);
                                let NoTimeDate2 = d3.getFullYear() + '/' + (d3.getMonth() + 1);
                                let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                                return itemValue >= startDate && itemValue <= endDate;
                            } else if (filterparams[key][0] === "less") {
                                return itemValue <= startDate;
                            } else if (filterparams[key][0] === "greater") {
                                return itemValue >= startDate;
                            }
                        }
                    }
                    if ((key === "min_schedule" || key === "max_schedule")) {
                        if (filterparams[key].length === 1) {
                            return true;
                        } else {
                            let startValue = parseInt(filterparams[key][1], 0);
                            let itemValue = parseInt(item[key], 0);
                            if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                let endValue = parseInt(filterparams[key][2], 0);
                                if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
                                    return item[key] >= startValue && itemValue <= endValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "less") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue <= startValue;;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "greater") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue >= startValue;
                                } else {
                                    return true;
                                }
                            }
                        }
                    } if (typeof filterparams[key] === 'string') {
                        return getValue(filterparams[key]) == getValue(item[key]);
                    }
                });
            });
        }
    }

    const applyTransactions = (allocatedData, transactions) => {
        let deepcopyschedulesData = allocatedData.map(o => { return { ...o }; })
        transactions.map((value1) => {
            deepcopyschedulesData.map((value2, index) => {
                if (value1.type !== 'delete' && value1.type !== 'add' && value1.type !== 'copy' && (value2.ID == value1.data.ID)) {
                    deepcopyschedulesData[index] = value1.data;
                } else if (value1.type == 'delete' && (value2.ID == value1.id)) {
                    deepcopyschedulesData.splice(index, 1)
                }
            });
            if (value1.type == 'add' || value1.type == 'copy') {
                if (value1.data !== undefined) {
                    deepcopyschedulesData.push(value1.data);
                }
            }
        });
        return deepcopyschedulesData;
    }

    useEffect(() => {
        if(masterDataUpdateState){
            //This dispatch and useEffect is triggered only if master cache data is been updated by admin 
            //masterDataUpdateState is at redux state for handling true/false if master cache data has been updated
            //Calling of DropDownService is used to push last updated cache master data to there respective dropdowns
              dispatch(fetchAllocatedDropdownValues(data,allocatedGridColDef,masterDataUpdateState));
              setShowDialog(true)
             }
        },[masterDataUpdateState])
        useEffect(() => {
        if (transactions.length == 0) {
            if (isFiltered == true || isSearchBarValueExists == true) {
            dispatch(fetchAllocatedData(selectedPlanId));
            }
        } else if (transactions.length > 0) {
            var currentdata = applyTransactions(allocatedData, transactions);
            var filteredCurrentData = filterRecords(currentdata, filterParams);
            var filteredData = filterRecords(allocatedData, filterParams);
            dispatch(populateGrid2("allocatedgrid", filteredData, filteredCurrentData, filterSearchKey, allocatedGridColDef.checkValue, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
        }
    }, [filterParams, filterSearchKey])

    useEffect(() => {
        var filteredData = filterRecords(allocatedData, filterParams);
        dispatch(populateGrid2("allocatedgrid", filteredData, filteredData, filterSearchKey, allocatedGridColDef.checkValue, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
    }, [allocatedData])

    const handleDropDownValueChange = (event, displayName, colDef) => {
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (Array.isArray(filterData[key])) {
                    filterData[key] = event;
                } else {
                    if (event[0])
                        filterData[key] = event[0].value;
                    else
                        filterData[key] = event.target.value;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const onDateDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onDateRangeChanged = (e, colDef, dateField) => {
        var date = new Date(e);
        if (dateField == "from") {
            setSelectedFromDate(date);
        }
        else if (dateField == "to") {
            setSelectedToDate(date);
        }
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (filterData[colDef][0] === "between") {
                    if (filterData[key].length === 3) {
                        if (dateField === "from") {
                            filterData[key][1] = date;
                        }
                        if (dateField === "to") {
                            filterData[key][2] = date;
                        }
                    } else {
                        if (filterData[key].length == 2 && dateField == "from")
                            filterData[key][1] = date;
                        else if (filterData[key].length == 2 && dateField == "to")
                            filterData[key][2] = date;
                        else
                            filterData[key].push(date);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(date);
                    else
                        filterData[key][1] = date;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const onRangeDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onValueRangeChanged = (e, colDef, rangeField) => {
        var range = e.target.value;
        if (rangeField == "from")
            setSelectedFromRangeValue(range);
        else if (rangeField == "to")
            setSelectedToRangeValue(range);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key == colDef) {
                if (filterData[colDef][0] == "between") {
                    if (filterData[key].length === 3) {
                        if (rangeField === "from") {
                            filterData[key][1] = range;
                        }
                        if (rangeField === "to") {
                            filterData[key][2] = range;
                        }
                    } else {
                        if (filterData[key].length == 1 && rangeField == "to") {
                            filterData[key].push("0");
                        }
                        filterData[key].push(range);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(range);
                    else
                        filterData[key][1] = range;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const getSelectedValue = (colDef) => {
        if (tempFilterArray !== undefined) {
            return tempFilterArray[colDef];
        }
    }

    const fetchDialogContent = () => {
        if (filterData !== undefined && filterData !== null && filterData.length > 0) {
            return (<div className="flexContainer">
                {filterData.map((fields, index) => {
                    return (<div className="filterMain">
                        {(fields.type === "dropdown") ?
                            (<DropdownFilter displayName={fields.displayName} options={fields.options}
                                theme={theme} selectedValue={getSelectedValue(fields.colDef)}
                                onValueChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}>
                            </DropdownFilter>) :
                            (fields.type === "input") ?
                                (<InputFilter selectedValue={getSelectedValue(fields.colDef)}
                                    displayName={fields.displayName} theme={theme}
                                    onTextChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}></InputFilter>) :
                                (fields.type === "datepicker") ?
                                    (<DatePickerFilter displayName={fields.displayName} theme={theme}
                                        selectedDate={tempFilterArray[fields.colDef]} selectedDropdown={selectedDropdown}
                                        onFromDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "from")}
                                        onToDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "to")}
                                        onValueChanged={(e) => onDateDropDownValueChanged(e, fields.colDef)}>
                                    </DatePickerFilter>) :
                                    (fields.type === "range") ?
                                        (<RangeFilter displayName={fields.displayName} theme={theme}
                                            selectedRangeValue={tempFilterArray[fields.colDef]}
                                            onFromValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "from")}
                                            onToValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "to")}
                                            onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.colDef)}
                                        >
                                        </RangeFilter>) :
                                        <div></div>}</div>);
                }
                )}</div>);
        }
    }

    // const onSearchValueChanged =(e)=>{
    //     setiFlterSearchKey(e.target.value);
    //     if(e.target.value===''){
    //         setIsSearchBarValueExists(false);
    //     }else{
    //         setIsSearchBarValueExists(true);
    //     }
    // }

    const onSearchValueChanged =(e)=>{
        if(e.target.value==='' && filterSearchKey!=''){
            setIsSearchBarValueExists(false);
            setiFlterSearchKey(e.target.value);
            dispatch(fetchAllocatedData(selectedPlanId));
        }
    }

    const onKeyEnterSearch = (e) => {
      if (e.target.value != "" && e.target.value != "$") {
        clearFilter(true);
        setiFlterSearchKey(e.target.value);
        setIsSearchBarValueExists(true);
      } else {
        setIsSearchBarValueExists(false);
      }
    };

    const handleDialogClose=()=>{
        setShowDialog(false);
      }

    return (
        <div className="mainHeader">
            <div className="filterStyle">
                <img src={IconFilter} className="imageIcon" onClick={handleFilterClick}></img>
                <div className="searchbar">
                <SearchBar onSearchValueChanged={onSearchValueChanged} onEnterSearch={true} onKeyEnterSearch={onKeyEnterSearch} filterSearchText={filterSearchKey} showActiveIconOnEnter={isSearchBarValueExists}></SearchBar>
                </div>
            </div>
            {/* Disable save cancel add +  buttons if are able to select cell */}
            {options && allocatedGridColDef.defaultColDef.editable?
                <div className="saveButtons">
                    <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
                    </Button></div>
                    <div className="primaryButton">
                        <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
                         </Button></div>
                    <div className="primaryButton">
                        {/* Disable add + if plan status is running  */}
                        <Button disabled={!allocatedGridColDef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>Add +
                         </Button>
                    </div>
                    <div className="primaryButton">
                        {/* Disable delete button if plan status is running  */}
                        <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !allocatedGridColDef.defaultColDef.editable)}>
                            Delete
                        </Button>
                    </div>
                </div> :
                (<div className="actionButtons">
                    <label>
                        {/* Disable upload button if plan status is running  */}
                        <input disabled={ !allocatedGridColDef.defaultColDef.editable} type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
                        <img src={IconActionUpload} className="imageIcon"></img>
                    </label>
                    <div className='imageIcon'>
                        <img src={IconActionDownload} onClick={() => downloadHandler()} />
                    </div>
                    {/* {(currentView === "grid" || actionView === "grid") ? */}
                    <div style={{ display: 'flex' }}>
                        <div className="primaryButton">
                            {/* Disable Add + button if plan status is running  */}
                            <Button  disabled={ !allocatedGridColDef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>
                                Add +
                                </Button></div>
                        <div className="primaryButton">
                             {/* Disable Delete button if plan status is running  */}
                            <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !allocatedGridColDef.defaultColDef.editable)}>
                                Delete
                                 </Button>
                        </div>
                    </div>
                    {/* : <div></div>} */}
                </div>)}
            <div>
                <DialogBox classes={{ paper: classes.dialogPaper }}
                    open={open} handleClose={(e) => handleClose(e)} handlePositiveBtnClick={(e) => applyFilter(e)} handleNegativeBtnClick={(e) => clearFilter(false)}
                    dialogContent={fetchDialogContent()} title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter">
                </DialogBox>
                <DeleteDialogBox open={openDeleteDialog} handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
                    dialogMessage={message} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DeleteDialogBox>
                <SingleButtonDialogComponent open={showDialog} handleOk={handleDialogClose}
                     dialogMessage={"Master Data Has Been Updated!!"} title={"Alert!!"} action="Ok">
                 </SingleButtonDialogComponent>
            </div>
        </div>
    )
}

export default AllocatedActionBar;