import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function getAllRFsData(){
 try{
 const accessToken = await getAccessTokenAfterAuth();

 const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

 const allRFsResponse = await client.apis.revenuefunction.getAllRFs();
 return allRFsResponse.obj.data;
  }
 catch (error) {
    console.error(error);
    return {'Error':'getallrfs failed'}
}
}

export async function createNewRfPlanService(rfid,description,rftype){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();
        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const newRfPlanId = await client.apis.revenuefunction.createNewRfPlan({"rfid":rfid},  {requestBody: { "updatedBy": userEmail,"rftype":rftype,"description":description}});
    return newRfPlanId.obj.data;
     }
    catch (error) {
       console.error(error);
       return {'Error':'failed to create new rf plan-inputs'}
   }
   }

   export async function saveRfDescription(rfid,description){
    try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();
    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const rfresponse = await client.apis.revenuefunction.updateRfPlan({"rfid":rfid},  {requestBody: { "updatedBy": userEmail,"description":description}});
    return rfresponse.data;
        
    }catch(e){

    }

   }

export async function fetchRfDetailsByRfId(rfid){
 try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();
    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const rfresponse = await client.apis.revenuefunction.getRfDetailsByRfId({"rfid":rfid});
    return rfresponse.obj.data;
        
    }catch(e){

    }
}