import React, { useCallback, useEffect, useState } from 'react';
import './DropdownFilter.css';
import { NttLabel } from '../../../core-components/Label/NttLabel';
import MultiSelect from "react-multi-select-component";
import { isWordWithoutSpecialCharacterAndSpaces } from '../../../utils/utils';


const DropdownFilter = (props) => {
    const { displayName, options, theme,fieldName, userSelectedValue, onValueChanged,isFilterCleared } = props;

    const [selectedValue,setSelectedValue]=useState(userSelectedValue);

    const ITEM_HEIGHT = 48;
    
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: 250,
            },
        },
    };

    const handleDropDownValueChange=(value)=>{
        setSelectedValue(value)
    }

    const customSearch = (options,filter) =>{
        if (!filter) {
            return options;
        }
        if (!isWordWithoutSpecialCharacterAndSpaces(filter)) {
            return [];
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }

    useEffect(()=>{
        if(selectedValue){
            onValueChanged(fieldName,selectedValue)
        }
    },[selectedValue])

    useEffect(()=>{
        if(userSelectedValue.length==0 && selectedValue.length>0){
            setSelectedValue([])
        }
    },[userSelectedValue,isFilterCleared])

    return (<span className="customdropdown"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel>
    <MultiSelect
        options={options}
        value={selectedValue}
        onChange={(value)=>{handleDropDownValueChange(value)}}
        labelledBy="Select"
        filterOptions={customSearch}
      />  
        </span>)
}

export default DropdownFilter;