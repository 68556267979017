export const FETCH_MILEAGETABLE_DATA_PENDING = 'FETCH_MILEAGETABLE_DATA_PENDING';
export const FETCH_MILEAGETABLE_DATA_SUCCESS = 'FETCH_MILEAGETABLE_DATA_SUCCESS';
export const FETCH_MILEAGETABLE_DATA_ERROR = 'FETCH_MILEAGETABLE_DATA_ERROR';
export const UPLOAD_MILEAGETABLE_PENDING = 'UPLOAD_MILEAGETABLE_PENDING';
export const UPLOAD_MILEAGETABLE_SUCCESS = 'UPLOAD_MILEAGETABLE_SUCCESS';
export const UPLOAD_MILEAGETABLE_ERROR = 'UPLOAD_MILEAGETABLE_ERROR';
export const SAVE_MILEAGETABLE_TRANSACTION_PENDING = 'SAVE_MILEAGETABLE_TRANSACTION_PENDING';
export const SAVE_MILEAGETABLE_TRANSACTION_SUCCESS = 'SAVE_MILEAGETABLE_TRANSACTION_SUCCESS';
export const SAVE_MILEAGETABLE_TRANSACTION_ERROR = 'SAVE_MILEAGETABLE_TRANSACTION_ERROR';
export const FETCH_MILEAGETABLE_DROPDOWNVALUES_PENDING = 'FETCH_MILEAGETABLE_DROPDOWNVALUES_PENDING';
export const FETCH_MILEAGETABLE_DROPDOWNVALUES_SUCCESS = 'FETCH_MILEAGETABLE_DROPDOWNVALUES_SUCCESS';
export const FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR = 'FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR';
export const RESET_TO_MILEAGETABLE_INITIALSTATE = 'RESET_TO_MILEAGETABLE_INITIALSTATE';
export const SET_MILEAGETABLE_EDITABLE='SET_MILEAGETABLE_EDITABLE';