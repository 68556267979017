import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import GridComponents from "../grid/grid_components";
import { useDispatch, useSelector } from "react-redux";
import { completeEditRow, validateGrid } from '../../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import './AdminMultiGridUI.css'; 
import { Button } from '@material-ui/core';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import ZoneMapActionRenderer from './grid_components/renderers/ZoneMapActionRenderer';

const AdminMultiGridUI = (props) => {
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const dispatch = useDispatch();
    const [changeCurrentData,setChangeCurrentData]=useState(false)
    const { reducer, gridName, gridColDef,planid,gridpageno,onRowEditingStarted, onRowEditingStopped, onCellEditingStopped, currentView, refreshCells, rowEditType, suppressclickEdit, handleMultipleRowDelete, scrollToTop,handleAddNewDropdownValue} = { ...props };
    const { currentData, transactions, validations } = reducer;
    
    function onGridReady(params) {
       
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();

        window.onresize = () => {
            params.api.sizeColumnsToFit();
        }
    }

    const frameworkComponents = {
        autoCompleteEditor: GridComponents.AutoCompleteEditor,
        autoCompletePopupEditor: GridComponents.AutoCompletePopupEditor,
        numericEditor: GridComponents.NumericEditor,
        freeSoloAutoCompleteEditor: GridComponents.FreeSoloAutoCompleteEditor,
        userManagementActionRenderer: GridComponents.userManagementActionRenderer,
        planManagementActionRenderer: GridComponents.planManagementActionRenderer,
        CarGroupActionRenderer: GridComponents.CarGroupActionRenderer,
        CarClassGroupActionRenderer: GridComponents.CarClassGroupActionRenderer,
        ModelMfrOemActionRenderer: GridComponents.ModelMfrOemActionRenderer,
        PoolManagementActionRenderer: GridComponents.PoolManagementActionRenderer,
        ZoneMapActionRenderer: GridComponents.ZoneMapActionRenderer
    };

    const handleCellEditingStopped = (params) => {
        return onCellEditingStopped();
    }

    const handleCellEditingStarted = (params) => {
     
        if(transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            return onRowEditingStarted(true, isOfTypeCopyOrDelete,transactions); 
        }
    }
 
    const onCellValueChanged = (params) => {
        if (params.oldValue !== params.newValue) {
            var updatedRowData = { ...params.data };
            params.data.isEdited = true;
            updatedRowData[params.column.colId] = params.newValue;
            let sortedData=[]
        if(gridApi.sortController.getSortModel().length>0){
                gridApi.forEachNodeAfterFilterAndSort((node, index)=>{
                sortedData.push(node.data)
                });  
                columnApi.applyColumnState({
                defaultState: {
                    sort: null
                }
            });
            setChangeCurrentData(true)  
        }
            dispatch(completeEditRow(gridName, updatedRowData, gridColDef.validationFn, { id: params.data.ID, value: params.colDef.field },gridColDef.businessValidationFn,sortedData));
        }
    return onCellEditingStopped();  
 }
    const [newCurrentData,setNewCurrentData]=useState()

    useEffect(()=>{
    if(changeCurrentData){
        setNewCurrentData(currentData)
    }
    setNewCurrentData([])
    setChangeCurrentData(false)
    },[changeCurrentData])


    const handleRowEditMode = (params) => {
     
        // // Handle click event for action cells  
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });
        // gridApi.setImmutableData(true);
        if (isCurrentRowEditing == true)
            return onRowEditingStarted(isCurrentRowEditing,null,transactions);
    }

    const rowClassRules = function () {
        return {
            // 'errorCellBorder': (params) => {
            //     return (params.data.rowStatus === 'INVALID_ROW' || params.data.rowStatus === 'INVALID_NEW_ROW')
            // },
            'errorRow': (params) => {
                return (params.data.rowStatus === 'ERROR_ROW' || params.data.rowStatus === 'ERROR_NEW_ROW' || params.data.rowStatus === 'ERROR_COPY_ROW')
            },
            'copyRow': (params) => {
                return (params.data.rowStatus === 'COPY_ROW')
            },

        }
    }

    useEffect(() => {
        if (gridApi !== null) {
            gridApi.ensureIndexVisible(0);
        }
        
    },[scrollToTop]);

    useEffect(() => {
        if(transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            if(isOfTypeCopyOrDelete)
                return onRowEditingStarted(true, isOfTypeCopyOrDelete,transactions); 
        }
    },[transactions]);

    useEffect(() => {
        if (gridApi !== null) {
            gridApi.refreshCells();
            gridApi.redrawRows();
            var errorFound = false;
            currentData.forEach((element) => {
                if (element.rowStatus === "ERROR_ROW" || element.rowStatus === "INVALID_ROW" || element.rowStatus === "ERROR_COPY_ROW") {
                    if (!errorFound) {
                        let node = gridApi.getRowNode(element.ID);
                        // gridApi.ensureIndexVisible(node.rowIndex);
                        // gridApi.refreshCells();
                        errorFound = true;
                    }
                }
            });
        }
    }, [refreshCells]);

    useEffect(()=>{
        if(transactions.length>0 && transactions[transactions.length-1].type!==undefined && currentData.length>1 && transactions[transactions.length-1].type=='add' && transactions[transactions.length-1].data==undefined){  //if the latest action is Add then scroll
            if(gridApi.sortController.getSortModel().length>0 ){
                columnApi.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
            }
            let node = gridApi.getRowNode(currentData[currentData.length-1].ID);
            if(node && node.rowIndex!==undefined){
                gridApi.ensureIndexVisible(currentData.length-2);
            }
        }
    },[currentData])

    const onSelectionChanged = () => {
        let selectedRows = [];
        if (gridApi && gridApi.getSelectedRows().length > 0) {
            selectedRows = gridApi.getSelectedRows();
        }
        if(handleMultipleRowDelete!==undefined){
            handleMultipleRowDelete(selectedRows);
        }
    }
    const refresh=()=>{
        setChangeCurrentData(true)
        dispatch(validateGrid(gridName,currentData,gridColDef.validationFn,gridColDef.businessValidationFn))
    }

    useEffect(()=>{
      
        if(gridColDef && gridColDef.defaultColDef && gridApi && 
            !gridColDef.defaultColDef.editable && gridApi.getEditingCells().length>0){

              
                gridApi.stopEditing();
        }
    },[gridColDef,gridApi]);


    return (
        <div id="grid-theme-wrapper" className="app-theme">
            <div style={{ 
                width: '100%',
                height: '100%'
            }}
                className="ag-theme-alpine">
                {(currentData !== null) ? <AgGridReact
                    overlayNoRowsTemplate='No Records To Show'
                    propsTest={props}
                    reactNext={true}
                    onGridReady={onGridReady}
                    columnDefs={gridColDef.columnDefs}
                    defaultColDef={gridColDef.defaultColDef}
                    rowData={changeCurrentData?newCurrentData:currentData}
                    getRowNodeId={data => data.ID}
                    checkboxSelection={true}
                    headerCheckboxSelection={true}
                    enterMovesDownAfterEdit={false}
                    enterMovesDown={false}
                    frameworkComponents={frameworkComponents}
                    suppressHorizontalScroll={false}
                    rowSelection={'multiple'}
                    editType={rowEditType}
                    immutableData={true}
                    onCellEditingStarted={handleCellEditingStarted}
                    onCellValueChanged={onCellValueChanged}
                    onRowClicked={handleRowEditMode}
                    onCellEditingStopped={handleCellEditingStopped}
                    rowClassRules={rowClassRules()}
                    gridName={gridName}
                    stopEditingWhenGridLosesFocus={false}
                    tooltipShowDelay={100}
                    tooltipMouseTrack={true}
                    suppressClickEdit={suppressclickEdit}
                    suppressMaintainUnsortedOrder={transactions.length>0 && (transactions[0].type!==undefined)&&(transactions[0].type=='copy') ?false:true}
                     onSelectionChanged={onSelectionChanged}
                    suppressRowClickSelection={true}    
                    suppressPropertyNamesCheck={true}                
                /> : <div></div>}
            </div>
          
        </div>);
}
export default AdminMultiGridUI;