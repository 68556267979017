import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const DepRateTabPanel = (props) =>{
    const{depRategridReducer,depRateTransactions,depRateGridColDef,currentView,
        handleRowEditingStarted, handleRowEditingStopped,refreshCells, handleCellEditingStopped,
        handleMultipleRowDelete,userMessage,pending, gridReducer,deleteSelectedRows,selectedPlanId,newArchitecture,gridName,loadGridData} = props;

 return(
    <div>
        {newArchitecture ? (<MainPlanInputsMultiGridUI 
            reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={depRateGridColDef}
            rowEditType={'single'}
            suppressclickEdit={!depRateGridColDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            isPaginationRequired={true}
            loadGridData={loadGridData}
             />) :
        (<MultiGridUI 
            reducer={depRategridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'deprategrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={depRateGridColDef}
            suppressclickEdit={!depRateGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            depRateTransactions={depRateTransactions}
            userMessage={userMessage}
             />)
        }
     </div>
 )
}

export default DepRateTabPanel;