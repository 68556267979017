import React from "react";

import Tabs from "@material-ui/core/Tabs";

const NttTabs = ({
  children,
  value,
  onChange,
  variant,
  scrollButtons,
  ariaLabel,
  indicatorColor,
  textColor,
  orientation,
}) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant={variant}
      scrollButtons={scrollButtons}
      aria-label={ariaLabel}
      indicatorColor={indicatorColor}
      textColor={textColor}
      orientation={orientation}
    >
      {children}
    </Tabs>
  );
};

export default NttTabs;
