import moment from "moment";
import { ManualVinDeletesColumns, maximumDate } from "../../Constants";
import { isStringAlphaNumeric, safeCaseInsensitiveCompare } from "../../utils/utils";
import { FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR, FETCH_MANUALVINDELETES_DROPDOWNVALUES_PENDING, FETCH_MANUALVINDELETES_DROPDOWNVALUES_SUCCESS } from "./ManualVinDeletes.ActionTypes";
import { columnDefs, defaultColDef } from "./manualvindeletes_grid_data/ManualVinDeletesColumnDefs";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
         let CPD = JSON.parse(sessionStorage.getItem("CPD"));
        return {
            ID: data.ID,
            VIN: { error: checkIfValueEmpty(data.VIN,CPD, ManualVinDeletesColumns.Vin.headerName) },
            MONTH: { error: checkIfValueEmpty(data.MONTH,CPD, ManualVinDeletesColumns.DelMonth.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, cpd,fieldName) {
    if (value === undefined || value === "" || value === null) {
        return fieldName +" "+"cannot be empty";
    }
    else {
     if(fieldName===ManualVinDeletesColumns.DelMonth.headerName){
         if(!cpd){
            return "cpd is null in parameters"
        }else{
            let cpdValue=cpd.split('_')[0];
            cpdValue = cpdValue.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
            // let itemValue=new Date(value)
            // if((itemValue.getFullYear() == new Date(cpdValue).getFullYear())){
            //     if(itemValue.getMonth() < new Date(cpdValue).getMonth()){
            //         return fieldName+ " should be equal or greater than cpd month"
            //     }else{
            //         return null;
            //     }
            // }
             if (value == 'Invalid date') {
                 return fieldName + " " + "cannot be invalid date";
             }
             if (moment(value).isSame(moment(cpdValue), 'year')) {
                 if (moment(value).isBefore(moment(cpdValue), 'month')){
                     return fieldName + " should be equal or greater than cpd month"
                 } else {
                     return null;
                 }
             }

             // else if (((itemValue.getFullYear() < new Date(cpdValue).getFullYear()) || (itemValue.getFullYear() > (new Date(cpdValue).getFullYear() + 5)))){
             //     return fieldName + " should be greater than or equal to cpd month and year and in +5 years";
             // }

             else if (!moment(value).isBetween(moment(cpdValue), maximumDate, 'year', '[]')) {
                 return fieldName + " should be greater than or equal to cpd month and year and in +5 years";
             }
            else{
                return null;
        }
        }
    }
    else if(fieldName===ManualVinDeletesColumns.Vin.headerName){
        let result = isStringAlphaNumeric(value)
         if(!result){
          return fieldName + " should be alphanumeric";
        }else if(value.length!=17){
          return fieldName + " length should be equal to 17"; 
        } 
          else{
          return null;
        }
      }
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.VIN,itm2.VIN)) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function fetchFilterValues(users,columns) {
    columns.forEach(element => {
        if(element.field === ManualVinDeletesColumns.Vin.field){
            element.filterOptions=[{"label":"Blanks","value":"Blanks"}];
        }
      else if(element.field === ManualVinDeletesColumns.UpdatedBy.field){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'ManualVinDeletesActionRenderer'
            }
        } 
    });

    return columns;
}

export default function manualVinDeletesColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MANUALVINDELETES_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_MANUALVINDELETES_DROPDOWNVALUES_SUCCESS:
           // const maxDeletesData = fetchZones(action.data.maxDeletesData, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.users, state.columnDefs)
            });
        case FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}