import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './InputsStartRunDialogComponent.css';
import icon_close from "../../assets/images/close.svg";
import { CircularProgress, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {planInputTabData,planAssumptionTabsForPlanInputs } from '../../Constants';

const InputsStartRunDialogBox = (props) => {
    const { open, handleClose, handleYes, action, canceltext } = props;

    const GridPending=useSelector((state)=>{
        return state.PaginationGridReducer.pending
      })

    const highlightAllTabsErrorStatus = useSelector((state)=>{
        return state.PaginationGridReducer.gridTabErrorStatus;
    })


    return (
            <Dialog
                open={open}
                className="startRunDialogBox"
            >
                <DialogTitle id="draggable-dialog-title" className="startRundialogtitle">
                    <span>Initiate Run</span>
                    {(GridPending)? <span> Validation In Progress!! Please wait.... <CircularProgress size={25} /></span>:<img src={icon_close} onClick={handleClose} className="startRunCloseIcon" alt="iconclose"></img>}
                </DialogTitle>
                <DialogContent className="startRunDialogContent">
                <div className="startRunMainTabsFlexContainer">
                   
                {planInputTabData.map(tabs=>(
                    (tabs.tabKey !=6) ? <span key={tabs.tabKey} className={(highlightAllTabsErrorStatus[tabs.gridName]==false || GridPending) ? "validStartRunDisplayName" : "inValidStartRunDisplayName" }>{tabs.tabTitle}</span>:""
                ))}
                </div>
                <div className="startRunPlanAssumptionTabsFlexContainer" >
                    <span className='planAssumptionTabTitle'>Plan Assumption Tabs</span>
                     
                {planAssumptionTabsForPlanInputs.map(tabs=>(
                    <span key={tabs.tabKey} className={(highlightAllTabsErrorStatus[tabs.gridName]==false || GridPending) ? "validStartRunDisplayName" : "inValidStartRunDisplayName" }>{tabs.tabDisplayTitle}</span>
                ))}
                </div>
                </DialogContent>
                <DialogActions className="startRunActionbuttons">
                         {(!GridPending)?<Button className='cancelbutton' autoFocus onClick={handleClose} color="primary">
                         {canceltext}
                        </Button> :""}
                        <Tooltip title={Object.values(highlightAllTabsErrorStatus).includes(true)?"There are some issues in the grid!! Please fix them":''} placement="bottom">
                            <span>
                           <Button className={(Object.values(highlightAllTabsErrorStatus).includes(true) || GridPending) ? 'buttonblur' : 'okbutton'} onClick={handleYes} color="primary" 
                            disabled={(Object.values(highlightAllTabsErrorStatus).includes(true) || GridPending)}
                            >
                                {action}
                            </Button>
                            </span>
                        </Tooltip>
                    </DialogActions>
            </Dialog>
    )
}
export default InputsStartRunDialogBox;