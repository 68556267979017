import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useComponentWillMount } from './../utils';
import DeleteIcon from "../../../../../assets/images/icon_trash.svg";
import EditIcon from "../../../../../assets/images/icon_edit.svg";
import CopyIcon from "../../../../../assets/images/icon_copy.svg";
import UndoIcon from "../../../../../assets/images/icon_undo.svg";
import { addNewRow, copyNewRow, deleteRow, deleteRowEditMode, revertRow } from '../../../../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions'
import DialogBox from '../../../../Dialogcomponent/DialogComponent';
import { ADD_ROW, BASIC_CANCEL_TEXT, BASIC_DELETE_MESSAGE, BASIC_DELETE_ROW_TITLE, BASIC_DELETE_TEXT, EVENT_CELL_EDITING_STOPPED, EVENT_CELL_VALUE_CHANGED, ADMINPOOLSGRID } from '../../../../../Constants';

export default (props) => {
    let [editing, setEditing] = useState(false);
    let [edited, setEdited] = useState(false);
    let [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    let message = BASIC_DELETE_MESSAGE
    let title = BASIC_DELETE_ROW_TITLE;
    let deletetext = BASIC_DELETE_TEXT;
    let canceltext = BASIC_CANCEL_TEXT;
    let isDeleteClicked = false;
    let isEditCLicked = false;

    const poolsGridColDef = useSelector((state) => {
        return state.rootPoolManagementReducer.PoolsManagementColumnDefinitionReducer;
      });

    // custom hook
    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            setDisabled(true);
        }
    });

    useEffect(() => {
        if (props.data.rowStatus === ADD_ROW && Object.keys(props.data).length <= 2) {
            startEditing();
        }
        props.api.addEventListener(EVENT_CELL_VALUE_CHANGED, onCellValueEditStarted);
        props.api.addEventListener(EVENT_CELL_EDITING_STOPPED, onCellValueEditStopped);

        return () => {
            props.api.removeEventListener(EVENT_CELL_VALUE_CHANGED, onCellValueEditStarted);
            props.api.removeEventListener(EVENT_CELL_EDITING_STOPPED, onCellValueEditStopped);
        };
    }, [editing, edited]);

    function onCellValueEditStarted(params) {
        // Plan rowStatus is running should disable editing   
        if (props.node === params.node) {
            setEditing(true);
            setEdited(false);
            isEditCLicked = true;
        } else {
            setDisabled(true);
        }
    
}

    function onCellValueEditStopped(params) {
        if (isDeleteClicked) {
            setEditing(true);
        }
        else if (props.node === params.node) {
            setEditing(false);
            if (JSON.stringify(params.data) !== JSON.stringify(params.node.data) || isEditCLicked) {
                setEdited(true);
            }
            else
                setEdited(false);
        } else {
            setDisabled(true);
        }
    }

    function startEditing() {
        // Plan rowStatus is running should disable editing        
                props.api.startEditingCell({
                rowIndex: props.node.rowIndex,
                colKey: props.api.getColumnDefs()[1].colId //edit the first column of the row
            });
    }

    function revertChanges() {
        props.api.stopEditing(true);
        dispatch(revertRow(ADMINPOOLSGRID, props.node.rowIndex, props.data.ID, props.data, poolsGridColDef.validationFn,  poolsGridColDef.businessValidationFn));
        setEdited(false);
    }


    function deleteThisRow() {
        // Plan rowStatus is running should disable editing      
        if (editing)
            dispatch(deleteRowEditMode(ADMINPOOLSGRID, props.data.ID));
        else
            dispatch(deleteRow(ADMINPOOLSGRID, props.data.ID));
}

    function copyRow() {
        // Plan rowStatus is running should disable editing         
            dispatch(copyNewRow(ADMINPOOLSGRID, props.node.rowIndex + 1, props.data, poolsGridColDef.validationFn,  poolsGridColDef.businessValidationFn));
    }

    function handleClickOpen() {
        // Plan rowStatus is running should disable editing       
        setOpen(true);
        isDeleteClicked = true;
    };

    function handleClose() {
        setOpen(false);
    };

    return (
        <div>
            <div>
                {editing
                    ? <div>
                        {/* <img src={DeleteIcon} onClick={() => handleClickOpen()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        <img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img> */}
                        {(props.data.rowStatus === ADD_ROW || props.data.rowStatus === "COPY_ROW" || props.data.rowStatus === "ERROR_NEW_ROW" || props.data.rowStatus === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={() => revertChanges()} style={{ paddingTop: '10px' }}></img>}
                    </div>
                    : <div>
                        {/* <img src={DeleteIcon} onClick={() => handleClickOpen()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        <img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img> */}

                        {edited
                            ?
                            (props.data.rowStatus === ADD_ROW || props.data.rowStatus === "COPY_ROW" || props.data.rowStatus === "ERROR_NEW_ROW" || props.data.rowStatus === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={revertChanges} style={{ paddingTop: '10px' }}></img>
                            :
                            ((props.data.rowStatus === ADD_ROW || props.data.rowStatus === "COPY_ROW" || props.data.rowStatus === "ERROR_NEW_ROW" || props.data.rowStatus === "INVALID_NEW_ROW") ? <div></div> : <img src={EditIcon} onClick={startEditing} style={{ paddingTop: '10px' }}></img>)
                        }
                    </div>
                }
            </div>
            <div>
                <DialogBox open={open} handleClose={handleClose} handleYes={deleteThisRow}
                    dialogMessage={message} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DialogBox>
            </div>
        </div>
    )
}
