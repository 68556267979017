import React from 'react';
import './RfPoolRenderer.css';

export default function RfPoolRenderer(props){

return(
<div>
 {props.data.POOL==true? <p className='fontfam'>&#x0FC;</p> : <div></div> }
</div>
)
}