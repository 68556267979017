import React, { useEffect, useState } from "react";
import MultiGridUI from '../../../components/revenuefunction/grid/RevenueFunctionMultiGridUI';
import { useDispatch, useSelector } from "react-redux";
import RevenueInputsActionBar from "../../../components/revenuefunction/revenueinputsactionbar/RevenueInputsActionBar";

const RfInputsRevenueTabPanel = (props) => {
    const {showSaveOption,downloadCSV,gridName,RFsData,RFsGridReducer,GridData,RFscolumnDefinitionReducer,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,setIsSearchBarValueExists,isSearchBarValueExists,refreshCells,handleMultipleRowDelete, rfid,loadGridDataForFilterParams,initializeFilterParamsAndViewStatus, userMessage,typeName,loadGridData} = props;

    const TncRevenueGridColDefReducer = useSelector((state)=>{
      return state.RFTncRevenuecolumnDefinitionReducer;
    });

    return (
      <div>
      <RevenueInputsActionBar
       RevenueInputsData={RFsData}
       RevenueInputsGridColDefReducer={RFscolumnDefinitionReducer?RFscolumnDefinitionReducer:TncRevenueGridColDefReducer}
       RevenueInputsGrid={gridName}
       //loadGridDataForFilterParams={loadGridDataForFilterParams} 
       initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}
       userMessage={userMessage}
       downloadCSV={downloadCSV}
       rfid={rfid}
       typeName={typeName}
       //filterSearchKey={''}
      //  setIsFiltered={setIsFiltered}
      //  isFiltered={isFiltered}
      //  transactions={transactions}
      //  isSearchBarValueExists={isSearchBarValueExists}
      //  setIsSearchBarValueExists={setIsSearchBarValueExists}
       >
      </RevenueInputsActionBar>
        <MultiGridUI
        reducer={RFsGridReducer}
        onRowEditingStarted={handleRowEditingStarted}
        onRowEditingStopped={handleRowEditingStopped}
        onCellEditingStopped={handleCellEditingStopped}
        gridName={gridName}
        rowEditType={'single'}
        gridColDef={RFscolumnDefinitionReducer?RFscolumnDefinitionReducer:TncRevenueGridColDefReducer}
        suppressclickEdit={RFscolumnDefinitionReducer?RFscolumnDefinitionReducer.defaultColDef.editable:TncRevenueGridColDefReducer.defaultColDef.editable}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete} 
        rfid={rfid}
        loadGridData={loadGridData}
        >
        </MultiGridUI>
        </div>
    )
}

export default RfInputsRevenueTabPanel;