import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './UpgradePathAutocompleteEdtor.css';

export default forwardRef((props, ref) => {
    const { options } = props;
    const inputRef = useRef();
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');

    function onChangeHandler(e, value) {
        setValue(value);
    }

    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
    }

    useEffect(o => {
        setTimeout(function () {
            if (inputRef && inputRef.current
                && inputRef.current.children.length > 0
                && inputRef.current.children[0].children.length > 0) {
                inputRef.current.children[0].children[0].select();
                inputRef.current.children[0].children[0].focus();
            }
        }, 100);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            }
        };
    });

    function onHighlightChangeHandler(e, option, reason) {
        if (option !== null && reason === 'keyboard') {
            setValue(option);
            let value = option.car_class + ' ' + option.car_class_car_group; //converting option as string
            setInputValue(value);
        }
    }

    function getOptions(props){
        if(props.column.colId){
            for(let i=0;i<props.column.gridOptionsWrapper.gridOptions.columnDefs.length;i++){
                if(props.column.gridOptionsWrapper.gridOptions.columnDefs[i].field===props.column.colId){
                    return props.column.gridOptionsWrapper.gridOptions.columnDefs[i].cellEditorParams.options?props.column.gridOptionsWrapper.gridOptions.columnDefs[i].cellEditorParams.options:props.options;
                }
            }
        }

        return [];
    }

    return (
        <div className='upgradeAutocomplete'>
            <Autocomplete
              //  options={props.options}
                options={getOptions(props)}
                value={value}
                onChange={onChangeHandler}
                closeIcon=''
                inputValue={inputValue}
                onInputChange={onInputChangeHandler}
                onHighlightChange={onHighlightChangeHandler}
                getOptionLabel={(option) => (option !== "" && option.car_class !== undefined) ? option.car_class + " " + option.car_class_car_group : option != "" ? option : ""}
                renderInput={(params) => (
                    <TextField
                        ref={inputRef}
                        {...params}
                        style={{ padding: '5px 0' }} />
                )}
            />
        </div>
    );
})
