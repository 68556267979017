import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction,serverSideSearchColumnsMatch } from '../../utils/utils';
import { MAX_MANUALVINDELETES_BATCH_SIZE, MAX_ROWS_ALLOWED_FOR_EACH_GRID, SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getManualVinDeletesData(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
     const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
     const promisemanualVinDeletesResponse= await(client.apis.plans.getAllManualVinDeletes({  "planid": planid ? planid : 'master',"pageno":(pageno!=undefined || pageno!=null)?pageno:1}));
     const responsesArray = await Promise.all([promisemanualVinDeletesResponse]);
     const [manualVinDeletesResponse] = responsesArray.map(o => o.body.data);
     
     if(manualVinDeletesResponse.rows.length>0){// CPD value is required for validating the month column
        sessionStorage.setItem("CPD",JSON.stringify(manualVinDeletesResponse.rows[0]["CPD"]));
     }
    return ({
        "gridData": manualVinDeletesResponse.rows,"rowcount":manualVinDeletesResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       throw 'Unable to fetch the data for Manual VIN Deletes tab'
       //return {'Error':'getManualVinDeletesData failed'}
   }
   }

   export async function saveManualVinDeletesTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactManualVinDeletes({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
   }

   export  async function uploadManualVinDeletesCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadManualVinDeletes({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Manual VIN Deletes tab, Please try again!!');
        //return {'Error':'uploadManualVinDeletesCSV failed'}
    }   }

    export async function fetchManualVinDeletesDropdownService(){
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);
    
        return ({
            "users":promiseUserManagementEmailAndIdMap
        })  
    };

export  async function InsertS3uploadedManualVinDeletesCSV (planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedManualVinDeletesData({"planid":planid?planid:1,"gridName":gridname?gridname.replace(/grid/gi,''):null},{
            requestBody: {
                "updatedBy":userEmail,
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Manual VIN Deletes tab, Please try again!!');
        //return {'Error':'InsertUploadManualVinDeletesCSV failed'}
    }
}

export async function getManualVinDeletesErrorIds(planId,manualVinDeletesTotData, limited){
    // console.log(`Total Data === PlanId: ${planId}, manualVinDeletesTotData:${manualVinDeletesTotData}`);
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray=[];
    // const promisemanualVinDeletesErrorResponse =  client.apis.plans.getAllManualVinDeletesError({ "planid": planId ? planId : 'master',"pageno":pageNo?pageNo:1});

    let duplicateRowsResponse=await client.apis.plans.getVinDuplicateRows({ "planid": planId ? planId : 'master','limited':!!limited});
    // console.log("duplicateRowsResponse===",duplicateRowsResponse);
    if(limited && duplicateRowsResponse.body.data.length>0){
        console.log("Limited is true, Error found, Returning immediately");
        return [duplicateRowsResponse.body.data[0].ID];
    }
    let numberOfPages=Math.ceil(manualVinDeletesTotData/MAX_MANUALVINDELETES_BATCH_SIZE);
    let limit=MAX_MANUALVINDELETES_BATCH_SIZE;
    let offset=0;
    for(let i=1;i<=numberOfPages;i++){
        offset=(MAX_MANUALVINDELETES_BATCH_SIZE*i)-MAX_MANUALVINDELETES_BATCH_SIZE;
        promiseArray.push(client.apis.plans.getVinRowsWithColumnErrorsPerPage({ "planid": planId ? planId : 'master',"pageno":i,"limit":limit,"offset":offset,'limited':!!limited}));
    }
    // console.log("Total Call to API==",promiseArray.length );
    const responsesArray = await Promise.all(promiseArray);

    // console.log("responsesArray===>",responsesArray);

    let tempInvalidRowIDsSet=new Set();
    //Adding duplicate rows
    // console.log("duplicateRowsResponse===",duplicateRowsResponse);
    duplicateRowsResponse.body.data.forEach(row=>tempInvalidRowIDsSet.add(row.ID));
    //Adding other rows to the set
    for(let i=0;i<responsesArray.length;i++){
        if(responsesArray[i].body.data!==-1){
            responsesArray[i].body.data.forEach(row=>tempInvalidRowIDsSet.add(row.ID));
        }else{
            throw new Error("Failed to get error records due to issue in API");
        }
    }

    let uniqueArrayIds=[...tempInvalidRowIDsSet].sort((a,b)=>a>b);
    // console.log("Total error records==",uniqueArrayIds.length );
    // console.log("Total error records==",uniqueArrayIds );

    return uniqueArrayIds;
}

export async function getManualVinDeletesErrorData(planId, pageNo, uniqueIDs) {
    try {
        // console.log(`Inside getManualVinDeletesErrorData: planId=${planId}, pageNo=${pageNo}, uniqueIDs=${uniqueIDs} `);
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const manualVinDeletesErrorResponse = await client.apis.plans.getAllManualVinDeletesError({ "planid": planId ? planId : 1, "pageno": pageNo }, { requestBody: { "isNewArchitecture": true } });
            const responsesArray = await Promise.all([manualVinDeletesErrorResponse]);
            const [manualVinDeletesResponse] = responsesArray.map(o => o.body.data);

            if(manualVinDeletesResponse.rows.length>0){// CPD value is required for validating the month column
                sessionStorage.setItem("CPD",JSON.stringify(manualVinDeletesResponse.rows[0]["CPD"]));
            }

            return ({
                "gridData": manualVinDeletesResponse.rows, "rowcount": manualVinDeletesResponse.rowCount
                
            }); 
        }
        else {//this else condition executes when it is older version
            pageNo = pageNo ? pageNo : 1;
            let offSet = (MAX_ROWS_ALLOWED_FOR_EACH_GRID * pageNo) - MAX_ROWS_ALLOWED_FOR_EACH_GRID;
            let choosenIDs = uniqueIDs.slice(offSet, offSet + MAX_ROWS_ALLOWED_FOR_EACH_GRID);
            // console.log("choosenIDs length===>>>",choosenIDs.length);
            const manualVinDeletesErrorResponse = await client.apis.plans.getAllManualVinDeletesError({ "planid": planId ? planId : 'master', "pageno": pageNo ? pageNo : 1 }, { requestBody: { "uniqueids": choosenIDs } });

            // console.log("manualVinDeletesErrorResponse.rows length==>", manualVinDeletesErrorResponse.body.data.rows.length);
            return ({
                "manualVinDeletesErrorData": manualVinDeletesErrorResponse.body.data.rows, "rowcount": manualVinDeletesErrorResponse.body.data.rowCount,
                "gridData": manualVinDeletesErrorResponse.body.data.rows
            });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch error data in Manual VIN Deletes tab, Please try again!!'
    }
}

export async function fetchManualVinDeletesFilterRecords(planId,pageNo,filterparameters){
    try {
        if (filterparameters.MONTH[1] === '' || filterparameters.MONTH[2] === '') {
            filterparameters.MONTH.splice(1)
        }
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseManualVinDeletesFilterResponse = await client.apis.plans.getManualVinDeletesFilterRecords({ "planid": planId ? planId : 'master', 'pageno': pageNo == 0 || pageNo == undefined ? 0 : pageNo }, { requestBody: { "filterparams": filterparameters } });
        const responsesArray = await Promise.all([promiseManualVinDeletesFilterResponse]);

        const [manualVinDeletesFilterResponse] = responsesArray.map(o => o.body.data);

        if(manualVinDeletesFilterResponse.rows.length>0){// CPD value is required for validating the month column
            sessionStorage.setItem("CPD",JSON.stringify(manualVinDeletesFilterResponse.rows[0]["CPD"]));
        }

        return ({
            "gridData": manualVinDeletesFilterResponse.rows, "rowcount": manualVinDeletesFilterResponse.rows[0] ? manualVinDeletesFilterResponse.rows[0].rowcount : 0
        });
    }
    catch (error) {
        console.error(error);
        throw 'Manual VIN Deletes tab has internal server error, please do re-apply the filter'
    }
}

export async function fetchManualVinDeletesSearchRecords(planId,pageno,searchInColumns, searchText) {
    try {
        if (searchText != '' && searchText != '$') {
            const accessToken = await getAccessTokenAfterAuth();

            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

            // this if condition is true when it is new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                const promiseManualVinDeletesSearchResponse = await client.apis.plans.getManualVinDeletesSearchRecords({ "planid": planId ? planId : 'master', 'pageno': pageno }, { requestBody: { "searchParams": searchInColumns,"isNewArchitecture": true } });
                const responsesArray = await Promise.all([promiseManualVinDeletesSearchResponse]);

                const [manualVinDeletesSearchResponse] = responsesArray.map(o => o.body.data);

                if(manualVinDeletesSearchResponse.rows.length>0){// CPD value is required for validating the month column
                    sessionStorage.setItem("CPD",JSON.stringify(manualVinDeletesSearchResponse.rows[0]["CPD"]));
                }
        
                return ({
                    "gridData": manualVinDeletesSearchResponse.rows, "rowcount": manualVinDeletesSearchResponse.rows[0] ? manualVinDeletesSearchResponse.rows[0].rowcount : 0
                });
            }
            else { //this else condition executes when it is older version

                const promiseManualVinDeletesSearchResponse = await client.apis.plans.getManualVinDeletesSearchRecords({ "planid": planId ? planId : 'master', 'pageno': pageno == 0 || pageno == undefined ? 0 : pageno }, { requestBody: { "searchText": searchText } });
                const responsesArray = await Promise.all([promiseManualVinDeletesSearchResponse]);

                const [manualVinDeletesSearchResponse] = responsesArray.map(o => o.body.data);

                return ({
                    "gridData": manualVinDeletesSearchResponse.rows, "rowcount": manualVinDeletesSearchResponse.rows[0] ? manualVinDeletesSearchResponse.rows[0].rowcount : 0
                });
            }
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to search data in Manual VIN Deletes tab, Please try again!!'
    }
}

export async function deleteManualVinDeletesRecordsService(planId, data) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const promisemanualVinDeletesDeleteResponse = await client.apis.plans.manualVinDeletesDeleteRecords({ "planid": planId ? planId : 'master' }, { requestBody: { "updatedBy": userEmail, "isNewArchitecture": true, ...data } });

            if (promisemanualVinDeletesDeleteResponse.obj && promisemanualVinDeletesDeleteResponse.obj.statusCode !== '500') {
                return promisemanualVinDeletesDeleteResponse;
            }
            else {
                throw Error();
            }
        }
        else { //this else condition executes when it is older version
            let { manualVinDeletesPageNo } = data;

            const promisemanualVinDeletesDeleteResponse = await client.apis.plans.manualVinDeletesDeleteRecords({ "planid": planId ? planId : 'master', 'pageno': manualVinDeletesPageNo == 0 || manualVinDeletesPageNo == undefined ? 0 : manualVinDeletesPageNo }, {
                requestBody: {
                    "updatedBy": userEmail,
                    "operation": data.operationApplied,
                    "searchColumns": data.manualVinDeletesColumnsConstantData,
                    "searchText": data.filterSearchKey,
                    "filterparams": data.manualVinDeletesFilterParams,
                    "uniqueErrorIDs": data.manualVinDeletesUniqueErrorIDs
                }
            });
            // await validateManualVinDeletesData(planId);
            return promisemanualVinDeletesDeleteResponse;
        }
    }
    catch (error) {
        console.error(error);
        throw 'Manual VIN Deletes tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}

export async function getManualVinDeletesTabErrorStatus(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseManualVinDeletesResponse = await client.apis.plans.getManualVinDeletesTabErrorStatus({"planid":planid?planid:1});
    if(promiseManualVinDeletesResponse.obj && promiseManualVinDeletesResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseManualVinDeletesResponse]);
    const [manualVinDeletesTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return manualVinDeletesTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Manual VIN Deletes tab error status, Please try again!!';
   }
   }

   export async function manualVinDeletesTabValidationService(planid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.plans.validateManualVinDeletes({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate Manual VIN Deletes tab, Please try again!!'
    }
   }