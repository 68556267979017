import {
    FETCH_CONSTRAINT_DATA_PENDING, FETCH_CONSTRAINT_DATA_SUCCESS,
    FETCH_CONSTRAINT_DATA_ERROR, FETCH_GROUPBY_CONSTRAINTS,
    FETCH_CONSTRAINT_FIELDS_PENDING, FETCH_CONSTRAINT_FIELDS_SUCCESS,
    FETCH_CONSTRAINT_FIELDS_ERROR,FILTER_SEARCH_CONSTRAINT_DATA, FETCH_CONSTRAINT_TYPES,
    FETCH_CONSTRAINT_FILTER_DATA_SUCCESS,
    DELETE_CONSTRAINT_DATA_PENDING, DELETE_CONSTRAINT_DATA_SUCCESS, DELETE_CONSTRAINT_DATA_ERROR,
    UPLOAD_CONSTRAINTS_PENDING, UPLOAD_CONSTRAINTS_SUCCESS,
    UPLOAD_CONSTRAINTS_ERROR,
    SAVE_CONSTRAINTS_TRANSACTION_PENDING, SAVE_CONSTRAINTS_TRANSACTION_SUCCESS, SAVE_CONSTRAINTS_TRANSACTION_ERROR,
    DISABLE_ACTION_BAR_ELEMENTS, SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_PENDING, SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_SUCCESS,
    SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR,RESET_TO_CONSTRAINTS_INITIALSTATE,SET_CONSTRAINTS_FILTER_PARAMS
} from './Constraints.ActionTypes';

const initialstate = {
    pending: false,
    constraints: [],
    constraintType: null,
    error: null,
    groupedConstraintsData: [],
    zones: [],
    sortedData: [],
    constraintFilterSearchData: [],
    constrainttypes: [],
    constraintFilterData: [],
    constraintFilterSearchKey: "",
    transactionSaveSuccess: null,
    isAccordionDataInEditMode: false,
    accordionTransactionSaveSuccess: null,
    uploadConstraintsSuccess: null,
    filterParams: null,
    constraintsWholeData:[],
    constraintsUploadState:false
}

function fetchGroupedData(selecteddata) {
    const { constraintdata, type, selectedvalue, sortkey, sortAscending } = selecteddata;
    let data = constraintdata.map(o => { return { ...o }; });
    let order = selectedvalue.split('>');
    let constraintData = [];
    order.map((val, index) => {
        if (val == 'Type') {
            order.splice(index, 1, 'constraint_type');
        } else if (val == 'Zone') {
            order.splice(index, 1, 'zone_name');
        } else if (val == 'BU') {
            order.splice(index, 1, 'bu')
        } else if (val == 'Zone') {
            order.splice(index, 2, 'zone_name');
        }
        return order;
    })
    let constraintgrouping = [];
    constraintgrouping = data.reduce((r, a) => {
        if (order[1] == 'zone_name') {
            r[a[order[0]]] = r[a[order[0]]] || {};
            if (a[order[1]] == undefined) {
                a[order[1]] = 'ALL ZONES';
            }
            r[a[order[0]]][a[order[1]]] = r[a[order[0]]][a[order[1]]] || [];
            r[a[order[0]]][a[order[1]]].push(a);
            // sorting
            if (sortkey == 'Date' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    if (a.month != undefined) {
                        return new Date(a.month) - new Date(b.month);
                    } else {
                        return new Date(a.to_date) - new Date(b.to_date);
                    }
                });
            }
            if (sortkey == 'Date' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    if (a.month != undefined) {
                        return new Date(b.month) - new Date(a.month);
                    } else {
                        return new Date(b.to_date) - new Date(a.to_date);
                    }
                });
            }
            if (sortkey == 'Min' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return a.min - b.min;
                });
            }
            if (sortkey == 'Min' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return b.min - a.min;
                });
            }
            if (sortkey == 'Max' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return a.max - b.max;
                });
            }
            if (sortkey == 'Max' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return b.max - a.max;
                });
            }
            //sorting
            return r;
        } else if (order[0] == 'zone_name') {
            r[a[order[0]]] = r[a[order[0]]] || {};
            if (a[order[0]] == undefined) {
                a[order[0]] = 'ALL ZONES';
            }
            r[a[order[0]]][a[order[1]]] = r[a[order[0]]][a[order[1]]] || [];
            r[a[order[0]]][a[order[1]]].push(a);
            // sorting
            if (sortkey == 'Date' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    if (a.month != undefined) {
                        return new Date(a.month) - new Date(b.month);
                    } else {
                        return new Date(a.to_date) - new Date(b.to_date);
                    }
                });
            }
            if (sortkey == 'Date' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    if (a.month != undefined) {
                        return new Date(b.month) - new Date(a.month);
                    } else {
                        return new Date(b.to_date) - new Date(a.to_date);
                    }
                });
            }
            if (sortkey == 'Min' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return a.min - b.min;
                });
            }
            if (sortkey == 'Min' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return b.min - a.min;
                });
            }
            if (sortkey == 'Max' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return a.max - b.max;
                });
            }
            if (sortkey == 'Max' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]].sort(function (a, b) {
                    return b.max - a.max;
                });
            }
            //sorting
            return r;
        }
        else if (order[1] == 'bu') {
            if (a[order[1]] == undefined || a[order[1]] == 'ALL') {
                a[order[1]] = 'ALL BUs';
            } else if (a[order[2]] == undefined) {
                a[order[1]] = 'ALL Zones';
            }
            r[a[order[0]]] = r[a[order[0]]] || {};
            r[a[order[0]]][a[order[1]]] = r[a[order[0]]][a[order[1]]] || {};
            r[a[order[0]]][a[order[1]]][a[order[2]]] = r[a[order[0]]][a[order[1]]][a[order[2]]] || [];
            r[a[order[0]]][a[order[1]]][a[order[2]]].push(a);
            // sorting
            if (sortkey == 'Date' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]][a[order[2]]].sort(function (a, b) {
                    if (a.month != undefined) {
                        return new Date(a.month) - new Date(b.month);
                    } else {
                        return new Date(a.to_date) - new Date(b.to_date);
                    }
                });
            }
            if (sortkey == 'Date' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]][a[order[2]]].sort(function (a, b) {
                    if (a.month != undefined) {
                        return new Date(b.month) - new Date(a.month);
                    } else {
                        return new Date(b.to_date) - new Date(a.to_date);
                    }
                });
            }
            if (sortkey == 'Min' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]][a[order[2]]].sort(function (a, b) {
                    return a.min - b.min;
                });
            }
            if (sortkey == 'Min' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]][a[order[2]]].sort(function (a, b) {
                    return b.min - a.min;
                });
            }
            if (sortkey == 'Max' && sortAscending == true) {
                r[a[order[0]]][a[order[1]]][a[order[2]]].sort(function (a, b) {
                    return a.max - b.max;
                });
            }
            if (sortkey == 'Max' && sortAscending == false) {
                r[a[order[0]]][a[order[1]]][a[order[2]]].sort(function (a, b) {
                    return b.max - a.max;
                });
            }
            //sorting
            return r;
        }
    }, Object.create(null));

    let groupedData = Object.entries(constraintgrouping);
    if (sortkey == 'Type' && sortAscending == true) {
        groupedData = groupedData.reverse();
    } else {
        groupedData = groupedData.sort();
    }

    groupedData.map((value, index) => {
        let obj = {}
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]);
        if (order.length > 2) {
            groupedData2.map((buvalue) => {
                let obj1 = {};
                obj1['children'] = [];
                obj1['header'] = '';
                obj1['header'] = buvalue[0];
                obj['children'].push(obj1);
                let groupedData3 = Object.entries(buvalue[1]);
                groupedData3.map((value1, ind) => {
                    let obj2 = {};
                    obj2['header'] = '';
                    obj2['children'] = [];
                    obj2['header'] = value1[0];
                    value1[1].map((value2, index) => {
                        let obj3 = {};
                        obj3['content'] = [];
                        obj3['content'].push(value2);
                        obj2['children'].push(obj3);
                    })
                    obj1['children'].push(obj2);
                })
            })
        }
        else {
            groupedData2.map((value1, ind) => {
                let obj1 = {}
                obj1['header'] = '';
                obj1['children'] = [];
                obj1['header'] = value1[0];
                obj['children'].push(obj1);
                Object.values(value1[1]).map((val, indx) => {
                    let obj2 = {};
                    obj2['content'] = [];
                    obj2['content'].push(val);
                    obj1['children'].push(obj2);
                })
            })
        }
        constraintData.push(obj);
    })
    return constraintData;
}



function fetchDropDownValues(constraintsData, field, dropdownData) {
    var dropdownData = [];
    var data = constraintsData.map(item => {
        // //console.log("Print the columnfield : " + item[element.field])
        if (item[field] !== undefined && item[field] !== null)
            return item[field]
    });

    //console.log("Print the data : " + JSON.stringify(data));

    var filterData = [];
    filterData = data.filter((value, index, self) => (self.indexOf(value) === index) && value !== undefined);
    //console.log("Filter Data : " + JSON.stringify(filterData));
    // if(!dropdownData[field]) {
    // dropdownData[field] = filterData;
    // }
    filterData.forEach(filter => {
        dropdownData.push({ label: filter, value: filter })
    });
    return dropdownData;
}

function checkIfKeyPresent(dropdownData, key) {
    let checkKeyPresenceInArray = dropdownData.some(obj => Object.keys(obj).includes(key));
    return checkKeyPresenceInArray;
}

function fetchFilterValues(constraintsData) {
    var dropdownData = [];
    // columns.forEach(element => {
    //     //console.log("Fetch the element type : " + JSON.stringify(element));
    //     var dropdownData = [];
    //     if (element.field == "constraint_type" || element.field == "zone_name" ||
    //         element.field == "pool" || element.field == "bu" ||
    //         element.field == "program_type" || element.field == "manufacturer_group_code" ||
    //         element.field == "car_group_name" || element.field == 'spi_code') {
    //         dropdownData = fetchDropDownValues(constraintsData, element.field, dropdownData);
    //         //console.log("Dropdown data : "+JSON.stringify(dropdownData));
    //     }
    // });   
    constraintsData.forEach(data => {
        var keys = Object.keys(data);
        //console.log("Keys for each data " + keys);
        keys.forEach(element => {
            if (element == "constraint_type") {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'constraint_type')
                if (!isKeyPresent) {
                    dropdownData.push({ "constraint_type": fetchDropDownValues(constraintsData, element) });
                }
            } if (element == 'zone_name') {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'zone_name')
                if (!isKeyPresent)
                    dropdownData.push({ "zone_name": fetchDropDownValues(constraintsData, element) });

            } if (element === 'pool') {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'pool')
                if (!isKeyPresent)
                    dropdownData.push({ "pool": fetchDropDownValues(constraintsData, element) });

            } if (element === 'bu') {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'bu')
                if (!isKeyPresent)
                    dropdownData.push({ "bu": fetchDropDownValues(constraintsData, element) });

            } if (element == "program_type") {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'program_type')
                if (!isKeyPresent)
                    dropdownData.push({ "program_type": fetchDropDownValues(constraintsData, element) });

            } if (element === 'manufacturer_group_code') {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'manufacturer_group_code')
                if (!isKeyPresent)
                    dropdownData.push({ "manufacturer_group_code": fetchDropDownValues(constraintsData, element) });

            } if (element == "car_group_name") {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'car_group_name')
                if (!isKeyPresent)
                    dropdownData.push({ "car_group_name": fetchDropDownValues(constraintsData, element) });
            } if (element === 'spi_code') {
                var isKeyPresent = checkIfKeyPresent(dropdownData, 'spi_code')
                if (!isKeyPresent)
                    dropdownData.push({ "spi_code": fetchDropDownValues(constraintsData, element) });
            }
        });
    });

    return [...dropdownData];
}

// function fetchZones(constraintsData, zones) {
//     constraintsData.forEach((constraint) => {
//         zones.forEach((zone) => {
//             if (zone.code === constraint.zone_code) {
//                 constraint.zone_name = zone.name;
//             }
//         });
//     })
//     return constraintsData;
// }


function fetchZones(constraintsData, zones) {
    // let zones = fetchAllZones();
    constraintsData.forEach((constraint) => {
        // if(constraint.constraint_type === undefined) {
        //     constraint.constraint_type = "ALL";
        // }
        if (constraint.pool === undefined) {
            constraint.pool = "ALL";
            constraint.Pool_code = "ALL"
        }
        zones.forEach((zone) => {
            if (zone.code == constraint.zone_code) {
                constraint.zone_name = zone.name;
            } else if (constraint.zone_code == "ALL") {
                constraint.zone_name = constraint.zone_code;
            }
            else if (constraint.zone_code == "ALL") {
                constraint.zone_name = constraint.zone_code;
            }
        });
        if (constraint.zone_code === undefined) {
            constraint.zone_name = "ALL";
        }
    })
    return constraintsData;
}

function fetchSearchValues(searchkey, constraintData) {
    let results = getSearchValues(searchkey, constraintData);
    return results;
}
function getSearchValues(searchkey, constraintData) {
    return constraintData.filter((item, i) => {
        let itemkeys = Object.keys(constraintData[i]);
        let entries = Object.entries(constraintData[i]);
        return entries.find((val, ind) => {
            if (val[0] !== "ID" && val[0] !== "id" && val[0] !== "constraint_table" && val[0] !== 'plan_id' && val[0] !== 'updated_by' && val[0] !== 'updated_on' && val[0] !== 'version' && val[0] !== 'zone_code' && val[0] !== 'from_date' && val[0] !== 'to_date') {
                if (val[1] !== null && !searchkey.includes(',')) {
                    return (val[1].toString().toLowerCase().includes(searchkey.toLowerCase()))
                }
                else if (searchkey.includes(',') && !searchkey.includes('$') && val[1] !== null) {
                    return (val[1].toString().startsWith(searchkey.toString().replace(/,/g, '')))
                }
            }
        })
    })
}

// function fetchConstraintTypes(constraintsData) {
//     let constrainttypes = [];
//     constraintsData.filter(function (e) {
//         if (e.constraint_type != undefined) {
//             constrainttypes.push(e.constraint_type);
//         }
//     });
//     return findCount(constrainttypes);
// }

// const findCount = (array) => {
//     let allCounts = 0;
//     const constraints = {};
//     let allConstraintTypes = ["ADDS", "DELETES", "FLEET", "NET G/L", "CORE_TO_TNC"];
//    array.forEach(item => {
//         if (constraints[item]) {
//             constraints[item] += 1;
//             allCounts += 1;
//             return
//         }
//         constraints[item] = 1;
//         allCounts += 1;
//     })
//     var keys = Object.keys(constraints);
//     allConstraintTypes.forEach(type => {
//         if (keys.indexOf(type) == -1) {
//             constraints[type] = 0;
//         }
//     });
//     let entries = Object.entries(constraints);
//     entries.sort();
//     let arr = [];
//     arr.push('ALL');
//     arr.push(allCounts);
//     entries.unshift(arr);
//     return entries;
// }

function fetchConstraintTypes(constraintsData) {
    let constraints = {};
    constraintsData.forEach((data) => {
        if (constraints[data.constraint_type]) {
            constraints[data.constraint_type] += 1;
        } else {
            constraints[data.constraint_type] = 1;
        }
    });
    if (Object.keys(constraints).length < 5) {
        let allConstraintTypes = ["ADDS", "DELETES", "FLEET", "NET G/L", "CORE_TO_TNC"];
        allConstraintTypes.forEach((types) => {
            if (!constraints.hasOwnProperty(types)) {
                constraints[types] = 0;
            }
        });
    }
    let entries = Object.entries(constraints);
    entries.sort();
    entries.unshift(["ALL", constraintsData.length]);
    return entries;
}

function sortDropdownElements(arr) {
    arr.sort(function (a, b) {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
    })
}

function fetchConstraintDropDownValues(constraintData, element) {
    var constraintsZoneDataWithZoneName = fetchFilterDataWithZone(constraintData.constraints);
    var data = constraintsZoneDataWithZoneName.map(item => item[element.colDef])
        .filter((value, index, self) => self.indexOf(value) === index);
    if (element.colDef === "constraint_type") {
        constraintData.constraint_type.map((val, index) => {
            let obj = {};
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
        })
    } else if (element.colDef == "zone_name") {
        constraintData.zones.map((val, index) => {
            let obj = {};
            // if (val !== 'ALL ZONES') {
            obj['label'] = val.name;
            obj['value'] = val.name;
            element.options.push(obj);
            // }
        })
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    } else if (element.colDef === "pool") {
        constraintData.pools.map((val, index) => {
            let obj = {};
            if (val !== undefined) {
                obj['label'] = val.code;
                obj['value'] = val.code;
                element.options.push(obj);
            }
        })
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    } else if (element.colDef === "bu") {
        constraintData.bu.map((val, index) => {
            let obj = {};
            if (val !== undefined) {
                obj['label'] = val;
                obj['value'] = val;
                element.options.push(obj);
            }
        })
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    } else if (element.colDef === "car_group_name") {
        constraintData.car_group_name.map((val, index) => {
            let obj = {};
            if (val !== undefined) {
                obj['label'] = val;
                obj['value'] = val;
                element.options.push(obj);
            }
        })
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);

    } else if (element.colDef === "program_type") {
        constraintData.program_type.map((val, index) => {
            let obj = {};
            if (val !== undefined) {
                obj['label'] = val;
                obj['value'] = val;
                element.options.push(obj);
            }
        })
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    } else if (element.colDef === "spi_code") {
        constraintData.spi_code.map((val, index) => {
            let obj = {};
            if (val !== undefined) {
                obj['label'] = val;
                obj['value'] = val;
                element.options.push(obj);
            }
        })
           let obj = {};
           obj['label'] = 'All';
           obj['value'] = 'All';
           element.options.push(obj);
            element.options = element.options.sort(function (a, b) {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            })
    } else if (element.colDef === "manufacturer_group_code") {
        constraintData.manufacturer_group.map((val, index) => {
            let obj = {};
            if (val !== undefined) {
                obj['label'] = val;
                obj['value'] = val;
                element.options.push(obj);
            }
        })
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    }
    return element;
}

function fetchConstraintFilterValues(object) {
    if (object.constraintData != undefined) {
        var constraintData = object.constraintData;
        var filterData = object.columns.data;
        filterData.forEach(element => {
            if (element.type == "dropdown")
                fetchConstraintDropDownValues(constraintData, element);
        });

    }
    return filterData;
}


function fetchFilterDataWithZone(data, zones) {
    if (zones) {
        data.forEach(element => {
            zones.forEach((zone) => {
                if (zone.code == element.zone_code) {
                    element.zone_name = zone.name;
                }
            });
        });
    }
    return data;
}

export default function constraintReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_CONSTRAINT_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case FETCH_CONSTRAINT_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                // constraints: fetchZones(action.data.constraints, action.data.zones),
                constraints:action.data.constraints,
                uploadConstraintsSuccess: null,
                constraintsWholeData:action.data,
                constraintsUploadState:false
            });
        case FETCH_CONSTRAINT_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case FETCH_GROUPBY_CONSTRAINTS:
            var groupedData = fetchGroupedData(action.data)
            return Object.assign({}, state, {
                groupedConstraintsData: groupedData,
                constraintsUploadState:false
            });
        case FETCH_CONSTRAINT_FIELDS_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case FETCH_CONSTRAINT_FIELDS_SUCCESS:
            // var constraintsData = fetchZones(action.data.constraints, action.data.zones)
            var filterValues = fetchFilterValues(action.data.constraints);
            return Object.assign({}, state, {
                pending: false,
                dropdownData: filterValues,
                constraintsWholeData:action.data,
                constraintsUploadState:false
            });
        case FETCH_CONSTRAINT_FIELDS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case FILTER_SEARCH_CONSTRAINT_DATA:
            return Object.assign({}, state, {
                constraintFilterSearchKey: action.data
            });
        case FETCH_CONSTRAINT_TYPES:
           var types = fetchConstraintTypes(action.data);
            return Object.assign({}, state, {
                constrainttypes: types,
                constraintsUploadState:false
            });
        case FETCH_CONSTRAINT_FILTER_DATA_SUCCESS:
            let filteractionData = fetchConstraintFilterValues(action.data);
            return Object.assign({}, state, {
                pending: false,
                constraintFilterData: filteractionData,
                constraintsUploadState:false
            });
        case DELETE_CONSTRAINT_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case DELETE_CONSTRAINT_DATA_SUCCESS:
            return Object.assign({}, state, {
                constraintFilterSearchKey: "",
                pending: false,
                // constraints: fetchZones(action.data.constraints, action.data.zones),
                constraints:action.data.constraints,
                constraintsWholeData:action.data,
                constraintsUploadState:false
            });
        case DELETE_CONSTRAINT_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case UPLOAD_CONSTRAINTS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                uploadConstraintsSuccess: null
            });
        case UPLOAD_CONSTRAINTS_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                // constraints: fetchZones(action.data.constraints, action.data.zones),
                constraints:action.data.constraints,
                uploadConstraintsSuccess: true,
                constraintsWholeData:action.data,
                constraintsUploadState:true
            });
        case UPLOAD_CONSTRAINTS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadConstraintsSuccess: false
            });
        case SAVE_CONSTRAINTS_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_CONSTRAINTS_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                constraintFilterSearchKey: "",
                // constraints: fetchZones(action.data.constraints, action.data.zones),
                constraints:action.data.constraints,
                transactionSaveSuccess: true,
                constraintsWholeData:action.data,
                constraintsUploadState:false
            });
        case SAVE_CONSTRAINTS_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                accordionTransactionSaveSuccess: null
            });
        case SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                constraints: fetchZones(action.data.constraints.constraints, 
                                        action.data.constraints.zones),
                constraintType: action.data.type,
                accordionTransactionSaveSuccess: true,
                constraintsWholeData:action.data.constraints,
                constraintsUploadState:false
            });
        case SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                accordionTransactionSaveSuccess: false
            });
        case DISABLE_ACTION_BAR_ELEMENTS:
            return Object.assign({}, state, {
                pending: false,
                isAccordionDataInEditMode: action.data,
                constraintsUploadState:false
            });
        case RESET_TO_CONSTRAINTS_INITIALSTATE:
            return initialstate;
        case SET_CONSTRAINTS_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterParams: action.data,
                constraintsUploadState:false
            });
        default:
            return state;
    }
}