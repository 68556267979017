import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import icon_close from "../../assets/images/close.svg";

const SingleButtonDialogComponent = (props) => {
    const { open, handleOk, dialogMessage, title, action } = props;

    function handleClickOk() {
        handleOk();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleOk}
                className="dialogbox"
            >
                <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                    <span>{title}</span>
                    <span><img src={icon_close} onClick={handleClickOk} className="closeicon" alt="iconclose"></img></span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="dialogcontent">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="actionbuttons">
                    <Button className='okbutton' onClick={handleClickOk} color="primary">
                        {action}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default SingleButtonDialogComponent;