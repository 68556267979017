import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button,createTheme } from '@material-ui/core';
import { populateGrid2 } from '../../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions';

const PlanManagementActionBar = (props)=>{
 const {options, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, downloadCSV, data, filterParams, allPlansData, columnDefinitionReducer, filterData, transactions,
   isFiltered,setIsFiltered} = props;

const dispatch = useDispatch();

useEffect(() => {
   if (allPlansData && allPlansData.length > 0) {
       //var filteredData = filterRecords(userManagementData, filterParams);
       dispatch(populateGrid2("planmanagementgrid", allPlansData, allPlansData,undefined,undefined, columnDefinitionReducer.validationFn, columnDefinitionReducer.businessValidationFn));
       //dispatch(validateGrid("usermanagementgrid", userManagementData, userManagementGridColDef.validationFn, userManagementGridColDef.businessValidationFn));
   }
   }, [allPlansData])

   return (
      <div className="mainActionBar">
      {/* Plan status is running should disable cancel save changes add +  */}
      {options?
        <div className="saveButtons">
          <div className="secondaryButtonParameters"><Button onClick={(e) => onCancelClicked(e)}>Cancel
        </Button></div>
          <div className="primaryButtonParameters">
            <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
          </Button></div>
          <div className="primaryButtonParameters">
            <Button onClick={(e) => onAddNewRowClick(e)}>Add +
        </Button>
          </div>

        </div> :
        (<div className="actionButtons">
          <div className="primaryButtonParameters">
            {/* <Button disabled={ !gridColDef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>
              Add +
                    </Button> */}
                    </div> : <div></div>
        </div>)}
      <div>
      </div>
    </div>
   )

}

export default PlanManagementActionBar;