import {getSalvageRateData, saveSalvageRateTransactions, fetchSalvageRateDropdownService,uploadSalvageRateCSV} from '../../services/salvagerate/SalvageRateService';
import {FETCH_SALVAGERATE_DATA_PENDING, FETCH_SALVAGERATE_DATA_SUCCESS, FETCH_SALVAGERATE_DATA_ERROR, UPLOAD_SALVAGERATE_PENDING, UPLOAD_SALVAGERATE_SUCCESS, UPLOAD_SALVAGERATE_ERROR,
    SAVE_SALVAGERATE_TRANSACTION_PENDING, SAVE_SALVAGERATE_TRANSACTION_SUCCESS, SAVE_SALVAGERATE_TRANSACTION_ERROR,
    FETCH_SALVAGERATE_DROPDOWNVALUES_PENDING,FETCH_SALVAGERATE_DROPDOWNVALUES_SUCCESS,FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR, RESET_TO_SALVAGERATE_INITIALSTATE} from '../../store/salvagerate/SalvageRate.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchSalvageRateData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_SALVAGERATE_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getSalvageRateData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_SALVAGERATE_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_SALVAGERATE_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_SALVAGERATE_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadSalvageRateData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_SALVAGERATE_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadSalvageRateCSV(planId, files);
            let salvageRateData = await getSalvageRateData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_SALVAGERATE_SUCCESS, salvageRateData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_SALVAGERATE_ERROR, "salvage rate upload failed"));
                dispatch(SetAssumptionError("salvage rate upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_SALVAGERATE_ERROR, "salvage rate upload failed"));
            dispatch(SetAssumptionError("salvage rate upload failed"))
        }
    }
}

export const saveSalvageRateTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_SALVAGERATE_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveSalvageRateTransactions(planid, transactionList);
            let salvageRateData = await getSalvageRateData(planid);
            if (salvageRateData) {
                dispatch(serviceActionSuccess(SAVE_SALVAGERATE_TRANSACTION_SUCCESS, salvageRateData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_SALVAGERATE_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchSalvageRateDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_SALVAGERATE_DROPDOWNVALUES_PENDING));
        let salvageRateData = await fetchSalvageRateDropdownService(planid);
        if (salvageRateData) {
            dispatch(serviceActionSuccess(FETCH_SALVAGERATE_DROPDOWNVALUES_SUCCESS, salvageRateData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("salvagerateGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{// this else is for old architecture and will be removed later
                    dispatch(validateGrid("salvagerategrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR, salvageRateData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetSalvageRate = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_SALVAGERATE_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}