export const FETCH_USER_MANAGEMENT_DATA_PENDING = 'FETCH_USER_MANAGEMENT_DATA_PENDING'; 
export const FETCH_USER_MANAGEMENT_DATA_SUCCESS = 'FETCH_USER_MANAGEMENT_DATA_SUCCESS';
export const FETCH_USER_MANAGEMENT_DATA_ERROR = 'FETCH_USER_MANAGEMENT_DATA_ERROR';

export const FETCH_ROLE_DROPDOWN_PENDING = 'FETCH_ROLE_DROPDOWN_PENDING';
export const FETCH_ROLE_DROPDOWN_SUCCESS = 'FETCH_ROLE_DROPDOWN_SUCCESS';
export const FETCH_ROLE_DROPDOWN_ERROR = 'FETCH_ROLE_DROPDOWN_ERROR';

export const SAVE_ROLE_MANAGEMENT_TRANSACTION_PENDING = 'SAVE_ROLE_MANAGEMENT_TRANSACTION_PENDING';
export const SAVE_ROLE_MANAGEMENT_TRANSACTION_SUCCESS = 'SAVE_ROLE_MANAGEMENT_TRANSACTION_SUCCESS';
export const SAVE_ROLE_MANAGEMENT_TRANSACTION_ERROR = 'SAVE_ROLE_MANAGEMENT_TRANSACTION_ERROR';