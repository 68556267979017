import { columnDefs, defaultColDef } from "./constraints_grid_data/ConstraintsColumnDefs";
import { FETCH_CONSTRAINT_TYPES_PENDING, FETCH_CONSTRAINT_TYPES_SUCCESS, FETCH_CONSTRAINT_TYPES_ERROR, SET_CONSTRAINTS_EDITABLE, SET_CONSTRAINT_EDITABLE } from "./Constraints.ActionTypes";
import { fetchAllZones } from "../../services/constraints/constraintsservice";
import { checkDate, safeCaseInsensitiveCompare } from "../../utils/utils";
import { ConstraintTableNameOptions, ConstraintsColumns, maximumDate, minimumDate } from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value)
        }
    },
    validationFn: function (data,transactions,uploadState) {
        var updatedConstraints = updateConstraintsTable(data);
        var validateData = validateBasedOnConstraint(updatedConstraints);
        return validateData;

    },
    businessValidationFn: function (currentData,transactions,uploadState) {
        var updatedConstraints = updateConstraintsTable(currentData);
        var duplicateData = findUniqueConstraints(updatedConstraints,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" && value !== "ADD_VOLUME_MAX" && value !== "id" && value !== "constraint_table" && value !== 'plan_id' && value !== 'zone_code' && value !== 'from_date' && value !== 'to_date' && value !== 'updated_by') {
        return true;
    } else {
        return false;
    }
}

function updateConstraintsTable(currentData) {
    var dataChanged;
    if (currentData && currentData.length > 0) {
        dataChanged = currentData.map((data) => {
            return updateConstraints(data);
        })
    } else if (currentData) {
        dataChanged = updateConstraints(currentData);
    }
    return dataChanged;
}

  function updateConstraints(data) {
    let fromMonth;
    let toMonth;
    let fromYear;
    let toYear;
    if (data.from_date) {
        fromMonth = new Date(data.from_date).getUTCMonth() + 1;
        fromYear = new Date(data.from_date).getUTCFullYear();
    }
    
    if (data.to_date) {
        toMonth = new Date(data.to_date).getUTCMonth() + 1;
        toYear = new Date(data.to_date).getUTCFullYear();
    }

    if((((data.constraint_type==='ADDS' || data.constraint_type==='DELETES')&& data.bu)||(data.constraint_type==='CORE_TO_TNC' && !data.bu)) && 
    data.zone_name==='ALL' && 
    (data.pool && data.pool!=='ALL') &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&
    data.program_type && (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0) &&
    !data.spi_code  && !data.car_group_name && !data.manufacturer_group_code){

        data.constraint_table = 'ADD_DEL_PM';
    }

    else if((((data.constraint_type==='ADDS' || data.constraint_type==='DELETES')&& data.bu)||(data.constraint_type==='CORE_TO_TNC' && !data.bu)) && 
    ( data.zone_name || data.zone_name==='ALL' )&& 
    data.pool ==='ALL' &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&(!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0) &&
    data.program_type && !data.spi_code && !data.car_group_name && !data.manufacturer_group_code){

        data.constraint_table = 'ADD_DEL_ZM';
    }

    else if( data.constraint_type==='ADDS' && data.zone_name==='ALL' && data.pool ==='ALL' &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear))&&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&& (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0) &&
    !data.bu && !data.program_type && !data.spi_code &&  !data.car_group_name && !data.manufacturer_group_code){

        data.constraint_table = 'ADD_M';
    }
    else if(data.constraint_type==='ADDS' && data.zone_name==='ALL' && data.pool ==='ALL' && 
    (fromMonth && toMonth && fromYear && toYear && (new Date(data.from_date)<=new Date(data.to_date))) &&
    (data.min || data.min === 0) && (data.max || data.max === 0) && !data.bu && data.program_type && data.spi_code && data.manufacturer_group_code && !data.car_group_name && 
    (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0))
    {
        data.constraint_table = 'ADD_OEM';
    }
    else if(data.constraint_type==='FLEET' && data.zone_name==='ALL' && data.pool ==='ALL' &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&
    data.bu &&
    !data.program_type && !data.spi_code && !data.car_group_name && !data.manufacturer_group_code){

        data.constraint_table = 'EF_M';
    }
    else if(data.constraint_type==='FLEET' && data.zone_name==='ALL' && (data.pool && data.pool!=='ALL') &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&
    data.bu  && !data.program_type && !data.spi_code && 
    !data.car_group_name && !data.manufacturer_group_code && 
    (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0)){

        data.constraint_table = 'EF_PM';
    }
    else if(data.constraint_type==='FLEET' && data.zone_name==='ALL' && (data.pool && data.pool!=='ALL') &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&
    data.bu  && !data.program_type && !data.spi_code && 
    data.car_group_name && !data.manufacturer_group_code && 
    (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0)){

        data.constraint_table = 'EF_PMC';
    }
    else if(data.constraint_type==='FLEET' && (data.zone_name && data.zone_name!=='ALL')&& data.pool ==='ALL' &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&
    data.bu  && !data.program_type && !data.spi_code && 
    !data.car_group_name && !data.manufacturer_group_code && 
    (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0)){

        data.constraint_table = 'EF_ZM';        
    }
    else if(data.constraint_type==='FLEET' && (data.zone_name && data.zone_name!=='ALL')&& data.pool ==='ALL' &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&&
    data.bu  && !data.program_type && !data.spi_code && 
    data.car_group_name && !data.manufacturer_group_code && 
    (!data.mileage_start && data.mileage_start!==0) && (!data.mileage_end && data.mileage_end!==0)){

        data.constraint_table = 'EF_ZMC';
    }
    else if(data.constraint_type==='NET G/L' && data.zone_name==='ALL' && (data.pool && data.pool!=='ALL') &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&& (!data.mileage_start && data.mileage_start!==0) && 
    (!data.mileage_end && data.mileage_end!==0) &&!data.bu && !data.program_type && 
    !data.spi_code &&  !data.car_group_name && !data.manufacturer_group_code){

        data.constraint_table = 'NETGL_PM';
    }
    else if(data.constraint_type==='NET G/L' && data.pool==='ALL' && (data.zone_name && data.zone_name!=='ALL') &&
    (fromMonth && toMonth && fromYear && toYear && (fromMonth === toMonth && fromYear === toYear)) &&
    (data.min||data.min=== 0)&&(data.max||data.max=== 0)&& (!data.mileage_start && data.mileage_start!==0) && 
    (!data.mileage_end && data.mileage_end!==0) &&!data.bu && !data.program_type && 
    !data.spi_code &&  !data.car_group_name && !data.manufacturer_group_code){

        data.constraint_table = 'NETGL_ZM';
    }
    else{
        data.constraint_table = 'UNKNOWN';
    }


    // if (data.constraint_type === 'NET G/L') {
    //     //NETGL Zone type
    //     if ((data.pool === 'ALL' && data.zone_name !== 'ALL') || (data.pool === 'ALL' && data.zone_name === 'ALL')) {
          
    //         data.constraint_table = 'NETGL_ZM';
          
    //     }
    //     //NETGL Pool type
    //     if ((data.pool !== 'ALL' && data.zone_name === 'ALL') ||(data.pool !== 'ALL' && data.zone_name !== 'ALL')) {
                
    //         data.constraint_table = 'NETGL_PM';
                
    //     }

    // }
    // else if (data.constraint_type === 'FLEET') {
    //     //Fleet All Zone All Pool type
    //     if (data.pool === undefined && data.zone_name === undefined) {
            
    //         data.constraint_table = 'EF_M';
         
    //     }
    //     if (data.pool === 'ALL' && data.zone_name === 'ALL') {
           
    //         data.constraint_table = 'EF_M';
           
    //     }
    //     //Fleet Pool type
    //     if (data.pool !== 'ALL' && data.zone_name === 'ALL') {
    //         if (data.car_group_name){
                
    //             data.constraint_table = 'EF_PMC';
                
    //         }
    //         else{
                
    //             data.constraint_table = 'EF_PM';
               
    //         }
    //     }
    //     //Fleet Zone type
    //     if (data.pool === 'ALL' && data.zone_name !== 'ALL') {
    //         if (data.car_group_name){
                
    //             data.constraint_table = 'EF_ZMC';
                
    //         }
    //         else{
               
    //             data.constraint_table = 'EF_ZM';
                
    //         }
    //     }
    // }
    // else if (data.constraint_type === 'ADDS') {
    //     //ADD All Zone All Pool type
    //     var fromMonth;
    //     var toMonth;
    //     var fromYear;
    //     var toYear;
    //      //Assume ADD_M if ADDS is selected and no other condition is satisfied--->commenting for now,need to confirm this
    //      if (data.pool === undefined || data.zone_name === undefined) {
        
    //         data.constraint_table = 'ADD_M';
            
    //     }
    //     if (data.from_date) {
    //         fromMonth = new Date(data.from_date).getMonth() + 1;
    //         fromYear = new Date(data.from_date).getFullYear();
    //     }

    //     if (data.to_date) {
    //         toMonth = new Date(data.to_date).getMonth() + 1;
    //         toYear = new Date(data.to_date).getFullYear();
    //     }
    //     //ADD Zone type
    //     if (data.pool === 'ALL' && (data.zone_name==='ALL' || data.zone_name) &&  (data.bu || data.program_type)&&(data.bu != "ALL") && (!data.spi_code && !data.manufacturer_group_code)) {
           
    //         data.constraint_table = 'ADD_DEL_ZM';
                   
    //     }  //ADD Pool type
    //     else if (data.pool !== 'ALL' && data.zone_name === 'ALL') {
    //         if (data.bu || data.program_type) {
                
    //             data.constraint_table = 'ADD_DEL_PM';
                
    //         }
    //     }
     
    //     if ((data.pool === 'ALL' && data.zone_name === 'ALL') && data.bu==null || (!data.pool && !data.zone_name)) {
    //         if (!data.program_type && !data.spi_code && !data.manufacturer_group_code && (((fromMonth && toMonth) && (fromYear && toYear)) && ((fromMonth == toMonth) && (fromYear == toYear)))){
                
    //             data.constraint_table = 'ADD_M';
                
    //         }
    //         else if (data.program_type && (data.spi_code || data.manufacturer_group_code)) {
                
    //             data.constraint_table = 'ADD_OEM';
                
    //         }
    //     }
      
    // }
    // else if (data.constraint_type === 'ADDS' || data.constraint_type === 'DELETES' || data.constraint_type === 'CORE_TO_TNC') {
    //  //Assume ADD_DEL_ZM if DELETES is selected and no other condition is satisfied--->commenting for now,need to confirm this
        
    //     data.constraint_table = 'ADD_DEL_ZM';
        
    //     if (data.pool !== 'ALL' && data.zone_name === 'ALL') {
            
    //         data.constraint_table = 'ADD_DEL_PM';
            
    //     }
    // }
    return data;
}

function validateBasedOnConstraint(data) {
    var updatedConstraints;
    if (data) {
        if (data.length > 0) {
            updatedConstraints = data.map((constraint) => {
                return validateConstraints(constraint)
            })
        } else {
            updatedConstraints = validateConstraints(data)
        }
    }
    return updatedConstraints;
}


const validationsBasedOnConstraintTableName = {
    'ADD_DEL_PM': (data) => {
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data, initialState.columnDefs.findIndex(itm => itm.field === ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date, ConstraintsColumns.FromDate.headerName, ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date, ConstraintsColumns.FromDate.headerName, ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName, ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName, ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueIsNotUndefinedNullOrEmptyBU(data.bu, ConstraintsColumns.BU.headerName, data) },
            program_type: { error: checkIfProgramTypeValid(data.program_type, ConstraintsColumns.ProgramType.headerName, data) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'ADD_DEL_ZM': (data) => {
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data, initialState.columnDefs.findIndex(itm => itm.field === ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date, ConstraintsColumns.FromDate.headerName, ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date, ConstraintsColumns.FromDate.headerName, ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName, ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName, ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueIsNotUndefinedNullOrEmptyBU(data.bu, ConstraintsColumns.BU.headerName, data) },
            program_type: { error: checkIfProgramTypeValid(data.program_type, ConstraintsColumns.ProgramType.headerName, data) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'ADD_M': (data) => {
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data, initialState.columnDefs.findIndex(itm => itm.field === ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date, ConstraintsColumns.FromDate.headerName, ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date, ConstraintsColumns.FromDate.headerName, ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName, ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName, ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName, data) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'ADD_OEM':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName) },
            program_type: { error: checkIfValueEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName, initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.ManufacturerGroupCode.field)) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'EF_M':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: validateMinMaxMileage(data.mileage_start, data.mileage_end, ConstraintsColumns.MileageStart.headerName,ConstraintsColumns.MileageEnd.headerName) },
            mileage_end: { error: validateMinMaxMileage(data.mileage_start, data.mileage_end, ConstraintsColumns.MileageStart.headerName,ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'EF_PM':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'EF_PMC':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'EF_ZM':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'EF_ZMC':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
            bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'NETGL_PM':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
            bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'NETGL_ZM':(data)=>{
        return {
            ID: data.ID,
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
            pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
            from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
            bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName) },
            program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
            spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
            manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
            car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
            mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
            mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    'UNKNOWN':(data)=>{
        return{
            ID: data.ID,
            constraint_table:{error: "Invalid Rule" },
            constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
            zone_name: { error: checkIfValueEmpty(data.zone_name, ConstraintsColumns.ZoneName.headerName) },
            pool: { error: checkIfValueEmpty(data.pool, ConstraintsColumns.Pool.headerName) },
            from_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            to_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
            min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
            max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
            bu: { error: null },
            program_type: { error: null },
            spi_code: { error: null },
            manufacturer_group_code: { error: null },
            car_group_name: { error: null },
            mileage_start: { error: null },
            mileage_end: { error: null },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    }
}
function validateConstraints(data) {
    if(validationsBasedOnConstraintTableName[data.constraint_table]){
        return validationsBasedOnConstraintTableName[data.constraint_table](data)
    }
    // if (data.constraint_table === 'ADD_DEL_PM' || data.constraint_table === 'ADD_DEL_ZM') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool,ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueIsNotUndefinedNullOrEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfProgramTypeValid(data.program_type, ConstraintsColumns.ProgramType.headerName,data) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if (data.constraint_table === 'ADD_M') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) }
    //     }
    // }
    // if (data.constraint_table === 'ADD_OEM') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName) },
    //         program_type: { error: checkIfValueEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName, initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.ManufacturerGroupCode.field)) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if (data.constraint_table === 'EF_M') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: validateMinMaxMileage(data.mileage_start, data.mileage_end, ConstraintsColumns.MileageStart.headerName,ConstraintsColumns.MileageEnd.headerName) },
    //         mileage_end: { error: validateMinMaxMileage(data.mileage_start, data.mileage_end, ConstraintsColumns.MileageStart.headerName,ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if (data.constraint_table === 'EF_PM') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) }
    //     }
    // }
    // if (data.constraint_table === 'EF_PMC') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if (data.constraint_table === 'EF_ZM') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if (data.constraint_table === 'EF_ZMC') {
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, false) },
    //         bu: { error: checkIfValueEmptyBU(data.bu, ConstraintsColumns.BU.headerName,data) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if (data.constraint_table === 'NETGL_PM' || data.constraint_table === 'NETGL_ZM') {
    //     //console.log("Inside net gl condition");
    //     return {
    //         ID: data.ID,
    //         constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //         zone_name: { error: checkIfZoneValid(data.zone_name, ConstraintsColumns.ZoneName.headerName, data) },
    //         pool: { error: checkIfPoolValid(data.pool, ConstraintsColumns.Pool.headerName, data,initialState.columnDefs.findIndex(itm=>itm.field===ConstraintsColumns.Pool.field)) },
    //         from_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         to_date: { error: checkIfDatesMatch(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //         min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
    //         max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
    //         bu: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.bu, ConstraintsColumns.BU.headerName) },
    //         program_type: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.program_type, ConstraintsColumns.ProgramType.headerName) },
    //         spi_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.spi_code, ConstraintsColumns.SpiCode.headerName) },
    //         manufacturer_group_code: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.manufacturer_group_code, ConstraintsColumns.ManufacturerGroupCode.headerName) },
    //         car_group_name: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.car_group_name, ConstraintsColumns.CarGroupName.headerName) },
    //         mileage_start: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_start, ConstraintsColumns.MileageStart.headerName) },
    //         mileage_end: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.mileage_end, ConstraintsColumns.MileageEnd.headerName) },
    //     }
    // }
    // if(data.constraint_table==='UNKNOWN'){
    //     return {
    //     ID: data.ID,
    //     constraint_table:{error: "Invalid Rule" },
    //     constraint_type: { error: checkIfValueEmpty(data.constraint_type, ConstraintsColumns.ConstraintType.headerName) },
    //     zone_name: { error: checkIfValueEmpty(data.zone_name, ConstraintsColumns.ZoneName.headerName) },
    //     pool: { error: checkIfValueEmpty(data.pool, ConstraintsColumns.Pool.headerName) },
    //     from_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //     to_date: { error: checkIfDateIsValid(data.from_date, data.to_date,ConstraintsColumns.FromDate.headerName,ConstraintsColumns.ToDate.headerName) },
    //     min: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
    //     max: { error: validateMinMaxFields(data.min, data.max, ConstraintsColumns.Min.headerName,ConstraintsColumns.Max.headerName, true) },
    //     bu: { error: null },
    //     program_type: { error: null },
    //     spi_code: { error: null },
    //     manufacturer_group_code: { error: null },
    //     car_group_name: { error: null },
    //     mileage_start: { error: null },
    //     mileage_end: { error: null },
    // }
    // }
}
function checkIfZoneValid(value, name, data){
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if((data.constraint_table=='ADD_M' || data.constraint_table=='ADD_DEL_PM' || data.constraint_table=='ADD_OEM' || data.constraint_table=='EF_PM' || data.constraint_table=='EF_PMC'|| data.constraint_table =='EF_M' || data.constraint_table=='NETGL_PM') && value!=='ALL'){
        return name + ' '+'is not valid for constraint type';
    }
    else if((data.constraint_table=='EF_ZM' || data.constraint_table=='EF_ZMC' || data.constraint_table=='NETGL_ZM') && value=='ALL'){
        return name + ' '+'is not valid for constraint type';
    }
    return null;
}

function checkIfPoolValid(value, name, data,cdata){
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(( data.constraint_table=='ADD_DEL_PM'  || data.constraint_table=='EF_PM' || data.constraint_table=='EF_PMC'|| data.constraint_table=='NETGL_PM') && value=='ALL'){
        return name + ' '+'is not valid for constraint type';
    }
    else if((data.constraint_table=='ADD_M' || data.constraint_table=='ADD_DEL_ZM' || data.constraint_table=='ADD_OEM' || data.constraint_table =='EF_M' || data.constraint_table=='EF_ZM' || data.constraint_table=='EF_ZMC' || data.constraint_table=='NETGL_ZM') && value!=='ALL'){
        return name + ' '+'is not valid for constraint type';
    } else
    if (initialState.columnDefs[cdata].cellEditorParams !== undefined) {
        if (initialState.columnDefs[cdata].cellEditorParams.options.indexOf(value) == -1) {  
            return name +" "+value+" no-longer available";
        } else return null
    }
    return null;
}

function findUniqueConstraints(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    var validateData = [];
    if (data && data.length > 0)
        validateData = data;
    else if (data.validateData && data.validateData.length > 0)
        validateData = data.validateData;

    validateData.forEach(function (itm) {
        var unique = true;
        if (itm.constraint_table === 'ADD_DEL_PM' || itm.constraint_table === 'ADD_DEL_ZM') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name)  &&
                    safeCaseInsensitiveCompare(itm.program_type, itm2.program_type) && (!(itm.constraint_type==='CORE_TO_TNC' && itm2.constraint_type==='CORE_TO_TNC') ? safeCaseInsensitiveCompare(itm.bu, itm2.bu) : true) //If type is CORE_TO_TNC,bu will be null and safeCaseInsensitiveCompare will return false and not considering rows as duplicate rows so making it as true.
                    && checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;

                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if (itm.constraint_table === 'ADD_M') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name)&&
                    checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if (itm.constraint_table === 'ADD_OEM') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name)  &&
                    safeCaseInsensitiveCompare(itm.program_type, itm2.program_type) && safeCaseInsensitiveCompare(itm.spi_code, itm2.spi_code) &&
                    safeCaseInsensitiveCompare(itm.manufacturer_group_code, itm2.manufacturer_group_code)&&
                    checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if (itm.constraint_table === 'EF_M') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name)  &&
                    safeCaseInsensitiveCompare(itm.bu, itm2.bu) && itm.mileage_start === itm2.mileage_start &&
                    itm.mileage_end === itm2.mileage_end&&
                    checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if (itm.constraint_table === 'EF_PM' || itm.constraint_table === 'EF_ZM') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type  &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name) &&itm.from_date === itm2.from_date && itm.to_date === itm2.to_date
                    && safeCaseInsensitiveCompare(itm.bu, itm2.bu)&&
                    checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if (itm.constraint_table === 'EF_PMC' || itm.constraint_table === 'EF_ZMC') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name) &&
                    safeCaseInsensitiveCompare(itm.bu, itm2.bu) &&
                    safeCaseInsensitiveCompare(itm.car_group_name, itm2.car_group_name) &&
                    checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if (itm.constraint_table === 'NETGL_PM' || 
        itm.constraint_table === 'NETGL_ZM') {
            cleaned.forEach(function (itm2) {
                if (itm.constraint_table === itm2.constraint_table &&
                    itm.constraint_type === itm2.constraint_type &&
                    safeCaseInsensitiveCompare(itm.pool, itm2.pool) && safeCaseInsensitiveCompare(itm.zone_name, itm2.zone_name)&&
                    checkDate(itm.from_date,itm2.from_date) && checkDate(itm.to_date ,itm2.to_date)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
        }
        if ((itm.constraint_table === 'ADD_M' || itm.constraint_table === 'ADD_OEM') &&
            itm.constraint_type !== 'ADDS') {
            if (itm.status == "ADD_ROW"){
                itm.status = "ERROR_NEW_ROW";
            }
            if(itm.status == "COPY_ROW"){
                itm.status = "ERROR_COPY_ROW";
            }
            else {
                itm.status = "ERROR_ROW";
            }
            duplicate.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}
function checkIfValueEmpty(value, name, data) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    if((name === ConstraintsColumns.ManufacturerGroupCode.headerName)){
        if (initialState.columnDefs[data].cellEditorParams && initialState.columnDefs[data].cellEditorParams.options) {
            if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                    return name +" "+value+" no-longer available";
            }
        }
    }
    return null;
}

function checkIfProgramTypeValid(value, name, data){
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(( data.constraint_table=='ADD_DEL_ZM'  || data.constraint_table=='ADD_DEL_PM' ) && value=='ALL'){
        return name + ' '+'Cannot be ALL';
    }
    return null;
}
function checkIfValueEmptyBU(value, name,data) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(( data.constraint_type=='FLEET') && value=='ALL'){
        return name + ' '+'Cannot be ALL';
    }
    else if(!isBUValid(value)){
        return name + " has an invalid value";
    }
    return null;
}
function checkIfValueIsNotUndefinedNullOrEmptyBU(value, name,data) {
    if((value === undefined || value === "" || value === null) && !(data.constraint_type==='CORE_TO_TNC')){
        return name + " cannot be empty";
    }else if((!(value === undefined || value === "" || value === null)) && !(data.constraint_type==='CORE_TO_TNC') && !isBUValid(value)){
        return name + " has an invalid value";
    }else if((!(value === undefined || value === "" || value === null)) && data.constraint_type==='CORE_TO_TNC'){
        return name + " is not valid for this constraint type.";
        //bu SHOULD NOT be all seperate functions for seperate conditions
    }else if(( data.constraint_type=='FLEET') && value=='ALL'){
        return name + ' '+'Cannot be ALL';
    }
    return null;
}
function checkIfValueIsNotUndefinedNullOrEmpty(value, name) {
    if (!(value === undefined || value === "" || value === null)) {
        return name + " is not valid for this constraint type.";
    }
    return null;
}

function checkIfValidYear(value, name) {
    if (value === undefined || value === '')
        return name + " cannot be empty";
    else {
        var itemValue = parseInt(value, 0);
        if (itemValue > new Date().getFullYear() || itemValue < 1900)
            return name + " should be in between 1900 and current year"
    }
    return null;
}

function checkIfDatesMatch(min, max,minDisplayName,maxDisplayName) {
    // var min;
    // var max;
    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Fields cannot be empty';
    }

    // var month1 = new Date(min).getUTCMonth() + 1;
    // var year1 = new Date(min).getUTCFullYear();
    // var month2 = new Date(max).getUTCMonth() + 1;
    // var year2 = new Date(max).getUTCFullYear();
    // if (month1 == month2 && year1 == year2) {
    //     return null;
    // } else {
    //     return minDisplayName+" date and "+maxDisplayName+" date should be the same month and year";
    // }
    else if (min == 'Invalid date' || max == 'Invalid date') {
        return minDisplayName + " date" + " and " + maxDisplayName + ' date cannot be invalid date';
    }
    else if (!moment(min).isBetween(minimumDate, maximumDate, 'year', '[]') || (!moment(max).isBetween(minimumDate, maximumDate, 'year', '[]'))) {
        return minDisplayName + " date" + " and " + maxDisplayName + " date should be in +5 or -10 range from the current year"
    } 
    else if (!moment(min).isSame(moment(max), 'month')) {
        return minDisplayName + " date and " + maxDisplayName + " date should be the same month and year";
    }
    return null;    
}

function checkIfDateIsValid(min, max,minDisplayName,maxDisplayName) {
    // var min;
    // var max;
    // if(data.from_date && data.to_date) {
    //     min = data.from_date;
    //     max = data.to_date;
    // }
    // if(data.from_date && data.month) {
    //     min = data.from_date;
    //     max = data.month;
    // }
    // if(data.to_date && data.month) {
    //     min = data.month;
    //     max = data.to_date;
    // }
    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Fields cannot be empty';
    }
    else if(min == 'Invalid date' || max == 'Invalid date'){
        return minDisplayName +" date" +" and " +  maxDisplayName + ' date cannot be invalid date';
    }
    else if (!moment(min).isBetween(minimumDate, maximumDate,'year','[]') || (!moment(max).isBetween(minimumDate, maximumDate,'year','[]'))){
        return minDisplayName +" date" +" and " +  maxDisplayName +  " date should be in +5 or -10 range from the current year"
    }
    else if(moment(min).isAfter(moment(max),'month')){
       return minDisplayName +" date should be less than "+ maxDisplayName+" date";
    }

    // var d1 = new Date(min);
    // var d2 = new Date(max);
    // if (((d1.getMonth() <= d2.getMonth()) && (d1.getFullYear() > d2.getFullYear())) || ((d1.getMonth() > d2.getMonth()) && (d1.getFullYear() >= d2.getFullYear()))) {
    //     return minDisplayName+" date should be less than "+maxDisplayName+" date";
    // } 
    else
        return null;
}

function validateMinMaxFields(min, max, minDisplayName,maxDisplayName, isNegativeAllowed) {

    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Fields cannot be empty';
    }
    if (!isNegativeAllowed) {
        if (min < 0 || max < 0 || min > 999999 || min > 999999) {
            return 'Please enter valid '+minDisplayName+' '+ maxDisplayName+' volume'
        }
    } else {
        if (min > 999999 || min > 999999) {
            return 'Please enter valid '+minDisplayName+' '+ maxDisplayName+' volume'
        }
    }
    if (parseInt(min) > parseInt(max)) {
        return minDisplayName + ' volume' + ' should be less than ' + maxDisplayName+ ' volume'
    }
    return null;
}

function validateMinMaxMileage(min, max, minDisplayName,maxDisplayName) {
    if ((min === undefined || min === '' || min === null) && (max === undefined || max === '' || max === null)) {
        return null; //don't validate
    }
    else if ((min !== undefined || min !== '' || min !== null) && (max === undefined || max === '' || max === null)) {
        return `${maxDisplayName} cannot be empty`;
    }
    else if ((max !== undefined || max !== '' || max !== null) && (min === undefined || min === '' || min === null)) {
        return `${minDisplayName} cannot be empty`;
    }

    if (min > 999999 || min > 999999) {
        return 'Please enter valid ' + minDisplayName+' '+maxDisplayName
    }
    if (parseInt(min) > parseInt(max)) {
        return minDisplayName + ' should be less than ' + maxDisplayName
    }
    return null;
}


function fetchDropDownValues(spiCode, element) {
    // var data = constraintsData.map(item => {
    //     if (item[element.field] !== undefined && item[element.field] !== null)
    //         return item[element.field]
    // });
    // var filterData = [];
    // filterData = data.filter((value, index, self) => (self.indexOf(value) === index) && value !== undefined);
    let spiCodeFilterData=[]
    spiCode.map((val)=>{
     spiCodeFilterData.push({ "label": val, "value": val })
    })
    spiCodeFilterData.push({"label":"Blanks","value":"Blanks"})
    element.cellEditorParams = { options: spiCode };
    element.filterOptions = spiCodeFilterData;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData=[];
    //zoneData.push("ALL")
    zones.forEach(val => {
        zoneFilterData.push({ "label": val.name, "value": val.name })
        //if (val.name != "ALL")
            zoneData.push(val.name)
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: zoneData };
    element.filterOptions = zoneFilterData;
}

function fetchDropdownsForPools(pools, adminpools, element) {
    var poolData = [], poolsFilterData = [];;
    //poolData.push('ALL');
    pools.forEach(val => {
        //if (val.code != 'ALL')
            poolData.push(val.code)
    });
    adminpools.forEach(val => {
        poolsFilterData.push({ "label": val.code, "value": val.code })
    })
    poolsFilterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: poolData };
    element.filterOptions = poolsFilterData;
}

function fetchDropdownForcarGroupName(carGroupName, admincarGroupName, element) {
    let carGroupNameFilterData = []
    admincarGroupName.map((val) => {
        carGroupNameFilterData.push({ "label": val, "value": val })
    })
    carGroupNameFilterData.push({ "label": "Blanks", "value": "Blanks" });
    element.cellEditorParams = { options: carGroupName };
    element.filterOptions = carGroupNameFilterData;
}

function fetchDropdownFormanufacturerGroup(manufacturerGroup, adminManufacturerGroup, element) {
    let manufacturerGroupFilterData = []
    adminManufacturerGroup.map((val) => {
        manufacturerGroupFilterData.push({ "label": val.code, "value": val.code })
    })
    manufacturerGroupFilterData.push({ "label": "Blanks", "value": "Blanks" });
    element.cellEditorParams = { options: manufacturerGroup };
    element.filterOptions = manufacturerGroupFilterData;
}

function fetchDropdownsForConstraintTable(element){
    let constraintTableOptionsFilterData=[];
    ConstraintTableNameOptions.map((val)=>{// ConstraintTableNameOptions consists an array of values for constraint_table column options
        constraintTableOptionsFilterData.push({"label":val,"value":val})
    })
    element.filterOptions = constraintTableOptionsFilterData;
}

function fetchDropdownsForConstraintTypes(constraintType, element) {
    let constraintTypeFilterData=[]
    constraintType.map((val)=>{
        constraintTypeFilterData.push({"label":val,"value":val})
    })
    constraintTypeFilterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: constraintType };
    element.filterOptions = constraintTypeFilterData;
}

function fetchDropdownValueOthers(data, element) {
    let filterData=[];
    data.map((val)=>{
        filterData.push({"label":val,"value":val})
    })
    filterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: data };
    element.filterOptions = filterData;
}


function fetchZones(constraintsData, zones) {
    // let zones = fetchAllZones();
    constraintsData.forEach((constraint) => {
        // if(constraint.constraint_type === undefined) {
        //     constraint.constraint_type = "ALL";
        // }
        if (constraint.pool === undefined) {
            constraint.pool = "ALL";
        }
        zones.forEach((zone) => {
            //TODO: review this. Zone code on programs response is coming back as string while it is integer master table. 
            if (zone.code == constraint.zone_code) {
                constraint.zone_name = zone.name;
            }
        });
        if (constraint.zone_code === undefined) {
            constraint.zone_name = "ALL";
        }
    })
    return constraintsData;
}

function fetchAllPools(constraintsData) {
    let zones = fetchAllZones();
    constraintsData.forEach((constraint) => {
        zones.forEach((zone) => {
            if (zone.code == constraint.zone_code) {
                constraint.zone_name = zone.name;
            }
        });
    })
    return constraintsData;
}

function fetchFilterValues(constraintType, programType, carGroupName, bu, manufacturerGroup, spiCode, zones, pools,adminpools,admincarGroupName,adminManufacturerGroup,users, columns) {
    columns.forEach(element => {
        if (element.field == ConstraintsColumns.ConstraintTable.field){
            fetchDropdownsForConstraintTable(element)
        }
        if (element.field == ConstraintsColumns.SpiCode.field) {
            fetchDropDownValues(spiCode, element);
        } else if (element.field == ConstraintsColumns.ZoneName.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field == ConstraintsColumns.Pool.field) {
            fetchDropdownsForPools(pools,adminpools, element);
        } else if (element.field == ConstraintsColumns.ConstraintType.field) {
            fetchDropdownsForConstraintTypes(constraintType, element);
        } else if (element.field == ConstraintsColumns.CarGroupName.field) {
            fetchDropdownForcarGroupName(carGroupName,admincarGroupName, element);
        } else if (element.field == ConstraintsColumns.BU.field) {
            fetchDropdownValueOthers(bu, element);
        } else if (element.field == ConstraintsColumns.ProgramType.field) {
            fetchDropdownValueOthers(programType, element);
        } else if (element.field == ConstraintsColumns.ManufacturerGroupCode.field) {
            fetchDropdownFormanufacturerGroup(manufacturerGroup,adminManufacturerGroup, element);
        }else if (element.field == ConstraintsColumns.UpdatedBy.field) {
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData
        }
        else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'constraintsActionRenderer'
            }
        }
    });

    return columns;
}

function isBUValid(bu){
    let buDataFromSession= sessionStorage.getItem("BU") ? (JSON.parse(sessionStorage.getItem("BU"))):{};
    if(buDataFromSession && buDataFromSession.obj && buDataFromSession.obj.data){
        // console.log("buDataFromSession.obj.data==>", buDataFromSession.obj.data);
        return buDataFromSession.obj.data.includes(bu);
    }
    
    return false;
}

export default function constraintsColumnDefReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CONSTRAINT_TYPES_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case FETCH_CONSTRAINT_TYPES_SUCCESS:
            //var constraintsData = fetchZones(action.data.constraints, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.constraint_type,
                    action.data.program_type, action.data.car_group_name, action.data.bu,
                    action.data.manufacturer_group, action.data.spi_code,
                    action.data.zones, action.data.pools,action.data.adminpools,action.data.admincarGroupName,action.data.adminManufacturerGroup,action.data.users, state.columnDefs)
            });
        case FETCH_CONSTRAINT_TYPES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_CONSTRAINT_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}