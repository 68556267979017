import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import './UserManagementActionbar.css';
//import IconActionUpload from "../../assets/images/icon_action_upload.svg";
//import IconActionDownload from "../../assets/images/icon_action_download.svg";
// import DialogBox from "../dialog_component/CustomDialog";
// import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';
// import IconFilter from "../../assets/images/icon_filter.svg";
// import SearchBar from "../searchbar/SearchBarComponent";
import { Button,createTheme } from '@material-ui/core';
import { makeStyles, } from "@material-ui/core/styles";
import { safeExcelValue, safeExcelDate} from '../../../utils/utils';
import { populateGrid2, validateGrid } from '../../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions'
import DialogBox from '../../Dialogcomponent/DialogComponent';
//import { uploadAllocatedData, fetchFilterData, setFilterParameters, fetchUserManagementData } from '../../../store/admin/usermanagement/UserManagement.Actions';


const UserManagementActionbar = (props) => {
    const { options, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, downloadCSV, data, filterParams, userManagementData, userManagementGridColDef, filterData, transactions,
            isFiltered,setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists  } = props;
    const theme = createTheme();
    //editable donwload 
    // useEffect(()=>{
    //     clearFilter()
    // },[])
      
    theme.typography.body2 = {
        fontSize: '14px'
    };

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    // const changeHandler = async (event) => {
    //     if (event.target.value !== '') {
    //         const fileToUpload = event.target.files[0];
    //         event.target.value = '';
    //         dispatch(uploadAllocatedData(selectedPlanId, fileToUpload));
    //     }
    // };

    // const downloadHandler = async (event) => {
    //     let JsonFields = ["spi_code", "manufacturer_group", "car_class_code", "model_group", "pool", "requested_delivery_month", "model_year", "count"]
    //     let data1 = JsonToCSV(data, JsonFields);
    //     let currentdate = new Date();
    //     let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
    //     downloadCSV(data1, `allocated-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`);
    // };

    // function JsonToCSV(JsonArray, JsonFields) {
    //     var csvStr = JsonFields.join(",") + "\n";
    // 
    //     JsonArray.forEach(element => {
    //         let spi_code = safeExcelValue(element.spi_code);
    //         let manufacturer_group = safeExcelValue(element.manufacturer_group);
    //         let car_class_code = safeExcelValue(element.car_class_code);
    //         let model_group = safeExcelValue(element.model_group);
    //         let pool = safeExcelValue(element.pool);
    //         let requested_delivery_month = safeExcelDate(element.requested_delivery_month, '01-MMM-YY');
    //         let model_year = safeExcelValue(element.model_year);
    //         let count = safeExcelValue(element.count);
    //         csvStr += spi_code + ',' + manufacturer_group + ',' + car_class_code + ',' + model_group + ',' + pool + ',' + requested_delivery_month + ',' + model_year + ',' + count + "\n";
    //     })
    //     return csvStr;
    // }

    // const loadFilterData = useCallback(async () => {
    //     try {
    //         await dispatch(fetchFilterData(selectedPlanId));
    //     } catch (err) {
    //         console.error('got error while loading filter data');
    //     }
    // }, [dispatch]);

  useEffect(() => {
    if (userManagementData.length > 0) {
        //var filteredData = filterRecords(userManagementData, filterParams);
        dispatch(populateGrid2("usermanagementgrid", userManagementData, userManagementData,undefined,undefined, userManagementGridColDef.validationFn, userManagementGridColDef.businessValidationFn));
        //dispatch(validateGrid("usermanagementgrid", userManagementData, userManagementGridColDef.validationFn, userManagementGridColDef.businessValidationFn));
    }
    }, [userManagementData])

    return (
        <div className="mainHeader">
            {/* Disable save cancel add +  buttons if are able to select cell */}
            {options ?
                <div className="saveButtons">
                    <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
                    </Button></div>
                    <div className="primaryButton">
                        <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
                         </Button></div>
                    {/* <div className="primaryButton">
                        <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ifPlanEditDisabled}>
                            Delete
                        </Button>
                    </div> */}
                </div> :
                <div className="saveButtons">
                    <div className="primaryButton"></div>
                </div>
            }
        </div>
    )
}

export default UserManagementActionbar;