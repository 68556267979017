import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconFilter from "../../../assets/images/icon_filter.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconActionDownload from '../../../assets/images/icon_action_download.svg';
import SearchBar from "../../searchbar/SearchBar";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Button, Divider, Input, List, ListItem, ListItemText, MenuItem } from '@material-ui/core';
import CustomPopOver from "../../../core-components/Popover/CustomPopOver";
import StatusIcon from "../../../assets/images/icons_status_icons_completed.svg";
import { NttLabel } from '../../../core-components/Label/NttLabel';
import { downloadData } from '../../../utils/utils';
import { populateGrid2, validateGrid } from '../../../store/revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions';
import FilterComponent from '../../new_filter_components/FilterComponent';
import S3UploadDownloadComponent from '../../s3_upload_download_component/S3UploadDownloadComponent';
import { MAX_ROWS_ALLOWED_FOR_EACH_GRID } from '../../../Constants';
import { addNotificationMessage } from '../../../store/topbar/AllPlans.Actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={'simple-tabpanel-${index}'}
          aria-labelledby={'simple-tab-${index}'}
          {...other}
      >
          {value === index && (
              <Box p={3}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const tabStyle = {
  default_tab: {
    color: "#434244",
    fontSize: 14,
    fontWeight: "bold"
  },
  active_tab: {
    background: "#ffed9b",
    borderRadius: "4px",
    color: "#434244",
    color: "black",
    fontSize: 14,
    fontWeight: "bold"
  },
}
const getStyle = (isActive, tabKey) => {
  return isActive ? tabStyle.active_tab : tabStyle.default_tab;
}
const RevenueInputsActionBar = (props) => {
  const { RevenueInputsData, pending,RevenueInputsGrid,showOption, actionView, onViewChanged, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, selectedRows, data,
    filterParams, tncIcetransactions,tncEvtransactions,tncEvData,tncIceData,tncParametersData,RevenueInputsGridColDefReducer, filterSearchKey, isFiltered,
    setIsFiltered, setIsSearchBarValueExists,onTableNameChange,onTypeNameChange,typeName,selectedTableName,setGenerateRfProcessClicked,loadGridDataForFilterParams,initializeFilterParamsAndViewStatus,userMessage,downloadCSV,rfid } = props;
    
    const [value, setValue] = useState(0);
  let [disabled, setDisabled] = useState(false);
  let [currentView, setCurrentView] = useState(actionView);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState('TNC');
  var filterCat = { "PROGRAM_YEAR": [], "PROGRAM_TYPE": [], "ACCEPTABLE_ZONES": [], "CAR_CLASS_CODE": [], "MODEL_GROUP": [], "MODEL_YEAR": [], "NET_CAPITAL_COST": "", "BEGIN_DATE": ["between"], "END_DATE": ["between"], "MIN_AGE": ["between"], "MAX_AGE": ["between"], "DEPRECIATION_COST": "", "MIN_MILEAGE": ["between"], "MAX_MILEAGE": ["between"], "INCENTIVE": "", "ADD_VOLUME_MIN": ["between"], "ADD_VOLUME_MAX": ["between"], "SPI_CODE": [], "MANUFACTURER_GROUP": [], "START_MILEAGE": "", "BUY_STATUS": [] };
  const [filterArray, setFilterArray] = useState(filterCat);
  const [tempFilterArray, setTempFilterArray] = useState(filterArray);
  const [popUp, setPopUp] = useState(null);
  const theme = createMuiTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(-1);
  const [filterClassName, setFilterClassName] = useState('filterCustomButton');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsFilterSearchKey, setFlterSearchKey] = useState('');
  const[fileNameToDownload,setFileNameToDownload]=useState(null);
  const filterPopOverOpen = Boolean(filterAnchorEl);
  const filterId = filterPopOverOpen ? 'simple-popover' : undefined;
  let message = "Are you sure that you want to delete selected rows?"
  let title = "Delete Row";
  let deletetext = "Delete";
  let canceltext = "Cancel";


  const ITEM_HEIGHT = 48;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.9,
        width: 250,
      },
    },
  };

  theme.typography.body2 = {
    fontSize: '14px'
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: '180vh',
      maxHeight: '180vh',
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  // const [selectedDropdown, setSelectedDropdown] = React.useState("between");
  // const [selectedFromDate, setSelectedFromDate] = React.useState();
  // const [selectedToDate, setSelectedToDate] = React.useState();
  // const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
  // const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
 
  const IndexValue = useSelector((state) => {
    return state.rootRevenueFunctionReducer.RevenueFunctionReducer.indexvalue;
  });

  const loadFilterData = useCallback(async (allRFsData) => {
    try {
      //await dispatch(fetchFilterData(allRFsData));
    } catch (err) {
      console.error('got error while loading dealer Branch details');
    }
  }, [dispatch]);

  const loadFilterParams = useCallback(async (tempFilterArray) => {
    try {
      //dispatch(setFilterParameters(tempFilterArray))
    } catch (err) {
      console.error('got error while loading filterparams');
    }
  }, [dispatch]);

  useEffect(() => {
    clearFilter()
  }, [])

  const gridTotalRowCount = useSelector((state) => {
    return state.PaginationGridReducer.gridTotalRowCount;
  });

  const clearFilter = () => {
    // setOpen(false);
    // setFilterArray(filterCat);
    // setTempFilterArray(filterCat);
    // setSelectedFilterIndex(-1);
    // loadFilterParams(null);
    // setActiveIndex(0);
    // setIsFiltered(false);
  }
  // function downloadCSV(csvStr, filename) {
  //   let hiddenElement = document.createElement('a');
  //   hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
  //   hiddenElement.target = '_blank';
  //   hiddenElement.download = filename;
  //   hiddenElement.click();
  // }

  const downloadHandler = async (event) => {
    if( RevenueInputsGrid =='tncrevenuegrid' || RevenueInputsGrid =='corerevenuegrid'){
      dispatch(addNotificationMessage('success','Please wait your file is getting ready!!'));
      if(gridTotalRowCount <= MAX_ROWS_ALLOWED_FOR_EACH_GRID){
        downloadData(RevenueInputsData,RevenueInputsGrid,RevenueInputsGridColDefReducer,downloadCSV,'rf' )
      }
      else{
        let currentDate = new Date();
        let dateStr = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}_${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
        let downloadFilename=`${RevenueInputsGrid.replace('grid','')}-${`rf-`}_${dateStr}.csv`
        setFileNameToDownload(downloadFilename)
      }
      }
    };  
    //downloadData(RevenueInputsData,RevenueInputsGrid,RevenueInputsGridColDefReducer,downloadCSV,'rf')


  useEffect(() => {
    if (actionView === "grid")
      setSelectedIndex(2);
    else
      setSelectedIndex(1);
  }, [actionView]);

  const selectFilterType = (type) => {
    if (type == 'Custom Filter') {
      loadFilterData(RevenueInputsData);
      setOpen(true);
    } else if (type == 'Clear Filter') {
      setOpen(false);
      setFilterArray(filterCat);
      setTempFilterArray(filterCat);
      setSelectedFilterIndex(-1);
      loadFilterParams(null);
      setActiveIndex(0);
    }
  };

  const handleFilterPopOverClose = () => {
    setFilterClassName('filterPrimaryButton');
    setFilterAnchorEl(null);
  };

  // useEffect(() => {
  //   if (transactions && transactions.length == 0) {
  //     dispatch(fetchAllRFsData(selectedPlanId));
  //   } else if (transactions.length > 0) {
  //     var currentdata = applyTransactions(allRFsData, transactions);
  //     var filteredCurrentData = filterRecords(currentdata, filterParams);
  //     var filteredData = filterRecords(allRFsData, filterParams);
  //     dispatch(populateGrid2("allrfsgrid", filteredData, filteredCurrentData, filterSearchKey, RevenueInputsGridColDefReducer.checkValue, RevenueInputsGridColDefReducer.validationFn, RevenueInputsGridColDefReducer.businessValidationFn));
  //   }
  // }, [filterParams, filterSearchKey])

  useEffect(() => {
    if (RevenueInputsData && RevenueInputsData.length>0) {
      var filteredData = RevenueInputsData;
      dispatch(populateGrid2(RevenueInputsGrid, filteredData, filteredData, filterSearchKey, RevenueInputsGridColDefReducer.checkValue, RevenueInputsGridColDefReducer.validationFn, RevenueInputsGridColDefReducer.businessValidationFn));
     // dispatch(validateGrid(RevenueInputsGrid, RevenueInputsData, RevenueInputsGridColDefReducer.validationFn, RevenueInputsGridColDefReducer.businessValidationFn));
    }
  }, [RevenueInputsData])

  const filterComponent = () => {
    return (<div className={(filterPopOverOpen || selectedFilterIndex === -1) ? 'filterCustomButton' : filterClassName} aria-describedby={id}>
      <Button id={id} startIcon={<img src={IconFilter} />} endIcon={(selectedFilterIndex !== -1) ? <ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon> : ''}>
        {selectedFilterIndex === 1 ? 'Custom Filter' : (selectedFilterIndex === 2) ? 'Clear Filter' : ''}
      </Button>
      <CustomPopOver
        className="filterPopOver"
        popOverId="filterPopoverId"
        open={filterPopOverOpen}
        anchorEl={filterAnchorEl}
        onClose={handleFilterPopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <List>
          <ListItem button
            selected={selectedFilterIndex === 1}
            onClick={(event) => handleFilterListItemClick(event, 1)}>
            <img src={(selectedFilterIndex === 1) ? StatusIcon : ''} className="imageSpacing"></img>
            <NttLabel theme={theme} labelVariant="body2">
              Custom Filter</NttLabel>
          </ListItem>
          <Divider />
          <ListItem button
            selected={selectedFilterIndex === 2}
            onClick={(event) => handleFilterListItemClick(event, 2)}>
            <img src={selectedFilterIndex === 2 ? StatusIcon : ''} className="imageSpacing"></img>
            <NttLabel theme={theme} labelVariant="body2">
              Clear Filter</NttLabel>
          </ListItem>
        </List>
      </CustomPopOver>
    </div>);
  }

  const handleFilterListItemClick = (event, index) => {
    setFilterClassName('filterPrimaryButton');
    setSelectedFilterIndex(index);
    setFilterAnchorEl(null);
    var selectedValue;
    if (index === 1)
      selectedValue = "Custom Filter";
    else if (index === 2)
      selectedValue = "Clear Filter";
    return selectFilterType(selectedValue);
  };

  const onSearchValueChanged = (e) => {
    // setFlterSearchKey(e.target.value)
    // if (e.target.value === '') {
    //   setIsSearchBarValueExists(false);
    // } else {
    //   setIsSearchBarValueExists(true);
    // }
    //dispatch(searchRecord(e.target.value));
  }












  const gridName=useSelector((state)=>{
    return state.PaginationGridReducer.gridName
  })

  const filterComponentDisplay = useMemo(()=>{
    if(gridName!=''){
      return <FilterComponent gridColumnDefs={RevenueInputsGridColDefReducer} 
      //loadGridDataForFilterParams={loadGridDataForFilterParams} 
      initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}/>
    }
  },[gridName])

  const s3UploadDownloadComponent=useMemo(()=>{
    if(gridName!=''){
      return <S3UploadDownloadComponent 
                selectedId={rfid}
                // showProgress={showProgress} 
                // setShowProgress={setShowProgress}
                fileNameToDownload={fileNameToDownload}
                setFileNameToDownload={setFileNameToDownload} 
                //FileToUpload={FileToUpload} 
                //SetFileToUpload={SetFileToUpload} 
                gridName={gridName} 
                userMessageFunction={userMessage}
                gridColumnDefs={RevenueInputsGridColDefReducer.columnDefs}
                selectedTabName={selectedTableName}
                isNewArchitecture={true}
            />
    }
    },[gridName,fileNameToDownload])

  return (
    <div className="revFunctionHeader">
      <div className="filterStyle">
      {filterComponentDisplay}
      {/* <div className="programsfilterMargin">{filterComponent()}</div> */}
          <SearchBar onSearchValueChanged ={onSearchValueChanged}></SearchBar>
      </div>
      <>
       {s3UploadDownloadComponent}
       </>
        <div className="actionButtons">
          <div className='flex'>
          <div className='imageIcon'>
            <img src={IconActionDownload} onClick={() => downloadHandler()} />
          </div>
          </div>
        </div>
    </div>
  )
}

export default RevenueInputsActionBar;