import { combineReducers } from 'redux';
import allRFsReducer from './AllRFs.Reducer';
import multiGridUIReducer from '../RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import columnDefinitionReducer from "../allrfs/AllRFs.ColumnDef.Reducer";

const rootAllRFsReducer = combineReducers({
      allRFsReducer
    , rootAllRFsGridReducer: createNamedWrapperReducer(multiGridUIReducer, "allrfsgrid")
    , columnDefinitionReducer
})

export default rootAllRFsReducer;