import React from "react";
import { Popover } from "@material-ui/core";


const CustomPopOver = (props) => {
    const {
        className,
        popOverId,
        open,
        anchorEl,
        onClose,
        anchorOrigin,
        transformOrigin,
        children
    } = props;
    return (
        <Popover
            className={className}
            id={popOverId}
            open={open}
            anchorEl={anchorEl}
            onClose={(event) => onClose(event)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {children}
        </Popover>
    );
};

export default CustomPopOver;


