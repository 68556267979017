import {SAVE_PLAN_MANAGEMENT_TRANSACTION_PENDING, SAVE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS, SAVE_PLAN_MANAGEMENT_TRANSACTION_ERROR, DELETE_PLAN_MANAGEMENT_TRANSACTION_PENDING, DELETE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS, DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR, DELETE_PLAN_MANAGEMENT_TRANSACTION_IN_PROGRESS} from "./PlanManagement.ActionTypes";

const initialState = {
    pending: false,
    error: null,
    transactionSaveSuccess:null,
    planDeleteSuccessful:null,
    jobId:0
}

export default function planManagementReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_PLAN_MANAGEMENT_TRANSACTION_PENDING:
                return Object.assign({}, state, {
                    pending: true,
                    transactionSaveSuccess: null
                    });
        case SAVE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS:
                return Object.assign({}, state, {
                    pending: false,
                    transactionSaveSuccess: true
                    });
        case SAVE_PLAN_MANAGEMENT_TRANSACTION_ERROR:
                return Object.assign({}, state, {
                    pending: false,
                    error: action.error,
                    transactionSaveSuccess: false
                    });
        case DELETE_PLAN_MANAGEMENT_TRANSACTION_PENDING:
                return Object.assign({}, state, {
                    pending: true,
                    planDeleteSuccessful: null
                    });
        case DELETE_PLAN_MANAGEMENT_TRANSACTION_IN_PROGRESS:
                return Object.assign({}, state, {
                    jobId: action.data
                    });
        case DELETE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS:
                return Object.assign({}, state, {
                    pending: false,
                    planDeleteSuccessful: true,
                    jobId:0
                    });
        case DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR:
                return Object.assign({}, state, {
                    pending: false,
                    error: action.error,
                    planDeleteSuccessful: false,
                    jobId:0
                    });
        default:
            return state;
    }
}

