export const FETCH_ALLRFS_DATA_PENDING = 'FETCH_ALLRFS_DATA_PENDING';
export const FETCH_ALLRFS_DATA_SUCCESS = 'FETCH_ALLRFS_DATA_SUCCESS';
export const FETCH_ALLRFS_DATA_ERROR = 'FETCH_ALLRFS_DATA_ERROR';

export const SAVE_ALLRFS_TRANSACTION_PENDING = 'SAVE_ALLRFS_TRANSACTION_PENDING';
export const SAVE_ALLRFS_TRANSACTION_SUCCESS = 'SAVE_ALLRFS_TRANSACTION_SUCCESS';
export const SAVE_ALLRFS_TRANSACTION_ERROR = 'SAVE_ALLRFS_TRANSACTION_ERROR';

export const FETCH_CREATERF_INPUTS_PENDING='FETCH_CREATERF_INPUTS_PENDING'
export const FETCH_CREATERF_INPUTS_SUCCESS='FETCH_CREATERF_INPUTS_SUCCESS'
export const FETCH_CREATERF_INPUTS_ERROR='FETCH_CREATERF_INPUTS_ERROR'

export const SET_GENERATE_RF_PROCESS_STATUS='SET_GENERATE_RF_PROCESS_STATUS'
export const SAVE_RF_DESCRIPTION_PENDING='SAVE_RF_DESCRIPTION_PENDING'
export const SAVE_RF_DESCRIPTION_SUCCESS='SAVE_RF_DESCRIPTION_SUCCESS'
export const SAVE_RF_DESCRIPTION_ERROR='SAVE_RF_DESCRIPTION_ERROR'
export const RF_DETAILS_BYID_PENDING = 'RF_DETAILS_BYID_PENDING'
export const RF_DETAILS_BYID_SUCCESS='RF_DETAILS_BYID_SUCCESS'
export const RF_DETAILS_BYID_ERROR='RF_DETAILS_BYID_ERROR'