import React, { useEffect, useCallback, useState } from 'react';
import AccordionView from '../../components/accordionview/AccordionView';
import { useDispatch, useSelector } from "react-redux";
import { groupByConstraints } from "../../store/constraints/Constraints.Actions";
import icon_sortdown from "../../assets/images/icon-arrow-sortdown.svg";
import icon_default_sortdown from "../../assets/images/icon-arrow-sort-default.svg";
import { Pool } from '@material-ui/icons';
import ConstraintAccordionContainer from './ConstraintAccordionContainer.css';

function ConstraintsAccordionContainer(constraintsdata) {
    const { data, currentView, selectedval, constraintType, tabIndex,planid } = constraintsdata;
    const [sortAscending, setSortAscending] = useState(true);
    let text = selectedval.replace(/>/g, " / ");
    let sortformat = text + ' / ' + 'Pool';
    const dispatch = useDispatch();

    if (currentView == 'accordion') {
        window.parent.document.body.style.overflow = "auto";
    }

    const loadgroupByConstraints = (constraintType, sortkey, sortAscending) => {
        dispatch(groupByConstraints(data, constraintType, selectedval, sortkey, sortAscending));
    }

    useEffect(() => {
        if (constraintType != '' && data !== undefined && data.length > 0) {
            loadgroupByConstraints(constraintType);
        }
    }, [data, constraintType, selectedval])

    var groupedConstraintsData = useSelector((state) => {
        return state.rootConstraintsReducer.constraintsReducer.groupedConstraintsData;
    });

    const sortConstraintAccordion = (sortkey) => {
        let sortkeysplit = sortformat.split(' / ');
        if (sortkeysplit[0] == 'Type') {
            loadgroupByConstraints(constraintType, sortkeysplit[0], sortAscending);
            setSortAscending(!sortAscending);
        } else {
            loadgroupByConstraints(constraintType, sortkeysplit[1], sortAscending);
            setSortAscending(!sortAscending);
        }
    }
    const sortConstraintDate = (sortkey) => {
        loadgroupByConstraints(constraintType, sortkey, sortAscending);
        setSortAscending(!sortAscending);
    }

    return (
        <div className={currentView == "grid" ? '': 'fixedHeader'}>
            <div className="constraintsort">
                <span className='groupsort' onClick={(e) => sortConstraintAccordion(sortformat)}>
                    <label className="sorttext">{sortformat}</label><img src={icon_sortdown} className="sorticon"></img>
                </span>
                <span className='datemargin' onClick={(e) => sortConstraintDate('Date')}><label className="sorttext">{"Date Range"}</label><img src={icon_default_sortdown} className="sorticon"></img></span>
                <span className='minmargin' onClick={(e) => sortConstraintDate('Min')}><label className="sorttext">{"Min"}</label><img src={icon_default_sortdown} className="sorticon"></img></span>
                <span className='maxmargin' onClick={(e) => sortConstraintDate('Max')}><label className="sorttext">{"Max"}</label><img src={icon_default_sortdown} className="sorticon"></img></span>
                <span className='attributemargin'><label className="sorttext attheader">{"Attributes"}</label></span>
            </div>
            {data != undefined && data.length>0 ?
            <AccordionView planid={planid} accordionData={data.length > 0 ? groupedConstraintsData : data} contentcomponent={constraintsdata.AccordionContent} tabIndex={tabIndex}
                sortConstraintDate={sortConstraintDate} constraintType={constraintType}>
            </AccordionView>:
            <div className="norecords">No Rows To Show</div>}
        </div>
    )
}
export default ConstraintsAccordionContainer;