import {fetchDepRateCarGroupDropdownService, getDepRateData, saveDepRateTransactions,uploadDepRateCSV} from '../../services/deprate/DepRateService';
import {FETCH_DEPRATE_DATA_PENDING, FETCH_DEPRATE_DATA_SUCCESS, FETCH_DEPRATE_DATA_ERROR, UPLOAD_DEPRATE_PENDING, UPLOAD_DEPRATE_SUCCESS, UPLOAD_DEPRATE_ERROR,
    SAVE_DEPRATE_TRANSACTION_PENDING, SAVE_DEPRATE_TRANSACTION_SUCCESS, SAVE_DEPRATE_TRANSACTION_ERROR,
    FETCH_DEPRATE_DROPDOWNVALUES_PENDING,FETCH_DEPRATE_DROPDOWNVALUES_SUCCESS,FETCH_DEPRATE_DROPDOWNVALUES_ERROR, RESET_TO_DEPRATE_INITIALSTATE} from './DepRate.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchDepRateData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_DEPRATE_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getDepRateData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_DEPRATE_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_DEPRATE_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_DEPRATE_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadDepRateData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_DEPRATE_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadDepRateCSV(planId, files);
            let depRateData = await getDepRateData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_DEPRATE_SUCCESS, depRateData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_DEPRATE_ERROR, "dep rate upload failed"));
                dispatch(SetAssumptionError("dep rate upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_DEPRATE_ERROR, "target zone pool upload failed"));
            dispatch(SetAssumptionError("target zone pool upload failed"))
        }
    }
}

export const saveDepRateTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_DEPRATE_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveDepRateTransactions(planid, transactionList);
            let depRateData = await getDepRateData(planid);
            if (depRateData) {
                dispatch(serviceActionSuccess(SAVE_DEPRATE_TRANSACTION_SUCCESS, depRateData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_DEPRATE_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchDepRateDropDownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_DEPRATE_DROPDOWNVALUES_PENDING));
        let depRateData = await fetchDepRateCarGroupDropdownService(planid);
        if (depRateData) {
            dispatch(serviceActionSuccess(FETCH_DEPRATE_DROPDOWNVALUES_SUCCESS, depRateData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("deprateGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{// this else is for old architecture and will be removed later
                dispatch(validateGrid("deprategrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
            }
        }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_DEPRATE_DROPDOWNVALUES_ERROR, depRateData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_DEPRATE_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetDepRate = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_DEPRATE_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}