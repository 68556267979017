import React from "react";
import ReadOnlyCellComponent from "./ReadOnlyCellComponent";
import './ReadOnlyCellComponent.css';
import EditableCellComponent from './EditableCellComponent';

const EditableAllCellsInAYearComponent = (props) => {
    const { adjustmentNode,accordionSummary,plansDataById,index,activity,zone} = props;
    

    if (adjustmentNode && adjustmentNode.total){
        return <EditableCellComponent type='summaryStack' adjustmentNode={adjustmentNode} accordionSummary={accordionSummary} plansDataById={plansDataById} index={index} activity={activity} zone={zone}/>
    }else if (adjustmentNode){
        return <EditableCellComponent adjustmentNode={adjustmentNode} accordionSummary={accordionSummary} plansDataById={plansDataById} index={index} activity={activity} zone={zone}/>
    }else{
        return <div></div>
    }
}

export default EditableAllCellsInAYearComponent;


