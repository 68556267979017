import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import './DateEditor.css';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';
import moment from "moment";
import { getDateISOString } from "../../../../utils/utils";
 
 
 
export default forwardRef((props, ref) => {
    const [selectedDate, setSelectedDate] = useState(props.value);//setting the existing date value as initial state value when component renders (when user double clicks on date column)
    const [open,setopen]=useState(false);
 
    function handleDateChange(d) {
        if (d) {
            //var newDate = new Date(d);
            //     newDate.setDate(28);
            //     newDate.setUTCHours(12, 0, 0, 0);
            // }

            // setSelectedDate(getDateISOString(newDate));
            if(!selectedDate || moment(d).format('YYYY-MM')!=moment(selectedDate).format('YYYY-MM')){//this will be true when existing value differ from user selected value, then only setting the new selected value
                setSelectedDate(moment(d).format(`YYYY-MM-DD`)); // updating the state with new user selected date value
            }
            // if (!dateValue.current || (moment(d).format('DD') < dateValue.current)) {//this condition will be true when particular date in not valid for selected month or when value is null so setting the date according to the selection by the user
            //     setSelectedDate(moment(d).format(`YYYY-MM-DD`));

            // }
            // else {
            //     console.log("eneterd into validdate",d)
            //     setSelectedDate(moment(d).format(`YYYY-MM-${dateValue.current}`));//this condition will be true when prevrious date is valid for selected month then setting only month and year(YYYY-MM) where as the date(DD) will be same as the prevrious value which is stored in dateValue ref
            // }
        } else {
            setSelectedDate(null);
        }
    }
 
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null;
                return selectedDate;
            },
            // isCancelAfterEnd: () => {
            //     return !selectedDate;
            // },
            afterGuiAttached: () => {
                if (!props.value) {
                    return;
                }
                //dateValue.current=moment(props.value).format('DD')//this is to get only date(DD) from existing datefield value            
                // var newD = new Date(props.value);
                //  var newDate = new Date(newD.getTime() - newD.getTimezoneOffset() * -60000);
                //  newDate.setDate(4);
                //  newDate.setUTCHours(12,0,0,0);
                // setSelectedDate(getDateISOString(newDate));
                //setSelectedDate(moment(props.value).format(`YYYY-MM-${dateValue.current}`));//setting only month and year(YYYY-MM) where as the date(DD) will be same as the prevrious value which is stored in dateValue ref
                setopen(true);
            }
        };
    });
 
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                clearable
                className="inputdatepicker"
                style={{ width: '100px', height: '28px', margin: '5px', border: 'solid 1px #babfc7', paddingLeft: '5px', borderRadius: '3px', overflow: 'hidden', backgroundColor: 'white' }}
                margin="normal"
                id="date-picker-dialog"
                format="MM-yy"
                value={selectedDate ? moment(selectedDate).utc():null}
                onChange={handleDateChange}
                onOpen={(e)=>{
                 setopen(true)
                 }}
                 onClose={(e)=>{
                 setopen(false)
                 }}
                //open={open}
                placeholder='MM-YY'
                orientation="landscape"
                views={["year", "month"]}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                variant='inline'
                autoOk={true}
                InputAdornmentProps={{ position: "start" }}
            />
        </MuiPickersUtilsProvider>
    )
});