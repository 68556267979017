import React from "react";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReadOnlySectionRowsComponent from './ReadOnlySectionRowsComponent';
import { ScrollSyncPane } from 'react-scroll-sync';
import EndFleetSectionRowsComponent from "./EndFleetSectionRowsComponent";



const SectionSummaryComponent = (props) => {
    const { startFleetValue,selectedVal, accordionContent, isExpanded, applyMargin, isOfTypeEndFleet, planStatus, addStartFleet} = props;
    return (<AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel-header"
        TransitionProps={{ unmountOnExit: true }}>

        <div className="allBurowFlex">
            <label className='allBuAdjustmentheading'>{((accordionContent.header == 'REPURCHASE_DELETES') ? 'REPURCHASE DELETES': (accordionContent.header == 'START_FLEET') ? 'OPERATIONAL START FLEET':(accordionContent.header == 'START_CS_FLEET') ? 'TOTAL START FLEET': accordionContent.header)}</label>
            {((accordionContent.header=='OPERATIONAL START FLEET' || accordionContent.header=='TOTAL START FLEET')?true:isExpanded)  ?
                <ScrollSyncPane group="horizontal"><span className={applyMargin == true ? "readOnlymarginOverall" : "readOnlynoMarginOverall"}>
                    {isOfTypeEndFleet ? <EndFleetSectionRowsComponent startFleetValue={startFleetValue} selectedVal={selectedVal} accordionContent={accordionContent.values[0]} isExpanded={isExpanded} planStatus={planStatus} addStartFleet={addStartFleet}/> : 
                     <ReadOnlySectionRowsComponent startFleetValue={startFleetValue} accordionContent={accordionContent.values[0]} isExpanded={isExpanded} planStatus={planStatus}/>}
                </span></ScrollSyncPane> : ''}
        </div>
    </AccordionSummary>)
}

export default SectionSummaryComponent;


