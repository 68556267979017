import{
    localeFormatter
} from "../../../../utils/formatters/valueFormatter";
export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: 'POOL_CODE', headerName: 'Pool Code', cellEditor: 'freeSoloAutoCompleteEditor',
        unSortIcon: true, minWidth: 100,
        cellClassRules: cellClassRules("POOL_CODE"),
        tooltipValueGetter: params => {
            return toolTipUI(params, "POOL_CODE")
        }
    },
    {
        field: 'ZONE_NAME', headerName: 'Zone Name', cellEditor: 'autoCompleteEditor',
        unSortIcon: true, minWidth: 250,
        cellClassRules: cellClassRules("ZONE_NAME"),
        tooltipValueGetter: params => {
            return toolTipUI(params, "ZONE_NAME")
        }
    },
    {
        field: 'ACTIVE', headerName: 'Active', cellEditor: 'autoCompleteEditor',
        unSortIcon: true, minWidth: 100,
        cellClassRules: cellClassRules("ACTIVE"),
        tooltipValueGetter: params => {
            return toolTipUI(params, "ACTIVE")
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "PoolManagementActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
