import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { removeNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { MessageNotificationValues } from '../../Constants';

const Messagenotifier = ({ messageNotifier }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        let id;
         id = setTimeout(() => {
            handleClose()
        },MessageNotificationValues.Timeout);

        return () => {
            clearTimeout(id)
        }
    }, [])


    function handleClose() {
        dispatch(removeNotificationMessage(messageNotifier.id));
    }

    return (
        <Alert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity={messageNotifier.severity}
        >
            {messageNotifier.message}
        </Alert>
    )
};
export default Messagenotifier;