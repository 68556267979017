import {
    FETCH_PARAMETERS_DATA_PENDING, FETCH_PARAMETERS_DATA_ERROR, FETCH_PARAMETERS_DATA_SUCCESS,
    SAVE_PARAMETERS_TRANSACIONS_PENDING, SAVE_PARAMETERS_TRANSACIONS_ERROR, SAVE_PARAMETERS_TRANSACIONS_SUCCESS,
    DELETE_PARAMETERS_DATA_PENDING, DELETE_PARAMETERS_DATA_SUCCESS, DELETE_PARAMETERS_DATA_ERROR
} from "./Parameters.ActionTypes";

const initialState = {
    pending: false,
    parameters: [],
    data: [],
    error: null,
    transactionSaveSuccess: null,
    ZoneRf:{},
    poolRf:{}
}

export default function parametersReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PARAMETERS_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_PARAMETERS_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                parameters: action.data
            });
        case FETCH_PARAMETERS_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                parameters: action.data
            });
        case DELETE_PARAMETERS_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case DELETE_PARAMETERS_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                parameters: action.data
            });
        case DELETE_PARAMETERS_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                parameters: action.data
            });
        case SAVE_PARAMETERS_TRANSACIONS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_PARAMETERS_TRANSACIONS_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                parameters: action.data,
                transactionSaveSuccess: true
            });
        case SAVE_PARAMETERS_TRANSACIONS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        default:
            return state;
    }
}

