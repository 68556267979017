export const FETCH_AGEMILEAGE_DATA_PENDING = 'FETCH_AGEMILEAGE_DATA_PENDING';
export const FETCH_AGEMILEAGE_DATA_SUCCESS = 'FETCH_AGEMILEAGE_DATA_SUCCESS';
export const FETCH_AGEMILEAGE_DATA_ERROR = 'FETCH_AGEMILEAGE_DATA_ERROR';
export const UPLOAD_AGEMILEAGE_PENDING = 'UPLOAD_AGEMILEAGE_PENDING';
export const UPLOAD_AGEMILEAGE_SUCCESS = 'UPLOAD_AGEMILEAGE_SUCCESS';
export const UPLOAD_AGEMILEAGE_ERROR = 'UPLOAD_AGEMILEAGE_ERROR';
export const SAVE_AGEMILEAGE_TRANSACTION_PENDING = 'SAVE_AGEMILEAGE_TRANSACTION_PENDING';
export const SAVE_AGEMILEAGE_TRANSACTION_SUCCESS = 'SAVE_AGEMILEAGE_TRANSACTION_SUCCESS';
export const SAVE_AGEMILEAGE_TRANSACTION_ERROR = 'SAVE_AGEMILEAGE_TRANSACTION_ERROR';
export const FETCH_AGEMILEAGE_DROPDOWNVALUES_PENDING = 'FETCH_AGEMILEAGE_DROPDOWNVALUES_PENDING';
export const FETCH_AGEMILEAGE_DROPDOWNVALUES_SUCCESS = 'FETCH_AGEMILEAGE_DROPDOWNVALUES_SUCCESS';
export const FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR = 'FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR';
export const RESET_TO_AGEMILEAGE_INITIALSTATE = 'RESET_TO_AGEMILEAGE_INITIALSTATE';
export const SET_AGEMILEAGE_EDITABLE='SET_AGEMILEAGE_EDITABLE';