import { getTncRevenueDropdownService } from "../../../../services/revenuefunction/revenue/tncrevenue/TncRevenueServices";
import { FETCH_TNCREVENUE_DROPDOWNVALUES_ERROR, FETCH_TNCREVENUE_DROPDOWNVALUES_PENDING, FETCH_TNCREVENUE_DROPDOWNVALUES_SUCCESS } from "../../RevenueFunction.ActionTypes";



export const fetchTncRevenueDropDownValues = ()=>{
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_TNCREVENUE_DROPDOWNVALUES_PENDING));
        let tncRevenueDropdownData = await getTncRevenueDropdownService();
        if (tncRevenueDropdownData) {
            dispatch(serviceActionSuccess(FETCH_TNCREVENUE_DROPDOWNVALUES_SUCCESS, tncRevenueDropdownData));
        } else {
            dispatch(serviceActionError(FETCH_TNCREVENUE_DROPDOWNVALUES_ERROR, tncRevenueDropdownData));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}
function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}