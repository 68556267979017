import React, { useState, useEffect } from 'react';
import ActionBar from '../../components/planassumptionsactionbar/PlanAssumptionsActionbar';
import PropTypes from "prop-types";
import NttTab from "../../components/tabs/NttTab"
import NttTabs from "../../components/tabs/NttTabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AgeMileageTabPanel from '../plan_assumptions/agemileage/AgeMileageTabPanel';
import AdditionalCostTabPanel from '../plan_assumptions/additionalcost/AdditionalCostTabPanel';
import MileageDefaultTabPanel from '../plan_assumptions/mileagedefault/MileageDefaultTabPanel';
import './PlanAssumptionsTabPanel.css';
import {fetchAdditionalCostData,saveAdditionalCostTransactionData,fetchAdditionalCostDropdownValues,resetAdditionalCost} from '../../store/additionalcost/AdditionalCost.Actions';
import {fetchMileageDefaultData,saveMileageDefaultTransactionData,fetchMileageDefaultDropdownValues,resetMileageDefaultt} from '../../store/mileagedefault/MileageDefault.Actions';
import { useDispatch, useSelector } from "react-redux";
import { addNewRow, populateGrid, validateRow, refreshGridData, cleanTransactions, validateGrid, deleteRow, deleteRowEditMode } from '../../store/MultiGrid/MultiGrid.Actions';
import {fetchAgeMileageData,saveAgeMileageTransactionData, fetchAgeMileageDropdownValues, resetAgeMileage} from '../../store/agemileage/AgeMileage.Actions';
import { SetAssumptionIndex } from '../../store/planassumptions/PlanAssumptions.Actions';
import { fetchMaxDeletesData, saveMaxDeletesTransactionData ,fetchMaxDeletesDropdownValues} from '../../store/maxdeletes/MaxDeletes.Actions';
import MaxDeletesTabPanel from './maxdeletes/MaxDeletesTabPanel';
import { fetchSalvageRateData, saveSalvageRateTransactionData ,fetchSalvageRateDropdownValues} from '../../store/salvagerate/SalvageRate.Actions';
import SalvageRateTabPanel from './salvagerate/SalvageRateTabPanel';
import { fetchTargetZonePoolData, saveTargetZonePoolTransactionData ,fetchTargetZonePoolDropdownValues} from '../../store/targetzonepool/TargetZonePool.Actions';
import TargetZonePoolTabPanel from './targetzonepool/TargetZonePoolTabPanel';
import { fetchZoneMapData, saveZoneMapTransactionData ,fetchZoneMapDropdownValues} from '../../store/zonemap/ZoneMap.Actions';
import ZoneMapTabPanel from './zonemap/ZoneMapTabPanel';
import { fetchMileageTableData,saveMileageTableTransactionData ,fetchMileageTableDropdownValues} from '../../store/mileagetable/MileageTable.Actions';
import MileageTableTabPanel from './mileagetable/MileageTableTabPanel';
import { fetchAddsInvalidPoolsData, saveAddsInvalidPoolsTransactionData ,fetchAddsInvalidPoolsDropdownValues} from '../../store/addsinvalidpools/AddsInvalidPools.Actions';
import AddsInvalidPoolsTabPanel from './addsinvalidpools/AddsInvalidPoolsTabPanel';
import { fetchFilterDisplayData, fetchMoveCostData, fetchMoveCostErrorData, fetchMoveCostFilterData, fetchMoveCostSearchData, saveMoveCostTransactionData, SetAssumptionMoveCostUploadStatus ,fetchMoveCostDropdownValues} from '../../store/movecost/MoveCost.Actions';
import MoveCostTabPanel from './movecost/MoveCostTabPanel';
import DepRateTabPanel from './deprate/DepRateTabPanel';
import {fetchDepRateData, saveDepRateTransactionData,fetchDepRateDropDownValues} from '../../store/deprate/DepRate.Actions';
import depRateColumnDefinitionReducer from '../../store/deprate/DepRate.ColumnDef.Reducer';
import { fetchDiversionsData, fetchDiversionsDropdownValues, saveDiversionsTransactionData } from '../../store/diversions/Diversions.Actions';
import { UpdateMasterDataCache} from '../../store/topbar/AllPlans.Actions';
import DiversionsTabPanel from './diversions/DiversionsTabPanel';
import {planAssumptionTabsForPlanInputs,planAssumptionTabsForMainInputs} from '../../Constants';
import ManualVinDeletesTabPanel from './ManualVinDeletesTabPanel';
import { fetchManualVinDeletesData,fetchManualVinDeletesDropdownValues,fetchManualVinDeletesErrorData,fetchManualVinDeletesFilterData,fetchManualVinDeletesSearchData,saveManualVinDeletesTransactionData } from '../../store/manualvindeletes/ManualVinDeletes.Actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={'simple-tabpanel-${index}'}
            aria-labelledby={'simple-tab-${index}'}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const PlanAssumptionsTabPanel = (props) => {
    const {showSaveOption,pending,userMessage,movecostFilterDisplayData,isSaveClicked,setIsSaveClicked,setSnackBarOpen,fetchValidTransactions, gridReducer,additionCostgridReducer, mileageDefaultgridReducer, currentView,
        setShowSaveOption, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
      bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions,snackBarIfError,setWarningSnackBarOpen,handleAddNewRow,additionalCostTransactions,mileageDefaultTransactions, addsInvalidPoolsTransactions,fetchInvalidTransactions,setIsCancelClicked,setRefreshCells,setSnackBarIfError,currentData,filteredData, filterParams, downloadCSV,
        manualVinDeletesData,manualVinDeletesGridReducer,manualVinDeletesCurrentData,manualVinDeletesGridColumnDef,manualVinDeletesTransactions,selectedurlHasMainInputs,manualVinDeletesRowDataInvalid,manualVinDeletesFilterDisplayData} = props;
        const dispatch = useDispatch();


    const tabStyle = {
        default_tab: {
            borderRadius: "4px",
            color: "#000000",
            background: "#ffff",
            fontSize: 14,
            textTransform: 'none',
        },
        active_tab: {
            background: "#ffed9b",
            borderColor: "#ffff",
            borderRadius: "4px",
            color: "#000000",
            fontSize: 14,
            textTransform: 'none'
        },
        defaultError_tab: {
          borderRadius: "4px",
            color: "red",
            background: "#ffff",
            fontSize: 14,
            textTransform: 'none',
            fontWeight: "bold",
        },
        activeError_tab: {
          background: "#ffcccc",
          borderColor: "#ffff",
          borderRadius: "4px",
          color: "#ff0000",
          fontSize: 14,
          textTransform: 'none',
          fontWeight: "bold",
        },
        tabItemContainer: {
            margin: "50px",
            display: "flex",
            flexWrap: "wrap",
        },
        copyInProgressIndicator:{
          borderRadius: "4px",
          color: "#000000",
          background: "#ffff",
          fontSize: 14,
          textTransform: 'none',
          animation: "copyinprogress_sub 2s linear infinite"
        },
        copyInProgressIndicator_active:{
          background: "#ffed9b",
          borderColor: "#ffff",
          borderRadius: "4px",
          color: "#000000",
          fontSize: 14,
          textTransform: 'none',
          animation: "copyinprogress_sub_active 2s linear infinite"
        },
        hideTab:{
          display:'none'
        }
    };

    const getStyle = (isActive, tabTitle,value) => {
       //console.log("tabTitle==",tabTitle,selectedurlHasMainInputs);
      if(!selectedurlHasMainInputs && tabTitle==='MOVE_COST'){
        return tabStyle.hideTab
      }
       if(copyToMainInputsStatus.some(tabName=>tabName===tabTitle)){
        return isActive ? tabStyle.copyInProgressIndicator_active :  tabStyle.copyInProgressIndicator
      }else{
        //switch case if grid has an error will or grid is empty it will turn red 
        switch(value){
          case 0:
            if(ageMileageRowDataInvalid || ageMileageData.length<1){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }                
          case 1:
            if(additionalCostRowDataInvalid || additionalCostData.length<1){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }                  
          case 2:
            if(addsInvalidPoolsRowDataInvalid || addsInvalidPoolsData.length<1){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }  
          case 3:
            if(maxDeletesRowDataInvalid || maxDeletesData.length<1 ){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }  
          case 4:
            if(mileageDefaultRowDataInvalid || mileageDefaultData.length<1){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }  
          case 5:
            // console.log("moveCostRowDataInvalid===",moveCostRowDataInvalid);
            // console.log("moveCostData.length===",moveCostData.length);
            // console.log("moveCostErrorView===",moveCostErrorView);
            // console.log("moveCostReducerData.length===",moveCostReducerData?moveCostReducerData.length:null);
            if(moveCostRowDataInvalid || movecostGrandTotalRowCount==0 || (uniqueErrorIDs && uniqueErrorIDs.length>0)){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }  
          case 6:
            if(salvageRateRowDataInvalid || salvageRateData.length<1){
              return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
            }else{
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
            }
          case 7:
              if(targetZonePoolRowDataInvalid){
               return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
              }else{
                return isActive ? tabStyle.active_tab : tabStyle.default_tab
              }                
          case 8:
              if(depRateRowDataInvalid || depRateData.length<1){
                return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
              }else{
                return isActive ? tabStyle.active_tab : tabStyle.default_tab
              }                  
          case 9:
              if(mileageTableRowDataInvalid || mileageTableData.length<1){
                return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
              }else{
                return isActive ? tabStyle.active_tab : tabStyle.default_tab
              }  
          case 10:
              if(diversionsRowDataInvalid){
                return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
              }else{
                return isActive ? tabStyle.active_tab : tabStyle.default_tab
              }
          case 11:
              if(manualVinDeletesRowDataInvalid){
                return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
              }else{
                return isActive ? tabStyle.active_tab : tabStyle.default_tab
              }
          /* Moved to Metadata
          // case 12:
          //     if(zoneMapRowDataInvalid || zoneMapData.legth<1){
          //       return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
          //     }else{
          //       return isActive ? tabStyle.active_tab : tabStyle.default_tab
          //     } 
          */
          default:
              return isActive ? tabStyle.active_tab : tabStyle.default_tab
          }
      } 
      
  }

    const IndexValue = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.PlanAssumptionsReducer.indexvalue;
      });


  const masterDataUpdateState = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  })

  const ageMileageGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer };
  });

  const ageMileageGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageColumnDefinitionReducer;
  });

  const ageMileageData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.AgeMileageReducer.ageMileageData;
  });

  const ageMileageUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.AgeMileageReducer.uploadAgeMileageSuccess;
  });

  const ageMileageRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer.isAnyRowInvalid;
  });

  const ageMileageTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.AgeMileageReducer.transactionSaveSuccess;
  })

  const manualVinDeletesTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.transactionSaveSuccess;
  })

  const manualVinDeletesUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesUploadSuccess;
  });

  const manualVinDeletesPageNo = useSelector((state)=>{
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesPageNo;
  })

  const manualVinDeletesFilterSelectedValues = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.filterSelectedData;
  });

  const ManualVinDeletesUniqueErrorIDs = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.uniqueErrorIDs
  })

  const manualVinDeletesTotalRowCount = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesTotalRowCount
  })

  const deleteManualVinDeletesRecordsSuccess = useSelector((state)=>{
     return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.deleteManualVinDeletesRecordsSuccess
   })

  const additionalCostGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer };
  });

  const additionalCostGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostColumnDefinitionReducer;
  });

  const additionalCostData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.AdditionalCostReducer.additionalCostData;
  });

  const additionalCostUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.AdditionalCostReducer.uploadAdditionalCostSuccess;
  });

  const additionalCostRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer.isAnyRowInvalid;
  });

  const additionalCostTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.AdditionalCostReducer.transactionSaveSuccess;
  })

  const mileageDefaultGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer };
  });

  const mileageDefaultGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultColumnDefinitionReducer;
  });

  const mileageDefaultData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.MileageDefaultReducer.mileageDefaultData;
  });

  const mileageDefaultUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.MileageDefaultReducer.uploadMileageDefaultSuccess;
  });

  const mileageDefaultRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer.isAnyRowInvalid;
  });

  const mileageDefaultTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.MileageDefaultReducer.transactionSaveSuccess;
  })

  const maxDeletesGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer };
  });

  const maxDeletesGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesColumnDefinitionReducer;
  });

  const maxDeletesData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.MaxDeletesReducer.maxDeletesData;
  });

  const maxDeletesUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.MaxDeletesReducer.uploadMaxDeletesSuccess;
  });

  const maxDeletesRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer.isAnyRowInvalid;
  });

  const maxDeletesTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.MaxDeletesReducer.transactionSaveSuccess;
  })

  const diversionsGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer };
  });

  const diversionsGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsColumnDefinitionReducer;
  });

  const diversionsData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.DiversionsReducer.diversionsData;
  });

  const diversionsUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.DiversionsReducer.uploadDiversionsSuccess;
  });

  const diversionsRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer.isAnyRowInvalid;
  });

  const diversionsTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.DiversionsReducer.transactionSaveSuccess;
  })

  const salvageRateGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer };
  });

  const salvageRateGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateColumnDefinitionReducer;
  });

  const salvageRateData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.SalvageRateReducer.salvageRateData;
  });

  const salvageRateUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.SalvageRateReducer.uploadSalvageRateSuccess;
  });

  const salvageRateRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer.isAnyRowInvalid;
  });

  const salvageRateTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.SalvageRateReducer.transactionSaveSuccess;
  });

  const targetZonePoolGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer };
  });

  const targetZonePoolGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolColumnDefinitionReducer;
  });

  const targetZonePoolData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.TargetZonePoolReducer.targetZonePoolData;
  });

  const targetZonePoolUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.TargetZonePoolReducer.uploadTargetZonePoolSuccess;
  });

  const targetZonePoolRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer.isAnyRowInvalid;
  });

  const targetZonePoolTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.TargetZonePoolReducer.transactionSaveSuccess;
  });
  /* Moved to Metadata Tab
  // const zoneMapGridReducer = useSelector((state) => {
  //   return { ...state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapGridReducer };
  // });

  // const zoneMapGridColDef = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapColumnDefinitionReducer;
  // });

  // const zoneMapData = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.ZoneMapReducer.zoneMapData;
  // });

  // const zoneMapUploadSuccess = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.ZoneMapReducer.uploadZoneMapSuccess;
  // });

  // const zoneMapRowDataInvalid = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapGridReducer.isAnyRowInvalid;
  // });

  // const zoneMapTransactionSaveSuccessful = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.ZoneMapReducer.transactionSaveSuccess;
  // });
  */
  const mileageTableGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer };
  });

  const mileageTableGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableColumnDefinitionReducer;
  });

  const mileageTableData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.MileageTableReducer.mileageTableData;
  });

  const mileageTableUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.MileageTableReducer.uploadMileageTableSuccess;
  });

  const mileageTableRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer.isAnyRowInvalid;
  });

  const mileageTableTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.MileageTableReducer.transactionSaveSuccess;
  });
  const moveCostGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer };
  });

  const moveCostGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostColumnDefinitionReducer;
  });

  const moveCostPageNo = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostpageno;
        });

  const moveCostDisableButton = useSelector((state) => {
          return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostUploadStatus;
              });
  const moveCostdata = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostData;
  });

  const moveCostErrorData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostErrorData;
  });
  const uniqueErrorIDs = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs;
  });

  const moveCostFilterData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterData;
  });

  const moveCostPageChange = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostpageno;
  });

  const moveCostTotRowCount = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostTotalRowCount;
  });
  const movecostGrandTotalRowCount = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostGrandTotalRowCount;
  });

  const moveCostFilterSelectedValues = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.filterSelectedData;
  });

  const moveCostFilterView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterView;
  });

  const moveCostFilterSearchView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterSearchView;
  });

  const moveCostUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.uploadMoveCostSuccess;
  });

  const deleteMoveCostRecordsSuccess = useSelector((state)=>{
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.deleteMoveCostRecordsSuccess
  })

  const moveCostRowDataInvalid = useSelector((state) => {
    // let uniqueIds=!!(state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs);
    // console.log("IN moveCostRowDataInvalid Selector in PlanAssumptionsTabPanel: uniqueIds==>",uniqueIds);
    // console.log("state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer.isAnyRowInvalid==>",state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer.isAnyRowInvalid);
    // console.log("!!(state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs==>",!!(state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs));
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer.isAnyRowInvalid || (state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs && state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs.length>0);
  });

  const moveCostTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.transactionSaveSuccess;
  })

  const moveCostColumnsConstantData = useSelector((state) => {
      return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostColumnsConstantData;
  });

  const moveCostFilterSearchText = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterSearchText;
  });   

  const addsInvalidPoolsGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer };
  });

  const addsInvalidPoolsGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsColumnDefinitionReducer;
  });

  const addsInvalidPoolsData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.AddsInvalidPoolsReducer.addsInvalidPoolsData;
  });

  const addsInvalidPoolsUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.AddsInvalidPoolsReducer.uploadAddsInvalidPoolsSuccess;
  });

  const addsInvalidPoolsRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer.isAnyRowInvalid;
  });

  const addsInvalidPoolsTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.AddsInvalidPoolsReducer.transactionSaveSuccess;
  })

  const ageMileageGridTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer.transactions;
  });

  const maxDeletesTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer.transactions;
  });

  const diversionsTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer.transactions;
  });

  const salvageRateTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer.transactions;
  });

  const targetZonePoolTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer.transactions;
  });
 //Moved to Metadata
  // const zoneMapTransactions = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapGridReducer.transactions;
  // });

  const mileageTableTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer.transactions;
  });

  const moveCostGridransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer.transactions;
  });

  const additionalCostGridransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer.transactions;
  });

  const mileageDefaultGridransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer.transactions;
  });

  const addsInvalidPoolsGridransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer.transactions;
  });


  const moveCostErrorView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostErrorView;
  });

  const moveCostReducerData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostData;
  });

  const depRateData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.DeprateReducer.depRateData;
  });

  const depRateTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer.transactions;
  });

  const depRateGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer };
  });

  const depRateGridColDef = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateColumnDefinitionReducer;
  });

  const depRateUploadSuccess = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.DeprateReducer.uploadDepRateSuccess;
  });
 
  const depRateRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer.isAnyRowInvalid;
  });

  const depRateTransactionSaveSuccessful = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.DeprateReducer.transactionSaveSuccess;
  })

  const manualVinDeletesFilterView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterView;
  });
  const manualVinDeletesFilterData = useSelector((state) => {
    return  state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterData;
  });
  const manualVinDeletesErrorView = useSelector((state) => {
  return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesErrorView;
  });
  const manualVinDeletesErrorData = useSelector((state)=>{
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesErrorData
  })
  const manualVinDeletesFilterSearchText = useSelector((state) => {
  return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterSearchText;
  });
  const manualVinDeletesFilterSearchView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterSearchView;
  });
  const manualVinDeletesColumnsConstantData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesColumnsConstantData;
  });

  let copyToMainInputsStatus=useSelector((state)=>{
      // console.log("copyToMainInputsStatus=useSelector(state)==",state);
      let copyPlanToMainInputsInProgress=state.rootAllPlansReducer.allPlansReducer.copyPlanToMainInputsInProgress;
      let tabsToBeCopiedToMain=state.rootAllPlansReducer.allPlansReducer.tabsToBeCopiedToMain;
      // console.log("copyPlanToMainInputsInProgress=useSelector(state)==",copyPlanToMainInputsInProgress);
      // console.log("tabsToBeCopiedToMain=useSelector(state)==",tabsToBeCopiedToMain);
      if(copyPlanToMainInputsInProgress){
        return tabsToBeCopiedToMain;
      }else{
        return [];
      }
  });


   // AddsInvalidPools here 
  useEffect(()=>{
    if(ageMileageGridTransactions.length>0 || additionalCostGridransactions.length>0 || maxDeletesTransactions.length>0 || mileageDefaultGridransactions.length>0 || addsInvalidPoolsGridransactions.length > 0 ||salvageRateTransactions.length>0 || diversionsTransactions.length>0|| targetZonePoolTransactions.length>0|| mileageTableTransactions.length>0||moveCostGridransactions.length>0||depRateTransactions.length>0 || manualVinDeletesTransactions.length>0){
      setShowSaveOption(true)
    }else{
    setShowSaveOption(false);
    }
  },[ageMileageGridTransactions,additionalCostGridransactions,maxDeletesTransactions,diversionsTransactions,mileageDefaultGridransactions,addsInvalidPoolsGridransactions,salvageRateTransactions , targetZonePoolTransactions,mileageTableTransactions,moveCostGridransactions,depRateTransactions,manualVinDeletesTransactions ])
  
    const [value, setValue] = useState(IndexValue);
    const [planAssumptionsGrid,setPlanAssumptionsGrid] = useState('');
    const [planAssumptionData,setplanAssumptionData]=useState(null);
    const [planAssumptionGridColDef,setplanAssumptionGridColDef]=useState(null);
    const [moveCostData,setMoveCostData]=useState((moveCostFilterView||moveCostFilterSearchView)?moveCostFilterData:(moveCostErrorView?moveCostErrorData:moveCostdata))
    const [manualVinDeletesdata,setManualVinDeletesdata]=useState((manualVinDeletesFilterView||manualVinDeletesFilterSearchView)?manualVinDeletesFilterData:(manualVinDeletesErrorView?manualVinDeletesErrorData:manualVinDeletesData)) 
    const[totalPage,setTotalpage]=useState(0)

    const tabsData = selectedPlanId ? planAssumptionTabsForPlanInputs: planAssumptionTabsForMainInputs;

    const handleSaveClicked = (gridName) => {
      setSnackBarIfError(!snackBarIfError);
      dispatch(UpdateMasterDataCache())
      if (gridName === 'agemileagegrid') {
        setIsSaveClicked('agemileagegrid');
        dispatch(validateGrid("agemileagegrid", ageMileageGridReducer.currentData, ageMileageGridColDef.validationFn, ageMileageGridColDef.businessValidationFn));
      }
      if (gridName === 'additionalcostgrid') {
        setIsSaveClicked('additionalcostgrid');
        dispatch(validateGrid("additionalcostgrid", additionalCostGridReducer.currentData, additionalCostGridColDef.validationFn, additionalCostGridColDef.businessValidationFn));
      }
      if (gridName === 'maxdeletegrid') {
        setIsSaveClicked('maxdeletegrid');
        dispatch(validateGrid("maxdeletegrid", maxDeletesGridReducer.currentData, maxDeletesGridColDef.validationFn, maxDeletesGridColDef.businessValidationFn));
      }
      if (gridName === 'diversionsgrid') {
        setIsSaveClicked('diversionsgrid');
        dispatch(validateGrid("diversionsgrid", diversionsGridReducer.currentData, diversionsGridColDef.validationFn, diversionsGridColDef.businessValidationFn));
      }
      if (gridName === 'mileagedefaultgrid') {
        setIsSaveClicked('mileagedefaultgrid');
        dispatch(validateGrid("mileagedefaultgrid", mileageDefaultGridReducer.currentData, mileageDefaultGridColDef.validationFn, mileageDefaultGridColDef.businessValidationFn));
      }
      if (gridName === 'addsinvalidpoolsgrid') {
        setIsSaveClicked('addsinvalidpoolsgrid');
        dispatch(validateGrid("addsinvalidpoolsgrid", addsInvalidPoolsGridReducer.currentData, addsInvalidPoolsGridColDef.validationFn, addsInvalidPoolsGridColDef.businessValidationFn));
      }
      if (gridName === 'salvagerategrid') {
        setIsSaveClicked('salvagerategrid');
        dispatch(validateGrid("salvagerategrid", salvageRateGridReducer.currentData, salvageRateGridColDef.validationFn, salvageRateGridColDef.businessValidationFn));
      }
      if (gridName === 'targetzonepoolgrid') {
        setIsSaveClicked('targetzonepoolgrid');
        dispatch(validateGrid("targetzonepoolgrid", targetZonePoolGridReducer.currentData, targetZonePoolGridColDef.validationFn, targetZonePoolGridColDef.businessValidationFn));
      }
      /* Moved to Metadata Tab
      // if (gridName === 'zonemapgrid') {
      //   setIsSaveClicked('zonemapgrid');
      //   dispatch(validateGrid("zonemapgrid", zoneMapGridReducer.currentData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));
      // }
      */
      if (gridName === 'mileagetablegrid') {
        setIsSaveClicked('mileagetablegrid');
        dispatch(validateGrid("mileagetablegrid", mileageTableGridReducer.currentData, mileageTableGridColDef.validationFn, mileageTableGridColDef.businessValidationFn));
      }
      if (gridName === 'movecostgrid') {
        setIsSaveClicked('movecostgrid');
        dispatch(validateGrid("movecostgrid", moveCostGridReducer.currentData,moveCostGridColDef.validationFn, moveCostGridColDef.businessValidationFn));
      }
      if (gridName === 'deprategrid') {
        setIsSaveClicked('deprategrid');
        dispatch(validateGrid("deprategrid", depRateGridReducer.currentData, depRateGridColDef.validationFn, depRateGridColDef.businessValidationFn));
      }
      if (gridName === 'manualvindeletesgrid') {
        setIsSaveClicked('manualvindeletesgrid');
        dispatch(validateGrid("manualvindeletesgrid", manualVinDeletesGridReducer.currentData, manualVinDeletesGridColumnDef.validationFn, manualVinDeletesGridColumnDef.businessValidationFn));
      }
    }

    const deleteSelectedRows = (gridName) => {
      let transactions = [];
      let ID = '';
      if (gridName == 'agemileagegrid') {
        transactions = ageMileageGridReducer.transactions;
      }else if (gridName == 'additionalcostgrid') {
        transactions = additionalCostGridReducer.transactions;
      }
      else if (gridName == 'maxdeletegrid') {
        transactions = maxDeletesGridReducer.transactions;
      }else if (gridName == 'diversionsgrid') {
        transactions = diversionsGridReducer.transactions;
      }else if (gridName == 'mileagedefaultgrid') {
        transactions = mileageDefaultGridReducer.transactions;
      }else if (gridName == 'addsinvalidpoolsgrid') {
        transactions = addsInvalidPoolsGridReducer.transactions;
      }else if (gridName == 'movecostgrid') {
        transactions = moveCostGridReducer.transactions;
      }
      else if (gridName == 'salvagerategrid') {
        transactions = salvageRateGridReducer.transactions;
      }
      else if (gridName == 'targetzonepoolgrid') {
        transactions = targetZonePoolGridReducer.transactions;
      }
      /* Moved to Metadata Tab
      // else if (gridName == 'zonemapgrid') {
      //   transactions = zoneMapGridReducer.transactions;
      // }
      */
      else if (gridName == 'deprategrid') {
        transactions = depRateGridReducer.transactions;
      }
      else if (gridName == 'mileagetablegrid') {
        transactions = mileageTableGridReducer.transactions;
      }else if(gridName == 'manualvindeletesgrid'){
        transactions = manualVinDeletesGridReducer.transactions;
      }
      if (transactions.length > 0) {
        transactions.map((i) => {
          if (i.type == 'edit') {
            dispatch(deleteRowEditMode(gridName, i.data.ID))
          } else
            if (selectedRows.length > 0) { //in case of copy row if we select both the rows we won't get both in transactions.
              selectedRows.map((i) => {
                dispatch(deleteRow(gridName, i.ID));
              })
            }
        })
      } else if (selectedRows.length > 0) {
        selectedRows.map((i) => {
          dispatch(deleteRow(gridName, i.ID));
        })
      }
    }

    const handleCancelClicked = (gridName) => {
      dispatch(UpdateMasterDataCache())
      if (gridName === 'agemileagegrid') {
        dispatch(cleanTransactions('agemileagegrid'));
        dispatch(refreshGridData('agemileagegrid'));
        dispatch(fetchAgeMileageData(selectedPlanId));
      }
      else if (gridName === 'additionalcostgrid') {
        dispatch(cleanTransactions('additionalcostgrid'));
        dispatch(refreshGridData('additionalcostgrid'));
        dispatch(fetchAdditionalCostData(selectedPlanId));
      }
      else if (gridName === 'mileagedefaultgrid') {
        dispatch(cleanTransactions('mileagedefaultgrid'));
        dispatch(refreshGridData('mileagedefaultgrid'));
        dispatch(fetchMileageDefaultData(selectedPlanId));
      }
      else if (gridName === 'maxdeletegrid') {
        dispatch(cleanTransactions('maxdeletegrid'));
        dispatch(refreshGridData('maxdeletegrid'));
        dispatch(fetchMaxDeletesData(selectedPlanId));
      }
      else if (gridName === 'diversionsgrid') {
        dispatch(cleanTransactions('diversionsgrid'));
        dispatch(refreshGridData('diversionsgrid'));
        dispatch(fetchDiversionsData(selectedPlanId));
      }
      else if (gridName === 'addsinvalidpoolsgrid') {
        dispatch(cleanTransactions('addsinvalidpoolsgrid'));
        dispatch(refreshGridData('addsinvalidpoolsgrid'));
        dispatch(fetchAddsInvalidPoolsData(selectedPlanId));
      }
      else if (gridName === 'movecostgrid') {
        dispatch(cleanTransactions('movecostgrid'));
        dispatch(refreshGridData('movecostgrid'));
        
        if(moveCostFilterSearchView){
          dispatch(fetchMoveCostSearchData(selectedPlanId,moveCostColumnsConstantData,moveCostFilterSearchText,moveCostPageNo));
        }else if(moveCostFilterView){
          dispatch(fetchMoveCostFilterData(selectedPlanId,moveCostFilterSelectedValues,moveCostPageNo));
        }else if(moveCostErrorView){
          //console.log("Calling fetchMoveCostErrorData from useEffect in PlanAssumptionTabPanel.js");
          dispatch(fetchMoveCostErrorData(selectedPlanId,moveCostTotRowCount,1,uniqueErrorIDs));
        }else{
          dispatch(fetchMoveCostData(selectedPlanId,moveCostPageNo));
        }
      }else if (gridName === 'salvagerategrid') {
        dispatch(cleanTransactions('salvagerategrid'));
        dispatch(refreshGridData('salvagerategrid'));
        dispatch(fetchSalvageRateData(selectedPlanId));
      }else if (gridName === 'targetzonepoolgrid') {
        dispatch(cleanTransactions('targetzonepoolgrid'));
        dispatch(refreshGridData('targetzonepoolgrid'));
        dispatch(fetchTargetZonePoolData(selectedPlanId));
      }else if (gridName === 'deprategrid') {
        dispatch(cleanTransactions('deprategrid'));
        dispatch(refreshGridData('deprategrid'));
        dispatch(fetchDepRateData(selectedPlanId));
      }
      else if (gridName === 'mileagetablegrid') {
        dispatch(cleanTransactions('mileagetablegrid'));
        dispatch(refreshGridData('mileagetablegrid'));
        dispatch(fetchMileageTableData(selectedPlanId));
      }
      else if (gridName === 'manualvindeletesgrid') {
        dispatch(cleanTransactions('manualvindeletesgrid'));
        dispatch(refreshGridData('manualvindeletesgrid'));
        if(manualVinDeletesFilterSearchView){
          dispatch(fetchManualVinDeletesSearchData(selectedPlanId,manualVinDeletesColumnsConstantData,manualVinDeletesFilterSearchText,manualVinDeletesPageNo));
        }else if(manualVinDeletesFilterView){
          dispatch(fetchManualVinDeletesFilterData(selectedPlanId,manualVinDeletesFilterSelectedValues,manualVinDeletesPageNo));
        }else if(manualVinDeletesErrorView){
          dispatch(fetchManualVinDeletesErrorData(selectedPlanId,manualVinDeletesTotalRowCount,1,ManualVinDeletesUniqueErrorIDs));
        }else{
          dispatch(fetchManualVinDeletesData(selectedPlanId,manualVinDeletesPageNo));
        }
      }
      setRefreshCells(!refreshCells);
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setIsCancelClicked(true);
      dispatch(validateGrid("agemileagegrid", ageMileageData, ageMileageGridColDef.validationFn, ageMileageGridColDef.businessValidationFn));
      dispatch(validateGrid("additionalcostgrid", additionalCostData, additionalCostGridColDef.validationFn, additionalCostGridColDef.businessValidationFn));
      dispatch(validateGrid("maxdeletegrid", maxDeletesData, maxDeletesGridColDef.validationFn, maxDeletesGridColDef.businessValidationFn)); 
      dispatch(validateGrid("diversionsgrid", diversionsData, diversionsGridColDef.validationFn, diversionsGridColDef.businessValidationFn)); 
      dispatch(validateGrid("addsinvalidpoolsgrid", addsInvalidPoolsData, addsInvalidPoolsGridColDef.validationFn, addsInvalidPoolsGridColDef.businessValidationFn)); 
      dispatch(validateGrid("mileagedefaultgrid", mileageDefaultData, mileageDefaultGridColDef.validationFn, mileageDefaultGridColDef.businessValidationFn));
      dispatch(validateGrid("movecostgrid", moveCostData,moveCostGridColDef.validationFn, moveCostGridColDef.businessValidationFn));
      dispatch(validateGrid("salvagerategrid", salvageRateData, salvageRateGridColDef.validationFn, salvageRateGridColDef.businessValidationFn)); 
      dispatch(validateGrid("targetzonepoolgrid", targetZonePoolData, targetZonePoolGridColDef.validationFn, targetZonePoolGridColDef.businessValidationFn));
      // dispatch(validateGrid("zonemapgrid", zoneMapData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));/* Moved to Metadata Tab*/
      dispatch(validateGrid("deprategrid", depRateData, depRateGridColDef.validationFn, depRateGridColDef.businessValidationFn));
      dispatch(validateGrid("mileagetablegrid", mileageTableData, mileageTableGridColDef.validationFn, mileageTableGridColDef.businessValidationFn));
      dispatch(validateGrid("manualvindeletesgrid", manualVinDeletesdata, manualVinDeletesGridColumnDef.validationFn, manualVinDeletesGridColumnDef.businessValidationFn));
    }  
const handleChangeTab = (e, index) => {
    setValue(index);
    dispatch(UpdateMasterDataCache())
    if(index==0){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchAgeMileageData(selectedPlanId));
        dispatch(fetchAgeMileageDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('agemileagegrid')
        // setplanAssumptionData(ageMileageData)
        // setplanAssumptionGridColDef(ageMileageGridColDef)
      } else if(index==1){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchAdditionalCostData(selectedPlanId));
        dispatch(fetchAdditionalCostDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('additionalcostgrid')
        // setplanAssumptionData(additionalCostData)
        // setplanAssumptionGridColDef(additionalCostGridColDef)
      }else if(index==2){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchAddsInvalidPoolsData(selectedPlanId));
        dispatch(fetchAddsInvalidPoolsDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('addsinvalidpoolsgrid')
        // setplanAssumptionData(addsInvalidPoolsData)
        // setplanAssumptionGridColDef(addsInvalidPoolsGridColDef)
      }
      else if(index==3){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchMaxDeletesData(selectedPlanId));
        dispatch(fetchMaxDeletesDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('maxdeletegrid')
        // setplanAssumptionData(maxDeletesData)
        // setplanAssumptionGridColDef(maxDeletesGridColDef)
      }
      else if(index==10){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchDiversionsData(selectedPlanId));
        dispatch(fetchDiversionsDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('diversionsgrid')
        // setplanAssumptionData(diversionsData)
        // setplanAssumptionGridColDef(diversionsGridColDef)
      }
      else if(index==4){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchMileageDefaultData(selectedPlanId));
        dispatch(fetchMileageDefaultDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('mileagedefaultgrid')
        // setplanAssumptionData(mileageDefaultData)
        // setplanAssumptionGridColDef(mileageDefaultGridColDef)
      }
      else if(index==6){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchSalvageRateData(selectedPlanId));
        dispatch(fetchSalvageRateDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('salvagerategrid')
        // setplanAssumptionData(salvageRateData)
        // setplanAssumptionGridColDef(salvageRateGridColDef)
      }
      // else if(index==12){
        // dispatch(SetAssumptionIndex(index))
        // dispatch(fetchZoneMapData(selectedPlanId));
        // dispatch(fetchZoneMapDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('zonemapgrid')
        // setplanAssumptionData(zoneMapData)
        // setplanAssumptionGridColDef(zoneMapGridColDef)
      // }
      else if(index==7){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchTargetZonePoolData(selectedPlanId));
        dispatch(fetchTargetZonePoolDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('targetzonepoolgrid')
        // setplanAssumptionData(targetZonePoolData)
        // setplanAssumptionGridColDef(targetZonePoolGridColDef)
      }
      else if(index==9){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchMileageTableData(selectedPlanId));
        dispatch(fetchMileageTableDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('mileagetablegrid')
        // setplanAssumptionData(mileageTableData)
        // setplanAssumptionGridColDef(mileageTableGridColDef)
      }

      else if(index==5){
        dispatch(SetAssumptionIndex(index))
        if(moveCostFilterSearchView){
          dispatch(fetchMoveCostSearchData(selectedPlanId,moveCostColumnsConstantData,moveCostFilterSearchText,moveCostPageNo));
        }else if(moveCostFilterView){
          dispatch(fetchMoveCostFilterData(selectedPlanId,moveCostFilterSelectedValues,moveCostPageNo));
        }else if(moveCostErrorView){
          //console.log("Calling fetchMoveCostErrorData from useEffect in PlanAssumptionTabPanel.js");
          dispatch(fetchMoveCostErrorData(selectedPlanId,moveCostTotRowCount,1,uniqueErrorIDs));
        }else{
          dispatch(fetchMoveCostData(selectedPlanId,moveCostPageNo));
        }
        dispatch(fetchMoveCostDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('movecostgrid')
        // setplanAssumptionData(moveCostData)
        // setplanAssumptionGridColDef(moveCostGridColDef)
      }
      else if(index==8){
        dispatch(SetAssumptionIndex(index))
        dispatch(fetchDepRateData(selectedPlanId));
        dispatch(fetchDepRateDropDownValues(selectedPlanId));
        // setPlanAssumptionsGrid('deprategrid')
        // setplanAssumptionData(depRateData)
        // setplanAssumptionGridColDef(depRateGridColDef)
      }
      else if(index===11){
        dispatch(SetAssumptionIndex(index))
        if(manualVinDeletesFilterSearchView){
          dispatch(fetchManualVinDeletesSearchData(selectedPlanId,manualVinDeletesColumnsConstantData,manualVinDeletesFilterSearchText,manualVinDeletesPageNo));
        }else if(manualVinDeletesFilterView){
          dispatch(fetchManualVinDeletesFilterData(selectedPlanId,manualVinDeletesFilterSelectedValues,manualVinDeletesPageNo));
        }else if(manualVinDeletesErrorView){
          dispatch(fetchManualVinDeletesErrorData(selectedPlanId,manualVinDeletesTotalRowCount,1,ManualVinDeletesUniqueErrorIDs));
        }else{
          dispatch(fetchManualVinDeletesData(selectedPlanId,manualVinDeletesPageNo));
        }
        dispatch(fetchManualVinDeletesDropdownValues(selectedPlanId));
        // setPlanAssumptionsGrid('manualvindeletesgrid')
        // setplanAssumptionData(manualVinDeletesdata)
        // setplanAssumptionGridColDef(manualVinDeletesGridColumnDef)
      }
}
  
useEffect(()=>{
  if(moveCostFilterView || moveCostFilterSearchView){
    setMoveCostData(moveCostFilterData)
  } else{  if(moveCostErrorView){
    setMoveCostData(moveCostErrorData)
   }
   if(moveCostErrorView==false) {
    setMoveCostData(moveCostdata)
   }
  }
},[moveCostErrorView,moveCostdata,moveCostErrorData,moveCostFilterView,moveCostFilterSearchView,moveCostFilterData])

//Age Mileage
useEffect(() => {
  if (ageMileageData) {
      setPlanAssumptionsGrid('agemileagegrid')
    setplanAssumptionData(ageMileageData)
    setplanAssumptionGridColDef(ageMileageGridColDef)
    }
}, [ageMileageData]);

      useEffect(() =>{
        if (!showSaveOption && ageMileageUploadSuccess == true) {
          if (ageMileageRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (ageMileageUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [ageMileageUploadSuccess, ageMileageRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='agemileagegrid'){
        const filterValidTransactions = fetchValidTransactions(ageMileageGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(ageMileageGridReducer.transactions);
    
        if ((!ageMileageGridReducer.transactions || ageMileageGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "agemileagegrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "agemileagegrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveAgeMileageTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "agemileagegrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [ageMileageRowDataInvalid, isSaveClicked, snackBarIfError,masterDataUpdateState,pending]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(ageMileageGridReducer.transactions);
    
        if (ageMileageTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (ageMileageRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('agemileagegrid'));
          dispatch(refreshGridData('agemileagegrid'));
          dispatch(validateGrid("agemileagegrid", ageMileageGridReducer.currentData, ageMileageGridColDef.validationFn, ageMileageGridColDef.businessValidationFn));
        }
        else if (ageMileageTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [ageMileageTransactionSaveSuccessful]);


//  Additional Cost
     useEffect(() => {
      if (additionalCostData ) {
        setPlanAssumptionsGrid('additionalcostgrid')
        setplanAssumptionData(additionalCostData)
        setplanAssumptionGridColDef(additionalCostGridColDef) 
       }
    }, [additionalCostData]);
      useEffect(() => {
        if (!showSaveOption && additionalCostUploadSuccess == true) {
          if (additionalCostRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (additionalCostUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [additionalCostUploadSuccess, additionalCostRowDataInvalid]);
      
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='additionalcostgrid'){
        const filterValidTransactions = fetchValidTransactions(additionalCostGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(additionalCostGridReducer.transactions);
      
        if ((!additionalCostGridReducer.transactions || additionalCostGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "additionalcostgrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
      
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "additionalcostgrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveAdditionalCostTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
      
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "additionalcostgrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [additionalCostRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
      
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(additionalCostGridReducer.transactions);
      
        if (additionalCostTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
      
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (additionalCostRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('additionalcostgrid'));
          dispatch(refreshGridData('additionalcostgrid'));
          dispatch(validateGrid("additionalcostgrid", additionalCostGridReducer.currentData, additionalCostGridColDef.validationFn,additionalCostGridColDef.businessValidationFn));
        }
        else if (additionalCostTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [additionalCostTransactionSaveSuccessful]);

//Max Deletes
useEffect(() => {
  if (maxDeletesData) {
      setPlanAssumptionsGrid('maxdeletegrid')
    setplanAssumptionData(maxDeletesData)
    setplanAssumptionGridColDef(maxDeletesGridColDef)
    }
}, [maxDeletesData]);

      useEffect(() =>{
        if (!showSaveOption && maxDeletesUploadSuccess == true) {
          if (maxDeletesRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (maxDeletesUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [maxDeletesUploadSuccess, maxDeletesRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='maxdeletegrid'){
        const filterValidTransactions = fetchValidTransactions(maxDeletesGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(maxDeletesGridReducer.transactions);
    
        if ((!maxDeletesGridReducer.transactions || maxDeletesGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "maxdeletegrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "maxdeletegrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveMaxDeletesTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "maxdeletegrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [maxDeletesRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(maxDeletesGridReducer.transactions);
    
        if (maxDeletesTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (maxDeletesRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('maxdeletegrid'));
          dispatch(refreshGridData('maxdeletegrid'));
          dispatch(validateGrid("maxdeletegrid", maxDeletesGridReducer.currentData, maxDeletesGridColDef.validationFn, maxDeletesGridColDef.businessValidationFn));  
        }
        else if (maxDeletesTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [maxDeletesTransactionSaveSuccessful]);

//Diversions
useEffect(() => {
  if (diversionsData) {
      setPlanAssumptionsGrid('diversionsgrid')
    setplanAssumptionData(diversionsData)
    setplanAssumptionGridColDef(diversionsGridColDef)
    }
}, [diversionsData]);

      useEffect(() =>{
        if (!showSaveOption && diversionsUploadSuccess == true) {
          if (diversionsRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (diversionsUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [diversionsUploadSuccess, diversionsRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='diversionsgrid'){
        const filterValidTransactions = fetchValidTransactions(diversionsGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(diversionsGridReducer.transactions);
    
        if ((!diversionsGridReducer.transactions || diversionsGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "diversionsgrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "diversionsgrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveDiversionsTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "diversionsgrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [diversionsRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(diversionsGridReducer.transactions);
    
        if (diversionsTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (diversionsRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('diversionsgrid'));
          dispatch(refreshGridData('diversionsgrid'));
          dispatch(validateGrid("diversionsgrid", diversionsGridReducer.currentData, diversionsGridColDef.validationFn, diversionsGridColDef.businessValidationFn));  
        }
        else if (diversionsTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [diversionsTransactionSaveSuccessful]);

//Adds Invalid Pools
useEffect(() => {
  if (addsInvalidPoolsData) {
      setPlanAssumptionsGrid('addsinvalidpoolsgrid')
    setplanAssumptionData(addsInvalidPoolsData)
    setplanAssumptionGridColDef(addsInvalidPoolsGridColDef)
    }
}, [addsInvalidPoolsData]);

      useEffect(() =>{
        if (!showSaveOption && addsInvalidPoolsUploadSuccess == true) {
          if (addsInvalidPoolsRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (addsInvalidPoolsUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [addsInvalidPoolsUploadSuccess, addsInvalidPoolsRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='addsinvalidpoolsgrid'){
        const filterValidTransactions = fetchValidTransactions(addsInvalidPoolsGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(addsInvalidPoolsGridReducer.transactions);
    
        if ((!addsInvalidPoolsGridReducer.transactions || addsInvalidPoolsGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "addsinvalidpoolsgrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "addsinvalidpoolsgrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveAddsInvalidPoolsTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "addsinvalidpoolsgrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [addsInvalidPoolsRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(addsInvalidPoolsGridReducer.transactions);
    
        if (addsInvalidPoolsTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (addsInvalidPoolsRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('addsinvalidpoolsgrid'));
          dispatch(refreshGridData('addsinvalidpoolsgrid'));
          dispatch(validateGrid("addsinvalidpoolsgrid", addsInvalidPoolsGridReducer.currentData, addsInvalidPoolsGridColDef.validationFn, addsInvalidPoolsGridColDef.businessValidationFn));  
        }
        else if (addsInvalidPoolsTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [addsInvalidPoolsTransactionSaveSuccessful]);

  //Mileage Default
useEffect(() => {
  if (mileageDefaultData) {
    setPlanAssumptionsGrid('mileagedefaultgrid')
    setplanAssumptionData(mileageDefaultData)
    setplanAssumptionGridColDef(mileageDefaultGridColDef)
    }
}, [mileageDefaultData]);

      useEffect(() =>{
        if (!showSaveOption && mileageDefaultUploadSuccess == true) {
          if (mileageDefaultRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (mileageDefaultUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [mileageDefaultUploadSuccess, mileageDefaultRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='mileagedefaultgrid'){
        const filterValidTransactions = fetchValidTransactions(mileageDefaultGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(mileageDefaultGridReducer.transactions);
    
        if ((!mileageDefaultGridReducer.transactions || mileageDefaultGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "mileagedefaultgrid")) {
            setSnackBarOpen(false);
            userMessage('warning', 'Nothing to be saved');
            setShowSaveOption(false);
            setIsSaveClicked(null);
          }
      
          if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "mileagedefaultgrid")) {
            if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
              dispatch(saveMileageDefaultTransactionData(selectedPlanId, filterValidTransactions));
            }
            else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
              userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
            }
          }
      
          if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "mileagedefaultgrid")) {
            setSnackBarOpen(true);
          }
        }
        }, [mileageDefaultRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]); 

        useEffect(() => {
          const filterValidTransactions = fetchValidTransactions(mileageDefaultGridReducer.transactions);
      
          if (mileageDefaultTransactionSaveSuccessful) {
            setShowSaveOption(false);
            setIsSaveClicked(null);
            setRefreshCells(!refreshCells);
      
            if (filterValidTransactions && filterValidTransactions.length > 0) {
              if (mileageDefaultRowDataInvalid) {
                userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
              } else {
                userMessage('success', 'Successfully saved rows')
              }
            }
            dispatch(cleanTransactions('mileagedefaultgrid'));
            dispatch(refreshGridData('mileagedefaultgrid'));
            dispatch(validateGrid("mileagedefaultgrid", mileageDefaultGridReducer.currentData, mileageDefaultGridColDef.validationFn, mileageDefaultGridColDef.businessValidationFn));  
          }
          else if (mileageDefaultTransactionSaveSuccessful === false) {
            alert('Unable to save transactions. Please try again.');
          }
        }, [mileageDefaultTransactionSaveSuccessful]);

  //Salvage Rate
useEffect(() => {
  if (salvageRateData) {
    setPlanAssumptionsGrid('salvagerategrid')
    setplanAssumptionData(salvageRateData)
    setplanAssumptionGridColDef(salvageRateGridColDef)
    }
}, [salvageRateData]);

      useEffect(() =>{
        if (!showSaveOption && salvageRateUploadSuccess == true) {
          if (salvageRateRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (salvageRateUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [salvageRateUploadSuccess, salvageRateRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='salvagerategrid'){
        const filterValidTransactions = fetchValidTransactions(salvageRateGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(salvageRateGridReducer.transactions);
    
        if ((!salvageRateGridReducer.transactions || salvageRateGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "salvagerategrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "salvagerategrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveSalvageRateTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "salvagerategrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [salvageRateRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(salvageRateGridReducer.transactions);
    
        if (salvageRateTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (salvageRateRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('salvagerategrid'));
          dispatch(refreshGridData('salvagerategrid'));
          dispatch(validateGrid("salvagerategrid", salvageRateGridReducer.currentData, salvageRateGridColDef.validationFn, salvageRateGridColDef.businessValidationFn));  
        }
        else if (salvageRateTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [salvageRateTransactionSaveSuccessful]);
  
//Target Zone Pool
useEffect(() => {
  if (targetZonePoolData) {
    setPlanAssumptionsGrid('targetzonepoolgrid')
    setplanAssumptionData(targetZonePoolData)
    setplanAssumptionGridColDef(targetZonePoolGridColDef)
    }
}, [targetZonePoolData]);

      useEffect(() =>{
        if (!showSaveOption && targetZonePoolUploadSuccess == true) {
          if (targetZonePoolRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (targetZonePoolUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [targetZonePoolUploadSuccess, targetZonePoolRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='targetzonepoolgrid'){
        const filterValidTransactions = fetchValidTransactions(targetZonePoolGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(targetZonePoolGridReducer.transactions);
    
        if ((!targetZonePoolGridReducer.transactions || targetZonePoolGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "targetzonepoolgrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "targetzonepoolgrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveTargetZonePoolTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "targetzonepoolgrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [targetZonePoolRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(targetZonePoolGridReducer.transactions);
    
        if (targetZonePoolTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (targetZonePoolRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('targetzonepoolgrid'));
          dispatch(refreshGridData('targetzonepoolgrid'));
          dispatch(validateGrid("targetzonepoolgrid", targetZonePoolGridReducer.currentData, targetZonePoolGridColDef.validationFn, targetZonePoolGridColDef.businessValidationFn));  
        }
        else if (targetZonePoolTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [targetZonePoolTransactionSaveSuccessful]);
      /* Moved to Metadata Tab
//   //Zone Map
// useEffect(() => {
//   if (zoneMapData) {
//     setPlanAssumptionsGrid('zonemapgrid')
//     setplanAssumptionData(zoneMapData)
//     setplanAssumptionGridColDef(zoneMapGridColDef)
//     }
// }, [zoneMapData]);
// 
      // useEffect(() =>{
      //   if (!showSaveOption && zoneMapUploadSuccess == true) {
      //     if (zoneMapRowDataInvalid == true)
      //       userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      //     else
      //       userMessage('success', 'Successfully uploaded');
      //   } else if (zoneMapUploadSuccess == false) {
      //     userMessage('error', 'Upload failed');
      //   }
      // }, [zoneMapUploadSuccess, zoneMapRowDataInvalid]);
    //
      // useEffect(() => {
      //   if(!pending && !masterDataUpdateState && isSaveClicked==='zonemapgrid'){
      //   const filterValidTransactions = fetchValidTransactions(zoneMapGridReducer.transactions);
      //   const filterInValidTransactions = fetchInvalidTransactions(zoneMapGridReducer.transactions);
    //
      //   if ((!zoneMapGridReducer.transactions || zoneMapGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "zonemapgrid")) {
      //     setSnackBarOpen(false);
      //     userMessage('warning', 'Nothing to be saved');
      //     setShowSaveOption(false);
      //     setIsSaveClicked(null);
      //   }
    //
      //   if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "zonemapgrid")) {
      //     if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
      //       dispatch(saveZoneMapTransactionData(selectedPlanId, filterValidTransactions));
      //     }
      //     else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
      //       userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      //     }
      //   }
    //
      //   if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "zonemapgrid")) {
      //     setSnackBarOpen(true);
      //   }
      // }
      // }, [zoneMapRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    //
      // useEffect(() => {
      //   const filterValidTransactions = fetchValidTransactions(zoneMapGridReducer.transactions);
    //
      //   if (zoneMapTransactionSaveSuccessful) {
      //     setShowSaveOption(false);
      //     setIsSaveClicked(null);
      //     setRefreshCells(!refreshCells);
    //
      //     if (filterValidTransactions && filterValidTransactions.length > 0) {
      //       if (zoneMapRowDataInvalid) {
      //         userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
      //       } else {
      //         userMessage('success', 'Successfully saved rows')
      //       }
      //     }
      //     dispatch(cleanTransactions('zonemapgrid'));
      //     dispatch(refreshGridData('zonemapgrid'));
      //     dispatch(validateGrid("zonemapgrid", zoneMapGridReducer.currentData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));  
      //   }
      //   else if (zoneMapTransactionSaveSuccessful === false) {
      //     alert('Unable to save transactions. Please try again.');
      //   }
      // }, [zoneMapTransactionSaveSuccessful]);
    */

//Mileage Table
useEffect(() => {
  if (mileageTableData) {
    setPlanAssumptionsGrid('mileagetablegrid')
    setplanAssumptionData(mileageTableData)
    setplanAssumptionGridColDef(mileageTableGridColDef)
    }
}, [mileageTableData]);

      useEffect(() =>{
        if (!showSaveOption && mileageTableUploadSuccess == true) {
          if (mileageTableRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (mileageTableUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [mileageTableUploadSuccess, mileageTableRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='mileagetablegrid'){
        const filterValidTransactions = fetchValidTransactions(mileageTableGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(mileageTableGridReducer.transactions);
    
        if ((!mileageTableGridReducer.transactions || mileageTableGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "mileagetablegrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "mileagetablegrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveMileageTableTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "mileagetablegrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [mileageTableRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(mileageTableGridReducer.transactions);
    
        if (mileageTableTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (mileageTableRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('mileagetablegrid'));
          dispatch(refreshGridData('mileagetablerid'));
          dispatch(validateGrid("mileagetablegrid", mileageTableGridReducer.currentData, mileageTableGridColDef.validationFn, mileageTableGridColDef.businessValidationFn));  
        }
        else if (mileageTableTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [mileageTableTransactionSaveSuccessful]);

 //Move Cost
useEffect(() => {
  if (moveCostData && IndexValue==5) {
    setPlanAssumptionsGrid('movecostgrid')
    setplanAssumptionData(moveCostData)
    setplanAssumptionGridColDef(moveCostGridColDef)
    }
}, [moveCostData,IndexValue]);


// useEffect(()=>{
//     console.log('total row count->',moveCostTotRowCount)
//     let totalPage=Math.floor(moveCostTotRowCount/5000)
//     console.log('total page->',totalPage)
//     setTotalpage(totalPage)  
// },[moveCostTotRowCount,moveCostErrorData,moveCostErrorView])

useEffect(() =>{
    if (moveCostDisableButton == true){
      userMessage('warning', 'Please wait while uploading...')
    }else if (moveCostDisableButton == false){
      userMessage('success', 'Successfully uploaded');
    }else if (moveCostDisableButton == 'download'){
      userMessage('success', 'Please wait your file is getting downloaded!!');
    }else if (moveCostDisableButton == 'insert failed'){
      userMessage('error', 'Insert failed Please upload your file again!!');
    }else if(moveCostDisableButton==='error'){
      userMessage('error', 'Upload failed...')
      dispatch(SetAssumptionMoveCostUploadStatus(null))
    } 
}, [moveCostDisableButton]);


      useEffect(() =>{
        if (!showSaveOption && moveCostUploadSuccess == true) {
          if (moveCostRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (moveCostUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [moveCostUploadSuccess, moveCostRowDataInvalid]);

    useEffect(() =>{
      if (deleteMoveCostRecordsSuccess == true) {
          userMessage('success', 'Deleted successfully');
      }
      else if (deleteMoveCostRecordsSuccess == false) {
        userMessage('error', 'Delete failed');
      }
    }, [deleteMoveCostRecordsSuccess]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='movecostgrid'){
        const filterValidTransactions = fetchValidTransactions(moveCostGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(moveCostGridReducer.transactions);
    
        if ((!moveCostGridReducer.transactions || moveCostGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "movecostgrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "movecostgrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveMoveCostTransactionData(selectedPlanId, filterValidTransactions,moveCostPageNo,moveCostErrorView,moveCostFilterView,moveCostFilterSelectedValues,moveCostFilterSearchView,moveCostColumnsConstantData,moveCostFilterSearchText));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "movecostgrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [moveCostRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(moveCostGridReducer.transactions);
    
        if (moveCostTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (moveCostRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('movecostgrid'));
          dispatch(refreshGridData('movecostgrid'));
          dispatch(validateGrid("movecostgrid", moveCostGridReducer.currentData, moveCostGridColDef.validationFn, moveCostGridColDef.businessValidationFn));  
        }
        else if (moveCostTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [moveCostTransactionSaveSuccessful]);
  
//Manual vin deletes
useEffect(() => {
  if (manualVinDeletesdata && IndexValue==11) {
    setPlanAssumptionsGrid('manualvindeletesgrid')
    setplanAssumptionData(manualVinDeletesdata)
    setplanAssumptionGridColDef(manualVinDeletesGridColumnDef)
    }
}, [manualVinDeletesdata,IndexValue]);

  useEffect(() =>{
    if (!showSaveOption && manualVinDeletesUploadSuccess == true) {
      if (manualVinDeletesRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      else
        userMessage('success', 'Successfully uploaded');
    } else if (manualVinDeletesUploadSuccess == false) {
      userMessage('error', 'Upload failed');
    }
  }, [manualVinDeletesUploadSuccess, manualVinDeletesRowDataInvalid]);
  
  useEffect(() =>{
    if (deleteManualVinDeletesRecordsSuccess == true) {
        userMessage('success', 'Deleted successfully');
    }
    else if (deleteManualVinDeletesRecordsSuccess == false) {
      userMessage('error', 'Delete failed');
    }
  }, [deleteManualVinDeletesRecordsSuccess]);

      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='manualvindeletesgrid'){
        const filterValidTransactions = fetchValidTransactions(manualVinDeletesGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(manualVinDeletesGridReducer.transactions);
    
        if ((!manualVinDeletesGridReducer.transactions || manualVinDeletesGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "manualvindeletesgrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "manualvindeletesgrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveManualVinDeletesTransactionData(selectedPlanId, filterValidTransactions,manualVinDeletesPageNo,manualVinDeletesColumnsConstantData,manualVinDeletesFilterSelectedValues,manualVinDeletesFilterSearchText,manualVinDeletesFilterView,manualVinDeletesFilterSearchView,manualVinDeletesErrorView));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "manualvindeletesgrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [manualVinDeletesRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(manualVinDeletesGridReducer.transactions);
    
        if (manualVinDeletesTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (manualVinDeletesRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('manualvindeletesgrid'));
          dispatch(refreshGridData('manualvindeletesgrid'));
          dispatch(validateGrid("manualvindeletesgrid", manualVinDeletesGridReducer.currentData, manualVinDeletesGridColumnDef.validationFn, manualVinDeletesGridColumnDef.businessValidationFn));
        }
        else if (manualVinDeletesTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [manualVinDeletesTransactionSaveSuccessful]);

    useEffect(()=>{
      if(manualVinDeletesFilterView || manualVinDeletesFilterSearchView){
        setManualVinDeletesdata(manualVinDeletesFilterData)
      } else{  if(manualVinDeletesErrorView){
        setManualVinDeletesdata(manualVinDeletesErrorData)
        }
        if(manualVinDeletesErrorView==false) {
          setManualVinDeletesdata(manualVinDeletesData)
        }
      }
    },[manualVinDeletesErrorView,manualVinDeletesData,manualVinDeletesErrorData,manualVinDeletesFilterView,manualVinDeletesFilterSearchView,manualVinDeletesFilterData])
  //Dep Rate
useEffect(() => {
  if (depRateData) {
      setPlanAssumptionsGrid('deprategrid')
    setplanAssumptionData(depRateData)
    setplanAssumptionGridColDef(depRateGridColDef)
    }
}, [depRateData]);

      useEffect(() =>{
        if (!showSaveOption && depRateUploadSuccess == true) {
          if (depRateRowDataInvalid == true)
            userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          else
            userMessage('success', 'Successfully uploaded');
        } else if (depRateUploadSuccess == false) {
          userMessage('error', 'Upload failed');
        }
      }, [depRateUploadSuccess, depRateRowDataInvalid]);
    
      useEffect(() => {
        if(!pending && !masterDataUpdateState && isSaveClicked==='deprategrid'){
        const filterValidTransactions = fetchValidTransactions(depRateGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(depRateGridReducer.transactions);
    
        if ((!depRateGridReducer.transactions || depRateGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "deprategrid")) {
          setSnackBarOpen(false);
          userMessage('warning', 'Nothing to be saved');
          setShowSaveOption(false);
          setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "deprategrid")) {
          if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
            dispatch(saveDepRateTransactionData(selectedPlanId, filterValidTransactions));
          }
          else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "deprategrid")) {
          setSnackBarOpen(true);
        }
      }
      }, [depRateRowDataInvalid, isSaveClicked, snackBarIfError, pending, masterDataUpdateState]);
    
      useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(depRateGridReducer.transactions);
    
        if (depRateTransactionSaveSuccessful) {
          setShowSaveOption(false);
          setIsSaveClicked(null);
          setRefreshCells(!refreshCells);
    
          if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (depRateRowDataInvalid) {
              userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
              userMessage('success', 'Successfully saved rows')
            }
          }
          dispatch(cleanTransactions('deprategrid'));
          dispatch(refreshGridData('deprategrid'));
          dispatch(validateGrid("deprategrid", depRateGridReducer.currentData, depRateGridColDef.validationFn, depRateGridColDef.businessValidationFn));
        }
        else if (depRateTransactionSaveSuccessful === false) {
          alert('Unable to save transactions. Please try again.');
        }
      }, [depRateTransactionSaveSuccessful]);

  useEffect(() => {
    // This useEffect is used to fetch data initially when user navigates into planAssumption Tab from his previously navigated tab inside the planAssumptions tab.
    if (IndexValue == 0) {
      dispatch(UpdateMasterDataCache())
      dispatch(fetchAgeMileageData(selectedPlanId));
      dispatch(fetchAgeMileageDropdownValues(selectedPlanId));
    } else
      if (IndexValue) {
        handleChangeTab('',IndexValue)
      }
  },[copyToMainInputsStatus.length>0])

useEffect(()=>{
  setSnackBarOpen(false);
  setWarningSnackBarOpen(false)
},[])




    return (
        <div className='planassumptionbgcolor'>
            <ActionBar
             planAssumptionData={planAssumptionData?planAssumptionData:ageMileageData}
             planAssumptionGridColDef={planAssumptionGridColDef?planAssumptionGridColDef:ageMileageGridColDef}
            //  ageMileageData={ageMileageData}
            //  additionalCostData={additionalCostData}
             planAssumptionsGrid={planAssumptionsGrid?planAssumptionsGrid:'agemileagegrid'}
            //  ageMileageGridColDef={ageMileageGridColDef}
            //  additionalCostGridColDef={additionalCostGridColDef}
             options={showSaveOption}
             movecostFilterDisplayData={movecostFilterDisplayData}
             handleSaveCancelDisabled={handleSaveCancelDisabled}
             onSaveClicked={(e) => handleSaveClicked(planAssumptionsGrid)}
             onCancelClicked={(e) => handleCancelClicked(planAssumptionsGrid)}
             onAddNewRowClick={(e) => handleAddNewRow(planAssumptionsGrid)}
             selectedPlanId={selectedPlanId}
             bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
             deleteSelectedRows={(e) => deleteSelectedRows(planAssumptionsGrid)}
             downloadCSV={downloadCSV}
             data={gridReducer.currentData}
             additionalCostCurrentdata={additionCostgridReducer.currentData}
             mileageDefaultCurrentdata={mileageDefaultgridReducer.currentData}
             maxDeletesCurrentdata={maxDeletesGridReducer.currentData}
             diversionsCurrentdata={diversionsGridReducer.currentData}
             salvageRateCurrentdata={salvageRateGridReducer.currentData}
             targetZonePoolCurrentdata={targetZonePoolGridReducer.currentData}
            //  zoneMapCurrentdata={zoneMapGridReducer.currentData} /* Moved to Metadata Tab */
             mileageTableCurrentdata={mileageTableGridReducer.currentData}
             moveCostCurrentdata={moveCostGridReducer.currentData}
             movecostFilterParams={moveCostFilterSelectedValues}
             addsInvalidPoolsCurrentdata={addsInvalidPoolsGridReducer.currentData}
             depRateCurrentdata={depRateGridReducer.currentData}
             manualVinDeletesCurrentData={manualVinDeletesGridReducer.currentData}
             manualVinDeletesFilterParams={manualVinDeletesFilterSelectedValues}
             //filterSearchKey={filterSearchKey}
             //filterParams={filterParams}
             transactions={ageMileageTransactions}
             additionalCostTransactions={additionalCostTransactions}
             mileageDefaultTransactions={mileageDefaultGridReducer.transactions}
             maxDeletesTransactions={maxDeletesGridReducer.transactions}
             diversionsTransactions={diversionsGridReducer.transactions}
             depRateTransactions={depRateGridReducer.transactions}
             salvageRateTransactions={salvageRateGridReducer.transactions}
             targetZonePoolTransactions={targetZonePoolGridReducer.transactions}
            //  zoneMapTransactions={zoneMapGridReducer.transactions}/* Moved to Metadata Tab */
             mileageTableTransactions={mileageTableGridReducer.transactions}
             moveCostTransactions={moveCostGridReducer.transactions}
             addsInvalidPoolsTransactions={addsInvalidPoolsGridReducer.transactions}
             manualVinDeletesTransactions={manualVinDeletesGridReducer.transactions}
             userMessage={userMessage}
             manualVinDeletesFilterDisplayData={manualVinDeletesFilterDisplayData}
            >
            </ActionBar>
            <div className='planassumptiontabs'>
                    <NttTabs
                        value={value}
                        orientation="vertical"
                        variant="scrollable"
                        scrollButtons='on'
                        onChange={(e,i)=>{handleChangeTab(e,i)}}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {tabsData.map((tabInfo, index) => (
                            <NttTab label={tabInfo.tabTitle}
                                id={`simple-tab-${index}`}
                                key={tabInfo.tabTitle}
                                style={getStyle(value === index,tabInfo.tabTitle,index)}
                                disabled={showSaveOption || pending ||moveCostDisableButton==true|| !(index==0 || index==1 || index==2 || index==3 || index==4 || index==5 || index==6 || index==7 || index==8 || index==9||index==10||index==11||index==12)  } 
                            />
                        ))}
                    </NttTabs>
                {tabsData.map((tabInfo, index) => (
                    <TabPanel key={index}
                        value={value} index={index} className='planassumptiontabpanel paddingtarget'>
                        {( ageMileageData && value == 0 ) ?
                            <AgeMileageTabPanel 
                            gridReducer={gridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            ageMileageGridColDef={ageMileageGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('agemileagegrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            // planAssumptionsGrid={planAssumptionsGrid}
                            ageMileageTransactions={ageMileageTransactions}
                            ageMileageData={ageMileageData}
                            userMessage={userMessage}
                           />
                            : ( additionalCostData && value == 1 ) ?
                            <AdditionalCostTabPanel 
                            additionCostgridReducer={additionCostgridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            additionalCostGridColDef={additionalCostGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('additionalcostgrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            // planAssumptionsGrid={planAssumptionsGrid}
                            additionalCostTransactions={additionalCostTransactions}
                            additionalCostData={additionalCostData}
                            userMessage={userMessage}
                           />
                           : ( addsInvalidPoolsData && value == 2 ) ?
                            <AddsInvalidPoolsTabPanel 
                            addsInvalidPoolsgridReducer={addsInvalidPoolsGridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            addsInvalidPoolsGridColDef={addsInvalidPoolsGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('addsinvalidpoolsgrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            // planAssumptionsGrid={planAssumptionsGrid}
                            addsInvalidPoolsTransactions={addsInvalidPoolsTransactions}
                            addsInvalidPoolsData={addsInvalidPoolsData}
                            userMessage={userMessage}
                           /> 
                            : (maxDeletesData && value == 3 ) ?
                            <MaxDeletesTabPanel 
                            maxDeletesgridReducer={maxDeletesGridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            maxDeletesGridColDef={maxDeletesGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('maxdeletegrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            // planAssumptionsGrid={planAssumptionsGrid}
                            maxDeletesTransactions={maxDeletesGridReducer.transactions}
                            maxDeletesData={maxDeletesData}
                            userMessage={userMessage}
                            
                           />
                           : (diversionsData && value == 10 ) ?
                           <DiversionsTabPanel 
                           diversionsgridReducer={diversionsGridReducer}
                           currentView={currentView}
                           refreshCells={refreshCells}
                           handleRowEditingStarted={handleRowEditingStarted}
                           handleRowEditingStopped={handleRowEditingStopped}
                           handleCellEditingStopped={handleCellEditingStopped}
                           diversionsGridColDef={diversionsGridColDef}
                           suppressclickEdit={false}
                           deleteSelectedRows={(e) => deleteSelectedRows('diversionsgrid')}
                           handleMultipleRowDelete={handleMultipleRowDelete} 
                           // planAssumptionsGrid={planAssumptionsGrid}
                           diversionsTransactions={diversionsGridReducer.transactions}
                           diversionsData={diversionsData}
                           userMessage={userMessage}
                          />
                            : ( mileageDefaultData && value == 4 ) ?
                            <MileageDefaultTabPanel 
                            mileageDefaultgridReducer={mileageDefaultgridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            mileageDefaultGridColDef={mileageDefaultGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('mileagedefaultgrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            // planAssumptionsGrid={planAssumptionsGrid}
                            mileageDefaultTransactions={mileageDefaultGridReducer.transactions}
                            mileageDefaultData={mileageDefaultData}                            
                            userMessage={userMessage}
                           />
                           : (moveCostData && value == 5 ) ?
                           <MoveCostTabPanel 
                           selectedPlanId={selectedPlanId}
                           moveCostgridReducer={moveCostGridReducer}
                           currentView={currentView}
                           refreshCells={refreshCells}
                           handleRowEditingStarted={handleRowEditingStarted}
                           handleRowEditingStopped={handleRowEditingStopped}
                           handleCellEditingStopped={handleCellEditingStopped}
                           moveCostGridColDef={moveCostGridColDef}
                           suppressclickEdit={false}
                           deleteSelectedRows={(e) => deleteSelectedRows('movecostgrid')}
                           handleMultipleRowDelete={handleMultipleRowDelete} 
                           // planAssumptionsGrid={planAssumptionsGrid}
                           moveCostTransactions={moveCostGridReducer.transactions}
                           moveCostPageNo={moveCostPageNo}
                           moveCostData={moveCostData}
                           totalPage={totalPage}                           
                           userMessage={userMessage}
                          />
                          //  : (zoneMapData && value == 12 && selectedurlHasMainInputs) ?
                          //   <ZoneMapTabPanel 
                          //   zoneMapgridReducer={zoneMapGridReducer}
                          //   currentView={currentView}
                          //   refreshCells={refreshCells}
                          //   handleRowEditingStarted={handleRowEditingStarted}
                          //   handleRowEditingStopped={handleRowEditingStopped}
                          //   handleCellEditingStopped={handleCellEditingStopped}
                          //   zoneMapGridColDef={zoneMapGridColDef}
                          //   suppressclickEdit={false}
                          //   deleteSelectedRows={(e) => deleteSelectedRows('zonemapgrid')}
                          //   handleMultipleRowDelete={handleMultipleRowDelete} 
                          //   // planAssumptionsGrid={planAssumptionsGrid}
                          //   zoneMapTransactions={zoneMapGridReducer.transactions}
                          //   zoneMapData={zoneMapData}
                          //   userMessage={userMessage}
                          //  />
                           : (salvageRateData && value == 6) ?
                           <SalvageRateTabPanel 
                           salvageRategridReducer={salvageRateGridReducer}
                           currentView={currentView}
                           refreshCells={refreshCells}
                           handleRowEditingStarted={handleRowEditingStarted}
                           handleRowEditingStopped={handleRowEditingStopped}
                           handleCellEditingStopped={handleCellEditingStopped}
                           salvageRateGridColDef={salvageRateGridColDef}
                           suppressclickEdit={false}
                           deleteSelectedRows={(e) => deleteSelectedRows('salvagerategrid')}
                           handleMultipleRowDelete={handleMultipleRowDelete} 
                           // planAssumptionsGrid={planAssumptionsGrid}
                           salvageRateTransactions={salvageRateGridReducer.transactions}
                           salvageRateData={salvageRateData}
                           userMessage={userMessage}
                          />
                           : (targetZonePoolData && value == 7) ?
                            <TargetZonePoolTabPanel 
                            targetZonePoolgridReducer={targetZonePoolGridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            targetZonePoolGridColDef={targetZonePoolGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('targetzonepoolgrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            targetZonePoolTransactions={targetZonePoolGridReducer.transactions}
                            targetZonePoolData={targetZonePoolData}                            
                            userMessage={userMessage}
                           />
                           : (depRateData && value == 8) ?
                            <DepRateTabPanel
                            depRategridReducer={depRateGridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            depRateGridColDef={depRateGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('deprategrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete}
                            depRateTransactions={depRateGridReducer.transactions}
                            depRateData={depRateData}
                            userMessage={userMessage}
                           />
                           : (mileageTableData && value == 9) ?
                            <MileageTableTabPanel 
                            mileageTablegridReducer={mileageTableGridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            mileageTableGridColDef={mileageTableGridColDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('mileagetablegrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            // planAssumptionsGrid={planAssumptionsGrid}
                            mileageTableTransactions={mileageTableGridReducer.transactions}
                            mileageTableData={mileageTableData}
                            userMessage={userMessage}
                           />:
                           (manualVinDeletesdata && value == 11) ?
                            <ManualVinDeletesTabPanel 
                            manualVinDeletesGridReducer={manualVinDeletesGridReducer}
                            currentView={currentView}
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            manualVinDeletesGridColumnDef={manualVinDeletesGridColumnDef}
                            suppressclickEdit={false}
                            deleteSelectedRows={(e) => deleteSelectedRows('manualvindeletesgrid')}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            manualVinDeletesTableTransactions={manualVinDeletesGridReducer.transactions}
                            manualVinDeletesData={manualVinDeletesdata}
                            manualVinDeletesPageNo={manualVinDeletesPageNo}
                            userMessage={userMessage}
                           />: ''}
                           
                    </TabPanel>
                ))}
                </div>
                </div>
    )
}
export default PlanAssumptionsTabPanel;