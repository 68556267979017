import { getExpirationTime, getToken, getUser } from "../config";
import { SESSION_TIME_OUT_LIMIT_IN_MINUTES } from "../Constants";


export async function getAccessTokenAfterAuth(){
    const accessToken = getToken();
    // console.log("accessToken==",accessToken);
    return accessToken;
}

export async function getAccessTokenNUserDetailsAfterAuth(){
    const accessToken = getToken();
    const user=getUser();

    // console.log("accessToken==",accessToken);
    // console.log("user==",user);


    let userDetailsWithToken={
        accessToken: accessToken,
        name: user.profile.name,
        email: user.profile.upn,
        preferred_username: user.userName
    };
    // console.log("userDetailsWithAccessToken==",userDetailsWithToken);

    return userDetailsWithToken;
}

//This function will add zero to minutes/seconds if either of them is less than 10 
function timeFormatter(val){
   return val<10?`0${val}`:val;
}

export function getTimeLeft(moment){

    const expirationTimeInSeconds=getExpirationTime();
    if(expirationTimeInSeconds===0){
        return {minutes:0, seconds:0};
    }
    const timeDiff = moment.duration(moment.unix(expirationTimeInSeconds).diff(moment())); 
    //console.log(Math.trunc(timeDiff.asMinutes())-5,Math.trunc(timeDiff.asSeconds())%60);
    // let epocTimeNow=Math.floor(new Date().getTime()/1000.0);
    // let minutesWithFraction=((expirationTimeInSeconds-epocTimeNow)/60)-SESSION_TIME_OUT_LIMIT_IN_MINUTES;//Minus 5 done so that it does not take the last 5 minutes in consideration

    // let minutes=Math.trunc(minutesWithFraction);
    // let seconds=Math.trunc((minutesWithFraction-minutes)*60);
    
    // // console.log(`Epoc time diff in minutes & seconds==${minutes}:${seconds}`);
    // let minutesStr=minutes<10?`0${minutes}`:minutes;
    // let secondsStr=seconds<10?`0${seconds}`:seconds;
    return {minutes:timeFormatter(Math.trunc(timeDiff.asMinutes())-5), seconds:timeFormatter(Math.trunc(timeDiff.asSeconds())%60)};
}
