import {
    FETCH_ALLOCATED_DATA_PENDING, FETCH_ALLOCATED_DATA_SUCCESS, FETCH_ALLOCATED_DATA_ERROR,
    FETCH_ALLOCATED_DROPDOWNVALUES_PENDING, FETCH_ALLOCATED_DROPDOWNVALUES_SUCCESS, FETCH_ALLOCATED_DROPDOWNVALUES_ERROR, SAVE_ALLOCATED_TRANSACTION_PENDING, SAVE_ALLOCATED_TRANSACTION_SUCCESS, SAVE_ALLOCATED_TRANSACTION_ERROR, UPLOAD_ALLOCATED_SUCCESS, UPLOAD_ALLOCATED_PENDING, UPLOAD_ALLOCATED_ERROR,
    SET_ALLOCATED_FILTER_PARAMS,FETCH_ALLOCATED_FILTER_DATA_PENDING,FETCH_ALLOCATED_FILTER_DATA_SUCCESS,FETCH_ALLOCATED_FILTER_DATA_ERROR,RESET_TO_ALLOCATED_INITIALSTATE
} from './Allocated.ActionTypes';
import { getAllAllocatedData, saveAllocatedTransactions, fetchAllocatedDropdownService, uploadAllocatedCSV } from '../../services/allocated/AllocatedService';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchAllocatedData = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_ALLOCATED_DATA_PENDING))
            let response = await getAllAllocatedData(planid);
            if (response) {
                dispatch(serviceActionSuccess(FETCH_ALLOCATED_DATA_SUCCESS, response));
            } else {
                dispatch(serviceActionError(FETCH_ALLOCATED_DATA_ERROR, "Unknown error"));
            }
        }
        catch (e) {
            console.error(e)
            dispatch(serviceActionError(FETCH_ALLOCATED_DATA_ERROR, "Unknown error"));
        }
    }
}

export const fetchAllocatedDropdownValues = (data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_ALLOCATED_DROPDOWNVALUES_PENDING));
        let allocatedData = await fetchAllocatedDropdownService();
        if (allocatedData) {
            dispatch(serviceActionSuccess(FETCH_ALLOCATED_DROPDOWNVALUES_SUCCESS, allocatedData));
                         //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
                         if(validateTransactionsIfMasterDataChanged){
                            if(isNewArchitecture){ // this if is for new architecture
                                dispatch(newValidateGrid("allocatedGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                            }
                            else{ // this else is for old architecture and will be removed later
                            dispatch(validateGrid("allocatedgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                            }
                        }
                        dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_ALLOCATED_DROPDOWNVALUES_ERROR, allocatedData));
        }
    }
}

export const uploadAllocatedData = (planId, files) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_ALLOCATED_PENDING));
            let uploadResp = await uploadAllocatedCSV(planId, files);
            let allocatedData = await getAllAllocatedData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_ALLOCATED_SUCCESS, allocatedData));
            }
            else{
                dispatch(serviceActionError(UPLOAD_ALLOCATED_ERROR, "allocated upload failed"));
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_ALLOCATED_ERROR, "allocated upload failed"));
        }
    }
}


export const saveAllocatedTransactionData = (planid, transactionList) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_ALLOCATED_TRANSACTION_PENDING));
            await saveAllocatedTransactions(planid, transactionList);
            let allocatedData = await getAllAllocatedData(planid);
            if (allocatedData) {
                dispatch(serviceActionSuccess(SAVE_ALLOCATED_TRANSACTION_SUCCESS, allocatedData));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_ALLOCATED_TRANSACTION_ERROR, "Unknown error"));
        }
    }

}

export const fetchFilterData = (selectedPlanId) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_ALLOCATED_FILTER_DATA_PENDING));
            let allocatedData = await getAllAllocatedData(selectedPlanId);
            if (allocatedData) {
                dispatch(serviceActionSuccess(FETCH_ALLOCATED_FILTER_DATA_SUCCESS,allocatedData));
            } 
        } catch (_) {
            dispatch(serviceActionError(FETCH_ALLOCATED_FILTER_DATA_ERROR, "Unknown error"));
        }
    }
}

export const setFilterParameters = (filterParams) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ALLOCATED_FILTER_PARAMS,filterParams))
    }
}
export const resetAllocated = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_ALLOCATED_INITIALSTATE))
    }
  }

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}