import { MileageTableColumns, cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter,valueFormatter,localeFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    }, 
    {
        field: MileageTableColumns.Zone.field, headerName: MileageTableColumns.Zone.headerName,  
        minWidth: 120, cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true, filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MileageTableColumns.Zone.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MileageTableColumns.Zone.name)
        }
    },
    {
        field: MileageTableColumns.CarGroup.field, headerName: MileageTableColumns.CarGroup.headerName, 
        cellEditor: cellEditors.autoCompleteEditor,
        unSortIcon: true, filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MileageTableColumns.CarGroup.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, MileageTableColumns.CarGroup.name)
        }
    },
    {
        field: MileageTableColumns.ModelYear.field, headerName: MileageTableColumns.ModelYear.headerName, 
        minWidth: 120, unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MileageTableColumns.ModelYear.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MileageTableColumns.ModelYear.name)
        }
    },
    {
        field: MileageTableColumns.MileageValue.field, headerName: MileageTableColumns.MileageValue.headerName, 
        minWidth: 120, unSortIcon: true, filterType:"input",
        cellEditor: cellEditors.numericEditor, 
        type: 'numericColumn',
        valueFormatter: params => {
                return localeFormatter(params.data.MILEAGE_VALUE);
        },
        cellClassRules: cellClassRules(MileageTableColumns.MileageValue.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MileageTableColumns.MileageValue.name)
        }
    },
    {
        field: MileageTableColumns.UpdatedBy.field, 
        headerName: MileageTableColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(MileageTableColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, MileageTableColumns.UpdatedBy.name)
        }
    },
    {
        field: MileageTableColumns.UpdatedOn.field, 
        headerName: MileageTableColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(MileageTableColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, MileageTableColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "MileageTableActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field]) {
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
