import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getSalvageRateData(planId,pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisesalvageRateResponse = await client.apis.plans.getAllSalvageRate({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });

        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const responsesArray = await Promise.all([promisesalvageRateResponse])
            const [salvageRateResponse] = responsesArray.map(o => o.body.data);
            return ({
                "gridData": salvageRateResponse.rows, "rowcount": salvageRateResponse.rowCount
            })
        }
        else {
            //TODO XM-385 avoid calling web service and pull the data from cache/local storage 
            const promiseZoneResponse = checkAndLoadDimention("zone", client);
            // const zones = localStorage.getItem("ZONES");

            const responsesArray = await Promise.all([promisesalvageRateResponse, promiseZoneResponse]);

            const [salvageRateResponse, zones] = responsesArray.map(o => o.body.data);
            return ({
                "salvageRateData": salvageRateResponse, "zones": zones
            });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Salvage Rates tab'
    }
}

export  async function uploadSalvageRateCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadSalvageRate({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Salvage Rates tab, Please try again!!');
        //return {'Error':'uploadSalvageRateCSV failed'}
    }
}

export async function saveSalvageRateTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactsalvagerate({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchSalvageRateDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

 //   const SalvageRateResponseData =  client.apis.plans.getAllSalvageRate({ "planid": planId ? planId : 'master' });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseBuResponse =  checkAndLoadDimention("BU", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
      //  SalvageRateResponseData,
        promiseZoneResponse,
        promiseBuResponse
    ]);

    const [
        //  salvageRateResponse,
          ZoneResponse,
          buResponse
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "salvageRateData":salvageRateResponse,
        "zone": ZoneResponse,
        "bu": buResponse,
        "users":promiseUserManagementEmailAndIdMap
    })  
}

export async function getSalvageRateFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseSalvageRateFilterResponse = await client.apis.plans.getSalvageRateFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseSalvageRateFilterResponse]);
        const [salvageRateResponse] = responsesArray.map(o => o.body.data);

        return({
            "gridData": salvageRateResponse.rows,"rowcount":salvageRateResponse.rows[0]?salvageRateResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'Salvage Rates tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllSalvageRateData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const salvageRateDeleteResponse = await client.apis.plans.deleteAllSalvageRateRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });
        if(salvageRateDeleteResponse.obj && salvageRateDeleteResponse.obj.statusCode !== '500'){
            return salvageRateDeleteResponse;
        }
        else{
            throw Error();
        }  
    }
    catch (error) {
        console.error(error);
        throw 'Salvage Rates tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}

export async function insertS3UploadSalvageRateCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedSalvageRateData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Salvage Rates tab, Please try again!!');
    }
}

export async function salvagerateTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateSalvageRate({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Salvage Rates Validation status ,Please try again!!';
    }
}

export async function getSalvageRateTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseSalvageRateResponse = await client.apis.plans.getSalvageRateTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseSalvageRateResponse.obj && promiseSalvageRateResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseSalvageRateResponse]);
            const [salvagerateTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return salvagerateTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Salvage Rates error status ,Please try again!!';
    }
}

export async function getSalvageRateErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisesalvagerateResponse = await client.apis.plans.getSalvageRateErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promisesalvagerateResponse]);
        const [salvagerateResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": salvagerateResponse.rows, "rowcount": salvagerateResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Salvage Rates tab'
    }
}

export async function getSalvageRateSearchData(planid, pageno, searchParams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseSalvageRateSearchResponse = await client.apis.plans.getSalvageRateSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseSalvageRateSearchResponse]);

        const [salvagerateSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": salvagerateSearchResponse.rows, "rowcount": salvagerateSearchResponse.rows[0] ? salvagerateSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Salvage Rates tab'
    }
}
