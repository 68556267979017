import { columnDefs, defaultColDef } from "./bvdepoverride_grid_data/BvDepOverrideColumnDefs";
import { SET_BVEPOVERRIDE_EDITABLE } from '../bvdepoverride/BvDepOverride.ActionTypes';
import { isStringAlphaNumeric, safeCaseInsensitiveCompare } from "../../../utils/utils";
import moment from "moment";
import { SalesandFinanceColumns } from "../../../../src/Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            VIN: { error: checkIfValueEmpty(data.VIN, SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.VIN.headerName) },
            NEW_DEP_AMT: { error: checkIfValueEmpty(data.NEW_DEP_AMT, SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.NEWDDEPAMT.headerName) },
            STARTING_BV: { error: checkIfValueEmpty(data.STARTING_BV, SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.STARTINGBV.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
        return null;
    }
}

function checkIfValueEmpty(value,fieldName) {
    if (value === undefined || value === "" || value === null) {
        return fieldName +" "+"cannot be empty";
    }
    else {
    if(fieldName === SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.VIN.headerName){
        let result = isStringAlphaNumeric(value)
         if(!result){
          return fieldName + " should be alphanumeric";
        }
        else if(value.length!=17){
          return fieldName + " length should be equal to 17"; 
        } 
          else{
          return null;
        }
      }else if(fieldName === SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.STARTINGBV.headerName ){ //decimal value validation is only for default numeric parameters,as per hertz team's suggestion.
        const numberAsString = value.toString();
        // check if String Contains Decimal
        if (numberAsString.includes('.')) {
            // using regex to remove if there are any signs like + or - to count the length
            if ((numberAsString.split('.')[1].replace(/[^\d]/g, '')).length>2 || (numberAsString.split('.')[1].replace(/[^\d]/g, '')).length<1 ||(numberAsString.split('.')[0].replace(/[^\d]/g, '')).length<1 || (numberAsString.split('.')[0].replace(/[^\d]/g, '')).length>6){
                return "The value can be 1 to 6 digits before decimal and 1 to 2 digits after"; 
            }else {
                return null
            };
        }
        else{
            return null;
        }
    }
    }
    return null;
}


function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.VIN,itm2.VIN)) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

export default function bvDepOverrideColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case SET_BVEPOVERRIDE_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}