import {FETCH_TARGETZONEPOOL_DATA_PENDING, FETCH_TARGETZONEPOOL_DATA_SUCCESS, FETCH_TARGETZONEPOOL_DATA_ERROR, UPLOAD_TARGETZONEPOOL_PENDING, UPLOAD_TARGETZONEPOOL_SUCCESS, UPLOAD_TARGETZONEPOOL_ERROR,
    SAVE_TARGETZONEPOOL_TRANSACTION_PENDING, SAVE_TARGETZONEPOOL_TRANSACTION_SUCCESS, SAVE_TARGETZONEPOOL_TRANSACTION_ERROR,RESET_TO_TARGETZONEPOOL_INITIALSTATE
    } from '../../store/targetzonepool/TargetZonePool.ActionTypes';

const initialState = {
    targetZonePoolData:[],
    pending:false,
    error:null,
    uploadTargetZonePoolSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(targetZonePoolData, zones) {
    targetZonePoolData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
        if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
            value['ZONE_NAME'] = "";
        }
    })
    return targetZonePoolData;
}

export default function TargetZonePoolReducer (state=initialState,action){
    switch(action.type){
        case FETCH_TARGETZONEPOOL_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_TARGETZONEPOOL_DATA_SUCCESS:
            return Object.assign({},state,{
                targetZonePoolData: fetchZones(action.data.targetZonePoolData, action.data.zones),
                uploadTargetZonePoolSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_TARGETZONEPOOL_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_TARGETZONEPOOL_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadTargetZonePoolSuccess: null
            });
        case UPLOAD_TARGETZONEPOOL_SUCCESS:
            return Object.assign({}, state, {
                targetZonePoolData: fetchZones(action.data.targetZonePoolData, action.data.zones),
                pending: false,
                error: null,
                uploadTargetZonePoolSuccess: true
            });
        case UPLOAD_TARGETZONEPOOL_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadTargetZonePoolSuccess: false
            });
        case SAVE_TARGETZONEPOOL_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_TARGETZONEPOOL_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                targetZonePoolData: fetchZones(action.data.targetZonePoolData, action.data.zones),
                transactionSaveSuccess: true
            });
        case SAVE_TARGETZONEPOOL_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_TARGETZONEPOOL_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}