import moment from "moment";

export const valueFormatter = (value) => {
    if (value !== undefined && value !== '-' && value !== '' && value !== null) {
        var formatted = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${formatted}`;
    } else
        return value;
}

///
//dateFormatter expects UTC date and formats it back to MMM-YY format
///
//This function is used for getting only MMM-YY
export const dateFormatter = (date) => {
    if (date !== undefined && date !== '' && date !== '-' && date !== null) {
        var selectedDate = moment(new Date(date)).utc().format("MMM-YY"); //date being passed is in UTC and should be returned back in UTC
        return selectedDate;
    }
    return date;
}
//This function is used for getting only 1-MMM-YY
export const formatDateToOne = (date) => {
    // console.log("date in formatDateToOne===>",date);
    if (date !== undefined && date !== '' && date !== '-' && date !== null) {
        var selectedDate = moment(new Date(date)).utc().format("1-MMM-YY"); //date being passed is in UTC and should be returned back in UTC
        return selectedDate;
    }
    return date;
}
//This function is used for getting only DD-MMM-YY
export const formatDate = (date) => {
    if (date !== undefined && date !== '' && date !== '-' && date !== null) {
        var selectedDate = moment(new Date(date)).utc().format("DD-MMM-YY"); //date being passed is in UTC and should be returned back in UTC
        return selectedDate;
    }
    return date;
}

//This function is used for getting only DD-MMM-YYYY
export const formatDatetoYear = (date) => {
    if (date !== undefined && date !== '' && date !== '-' && date !== null) {
        var selectedDate = moment(new Date(date)).utc().format("DD-MMM-YY"); //date being passed is in UTC and should be returned back in UTC
        return selectedDate;
    }
    return date;
}

export const plansDateFormatter = (date) => {
    if (date !== undefined && date !== '' && date !== '-' && date !== null) {
        var selectedDate = moment(date).format("MM/DD/YY hh:mm A")
        return selectedDate;
    }
    return date;
}

//This function is used for getting only DD-MMM-YY HH:MM:SS
export const updatedOnDateFormatter = (date) => {
    if (date !== undefined && date !== '' && date !== '-' && date !== null) {
        var selectedDate = moment(date).format("DD-MMM-YY HH:mm:ss")
        return selectedDate;
    }
    return date;
}

export const localeFormatter = (value) => {
    if (value !== undefined && value !== '-' && value !== '' && value !== null)
        return value.toLocaleString()
    
    return "";
}

export const assignSign = (value) => {
    if (value !== undefined && value !== '-' && value !== '' && value !== null) {
        let localeValue;
        if(value > 0) 
            localeValue = "+"+Math.round(value).toLocaleString();
        else         
            localeValue = Math.round(value).toLocaleString();               
        return localeValue;
    }
    else 
        return value;
}

export const localeFormatAndRounding = (value) => {
    if (value !== undefined && value !== '-' && value !== '' && value !== null) {
        let localeValue;
        localeValue =  Math.round(value).toLocaleString()
        if(localeValue == -0) 
            localeValue = 0;
        return localeValue;
    }
    else
        return '';
}


// export const dateFormatterConstraints = (date) => {
//     debugger;
//     if (date !== undefined && date !== '' && date !== '-') {
//         // var selectedDate = moment(date).format("MMM-YY")
//         var selectedDate = moment(new Date(date)).utcOffset('+05:30').format('MMM-YY');
//         return selectedDate;
//     }
//     return date;
// }

export const currencyFormatter = (currency, sign) => {
    if (currency && currency !== '-' && currency !== '') {
        var formatted = currency.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return sign + `${formatted}`;
    } else
        return currency;
}

export const percentageFormatter = (currency, sign) => {
    if (currency && currency !== '-' && currency !== '') {
            return `${currency}` + sign;
        }
        else 
            return currency;
 }

export const statusFormatter = (status)=>{
 if (status !== undefined && status !== '-' && status !== '' && status !== null){
    if(status.toLowerCase()==='prep_error'){
        return 'Preparation Error'
    }else if(status.toLowerCase()==='building model' || status.toLowerCase()==='optimization queued' || status.toLowerCase()==='optimizing' || status.toLowerCase()==='pulling data'){
        return 'Running';
    }else if(status.toLowerCase()==='optimization complete'){
        return 'Completed';
    }else if(status.toLowerCase()==='error'){
        return "Failed";
    }else if(status.toLowerCase()==='apply adjustments'){
        return "Adjustment in Process";
    }else if(status.toLowerCase()==='sales adjustments ready'){
        return "Sales Adjustments Ready";
    }else if(status.toLowerCase()==='vin dep process ready'){
        return "VIN Dep Process Ready";
    }else if(status.toLowerCase()==='sales adjustments running'){
        return "Running";
    }else if(status.toLowerCase()==='vin dep process running'){
        return "Running";
    }else if(status.toLowerCase()==='sales adjustments completed'){
        return "Sales Adjustments Completed";
    }else if(status.toLowerCase()==='vin dep process completed'){
        return "VIN Dep Process Completed";
    }else if(status.toLowerCase()==='optimization complete as sub-optim'){
        return "Completed - Sub Optimal";
    }else if(status.toLowerCase()==='optimization complete as optimal'){
        return "Completed - Optimal";
    }else if(status.toLowerCase()==='optimization complete unknown status'){
        return "Completed - Unknown";
    }else if(status.toLowerCase()==='adjustments modified'){
        return "Adjustments Modified";
    }else if(status.toLowerCase() === "adjustment complete"){
        return "Adjustment Complete";
    }else if(status.toLowerCase() === "published"){
        return "Published";
    }else if(status.toLowerCase() === "editing adjustments"){
        return "Editing Adjustments";
    }

    return status;
 }
}