import {getCarClassGroupData, saveCarClassGroupTransactions, fetchCarClassGroupDropdownService, getCarClassGroupFilteredData, getCarClassGroupSearchData} from '../../../services/admin/metadata_carclassgroup/CarClassGroupService';
import { saveCarGroupTransactions } from '../../../services/admin/metadata_cargroup/CarGroupService';
import {FETCH_CARCLASSGROUP_DATA_PENDING, FETCH_CARCLASSGROUP_DATA_SUCCESS, FETCH_CARCLASSGROUP_DATA_ERROR, 
    SAVE_CARCLASSGROUP_TRANSACTION_PENDING, SAVE_CARCLASSGROUP_TRANSACTION_SUCCESS, SAVE_CARCLASSGROUP_TRANSACTION_ERROR,
    FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING,FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS,FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR, RESET_TO_CARCLASSGROUP_INITIALSTATE, SAVE_CARGROUP_TRANSACTION_PENDING, SAVE_CARGROUP_TRANSACTION_SUCCESS, SAVE_CARGROUP_TRANSACTION_ERROR, SET_ADMIN_CARCLASSGROUP_FILTER_POPUP_OPEN, SET_ADMIN_CARCLASSGROUP_FILTER_PARAMS, SET_ADMIN_CARCLASSGROUP_FILTER_CLEAR, SET_ADMIN_CARCLASSGROUP_FILTER_APPLY, SET_ADMIN_CARCLASSGROUP_FILTER_VIEW, SET_ADMIN_CARCLASSGROUP_SEARCH_VIEW, SET_ADMIN_CARCLASSGROUP_SEARCH_TEXT, SET_ADMIN_CARCLASSGROUP_DELETE_FILTER} from '../metadata_carclassgroup/CarClassGroup.ActionTypes';


export const fetchCarClassGroupData = (filterParams,deleteMode) =>{
    // console.log("Inside fetchCarClassGroupData...");
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_CARCLASSGROUP_DATA_PENDING));    
            let response = await getCarClassGroupFilteredData(filterParams,deleteMode);
            if(response){
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DATA_SUCCESS,response));
            }else{
                dispatch(serviceActionError(FETCH_CARCLASSGROUP_DATA_ERROR,"Unknown error"));
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_CARCLASSGROUP_DATA_ERROR,"Unknown error"));
        }
    }

}


export const saveCarClassGroupTransactionData = (transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_CARCLASSGROUP_TRANSACTION_PENDING));
           
            await saveCarClassGroupTransactions(transactionList);
            
            dispatch(serviceActionPending(FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING));
            let response = await getCarClassGroupData();
            let carClassGroupData = await fetchCarClassGroupDropdownService(response.carClassGroupData);
            if(response && carClassGroupData){
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DATA_SUCCESS,response));
                dispatch(serviceActionSuccess(SAVE_CARCLASSGROUP_TRANSACTION_SUCCESS, response));             
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS, carClassGroupData));
            }else{
                dispatch(serviceActionError(FETCH_CARCLASSGROUP_DATA_ERROR,"Unknown error"));
                dispatch(serviceActionError(FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR, carClassGroupData));
            }        
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_CARCLASSGROUP_TRANSACTION_ERROR, e.message));

        }
    }
}

export const saveCarGroupTransactionsData = (transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_CARGROUP_TRANSACTION_PENDING));

            await saveCarGroupTransactions(transactionList);

            dispatch(serviceActionPending(FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING));

            let response = await getCarClassGroupData();
            let carGroupData = await fetchCarClassGroupDropdownService(response.carClassGroupData);
            if(response && carGroupData){
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DATA_SUCCESS,response));
                dispatch(serviceActionSuccess(SAVE_CARGROUP_TRANSACTION_SUCCESS));
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS, carGroupData));
            }else{
                dispatch(serviceActionError(FETCH_CARCLASSGROUP_DATA_ERROR,"Unknown error"));
                dispatch(serviceActionError(FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR, carGroupData));
            }        
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_CARGROUP_TRANSACTION_ERROR, e.message));

        }
    }
}
export const fetchCarClassGroupDropdownValues = () => {
    // console.log("Inside resetCarClassGroup...");
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING));
        let carGroupData = await fetchCarClassGroupDropdownService();
        if (carGroupData) {
            dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS, carGroupData));
        } else {
            dispatch(serviceActionError(FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR, carGroupData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetCarClassGroup = ()=>{
    // console.log("Inside resetCarClassGroup...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_CARCLASSGROUP_INITIALSTATE))
    }
}

export const openCarClassGroupFilterPopup = ()=>{
    // console.log("Inside openCarClassGroupFilterPopup...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_FILTER_POPUP_OPEN,true));
    }
}

export const closeCarClassGroupFilterPopup = ()=>{
    // console.log("Inside closeCarClassGroupFilterPopup...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_FILTER_POPUP_OPEN,false));
    }
}

export const setCarClassGroupFilterParams = (filterParams)=>{
    // console.log("Inside setCarClassGroupFilterParams...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_FILTER_PARAMS,filterParams));
    }
}

export const clearCarClassGroupFilter = (filterParams,deleteFilter)=>{
    // console.log("Inside clearCarClassGroupFilter... ", filterParams);
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_FILTER_CLEAR,filterParams));
    }
}

export const applyCarClassGroupFilter = (filterParams,deleteMode)=>{
    // console.log("Inside applyCarClassGroupFilter...");
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_SEARCH_TEXT,''));
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_FILTER_VIEW,true));
    }
    
}

export const clearCarClassGroupSearchView=()=>{
    // console.log("Inside clearCarClassGroupSearchView...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_SEARCH_VIEW,false));
    }
}

export const applyCarClassGroupSearchView=(searchText,deleteMode)=>{
    // console.log("Inside applyCarClassGroupSearchView...");
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_SEARCH_VIEW,true));
    }
}

export const fetchCarClassGroupDataSearchData=(searchText,deleteMode)=>{
    // console.log("Inside fetchCarClassGroupDataSearchData...");
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_CARCLASSGROUP_DATA_PENDING));    
            let response = await getCarClassGroupSearchData(searchText,deleteMode);
            // console.log("applyCarClassGroupSearchView response ===>", response);
            if(response){
                dispatch(serviceActionSuccess(FETCH_CARCLASSGROUP_DATA_SUCCESS,response));
            }else{
                dispatch(serviceActionError(FETCH_CARCLASSGROUP_DATA_ERROR,"Unknown error"));
            }
        } 
        catch(e){
            console.error("ERROR : ",e.message);
            console.error(e);
            dispatch(serviceActionError(FETCH_CARCLASSGROUP_DATA_ERROR,e.message));
        }
    }
}

export const setCarClassGroupSearchText=(searchText)=>{
    // console.log("Inside setCarClassGroupSearchText...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_SEARCH_TEXT,searchText));
    }
}

export const changeDeleteFilterMode=(mode)=>{
    // console.log("Inside changeDeleteFilterMode...");
    if(mode===undefined){
        return;
    }
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_CARCLASSGROUP_DELETE_FILTER,mode));
    }
}

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}