import {FETCH_ADDSINVALIDPOOLS_DATA_PENDING, FETCH_ADDSINVALIDPOOLS_DATA_SUCCESS, FETCH_ADDSINVALIDPOOLS_DATA_ERROR, UPLOAD_ADDSINVALIDPOOLS_PENDING, UPLOAD_ADDSINVALIDPOOLS_SUCCESS, UPLOAD_ADDSINVALIDPOOLS_ERROR,
    SAVE_ADDSINVALIDPOOLS_TRANSACTION_PENDING, SAVE_ADDSINVALIDPOOLS_TRANSACTION_SUCCESS, SAVE_ADDSINVALIDPOOLS_TRANSACTION_ERROR,RESET_TO_ADDSINVALIDPOOLS_INITIALSTATE
    } from '../addsinvalidpools/AddsInvalidPools.ActionTypes';

const initialState = {
    addsInvalidPoolsData:[],
    pending:false,
    error:null,
    uploadAddsInvalidPoolsSuccess:null,
    transactionSaveSuccess:null
}

export default function AddsInvalidPoolsReducer (state=initialState,action){
    switch(action.type){
        case FETCH_ADDSINVALIDPOOLS_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_ADDSINVALIDPOOLS_DATA_SUCCESS:
            return Object.assign({},state,{
                addsInvalidPoolsData: action.data.addsInvalidPoolsData,
                uploadAddsInvalidPoolsSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_ADDSINVALIDPOOLS_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_ADDSINVALIDPOOLS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadAddsInvalidPoolsSuccess: null
            });
        case UPLOAD_ADDSINVALIDPOOLS_SUCCESS:
            return Object.assign({}, state, {
                addsInvalidPoolsData:action.data.addsInvalidPoolsData,
                pending: false,
                error: null,
                uploadAddsInvalidPoolsSuccess: true
            });
        case UPLOAD_ADDSINVALIDPOOLS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadAddsInvalidPoolsSuccess: false
            });
        case SAVE_ADDSINVALIDPOOLS_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_ADDSINVALIDPOOLS_TRANSACTION_SUCCESS:
            return Object.assign({}, state,{
                pending: false,
                addsInvalidPoolsData:action.data.addsInvalidPoolsData,
                transactionSaveSuccess: true
            });
        case SAVE_ADDSINVALIDPOOLS_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_ADDSINVALIDPOOLS_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}
