export const RF_EDIT_ROW = 'RF_EDIT_ROW';
export const RF_ADD_ROW = 'RF_ADD_ROW';
export const RF_COPY_ROW = 'RF_COPY_ROW';
export const RF_DELETE_ROW = 'RF_DELETE_ROW';
export const RF_DELETE_ROW_EDIT = 'RF_DELETE_ROW_EDIT';
export const RF_REVERT_ROW = 'RF_REVERT_ROW';
export const RF_POPULATE_GRID = 'RF_POPULATE_GRID';
export const RF_VALIDATE_ROW = 'RF_VALIDATE_ROW';
export const RF_REFRESH_GRID = 'RF_REFRESH_GRID';
export const RF_VALIDATE_GRID = 'RF_VALIDATE_GRID';
export const RF_CLEAN_TRASACTIONS = 'RF_CLEAN_TRASACTIONS';
export const RF_POPULATE_GRID2 = 'RF_POPULATE_GRID2';
export const RESET_GRID_STATE = 'RESET_GRID_STATE'
export const RF_EDIT_ROW_MULTIPLE='RF_EDIT_ROW_MULTIPLE';
export const RF_UPDATEGRID_ONVALIDATION = "RF_UPDATEGRID_ONVALIDATION";

export const validateRow = (gridname, data, businessValidationFn)=>{
    return {
        name:gridname,
        type:RF_VALIDATE_ROW,
        data:{
            data,
            businessValidationFn
        }
    }
}

export const validateGrid = (gridname, data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:RF_VALIDATE_GRID,
        data:{
            data,
            validationFn,
            businessValidationFn
        }
    }
}

export const populateGrid = (gridname, data, filterSearchKey, checkType, checkValue, checkIfRequiredFn)=>{
    return {
        name:gridname,
        type:RF_POPULATE_GRID,
        data:data,
        filterSearchKey:filterSearchKey,
        checkType:checkType,
        checkValue:checkValue,
        checkIfRequiredFn:checkIfRequiredFn,
    }
}

export const populateGrid2 = (gridname, originalDta,currentData,filterSearchKey,checkValue,validationFn, businessValidationFn,uploadState)=>{
    return {
        name:gridname,
        type:RF_POPULATE_GRID2,
        originalData:originalDta,
        currentData:currentData,
        filterSearchKey:filterSearchKey,
        checkValue:checkValue,
        data:{
            validationFn,
            businessValidationFn
        },
        uploadState:uploadState
        }
}

export const completeEditRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:RF_EDIT_ROW,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}
export const completeEditMultipleRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:RF_EDIT_ROW_MULTIPLE,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}

export const addNewRow = (gridname,rowindex,data)=>{
    return {
        name:gridname,
        type:RF_ADD_ROW,
        data:{rowindex,data}
    }
}

export const copyNewRow = (gridname,rowindex,data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:RF_COPY_ROW,
        data:{rowindex,data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}


export const deleteRow = (gridname,id)=>{
    return {
        name:gridname,
        type:RF_DELETE_ROW,
        data:{id}
    }
}

export const deleteRowEditMode = (gridname,id)=>{
    return {
        name:gridname,
        type:RF_DELETE_ROW_EDIT,
        data:{id}
    }
}

export const revertRow = (gridname,rowindex,rowID, data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:RF_REVERT_ROW,
        data:{rowindex,rowID, data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}

export const refreshGridData = (gridName) => {
    return {
        name: gridName,
        type: RF_REFRESH_GRID
    }
}

export const cleanTransactions = (gridName)=>{
    return {
        name: gridName,
        type: RF_CLEAN_TRASACTIONS
    }
}

export const ResetRFMultiGridState = ()=>{
    return {
        type: RESET_GRID_STATE
    }
}