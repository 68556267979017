import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getMileageDefaultData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisemileageDefaultResponse = await client.apis.plans.getAllMileageDefault({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });

        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const responsesArray = await Promise.all([promisemileageDefaultResponse]);
            const [mileageDefaultResponse] = responsesArray.map(o => o.body.data);

            return ({
                "gridData": mileageDefaultResponse.rows, "rowcount": mileageDefaultResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
            const promiseZoneResponse = checkAndLoadDimention("zone", client);
            const responsesArray = await Promise.all([promisemileageDefaultResponse, promiseZoneResponse]);
            const [mileageDefaultResponse, zoneResponse] = responsesArray.map(o => o.body.data);

            return ({
                "mileageDefaultData": mileageDefaultResponse, "zones": zoneResponse
            });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for  Monthly Mileage Defaults tab'
    }
}

export  async function uploadMileageDefaultCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadMileageDefault({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Monthly Mileage Defaults tab, Please try again!!');
    }
}

export async function saveMileageDefaultTransactions(planid, transactionList){

    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactmileagedefault({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchMileageDefaultDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const mileageDefaultResponseData =  client.apis.plans.getAllMileageDefault({ "planid": planId ? planId : 'master' });
    const promiseCarClassCodeResponse =  checkAndLoadDimention("carClassCode", client);
    //TODO XM-385 avoid calling web service and pull the data from cache/local storage 
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    // const zones = localStorage.getItem("ZONES");

    const promiseprogramtypeResponse = await checkAndLoadDimention("programType", client);
    const promiseGetAdminCarClasses =  checkAndLoadDimention("admincarclassAndgroup", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
        //  mileageDefaultResponseData,
          promiseCarClassCodeResponse, 
          promiseprogramtypeResponse,
          promiseZoneResponse,
          promiseGetAdminCarClasses
    ]);

    const [
         // mileageDefaultResponse,
          carClassCodeResponse,
          programtypeResponse ,
          zones,
          adminCarClass     
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "mileageDefaultData":mileageDefaultResponse,
        "car_class": carClassCodeResponse,
        "zones": zones,
        "program_type": programtypeResponse,
        "adminCarClass":adminCarClass,
        "users":promiseUserManagementEmailAndIdMap
    })  
}

export async function getMileageDefaultFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseMileageDefaultFilterResponse = await client.apis.plans.getMileageDefaultFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseMileageDefaultFilterResponse]);
        const [mileagedefaultResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": mileagedefaultResponse.rows, "rowcount": mileagedefaultResponse.rows[0] ? mileagedefaultResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'Monthly Mileage Defaults tab has internal server error, please do re-apply the filter'

    }
}

export async function deleteAllMileageDefaultData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promisemileagedefaultDeleteResponse = await client.apis.plans.deleteAllMileageDefaultRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });

        if (promisemileagedefaultDeleteResponse.obj && promisemileagedefaultDeleteResponse.obj.statusCode !== '500') {
            return promisemileagedefaultDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Monthly Mileage Defaults tab has internal server error, Unable to delete all the records!! Please try again'
    }
}

export async function insertS3UploadMileageDefaultCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.insertS3UploadedMileageDefaultData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi, ''),
                "fileName": filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Monthly Mileage Defaults tab, Please try again!!');
    }
}

export async function mileagedefaultTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateMileageDefault({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Monthly Mileage Defaults Validation status ,Please try again!!';

    }
}

export async function getMileageDefaultTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseMileageDefaultResponse = await client.apis.plans.getMileageDefaultTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseMileageDefaultResponse.obj && promiseMileageDefaultResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseMileageDefaultResponse]);
            const [mileagedefaultTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return mileagedefaultTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Monthly Mileage Defaults error status ,Please try again!!';

    }
}

export async function getMileageDefaultErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisemileagedefaultResponse = await client.apis.plans.getMileageDefaultErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promisemileagedefaultResponse]);
        const [mileagedefaultResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": mileagedefaultResponse.rows, "rowcount": mileagedefaultResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Monthly Mileage Defaults tab'

    }
}

export async function getMileageDefaultSearchData(planid, pageno, searchParams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseMileageDefaultSearchResponse = await client.apis.plans.getMileageDefaultSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseMileageDefaultSearchResponse]);

        const [mileagedefaultSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": mileagedefaultSearchResponse.rows, "rowcount": mileagedefaultSearchResponse.rows[0] ? mileagedefaultSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Monthly Mileage Defaults tab'
    }
}



