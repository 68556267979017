import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdjustmentsActionBar from '../../../components/adjustments/actionbar/AdjustmentsActionBar';
import CoreBUAccordionContainer from '../../accordionview/CoreBUAccordionContainer';
import TncElectricBUAccordionContainer from '../../accordionview/TncElectricBUAccordionContainer';

const TncElectricTabPanel = (props) => {
    const { accordionData, tabIndex, planId, onViewChanged,handleGridSaveBehavior, showCancelButton, handleCancel,  allZones, planStatus, filterData, filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue} = props;
    let selectedVal = useSelector((state) => state.rootAdjustmentsReducer.adjustmentsReducer.selectedViewType[tabIndex]);
    return (
        <div>
            <AdjustmentsActionBar onViewChanged={onViewChanged} showCancelButton={showCancelButton} handleCancel={handleCancel} selectedVal={selectedVal} filterData={filterData}
            tabIndex={tabIndex} planStatus = {planStatus}/>
            <TncElectricBUAccordionContainer accordionData={accordionData} tabIndex={tabIndex} planStatus={planStatus}
                planId={planId} handleGridSaveBehavior={handleGridSaveBehavior} selectedVal={selectedVal} allZones={allZones} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} 
                filterActivityForOriginalValue={filterActivityForOriginalValue} filterData={filterData}/>
        </div>
    )

}

export default TncElectricTabPanel;