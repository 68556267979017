import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import ActionBar from '../../../components/planassumptionsactionbar/PlanAssumptionsActionbar';
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const AgeMileageTabPanel = (props)=>{
    const{showSaveOption, ageMileageData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        ageMileageGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions, currentData,filteredData, filterParams,tabIndex, downloadCSV,userMessage,newArchitecture,gridName,loadGridData} = props;

        return(
     <div>
        {newArchitecture ? (<MainPlanInputsMultiGridUI 
                reducer={gridReducer}
                gridName={gridName}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={ageMileageGridColDef}
                rowEditType={'single'}
                suppressclickEdit={!ageMileageGridColDef.defaultColDef.editable||pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={true} />) :
         <MultiGridUI 
            reducer={gridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'agemileagegrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={ageMileageGridColDef}
            suppressclickEdit={!ageMileageGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            ageMileageTransactions={ageMileageTransactions}
            userMessage={userMessage}
             />
        }
     </div>
 )
}

export default AgeMileageTabPanel;