import {
    FETCH_GROUP_BY_TYPE_ZONE, FETCH_GROUP_BY_YEAR, FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR, FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING, FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS, ADJUSTMENTS_ROW_ACTION,
    FETCH_PLAN_OPTIMIZATION_DATA_PENDING, FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS, FETCH_PLAN_OPTIMIZATION_DATA_ERROR,SET_DATA_WITH_ADJUSTEMENT_VALUE,UPDATE_ADJUSTMENTS_PENDING, ADJUSTMENTS_SET_LEVEL,
    UPDATE_ADJUSTMENTS_SUCCESS,UPDATE_ADJUSTMENTS_ERROR,FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_PENDING,FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_SUCCESS,FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR,
    FETCH_ADJUSTMENTS_FILTER_DATA, SET_TABALL_FILTER_PARAMS, SET_CORETAB_FILTER_PARAMS, SET_COREICETAB_FILTER_PARAMS,SET_ADJUSTMENTS_EXPANDALL_FLAGS,SET_COREELECTAB_FILTER_PARAMS,SET_TNCTAB_FILTER_PARAMS,SET_TNCICETAB_FILTER_PARAMS,SET_TNCELECTAB_FILTER_PARAMS,
    SET_CARGOCTAB_FILTER_PARAMS,
    SET_BU_GROUP_VIEW_TYPE,
    SET_ACCORDIAN_CLICK
} from "./Adjustments.ActionTypes";
import {FETCH_ALLPLANS_DATA_PENDING, FETCH_ALLPLANS_DATA_SUCCESS, GET_ALLPLANS_TABLE_DATA, FETCH_ALLPLANS_DATA_ERROR, FETCH_PLANBYID_ONREFRESH_SUCCESS} from "../topbar/AllPlans.ActionTypes"
import { fetchPlanOptimizationOutput, updateAdjustmentsService, processAdjustmentsService } from '../../services/output/Service.output';
import {CallGetPlanByIdService, fetchAllPlans} from '../../services/topbar/AllPlansService';
import { fetchParameters } from "../../services/parameters/ParametersService";

// export const fetchOptimizedPlanOutputData = (planId) => {
//     return async dispatch => {
//         try {
//             dispatch(serviceActionPending(FETCH_PLAN_OPTIMIZATION_DATA_PENDING));
//             let planOptimizationData = await fetchPlanOptimizationOutput(planId);
//             let parameters = await fetchParameters(planId);
//             if (planOptimizationData && parameters) {
//                 dispatch(serviceActionSuccess(FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS, {"planOptimizationData":planOptimizationData,"parameters":parameters}));
//             }
//             else {
//                 dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, planOptimizationData));
//             }
//         } catch (_) {
//             dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, "Unknown error"));
//         }
//     }
// }

export const fetchOptimizedPlanOutputData = (planId, parametersData) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PLAN_OPTIMIZATION_DATA_PENDING));
            let planOptimizationData = await fetchPlanOptimizationOutput(planId);
            let parameters = []

            if (parametersData.length > 0) {
                parameters = parametersData;
            } else {
                parameters = await fetchParameters(planId)
            }
            if (planOptimizationData && parameters) {

                dispatch(serviceActionSuccess(FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS, { "planOptimizationData": planOptimizationData, "parameters": parameters }));
            }
            else {
                dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, planOptimizationData));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, "Unknown error"));
        }
    }
}

export const fetchGroupByTypeOrZone = (tabIndex, groupByCategory, sortData) => {
    //TOREVIEW: replace tabSelected should be the BU name instead
    return serviceActionSuccess(FETCH_GROUP_BY_TYPE_ZONE, { "groupByCategory": groupByCategory, "tabSelected": tabIndex, "sortData": sortData });
}

export const fetchGroupByYear = () => {
    return serviceActionSuccess(FETCH_GROUP_BY_YEAR);
}

export const setDataWithAdjustment = (data)=>{
    return serviceActionSuccess(SET_DATA_WITH_ADJUSTEMENT_VALUE,data);
}

export const setAdjustmentAction = (data, type)=>{
    return adjustmentActionSuccess(ADJUSTMENTS_ROW_ACTION, data, type);
}

export const setAdjustmentLevel = (data)=>{
    return adjustmentLevel(ADJUSTMENTS_SET_LEVEL, data);
}

export const updateAdjustments = (planid,transactionList) =>{
    return async dispatch=>{
    try {
        dispatch(serviceActionPending(UPDATE_ADJUSTMENTS_PENDING));
        let response = await updateAdjustmentsService(planid,transactionList);
        if (response) {
            dispatch(serviceActionPending(FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_PENDING));
            let planOptimizationData = await fetchPlanOptimizationOutput(planid);
            if(planOptimizationData){
                dispatch(serviceActionSuccess(FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_SUCCESS, planOptimizationData));
                dispatch(serviceActionSuccess(UPDATE_ADJUSTMENTS_SUCCESS, response));
            }else{
                dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR, "Unknown error"));
                dispatch(serviceActionError(UPDATE_ADJUSTMENTS_ERROR, "Unknown error"));
            }
        }
        else {
            dispatch(serviceActionError(UPDATE_ADJUSTMENTS_ERROR, "Unknown error"));
        }
    } catch (_) {
        dispatch(serviceActionError(UPDATE_ADJUSTMENTS_ERROR, "Unknown error"));
    }
}
}

export const processAdjustmentsRun = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING));
            let adjustmentsRunResponse = await processAdjustmentsService(planid);
            if (adjustmentsRunResponse.resp == 'Success') {
                let planByIdResponse = await CallGetPlanByIdService(planid);
            if (planByIdResponse) {
                dispatch(serviceActionSuccess(FETCH_PLANBYID_ONREFRESH_SUCCESS, planByIdResponse));
            }
                dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
                let allPlansData = await fetchAllPlans()
                if (allPlansData) {
                    dispatch(serviceActionSuccess(FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS, adjustmentsRunResponse));
                    dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                    dispatch(getAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                }
                else {
                    dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
                }
            } else {
                dispatch(serviceActionError(FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR, adjustmentsRunResponse));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR, "Unknown error"));
        }
    }
}

export const loadFilterData = (data) =>{
    return async dispatch =>{
      dispatch(serviceActionSuccess(FETCH_ADJUSTMENTS_FILTER_DATA, data));
    }
}

export const setTabAllFilterParameters = (filterParams,viewType) =>{
    // console.log("Setting all tab filter params...");
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_TABALL_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setCoreTabFilterParameters = (filterParams,viewType)=>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_CORETAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setCoreIceFilterParameters = (filterParams,viewType,tabIndex) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_COREICETAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setCoreElecFilterParameters = (filterParams,viewType,tabIndex) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_COREELECTAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setTncFilterParameters = (filterParams,viewType,tabIndex) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_TNCTAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setTncIceFilterParameters = (filterParams,viewType,tabIndex) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_TNCICETAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setTncElecFilterParameters = (filterParams,viewType,tabIndex) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_TNCELECTAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}
export const setCargoFilterParameters = (filterParams,viewType,tabIndex) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_CARGOCTAB_FILTER_PARAMS,{filterParams:{...filterParams},viewType:viewType}))
    }
}

export const toggleExpandAll = (tabIndex,viewType) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ADJUSTMENTS_EXPANDALL_FLAGS,{expandAll:true,tabIndex:tabIndex,viewType:viewType}));
    }
}

export const resetExpandAll = (tabIndex,viewType) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ADJUSTMENTS_EXPANDALL_FLAGS,{expandAll:false,tabIndex:tabIndex,viewType:viewType}));
    }
}

export const setGroupViewType = (tabIndex,viewType) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_BU_GROUP_VIEW_TYPE,{tabIndex:tabIndex,viewType:viewType}));
    }
}

export const setAccordianClicked = (tabIndex,viewType,header,isTopLevel,parentValHeader) =>{
    // console.log("setAccordianClicked with :: ",tabIndex,viewType,header,isTopLevel,parentValHeader);
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ACCORDIAN_CLICK,
            {
                tabIndex:tabIndex,
                viewType:viewType,
                header:header,
                parentHeader:(!isTopLevel?parentValHeader:null)
            }));
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, optimizedData) {
    return {
        type: type,
        data: optimizedData
    }
}

function adjustmentActionSuccess(type, data, action) {
    return {
        type: type,
        data: data,
        action: action
    }
}

function adjustmentLevel(type, data) {
    return {
        type: type,
        data: data
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}
function getAllPlansTableData(type) {
    return {
        type: type
    }
}