import React, { useEffect, useCallback, useState } from 'react';
import { planInputSubTablesData, planInputTabData } from '../../Constants';
import PlanInputsPagestyle from './PlanInputsPage.css';

export const AllTabTableList=(props)=>{
    const {tabsToBeCopiedToMain, setTabsToBeCopiedToMain,tabStatuses}=props;
    // console.log("AllTabTableList==tabsToBeCopiedToMain===",tabsToBeCopiedToMain);
    return(
        <div className="flexContainer">
            <ul>
            {planInputTabData.map((tabData,index)=>{
                // console.log("tabData==",tabData);
                let internalTablesOfTab=[];
                planInputSubTablesData[tabData.tabKey].forEach(item=>{//removing UPGRADE_TEMPLATE for now,not including in copy .
                    if(item!=="MOVE_COST" && item!=="UPGRADE_TEMPLATE"){  //removing  MOVE_COST for now. As per the client suggestion,not including movecost in copy or create 
                    if(tabStatuses[item]){
                        internalTablesOfTab.push({item:item,isDisabled:tabStatuses[item]});
                    }
                    else{
                        internalTablesOfTab.push({item:item,isDisabled:false});
                    }
                }});
                // console.log("tabStatuses==",tabStatuses);
                
                let isDisabled=false;
                if(internalTablesOfTab.length===1 && internalTablesOfTab[0].isDisabled){
                    isDisabled=true;
                }

                let internalTabData={...tabData,tables:internalTablesOfTab};
                // console.log("internalTabData==",internalTabData);

                return(
                    <li key={"tabData"+index} className={isDisabled?"flexContainer_ul_li_disabled":"flexContainer_ul_li_normal"}>
                        {(tabData.tabTitle!=="Plan Assumptions")?<input type="checkbox" id={'listCheckBox_'+index}  onClick={(e)=>registerTableSelect(e,internalTabData.tables)}  value={isTableSelected(internalTabData.tables)} disabled={isDisabled}/>:''}
                        <span>{tabData.tabTitle}</span>
                        {getSubList(internalTabData.tables)}
                    </li>
                )
            })}
            </ul>
        </div>
    );

    function getSubList(subListTableData){
        // console.log("subListTableData==",subListTableData);
        if(subListTableData.length>1){
            return (
                <ul>
                    {subListTableData.filter(tabData=>tabData.item!=='ZONE_MAP')
                                     .map((tabData,idx)=>{
                        return(
                            <li key={"subtableName_"+tabData.item}  className={tabData.isDisabled?"flexContainer_ul_li_disabled":"flexContainer_ul_li_normal"}>
                                <input type="checkbox" id={'sublistCheckBox_'+tabData.item+'_'+idx} onChange={(e)=>registerTableSelect(e,tabData)} defaultChecked={isTableSelected(tabData)} disabled={tabData.isDisabled}/>
                                <span>{tabData.item}</span>
                            </li>
                        )
                    })}
                </ul>
            );
        }else{
            return '';
        }
    }
    
    function registerTableSelect(e,value){
        // console.log("Value====",value,e);
        // console.log("Value.length====",value.length,e);


        //For Sublist, value will come as string, so convert to array
        if(value.length===undefined){
            let tempValue=[];
            tempValue.push(value)
            value=tempValue;
        }

        if(value.length>0){            
            let tempArray=[...tabsToBeCopiedToMain];
            for(let i=0;i<value.length;i++){
                if(tempArray.indexOf(value[i].item)>-1){
                    //Value found
                    tempArray=tempArray.filter(item=>item!==value[i].item);
                }else{
                    //Value not found
                    tempArray.push(value[i].item);
                }
            } 
    
            setTabsToBeCopiedToMain(tempArray);
            // console.log("tempArray====",tempArray);
        }
    }
    
    function isTableSelected(value){
        let isFound=false;

        //For Sublist, value will come as string, so convert to array
        if(value.length===undefined){
            let tempValue=[];
            tempValue.push(value)
            value=tempValue;
        }

        // console.log("tabsToBeCopiedToMain insude isTablwSelected==",tabsToBeCopiedToMain);
        if(value.length>0){            
            let tempArray=[...tabsToBeCopiedToMain];
            for(let i=0;i<value.length;i++){
                if(tempArray.indexOf(value[i].item)>-1){
                    isFound=true;
                }else{
                    isFound=false;
                    break;
                }
            } 
            

        }
        // console.log("isTableSelected===",isFound,value);
        let returnValue="";
        if(isFound){
            returnValue= "checked";
        }

        return returnValue;
    }
}

