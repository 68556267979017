import { columnDefs, defaultColDef } from "./mileageTable_grid_data/MileageTableColumnDefs";
import { FETCH_MILEAGETABLE_DROPDOWNVALUES_PENDING,FETCH_MILEAGETABLE_DROPDOWNVALUES_SUCCESS,FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR, SET_MILEAGETABLE_EDITABLE } from '../mileagetable/MileageTable.ActionTypes';
import { getDropdownValuesForYear,safeCaseInsensitiveCompare } from "../../utils/utils";
import moment from "moment";
import { MileageTableColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, MileageTableColumns.Zone.headerName) },
            CAR_GROUP: { error: checkIfValueEmpty(data.CAR_GROUP,MileageTableColumns.CarGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===MileageTableColumns.CarGroup.field)) },
            MODEL_YEAR: { error: checkIfValidYear(data.MODEL_YEAR, MileageTableColumns.ModelYear.headerName) },
            MILEAGE_VALUE: { error: validateMileageFields(data.MILEAGE_VALUE, MileageTableColumns.MileageValue.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}
function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) { 
                if (safeCaseInsensitiveCompare(itm.ZONE_NAME,itm2.ZONE_NAME) && safeCaseInsensitiveCompare(itm.CAR_GROUP,itm2.CAR_GROUP) && safeCaseInsensitiveCompare(itm.MODEL_YEAR,itm2.MODEL_YEAR) 
                //&& safeCaseInsensitiveCompare(itm.MILEAGE_VALUE,itm2.MILEAGE_VALUE)
            ){
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                } 
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            transactions.forEach((itm1,key)=>{
                if(itm1.data){
                  if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                    transactions[key].data.status=itm.status
                  }
                }
              })
    });
    return duplicate;
}
function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}
function checkIfValueEmpty(value, name,index) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(value==='ALL'){
        return name + " cannot be ALL";
    }
    else if(name===MileageTableColumns.CarGroup.headerName && initialState.columnDefs[index].cellEditorParams !== undefined){
        let allCarGroup =sessionStorage.getItem("CARGROUP") ? (JSON.parse(sessionStorage.getItem("CARGROUP"))).body.data:[];
        if (allCarGroup && allCarGroup.length && (allCarGroup.indexOf(value) == -1)) {
            return name + " " + value + " no-longer available";
        }
    }
    return null;
}
function validateMileageFields( max, displayName) {
    if ( max === undefined ||  max === '' ||  max === null) {
        return displayName + ' cannot be empty';
    }
   else if (max < 0) {
            return 'Please enter valid ' + displayName
        }
    return null;
}
function checkIfValidYear(value, name) {
        if (value === undefined || value === '' || value === null)
            return name + " cannot be empty";
        else {
            var itemValue = parseInt(value, 0);
            if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10))
                return name + " should be in +5 or -10 range from the current year"
        }
        return null;
    }



function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData=[];

    zones.forEach(val => {
        zoneData.push(val.name)
        zoneFilterData.push({ "label": val.name, "value": val.name })
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"});

    element.cellEditorParams = { options: zoneData };
    element.filterOptions = zoneFilterData;
}

function fetchDropdownValueOthers(data,adminData,element) {
    let carGroupNameFilterData = []
    adminData.map((val) => {
        carGroupNameFilterData.push({ "label": val, "value": val })
    })
    carGroupNameFilterData.push({ "label": "Blanks", "value": "Blanks" });

    element.cellEditorParams = { options: data };
    element.filterOptions = carGroupNameFilterData;
}

function fetchZones(mileageTableData, zones) {
    mileageTableData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return mileageTableData;
}

function fetchFilterValues( carGroupName, zones,adminCarGroup,users,columns) {
    columns.forEach(element => {
        if (element.field === MileageTableColumns.Zone.field) {
            fetchDropdownsForZones(zones, element);
        }  else if (element.field === MileageTableColumns.CarGroup.field) {
            fetchDropdownValueOthers(carGroupName,adminCarGroup,element);
        }
        else if(element.field === MileageTableColumns.ModelYear.field){
            element.filterOptions = getDropdownValuesForYear() // this function is used to get dropdown values for year columns from current year (-10 years to +5 years)
        }
        else if(element.field === MileageTableColumns.UpdatedBy.field){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'MileageTableActionRenderer'
            }
        } 
    });

    return columns;
}

export default function mileageTableColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MILEAGETABLE_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_MILEAGETABLE_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.car_group_name,action.data.zone,action.data.admincarGroupName,action.data.users,state.columnDefs)
            });
        case FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_MILEAGETABLE_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}