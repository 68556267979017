import React from 'react';
import ActionBar from "../../components/actionbar/ActionBar";
import MultiGridUI from "../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';
import ProgramsAccordionContainer from '../accordionview/ProgramsAccordionContainer';

const ProgramsTabPanel = (props) => {
    const { programData, handleSwitchView, currentView, showSaveOption,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted,handleRowEditingStopped,
        tabIndex,selectedPlanId,tabInfo, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped, 
        gridReducer, programgridcoldef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, 
        selectedRows, downloadCSV,/* filterParams,*/  transactions, filterSearchKey,isFiltered,
        setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists,userMessage,newArchitecture,gridName,pending,loadGridData} = props;
    return (
        <div>
            {newArchitecture ? (<MainPlanInputsMultiGridUI reducer={gridReducer}
                gridName={gridName}
                //currentView={currentView}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={programgridcoldef} 
                rowEditType={'single'}
                suppressclickEdit={!programgridcoldef.defaultColDef.editable||pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={true}/>):(
                <>
                <ActionBar onViewChanged={handleSwitchView}
                onAddNewRowClick={handleAddNewRow}
                actionView={currentView}
                options={showSaveOption}
                handleSaveCancelDisabled={handleSaveCancelDisabled}
                onSaveClicked={handleSaveClicked}
                onCancelClicked={handleCancelClicked}
                selectedPlanId={selectedPlanId} 
                deleteSelectedRows={deleteSelectedRows}
                bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                selectedRows={selectedRows}
                downloadCSV={downloadCSV}
                data={gridReducer.currentData}
                // filterParams={filterParams}
                transactions={transactions}
                programsData={programData}
                filterSearchKey={filterSearchKey}
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                isSearchBarValueExists={isSearchBarValueExists}
                setIsSearchBarValueExists={setIsSearchBarValueExists}
                programgridcoldef={programgridcoldef}/>
                {(currentView === "grid") ? (<MultiGridUI reducer={gridReducer}
                gridName={"programsgrid"}
                currentView={currentView}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={programgridcoldef} 
                rowEditType={'single'}
                suppressclickEdit={!programgridcoldef.defaultColDef.editable}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}/>) :
                (<ProgramsAccordionContainer planid={selectedPlanId} 
                    data={gridReducer.currentData}
                    tabIndex={tabIndex}
                    selectedTab={tabInfo.Title} currentView={currentView}></ProgramsAccordionContainer>)}
                    </>
                )}
        </div>
    )

}

export default ProgramsTabPanel;