const manualVinDeletesfilterColumns = () => {
    return ({
        "data": [
            {
                "colDef": "VIN",
                "displayName": "Vin",
                "type": "dropdown",
                "options": [],
            },

            {
                "colDef": "MONTH",
                "displayName": "Month",
                "type": "datepicker",
                "options": [],
            },
        ]
    });
}

export default manualVinDeletesfilterColumns;