import { MoveConstraintsColumns, cellEditors } from "../../../Constants";
import { dateFormatter, localeFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customNumericSortComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 20,
        width:40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: MoveConstraintsColumns.ZoneName.field, 
        headerName: MoveConstraintsColumns.ZoneName.headerName, 
        minWidth:65,width:65,
        cellEditor: cellEditors.autoCompleteEditor,
        unSortIcon: true,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MoveConstraintsColumns.ZoneName.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.ZoneName.name)
        }
    },
    {
        field: MoveConstraintsColumns.Pool.field, 
        headerName: MoveConstraintsColumns.Pool.headerName, 
        minWidth:65,width:65,
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MoveConstraintsColumns.Pool.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.Pool.name)
        }
    },
    {
        field: MoveConstraintsColumns.Month.field, 
        headerName: MoveConstraintsColumns.Month.headerName,
        minWidth:60,width:60,
        cellEditor: cellEditors.dateEditor, 
        unSortIcon: true,
        filterType:"datepicker",
        valueFormatter: params => dateFormatter(params.data.MONTH),
        cellClassRules: cellClassRules(MoveConstraintsColumns.Month.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.Month.name)
        }
    },
    {
        field: MoveConstraintsColumns.MinFrom.field, 
        headerName: MoveConstraintsColumns.MinFrom.headerName, 
        minWidth:65,width:65,unSortIcon: true,
        cellEditor: cellEditors.numericEditor,
        filterType:"range",
        type: 'numericColumn',
        comparator: customNumericSortComparator,
        valueFormatter: params => {
            return localeFormatter(params.data.MIN_FROM);
        },
        cellClassRules: cellClassRules(MoveConstraintsColumns.MinFrom.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.MinFrom.name)
        }
    },
    {
        field: MoveConstraintsColumns.MaxFrom.field, 
        headerName: MoveConstraintsColumns.MaxFrom.headerName, 
        minWidth:65,width:65,unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        filterType:"range",
        type: 'numericColumn',
        comparator: customNumericSortComparator,
        valueFormatter: params => {
            return localeFormatter(params.data.MAX_FROM);
        },
        cellClassRules: cellClassRules(MoveConstraintsColumns.MaxFrom.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.MaxFrom.name)
        }
    },
    {
        field: MoveConstraintsColumns.UpdatedBy.field, 
        headerName: MoveConstraintsColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        minWidth:65,width:65,
        cellClassRules: cellClassRules(MoveConstraintsColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.UpdatedBy.name)
        }
    },
    {
        field: MoveConstraintsColumns.UpdatedOn.field, 
        headerName: MoveConstraintsColumns.UpdatedOn.headerName, 
        editable: false, minWidth:65,width:65,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(MoveConstraintsColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, MoveConstraintsColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "MoveConstraintActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 99,
        width:99,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field] &&
        params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef && params.data.validations[field]) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
