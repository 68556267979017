import { columnDefs, defaultColDef } from "./targetZonePool_grid_data/TargetZonePoolColumnDefs";
import { FETCH_TARGETZONEPOOL_DROPDOWNVALUES_PENDING,FETCH_TARGETZONEPOOL_DROPDOWNVALUES_SUCCESS,FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR, SET_TARGETZONEPOOL_EDITABLE } from '../targetzonepool/TargetZonePool.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import moment from "moment";
import { TargetZonePoolColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME,data.POOL, TargetZonePoolColumns.Zone.headerName) },
            POOL: { error: checkIfPool(data.POOL, data.ZONE_NAME,TargetZonePoolColumns.Pool.headerName,initialState.columnDefs.findIndex(itm=>itm.field=== TargetZonePoolColumns.Pool.field)) },
            BU: { error: checkForBu(data.BU, TargetZonePoolColumns.BU.headerName) },
            TOTAL_RF_QUANTITY: { error: checkForTotalRFQuantity(data.TOTAL_RF_QUANTITY, TargetZonePoolColumns.TotalRFQuantity.headerName) },
            ONRENT_MONTH: { error: checkIfMonthEmpty(data.ONRENT_MONTH, TargetZonePoolColumns.OnrentMonth.headerName) },
            YEAR: { error: checkIfValidYear(data.YEAR, TargetZonePoolColumns.Year.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) { 
                if (safeCaseInsensitiveCompare(itm.ZONE_NAME ,itm2.ZONE_NAME) && 
                (safeCaseInsensitiveCompare(itm.POOL ,itm2.POOL)) && 
                (safeCaseInsensitiveCompare(itm.BU,itm2.BU)) && 
                    (safeCaseInsensitiveCompare(itm.TOTAL_RF_QUANTITY,itm2.TOTAL_RF_QUANTITY)) && 
                    (safeCaseInsensitiveCompare(itm.ONRENT_MONTH, itm2.ONRENT_MONTH)) &&
                    (safeCaseInsensitiveCompare(itm.YEAR,itm2.YEAR))){
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                } 
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            // transactions.forEach((itm1,key)=>{
            //     if(itm1.data){
            //       if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
            //         transactions[key].data.status=itm.status
            //       }
            //     }
            //   })
    });
    return duplicate;
}

function checkIfValueEmpty(value, pool, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    if(value==='ALL' && pool==='ALL'){
        return 'Both zone and pool cannot be ALL'
    }
    if(pool!==null && pool!=='ALL' && value!=='ALL'){
        return name + " should be ALL";
    }
    return null;
}
function checkIfMonthEmpty(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    return null;
}
function checkIfPool(pool,zone,name,data) {
    if (pool === undefined || pool === "" || pool === null) {
        return name + " cannot be empty";
    } else
    if(pool==='ALL' && zone==='ALL'){
        return 'Both zone and pool cannot be ALL'
    } else
    if(zone!==null && zone!=='ALL' && pool!==null && pool!=='ALL'){
        return name + " should be ALL";
    } else
    if (initialState.columnDefs[data].cellEditorParams !== undefined) {
        if (initialState.columnDefs[data].cellEditorParams.options.indexOf(pool) == -1) {
            return name +" "+pool+" no-longer available";
        }
    }
    return null;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}
function checkForBu(value, name) {
    if(name===TargetZonePoolColumns.BU.headerName){
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        } else if(value==='ALL'){
            return name + " Cannot be ALL"
        }
    } else 
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    return null;
}

function checkForTotalRFQuantity(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } 
    if(value<0){
        return name + " cannot be less than zero";
    }
    return null;
}
function checkIfValidYear(value, name) {
    if (value === undefined || value === '' || value === null)
        return name + " cannot be empty";
    else {
        var itemValue = parseInt(value, 0);
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10))
            return name + " should be in +5 or -10 range from the current year"
    }
    return null;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [], zoneFilterData=[];
    //zoneData.push("ALL")
    if(zones.length>0){
    zones.forEach(val => {
        zoneFilterData.push({"label":val.name,"value":val.name})
        //if (val.name.toUpperCase() != "ALL")
            zoneData.push(val.name)
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"})
}
    element.cellEditorParams = { options: zoneData }
    element.filterOptions=zoneFilterData
}

function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = []
    poolData.push("ALL")
    if(pools.length>0){
    pools.forEach(val => {
        if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        }
    });
  }
  adminpools.forEach(val=>{
    poolFilterData.push({"label":val.code,"value":val.code})
  })
    poolFilterData.push({"label":"Blanks","value":"Blanks"})
   
    element.cellEditorParams = { options: poolData }
    element.filterOptions=poolFilterData
}

function fetchDropdownValueOthers(data, element) {
    let filterData=[];
    data.map((val)=>{
        filterData.push({"label":val,"value":val})
    })
    filterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: data };
    element.filterOptions = filterData;
}

function fetchDropdownForMonths(element){
    let monthsShortData = moment.monthsShort(), monthsShortFilterData = [];
        monthsShortData.forEach(month => {
        monthsShortFilterData.push({ "label": month.toUpperCase(), "value": month.toUpperCase() })
    })
        monthsShortFilterData.push({ "label": "Blanks", "value": "Blanks" })
        element.filterOptions = monthsShortFilterData;
        element.cellEditorParams = { options: monthsShortData}
}

function fetchZones(targetZonePoolData, zones) {
    targetZonePoolData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return targetZonePoolData;
}

function fetchFilterValues( bu, pool,adminpools, zones,users,columns) {
    columns.forEach(element => {
        if (element.field === TargetZonePoolColumns.Zone.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field === TargetZonePoolColumns.Pool.field) {
            fetchDropdownsForPools(pool, adminpools,element);
        } else if (element.field === TargetZonePoolColumns.BU.field) {
            fetchDropdownValueOthers(bu, element);
        } else if(element.field === TargetZonePoolColumns.OnrentMonth.field){
            fetchDropdownForMonths(element)
        }else if(element.field === TargetZonePoolColumns.UpdatedBy.field) {
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
          if (JSON.parse(localStorage.getItem("newArchitecture"))) {
              element.cellRenderer = 'MainPlanInputsActionRenderer'
          }
          else {
              // this else condition changes cellrenderer when old version and will be removed after acceptance
              element.cellRenderer = 'TargetZonePoolActionRenderer'
          }
      }
  });

    return columns;
}

export default function targetZonePoolColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TARGETZONEPOOL_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_TARGETZONEPOOL_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.bu,action.data.pool,action.data.adminpools,
                    action.data.zone,action.data.users, state.columnDefs)
            });
        case FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_TARGETZONEPOOL_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}