export const FETCH_PARAMETERS_DATA_PENDING = 'FETCH_PARAMETERS_DATA_PENDING';
export const FETCH_PARAMETERS_DATA_SUCCESS = 'FETCH_PARAMETERS_DATA_SUCCESS';
export const FETCH_PARAMETERS_DATA_ERROR = 'FETCH_PARAMETERS_DATA_ERROR';
export const SAVE_PARAMETERS_TRANSACIONS_PENDING = 'SAVE_PARAMETERS_TRANSACIONS_PENDING';
export const SAVE_PARAMETERS_TRANSACIONS_SUCCESS = 'SAVE_PARAMETERS_TRANSACIONS_SUCCESS';
export const SAVE_PARAMETERS_TRANSACIONS_ERROR = 'SAVE_PARAMETERS_TRANSACIONS_ERROR';
export const DELETE_PARAMETERS_DATA_PENDING = 'DELETE_PARAMETERS_DATA_PENDING';
export const DELETE_PARAMETERS_DATA_SUCCESS = 'DELETE_PARAMETERS_DATA_SUCCESS';
export const DELETE_PARAMETERS_DATA_ERROR = 'DELETE_PARAMETERS_DATA_ERROR';
export const FETCH_PARAMETERS_DROPDOWN_PENDING = 'FETCH_PARAMETERS_DROPDOWN_PENDING';
export const FETCH_PARAMETERS_DROPDOWN_SUCCESS = 'FETCH_PARAMETERS_DROPDOWN_SUCCESS';
export const FETCH_PARAMETERS_DROPDOWN_ERROR = 'FETCH_PARAMETERS_DROPDOWN_ERROR';
export const SET_PARAMETERS_EDITABLE='SET_PARAMETERS_EDITABLE';