import React, { useState} from 'react';
import icon_plandetailsview from "../../../../../assets/images/buttons-detailed-view.svg";
import icon_actionsmoredots from "../../../../../assets/images/icons-actions-more-dots-stacked.svg";
import './AllRFsActionRenderer.css';
import { useHistory } from 'react-router-dom';

export default (props)=>{
const history = useHistory();
const rf_id = props.data.ID;
const status = props.data.STATUS;
const [selectedRfId, setSelectedRfId] = useState();

const handleNavigation = () => {
   if (props && (props.data.STATUS === "completed" || props.data.STATUS === "optimization complete")) {
     let url = "/revenuefunction/" + rf_id + "/rfinputs";
     setSelectedRfId(rf_id);
     history.push({
       pathname: url,
     });
   }
 };

 return(
    <div className='actionrendererstyle'>
     <img  onClick={(e) => handleNavigation()} src={icon_plandetailsview} className={(status === 'optimization complete') || (status === 'completed') ? 'cursorpoint' : ''}></img>
     <img src={icon_actionsmoredots}></img>
    </div>
 )
}