import { FETCH_TNCREVENUE_DROPDOWNVALUES_PENDING, FETCH_TNCREVENUE_DROPDOWNVALUES_ERROR, FETCH_TNCREVENUE_DROPDOWNVALUES_SUCCESS } from "../../RevenueFunction.ActionTypes";
import { columnDefs, defaultColDef } from "../tnc_revenue/tnc_revenue_grid_data/TncRevenueColumnDefs";
import * as CONSTANTS from "../../../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues:[],
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            POOL: { error: checkIfValueEmpty(data.POOL, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Pool.field, data) },
            ONRENT_DATE: { error: checkIfValueEmpty(data.ONRENT_DATE, "On Rent Date") },
            CAR_CLASS: { error: checkIfValueEmpty(data.CAR_CLASS, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Carclass.field, data) },
            MONTH: { error: checkIfValueEmpty(data.MONTH, "MONTH") },
            MIN_FLEET_NEEDED: { error: checkIfValueEmpty(data.MIN_FLEET_NEEDED, "MIN_FLEET_NEEDED") },
            RPU_AT_MIN_FLEET: { error: checkIfValueEmpty(data.RPU_AT_MIN_FLEET, "RPU_AT_MIN_FLEET") }
        }
    },
    businessValidationFn: function (currentData,transactions) {
        return currentData;
    }
}

function checkIfValueEmpty(value, fiedName) {
    if (value === undefined || value === "" || value === null) {
        return fiedName +" "+"cannot be empty";
    }
    return null;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}
function fetchDropdownsForPools(pools, element) {
    var poolData = [],poolFilterData = [];
    if(pools && pools.length>0){
    pools.forEach(val => {
        poolFilterData.push({"label":val.code,"value":val.code})
        if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        }
    });
    poolFilterData.push({"label":"Blanks","value":"Blanks"})
  }
    element.cellEditorParams = { options: poolData }
    element.filterOptions = poolFilterData;
}
function fetchDropdownsForCarClass(carClasses, element) {
    var carClassData = [],carClassFilterDropdownOptions=[]
    if(carClasses && carClasses.length>0){
        carClassData.push("ALL");
        carClassFilterDropdownOptions.push({"label":"ALL","value":"ALL"})
        carClasses.forEach(val => {
            carClassData.push(val);
            carClassFilterDropdownOptions.push({"label":val,"value":val})
        });
    }
    element.cellEditorParams = { options: carClassData }
    carClassFilterDropdownOptions.push({"label":"Blanks","value":"Blanks"})
    element.filterOptions = carClassFilterDropdownOptions;
}
function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Pool.field) {
            fetchDropdownsForPools(data.pools, column);
        }else if(column.field === CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Carclass.field){
            fetchDropdownsForCarClass(data.carClasses, column);
        }else{
            //NO Need
        }
    });
    return columnDefs;
}

export default function RFTncRevenuecolumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TNCREVENUE_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues: setColumnDefParams(state.columnDefs, [])
            });
        case FETCH_TNCREVENUE_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_TNCREVENUE_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}