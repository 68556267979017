import {getMileageTableData, saveMileageTableTransactions, fetchMileageTableDropdownService,uploadMileageTableCSV} from '../../services/mileagetable/MileageTableService';
import {FETCH_MILEAGETABLE_DATA_PENDING, FETCH_MILEAGETABLE_DATA_SUCCESS, FETCH_MILEAGETABLE_DATA_ERROR, UPLOAD_MILEAGETABLE_PENDING, UPLOAD_MILEAGETABLE_SUCCESS, UPLOAD_MILEAGETABLE_ERROR,
    SAVE_MILEAGETABLE_TRANSACTION_PENDING, SAVE_MILEAGETABLE_TRANSACTION_SUCCESS, SAVE_MILEAGETABLE_TRANSACTION_ERROR,
    FETCH_MILEAGETABLE_DROPDOWNVALUES_PENDING,FETCH_MILEAGETABLE_DROPDOWNVALUES_SUCCESS,FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR, RESET_TO_MILEAGETABLE_INITIALSTATE} from './MileageTable.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchMileageTableData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MILEAGETABLE_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getMileageTableData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MILEAGETABLE_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MILEAGETABLE_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MILEAGETABLE_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadMileageTableData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_MILEAGETABLE_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadMileageTableCSV(planId, files);
            let mileageTableData = await getMileageTableData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_MILEAGETABLE_SUCCESS, mileageTableData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_MILEAGETABLE_ERROR, "mileage table upload failed"));
                dispatch(SetAssumptionError("mileage table upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_MILEAGETABLE_ERROR, "mileage table upload failed"));
            dispatch(SetAssumptionError("mileage table upload failed"))
        }
    }
}

export const saveMileageTableTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MILEAGETABLE_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveMileageTableTransactions(planid, transactionList);
            let mileageTableData = await getMileageTableData(planid);
            if (mileageTableData) {
                dispatch(serviceActionSuccess(SAVE_MILEAGETABLE_TRANSACTION_SUCCESS, mileageTableData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_MILEAGETABLE_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchMileageTableDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_MILEAGETABLE_DROPDOWNVALUES_PENDING));
        let mileageTableData = await fetchMileageTableDropdownService(planid);
        if (mileageTableData) {
            dispatch(serviceActionSuccess(FETCH_MILEAGETABLE_DROPDOWNVALUES_SUCCESS, mileageTableData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if (isNewArchitecture) {// this if is for new architecture
                    dispatch(newValidateGrid("mileagetableGrid", data, coldef.validationFn, coldef.businessValidationFn, validateTransactionsIfMasterDataChanged));
                }
                else {// this else is for old architecture and will be removed later
                    dispatch(validateGrid("mileagetablegrid", data, coldef.validationFn, coldef.businessValidationFn, validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR, mileageTableData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MILEAGETABLE_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetMileageTable = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MILEAGETABLE_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}