import { combineReducers } from 'redux';
import ModelMfrOemReducer from './ModelMfrOem.Reducer';
import multiGridUIReducer from '../AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import modelMfrOemColumnDefinitionReducer from "./ModelMfrOem.ColumnDef.Reducer";
import {MODELMFROEM} from '../../../../src/Constants';

const rootModelMfrOemReducer = combineReducers({
      ModelMfrOemReducer
    , modelMfrOemGridReducer: createNamedWrapperReducer(multiGridUIReducer, MODELMFROEM)
    , modelMfrOemColumnDefinitionReducer
})

export default rootModelMfrOemReducer;