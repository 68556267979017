import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAllMoveConstraints(planid,pageno) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseConstraintsResponse = await client.apis.plans.getAllMoveConstraints({ "planid": planid ? planid : 'master','pageno':pageno ? pageno:0 });
    const responsesArray = await Promise.all([promiseConstraintsResponse]);
    const [moveconstraintsResponse] = responsesArray.map(o => o.body.data);
    // return ({
    //     "moveconstraints": moveconstraintsResponse,
    //     "zones": zones, "pools": poolResponse
    // });

     // this if condition is true when it is new version
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        return ({
            "gridData": moveconstraintsResponse.rows, "rowcount": moveconstraintsResponse.rowCount
        });
    }
    else {
         //this else condition executes when it is older version
        return ({
            "moveconstraints": moveconstraintsResponse,
        });
    }
}

export async function getMoveConstraintsFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseMoveConstraintsFilterResponse = await client.apis.plans.getMoveConstraintsFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseMoveConstraintsFilterResponse]);
        const [moveconstraintsResponse] = responsesArray.map(o => o.body.data);
        
        return({
            "gridData": moveconstraintsResponse.rows,"rowcount":moveconstraintsResponse.rows[0]?moveconstraintsResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'MoveConstraints tab has internal server error, please do re-apply the filter'
    }
}

export async function fetchMoveConstraintsDropdownService (){
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([promiseZoneResponse,promisePoolResponse,promiseAdminPoolResponse])
    
    const [zoneResponse,poolResponse,adminPoolResponse]=responsesArray.map(o=>o.body.data)

    return({
        "zones":zoneResponse,
        "pools":poolResponse,
        "adminpools":adminPoolResponse,
        "users":promiseUserManagementEmailAndIdMap
    })
}

export async function deleteAllMoveConstraintsData(planid,params){
    try{
            const userNToken=await getAccessTokenNUserDetailsAfterAuth();
            const accessToken=userNToken.accessToken;
            const userEmail = userNToken.email;
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });            
            let promisemoveconstraintsDeleteResponse  = await client.apis.plans.deleteAllMoveConstraintsRecords({ "planid": planid ? planid : 'master' },{requestBody: {"updatedBy":userEmail,...params}});
            if (promisemoveconstraintsDeleteResponse.obj && promisemoveconstraintsDeleteResponse.obj.statusCode !== '500') {
                return promisemoveconstraintsDeleteResponse;;
            }
            else {
                throw Error();
            }
        } 
        catch (error) {
            console.error(error);
            throw 'Move Constraints tab has internal server error, Unable to delete all the records!!Please try again'
        }
}

export async function saveMoveConstraintTransactions(planid, transactionList) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactmoveconstraint({"planid":planid?planid:"master"},  {requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) }});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
}

export async function uploadMoveConstraintsCSV(planid, file){
    try {
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.uploadMoveConstraint({ "planid": planid ? planid : 'master' }, {
            requestBody: {
                "updatedBy": userEmail,
                "file": file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for MoveConstraints tab, Please try again!!')
    }
}

export async function insertS3UploadMoveConstraintsCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.insertS3UploadedMoveConstraintsData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi,''),
                "fileName": filename
            }
        });
        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for MoveConstraints tab, Please try again!!')
    }
}

export async function moveConstraintsTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateMoveConstraints({"planid": planid ? planid : 1}, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        if (response.obj.statusCode === '500') {
            throw new Error((JSON.parse(response.obj.body)).error);
        }
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch MoveConstraints Validation status ,Please try again!!';
        //return { 'Error': 'MoveConstraintsTabValidationService failed' }
    }
}

export async function getMoveConstraintsTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisemoveConstraintsResponse = await client.apis.plans.getMoveConstraintsTabErrorStatus({ "planid": planid ? planid : 1 });        
        
        if (promisemoveConstraintsResponse.obj && promisemoveConstraintsResponse.obj.statusCode !== '500') {
        const responsesArray = await Promise.all([promisemoveConstraintsResponse]);
        const [moveConstraintsTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
        return moveConstraintsTabErrorStatusResponse;
        }
        else {
            throw Error();
        }        
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch MoveConstraints error status ,Please try again!!';
        // return { 'Error': 'getMoveConstraintsTabErrorStatus failed' }
    }
}

export async function getMoveConstraintsErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisemoveconstraintResponse = await client.apis.plans.getMoveConstraintErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promisemoveconstraintResponse]);
        const [moveconstraintResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": moveconstraintResponse.rows, "rowcount": moveconstraintResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for MoveConstraints tab'
        //return { 'Error': 'fetchAllocated failed' }
    }
} 

export async function getMoveConstraintSearchData(planid,pageno,searchParams){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseMoveConstraintSearchResponse = client.apis.plans.getMoveConstraintSearchRecords({"planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseMoveConstraintSearchResponse]);

        const [moveconstraintSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": moveconstraintSearchResponse.rows, "rowcount": moveconstraintSearchResponse.rows[0] ? moveconstraintSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for MoveConstraints tab'
    }
}