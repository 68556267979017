/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import "./SearchBar.css";
import {useDispatch} from "react-redux";

const SearchBar = (props) => {
    const {onSearchValueChanged} = props;

    const onValueChanged =(e)=>{
        onSearchValueChanged(e);
    }

    return (
        <TextField className="inputRounded" id="input-with-icon-textfield"
            placeholder="Search" InputProps={{
                startAdornment: (
                    <InputAdornment position="start" className="inputadorent">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        type="search" variant="outlined" onChange={(e)=>onValueChanged(e)} />
    );
}

SearchBar.propTypes = {
    onSearchValueChanged: PropTypes.any
}

export default SearchBar;