import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";

export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState('');

    function inputHandler(e) {
        setValue(e.target.value);
    }
    
    useEffect(o => {
        setTimeout(function () {
            if(inputRef && inputRef.current) {
                inputRef.current.select();
                inputRef.current.focus();
            }
        }, 100);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            }
        };
    });

    return (
        <input 
            type="number"
            className="ag-input-field-input ag-text-field-input"
            ref={inputRef}
            onChange={inputHandler}
            value={value}
            autoFocus={true}
        />
    )
})