import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { minifyTransaction } from '../../../../utils/utils';

export async function getCoreParameterData(rfid){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   //Note:using same api,getRFParameters for tncparameters and coreparameters
    const coreParameterResponse = await client.apis.revenuefunction.getRFParameters({},{requestBody:{'rfid':rfid}});
    return ({
        "gridData": coreParameterResponse.obj.data,"rowcount":coreParameterResponse.obj.data.length
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreParameterData failed'}
   }
   }

export async function saveRfCoreParameterTransactions(rfid,transactionList,tabname,typeName){
    try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.revenuefunction.bulktransactrfparameters({"rfid":rfid},  {requestBody: { "updatedBy": userEmail,"tabName":tabname,"typeName":typeName,"transactions": minifyTransaction(transactionList)}});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
    }catch(error){

    }
}

export async function getCoreParametersTabErrorStatus(rfid) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const promisecoreParametersResponse = await client.apis.revenuefunction.getRFParametersTabErrorStatus({ 'rfid': rfid });
        const responsesArray = await Promise.all([promisecoreParametersResponse]);
        const [coreParametersTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
        return coreParametersTabErrorStatusResponse;
    }
    catch (error) {
        console.error(error);
        return { 'Error': 'getCoreParametersTabErrorStatus failed' }
    }
}