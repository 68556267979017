import { combineReducers } from 'redux';
import AdditionalCostReducer from './AdditionalCost.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import additionalCostColumnDefinitionReducer from "./AdditionalCost.ColumnDef.Reducer";

const rootAdditionalCostReducer = combineReducers({
    AdditionalCostReducer
    , additionalCostGridReducer: createNamedWrapperReducer(multiGridUIReducer, "additionalcostgrid")
    , additionalCostColumnDefinitionReducer
})

export default rootAdditionalCostReducer;