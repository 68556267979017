import {FETCH_MILEAGEDEFAULT_DATA_PENDING, FETCH_MILEAGEDEFAULT_DATA_SUCCESS, FETCH_MILEAGEDEFAULT_DATA_ERROR, UPLOAD_MILEAGEDEFAULT_PENDING, UPLOAD_MILEAGEDEFAULT_SUCCESS, UPLOAD_MILEAGEDEFAULT_ERROR,
    SAVE_MILEAGEDEFAULT_TRANSACTION_PENDING, SAVE_MILEAGEDEFAULT_TRANSACTION_SUCCESS, SAVE_MILEAGEDEFAULT_TRANSACTION_ERROR,RESET_TO_MILEAGEDEFAULT_INITIALSTATE
    } from '../../store/mileagedefault/MileageDefault.ActionTypes';

const initialState = {
    mileageDefaultData:[],
    pending:false,
    error:null,
    uploadMileageDefaultSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(mileageDefaultData, zones) {
    mileageDefaultData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
        if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
            value['ZONE_NAME'] = "";
        }
    })
    return mileageDefaultData;
}

export default function MileageDefaultReducer (state=initialState,action){
    switch(action.type){
        case FETCH_MILEAGEDEFAULT_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_MILEAGEDEFAULT_DATA_SUCCESS:
            return Object.assign({},state,{
                mileageDefaultData: fetchZones(action.data.mileageDefaultData, action.data.zones),
                uploadMileageDefaultSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_MILEAGEDEFAULT_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_MILEAGEDEFAULT_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadMileageDefaultSuccess: null
            });
        case UPLOAD_MILEAGEDEFAULT_SUCCESS:
            return Object.assign({}, state, {
                mileageDefaultData: fetchZones(action.data.mileageDefaultData, action.data.zones),
                pending: false,
                error: null,
                uploadMileageDefaultSuccess: true
            });
        case UPLOAD_MILEAGEDEFAULT_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadMileageDefaultSuccess: false
            });
        case SAVE_MILEAGEDEFAULT_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_MILEAGEDEFAULT_TRANSACTION_SUCCESS:
            return Object.assign({}, state,{
                pending: false,
                mileageDefaultData:fetchZones(action.data.mileageDefaultData, action.data.zones),
                transactionSaveSuccess: true
            });
        case SAVE_MILEAGEDEFAULT_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_MILEAGEDEFAULT_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}

