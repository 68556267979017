import { coreAdjTabValidationService, getCoreAdjData, getCoreAdjTabErrorStatus, saveRfCoreAdjTransactions, uploadCoreAdjCSV, getCoreAdjFilterData, deleteAllCoreAdjData, insertS3UploadCoreAdjCSV, getCoreAdjustmentsErrorData, getCoreAdjustmentsSearchData } from "../../services/revenuefunction/mainrfinputs/coreAdjustments/CoreAdjustmentService";
import { getRFTncAllTabsValidationStatus, getRFTncTabsErrorStatus } from "../../services/revenuefunction/mainrfinputs/rftnc_tabs_errorstatus_services";
import { getTncEvData, saveRfTncEvTransactions, uploadTncEvCSV, tncEvTabValidationService, getTncEvTabErrorStatus, getTncEvFilterData, deleteAllTncEvData, insertS3UploadTncEvCSV, getTncEvErrorData, getTncEvSearchData } from "../../services/revenuefunction/mainrfinputs/tncev/TncEvService";
import { getTncIceData, saveRfTncIceTransactions, uploadTncIceCSV, tncIceTabValidationService, getTncIceTabErrorStatus, getTncIceFilterData, deleteAllTncIceData, insertS3UploadTncIceCSV, getTncIceErrorData, getTncIceSearchData } from "../../services/revenuefunction/mainrfinputs/tncice/TncIceService";
import { getTncParameterData, getTncParametersTabErrorStatus, saveRfTncParameterTransactions } from "../../services/revenuefunction/mainrfinputs/tncparameter/TncParameterService";
import { getTncRevenueData, getTncRevenueFilterData } from "../../services/revenuefunction/revenue/tncrevenue/TncRevenueServices";
import { getCoreRevenueData } from "../../services/revenuefunction/revenue/corerevenue/CoreRevenueServices";
import { RF_UPDATEGRID_ONVALIDATION } from "../revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions";
import {
    FETCH_GRID_DATA_ERROR, FETCH_GRID_DATA_PENDING, FETCH_GRID_DATA_SUCCESS, RESET_TO_GRID_DATA_INITIALSTATE, SAVE_GRID_DATA_TRANSACTION_ERROR, SAVE_GRID_DATA_TRANSACTION_PENDING, SAVE_GRID_DATA_TRANSACTION_SUCCESS, SET_GRID_DATA_USER_PAGE_VALUE, SET_GRID_NAME, UPLOAD_GRID_DATA_ERROR, UPLOAD_GRID_DATA_PENDING, UPLOAD_GRID_DATA_SUCCESS,
    SET_TABS_VALIDATION_STATUS, SET_VALIDATE_GRIDDATA_PENDING, SET_VALIDATE_GRIDDATA_ERROR, SET_VALIDATE_GRIDDATA_SUCCESS, FETCH_GRID_TAB_STATUS_PENDING, FETCH_GRID_TAB_STATUS_SUCCESS, FETCH_GRID_TAB_STATUS_ERROR, SET_GRID_FILTER_PARAMS_OPTIONS_PENDING, SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS, SET_GRID_FILTER_PARAMS_OPTIONS_ERROR, SET_GRID_DATA_FILTER_VIEW_STATUS, RESET_TABS_VALIDATION_STATUS, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_PENDING, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_SUCCESS, FETCH_ALL_DIMENSIONS_DATA_ERROR, SET_GRID_DATA_ERROR_VIEW_STATUS,
    SET_GRID_SEARCH_TEXT_SUCCESS, SET_GRID_DATA_SEARCH_VIEW_STATUS, FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_ERROR, FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_SUCCESS, FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_PENDING,
    DELETE_ALL_GRID_DATA_PENDING,
    DELETE_ALL_GRID_DATA_SUCCESS,
    DELETE_ALL_GRID_DATA_ERROR
} from "./PaginationGrid.ActionTypes";
import { coreDemandTabValidationService, getCoreDemandData, getCoreDemandFilterData, getCoreDemandTabErrorStatus, saveRfCoreDemandTransactions, uploadCoreDemandCSV, deleteAllCoreDemandData, insertS3UploadCoreDemandCSV, getCoreDemandErrorData, getCoreDemandSearchData } from "../../services/revenuefunction/mainrfinputs/coredemand/CoreDemandService";
import { getCoreParameterData, getCoreParametersTabErrorStatus, saveRfCoreParameterTransactions } from "../../services/revenuefunction/mainrfinputs/coreparameter/CoreParameterService";
import { fetchPrograms, deleteProgram, fetchProgramsDropdownService, saveTransactions, uploadProgramCSV, getProgramsFilterData, deleteAllProgramsData, insertS3UploadProgramCSV, getProgramTabErrorStatus, programTabValidationService, getProgramsErrorData, getProgramsSearchData } from '../../services/programs/ProgramsService';
import { translateErrorCodes } from "../../utils/utils";
import { getRFCoreAllTabsValidationStatus, getRFCoreTabsErrorStatus } from "../../services/revenuefunction/mainrfinputs/rfcore_tabs_errorstatus_services";
import { FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_PENDING, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from "../topbar/AllPlans.ActionTypes";
import { fetchAllPlansWithDimensions, fetchLastUpdatedCacheTime } from "../../services/topbar/AllPlansService";
import { addNotificationMessage } from "../topbar/AllPlans.Actions";
import { MessageNotificationValues } from "../../Constants";
import { deleteAllSchedulesData, getAllSchedulesData, getScheduleErrorData, getScheduleFilterData, getScheduleSearchData, getScheduleTabErrorStatus, getSchedulesErrorData, insertS3UploadSchedulesCSV, saveScheduleTransactions, scheduleTabValidationService, uploadSchedulesCSV } from "../../services/schedules/SchedulesService";
import { constraintsTabValidationService, deleteAllConstraintsData, fetchConstraintsTopLevelFilterOptions, getAllConstraints, getConstraintSearchData, getConstraintsErrorData, getConstraintsFilterData, getConstraintsTabErrorStatus, insertS3UploadConstraintsCSV, saveConstraintTransactions, updateConstraintTableOnUpload, uploadConstraintsCSV } from "../../services/constraints/constraintsservice";
import { deleteAllMoveConstraintsData, getAllMoveConstraints, getMoveConstraintSearchData, getMoveConstraintsErrorData, getMoveConstraintsFilterData, getMoveConstraintsTabErrorStatus, insertS3UploadMoveConstraintsCSV, moveConstraintsTabValidationService, saveMoveConstraintTransactions, uploadMoveConstraintsCSV } from "../../services/moveconstraints/moveconstraintsservice";
import { allocatedTabValidationService, deleteAllAllocatedData, getAllAllocatedData, getAllocatedErrorData, getAllocatedFilterData, getAllocatedSearchData, getAllocatedTabErrorStatus, insertS3UploadAllocatedCSV, saveAllocatedTransactions, uploadAllocatedCSV } from "../../services/allocated/AllocatedService";
import { deleteAllUpgradePathsData, fetchUpgradePathsData, fetchUpgradeTemplatesData, getUpgradePathSearchData, getUpgradePathsErrorData, getUpgradePathsFilterData, getUpgradePathsTabErrorStatus, insertS3UploadUpgradePathsCSV, saveUpgradePaths, saveUpgradeTemplateTransactions, upgradePathsTabValidationService, uploadUpgradePathsCSV } from "../../services/upgradepaths/UpgradeTemplatesService";
import { DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY } from "../topbar/AllPlans.ActionTypes";
import { MAIN_AND_PLANINPUTS_UPDATEGRID_ONVALIDATION } from "../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions";
import { getAllInputTabsErrorStatus, getAllInputTabsValidationStatus } from "../../services/topbar/all_input_tabs_errorstatus_services";
import { ageMileageTabValidationService, deleteAllAgeMileageData, getAgeMileageData, getAgeMileageErrorData, getAgeMileageFilterData, getAgeMileageSearchData, getAgeMileageTabErrorStatus, insertS3UploadAgeMileageCSV, saveAgeMileageTransactions, uploadAgeMileageCSV } from "../../services/agemileage/AgeMileageService";
import { additionalCostTabValidationService, deleteAllAdditionalCostData, getAdditionalCostData, getAdditionalCostErrorData, getAdditionalCostFilterData, getAdditionalCostSearchData, getAdditionalCostTabErrorStatus, insertS3UploadAdditionalCostCSV, saveAdditionalCostTransactions, uploadAdditionalCostCSV } from "../../services/additionalcost/AdditionalCostService";
import { deleteAllMaxDeletesData, getMaxDeletesData, getMaxDeletesErrorData, getMaxDeletesFilterData, getMaxDeletesSearchData, getMaxDeletesTabErrorStatus, insertS3UploadMaxDeletesCSV, maxDeletesTabValidationService, saveMaxDeletesTransactions, uploadMaxDeletesCSV } from "../../services/maxdeletes/MaxDeletesService";
import { deleteAllSalvageRateData, getSalvageRateData, getSalvageRateFilterData, insertS3UploadSalvageRateCSV, saveSalvageRateTransactions, uploadSalvageRateCSV, getSalvageRateErrorData, getSalvageRateSearchData, getSalvageRateTabErrorStatus, salvagerateTabValidationService } from "../../services/salvagerate/SalvageRateService";
import { addsinvalidpoolsTabValidationService, deleteAllAddsInvalidPoolsData, getAddsInvalidPoolsData, getAddsInvalidPoolsErrorData, getAddsInvalidPoolsFilterData, getAddsInvalidPoolsSearchData, getAddsInvalidPoolsTabErrorStatus, insertS3UploadAddsInvalidPoolsCSV, saveAddsInvalidPoolsTransactions, uploadAddsInvalidPoolsCSV } from "../../services/addsinvalidpools/AddsInvalidPoolsService";
import { deleteAllMileageDefaultData, getMileageDefaultData, getMileageDefaultErrorData, getMileageDefaultFilterData, getMileageDefaultSearchData, getMileageDefaultTabErrorStatus, insertS3UploadMileageDefaultCSV, mileagedefaultTabValidationService, saveMileageDefaultTransactions, uploadMileageDefaultCSV } from "../../services/mileagedefault/MileageDefaultService";
import { deleteAllDepRateData, depRateTabValidationService, getDepRateData, getDepRateErrorData, getDepRateFilterData, getDepRateSearchData, getDepRateTabErrorStatus, insertS3UploadDepRateCSV, saveDepRateTransactions, uploadDepRateCSV } from "../../services/deprate/DepRateService";
import { deleteAllTargetZonePoolData, getTargetZonePoolData, getTargetZonePoolErrorData, getTargetZonePoolFilterData, getTargetZonePoolSearchData, getTargetZonePoolTabErrorStatus, insertS3UploadTargetZonePoolCSV, saveTargetZonePoolTransactions, targetzonepoolTabValidationService, uploadTargetZonePoolCSV } from "../../services/targetzonepool/TargetZonePoolService";
import { deleteAllDiversionsData, diversionsTabValidationService, getDiversionsData, getDiversionsErrorData, getDiversionsFilterData, getDiversionsSearchData, getDiversionsTabErrorStatus, insertS3UploadDiversionsCSV, saveDiversionsTransactions, uploadDiversionsCSV } from "../../services/diversions/DiversionsService";
import { fetchParameters, getParameterTabErrorStatus, getParametersSearchData, parameterTabValidationService, saveParameterTransactions} from "../../services/parameters/ParametersService";
import { deleteAllMileageTableData, getMileageTableData, getMileageTableErrorData, getMileageTableFilterData, getMileageTableSearchData, getMileageTableTabErrorStatus, insertS3UploadMileageTableCSV, mileageTableTabValidationService, saveMileageTableTransactions, uploadMileageTableCSV } from "../../services/mileagetable/MileageTableService";
import { InsertS3uploadedManualVinDeletesCSV, deleteManualVinDeletesRecordsService, fetchManualVinDeletesFilterRecords, fetchManualVinDeletesSearchRecords, getManualVinDeletesData, getManualVinDeletesErrorData, getManualVinDeletesTabErrorStatus, manualVinDeletesTabValidationService, saveManualVinDeletesTransactions, uploadManualVinDeletesCSV } from "../../services/manualvindeletes/ManualVinDeletesService";
import { deleteMoveCostRecordsService, fetchMoveCostFilterRecords, fetchMoveCostSearchRecords, getMoveCostData, getMoveCostErrorData, getMoveCostTabErrorStatus, insertUploadData, moveCostTabValidationService, saveMoveCostTransactions, uploadMoveCostCSV } from "../../services/movecost/movecostservice";
import {getBvDepOverrideData, deleteAllBvDepOverrideData,uploadBvDepOverrideGrid, insertS3UploadedBvDepOverRideData,getBvDepOverrideErrorData, bvDepOverRideValidationService, getBvDepOverrideTabErrorStatus} from '../../services/salesandfinance/bvdepoverride/BvDepOverrideService'
import { getAllSalesFinanceErrorStatus } from "../../services/salesandfinance/salesandfinance_tabs_errorstatus_services";

const getApiServices = async (id, gridname, pageno) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await getTncIceData(id, pageno);
            break;
        case "tncevgrid":
            response = await getTncEvData(id, pageno);
            break;
        case "tncparametergrid":
            response = await getTncParameterData(id, pageno);
            break;
        case "tncrevenuegrid":
            response = await getTncRevenueData(id, pageno);
            break;
        case "corerevenuegrid":
            response = await getCoreRevenueData(id, pageno);
            break;
        case "coredemandgrid":
            response = await getCoreDemandData(id, pageno);
            break;
        case "coreadjustmentsgrid":
            response = await getCoreAdjData(id, pageno);
            break;
        case "coreparametergrid":
            response = await getCoreParameterData(id, pageno);
            break;
        case "programsGrid":
            response = await fetchPrograms(id, pageno);
            break;
        case "schedulesGrid":
            response = await getAllSchedulesData(id, pageno);
            break;
        case "allocatedGrid":
            response = await getAllAllocatedData(id, pageno);
            break;
        case "constraintsGrid":
            response = await getAllConstraints(id, pageno);
            break;
        case "moveconstraintsGrid":
            response = await getAllMoveConstraints(id, pageno);
            break;
        case "upgradepathsGrid":
            response = await fetchUpgradePathsData(id, pageno);
            break;
        case "upgradetemplatesGrid":
            response = await fetchUpgradeTemplatesData(id);
            break;
        case "agemileageGrid":
            response = await getAgeMileageData(id,pageno)
            break;
        case "additionalcostGrid":
            response= await getAdditionalCostData(id,pageno);
            break;
        case "maxdeleteGrid":
            response= await getMaxDeletesData(id,pageno);
            break;
        case "salvagerateGrid":
            response = await getSalvageRateData(id,pageno);
            break;
        case "addsinvalidpoolsGrid":
            response = await getAddsInvalidPoolsData(id,pageno)
            break;
        case "mileagedefaultGrid":
            response = await getMileageDefaultData(id,pageno)
            break;
        case "targetzonepoolGrid":
            response = await getTargetZonePoolData(id,pageno)
            break;            
        case "deprateGrid":
            response = await getDepRateData(id,pageno)
            break;
        case "diversionsGrid":
            response = await getDiversionsData(id,pageno)
            break;
        case "parametersGrid":
            response = await fetchParameters(id)
            break;            
        case "mileagetableGrid":
            response = await getMileageTableData(id,pageno);
            break;
        case "manualvindeletesGrid":
            response = await getManualVinDeletesData(id,pageno);
            break;
        case "movecostGrid":
            response = await getMoveCostData(id,pageno);
            break;
        case "bvdepoverridegrid":
            response = await getBvDepOverrideData(id,pageno);
            break;
        default:
            response = null;
    }
    return response
}

const getFilterApiServices = async (id, gridname, pageno, filterparams) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await getTncIceFilterData(id, pageno, filterparams);
            break;
        case "tncevgrid":
            response = await getTncEvFilterData(id, pageno, filterparams);
            break;
        case "coredemandgrid":
            response = await getCoreDemandFilterData(id, pageno, filterparams);
            break;
        case "coreadjustmentsgrid":
            response = await getCoreAdjFilterData(id, pageno, filterparams);
            break;
        case "programsGrid":
            response = await getProgramsFilterData(id, pageno, filterparams);
            break;
        case "allocatedGrid":
            response = await getAllocatedFilterData(id, pageno, filterparams);
            break;
        case "schedulesGrid":
            response = await getScheduleFilterData(id, pageno, filterparams);
            break;
        case "constraintsGrid":
            response = await getConstraintsFilterData(id, pageno, filterparams);
            break;
        case "moveconstraintsGrid":
            response = await getMoveConstraintsFilterData(id, pageno, filterparams)
            break;
        case "upgradepathsGrid":
            response = await getUpgradePathsFilterData(id, pageno, filterparams)
            break;
        case "tncrevenuegrid":
            response = await getTncRevenueFilterData(id, pageno, filterparams);
            break;
        case "agemileageGrid":
            response = await getAgeMileageFilterData(id, pageno, filterparams)
            break;
        case "additionalcostGrid":
            response= await getAdditionalCostFilterData(id, pageno, filterparams);
            break;
        case "maxdeleteGrid":
            response= await getMaxDeletesFilterData(id, pageno, filterparams);
            break;
        case "salvagerateGrid":
            response = await getSalvageRateFilterData(id, pageno, filterparams);
            break;
        case "addsinvalidpoolsGrid":
            response = await getAddsInvalidPoolsFilterData(id,pageno,filterparams)
            break;
        case "mileagedefaultGrid":
            response = await getMileageDefaultFilterData(id,pageno,filterparams)
            break;
        case "targetzonepoolGrid":
            response = await getTargetZonePoolFilterData(id,pageno,filterparams)
            break;
        case "deprateGrid":
            response = await getDepRateFilterData(id,pageno,filterparams)
            break;
        case "diversionsGrid":
            response = await getDiversionsFilterData(id,pageno,filterparams)
            break;
        case "mileagetableGrid":
            response = await getMileageTableFilterData(id,pageno,filterparams)
            break;
        case "manualvindeletesGrid":
            response = await fetchManualVinDeletesFilterRecords(id,pageno,filterparams);
            break;
        case "movecostGrid":
            response = await fetchMoveCostFilterRecords(id,filterparams,pageno);
            break;
        default:
            response = null;
    }
    return response
}

const getSearchApiServices = async (id, gridname, pageno, searchParams) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await getTncIceSearchData(id, pageno, searchParams);
            break;
        case "tncevgrid":
            response = await getTncEvSearchData(id, pageno, searchParams);
            break;
        case "coredemandgrid":
            response = await getCoreDemandSearchData(id, pageno, searchParams);
            break;
        case "coreadjustmentsgrid":
            response = await getCoreAdjustmentsSearchData(id, pageno, searchParams);
            break;
        case "programsGrid":
            response = await getProgramsSearchData(id, pageno, searchParams);
            break;
        case "schedulesGrid":
            response = await getScheduleSearchData(id, pageno, searchParams);
            break;
        case "allocatedGrid":
            response =await getAllocatedSearchData(id, pageno,searchParams);
            break;
        case "constraintsGrid":
            response = await getConstraintSearchData(id, pageno,searchParams);
            break;
        case "moveconstraintsGrid":
            response = await getMoveConstraintSearchData(id, pageno,searchParams);
            break;
        case "upgradepathsGrid":
            response = await getUpgradePathSearchData(id, pageno,searchParams);
            break;
        case "agemileageGrid":
            response = await getAgeMileageSearchData(id, pageno, searchParams)
            break;
        case "additionalcostGrid":
            response= await getAdditionalCostSearchData(id, pageno,searchParams);
            break;
        case "maxdeleteGrid":
            response= await getMaxDeletesSearchData(id, pageno,searchParams);
            break;
        case "addsinvalidpoolsGrid":
            response = await getAddsInvalidPoolsSearchData(id,pageno,searchParams)
            break;
        case "mileagedefaultGrid":
            response = await getMileageDefaultSearchData(id,pageno,searchParams)
            break;
        case "salvagerateGrid":
            response = await getSalvageRateSearchData(id,pageno,searchParams)
            break;
        case "targetzonepoolGrid":
            response = await getTargetZonePoolSearchData(id,pageno,searchParams)
            break;
        case "deprateGrid":
            response = await getDepRateSearchData(id,pageno,searchParams)
            break;
        case "diversionsGrid":
            response = await getDiversionsSearchData(id,pageno,searchParams)
            break;
        case "parametersGrid":
            response = await getParametersSearchData(id,pageno,searchParams)
            break;
        case "mileagetableGrid":
            response = await getMileageTableSearchData(id,pageno,searchParams);
            break;
        case "manualvindeletesGrid":
            response = await fetchManualVinDeletesSearchRecords(id,pageno,searchParams);
            break;
        case "movecostGrid":
            response = await fetchMoveCostSearchRecords(id,searchParams,'',pageno);
            break;
        default:
            response = null;
    }
    return response;
}

const getTopLevelFilterOptionsApiServices = async(gridname, id)=>{
    let response;
    switch (gridname) {
        case "constraintsGrid":
            response = await fetchConstraintsTopLevelFilterOptions(id);
            break;
        default:
            response = null;
    }
    return response;
}

const uploadApiServices = async (id, file, gridname, tabName) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await uploadTncIceCSV(id, tabName, file);
            break;
        case "tncevgrid":
            response = await uploadTncEvCSV(id, tabName, file);
            break;
        case "coredemandgrid":
            response = await uploadCoreDemandCSV(id, tabName, file);
            break;
        case "coreadjustmentsgrid":
            response = await uploadCoreAdjCSV(id, tabName, file);
            break;
        case "programsGrid":
            response = await uploadProgramCSV(id, file);
            break;
        case "schedulesGrid":
            response = await uploadSchedulesCSV(id, file);
            break;
        case "allocatedGrid":
            response = await uploadAllocatedCSV(id, file);
            break;
        case "constraintsGrid":
            response = await uploadConstraintsCSV(id, file);
            break;
        case "moveconstraintsGrid":
            response = await uploadMoveConstraintsCSV(id, file);
            break;
        case "upgradepathsGrid":
            response = await uploadUpgradePathsCSV(id, file)
            break;
        case "agemileageGrid":
            response = await uploadAgeMileageCSV(id, file)
            break;
        case "additionalcostGrid":
            response= await uploadAdditionalCostCSV(id, file);
            break;
        case "maxdeleteGrid":
            response= await uploadMaxDeletesCSV(id, file);
            break;
        case "salvagerateGrid":
            response = await uploadSalvageRateCSV(id, file);
            break;
        case "addsinvalidpoolsGrid":
            response = await uploadAddsInvalidPoolsCSV(id,file)
            break;
        case "mileagedefaultGrid":
            response = await uploadMileageDefaultCSV(id,file)
            break;
        case "targetzonepoolGrid":
            response = await uploadTargetZonePoolCSV(id,file)
            break;
        case "deprateGrid":
            response = await uploadDepRateCSV(id,file)
            break;
        case "diversionsGrid":
            response = await uploadDiversionsCSV(id,file)
            break;
        case "mileagetableGrid":
            response = await uploadMileageTableCSV(id,file);
            break;
        case "manualvindeletesGrid":
            response = await uploadManualVinDeletesCSV(id,file);
            break;
        case "movecostGrid":
            response = await uploadMoveCostCSV(id,file);
            break;
        case "bvdepoverridegrid":
            response = await uploadBvDepOverrideGrid(id,file);
            break;
        default:
            response = null;
    }
    return response
}

const insertS3UploadedApiServices = async (id, filename, gridname) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await insertS3UploadTncIceCSV(id, filename, gridname);
            break;
        case "tncevgrid":
            response = await insertS3UploadTncEvCSV(id, filename, gridname);
            break;
        case "coredemandgrid":
            response = insertS3UploadCoreDemandCSV(id, filename, gridname)
            break;
        case "coreadjustmentsgrid":
            response = insertS3UploadCoreAdjCSV(id, filename, gridname);
            break;
        case "schedulesGrid":
            response = await insertS3UploadSchedulesCSV(id, filename, gridname);
            break;
        case "moveconstraintsGrid":
            response = await insertS3UploadMoveConstraintsCSV(id, filename, gridname);
            break;
        case "programsGrid":
            response = await insertS3UploadProgramCSV(id, filename, gridname);
            break;
        case "allocatedGrid":
            response = await insertS3UploadAllocatedCSV(id, filename, gridname);
            break;
        case "upgradepathsGrid":
            response = await insertS3UploadUpgradePathsCSV(id, filename, gridname);
            break;
        case "constraintsGrid":
            response = await insertS3UploadConstraintsCSV(id, filename, gridname);
            break;
        case "agemileageGrid":
            response = await insertS3UploadAgeMileageCSV(id, filename, gridname);
            break;
        case "additionalcostGrid":
            response= await insertS3UploadAdditionalCostCSV(id, filename, gridname);
            break;
        case "maxdeleteGrid":
            response= await insertS3UploadMaxDeletesCSV(id, filename, gridname);
            break;
        case "salvagerateGrid":
            response = await insertS3UploadSalvageRateCSV(id, filename, gridname);
            break;
        case "addsinvalidpoolsGrid":
            response = await insertS3UploadAddsInvalidPoolsCSV(id,filename, gridname)
            break;
        case "mileagedefaultGrid":
            response = await insertS3UploadMileageDefaultCSV(id,filename, gridname)
            break;
        case "targetzonepoolGrid":
            response = await insertS3UploadTargetZonePoolCSV(id,filename, gridname)
            break;
        case "deprateGrid":
            response = await insertS3UploadDepRateCSV(id,filename, gridname)
            break;
        case "diversionsGrid":
            response = await insertS3UploadDiversionsCSV(id,filename, gridname)
            break;
        case "mileagetableGrid":
            response = await insertS3UploadMileageTableCSV(id,filename, gridname);
            break;
        case "manualvindeletesGrid":
            response = await InsertS3uploadedManualVinDeletesCSV(id,filename, gridname);
            break;
        case "movecostGrid":
            response = await insertUploadData(id,filename, gridname);
            break;
        case "bvdepoverridegrid":
            response = await insertS3UploadedBvDepOverRideData(id,filename, gridname)
            break;    
        default:
            response = null;
    }
    return response
}

const singleTabValidationApiServices = async (gridname, id, transactionList) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await tncIceTabValidationService(id, transactionList);
            break;
        case "tncevgrid":
            response = await tncEvTabValidationService(id, transactionList);
            break;
        case "coredemandgrid":
            response = await coreDemandTabValidationService(id, transactionList);
            break;
        case "coreadjustmentsgrid":
            response = await coreAdjTabValidationService(id, transactionList);
            break;
        case "constraintsGrid":
            if (transactionList && transactionList.length == 0) {
                await updateConstraintTableOnUpload(id);
            }
            response = await constraintsTabValidationService(id, transactionList);
            break;
        case "programsGrid":
            response = await programTabValidationService(id, transactionList);
            break;
        case "schedulesGrid":
            response = await scheduleTabValidationService(id, transactionList);
            break;
        case "upgradepathsGrid":
            response = await upgradePathsTabValidationService(id, transactionList);
            break;
        case "allocatedGrid":
            response = await allocatedTabValidationService(id, transactionList);
            break;
        case "moveconstraintsGrid":
            response = await moveConstraintsTabValidationService(id, transactionList);
            break;
        case "agemileageGrid":
            response = await ageMileageTabValidationService(id, transactionList);
            break;
        case "addsinvalidpoolsGrid":
            response = await addsinvalidpoolsTabValidationService(id,transactionList)
            break;
        case "mileagedefaultGrid":
            response = await mileagedefaultTabValidationService(id,transactionList)
            break;
        case "salvagerateGrid":
            response = await salvagerateTabValidationService(id,transactionList)
            break;           
        case "additionalcostGrid":
            response= await additionalCostTabValidationService(id, transactionList);
            break;
        case "maxdeleteGrid":
            response= await maxDeletesTabValidationService(id, transactionList);
            break;
        case "targetzonepoolGrid":
            response = await targetzonepoolTabValidationService(id,transactionList)
            break;
        case "deprateGrid":
            response = await depRateTabValidationService(id,transactionList)
            break;
        case "diversionsGrid":
            response = await diversionsTabValidationService(id,transactionList)
            break;
        case "mileagetableGrid":
            response = await mileageTableTabValidationService(id, transactionList);
            break;
        case "manualvindeletesGrid":
            response = await manualVinDeletesTabValidationService(id, transactionList);
            break;
        case "movecostGrid":
            response = await moveCostTabValidationService(id, transactionList);
            break;
        case "parametersGrid":
            response = await parameterTabValidationService(id, transactionList);
            break;
        case "bvdepoverridegrid":
            response = await bvDepOverRideValidationService(id, transactionList);
            break;
        default:
            response = false;
    }

    return response;
}

const multiTabValidationApiServices = async (id, typeName, moduleName) => {
    let response;
    switch (moduleName) {
        case "RF":
            switch (typeName) {
                case "TNC":
                    response = await getRFTncAllTabsValidationStatus(id, []);
                    break;
                case "CORE":
                    response = await getRFCoreAllTabsValidationStatus(id, []);
                    break;
                case "CARGO":
                    //todo
                    break
            }
            break;
        case "MAIN_AND_PLAN-INPUTS":
            response = await getAllInputTabsValidationStatus(id, [])
            break;
    }
    return response;
}

const getErrorApiServices = async (id, gridname, pageno) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await getTncIceErrorData(id, pageno);
            break;
        case "tncevgrid":
            response = await getTncEvErrorData(id, pageno);
            break;
        case "coredemandgrid":
            response = await getCoreDemandErrorData(id, pageno);
            break;
        case "coreadjustmentsgrid":
            response = await getCoreAdjustmentsErrorData(id, pageno);
            break;
        case "programsGrid":
            response = await getProgramsErrorData(id, pageno);
            break;
        case "allocatedGrid":
            response = await getAllocatedErrorData(id, pageno);
            break;
        case "schedulesGrid":
            response = await getScheduleErrorData(id, pageno);
            break;
        case "constraintsGrid":
            response = await getConstraintsErrorData(id, pageno);
            break;
        case "moveconstraintsGrid":
            response = await getMoveConstraintsErrorData(id, pageno)
            break;
        case "upgradepathsGrid":
            response = await getUpgradePathsErrorData(id, pageno)
            break;
        case "tncrevenuegrid":
            // response=await getTncRevenueErrorData(id,pageno,errorparams);
            break;
        case "agemileageGrid":
            response = await getAgeMileageErrorData(id, pageno);
            break;
        case "additionalcostGrid":
            response= await getAdditionalCostErrorData(id, pageno);
            break;
        case "maxdeleteGrid":
            response= await getMaxDeletesErrorData(id, pageno);
            break;
        case "addsinvalidpoolsGrid":
            response = await getAddsInvalidPoolsErrorData(id, pageno);
            break;
        case "mileagedefaultGrid":
            response = await getMileageDefaultErrorData(id,pageno)
            break;
        case "salvagerateGrid":
            response = await getSalvageRateErrorData(id,pageno)
            break;
        case "targetzonepoolGrid":
            response = await getTargetZonePoolErrorData(id,pageno)
            break;
        case "deprateGrid":
            response = await getDepRateErrorData(id,pageno)
            break;
        case "diversionsGrid":
            response = await getDiversionsErrorData(id,pageno)
            break;
        case "mileagetableGrid":
            response = await getMileageTableErrorData(id,pageno);
            break;
        case "manualvindeletesGrid":
            response = await getManualVinDeletesErrorData(id,pageno);
            break;
        case "movecostGrid":
            response = await getMoveCostErrorData(id,null,pageno);
            break;
        case "bvdepoverridegrid":
            response = await getBvDepOverrideErrorData(id,pageno);
            break;
        default:
            response = null;
    }
    return response
}

const saveTransactionsApiServices = async (id, transactionList, gridname, tabName, typeName) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await saveRfTncIceTransactions(id, transactionList, tabName);
            break;
        case "tncevgrid":
            response = await saveRfTncEvTransactions(id, transactionList, tabName);
            break;
        case "tncparametergrid":
            response = await saveRfTncParameterTransactions(id, transactionList, tabName, typeName);
            break;
        case "coredemandgrid":
            response = await saveRfCoreDemandTransactions(id, transactionList, tabName);
            break;
        case "coreadjustmentsgrid":
            response = await saveRfCoreAdjTransactions(id, transactionList, tabName);
            break;
        case "coreparametergrid":
            response = await saveRfCoreParameterTransactions(id, transactionList, tabName, typeName);
            break;
        case "programsGrid":
            response = await saveTransactions(id, transactionList);
            break;
        case "schedulesGrid":
            response = await saveScheduleTransactions(id, transactionList);
            break;
        case "allocatedGrid":
            response = await saveAllocatedTransactions(id, transactionList);
            break;
        case "constraintsGrid":
            response = await saveConstraintTransactions(id, transactionList);
            break;
        case "moveconstraintsGrid":
            response = await saveMoveConstraintTransactions(id, transactionList);
            break;
        case "upgradetemplatesGrid":
            response = await saveUpgradeTemplateTransactions(id, transactionList);
            break;
        case "upgradepathsGrid":
            response = await saveUpgradePaths(id, transactionList);
            break;
        case "agemileageGrid":
            response = await saveAgeMileageTransactions(id, transactionList);
            break;
        case "additionalcostGrid":
            response = await saveAdditionalCostTransactions(id, transactionList);
            break;
        case "maxdeleteGrid":
            response =await saveMaxDeletesTransactions(id, transactionList);
            break;
        case "salvagerateGrid":
            response= await saveSalvageRateTransactions(id, transactionList);
            break;
        case "addsinvalidpoolsGrid":
            response = await saveAddsInvalidPoolsTransactions(id, transactionList);
            break;  
        case "mileagedefaultGrid":
            response = await saveMileageDefaultTransactions(id,transactionList)
            break;
        case "targetzonepoolGrid":
            response = await saveTargetZonePoolTransactions(id,transactionList)
            break;
        case "deprateGrid":
            response = await saveDepRateTransactions(id,transactionList)
            break;
        case "diversionsGrid":
            response = await saveDiversionsTransactions(id,transactionList)
            break;
        case "parametersGrid":
            response = await saveParameterTransactions(id,transactionList)
            break;  
        case "mileagetableGrid":
            response= await saveMileageTableTransactions(id,transactionList);
            break;
        case "manualvindeletesGrid":
            response = await saveManualVinDeletesTransactions(id,transactionList);
            break;
        case "movecostGrid":
            response = await saveMoveCostTransactions(id,transactionList);
            break;
        default:
            response = null;
    }
    return response
}

const deleteAllGridDataApiServices = async (id, gridname, deleteParams) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await deleteAllTncIceData(id, deleteParams);
            break;
        case "tncevgrid":
            response = await deleteAllTncEvData(id, deleteParams);
            break;
        case "coredemandgrid":
            response = await deleteAllCoreDemandData(id, deleteParams);
            break;
        case "coreadjustmentsgrid":
            response = await deleteAllCoreAdjData(id, deleteParams);
            break;
        case "schedulesGrid":
            response = await deleteAllSchedulesData(id, deleteParams);
            break;
        case "allocatedGrid":
            response = await deleteAllAllocatedData(id, deleteParams);
            break;
        case "moveconstraintsGrid":
            response = await deleteAllMoveConstraintsData(id, deleteParams);
            break;
        case "programsGrid":
            response = await deleteAllProgramsData(id, deleteParams);
            break;
        case "constraintsGrid":
            response = await deleteAllConstraintsData(id, deleteParams)
            break;
        case "upgradepathsGrid":
            response = await deleteAllUpgradePathsData(id, deleteParams);
            break;
        case "agemileageGrid":
            response = await deleteAllAgeMileageData(id, deleteParams);
            break;
        case "additionalcostGrid":
            response = await deleteAllAdditionalCostData(id, deleteParams);
            break;
        case "maxdeleteGrid":
            response =await deleteAllMaxDeletesData(id, deleteParams);
            break;
        case "salvagerateGrid":
            response = await deleteAllSalvageRateData(id, deleteParams);
            break;
        case "addsinvalidpoolsGrid":
            response = await deleteAllAddsInvalidPoolsData(id, deleteParams);
            break;
        case "mileagedefaultGrid":
            response = await deleteAllMileageDefaultData(id,deleteParams)
            break;
        case "targetzonepoolGrid":
            response = await deleteAllTargetZonePoolData(id,deleteParams)
            break;
        case "deprateGrid":
            response = await deleteAllDepRateData(id,deleteParams)
            break;
        case "diversionsGrid":
            response = await deleteAllDiversionsData(id,deleteParams)
            break;
        case "mileagetableGrid":
            response = await deleteAllMileageTableData(id,deleteParams);
            break;
        case "manualvindeletesGrid":
            response = await deleteManualVinDeletesRecordsService(id,deleteParams);
            break;
        case "movecostGrid":
            response = await deleteMoveCostRecordsService(id,deleteParams);
            break;
        case "bvdepoverridegrid":
            response = await deleteAllBvDepOverrideData(id,deleteParams);
            break;
        default:
            response = null;
    }
    return response
}

const multiTabErrorStatusApiServices = async (id, typeName, moduleName) => {
    let response;
    switch (moduleName) {
        case "RF":
            switch (typeName) {
                case "TNC":
                    response = await getRFTncTabsErrorStatus(id);
                    break;
                case "CORE":
                    response = await getRFCoreTabsErrorStatus(id);
                    break;
                case "CARGO":
                    //todo
                    break
            }
            break;
        case "MAIN_AND_PLAN-INPUTS":
            response = await getAllInputTabsErrorStatus(id);
            break;
        case "SALES_AND_FINANCE":
            response = await getAllSalesFinanceErrorStatus(id);
            break;
    }
    return response;
}

const singleTabErrorStatusApiServices = async (id, gridname) => {
    let response;
    switch (gridname) {
        case "tncicegrid":
            response = await getTncIceTabErrorStatus(id);
            break;
        case "tncevgrid":
            response = await getTncEvTabErrorStatus(id);
            break;
        case "tncparametergrid":
            response = await getTncParametersTabErrorStatus(id);
            break;
        case "coredemandgrid":
            response = await getCoreDemandTabErrorStatus(id);
            break;
        case "coreadjustmentsgrid":
            response = await getCoreAdjTabErrorStatus(id);
            break;
        case "coreparametergrid":
            response = await getCoreParametersTabErrorStatus(id);
            break;
        case "constraintsGrid":
            response = await getConstraintsTabErrorStatus(id);
            break;
        case "programsGrid":
            response = await getProgramTabErrorStatus(id);
            break;
        case "schedulesGrid":
            response = await getScheduleTabErrorStatus(id);
            break;
        case "upgradepathsGrid":
            response = await getUpgradePathsTabErrorStatus(id);
            break;
        case "allocatedGrid":
            response = await getAllocatedTabErrorStatus(id);
            break;
        case "moveconstraintsGrid":
            response = await getMoveConstraintsTabErrorStatus(id);
            break;
        case "agemileageGrid":
            response = await getAgeMileageTabErrorStatus(id);
            break;
        case "additionalcostGrid":
            response= await getAdditionalCostTabErrorStatus(id);
            break;
        case "maxdeleteGrid":
            response= await getMaxDeletesTabErrorStatus(id);
            break;
        case "addsinvalidpoolsGrid":
            response = await getAddsInvalidPoolsTabErrorStatus(id);
            break;
        case "mileagedefaultGrid":
            response = await getMileageDefaultTabErrorStatus(id)
            break;
         case "salvagerateGrid":
            response = await getSalvageRateTabErrorStatus(id)
            break;
        case "targetzonepoolGrid":
            response = await getTargetZonePoolTabErrorStatus(id)
            break;
         case "deprateGrid":
            response = await getDepRateTabErrorStatus(id)
            break;
        case "diversionsGrid":
            response = await getDiversionsTabErrorStatus(id)
            break;
        case "mileagetableGrid":
            response = await getMileageTableTabErrorStatus(id);
            break;
        case "manualvindeletesGrid":
            response = await getManualVinDeletesTabErrorStatus(id);
            break;
        case "movecostGrid":
            response = await getMoveCostTabErrorStatus(id);
            break;
        case "parametersGrid":
            response = await getParameterTabErrorStatus(id);
            break;
        case "bvdepoverridegrid":
            response = await getBvDepOverrideTabErrorStatus(id);
            break;
        default:
            response = false;
    }
    return response
}

const initializeFilterParams = (gridColumnDefs) => {
    let filterParams = {};
    if (gridColumnDefs && gridColumnDefs.columnDefs && gridColumnDefs.columnDefs.length > 0) {
        gridColumnDefs.columnDefs.forEach((fields) => {
            if (fields && fields.field && fields.field != '') {
                if (fields.filterType === 'dropdown') {
                    filterParams[fields.field] = [];
                }
                else if (fields.filterType === 'datepicker') {
                    filterParams[fields.field] = ["between"];
                }
                else if (fields.filterType === 'range') {
                    filterParams[fields.field] = ["between"];
                }
                else if (fields.filterType === 'input') {
                    filterParams[fields.field] = "";
                }
            }
        });

    }
    return filterParams;
}

/**
 * @param {number} id - can be either planid or rfid
 * @param {string} gridname - refers to which grid user is working on
 * @param {number} pageno - refers to which page user wants the data 
 * @param {Object} filterParams - refers to the filter options applied
 * @param {boolean} filterView - referes to wheter the current grid is in filter mode or not 
 * @param {boolean} errorView - referes to wheter the current grid is in error mode or not 
 */
export const fetchGridData = (id, gridname, pageno, filterParams, filterView, errorView, searchParams, serachView) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_GRID_DATA_PENDING));
            let response;
            if (filterView) {
                response = await getFilterApiServices(id, gridname, pageno, filterParams)
            } else if (errorView) {
                response = await getErrorApiServices(id, gridname, pageno)
            } else if (serachView) {
                response = await getSearchApiServices(id, gridname, pageno, searchParams)
            } else {
                response = await getApiServices(id, gridname, pageno);
            }
            if (response) {
                dispatch(serviceActionSuccess(FETCH_GRID_DATA_SUCCESS, response));
            } else {
                dispatch(serviceActionError(FETCH_GRID_DATA_ERROR, "Unknown error"));
            }
        }
        catch (message) {
            console.error(message);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `${message}`))
            dispatch(serviceActionError(FETCH_GRID_DATA_ERROR, "Unknown error"));
        }
    }
}


/**
 * @param {number} id - id can be either planid or rfid
 * @param {string} gridname -refers to which grid user is working on
 */
export const fetchGridTabErrorStatus = (id, gridname) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_GRID_TAB_STATUS_PENDING));
            let response = await singleTabErrorStatusApiServices(id, gridname);
            if (response !== undefined) {
                dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, { [gridname]: response }));
                dispatch(serviceActionSuccess(FETCH_GRID_TAB_STATUS_SUCCESS));
            } else {
                dispatch(serviceActionError(FETCH_GRID_TAB_STATUS_ERROR, "Cannot get status"));
            }
        }
        catch (message) {
            console.error(message);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `${message}`))
            dispatch(serviceActionError(FETCH_GRID_TAB_STATUS_ERROR, "Cannot get status"));
        }
    }
}


/**
 * @param {number} id - id can be either planid or rfid
 * @param {string} typeName - This is used only in Revenue module and it is used to get subtab name(Like coreparameter,cargoparameter,tncparameter) based on either (CORE,TNC,CARGO) selected
 * @param {string} moduleName - This is used to tell for which module user is working like (Main/plan inputs,Admin,RF)
 */
export const fetchMultiTabErrorStatusBasedOnModule = (id, typeName, moduleName) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_GRID_TAB_STATUS_PENDING));
            let response = await multiTabErrorStatusApiServices(id, typeName, moduleName);
            if (response) {
                dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, response));
                dispatch(serviceActionSuccess(FETCH_GRID_TAB_STATUS_SUCCESS));
            } else {
                dispatch(serviceActionError(FETCH_GRID_TAB_STATUS_ERROR, "Cannot get status"));
            }
        }
        catch (message) {
            console.error(message);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `${message}`))
            dispatch(serviceActionError(FETCH_GRID_TAB_STATUS_ERROR, "Cannot get status"));
        }
    }
}

/**
 * @param {Boolean} isS3Insert - If its true then it will call insertUploadedApiServices
 * @param {string} s3UploadedFileName - The name of the file where user uploaded data is stored in S3 AWS
 * @param {number} id - id can be either planid or rfid
 * @param {BinaryType} file - refers to which user wants to upload to the grid
 * @param {string} gridname - refers to which grid user is working on
 * @param {string} tabName - This is used only in Revenue module and it is used to get subtab name(Like tncice,tncev,tncparameter) based on either (CORE,TNC,CARGO) selected
 * @param {function} loadGridData - this is a function used to fetch gridData and to validate 
 */
export const uploadGridData = (isS3Insert, s3UploadedFileName, id, file, gridname, tabName,loadGridData) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_GRID_DATA_PENDING));
            let uploadResp;
            if (isS3Insert) {
                uploadResp = await insertS3UploadedApiServices(id, s3UploadedFileName, gridname);
            } else {
                uploadResp = await uploadApiServices(id, file, gridname, tabName);
            }
            // let newGridDataAfterUpload = '';
            if (uploadResp.obj && uploadResp.obj.statusCode !== '500') {
                await loadGridData(true,1)// true is sent as parameter to validate the data and 1 is the page number to fetch data because on upload data get shuffeled
                
                //await dispatch(fetchGridData(id, gridname, pageno))
                //let tabValidationApiServiceResp = await singleTabValidationApiServices(gridname, id, []);
                // if (pageno == 1) { //it will removed after some analysis
                //     if (filterView) {
                //         newGridDataAfterUpload = await getFilterApiServices(id, gridname, 1, filterParams) //for now pageno is set to 1 because on upload data get shuffeled 
                //     } else if (searchView) {
                //         newGridDataAfterUpload = await getSearchApiServices(id, gridname, 1, searchParams) //for now pageno is set to 1 because on upload data get shuffeled
                //     }else {
                //         newGridDataAfterUpload = await getApiServices(id, gridname, 1);//for now pageno is set to 1 because on upload data get shuffeled 
                //     }
                // }
                //dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, { [gridname]: tabValidationApiServiceResp.validation_result }));
                dispatch(serviceActionSuccess(UPLOAD_GRID_DATA_SUCCESS));
                //dispatch(serviceActionSuccess(SET_VALIDATE_GRIDDATA_SUCCESS));
                dispatch(serviceActionSuccess(DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY, gridname))
            } else if (uploadResp.obj && uploadResp.obj.body) {
                let body = JSON.parse(uploadResp.obj.body);
                dispatch(serviceActionError(UPLOAD_GRID_DATA_ERROR, `Upload Failed: ${translateErrorCodes(body.error)}`));
                dispatch(serviceActionSuccess(DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY, gridname))
            }
            else {
                dispatch(serviceActionError(UPLOAD_GRID_DATA_ERROR, "Internal Server Error!! Upload Failed"));
                dispatch(serviceActionSuccess(DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY, gridname))
            }

        } catch (e) {
            console.error("Exception while uploading Grid Data: ", e.message);
            console.error("Exception while uploading Grid Data: ", e.stack);
            dispatch(serviceActionError(UPLOAD_GRID_DATA_ERROR, "Internal Server Error!! Upload Failed"));
            dispatch(serviceActionSuccess(DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY, gridname))
        }
    }
}

/**
 * @param {Function} userMessage - pop up message in grid to notify user(types of messages:success,error,warning) 
 * @param {number} id - can be either planid or rfid
 * @param {Array} transactionList - refers to the list of transactions(CRUD) which user has done and ready to save them to the grid 
 * @param {string} gridname - refers to which grid user is working on
 * @param {string} tabName - This is used only in Revenue module and it is used to get subtab name(Like tncice,tncev,tncparameter) based on either (CORE,TNC,CARGO) selected
 * @param {string} typeName - This is used only in Revenue module and it is used to get subtab name(Like coreparameter,cargoparameter,tncparameter) based on either (CORE,TNC,CARGO) selected
 * @param {number} pageno - refers to which page user wants the data 
 * @param {function} loadGridData - this is a function used to fetch gridData and to fetch error status of the tab  
 */
export const saveGridTransactionData = (userMessage, id, transactionList, gridname, tabName, typeName, pageno,loadGridData) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_GRID_DATA_TRANSACTION_PENDING));
            let tabValidationApiServiceResp = await singleTabValidationApiServices(gridname, id, transactionList);
            if (tabValidationApiServiceResp && tabValidationApiServiceResp.isInvalid) {
                if (typeName) {
                    dispatch(serviceActionSuccess(RF_UPDATEGRID_ONVALIDATION, tabValidationApiServiceResp.validation_result));
                } else {
                    dispatch(serviceActionSuccess(MAIN_AND_PLANINPUTS_UPDATEGRID_ONVALIDATION, tabValidationApiServiceResp.validation_result));
                }
                dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, { [gridname]: true }));
                dispatch(serviceActionSuccess(SET_VALIDATE_GRIDDATA_SUCCESS))
                dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Cannot save. Please review if any row is invalid.'))
                //userMessage('error', 'Cannot save. Please review if any row is invalid.')   
            } else {
                await saveTransactionsApiServices(id, transactionList, gridname, tabName, typeName, pageno);
                await loadGridData(false,pageno)// false is sent as parameter to fetch error status of tab and pageno is the page number to fetch data
                // let newGridDataAfterSave, statusResponse;
                // if (filterView) {
                //     newGridDataAfterSave = await getFilterApiServices(id, gridname, pageno, filterParams)
                // } else if (errorView) {
                //     newGridDataAfterSave = await getErrorApiServices(id, gridname, pageno)
                // } else if (searchView) {
                //     newGridDataAfterSave = await getSearchApiServices(id, gridname, pageno, searchParams)
                // }
                // else {
                //     newGridDataAfterSave = await getApiServices(id, gridname, pageno);
                // }
                // statusResponse = await singleTabErrorStatusApiServices(id, gridname);
               // if (newGridDataAfterSave) {
                    //dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, { [gridname]: statusResponse }));
                    
                    dispatch(serviceActionSuccess(SAVE_GRID_DATA_TRANSACTION_SUCCESS, pageno));
                    //await dispatch(fetchGridData(id, gridname, pageno, filterParams, filterView, errorView, searchParams, searchView))
                    
                //} else {
                    //dispatch(serviceActionError(SAVE_GRID_DATA_TRANSACTION_ERROR, "Internal Server Error!!"));
                //}
            }
        } catch (e) {
            console.log("Exception", e);
            dispatch(serviceActionError(SAVE_GRID_DATA_TRANSACTION_ERROR, "Internal Server Error!! Transaction Failed"));
        }
    }
}

/**
 * @param {number} id - can be either planid or rfid
 * @param {string} gridname - refers to which grid user is working on
 * @param {object} deleteParams -contains all parameters wheter user wants to delete filterdata or searchdata or full grid data
 * @param {function} loadGridData - this is a function used to fetch gridData and to fetch error status of the tab  
 */
export const deleteAllGridsData = (id, gridname, deleteParams, loadGridData) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(DELETE_ALL_GRID_DATA_PENDING));
            let deleteApiServiceResp = await deleteAllGridDataApiServices(id, gridname, deleteParams,1);
            if (deleteApiServiceResp) {
                await loadGridData(false,1)// false is sent as parameter to fetch error status of tab and 1 is the page number to fetch data because on delete all data will be empty
                // let newGridDataAfterSave, tabStatusResponse;
                // if (filterView) {
                //     newGridDataAfterSave = await getFilterApiServices(id, gridname, 1, deleteParams.filterparams)
                // }
                // else if (errorView) {
                //     newGridDataAfterSave = await getErrorApiServices(id, gridname, 1)
                // }
                // else if (searchView) {
                //     newGridDataAfterSave = await getSearchApiServices(id, gridname, 1, deleteParams.searchparams)
                // }
                // else {
                //     newGridDataAfterSave = await getApiServices(id, gridname, 1);
                // }
                // tabStatusResponse = await singleTabErrorStatusApiServices(id, gridname);
                // if (newGridDataAfterSave) {
                //     dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, { [gridname]: tabStatusResponse }));
                    dispatch(serviceActionSuccess(DELETE_ALL_GRID_DATA_SUCCESS, 1)); //pageno is set to 1 because on delete all data will be empty
                // } else {
                //     dispatch(serviceActionError(SAVE_GRID_DATA_TRANSACTION_ERROR, "Internal Server Error!!"));
                // }
            } else {
                dispatch(serviceActionError(DELETE_ALL_GRID_DATA_ERROR, "Internal Server Error!!"));
            }
        }
        catch (message) {
            console.log("Exception", message);
            dispatch(serviceActionError(DELETE_ALL_GRID_DATA_ERROR, message));
        }
    }
}

/**
 * @param {number} id - can be either planid or rfid
 * @param {string} typeName This is used only in Revenue module and it is used to get subtab name(Like tncice,tncev,tncparameter) based on either (CORE,TNC,CARGO) selected
 * @param {string} moduleName This is used to get sub modules of application like planinputs/maininputs,revenuefunction(RF),Admin,Adjustment
 */
export const validateAllGridsData = (id, typeName, moduleName) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SET_VALIDATE_GRIDDATA_PENDING));
            let tabValidationApiServiceResp = await multiTabValidationApiServices(id, typeName, moduleName);
            if (tabValidationApiServiceResp) {
                dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, tabValidationApiServiceResp));
                dispatch(serviceActionSuccess(SET_VALIDATE_GRIDDATA_SUCCESS));
            } else {
                dispatch(serviceActionError(SET_VALIDATE_GRIDDATA_ERROR, "Internal Server Error!! Grid Validation Failed"));
            }
        }
        catch (message) {
            console.log("Exception", message);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `${message}`))
            dispatch(serviceActionError(SET_VALIDATE_GRIDDATA_ERROR, "Internal Server Error!! Grid Validation Failed"));
        }
    }
}


/**
 * @param {string} gridname - refers to which grid user is working on
 * @param {Array} columnDefs - refers to all constant columndefinitions which are created while load of grid 
 * @param {Boolean} isInitializeFilter - It will be either true or false. True means when we want to create and initialize the filter params for current working grid. 
 *                                 False means the user selected filter params will be saved to redux for remembering throughout the session
 * @param {Object} userSelectedFilterParams - Refers to the filter options which are selected by user and wants to apply the filter for those selected options
 */
export const setGridFilterParams = (gridname, columnDefs, isInitializeFilter, userSelectedFilterParams) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SET_GRID_FILTER_PARAMS_OPTIONS_PENDING));
            if (isInitializeFilter) {
                dispatch(serviceActionSuccess(SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS, { [gridname]: initializeFilterParams(columnDefs) }));
            } else {
                dispatch(serviceActionSuccess(SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS, { [gridname]: userSelectedFilterParams }));
            }
        }
        catch (e) {
            console.error(e);
            dispatch(serviceActionError(SET_GRID_FILTER_PARAMS_OPTIONS_ERROR, "Internal Server Error!! Error while updating filter parameters!!"));
        }
    }
}

export const validateSingleGridsData = (gridname, id) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SET_VALIDATE_GRIDDATA_PENDING));
            let tabValidationApiServiceResp = await singleTabValidationApiServices(gridname, id, []);
            if (tabValidationApiServiceResp) {
                dispatch(serviceActionSuccess(SET_TABS_VALIDATION_STATUS, { [gridname]: tabValidationApiServiceResp.validation_result }));
                dispatch(serviceActionSuccess(SET_VALIDATE_GRIDDATA_SUCCESS));
            } else {
                dispatch(serviceActionError(SET_VALIDATE_GRIDDATA_ERROR, "Internal Server Error!! Grid Validation Failed"));
            }
        }
        catch (message) {
            console.log("Exception", message);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `${message}`))
            dispatch(serviceActionError(SET_VALIDATE_GRIDDATA_ERROR, "Internal Server Error!! Grid Validation Failed"));
        }
    }
}

export const fetchTopLevelFilterOptions = (gridname, id) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_PENDING));

            let response = await getTopLevelFilterOptionsApiServices(gridname, id)
            if (response) {
                dispatch(serviceActionSuccess(FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_SUCCESS, response));
            } else {
                dispatch(serviceActionError(FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_ERROR, "Internal Server Error!! Fetching Top Level Filter Options Failed"));
            }
        }
        catch (message) {
            console.log("Exception", message);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `${message}`))
            dispatch(serviceActionError(FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_ERROR, "Internal Server Error!! Fetching Top Level Filter Options Failed"));
        }
    }
}

export const UpdateMasterDataCache = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_PENDING));
            let updatedLogsData = await fetchLastUpdatedCacheTime(sessionStorage.getItem('LASTCACHEUPDATEDAT'));
            let isMasterDataUpdated = false
            if (updatedLogsData.length > 0) {
                isMasterDataUpdated = true
                updatedLogsData.map((val) => {
                    if (val.table_name === 'car_class_group') {
                        sessionStorage.removeItem('CARCLASSCODE')
                        sessionStorage.removeItem('ALLCARGROUPCARCLASS')
                        sessionStorage.removeItem('CARGROUP')
                        sessionStorage.removeItem('CARCLASSANDGROUP')
                        sessionStorage.removeItem('ALLCARGROUPCARCLASS_CLEAN_MAP')
                        sessionStorage.removeItem('ADMINCARGROUP')
                        sessionStorage.removeItem('ADMINCARCLASSANDGROUP')
                    } else if (val.table_name === 'model_mfr_oem') {
                        sessionStorage.removeItem('MODELGROUP')
                        sessionStorage.removeItem('MANUFACTURER')
                        sessionStorage.removeItem('ALLMANUFACTURERGROUPMODELGROUP')
                        sessionStorage.removeItem('ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP')
                        sessionStorage.removeItem('ADMINMODELGROUP')
                        sessionStorage.removeItem('ADMINMANUFACTURER')
                    } else if (val.table_name === 'pools') {
                        sessionStorage.removeItem('POOL')
                        sessionStorage.removeItem('ADMINPOOL')
                    } else if(val.table_name==='acceptablezones'){
                        sessionStorage.removeItem('ACCEPTABLEZONES');
                    }
                })
                sessionStorage.removeItem('LASTCACHEUPDATEDAT')
            }
            let allplansData = await fetchAllPlansWithDimensions(true);
            if (allplansData) {
                // This Dispatch is to set isMasterDataUpdated as true, which is present in allplans reducer
                dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS, isMasterDataUpdated));
                //This Dispatch is to set pending as false, which is present in paginationGrid.Reducer
                dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_SUCCESS));
            }
        } catch (e) {
            console.log(e)
            dispatch(serviceActionError(FETCH_ALL_DIMENSIONS_DATA_ERROR, "Fetch All Dimensions Api Failed"));
        }
    }
}


export const setGridUserPageNumber = (pgno) => {
    return async dispatch => {
        if (pgno) {
            dispatch(serviceActionSuccess(SET_GRID_DATA_USER_PAGE_VALUE, pgno));
        }
    }
}

export const setGridFilterViewStatus = (gridname, status) => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GRID_DATA_FILTER_VIEW_STATUS, { [gridname]: status }));
    }
}

export const setGridSearchParams = (gridname, searchParams) => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GRID_SEARCH_TEXT_SUCCESS, { [gridname]: searchParams }));
    }
}

export const setGridSearchViewStatus = (gridname, status) => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GRID_DATA_SEARCH_VIEW_STATUS, { [gridname]: status }));
    }
}

export const setGridErrorViewStatus = (gridname, status) => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GRID_DATA_ERROR_VIEW_STATUS, { [gridname]: status }));
    }
}

export const setGridName = (gridname) => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GRID_NAME, gridname));
    }
}

export const resetGridState = (isHardReset) => {
    //if isHardReset is true - when we want to reinitialize entire redux state to its initial state 
    //if isHardReset is false - When we want to reinitialize some of the state to its initial state
    return dispatch => {
        dispatch(serviceActionSuccess(RESET_TO_GRID_DATA_INITIALSTATE, isHardReset))
    }
}

export const resetAllGridTabStatus = () => {
    return dispatch => {
        dispatch(serviceActionSuccess(RESET_TABS_VALIDATION_STATUS))
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, data) {
    return {
        type: type,
        data: data
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}