export const FETCH_DIVERSIONS_DATA_PENDING = 'FETCH_DIVERSIONS_DATA_PENDING';
export const FETCH_DIVERSIONS_DATA_SUCCESS = 'FETCH_DIVERSIONS_DATA_SUCCESS';
export const FETCH_DIVERSIONS_DATA_ERROR = 'FETCH_DIVERSIONS_DATA_ERROR';
export const UPLOAD_DIVERSIONS_PENDING = 'UPLOAD_DIVERSIONS_PENDING';
export const UPLOAD_DIVERSIONS_SUCCESS = 'UPLOAD_DIVERSIONS_SUCCESS';
export const UPLOAD_DIVERSIONS_ERROR = 'UPLOAD_DIVERSIONS_ERROR';
export const SAVE_DIVERSIONS_TRANSACTION_PENDING = 'SAVE_DIVERSIONS_TRANSACTION_PENDING';
export const SAVE_DIVERSIONS_TRANSACTION_SUCCESS = 'SAVE_DIVERSIONS_TRANSACTION_SUCCESS';
export const SAVE_DIVERSIONS_TRANSACTION_ERROR = 'SAVE_DIVERSIONS_TRANSACTION_ERROR';
export const RESET_TO_DIVERSIONS_INITIALSTATE = 'RESET_TO_DIVERSIONS_INITIALSTATE';
export const SET_DIVERSIONS_EDITABLE='SET_DIVERSIONS_EDITABLE';
export const FETCH_DIVERSIONS_DROPDOWNVALUES_PENDING = 'FETCH_DIVERSIONS_DROPDOWNVALUES_PENDING';
export const FETCH_DIVERSIONS_DROPDOWNVALUES_SUCCESS = 'FETCH_DIVERSIONS_DROPDOWNVALUES_SUCCESS';
export const FETCH_DIVERSIONS_DROPDOWNVALUES_ERROR = 'FETCH_DIVERSIONS_DROPDOWNVALUES_ERROR';