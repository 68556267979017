import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AllPlansPageStyles from './AllPlansPage.css';
import AllPlansTable from '../../components/allplans/AllPlansTable';
import icon_path from '../../assets/images/path.svg';
import { Button, Snackbar } from '@material-ui/core';
import { useParams, useLocation,Link,Switch, Route, useHistory} from 'react-router-dom';
import {getPlanBySelectedPlanId, getZoneRfData, getPoolRfData,activateCreatePlan,updateCreatePlanStatus} from '../../store/topbar/AllPlans.Actions';
import { plansDateFormatter } from "../../utils/formatters/valueFormatter";
import { userAcs } from '../../App';
import {pollTimeoutInterval} from '../../Constants';
import JobInProgressDialog from '../../components/Dialogcomponent/JobInProgressDialogComponent';
import Alert from '@material-ui/lab/Alert';

export default function AllPlansPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const[addPlan,setAddPlan]=useState(false); 
  const [isAddDisabled,setAddDisabled] = useState(false);
  const defuserAcs =  useContext(userAcs);
  const [timerVariable,setTimerVariable]=useState(null);
  const [jobInProgressDialogOpen, setJobInProgressDialogOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [showMessage,setShowMessage] = useState(false);
  const creationInProgressTitle = "Create plan";
  const creationInProgress = "Creating a new plan,please wait";
  useEffect(()=>{
    defuserAcs.setUserAcs(false)
},[])
  const AddNewPlan=()=>{
    setAddPlan(true);
    setAddDisabled(true);
    dispatch(activateCreatePlan());
    setJobInProgressDialogOpen(true);
    
  }

  var allplans = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.allplans;
  });

  const newPlanId = useSelector((reduxstate)=>{
    return reduxstate.rootAllPlansReducer.allPlansReducer.newPlanId;
  })
  const newplanFlag = useSelector((reduxstate)=>{
    return reduxstate.rootAllPlansReducer.allPlansReducer.newplanFlag;
  })

  var allPlansData = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.allplans;
  });

  let plansDataById = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.plansDataById;
});

const createPlanJobId = useSelector((reduxstate) => {
  return reduxstate.rootAllPlansReducer.allPlansReducer.createPlanJobId;
})

let planCreateSuccessful=useSelector((reduxstate)=>{
return reduxstate.rootAllPlansReducer.allPlansReducer.planCreateSuccessful;
});

const  error = useSelector((reduxState) => {
  return reduxState.rootAllPlansReducer.allPlansReducer.error;
})

useEffect(()=>{
  if(jobInProgressDialogOpen && createPlanJobId>0){
     setTimerVariable(setInterval(()=>dispatch(updateCreatePlanStatus(createPlanJobId)),pollTimeoutInterval));
  }        
},[createPlanJobId]);

const userMessage = (severity, message) => {
  setWarningSnackBarOpen(true);
  setSeverity(severity);
  setWarningMessage(message);
}

const handleCloseWarningSnackBar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setWarningSnackBarOpen(false);
};

const loadPlanDataById = (planid) => {
  dispatch(getPlanBySelectedPlanId(planid));
}

const loadZoneRfData = (planid) => {
  dispatch(getZoneRfData(planid));
}

const loadPoolRfData = (planid) => {
  dispatch(getPoolRfData(planid));
}

const LoadNewPlanDetails = (plansDataById) =>{
  let dateTime = plansDateFormatter(plansDataById[0].date);
    loadZoneRfData(newPlanId);
    loadPoolRfData(newPlanId);
    let url='/plan/'+newPlanId+'/planinputs';
    history.replace({
      pathname: url,
      state: {  // location state
        selectedplan:newPlanId,
        datetime: dateTime,
        addPlan:addPlan
      },
    });
    return history;
  }

  useEffect(()=>{
    if(planCreateSuccessful!==null){
      if(showMessage===false && planCreateSuccessful===true){
        userMessage('success', 'Successfully created plan.')
        setShowMessage(true)
      } else if(showMessage===false && planCreateSuccessful===false){
        if(error){
          userMessage('error', error);
          setShowMessage(true)
        }else{
          userMessage('error', 'Failed to create plan.');
          setShowMessage(true)
        }
        
      }
        setJobInProgressDialogOpen(false);
        setAddDisabled(false);
        clearInterval(timerVariable);
        setTimerVariable(null);
    }
    if (addPlan == true && newPlanId !== "") {
      loadPlanDataById(newPlanId);
    }
    if (addPlan == true && plansDataById !== undefined && plansDataById.length > 0 && plansDataById[0].id == newPlanId) {
      setAddPlan(false);
      LoadNewPlanDetails(plansDataById);
    }
  },[plansDataById,newPlanId,planCreateSuccessful])

  return (
    <div className='allplansmargin'>
      <div className='flexitems'>
        <h2 className='allplansheader'>All Plans</h2>
        <div className="planAddButton">
          <Button onClick={AddNewPlan} disabled={isAddDisabled} className={isAddDisabled?"addblur":""}><img src={icon_path}></img>
          <label className='addlabel'>Add</label>
          </Button>
        </div>
      </div>
      <AllPlansTable tableRowData={allplans}></AllPlansTable>
      <JobInProgressDialog open={jobInProgressDialogOpen} 
        dialogMessage={creationInProgress} title={creationInProgressTitle}>
    </JobInProgressDialog>
    <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
      <Alert
        onClose={handleCloseWarningSnackBar}
        severity={severity}>{warningMessage}
      </Alert>
    </Snackbar>
    </div>
  )
}