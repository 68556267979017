import {FETCH_MOVE_CONSTRAINT_DATA_PENDING,FETCH_MOVE_CONSTRAINT_DATA_SUCCESS,FETCH_MOVE_CONSTRAINT_DATA_ERROR,
    SAVE_MOVE_CONSTRAINTS_TRANSACTION_PENDING,SAVE_MOVE_CONSTRAINTS_TRANSACTION_SUCCESS,SAVE_MOVE_CONSTRAINTS_TRANSACTION_ERROR,
    UPLOAD_MOVE_CONSTRAINTS_PENDING,UPLOAD_MOVE_CONSTRAINTS_SUCCESS,UPLOAD_MOVE_CONSTRAINTS_ERROR,RESET_TO_MOVE_CONSTRAINTS_INITIALSTATE} from './MoveConstraints.ActionTypes';

const initialstate = {
    pending: false,
    error: null,
    moveconstraints:[],
    uploadMoveConstraintsSuccess:null,
    moveConstraintFilterSearchKey:'',
    transactionSaveSuccess:null
}

function fetchZones(constraintsData, zones) {
    constraintsData.forEach((constraint) => {
        if (constraint.POOL === undefined) {
            constraint.POOL = "ALL";
            constraint.Pool_code = "ALL"
        }
        zones.forEach((zone) => {
            if (zone.code == constraint.ZONE_CODE) {
                constraint.ZONE_NAME = zone.name;
            } else if (constraint.ZONE_CODE == "ALL") {
                constraint.ZONE_NAME = constraint.ZONE_CODE;
            }
            else if (constraint.ZONE_CODE == "ALL") {
                constraint.ZONE_NAME = constraint.ZONE_CODE;
            }
        });
        if (constraint.ZONE_CODE === undefined) {
            constraint.ZONE_NAME = "ALL";
        }
    })
    return constraintsData;
}

export default function moveConstraintsReducer(state = initialstate,action){
    switch(action.type){
        case FETCH_MOVE_CONSTRAINT_DATA_PENDING:
            return Object.assign({},state,{
                pending:true
            });
        case FETCH_MOVE_CONSTRAINT_DATA_SUCCESS:
            return Object.assign({},state,{
                pending: false,
                //moveconstraints: fetchZones(action.data.moveconstraints, action.data.zones),
                moveconstraints:action.data.moveconstraints,
                uploadMoveConstraintsSuccess: null
            });
        case FETCH_MOVE_CONSTRAINT_DATA_ERROR:
            Object.assign({},state,{
                error: action.error,
            });
        case SAVE_MOVE_CONSTRAINTS_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_MOVE_CONSTRAINTS_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                moveConstraintFilterSearchKey: "",
                // moveconstraints: fetchZones(action.data.moveconstraints, action.data.zones),
                moveconstraints:action.data.moveconstraints,
                transactionSaveSuccess: true,
            });
        case SAVE_MOVE_CONSTRAINTS_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case UPLOAD_MOVE_CONSTRAINTS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                uploadMoveConstraintsSuccess: null
            });
        case UPLOAD_MOVE_CONSTRAINTS_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                // moveconstraints: fetchZones(action.data.moveconstraints, action.data.zones),
                moveconstraints:action.data.moveconstraints,
                uploadMoveConstraintsSuccess: true,
            });
        case UPLOAD_MOVE_CONSTRAINTS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadMoveConstraintsSuccess: false
            });
        case RESET_TO_MOVE_CONSTRAINTS_INITIALSTATE:
            return initialstate;
        default:
            return state;
    }
}