import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { cleanFilterParams, minifyTransaction } from '../../../utils/utils';
import { getRoleInfo } from '../../topbar/AllPlansService';
import { ADMIN_ACCESS_REVOKED_ERROR_CODE } from '../../../Constants';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function getModelMfrOemData(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisemodelMfrOemResponse =  await client.apis.admin.getAllModelMfrOem();

    const responsesArray = await Promise.all([promisemodelMfrOemResponse]);

    const [modelMfrOemResponse] = responsesArray.map(o => o.body.data);

    return ({
        "modelMfrOemData": modelMfrOemResponse
    });
}

export async function saveModelMfrOemTransactions(transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
    if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let promiseArray = [];
    await client.apis.admin.bulktransactmodelmfroem(null, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
    } else {
        throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
    }
}

export async function saveManufacturerModelGroupTransactions(transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
    if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let promiseArray = [];
    await client.apis.admin.bulkTransactManufacturerModelGroup(null, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
    } else {
        throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
    }
}

export async function fetchModelMfrOemDropdownService(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   
    const promiseGetAllModelGroups =  await client.apis.dimensions.getAllModelGroups({"isAdmin":true});
    const promiseGetManufacturerGroup = await client.apis.dimensions.getAllManufacturerGroups({"isAdmin":true});
    
    const responsesArray = await Promise.all([
          promiseGetAllModelGroups,
          promiseGetManufacturerGroup,
    ]);

    const [
          ModelGroups,
          manufacturerGroupResponse,
    ] = responsesArray.map(o => o.body.data);

    let manufacturerGroups=[],oemValues=[],oemMfrMap={}
    manufacturerGroupResponse.map((val)=>{
        manufacturerGroups.push(val.code)
        oemValues.push(val.name)
        oemMfrMap[val.name]=val.code;
    })
    sessionStorage.setItem("ADMIN_OEM_MFR_MAP",JSON.stringify(oemMfrMap));
    return ({
        "model_group": ModelGroups,
        "manufacturer_group": manufacturerGroups,
        "oem":oemValues
    })
}

export async function getModelMfrOemFilteredData(filterParams,deleteMode){
    // console.log("filterParams in getModelMfrOemFilteredData SPA===>", filterParams);    

    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisemodelMfrOemResponse =  client.apis.admin.filterModelMfrOem(null, { requestBody: { "filterParams": cleanFilterParams(filterParams),"deleteMode":deleteMode } });

    const responsesArray = await Promise.all([promisemodelMfrOemResponse]);

    const [modelMfrOemResponse] = responsesArray.map(o => o.body.data);

    // console.log("modelMfrOemResponse in getModelMfrOemFilteredData SPA===>", modelMfrOemResponse);    
    return ({
        "modelMfrOemData": modelMfrOemResponse
    });
}

export async function getModelMfrOemSearchData(searchText,deleteMode){

    // console.log("searchText===>", searchText);    

    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisemodelMfrOemResponse =  client.apis.admin.searchModelMfrOem(null, { requestBody: { "searchText": searchText,"deleteMode":deleteMode } });

    const responsesArray = await Promise.all([promisemodelMfrOemResponse]);

    const [modelMfrOemResponse] = responsesArray.map(o => o.body.data);

    return ({
        "modelMfrOemData": modelMfrOemResponse
    });
}