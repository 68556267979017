import React from 'react';
import AdminMultiGridUI from '../../../components/admin/grid/AdminMultiGridUI';
import {ADMINPOOLSGRID} from '../../../../src/Constants';


const PoolsTabPanel = (props)=>{
    const{handleRowEditingStarted, handleRowEditingStopped,refreshCells,  handleCellEditingStopped,poolsGridColDef,  handleMultipleRowDelete, 
        poolsTransactions, poolsGridReducer,handleAddNewDropdownValue} = props;

 return(
     <div>
         <AdminMultiGridUI 
            reducer={poolsGridReducer}
            refreshCells={refreshCells}
            gridName={ADMINPOOLSGRID}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={poolsGridColDef}
            suppressclickEdit={false}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            poolsTransactions={poolsTransactions}
            handleAddNewDropdownValue={handleAddNewDropdownValue}
             />
     </div>
 )
}

export default PoolsTabPanel;