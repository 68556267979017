import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconActionDownload from '../../../assets/images/icon_action_download.svg';
import IconActionUpload from "../../../assets/images/icon_action_upload.svg";
import { makeStyles, createMuiTheme, useTheme } from "@material-ui/core/styles";
import { Button, Input,MenuItem } from '@material-ui/core';
import CustomPopOver from "../../../core-components/Popover/CustomPopOver";
import { downloadData } from '../../../utils/utils';
import { populateGrid2 } from '../../../store/revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions';
import { mainRfInputsDropdownValues, mainRfInputsTabs, revFunctionBus, rfids } from '../../../Constants';
import { NttDropdown } from '../../../core-components/Dropdown/Dropdown';
import {setRFIndex} from '../../../store/revenuefunction/RevenueFunction.Actions';
import NttTab from '../../tabs/NttTab';
import NttTabs from '../../tabs/NttTabs';
import DeleteDialogBox from '../../../components/Dialogcomponent/DialogComponent';
import {deleteAllGridsData, resetAllGridTabStatus, resetGridState, setGridErrorViewStatus, setGridFilterParams, setGridFilterViewStatus, setGridSearchViewStatus, uploadGridData,validateAllGridsData } from '../../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { getCoreDemandData } from '../../../services/revenuefunction/mainrfinputs/coredemand/CoreDemandService';
import { getCoreAdjData } from '../../../services/revenuefunction/mainrfinputs/coreAdjustments/CoreAdjustmentService';
import FilterComponent from '../../new_filter_components/FilterComponent';
import DeleteAllComponent from '../../deleteallrecords_component/DeleteAllComponent';
import S3UploadDownloadComponent from '../../s3_upload_download_component/S3UploadDownloadComponent';
import SearchComponent from '../../new_search_component/SearchComponent';
import ErrorComponent from '../../new_error_button_component/ErrorComponent';
import { addNotificationMessage } from '../../../store/topbar/AllPlans.Actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={'simple-tabpanel-${index}'}
          aria-labelledby={'simple-tab-${index}'}
          {...other}
      >
          {value === index && (
              <Box p={3}>
                  <Typography>{children}</Typography>
              </Box>
          )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const tabStyle = {
  default_tab: {
    borderRadius: "4px",
    color: "#000000",
    background: "#ffff",
    fontSize: 14,
    textTransform: 'none',
  },
  active_tab: {
    background: "#ffed9b",
    borderColor: "#ffff",
    borderRadius: "4px",
    color: "#000000",
    fontSize: 14,
    textTransform: 'none'
  },
  defaultError_tab: {
  borderRadius: "4px",
    color: "red",
    background: "#ffff",
    fontSize: 14,
    textTransform: 'none',
    fontWeight: "bold",
  },
  activeError_tab: {
  background: "#ffcccc",
  borderColor: "#ffff",
  borderRadius: "4px",
  color: "#ff0000",
  fontSize: 14,
  textTransform: 'none',
  fontWeight: "bold",
  },
  tabItemContainer: {
    margin: "50px",
    display: "flex",
    flexWrap: "wrap",
  },
  copyInProgressIndicator:{
  borderRadius: "4px",
  color: "#000000",
  background: "#ffff",
  fontSize: 14,
  textTransform: 'none',
  animation: "copyinprogress_sub 2s linear infinite"
  },
  copyInProgressIndicator_active:{
  background: "#ffed9b",
  borderColor: "#ffff",
  borderRadius: "4px",
  color: "#000000",
  fontSize: 14,
  textTransform: 'none',
  animation: "copyinprogress_sub_active 2s linear infinite"
  }
}
const MainRfInputsActionBar = (props) => {
  //loadGridDataForFilterParams,loadGridDataForErrorView ,loadGridDataForSearchParam  ====>we might delete this later
  const { mainRfInputsData, pending,mainRfInputsGrid,showOption, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, deleteSelectedRows, bulkRowDeleteIconDisabled, downloadCSV,
    mainRfInputsGridColDefReducer, filterSearchKey,onTableNameChange,onTypeNameChange,typeName,selectedTableName,setGenerateRfProcessClicked,hideActions,userMessage,initializeFilterParamsAndViewStatus,gridFilterUserSelectedParams,loadGridData} = props;

  const [value, setValue] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;
  const [mainInputValue, setMainInputValue] = useState(typeName!=null?typeName:mainRfInputsDropdownValues[0]);
  const [mainRfInputTabs, setMainRfInputTabs] = useState(mainRfInputsTabs[mainInputValue]);
  const[FileToUpload,SetFileToUpload]=useState(null);
  const[fileNameToDownload,setFileNameToDownload]=useState(null);
  const[searchText,setSearchText] = useState('');

  const theme=useTheme();
  const dispatch = useDispatch();

  let message = "Are you sure that you want to delete selected rows?"
  let title = "Delete Row";
  let deletetext = "Delete";
  let canceltext = "Cancel";


  const ITEM_HEIGHT = 48;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.9,
        width: 250,
      },
    },
  };

  theme.typography.body2 = {
    fontSize: '14px'
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: '180vh',
      maxHeight: '180vh',
    }
  }));
  const highlightAllTabsErrorStatus = useSelector((state)=>{
    return state.PaginationGridReducer.gridTabErrorStatus;
  })

  const gridName=useSelector((state)=>{
    return state.PaginationGridReducer.gridName
  })

  const gridUserPageno=useSelector((state)=>{
    return state.PaginationGridReducer.gridUserPageno;
  })

  const gridFilterViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridFilterView};
  },shallowEqual);

  const gridSearchViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridSearchView};
  },shallowEqual);

  const gridErrorViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridErrorView};
  },shallowEqual);

  const gridTotalRowCount = useSelector((state) => {
    return state.PaginationGridReducer.gridTotalRowCount;
  });

  const callGridFetchApi = useSelector((state)=>{
    return state.PaginationGridReducer.callGridFetchApi;
  });

  const getStyle = (isActive, tabKey,index,gridname) => {
    if(highlightAllTabsErrorStatus[tabKey.gridName]===true){
      return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
    } else {
      return isActive ? tabStyle.active_tab : tabStyle.default_tab
    }
  }

  const downloadHandler = async (event) => {
   if(mainRfInputsGrid!='tncicegrid' && mainRfInputsGrid!='tncevgrid' && mainRfInputsGrid!='coredemandgrid' && mainRfInputsGrid!='coreadjustmentsgrid'){
    let RfGridData=mainRfInputsData;
    dispatch(addNotificationMessage('success','Please wait your file is getting ready!!'));
    downloadData(RfGridData,mainRfInputsGrid,mainRfInputsGridColDefReducer,downloadCSV,'rf')
   } else {
        if(gridTotalRowCount<=2000){
          dispatch(addNotificationMessage('success','Please wait your file is getting ready!!'));
          downloadData(mainRfInputsData,mainRfInputsGrid,mainRfInputsGridColDefReducer,downloadCSV,'rf')
        } else {
            let rfId=rfids[0][mainInputValue]
            rfId=(rfId===1||rfId===2||rfId===3)?'':rfId
            let currentDate = new Date();
            let dateStr = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}_${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;
            let downloadFilename=`${mainRfInputsGrid.replace('grid','')}-${rfId?`rf-${rfId}`:'rf'}_${dateStr}.csv`
            setFileNameToDownload(downloadFilename)
      }
   }
  };

  useEffect(() => {
    if (mainRfInputsData && mainRfInputsData.length > 0) {
      var filteredData = mainRfInputsData;
      dispatch(populateGrid2(mainRfInputsGrid, filteredData, filteredData, filterSearchKey, mainRfInputsGridColDefReducer.checkValue, mainRfInputsGridColDefReducer.validationFn, mainRfInputsGridColDefReducer.businessValidationFn));
      //dispatch(validateGrid(mainRfInputsGrid, mainRfInputsData, mainRfInputsGridColDefReducer.validationFn, mainRfInputsGridColDefReducer.businessValidationFn));
    }
  }, [mainRfInputsData])

  //const onSearchValueChanged = (e) => {
    // setFlterSearchKey(e.target.value)
    // if (e.target.value === '') {
    //   setIsSearchBarValueExists(false);
    // } else {
    //   setIsSearchBarValueExists(true);
    // }
    //dispatch(searchRecord(e.target.value));
 // }

  const handleChangeTab = (e, index) => {
    setValue(index);
    dispatch(setRFIndex(index))
    if(index==0){
        onTableNameChange(mainRfInputsTabs[mainInputValue][index].tabTitle);
      } else if(index==1){
        onTableNameChange(mainRfInputsTabs[mainInputValue][index].tabTitle);
      }else if(index==2){
        onTableNameChange(mainRfInputsTabs[mainInputValue][index].tabTitle);
      }
}

  const onmainInputValueValueChanged = (e) => {
    setMainInputValue(e.target.value);
    onTypeNameChange(e.target.value);
    dispatch(resetAllGridTabStatus());
    dispatch(resetGridState(false));
    setMainRfInputTabs(mainRfInputsTabs[e.target.value]);
  }

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };


  const handleClickOpen = (props) => {
    if (!bulkRowDeleteIconDisabled) {
        setOpenDeleteDialog(true);
    }
  }
  const handleCloseDeleteDialog =()=>{
    setOpenDeleteDialog(false);
  }

  const handleDeleteSelectedRows =()=>{
    setOpenDeleteDialog(false);
    deleteSelectedRows()
  }

  const changeHandler = async (event) => {
    if (event.target.value !== '') {
      const fileToUpload = event.target.files[0];
      event.target.value = '';
      if(mainRfInputsGrid!=='' && (mainRfInputsGrid==='tncicegrid' || mainRfInputsGrid==='tncevgrid' || mainRfInputsGrid==='coredemandgrid' || mainRfInputsGrid==='coreadjustmentsgrid')){
        SetFileToUpload(fileToUpload)
      } else if(mainRfInputsGrid!==''){// this else block will be completely removed once we move rftncev ,core demand and core adjustment grids to S3
        dispatch(uploadGridData(false,null,rfids[0][mainInputValue],fileToUpload,gridName,selectedTableName,gridUserPageno,gridFilterUserSelectedParams[gridName],gridFilterViewStatus[gridName]));
      }
    }
  };

  const switchViewComponent = () => {
    return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
      <Button id={id} endIcon={<ArrowDropDownIcon fontSize="13px" disabled={hideActions}></ArrowDropDownIcon>}>View</Button>
      <CustomPopOver
        className="actiobarPopOver"
        popOverId="popoverId"
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
      </CustomPopOver>
    </div>);
  }

  const activateGenerateRfPlanProcess=()=>{
    setGenerateRfProcessClicked(true)
    dispatch(validateAllGridsData(rfids[0][typeName],typeName,'RF'))
  }

  useEffect(()=>{
    handleChangeTab(null,0);
  }, [mainInputValue])

  const changeErrorMode=useCallback((gridErrorView)=>{
    dispatch(setGridFilterParams(gridName,mainRfInputsGridColDefReducer,true))
    dispatch(setGridFilterViewStatus(gridName,false));

    dispatch(setGridSearchViewStatus(gridName,false));

    dispatch(setGridErrorViewStatus(gridName,gridErrorView));
    //loadGridDataForErrorView(gridErrorView);
  },[gridName]);

  const deleteAllRecords=useCallback((deleteParams)=>{
    try{
      dispatch(deleteAllGridsData(rfids[0][mainInputValue],gridName,deleteParams,loadGridData))
    }catch(e){
      console.error('got error while deleting all records');
    }
  },[callGridFetchApi])

  const filterComponentDisplay=useMemo(()=>{
    if(gridName!=''){
      return <FilterComponent gridColumnDefs={mainRfInputsGridColDefReducer} 
      //loadGridDataForFilterParams={loadGridDataForFilterParams} 
      initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus} hasPendingTransactions={showOption}/>
    }
  },[gridName,mainRfInputsGridColDefReducer,showOption])

  const searchComponentDisplay=useMemo(()=>{
    if(gridName!=''){
      return <SearchComponent isButtonRequired={(mainRfInputsGrid!=="tncparametergrid" && mainRfInputsGrid!=="coreparametergrid")?true:false} setSearchText={setSearchText} searchText={searchText} 
      //loadGridDataForSearchParam={loadGridDataForSearchParam} 
      onEnterSearch={true} gridColumnDefs={mainRfInputsGridColDefReducer} hasPendingTransactions={showOption}></SearchComponent>
    }
  },[gridName,mainRfInputsGridColDefReducer,showOption])

  const errorComponentDisplay=useMemo(()=>{
    if(gridName!=''){
      let isButtonReqd=true;
      if(!(gridFilterViewStatus[gridName]||gridSearchViewStatus[gridName]||gridErrorViewStatus[gridName]) && gridTotalRowCount==0){ // gridTotalRowCount is a String '0' and not integer 0
        isButtonReqd=false;
      }
      return <ErrorComponent isButtonRequired={(mainRfInputsGrid!=="tncparametergrid" && mainRfInputsGrid!=="coreparametergrid") && isButtonReqd?true:false} isDisabled={false} changeErrorMode={changeErrorMode} hasPendingTransactions={showOption}/>
    }
  },[gridName,showOption,gridTotalRowCount]);

  const deleteAllButtonComponent=useMemo(()=>{
    // let isButtonReqd=true;
    // if(gridTotalRowCount==0){ // gridTotalRowCount is a String '0' and not integer 0
    //   isButtonReqd=false;
    // }
    if(gridName!=''){
      return <DeleteAllComponent isButtonRequired={((mainRfInputsGrid!='tncparametergrid' && mainRfInputsGrid!='coreparametergrid') && (mainRfInputsData && mainRfInputsData.length>0))?true:false} deleteAllRecords={deleteAllRecords} />
    }
  },[callGridFetchApi,mainRfInputsData])

  const s3UploadDownloadComponent=useMemo(()=>{
    if(gridName!=''){
      return <S3UploadDownloadComponent 
                selectedId={rfids[0][mainInputValue]}
                loadGridData={loadGridData}
                // showProgress={showProgress} 
                // setShowProgress={setShowProgress}
                fileNameToDownload={fileNameToDownload}
                setFileNameToDownload={setFileNameToDownload} 
                FileToUpload={FileToUpload} 
                SetFileToUpload={SetFileToUpload} 
                gridName={gridName} 
                //userMessageFunction={userMessage}
                gridColumnDefs={mainRfInputsGridColDefReducer.columnDefs}
                selectedTabName={selectedTableName}
                isNewArchitecture={true}
            />
    }
  },[callGridFetchApi,FileToUpload,fileNameToDownload])
  // console.log("mainInputValue===>",mainInputValue);
  return (
    <div className="revFunctionHeader">
      <div className="revFunctiontables">
        <NttDropdown
          selectVariant="outlined"
          defaultValue={mainInputValue}
          selectValue={mainInputValue}
          selectClassName={"dropdownfield bottomMarginRemoved"}
          selectOnchange={onmainInputValueValueChanged}
          selectCustomInput={<Input />}
          selectMenuProps={MenuProps}
        >
          {mainRfInputsDropdownValues && mainRfInputsDropdownValues.map((dropdownvalue, index) => {
            return (
              <MenuItem value={dropdownvalue} key={index}>
                {dropdownvalue}
              </MenuItem>)
          })}
        </NttDropdown>
        <div className="headerDivider"></div>
        <div className="rfInputTabs flex">
          <NttTabs
            orientation="horizontal"
            variant="scrollable"
            scrollButtons='on'
            onChange={(e,i)=>{handleChangeTab(e,i)}}
            sx={{ borderRight: 1, borderColor: 'divider' }}
            value={value}
            indicatorColor='string'
            >
            {mainRfInputTabs.map((tabInfo, index) => (
              <NttTab label={tabInfo.tabTitle}
                  id={`simple-tab-${index}`}
                  key={tabInfo.tabTitle}
                  style={getStyle(value === index,tabInfo,index,gridName)}
                  disabled={showOption || pending || !(index==0 || index==1 || index==2)  } 
              />
            ))}
        </NttTabs>
        </div>
        {!hideActions?<div className="primaryButton">
         <Button onClick={(e)=>activateGenerateRfPlanProcess(e)}>
          Generate RF
         </Button>
       </div>:''}
      </div>
      <div className="filterStyle">     
          {filterComponentDisplay}
          <div style={{marginRight:'0%',marginTop:'8px'}}>
            {searchComponentDisplay}
          </div>
          {errorComponentDisplay}
          <>
            {s3UploadDownloadComponent}
          </>
      </div>
      
      {(showOption && !hideActions)?
        <div className="saveButtons">
          <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
          </Button></div>
          <div className="primaryButton">
            <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
            </Button></div>
          <div className="primaryButton">
            {/* Disable Add + buttton if plan status running  */}
            <Button disabled={!mainRfInputsGridColDefReducer.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>Add +
            </Button>
          </div>
          <div className="primaryButton">
            {/* Disable delete delete button if plan is running  */}
            <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || !mainRfInputsGridColDefReducer.defaultColDef.editable}>
            Delete
           </Button>
          </div>
          {deleteAllButtonComponent}
        </div> :
        (<div className="actionButtons">
          {/* {(currentView !== "grid" && actionView !== "grid") ? <img src={IconActionEdit} className="imageIcon" onClick={(e) => handleEditClick(e)}></img> :
            <img src={IconGrid} className="imageIcon" onClick={(e) => handleEditClick(e)}></img>} */}
          {(mainRfInputsGrid!=='tncparametergrid' && mainRfInputsGrid!=='coreparametergrid')?<div className='flex'>
            {!hideActions ? 
              <>
                {!gridErrorViewStatus[gridName] ? 
                    <label>
                        <input disabled={!mainRfInputsGridColDefReducer.defaultColDef.editable} type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
                        <img src={IconActionUpload} className="imageIcon cursorpoint"></img>
                    </label>
                :''} 
              </>
            :''}
          <div className='imageIcon cursorpoint'>
            <img src={IconActionDownload} onClick={() => downloadHandler()} />
          </div></div>:''}
          {/* {switchViewComponent()} */}
          {!hideActions? <div style={{display:'flex'}}>
            <div className="primaryButton">
              <Button disabled={!mainRfInputsGridColDefReducer.defaultColDef.editable || hideActions || gridErrorViewStatus[gridName]} onClick={(e) => onAddNewRowClick(e)}>
                Add +
            </Button></div>
            <div className="primaryButton">
              <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || !mainRfInputsGridColDefReducer.defaultColDef.editable || hideActions}>
             Delete
            </Button>
            </div>
            {deleteAllButtonComponent}
            </div>:''}
        </div>)}
        <DeleteDialogBox open={openDeleteDialog}  handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
          dialogMessage={message} title={title} action={deletetext}
          canceltext={canceltext}>
        </DeleteDialogBox>
    </div>
  )
}

export default MainRfInputsActionBar;