import {getAddsInvalidPoolsData, saveAddsInvalidPoolsTransactions, fetchAddsInvalidPoolsDropdownService,uploadAddsInvalidPoolsCSV} from '../../services/addsinvalidpools/AddsInvalidPoolsService';
import {FETCH_ADDSINVALIDPOOLS_DATA_PENDING, FETCH_ADDSINVALIDPOOLS_DATA_SUCCESS, FETCH_ADDSINVALIDPOOLS_DATA_ERROR, UPLOAD_ADDSINVALIDPOOLS_PENDING, UPLOAD_ADDSINVALIDPOOLS_SUCCESS, UPLOAD_ADDSINVALIDPOOLS_ERROR,
    SAVE_ADDSINVALIDPOOLS_TRANSACTION_PENDING, SAVE_ADDSINVALIDPOOLS_TRANSACTION_SUCCESS, SAVE_ADDSINVALIDPOOLS_TRANSACTION_ERROR,
    FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_PENDING,FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_SUCCESS,FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR, RESET_TO_ADDSINVALIDPOOLS_INITIALSTATE} from '../../store/addsinvalidpools/AddsInvalidPools.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchAddsInvalidPoolsData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_ADDSINVALIDPOOLS_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getAddsInvalidPoolsData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_ADDSINVALIDPOOLS_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_ADDSINVALIDPOOLS_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_ADDSINVALIDPOOLS_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadAddsInvalidPoolsData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_ADDSINVALIDPOOLS_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadAddsInvalidPoolsCSV(planId, files);
            let addsInvalidPoolsData = await getAddsInvalidPoolsData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_ADDSINVALIDPOOLS_SUCCESS, addsInvalidPoolsData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_ADDSINVALIDPOOLS_ERROR, "adds invalid pools upload failed"));
                dispatch(SetAssumptionError("adds invalid pools upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_ADDSINVALIDPOOLS_ERROR, "adds invalid pools upload failed"));
            dispatch(SetAssumptionError("adds invalid pools upload failed"))
        }
    }
}

export const saveAddsInvalidPoolsTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_ADDSINVALIDPOOLS_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveAddsInvalidPoolsTransactions(planid, transactionList);
            let addsInvalidPoolsData = await getAddsInvalidPoolsData(planid);
            if (addsInvalidPoolsData) {
                dispatch(serviceActionSuccess(SAVE_ADDSINVALIDPOOLS_TRANSACTION_SUCCESS, addsInvalidPoolsData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_ADDSINVALIDPOOLS_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchAddsInvalidPoolsDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_PENDING));
        let addsInvalidPoolsData = await fetchAddsInvalidPoolsDropdownService(planid);
        if (addsInvalidPoolsData) {
            dispatch(serviceActionSuccess(FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_SUCCESS, addsInvalidPoolsData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("addsinvalidpoolsGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later
                dispatch(validateGrid("addsinvalidpoolsgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
            }
        }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR, addsInvalidPoolsData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetAddsInvalidPools = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_ADDSINVALIDPOOLS_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}