import { combineReducers } from 'redux';
import ManualVinDeletesReducer from './ManualVinDeletes.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import manualvindeletesColumnDefinitionReducer from "./ManualVinDeletes.ColumnDef.Reducer";

const rootManualVinDeletesReducer = combineReducers({
      ManualVinDeletesReducer
    , manualVinDeletesGridReducer: createNamedWrapperReducer(multiGridUIReducer, "manualvindeletesgrid")
    , manualvindeletesColumnDefinitionReducer
})

export default rootManualVinDeletesReducer;