import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction, serverSideSearchColumnsMatch } from '../../../../utils/utils';
import { pollTimeoutInterval } from '../../../../Constants';

export async function getTncIceData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncIceResponse = await client.apis.revenuefunction.getRFTncIce({'pageno':pageno},{requestBody:{'rfid':rfid}});
    const responsesArray = await Promise.all([promisetncIceResponse]);
    const [tncIceResponse] = responsesArray.map(o => o.body.data);
    //return tncIceResponse.obj.data;
    return ({
        "gridData": tncIceResponse.rows,"rowcount":tncIceResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncIcsData failed'}
   }
   }

   export async function getTncIceFilterData(rfid,pageno,filterparams){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncIceFilterResponse = await client.apis.revenuefunction.getRFTncIceFilterRecords({'pageno':pageno},{requestBody:{'rfid':rfid,'filterparams':filterparams}});
    const responsesArray = await Promise.all([promisetncIceFilterResponse]);
    const [tncIceResponse] = responsesArray.map(o => o.body.data);
    //return tncIceResponse.obj.data;
    return ({
        "gridData": tncIceResponse.rows,"rowcount":tncIceResponse.rows[0]?tncIceResponse.rows[0].rowcount:0
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncIceFilterData failed'}
   }
   }

   export async function getTncIceSearchData(rfid,pageno,searchParams){
     const accessToken = await getAccessTokenAfterAuth();
 
     const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
     const promiseTncIceSearchResponse =  client.apis.revenuefunction.getRFTncIceSearchRecords({ 'rfid':rfid,'pageno':pageno==0 || pageno==undefined?0:pageno},{requestBody: {"searchParams": searchParams } });
     const responsesArray = await Promise.all([promiseTncIceSearchResponse]);
 
     const [tncIceSearchResponse] = responsesArray.map(o => o.body.data);
     return ({
         "gridData": tncIceSearchResponse.rows,"rowcount":tncIceSearchResponse.rows[0]?tncIceSearchResponse.rows[0].rowcount:0
     });
   }

export async function getTncIceDropdownService(){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
     const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
     const promisePoolResponse =  await checkAndLoadDimention("pool", client);
     const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
     const responsesArray = await Promise.all([
         promisePoolResponse
        , promiseAdminPoolResponse
    ]);

    const [
         pool,adminPoolResponse
    ] = responsesArray.map(o => o.body.data);

    return ({
        "pool": pool,
        "adminpools":adminPoolResponse
    })

    }
    catch(error){

    }
}

export async function saveRfTncIceTransactions(rfid,transactionList,tabname){
    try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.revenuefunction.bulktransactrftncnc({"rfid":rfid},  {requestBody: { "updatedBy": userEmail,"tabName":tabname,"transactions": minifyTransaction(transactionList)}});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
    }catch(error){

    }
}

export async function deleteAllTncIceData(rfid,params){
try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();
        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        
        let promiseRfTncIceDeleteResponse = await client.apis.revenuefunction.deleteAllRfTncIceRecords({ "rfid": rfid},{requestBody: {"updatedBy":userEmail,...params}});
        return promiseRfTncIceDeleteResponse;
    } 
    catch (error) {
        console.error(error);
        return {'Error':'deleteAllTncData failed'}
    }
   }

export async function uploadTncIceCSV(rfid,tabName,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.revenuefunction.uploadRfTnc({"rfid":rfid},{
            requestBody: {
                "updatedBy":userEmail,
                "tabName":tabName,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'uploadProgramCSV failed'}
    }
}

export async function insertS3UploadTncIceCSV(rfid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        // console.log("Before getting new ID....");
        const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
        // console.log("After getting new ID....",responseWithJobId);
        const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
        // console.log("New Job ID....",jobId);
        let response = null;
        try{
            response = await client.apis.revenuefunction.insertS3UploadedRfTncIceData({"rfid":rfid},{
                requestBody: {
                    "updatedBy":userEmail,
                    "gridName":gridname.replace('grid',''),
                    "fileName":filename,
                    "jobId":jobId
                }
            });

        }catch(e){
            console.log("e.message==>", e.message);
            let tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
            let status=tempResponse.body.data.rows[0].status;
            let operationStarted=tempResponse.body.data.rows[0].created_on;
            // let startTime=
            while(status!=='SUCCESS' && status!=='ERROR'){
                if((new Date().getMilliseconds()-(new Date(operationStarted)).getMilliseconds())>900000){
                    status='TIMEOUT';
                    break;
                }
                // console.log(`Getting the status....`);
                tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
                // console.log(`tempResponse===`,tempResponse);
                status=tempResponse.body.data.rows[0].status;
                // console.log(`status===`,status);
            }
            response = {
                obj:{
                    statusCode: status==="SUCCESS"?'200':'500',
                    body:status==="ERROR"?JSON.stringify({error:tempResponse.obj.data.rows[0].message}):status==="TIMEOUT"?JSON.stringify({error:"Timed Out"}):''
                }
            }
            // console.log("Operation completed...");
        }
        console.log("insertS3UploadTncIceCSV===>",response);
        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'uploadProgramCSV failed'}
    }
}

export async function tncIceTabValidationService(rfid,transactionList){
 try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

    const response = await client.apis.revenuefunction.validateRFTncIce({"rfid":rfid},{
        requestBody: {
            "updatedBy":userEmail,
            "transactions":minifyTransaction(transactionList)
        }
    });
    return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
 }
 catch(error){
    console.error(error);
    return {'Error':'tncicevalidationservice failed'}
 }
}

export async function getTncIceTabErrorStatus(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncIceResponse = await client.apis.revenuefunction.getRFTncIceTabErrorStatus({'rfid':rfid});
    const responsesArray = await Promise.all([promisetncIceResponse]);
    const [tncIceTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return tncIceTabErrorStatusResponse;
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncIceTabErrorStatus failed'}
   }
   }

export async function getTncIceErrorData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncIceErrorResponse = await client.apis.revenuefunction.getRFTncIceErrorRecords({'pageno':pageno,'rfid':rfid},null);
    const responsesArray = await Promise.all([promisetncIceErrorResponse]);
    const [tncIceResponse] = responsesArray.map(o => o.body.data);
    //return tncIceResponse.obj.data;
    return ({
        "gridData": tncIceResponse.rows,"rowcount":tncIceResponse.rows[0]?tncIceResponse.rows[0].rowcount:0
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncIceErrorData failed'}
   }
}