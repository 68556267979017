import { columnDefs, defaultColDef } from "./modelMfrOem_grid_data/ModelMfrOemColumnDefs";
import { FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING,FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS,FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR } from '../metadata_modelmfroem/ModelMfrOem.ActionTypes';


const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,                          
            OEM: { error: checkIfValueEmpty(data.OEM, "OEM") },
            MANUFACTURER_GROUP: { error: checkIfValueEmpty(data.MANUFACTURER_GROUP, "Manufacturer Group",{oem:data.OEM}) },
            MODEL_GROUP: { error: checkIfValueEmpty(data.MODEL_GROUP, "Model Group") },
            ARCHIVED: {error: checkIfValueEmpty(data.ARCHIVED, "ARCHIVED")}
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name, additionalData) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }else if(name==="Manufacturer Group" && value!==getMfrCorrspondingToOem(additionalData.oem.trim())){
        return name + " is incorrect for selected OEM";
    }
    return null;
}

function getMfrCorrspondingToOem(oem){
    let oemMfrMapStr =sessionStorage.getItem("ADMIN_OEM_MFR_MAP");
    if(oemMfrMapStr){
        let oemMfrMap=JSON.parse(oemMfrMapStr);
        // console.log("oemMfrMap: ", oemMfrMap);
        // console.log("oem: ", oem);
        let mfr= oemMfrMap[oem];
        // console.log("mfr: ", mfr);
        return mfr;
    }

    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if ((itm.OEM === itm2.OEM)&&(itm.MANUFACTURER_GROUP === itm2.MANUFACTURER_GROUP)&&(itm.MODEL_GROUP === itm2.MODEL_GROUP)) {
                unique = false;
                if (itm.rowStatus == "ADD_ROW"){
                    itm.rowStatus = "ERROR_NEW_ROW";
                }
                else if (itm2.rowStatus == "ADD_ROW"){
                    itm2.rowStatus = "ERROR_NEW_ROW"
                }
                if(itm.rowStatus == "COPY_ROW"){
                    itm.rowStatus = "ERROR_COPY_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                 else if(itm2.rowStatus == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.rowStatus = "ERROR_COPY_ROW"
                }
                else {
                    itm.rowStatus = "ERROR_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.rowStatus != "ADD_ROW") {
            itm.rowStatus = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.rowStatus == "ERROR_COPY_ROW" || transactions[key].data.rowStatus == "ERROR_ROW") && itm.rowStatus==''){
                transactions[key].data.rowStatus=itm.rowStatus
              }
            }
          })
    });
    return duplicate;
}

// function fetchDropdownsForColumns(colVal, element) {
//     var colData = [];
//     colVal.forEach(val => {
//         colData.push(val.name)
//     });

//     element.cellEditorParams = { options: colData }
// }

function fetchDropdownValueOthers(data, element) {
    element.cellEditorParams = { options: data }
}

function fetchFilterValues(manufacturer_group,model_group,oem,columns) {
    columns.forEach(element => {
        if (element.field == "MANUFACTURER_GROUP") {
            // Commented to set the options dynamically based on selected OEM value
            // fetchDropdownValueOthers(manufacturer_group, element);
        } else if(element.field == "MODEL_GROUP"){
            fetchDropdownValueOthers(model_group,element);
        } else if(element.field == "OEM"){
            fetchDropdownValueOthers(oem,element);
        }
        else if (element.field === "ARCHIVED") {
            element.cellEditorParams = { options: ['true','false'] }
        }
    });

    return columns;
}

export default function modelMfrOemColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues: fetchFilterValues([],[],[],[],state.columnDefs)
            });
        case FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues:fetchFilterValues(action.data.manufacturer_group,action.data.model_group,action.data.oem,state.columnDefs)
            });
        case FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}