import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import config from '../../../config';
import { checkAndLoadDimention, minifyTransaction } from '../../../utils/utils';
import { getRoleInfo } from '../../topbar/AllPlansService';
import { ADMIN_ACCESS_REVOKED_ERROR_CODE } from '../../../Constants';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function getAdminPoolData(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseadminPoolsResponse =  await client.apis.admin.getAllPoolZone();

    const responsesArray = await Promise.all([promiseadminPoolsResponse]);

    const [adminPoolsResponse] = responsesArray.map(o => o.body.data);

    return ({
        "adminPoolsData": adminPoolsResponse
    });
}

export async function savePoolTransactions(transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
    if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let promiseArray = [];
    await client.apis.admin.bulktransactadminpools(null, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
    } else {
        throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
    }
}

export async function fetchPoolDropdownService(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promisePoolResponse = await client.apis.dimensions.getPool({"isAdmin":true});
    
    const responsesArray = await Promise.all([
          promiseZoneResponse,
          promisePoolResponse,
    ]);

    const [
          zones,
          pools,
    ] = responsesArray.map(o => o.body.data);


    return ({
        "zones": zones,
        "pools": pools
    })
}