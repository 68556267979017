import {FETCH_ADDITIONALCOST_DATA_PENDING, FETCH_ADDITIONALCOST_DATA_SUCCESS, FETCH_ADDITIONALCOST_DATA_ERROR, UPLOAD_ADDITIONALCOST_PENDING, UPLOAD_ADDITIONALCOST_SUCCESS, UPLOAD_ADDITIONALCOST_ERROR,
    SAVE_ADDITIONALCOST_TRANSACTION_PENDING, SAVE_ADDITIONALCOST_TRANSACTION_SUCCESS, SAVE_ADDITIONALCOST_TRANSACTION_ERROR,RESET_TO_ADDITIONALCOST_INITIALSTATE
    } from '../../store/additionalcost/AdditionalCost.ActionTypes';

const initialState = {
    additionalCostData:[],
    pending:false,
    error:null,
    uploadAdditionalCostSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(additionalCostData, zones) {
    additionalCostData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
        if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
            value['ZONE_NAME'] = "";
        }
    })
    return additionalCostData;
}

export default function AdditionalCostReducer (state=initialState,action){
    switch(action.type){
        case FETCH_ADDITIONALCOST_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_ADDITIONALCOST_DATA_SUCCESS:
            return Object.assign({},state,{
                additionalCostData: fetchZones(action.data.additionalCostData, action.data.zones),
                uploadAdditionalCostSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_ADDITIONALCOST_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_ADDITIONALCOST_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadAdditionalCostSuccess: null
            });
        case UPLOAD_ADDITIONALCOST_SUCCESS:
            return Object.assign({}, state, {
                additionalCostData: fetchZones(action.data.additionalCostData, action.data.zones),
                pending: false,
                error: null,
                uploadAdditionalCostSuccess: true
            });
        case UPLOAD_ADDITIONALCOST_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadAdditionalCostSuccess: false
            });
        case SAVE_ADDITIONALCOST_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_ADDITIONALCOST_TRANSACTION_SUCCESS:
            return Object.assign({}, state,{
                pending: false,
                additionalCostData:fetchZones(action.data.additionalCostData, action.data.zones),
                transactionSaveSuccess: true
            });
        case SAVE_ADDITIONALCOST_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_ADDITIONALCOST_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}
