import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchTopLevelFilterOptions, setGridErrorViewStatus, setGridFilterParams, setGridFilterViewStatus, setGridSearchParams, setGridSearchViewStatus } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import './TopLevelFilterComponent.css';
import { Button } from '@material-ui/core';
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { CANNOT_CHANGE_DATASET_ERROR } from '../../Constants';
import { removeUnderscores } from '../../utils/utils';

const TopLevelFilterComponent = (props) => {

    const {hasPendingTransactions,selectedId}=props;

    const dispatch = useDispatch();
    
    const pending = useSelector((state) => {
        return  state.PaginationGridReducer.pending;
      });

    const gridErrorMessage = useSelector((state) => {
        return  state.PaginationGridReducer.gridErrorMessage;
      });
     
    const gridTransactionSaveSuccess = useSelector((state) => {
        return  state.PaginationGridReducer.gridTransactionSaveSuccess;
      });
    
    const gridUploadStatus = useSelector((state) => {
        return  state.PaginationGridReducer.gridUploadStatus;
      });

    const gridDeleteAllDataSuccess = useSelector((state)=>{
        return state.PaginationGridReducer.gridDeleteAllDataSuccess;
      });

    const gridFilterUserSelectedParams = useSelector((state) => {
        return state.PaginationGridReducer.gridFilterUserSelectedParams;
    }); 
    
    const gridName=useSelector((state)=>{
        return state.PaginationGridReducer.gridName
    });

    const gridTopLevelFilterOptions = useSelector((state)=>{
      return state.PaginationGridReducer.gridTopLevelFilterOptions;
    });

    // Note:-
    // This component can be utilized for any tabs only if the below conditions gets satisfied :-
    // 1) this provide filter options for only one column and that column should be of dropdown filter type
    // 2) the key names of the api response data should consist of
    //     filterOptionColName  (consist of column name for options provided)
    //     filterOptionTypeName (consist of filter option name)
    //     filterOptionTypeCount (consist of count for particular filter option)

    useEffect(() => {// this useEffect is used to fetch the filter options when ever there is change in gridName or when transaction or upload has been done.
        if (pending==false && gridErrorMessage==null) {
          dispatch(fetchTopLevelFilterOptions(gridName,selectedId)) 
        }
    }, [gridTransactionSaveSuccess, gridUploadStatus,gridDeleteAllDataSuccess,gridName])

    
  const applyTopLevelFilter = (selectedFilterOption) => {//when user clicks on the topLevel Filter Options, based upon the selectedFilterOption the filter gets applied. 
    if (hasPendingTransactions) {//when ever user is in the pending transactions, the changes in the filter should be prevented.
      dispatch(addNotificationMessage('error', CANNOT_CHANGE_DATASET_ERROR))
    }
    else {
      let filterParams = JSON.parse(JSON.stringify(gridFilterUserSelectedParams[gridName]));//to store the gridFilterUserSelectedParams by deepCopying.

      if (selectedFilterOption.filterOptionTypeName == 'ALL') {//when user clicks on 'ALL' button then all the gridTopLevelFilterOptions should be applied for filtering the records.
        let filterOptions = [];
        gridTopLevelFilterOptions.map(options => {
          if (options.filterOptionTypeName !== 'ALL') {
            filterOptions.push({ "label": options.filterOptionTypeName, "value": options.filterOptionTypeName })
          }
        })
        filterParams[selectedFilterOption.filterOptionColName] = filterOptions;
      }

      else {// when user clicks on the options other than 'ALL', then that selectedFilterOption should be applied for filtering the records.
        filterParams[selectedFilterOption.filterOptionColName] = [{ "label": selectedFilterOption.filterOptionTypeName, "value": selectedFilterOption.filterOptionTypeName }]
      }

      if (JSON.stringify(gridFilterUserSelectedParams[gridName]) != JSON.stringify(filterParams)) {// this condition is to prevent applying filter for the same option that user chooses multiple times.
        dispatch(setGridFilterParams(gridName, null, false, filterParams));
        dispatch(setGridFilterViewStatus(gridName, true));
        dispatch(setGridErrorViewStatus(gridName, false));
        dispatch(setGridSearchParams(gridName, null));
        dispatch(setGridSearchViewStatus(gridName, false));
      }

    }

  }
        
  const filterOptionsDisplay=useMemo(()=>{// this functionality is to render the filterOptions and to highlight the option which is inculded in the filtering of records.
    if (gridTopLevelFilterOptions.length > 0) {

        return (gridTopLevelFilterOptions.map((options) => (
         //this condition is to highlight the options which is inculded in the filtering of records.
          ((gridFilterUserSelectedParams[gridName][options.filterOptionColName].length != 1 && options.filterOptionTypeName === 'ALL') ||
            (gridFilterUserSelectedParams[gridName][options.filterOptionColName].length == 1 ?
              gridFilterUserSelectedParams[gridName][options.filterOptionColName][0]["label"] === options.filterOptionTypeName
              : false)) ?

            (<Button key={options.filterOptionTypeName} className='activeButton' onClick={() => applyTopLevelFilter(options)} >
              <span>
                {removeUnderscores(options.filterOptionTypeName)}
              </span>
              {(options.filterOptionTypeCount) ?
                <span >
                  {options.filterOptionTypeCount}
                </span>
                : ''}
            </Button>)
            //this else is not to highlight the option.
            : (<Button key={options.filterOptionTypeName} className='inactiveButton' onClick={() => applyTopLevelFilter(options)}>
              <span>
                {removeUnderscores(options.filterOptionTypeName)}
              </span>
              {(options.filterOptionTypeCount) ?
                <span>
                  {options.filterOptionTypeCount}
                </span>
              : ''}
              </Button>)
        )))
    }
  },[gridFilterUserSelectedParams[gridName],gridTopLevelFilterOptions,hasPendingTransactions])

  
    return(
    <div className='toplevelfilteroptionscontainer' disabled={pending} >{filterOptionsDisplay}</div>
    )
}
export default TopLevelFilterComponent;