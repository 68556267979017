import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adjustmentFilters } from "../../../../containers/adjustments/AdjustmentsPage";
import { localeFormatAndRounding, assignSign } from "../../../../utils/formatters/valueFormatter";
import '../ReadOnlyCellComponent.css';
import EditableFieldComponent from './EditableFieldComponent';
import { checkFilterParameterValue, checkType } from "../../../../utils/AdjustmentsUtils";


const EditableCellComponent = (props) => {
    const { data, type, isExpanded, fetchUpdatedCellMonthANdYear, updatedCellMonthAndYearArr, checkForErrors, content, planStatus, filterParams, zonename, filterRecord, currentHeader } = props;
    let adjustmentActivities = useContext(adjustmentFilters);
    let isVarianceMatches = false;
    let isReqValueMatches = false;
    let isReqAdjustmentMatches = false;
    let isOriginalValMatches = false;
    let finalValue = calculateFinalValue(data);
    isReqValueMatches = (filterParams ? checkIfValueMatches(filterParams.requestedValue, Math.round(finalValue), adjustmentActivities.filterActivityForReqValue, "reqValue") : false);
    let currentValue = checkType(data.current);
    let originalValue = checkType(data.original);
    isOriginalValMatches = (filterParams ? checkIfValueMatches(filterParams.originalValue, Math.round(originalValue), adjustmentActivities.filterActivityForOriginalValue, "originalValue") : false);
    let adjustment = checkType(data.adjustment);
    isReqAdjustmentMatches = (filterParams ? checkIfValueMatches(filterParams.requestedAdjustment, Math.round(adjustment), adjustmentActivities.filterActivityForReqAdjVal, "adjValue") : false);
    let adjustmentvar = finalValue - currentValue; // adj calc for activity
    const [finalValueOnEdit, setFinalValueOnEdit] = useState(0);
    isVarianceMatches = (filterParams ? checkIfValueMatches(filterParams.variance, Math.round(adjustmentvar), adjustmentActivities.filterActivityForAdjVar, "adjVar") : false);

    let fnlVal=data.fnlVal;
    let adjVar=data.adjVar;

    //console.log("Data at row-cell component====",data);
    //let shouldBeHighlighted=false;
    let shouldBeHighlighted=checkIfShouldBeHighlighted(filterParams,data);

    function checkIfShouldBeHighlighted(filterParams,data){
        // console.log("checkIfShouldBeHighlighted===filterParams===", filterParams);
        // console.log("checkIfShouldBeHighlighted===data===========", data);

        if(filterParams && filterParams.isFiltered){
            return checkFilterParameterValue(checkType(data.adjustment),filterParams.requestedAdjustment)
                || checkFilterParameterValue(checkType(data.original),filterParams.originalValue)
                || checkFilterParameterValue(fnlVal,filterParams.requestedValue)
                || checkFilterParameterValue(adjVar,filterParams.variance);
        }else{
            return false;
        }
    }

    function checkIfValueMatches(params, value, activitiesToBeFiltered, filterType) {
        let isValueMatches = false;
        if (params) {
            if (params && params.length > 1) {
                let paramsValue = params[1].replace(/,/g,'');
                if (params[0] === 'equal') {
                    isValueMatches = ((parseInt(paramsValue)) === value)
                } else if (params[0] === 'between') {
                    let startValue = parseInt(paramsValue);
                    let endValue = parseInt(params[2].replace(/,/g,''));
                    if (params[1] !== "" && params[2] !== "") {
                        isValueMatches = ((value >= startValue) && (value <= endValue));
                    } else {
                        isValueMatches = true;
                    }
                } else if (params[0] === "less") {
                    let startValue = parseInt(paramsValue);
                    if (params[1] !== "") {
                        isValueMatches = (value <= startValue);
                    } else {
                        isValueMatches = true;
                    }
                } else if (params[0] === "greater") {
                    let startValue = parseInt(paramsValue);
                    if (params[1] !== "") {
                        isValueMatches = (value >= startValue);
                    } else {
                        isValueMatches = true;
                    }
                }
            } else if (params[0] === 'notzero') {
                isValueMatches = (value !== 0)
            }
            if (isValueMatches) {
                let arr = activitiesToBeFiltered.filter((val) => {
                    return (val === data.activity_type);
                })
                if (arr.length === 0) {
                    let subArr = [];
                    let activityArr = []
                    if (zonename) {
                        activityArr.push(currentHeader);
                        subArr.push(currentHeader);
                        subArr.push(zonename);
                        activitiesToBeFiltered.push(activityArr);
                        activitiesToBeFiltered.push(subArr);
                    } else {
                        activityArr.push(data.activity_type)
                        activitiesToBeFiltered.push(activityArr);
                    }
                }
                filterRecord(data.activity_type, filterType,value);
            }
        }
        return isValueMatches;
    }


    function calculateFinalValue(data) {
        let finalValue = 0;
        let adjustment = 0;
        if (data !== undefined) {
            if (data.adjustment != null) {
                if (typeof data.adjustment == 'object')
                    adjustment = data.adjustment.value;
                else
                    adjustment = data.adjustment;
            }
            if (typeof data.original == 'object')
                finalValue = data.original.value + adjustment;
            else
                finalValue = data.original + adjustment;
            return finalValue;
        }
    }

    const dataWithAdjustmentValue = useSelector((state) => {
        return { ...state.rootAdjustmentsReducer.adjustmentsReducer.dataWithAdjustmentValue };
    })

    useEffect(() => {
        let finalVlaue = calculateFinalValue(dataWithAdjustmentValue);
        setFinalValueOnEdit(finalVlaue);
    }, [dataWithAdjustmentValue])

    function getUpdatedCellMonthANdYear(monthAndYear) {
        fetchUpdatedCellMonthANdYear(monthAndYear)
    }

    let isAdjComplete = (planStatus && planStatus.toLowerCase() == 'adjustment complete');

    return isExpanded ?  (
        <div className={type == 'summaryStack' ? 'applygreybackground' : 'cellComponent'}>
            <span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentValuesWithMargin":"adjustmentValuesWithMargin"}>{localeFormatAndRounding(currentValue)}</span>
        </div>
    ) : (
        <div className={type == 'summaryStack' ? ((shouldBeHighlighted) ? 'applygreybackground selectedForFilter' : 'applygreybackground') : ((shouldBeHighlighted && data.isFreezMonth) ? 'applyFreezMonthColor cellComponent selectedForFilter': (shouldBeHighlighted ? 'cellComponent selectedForFilter': 'cellComponent'))}>
            <span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentValuesWithMargin":'adjustmentValuesWithMargin'}>{localeFormatAndRounding(currentValue)}</span>
            <span className='editablefield'>
                <EditableFieldComponent data={data} adjvalue={Math.round(adjustment)} calculateFinalValue={calculateFinalValue} getUpdatedCellMonthANdYear={getUpdatedCellMonthANdYear} updatedCellMonthAndYearArr={updatedCellMonthAndYearArr}
                    currentValue={Math.round(currentValue)} checkForErrors={checkForErrors} originalValue={Math.round(originalValue)} isDisableEdit={data.isFreezMonth}></EditableFieldComponent>
            </span>
            {planStatus && planStatus.toLowerCase() == 'adjustment complete' ? (<span className={(isVarianceMatches && data.isFreezMonth) ? "applyFreezMonthColor adjustmentvalues selectedForFilter" : isVarianceMatches ? 'adjustmentvalues selectedForFilter' : "adjustmentvalues"}>{localeFormatAndRounding(adjustmentvar)}</span>) : ''}
            <span className={((finalValue != 0 || finalValueOnEdit != 0) && data.isFreezMonth) ? "applyFreezMonthColor adjustmentvaluesblue":(finalValue != 0 || finalValueOnEdit != 0) ? 'adjustmentvaluesblue' : "adjustmentvalues"}>{localeFormatAndRounding(finalValue)}</span>
            {isAdjComplete ? <span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentvalues":'adjustmentvalues'}>{localeFormatAndRounding(originalValue)}</span> : ''}

        </div>
    )
}
export default EditableCellComponent;;


