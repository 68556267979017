
import { SET_TEMPLATES_EDITABLE } from "./UpgradeTemplates.ActionTypes";
import { columnDefs, defaultColDef } from "./upgrade_templates_grid_data/UpgradeTemplatesColumnDefs";
import { UpgradeTemplatesColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    validationFn: function (data) {
        return {
           ID: data.ID,
           JAN: { error: checkIfValidData(data.JAN, UpgradeTemplatesColumns.Jan.headerName) },
           FEB: { error: checkIfValidData(data.FEB, UpgradeTemplatesColumns.Feb.headerName) },
           MAR: { error: checkIfValidData(data.MAR, UpgradeTemplatesColumns.Mar.headerName) },
           APR: { error: checkIfValidData(data.APR, UpgradeTemplatesColumns.Apr.headerName) },
           MAY: { error: checkIfValidData(data.MAY, UpgradeTemplatesColumns.May.headerName) },
           JUN: { error: checkIfValidData(data.JUN, UpgradeTemplatesColumns.Jun.headerName) },
           JUL: { error: checkIfValidData(data.JUL, UpgradeTemplatesColumns.Jul.headerName) },
           AUG: { error: checkIfValidData(data.AUG, UpgradeTemplatesColumns.Aug.headerName) },
           SEP: { error: checkIfValidData(data.SEP, UpgradeTemplatesColumns.Sep.headerName) },
           OCT: { error: checkIfValidData(data.OCT, UpgradeTemplatesColumns.Oct.headerName) },
           NOV: { error: checkIfValidData(data.NOV, UpgradeTemplatesColumns.Nov.headerName) },
           DEC: { error: checkIfValidData(data.NOV, UpgradeTemplatesColumns.Dec.headerName) },
        }
    },
    businessValidationFn: function(currentData) {
        var duplicate = [];
        return duplicate;
    }
}

function checkIfValidData(value, name) {
    if (value === undefined || value === '' || value === null) {
        return 'Field' + " cannot be empty";
    }
    return null;
}

export default function templatesColumnDefReducer(state = initialState, action){
    switch (action.type) {
        case SET_TEMPLATES_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}