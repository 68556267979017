import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getDiversionsData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisediversionsResponse = await client.apis.plans.getAllDiversions({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });
        const responsesArray = await Promise.all([promisediversionsResponse]);
        const [diversionsResponse] = responsesArray.map(o => o.body.data);

        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": diversionsResponse.rows, "rowcount": diversionsResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
            return ({
                "diversionsData": diversionsResponse
            });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Diversions tab'
    }
}

export  async function uploadDiversionsCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadDiversions({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Diversions tab, Please try again!!');
    }
}

export async function saveDiversionsTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactdiversions({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchDiversionsDropdownService(){
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    return ({
        "users":promiseUserManagementEmailAndIdMap
    })
}

export async function getDiversionsFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseDiversionsFilterResponse = await client.apis.plans.getDiversionsFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseDiversionsFilterResponse]);
        const [diversionsResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": diversionsResponse.rows, "rowcount": diversionsResponse.rows[0] ? diversionsResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'Diversions tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllDiversionsData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promisediversionsDeleteResponse = await client.apis.plans.deleteAllDiversionsRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });

        if (promisediversionsDeleteResponse.obj && promisediversionsDeleteResponse.obj.statusCode !== '500') {
            return promisediversionsDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Diversions tab has internal server error, Unable to delete all the records!! Please try again'
    }
}

export async function insertS3UploadDiversionsCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.insertS3UploadedDiversionsData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi, ''),
                "fileName": filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Diversions tab, Please try again!!');
    }
}

export async function diversionsTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateDiversions({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });

        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Diversions Validation status ,Please try again!!';
    }
}

export async function getDiversionsTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseDiversionsResponse = await client.apis.plans.getDiversionsTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseDiversionsResponse.obj && promiseDiversionsResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseDiversionsResponse]);
            const [diversionsTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return diversionsTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Diversions error status ,Please try again!!';
    }
}

export async function getDiversionsErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisediversionsResponse = await client.apis.plans.getDiversionsErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promisediversionsResponse]);
        const [diversionsResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": diversionsResponse.rows, "rowcount": diversionsResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Diversions tab'
    }
}

export async function getDiversionsSearchData(planid, pageno, searchParams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseDiversionsSearchResponse = await client.apis.plans.getDiversionsSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseDiversionsSearchResponse]);

        const [diversionsSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": diversionsSearchResponse.rows, "rowcount": diversionsSearchResponse.rows[0] ? diversionsSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Diversions tab'
    }
}