import SwaggerClient from 'swagger-client';
import { MAX_MOVE_COST_BATCH_SIZE, MAX_ROWS_ALLOWED_FOR_EACH_GRID, SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, cloneAndRemoveUnnecessaryData, minifyTransaction, serverSideSearchColumnsMatch, timer } from '../../utils/utils';



const getPayloadBasedOnOperation=(payloadParams)=>{
    let reqPayloadBody;
    let {moveCostColumnsConstantData,filterSearchKey,movecostFilterParams,moveCostPageNo,operationApplied,uniqueErrorIDs} = payloadParams;
    if(operationApplied==='search'){
       let SearchColumnsFound,foundZoneCodes=[];
       SearchColumnsFound=serverSideSearchColumnsMatch('movecostgrid',moveCostColumnsConstantData,filterSearchKey)
        if(SearchColumnsFound.includes('zones')){
           const matcher = new RegExp(`${filterSearchKey}`, 'gi');
           moveCostColumnsConstantData['zoneCodes'].map((val)=>{
             if(val.name.match(matcher)){
               foundZoneCodes.push(String(val.code))
             }  
           })
        }
        reqPayloadBody={"searchColumns": [...SearchColumnsFound,foundZoneCodes],"searchText":filterSearchKey,"operation":operationApplied}
    }
     else if(operationApplied==='filter'){
       if(movecostFilterParams.move_cost[1]==='' || movecostFilterParams.move_cost[2]===''){
           movecostFilterParams.move_cost.splice(1)
          }
          reqPayloadBody={"filterparams": movecostFilterParams,"operation":operationApplied }
    }
    else if(operationApplied==='errordata'){
        reqPayloadBody={"operation":operationApplied,"uniqueids":uniqueErrorIDs};
     }
    else{
        reqPayloadBody={"operation":operationApplied}
    }
    return reqPayloadBody
}
export async function getMoveCostData(planId,moveCostPageNo){
    try{
    planId='master';
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    // await validateMoveCostData(planId, true);//Need to be done before getting data
    const promisemoveCostResponse =  client.apis.plans.getAllMoveCost({ "planid": planId ? planId : 'master','pageno':moveCostPageNo==0 || moveCostPageNo==undefined?0:moveCostPageNo });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const responsesArray = await Promise.all([promisemoveCostResponse, promiseZoneResponse]);
    // console.log("promisemoveCostResponse===>",promisemoveCostResponse);
    const [moveCostResponse, zoneResponse] = responsesArray.map(o => o.body.data);
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        return ({
            "gridData": moveCostResponse.rows, "rowcount": moveCostResponse.rowCount
        })
    } else {
        return ({
            "moveCostData": moveCostResponse.rows,"zones": zoneResponse,"rowcount":moveCostResponse.rowCount?parseInt(moveCostResponse.rowCount):moveCostResponse.rowCount,"totrowcount":moveCostResponse.totrowcount
        });
    }
    }catch(e){
        console.error(e);
       throw Error('Unable to fetch the data for Transport Costs tab')
    }
}

export async function getMoveCostErrorData(planId,uniqueIDs,pageNo){
    try{
    planId='master';
    // console.log(`Inside getMoveCostErrorData: planId=${planId}, pageNo=${pageNo}, uniqueIDs=${uniqueIDs} `);
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const moveCostErrorResponse = await client.apis.plans.getAllMoveCostError({ "planid": planId ? planId : 1, "pageno": pageNo }, { requestBody: { "isNewArchitecture": true } });
            const responsesArray = await Promise.all([moveCostErrorResponse]);
            const [moveCostResponse] = responsesArray.map(o => o.body.data);

            return ({
                "gridData": moveCostResponse.rows, "rowcount": moveCostResponse.rowCount
                
            }); 
    }else{
        pageNo=pageNo?pageNo:1;
        let offSet=(MAX_ROWS_ALLOWED_FOR_EACH_GRID*pageNo)-MAX_ROWS_ALLOWED_FOR_EACH_GRID;
        let choosenIDs=uniqueIDs.slice(offSet,offSet+MAX_ROWS_ALLOWED_FOR_EACH_GRID);
        // console.log("choosenIDs length===>>>",choosenIDs.length);
        const promisemoveCostErrorResponse =  client.apis.plans.getAllMoveCostError({ "planid": planId ? planId : 'master',"pageno":pageNo?pageNo:1}, { requestBody: { "uniqueids":choosenIDs } });
        const promiseZoneResponse =  checkAndLoadDimention("zone", client);
        const responsesArray = await Promise.all([promisemoveCostErrorResponse, promiseZoneResponse]);

        const [moveCostErrorResponse, zoneResponse] = responsesArray.map(o => o.body.data);
        // console.log("moveCostErrorResponse.rows length==>", moveCostErrorResponse.rows.length);
        return ({
            "moveCostErrorData": moveCostErrorResponse.rows,"zones": zoneResponse,"rowcount":moveCostErrorResponse.rowCount,"totrowcount":moveCostErrorResponse.totrowcount
        });
    }   
    }catch(e){
        console.error(e);
       throw Error('Unable to fetch error data in Transport Costs tab, Please try again!!')
    }
}
export async function getMoveCostErrorIds(planId,moveCostTotData, limited){
    planId='master';
    // console.log(`Total Data === PlanId: ${planId}, moveCostTotData:${moveCostTotData}`);
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray=[];
    // const promisemoveCostErrorResponse =  client.apis.plans.getAllMoveCostError({ "planid": planId ? planId : 'master',"pageno":pageNo?pageNo:1});
    
    let duplicateRowsResponse=await client.apis.plans.getDuplicateRows({ "planid": planId ? planId : 'master','limited':!!limited});
    // console.log("duplicateRowsResponse===",duplicateRowsResponse);
    if(limited && duplicateRowsResponse.body.data.length>0){
        // console.log("Limited is true, Error found, Returning immediately");
        return [duplicateRowsResponse.body.data[0].ID];
    }
    let numberOfPages=Math.ceil(moveCostTotData/MAX_MOVE_COST_BATCH_SIZE);
    let limit=MAX_MOVE_COST_BATCH_SIZE;
    let offset=0;
    for(let i=1;i<=numberOfPages;i++){
        offset=(MAX_MOVE_COST_BATCH_SIZE*i)-MAX_MOVE_COST_BATCH_SIZE;
        promiseArray.push(client.apis.plans.getRowsWithColumnErrorsPerPage({ "planid": planId ? planId : 'master',"pageno":i,"limit":limit,"offset":offset,'limited':!!limited}));
    }
    // console.log("Total Call to API==",promiseArray.length );
    const responsesArray = await Promise.all(promiseArray);

    // console.log("responsesArray===>",responsesArray);
    
    let tempInvalidRowIDsSet=new Set();
    //Adding duplicate rows
    // console.log("duplicateRowsResponse===",duplicateRowsResponse);
    duplicateRowsResponse.body.data.forEach(row=>tempInvalidRowIDsSet.add(row.ID));
    //Adding other rows to the set
    for(let i=0;i<responsesArray.length;i++){
        if(responsesArray[i].body.data!==-1){
            responsesArray[i].body.data.forEach(row=>tempInvalidRowIDsSet.add(row.ID));
        }else{
            throw new Error("Failed to get error records due to issue in API");
        }
    }

    let uniqueArrayIds=[...tempInvalidRowIDsSet].sort((a,b)=>a>b);
    // console.log("Total error records==",uniqueArrayIds.length );
    // console.log("Total error records==",uniqueArrayIds );

    return uniqueArrayIds;
}


export async function insertUploadData(planId,filename,gridname){
    try{
    planId='master';
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();

    const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
    let response = null
    try{
        response = await client.apis.plans.insertS3UploadedMoveCost({ "planid": planId ? planId : 1 }, {
           requestBody: {
               "updatedBy": userEmail,
               "gridName": gridname.replace(/grid/gi,''),
               "fileName": filename,
               "jobId":jobId
           }
       });
    }catch(e){
        console.log("e.message==>", e.message);
        let tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
        let status=tempResponse.body.data.rows[0].status;
        let operationStarted=tempResponse.body.data.rows[0].created_on;
        // let startTime=
        while(status!=='SUCCESS' && status!=='ERROR'){
            if((new Date().getMilliseconds()-(new Date(operationStarted)).getMilliseconds())>900000){
                status='TIMEOUT';
                break;
            }
            // console.log(`Getting the status....`);
            tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
            // console.log(`tempResponse===`,tempResponse);
            status=tempResponse.body.data.rows[0].status;
            // console.log(`status===`,status);
        }
        response = {
            obj:{
                statusCode: status==="SUCCESS"?'200':'500',
                body:status==="ERROR"?JSON.stringify({error:tempResponse.obj.data.rows[0].message}):status==="TIMEOUT"?JSON.stringify({error:"Timed Out"}):''
            }
        }
    }
    return response;
    }catch(e){
        console.error(e);
        throw 'Unable to upload the data for Transport Costs tab, Please try again!!'
    }
}

export async function getUploadPreSignedUrl(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisemoveCostUploadUrlResponse = await client.apis.plans.getPreSignedUrl();
    const responsesArray = await Promise.all([promisemoveCostUploadUrlResponse]);
    const [url] = responsesArray.map(o => o.body.uploadUrl.body);

    return ({
        "moveCostUploadUrl": url
    });
}


export async function getDownloadPreSignedUrl(planid,filename,paramsData){
    planid='master';
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    //For now in the interest of time, sending planId=1, because it is creating issue as the planid=master check is not present in the api only for this api
    //const promisemoveCostDownloadUrlResponse = await client.apis.plans.getDownloadPreSignedUrl({"planid":planid?planid:1,"filename":filename},{requestBody:getPayloadBasedOnOperation(paramsData)});
    const promisemoveCostDownloadUrlResponse = await client.apis.plans.getDownloadPreSignedUrl({"planid":1,"filename":filename},{requestBody:getPayloadBasedOnOperation(paramsData)});
    const responsesArray = await Promise.all([promisemoveCostDownloadUrlResponse]);
    const [url] = responsesArray.map(o => o.body.data);

    return ({
        "moveCostDownloadUrl": url
    });
}

export  async function uploadMoveCostCSV (planid,file){
    planid='master';
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadMoveCost({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });
        // await validateMoveCostData(planid);
        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Transport Costs tab, Please try again!!')
    }
}

export async function saveMoveCostTransactions(planid, transactionList){
    planid='master';
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    let promiseArray = [];
    await client.apis.plans.bulktransactmovecost({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });
    // await validateMoveCostData(planid);

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}


export async function fetchMoveCostFilterRecords(planId, filterparameters,moveCostPageNo){
    try{
    planId='master';
    if(!JSON.parse(localStorage.getItem("newArchitecture"))){
        if(filterparameters.move_cost[1]==='' || filterparameters.move_cost[2]===''){
            filterparameters.move_cost.splice(1)
        }
    }
   const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisemoveCostFilterResponse =  client.apis.plans.getMoveCostFilterRecords({ "planid": planId ? planId : 'master','pageno':moveCostPageNo==0 || moveCostPageNo==undefined?0:moveCostPageNo},{requestBody: {"filterparams": filterparameters,"isNewArchitecture":JSON.parse(localStorage.getItem("newArchitecture")) } });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const responsesArray = await Promise.all([promisemoveCostFilterResponse, promiseZoneResponse]);

    const [moveCostFilterResponse, zoneResponse] = responsesArray.map(o => o.body.data);
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        return ({
            "gridData": moveCostFilterResponse.rows, "rowcount":moveCostFilterResponse.rows[0]?moveCostFilterResponse.rows[0].rowcount:0
        })
    } else {
    return ({
        "moveCostFilterData": moveCostFilterResponse.rows,"zones": zoneResponse,"rowcount":moveCostFilterResponse.rows[0]?moveCostFilterResponse.rows[0].rowcount:0,"totrowcount":moveCostFilterResponse.totrowcount
    });
    }
    }catch(e){
        console.error(e);
        throw Error('Transport Costs tab has internal server error, please do re-apply the filter')
    }
}


export async function fetchMoveCostSearchRecords(planId, searchInColumns,searchText,moveCostPageNo){
    try{
    planId='master';
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        const promiseMoveCostSearchResponse = client.apis.plans.getMoveCostSearchRecords({ "planid": planId ? planId : 'master', 'pageno': moveCostPageNo }, { requestBody: { "searchParams": searchInColumns,"isNewArchitecture": true } });
        const responsesArray = await Promise.all([promiseMoveCostSearchResponse]);

        const [moveCostSearchResponse] = responsesArray.map(o => o.body.data);

        return ({
            "gridData": moveCostSearchResponse.rows, "rowcount": moveCostSearchResponse.rows[0] ? moveCostSearchResponse.rows[0].rowcount : 0
        });
    }else{
            let SearchColumnsFound,foundZoneCodes=[]
            if(searchText!='' && searchText!='$'){
            SearchColumnsFound=serverSideSearchColumnsMatch('movecostgrid',searchInColumns,searchText)
            if(SearchColumnsFound.includes('zones')){
                const matcher = new RegExp(`${searchText}`, 'gi');
                searchInColumns['zoneCodes'].map((val)=>{
                if(val.name.match(matcher)){
                    foundZoneCodes.push(String(val.code))
                }  
                })
            }
            const promisemoveCostFilterResponse =  client.apis.plans.getMoveCostSearchRecords({ "planid": planId ? planId : 'master','pageno':moveCostPageNo==0 || moveCostPageNo==undefined?0:moveCostPageNo},{requestBody: {"searchColumns": [...SearchColumnsFound,foundZoneCodes],"searchText":searchText } });
            const promiseZoneResponse =  checkAndLoadDimention("zone", client);
            const responsesArray = await Promise.all([promisemoveCostFilterResponse, promiseZoneResponse]);
        
            const [moveCostFilterResponse, zoneResponse] = responsesArray.map(o => o.body.data);

            return ({
                "moveCostFilterData": moveCostFilterResponse.rows,"zones": zoneResponse,"rowcount":moveCostFilterResponse.rows[0]?moveCostFilterResponse.rows[0].rowcount:0,"totrowcount":moveCostFilterResponse.totrowcount
            });
        }
    }
    }catch(e){
        console.error(e);
        throw 'Unable to search data in Transport Costs tab, Please try again!!'
    }
 }

export async function deleteMoveCostRecordsService(planId,data){
    try{
    planId='master';
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const promisemoveCostDeleteResponse = await client.apis.plans.movecostdeleterecords({ "planid": planId ? planId : 'master' }, { requestBody: { "updatedBy": userEmail, "isNewArchitecture": true, ...data } });
            if (promisemoveCostDeleteResponse.obj && promisemoveCostDeleteResponse.obj.statusCode !== '500') {
                return promisemoveCostDeleteResponse;
            }
            else {
                throw Error();
            }
    }else{
        let promisemoveCostDeleteResponse='';
        let {moveCostPageNo} = data;
      
      promisemoveCostDeleteResponse = await client.apis.plans.movecostdeleterecords({ "planid": planId ? planId : 'master','pageno':moveCostPageNo==0 || moveCostPageNo==undefined?0:moveCostPageNo},{requestBody:getPayloadBasedOnOperation(data)});
      // await validateMoveCostData(planId);
      return promisemoveCostDeleteResponse;
    }
    }catch(e){
        console.error(e);
        throw 'Transport Costs tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}


export async function fetchMoveCostDropdownService(planId){
    planId='master';
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseCarClassCodeResponse =  checkAndLoadDimention("carClassCode", client);
    const promiseGetAdminCarClasses =  checkAndLoadDimention("admincarclassAndgroup", client);
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseGetAllCarsGroup =  checkAndLoadDimention("carGroup", client);
    const promiseGetAdminCarGroup =  checkAndLoadDimention("adminCarGroup", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseBUResponse =  checkAndLoadDimention("BU", client);
    const promiseAllCargroupCarClassResponse =  checkAndLoadDimention("AllCargroupCarClass", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
          promiseCarClassCodeResponse, 
          promiseGetAdminCarClasses,
          promiseGetAllCarsGroup,
          promiseGetAdminCarGroup,
          promiseZoneResponse,
          promisePoolResponse,
          promiseAdminPoolResponse,
          promiseBUResponse,
          promiseAllCargroupCarClassResponse
    ]);

    const [
          carClassCodeResponse,
          adminCarClasses,
          carsGroupResponse ,
          admincarGroupName,
          zones ,
          poolResponse ,
          adminPoolResponse,
          buResponse,
          allCargroupCarClassResponse 
    ] = responsesArray.map(o => o.body.data);
    return ({
        "car_class": carClassCodeResponse,
        "adminCarClass":adminCarClasses,
        "car_group_name": carsGroupResponse,
        "admincarGroupName":admincarGroupName,
        "zones": zones,
        "pools": poolResponse,
        "adminpools":adminPoolResponse,
        "bu": buResponse,
        "allCargroup_CarClass":allCargroupCarClassResponse,
        "users":promiseUserManagementEmailAndIdMap
    })  
}


export async function fetchMoveCostFilterService(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseCarClassCodeResponse =  checkAndLoadDimention("carClassCode", client);
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseGetAllCarsGroup =  checkAndLoadDimention("carGroup", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseBUResponse =  checkAndLoadDimention("BU", client);

    const responsesArray = await Promise.all([
          promiseCarClassCodeResponse, 
          promiseGetAllCarsGroup,
          promiseZoneResponse,
          promisePoolResponse,
          promiseBUResponse
    ]);

    let [
          carClassCodeResponse,
          carsGroupResponse ,
          zones ,
          poolResponse ,
          buResponse     
    ] = responsesArray.map(o => o.body.deletedData?[...o.body.data,...o.body.deletedData]:o.body.data);

//Zone names
    var zoneData = [];
    var zoneCodes=zones
    zones.forEach(val => {
        zoneData.push(val.name)
    });
    zones=zoneData

//Pool names
    var poolData = [];
    poolResponse.forEach(val => {
        if (val.code == 'ALL')
            poolData.push(val.code)
    });
    poolResponse.forEach(val => {
        if (val.code != 'ALL')
            poolData.push(val.code)
    });
    poolResponse=poolData

    return ({
        "car_class": carClassCodeResponse,
        "car_group_name": carsGroupResponse,
        "zones": zones,
        "zoneCodes":zoneCodes,
        "pools": poolResponse,
        "bu": buResponse
    })  
}

//Not using this function need to remove after completing new version
export async function validateMoveCostData(planId, isGetMethod){
    planId='master';
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

            const accessToken=userNToken.accessToken;
            const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
        const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
        
        let status=await client.apis.plans.initiateTabValidation(
            { "planid": planId ? planId : "master" }, 
            { requestBody: { 
                    "updatedBy": userEmail,
                    "jobId": jobId,
                    "isGetMethod": isGetMethod,
                } 
            }
        );

        if(status==='VALIDATION-INITIATED'){
            
            await timer(5000); // then the created Promise can be awaited

        }

        return;
    }catch(e){
        console.log("ERROR while validating Transport Costs data: ", e.message);
        throw e;        
    }

    
}

export async function moveCostTabValidationService(planid,transactionList){
    planid=1;
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.plans.validateMoveCost({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate Transport Costs tab, Please try again!!'
    }
}

export async function getMoveCostTabErrorStatus(planid,pageno){
    planid=1
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseMoveCostResponse = await client.apis.plans.getMoveCostTabErrorStatus({"planid":planid?planid:1});
    if(promiseMoveCostResponse.obj && promiseMoveCostResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseMoveCostResponse]);
    const [moveCostTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return moveCostTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Transport Costs tab error status, Please try again!!';
   }
   }