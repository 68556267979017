import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../../Constants';

export async function getBvDepOverrideData(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
     const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
     const promisebvDepOverrideResponse= await(client.apis.adjustments.getAllBvDepOverRide({  "planid": planid ? planid : 'master',"pageno":(pageno!=undefined || pageno!=null)?pageno:1}));
     const responsesArray = await Promise.all([promisebvDepOverrideResponse]);
     const [bvDepOverrideResponse] = responsesArray.map(o => o.body.data);

    return ({
        "gridData": bvDepOverrideResponse.rows,"rowcount":bvDepOverrideResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       throw 'Unable to fetch the data for Bv Dep Override tab'
       //return {'Error':'getBvepOverrideData failed'}
   }
}


export async function deleteAllBvDepOverrideData(planid,params){
    try{
            const userNToken=await getAccessTokenNUserDetailsAfterAuth();
            const accessToken=userNToken.accessToken;
            const userEmail = userNToken.email;
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
            
            let promiseBvDepOverrideDeleteResponse = await client.apis.adjustments.deleteAllBvDepOverRideRecords({ "planid": planid},{requestBody: {"updatedBy":userEmail,...params}});
            if(promiseBvDepOverrideDeleteResponse.obj && promiseBvDepOverrideDeleteResponse.obj.statusCode !== '500'){
                return promiseBvDepOverrideDeleteResponse;
            }
            else{
                throw Error();
            } 
        } 
        catch (error) {
            console.error(error);
            throw 'Bv Dep OverRide tab has internal server error, Unable to delete all the records!!, Please try again'
        }
       }

export async function uploadBvDepOverrideGrid(planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const response = await client.apis.adjustments.uploadBvDepOverRide({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Bv Dep OverRide tab, Please try again!!');
        //return {'Error':'uploadMileageTableCSV failed'}
    }
}

export async function insertS3UploadedBvDepOverRideData(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        // const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();

        // const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId

        let response = null;
        // try{
            response = await client.apis.adjustments.insertS3UploadedBvDepOverRideData({"planid":planid},{
                requestBody: {
                    "updatedBy":userEmail,
                    "gridName":gridname.replace('grid',''),
                    "fileName":filename
                }
            });

        // }catch(e){
        //     console.log("e.message==>", e.message);
        //     let tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
        //     let status=tempResponse.body.data.rows[0].status;
        //     let operationStarted=tempResponse.body.data.rows[0].created_on;
        //     // let startTime=
        //     while(status!=='SUCCESS' && status!=='ERROR'){
        //         if((new Date().getMilliseconds()-(new Date(operationStarted)).getMilliseconds())>900000){
        //             status='TIMEOUT';
        //             break;
        //         }
            
        //         tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
            
        //         status=tempResponse.body.data.rows[0].status;
            
        //     }
        //     response = {
        //         obj:{
        //             statusCode: status==="SUCCESS"?'200':'500',
        //             body:status==="ERROR"?JSON.stringify({error:tempResponse.obj.data.rows[0].message}):status==="TIMEOUT"?JSON.stringify({error:"Timed Out"}):''
        //         }
        //     }
   
        // }
        return response;
    } 
    catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Bv Dep OverRide tab, Please try again!!');
    }
}

export async function getBvDepOverrideErrorData(planid, pageno){
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseBvDepOverrideTableErrorResponse = await client.apis.adjustments.getBvDepOverRideErrorRecords({"planid":planid?planid:1 ,'pageno':pageno});
        const responsesArray = await Promise.all([promiseBvDepOverrideTableErrorResponse]);
        const [BvDepOverrideTableResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": BvDepOverrideTableResponse.rows, "rowcount": BvDepOverrideTableResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch error data in BvDepOverride tab, Please try again!!'
     }
}

export async function bvDepOverRideValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.adjustments.validateBvDepOverRide({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });

        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Bv dep override Validation status ,Please try again!!';
    }
}

export async function getBvDepOverrideTabErrorStatus(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseBvDepOverrideResponse = await client.apis.adjustments.getBvDepOverRideTabErrorStatus({"planid":planid?planid:1});
    if(promiseBvDepOverrideResponse.obj && promiseBvDepOverrideResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseBvDepOverrideResponse]);
    const [bvDepOverrideTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return bvDepOverrideTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Bv Dep Override tab error status, Please try again!!';
   }
   }