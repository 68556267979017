
import { fetchPrograms, deleteProgram, fetchProgramsDropdownService, saveTransactions, uploadProgramCSV } from '../../services/programs/ProgramsService';
import {
    FETCH_PROGRAM_DATA_PENDING, FETCH_PROGRAM_DATA_SUCCESS,
    FETCH_PROGRAM_DATA_ERROR,FETCH_FILTER_DATA,
    FETCH_FILTER_CATEGORIES_PENDING, FETCH_FILTER_CATEGORIES_SUCCESS,
    FETCH_FILTER_CATEGORIES_ERROR,
    DELETE_PROGRAM_DATA_ERROR, FETCH_PROGRAM_DROPDOWN_ERROR, FETCH_PROGRAM_DROPDOWN_SUCCESS,
    FETCH_PROGRAM_DROPDOWN_PENDING,
    FILTER_SEARCH_PROGRAM_DATA, SAVE_TRANSACTION_PENDING, SAVE_TRANSACTION_SUCCESS, SAVE_TRANSACTION_ERROR,
    UPLOAD_PROGRAMS_PENDING,UPLOAD_PROGRAMS_SUCCESS,UPLOAD_PROGRAMS_ERROR,RESET_TO_PROGRSMS_INITIALSTATE,
    SET_PROGRAMS_FILTER_PARAMS
} from "./Programs.ActionTypes";
import filterCategories from '../../data/filterCategories';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchProgramData = (id) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PROGRAM_DATA_PENDING));
            let programData = await fetchPrograms(id);
            if (programData !== -1) {
                dispatch(serviceActionSuccess(FETCH_PROGRAM_DATA_SUCCESS, {"programs": programData, "filterBy": false}));
            }
            else
            {
                dispatch(serviceActionError(FETCH_PROGRAM_DATA_ERROR, "Unknown error"));
            }
        } catch (e) {
            console.error(e);
            dispatch(serviceActionError(FETCH_PROGRAM_DATA_ERROR, "Unknown error"));
        }
    }
}

export const fetchFilterConstraints = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_FILTER_CATEGORIES_PENDING));
            dispatch(serviceActionSuccess(FETCH_FILTER_CATEGORIES_SUCCESS, filterCategories()));
        } catch (_) {
            dispatch(serviceActionError(FETCH_FILTER_CATEGORIES_ERROR, "Unknown error"));
        }
    }
}

export const fetchFilterData = () => {
    return async dispatch => {
            dispatch(serviceActionSuccess(FETCH_FILTER_DATA));
    }
}
export const resetPrograms = ()=>{
  return  dispatch=>{
      dispatch(serviceActionSuccess(RESET_TO_PROGRSMS_INITIALSTATE))
  }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}

export const deleteProgramsRow = (planid, id) => {
    return async dispatch => {
        let res = await deleteProgram(planid, id)
        if (res && res.body.data && res.body.data.rowCount == 1) {   
            dispatch(serviceActionPending(FETCH_PROGRAM_DATA_PENDING));
            let programData = await fetchPrograms(planid)
            if (programData) {
                dispatch(serviceActionSuccess(FETCH_PROGRAM_DATA_SUCCESS, {"programs": programData, "filterBy": false}));
            }
            else {
                dispatch(serviceActionError(FETCH_PROGRAM_DATA_ERROR, programData));
            }
        } else {
            dispatch(serviceActionError(DELETE_PROGRAM_DATA_ERROR, res.response));
        }
    }
}


// export const deleteRow = (id) => {
//     return async dispatch => {
//         let res = await deleteProgram(id)
//         if (res.response == 'success') {
//             let programData = await fetchPrograms()
//             if (programData) {
//                 dispatch(serviceActionPending(FETCH_PROGRAM_DATA_PENDING));
//                 dispatch(serviceActionSuccess(FETCH_PROGRAM_DATA_SUCCESS, [...programData]));         
//             }
//             else {
//                 dispatch(serviceActionError(FETCH_PROGRAM_DATA_ERROR, programData));
//             }
//         } else {
//             dispatch(serviceActionError(DELETE_PROGRAM_DATA_ERROR, res.response));
//         }
//     }
// }

export const fetchProgramDropdownValues = (data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PROGRAM_DROPDOWN_PENDING));
            let programDropdownData = await fetchProgramsDropdownService();
            if (programDropdownData) {
                dispatch(serviceActionSuccess(FETCH_PROGRAM_DROPDOWN_SUCCESS, programDropdownData));
                //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
                if(validateTransactionsIfMasterDataChanged){
                    if(isNewArchitecture){ // this if is for new architecture
                        dispatch(newValidateGrid("programsGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                    }
                    else{ // this else is for old architecture and will be removed later
                        dispatch(validateGrid("programsgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                    }  
                }
                dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_PROGRAM_DROPDOWN_ERROR, "Unknown error"));
        }
    }
}


export const saveTransactionData = (planid, transactionList) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_TRANSACTION_PENDING));
            await saveTransactions(planid, transactionList);
            let programData = await fetchPrograms(planid);
            if (programData) {
                dispatch(serviceActionSuccess(SAVE_TRANSACTION_SUCCESS, {"programs": programData, "filterBy": false}));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const uploadProgramData = (planId, files) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_PROGRAMS_PENDING));
            let uploadResp = await uploadProgramCSV(planId, files);
            let programData = await fetchPrograms(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_PROGRAMS_SUCCESS, {"programs": programData, "filterBy": false}));
            }
            else{
                dispatch(serviceActionError(UPLOAD_PROGRAMS_ERROR, "PROGRAMS upload failed"));
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_PROGRAMS_ERROR, "PROGRAMS upload failed"));
        }
    }
}

export const searchRecord = (searchkey) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(FILTER_SEARCH_PROGRAM_DATA, searchkey));  
    }
}

export const setFilterParameters = (filterParams) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_PROGRAMS_FILTER_PARAMS,filterParams))
    }
}



let PROGRAM_FILTER_MAP={ "PROGRAM_YEAR": [], "PROGRAM_TYPE": [], "ACCEPTABLE_ZONES": [], "CAR_CLASS_CODE": [], "MODEL_GROUP": [], "MODEL_YEAR": [], "NET_CAPITAL_COST": "", "BEGIN_DATE": ["between"], "END_DATE": ["between"], "MIN_AGE": ["between"], "MAX_AGE": ["between"], "DEPRECIATION_COST": "", "MIN_MILEAGE": ["between"], "MAX_MILEAGE": ["between"], "INCENTIVE": "", "ADD_VOLUME_MIN": ["between"], "ADD_VOLUME_MAX": ["between"], "SPI_CODE": [], "MANUFACTURER_GROUP": [], "START_MILEAGE": "", "BUY_STATUS": [] };

export function cloneProgramFilterMap(){
    return { "PROGRAM_YEAR": [], "PROGRAM_TYPE": [], "ACCEPTABLE_ZONES": [], "CAR_CLASS_CODE": [], "MODEL_GROUP": [], "MODEL_YEAR": [], "NET_CAPITAL_COST": "", "BEGIN_DATE": ["between"], "END_DATE": ["between"], "MIN_AGE": ["between"], "MAX_AGE": ["between"], "DEPRECIATION_COST": "", "MIN_MILEAGE": ["between"], "MAX_MILEAGE": ["between"], "INCENTIVE": "", "ADD_VOLUME_MIN": ["between"], "ADD_VOLUME_MAX": ["between"], "SPI_CODE": [], "MANUFACTURER_GROUP": [], "START_MILEAGE": "", "BUY_STATUS": [] };
}