import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import ProgramsTabPanel from "../programs/ProgramsTabPanel";
import { UpdateMasterDataCache, fetchGridData, fetchGridTabErrorStatus, fetchMultiTabErrorStatusBasedOnModule, resetGridState, saveGridTransactionData, setGridName, setGridUserPageNumber, validateSingleGridsData } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { addNewRow, populateGrid, populateGrid2, validateRow, refreshGridData, cleanTransactions, validateGrid, deleteRow, deleteRowEditMode } from '../../store/MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import MainPlanInputsActionBar from "../../components/newactionbar/MainPlanInputsActionBar";
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { MAX_ROWS_ALLOWED_FOR_EACH_GRID, MessageNotificationValues, PROGRAM, SCHEDULE, planAssumptionTabsForPlanInputs } from "../../Constants";
import SchedulesTabPanel from "../schedules/SchedulesTabPanel";
import AllocatedTabPanel from "../allocated/AllocatedTabPanel";
import MoveConstraintsTabPanel from "../moveconstraints/MoveConstraintsTabpanel";
import ConstraintsTabPanel from "../constraints/ConstraintsTabPanel";
import UpgradePathsTabPanel from "../upgradepaths/UpgradePathsTabPanel";
import PlanAssumptionTabPanel from "./PlanAssumptionTabPanel";
import NttTabs from "../../components/tabs/NttTabs";
import NttTab from "../../components/tabs/NttTab";
import './MainPlanInputsPage.css';
import ParametersTabPanel from "../parameters/ParametersTabPanel";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const MainPlanInputsTabPanel = (props) => {
  //loadGridDataForFilterParams,loadGridDataForErrorView ,loadGridDataForSearchParam  ====>we might delete this later
  const { tabsData, value, gridColDefReducer,setGridColDefReducer, gridData, setShowSaveOption, showSaveOption, selectedPlanId, gridName, gridUserPageno, loadGridData, pending ,initializeFilterParamsAndViewStatus} = props;
  const [refreshCells, setRefreshCells] = useState(false);
  const [bulkRowDeleteIconDisabled, setBulkRowDeleteIconDisabled] = useState(true);
  //const [isSaveClicked, setIsSaveClicked] = useState(null);
  const [saveCancelDisabled, setSaveCancelDisabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();
  const isSaveClicked = useRef(null)
  const planAssumptionTabvalue = useRef({tabIndex:planAssumptionTabsForPlanInputs.findIndex(tab=>tab.gridName==='agemileageGrid'),tabName:'agemileageGrid'}); // this Object consisting of tab index and gridName for the initail tab to be rendered when user clicks on planAssumptions tab for the first time.

  const gridReducer = useSelector((state) => {
    return state.inputsMultiGridUIReducer
  });

  const masterDataUpdateState = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  });

  const gridRowDataInvalid = useSelector((state) => {
    return state.inputsMultiGridUIReducer.isAnyRowInvalid
  });

  const gridTotalRowCount = useSelector((state) => {
    return state.PaginationGridReducer.gridTotalRowCount;
  });

  const gridTransactionSaveSuccess = useSelector((state) => {
    return state.PaginationGridReducer.gridTransactionSaveSuccess;
  })

  const gridReducerCurrentData = useSelector((state) => {
    return state.inputsMultiGridUIReducer.currentData
  });

  const gridFilterViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridFilterView};
  },shallowEqual);
  const gridSearchViewStatus = useSelector((state)=>{
    return {...state.PaginationGridReducer.gridSearchView};
  },shallowEqual)

  const gridErrorViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridErrorView};
  },shallowEqual);

  const callGridFetchApi = useSelector((state)=>{
    return state.PaginationGridReducer.callGridFetchApi;
  })

  const uploadGridSuccess=useSelector((state) => {
    return state.PaginationGridReducer.gridUploadStatus;
  });

  const  gridErrorMessage = useSelector((state) => {
    return state.PaginationGridReducer.gridErrorMessage
  });

  const gridTabErrorStatus = useSelector((state)=>{
    return state.PaginationGridReducer.gridTabErrorStatus;
  });

  const gridDeleteAllDataSuccess = useSelector((state)=>{
    return state.PaginationGridReducer.gridDeleteAllDataSuccess;
  });

  const handleRowEditingStarted = useCallback((isRowEditing, isOfTypeCopyOrDelete, transactions) => {
    if (isRowEditing && transactions.length > 0) {
      setShowSaveOption(true);
      if (!saveCancelDisabled && !isOfTypeCopyOrDelete)
        setSaveCancelDisabled(true);
    }
  }, [saveCancelDisabled])

  const handleRowEditingStopped = useCallback((isRowEditing) => {
    if (isRowEditing) {
      setShowSaveOption(false);
    }
  }, [])

  const handleCellEditingStopped = useCallback(() => {
    setSaveCancelDisabled(false);
  }, [])

  const downloadCSV = useCallback((csvStr, filename) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }, [])

  const handleAddNewRow = useCallback((gridName,rowIndex) => {
    setShowSaveOption(true);
    dispatch(addNewRow(gridName, rowIndex, []));
  }, [])

  const handleMultipleRowDelete = useCallback((selectedRows) => {
    if (selectedRows.length > 0) {
      setBulkRowDeleteIconDisabled(false);
      setSelectedRows(selectedRows);
    } else {
      setBulkRowDeleteIconDisabled(true);
      setSelectedRows(selectedRows)
    }
  }, [selectedRows])

  const deleteSelectedRows = useCallback((gridName) => {

    if (gridName != '' && selectedRows.length > 0) {
      selectedRows.map((i) => {
        dispatch(deleteRow(gridName, i.ID));
      })
    }
    
    // let transactions = [];
    // let ID = '';
    // if (gridName != '') {
    //   transactions = gridReducer.transactions;
    // }
    // if (transactions.length > 0) {
    //   transactions.map((i) => {
    //     if (i.type == 'edit') {
    //       dispatch(deleteRowEditMode(gridName, i.data.ID))
    //      } })
    //     //else
    //       if (selectedRows.length > 0) { //in case of copy row if we select both the rows we won't get both in transactions.
    //         selectedRows.map((i) => {
    //           dispatch(deleteRow(gridName, i.ID));
    //         })
    //       }
      
    // } else 
    
  }, [selectedRows])

  const handleSaveClicked = useCallback((gridName) => {
    //setSnackBarIfError(!snackBarIfError); 
    dispatch(UpdateMasterDataCache())
    if (gridName != '') {
      // setIsSaveClicked(gridName);
      isSaveClicked.current = gridName
      dispatch(validateGrid(gridName, gridReducer.currentData, gridColDefReducer.validationFn, gridColDefReducer.businessValidationFn));
    }
  }, [gridReducerCurrentData])


  const handleCancelClicked = useCallback(async (gridName) => {
    // setRefreshCells(!refreshCells);
    // setIsSaveClicked(null);
    isSaveClicked.current = null
    await dispatch(UpdateMasterDataCache())
    //setRefreshCells(prevState=>!prevState)
    //dispatch(cleanTransactions(gridName));
    setShowSaveOption(false);
    loadGridData()
  },[callGridFetchApi,gridUserPageno]); //callGridFetchApi triggers when there is a change in gridName,gridFilterViewStatus,gridErrorViewStatus,gridSearchViewStatus.

  // const fetchValidTransactions = (transactions) => {
  //   const filterValidTransactions = transactions.filter(item => {
  //     return (((item && item.data) && (item.data.status != "ERROR_ROW" && item.data.status != "ERROR_COPY_ROW" &&
  //       item.data.status != "ERROR_NEW_ROW" && item.data.status != "INVALID_ROW" && item.data.status != "INVALID_NEW_ROW") ||
  //       (item && !item.data) && item.type == "delete"))
  //   })
  //   return filterValidTransactions;
  // }

  // const fetchInvalidTransactions = (transactions) => {
  //   const filterInValidTransactions = transactions.filter(item => {
  //     return ((item && item.data) && (item.data.status == "ERROR_ROW" || item.data.status == "ERROR_COPY_ROW" ||
  //       item.data.status == "ERROR_NEW_ROW" || item.data.status == "INVALID_ROW" || item.data.status == "INVALID_NEW_ROW") ||
  //       (item && !item.data) && item.type == "add")
  //   })
  //   return filterInValidTransactions;
  // }

  const fetchInvalidTransactions = (transactions) => {
    return !!transactions.find(item => {
      return ((item && item.data) && (item.data.status == "ERROR_ROW" || item.data.status == "ERROR_COPY_ROW" ||
        item.data.status == "ERROR_NEW_ROW" || item.data.status == "INVALID_ROW" || item.data.status == "INVALID_NEW_ROW") ||
        (item && !item.data) && item.type == "add")
    })
  }

  // useEffect(() => {
  //   if (!pending && !masterDataUpdateState && isSaveClicked.current === gridName) {
  //     const filterValidTransactions = fetchValidTransactions(gridReducer.transactions);
  //     const filterInValidTransactions = fetchInvalidTransactions(gridReducer.transactions);
  //     if ((!gridReducer.transactions || gridReducer.transactions.length == 0) && (isSaveClicked.current != null && isSaveClicked.current == gridName)) {
  //       // setSnackBarOpen(false);
  //       //userMessage('warning', 'Nothing to be saved');
  //       dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Nothing to be saved'))
  //       setShowSaveOption(false);
  //       //setIsSaveClicked(null);
  //       isSaveClicked.current = null
  //     }

  //     if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked.current != null && isSaveClicked.current == gridName)) {
  //       if ((!filterInValidTransactions || filterInValidTransactions.length <= 0)) {
  //         //dispatch(saveGridTransactionData(userMessage,selectedPlanId,filterValidTransactions,gridName,selectedTableName,typeName,gridUserPageno)); 
  //         dispatch(saveGridTransactionData(undefined, selectedPlanId, filterValidTransactions, gridName, gridName, undefined, gridUserPageno,gridFilterUserSelectedParams[gridName],gridFilterViewStatus[gridName]));
  //         //setIsSaveClicked(null);
  //         isSaveClicked.current = null
  //       }
  //       else if ((filterInValidTransactions && filterInValidTransactions.length > 0)) {
  //         //userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
  //         dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Some modified rows are invalid. Please fix them before trying to save again.'))
  //       }
  //     }

  //     if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked.current == gridName)) {
  //       //setSnackBarOpen(true);
  //       // alert show be shown that Cannot save. Please review if any row is invalid.
  //       //userMessage('error', 'Cannot save. Please review if any row is invalid.');
  //       dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Cannot save. Please review if any row is invalid.'))
  //     }
  //   }
  // }, [masterDataUpdateState, pending]); 

  useEffect(async() => {
    if (!pending && !masterDataUpdateState && isSaveClicked.current === gridName) {
      const filterInValidTransactions = fetchInvalidTransactions(gridReducer.transactions);
      if ((!gridReducer.transactions || gridReducer.transactions.length == 0) && (isSaveClicked.current != null && isSaveClicked.current == gridName)) {
        dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Nothing to be saved'))
        setShowSaveOption(false);
        isSaveClicked.current = null
      }
      if (!filterInValidTransactions && (isSaveClicked.current != null && isSaveClicked.current == gridName)) {
        let pageNo=gridUserPageno;
        if(gridUserPageno!=1&&gridUserPageno==Math.ceil(gridTotalRowCount / MAX_ROWS_ALLOWED_FOR_EACH_GRID)&&gridReducerCurrentData.length==0){
          pageNo=gridUserPageno-1;
        }
        dispatch(saveGridTransactionData(undefined, selectedPlanId, gridReducer.transactions, gridName, gridName, undefined, pageNo,loadGridData));
        isSaveClicked.current = null
      }
      else if ((filterInValidTransactions && isSaveClicked.current == gridName)) {
        dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Cannot save. Please review if any row is invalid.'))
      }
    }
  }, [masterDataUpdateState, pending]);

  useEffect(async() => {
    if (gridTransactionSaveSuccess || gridDeleteAllDataSuccess) {
      //dispatch(cleanTransactions(gridName));
      //dispatch(refreshGridData(gridName));
      setShowSaveOption(false);
      //setIsSaveClicked(null);
      isSaveClicked.current = null

        if (gridTabErrorStatus[gridName] && gridData.length > 0) {
          dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Successfully saved rows. But some rows are still invalid, please review.'))
        } else {
          dispatch(addNotificationMessage(MessageNotificationValues.Severity.success, 'Successfully saved rows'))
        }

      //dispatch(validateGrid(gridName, gridReducer.currentData, gridColDefReducer.validationFn, gridColDefReducer.businessValidationFn));
    }
    else if (gridTransactionSaveSuccess === false) {
      dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, gridErrorMessage));
    }
  }, [gridTransactionSaveSuccess,gridDeleteAllDataSuccess]);

  useEffect(async() => {
    if (!showSaveOption && uploadGridSuccess == true) {
      if (gridTabErrorStatus[gridName]){
        dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Successfully uploaded. Some rows are invalid. Please review'));
      }else{
        dispatch(addNotificationMessage(MessageNotificationValues.Severity.success,'Successfully uploaded'));
      }
    } else if (uploadGridSuccess == false) {
      dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, gridErrorMessage));
    }
  }, [uploadGridSuccess])

  return (
    <div className="mainplaninputstabpanel">
      <MainPlanInputsActionBar showOption={showSaveOption}
        pending={pending}
        selectedPlanId={selectedPlanId}
        gridUserPageno={gridUserPageno}
        gridReducer={gridReducer}
        onCancelClicked={handleCancelClicked}
        onSaveClicked={handleSaveClicked}
        onAddNewRowClick={handleAddNewRow}
        deleteSelectedRows={deleteSelectedRows}
        handleSaveCancelDisabled={saveCancelDisabled}
        gridColDefReducer={gridColDefReducer}
        gridData={gridData} gridName={gridName}
        loadGridData={loadGridData}
        bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
       // loadGridDataForFilterParams={loadGridDataForFilterParams} 
        initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}
        //loadGridDataForErrorView={loadGridDataForErrorView}     
        //loadGridDataForSearchParam={loadGridDataForSearchParam}   
        downloadCSV={downloadCSV}/>
      {tabsData.map((tabInfo, index) => (
        <TabPanel key={index}
          value={value} index={index}>
          {(index == 0 && gridName === "programsGrid") ?
            <ProgramsTabPanel
              newArchitecture={true}
              gridName={gridName}
              pending={pending}
              refreshCells={refreshCells}
              currentView={"grid"}
              gridReducer={gridReducer}
              handleRowEditingStarted={handleRowEditingStarted}
              handleRowEditingStopped={handleRowEditingStopped}
              programgridcoldef={gridColDefReducer}
              tabInfo={tabInfo} tabIndex={value}
              handleCellEditingStopped={handleCellEditingStopped}
              selectedPlanId={selectedPlanId}
              loadGridData={loadGridData}
              handleMultipleRowDelete={handleMultipleRowDelete}
            >
            </ProgramsTabPanel>
            : (index == 1 && gridName === "schedulesGrid") ? <SchedulesTabPanel
              newArchitecture={true}
              gridName={gridName}
              pending={pending}
              refreshCells={refreshCells}
              currentView={"grid"}
              gridReducer={gridReducer}
              handleRowEditingStarted={handleRowEditingStarted}
              handleRowEditingStopped={handleRowEditingStopped}
              schedulesGridColDef={gridColDefReducer}
              tabInfo={tabInfo} tabIndex={value}
              handleCellEditingStopped={handleCellEditingStopped}
              selectedPlanId={selectedPlanId}
              handleMultipleRowDelete={handleMultipleRowDelete}
              loadGridData={loadGridData}
            >
            </SchedulesTabPanel>
              : (index == 2 && gridName === "allocatedGrid") ? <AllocatedTabPanel
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                allocatedGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={value}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
              >
              </AllocatedTabPanel>
                : (index == 3 && gridName === "constraintsGrid") ? <ConstraintsTabPanel
                  newArchitecture={true}
                  pending={pending}
                  gridName={gridName}
                  refreshCells={refreshCells}
                  currentView={'grid'}
                  gridReducer={gridReducer}
                  handleRowEditingStarted={handleRowEditingStarted}
                  handleRowEditingStopped={handleRowEditingStopped}
                  gridColumnDef={gridColDefReducer}
                  tabInfo={tabInfo} tabIndex={value}
                  handleCellEditingStopped={handleCellEditingStopped}
                  selectedPlanId={selectedPlanId}
                  handleMultipleRowDelete={handleMultipleRowDelete}
                  loadGridData={loadGridData}
                >
                </ConstraintsTabPanel> :
                  (index == 4 && gridName === "moveconstraintsGrid") ? <MoveConstraintsTabPanel
                    newArchitecture={true}
                    pending={pending}
                    gridName={gridName}
                    refreshCells={refreshCells}
                    currentView={'grid'}
                    gridReducer={gridReducer}
                    handleRowEditingStarted={handleRowEditingStarted}
                    handleRowEditingStopped={handleRowEditingStopped}
                    gridColumnDef={gridColDefReducer}
                    tabInfo={tabInfo} tabIndex={value}
                    handleCellEditingStopped={handleCellEditingStopped}
                    selectedPlanId={selectedPlanId}
                    handleMultipleRowDelete={handleMultipleRowDelete}
                    loadGridData={loadGridData}
                  >
                  </MoveConstraintsTabPanel> :
                    (index == 5 && (gridName === "upgradepathsGrid" || gridName === "upgradetemplatesGrid")) ?
                      <UpgradePathsTabPanel
                        newArchitecture={true}
                        pending={pending}
                        gridName={gridName}
                        refreshCells={refreshCells}
                        currentView={"grid"}
                        gridReducer={gridReducer}
                        upgradePathsGridColDef={gridColDefReducer}
                        templatesGridColDef={gridColDefReducer}
                        handleRowEditingStarted={handleRowEditingStarted}
                        handleRowEditingStopped={handleRowEditingStopped}
                        tabInfo={tabInfo} tabIndex={value}
                        handleCellEditingStopped={handleCellEditingStopped}
                        selectedPlanId={selectedPlanId}
                        handleMultipleRowDelete={handleMultipleRowDelete}
                        loadGridData={loadGridData}
                      ></ UpgradePathsTabPanel> 
                      : (index == 6) ? <PlanAssumptionTabPanel
                      showSaveOption={showSaveOption}
                      planAssumptionTabvalue={planAssumptionTabvalue}
                      newArchitecture={true}
                      pending={pending}
                      gridName={gridName}
                      refreshCells={refreshCells}
                      gridReducer={gridReducer}
                      handleRowEditingStarted={handleRowEditingStarted}
                      handleRowEditingStopped={handleRowEditingStopped}
                      gridColDefReducer={gridColDefReducer}
                      setGridColDefReducer={setGridColDefReducer}
                      handleCellEditingStopped={handleCellEditingStopped}
                      selectedPlanId={selectedPlanId}
                      handleMultipleRowDelete={handleMultipleRowDelete}
                      loadGridData={loadGridData}
                    >
                    </PlanAssumptionTabPanel>
                     :
                     (index == 7 && gridName === "parametersGrid") ? <ParametersTabPanel
                    newArchitecture={true}
                    pending={pending}
                    gridName={gridName}
                    refreshCells={refreshCells}
                    currentView={'grid'}
                    gridReducer={gridReducer}
                    handleRowEditingStarted={handleRowEditingStarted}
                    handleRowEditingStopped={handleRowEditingStopped}
                    parametersGridColDef={gridColDefReducer}
                    tabInfo={tabInfo} tabIndex={value}
                    handleCellEditingStopped={handleCellEditingStopped}
                    selectedPlanId={selectedPlanId}
                    handleMultipleRowDelete={handleMultipleRowDelete}
                    loadGridData={loadGridData}
                  >
                  </ParametersTabPanel> : ""}
        </TabPanel>))}
    </div>
  )
};
export default React.memo(MainPlanInputsTabPanel);
