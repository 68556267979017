import React from "react";
import MultiGridUI from '../../../../components/revenuefunction/grid/RevenueFunctionMultiGridUI';

const CoreParameterTabPanel = (props) => {
    const {showSaveOption,downloadCSV,coreParameterGridColDefReducer,coreParameterGridReducer,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,setIsSearchBarValueExists,isSearchBarValueExists,refreshCells,handleMultipleRowDelete,hideActions,rfid,loadGridData} = props;

    return (
        <div>
        <MultiGridUI
        reducer={coreParameterGridReducer}
        gridColDef={coreParameterGridColDefReducer}
        onRowEditingStarted={handleRowEditingStarted}
        onRowEditingStopped={handleRowEditingStopped}
        onCellEditingStopped={handleCellEditingStopped}
        gridName={'coreparametergrid'}
        rowEditType={'single'}
        suppressclickEdit={!coreParameterGridColDefReducer.defaultColDef.editable}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete} 
        hideActions={hideActions}
        rfid={rfid}
        loadGridData={loadGridData}
        >
        </MultiGridUI>
        </div>
    )
}

export default CoreParameterTabPanel;