import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReadOnlyRowComponent from "./ReadOnlyRowComponent";
import { checkIfFreezMonth } from "../../../utils/AdjustmentsUtils";

const SectionRowsComponent = (props) => {
    const { accordionContent, isExpanded, content, planStatus, filterParams, zonename,filterRecord,isOfTypeEndFleet,currentHeader} = props;

    let freezeCpd  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.freezeCpd;
    });
    
    if (accordionContent) {
        var arrayObject = accordionContent.currentValues.map((obj, ix) => {
            return{
                "activity_type": obj.activity_type,
                "year": obj.year,
                "current": obj,
                "adjustment": accordionContent.adjustments[ix],
                "original": accordionContent.originalValues[ix],
                "fnlVal": accordionContent.fnlValues?accordionContent.fnlValues[ix]:null,
                "adjVar": accordionContent.adjVarValues?accordionContent.adjVarValues[ix]:null,
                "month":obj.month,
                "isFreezMonth":checkIfFreezMonth(obj.year,obj.month,freezeCpd)
            }
        });
        return <ReadOnlyRowComponent arrayObject={arrayObject} isExpanded={isExpanded} content={content}  planStatus = {planStatus} filterParams={filterParams} accordionContent={accordionContent} zonename={zonename} filterRecord={filterRecord} isOfTypeEndFleet={isOfTypeEndFleet} currentHeader={currentHeader}></ReadOnlyRowComponent>
    } else {
        return (
            <div></div>
        )
    }
};
export default SectionRowsComponent