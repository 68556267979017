import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AllPlansstyles from "./AllPlansTable.css";
import { createMuiTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link, useHistory } from "react-router-dom";
import icon_plandetailsview from "../../assets/images/buttons-detailed-view.svg";
import icon_actionsmoredots from "../../assets/images/icons-actions-more-dots-stacked.svg";
import icon_trash from "../../assets/images/icons-actions-trash.svg";
import Popover from "@material-ui/core/Popover";
import {
  deletePlan,
  getPlanBySelectedPlanId,
  getZoneRfData,
  getPoolRfData,
  copyPlanWithOutput,
} from "../../store/topbar/AllPlans.Actions";
import { plansDateFormatter } from "../../utils/formatters/valueFormatter";
import DialogBox from "../Dialogcomponent/DialogComponent";
import { Button, Divider, List, ListItem, Tooltip } from "@material-ui/core";
import { NttLabel } from "../../core-components/Label/NttLabel";
import icons_actions_copy from "../../assets/images/icons-actions-copy.svg";
import CustomPopOver from "../../core-components/Popover/CustomPopOver";
import { allPlanSorter, unEscapeHtml } from "../../utils/utils";

function AllPlansTable(props) {
  const { tableRowData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [popUp, setPopUp] = useState(null);
  const popOverOpen = Boolean(popUp);
  const id = popOverOpen ? "simple-popover" : undefined;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [copiedFrom, setCopiedFrom] = useState();
  const [lockedCopy, setLockedCopy] = useState(false);
  const [allPlansPagetableData, setAllPlansPagetableData] = useState([]);
  window.parent.document.body.style.overflow = "auto";
  let message = "Are you sure that you want to delete this plan?";
  let title = "Delete Plan";
  let deletetext = "Delete";
  let canceltext = "Cancel";
  const theme = createMuiTheme();

  const newPlanId = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.newPlanId;
  });

  let plansDataById = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.plansDataById;
  });

  useEffect(()=>{
    if(tableRowData && tableRowData.length>0){
    let filtereddata = tableRowData.filter(element=>{
      return (element.deleted==='No' && element.hidden==='No');// if any one of them is No plan should be hidden in UI
    }).toSorted(allPlanSorter);
    setAllPlansPagetableData(filtereddata)
  }
  },[tableRowData])

  const openConfirmationDialog = (index, rowid) => {
    setOpenDialog(true);
    handleClose();
    setSelectedIndex(index);
    setPopUp(null);
  };

  const deletePlanRow = () => {
    try {
      dispatch(deletePlan(selectedPlan));
      handleDialogClose();
    } catch (err) {
      console.error("got error while deleting plan");
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleClickMore = (e, planid) => {
    setSelectedPlan(planid);
    setPopUp(e.currentTarget);
    setCopiedFrom(planid);
  };

  const handleClose = () => {
    setPopUp(null);
  };
  const open = Boolean(popUp);
  const planspopoverId = "popoverId";

  const loadPlanDataById = (planid) => {
    dispatch(getPlanBySelectedPlanId(planid));
  };

  const getPlanDetailsById = (planid, datetime) => {
    loadPlanDataById(planid);
    let url = "/plan/" + planid + "/planinputs";
    setSelectedPlan(planid);
    history.push({
      pathname: url,
      state: {
        // location state
        selectedplan: planid,
        datetime: datetime,
      },
    });
  };

  const handleCopyPlanWithOutput = (index) => {
    setSelectedIndex(index);
    setPopUp(null);
    setLockedCopy(true);
    dispatch(copyPlanWithOutput(copiedFrom));
  };

  const LoadLockedCopyPlanDetails = (plansDataById) => {
    let dateTime = plansDateFormatter(plansDataById[0].date);
    loadZoneRfData(newPlanId);
    loadPoolRfData(newPlanId);
    let url = "/plan/" + newPlanId + "/planinputs";
    history.replace({
      pathname: url,
      state: {
        // location state
        selectedplan: newPlanId,
        datetime: dateTime,
        lockedCopy: lockedCopy,
      },
    });
  };

  useEffect(() => {
    if (lockedCopy == true && newPlanId !== "") {
      loadPlanDataById(newPlanId);
    }
    if (
      lockedCopy == true &&
      plansDataById !== undefined &&
      plansDataById.length > 0 &&
      plansDataById[0].id == newPlanId
    ) {
      setLockedCopy(false);
      LoadLockedCopyPlanDetails(plansDataById);
    }
  }, [plansDataById, newPlanId]);

  const loadZoneRfData = (planid) => {
    dispatch(getZoneRfData(planid));
  };

  const loadPoolRfData = (planid) => {
    dispatch(getPoolRfData(planid));
  };

  const handlePopOverClose = () => {
    setPopUp(null);
  };

  return (
    <TableContainer className="tablecontainer" component={Paper}>
      {allPlansPagetableData.length > 0 ? (
        <Table className="planstable" aria-label="simple table">
          <TableHead className="planshead">
            <TableRow>
              <TableCell className="initaltextstyle">#</TableCell>
              <TableCell className="desctextstyle">Description</TableCell>
              <TableCell className="numbertyle">Pool Months</TableCell>
              <TableCell className="textstyle">Scenario Name</TableCell>
              <TableCell className="textstyle">Date</TableCell>
              <TableCell className="numbertyle">Run Time(hrs)</TableCell>
              <TableCell className="textstyle">Status</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="plansbody">
            {allPlansPagetableData.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  className="planidlink rownumstyle"
                  onClick={(e) =>
                    getPlanDetailsById(row.id, plansDateFormatter(row.date))
                  }
                >
                  {row.id}
                </TableCell>
                {row.description == " " ||
                row.description == undefined ||
                row.description == null ? (
                  <TableCell className="rowtextstyle">No description</TableCell>
                ) : (
                  <TableCell className="rowtextstyle">
                    {unEscapeHtml(row.description)}
                  </TableCell>
                )}
                {row.pool_month == 0 || row.pool_month == "" ? (
                  <TableCell className="rownumstyle">-</TableCell>
                ) : (
                  <TableCell className="rownumstyle">
                    {row.pool_month}&nbsp;PM
                  </TableCell>
                )}
                {row.scenario_name === " " ||
                row.scenario_name == undefined ||
                row.scenario_name == null ? (
                  <TableCell className="rowtextstyle">-</TableCell>
                ) : (
                  <TableCell className="rowtextstyle-scenario-name">
                    {row.scenario_name}
                  </TableCell>
                )}
                <TableCell className="rowtextstyle">
                  {plansDateFormatter(row.date)}
                </TableCell>
                {row.runtime == " " ||
                row.runtime == undefined ||
                row.runtime == null ? (
                  <TableCell className="rownumstyle">-</TableCell>
                ) : (
                  <TableCell className="rownumstyle">{row.runtime}</TableCell>
                )}
                <TableCell className="textcapital">
                  <Tooltip title={row.status} placement="bottom-end">
                  <span
                    className={
                      row.status.toLowerCase() == "new"
                        ? "new"
                        : row.status.toLowerCase() == "ready"
                        ? "ready"
                        : row.status.toLowerCase() == "building model"
                        ? "run"
                        : row.status.toLowerCase() == "optimization queued"
                        ? "run"
                        : row.status.toLowerCase() == "optimizing"
                        ? "run"
                        : row.status.toLowerCase() == "published"
                        ? "publish"
                        : row.status.toLowerCase() == "optimization complete"
                        ? "complete"
                        : row.status.toLowerCase() == "error"
                        ? "fail"
                        : row.status.toLowerCase() == "adjustment complete"
                        ? "adjustmentcomplete"
                        : row.status.toLowerCase() == "apply adjustments"
                        ? "ready"
                        : row.status.toLowerCase() == "sales adjustments ready"
                        ? "ready"
                        : row.status.toLowerCase() == "vin dep process ready"
                        ? "ready"
                        : row.status.toLowerCase() == "sales adjustments running"
                        ? "run"
                        : row.status.toLowerCase() == "vin dep process running"
                        ? "run"
                        : row.status.toLowerCase() == "sales adjustments completed"
                        ? "adjustmentcomplete"
                        : row.status.toLowerCase() == "vin dep process completed"
                        ? "adjustmentcomplete"
                        : row.status.toLowerCase() == "pulling data"
                        ? "run"
                        : row.status.toLowerCase() == "optimization complete as sub-optim"
                        ? "runSupOptm "
                        : row.status.toLowerCase() == "optimization complete as optimal"
                        ? "complete"
                        : row.status.toLowerCase() == "optimization complete unknown status"
                        ? "runUnknown"
                        : row.status.toLowerCase() === "editing adjustments"
                        ? "EditingAdjustments"
                        : row.status.toLowerCase() === "adjustments modified"
                        ? "AdjustmentsModified"
                        : "run"
                    }
                  >
                    {row.status.toLowerCase() == "new"
                      ? "New"
                      : row.status.toLowerCase() === "ready"
                      ? "Ready"
                      : row.status.toLowerCase() === "building model"
                      ? "Running"
                      : row.status.toLowerCase() === "optimization queued"
                      ? "Running"
                      : row.status.toLowerCase() === "optimizing"
                      ? "Running"
                      : row.status.toLowerCase() === "published"
                      ? "Published"
                      : row.status.toLowerCase() === "optimization complete"
                      ? "Completed"
                      : row.status.toLowerCase() === "error"
                      ? "Failed"
                      : row.status.toLowerCase() === "adjustment complete"
                      ? "Adjustment Complete"
                      : row.status.toLowerCase() === "apply adjustments"
                      ? "Adjustment in Process"
                      : row.status.toLowerCase() === "sales adjustments ready"
                      ? "Sales Adjustments Ready"
                      : row.status.toLowerCase() === "vin dep process ready"
                      ? "VIN Dep Process Ready"
                      : row.status.toLowerCase() == "sales adjustments running"
                      ? "Running"
                      : row.status.toLowerCase() == "vin dep process running"
                      ? "Running"
                      : row.status.toLowerCase() == "sales adjustments completed"
                      ? "Sales Adjustments Completed"
                      : row.status.toLowerCase() == "vin dep process completed"
                      ? "VIN Dep Process Completed"
                      : row.status.toLowerCase() === "pulling data"
                      ? "Running"
                      : row.status.toLowerCase() === "optimization complete as sub-optim"
                      ? "Completed - Sub Optimal"
                      : row.status.toLowerCase() === "optimization complete as optimal"
                      ? "Completed - Optimal"
                      : row.status.toLowerCase() === "optimization complete unknown status"
                      ? "Completed - Unknown"
                      : row.status.toLowerCase() === "editing adjustments"
                      ? "Editing Adjustments"
                      : row.status.toLowerCase() === "adjustments modified"
                      ? "Adjustments Modified"
                      : "Running"}
                  </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <img
                    className="cursorpoint"
                    onClick={(e) =>
                      getPlanDetailsById(row.id, plansDateFormatter(row.date))
                    }
                    src={icon_plandetailsview}
                  ></img>
                </TableCell>
                <TableCell>
                  <img
                    src={icon_actionsmoredots}
                    className="planmoreicon"
                    onClick={(e) => handleClickMore(e, row.id)}
                  ></img>
                </TableCell>
                <CustomPopOver
                  className="lockedcopyPopOver"
                  popOverId="popoverId"
                  open={popOverOpen}
                  anchorEl={popUp}
                  onClose={handlePopOverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <List>
                    <ListItem
                      button
                      selected={selectedIndex === 1}
                      onClick={(event) => openConfirmationDialog(1, row.id)}
                    >
                      <img src={icon_trash} className="showmorepopup"></img>
                      <NttLabel
                        theme={theme}
                        labelVariant="body2"
                        className="showmoretext"
                      >
                        Remove
                      </NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem
                      button
                      selected={selectedIndex === 2}
                      disabled={true}
                      onClick={(event) => handleCopyPlanWithOutput(2)}
                    >
                      <img
                        src={icons_actions_copy}
                        className="showmorepopup"
                      ></img>
                      <NttLabel
                        theme={theme}
                        labelVariant="body2"
                        className="showmoretext"
                      >
                        Locked Copy
                      </NttLabel>
                    </ListItem>
                  </List>
                </CustomPopOver>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          <Table className="planstable" aria-label="simple table">
            <TableHead className="planshead">
              <TableRow>
                <TableCell className="initaltextstyle">#</TableCell>
                <TableCell className="desctextstyle">Description</TableCell>
                <TableCell className="numbertyle">Pool Months</TableCell>
                <TableCell className="textstyle">Date</TableCell>
                <TableCell className="numbertyle">Run Time(hrs)</TableCell>
                <TableCell className="textstyle">Status</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className="noplansrow">No Records To Show</div>
        </div>
      )}
      <div>
        <DialogBox
          open={openDialog}
          handleClose={handleDialogClose}
          handleYes={deletePlanRow}
          dialogMessage={message}
          title={title}
          action={deletetext}
          styles={"styles"}
          canceltext={canceltext}
        ></DialogBox>
      </div>
    </TableContainer>
  );
}

export default AllPlansTable;
