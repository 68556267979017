import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import MultiGridUI from '../../../../components/revenuefunction/grid/RevenueFunctionMultiGridUI';
import ActionBar from '../../../../components/revenuefunction/mainrfinputsactionbar/MainRfInputsActionBar';
import {fetchCoreDemandData} from '../../../../store/revenuefunction/mainrfinputs/coredemand/CoreDemand.Actions';

const CoreDemandTabPanel = (props) => {
    const {coreDemandGridReducer,coreDemandGridColDefReducer,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,setIsSearchBarValueExists,isSearchBarValueExists,refreshCells,handleMultipleRowDelete,hideActions,rfid,loadGridData } = props;
    return (
        <div>
        <MultiGridUI
        reducer={coreDemandGridReducer}
        onRowEditingStarted={handleRowEditingStarted}
        onRowEditingStopped={handleRowEditingStopped}
        onCellEditingStopped={handleCellEditingStopped}
        gridName={'coredemandgrid'}
        rowEditType={'single'}
        gridColDef={coreDemandGridColDefReducer}
        suppressclickEdit={!coreDemandGridColDefReducer.defaultColDef.editable}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete}
        hideActions={hideActions}
        isPaginationRequired={true} 
        rfid={rfid}
        loadGridData={loadGridData}
        >
        </MultiGridUI>
        </div>
    )
}

export default CoreDemandTabPanel;