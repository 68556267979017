import { columnDefs, defaultColDef } from "../coreadjustments/core_adjustments_grid_data/CoreAdjustmentsColumnDefs";
import {FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_PENDING,FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_SUCCESS,FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_ERROR} from "../coreadjustments/CoreAdj.ActionTypes";
import { checkDate, checkFullDate, safeCaseInsensitiveCompare } from "../../../../utils/utils";
import * as CONSTANTS from '../../../../Constants';
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues:[],
    checkValue: function (value) {
        return {
            //value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ONRENT_DATE: { error: checkOnRentDate(data.ONRENT_DATE,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.OnRentDate.field, data) },
            POOL: { error: checkPool(data.POOL,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field,data,initialState.columnDefs.findIndex(itm=>itm.field===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field)) },
            ZONE: { error: checkZone(data.ZONE,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field, data,initialState.columnDefs.findIndex(itm=>itm.field===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field)) },
            UTE: { error: checkUTE(data.UTE,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.UTE.field, data) },
            BUSINESS_SEGMENT: { error: checkBusinessSegment(data.BUSINESS_SEGMENT,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.BusinessUnit.field, data) },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.ZONE, itm2.ZONE) && safeCaseInsensitiveCompare(itm.POOL, itm2.POOL) && checkFullDate(itm.ONRENT_DATE, itm2.ONRENT_DATE) && safeCaseInsensitiveCompare(itm.BUSINESS_SEGMENT, itm2.BUSINESS_SEGMENT)
            ) {
                unique = false;
                if(itm.rowStatus == "ADD_ROW"){
                    itm.rowStatus = "ERROR_NEW_ROW";
                }
                else if(itm2.rowStatus == "ADD_ROW"){
                    itm2.rowStatus = "ERROR_NEW_ROW"
                }
                if(itm.rowStatus == "COPY_ROW"){
                    itm.rowStatus = "ERROR_COPY_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                 else if(itm2.rowStatus == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.rowStatus = "ERROR_COPY_ROW"
                }
                else {
                    itm.rowStatus = "ERROR_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.rowStatus == "ERROR_COPY_ROW" || transactions[key].data.rowStatus == "ERROR_ROW") && itm.rowStatus==''){
                transactions[key].data.rowStatus=itm.rowStatus
              }
            }
          })
        if (unique && itm.rowStatus != "ADD_ROW") {
            itm.rowStatus = "";
            cleaned.push(itm);
        }
    });
    return duplicate;
}

function checkOnRentDate(value,fiedName,data){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.OnRentDate.field;

    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }else if(value == 'Invalid date'){
        return fiedName +" "+"cannot be invalid date";
    }
    else if(!moment(value).isBetween(CONSTANTS.minimumDate, CONSTANTS.maximumDate,'year','[]')){
        return fiedName + " should be in +5 or -10 range from the current year"
    
    //     var itemValue = new Date(value).getFullYear();
    //     if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
    //         return fiedName + " should be in +5 or -10 range from the current year"
    //     }
     }
    // console.log("All OKKKKK....");
    return null;
}

function checkPool(value,fiedName,data,columnOptions){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field;
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    } else if(value === 'ALL' && data.ZONE==='ALL'){
        return 'Both Pool And Zone cannot be ALL'
    }else if(value !== 'ALL' && data.ZONE !=='ALL'){
        return 'Either Pool Or Zone should be ALL'
    } else {
        if (initialState.columnDefs[columnOptions].cellEditorParams !== undefined) {
            if (initialState.columnDefs[columnOptions].cellEditorParams.options.indexOf(value) == -1) {
                return fiedName +" "+value+" no-longer available";
            } else 
            return null
        }
    }
    //Based on clients requirement removing the DEMAND_BY_CAR_CLASS validation 
    //Keeping below code for future reference incase if they want this again 
    // {
    //     // console.log("Checking Pool...");
    //     let parameters=sessionStorage.getItem(CONSTANTS.PARAMETERS_SESSION_STORAGE_NAME);
    //     let parametersMap=JSON.parse(parameters);
    //     // console.log(`fiedName===${CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field} && parametersMap["RUN_TYPE"]===${parametersMap["RUN_TYPE"]}`);
    //     if(parametersMap && !parametersMap["RUN_TYPE"]){
    //         // console.log("RUN TYPE not set in PARAMETERS");
    //         return "RUN TYPE not set in PARAMETERS";
    //     }
    //     if(parametersMap && parametersMap["RUN_TYPE"].toUpperCase()==='POOL' && value.toUpperCase()==='ALL'){
    //         // console.log("cannot be ALL as it is a POOL Run");
    //         return fiedName +" "+"cannot be ALL as it is a POOL Run";
    //     }
    //     if(parametersMap && parametersMap["RUN_TYPE"].toUpperCase()!=='POOL' && value.toUpperCase()!=='ALL'){
    //         // console.log("Must be ALL as it is a ZONE Run");
    //         return fiedName +" "+"Must be ALL as it is a ZONE Run";
    //     }
    //     if(parametersMap && parametersMap["RUN_TYPE"].toUpperCase()==='POOL' && value.toUpperCase()!=='ALL'){
    //         let poolList=JSON.parse(sessionStorage.getItem(CONSTANTS.POOL_SESSION_STORAGE_NAME));
    //         // console.log("poolList====>",poolList);
    //         if(poolList && poolList.body && poolList.body.data){
    //             let poolExists=poolList.body.data.some((item)=>{
    //                 // console.log("item===>",item, ", value.toUpperCase()==>",value.toUpperCase());
    //                 return value.toUpperCase()===item.code
    //             });
    //             // console.log("selectedPools===>",selectedPools);
    //             if(!poolExists){
    //                 return "Invalid Pool";
    //             }
    //         }            
    //     }
    // }
    
    // console.log("All OKKKKK....");
    return null;
}

function checkZone(value,fiedName,data){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field;
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }else if(value === 'ALL' && data.POOL==='ALL'){
        return 'Both Pool And Zone cannot be ALL'
    }else if(value !== 'ALL' && data.POOL !=='ALL'){
        return 'Either Pool Or Zone should be ALL'
    }

    //Based on clients requirement removing the DEMAND_BY_CAR_CLASS validation 
    //Keeping below code for future reference incase if they want this again 
    // {
    //     // console.log("Checking Zone...");
    //     let parameters=sessionStorage.getItem(CONSTANTS.PARAMETERS_SESSION_STORAGE_NAME);
    //     let parametersMap=JSON.parse(parameters);
    //     // console.log(`fiedName===${CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field} && parametersMap["RUN_TYPE"]===${parametersMap["RUN_TYPE"]}`);
    //     if(parametersMap && !parametersMap["RUN_TYPE"]){
    //         // console.log("RUN TYPE not set in PARAMETERS");
    //         return "RUN TYPE not set in PARAMETERS";
    //     }
    //     if(parametersMap && parametersMap["RUN_TYPE"].toUpperCase()==='ZONE' && value.toUpperCase()==='ALL'){
    //         // console.log("cannot be ALL as it is a ZONE Run");
    //         return fiedName +" "+"cannot be ALL as it is a ZONE Run";
    //     }   
    //     if(parametersMap && parametersMap["RUN_TYPE"].toUpperCase()!=='ZONE' && value.toUpperCase()!=='ALL'){
    //         // console.log("Must be ALL as it is a POOL Run");
    //         return fiedName +" "+"Must be ALL as it is a POOL Run";
    //     }
    // }
    
    // console.log("All OKKKKK....");
    return null;
}

function checkUTE(value,name,data){
    //const name=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.UTE.field;
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    if(isNaN(value)){
        return name + " must be a valid number";
    } 
    if(value <0){
        return name + " cannot be less than zero";
    }
    if(value>1){
        return name + " cannot be greater than one";
    }
    
    // console.log("All OKKKKK....");
    return null;
}

function checkBusinessSegment(value,fiedName,data){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.BusinessUnit.field;
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }else if(!CONSTANTS.BUSINESS_SEGMENT_OPTIONS.includes(value)){
        return "Please choose from dropdown options";
    }
    
    // console.log("All OKKKKK....");
    return null;
}

//Not To be used but kept for reference as of Sprint 50
function checkIfValueEmpty(value, fiedName) {
//     console.log(`value=${value}, fiedName=${fiedName}`);
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }else if(fiedName===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.OnRentDate.field){
        var itemValue = parseInt(value, 0);
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
            return fiedName + " should be in +5 or -10 range from the current year"
        }
    }else if(fiedName===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field){
        // console.log("Checking Pool...");
        let parameters=sessionStorage.getItem(CONSTANTS.PARAMETERS_SESSION_STORAGE_NAME);
        let parametersMap=JSON.parse(parameters);
        // console.log(`fiedName===${CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field} && parametersMap["RUN_TYPE"]===${parametersMap["RUN_TYPE"]}`);
        if(!parametersMap["RUN_TYPE"]){
            // console.log("RUN TYPE not set in PARAMETERS");
            return "RUN TYPE not set in PARAMETERS";
        }
        if(parametersMap["RUN_TYPE"].toUpperCase()==='POOL' && value.toUpperCase()==='ALL'){
            // console.log("cannot be ALL as it is a POOL Run");
            return fiedName +" "+"cannot be ALL as it is a POOL Run";
        }
        if(parametersMap["RUN_TYPE"].toUpperCase()!=='POOL' && value.toUpperCase()!=='ALL'){
            // console.log("Must be ALL as it is a ZONE Run");
            return fiedName +" "+"Must be ALL as it is a ZONE Run";
        }
    }else if(fiedName===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field){
        // console.log("Checking Zone...");
        let parameters=sessionStorage.getItem(CONSTANTS.PARAMETERS_SESSION_STORAGE_NAME);
        let parametersMap=JSON.parse(parameters);
        // console.log(`fiedName===${CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field} && parametersMap["RUN_TYPE"]===${parametersMap["RUN_TYPE"]}`);
        if(!parametersMap["RUN_TYPE"]){
            // console.log("RUN TYPE not set in PARAMETERS");
            return "RUN TYPE not set in PARAMETERS";
        }
        if(parametersMap["RUN_TYPE"].toUpperCase()==='ZONE' && value.toUpperCase()==='ALL'){
            // console.log("cannot be ALL as it is a ZONE Run");
            return fiedName +" "+"cannot be ALL as it is a ZONE Run";
        }   
        if(parametersMap["RUN_TYPE"].toUpperCase()!=='ZONE' && value.toUpperCase()!=='ALL'){
            // console.log("Must be ALL as it is a POOL Run");
            return fiedName +" "+"Must be ALL as it is a POOL Run";
        }
    }
    
    // console.log("All OKKKKK....");
    return null;
    
}


function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = [];
    if(pools && pools.length>0){
        pools.forEach(val => {
           // if (val.code.toUpperCase() != 'ALL'){
                poolData.push(val.code)
            //}
        });
        //poolData.push('ALL')
    }

    if(adminpools && adminpools.length>0){
        adminpools.forEach(val=>{
            poolFilterData.push({"label":val.code,"value":val.code})
        })
        poolFilterData.push({"label":"Blanks","value":"Blanks"})
    }
    element.cellEditorParams = { options: poolData }
    element.filterOptions = poolFilterData;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData = [];
    // console.log("In fetchDropdownsForZones: zones===>",zones);
    if(zones && zones.length>0){
        zones.forEach(val => {
            zoneFilterData.push({"label":val.name,"value":val.name})
            zoneData.push(val.name)
        });
        zoneFilterData.push({"label":"Blanks","value":"Blanks"})
    }

    element.cellEditorParams = { options: zoneData }
    element.filterOptions = zoneFilterData;
}

function fetchDropdownsForBusinessUnit(element) {
    let businessSegFilterDropdownOptions=[];
    CONSTANTS.BUSINESS_SEGMENT_OPTIONS.map((val)=>{
    businessSegFilterDropdownOptions.push({"label":val,"value":val})
 })
    element.cellEditorParams = { options: CONSTANTS.BUSINESS_SEGMENT_OPTIONS }
    businessSegFilterDropdownOptions.push({"label":"Blanks","value":"Blanks"})
    element.filterOptions = businessSegFilterDropdownOptions;
}

function setColumnDefParams(columnDefs, data) {
    //console.log("In setColumnDefParams: data====> ",data);
    columnDefs.map((column) => {
        if (column.field === CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Pool.field) {
            fetchDropdownsForPools(data.pools,data.adminPools, column);
        }else if (column.field === CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.Zone.field) {
            // console.log("data.zones===>",data.zones);
            fetchDropdownsForZones(data.zones, column);
        }else if(column.field === CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreAdjColumns.BusinessUnit.field) {
            fetchDropdownsForBusinessUnit(column) ;
        }
    });
    return columnDefs;
}

export default function RFCoreAdjColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues: setColumnDefParams(state.columnDefs, [])
            });
        case FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}