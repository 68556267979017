import { columnDefs, defaultColDef } from "./zoneMap_grid_data/ZoneMapColumnDefs";
import { FETCH_ZONEMAP_DROPDOWNVALUES_PENDING,FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS,FETCH_ZONEMAP_DROPDOWNVALUES_ERROR } from '../zonemap/ZoneMap.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, "Zones") },
            ACCEPTABLE_ZONES: { error: checkIfValueEmpty(data.ACCEPTABLE_ZONES, "Acceptable Zones") },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}
function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) { 
                if ((itm.ZONE_NAME === itm2.ZONE_NAME) && (itm.ACCEPTABLE_ZONES === itm2.ACCEPTABLE_ZONES)){
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                } 
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            transactions.forEach((itm1,key)=>{
                if(itm1.data){
                  if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                    transactions[key].data.status=itm.status
                  }
                }
              })
    });
    return duplicate;
}
function checkIfValueEmpty(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    return null;
}


function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [];
    zones.forEach(val => {
        zoneData.push(val.name)
    });

    element.cellEditorParams = { options: zoneData }
}

function fetchDropdownValueOthers(data, element) {
    element.cellEditorParams = { options: data }
}

function fetchZones(zoneMapData, zones) {
    zoneMapData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_NAME) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return zoneMapData;
}

function fetchFilterValues( acceptableZones, zones,columns) {
    columns.forEach(element => {
        if (element.field == "ZONE_NAME") {
            fetchDropdownsForZones(zones, element);
        }  else if (element.field == "ACCEPTABLE_ZONES") {
            fetchDropdownValueOthers(acceptableZones, element);
        }
    });

    return columns;
}

export default function zoneMapColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ZONEMAP_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.acceptablezones,
                    action.data.zones, state.columnDefs)
            });
        case FETCH_ZONEMAP_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}