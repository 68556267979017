import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import ActionBar from '../../../components/planassumptionsactionbar/PlanAssumptionsActionbar';
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const MileageDefaultTabPanel = (props)=>{
    const{showSaveOption, mileageDefaultData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        mileageDefaultGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        mileageDefaultTransactions, mileageDefaultgridReducer,currentData,filteredData, filterParams,tabIndex, downloadCSV,
        userMessage,newArchitecture,gridName,loadGridData} = props;
 return(
     <div>
        {newArchitecture ? (<MainPlanInputsMultiGridUI 
            reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={mileageDefaultGridColDef}
            rowEditType={'single'}
            suppressclickEdit={!mileageDefaultGridColDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            isPaginationRequired={true}
            loadGridData={loadGridData}
             />) :
         (<MultiGridUI 
            reducer={mileageDefaultgridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'mileagedefaultgrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={mileageDefaultGridColDef}
            suppressclickEdit={!mileageDefaultGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            mileageDefaultTransactions={mileageDefaultTransactions}            
            userMessage={userMessage}
             />)
        }
     </div>
 )
}

export default MileageDefaultTabPanel;