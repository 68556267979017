import React, { useState, useEffect, useContext } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import '../corebu/CoreBUAdjustmentView.css';
import AllBUAccordionTitle from '../AllBUAccordionTitle';
import { Divider, Button } from '@material-ui/core';
import EditableSectionRowsComponent from '../EditableSectionRowsComponent';
import EndFleetSectionRowsComponent from '../EndFleetSectionRowsComponent';
import EditableSectionSummaryComponent from '../EditableSectionSummaryComponent';
import { ScrollSyncPane } from 'react-scroll-sync';
import {safeActivityTemplate, ZoneViewAdjDisable, checkFilterParams,willExpand, selectFilterDataSectionFromStore} from '../../../../utils/AdjustmentsUtils'
import { useDispatch, useSelector } from 'react-redux';
import { adjustmentFilters } from '../../../../containers/adjustments/AdjustmentsPage';
import { setAccordianClicked } from '../../../../store/adjustments/Adjustments.Actions';

const CoreElectricBUAdjustmentView = (props) => {
    let { aggregatedAddsArray,
        aggregatedDeletesArray,
        aggregatedEndfleetArray,
        aggregatedStartfleetArray,
        aggregatedSalvageArray,
        aggregatedNetonewayArray,
        aggregatedNetmovesArray,
        aggregatedCoretoTncArray,
        selectedVal,
        aggregatedCoretoCsArray, allZones, planid,  planStatus,
        handleGridSaveBehavior, filterActivityForAdjVar, filterActivityForReqValue,
        filterActivityForReqAdjVal,filterActivityForOriginalValue,filterData,tabIndex} = props;

        const [selectedAdjVar,setSelectedAdjVar] = useState('');
        const[SelectedRequValue,setSelectedRequValue] = useState('');
        const[selectedOriginalVal,setSelectedOriginalVal] = useState('');
        const[selectedAdjVal,setSelectedAdjVal] = useState('');
        const dispatch = useDispatch();
        let adjustmentActivities = useContext(adjustmentFilters);
        // let tempFilterArray = selectedVal==='Type>Zone' ? adjustmentActivities.tempFilterArray : adjustmentActivities.tempFilterArrayForZoneView;

        let filterParams=selectFilterDataSectionFromStore(tabIndex,selectedVal);
        let tempFilterArray={};//Dummy for testing

        var coreelectric_aggregatedZoneArray=useSelector((state)=>{
            return state.rootAdjustmentsReducer.adjustmentsReducer.coreelectric_aggregatedZoneArray
        })
        let adjlevel  = useSelector((state) => {
            return state.rootAdjustmentsReducer.adjustmentsReducer.adjlevel;
        });

       let zoneAdjEdit=ZoneViewAdjDisable(adjlevel)
    let currentHeader = null;

    const EndFleetSectionComponent = ({ startFleetValue, val, applyMargin,isTopLevel,parentValHeader }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const [timeOfExpansion, setTimeOfExpansion] = useState(new Date());
        const [expansionMode, setExpansionMode] = useState("INITIAL");//"INITIAL","FILTER","EXPANDALL","CLICK" ("" is when accordian is toggled)
        if (val.header && !val.content){
            currentHeader = val.header;
        }
        let expandSection;
        if(selectedVal==='Type>Zone'? (currentHeader==='START_FLEET' && val.header!='START_FLEET') : (currentHeader==='ALL' && val.header==='START_FLEET')){
            expandSection=false
        } else{
         expandSection=willExpand(tabIndex,isExpanded, timeOfExpansion, expansionMode, val,filterParams,filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue,currentHeader,selectedVal,tempFilterArray,filterData,isTopLevel,parentValHeader, setIsExpanded, setExpansionMode);
        }   
        return (
            <span className="newadjustmentaccordions">
                <Accordion
                    expanded={expandSection}
                    onChange={(e) => {
                        // setIsExpanded(!isExpanded);
                        //     setTimeOfExpansion(new Date());
                        //     setExpansionMode("CLICK");
                        dispatch(setAccordianClicked(tabIndex,selectedVal,val.header,isTopLevel,parentValHeader));
                            // console.log("Accordian clicked...",e.currentTarget.ariaExpanded);
                    }
                    }>
                    <EditableSectionSummaryComponent startFleetValue={startFleetValue} selectedVal={selectedVal} accordionContent={val} isExpanded={!expandSection} applyMargin={applyMargin} isOfTypeEndFleet={true}  planStatus={planStatus} addStartFleet={false}/>
                    {val.header!=='START_FLEET'?
                    <span className="adjustmentOverallAccordiondetails">
                        <AccordionDetails className='adjustmentOverallAccordiondetailsTitle'>
                            <span className="alignTitle">
                                {(val.title && val.title.length > 0 && !val.content) ?
                                    <AllBUAccordionTitle data={val.title} planid={planid} planStatus={planStatus}/>
                                    : ''}</span>
                        </AccordionDetails>
                        <AccordionDetails className='coreBUadjustmentOverallAccordionContent'>
                            {(val.values && val.values.length > 0 && !val.content) ?
                                <span className="coreBUalignColumnContent">
                                    {val.values && val.values.map((child, index) => {
                                        return ( <ScrollSyncPane group="horizontal">
                                            <span className="enfleetbuadjustmentcontent">
                                                <EndFleetSectionRowsComponent startFleetValue={startFleetValue} selectedVal={selectedVal} accordionContent={child} planStatus={planStatus} addStartFleet={false}
                                                 filterParams={filterParams} filterRecord={filterRecord} isOfTypeEndFleet={true} currentHeader={currentHeader}/>
                                            </span>
                                         </ScrollSyncPane>
                                        )
                                    })}
                                </span>
                                : ''}
                        </AccordionDetails>
                    </span>:''}
                    {(val.values && val.values.length > 0 && !val.content) ? <Divider className="overallDivider" /> : ''}
                    <span className="adjustmentAccordiondetails">
                        <AccordionDetails className='adjustmentAccordiondetailsTitle'>
                            <span className="alignTitle">
                                {(val.title && val.title.length > 0 && val.content) ?
                                    <AllBUAccordionTitle data={val.title} planid={planid} planStatus={planStatus}/>
                                    : ''}</span>
                        </AccordionDetails>
                        <AccordionDetails className='coreBUadjustmentAccordiondetailsContent'>
                            {(val.values && val.values.length > 0 &&
                                val.content && val.content.length > 0) ?
                                <span className="coreBUalignContent">{val.values && val.values.map((child, index) => {
                                    let zoneName = val.header;
                                    if (child.adjustments != undefined) {
                                        return (
                                            <span className='displayflexstyle'>
                                                <ScrollSyncPane group="horizontal">
                                                <span className={selectedVal==='Zone>Type'?"buadjustmentcontent":"enfleetbuadjustmentcontent"}>
                                                        {selectedVal==='Zone>Type'?
                                                         <EditableSectionRowsComponent accordionContent={child} planid={planid} selectedVal={selectedVal} adjDisable={zoneAdjEdit.endFleetAdj} handleGridSaveBehavior={handleGridSaveBehavior}
                                                         content={val.content===undefined?false:true} planStatus={planStatus}/>
                                                         :
                                                         <EndFleetSectionRowsComponent startFleetValue={child.currentValues[0].value} selectedVal={selectedVal} accordionContent={child} planStatus={planStatus} addStartFleet={false}
                                                         filterParams={filterParams} zonename={zoneName} filterRecord={filterRecord} currentHeader={currentHeader}/>
                                                        }
                                                    </span>
                                                </ScrollSyncPane>
                                            </span>
                                        )

                                    }
                                    else
                                        if (child.children != undefined && child.children.length > 0 || child.header != undefined)
                                            if (child.header!='ALL') {//to not to display ALL zone in UI
                                                return (
                                                    <div>
                                                        <EndFleetSectionComponent startFleetValue = {child.values[0].currentValues[0].value} val={child} applyMargin={true} isTopLevel={false} parentValHeader={val.header}></EndFleetSectionComponent>
                                                    </div>
                                                )
                                            }}
                                )}</span> :

                                <span>
                                    {val.children && val.children.map((child, index) => {
                                        if (child.children != undefined && child.children.length > 0 || child.header != undefined)
                                            if (child.header!='ALL') {//to not to display ALL zone in UI
                                                return (
                                                    <div>
                                                        <EndFleetSectionComponent startFleetValue = {child.values[0].currentValues[0].value} val={child} applyMargin={true} isTopLevel={false} parentValHeader={val.header}></EndFleetSectionComponent>
                                                    </div>
                                                )
                                            }
                                    })}</span>}
                        </AccordionDetails>
                    </span>
                    {(val.values && val.values.length > 0 && val.content) ? <Divider className="childDivider" /> : ''}

                </Accordion>
            </span>

        )
    }

    function filterRecord(filteredValue,filterType,value){
        if(filterType==='reqValue'){
            setSelectedRequValue(value);
        }else if(filterType==='adjVar'){
            setSelectedAdjVar(value);
        }else if(filterType==='originalValue'){
            setSelectedOriginalVal(value);
        }else if(filterType==='adjValue'){
            setSelectedAdjVal(value);
        }
    } 
    useEffect(()=>{
        if(selectedAdjVar){
            //console.log("selectedAdjVar inuseeffect:",selectedAdjVar);
        }
    },[selectedAdjVar])

    useEffect(()=>{
        if(SelectedRequValue){
            //console.log("SelectedRequValue in useeffect:",SelectedRequValue);
        }
    },[SelectedRequValue])

    useEffect(()=>{
        if(selectedOriginalVal){
            //console.log("selectedOriginalVal in useeffect:",selectedOriginalVal);
        }
    },[selectedOriginalVal])

    useEffect(()=>{
        if(selectedAdjVal){
            //console.log("selectedAdjVal in useeffect:",selectedAdjVal);
        }
    },[selectedAdjVal])

    const SectionComponent = ({ val, applyMargin,isTopLevel,parentValHeader }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const [timeOfExpansion, setTimeOfExpansion] = useState(new Date());
        const [expansionMode, setExpansionMode] = useState("INITIAL");//"INITIAL","FILTER","EXPANDALL","CLICK" ("" is when accordian is toggled)
        
        // console.log("FINAL expandSection=",expandSection);
        if (val.header && !val.content){
            currentHeader = val.header;
        }
        let expandSection;
    if(currentHeader && val.header==='START_FLEET'){
        expandSection=false
    } else{
        expandSection=willExpand(tabIndex,isExpanded, timeOfExpansion, expansionMode, val,filterParams,filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue,currentHeader,selectedVal,tempFilterArray,filterData,isTopLevel,parentValHeader, setIsExpanded, setExpansionMode);
    }

        return (
            <span className="newadjustmentaccordions">
                <Accordion
                    expanded={expandSection}
                    onChange={(e) => {
                        // setIsExpanded(!isExpanded);
                        // setTimeOfExpansion(new Date());
                        // setExpansionMode("CLICK");
                        dispatch(setAccordianClicked(tabIndex,selectedVal,val.header,isTopLevel,parentValHeader));
                        // console.log("Accordian clicked...",e.currentTarget.ariaExpanded);
                    }
                    }>
                    <EditableSectionSummaryComponent accordionContent={val} selectedVal={selectedVal}
                        isExpanded={!expandSection} applyMargin={applyMargin}
                        isOfTypeEndFleet={selectedVal==='Zone>Type'?true:false} content={val.content===undefined?false:true} planStatus={planStatus}/>

                    <span className="adjustmentOverallAccordiondetails">
                        <AccordionDetails className='adjustmentOverallAccordiondetailsTitle'>
                            <span className="alignTitle">
                                {(val.title && val.title.length > 0 && !val.content) ?
                                    <AllBUAccordionTitle data={val.title} planid={planid} planStatus={planStatus}/>
                                    : ''}</span>
                        </AccordionDetails>

                        <AccordionDetails className='coreBUadjustmentOverallAccordionContent'>
                            {(val.values && val.values.length > 0 && !val.content) ?
                                <span className="coreBUalignColumnContent">
                                    {val.values && val.values.map((child, index) => {
                                        return (
                                            <ScrollSyncPane group="horizontal">
                                            <span className={selectedVal==='Zone>Type'?"enfleetbuadjustmentcontent":"buadjustmentcontent"}>
                                            {selectedVal==='Zone>Type'?
                                            <EndFleetSectionRowsComponent startFleetValue={0} selectedVal={selectedVal} accordionContent={child} planStatus={planStatus} addStartFleet={false}/>
                                             :
                                             <EditableSectionRowsComponent accordionContent={child} selectedVal={selectedVal} planid={planid} handleGridSaveBehavior={handleGridSaveBehavior} planStatus={planStatus} filterParams={filterParams} filterRecord={filterRecord} isOfTypeEndFleet={false} currentHeader={currentHeader}/>                              
                                             }
                                       </span>
                                         </ScrollSyncPane>
                                        )
                                    })}
                                </span>
                                : ''}
                        </AccordionDetails>
                    </span>
                    {(val.values && val.values.length > 0 && !val.content) ? <Divider className="overallDivider" /> : ''}

                    <span className="adjustmentAccordiondetails">
                        <AccordionDetails className='adjustmentAccordiondetailsTitle'>
                            <span className="alignTitle">
                                {(val.title && val.title.length > 0 && val.content) ?
                                    <AllBUAccordionTitle data={val.title} planid={planid} planStatus={planStatus}/>
                                    : ''}</span>
                        </AccordionDetails>
                        <AccordionDetails className='coreBUadjustmentAccordiondetailsContent'>

                            {(val.values && val.values.length > 0 &&
                                val.content && val.content.length > 0) ?
                                <span className="coreBUalignContent">{val.values && val.values.map((child, index) => {
                                    let zoneName = val.header;
                                    if (child.adjustments != undefined) {
                                        return (
                                            <span className='displayflexstyle'>
                                                <ScrollSyncPane group="horizontal">
                                                    <span className="buadjustmentcontent">
                                                        <EditableSectionRowsComponent accordionContent={child} planid={planid} adjDisable={zoneAdjEdit.sectionFleetAdj} handleGridSaveBehavior={handleGridSaveBehavior}
                                                        content={val.content===undefined?false:true} selectedVal={selectedVal} planStatus={planStatus} filterParams={filterParams} 
                                                        zonename={zoneName} filterRecord={filterRecord} currentHeader={currentHeader}/>
                                                    </span>
                                                </ScrollSyncPane>
                                            </span>
                                        )

                                    }
                                    else
                                        if (child.children != undefined && child.children.length > 0 || child.header != undefined)
                                            if (child.header!='ALL') { //to not to display ALL zone in UI
                                                return (
                                                    <div>
                                                        <SectionComponent val={child} applyMargin={true} isTopLevel={false} parentValHeader={val.header}></SectionComponent>
                                                    </div>
                                                )
                                            }}
                                )}</span> :

                                <span>
                                    {val.children && val.children.map((child, index) => {
                                        if (child.children != undefined && child.children.length > 0 || child.header != undefined)
                                            if (child.header!='ALL') { //to not to display ALL zone in UI
                                                return (
                                                    <div>
                                                        <SectionComponent val={child} applyMargin={true} isTopLevel={false} parentValHeader={val.header}></SectionComponent>
                                                    </div>
                                                )
                                            }
                                    })}</span>}


                        </AccordionDetails>
                    </span>
                    {(val.values && val.values.length > 0 && val.content) ? <Divider className="childDivider" /> : ''}

                </Accordion>
            </span>

        )
    }

    var startFleetObj = aggregatedStartfleetArray[0]
    let returnArray = [];
    if(selectedVal==='Type>Zone'){
        returnArray.push((
            <div>
                <EndFleetSectionComponent startFleetValue={0} val={safeActivityTemplate(aggregatedStartfleetArray, aggregatedEndfleetArray, allZones, "START_FLEET", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
            </div>));
    }
    if (aggregatedEndfleetArray && aggregatedEndfleetArray.length > 0) {
        returnArray.push((
            <div>
                {<EndFleetSectionComponent startFleetValue={startFleetObj ? startFleetObj.values[0].currentValues[0].value : 0} val={aggregatedEndfleetArray[0]} applyMargin={true} isTopLevel={true} />}
            </div>));
    }
    else {
        return returnArray;
    }

    if (aggregatedEndfleetArray[0].children.length == 0) {
        return returnArray;
    }
    if(selectedVal==='Type>Zone'){
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedAddsArray, aggregatedEndfleetArray, allZones, "ADDS", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedDeletesArray, aggregatedEndfleetArray, allZones, "REPURCHASE_DELETES", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedCoretoTncArray, aggregatedEndfleetArray, allZones, "CORE_TO_TNC", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedCoretoCsArray, aggregatedEndfleetArray, allZones, "CORE_TO_CS", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedSalvageArray, aggregatedEndfleetArray, allZones, "SALVAGE", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedNetonewayArray, aggregatedEndfleetArray, allZones, "NET_ONEWAY", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    returnArray.push((
        <div>
            <SectionComponent val={safeActivityTemplate(aggregatedNetmovesArray, aggregatedEndfleetArray, allZones, "NET_MOVES", "CORE", "ELECTRIC")} applyMargin={true} isTopLevel={true} />
        </div>));
    }else{
            if(coreelectric_aggregatedZoneArray && coreelectric_aggregatedZoneArray.length>0){
                coreelectric_aggregatedZoneArray.map((ele)=>{
                    if(ele.header!='ALL'){
                    returnArray.push((
                        <div>
                            <SectionComponent val={ele} applyMargin={true} isTopLevel={true} />
                        </div>));
                         } 
                    })
                }
        }
    return returnArray;
}

export default CoreElectricBUAdjustmentView;