import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getDepRateData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisedepRateResponseResponse = await client.apis.plans.getAllDepRate({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });
        const responsesArray = await Promise.all([promisedepRateResponseResponse]);
        const [depRateResponse] = responsesArray.map(o => o.body.data);
        //return depRateResponse.body.data;
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": depRateResponse.rows, "rowcount": depRateResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
            return depRateResponse
         }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Depreciation Rates tab'
    }
}

export  async function uploadDepRateCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadDepRate({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Depreciation Rates tab, Please try again!!'); 
    }
}

export async function saveDepRateTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.plans.bulktransactDepRate({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchDepRateCarGroupDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const depRateResponse =  client.apis.plans.getAllDepRate({ "planid": planId ? planId : 'master' });
    const promiseCargroupResponse =  checkAndLoadDimention("carGroup", client);
    const promiseGetAdminCarGroup =  checkAndLoadDimention("adminCarGroup", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
       // depRateResponse,
        promiseCargroupResponse,
        promiseGetAdminCarGroup
    ]);

    const [
         // DepRateResponse,
          CarGroupResponse,
          admincarGroup,
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "depRateData":DepRateResponse,
        "carGroups": CarGroupResponse,
        "admincarGroup": admincarGroup,
        "users":promiseUserManagementEmailAndIdMap
    })  
}

export async function getDepRateFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseDepRateFilterResponse = await client.apis.plans.getDepRateFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseDepRateFilterResponse]);
        const [depRateResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": depRateResponse.rows, "rowcount": depRateResponse.rows[0] ? depRateResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'Depreciation Rates tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllDepRateData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promisedepRateDeleteResponse = await client.apis.plans.deleteAllDepRateRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });

        if (promisedepRateDeleteResponse.obj && promisedepRateDeleteResponse.obj.statusCode !== '500') {
            return promisedepRateDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Depreciation Rates tab has internal server error, Unable to delete all the records!! Please try again'
    }
}

export async function insertS3UploadDepRateCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.insertS3UploadedDepRateData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi, ''),
                "fileName": filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Depreciation Rates tab, Please try again!!');
    }
}

export async function depRateTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateDepRate({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });

        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Depreciation Rates Validation status ,Please try again!!';
    }
}

export async function getDepRateTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseDepRateResponse = await client.apis.plans.getDepRateTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseDepRateResponse.obj && promiseDepRateResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseDepRateResponse]);
            const [depRateTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return depRateTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Depreciation Rates error status ,Please try again!!';
    }
}

export async function getDepRateErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisedepRateResponse = await client.apis.plans.getDepRateErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promisedepRateResponse]);
        const [depRateResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": depRateResponse.rows, "rowcount": depRateResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Depreciation Rates tab'
    }
}

export async function getDepRateSearchData(planid, pageno, searchParams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseDepRateSearchResponse = await client.apis.plans.getDepRateSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseDepRateSearchResponse]);

        const [depRateSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": depRateSearchResponse.rows, "rowcount": depRateSearchResponse.rows[0] ? depRateSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Depreciation Rates tab'
    }
}