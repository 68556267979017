export const FETCH_SALES_ADJUSTMENT_DATA_PENDING="FETCH_SALES_ADJUSTMENT_DATA_PENDING";
export const FETCH_SALES_ADJUSTMENT_DATA_ERROR="FETCH_SALES_ADJUSTMENT_DATA_ERROR";
export const FETCH_SALES_ADJUSTMENT_DATA_SUCCESS="FETCH_SALES_ADJUSTMENT_DATA_SUCCESS";
export const SET_SALES_ACCORDIAN_CLICK="SET_SALES_ACCORDIAN_CLICK";
export const SET_SALES_ZONE_ACCORDIAN_CLICK="SET_SALES_ZONE_ACCORDIAN_CLICK";
export const TOGGLE_EXAPND_ALL_IN_SALES="TOGGLE_EXAPND_ALL_IN_SALES";
export const ENABLE_EDIT_ACTIVITY="ENABLE_EDIT_ACTIVITY";
export const CANCEL_EDIT_ACTIVITY="CANCEL_EDIT_ACTIVITY";
export const UPDATE_SALES_ADJUSTMENT_ACTIVITY='UPDATE_SALES_ADJUSTMENT_ACTIVITY';
export const FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR = 'FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR';
export const FETCH_PROCESS_SALESADJUSTMENTS_RUN_PENDING = 'FETCH_PROCESS_SALESADJUSTMENTS_RUN_PENDING';
export const FETCH_PROCESS_SALESADJUSTMENTS_RUN_SUCCESS = 'FETCH_PROCESS_SALESADJUSTMENTS_RUN_SUCCESS';
export const FETCH_PROCESS_SALESADJUSTMENTS_RUN_ERROR = 'FETCH_PROCESS_SALESADJUSTMENTS_RUN_ERROR';
export const FETCH_PROCESS_BVDEPOVERRIDE_RUN_PENDING = 'FETCH_PROCESS_BVDEPOVERRIDE_RUN_PENDING';
export const FETCH_PROCESS_BVDEPOVERRIDE_RUN_SUCCESS = 'FETCH_PROCESS_BVDEPOVERRIDE_RUN_SUCCESS';
export const FETCH_PROCESS_BVDEPOVERRIDE_RUN_ERROR = 'FETCH_PROCESS_BVDEPOVERRIDE_RUN_ERROR';

