export const FETCH_ADDSINVALIDPOOLS_DATA_PENDING = 'FETCH_ADDSINVALIDPOOLS_DATA_PENDING';
export const FETCH_ADDSINVALIDPOOLS_DATA_SUCCESS = 'FETCH_ADDSINVALIDPOOLS_DATA_SUCCESS';
export const FETCH_ADDSINVALIDPOOLS_DATA_ERROR = 'FETCH_ADDSINVALIDPOOLS_DATA_ERROR';
export const UPLOAD_ADDSINVALIDPOOLS_PENDING = 'UPLOAD_ADDSINVALIDPOOLS_PENDING';
export const UPLOAD_ADDSINVALIDPOOLS_SUCCESS = 'UPLOAD_ADDSINVALIDPOOLS_SUCCESS';
export const UPLOAD_ADDSINVALIDPOOLS_ERROR = 'UPLOAD_ADDSINVALIDPOOLS_ERROR';
export const SAVE_ADDSINVALIDPOOLS_TRANSACTION_PENDING = 'SAVE_ADDSINVALIDPOOLS_TRANSACTION_PENDING';
export const SAVE_ADDSINVALIDPOOLS_TRANSACTION_SUCCESS = 'SAVE_ADDSINVALIDPOOLS_TRANSACTION_SUCCESS';
export const SAVE_ADDSINVALIDPOOLS_TRANSACTION_ERROR = 'SAVE_ADDSINVALIDPOOLS_TRANSACTION_ERROR';
export const FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_PENDING = 'FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_PENDING';
export const FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_SUCCESS = 'FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_SUCCESS';
export const FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR = 'FETCH_ADDSINVALIDPOOLS_DROPDOWNVALUES_ERROR';
export const RESET_TO_ADDSINVALIDPOOLS_INITIALSTATE = 'RESET_TO_ADDSINVALIDPOOLS_INITIALSTATE';
export const SET_ADDSINVALIDPOOLS_EDITABLE='SET_ADDSINVALIDPOOLS_EDITABLE';