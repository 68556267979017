 import { columnDefs, defaultColDef } from "./parameters_grid_data/ParametersColumns";
 import parametersData from './../../data/requiredParametersForRun.json';
 import { safeCaseInsensitiveCompare } from "../../utils/utils";
 import {FETCH_PARAMETERS_DROPDOWN_PENDING,FETCH_PARAMETERS_DROPDOWN_SUCCESS,FETCH_PARAMETERS_DROPDOWN_ERROR, SET_PARAMETERS_EDITABLE} from './Parameters.ActionTypes';
import { ParametersColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues:[],
    validationFn: function (data) {
         return {
            ID: data.ID,
            key: {error:  checkIfKeyEmpty(data.key, ParametersColumns.key.headerName)},
            value: {error:  checkIfValueEmpty(data.value, ParametersColumns.value.headerName,data.key)},
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    checkIfRequiredFn: function(data){
        return (parametersData.data.includes(data.key));
    },
    businessValidationFn: function(currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function(itm) {
        var unique = true;
        cleaned.forEach(function(itm2) {
            if (safeCaseInsensitiveCompare(itm.key, itm2.key)) {
                unique = false;
                itm.status = "ERROR_ROW";
                itm2.status = "ERROR_ROW";
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && transactions[key].data.status == "ERROR_ROW" && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function checkIfKeyEmpty(key, name){
    if(!key){
        return name + " cannot be empty";  
    }
    return null;
}

function checkIfValueEmpty(value, name,key) {
    if(key){
        if(key.toLowerCase()!=='base_plan'){
            if (value === undefined || value === "" || value === null) {
                return name + " cannot be empty";
            }
        }
        if(key.toUpperCase()==='TNC_DELETE_ICE_MIN_MILEAGE' || key.toUpperCase()==='TNC_DELETE_EV_MIN_MILEAGE'){
            if (value < 0) {
                return name + " cannot be negative";
            }
            const matcher = new RegExp('^[0-9]*$', 'g');
            if (!value.match(matcher)) {
                return name + " must be a whole number";
            }
        }
        if(key.toUpperCase()==='TNC_TRANSFER_MIN_MILEAGE_ICE' || key.toUpperCase()==='TNC_TRANSFER_MIN_MILEAGE_EV'){
            if (value < 0) {
                return name + " cannot be negative";
            }
            const matcher = new RegExp('^[0-9]*$', 'g');
            if (!value.match(matcher)) {
                return name + " must be a whole number";
            }
        }
        return null;
    }
    return null;
   
}

function setColumnDefParams(columnDefs, data) {
 columnDefs.map((column) => {
    column.cellEditorParams = {zoneRfoptions:data.zoneRfData,poolRfoptions:data.poolRfData,tncRfoptions:data.tncRfData}
    if (column.field == ParametersColumns.UpdatedBy.field) {
        let userKeys = Object.keys(data.users), usersFilterData = [];
        userKeys.forEach(usersData => {
            usersFilterData.push({ "label": data.users[usersData], "value": data.users[usersData] })
        })
        column.filterOptions = usersFilterData
    }
    if (column.colId === 'actions') {
        // this if condition changes cellrenderer when new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            column.cellRenderer = 'MainPlanInputsActionRenderer'
        }
        else {
            // this else condition changes cellrenderer when old version and will be removed after acceptance
            column.cellRenderer = 'parametersActionsRenderer'
        }
    } 
 });
 return columnDefs;
}

export default function parametersColumnDefReducer(state = initialState, action) {
 switch(action.type){
    case FETCH_PARAMETERS_DROPDOWN_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
    case FETCH_PARAMETERS_DROPDOWN_SUCCESS:
        return Object.assign({}, state, {
            pending: false,
            dropdownValues: setColumnDefParams(state.columnDefs, action.data)
         });
    case FETCH_PARAMETERS_DROPDOWN_ERROR:
        return Object.assign({}, state, {
            pending: false,
            dropdownValues: action.error
        });
    case SET_PARAMETERS_EDITABLE:
        return Object.assign({}, state, {
            defaultColDef:{...defaultColDef,editable:action.data}
        }); 
     default:
        return state;
 }
}