import { isColumnEditable } from "../../../../utils/utils";
import { plansDateFormatter,statusFormatter } from "../../../../utils/formatters/valueFormatter";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: 'ID', headerName: '#', minWidth: 100, unSortIcon: true,
        editable: false,
    },
    {
        field: 'description', headerName: 'Description', unSortIcon: true,
        editable: false,valueFormatter:(params)=>{
            if(params && params.data.description==' '){
                return 'No description';
            }
        }
    },
    {
        field: 'pool_month', headerName: 'Pool Months', unSortIcon: true,
        editable: false
    },
    {
        field: 'scenario_name', headerName: 'Scenario Name',
        unSortIcon: true,editable: false
    },
    {
        field: 'date', headerName: 'Date',
        valueFormatter:params=>plansDateFormatter(params && params.data.date),
        editable: false,unSortIcon: true,
    },
    {
        field: 'runtime', headerName: 'Run Time(hrs)',
        editable: false,unSortIcon: true,
    },
    {
        field: 'deleted', headerName: 'Archived',
        unSortIcon: true,cellEditor: "autoCompleteEditor",
        editable: params => {
            return isColumnEditable(params);
        },
    },
    {
        field: 'hidden', headerName: 'Hidden',
        unSortIcon: true,cellEditor: "autoCompleteEditor",
        editable: params => {
            return isColumnEditable(params);
        },
    },
    {
        field: 'status', headerName: 'Status',
        editable: false,unSortIcon: true,cellStyle: params=>{
            return statuscellStyles(params)
        },
        valueFormatter:params=>statusFormatter(params && params.data.status)
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "planManagementActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function statuscellStyles(params){
    let borderRadius='12px !important',backgroundColor='',textTransform='capitalize';
    if(params && params.value.toLowerCase()==="new"){
        backgroundColor='#ffcc00';
    }else if(params && (params.value.toLowerCase()=== "ready" || params.value.toLowerCase() == "apply adjustments" || params.value.toLowerCase() == "sales adjustments ready" || params.value.toLowerCase() == "vin dep process ready")){
        backgroundColor='#ebe6e7';
    }else if(params && (params.value.toLowerCase() == "building model" || params.value.toLowerCase() == "optimization queued" || params.value.toLowerCase() == "optimizing" ||params.value.toLowerCase() == "pulling data" ||params.value.toLowerCase() == "sales adjustments running" ||params.value.toLowerCase() == "vin dep process running")){
        backgroundColor='#ece4f4'; 
    }else if(params && params.value.toLowerCase() == "published"){
        backgroundColor='#e2f4ff';    
    }else if(params && (params.value.toLowerCase() == "optimization complete" || params.value.toLowerCase() == "optimization complete as optimal")){
        backgroundColor='#e5fdf1';     
    }else if(params && params.value.toLowerCase() == "error"){
        backgroundColor='#fee6d8';  
    }else if(params && (params.value.toLowerCase() == "adjustment complete" ||params.value.toLowerCase() == "sales adjustments completed" || params.value.toLowerCase() == "vin dep process completed")){
        backgroundColor='#f0fde5';    
    }else if(params && params.value.toLowerCase() == "optimization complete as sub-optim"){
        backgroundColor='#ff980e73'; 
    }else if(params && params.value.toLowerCase() == "optimization complete unknown status"){
        backgroundColor='#eb200b54';
    }else if(params && params.value.toLowerCase() === "editing adjustments"){
        backgroundColor='#e0908854';   
    }else if(params && params.value.toLowerCase() === "adjustments modified"){
        backgroundColor='#8d8bd054';   
    }
    return {
            borderRadius:borderRadius,
            backgroundColor:backgroundColor,
            textTransform:textTransform
          }
}

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
