export const FETCH_TARGETZONEPOOL_DATA_PENDING = 'FETCH_TARGETZONEPOOL_DATA_PENDING';
export const FETCH_TARGETZONEPOOL_DATA_SUCCESS = 'FETCH_TARGETZONEPOOL_DATA_SUCCESS';
export const FETCH_TARGETZONEPOOL_DATA_ERROR = 'FETCH_TARGETZONEPOOL_DATA_ERROR';
export const UPLOAD_TARGETZONEPOOL_PENDING = 'UPLOAD_TARGETZONEPOOL_PENDING';
export const UPLOAD_TARGETZONEPOOL_SUCCESS = 'UPLOAD_TARGETZONEPOOL_SUCCESS';
export const UPLOAD_TARGETZONEPOOL_ERROR = 'UPLOAD_TARGETZONEPOOL_ERROR';
export const SAVE_TARGETZONEPOOL_TRANSACTION_PENDING = 'SAVE_TARGETZONEPOOL_TRANSACTION_PENDING';
export const SAVE_TARGETZONEPOOL_TRANSACTION_SUCCESS = 'SAVE_TARGETZONEPOOL_TRANSACTION_SUCCESS';
export const SAVE_TARGETZONEPOOL_TRANSACTION_ERROR = 'SAVE_TARGETZONEPOOL_TRANSACTION_ERROR';
export const FETCH_TARGETZONEPOOL_DROPDOWNVALUES_PENDING = 'FETCH_TARGETZONEPOOL_DROPDOWNVALUES_PENDING';
export const FETCH_TARGETZONEPOOL_DROPDOWNVALUES_SUCCESS = 'FETCH_TARGETZONEPOOL_DROPDOWNVALUES_SUCCESS';
export const FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR = 'FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR';
export const RESET_TO_TARGETZONEPOOL_INITIALSTATE = 'RESET_TO_TARGETZONEPOOL_INITIALSTATE';
export const SET_TARGETZONEPOOL_EDITABLE='SET_TARGETZONEPOOL_EDITABLE';