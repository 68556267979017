import moment from "moment";

export const HttpResponse = {
  Ok: "OK",
};

export const maximumDate = moment().add(5,'y')
export const minimumDate = moment().subtract(10,'y');

export const MaxDeleteColumns={
  ZoneName: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  ModelGroup:{
    field: "MODEL_GROUP",
    name: "MODEL_GROUP",
    headerName: "Model",
  },
  CarGroup:{
    field: "CAR_GROUP",
    name: "CAR_GROUP",
    headerName: "Car Group",
  },
  MaxDeletes:{
    field: "MAX_DELETES",
    name: "MAX_DELETES",
    headerName: "Max Deletes",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By"
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On"
  }
}

export const MileageTableColumns = {
  Zone: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  CarGroup: {
    field: "CAR_GROUP",
    name: "CAR_GROUP",
    headerName: "Car Group",
  },
  ModelYear: {
    field: "MODEL_YEAR",
    name: "MODEL_YEAR",
    headerName: "Model Year",
  },
  MileageValue: {
    field: "MILEAGE_VALUE",
    name: "MILEAGE_VALUE",
    headerName: "Mileage Value",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By"
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On"
  }
}

export const AdditionaCostColumns = {
  Zone: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  ManufacturerGroup: {
    field: "MANUFACTURER_GROUP",
    name: "MANUFACTURER_GROUP",
    headerName: "Manufacturer",
  },
  MaintenanceCost:{
    field: "MAINTENANCE_COST",
    name: "MAINTENANCE_COST",
    headerName: "Maintenance",
  },
  DamageCost:{
    field: "DAMAGE_COST",
    name: "DAMAGE_COST",
    headerName: "Damage",
  },
  NetInServiceCost:{
    field: "NET_IN_SERVICE_COST",
    name: "NET_IN_SERVICE_COST",
    headerName: "Net In Service",
  },
  RepurchaseReconditioningCost:{
    field: "REPURCHASE_RECONDITIONING_COST",
    name: "REPURCHASE_RECONDITIONING_COST",
    headerName: "Repurchase Reconditioning",
  },
  RiskReconditioningCost:{
    field: "RISK_RECONDITIONING_COST",
    name: "RISK_RECONDITIONING_COST",
    headerName: "Risk Reconditioning",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By"
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On"
  }
};

export const SalvageRateColumns={
  ZoneName: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  BU: {
    field: "BU",
    name: "BU",
    headerName: "BU",
  },
  Quarter:{
    field: "QUARTER",
    name: "QUARTER",
    headerName: "Quarter",
  },
  SalvageMinMileage: {
    field: "SALVAGE_MIN_MILEAGE",
    name: "SALVAGE_MIN_MILEAGE",
    headerName: "Salvage Min Mileage",
  },
  SalvagePercentage: {
    field: "SALVAGE_PERCENTAGE",
    name: "SALVAGE_PERCENTAGE",
    headerName: "Salvage Percentage",
  },
  Month: {
    field: "MONTH",
    name: "MONTH",
    headerName: "Month",
  },
  EngineType: {
    field: "ENGINE_TYPE",
    name: "ENGINE_TYPE",
    headerName: "Engine Type",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By"
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On"
  }
};

export const cellEditors = {
  autoCompleteEditor: "autoCompleteEditor",
  numericEditor: "numericEditor",
  freeSoloAutoCompleteEditor: "freeSoloAutoCompleteEditor",
  dateEditor: "dateEditor",
  upgradePathAutocompleteEditor: "upgradePathAutocompleteEditor",
  TextFieldEditor: "TextFieldEditor",
  toggleButton: "toggleButton",
  ParametersAutoCompleteEditor: "ParametersAutoCompleteEditor",
  autoCompletePopupEditor: "autoCompletePopupEditor"
};

export let MoveCostfilterCategory =
{
  "from_zone": [],
  "to_zone": [],
  "from_pool": [],
  "to_pool": [],
  "car_class": [],
  "car_group": [],
  "allowed": [],
  "bu": [],
  "move_cost": ["between"]
};

export let ManualVinDeletesfilterCategory =
{
  "VIN": [],
  "MONTH": ["between"]
};

export const GridNumericColumns = {
  'movecostgrid': ['allowed', 'move_cost']
}
export const MileageDefaultColumns = {
  Zone: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  CAR_CLASS: {
    field: "CAR_CLASS",
    name: "CAR_CLASS",
    headerName: "Car Class",
  },
  MONTHLY_MILEAGE: {
    field: "MONTHLY_MILEAGE",
    name: "MONTHLY_MILEAGE",
    headerName: "Monthly Mileage",
  },
  PROGRAM_TYPE: {
    field: "PROGRAM_TYPE",
    name: "PROGRAM_TYPE",
    headerName: "Program Type",
  },
  Year: {
    field: "YEAR",
    name: "YEAR",
    headerName: "Year"
  },
  MONTH_NAME: {
    field: "MONTH_NAME",
    name: "MONTH_NAME",
    headerName: "Month"
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const MoveCostColumns = {
  FROM_ZONE: {
    field: "FROM_ZONE",
    name: "FROM_ZONE",
    headerName: "From Zone",
  },
  TO_ZONE: {
    field: "TO_ZONE",
    name: "TO_ZONE",
    headerName: "To Zone",
  },
  FROM_POOL: {
    field: "FROM_POOL",
    name: "FROM_POOL",
    headerName: "From Pool",
  },
  TO_POOL: {
    field: "TO_POOL",
    name: "TO_POOL",
    headerName: "To Pool",
  },
  CAR_CLASS: {
    field: "CAR_CLASS",
    name: "CAR_CLASS",
    headerName: "Car Class",
  },
  CAR_GROUP: {
    field: "CAR_GROUP",
    name: "CAR_GROUP",
    headerName: "Car Group",
  },
  MOVE_COST: {
    field: "MOVE_COST",
    name: "MOVE_COST",
    headerName: "Move Cost",
  },
  ALLOWED: {
    field: "ALLOWED",
    name: "ALLOWED",
    headerName: "Allowed"
  },
  BU: {
    field: "BU",
    name: "BU",
    headerName: "BU"
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  }
};

export const ProgramsColumns = {
  ProgramYear: {
    field: "PROGRAM_YEAR",
    name: "PROGRAM_YEAR",
    headerName: "Year"
  },
  ManufacturerGroup: {
    field: "MANUFACTURER_GROUP",
    name: "MANUFACTURER_GROUP",
    headerName: "Manufacturer"
  },
  SpiCode: {
    field: "SPI_CODE",
    name: "SPI_CODE",
    headerName: "SPI"
  },
  ProgramType: {
    field: "PROGRAM_TYPE",
    name: "PROGRAM_TYPE",
    headerName: "Program Type"
  },
  ModelGroup: {
    field: "MODEL_GROUP",
    name: "MODEL_GROUP",
    headerName: "Model"
  },
  ModelYear: {
    field: "MODEL_YEAR",
    name: "MODEL_YEAR",
    headerName: "Model Year"
  },
  CarClassCode: {
    field: "CAR_CLASS_CODE",
    name: "CAR_CLASS_CODE",
    headerName: "Class"
  },
  BeginDate: {
    field: "BEGIN_DATE",
    name: "BEGIN_DATE",
    headerName: "Start"
  },
  EndDate: {
    field: "END_DATE",
    name: "END_DATE",
    headerName: "End"
  },
  AcceptableZones: {
    field: "ACCEPTABLE_ZONES",
    name: "ACCEPTABLE_ZONES",
    headerName: "Zone"
  },
  MinAge: {
    field: "MIN_AGE",
    name: "MIN_AGE",
    headerName: "Min Age"
  },
  MaxAge: {
    field: "MAX_AGE",
    name: "MAX_AGE",
    headerName: "Max Age"
  },
  MinMileage: {
    field: "MIN_MILEAGE",
    name: "MIN_MILEAGE",
    headerName: "Min Mileage"
  },
  MaxMileage: {
    field: "MAX_MILEAGE",
    name: "MAX_MILEAGE",
    headerName: "Max Mileage"
  },
  NetCapitalCost: {
    field: "NET_CAPITAL_COST",
    name: "NET_CAPITAL_COST",
    headerName: "Capital"
  },
  DepreciationCost: {
    field: "DEPRECIATION_COST",
    name: "DEPRECIATION_COST",
    headerName: "Depreciation"
  },
  Incentive: {
    field: "INCENTIVE",
    name: "INCENTIVE",
    headerName: "Incentive"
  },
  AddVolumeMin: {
    field: "ADD_VOLUME_MIN",
    name: "ADD_VOLUME_MIN",
    headerName: "Min Additional Volume"
  },
  AddVolumeMax: {
    field: "ADD_VOLUME_MAX",
    name: "ADD_VOLUME_MAX",
    headerName: "Max Additional Volume"
  },
  StartMileage: {
    field: "START_MILEAGE",
    name: "START_MILEAGE",
    headerName: "Start Mileage"
  },
  BuyStatus: {
    field: "BUY_STATUS",
    name: "BUY_STATUS",
    headerName: "Buy Status"
  },
  UpdatedByName: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By"
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On"
  }
}
export const ConstraintTableNameOptions=["ADD_DEL_PM","ADD_DEL_ZM","ADD_M","ADD_OEM","EF_M","EF_PM","EF_PMC","EF_ZM","EF_ZMC","NETGL_PM","NETGL_ZM","UNKNOWN"]

export const ConstraintsColumns = {
  ConstraintTable: {
    field: "constraint_table",
    name: "constraint_table",
    headerName: "Constraint Table"
  },
  ConstraintType: {
    field: "constraint_type",
    name: "constraint_type",
    headerName: "Type"
  },
  ZoneName: {
    field: "zone_name",
    name: "zone_name",
    headerName: "Zone"
  },
  Pool: {
    field: "pool",
    name: "pool",
    headerName: "Pool"
  },
  FromDate: {
    field: "from_date",
    name: "from_date",
    headerName: "From"
  },
  ToDate: {
    field: "to_date",
    name: "to_date",
    headerName: "To"
  },
  Min: {
    field: "min",
    name: "min",
    headerName: "Min"
  },
  Max: {
    field: "max",
    name: "max",
    headerName: "Max"
  },
  BU: {
    field: "bu",
    name: "bu",
    headerName: "BU"
  },
  ProgramType: {
    field: "program_type",
    name: "program_type",
    headerName: "Program Type"
  },
  SpiCode: {
    field: "spi_code",
    name: "spi_code",
    headerName: "SPI Code"
  },
  ManufacturerGroupCode: {
    field: "manufacturer_group_code",
    name: "manufacturer_group_code",
    headerName: "Manufacturer"
  },
  CarGroupName: {
    field: "car_group_name",
    name: "car_group_name",
    headerName: "Car Group"
  },
  MileageStart: {
    field: "mileage_start",
    name: "mileage_start",
    headerName: "Min Mileage"
  },
  MileageEnd: {
    field: "mileage_end",
    name: "mileage_end",
    headerName: "Max Mileage"
  },
  Notes: {
    field: "notes",
    name: "notes",
    headerName: "Notes"
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
}

export const UpgradeTemplatesColumns = {//Using the keys for adjustments also
  ZoneName: {
    field: "zone_name",
    name: "zone_name",
    headerName: "Zone"
  },
  Jan: {
    field: "JAN",
    name: "JAN",
    headerName: "Jan"
  },
  Feb: {
    field: "FEB",
    name: "FEB",
    headerName: "Feb"
  },
  Mar: {
    field: "MAR",
    name: "MAR",
    headerName: "Mar"
  },
  Apr: {
    field: "APR",
    name: "APR",
    headerName: "Apr"
  },
  May: {
    field: "MAY",
    name: "MAY",
    headerName: "May"
  },
  Jun: {
    field: "JUN",
    name: "JUN",
    headerName: "Jun"
  },
  Jul: {
    field: "JUL",
    name: "JUL",
    headerName: "Jul"
  },
  Aug: {
    field: "AUG",
    name: "AUG",
    headerName: "Aug"
  },
  Sep: {
    field: "SEP",
    name: "SEP",
    headerName: "Sep"
  },
  Oct: {
    field: "OCT",
    name: "OCT",
    headerName: "Oct"
  },
  Nov: {
    field: "NOV",
    name: "NOV",
    headerName: "Nov"
  },
  Dec: {
    field: "DEC",
    name: "DEC",
    headerName: "Dec"
  },
}

export const AddsInvalidPoolsColumns = {
  Pool: {
    field: "POOL",
    name: "POOL",
    headerName: "Pool",
  },
  ManufacturerGroup: {
    field: "MANUFACTURER_GROUP",
    name: "MANUFACTURER_GROUP",
    headerName: "Manufacturer",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const DepRateColumns = {
  CarGroup: {
    field: "CAR_GROUP",
    name: "CAR_GROUP",
    headerName: "Car Group",
  },
  DepRate: {
    field: "DEP_RATE",
    name: "DEP_RATE",
    headerName: "Dep Rate",
  },
  DepAdjustment: {
    field: "DEP_ADJUSTMENT",
    name: "DEP_ADJUSTMENT",
    headerName: "Dep Adjustment",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const DiversionsColumns = {
  PO: {
    field: "PO",
    name: "PO",
    headerName: "PO",
  },
  Item: {
    field: "ITEM",
    name: "ITEM",
    headerName: "Item",
  },
  Drop: {
    field: "DROP",
    name: "DROP",
    headerName: "Drop",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const TargetZonePoolColumns = {
  Zone: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  Pool: {
    field: "POOL",
    name: "POOL",
    headerName: "Pool",
  },
  BU: {
    field: "BU",
    name: "BU",
    headerName: "BU",
  },
  TotalRFQuantity: {
    field: "TOTAL_RF_QUANTITY",
    name: "TOTAL_RF_QUANTITY",
    headerName: "Total RF Quantity",
  },
  OnrentMonth: {
    field: "ONRENT_MONTH",
    name: "ONRENT_MONTH",
    headerName: "Onrent Month",
  },
  Year: {
    field: "YEAR",
    name: "YEAR",
    headerName: "Year",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const ManualVinDeletesColumns = {
  Vin: {
    field: "VIN",
    name: "VIN",
    headerName: "VIN",
  },
  DelMonth: {
    field: "MONTH",
    name: "MONTH",
    headerName: "MONTH",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  }
}

export const ADJLEVEL = Object.freeze({
  BU: 'bulevel',
  ZONE: 'zonelevel',
  NONE: 'nolevel'
});

export const AllTabActivitiy = ["ADDS", "CARGO_TO_CS", "CORE_TO_CS", "TNC_TO_CS", "REPURCHASE_DELETES", "SALES", "SALVAGE", "NET_ONEWAY", "NET_MOVES", "START_FLEET", "START_CS_FLEET"]

export const SalesTabActivity = {
  "START_CS_FLEET":{show:"true",label:"CAR SALES START FLEET"},
  "CARGO_TO_CS":{show:"false",label:"CAR SALES CARGO"},
  "CORE_TO_CS":{show:"false",label:"CAR SALES CORE"},
  "TNC_TO_CS":{show:"false",label:"CAR SALES TNC"},
  "RISK_DELETES": {show:"true",label:"RISK DELETES"},
  "SALES":{show:"true",label:"SALES"},
  "SALVAGE":{show:"true",label:"SALVAGE"}, 
  "END_CS_FLEET":{show:"true",label:"CAR SALES END FLEET"},
}

export const OperationalEndFleetActivity = [
  {//ALL 
    "ADDS": 1,
    "REPURCHASE_DELETES": -1,
    "CORE_TO_CS": -1,
    "TNC_TO_CS": -1,
    "CARGO_TO_CS": -1,
    "SALES": 1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1,
    "START_CS_FLEET": 0
  }
]

export const BuActivityMap = [
  {//ALL 
    "ADDS": 1,
    "REPURCHASE_DELETES": -1,
    "SALES": -1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1,
    "START_CS_FLEET": 1
  },
  {//CORE 
    "ADDS": 1,
    "REPURCHASE_DELETES": -1,
    "CORE_TO_TNC": -1,
    "CORE_TO_CS": -1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
  {//CORE ICE
    "ADDS": 1,
    "REPURCHASE_DELETES": -1,
    "CORE_TO_TNC": -1,
    "CORE_TO_CS": -1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
  {//CORE ELEC
    "ADDS": 1,
    "REPURCHASE_DELETES": -1,
    "CORE_TO_TNC": -1,
    "CORE_TO_CS": -1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
  {//TNC 
    "ADDS": 1,
    "TNC_TO_CS": -1,
    "CORE_TO_TNC": 1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
  {//TNC ICE 
    "ADDS": 1,
    "TNC_TO_CS": -1,
    "CORE_TO_TNC": 1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
  {//TNC ELEC 
    "ADDS": 1,
    "TNC_TO_CS": -1,
    "CORE_TO_TNC": 1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
  {//CARGO
    "ADDS": 1,
    "CARGO_TO_CS": -1,
    "SALVAGE": -1,
    "NET_ONEWAY": 1,
    "NET_MOVES": 1,
    "START_FLEET": 1
  },
];


export const BUEngineNames = [
  {
    'bu': 'CORE',
    'engine_group': 'NA'
  },
  {
    'bu': 'CORE',
    'engine_group': 'NA'
  },
  {
    'bu': 'CORE',
    'engine_group': 'ICE'
  },
  {
    'bu': 'CORE',
    'engine_group': 'ELECTRIC'
  },
  {
    'bu': 'TNC',
    'engine_group': 'NA'
  },
  {
    'bu': 'TNC',
    'engine_group': 'ICE'
  },
  {
    'bu': 'TNC',
    'engine_group': 'ELECTRIC'
  },
  {
    'bu': 'CARGO',
    'engine_group': 'NA'
  },

]

export const FilterDropdownOptions = [
  [//ALL 
    "TOTAL START FLEET",
    "TOTAL END FLEET",
    "OPERATIONAL START FLEET",
    "OPERATIONAL END FLEET",
    "ADDS",
    "REPURCHASE_DELETES",
    "SALES",
    "SALVAGE",
    "RISK DELETES",
    "NET_ONEWAY",
    "NET_MOVES"
  ],
  [//CORE 
    "ADDS",
    "REPURCHASE_DELETES",
    "CORE_TO_TNC",
    "CORE_TO_CS",
    "SALVAGE",
    "NET_ONEWAY",
    "END FLEET",
    "START_FLEET"
  ],
  [//TNC
    "ADDS",
    "TNC_TO_CS",
    "CORE_TO_TNC",
    "SALVAGE",
    "NET_ONEWAY",
    "END FLEET",
    "START_FLEET"
  ],
  [//CARGO
    "ADDS",
    "CARGO_TO_CS",
    "SALVAGE",
    "NET_ONEWAY",
    "END FLEET",
    "START_FLEET"
  ],

];
export const TYPE_ZONE_STR = 'Type>Zone';
export const ZONE_TYPE_STR = 'Zone>Type';
export const filterCat = { "activity_type": [], "zones": [], "variance": ["between"], "requestedValue": ["between"], "originalValue": ["between"], "requestedAdjustment": ["between"], "isFiltered": false };

export const planInputTabData = [{ tabTitle: "Programs", tabKey: 0, gridName: "programsGrid" },
{ tabTitle: "Add Schedules", tabKey: 1, gridName: "schedulesGrid" },
{ tabTitle: "Allocated", tabKey: 2, gridName: "allocatedGrid" },
{ tabTitle: "Constraints", tabKey: 3, gridName: "constraintsGrid" },
{ tabTitle: "Move Constraints", tabKey: 4, gridName: "moveconstraintsGrid" },
{ tabTitle: "Upgrade Paths", tabKey: 5, gridName: "upgradepathsGrid" },
{ tabTitle: "Plan Assumptions", tabKey: 6, gridName: "planassumptionsGrid" },
{ tabTitle: "Parameters", tabKey: 7, gridName: "parametersGrid" },]

export const upgradeButtons = [{ tabTitle: "UPGRADE PATHS", tabInfo: 'upgradepathsGrid' },
{ tabTitle: "UPGRADE TEMPLATES", tabInfo: 'upgradetemplatesGrid' },]

export const planInputSubTablesData = [["PROGRAM"], ["SCHEDULE"], ["ALLOCATED"], ["CONSTRAINT"], ["MOVE_CONSTRAINT"], ["UPGRADE_PATH","UPGRADE_TEMPLATE"], ["AGE_MILEAGE", "ADDITIONAL_COST", "ADDS_INVALID_POOLS", "MAX_DEL_ZONE", "MILEAGE_DEFAULT", "MOVE_COST", "SALVAGE_RATE", "TARGET_ZONE", "DEP_RATE", "MILEAGE_TABLE", 'DIVERSIONS', 'MANUAL_VIN_DELETES'], ["PARAMETERS"]];

export const PROGRAM = "PROGRAM";
export const SCHEDULE = "SCHEDULE";
export const ALLOCATED = "ALLOCATED";
export const CONSTRAINT = "CONSTRAINT";
export const MOVE_CONSTRAINT = "MOVE_CONSTRAINT";
export const UPGRADE_PATH = "UPGRADE_PATH";
export const UPGRADE_TEMPLATE = "UPGRADE_TEMPLATE";
export const PLAN_ASSUMPTIONS = "PLAN_ASSUMPTIONS";
export const AGE_MILEAGE = "AGE_MILEAGE";
export const ADDITIONAL_COST = "ADDITIONAL_COST";
export const ADDS_INVALID_POOLS = "ADDS_INVALID_POOLS";
export const MAX_DEL_ZONE = "MAX_DEL_ZONE";
export const MILEAGE_DEFAULT = "MILEAGE_DEFAULT";
export const MOVE_COST = "MOVE_COST";
export const SALVAGE_RATE = "SALVAGE_RATE";
export const ZONE_MAP = "ZONE_MAP";
export const TARGET_ZONE = "TARGET_ZONE";
export const DEP_RATE = "DEP_RATE";
export const MILEAGE_TABLE = "MILEAGE_TABLE";
export const DIVERSIONS = "DIVERSIONS";
export const PARAMETERS = "PARAMETERS";
export const MANUAL_VIN_DELETES = "MANUAL_VIN_DELETES";

export const planAssumptionTabsForMainInputs = [
  { tabDisplayTitle:"Age & Mileage by Car Group by Zone",tabTitle: "AGE_MILEAGE", tabKey: 0, gridName:"agemileageGrid" },
  { tabDisplayTitle:"Additional Costs",tabTitle: "ADDITIONAL_COST", tabKey: 1, gridName:"additionalcostGrid" },
  { tabDisplayTitle:"Invalid Pools by OEM",tabTitle: "ADDS_INVALID_POOLS", tabKey: 2, gridName:"addsinvalidpoolsGrid" },
  { tabDisplayTitle:"Maximum Deletes by Zone",tabTitle: "MAX_DEL_ZONE", tabKey: 3, gridName:"maxdeleteGrid" },
  { tabDisplayTitle:"Monthly Mileage Defaults",tabTitle: "MILEAGE_DEFAULT", tabKey: 4, gridName:"mileagedefaultGrid" },
  { tabDisplayTitle:"Transport Costs",tabTitle: "MOVE_COST", tabKey: 5, gridName:"movecostGrid" },
  { tabDisplayTitle:"Salvage Rates",tabTitle: "SALVAGE_RATE", tabKey: 6, gridName:"salvagerateGrid" },
  { tabDisplayTitle:"Demand Target by Zone",tabTitle: "TARGET_ZONE", tabKey: 7, gridName:"targetzonepoolGrid" },
  { tabDisplayTitle:"Depreciation Rates",tabTitle: "DEP_RATE", tabKey: 8, gridName:"deprateGrid" },
  { tabDisplayTitle:"Minimum Risk Delete Mileage",tabTitle: "MILEAGE_TABLE", tabKey: 9, gridName:"mileagetableGrid" },
  { tabDisplayTitle:"Diversions",tabTitle: "DIVERSIONS", tabKey: 10, gridName:"diversionsGrid" },
  { tabDisplayTitle:"Manual VIN Deletes",tabTitle: "MANUAL_VIN_DELETES", tabKey: 11, gridName:"manualvindeletesGrid" },
  // { tabDisplayTitle:"Acceptable Zone Mappings",tabTitle: "ZONE_MAP", tabKey: 12, gridName:"zonemapGrid" },/* Moved to Metadata Tab */
];

export const planAssumptionTabsForPlanInputs = [
  { tabDisplayTitle:"Age & Mileage by Car Group by Zone",tabTitle: "AGE_MILEAGE", tabKey: 0, gridName:"agemileageGrid" },
  { tabDisplayTitle:"Additional Costs",tabTitle: "ADDITIONAL_COST", tabKey: 1, gridName:"additionalcostGrid" },
  { tabDisplayTitle:"Invalid Pools by OEM",tabTitle: "ADDS_INVALID_POOLS", tabKey: 2, gridName:"addsinvalidpoolsGrid" },
  { tabDisplayTitle:"Maximum Deletes by Zone",tabTitle: "MAX_DEL_ZONE", tabKey: 3, gridName:"maxdeleteGrid" },
  { tabDisplayTitle:"Monthly Mileage Defaults",tabTitle: "MILEAGE_DEFAULT", tabKey: 4, gridName:"mileagedefaultGrid" },
  { tabDisplayTitle:"Transport Costs",tabTitle: "MOVE_COST", tabKey: 5, gridName:"movecostGrid" },
  { tabDisplayTitle:"Salvage Rates",tabTitle: "SALVAGE_RATE", tabKey: 6, gridName:"salvagerateGrid" },
  { tabDisplayTitle:"Demand Target by Zone",tabTitle: "TARGET_ZONE", tabKey: 7, gridName:"targetzonepoolGrid" },
  { tabDisplayTitle:"Depreciation Rates",tabTitle: "DEP_RATE", tabKey: 8, gridName:"deprateGrid" },
  { tabDisplayTitle:"Minimum Risk Delete Mileage",tabTitle: "MILEAGE_TABLE", tabKey: 9, gridName:"mileagetableGrid" },
  { tabDisplayTitle:"Diversions",tabTitle: "DIVERSIONS", tabKey: 10, gridName:"diversionsGrid" },
  { tabDisplayTitle:"Manual VIN Deletes",tabTitle: "MANUAL_VIN_DELETES", tabKey: 11, gridName:"manualvindeletesGrid" }];

export const PlanInputsTabStyle = {
    default_tab: {
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "black",
      fontSize: 14,
      textTransform: 'none',
    },
    active_tab: {
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "black",
      fontSize: 14,
      textTransform: 'none'
    },
    defaultError_tab: {
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "red",
      fontSize: 14,
      textTransform: 'none',
      fontWeight: "bold",
    },
    activeError_tab: {
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "red",
      fontSize: 14,
      textTransform: 'none',
      fontWeight: "bold",
    },
    tabItemContainer: {
      margin: "50px",
      display: "flex",
      flexWrap: "wrap",
    },
    copyInProgressIndicator: {
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "black",
      fontSize: 14,
      textTransform: 'none',
      animation: "copyinprogress 2s linear infinite"
    },
    copyInProgressIndicator_active: {
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "black",
      fontSize: 14,
      textTransform: 'none',
      animation: "copyinprogress_active 2s linear infinite"
    }
};

export const PlanAssumptionTabStyle = {
  default_tab: {
      borderRadius: "4px",
      color: "#000000",
      background: "#ffff",
      fontSize: 14,
      padding:"3px",
      textTransform: 'none',
  },
  active_tab: {
      background: "#ffed9b",
      borderColor: "#ffff",
      borderRadius: "4px",
      color: "#000000",
      fontSize: 14,
      padding:"3px",
      textTransform: 'none'
  },
  defaultError_tab: {
      borderRadius: "4px",
      color: "red",
      background: "#ffff",
      fontSize: 14,
      padding:"3px",
      textTransform: 'none',
      fontWeight: "bold",
  },
  activeError_tab: {
      background: "#ffcccc",
      borderColor: "#ffff",
      borderRadius: "4px",
      color: "#ff0000",
      fontSize: 14,
      textTransform: 'none',
      padding:"3px",
      fontWeight: "bold",
  },
  tabItemContainer: {
      margin: "50px",
      display: "flex",
      flexWrap: "wrap",
  },
  copyInProgressIndicator:{
      borderRadius: "4px",
      color: "#000000",
      background: "#ffff",
      fontSize: 14,
      textTransform: 'none',
      animation: "copyinprogress_sub 2s linear infinite"
  },
  copyInProgressIndicator_active:{
      background: "#ffed9b",
      borderColor: "#ffff",
      borderRadius: "4px",
      color: "#000000",
      fontSize: 14,
      textTransform: 'none',
      animation: "copyinprogress_sub_active 2s linear infinite"
  },
  hideTab:{
      display:'none'
  }
};

export const USER = "user";

export const adminTabs = [
  { tabTitle: "User Management", tabKey: 0 },
  { tabTitle: "Plan Management", tabKey: 1 },
  { tabTitle: "Metadata Management", tabKey: 2 }
]

export const revenuefunctionTabs = [
  { tabTitle: "All RFs", tabKey: 0 },
  { tabTitle: "Main RF Inputs", tabKey: 1 },
]

export const revenuefunctionRfInputsTabs = [
  { tabTitle: "Revenue", tabKey: 0 },
  { tabTitle: "RF Inputs", tabKey: 1 },
]

export const mainRfInputsDropdownValues = ['TNC', 'CORE'];//should be same as in rfids const created
export const mainRfInputsTabs = {
  TNC: [{ tabTitle: "TNC ICE", tabKey: 0, gridName: "tncicegrid" }, { tabTitle: "TNC EV", tabKey: 1, gridName: "tncevgrid" }, { tabTitle: "TNC PARAMETERS", tabKey: 2, gridName: "tncparametergrid" }],
  CORE: [{ tabTitle: "DEMAND", tabKey: 0, gridName: "coredemandgrid" }, { tabTitle: "ADJUSTMENTS", tabKey: 1, gridName: "coreadjustmentsgrid" }, { tabTitle: "CORE PARAMETERS", tabKey: 2, gridName: "coreparametergrid" }],
  //here key name should be same as it is mentioned in mainRfInputsDropdownValues const variable, since we are using same in required places.
}

export const rfids = [{ 'CORE': 1, 'CARGO': 2, 'TNC': 3 }];

export const pollTimeoutInterval = 6000;

export const salesAndFinanceTabs = {
  salesAndFinance: [{ tabTitle: "Sales", tabKey: 0, gridName: "salesgrid" }, { tabTitle: "Sales Channels", tabKey: 1, gridName: "saleschannelsgrid" }, { tabTitle: "Bv dep Override", tabKey: 2, gridName: "bvdepoverridegrid" }]
}

export const safids = [{ 'SALES': 1, 'SALESCHANNEL': 2, 'BVDEPOVERRIDE': 3 }];

//Misc constants
export const BASIC_DELETE_MESSAGE = "Do you want to Delete this Plan permanently?"
export const BASIC_DELETE_ROW_TITLE = "Delete Plan";
export const BASIC_DELETE_TEXT = "Delete";
export const BASIC_CANCEL_TEXT = "Cancel";
export const ADD_ROW = "ADD_ROW";
export const EVENT_CELL_VALUE_CHANGED = 'cellValueChanged';
export const EVENT_CELL_EDITING_STOPPED = 'cellEditingStopped';
export const STATUS_DELETE_IN_PROGRESS = "Please wait for few minutes until the plan is deleted."
export const STATUS_DELETE_IN_PROGRESS_TITLE = "Deletion in progress"

export const MODELMFROEM = "modelmfroemgrid";
export const CARCLASSGROUP = 'carclassgroupgrid';
export const ADMINPOOLSGRID = "adminpoolsgrid";
export const ZONEMAPGRID = "zonemapgrid";

export const ADMIN_ACCESS_REVOKED_ERROR_CODE = "Admin Access Revoked";
export const MAX_ROWS_ALLOWED_FOR_MASS_CHANGE_CONSTRAINTS = 40;
export const MAX_ROWS_ALLOWED_FOR_MASS_CHANGE = 2000;
export const MAX_ROWS_ALLOWED_FOR_EACH_GRID = 2000;
export const MAX_MOVE_COST_BATCH_SIZE = 500000;
export const MAX_MANUALVINDELETES_BATCH_SIZE = 500000;
export const MAX_FILESIZE_ALLOWED_FOR_UPLOAD_WITHOUT_S3 = 3145728;//(3145728=>3*1024*1024--->3mb)

export const CHIP_LIMIT_PER_PARAMETER = 5;
export const CHIP_OVER_LIMIT_FLAG = "CHIP_COUNT_OVER_LIMIT";

export const revFunctionBus = ['ALL', 'CORE', 'TNC', 'CARGO'];

export const AllRFsColumns = {
  Id: {
    field: "ID",
    name: "ID",
    headerName: "RF Id",
  },
  RfCode: {
    field: "RF_CODE",
    name: "RF_CODE",
    headerName: "RF Code",
  },
  Description: {
    field: "DESCRIPTION",
    name: "DESCRIPTION",
    headerName: "Description",
  },
  Type: {
    field: "TYPE",
    name: "TYPE",
    headerName: "Type",
  },
  Pool: {
    field: "POOL",
    name: "POOL",
    headerName: "Pool",
  },
  Pieces: {
    field: "PIECES",
    name: "PIECES",
    headerName: "Pieces",
  },
  Status: {
    field: "STATUS",
    name: "STATUS",
    headerName: "Status",
  },
};

export const MainRFInputTncColumns = {
  MainRFInputTncIceColumns: {
    Pool: {
      field: "POOL",
      name: "POOL",
      headerName: "Pool",
    },
    Month: {
      field: "MONTH",
      name: "MONTH",
      headerName: "Month",
    },
    Count: {
      field: "COUNT",
      name: "COUNT",
      headerName: "Count",
    }
  },
  MainRFInputTncEvColumns: {
    Pool: {
      field: "POOL",
      name: "POOL",
      headerName: "Pool",
    },
    Month: {
      field: "MONTH",
      name: "MONTH",
      headerName: "Month",
    },
    Count: {
      field: "COUNT",
      name: "COUNT",
      headerName: "Count",
    }
  },
  MainRFInputTncParameterColumns: {
    Name: {
      field: "PARAMETER_NAME",
      name: "PARAMETER_NAME",
      headerName: "Parameter Name",
    },
    Value: {
      field: "PARAMETER_VALUE",
      name: "PARAMETER_VALUE",
      headerName: "Parameter Value",
    },
  }
};

export const MainRFInputCoreColumns = {
  MainRFInputCoreAdjColumns: {
    OnRentDate: {
      field: "ONRENT_DATE",
      name: "ONRENT_DATE",
      headerName: "On Rent Date",
    },
    Pool: {
      field: "POOL",
      name: "POOL",
      headerName: "Pool",
    },
    Zone: {
      field: "ZONE",
      name: "ZONE",
      headerName: "Zone Name",
    },
    UTE: {
      field: "UTE",
      name: "UTE",
      headerName: "UTE",
    },
    BusinessUnit: {
      field: "BUSINESS_SEGMENT",
      name: "BUSINESS_SEGMENT",
      headerName: "Business Segment",
    }
  }, MainRFInputCoreDemandColumns: {
    Pool: {
      field: "POOL",
      name: "POOL",
      headerName: "Pool",
    },
    BusinessSegment: {
      field: "BUSINESS_SEGMENT",
      name: "BUSINESS_SEGMENT",
      headerName: "Business Segment",
    },
    StartDate: {
      field: "START_DATE",
      name: "START_DATE",
      headerName: "Start Date",
    },
    OnRentTy: {
      field: "ONRENT_TY",
      name: "ONRENT_TY",
      headerName: "On Rent Ty",
    },
    Carclass: {
      field: "CAR_CLASS",
      name: "CAR_CLASS",
      headerName: "Car Class",
    }
  },
  MainRFInputCoreParameterColumns: {
    Name: {
      field: "PARAMETER_NAME",
      name: "PARAMETER_NAME",
      headerName: "Parameter Name",
    },
    Value: {
      field: "PARAMETER_VALUE",
      name: "PARAMETER_VALUE",
      headerName: "Parameter Value",
    },
  }
};

export const RFPlanRevenueInputColumns = {
  RFPlanRevenueInputTncColumns: {
    Pool: {
      field: "POOL",
      name: "POOL",
      headerName: "Pool",
    },
    Onrentdate: {
      field: "ONRENT_DATE",
      name: "ONRENT_DATE",
      headerName: "Onrent Date",
    },
    Carclass: {
      field: "CAR_CLASS",
      name: "CAR_CLASS",
      headerName: "Car Class",
    },
    Month: {
      field: "MONTH",
      name: "MONTH",
      headerName: "Month",
    },
    Minfleetneeded: {
      field: "MIN_FLEET_NEEDED",
      name: "MIN_FLEET_NEEDED",
      headerName: "Min Fleet Needed",
    },
    Rpuatminfleet: {
      field: "RPU_AT_MIN_FLEET",
      name: "RPU_AT_MIN_FLEET",
      headerName: "Rpu At Min Fleet",
    }
  },
  RFPlanRevenueInputCoreColumns: {
    Zone: {
      field: "ZONE_NAME",
      name: "ZONE_NAME",
      headerName: "Zone Name",
    },
    Pool: {
      field: "POOL",
      name: "POOL",
      headerName: "Pool",
    },
    Onrentmonth: {
      field: "ONRENT_MONTH",
      name: "ONRENT_MONTH",
      headerName: "Onrent Month",
    },
    Carclass: {
      field: "CAR_CLASS",
      name: "CAR_CLASS",
      headerName: "Car Class",
    },
    Pieceindex: {
      field: "PIECE_INDEX",
      name: "PIECE_INDEX",
      headerName: "Piece Index",
    },
    Incfleet: {
      field: "INC_FLEET",
      name: "INC_FLEET",
      headerName: "Inc Fleet",
    },
    Increv: {
      field: "INC_REV",
      name: "INC_REV",
      headerName: "Inc Rev",
    },
    Perunit: {
      field: "PER_UNIT",
      name: "PER_UNIT",
      headerName: "Per Unit"
    }
  }
}

export const SchedulesColumns = {
  SpiCode: {
    field: "spi_code",
    name: "spi_code",
    headerName: "SPI",
  },
  CarClassCode: {
    field: "car_class_code",
    name: "car_class_code",
    headerName: "Class",
  },
  ModelGroup: {
    field: "model_group",
    name: "model_group",
    headerName: "Model",
  },
  ModelYear: {
    field: "model_year",
    name: "model_year",
    headerName: "Model Year",
  },
  ManufacturerGroup: {
    field: "manufacturer_group",
    name: "manufacturer_group",
    headerName: "Manufacturer Group",
  },
  ProgramYear: {
    field: "program_year",
    name: "program_year",
    headerName: "Year",
  },
  ProgramType: {
    field: "program_type",
    name: "program_type",
    headerName: "Program Type",
  },
  AcceptableZones: {
    field: "acceptable_zones",
    name: "acceptable_zones",
    headerName: "Zone",
  },
  AllocationMonth: {
    field: "allocation_month",
    name: "allocation_month",
    headerName: "Allocation Month",
  },
  MinSchedule: {
    field: "min_schedule",
    name: "min_schedule",
    headerName: "Min Schedule",
  },
  MaxSchedule: {
    field: "max_schedule",
    name: "max_schedule",
    headerName: "Max Schedule",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const AllocatedColumns = {
  SpiCode: {
    field: "spi_code",
    name: "spi_code",
    headerName: "SPI",
  },
  ManufacturerGroup: {
    field: "manufacturer_group",
    name: "manufacturer_group",
    headerName: "Manufacturer",
  },
  CarClassCode: {
    field: "car_class_code",
    name: "car_class_code",
    headerName: "Class",
  },
  ModelGroup: {
    field: "model_group",
    name: "model_group",
    headerName: "Model",
  },
  Pool: {
    field: "pool",
    name: "pool",
    headerName: "Pool",
  },
  ModelYear: {
    field: "model_year",
    name: "model_year",
    headerName: "Model Year",
  },
  RequestedDeliveryMonth: {
    field: "requested_delivery_month",
    name: "requested_delivery_month",
    headerName: "Requested Delivery Month",
  },
  Count: {
    field: "count",
    name: "count",
    headerName: "Count",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
}

export const MoveConstraintsColumns = {
  ZoneName: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  Pool: {
    field: "POOL",
    name: "POOL",
    headerName: "Pool",
  },
  Month: {
    field: "MONTH",
    name: "MONTH",
    headerName: "Month",
  },
  MinFrom: {
    field: "MIN_FROM",
    name: "MIN_FROM",
    headerName: "Min From",
  },
  MaxFrom: {
    field: "MAX_FROM",
    name: "MAX_FROM",
    headerName: "Max From",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
}

export const UpgradePathsColumns = {
  ZoneTemp: {
    field: "upgrade_zone_template_id",
    name: "upgrade_zone_template_id",
    headerName: "Zone Temp",
  },
  FromClass: {
    field: "from_cc",
    name: "from_cc",
    headerName: "From Class",
  },
  ToClass: {
    field: "to_cc",
    name: "to_cc",
    headerName: "To Class",
  },
  Cost: {
    field: "cost",
    name: "cost",
    headerName: "Cost",
  },
  UltimateChoice: {
    field: "ultimate_choice",
    name: "ultimate_choice",
    headerName: "Ultimate Choice %",
  },
  Attributes: {
    field: "Attributes",
    name: "Attributes",
    headerName: "Attributes",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
}

export const ParametersColumns = {
  key: {
    field: "key",
    name: "key",
    headerName: "Name",
  },
  value: {
    field: "value",
    name: "value",
    headerName: "Value",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
}

export const AgeMileageColumns = {
  CarGroup: {
    field: "CAR_GROUP",
    name: "CAR_GROUP",
    headerName: "Car Group",
  },
  ZoneName: {
    field: "ZONE_NAME",
    name: "ZONE_NAME",
    headerName: "Zone",
  },
  ProgramType: {
    field: "PROGRAM_TYPE",
    name: "PROGRAM_TYPE",
    headerName: "Program Type",
  },
  MinAge: {
    field: "MIN_AGE",
    name: "MIN_AGE",
    headerName: "Min Age",
  },
  MaxAge: {
    field: "MAX_AGE",
    name: "MAX_AGE",
    headerName: "Max Age",
  },
  MinMileage: {
    field: "MIN_MILEAGE",
    name: "MIN_MILEAGE",
    headerName: "Min Mileage",
  },
  MaxMileage: {
    field: "MAX_MILEAGE",
    name: "MAX_MILEAGE",
    headerName: "Max Mileage",
  },
  UpdatedBy: {
    field: "updated_by",
    name: "updated_by",
    headerName: "Updated By",
  },
  UpdatedOn: {
    field: "updated_on",
    name: "updated_on",
    headerName: "Updated On",
  },
};

export const SalesandFinanceColumns = {
  SalesandFinanceBvDepOverrideColumns: {
    VIN: {
      field: "VIN",
      name: "VIN",
      headerName: "VIN",
    },
    NEWDDEPAMT: {
      field: "NEW_DEP_AMT",
      name: "NEW_DEP_AMT",
      headerName: "New Dep Amt",
    },
    STARTINGBV: {
      field: "STARTING_BV",
      name: "STARTING_BV",
      headerName: "Starting BV",
    }
  }
};

export const SalesandFinanceTabData = [
  { tabTitle: "Sales", tabKey: 0, gridName: "salesGrid" },
  { tabTitle: "Sales Channels", tabKey: 1, gridName: "saleschannelGrid" },
  { tabTitle: "BV Dep Override", tabKey: 2, gridName: "bvdepoverridegrid" },]

export const SESSION_TIME_OUT_LIMIT_IN_MINUTES = 5;
export const WARNING_TIME_LIMIT_IN_MINUTES = 5;

export const MessageNotificationValues = {
  Timeout: 7000,
  Severity: {
    warning: 'warning',
    info: 'info',
    success: 'success',
    error: 'error'
  }
};
export const INVALID_FILE_COLUMN_MISMATCH = "INVALID_FILE_COLUMN_MISMATCH";
export const PARAMETERS_SESSION_STORAGE_NAME = "PARAMETERS";

export const BUSINESS_SEGMENT_OPTIONS = ['AP', 'LE'];

export const SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP = 'EMAIL-NAME-MAP';
export const CANNOT_CHANGE_DATASET_ERROR = 'Cannot change dataset while there are pending transactions. Please save or cancel.';

export const POOL_SESSION_STORAGE_NAME = "POOL";


export const MONTHS_3_LETTER=['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
export const COLUMN_TOTAL='Total';
export const ADJUSTMENT_LABLE_REQUESTED_ADJUSTMENT='Requested Adjustment';
export const ADJUSTMENT_LABLE_REQUESTED_VALUE='Requested Value';
export const ADJUSTMENT_LABLE_ADJUSTMENT_VARIANCE='Adjustment Variance';
export const ADJUSTMENT_LABLE_ORIGINAL_VALUE='Original Value';

export const PLAN_STATUS_ADJUSTMENT_COMPLETE="adjustment complete";
export const PLAN_STATUS_SALES_ADJUSTMENTS_READY="sales adjustments ready";
export const PLAN_STATUS_VIN_DEP_PROCESS_READY="vin dep process ready";
export const PLAN_STATUS_SALES_ADJUSTMENTS_RUNNING="sales adjustments running";
export const PLAN_STATUS_VIN_DEP_PROCESS_RUNNING="vin dep process running";
export const PLAN_STATUS_SALES_ADJUSTMENTS_COMPLETE="sales adjustments completed";
export const PLAN_STATUS_VIN_DEP_PROCESS_COMPLETE="vin dep process completed";

export const IS_ADJUSTMENT_COMPLETE_ARRAY=[
      PLAN_STATUS_ADJUSTMENT_COMPLETE, 
      PLAN_STATUS_SALES_ADJUSTMENTS_READY, 
      PLAN_STATUS_VIN_DEP_PROCESS_READY, 
      PLAN_STATUS_SALES_ADJUSTMENTS_RUNNING, 
      PLAN_STATUS_VIN_DEP_PROCESS_RUNNING, 
      PLAN_STATUS_SALES_ADJUSTMENTS_COMPLETE, 
      PLAN_STATUS_VIN_DEP_PROCESS_COMPLETE
];
