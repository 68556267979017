import DateEditor from './editors/DateEditor';
import ActionsRenderer from './renderers/ActionsRenderer';
import ParametersActionsRenderer from './renderers/ParametersActionsRenderer';
import ConstraintsActionRenderer from './renderers/ConstraintsActionRenderer';
import NumericEditor from './editors/NumericEditor';
import AutoCompleteEditor from './editors/AutoCompleteEditor';
import FreeSoloAutoCompleteEditor from './editors/FreeSoloAutoCompleteEditor';
import ToggleButtonComponent from './renderers/ToggleButtonComponent';
import TemplateActionsRenderer from './renderers/TemplateActionsRenderer';
import UpgradePathActionsRenderer from './renderers/UpgradePathActionsRenderer';
import FromClassRenderer from './renderers/UpgradeFromClassRenderer';
import UpgradeToClassRenderer from './renderers/UpgradeToClassRenderer';
import UpgradePathAutocompleteEditor from '../../../containers/upgradepaths/UpgradePathGridEditors/UpgradePathAutocompleteEditor';
import UpgradePathZoneTempRenderer from './renderers/UpgradePathZoneTempRenderer';
import SchedulesActionRenderer from './renderers/SchedulesActionRenderer';
import AllocatedActionRenderer from './renderers/AllocatedActionRenderer';
import AgeMileageActionRenderer from './renderers/AgeMileageActionRenderer';
import AdditionalCostActionRenderer from './renderers/AdditionalCostActionRenderer';
import MileageDefaultActionRenderer from './renderers/MileageDefaultActionRenderer';
import MaxDeletesActionRenderer from './renderers/MaxDeletesActionRenderer';
import DiversionsActionRenderer from './renderers/DiversionsActionRenderer';
import AddsInvalidPoolsActionRenderer from './renderers/AddsInvalidPoolsActionRenderer';
import SalvageRateActionRenderer from './renderers/SalvageRateActionRenderer';
import TargetZonePoolActionRenderer from './renderers/TargetZonePoolActionRenderer';
// import ZoneMapActionRenderer from './renderers/ZoneMapActionRenderer';//Moved to Metadata
import MileageTableActionRenderer from './renderers/MileageTableActionRenderer';
import TextFieldEditor from './editors/TextFieldEditor';
import MoveConstraintActionRenderer from './renderers/MoveConstraintActionRenderer';
import MoveCostActionRenderer from './renderers/MoveCostActionRenderer';
import ToggleSwitchEditor from './editors/ToggleSwitchEditor';
import DepRateActionRenderer from './renderers/DepRateActionRenderer';
import ParametersAutoCompleteEditor from '../../../containers/parameters/ParametersAutoCompleteEditor';
import ManualVinDeletesActionRenderer from './renderers/ManualVinDeletesActionRenderer';
import MainPlanInputsActionRenderer from './renderers/MainPlanInputsActionRenderer'
export default {
    NumericEditor,
    AutoCompleteEditor,
    FreeSoloAutoCompleteEditor,
    DateEditor,
    ActionsRenderer,
    ToggleButtonComponent,
    TemplateActionsRenderer,
    ConstraintsActionRenderer,
    ParametersActionsRenderer,
    UpgradePathActionsRenderer,
    FromClassRenderer,
    UpgradeToClassRenderer,
    UpgradePathAutocompleteEditor,
    UpgradePathZoneTempRenderer,
    SchedulesActionRenderer,
    AllocatedActionRenderer,
    AgeMileageActionRenderer,
    AdditionalCostActionRenderer,
    TextFieldEditor,
    MaxDeletesActionRenderer,
    DiversionsActionRenderer,
    MoveConstraintActionRenderer,
    MileageDefaultActionRenderer,
    AddsInvalidPoolsActionRenderer,
    SalvageRateActionRenderer,
    TargetZonePoolActionRenderer,
    // ZoneMapActionRenderer, //Moved to Metadata
    MileageTableActionRenderer,
    MoveCostActionRenderer,
    ToggleSwitchEditor,
    DepRateActionRenderer,
    ParametersAutoCompleteEditor,
    ManualVinDeletesActionRenderer,
    MainPlanInputsActionRenderer
}