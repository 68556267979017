import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import GridComponents from "./grid_components";
import { useDispatch, useSelector } from "react-redux";
import { completeEditMultipleRow, completeEditRow, validateGrid } from '../../../store/Sales_FinanceMultiGrid/SalesAndFinanceMultiGrid.Actions';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import './SalesAndFinanceMultiGridUI.css';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { UpdateMasterDataCache, fetchTopLevelFilterOptions, setGridUserPageNumber, validateSingleGridsData } from '../../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { MAX_ROWS_ALLOWED_FOR_EACH_GRID, MAX_ROWS_ALLOWED_FOR_MASS_CHANGE, MessageNotificationValues } from '../../../Constants';
import { Button } from '@material-ui/core';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import { addNotificationMessage } from '../../../store/topbar/AllPlans.Actions';

const SalesAndFinanceMultiGridUI = (props) => {
    //const [gridApi.current, setGridApi] = useState(null);
    //const [columnApi.current, setColumnApi] = useState(null);
    const dispatch = useDispatch();
    const [changeCurrentData, setChangeCurrentData] = useState(false)
    const { reducer, gridName, gridColDef, planid, gridpageno, onRowEditingStarted, onRowEditingStopped, onCellEditingStopped, currentView, refreshCells, rowEditType, suppressclickEdit, handleMultipleRowDelete, scrollToTop, handleAddNewDropdownValue, isPaginationRequired, selectedPlanId,loadGridData } = { ...props };
    const { currentData, transactions, validations } = reducer;
    const [newCurrentData, setNewCurrentData] = useState()

    const gridTotalRowCount = useSelector((state) => {
        return state.PaginationGridReducer.gridTotalRowCount;
    });

    const gridUserPageno = useSelector((state) => {
        return state.PaginationGridReducer.gridUserPageno;
    })

    const gridTransactionSaveSuccess = useSelector((state) => {
        return state.PaginationGridReducer.gridTransactionSaveSuccess;
    })


    const gridApi = useRef(null);
    const columnApi = useRef(null);
    const pagesize = useRef();

    function onGridReady(params) {

        gridApi.current = params.api
        columnApi.current = params.columnApi

        params.api.sizeColumnsToFit();

        window.onresize = () => {
            params.api.sizeColumnsToFit();
        }
    }

    useEffect(() => {
        pagesize.current = Math.ceil(gridTotalRowCount / MAX_ROWS_ALLOWED_FOR_EACH_GRID)
        if (gridUserPageno != 1 && (gridUserPageno > pagesize.current)) {//&& (gridTotalRowCount<=pagesize.current*MAX_ROWS_ALLOWED_FOR_EACH_GRID)
            dispatch(setGridUserPageNumber(pagesize.current==0 ? 1: pagesize.current))
            //setPageNo(totPageNumbersToBeDisplayed)
            //pageno.current = pagesize.current;
        }
    }, [gridTotalRowCount])

    const frameworkComponents = {
        autoCompleteEditor: GridComponents.AutoCompleteEditor,
        autoCompletePopupEditor: GridComponents.AutoCompletePopupEditor,
        numericEditor: GridComponents.NumericEditor,
        freeSoloAutoCompleteEditor: GridComponents.FreeSoloAutoCompleteEditor,
        dateEditor:GridComponents.DateEditor,
        fullDateEditor:GridComponents.FullDateEditor,
        SalesAndFinanceActionRenderer: GridComponents.SalesAndFinanceActionRenderer,
    };

    const handleCellEditingStopped = (params) => {
        return onCellEditingStopped();
    }

    const handleCellEditingStarted = (params) => {
        if (transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            return onRowEditingStarted(true, isOfTypeCopyOrDelete, transactions);
        }
    }

    const onCellValueChanged = (params) => {
        params.data.row_validation_status = "";
        let allowMassChange = true;
        if (gridApi.current && gridApi.current.getSelectedRows().length > MAX_ROWS_ALLOWED_FOR_MASS_CHANGE) {
            // userMessage('error',`Please select up-to ${MAX_ROWS_ALLOWED_FOR_MASS_CHANGE} records only. Only 1 row was updated.`);
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, `Please select up-to ${MAX_ROWS_ALLOWED_FOR_MASS_CHANGE} records only. Only 1 row was updated.`))
            allowMassChange = false;
        }
        if (params.oldValue !== params.newValue) {
            var updatedRowData = { ...params.data };
            params.data.isEdited = true;
            updatedRowData[params.column.colId] = params.newValue;
            let sortedData = [];
            let selectedRows = [];
            let isSelectedRowModified = false;
            if (gridApi.current && gridApi.current.getSelectedRows().length > 0) {
                selectedRows = gridApi.current.getSelectedRows();
                // console.log("selectedRows===",selectedRows.length);
                isSelectedRowModified = selectedRows.length > 0 && selectedRows.length <= MAX_ROWS_ALLOWED_FOR_MASS_CHANGE && selectedRows.findIndex(aRow => aRow.ID === updatedRowData.ID) > -1;
                if (isSelectedRowModified && allowMassChange) {
                    selectedRows.forEach(rowData => {
                        rowData[params.column.colId] = params.newValue;
                    });
                }
            }
            if (gridApi.current.sortController.getSortModel().length > 0) {
                gridApi.current.forEachNodeAfterFilterAndSort((node, index) => {
                    sortedData.push(node.data)
                });
                columnApi.current.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
                setChangeCurrentData(true)
            }
            if (isSelectedRowModified && allowMassChange) {
                let rowIds = [];
                selectedRows.forEach(rowData => {
                    rowIds.push(rowData.ID);
                });

                dispatch(completeEditMultipleRow(gridName, updatedRowData, gridColDef.validationFn, { id: rowIds, value: params.colDef.field }, gridColDef.businessValidationFn, sortedData));
            } else {
                dispatch(completeEditRow(gridName, updatedRowData, gridColDef.validationFn, { id: params.data.ID, value: params.colDef.field }, gridColDef.businessValidationFn, sortedData));
            }
           
        }
        return onCellEditingStopped();
    }

    useEffect(() => {
        if (changeCurrentData) {
            setNewCurrentData(currentData)
        }
        setNewCurrentData([])
        setChangeCurrentData(false)
    }, [changeCurrentData])

    const handleRowEditMode = (params) => {
        // // Handle click event for action cells  
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });
        // gridApi.current.setImmutableData(true);
        if (isCurrentRowEditing == true)
            return onRowEditingStarted(isCurrentRowEditing, null, transactions);
    }

    const rowClassRules = function () {
        return {
            'errorCellBorder': (params) => {
                return (params.data.status === 'INVALID_ROW' || params.data.status === 'INVALID_NEW_ROW')
            },
            'errorRow': (params) => {
                return (params.data.status === 'ERROR_ROW' || params.data.status === 'ERROR_NEW_ROW' || params.data.status === 'ERROR_COPY_ROW' || (params.data.row_validation_status && params.data.row_validation_status.includes('D')))//use includes
            },
            'copyRow': (params) => {
                return (params.data.status === 'COPY_ROW')
            },

        }
    }

    useEffect(() => {
        if (gridApi.current !== null) {
            gridApi.current.ensureIndexVisible(0);
        }

    }, [scrollToTop]);

    useEffect(() => {
        if (transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            if (isOfTypeCopyOrDelete)
                return onRowEditingStarted(true, isOfTypeCopyOrDelete, transactions);
        }
    }, [transactions]);

    useEffect(() => {
        if (gridApi.current !== null && gridTransactionSaveSuccess) {
            setTimeout(() => {
                gridApi.current.refreshCells();
                gridApi.current.redrawRows();
            }, 100)
        }
    }, [gridTransactionSaveSuccess]);//refreshCells

    useEffect(() => {
        if (transactions.length > 0 && transactions[transactions.length - 1].type !== undefined && currentData.length > 1 && transactions[transactions.length - 1].type == 'add' && transactions[transactions.length - 1].data == undefined) {  //if the latest action is Add then scroll
            if (gridApi.current.sortController.getSortModel().length > 0) {
                columnApi.current.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
            }
            let node = gridApi.current.getRowNode(currentData[currentData.length - 2].ID);
            if (node && node.rowIndex !== undefined) {
                gridApi.current.ensureIndexVisible(currentData.length - 2);
            }
        }
    }, [currentData])

    const onSelectionChanged = () => {
        let selectedRows = [];
        if (gridApi.current && gridApi.current.getSelectedRows().length > 0) {
            selectedRows = gridApi.current.getSelectedRows();
        }
        if (handleMultipleRowDelete !== undefined) {
            handleMultipleRowDelete(selectedRows);
        }
    }

    const refresh =async () => {
        setChangeCurrentData(true)
        dispatch(validateGrid(gridName, currentData, gridColDef.validationFn, gridColDef.businessValidationFn))
    }

    useEffect(() => {
        if (gridColDef && gridColDef.defaultColDef && gridApi.current &&
            !gridColDef.defaultColDef.editable && gridApi.current.getEditingCells().length > 0) {
            gridApi.current.stopEditing();
        }
    }, [gridColDef, gridApi.current]);

    const setpageNumber = (event, value) => {
        try {
            if (gridUserPageno != value) {//this will be true when user clicks on different page than his existing page
                dispatch(setGridUserPageNumber(value))
                //setPageNo(value);
                //pageno.current = value;
                loadGridData(false, value)// false is sent as parameter to fetch error status of tab and value is the page number to fetch data
            }
        } catch (e) {
            console.error(e)
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Changing the page number have been failed, Please try again'))
        }
    }

    return (
        <div id="grid-theme-wrapper" className="app-theme">
            <div style={{ 'backgroundColor': 'white' }}>
                <Button onClick={refresh}><RefreshSharpIcon fontSize='large' /></Button>
                Selected rows={gridApi.current ? gridApi.current.getSelectedRows().length : 0},Pending Transactions={transactions ? transactions.length : 0}
                {/* {gridName && gridName==='movecostgrid' && selectedPlanId? 
    <div style={{'backgroundColor':'red','color':'white', 'alignItems':'center','fontWeight':'bold'}}>Main Inputs Data !!</div>
    :''
  } */}
            </div>
            <div style={{
                width: '100%',
                height: '100%'
            }}
                className="ag-theme-alpine">
                {(currentData !== null) ? <AgGridReact
                    overlayNoRowsTemplate='No Records To Show'
                    propsTest={props}
                    reactNext={true}
                    onGridReady={onGridReady}
                    columnDefs={gridColDef.columnDefs}
                    defaultColDef={gridColDef.defaultColDef}
                    rowData={changeCurrentData ? newCurrentData : currentData}
                    getRowNodeId={data => data.ID}
                    checkboxSelection={true}
                    headerCheckboxSelection={true}
                    enterMovesDownAfterEdit={false}
                    enterMovesDown={false}
                    frameworkComponents={frameworkComponents}
                    suppressHorizontalScroll={false}
                    rowSelection={'multiple'}
                    editType={rowEditType}
                    immutableData={true}
                    suppressPropertyNamesCheck={true}
                    onCellEditingStarted={handleCellEditingStarted}
                    onCellValueChanged={onCellValueChanged}
                    onRowClicked={handleRowEditMode}
                    onCellEditingStopped={handleCellEditingStopped}
                    rowClassRules={rowClassRules()}
                    gridName={gridName}
                    stopEditingWhenGridLosesFocus={false}
                    tooltipShowDelay={100}
                    tooltipMouseTrack={true}
                    suppressClickEdit={suppressclickEdit}
                    suppressMaintainUnsortedOrder={false}
                    onSelectionChanged={onSelectionChanged}
                    suppressRowClickSelection={true}
                /> : <div></div>}
                {isPaginationRequired ?
                    <Pagination
                        disabled={transactions.length > 0 ? true : false}
                        style={{ marginLeft: '30%' }}
                        count={(pagesize.current <= 0 ? 1 : pagesize.current)}
                       // page={pageno.current}
                        page={gridUserPageno}
                        showFirstButton
                        showLastButton size="large"
                        onChange={setpageNumber} />
                    : ''}
            </div>

        </div>);
}
export default SalesAndFinanceMultiGridUI;
