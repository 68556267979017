import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import IconFilter from "../../../assets/images/icon_filter.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconActionDownload from '../../../assets/images/icon_action_download.svg';
import SearchBar from "../../searchbar/SearchBar";
import './AllRFsActionBar.css';
import {fetchAllRFsData } from '../../../store/revenuefunction/allrfs/AllRFs.Actions';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import InputFilter from '../../filter_components/input_filter/InputFilter';
import DatePickerFilter from '../../filter_components/datepicker_filter/DatePickerFilter';
import { Button, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import RangeFilter from '../../constraintsactionbar/filter_components/range_filter/Constraint_RangeFilter';
import DropdownFilter from '../../constraintsactionbar/filter_components/dropdown_filter/constraint_DropdownFilter';
import CustomPopOver from "../../../core-components/Popover/CustomPopOver";
import StatusIcon from "../../../assets/images/icons_status_icons_completed.svg";
import { NttLabel } from '../../../core-components/Label/NttLabel';
import { safeExcelValue, safeExcelDate } from '../../../utils/utils';
import { populateGrid2 } from '../../../store/revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions';
import { revFunctionBus } from '../../../Constants';
//import {searchRecord} from "../../../store/programs/Programs.Actions";


const AllRFsActionBar = (props) => {
  const { allRFsData, options, actionView, onViewChanged, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, selectedRows, data, downloadCSV,
    filterParams, transactions, allRFsGridReducer, allRFscolumnDefinitionReducer, filterSearchKey, isFiltered,
    setIsFiltered, setIsSearchBarValueExists } = props;
  let [disabled, setDisabled] = useState(false);
  let [currentView, setCurrentView] = useState(actionView);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  var filterCat = { "PROGRAM_YEAR": [], "PROGRAM_TYPE": [], "ACCEPTABLE_ZONES": [], "CAR_CLASS_CODE": [], "MODEL_GROUP": [], "MODEL_YEAR": [], "NET_CAPITAL_COST": "", "BEGIN_DATE": ["between"], "END_DATE": ["between"], "MIN_AGE": ["between"], "MAX_AGE": ["between"], "DEPRECIATION_COST": "", "MIN_MILEAGE": ["between"], "MAX_MILEAGE": ["between"], "INCENTIVE": "", "ADD_VOLUME_MIN": ["between"], "ADD_VOLUME_MAX": ["between"], "SPI_CODE": [], "MANUFACTURER_GROUP": [], "START_MILEAGE": "", "BUY_STATUS": [] };
  const [filterArray, setFilterArray] = useState(filterCat);
  const [tempFilterArray, setTempFilterArray] = useState(filterArray);
  const [popUp, setPopUp] = useState(null);
  const theme = createMuiTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(-1);
  const [filterClassName, setFilterClassName] = useState('filterCustomButton');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsFilterSearchKey, setFlterSearchKey] = useState('');
  const filterPopOverOpen = Boolean(filterAnchorEl);
  const filterId = filterPopOverOpen ? 'simple-popover' : undefined;
  let message = "Are you sure that you want to delete selected rows?"
  let title = "Delete Row";
  let deletetext = "Delete";
  let canceltext = "Cancel";



  theme.typography.body2 = {
    fontSize: '14px'
  };

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: '180vh',
      maxHeight: '180vh',
    }
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDropdown, setSelectedDropdown] = React.useState("between");
  const [selectedFromDate, setSelectedFromDate] = React.useState();
  const [selectedToDate, setSelectedToDate] = React.useState();
  const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
  const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);



  var filterData = useSelector((state, shallowEqual) => {
    return state.rootProgramsReducer.programsReducer.data;
  });

  const loadFilterData = useCallback(async (allRFsData) => {
    try {
      //await dispatch(fetchFilterData(allRFsData));
    } catch (err) {
      console.error('got error while loading dealer Branch details');
    }
  }, [dispatch]);

  const loadFilterParams = useCallback(async (tempFilterArray) => {
    try {
      //dispatch(setFilterParameters(tempFilterArray))
    } catch (err) {
      console.error('got error while loading filterparams');
    }
  }, [dispatch]);

  const loadFilterCategories = useCallback(async () => {
    try {
      //await dispatch(fetchFilterConstraints());
    } catch (err) {
      console.error('got error while loading dealer Branch details');
    }
  }, [dispatch]);

  const switchView = (index) => {
    if (index == 1) {
      setCurrentView("accordion");
      return onViewChanged("grid");
    } else {
      setCurrentView("grid");
      return onViewChanged("accordion");
    }
  }

  useEffect(() => {
    clearFilter()
  }, [])

  const handleChange = (event) => {
    switchView();
  };

  const handleEditClick = (e, selected) => {
    setAnchorEl(null);
    if (actionView == "grid") {
      setCurrentView("accordion");
      setSelectedIndex(1);
      return onViewChanged("grid");
    } else {
      setCurrentView("grid");
      setSelectedIndex(2);
      return onViewChanged("accordion");
    }
  };

  const handleDropDownValueChange = (event, displayName, colDef) => {
    setSelectedValue(event);
    var filterData = { ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key === colDef) {
        if (Array.isArray(filterData[key])) {
          filterData[key] = event;
        } else {
          if (event[0])
            filterData[key] = event[0].value;
          else
            filterData[key] = event.target.value;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const onDateDropDownValueChanged = (e, colDef) => {
    tempFilterArray[colDef][0] = e.target.value;
    setSelectedDropdown(e.target.value);
  }

  const onDateRangeChanged = (e, colDef, dateField) => {
    var date = new Date(e);
    if (dateField == "from")
      setSelectedFromDate(date);
    else if (dateField == "to")
      setSelectedToDate(date);
    var filterData = { ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key === colDef) {
        if (filterData[colDef][0] === "between") {
          if (filterData[key].length === 3) {
            if (dateField === "from") {
              filterData[key][1] = date;
            }
            if (dateField === "to") {
              filterData[key][2] = date;
            }
          } else {
            if (filterData[key].length == 2 && dateField == "from")
              filterData[key][1] = date;
            else if (filterData[key].length == 2 && dateField == "to")
              filterData[key][2] = date;
            else
              filterData[key].push(date);
          }
        } else {
          if (filterData[key].length == 1)
            filterData[key].push(date);
          else
            filterData[key][1] = date;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const onRangeDropDownValueChanged = (e, colDef) => {
    tempFilterArray[colDef][0] = e.target.value;
    setSelectedDropdown(e.target.value);
  }

  const onValueRangeChanged = (e, colDef, rangeField) => {
    var range = e.target.value;
    if (rangeField == "from")
      setSelectedFromRangeValue(range);
    else if (rangeField == "to")
      setSelectedToRangeValue(range);
    var filterData = { ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key == colDef) {
        if (filterData[colDef][0] == "between") {
          if (filterData[key].length === 3) {
            if (rangeField === "from") {
              filterData[key][1] = range;
            }
            if (rangeField === "to") {
              filterData[key][2] = range;
            }
          } else {
            if (filterData[key].length == 1 && rangeField == "to") {
              filterData[key].push("0");
            }
            filterData[key].push(range);
          }
        } else {
          if (filterData[key].length == 1)
            filterData[key].push(range);
          else
            filterData[key][1] = range;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const getSelectedValue = (colDef) => {
    if (tempFilterArray !== undefined) {
      return tempFilterArray[colDef];
    }
  }

  const applyFilter = () => {
    setOpen(false);
    setFilterArray({ ...tempFilterArray });
    loadFilterParams({ ...tempFilterArray });
    setIsFiltered(true);
  }

  const clearFilter = () => {
    //setOpen(false);
    setFilterArray(filterCat);
    setTempFilterArray(filterCat);
    setSelectedFilterIndex(-1);
    loadFilterParams(null);
    setActiveIndex(0);
    setIsFiltered(false);
  }

  const fetchDialogContent = () => {
    if (filterData !== undefined && filterData !== null && filterData.length > 0) {
      return (<div className="flexContainer">
        {filterData.map((fields, index) => {
          return (<div className="filterMain">
            {(fields.type === "dropdown") ?
              (<DropdownFilter displayName={fields.displayName} options={fields.options}
                theme={theme} selectedValue={getSelectedValue(fields.colDef)}
                onValueChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}>
              </DropdownFilter>) :
              (fields.type === "input") ?
                (<InputFilter selectedValue={getSelectedValue(fields.colDef)}
                  displayName={fields.displayName} theme={theme}
                  onTextChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}></InputFilter>) :
                (fields.type === "datepicker") ?
                  (<DatePickerFilter displayName={fields.displayName} theme={theme}
                    selectedDate={tempFilterArray[fields.colDef]}
                    onFromDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "from")}
                    onToDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "to")}
                    onValueChanged={(e) => onDateDropDownValueChanged(e, fields.colDef)}>
                  </DatePickerFilter>) :
                  (fields.type === "range") ?
                    (<RangeFilter displayName={fields.displayName} theme={theme}
                      selectedRangeValue={tempFilterArray[fields.colDef]}
                      onFromValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "from")}
                      onToValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "to")}
                      onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.colDef)}
                    >
                    </RangeFilter>) :
                    <div></div>}</div>);
        }
        )}</div>);
    }
  }

  const handleClose = (e) => {
    setOpen(false);
    setTempFilterArray(filterArray);
  };

  const changeHandler = async (event) => {
    if (event.target.value !== '') {
      const fileToUpload = event.target.files[0];
      event.target.value = '';
      //dispatch(uploadProgramData(selectedPlanId, fileToUpload));
    }
  };

  const downloadHandler = async (event) => {
  };

  function JsonToCSV(JsonArray, JsonFields) {
    var csvStr = JsonFields.join(",") + "\n";

    JsonArray.forEach(element => {
      let program_year = safeExcelValue(element.PROGRAM_YEAR);
      let manufacturer_group_code = safeExcelValue(element.MANUFACTURER_GROUP);
      let spi_code = safeExcelValue(element.SPI_CODE);
      let program_type = safeExcelValue(element.PROGRAM_TYPE);
      let model_group = safeExcelValue(element.MODEL_GROUP);
      let model_year = safeExcelValue(element.MODEL_YEAR);
      let car_class_code = safeExcelValue(element.CAR_CLASS_CODE);
      let begin_date = safeExcelDate(element.BEGIN_DATE, 'DD-MMM-YY');
      let end_date = safeExcelDate(element.END_DATE, 'DD-MMM-YY');
      let acceptable_zone = safeExcelValue(element.ACCEPTABLE_ZONES);
      let min_age = safeExcelValue(element.MIN_AGE);
      let max_age = safeExcelValue(element.MAX_AGE);
      let min_milage = safeExcelValue(element.MIN_MILEAGE);
      let max_milage = safeExcelValue(element.MAX_MILEAGE);
      let net_capital_cost = safeExcelValue(element.NET_CAPITAL_COST);
      let depreciation_cost = safeExcelValue(element.DEPRECIATION_COST);
      let incentive = safeExcelValue(element.INCENTIVE);
      let add_volume_min = safeExcelValue(element.ADD_VOLUME_MIN);
      let add_volumn_max = safeExcelValue(element.ADD_VOLUME_MAX);
      let start_milage = safeExcelValue(element.START_MILEAGE);
      let buy_status = safeExcelValue(element.BUY_STATUS);
      csvStr += program_year + ',' + manufacturer_group_code + ',' + spi_code + ',' + program_type + ',' + model_group + ',' + model_year + ',' + car_class_code + ',' + begin_date + ',' + end_date + ',' + acceptable_zone + ',' + min_age + ',' + max_age + ',' + min_milage + ',' + max_milage + ',' + net_capital_cost + ',' + depreciation_cost + ',' + incentive + ',' + add_volume_min + ',' + add_volumn_max + ',' + start_milage + ',' + buy_status + "\n";
    })
    return csvStr;
  }

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handlePopOverClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    return switchView(index);
  };

  const switchViewComponent = () => {
    return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
      <Button id={id} onClick={(e) => handlePopOverClick(e)} endIcon={<ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon>}>View</Button>
      <CustomPopOver
        className="actiobarPopOver"
        popOverId="popoverId"
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
      </CustomPopOver>
    </div>);
  }

  useEffect(() => {
    if (actionView === "grid")
      setSelectedIndex(2);
    else
      setSelectedIndex(1);
  }, [actionView]);

  const handleClickOpen = (props) => {
    if (!bulkRowDeleteIconDisabled) {
      setOpenDeleteDialog(true);
    }
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleDeleteSelectedRows = () => {
    setOpenDeleteDialog(false);
    deleteSelectedRows()
  }

  const selectFilterType = (type) => {
    if (type == 'Custom Filter') {
      loadFilterData(allRFsData);
      setOpen(true);
    } else if (type == 'Clear Filter') {
      setOpen(false);
      setFilterArray(filterCat);
      setTempFilterArray(filterCat);
      setSelectedFilterIndex(-1);
      loadFilterParams(null);
      setActiveIndex(0);
    }
  };

  const handleFilterPopOverClick = (e) => {
    setFilterClassName('filterSecondaryButton');
    setFilterAnchorEl(e.currentTarget);
  }

  const handleFilterPopOverClose = () => {
    setFilterClassName('filterPrimaryButton');
    setFilterAnchorEl(null);
  };

  const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);

  function filterRecords(array, filterparams) {
    if (filterparams == null) {
      return array;
    } else {
      const filterKeys = Object.keys(filterparams);
      return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
          // ignores an empty filter
          if (!filterparams[key].length) return true;
          if (key === "BEGIN_DATE" || key === "END_DATE") {
            if (filterparams[key].length === 1) {
              return true;
            }
            else {
              if (item[key] == undefined && item.month) {
                let d1 = new Date(filterparams[key][1]);
                let d2 = new Date(item.month.split('T')[0]);
                let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                  let d3 = new Date(filterparams[key][2]);
                  let NoTimeDate2 = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                  let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                  return itemValue >= startDate && itemValue <= endDate;
                } else if (filterparams[key][0] === "less") {
                  return itemValue <= startDate;
                } else if (filterparams[key][0] === "greater") {
                  return itemValue >= startDate;
                }
              }
              else {
                let d1 = new Date(filterparams[key][1]);
                let d2 = new Date(item[key].split('T')[0]);
                let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                  let d3 = new Date(filterparams[key][2]);
                  let NoTimeDate2 = d3.getFullYear() + '/' + (d3.getMonth() + 1);
                  let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                  return itemValue >= startDate && itemValue <= endDate;
                } else if (filterparams[key][0] === "less") {
                  return itemValue <= startDate;
                } else if (filterparams[key][0] === "greater") {
                  return itemValue >= startDate;
                }
              }
            }
          }
          if (typeof filterparams[key] === 'object' && key !== "BEGIN_DATE" && key !== "END_DATE" &&
            key !== "MIN_AGE" && key !== "MAX_AGE" &&
            key !== "ADD_VOLUME_MIN" && key !== "ADD_VOLUME_MAX" &&
            key !== "MIN_MILEAGE" && key !== "MAX_MILEAGE") {
            if (filterparams[key].length == 0) {
              return true;
            }
            else {
              if (key && filterparams[key][0].value === 'Blanks') {
                return ((item[key] === null) || (item[key] === undefined))
              } else {
                return (filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) == undefined ? filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) : filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])).value);
              }
            }
          }
          if ((key === "MIN_AGE" || key === "MAX_AGE")) {
            if (filterparams[key].length === 1) {
              return true;
            } else {
              let startValue = parseInt(filterparams[key][1], 0);
              let itemValue = parseInt(item[key], 0);
              if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                let endValue = parseInt(filterparams[key][2], 0);
                if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
                  return item[key] >= startValue && itemValue <= endValue;
                } else {
                  return true;
                }
              } else if (filterparams[key][0] === "less") {
                if (filterparams[key][1] !== "") {
                  return itemValue <= startValue;;
                } else {
                  return true;
                }
              } else if (filterparams[key][0] === "greater") {
                if (filterparams[key][1] !== "") {
                  return itemValue >= startValue;
                } else {
                  return true;
                }
              }
            }
          } if ((key === "ADD_VOLUME_MIN" || key === "ADD_VOLUME_MAX")) {
            if (filterparams[key].length === 1) {
              return true;
            } else {
              let startValue = parseInt(filterparams[key][1], 0);
              let itemValue = parseInt(item[key], 0);
              if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                let endValue = parseInt(filterparams[key][2], 0);
                if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
                  return itemValue >= startValue && itemValue <= endValue;
                } else {
                  return true;
                }
              } else if (filterparams[key][0] === "less") {
                if (filterparams[key][1] !== "") {
                  return itemValue <= startValue;
                } else {
                  return true;
                }
              } else if (filterparams[key][0] === "greater") {
                if (filterparams[key][1] !== "") {
                  return itemValue >= startValue;
                } else {
                  return true;
                }
              }
            }
          } if ((key === "MIN_MILEAGE" || key === "MAX_MILEAGE")) {
            if (filterparams[key].length === 1) {
              return true;
            } else {
              let startValue = parseInt(filterparams[key][1], 0);
              let itemValue = parseInt(item[key], 0);
              if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                let endValue = parseInt(filterparams[key][2], 0);
                if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
                  return itemValue >= startValue && itemValue <= endValue;
                } else {
                  return true;
                }
              } else if (filterparams[key][0] === "less") {
                if (filterparams[key][1] !== "") {
                  return itemValue <= startValue;
                } else {
                  return true;
                }
              } else if (filterparams[key][0] === "greater") {
                if (filterparams[key][1] !== "") {
                  return itemValue >= startValue;
                } else {
                  return true;
                }
              }
            }
          } if (typeof filterparams[key] === 'string') {
            return getValue(filterparams[key]) == getValue(item[key]);
          }
        });
      });
    }
  }

  const applyTransactions = (allRFsData, transactions) => {
    let deepcopyProgramsData = allRFsData.map(o => { return { ...o }; })
    transactions.map((value1) => {
      deepcopyProgramsData.map((value2, index) => {
        if (value1.type !== 'delete' && value1.type !== 'add' && value1.type !== 'copy' && (value2.ID == value1.data.ID)) {
          deepcopyProgramsData[index] = value1.data;
        } else if (value1.type == 'delete' && (value2.ID == value1.id)) {
          deepcopyProgramsData.splice(index, 1)
        }
      });
      if (value1.type == 'add' || value1.type == 'copy') {
        if (value1.data !== undefined) {  // If data is undefined,app is braking while filter and clear filter.That's why adding condition.But here we loose added row on filter if it is blank.
          deepcopyProgramsData.push(value1.data);
        }
      }
    });
    return deepcopyProgramsData;
  }

  useEffect(() => {
    if (transactions && transactions.length == 0) {
      dispatch(fetchAllRFsData(selectedPlanId));
    } else if (transactions.length > 0) {
      var currentdata = applyTransactions(allRFsData, transactions);
      var filteredCurrentData = filterRecords(currentdata, filterParams);
      var filteredData = filterRecords(allRFsData, filterParams);
      dispatch(populateGrid2("allrfsgrid", filteredData, filteredCurrentData, filterSearchKey, allRFscolumnDefinitionReducer.checkValue, allRFscolumnDefinitionReducer.validationFn, allRFscolumnDefinitionReducer.businessValidationFn));
    }
  }, [filterParams, filterSearchKey])

  useEffect(() => {
    if (allRFsData.length > 0) {
      var filteredData = filterRecords(allRFsData, filterParams);
      dispatch(populateGrid2("allrfsgrid", filteredData, filteredData, filterSearchKey, allRFscolumnDefinitionReducer.checkValue, allRFscolumnDefinitionReducer.validationFn, allRFscolumnDefinitionReducer.businessValidationFn));
    }
  }, [allRFsData])

  const filterComponent = () => {
    return (<div className={(filterPopOverOpen || selectedFilterIndex === -1) ? 'filterCustomButton' : filterClassName} aria-describedby={id}>
      <Button id={id} startIcon={<img src={IconFilter} />} endIcon={(selectedFilterIndex !== -1) ? <ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon> : ''}>
        {selectedFilterIndex === 1 ? 'Custom Filter' : (selectedFilterIndex === 2) ? 'Clear Filter' : ''}
      </Button>
      <CustomPopOver
        className="filterPopOver"
        popOverId="filterPopoverId"
        open={filterPopOverOpen}
        anchorEl={filterAnchorEl}
        onClose={handleFilterPopOverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <List>
          <ListItem button
            selected={selectedFilterIndex === 1}
            onClick={(event) => handleFilterListItemClick(event, 1)}>
            <img src={(selectedFilterIndex === 1) ? StatusIcon : ''} className="imageSpacing"></img>
            <NttLabel theme={theme} labelVariant="body2">
              Custom Filter</NttLabel>
          </ListItem>
          <Divider />
          <ListItem button
            selected={selectedFilterIndex === 2}
            onClick={(event) => handleFilterListItemClick(event, 2)}>
            <img src={selectedFilterIndex === 2 ? StatusIcon : ''} className="imageSpacing"></img>
            <NttLabel theme={theme} labelVariant="body2">
              Clear Filter</NttLabel>
          </ListItem>
        </List>
      </CustomPopOver>
    </div>);
  }

  const handleFilterListItemClick = (event, index) => {
    setFilterClassName('filterPrimaryButton');
    setSelectedFilterIndex(index);
    setFilterAnchorEl(null);
    var selectedValue;
    if (index === 1)
      selectedValue = "Custom Filter";
    else if (index === 2)
      selectedValue = "Clear Filter";
    return selectFilterType(selectedValue);
  };

  const onSearchValueChanged = (e) => {
    setFlterSearchKey(e.target.value)
    if (e.target.value === '') {
      setIsSearchBarValueExists(false);
    } else {
      setIsSearchBarValueExists(true);
    }
    //dispatch(searchRecord(e.target.value));
  }

  const onConstraintTypeClick = (e, type) => {

  }

  return (
    <div className="revFunctionHeader">
      <div className="revFunctionbus">
        {revFunctionBus.map((type, index) => {
          return (
            <div className="typebuttons" key={index}>
              <Button key={index} className={activeIndex === index ? 'btnactive' : ''} onClick={(e) => onConstraintTypeClick(e, type, index)}><span className={activeIndex === index ? "types activefontstyle" : 'types'}>{type}</span></Button>
            </div>
          )
        })}
      </div>
      <div className="filterStyle">
        <div className="programsfilterMargin">{filterComponent()}</div>
        <SearchBar onSearchValueChanged={onSearchValueChanged}></SearchBar>
      </div>
      <div className="actionButtons">
        <div className='imageIcon'>
          <img src={IconActionDownload} onClick={() => downloadHandler()} />
        </div>
        {switchViewComponent()}
      </div>
    </div>
  )
}

export default AllRFsActionBar;