import { combineReducers } from 'redux';
import MileageDefaultReducer from './MileageDefault.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import mileageDefaultColumnDefinitionReducer from "./MileageDefault.ColumnDef.Reducer";

const rootMileageDefaultReducer = combineReducers({
    MileageDefaultReducer
    , mileageDefaultGridReducer: createNamedWrapperReducer(multiGridUIReducer, "mileagedefaultgrid")
    , mileageDefaultColumnDefinitionReducer
})

export default rootMileageDefaultReducer;