import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/core";


export const NttLabel = ({
    theme,
    labelVariant,
    labelComponent,
    labelColor,
    children,
    labelGutterBottom,
}) => {
    return (
        <ThemeProvider theme={theme}>
        <Typography
            variant={labelVariant}
            component={labelComponent}
            gutterBottom={labelGutterBottom}
            color={labelColor}
        >
            {children}
        </Typography>
        </ThemeProvider>
    );
};

NttLabel.propTypes = {
    labelVariant: PropTypes.string,
    labelComponent: PropTypes.string,
    labelGutterBottom: PropTypes.bool,
    labelAlign: PropTypes.string,
    labelClasses: PropTypes.object,
    labelColor: PropTypes.string,
    labelDisplay: PropTypes.string,
    labelNoWrap: PropTypes.bool,
    labelParagraph: PropTypes.bool,
};
NttLabel.defaultProps = {
    labelGutterBottom: false,
    labelNoWrap: false,
    labelParagraph: false,
};
