import React, { useContext } from "react";
import { adjustmentFilters } from "../../../containers/adjustments/AdjustmentsPage";
import { checkFilterParameterValue, checkType } from "../../../utils/AdjustmentsUtils";
import { localeFormatAndRounding, assignSign } from "../../../utils/formatters/valueFormatter";
import './ReadOnlyCellComponent.css';


const ReadOnlyCellComponent = (props) => {
    const { data, type, isExpanded, planStatus, filterParams, zonename, filterRecord, currentHeader, isOfTypeEndFleet } = props;
    let adjustmentActivities = useContext(adjustmentFilters);
    let isVarianceMatches = false;
    let isReqValueMatches = false;
    let isReqAdjustmentMatches = false;
    let isOriginalValMatches = false;
    let finalValue = calculateFinalValue(data);
    isReqValueMatches = (filterParams ? checkIfValueMatches(filterParams.requestedValue, Math.round(finalValue), adjustmentActivities.filterActivityForReqValue, "reqValue") : false);
    let currentValue = checkType(data.current);
    let originalValue = checkType(data.original);
    isOriginalValMatches = (filterParams ? checkIfValueMatches(filterParams.originalValue, Math.round(originalValue), adjustmentActivities.filterActivityForOriginalValue, "originalValue") : false);
    let adjustment = checkType(data.adjustment);
    isReqAdjustmentMatches = (filterParams ? checkIfValueMatches(filterParams.requestedAdjustment, Math.round(adjustment), adjustmentActivities.filterActivityForReqAdjVal, "adjValue") : false);
    let adjustmentvar = finalValue - currentValue; // adj calc for endfleet
    isVarianceMatches = (filterParams ? checkIfValueMatches(filterParams.variance, Math.round(adjustmentvar), adjustmentActivities.filterActivityForAdjVar, "adjVar") : false);

    let fnlVal=data.fnlVal;
    let adjVar=data.adjVar;

    //console.log("checkIfShouldBeHighlighted====",type,zonename,currentHeader);
    let shouldBeHighlighted=checkIfShouldBeHighlighted(filterParams,data,currentHeader,zonename);

    function checkIfShouldBeHighlighted(filterParams,data,currentHeader,zonename){

        
        let isActivityMatches = false, isZoneMatches = false, isVarianceMatches = false, isRequestedValueMatches = false, isOriginalValueMatches = false, isReqAdjustmentMatches = false;
        let isActivityProvided = false, isZoneProvided = false, isVarianceProvided = false, isRequestedValueProvided = false, isOriginalValueProvided = false, isReqAdjustmentProvided = false;
    

        if(filterParams && filterParams.isFiltered){
            
            
            if (filterParams && ((filterParams.variance.length > 1) || (filterParams.variance[0] === "notzero")) ) {
                isVarianceProvided=true;
                isVarianceMatches =checkFilterParameterValue(adjVar,filterParams.variance);
            }
            if (filterParams && ((filterParams.requestedValue.length > 1) || (filterParams.requestedValue[0] === "notzero")) ) {
                isRequestedValueProvided=true;
                isRequestedValueMatches = checkFilterParameterValue(fnlVal,filterParams.requestedValue);
            }
            if (filterParams && ((filterParams.originalValue.length > 1) || (filterParams.originalValue[0] === "notzero")) ) {
                isOriginalValueProvided=true;
                isOriginalValueMatches = checkFilterParameterValue(checkType(data.original),filterParams.originalValue);
            }
            if (filterParams && ((filterParams.requestedAdjustment.length > 1) || (filterParams.requestedAdjustment[0] === "notzero"))) {
                isReqAdjustmentProvided=true;
                isReqAdjustmentMatches = checkFilterParameterValue(checkType(data.adjustment),filterParams.requestedAdjustment);
            }
            if(filterParams.zones.length > 0){
                isZoneProvided=true;                
                isZoneMatches=filterParams.zones.some(zone=>zone.label===zonename || zone.label===currentHeader);//This condition is for supporting both the views because the ViewType value is not present
            }
            if(filterParams.activity_type.length > 0){
                isActivityProvided=true;
                isActivityMatches=filterParams.activity_type.some(activity=>activity.label===currentHeader || activity.label===zonename);//This condition is for supporting both the views because the ViewType value is not present
            }


            let finalDecision=true;
            let isFiltered=false;

            if(isVarianceProvided){
                isFiltered=true;
                finalDecision=finalDecision && isVarianceMatches;
            }
            if(isRequestedValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isRequestedValueMatches;
            }
            if(isOriginalValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isOriginalValueMatches;
            }
            if(isReqAdjustmentProvided){
                isFiltered=true;
                finalDecision=finalDecision && isReqAdjustmentMatches;
            }
            if(isZoneProvided){
                isFiltered=true;
                finalDecision=finalDecision && isZoneMatches;
            }
            if(isActivityProvided ){
                isFiltered=true;
                finalDecision=finalDecision && isActivityMatches;
            }


            if(!isFiltered){
                finalDecision=false;
            }
            return finalDecision;
        }else{
            return false;
        }
    }

    function checkIfValueMatches(params, value, activitiesToBeFiltered, filterType) {
        let isValueMatches = false;
        if (params) {
            if (params && params.length > 1) {
                let paramsValue = params[1].replace(/,/g,'');
                if (params[0] === 'equal') {
                    isValueMatches = ((parseInt(paramsValue)) === value)
                } else if (params[0] === 'between') {
                    let startValue = parseInt(paramsValue);
                    let endValue = parseInt(params[2].replace(/,/g,''));
                    if (params[1] !== "" && params[2] !== "") {
                        isValueMatches = ((value >= startValue) && (value <= endValue));
                    } else {
                        isValueMatches = true;
                    }
                } else if (params[0] === "less") {
                    let startValue = parseInt(paramsValue);
                    if (params[1] !== "") {
                        isValueMatches = (value <= startValue);
                    } else {
                        isValueMatches = true;
                    }
                } else if (params[0] === "greater") {
                    let startValue = parseInt(paramsValue);
                    if (params[1] !== "") {
                        isValueMatches = (value >= startValue);
                    } else {
                        isValueMatches = true;
                    }
                }
            } else if (params[0] === 'notzero') {
                isValueMatches = (value !== 0)
            }
            if (isValueMatches) {
                let arr = activitiesToBeFiltered.filter((val) => {
                    return (val === data.activity_type);
                })
                if (arr.length === 0) {
                    let subArr = [];
                    let activityArr = []
                    if (zonename) {
                        activityArr.push(currentHeader);
                        subArr.push(currentHeader);
                        subArr.push(zonename);
                        activitiesToBeFiltered.push(activityArr);
                        activitiesToBeFiltered.push(subArr);
                    } else {
                        activityArr.push(data.activity_type)
                        activitiesToBeFiltered.push(activityArr);
                    }
                }
                filterRecord(data.activity_type, filterType,value);
            }
        }
        return isValueMatches;
    }

    function calculateFinalValue(data) {
        let finalValue = 0;
        let adjustment = 0;
        if (data.adjustment != null) {
            if (typeof data.adjustment == 'object')
                adjustment = data.adjustment.value;
            else
                adjustment = data.adjustment;
        }
        if (typeof data.original == 'object')
            finalValue = data.original.value + adjustment;
        else
            finalValue = data.original + adjustment;
        return finalValue;
    }
    let isAdjComplete = (planStatus && planStatus.toLowerCase() == 'adjustment complete');
    return isExpanded ? (
        <div className={type == 'summaryStack' ? 'applygreybackground' :(data.isFreezMonth)? 'applyFreezMonthColor cellComponent':'cellComponent'}>
            <span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentValuesWithMargin":'adjustmentValuesWithMargin'}>{localeFormatAndRounding(currentValue)}</span>
        </div>
    ) : (
        <div className={type == 'summaryStack' ? ((shouldBeHighlighted) ? 'applygreybackground selectedForFilter' : 'applygreybackground') : (((shouldBeHighlighted) && data.isFreezMonth) ? ' applyFreezMonthColor cellComponent selectedForFilter':((shouldBeHighlighted) ? 'cellComponent selectedForFilter' : 'cellComponent'))}>
            <span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentValuesWithMargin":'adjustmentValuesWithMargin'}>{localeFormatAndRounding(currentValue)}</span>
            <span className={((adjustment > 0) && data.isFreezMonth) ? "applyFreezMonthColor adjustmentvaluesgreen":(adjustment > 0) ? 'adjustmentvaluesgreen' :
                ((adjustment < 0) && data.isFreezMonth) ? 'applyFreezMonthColor adjustmentvaluesred' : (adjustment < 0) ? 'adjustmentvaluesred':(data.isFreezMonth) ? 'applyFreezMonthColor adjustmentvalues': 'adjustmentvalues'}>{assignSign(adjustment)}</span>
            {isAdjComplete ? (<span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentvalues": 'adjustmentvalues'}>{localeFormatAndRounding(adjustmentvar)}</span>) : ''}
            <span className={(finalValue != 0 && data.isFreezMonth) ? "applyFreezMonthColor adjustmentvaluesblue":(finalValue != 0) ? 'adjustmentvaluesblue':(data.isFreezMonth) ? 'applyFreezMonthColor adjustmentvaluesblue': "adjustmentvalues"}>{localeFormatAndRounding(finalValue)}</span>
            {isAdjComplete ? <span className={data.isFreezMonth ? "applyFreezMonthColor adjustmentvalues":'adjustmentvalues'}>{localeFormatAndRounding(originalValue)}</span> : ''}
        </div>
    )
}
export default ReadOnlyCellComponent;


