import React, { useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsNoneIcon from "../../assets/images/icons-non-functional-icons-notification.svg";
import MoreIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import AppLogo from "../../assets/images/app_logo.png";
import AppLogoInverted from "../../assets/images/logo_Hertz_inverted.png";
import UserIcon from "../../assets/images/icons_header_user.svg"
import PlansList from "./PlansDropdown";
import { NttDropdown } from '../../core-components/Dropdown/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPlansData, getPlanBySelectedPlanId, getZoneRfData, getPoolRfData, initializeRoleAction, activateCreatePlan, updateCreatePlanStatus } from "../../store/topbar/AllPlans.Actions";
import { Link, NavLink, useHistory,useLocation} from 'react-router-dom';
import { plansDateFormatter } from "../../utils/formatters/valueFormatter";
import { pollTimeoutInterval } from "../../Constants";
import JobInProgressDialog from "../Dialogcomponent/JobInProgressDialogComponent";
import { Snackbar, Tooltip } from "@material-ui/core";
import { Box } from '@material-ui/core'
import Alert from "@material-ui/lab/Alert";
import { getAccessTokenNUserDetailsAfterAuth, getTimeLeft } from "../../utils/authUtils";
import { authContext, handleManualLogout, handleSessionLogout } from "../../config";
import ExpiryTimeTicker from "./ExpiryTimeTicker";
import Messagenotifier from "../message_notifier_component/Messagenotifier";

const useStyles = makeStyles(theme => ({
  appLogo: {
    width: 92,
    marginTop: 5,
    marginRight: 40
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#434244",
    marginRight: 40
  },
  titleInverted: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#ffcc00",
    marginRight: 40
  },
  userNameLabel: {
    marginLeft: 5
  },
  planSelect: {
    width: 300,
    [theme.breakpoints.down("xs")]: {
      width: 100
    },
    marginRight: 10
  },
  addPlan: {
    backgroundColor: "#fff",
    color: "#434244",
    fontSize: 30,
    width: 24,
    height: 34,
    paddingLeft: 4,
    borderRadius: 4,
    cursor: "pointer"
  },
  divisionList: {
    marginTop: 10,
    color: "#000"
  },
  notificationBadge: {
    backgroundColor: "#000",
    color: "#fff"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  },
  sectionDesktop: {
    display: "none",
    marginTop: "10px",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  pointerCursor: {
    cursor: "pointer"
  }
}));

export default function NavigationItem() {
  // console.log("---Hi from topbar.js file---")
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("loading...");
  const [useremail, setUseremail] = useState(null);
  const history = useHistory();
  const location=useLocation()
  const [addPlan, setAddPlan] = useState(false);
  const [timerVariable,setTimerVariable]=useState(null);
  const [jobInProgressDialogOpen, setJobInProgressDialogOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [showMessage,setShowMessage] = useState(false);
  const creationInProgressTitle = "Create plan";
  const creationInProgress = "Creating a new plan,please wait";
  const url=location.pathname.split('/');
  const isPlanInputs=url && url.length>1 && url[1]==="plan";

  var allPlansData = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.allplans;
  });

  let plansDataById = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.plansDataById;
  });
  const newPlanId = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.newPlanId;
  })
  const roleName = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.userRoleName;
  })

  const createPlanJobId = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.createPlanJobId;
  })
  
  let planCreateSuccessful=useSelector((reduxstate)=>{
  return reduxstate.rootAllPlansReducer.allPlansReducer.planCreateSuccessful;
  });
 const notificationMessages=useSelector((state)=>{
  return state.rootAllPlansReducer.allPlansReducer.notificationMessages;
 })
  const  error = useSelector((reduxState) => {
    return reduxState.rootAllPlansReducer.allPlansReducer.error;
  })

  const loadAllPlansData = useCallback(async () => {
    try {
      let resp = await dispatch(fetchAllPlansData());
    } catch (err) {
      console.error('got error while loading allplans data');
    }
  }, [dispatch]);

  const loadPlanDataById = (planid) => {
    dispatch(getPlanBySelectedPlanId(planid));
  }

  const loadZoneRfData = (planid) => {
    dispatch(getZoneRfData(planid));
  }

  const loadPoolRfData = (planid) => {
    dispatch(getPoolRfData(planid));
  }

  const LoadNewPlanDetails = (plansDataById) => {
    let dateTime = plansDateFormatter(plansDataById[0].date);
    loadZoneRfData(newPlanId);
    loadPoolRfData(newPlanId);
    let url = '/plan/' + newPlanId + '/planinputs';
    history.replace({
      pathname: url,
      state: {  // location state
        selectedplan: newPlanId,
        datetime: dateTime
      },
    });
    return history;
  }

  useEffect(() => {
    if (allPlansData === undefined || allPlansData.length == 0) {
      loadAllPlansData();
      // this localStorage is set initially as false to use older version and will be removed after confirmation
      localStorage.setItem("newArchitecture",false)
    }
  }, [])

  useEffect(() => {
    if(planCreateSuccessful!==null){
      if( showMessage===false && planCreateSuccessful===true){
        userMessage('success', 'Successfully created plan.')
        setShowMessage(true)
      } else if(showMessage===false && planCreateSuccessful===false){
        if(error){
          userMessage('error', error);
          setShowMessage(true)
        }else{
          userMessage('error', 'Failed to create plan.');
          setShowMessage(true)
        }
        
      }
        setJobInProgressDialogOpen(false);
        clearInterval(timerVariable);
        setTimerVariable(null);
    }
    if (addPlan == true && newPlanId !== "") {
      loadPlanDataById(newPlanId);
    }
    if (addPlan == true && plansDataById !== undefined && plansDataById.length > 0 && plansDataById[0].id == newPlanId) {
      setAddPlan(false);
      LoadNewPlanDetails(plansDataById);
    }
  }, [allPlansData, plansDataById, newPlanId,planCreateSuccessful])

  useEffect(async () => {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();
    // console.log("In USE EFFECT on authContext._user: authContext._user=",authContext._user);
    // console.log("In USE EFFECT on authContext._user: userNToken=",userNToken);

      const name = userNToken.name;
      const email = userNToken.email;
      const preferred_username = userNToken.preferred_username;

    setUsername(name);
    setUseremail(email);
    dispatch(initializeRoleAction(name, email, preferred_username));
  }, [authContext._user]);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = async () => {
    handleManualLogout();
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const userMessage = (severity, message) => {
    setWarningSnackBarOpen(true);
    setSeverity(severity);
    setWarningMessage(message);
  }
  
  const handleCloseWarningSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningSnackBarOpen(false);
  };

  const AddNewPlan = () => {
    setAddPlan(true);
    dispatch(activateCreatePlan());
    setJobInProgressDialogOpen(true);
  }

  useEffect(()=>{
    if(jobInProgressDialogOpen && createPlanJobId>0){
        setTimerVariable(setInterval(()=>dispatch(updateCreatePlanStatus(createPlanJobId)),pollTimeoutInterval));
    }        
  },[createPlanJobId]);

  const messageNotifications = useMemo(() => {
    return (<>
    {notificationMessages.length != 0 ? (<Box className="notificationmessage">
      {notificationMessages.map(msg => (<Messagenotifier messageNotifier={msg} key={msg.id} />))}
    </Box>) : ""}
    </>)
  }, [notificationMessages])
   
const changeArchitecture=()=>{
  // this functionality is to toggle between new and old version and will be removed later
  if (!JSON.parse(localStorage.getItem("newArchitecture"))) {
    localStorage.setItem("newArchitecture", true)
    if (url[1] == "plan") {
       let dateTime = plansDateFormatter(plansDataById[0].date);
       let planid = history.location.state.selectedplan
      let pathUrl = '/plan/' + planid + '/newplaninputs';
      history.push({
        pathname: pathUrl,
        state: {  // location state
          selectedplan: planid,
          datetime: dateTime
        },
      })
    }
    else {
      history.push({
        pathname: "/newmaininputs"
      })
    }
  }
  else {
    localStorage.setItem("newArchitecture", false)
    if (url[1] == "plan") {
      let dateTime = plansDateFormatter(plansDataById[0].date);
      let planid = history.location.state.selectedplan
      let pathUrl = '/plan/' + planid + '/planinputs';
      history.push({
        pathname: pathUrl,
        state: {  // location state
          selectedplan: planid,
          datetime: dateTime
        },
      })
    }
    else {
      history.push({
        pathname: "/maininputs"
      })
    }
  }
}
  return (
    <div className={classes.grow} style={{width: "101%"}}>
      <AppBar position="static">
        <Toolbar style={isPlanInputs?{backgroundColor:"#ffcc00"}:{backgroundColor:"#000000"}}>
          <Link to={{ pathname: `/` }}>
            {isPlanInputs?
              <img src={AppLogo} alt="app-logo" className={classes.appLogo} />
              :
              <img src={AppLogoInverted} alt="app-logo" className={classes.appLogo} />
            }
          </Link>
          <Typography className={isPlanInputs?classes.title:classes.titleInverted} variant="h6" noWrap>
            XFLEET
          </Typography>
          <PlansList allPlansData={allPlansData}></PlansList>
          <AddIcon className={classes.addPlan} onClick={AddNewPlan} />
          <ExpiryTimeTicker timeReaderFunction={getTimeLeft} isPlanInputs={isPlanInputs}/>
          {/* just comment the below line to remove version changes feature */}
          {((url[1]=='plan'||url[1]=='maininputs'|| url[1]=='newmaininputs') && process.env.REACT_APP_IS_NEW_ARCHITECTURE_REQUIRED==='true')?
          <Tooltip title="To Change Version" placement="bottom"><button onClick={changeArchitecture} className='newarchitecturebutton'>
            Move to {(JSON.parse(localStorage.getItem("newArchitecture")))? 'Old':'New'} Version</button></Tooltip>:""}
          
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            <div>
              <NttDropdown selectClassName={isPlanInputs?"usDivisionDropdown":"usDivisionDropdown-inverted"}
                formControlClassName={isPlanInputs?"usDivisionDropdown":"usDivisionDropdown-inverted"}
                color="primary"
                selectId="demo-simple-select"
                selectLabelId="demo-simple-select-label"
                selectValue="us"
              >
                <MenuItem value="us">US Division</MenuItem>
              </NttDropdown>
            </div>
            <div
              onClick={handleProfileMenuOpen} style={{width: "100%"}}>
              {/* Not needed by clients, but then can come back <span><img src={NotificationsNoneIcon} className='nofificationstyle'></img></span> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
              >
                <img src={UserIcon} className='usericonstyle' />
              </IconButton>
              {/* <span className={classes.pointerCursor}>{username}</span> */}
              <NttDropdown selectClassName={isPlanInputs?"usermenudropdown":"usermenudropdown-inverted"}
                formControlClassName={isPlanInputs?"usermenudropdown":"usermenudropdown-inverted"}
                color="primary"
                selectId="demo-simple-select"
                selectLabelId="demo-simple-select-label"
                selectValue="us"
              >
                <MenuItem value="us" className='username'>{`${username} (${roleName})`}</MenuItem>
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </NttDropdown>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <JobInProgressDialog open={jobInProgressDialogOpen} 
        dialogMessage={creationInProgress} title={creationInProgressTitle}>
    </JobInProgressDialog>
    <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
      <Alert
        onClose={handleCloseWarningSnackBar}
        severity={severity}>{warningMessage}
      </Alert>
    </Snackbar>
    {messageNotifications}
      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
    </div>
  );
}
