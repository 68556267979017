import React from 'react';
import AdminMultiGridUI from '../../../components/admin/grid/AdminMultiGridUI';


const CarClassGroupTabPanel = (props)=>{
    const{handleRowEditingStarted, handleRowEditingStopped,refreshCells,  handleCellEditingStopped,carClassGroupGridColDef,  handleMultipleRowDelete, 
        carClassGroupTransactions, carClassGroupGridReducer,handleAddNewDropdownValue} = props;

 return(
     <div>
         <AdminMultiGridUI 
            reducer={carClassGroupGridReducer}
            refreshCells={refreshCells}
            gridName={'carclassgroupgrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={carClassGroupGridColDef}
            suppressclickEdit={false}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            carClassGroupTransactions={carClassGroupTransactions}
            handleAddNewDropdownValue={handleAddNewDropdownValue}
             />
     </div>
 )
}

export default CarClassGroupTabPanel;