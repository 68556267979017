import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import './EditableFieldComponent.css';
import { TextFieldInput } from '../../../core-components/Input/Input';
import { Tooltip } from '@material-ui/core';
import { updateSalesAdjustmentChanges } from '../../../store/salesandfinance/SalesAndFinance.Actions';

function EditableFieldComponent(props) {
    const {adjustmentNode,index,activity,zone}=props;

    const [fieldValue,setFieldValue]=useState(adjustmentNode.requestedAdjustment);

    const dispatch=useDispatch();

    let isDisableEdit=false;
    let showErrorToolTip=false;

    function onAdjustmentValueChanged(event) {
        setFieldValue(event.target.value);
    }

    function onBlurSubmitValue(){
        dispatch(updateSalesAdjustmentChanges(activity,zone,index,fieldValue))
    }

    return (
        <div className={showErrorToolTip ? 'errorMsgStyle' :(isDisableEdit?'adjustmentinputfield adjustmentinputdisablefield':'adjustmentinputfield')}>
            <TextFieldInput
                type="number"
                id="BasicOutline"
                value={fieldValue}
                variant="outlined"
                defaultValue={fieldValue}
                inputDisabled={isDisableEdit}
                onTextChanged={(e) => onAdjustmentValueChanged(e)}
                handleOnBlur={()=>onBlurSubmitValue()}
            />
        </div>
    )
}

export default EditableFieldComponent;