import { columnDefs, defaultColDef } from "./salvageRate_grid_data/SalvageRateColumnDefs";
import { FETCH_SALVAGERATE_DROPDOWNVALUES_PENDING,FETCH_SALVAGERATE_DROPDOWNVALUES_SUCCESS,FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR, SET_SALVAGERATE_EDITABLE } from '../salvagerate/SalvageRate.ActionTypes';
import { checkDate, safeCaseInsensitiveCompare } from "../../utils/utils";
import { SalvageRateColumns, maximumDate, minimumDate } from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, SalvageRateColumns.ZoneName.headerName) },
            BU: { error: checkForBu(data.BU, SalvageRateColumns.BU.headerName) },
            SALVAGE_MIN_MILEAGE: { error: checkForMinMileage(data.SALVAGE_MIN_MILEAGE, SalvageRateColumns.SalvageMinMileage.headerName) },
            SALVAGE_PERCENTAGE: { error: checkForPercentage(data.SALVAGE_PERCENTAGE, SalvageRateColumns.SalvagePercentage.headerName) },
            MONTH: { error: checkForMonth(data.MONTH, SalvageRateColumns.Month.headerName) },
            ENGINE_TYPE: { error: checkForEngineType(data.ENGINE_TYPE, SalvageRateColumns.EngineType.headerName)  },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    if(value==='ALL'){
        return name + " cannot be ALL";
    }
    return null;
}

function checkForBu(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } 
    if(value==='ALL'){
        return name + " cannot be ALL";
    }
    return null;
}

function checkForPercentage(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } 
    if(value<0){
        return name + " cannot be less than zero";
    }
    if(value>1 && name === SalvageRateColumns.SalvagePercentage.headerName){
        return name + " cannot be greater than one";
    }
    return null;
}

function checkForMinMileage(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } 
    if(!(value%10000 == 0)){
        return name + " It is not 10k buckets";
    }
    if(value<0){
        return name + " cannot be less than 0";
    }
    return null;
}

function checkForQuarter(value, name) {
    // As per NEW REQUIREMENT : This column is not required, but kept for reference and for undoing
    // if (value === undefined || value === "" || value === null) {
    //     return name + " cannot be empty";
    // } 
    // if(value<1 || value>4){
    //     return name + " cannot be "+value;
    // }
    return null;
}

function checkForMonth(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(value == 'Invalid date'){
       return name +" "+"cannot be invalid date";
    }
    else if(!moment(value).isBetween(minimumDate, maximumDate,'year','[]')){
       return name + " should be in +5 or -10 range from the current year"
    }
    return null;
}

function checkForEngineType(value, name) {
    if (value === undefined || value === '' || value === null)
        return name + " cannot be empty";
    if (value !== 'ELECTRIC' && value !== 'ICE' )
        return name + " can be ICE or ELECTRIC only"; 
    return null;
}

function arrUnique(data,transactions) {
    // console.log("Starting Salvage Rate arrUnique...........");
    // let startDate=new Date();
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) { 
                if (safeCaseInsensitiveCompare(itm.ZONE_NAME , itm2.ZONE_NAME) && safeCaseInsensitiveCompare(itm.BU , itm2.BU) && safeCaseInsensitiveCompare(itm.ENGINE_TYPE ,itm2.ENGINE_TYPE) && checkDate(itm.MONTH,itm2.MONTH) && safeCaseInsensitiveCompare(itm.SALVAGE_MIN_MILEAGE,itm2.SALVAGE_MIN_MILEAGE) ){ 
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                } 
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            transactions.forEach((itm1,key)=>{
                if(itm1.data){
                  if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                    transactions[key].data.status=itm.status
                  }
                }
              })
    });

    //To gauge the time
    // console.log("delay time in ms=", ((new Date().getTime())-(startDate.getTime())));
    return duplicate;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData=[];
    zones.forEach(val => {
        zoneData.push(val.name)
        zoneFilterData.push({ "label": val.name, "value": val.name })
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"});

    element.cellEditorParams = { options: zoneData };
    element.filterOptions = zoneFilterData;
}

function fetchDropdownValueOthers(data, element) {
    let filterData=[];
    data.map((val)=>{
        filterData.push({"label":val,"value":val})
    })
    filterData.push({"label":"Blanks","value":"Blanks"});

    element.cellEditorParams = { options: data };
    element.filterOptions = filterData;
}

function fetchZones(salvageRateData, zones) {
    salvageRateData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return salvageRateData;
}

function fetchFilterValues( bu,zones,users,columns) {
    columns.forEach(element => {
        if (element.field === SalvageRateColumns.ZoneName.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field === SalvageRateColumns.BU.field) {
            fetchDropdownValueOthers(bu, element);
        }else if (element.field === SalvageRateColumns.Quarter.field) {
            fetchDropdownValueOthers(['1','2','3','4'], element);
        }else if (element.field === SalvageRateColumns.EngineType.field) {
            fetchDropdownValueOthers(['ELECTRIC','ICE'], element);
        }else if(element.field === SalvageRateColumns.UpdatedBy.field){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'SalvageRateActionRenderer'
            }
        }
    });

    return columns;
}

export default function salvageRateColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SALVAGERATE_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_SALVAGERATE_DROPDOWNVALUES_SUCCESS:
         //   const salvageRateData = fetchZones(action.data.salvageRateData, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.bu,
                    action.data.zone,action.data.users,state.columnDefs)
            });
        case FETCH_SALVAGERATE_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_SALVAGERATE_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}