import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import "./ErrorComponent.css"
import { useDispatch, useSelector } from 'react-redux';
import { setGridErrorViewStatus } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { Button } from '@material-ui/core';
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { CANNOT_CHANGE_DATASET_ERROR } from '../../Constants';
const ErrorComponent = ({isButtonRequired,isDisabled,changeErrorMode,hasPendingTransactions}) => {
    const dispatch=useDispatch();

    const gridErrorView = useSelector((state) => {
        return state.PaginationGridReducer.gridErrorView;
    });

    const gridName=useSelector((state)=>{
        return state.PaginationGridReducer.gridName
    });

    const switchErrorMode=useCallback((hasPendingTransactions)=>{
        if(hasPendingTransactions){
            dispatch(addNotificationMessage('error',CANNOT_CHANGE_DATASET_ERROR))
        }else{
            changeErrorMode(!gridErrorView[gridName]);
        }
        
    });

    return (
            <div className="primaryButton">
                {isButtonRequired?
                    <Button onClick={()=>switchErrorMode(hasPendingTransactions)} disabled={isDisabled} >
                        Show {gridErrorView[gridName]?'All':'Error'} Records
                    </Button>
                :''}
            </div>
    );
};

export default ErrorComponent;