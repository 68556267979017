import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './ConstraintCustomDialog.css';
import icon_close from "../../assets/images/close.svg";

const ConstraintCustomDialog = (props) => {
    const { open, handleClose, handlePositiveBtnClick, handleNegativeBtnClick, dialogContent, title, positiveBtnLbl, negativeBtnLbl } = props;

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="constraintcustomDialog"
            >
                <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                    <span>{title}</span>
                    <span><img src={icon_close} onClick={handleClose} className="closeicon" alt="iconclose"></img></span>
                </DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
                <DialogActions className="actionbuttons">
                    <Button onClick={(e) => handleNegativeBtnClick(e)} color="primary">
                        {negativeBtnLbl}
                </Button>
                    <Button onClick={(e) => handlePositiveBtnClick(e)} color="primary">
                        {positiveBtnLbl}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default ConstraintCustomDialog;