import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAllSchedulesData(planId,pageno) {
    try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseSchedulesResponse = await client.apis.plans.getPlanSchedule({ "planid": planId ? planId : 'master',pageno:pageno ? pageno:0 });
    const responsesArray = await Promise.all([promiseSchedulesResponse]);
    const [schedulesResponse] = responsesArray.map(o => o.body.data);
    // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": schedulesResponse.rows, "rowcount": schedulesResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
            return schedulesResponse;
        }
}
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Schedules tab'
        //return { 'Error': 'getAllSchedulesData failed' }
    }
}

export async function saveScheduleTransactions(planid, transactionList) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactschedule({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchSchedulesDropdownService() {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseGetAllModelGroups =  checkAndLoadDimention("modelGroup", client);
    const promiseGetCarClasses =  checkAndLoadDimention("carClassCode", client);
    const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    const promisegetAcceptableZones =  checkAndLoadDimention("acceptablezones", client);
    const promiseprogramtypeResponse =  checkAndLoadDimention("programType", client);
    const promiseAllManufacturergroupModelgroupResponse =  checkAndLoadDimention("AllManufacturergroupModelgroup", client);
    const promiseSPICodeResponse =  client.apis.dimensions.getAllSPICodes();
    const promiseGetAllAdminModelGroups =  checkAndLoadDimention("adminModelGroup", client);
    const promiseGetAdminCarClasses =  checkAndLoadDimention("admincarclassAndgroup", client);
    const promiseGetAdminManufacturerGroup =  checkAndLoadDimention("adminManufacturer", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([promiseGetAllModelGroups
        , promiseGetCarClasses
        , promisegetAcceptableZones
        , promiseprogramtypeResponse
        , promiseAllManufacturergroupModelgroupResponse
        , promiseSPICodeResponse
        , promiseGetManufacturerGroup
        , promiseGetAllAdminModelGroups
        , promiseGetAdminCarClasses
        , promiseGetAdminManufacturerGroup
    ]);

    const [modelgroups
        , carclasses
        , acceptableZones
        , programtypeResponse
        , allManufacturergroupModelgroups
        , spicodes
        , manufacturerGroups
        , adminModelgroups
        , adminCarClasses
        , adminManufacturerGroups
    ] = responsesArray.map((o,index) => (index!==4)?o.body.data:o);

    return ({
        "programType": programtypeResponse,"manufacturer": manufacturerGroups,
        "spiCode": spicodes, "carClassCode": carclasses,
        "modelGroup": modelgroups, "zone": acceptableZones,
        "manufacturerMapping": allManufacturergroupModelgroups,
        "adminModelGroup":adminModelgroups,
        "adminCarClass":adminCarClasses,
        "adminManufacturerGroup":adminManufacturerGroups,
        "users":promiseUserManagementEmailAndIdMap
    })

}

export async function uploadSchedulesCSV(planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadSchedule({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Schedules tab, Please try again!!')
    }
}

export async function insertS3UploadSchedulesCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.insertS3UploadedSchedulesData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi,''),
                "fileName": filename
            }
        });
        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Schedules tab, Please try again!!')
    }
}

export async function getScheduleFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseScheduleFilterResponse = await client.apis.plans.getScheduleFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseScheduleFilterResponse]);
        const [scheduleResponse] = responsesArray.map(o => o.body.data);

        return ({
            "gridData": scheduleResponse.rows, "rowcount": scheduleResponse.rows[0] ? scheduleResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'Schedules tab has internal server error, please do re-apply the filter'
        //return { 'Error': 'fetchSchedule failed' }
    }
}

export async function deleteAllSchedulesData(planid,params){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();
        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promisescheduleDeleteResponse = await client.apis.plans.deleteAllSchedulesRecords({ "planid": planid ? planid : 'master' }, { requestBody: { "updatedBy": userEmail, ...params } });
        if (promisescheduleDeleteResponse.obj && promisescheduleDeleteResponse.obj.statusCode !== '500') {
            return promisescheduleDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Schedules tab has internal server error, Unable to delete all the records!!Please try again'
    }
}

export async function getScheduleTabErrorStatus(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseScheduleResponse = await client.apis.plans.getScheduleTabErrorStatus({"planid":planid?planid:1});
    if(promiseScheduleResponse.obj && promiseScheduleResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseScheduleResponse]);
    const [scheduleTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return scheduleTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Schedule tab error status, Please try again!!';
   }
   }

   export async function scheduleTabValidationService(planid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.plans.validateSchedule({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate Schedule tab, Please try again!!'
    }
   }

   export async function getScheduleErrorData(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseScheduleErrorResponse = await client.apis.plans.getScheduleErrorRecords({"planid":planid?planid:1 ,'pageno':pageno});
        const responsesArray = await Promise.all([promiseScheduleErrorResponse]);
        const [ScheduleResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": ScheduleResponse.rows, "rowcount": ScheduleResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch error data in Schedules tab, Please try again!!'
     }
}

export async function getScheduleSearchData(planid, pageno, searchParams) {
    try {
            const accessToken = await getAccessTokenAfterAuth();
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
            const promiseScheduleSearchResponse = await client.apis.plans.getScheduleSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno},{requestBody: {"searchParams": searchParams }});
            const responsesArray = await Promise.all([promiseScheduleSearchResponse]);
            const [scheduleSearchResponse] = responsesArray.map(o => o.body.data);
            return ({
               "gridData": scheduleSearchResponse.rows,"rowcount":scheduleSearchResponse.rows[0]?scheduleSearchResponse.rows[0].rowcount:0
            });   
    }
    catch (error) {
        console.error(error);
        throw 'Unable to search data in Schedule tab, Please try again!!'
    }
}