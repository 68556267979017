import {
    dateFormatter,
    currencyFormatter,
    valueFormatter,
    localeFormatter,
    updatedOnDateFormatter
  } from "../../../utils/formatters/valueFormatter";
  import * as CONSTANTS from "../../../Constants";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";
  
  export const columnDefs = [
    {
      headerName: "",
      field: "",
      minWidth: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      editable: false,
      sortable: false,
      resizable: false,
      suppressColumnsToolPanel: true,
      flex: 0,
    },
    {
      field: CONSTANTS.MoveCostColumns.FROM_ZONE.field,
      headerName: CONSTANTS.MoveCostColumns.FROM_ZONE.headerName,
      unSortIcon: true,filterOptions:[],filterType:"dropdown",
      minWidth: 150,
      cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
      cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.FROM_ZONE.name),
      tooltipValueGetter: (params) => {
        return toolTipUI(params, CONSTANTS.MoveCostColumns.FROM_ZONE.name);
      },
    },
    { 
      field: CONSTANTS.MoveCostColumns.TO_ZONE.field,
      headerName:CONSTANTS.MoveCostColumns.TO_ZONE.headerName,
      cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
      minWidth: 150,filterOptions:[],filterType:"dropdown",
      unSortIcon: true,
      cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
      cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.TO_ZONE.name),
      tooltipValueGetter: (params) => {
        return toolTipUI(params, CONSTANTS.MoveCostColumns.TO_ZONE.name);
      },
    },
    {
        field: CONSTANTS.MoveCostColumns.FROM_POOL.field,
        headerName: CONSTANTS.MoveCostColumns.FROM_POOL.headerName,
        unSortIcon: true,
        minWidth: 150,filterOptions:[],filterType:"dropdown",
        cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
        cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.FROM_POOL.name),
        tooltipValueGetter: (params) => {
          return toolTipUI(params, CONSTANTS.MoveCostColumns.FROM_POOL.name);
        },
      },
      { 
        field: CONSTANTS.MoveCostColumns.TO_POOL.field,
        headerName:CONSTANTS.MoveCostColumns.TO_POOL.headerName,
        cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
        minWidth: 150,filterOptions:[],filterType:"dropdown",
        unSortIcon: true,
        cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
        cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.TO_POOL.name),
        tooltipValueGetter: (params) => {
          return toolTipUI(params, CONSTANTS.MoveCostColumns.TO_POOL.name);
        },
      },
    {
      field: CONSTANTS.MoveCostColumns.CAR_CLASS.field,
      headerName: CONSTANTS.MoveCostColumns.CAR_CLASS.headerName,
      cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
      minWidth: 150,filterOptions:[],filterType:"dropdown",
      unSortIcon: true,
      cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.CAR_CLASS.name),
      tooltipValueGetter: (params) => {
        return toolTipUI(params, CONSTANTS.MoveCostColumns.CAR_CLASS.name);
      },
      valueGetter: (params)=>{
          return params.data.CAR_CLASS
      },
      valueSetter:(params)=>{
       params.data.CAR_CLASS=params.newValue;
        if(params.newValue!=null){
          let allCarClassesCargroup =sessionStorage.getItem("CARCLASSANDGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("CARCLASSANDGROUP_CLEAN_MAP"))):[];
          let trimmedCarClasses=params.newValue.trim();
          params.data.CAR_GROUP=allCarClassesCargroup[trimmedCarClasses]?allCarClassesCargroup[trimmedCarClasses]:params.data.CAR_GROUP;
        } 
        return true
      }
      // cellEditorParams:(params)=>{
      //   let options =  getCarClassesBasedOnCarGroup(params);
      //   return options;
      // }
    },
    {    
        field: CONSTANTS.MoveCostColumns.CAR_GROUP.field,
        headerName:CONSTANTS.MoveCostColumns.CAR_GROUP.headerName,
        minWidth: 150,filterOptions:[],filterType:"dropdown",
        unSortIcon: true,
        cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
        cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.CAR_GROUP.name),
        tooltipValueGetter: (params) => {
          return toolTipUI(params, CONSTANTS.MoveCostColumns.CAR_GROUP.name);
        },
        cellEditorParams:(params)=>{
            let options =  getCarGroupBasedOnCarClasses(params);
            return options;
          }
      },
    {
      field: CONSTANTS.MoveCostColumns.MOVE_COST.field,
      headerName: CONSTANTS.MoveCostColumns.MOVE_COST.headerName,
      cellEditor: CONSTANTS.cellEditors.numericEditor,
      type: 'numericColumn',
      minWidth: 150,filterType:"input",
      unSortIcon: true,
      valueFormatter:params=>localeFormatter(params.data.MOVE_COST),
      cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.MOVE_COST.name),
      tooltipValueGetter: (params) => {
        return toolTipUI(params, CONSTANTS.MoveCostColumns.MOVE_COST.name);
      }
    },
    {
        field: CONSTANTS.MoveCostColumns.ALLOWED.field,
        headerName: CONSTANTS.MoveCostColumns.ALLOWED.headerName,
        minWidth: 110,filterOptions:[],filterType:"dropdown",
        unSortIcon: true,
        cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
        valueFormatter:params=>localeFormatter(params.data.ALLOWED),
        cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.ALLOWED.name),
        tooltipValueGetter: (params) => {
          return toolTipUI(params, CONSTANTS.MoveCostColumns.ALLOWED.name);
        }
      },
    {
      field: CONSTANTS.MoveCostColumns.BU.field,
      headerName: CONSTANTS.MoveCostColumns.BU.headerName,
      cellEditor: CONSTANTS.cellEditors.autoCompleteEditor,
      minWidth: 150,filterOptions:[],filterType:"dropdown",
      unSortIcon: true,
      cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.BU.name),
      tooltipValueGetter: (params) => {
        return toolTipUI(params, CONSTANTS.MoveCostColumns.BU.name);
      },
    },
    {
      field: CONSTANTS.MoveCostColumns.UpdatedBy.field, 
      headerName: CONSTANTS.MoveCostColumns.UpdatedBy.headerName, 
      editable: false, unSortIcon: true,filterOptions:[],
      cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.UpdatedBy.name), 
      valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
      comparator: customUpdatedByComparator,
      tooltipValueGetter: params => {
          return toolTipUI(params, CONSTANTS.MoveCostColumns.UpdatedBy.name)
      }
  },
  {
      field: CONSTANTS.MoveCostColumns.UpdatedOn.field, 
      headerName: CONSTANTS.MoveCostColumns.UpdatedOn.headerName, 
      editable: false,
      valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
      unSortIcon: true, cellClassRules: cellClassRules(CONSTANTS.MoveCostColumns.UpdatedOn.name), 
      comparator: customDateComparator,
      tooltipValueGetter: params => {
          return toolTipUI(params, CONSTANTS.MoveCostColumns.UpdatedOn.name)
      }
  },
    {
      headerName: "",
      colId: "actions",
      cellRenderer: "MoveCostActionRenderer",
      editable: false,
      filter: false,
      sortable: false,
      minWidth: 120,
      pinned: "right",
    },
  ];
  
  function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field]) {
      if (params.data.validations && params.data.validations[field].error !== null) {
          return params.data.validations[field].error
      } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
          return "Duplicate row";
      }
  }
  }
  
  function cellClassRules(field) {
    return {
      errorCell: (params) => {
        if (params.data.validations && params.data.validations[field]) {
          return params.data.validations[field].error !== null;
        }
      },
      validRow: (params) => {
        if (params.data.validations && params.data.colDef) {
          var index = params.data.colDef.findIndex(function (item, i) {
            return item.id === params.data.ID && item.value === field;
          });
          return index > -1 && params.data.validations[field].error === null;
        }
      },
    };
  }

  function getCarClassesBasedOnCarGroup(params){    
    let allCargroupCarClasses =sessionStorage.getItem("ALLCARGROUPCARCLASS_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLCARGROUPCARCLASS_CLEAN_MAP"))):[];
    let trimmedCarGroup=params.data.CAR_GROUP?params.data.CAR_GROUP.trim():'';
    let options=allCargroupCarClasses[trimmedCarGroup]?allCargroupCarClasses[trimmedCarGroup]:[];
    return {'options':options};
  }
  function getCarGroupBasedOnCarClasses(params){
    let allCarClassesCargroup =sessionStorage.getItem("CARCLASSANDGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("CARCLASSANDGROUP_CLEAN_MAP"))):[];
    let trimmedCarClasses=params.data.CAR_CLASS?params.data.CAR_CLASS.trim():""
    let options=allCarClassesCargroup[trimmedCarClasses]?allCarClassesCargroup[trimmedCarClasses]:""
    return {'options':[options]}
  }
  export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
  };
  