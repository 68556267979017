import React from "react";
import { useSelector } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';

const LinearProgressIndicator = () => {

    const pending = useSelector((state) => {
        // return state.rootProgramsReducer.columnDefinitionReducer.pending;
        for (var x in state) {
            for (var y in state[x]) {
                for (var z in state[x][y]) {
                    if (z === 'pending' && state[x][y][z]) {
                        return true;
                    }
                }
            }
        }
        return false;
    });
    const GridPending = useSelector((state) => {
        return state.PaginationGridReducer.pending
    })
    
    return (
        <div className="progressWrapper">{(pending || GridPending) ? <LinearProgress color="secondary" /> : <span></span>}</div>
    )
}
export default React.memo(LinearProgressIndicator)