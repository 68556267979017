import {getCoreDemandData,getCoreDemandDropdownService,saveTransactions,uploadCoreDemandCSV} from '../../../../services/revenuefunction/mainrfinputs/coredemand/CoreDemandService';
import { SetRFError, SetRFPending } from '../../RevenueFunction.Actions';
import {FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_ERROR, FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_PENDING, FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_SUCCESS } from './CoreDemand.ActionTypes';

export const fetchCoreDemandDropDownValues = ()=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_PENDING));
            let coreDemandDropdownData = await getCoreDemandDropdownService();
            if (coreDemandDropdownData) {
                dispatch(serviceActionSuccess(FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_SUCCESS, coreDemandDropdownData));
            } else {
                dispatch(serviceActionError(FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_ERROR, coreDemandDropdownData));
            }
        } catch (e) {
            console.log("Failed to get Core Demand drop down values due to : ", e.message);
            dispatch(serviceActionError(FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_ERROR,e.message));
            dispatch(SetRFError("Unknown error"));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}