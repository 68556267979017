import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import icon_delete from "../../assets/images/icon_trash.svg";
import  "./AccordionView.css";
import { deleteProgramsRow } from "../../store/programs/Programs.Actions";
import DialogBox from "../Dialogcomponent/DialogComponent";
import {valueFormatter, dateFormatter, currencyFormatter} from "../../utils/formatters/valueFormatter";

    const ProgramsAccordionContent = (props) => {
    const { data,planid } = props;
    const [open, setOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const dispatch = useDispatch();
    let message = "Are you sure that you want to delete this row?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";

    function handleClickOpen(data) {
        setSelectedRowData(data);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function deleteSelectedRow() {
        try {
            dispatch(deleteProgramsRow(planid, selectedRowData.ID));
            handleClose()
        } catch (err){
		console.error('got error while deleting data');
        }
    };

    if (data != undefined && data.length && data.length > 0) {
        return (
            <div>
                {data[0].map((content, index) => {
                    return (
                        <div className="indent contenticons" key={index}>
                            <span className="contentpanel">
                                <span className="modalgroup"><span className="modalatext">{content.MODEL_GROUP}</span><span className="carcode">{content.MODEL_YEAR}  {content.CAR_CLASS_CODE}</span></span>
                                <span><span className="datetext">{dateFormatter(content.BEGIN_DATE)} - {dateFormatter(content.END_DATE)}</span><span className="zone">{content.ACCEPTABLE_ZONES}</span></span>
                                <span className="divideline"></span>
                                <span className="volumestyle"><span className='volume'><label className="volumetext">Volume:&nbsp;</label><span className="mileage">{valueFormatter(content.ADD_VOLUME_MIN)} - {valueFormatter(content.ADD_VOLUME_MAX)}</span></span><span className="zone"><label className="volumetext">Capital:&nbsp;</label>{currencyFormatter(content.NET_CAPITAL_COST, "$")}</span></span>
                                <span className="volumestyle"><span className='volume'><label className="volumetext">Age:&nbsp;</label><span className="mileage">{content.MIN_AGE} - {content.MAX_AGE}</span></span><span className="zone"><label className="volumetext">Dep:&nbsp;</label>{currencyFormatter(content.DEPRECIATION_COST, "$")}</span></span>
                                <span className="volumestyle"><span className='volume'><label className="volumetext">Miles:&nbsp;</label><span className="mileage">{valueFormatter(content.MIN_MILEAGE)} - {valueFormatter(content.MAX_MILEAGE)}</span></span><span className="zone"><label className="volumetext">Incentive:&nbsp;</label>{currencyFormatter(content.INCENTIVE, "$")}</span></span>
                                <span className="volumestyle"><span className='volume'><label className="volumetext">Start Miles:&nbsp;</label><span className="mileage">{valueFormatter(content.START_MILEAGE)}</span></span></span>
                            </span>
                            <span onClick={() => handleClickOpen(content)}><img src={icon_delete}></img>
                            </span>
                            <div>
                                <DialogBox open={open} handleClose={handleClose} handleYes={deleteSelectedRow}
                                    dialogMessage={message} title={title} action={deletetext} styles={"styles"} 
                                    canceltext={canceltext}
                                    >
                                </DialogBox>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return (
            <div></div>
        )

    }


}
export default ProgramsAccordionContent;