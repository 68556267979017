import {cellEditors,ManualVinDeletesColumns} from '../../../Constants';
import { formatDateToOne, updatedOnDateFormatter } from '../../../utils/formatters/valueFormatter';
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from '../../../utils/utils';

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: ManualVinDeletesColumns.Vin.field, headerName: ManualVinDeletesColumns.Vin.headerName, unSortIcon: true,
        cellClassRules: cellClassRules(ManualVinDeletesColumns.Vin.name),
        cellEditor:cellEditors.TextFieldEditor,
        filterType:"dropdown",filterOptions:[],
        tooltipValueGetter: params => {
            return toolTipUI(params,ManualVinDeletesColumns.Vin.name)
        },comparator: customComparator,
    },
    {
        field: ManualVinDeletesColumns.DelMonth.field, headerName: ManualVinDeletesColumns.DelMonth.headerName, 
        cellEditor: cellEditors.dateEditor,filterType:"datepicker",
        valueFormatter: params => formatDateToOne(params.data.MONTH),
        unSortIcon: true,cellClassRules: cellClassRules(ManualVinDeletesColumns.DelMonth.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ManualVinDeletesColumns.DelMonth.name)
        }
    },
    {
        field: ManualVinDeletesColumns.UpdatedBy.field, 
        headerName: ManualVinDeletesColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(ManualVinDeletesColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ManualVinDeletesColumns.UpdatedBy.name)
        }
    },
    {
        field: ManualVinDeletesColumns.UpdatedOn.field, 
        headerName: ManualVinDeletesColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(ManualVinDeletesColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ManualVinDeletesColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer:'ManualVinDeletesActionRenderer',
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

function customComparator(valueA, valueB) {
    if(valueA===null && valueB===null){
        return true;
    }else if(valueA===null || valueB===null){
        return false;
    }else{
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
}

export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
