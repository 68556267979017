import React, { useEffect } from 'react';
import UpgradePathsActionBar from "../../components/upgradepathsactionbar/UpgradePathsActionBar";
import MultiGridUI from "../../components/grid/MultiGridUI";
import UpgradepathAccordionContainer from '../../containers/accordionview/UpgradepathAccordionContainer';
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const UpgradePathsTabPanel = (props) => {
    const { getSelectedUpgradeButton, templateGridReducer, templatesGridColDef, handleRowEditingStarted, upgradeButtonSelected,
        tabInfo, selectedval, upgradePathData, currentView, tabIndex, upgradePathfilterSearchData, upgradePathFilterSearchFlag, handleRowEditingStopped,
        handleSwitchView, upgradePathGridReducer, upgradePathColumnDef, showSaveOption, handleSaveClicked, handleCancelClicked, refreshCells,
        SelectedButtonIndex, handleAddNewRow, handleCellEditingStopped, handleSaveCancelDisabled, upgradePathRowDataInvalid, deleteSelectedRows, bulkRowDeleteIconDisabled, selectedRows, handleMultipleRowDelete,
        isFiltered,setIsFiltered,gridReducer,upgradePathsGridColDef,gridName,pending,newArchitecture,isSearchBarValueExists,setIsSearchBarValueExists,userMessage,downloadCSV,selectedPlanId,loadGridData } = props;

        return (
        <div>
              {newArchitecture ? ((gridName === 'upgradepathsGrid') ? 
              <MainPlanInputsMultiGridUI reducer={gridReducer}
                gridName={gridName}
                //currentView={currentView}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={upgradePathsGridColDef}
                rowEditType={'single'}
                suppressclickEdit={!upgradePathsGridColDef.defaultColDef.editable||pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={true} /> :
                <MainPlanInputsMultiGridUI
                    key={'templatekey'}
                    reducer={gridReducer}
                    selectedPlanId={selectedPlanId}
                    gridColDef={templatesGridColDef}
                    gridName={gridName}
                    onRowEditingStarted={handleRowEditingStarted}
                    onRowEditingStopped={handleRowEditingStopped}
                    showSaveOption={showSaveOption}
                    refreshCells={refreshCells}
                    onCellEditingStopped={handleCellEditingStopped}
                    rowEditType={'fullRow'}
                    suppressclickEdit={!templatesGridColDef.defaultColDef.editable ||pending}
                    loadGridData={loadGridData}
                    userMessage={userMessage}
                /> ) : (<>
            <UpgradePathsActionBar
                upgradePathData={upgradePathData}
                getSelectedUpgradeButton={getSelectedUpgradeButton}
                upgradeButtonSelected={upgradeButtonSelected}
                actionView={upgradeButtonSelected == 'UPGRADE TEMPLATES' ? 'grid' : currentView}
                onViewChanged={handleSwitchView}
                showSaveOption={showSaveOption}
                onSaveClicked={handleSaveClicked}
                onCancelClicked={handleCancelClicked}
                onAddNewRowClick={handleAddNewRow}
                handleSaveCancelDisabled={handleSaveCancelDisabled}
                upgradePathRowDataInvalid={upgradePathRowDataInvalid}
                bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                deleteSelectedRows={deleteSelectedRows}
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                isSearchBarValueExists={isSearchBarValueExists}
                setIsSearchBarValueExists={setIsSearchBarValueExists}
                templatesGridColDef={templatesGridColDef}
                upgradePathColumnDef={upgradePathColumnDef}
                downloadCSV={downloadCSV}
                selectedPlanId={selectedPlanId}
            ></UpgradePathsActionBar>
            {upgradeButtonSelected == 'UPGRADE TEMPLATES' ?
                <MultiGridUI
                    key={'templatekey'}
                    reducer={templateGridReducer}
                    gridColDef={templatesGridColDef}
                    gridName={"upgradetemplatesgrid"}
                    onRowEditingStarted={handleRowEditingStarted}
                    onRowEditingStopped={handleRowEditingStopped}
                    showSaveOption={showSaveOption}
                    refreshCells={refreshCells}
                    onCellEditingStopped={handleCellEditingStopped}
                    rowEditType={'fullRow'}
                    suppressclickEdit={!templatesGridColDef.defaultColDef.editable}
                    userMessage={userMessage}
                />
                :
                (currentView === "grid") ?
                    (
                        <MultiGridUI
                            key={'pathkey'}
                            reducer={upgradePathGridReducer}
                            gridColDef={upgradePathColumnDef}
                            gridName={"upgradepathsgrid"}
                            currentView={currentView}
                            onRowEditingStarted={handleRowEditingStarted}
                            onRowEditingStopped={handleRowEditingStopped}
                            showSaveOption={showSaveOption}
                            refreshCells={refreshCells}
                            onCellEditingStopped={handleCellEditingStopped}
                            rowEditType={'single'}
                            suppressclickEdit={!upgradePathColumnDef.defaultColDef.editable}
                            handleMultipleRowDelete={handleMultipleRowDelete}
                        />
                    ) :
                    (<UpgradepathAccordionContainer
                        data={upgradePathFilterSearchFlag == true ? upgradePathfilterSearchData : upgradePathData} tabIndex={tabIndex} gridName={"upgradepathsgrid"}
                        buttonSelected={upgradeButtonSelected} selectedTab={tabInfo.Title}
                        currentView={currentView} selectedval={selectedval} >
                    </UpgradepathAccordionContainer>)}
       </>
                 )} </div>

    )
}

export default UpgradePathsTabPanel;