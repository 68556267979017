import { useDispatch, useSelector } from "react-redux";
import { ADJLEVEL, filterCat, FilterDropdownOptions, TYPE_ZONE_STR, ZONE_TYPE_STR } from "../Constants";
import { setCargoFilterParameters, setCoreElecFilterParameters, setCoreIceFilterParameters, setCoreTabFilterParameters, setTabAllFilterParameters, setTncElecFilterParameters, setTncFilterParameters, setTncIceFilterParameters } from "../store/adjustments/Adjustments.Actions";
import { localeFormatAndRounding } from "./formatters/valueFormatter";

export const isDisableEditing = (bu, activity_type, engine_group) => {

    switch (bu) {
        case 'CORE':
            if (activity_type == 'ADDS' ||
                activity_type == 'REPURCHASE_DELETES' ||
                activity_type == 'CORE_TO_TNC' ||
                activity_type == 'CORE_TO_CS') {
                if (engine_group == 'ICE' || engine_group == 'ELECTRIC') {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        case 'TNC':
            if (activity_type == 'ADDS' ||
                activity_type == 'CORE_TO_TNC' ||
                activity_type == 'TNC_TO_CS') {
                if (engine_group == 'ICE' || engine_group == 'ELECTRIC') {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        case 'CARGO':
            if (activity_type == 'ADDS' ||
                activity_type == 'CARGO_TO_CS') {
                return false;
            }
            else {
                return true;
            }

        case 'ALL': // TOREVIEW: All other BUs are editable
            break;
        default: return false;
    }
}

/**
 * private function to create an empty zone details template
 * @param {*} zoneTemplate is sample zone object 
 * @param {*} zone is the new zone details (name and code)
 * @returns an empty zone template that is compatible to display on UI components
 */
function createZoneTemplate(zoneTemplate, zone) {
    let items = zoneTemplate.map(a => { return { ...a } })
    let newZone = [];
    newZone.push(zone.name);

    items.map((child) => {
        child.value = 0;
        child.originalValue = 0;
        child.adjustment = 0;
        child.zone_name = zone.name;
        child.zone_code = zone.code;
    });
    newZone.push(items);
    return newZone;
}

/**
 * 
 * @param {*} groupedData is the zones list for that activity 
 * @param {*} zones is all the available zones fetched from backend. 
 */
export const fillEmptyZones = (groupedData, zones) => {
    // get available zones in the input data
    let availableZones = groupedData.map((item) => {
        return item[0];
    });
    //loop through all the zones and check if each zone is included in ${availableZones}
    zones.forEach((item) => {
        if (!availableZones.includes(item.name) && item.name != 'ALL') {
            // create the object and push to groupedData
            let zone = createZoneTemplate(groupedData[0][1], item);
            groupedData.push(zone);
        }
    });

}


/**
 * Method to safely return activity data array.
 * @param {*} aggregatedArray is the activity data array to display an activity type
 * @param {*} aggregatedEndfleetArray is endfleet array for that bu
 * @param {*} zones is the list of all zones fetched from backend
 * @param {*} activity_type is the activity_type name eg., ADDS, SALVAGE
 * @returns ${aggregatedArray[0]} if not empty or not undefined. else returns a activity_type template filled with all zones and values as 0 
 */
export const safeActivityTemplate = (aggregatedArray, aggregatedEndfleetArray, zones, activity_type, bu, engine_group) => { //TODO TOREVIEW Remove dependency on aggregatedEndfleetArray

    if (aggregatedArray && aggregatedArray.length > 0) {
        return aggregatedArray[0]
    }

    let sampleChild = getSampleChild(aggregatedEndfleetArray);
    let sampleValue = getSampleValue(aggregatedEndfleetArray);
    return fillEmptyActivity(sampleChild, sampleValue, zones, activity_type, bu, engine_group);
}

function fillEmptyActivity(sampleChild, sampleValue, zones, activity_type, bu, engine_group) {
    let title = ["Requested Adjustment", "Requested Value", "Original Value"]
    let children = [];
    //loop through all the zones and create empty zone templates
    zones.forEach(element => {
        let newZone = JSON.parse(JSON.stringify(sampleChild));
        newZone.header = element.name;
        newZone.content.map((child) => {
            child.value = 0;
            child.originalValue = 0;
            child.adjustments = 0;
            child.zone_name = element.name;
            child.zone_code = element.code;
            child.activity_type = activity_type;
            child.bu = bu;
            child.engine_group = engine_group;
        });
        newZone.values[0].adjustments.map((child) => {
            child.value = 0;
            child.activity_type = activity_type;
            child.engine_group = engine_group;
            child.bu = bu;
            child.zone_code = element.code;
        });

        newZone.values[0].currentValues.map((child) => {
            child.value = 0;
            child.activity_type = activity_type;
            child.engine_group = engine_group;
            child.bu = bu;
            child.zone_code = element.code;
        });

        newZone.values[0].originalValues.map((child) => {
            child.value = 0;
            child.activity_type = activity_type;
            child.engine_group = engine_group;
            child.bu = bu;
            child.zone_code = element.code;
        });

        children.push(newZone);
    });

    let values = [];
    let value = JSON.parse(JSON.stringify(sampleValue));
    value.currentValues.map((child) => {
        child.value = 0;
        child.activity_type = activity_type;
        child.engine_group = engine_group;
        child.bu = bu;
    });

    value.adjustments.map((child) => {
        child.value = 0;
        child.activity_type = activity_type;
        child.engine_group = engine_group;
        child.bu = bu;
    });

    value.originalValues.map((child) => {
        child.value = 0;
        child.activity_type = activity_type;
        child.engine_group = engine_group;
        child.bu = bu;
    });

    values.push(value);
    var activityTypeData = {
        header: `${activity_type}`,
        children: children,
        values: values,
        title: title
    }

    return activityTypeData;
}

function getSampleChild(aggregatedEndfleetArray) {
    return JSON.parse(JSON.stringify(aggregatedEndfleetArray[0].children[0]));
}

function getSampleValue(aggregatedEndfleetArray) {
    return JSON.parse(JSON.stringify(aggregatedEndfleetArray[0].values[0]));
}

export const checkFilterParams = (val, filterParams, currentHeader,selectedVal,filterData) => {

    let isActivityMatches = false, isZoneMatches = false, isVarianceMatches = false, isRequestedValueMatches = false, isOriginalValueMatches = false, isReqAdjustmentMatches = false;
    let isActivityProvided = false, isZoneProvided = false, isVarianceProvided = false, isRequestedValueProvided = false, isOriginalValueProvided = false, isReqAdjustmentProvided = false;
    
    

    if(selectedVal===TYPE_ZONE_STR){
        if(val.children){//Top Level
            if (filterParams && filterParams.activity_type.length > 0) {
                isActivityProvided=true;
                // console.log("filterParams.activity_type===",filterParams.activity_type);
                isActivityMatches = filterParams.activity_type.some(function (value) {
                    // console.log("value.label & val.header==",value.label,val.header );
                    return (value.label === val.header);
                });
            }

            if(filterParams.zones.length > 0 ){//
                isZoneProvided=true;
                if(val.children && val.children.some(child=>filterParams.zones.some(value=> value.label === child.header))){
                    isZoneMatches=true;
                }
            }

            let shoulCheckChildren=true;
            let shoulCheckParent=true;
            if(isZoneProvided && !isZoneMatches){
                shoulCheckChildren=false;
            }
            if(isZoneProvided && isZoneMatches){
                shoulCheckParent=false;
            }

            if (filterParams && ((filterParams.variance.length > 1) || (filterParams.variance[0] === "notzero")) ) {
                isVarianceProvided=true;
                isVarianceMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].adjVarValues.some(adjVar=>checkFilterParameterValue(adjVar,filterParams.variance)))
                                    || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isZoneProvided && filterParams.zones.some(value=> value.label === child.header)) || !isZoneProvided) && child.values[0].adjVarValues.some(adjVar=>checkFilterParameterValue(adjVar,filterParams.variance))));
            }
            if (filterParams && ((filterParams.requestedValue.length > 1) || (filterParams.requestedValue[0] === "notzero")) ) {
                isRequestedValueProvided=true;
                isRequestedValueMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].fnlValues.some(fnlVal=>checkFilterParameterValue(fnlVal,filterParams.requestedValue)))
                || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isZoneProvided && filterParams.zones.some(value=> value.label === child.header)) || !isZoneProvided) && child.values[0].fnlValues.some(fnlVal=>checkFilterParameterValue(fnlVal,filterParams.requestedValue))));;
            }
            if (filterParams && ((filterParams.originalValue.length > 1) || (filterParams.originalValue[0] === "notzero")) ) {
                isOriginalValueProvided=true;
                isOriginalValueMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].originalValues.some(orgVar=>checkFilterParameterValue(orgVar,filterParams.originalValue)))
                || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isZoneProvided && filterParams.zones.some(value=> value.label === child.header)) || !isZoneProvided) && child.values[0].originalValues.some(orgVar=>checkFilterParameterValue(orgVar,filterParams.originalValue))));
            }
            if (filterParams && ((filterParams.requestedAdjustment.length > 1) || (filterParams.requestedAdjustment[0] === "notzero"))) {
                isReqAdjustmentProvided=true;
                isReqAdjustmentMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].adjustments.some(adjustment=>checkFilterParameterValue(adjustment,filterParams.requestedAdjustment)))
                || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isZoneProvided && filterParams.zones.some(value=> value.label === child.header)) || !isZoneProvided) && child.values[0].adjustments.some(adjustment=>checkFilterParameterValue(adjustment,filterParams.requestedAdjustment))));
            }

            let finalDecision=true;
            let isFiltered=false;

            if(isVarianceProvided){
                isFiltered=true;
                finalDecision=finalDecision && isVarianceMatches;
            }
            if(isRequestedValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isRequestedValueMatches;
            }
            if(isOriginalValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isOriginalValueMatches;
            }
            if(isReqAdjustmentProvided){
                isFiltered=true;
                finalDecision=finalDecision && isReqAdjustmentMatches;
            }
            if(isZoneProvided){
                isFiltered=true;
                finalDecision=finalDecision && isZoneMatches;
            }
            if(isActivityProvided ){
                isFiltered=true;
                finalDecision=finalDecision && isActivityMatches;
            }


            if(!isFiltered){
                finalDecision=false;
            }

            return finalDecision;
        }else{//val.children===null ===> 2nd layer
            if(filterParams.zones.length > 0){
                isZoneProvided=true;
                if(filterParams.zones.some(value=> value.label === val.header)){
                    isZoneMatches=true;
                }
            }

            if (filterParams && ((filterParams.variance.length > 1) || (filterParams.variance[0] === "notzero")) ) {
                isVarianceProvided=true;
                isVarianceMatches = (val.values && val.values.length>0 && ((isZoneProvided && filterParams.zones.some(value=> value.label === val.header)) || !isZoneProvided) &&  val.values[0].adjVarValues.some(adjVar=>checkFilterParameterValue(adjVar,filterParams.variance)));
            }
            if (filterParams && ((filterParams.requestedValue.length > 1) || (filterParams.requestedValue[0] === "notzero")) ) {
                isRequestedValueProvided=true;
                isRequestedValueMatches = (val.values && val.values.length>0 && ((isZoneProvided && filterParams.zones.some(value=> value.label === val.header)) || !isZoneProvided) && val.values[0].fnlValues.some(fnlVal=>checkFilterParameterValue(fnlVal,filterParams.requestedValue)));
            }
            if (filterParams && ((filterParams.originalValue.length > 1) || (filterParams.originalValue[0] === "notzero")) ) {
                isOriginalValueProvided=true;
                isOriginalValueMatches = (val.values && val.values.length>0 && ((isZoneProvided && filterParams.zones.some(value=> value.label === val.header)) || !isZoneProvided) && val.values[0].originalValues.some(orgVar=>checkFilterParameterValue(orgVar,filterParams.originalValue)));
            }
            if (filterParams && ((filterParams.requestedAdjustment.length > 1) || (filterParams.requestedAdjustment[0] === "notzero"))) {
                isReqAdjustmentProvided=true;
                isReqAdjustmentMatches = (val.values && val.values.length>0 && ((isZoneProvided && filterParams.zones.some(value=> value.label === val.header)) || !isZoneProvided) && val.values[0].adjustments.some(adjustment=>checkFilterParameterValue(adjustment,filterParams.requestedAdjustment)));
            }

            

            let finalDecision=true;
            let isFiltered=false;

            if(isVarianceProvided){
                isFiltered=true;
                finalDecision=finalDecision && isVarianceMatches;
            }
            if(isRequestedValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isRequestedValueMatches;
            }
            if(isOriginalValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isOriginalValueMatches;
            }
            if(isReqAdjustmentProvided){
                isFiltered=true;
                finalDecision=finalDecision && isReqAdjustmentMatches;
            }
            if(isZoneProvided){
                isFiltered=true;
                finalDecision=finalDecision && isZoneMatches;
            }
            if(isActivityProvided ){
                isFiltered=true;
                finalDecision=finalDecision && isActivityMatches;
            }


            if(!isFiltered){
                finalDecision=false;
            }

            return finalDecision;
        }
    }else{//ZONE_TYPE_STR
        if(val.children){//Top Level
            
            if(filterParams && filterParams.zones.length > 0 ){//
                isZoneProvided=true;
                if(filterParams.zones.some(value=> value.label === val.header)){
                    isZoneMatches=true;
                }
            }

            if(filterParams && filterParams.activity_type.length > 0 ){//
                isActivityProvided=true;
                if(val.children && val.children.some(child=>filterParams.activity_type.some(value=> value.label === child.header))){
                    isActivityMatches=true;
                }
            }

            let shoulCheckChildren=true;
            let shoulCheckParent=true;
            if(isActivityProvided && !isActivityMatches){
                shoulCheckChildren=false;
            }
            if(isActivityProvided && isActivityMatches){
                shoulCheckParent=false;
            }

            if (filterParams && ((filterParams.variance.length > 1) || (filterParams.variance[0] === "notzero")) ) {
                isVarianceProvided=true;
                isVarianceMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].adjVarValues.some(adjVar=>checkFilterParameterValue(adjVar,filterParams.variance)))
                                    || (shoulCheckChildren && isZoneMatches && val.children && val.children.length>0 && val.children.some(child=>((isActivityProvided && filterParams.activity_type.some(value=> value.label === child.header)) || !isActivityProvided) && child.values[0].adjVarValues.some(adjVar=>checkFilterParameterValue(adjVar,filterParams.variance))));
            }
            if (filterParams && ((filterParams.requestedValue.length > 1) || (filterParams.requestedValue[0] === "notzero")) ) {
                isRequestedValueProvided=true;
                isRequestedValueMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].fnlValues.some(fnlVal=>checkFilterParameterValue(fnlVal,filterParams.requestedValue)))
                || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isActivityProvided && filterParams.activity_type.some(value=> value.label === child.header)) || !isActivityProvided) && child.values[0].fnlValues.some(fnlVal=>checkFilterParameterValue(fnlVal,filterParams.requestedValue))));;
            }
            if (filterParams && ((filterParams.originalValue.length > 1) || (filterParams.originalValue[0] === "notzero")) ) {
                isOriginalValueProvided=true;
                isOriginalValueMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].originalValues.some(orgVar=>checkFilterParameterValue(orgVar,filterParams.originalValue)))
                || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isActivityProvided && filterParams.activity_type.some(value=> value.label === child.header)) || !isActivityProvided) && child.values[0].originalValues.some(orgVar=>checkFilterParameterValue(orgVar,filterParams.originalValue))));
            }
            if (filterParams && ((filterParams.requestedAdjustment.length > 1) || (filterParams.requestedAdjustment[0] === "notzero"))) {
                isReqAdjustmentProvided=true;
                isReqAdjustmentMatches = (shoulCheckParent && val.values && val.values.length>0 && val.values[0].adjustments.some(adjustment=>checkFilterParameterValue(adjustment,filterParams.requestedAdjustment)))
                || (shoulCheckChildren && val.children && val.children.length>0 && val.children.some(child=>((isActivityProvided && filterParams.activity_type.some(value=> value.label === child.header)) || !isActivityProvided) && child.values[0].adjustments.some(adjustment=>checkFilterParameterValue(adjustment,filterParams.requestedAdjustment))));
            }

            let finalDecision=true;
            let isFiltered=false;

            if(isVarianceProvided){
                isFiltered=true;
                finalDecision=finalDecision && isVarianceMatches;
            }
            if(isRequestedValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isRequestedValueMatches;
            }
            if(isOriginalValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isOriginalValueMatches;
            }
            if(isReqAdjustmentProvided){
                isFiltered=true;
                finalDecision=finalDecision && isReqAdjustmentMatches;
            }
            if(isZoneProvided){
                isFiltered=true;
                finalDecision=finalDecision && isZoneMatches;
            }
            if(isActivityProvided ){
                isFiltered=true;
                finalDecision=finalDecision && isActivityMatches;
            }


            if(!isFiltered){
                finalDecision=false;
            }

            return finalDecision;
        }else{//val.children===null ===> 2nd layer

            if(filterParams.activity_type.length > 0){
                isActivityProvided=true;
                if(filterParams.activity_type.some(value=> value.label === val.header)){
                    isActivityMatches=true;
                }
            }

            if (filterParams && ((filterParams.variance.length > 1) || (filterParams.variance[0] === "notzero")) ) {
                isVarianceProvided=true;
                isVarianceMatches = (val.values && val.values.length>0 && ((isActivityProvided && filterParams.activity_type.some(value=> value.label === val.header)) || !isActivityProvided) && val.values[0].adjVarValues.some(adjVar=>checkFilterParameterValue(adjVar,filterParams.variance)));
            }
            if (filterParams && ((filterParams.requestedValue.length > 1) || (filterParams.requestedValue[0] === "notzero")) ) {
                isRequestedValueProvided=true;
                isRequestedValueMatches = (val.values && val.values.length>0 && ((isActivityProvided && filterParams.activity_type.some(value=> value.label === val.header)) || !isActivityProvided) && val.values[0].fnlValues.some(fnlVal=>checkFilterParameterValue(fnlVal,filterParams.requestedValue)));
            }
            if (filterParams && ((filterParams.originalValue.length > 1) || (filterParams.originalValue[0] === "notzero")) ) {
                isOriginalValueProvided=true;
                isOriginalValueMatches = (val.values && val.values.length>0 && ((isActivityProvided && filterParams.activity_type.some(value=> value.label === val.header)) || !isActivityProvided) && val.values[0].originalValues.some(orgVar=>checkFilterParameterValue(orgVar,filterParams.originalValue)));
            }
            if (filterParams && ((filterParams.requestedAdjustment.length > 1) || (filterParams.requestedAdjustment[0] === "notzero"))) {
                isReqAdjustmentProvided=true;
                isReqAdjustmentMatches = (val.values && val.values.length>0 && ((isActivityProvided && filterParams.activity_type.some(value=> value.label === val.header)) || !isActivityProvided) && val.values[0].adjustments.some(adjustment=>checkFilterParameterValue(adjustment,filterParams.requestedAdjustment)));
            }


            

            let finalDecision=true;
            let isFiltered=false;

            if(isVarianceProvided){
                isFiltered=true;
                finalDecision=finalDecision && isVarianceMatches;
            }
            if(isRequestedValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isRequestedValueMatches;
            }
            if(isOriginalValueProvided){
                isFiltered=true;
                finalDecision=finalDecision && isOriginalValueMatches;
            }
            if(isReqAdjustmentProvided){
                isFiltered=true;
                finalDecision=finalDecision && isReqAdjustmentMatches;
            }
            if(isZoneProvided){
                isFiltered=true;
                finalDecision=finalDecision && isZoneMatches;
            }
            if(isActivityProvided ){
                isFiltered=true;
                finalDecision=finalDecision && isActivityMatches;
            }


            if(!isFiltered){
                finalDecision=false;
            }

            return finalDecision;
        }
    }
}

export function checkFilterParameterValue(ipValue, ipParams){//value=0, filterDetails=['between', '1000', '2000']
    let isCondtionTrue=false;
    let value=0;


    try{        
        if(ipValue===null || ipValue===undefined || ipParams===null || ipParams===undefined){            
            return false;
        }
        if (ipParams && (ipParams.length > 1|| ipParams[0]==='notzero')) {

            let tempValue=checkType(ipValue);
            if(isNaN(tempValue)){
                if(tempValue.indexOf(',')>-1){
                    tempValue=tempValue.replace(',','');
                    if(isNaN(tempValue)){
                        return false;
                    }else{
                        value=Math.round(parseFloat(tempValue));
                    }
                }else{
                    return false;
                }
            }else{
                value=Math.round(tempValue);
            }
            
            let params=[];
            // console.log("ipParams===",ipParams);
            ipParams.forEach((element,idx)=>{
                if(idx===0){
                    params.push(element);
                }else{
                    // console.log("element,params===",element,params);
                    if(element!==null && element!=='' && element!==NaN){
                        if(isNaN(element)){
                            params.push(parseFloat(element.replace(',','')));
                        }else{
                            params.push(parseFloat(element));
                        }
                    }
                }
            });

            switch(params[0]){ //NOTE: == is used so that datatype check does not result in a false
                case 'equal':
                    isCondtionTrue= params.length>1 && params[1]==value;
                    break;
                case 'between':
                    isCondtionTrue= params.length>2 && ((params[1]<value && value<params[2]) || params[1]==value || params[2]==value);
                    // console.log("in between==",isCondtionTrue,value,params );
                    break;
                case 'less':
                    isCondtionTrue= params.length>1 && value<params[1];
                    break;
                case 'greater':
                    isCondtionTrue= params.length>1 && value>params[1];
                    break;
                case 'notzero':
                    isCondtionTrue= params.length>0 && value!=0;
                    break;
                default:
                    isCondtionTrue= false;
            }
        }else{
            isCondtionTrue=false;
        }
    }catch(err){
        console.log("CAUGHT ERROR=",err );
        isCondtionTrue=false;
    }
    // console.log("isCondtionTrue=",isCondtionTrue,ipValue, ipParams );
    return isCondtionTrue;
}

export function checkType(obj) {
    let value;
    if (typeof obj == 'object')
        value = obj.value;
    else
        value = obj;

    return value;
}



//This function is used in Zone>Type to disable/enable the activity_types
export const ZoneViewAdjDisable = (adjlevel) => {
    let endFleetAdj, sectionFleetAdj
    if (adjlevel === ADJLEVEL.BU) {
        //bulevel->endfleetcomponent false and sectioncomponent true 
        endFleetAdj = false
        sectionFleetAdj = true
    } else if (adjlevel === ADJLEVEL.ZONE) {
        //zonelevel->endfleetcomponent true and sectioncomponent false
        endFleetAdj = true
        sectionFleetAdj = false
    } else if (adjlevel === ADJLEVEL.NONE) {
        //nolevel->endfleetcomponent false and sectioncomponent false
        endFleetAdj = false
        sectionFleetAdj = false
    }
    return { endFleetAdj, sectionFleetAdj }
}

export const willExpand=(tabIndex,isExpanded, timeOfExpansion, expansionMode, val,filterParams,filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue,currentHeader,selectedVal,tempFilterArray,filterData,isTopLevel,parentValHeader, setIsExpanded, setExpansionMode)=>{
// export const willExpand=(tabIndex,isExpanded, timeOfExpansion, expansionMode, val,filterParams,currentHeader,selectedVal,filterData,isTopLevel, setIsExpanded, setExpansionMode)=>{    
        // console.log("Filter activity==",filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue);

        // console.log("val====",val);
        let expandSection = false;

        let expandAllBean=useSelector((state)=>{
            if(selectedVal==="Type>Zone"){
                return state.rootAdjustmentsReducer.adjustmentsReducer.expandAll_type_zone[tabIndex];
            }else{
                return state.rootAdjustmentsReducer.adjustmentsReducer.expandAll_zone_type[tabIndex];
            }
        }) ;

        let accordianClicksBean=useSelector((state)=>state.rootAdjustmentsReducer.adjustmentsReducer.accordianClicks);
        let accordianClicks=[];
        if(selectedVal==="Type>Zone"){
            accordianClicks= accordianClicksBean.type_zone[tabIndex];
        }else{
            accordianClicks= accordianClicksBean.zone_type[tabIndex];
        }
        
        let hdrString=val.header+(parentValHeader?"-"+parentValHeader:'');
        

        let headers=accordianClicks.headers;
        let expansionModeMarker="";
        let timeOfAccordianExpansion=new Date();
        let isItExpanded=false;
        if(headers.some(str=>str===hdrString)){
            expansionModeMarker="CLICK";
            timeOfAccordianExpansion=accordianClicks.dateTime;
            isItExpanded=true;
        }

        if(filterParams!==null && filterParams.isFiltered===true){
            
                expandSection=false;//collapse
                //During "SET_ADJUSTMENTS_FILTER_PARAMS" action, expandAllFlag is reset to 0
                expandSection=checkFilterParams(val,filterParams,currentHeader, selectedVal,filterData);
                // expandSection=checkFilterParams(val,filterParams,filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue,currentHeader); 
                
                if(expansionModeMarker==="CLICK" && timeOfAccordianExpansion && Date.parse(timeOfAccordianExpansion)>Date.parse(filterParams.dateTime)){
                    expandSection=!expandSection;
                }
        }else{
                
                if(expandAllBean.value===1){ 
                    expandSection=false;//collapse 
                }else if(expandAllBean.value===2){
                    if(isTopLevel){
                        expandSection=true;//expand 
                    }else{
                        expandSection=false;//expand 
                    }
                    
                }else {//expandAllFlag===0
                    expandSection=false;//collapse 
                }
                // console.log("Date.parse(timeOfAccordianExpansion)====",Date.parse(timeOfAccordianExpansion));
                // console.log("Date.parse(expandAllBean.dateTime)====",Date.parse(expandAllBean.dateTime));
                if(expansionModeMarker==="CLICK" && timeOfAccordianExpansion && Date.parse(timeOfAccordianExpansion)>Date.parse(expandAllBean.dateTime)){
                    expandSection=!expandSection;
                }
        }

        return expandSection;
}

export const selectFilterDataSectionFromStore=(tabIndex,viewType)=>{
    let tempFilterArray=useSelector((state)=>{
        
        let filterParams=null;
        switch(parseInt(tabIndex,10)){
            case 0: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.tabAllfilterParams;break;
            case 1: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.coreBuFilterParams;break;
            case 2: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.coreIceBuFilterParams;break;
            case 3: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.coreElecBuFilterParams;break;
            case 4: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.tncBuFilterParams;break;
            case 5: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.tncIceBuFilterParams;break;
            case 6: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.tncElecBuFilterParams;break;
            case 7: filterParams=state.rootAdjustmentsReducer.adjustmentsReducer.cargoBuFilterParams;break;
            default: 
                console.log("Invalid Tab ! tabIndex===",tabIndex);
                return null;
        };

        if(filterParams){
            if(viewType==="Type>Zone"){
                return filterParams.type_zone;
            }else{
                return filterParams.zone_type;
            }
        }else{
            console.log("Invalid filterParams !");
        }

    });

    // console.log("Filter Params from redux: ", tempFilterArray," for tabIndex= ",tabIndex);
    return  tempFilterArray;
};

export const setFilterDataSectionInStore= (tempFilterArray, tabIndex, viewType) => {
        // console.log("Setting Filter Params.......")
        // console.log("In loadFilterParams tempFilterArray=",tempFilterArray);
        try {

            switch(parseInt(tabIndex,10)){
                case 0: return (tempFilterArray,viewType)=>setTabAllFilterParameters(tempFilterArray,viewType);
                case 1: return (tempFilterArray,viewType)=>setCoreTabFilterParameters(tempFilterArray,viewType);
                case 2: return (tempFilterArray,viewType)=>setCoreIceFilterParameters(tempFilterArray,viewType);
                case 3: return (tempFilterArray,viewType)=>setCoreElecFilterParameters(tempFilterArray,viewType);
                case 4: return (tempFilterArray,viewType)=>setTncFilterParameters(tempFilterArray,viewType);
                case 5: return (tempFilterArray,viewType)=>setTncIceFilterParameters(tempFilterArray,viewType);
                case 6: return (tempFilterArray,viewType)=>setTncElecFilterParameters(tempFilterArray,viewType);
                case 7: return (tempFilterArray,viewType)=>setCargoFilterParameters(tempFilterArray,viewType);
                default: 
                    console.log("Invalid Tab ! tabIndex==",tabIndex);
                    return null;
            };
        } catch (err) {
          console.error('got error while loading filterparams');
        }
};

export function customCloneObject(obj){
    return JSON.parse(JSON.stringify(obj));
}

export function getClonedFilterCatObject(){
    return customCloneObject(filterCat);
}

export const checkIfFreezMonth = (year,month,freezeCpd) =>{
 if(freezeCpd && freezeCpd.length>0){
    let cpdValue={};
    freezeCpd.filter((params)=>{
        if(params.key==='CPD'){
            if(params.value){
            let value =  params.value.split('_')[0];
            cpdValue['year'] = value.substr(0,4);
            if((value.substr(4,1))=='0'){
             cpdValue['month'] = value.substr(5,1);
            }else{
                cpdValue['month'] = value.substr(4,2);
            }
          }
        }
    });
    if(cpdValue.year && cpdValue.month){
        if(year == cpdValue.year){
            if(month < cpdValue.month){
                return true;
            }else{
                return false
            }
        }else{
            if(year < cpdValue.year){
                return true;
            }else{
                return false;
            }
        }
    } else {
        return false
    }
}

}