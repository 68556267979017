import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import EditableAllCellsInAYearComponent from './EditableAllCellsInAYearComponent';
import EditIcon from "../../../assets/images/icon_edit.svg";
import { Divider, Button } from '@material-ui/core';
import { ScrollSyncPane } from 'react-scroll-sync';
import { setAdjustmentAction, setAdjustmentLevel, updateAdjustments } from '../../../store/adjustments/Adjustments.Actions';
import { isDisableEditing } from "../../../utils/AdjustmentsUtils";
import { ADJLEVEL } from "../../../utils/constants";
import {setEditAdjustmentsStatus,loadPlanDataById} from '../../../store/topbar/AllPlans.Actions';
import AllCellsInAYearComponent from "./AllCellsInAYearComponent";
const EditableRowComponent = (props) => {
    const{activityNode,accordionSummary,plansDataById,activity,zone}=props;

    if(activityNode.isEditEnabled){
        return activityNode.nodes.map((node, ix) => {
            return <EditableAllCellsInAYearComponent adjustmentNode={node} index={ix} key={`${activity}_${ix}`} accordionSummary={accordionSummary} plansDataById={plansDataById}  activity={activity} zone={zone}/>
        });
    }else{
        return activityNode.nodes.map((node, ix) => {
            return <AllCellsInAYearComponent adjustmentNode={node} index={ix} key={`${activity}_${ix}`} accordionSummary={accordionSummary} plansDataById={plansDataById} />
        });
    }
}

export default EditableRowComponent;
