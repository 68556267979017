import React, { useEffect, useCallback, useState, useRef, useContext, useLayoutEffect } from 'react';
import './InputsPage.css';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import NttTab from "../../components/tabs/NttTab"
import NttTabs from "../../components/tabs/NttTabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { deleteProgramsRow, fetchProgramData, fetchProgramDropdownValues, saveTransactionData, resetPrograms } from "../../store/programs/Programs.Actions";
import { addNewRow, populateGrid, validateRow, refreshGridData, cleanTransactions, validateGrid, deleteRow, deleteRowEditMode } from '../../store/MultiGrid/MultiGrid.Actions';
import ProgramsTabPanel from "../programs/ProgramsTabPanel";
import ConstraintsTabPanel from "../constraints/ConstraintsTabPanel";
import ParametersTabPanel from "../parameters/ParametersTabPanel";
import UpgradePathsTabPanel from "../upgradepaths/UpgradePathsTabPanel";
import SchedulesTabPanel from "../schedules/SchedulesTabPanel";
import AllocatedTabPanel from "../allocated/AllocatedTabPanel";
import PlanAssumptionsTabPanel from '../plan_assumptions/PlanAssumptionsTabPanel';
import MoveConstraintsTabPanel from '../moveconstraints/MoveConstraintsTabpanel';
import { fetchConstraintData, fetchConstraintsOptions, fetchConstriantByType, saveConstraintsTransactions, resetConstraints } from "../../store/constraints/Constraints.Actions";
import { getParameters, saveParametersTransactions, fetchParametersDropDownData } from "../../store/parameters/Parameters.Actions";
import { fetchUpgradeTemplates, saveTemplateTranctions } from "../../store/upgradepaths/UpgradeTemplates.Actions";
import { fetchUpgradePaths, fetchUpgradeCarClassgroup, saveUpgradePathTranctions } from "../../store/upgradepaths/UpgradePaths.Actions";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { fetchSchedulesData, fetchSchedulesDropdownValues, saveSchedulesTransactionData, resetSchedules } from "../../store/schedules/Schedules.Actions";
import { fetchAllocatedData, fetchAllocatedDropdownValues, saveAllocatedTransactionData, resetAllocated } from "../../store/allocated/Allocated.Actions";
import {fetchAgeMileageData,saveAgeMileageTransactionData, fetchAgeMileageDropdownValues, resetAgeMileage} from '../../store/agemileage/AgeMileage.Actions';
import {fetchAdditionalCostData,saveAdditionalCostTransactionData,fetchAdditionalCostDropdownValues,resetAdditionalCost} from '../../store/additionalcost/AdditionalCost.Actions';
import {fetchMileageDefaultData,saveMileageDefaultTransactionData,fetchMileageDefaultDropdownValues,resetMileageDefault} from '../../store/mileagedefault/MileageDefault.Actions';
import {fetchAddsInvalidPoolsData,saveAddsInvalidPoolsTransactionData,fetchAddsInvalidPoolsDropdownValues,resetAddsInvalidPools} from '../../store/addsinvalidpools/AddsInvalidPools.Actions';
import { SetAssumptionIndex } from '../../store/planassumptions/PlanAssumptions.Actions';
import { fetchMaxDeletesData, fetchMaxDeletesDropdownValues, resetMaxDeletes } from '../../store/maxdeletes/MaxDeletes.Actions';
import { fetchSalvageRateData, fetchSalvageRateDropdownValues, resetSalvageRate } from '../../store/salvagerate/SalvageRate.Actions';
import { fetchTargetZonePoolData, fetchTargetZonePoolDropdownValues, resetTargetZonePool } from '../../store/targetzonepool/TargetZonePool.Actions';
import { fetchZoneMapData, fetchZoneMapDropdownValues, resetZoneMap } from '../../store/zonemap/ZoneMap.Actions';
import { fetchMileageTableData, fetchMileageTableDropdownValues, resetMileageTable } from '../../store/mileagetable/MileageTable.Actions';
import {fetchMoveConstraintsData, fetchMoveConstraintDropdownValues, saveMoveConstraintsTransactions, resetMoveConstraints} from '../../store/moveconstraints/MoveConstraints.Actions';
import { userAcs } from '../../App';
import {fetchDepRateData, fetchDepRateDropDownValues, resetDepRate} from '../../store/deprate/DepRate.Actions';
import { fetchFilterDisplayData, fetchMoveCostData, fetchMoveCostDropdownValues, resetMoveCost } from '../../store/movecost/MoveCost.Actions';
import { ADDITIONAL_COST, ADDS_INVALID_POOLS, AGE_MILEAGE, ALLOCATED, CONSTRAINT, DEP_RATE, DIVERSIONS, MAX_DEL_ZONE, MILEAGE_DEFAULT, MILEAGE_TABLE, MOVE_CONSTRAINT, MOVE_COST, PARAMETERS, planInputSubTablesData, planInputTabData as tabsData, PROGRAM, SALVAGE_RATE, SCHEDULE, TARGET_ZONE, UPGRADE_PATH, ZONE_MAP } from '../../Constants';
import { fetchDiversionsData, fetchDiversionsDropdownValues, resetDiversions } from '../../store/diversions/Diversions.Actions';
import { isPlanningStatusRunning } from '../../utils/utils';
import { UpdateMasterDataCache, updatePlanEditDisabled } from '../../store/topbar/AllPlans.Actions';
import {fetchManualVinDeletesData,fetchManualVinDeletesDropdownValues,fetchManualVinDeletesFilterDisplayData, resetManualVinDeletes} from '../../store/manualvindeletes/ManualVinDeletes.Actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function InputsPage(props) {
  const { selectedPlanId, saveUpdatedResponse} = props;
  const [value, setValue] = useState(0);
  let [showSaveOption, setShowSaveOption] = useState(false);
  let [isSaveClicked, setIsSaveClicked] = useState(null);
  let [isCancelClicked, setIsCancelClicked] = useState(false);
  const [refreshCells, setRefreshCells] = useState(false);
  const [selectedval, setSelectedval] = useState('Type>Zone');
  const [constraintType, setConstraintType] = useState('ALL');
  let [currentView, setCurrentView] = useState("grid");
  // const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [snackBarIfError, setSnackBarIfError] = useState(false);
  let selectedurlHasMainInputs = window.location.href.indexOf("maininputs") > -1;
  const [upgradeButtonSelected, setSelectedUpgradeButton] = useState('UPGRADE TEMPLATES');
  const [SelectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [saveCancelDisabled, setSaveCancelDisabled] = useState(false);
  const [bulkRowDeleteIconDisabled, setBulkRowDeleteIconDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [scrollToTop, setScrollToTop] = useState(false);
  const defuserAcs =  useContext(userAcs);
  const [isFiltered,setIsFiltered]=useState(false);
  const [isSearchBarValueExists,setIsSearchBarValueExists]=useState(false);
  //Plan Edit Disabled useSelector
  let ifPlanEditDisabled = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
  });

  let message = "The records contains one or more errors. Do you want to fix them?"
  let title = "Error";
  let oktext = "Fix them";
  let canceltext = "Cancel";

  // console.log("isFiltered==",isFiltered);
  // console.log("isSearchBarValueExists==",isSearchBarValueExists);

  const dispatch = useDispatch();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
 
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleCloseWarningSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningSnackBarOpen(false);
  };

  
  const copyToMainInputsStatus=useSelector((state)=>{
    // console.log("copyToMainInputsStatus=useSelector(state)==",state);
    let copyPlanToMainInputsInProgress=state.rootAllPlansReducer.allPlansReducer.copyPlanToMainInputsInProgress;
    let tabsToBeCopiedToMain=state.rootAllPlansReducer.allPlansReducer.tabsToBeCopiedToMain;
    // console.log("copyPlanToMainInputsInProgress=useSelector(state)==",copyPlanToMainInputsInProgress);
    // console.log("tabsToBeCopiedToMain=useSelector(state)==",tabsToBeCopiedToMain);
    if(copyPlanToMainInputsInProgress){
      return tabsToBeCopiedToMain;
    }else{
      return [];
    }
  });
  const moveCostPageNo = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostpageno;
        });

  const moveCostDisableButton = useSelector((state) => {
      return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostUploadStatus;
        });
  
  const manualVinDeletesData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesData;
  });

  const manualVinDeletesRowDataInvalid = useSelector((state)=>{
    
    let errorIDs=state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.uniqueErrorIDs;
    let errorIdPresent=errorIDs && errorIDs.length>0;
    return errorIdPresent||state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.manualVinDeletesGridReducer.isAnyRowInvalid;
  })
  const manualVinDeletesPageNo = useSelector((state)=>{
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesPageNo;
  })
  const manualVinDeletesTransactions = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.manualVinDeletesGridReducer.transactions;
  });
  const manualVinDeletesGridColumnDefReducer = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.manualvindeletesColumnDefinitionReducer;
  });
  const manualVinDeletesGridReducer = useSelector((state)=>{
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.manualVinDeletesGridReducer;
  })
  const manualVinDeletesFilterDisplayData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.filterDisplayData;
  })

  const programTransactionSaveSuccessful = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.transactionSaveSuccess;
  })
  const constraintsWholeData = useSelector((state)=>{
    return state.rootConstraintsReducer.constraintsReducer.constraintsWholeData
  })
  const constraintTransactionSaveSuccessful = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.transactionSaveSuccess;
  })

  const constraintAccordionTransactionSaveSuccessful = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.accordionTransactionSaveSuccess;
  })

  const constraintsUploadState = useSelector((state)=>{
    return state.rootConstraintsReducer.constraintsReducer.constraintsUploadState;
  })

  const selectedConstraintType = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.constraintType;
  })

  const parametersTransactionSaveSuccessful = useSelector((state) => {
    return state.rootParametersReducer.parametersReducer.transactionSaveSuccess;
  })

  const templatesTransactionSaveSuccessful = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesReducer.transactionSaveSuccess;
  })

  const pathTransactionSaveSuccess = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathReducer.pathTransactionSaveSuccess;
  })

  const gridReducer = useSelector((state) => {
    return { ...state.rootProgramsReducer.programsGridReducer };
  });

  const contraintsGridReducer = useSelector((state) => {
    return { ...state.rootConstraintsReducer.constraintsGridReducer };
  });

  const parametersGridReducer = useSelector((state) => {
    return { ...state.rootParametersReducer.parametersGridReducer };
  });

  const programsTransactions = useSelector((state) => {
    return state.rootProgramsReducer.programsGridReducer.transactions
  });

  const constraintTransactions = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsGridReducer.transactions;
  });

  const parametersTransactions = useSelector((state) => {
    return state.rootParametersReducer.parametersGridReducer.transactions;
  });

  const constraintRowDataInvalid = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsGridReducer.isAnyRowInvalid;
  });

  const programsRowDataInvalid = useSelector((state) => {
    return state.rootProgramsReducer.programsGridReducer.isAnyRowInvalid;
  });

  const parametersRowDataInvalid = useSelector((state) => {
    return state.rootParametersReducer.parametersGridReducer.isAnyRowInvalid;
  });

  const upgradePathRowDataInvalid = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathGridReducer.isAnyRowInvalid;
  });

  const upgradeTemplateRowDataInvalid = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesGridReducer.isAnyRowInvalid;
  });
  const ageMileageRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer.isAnyRowInvalid;
  });
  const additionalCostRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer.isAnyRowInvalid;
  });
  const addsInvalidPoolsRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer.isAnyRowInvalid;
  });
  const maxDeletesRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer.isAnyRowInvalid;
  });
  const mileageDefaultRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer.isAnyRowInvalid;
  });
  const moveCostRowDataInvalid = useSelector((state) => {
    // console.log("state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer==>",state);    
    // let uniqueIds=!!(state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs);
    // console.log("IN moveCostRowDataInvalid Selector in InputsPage: uniqueIds==>",uniqueIds);
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer.isAnyRowInvalid || (state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs && state.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs.length>0);
  });
  const salvageRateRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer.isAnyRowInvalid;
  });
  const targetZonePoolRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer.isAnyRowInvalid;
  });
  const depRateRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer.isAnyRowInvalid;
  });
  const mileageTableRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer.isAnyRowInvalid;
  });
  const diversionsRowDataInvalid = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer.isAnyRowInvalid;
  });
  /* Moved to Metadata
  // const zoneMapRowDataInvalid = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapGridReducer.isAnyRowInvalid;
  // });
  */

  //Arrow function to check if theres any error in the plan assumptions grid
  const planAssumptionDataInvalid = () => {
    if(ageMileageRowDataInvalid || ageMileageData.length<1 || additionalCostRowDataInvalid || additionalCostData.length<1 || addsInvalidPoolsRowDataInvalid || addsInvalidPoolsData.length<1 || maxDeletesRowDataInvalid || maxDeletesData.length<1 || mileageDefaultRowDataInvalid || mileageDefaultData.length<1 || moveCostRowDataInvalid || movecostGrandTotalRowCount<1 || salvageRateRowDataInvalid || salvageRateData.length<1 || targetZonePoolRowDataInvalid || depRateRowDataInvalid || depRateData.length<1 || mileageTableRowDataInvalid || mileageTableData.length<1 || diversionsRowDataInvalid || manualVinDeletesRowDataInvalid){
      return true;
    }
    else{
      return false;
    }
  }



  // If plan status is running should return to later disable editing 
  const programgridcoldef = useSelector((state) => {
    // copyToMainInputsStatus
    // console.log("state.rootProgramsReducer.columnDefinitionReducer===",state.rootProgramsReducer.columnDefinitionReducer);
    let colDef=state.rootProgramsReducer.columnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===PROGRAM) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  })
   // If plan status is running should return to later disable editing 
  const constraintsGridColDef = useSelector((state) => {
    // console.log("state.rootConstraintsReducer.constraintsColumnDefReducer===",state.rootConstraintsReducer.constraintsColumnDefReducer);
    let colDef=state.rootConstraintsReducer.constraintsColumnDefReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===CONSTRAINT) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  })
  // If plan status is running should return to later disable editing
  const parametersGridColDef = useSelector((state) => {
    // return state.rootParametersReducer.parametersColumnDefReducer;
    // console.log("state.rootParametersReducer.parametersColumnDefReducer===",state.rootParametersReducer.parametersColumnDefReducer);
    let colDef=state.rootParametersReducer.parametersColumnDefReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===PARAMETERS) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  })

  var programData = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.programs;
  });

  const programsUploadSuccess = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.uploadProgramsSuccess;
  });

  var filterSearchData = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.filterSearchData;
  });

  var filterFlag = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.filterFlag;
  });

  var programFilterSearchKey = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.programFilterSearchKey;
  })

  //Moving to Action Bar JS
  const programsFilterParams = useSelector((state) => {
    return state.rootProgramsReducer.programsReducer.filterParams;
  });

  var constraintsData = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.constraints;
  });

  var parametersData = useSelector((state) => {
    return state.rootParametersReducer.parametersReducer.parameters;
  });

  var programDropdownData = useSelector((state) => {
    return state.rootProgramsReducer.columnDefinitionReducer.dropdownValues;
  });

  var constraintsDropdownData = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsColumnDefReducer.dropdownValues;
  });

  var constraintFilterSearchKey = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.constraintFilterSearchKey;
  })

  var constrainttypes = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.constrainttypes;
  });

  const constraintsUploadSuccess = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.uploadConstraintsSuccess;
  });

  const constraintsFilterParams = useSelector((state) => {
    return state.rootConstraintsReducer.constraintsReducer.filterParams;
  });

  var upgradeTemplatesData = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesReducer.templates;
  });

  var templatesGridReducer = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesGridReducer;
  });

  const templatesGridColumnDef = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesColumnDefReducer;
  })

  var templateFilterSearchFlag = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesReducer.templateFilterSearchFlag;
  });

  var templateFilterSearchKey = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesReducer.templateFilterSearchKey;
  });

  var templateFilterSearchData = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesReducer.templateFilterSearchData;
  });

  var upgradePathData = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathReducer.upgradePathData;
  });

  var upgradePathFilterSearchFlag = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathReducer.upgradePathFilterSearchFlag;
  });

  var upgradePathSearchKey = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathReducer.upgradePathSearchKey;
  });

  var upgradePathFilterSearcData = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathReducer.upgradePathFilterSearcData;
  })

  var upgradePathGridReducer = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathGridReducer;
  });
  // If plan status is running should return to later disable editing
  const upgradePathColumnDef = useSelector((state) => {
    // return state.rootUpgradePathsReducer.upgradePathsColumnDefReducer;
    let colDef=state.rootUpgradePathsReducer.upgradePathsColumnDefReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===UPGRADE_PATH) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  })
  const upgradeZoneTransactions = useSelector((state) => {
    return state.rootUpgradeTemplatesReducer.templatesGridReducer.transactions;
  });
  const upgradePathTransactions = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathGridReducer.transactions;
  });

  const upgradePathCarGroupData = useSelector((state) => {
    return state.rootUpgradePathsReducer.upgradePathsColumnDefReducer.upgradePathCarGroupData;
  })
  // If plan status is running should return to later disable editing
  const schedulesGridColDef = useSelector((state) => {
    // return state.rootSchedulesReducer.schedulesColumnDefinitionReducer;
    let colDef=state.rootSchedulesReducer.schedulesColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===SCHEDULE) || ifPlanEditDisabled ){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  })

  const schedulesData = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesReducer.schedules;
  });

  const schedulesUploadSuccess = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesReducer.uploadSchedulesSuccess;
  });

  const schedulesGridReducer = useSelector((state) => {
    return {...state.rootSchedulesReducer.schedulesGridReducer};
  });

  const schedulesRowDataInvalid = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesGridReducer.isAnyRowInvalid;
  });

  const scheduleTransactionSaveSuccessful = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesReducer.transactionSaveSuccess;
  })

  const scheduleTransactions = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesGridReducer.transactions;
  });

  const schedulesFilterData = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesReducer.data;
  });

  const SchedulesFilterParams = useSelector((state) => {
    return state.rootSchedulesReducer.schedulesReducer.filterParams;
  });
  // If plan status is running should return to later disable editing
  const allocatedGridColDef = useSelector((state) => {
    // console.log("state.rootAllocatedReducer.allocatedColumnDefinitionReducer===",state.rootAllocatedReducer.allocatedColumnDefinitionReducer);
    let colDef=state.rootAllocatedReducer.allocatedColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===ALLOCATED) || ifPlanEditDisabled ){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  })

  const allocatedData = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedReducer.allocated;
  });

  const allocatedUploadSuccess = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedReducer.allocatedUploadSuccess;
  });

  const allocatedGridReducer = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedGridReducer;
  });

  const allocatedRowDataInvalid = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedGridReducer.isAnyRowInvalid;
  });

  const allocatedTransactionSaveSuccessful = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedReducer.transactionSaveSuccess;
  })

  const allocatedDropdownValues = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedColumnDefinitionReducer.dropdownValues;
  })

  const allocatedFilterData = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedReducer.data;
  });

  const allocatedFilterParams = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedReducer.filterParams;
  });

  const allocatedTransactions = useSelector((state) => {
    return state.rootAllocatedReducer.allocatedGridReducer.transactions;
  })

  const ageMileageGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer };
  });
  // If plan status is running should return to later disable editing
  const ageMileageGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageColumnDefinitionReducer;
    // console.log("state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageColumnDefinitionReducer===",state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageColumnDefinitionReducer);
    let colDef=state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===AGE_MILEAGE) || ifPlanEditDisabled ){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const ageMileageData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAgeMileageReducer.AgeMileageReducer.ageMileageData;
  });

  const additionalCostGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer };
  });
  // If plan status is running should return to later disable editing
  const additionalCostGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===ADDITIONAL_COST) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const additionalCostData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.AdditionalCostReducer.additionalCostData;
  });

  const mileageDefaultGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer };
  });
  // If plan status is running should return to later disable editing
  const mileageDefaultGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===MILEAGE_DEFAULT) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const mileageDefaultData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.MileageDefaultReducer.mileageDefaultData;
  });
  const maxDeletesGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer };
  });
  // If plan status is running should return to later disable editing
  const maxDeletesGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===MAX_DEL_ZONE) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const diversionsData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDiversionsReducer.DiversionsReducer.diversionsData;
  });

  const diversionsGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer };
  });
  // If plan status is running should return to later disable editing
  const diversionsGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===DIVERSIONS) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const maxDeletesData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.MaxDeletesReducer.maxDeletesData;
  });

  const depRateData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootDepRateReducer.DeprateReducer.depRateData;
  });

  const depRateGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer };
  });
  // If plan status is running should return to later disable editing
  const depRateGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===DEP_RATE) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const salvageRateGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer };
  });
  // If plan status is running should return to later disable editing
  const salvageRateGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===SALVAGE_RATE) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const salvageRateData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootSalvageRateReducer.SalvageRateReducer.salvageRateData;
  });

  const targetZonePoolGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer };
  });
  // If plan status is running should return to later disable editing
  const targetZonePoolGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===TARGET_ZONE) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const targetZonePoolData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.TargetZonePoolReducer.targetZonePoolData;
  });

  const zoneRfData= useSelector((state)=>{
    return state.rootAllPlansReducer.allPlansReducer.zoneRfData;
  })

  const poolRfData= useSelector((state)=>{
    return state.rootAllPlansReducer.allPlansReducer.poolRfData;
  })
  /* Moved To Metadata
  // const zoneMapGridReducer = useSelector((state) => {
  //   return { ...state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapGridReducer };
  // });
  //
  // const zoneMapGridColDef = useSelector((state) => {
  //   // return state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapColumnDefinitionReducer;
  //   let colDef=state.rootPlanAssumptionsReducer.rootZoneMapReducer.zoneMapColumnDefinitionReducer;
  //   if(copyToMainInputsStatus.some(tabName=>tabName===ZONE_MAP) || ifPlanEditDisabled){
  //       colDef.defaultColDef.editable=false;
  //   }else{
  //       colDef.defaultColDef.editable=true;
  //   }
  //   return colDef;
  // });

  // const zoneMapData = useSelector((state) => {
  //   return state.rootPlanAssumptionsReducer.rootZoneMapReducer.ZoneMapReducer.zoneMapData;
  // }); 
  */

  const mileageTableGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer };
  });
  // If plan status is running should return to later disable editing
  const mileageTableGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===MILEAGE_TABLE) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const mileageTableData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMileageTableReducer.MileageTableReducer.mileageTableData;
  });
  const moveCostGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer };
  });
    // If plan status is running should return to later disable editing
  const moveCostGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===MOVE_COST) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const moveCostData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostData;
  });

  const movecostGrandTotalRowCount = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostGrandTotalRowCount;
  });

  const moveCostErrorView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostErrorView;
  });



  const movecostFilterDisplayData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.filterDisplayData;
  })

  const moveCostErrorData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostErrorData;
  });

  const addsInvalidPoolsGridReducer = useSelector((state) => {
    return { ...state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer };
  });
  // If plan status is running should return to later disable editing
  const addsInvalidPoolsGridColDef = useSelector((state) => {
    // return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsColumnDefinitionReducer;
    let colDef=state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsColumnDefinitionReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===ADDS_INVALID_POOLS) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });

  const addsInvalidPoolsData = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.AddsInvalidPoolsReducer.addsInvalidPoolsData;
  });

  var moveConstraintsData = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsReducer.moveconstraints;
  });
  const moveConstraintsGridReducer = useSelector((state) => {
    return { ...state.rootMoveConstraintsReducer.moveConstraintsGridReducer };
  });
  // If plan status is running should return to later disable editing
  const moveConstraintsGridColDef = useSelector((state) => {
    // return state.rootMoveConstraintsReducer.moveConstraintsColumnDefReducer;
    let colDef=state.rootMoveConstraintsReducer.moveConstraintsColumnDefReducer;
    if(copyToMainInputsStatus.some(tabName=>tabName===MOVE_CONSTRAINT) || ifPlanEditDisabled){
        colDef.defaultColDef.editable=false;
    }else{
        colDef.defaultColDef.editable=true;
    }
    return colDef;
  });
  var moveConstraintFilterSearchKey = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsReducer.moveConstraintFilterSearchKey;
  });
  const moveConstraintRowDataInvalid = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsGridReducer.isAnyRowInvalid;
  });
  const moveConstraintTransactions = useSelector((state) =>{
    return state.rootMoveConstraintsReducer.moveConstraintsGridReducer.transactions;
  });
  var moveConstraintFilterSearchKey = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsReducer.moveConstraintFilterSearchKey;
  });
  const moveConstraintsUploadSuccess = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsReducer.uploadMoveConstraintsSuccess;
  });
  const moveConstraintTransactionSaveSuccessful = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsReducer.transactionSaveSuccess;
  });
  const uploadMoveConstraintsSuccess = useSelector((state) => {
    return state.rootMoveConstraintsReducer.moveConstraintsReducer.uploadMoveConstraintsSuccess;
  });

  const masterDataUpdateState = useSelector((reduxstate)=>{
    return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  })

  const pending = useSelector((state) => {
    // return state.rootProgramsReducer.columnDefinitionReducer.pending;
    for (var x in state) {
      for (var y in state[x]) {
        for (var z in state[x][y]) {
          if (z === 'pending' && state[x][y][z]) {
            return true;
          }
        }
      }
    }
    return false;
  });


  var isConstraintsAccordionInEditMode = useSelector((state, shallowEqual) => {
    return state.rootConstraintsReducer.constraintsReducer.isAccordionDataInEditMode;
  });

let plansDataById = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.plansDataById;
});

const upgradePathUploadSuccess = useSelector((state) => {
  return state.rootUpgradePathsReducer.upgradePathReducer.upgradePathUploadSuccess;
})

  useEffect(() => {
    let planninngtesting = isPlanningStatusRunning(selectedurlHasMainInputs, plansDataById);
    dispatch(updatePlanEditDisabled(planninngtesting));
  }, [ifPlanEditDisabled,plansDataById]);

  const loadProgramData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(fetchProgramData(selectedPlanId));
    } catch (err) {
      console.error('got error while loading program data');
    }
  }, [dispatch]);

  const loadProgramDropdownData = useCallback(async () => {
    try {
      await dispatch(fetchProgramDropdownValues());
    } catch (err) {
      console.error('got error while loading program dropdown');
    }
  }, [dispatch]);

  const loadSChedulesDropdown = useCallback(async () => {
    try {
      await dispatch(fetchSchedulesDropdownValues());
    } catch (err) {
      console.error('got error while loading schedule dropdown');
    }
  }, [dispatch]);

  const loadAllocatedDropdown = useCallback(async () => {
    try {
      await dispatch(fetchAllocatedDropdownValues());
    } catch (err) {
      console.error('got error while loading allocated dropdown');
    }
  }, [dispatch]);

  const loadConstraintsDropdownData = useCallback(async () => {
    try {
      await dispatch(fetchConstraintsOptions());
    } catch (err) {
      console.error('got error while loading constraints dropdown');
    }
  }, [dispatch]);

  // const loadParametersDropDownData = useCallback(async(planid)=>{
  //   try{
  //     await dispatch(fetchParametersDropDownData(planid));
  //   }catch(err){
  //     console.error('got error while loading constraints dropdown');
  //   }
  // })
  const loadParametersDropDownData = useCallback(async(planid,zoneRfData,poolRfData)=>{
    try{
      await dispatch(fetchParametersDropDownData(planid,zoneRfData,poolRfData));
    }catch(err){
      console.error('got error while loading constraints dropdown');
    }
  })

  const loadAgeMileageDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchAgeMileageDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading agemileage dropdown');
    }
  }, [dispatch]);

  const loadAdditionalCostDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchAdditionalCostDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading additionalcost dropdown');
    }
  }, [dispatch]);

  const loadMileageDefaultDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchMileageDefaultDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading additionalcost dropdown');
    }
  }, [dispatch]);

  const loadMaxDeletesDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchMaxDeletesDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading maxdeletes dropdown');
    }
  }, [dispatch]);

  const loadSalvageRateDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchSalvageRateDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading salvagerate dropdown');
    }
  }, [dispatch]);
  
  const loadTargetZonePoolDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchTargetZonePoolDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading targetzonepool dropdown');
    }
  }, [dispatch]);

  const loadZoneMapDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchZoneMapDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading zonemap dropdown');
    }
  }, [dispatch]); 

  const loadMileageTableDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchMileageTableDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading mileagetable dropdown');
    }
  }, [dispatch]);

  const loadAddsInvalidPoolsDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchAddsInvalidPoolsDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading adds invalid pools dropdown');
    }
  }, [dispatch]);

  const loadMoveCostDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchMoveCostDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading maxdeletes dropdown');
    }
  }, [dispatch]);

  const loadDiversionsDropdown = useCallback(async (planid) => {
    try {
      await dispatch(fetchDiversionsDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading Diversions dropdown');
    }
  }, [dispatch]);

  const loadManualVinDeletesDropdown = useCallback(async (planid)=>{
    try {
      await dispatch(fetchManualVinDeletesDropdownValues(planid));
    } catch (err) {
      console.error('got error while loading manualvindeletes dropdown');
    }
  }, [dispatch]);

  const loadMoveCostFilterDisplayDropdown = useCallback(async () => {
    try {
      await dispatch(fetchFilterDisplayData())
    } catch (err) {
      console.error('got error while loading maxdeletes dropdown');
    }
  }, [dispatch]);

  const loadMoveConstraintDropdownData = useCallback(async () => {
    try {
      await dispatch(fetchMoveConstraintDropdownValues());
    } catch (err) {
      console.error('got error while loading program dropdown');
    }
  }, [dispatch]);

  const loadConstraintsData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(fetchConstraintData(selectedPlanId));
    } catch (err) {
      console.error('got error while loading constraints');
    }
  }, [dispatch]);

  const loadParametersData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(getParameters(selectedPlanId));
    } catch (err) {
      console.error('got error while loading parameters');
    }
  }, [dispatch]);

  const loadUpgradeTemplatesData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(fetchUpgradeTemplates(selectedPlanId));
    } catch (err) {
      console.error('got error while loading upgrade templates');
    }
  }, [dispatch]);

  const loadUpgradePathsData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(fetchUpgradePaths(selectedPlanId));
    } catch (err) {
      console.error('got error while loading upgrade templates');
    }
  }, [dispatch])

  const loadSchedulesData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(fetchSchedulesData(selectedPlanId));
    } catch (err) {
      console.error('got error while loading schedule data');
    }
  }, [dispatch]);

  const loadAllocatedData = useCallback(async (selectedPlanId) => {
    try {
      await dispatch(fetchAllocatedData(selectedPlanId));
    } catch (err) {
      console.error('got error while loading allocated data');
    }
  }, [dispatch]);

 const loadAgeMileageData = useCallback(async(selectedPlanId) =>{
   try{
     await dispatch(fetchAgeMileageData(selectedPlanId));
   }catch(err){
     console.error('got error while loading age mileage data');
   }
 }, [dispatch])

 const loadAdditionalCostData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchAdditionalCostData(selectedPlanId));
  }catch(err){
    console.error('got error while loading age mileage data');
  }
}, [dispatch])

const loadMileageDefaultData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchMileageDefaultData(selectedPlanId));
  }catch(err){
    console.error('got error while loading age mileage data');
  }
}, [dispatch])

const loadMaxDeletesData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchMaxDeletesData(selectedPlanId));
  }catch(err){
    console.error('got error while loading max deletes data');
  }
}, [dispatch])

const loadDiversionsData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchDiversionsData(selectedPlanId));
  }catch(err){
    console.error('got error while loading max deletes data');
  }
}, [dispatch])

const loadSalvageRateData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchSalvageRateData(selectedPlanId));
  }catch(err){
    console.error('got error while loading salvage rate data');
  }
}, [dispatch])

const loadTargetZonePoolData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchTargetZonePoolData(selectedPlanId));
  }catch(err){
    console.error('got error while loading target zone pool data');
  }
}, [dispatch])

// const loadZoneMapData = useCallback(async(selectedPlanId) =>{
//   try{
//     await dispatch(fetchZoneMapData(selectedPlanId));
//   }catch(err){
//     console.error('got error while loading zone map data');
//   }
// }, [dispatch])

const loadMileageTableData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchMileageTableData(selectedPlanId));
  }catch(err){
    console.error('got error while loading mileage table data');
  }
}, [dispatch])

const loadAddsInvalidPoolsData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchAddsInvalidPoolsData(selectedPlanId));
  }catch(err){
    console.error('got error while loading adds invalid poolsdata');
  }
}, [dispatch])

const loadMoveCostData = useCallback(async(selectedPlanId) =>{
  try{
    await dispatch(fetchMoveCostData(selectedPlanId,moveCostPageNo));
  }catch(err){
    console.error('got error while loading adds invalid poolsdata');
  }
}, [dispatch])

const loadDepRateData =useCallback(async(selectedPlanId)=>{
  try{
    await dispatch(fetchDepRateData(selectedPlanId))
  }catch(err){
    console.error('got error while loading dep rate data');
  }
})

const loadDepRateDropDownData = useCallback(async (planid) => {
  try {
    await dispatch(fetchDepRateDropDownValues(planid));
  } catch (err) {
    console.error('got error while loading program dropdown');
  }
}, [dispatch]);

 const loadMoveConstraintsData  = useCallback(async(selectedPlanId) =>{
   try{
      await dispatch(fetchMoveConstraintsData(selectedPlanId));
   }catch(err){
     console.error('got error while loading move constraints data')
   }
 }, [dispatch])

 const loadManualVinDeletesData = useCallback(async(selectedPlanId) =>{
  try{
   await dispatch(fetchManualVinDeletesData(selectedPlanId,manualVinDeletesPageNo));
  }catch(err){
    console.error('got error while loading ManualVinDeletes data');
  }
 },[dispatch])

 const loadManualVinDeletesFilterDisplayData = useCallback(async () => {
  try {
    await dispatch(fetchManualVinDeletesFilterDisplayData())
  } catch (err) {
    console.error('got error while loading maxdeletes dropdown');
  }
}, [dispatch]);

  const refreshGrid = (gridName, currentData) => {
    dispatch(refreshGridData(gridName));
    setRefreshCells(!refreshCells);
    dispatch(populateGrid(gridName, currentData));
  }

  const tabStyle = {
    default_tab: {
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "black",
      fontSize: 14,
      textTransform: 'none',
    },
    active_tab: {
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "black",
      fontSize: 14,
      textTransform: 'none'
    },
    defaultError_tab: {
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "red",
      fontSize: 14,
      textTransform: 'none',
      fontWeight: "bold",
    },
    activeError_tab: {
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "red",
      fontSize: 14,
      textTransform: 'none',
      fontWeight: "bold",
    },
    tabItemContainer: {
      margin: "50px",
      display: "flex",
      flexWrap: "wrap",
    },
    copyInProgressIndicator:{
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "black",
      fontSize: 14,
      textTransform: 'none',
      animation: "copyinprogress 2s linear infinite"
    },
    copyInProgressIndicator_active:{
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "black",
      fontSize: 14,
      textTransform: 'none',
      animation: "copyinprogress_active 2s linear infinite"
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setShowSaveOption(false);
    setSelectedRows([]);
    setBulkRowDeleteIconDisabled(true);
    setSelectedUpgradeButton('UPGRADE TEMPLATES');
    if (newValue == 3) {
      dispatch(UpdateMasterDataCache())
      loadConstraintsData(selectedPlanId);
      //loadConstraintsDropdownData()
      setSelectedval('Type>Zone');
      if (constraintRowDataInvalid)
        setCurrentView("grid")
      // else
      //   setCurrentView("accordion")
    }
    // if newValue==6 then it is plan assumptions tab, refer planAssumptionsTabPanel for the same

    if (newValue == 0) {
      //ToDO:-THis dispatch will be later pushed to the top level(at the beggining) of this function in later sprints
      dispatch(UpdateMasterDataCache())
      loadProgramData(selectedPlanId);
      loadProgramDropdownData();
      if (programsRowDataInvalid)
        setCurrentView("grid")
      // else
      //   setCurrentView("accordion")
    }
    if (newValue == 4) {
      loadMoveConstraintsData(selectedPlanId);
      if (moveConstraintRowDataInvalid)
        setCurrentView("grid")
      else
        setCurrentView("grid")
    }
    if (newValue == 5) {
      loadUpgradeTemplatesData(selectedPlanId)
    }
    if (newValue == 1) {
      loadSChedulesDropdown();
      dispatch(UpdateMasterDataCache())
      loadSchedulesData(selectedPlanId)
    }
    if (newValue == 2) {
      loadAllocatedDropdown();
      dispatch(UpdateMasterDataCache())
      loadAllocatedData(selectedPlanId)
    }
    setIsFiltered(false);
    setIsSearchBarValueExists(false);
  }

  const getStyle = (isActive, tabKey,index) => {
    // console.log("tabKey==",tabKey);
    let tableArray=planInputSubTablesData[tabKey];
    // console.log("tableArray==",tableArray);
    let isFound=false;
    for(let i=0;i<tableArray.length;i++){
      // console.log("Checking for TAB ", tableArray[i]);
      if(copyToMainInputsStatus.some(tabName=>tabName===tableArray[i])){
        // console.log("TAB is a match");
        isFound=true;
        break;
      }
    }

    if(isFound){
      return isActive ? tabStyle.copyInProgressIndicator_active :  tabStyle.copyInProgressIndicator
    }else{
      // console.log(copyToMainInputsStatus+" does not include "+ tableArray);
      //If there is an error in the grid the tab will be highlighted red 
    switch(index){
      case 0:
        if(programsRowDataInvalid || programData.programs.length<1){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }                
      case 1:
        if(schedulesRowDataInvalid || schedulesData.length<1){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }                  
      case 2:
        if(allocatedRowDataInvalid || allocatedData.length<1){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }  
      case 3:
        if(constraintRowDataInvalid || constraintsData.length<1){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }  
      case 4:
        if(moveConstraintRowDataInvalid || moveConstraintsData.length<1){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }  
      case 5:
        if((upgradePathRowDataInvalid || upgradeTemplateRowDataInvalid ||
            upgradePathData.length<1 || upgradeTemplatesData.length<1
          )){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }
      case 6:
        if((planAssumptionDataInvalid())){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }     
      case 7:
        if(parametersRowDataInvalid || parametersData.length<1){
          return isActive ? tabStyle.activeError_tab :  tabStyle.defaultError_tab
        }else{
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
        }  
      default:
          return isActive ? tabStyle.active_tab : tabStyle.default_tab
      }
    }
    
  }

  const handleSwitchView = (selectedview, selectedval) => {
    if (selectedview == "grid") {
      setCurrentView("accordion");
      if (selectedval)
        setSelectedval(selectedval);
    } else {
      setCurrentView("grid");
    }
  }

  const handleRowEditingStarted = (isRowEditing, isOfTypeCopyOrDelete,transactions) => {
    if (isRowEditing && transactions.length>0) {
      setShowSaveOption(true);
      if (!saveCancelDisabled && !isOfTypeCopyOrDelete)
        setSaveCancelDisabled(true);
    }
  }

  const handleRowEditingStopped = (isRowEditing) => {
    if (isRowEditing) {
      setShowSaveOption(false);
    }
  }

  const handleCellEditingStopped = () => {
    setSaveCancelDisabled(false);
  }

  const handleMultipleRowDelete = (selectedRows) => {
    if (selectedRows.length > 0) {
      setBulkRowDeleteIconDisabled(false);
      setSelectedRows(selectedRows);
    } else {
      setBulkRowDeleteIconDisabled(true);
      setSelectedRows(selectedRows)
    }
  }

  const constraintTypeSelected = (type) => {
    setScrollToTop(!scrollToTop)
  }

  const getSelectedUpgradeButton = (name, buttonindex) => {
    setSelectedUpgradeButton(name);
    setSelectedButtonIndex(buttonindex);
    setSelectedRows([]);
    setBulkRowDeleteIconDisabled(true);
    if (value == 2 && buttonindex == 1) {
      loadUpgradePathsData(selectedPlanId);
      if (upgradePathRowDataInvalid)
        setCurrentView("grid")
      // else
      //   setCurrentView("accordion")
    } 
    // else {
    //   setCurrentView('accordion');
    // }
  }

  const handleAddNewRow = (gridName) => {
    setShowSaveOption(true);
    dispatch(addNewRow(gridName, 0, []));
  }

  const handleSaveClicked = (gridName) => {
    setSnackBarIfError(!snackBarIfError);
    if (gridName === 'programsgrid') {
      //ToDO:-THis dispatch of UpdateMasterDataCache will be later pushed to the top level(at the beggining) of this function in later sprints
      dispatch(UpdateMasterDataCache())
      setIsSaveClicked('programsgrid');
      dispatch(validateGrid("programsgrid", gridReducer.currentData, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
    } if (gridName === 'constraintsgrid') {
      dispatch(UpdateMasterDataCache())
      setIsSaveClicked('constraintsgrid');
      dispatch(validateGrid("constraintsgrid", contraintsGridReducer.currentData, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
    } if (gridName === 'parametersgrid') {
      setIsSaveClicked('parametersgrid');
      dispatch(validateGrid("parametersgrid", parametersGridReducer.currentData, parametersGridColDef.validationFn, parametersGridColDef.businessValidationFn));
    }
    if (gridName === 'upgradetemplatesgrid') {
      setIsSaveClicked('upgradetemplatesgrid');
      dispatch(validateGrid("upgradetemplatesgrid", templatesGridReducer.currentData, templatesGridColumnDef.validationFn, templatesGridColumnDef.businessValidationFn))
    }
    if (gridName === 'upgradepathsgrid') {
      setIsSaveClicked('upgradepathsgrid');
      dispatch(validateGrid("upgradepathsgrid", upgradePathGridReducer.currentData, upgradePathColumnDef.validationFn, upgradePathColumnDef.businessValidationFn));
    }
    if (gridName === 'schedulesgrid') {
      dispatch(UpdateMasterDataCache())
      setIsSaveClicked('schedulesgrid');
      dispatch(validateGrid("schedulesgrid", schedulesGridReducer.currentData, schedulesGridColDef.validationFn, schedulesGridColDef.businessValidationFn));
    }
    if (gridName === 'allocatedgrid') {
      dispatch(UpdateMasterDataCache())
      setIsSaveClicked('allocatedgrid');
      dispatch(validateGrid("allocatedgrid", allocatedGridReducer.currentData, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
    }
    if (gridName === 'moveconstraintsgrid') {
      setIsSaveClicked('moveconstraintsgrid');
      dispatch(validateGrid("moveconstraintsgrid", moveConstraintsGridReducer.currentData, moveConstraintsGridColDef.validationFn, moveConstraintsGridColDef.businessValidationFn));
    }
  }

  const deleteSelectedRows = (gridName) => {
    let transactions = [];
    let ID = '';
    if (gridName == 'programsgrid') {
      transactions = gridReducer.transactions;
    } else if (gridName == 'constraintsgrid') {
      transactions = contraintsGridReducer.transactions;
    } else if (gridName == 'upgradepathsgrid') {
      transactions = upgradePathGridReducer.transactions;
    } else if (gridName == 'schedulesgrid') {
      transactions = schedulesGridReducer.transactions;
    } else if (gridName == 'allocatedgrid') {
      transactions = allocatedGridReducer.transactions;
    }else if (gridName == 'moveconstraintsgrid') {
      transactions = moveConstraintsGridReducer.transactions;
    }
    if (transactions.length > 0) {
      transactions.map((i) => {
        if (i.type == 'edit') {
          dispatch(deleteRowEditMode(gridName, i.data.ID))
        } else
          if (selectedRows.length > 0) { //in case of copy row if we select both the rows we won't get both in transactions.
            selectedRows.map((i) => {
              dispatch(deleteRow(gridName, i.ID));
            })
          }
      })
    } else if (selectedRows.length > 0) {
      selectedRows.map((i) => {
        dispatch(deleteRow(gridName, i.ID));
      })
    }
  }

  const handleCancelClicked = (gridName) => {
    if (gridName === 'programsgrid') {
      //ToDO:-THis dispatch of UpdateMasterDataCache will be later pushed to the top level(at the beggining) of this function in later sprints
      dispatch(UpdateMasterDataCache())
      dispatch(cleanTransactions('programsgrid'));
      dispatch(refreshGridData('programsgrid'));
      dispatch(fetchProgramData(selectedPlanId));
    } else if (gridName === 'constraintsgrid') {
      dispatch(UpdateMasterDataCache())
       dispatch(cleanTransactions('constraintsgrid'));
       dispatch(refreshGridData('constraintsgrid', constraintsData));
       dispatch(fetchConstraintData(selectedPlanId));
    } else if (gridName === 'parametersgrid') {
      dispatch(cleanTransactions('parametersgrid'));
      dispatch(refreshGridData('parametersgrid'));
      dispatch(populateGrid("parametersgrid", parametersData));
    }
    else if (gridName === 'upgradetemplatesgrid') {
      dispatch(cleanTransactions('upgradetemplatesgrid'));
      dispatch(refreshGridData('upgradetemplatesgrid'));
      dispatch(populateGrid("upgradetemplatesgrid", upgradeTemplatesData));
    }
    else if (gridName === 'upgradepathsgrid') {
      dispatch(cleanTransactions('upgradepathsgrid'));
      dispatch(refreshGridData('upgradepathsgrid'));
      dispatch(populateGrid("upgradepathsgrid", upgradePathData));
    }
    else if (gridName === 'schedulesgrid') {
      dispatch(UpdateMasterDataCache())
      dispatch(cleanTransactions('schedulesgrid'));
      dispatch(refreshGridData('schedulesgrid'));
      dispatch(fetchSchedulesData(selectedPlanId));
    }
    else if (gridName === 'allocatedgrid') {
      dispatch(UpdateMasterDataCache())
      dispatch(cleanTransactions('allocatedgrid'));
      dispatch(refreshGridData('allocatedgrid'));
      dispatch(fetchAllocatedData(selectedPlanId));
    }
    else if (gridName === 'moveconstraintsgrid') {
      dispatch(cleanTransactions('moveconstraintsgrid'));
      dispatch(refreshGridData('moveconstraintsgrid'));
      dispatch(fetchMoveConstraintsData(selectedPlanId));
    }
    setRefreshCells(!refreshCells);
    setShowSaveOption(false);
    setIsSaveClicked(null);
    setIsCancelClicked(true);
    dispatch(validateGrid("constraintsgrid", constraintsData, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
    dispatch(validateGrid("programsgrid", programData.programs, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
    dispatch(validateGrid("parametersgrid", parametersData, parametersGridColDef.validationFn, parametersGridColDef.businessValidationFn));
    dispatch(validateGrid("upgradepathsgrid", upgradePathData, upgradePathColumnDef.validationFn, upgradePathColumnDef.businessValidationFn));
    dispatch(validateGrid("upgradetemplatesgrid", upgradeTemplatesData, templatesGridColumnDef.validationFn, templatesGridColumnDef.businessValidationFn));
    dispatch(validateGrid("schedulesgrid", schedulesData, schedulesGridColDef.validationFn, schedulesGridColDef.businessValidationFn));
    dispatch(validateGrid("allocatedgrid", allocatedData, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
    dispatch(validateGrid("moveconstraintsgrid", moveConstraintsData, moveConstraintsGridColDef.validationFn, moveConstraintsGridColDef.businessValidationFn));
  }

  function loadUpgradePathCarGroupData() {
    dispatch(fetchUpgradeCarClassgroup());
  }

  useEffect(()=>{   
    return()=>{   
    dispatch(resetPrograms());
    dispatch(resetConstraints());
    dispatch(resetSchedules());
    dispatch(resetAllocated());
    dispatch(resetAgeMileage());
    dispatch(resetAdditionalCost());
    dispatch(resetMileageDefault());
    dispatch(resetMaxDeletes());
    dispatch(resetDiversions());
    dispatch(resetSalvageRate());
    dispatch(resetTargetZonePool());
    dispatch(resetZoneMap());
    dispatch(resetMileageTable());
    dispatch(resetAddsInvalidPools());
    dispatch(SetAssumptionIndex(0))
    dispatch(resetMoveConstraints());
    dispatch(resetMoveCost())
    dispatch(resetDepRate());
    dispatch(resetManualVinDeletes());
  }
  },[])

  useEffect(async () => {
    loadProgramDropdownData();
    
    let promiseSchedule =  loadSChedulesDropdown();
    let promiseAllocate= loadAllocatedDropdown();
    await Promise.all([promiseSchedule,promiseAllocate])
    
    loadConstraintsData(selectedPlanId);
    loadProgramData(selectedPlanId);
    loadParametersData(selectedPlanId);
    loadAgeMileageDropdown(selectedPlanId)
    loadAdditionalCostDropdown(selectedPlanId)
    loadMileageDefaultDropdown(selectedPlanId)
    loadMaxDeletesDropdown(selectedPlanId)
    loadSalvageRateDropdown(selectedPlanId)
    loadTargetZonePoolDropdown(selectedPlanId) 
    if(selectedurlHasMainInputs){
      loadZoneMapDropdown(selectedPlanId)
    }
    loadMileageTableDropdown(selectedPlanId)
    loadAddsInvalidPoolsDropdown(selectedPlanId)
    loadMoveCostDropdown(selectedPlanId)
    loadManualVinDeletesDropdown(selectedPlanId)
    loadMoveCostFilterDisplayDropdown()
    loadDiversionsDropdown(selectedPlanId)
    // if (isSaveClicked == null) {
    //   if (programsRowDataInvalid)
    //     setCurrentView("grid")
    //   else
    //     setCurrentView("accordion")
    // }
    loadUpgradeTemplatesData(selectedPlanId);
    loadUpgradePathsData(selectedPlanId);
    loadUpgradePathCarGroupData();
    loadSchedulesData(selectedPlanId);
    loadAllocatedData(selectedPlanId);
    loadAgeMileageData(selectedPlanId);
    loadAdditionalCostData(selectedPlanId);
    loadMileageDefaultData(selectedPlanId)
    loadMaxDeletesData(selectedPlanId)
    loadDiversionsData(selectedPlanId)
    loadSalvageRateData(selectedPlanId)
    loadTargetZonePoolData(selectedPlanId)
    // if(selectedurlHasMainInputs){
    //   loadZoneMapData(selectedPlanId)
    // }
    loadMileageTableData(selectedPlanId)
    loadAddsInvalidPoolsData(selectedPlanId)
    loadMoveConstraintDropdownData();
    loadMoveConstraintsData(selectedPlanId);
    loadMoveCostData(selectedPlanId);
    loadDepRateData(selectedPlanId);
    loadDepRateDropDownData(selectedPlanId);
    loadParametersDropDownData(selectedPlanId,zoneRfData,poolRfData);
    loadManualVinDeletesData(selectedPlanId);
    loadManualVinDeletesFilterDisplayData();
  }, [selectedPlanId]);
  
  useEffect(() => {
     if (Object.keys(constraintsWholeData) != 0) {
      loadConstraintsDropdownData();
    }
  }, [selectedPlanId, constraintsWholeData]);
  //useEffect will set true/false dependent on showSaveOptions
  useEffect(() =>{
      defuserAcs.setUserAcs(showSaveOption);
  }, [showSaveOption, setShowSaveOption])

  useEffect(() => {
    // if (!constraintRowDataInvalid && (isSaveClicked != null && isSaveClicked == "constraintsgrid")) {
    //   dispatch(saveConstraintsTransactions(selectedPlanId, constraintTransactions));
    // }
    // if (constraintRowDataInvalid && (isSaveClicked != null && isSaveClicked == "constraintsgrid")) {
    //   setSnackBarOpen(true);
    // }
    // if(constraintsUploadState){
    //   dispatch(saveConstraintsTransactions(selectedPlanId,constraintTransactions));
    // }
    if(!pending && !masterDataUpdateState && isSaveClicked==='constraintsgrid'){
    const filterValidTransactions = fetchValidTransactions(constraintTransactions);
    const filterInValidTransactions = fetchInvalidTransactions(constraintTransactions);
    if ((!constraintTransactions || constraintTransactions.length == 0) && (isSaveClicked != null && isSaveClicked == "constraintsgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }
    if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "constraintsgrid")) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
        dispatch(saveConstraintsTransactions(selectedPlanId, filterValidTransactions));
        setIsSaveClicked(null);
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }
    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "constraintsgrid")) {
      setSnackBarOpen(true);
    }
  }
  }, [constraintRowDataInvalid, isSaveClicked, snackBarIfError,constraintsUploadState,masterDataUpdateState,pending]);

  useEffect(() => {
    if (isSaveClicked == null) {
      if ((constraintRowDataInvalid || isCancelClicked == true) && !constraintFilterSearchKey)
        setCurrentView("grid")
      // else
      //   setCurrentView("accordion")
    }
  }, [constraintRowDataInvalid]);

  useEffect(() => {
    if (!showSaveOption && constraintsUploadSuccess == true) {
      if (constraintRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      else
        userMessage('success', 'Successfully uploaded');
    } else if (constraintsUploadSuccess == false) {
        userMessage('error', 'Upload failed');
    }
  }, [constraintsUploadSuccess, constraintRowDataInvalid]);

  useEffect(() => {
    //THis useEffect is used for saving transaction of Programs Grid 
    //Note:- Perfomance will be improved if we add same kind of if condition in all the savechanges useEffect's
    if(!pending && !masterDataUpdateState && isSaveClicked==='programsgrid'){
    const filterValidTransactions = fetchValidTransactions(gridReducer.transactions);
    const filterInValidTransactions = fetchInvalidTransactions(gridReducer.transactions);
  
  
    // if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "programsgrid")) {
    //   dispatch(saveTransactionData(selectedPlanId, filterValidTransactions));
    //   if(programsRowDataInvalid) {
    //     setWarningSnackBarOpen(true);
    //     setWarningMessage("Successfully saved rows. But some rows are still invalid, please review");
    //   }
    // }

    if ((!gridReducer.transactions || gridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "programsgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }

    if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "programsgrid")) {
      if ((!filterInValidTransactions || filterInValidTransactions.length <= 0)) {
        dispatch(saveTransactionData(selectedPlanId, filterValidTransactions));
        //IsSaveClicked is set to null for avoiding multiple render of useEffect's 
        //Anyhow on each time SaveCHanges is clicked setIsSaveClicked is set to programsgrid again 
        setIsSaveClicked(null);
      }
      else if ((filterInValidTransactions && filterInValidTransactions.length > 0)) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }

    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "programsgrid")) {
      setSnackBarOpen(true);
    }
  }
  }, [programsRowDataInvalid, isSaveClicked, snackBarIfError,masterDataUpdateState,pending]);

  useEffect(() => {
    if(!pending && !masterDataUpdateState && isSaveClicked==='schedulesgrid'){
    const filterValidTransactions = fetchValidTransactions(schedulesGridReducer.transactions);
    const filterInValidTransactions = fetchInvalidTransactions(schedulesGridReducer.transactions);

    if ((!schedulesGridReducer.transactions || schedulesGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "schedulesgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }

    if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "schedulesgrid")) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
        dispatch(saveSchedulesTransactionData(selectedPlanId, filterValidTransactions));
        setIsSaveClicked(null);
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }

    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "schedulesgrid") ) {
      setSnackBarOpen(true);
    }
  }
  }, [schedulesRowDataInvalid, isSaveClicked, snackBarIfError,masterDataUpdateState,pending]);

  useEffect(() => {
    if(!pending && !masterDataUpdateState && isSaveClicked==='allocatedgrid'){
    const filterValidTransactions = fetchValidTransactions(allocatedGridReducer.transactions);
    const filterInValidTransactions = fetchInvalidTransactions(allocatedGridReducer.transactions);

    if ((!allocatedGridReducer.transactions || allocatedGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "allocatedgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }

    if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "allocatedgrid")) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
        dispatch(saveAllocatedTransactionData(selectedPlanId, filterValidTransactions));
        setIsSaveClicked(null);
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }

    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "allocatedgrid")) {
      setSnackBarOpen(true);
      }
    }
  }, [allocatedRowDataInvalid, isSaveClicked, snackBarIfError,masterDataUpdateState,pending]);

  useEffect(() => {
    if (isSaveClicked == null) {
      if ((programsRowDataInvalid || isCancelClicked == true) && !programFilterSearchKey) {
        setCurrentView("grid")
      }
      // else {
      //   setCurrentView("accordion")
      // }
    }
  }, [programsRowDataInvalid]);

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(moveConstraintTransactions);
    const filterInValidTransactions = fetchInvalidTransactions(moveConstraintTransactions);
    if ((!moveConstraintTransactions || moveConstraintTransactions.length == 0) && (isSaveClicked != null && isSaveClicked == "moveconstraintsgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }
    if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "moveconstraintsgrid")) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
        dispatch(saveMoveConstraintsTransactions(selectedPlanId, filterValidTransactions));
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }
    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "moveconstraintsgrid")) {
      setSnackBarOpen(true);
    }

  }, [moveConstraintRowDataInvalid, isSaveClicked, snackBarIfError]);

  useEffect(() => {
    if (isSaveClicked == null) {
      if ((moveConstraintRowDataInvalid || isCancelClicked == true) && !moveConstraintFilterSearchKey)
        setCurrentView("grid")
      // else
      //   setCurrentView("accordion")
    }
  }, [moveConstraintRowDataInvalid]);

  useEffect(() => {
    if (!showSaveOption && moveConstraintsUploadSuccess == true) {
      if (moveConstraintRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      else
       userMessage('success', 'Successfully uploaded');
    } else if (moveConstraintsUploadSuccess == false) {
       userMessage('error', 'Upload failed');
    }
  }, [moveConstraintsUploadSuccess, moveConstraintRowDataInvalid]);

  useEffect(() => {
    if (!showSaveOption && programsUploadSuccess == true) {
      if (programsRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
    
      else
        userMessage('success', 'Successfully uploaded');
    } else if (programsUploadSuccess == false) {
        userMessage('error', 'Upload failed');
    }
  }, [programsUploadSuccess, programsRowDataInvalid])

  useEffect(() => {
    if (!showSaveOption && schedulesUploadSuccess == true) {
      if (schedulesRowDataInvalid == true) {
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      } else {
        userMessage('success', 'Successfully uploaded');
      }
    } else if (schedulesUploadSuccess == false) {
       userMessage('error', 'Upload failed');
    }
  }, [schedulesUploadSuccess, schedulesRowDataInvalid])

  useEffect(() => {
    if (ageMileageData) {
      dispatch(validateGrid("agemileagegrid", ageMileageData, ageMileageGridColDef.validationFn, ageMileageGridColDef.businessValidationFn));
    }
    //loadAgeMileageDropdown(selectedPlanId);
  }, [ageMileageData]);


useEffect(() => {
  if (additionalCostData) {
    dispatch(validateGrid("additionalcostgrid", additionalCostData, additionalCostGridColDef.validationFn, additionalCostGridColDef.businessValidationFn));
  }
  //loadAgeMileageDropdown(selectedPlanId);
}, [additionalCostData]);

useEffect(() => {
  if (mileageDefaultData) {
    dispatch(validateGrid("mileagedefaultgrid", mileageDefaultData, mileageDefaultGridColDef.validationFn, mileageDefaultGridColDef.businessValidationFn));
  }
  //loadMileageDefaultDropdown(selectedPlanId);
}, [mileageDefaultData]);

useEffect(() => {
  if (maxDeletesData) {
    dispatch(validateGrid("maxdeletegrid", maxDeletesData, maxDeletesGridColDef.validationFn, maxDeletesGridColDef.businessValidationFn));
  }
  //loadAgeMileageDropdown(selectedPlanId);
}, [maxDeletesData]);

useEffect(() => {
  if (diversionsData) {
    dispatch(validateGrid("diversionsgrid", diversionsData, diversionsGridColDef.validationFn, diversionsGridColDef.businessValidationFn));
  }
  //loadAgeMileageDropdown(selectedPlanId);
}, [diversionsData]);

useEffect(() => {
  if (depRateData) {
    dispatch(validateGrid("deprategrid", depRateData, depRateGridColDef.validationFn, depRateGridColDef.businessValidationFn));
  }
}, [depRateData]);

useEffect(() => {
  if (salvageRateData) {
    dispatch(validateGrid("salvagerategrid", salvageRateData, salvageRateGridColDef.validationFn, salvageRateGridColDef.businessValidationFn));
  }
  //loadAgeMileageDropdown(selectedPlanId);
}, [salvageRateData]);

useEffect(() => {
  if (targetZonePoolData) {
    dispatch(validateGrid("targetzonepoolgrid", targetZonePoolData,targetZonePoolGridColDef.validationFn, targetZonePoolGridColDef.businessValidationFn));
  }
}, [targetZonePoolData]);
/* Moved to Metadata
// useEffect(() => {
//   if (zoneMapData) {
//     dispatch(validateGrid("zonemapgrid", zoneMapGridReducer.currentData,zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));
//   }
// }, [zoneMapData]);
*/

useEffect(() => {
  if (mileageTableData) {
    dispatch(validateGrid("mileagetablegrid", mileageTableData,mileageTableGridColDef.validationFn, mileageTableGridColDef.businessValidationFn));
  }
}, [mileageTableData]);

useEffect(() => {
  if (addsInvalidPoolsData) {
    dispatch(validateGrid("addsinvalidpoolsgrid", addsInvalidPoolsData, addsInvalidPoolsGridColDef.validationFn, addsInvalidPoolsGridColDef.businessValidationFn));
  }
  //loadAddsInvalidPoolsDropdown(selectedPlanId);
}, [addsInvalidPoolsData]);
const moveCostFilterData = useSelector((state) => {
  return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterData;
});
useEffect(() => {
  if (moveCostData || moveCostErrorData || moveCostFilterData) {
    dispatch(validateGrid("movecostgrid", moveCostGridReducer.currentData, moveCostGridColDef.validationFn, moveCostGridColDef.businessValidationFn));
  }
}, [moveCostData,moveCostErrorData,moveCostFilterData]);

useEffect(() => {
  if (manualVinDeletesData) {
    dispatch(validateGrid("manualvindeletesgrid", manualVinDeletesData, manualVinDeletesGridColumnDefReducer.validationFn, manualVinDeletesGridColumnDefReducer.businessValidationFn));
  }
}, [manualVinDeletesData]);

useEffect(() => {
    if (isSaveClicked == null) {
      if ((upgradePathRowDataInvalid || isCancelClicked == true) && !upgradePathSearchKey)
        setCurrentView("grid")
      // else
      //   setCurrentView("accordion")
    }
  }, [upgradePathRowDataInvalid]);

  useEffect(() => {
    // if (!parametersRowDataInvalid && (isSaveClicked != null && isSaveClicked == "parametersgrid")) {
    //   dispatch(saveParametersTransactions(selectedPlanId, parametersGridReducer.transactions));
    // }
    // if (parametersRowDataInvalid && (isSaveClicked != null && isSaveClicked == "parametersgrid")) {
    //   setSnackBarOpen(true);
    // }
    const filterValidTransactions = fetchValidTransactions(parametersGridReducer.transactions);
    const filterInValidTransactions = fetchInvalidTransactions(parametersGridReducer.transactions);
    // if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "parametersgrid")) {
    //   dispatch(saveParametersTransactions(selectedPlanId, filterValidTransactions));
    // }

    if (isSaveClicked != null && isSaveClicked == "parametersgrid") {
      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
          dispatch(saveParametersTransactions(selectedPlanId, filterValidTransactions));
        }
        else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
          userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
        }
      } else if (!parametersGridReducer.transactions || parametersGridReducer.transactions.length == 0) {
        setSnackBarOpen(false);
        userMessage('warning', 'Nothing to be saved');
        setShowSaveOption(false);
        setIsSaveClicked(null);
      }
    }

    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "parametersgrid")) {
      setSnackBarOpen(true);
    }
  }, [parametersRowDataInvalid, isSaveClicked, snackBarIfError]);

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(upgradePathGridReducer.transactions);
    const filterInValidTransactions = fetchInvalidTransactions(upgradePathGridReducer.transactions);

    if ((!upgradePathGridReducer.transactions || upgradePathGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "upgradepathsgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }
    else if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "upgradepathsgrid")) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
        dispatch(saveUpgradePathTranctions(selectedPlanId, filterValidTransactions));
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }
    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "upgradepathsgrid")) {
      setSnackBarOpen(true);
    }
  }, [upgradePathRowDataInvalid, isSaveClicked, snackBarIfError]);

  useEffect(() => {
    if (!upgradeTemplateRowDataInvalid && (isSaveClicked != null && isSaveClicked == "upgradetemplatesgrid")) {
      dispatch(saveTemplateTranctions(selectedPlanId, templatesGridReducer.transactions));
    }
    if (upgradeTemplateRowDataInvalid && (isSaveClicked != null && isSaveClicked == "upgradetemplatesgrid")) {
      setSnackBarOpen(true);
    }
  }, [upgradeTemplateRowDataInvalid, isSaveClicked, snackBarIfError]);


  useEffect(() => {
    if(saveUpdatedResponse==true){
    loadParametersData(selectedPlanId);}
  }, [saveUpdatedResponse])

  useEffect(() => {
    if (parametersData) {
      dispatch(populateGrid("parametersgrid", parametersData, undefined, undefined, undefined, parametersGridColDef.checkIfRequiredFn));
      dispatch(validateGrid("parametersgrid", parametersData, parametersGridColDef.validationFn, parametersGridColDef.businessValidationFn));
    }

  }, [parametersData]);

  useEffect(() => {
    if (upgradeTemplatesData)
      dispatch(populateGrid("upgradetemplatesgrid", upgradeTemplatesData, templateFilterSearchKey));
    dispatch(validateGrid("upgradetemplatesgrid", upgradeTemplatesData, templatesGridColumnDef.validationFn, templatesGridColumnDef.businessValidationFn));
  }, [upgradeTemplatesData, templateFilterSearchKey])

  useEffect(() => {
    if (upgradePathData && upgradePathSearchKey ) {
      dispatch(populateGrid("upgradepathsgrid", upgradePathData, upgradePathSearchKey, upgradePathColumnDef.checkType, upgradePathColumnDef.checkValue));
    }
  }, [upgradePathData, upgradePathSearchKey])


  useEffect(()=>{
    loadMoveConstraintDropdownData();
  },[moveConstraintsData])

  useEffect(() => {
    // loadAllocatedDropdown();
    if (allocatedData && !isFiltered && !isSearchBarValueExists) {
      dispatch(validateGrid("allocatedgrid", allocatedData, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
    }
  }, [allocatedData,isFiltered,isSearchBarValueExists]);

  useEffect(() => {
    if (!showSaveOption && allocatedUploadSuccess == true) {
      if (allocatedRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      else
        userMessage('success', 'Successfully uploaded');
    } else if (allocatedUploadSuccess == false) {
        userMessage('error', 'Upload failed');
    }
  }, [allocatedUploadSuccess, allocatedRowDataInvalid])

  const userMessage = (severity, message) => {
    setWarningSnackBarOpen(true);
    setSeverity(severity);
    setWarningMessage(message);
  }

  const fetchValidTransactions = (transactions) => {
    const filterValidTransactions = transactions.filter(item => {
      return (((item && item.data) && (item.data.status != "ERROR_ROW" && item.data.status != "ERROR_COPY_ROW" &&
        item.data.status != "ERROR_NEW_ROW" && item.data.status != "INVALID_ROW" && item.data.status != "INVALID_NEW_ROW") ||
        (item && !item.data) && item.type == "delete"))
    })
    return filterValidTransactions;
  }

  const fetchInvalidTransactions = (transactions) => {
    const filterInValidTransactions = transactions.filter(item => {
      return ((item && item.data) && (item.data.status == "ERROR_ROW" || item.data.status == "ERROR_COPY_ROW" ||
        item.data.status == "ERROR_NEW_ROW" || item.data.status == "INVALID_ROW" || item.data.status == "INVALID_NEW_ROW") ||
        (item && !item.data) && item.type == "add")
    })
    return filterInValidTransactions;
  }

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(gridReducer.transactions);

    if (programTransactionSaveSuccessful) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (programsRowDataInvalid) {
           userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
           userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('programsgrid'));
      dispatch(refreshGridData('programsgrid'));
      dispatch(validateGrid("programsgrid", gridReducer.currentData, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
    }
    else if (programTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.');
    }
  }, [programTransactionSaveSuccessful]);

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(schedulesGridReducer.transactions);

    if (scheduleTransactionSaveSuccessful) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (schedulesRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('schedulesgrid'));
      dispatch(refreshGridData('schedulesgrid'));
      dispatch(validateGrid("schedulesgrid", schedulesGridReducer.currentData, schedulesGridColDef.validationFn, schedulesGridColDef.businessValidationFn));
    }
    else if (scheduleTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.');
    }
  }, [scheduleTransactionSaveSuccessful]);

  useEffect(() => {
    // loadSChedulesDropdown();
    if (schedulesData && !isFiltered && !isSearchBarValueExists) {
      // console.log("Validating schedulesgrid and passing schedulesData in useEffect [schedulesData,isFiltered,isSearchBarValueExists]...");
      dispatch(validateGrid("schedulesgrid", schedulesData, schedulesGridColDef.validationFn, schedulesGridColDef.businessValidationFn));
    }
  }, [schedulesData,isFiltered,isSearchBarValueExists]);


  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(allocatedGridReducer.transactions);

    if (allocatedTransactionSaveSuccessful) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (allocatedRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('allocatedgrid'));
      dispatch(refreshGridData('allocatedgrid'));
      dispatch(validateGrid("allocatedgrid", allocatedGridReducer.currentData, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
    }
    else if (allocatedTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.');
    }
  }, [allocatedTransactionSaveSuccessful]);

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(contraintsGridReducer.transactions);

    if (constraintTransactionSaveSuccessful) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (constraintRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('constraintsgrid'));
      dispatch(refreshGridData('constraintsgrid'));
      dispatch(validateGrid("constraintsgrid", contraintsGridReducer.currentData, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
      if (selectedConstraintType == undefined || selectedConstraintType == null)
        setConstraintType('ALL');

    }
    else if (constraintTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.')
    }
  }, [constraintTransactionSaveSuccessful]);

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(moveConstraintsGridReducer.transactions);

    if (moveConstraintTransactionSaveSuccessful) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (moveConstraintRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('moveconstraintsgrid'));
      dispatch(refreshGridData('moveconstraintsgrid'));
      dispatch(validateGrid("moveconstraintsgrid", moveConstraintsGridReducer.currentData, moveConstraintsGridColDef.validationFn, moveConstraintsGridColDef.businessValidationFn));
    }
    else if (moveConstraintTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.')
    }
  }, [moveConstraintTransactionSaveSuccessful]);

  useEffect(() => {
    if (selectedConstraintType !== undefined || selectedConstraintType != null) {
      constraintTypeSelected(selectedConstraintType)
    }
    if (constraintAccordionTransactionSaveSuccessful == false) {
      alert('Unable to save transactions. Please try again.')
    }
  }, [constraintAccordionTransactionSaveSuccessful, selectedConstraintType])

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(parametersGridReducer.transactions);

    if (parametersTransactionSaveSuccessful) {
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (parametersRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('parametersgrid'));
      dispatch(refreshGridData('parametersgrid'));
      dispatch(validateGrid("parametersgrid", parametersGridReducer.currentData, parametersGridColDef.validationFn, parametersGridColDef.businessValidationFn));
      setShowSaveOption(false);
    }
    else if (parametersTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.')
    }
  }, [parametersTransactionSaveSuccessful]);

  useEffect(() => {
    if (templatesTransactionSaveSuccessful) {
      setShowSaveOption(false);
      dispatch(cleanTransactions('upgradetemplatesgrid'));
      dispatch(refreshGridData('upgradetemplatesgrid'));
      setRefreshCells(!refreshCells);
      userMessage('success', 'Successfully saved rows')
    }
    else if (templatesTransactionSaveSuccessful === false) {
      alert('Unable to save transactions. Please try again.');
    }
  }, [templatesTransactionSaveSuccessful]);

  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(upgradePathGridReducer.transactions);

    if (pathTransactionSaveSuccess) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      dispatch(cleanTransactions('upgradepathsgrid'));
      dispatch(refreshGridData('upgradepathsgrid'));
      setRefreshCells(!refreshCells)

      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (upgradePathRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions('upgradepathsgrid'));
      dispatch(refreshGridData('upgradepathsgrid'));
      dispatch(validateGrid("upgradepathsgrid", upgradePathGridReducer.currentData, upgradePathColumnDef.validationFn, upgradePathColumnDef.businessValidationFn));
      setShowSaveOption(false);
    }
    else if (pathTransactionSaveSuccess === false) {
      alert('Unable to save transactions. Please try again.');
    }
  }, [pathTransactionSaveSuccess]);

  function downloadCSV(csvStr, filename) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }

  useEffect(()=>{
    if((programsTransactions.length>0 || scheduleTransactions.length>0 || constraintTransactions.length>0 || parametersTransactions.length>0 || allocatedTransactions.length>0 || moveConstraintTransactions.length>0 || upgradePathTransactions.length>0 || upgradeZoneTransactions.length>0) && (constraintsUploadState==false) ){
      setShowSaveOption(true)
    }else{
    setShowSaveOption(false);
    }
  },[programsTransactions,constraintTransactions,scheduleTransactions,
    parametersTransactions,allocatedTransactions,moveConstraintTransactions,
    upgradePathTransactions,upgradeZoneTransactions])

  useEffect(()=>{
    // loadProgramDropdownData();
    if(programData.programs && !isFiltered && !isSearchBarValueExists && !programsFilterParams){
      dispatch(validateGrid("programsgrid", programData.programs, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
    }  
  },[programData,isFiltered,isSearchBarValueExists]);

  useEffect(()=>{
    if(constraintsData && !isFiltered && !isSearchBarValueExists){
      dispatch(validateGrid("constraintsgrid", constraintsData, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
    }
  },[constraintsData,isFiltered,isSearchBarValueExists]);

  useEffect(()=>{
    if(parametersData){
      dispatch(validateGrid("parametersgrid", parametersData, parametersGridColDef.validationFn, parametersGridColDef.businessValidationFn));
    }
  },[parametersData]);

  useEffect(()=>{
    if(upgradePathData && !isFiltered && !isSearchBarValueExists){
      dispatch(validateGrid("upgradepathsgrid", upgradePathData, upgradePathColumnDef.validationFn, upgradePathColumnDef.businessValidationFn));
    }
  },[upgradePathData,isFiltered,isSearchBarValueExists]);

  useEffect(()=>{
    if(upgradeTemplatesData && !isFiltered && !isSearchBarValueExists){
      dispatch(validateGrid("upgradetemplatesgrid", upgradeTemplatesData, templatesGridColumnDef.validationFn, templatesGridColumnDef.businessValidationFn));
    }
  },[upgradeTemplatesData,isFiltered,isSearchBarValueExists]);

  useEffect(()=>{
    if(templateFilterSearchData && templateFilterSearchFlag){
      dispatch(validateGrid("upgradetemplatesgrid", templateFilterSearchData, templatesGridColumnDef.validationFn, templatesGridColumnDef.businessValidationFn));
    }
  },[templateFilterSearchData,templateFilterSearchFlag]);

  useEffect(()=>{
    if(moveConstraintsData){
      dispatch(validateGrid("moveconstraintsgrid", moveConstraintsData, moveConstraintsGridColDef.validationFn, moveConstraintsGridColDef.businessValidationFn));
    }
  },[moveConstraintsData]);
  
  useEffect(() => {
    if (!showSaveOption && upgradePathUploadSuccess == true) {
      if (upgradePathRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      else
        userMessage('success', 'Successfully uploaded');
    } else if (upgradePathUploadSuccess == false) {
      userMessage('error', 'Upload failed');
    }
  }, [upgradePathUploadSuccess, upgradePathRowDataInvalid])
    
  return (
    <div className={selectedurlHasMainInputs ? "margin" : "marginleft"}>
      {selectedurlHasMainInputs ? <h2 className="headertext">Main Inputs</h2> : <div></div>}
      <div aria-disabled={isConstraintsAccordionInEditMode} className={(isConstraintsAccordionInEditMode) ? 'is-disabled' : ''}>
        <NttTabs value={value} onChange={handleChange} style={tabStyle.tabItemContainer} indicatorColor='string'> 
          {tabsData.map((tabInfo, index) => (
            <NttTab label={tabInfo.tabTitle}
              id={`simple-tab-${index}`}
              key={tabInfo.tabTitle}
              style={getStyle(value === index,tabInfo.tabKey,index)}
              disabled={showSaveOption || pending || moveCostDisableButton} />
          ))}
        </NttTabs></div>
      {tabsData.map((tabInfo, index) => (
        <TabPanel key={index}
          value={value} index={index}>
          {((programData && programData.programs) && index == 0) ?
            (<div>
              <ProgramsTabPanel programData={programData.programs} handleSwitchView={handleSwitchView}
                showSaveOption={showSaveOption} refreshCells={refreshCells} downloadCSV={downloadCSV}
                currentView={currentView} gridReducer={gridReducer}
                handleAddNewRow={(e) => handleAddNewRow('programsgrid')}
                handleSaveClicked={(e) => handleSaveClicked('programsgrid')} handleCancelClicked={(e) => handleCancelClicked('programsgrid')}
                handleRowEditingStarted={handleRowEditingStarted} handleRowEditingStopped={handleRowEditingStopped}
                programgridcoldef={programgridcoldef} filterSearchData={filterSearchData} filterFlag={filterFlag}
                tabInfo={tabInfo} tabIndex={value} handleSaveCancelDisabled={saveCancelDisabled}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId} deleteSelectedRows={(e) => deleteSelectedRows('programsgrid')}
                bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                // filterParams={programsFilterParams}
                transactions={gridReducer.transactions}
                isFiltered={isFiltered}
                setIsFiltered={setIsFiltered}
                isSearchBarValueExists={isSearchBarValueExists}
                setIsSearchBarValueExists={setIsSearchBarValueExists}
                filterSearchKey={programFilterSearchKey}                
                userMessage={userMessage}>
              </ProgramsTabPanel>
            </div>) :
            (<div>
              {((constraintsData !== undefined) && index == 3) ? <div>
                <ConstraintsTabPanel selectedPlanId={selectedPlanId} constraintsData={constraintsData} handleSwitchView={handleSwitchView}
                  currentView={currentView} showSaveOption={showSaveOption} refreshCells={refreshCells} downloadCSV={downloadCSV}
                  gridReducer={contraintsGridReducer} handleAddNewRow={(e) => handleAddNewRow('constraintsgrid')}
                  gridColumnDef={constraintsGridColDef}
                  handleRowEditingStarted={handleRowEditingStarted}
                  handleRowEditingStopped={handleRowEditingStopped}
                  handleCellEditingStopped={handleCellEditingStopped}
                  handleSaveCancelDisabled={saveCancelDisabled}
                  tabInfo={tabInfo} selectedval={selectedval} constraintTypeSelected={constraintTypeSelected}
                  constraintType={constraintType} tabIndex={value} handleSaveClicked={(e) => handleSaveClicked('constraintsgrid')}
                  handleCancelClicked={(e) => handleCancelClicked('constraintsgrid')}
                  constrainttypes={constrainttypes}
                  deleteSelectedRows={(e) => deleteSelectedRows('constraintsgrid')}
                  bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                  handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                  scrollToTop={scrollToTop}
                  filterSearchKey={constraintFilterSearchKey}
                  transactions={contraintsGridReducer.transactions}
                  filterParams={constraintsFilterParams}
                  currentData={contraintsGridReducer.currentData}
                  constraintsWholeData={constraintsWholeData}
                  constraintsUploadState={constraintsUploadState}                  
                  isFiltered={isFiltered}
                  setIsFiltered={setIsFiltered}
                  isSearchBarValueExists={isSearchBarValueExists}
                  setIsSearchBarValueExists={setIsSearchBarValueExists}
                  userMessage={userMessage}
                  >
                </ConstraintsTabPanel>
              </div>
                : ((parametersData !== undefined) && index === 7) ?
                  (<div>
                    <ParametersTabPanel showSaveOption={showSaveOption} refreshCells={refreshCells}
                      currentView={currentView} gridReducer={parametersGridReducer}
                      handleAddNewRow={(e) => handleAddNewRow('parametersgrid')}
                      handleSaveClicked={(e) => handleSaveClicked('parametersgrid')} handleCancelClicked={(e) => handleCancelClicked('parametersgrid')}
                      handleRowEditingStarted={handleRowEditingStarted} handleRowEditingStopped={handleRowEditingStopped}
                      parametersGridColDef={parametersGridColDef}
                      handleCellEditingStopped={handleCellEditingStopped}
                      handleSaveCancelDisabled={saveCancelDisabled}
                      userMessage={userMessage}>
                    </ParametersTabPanel>
                  </div>) :
                  (index === 5) ?
                    (<div>
                      <UpgradePathsTabPanel
                        upgradeTemplatesData={upgradeTemplatesData}
                        upgradeButtonSelected={upgradeButtonSelected}
                        getSelectedUpgradeButton={getSelectedUpgradeButton}
                        templateGridReducer={templatesGridReducer}
                        templatesGridColDef={templatesGridColumnDef}
                        showSaveOption={showSaveOption}
                        tabIndex={value}
                        filterSearchData={templateFilterSearchFlag == true ? templateFilterSearchData : upgradeTemplatesData}
                        upgradePathfilterSearchData={upgradePathFilterSearchFlag == true ? upgradePathFilterSearcData : upgradePathData}
                        handleRowEditingStarted={handleRowEditingStarted}
                        upgradePathData={upgradePathData}
                        tabInfo={tabInfo}
                        selectedval={selectedval}
                        currentView={currentView}
                        templateFilterSearchFlag={templateFilterSearchFlag}
                        upgradePathFilterSearchFlag={upgradePathFilterSearchFlag}
                        handleRowEditingStopped={handleRowEditingStopped}
                        handleSwitchView={handleSwitchView}
                        upgradePathGridReducer={upgradePathGridReducer}
                        upgradePathColumnDef={upgradePathColumnDef}
                        handleSaveClicked={(e) => handleSaveClicked((upgradeButtonSelected == 'UPGRADE TEMPLATES') ? 'upgradetemplatesgrid' : 'upgradepathsgrid')}
                        handleCancelClicked={(e) => handleCancelClicked((upgradeButtonSelected == 'UPGRADE TEMPLATES') ? 'upgradetemplatesgrid' : 'upgradepathsgrid')}
                        refreshCells={refreshCells}
                        SelectedButtonIndex={SelectedButtonIndex}
                        handleAddNewRow={(e) => handleAddNewRow('upgradepathsgrid')}
                        handleCellEditingStopped={handleCellEditingStopped}
                        handleSaveCancelDisabled={saveCancelDisabled}
                        upgradePathRowDataInvalid={upgradePathRowDataInvalid}
                        deleteSelectedRows={(e) => deleteSelectedRows('upgradepathsgrid')}
                        bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                        handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                        isFiltered={isFiltered}
                        setIsFiltered={setIsFiltered}
                        isSearchBarValueExists={isSearchBarValueExists}
                        setIsSearchBarValueExists={setIsSearchBarValueExists}
                        userMessage={userMessage}
                        downloadCSV={downloadCSV}
                        selectedPlanId={selectedPlanId}
                      >
                      </UpgradePathsTabPanel>
                    </div>) :
                    (index === 1) ?
                      (<div>
                        <SchedulesTabPanel
                          schedulesData={schedulesData} handleSwitchView={handleSwitchView}
                          showSaveOption={showSaveOption} refreshCells={refreshCells}
                          currentView={currentView} gridReducer={schedulesGridReducer}
                          handleAddNewRow={(e) => handleAddNewRow('schedulesgrid')}
                          handleSaveClicked={(e) => handleSaveClicked('schedulesgrid')} handleCancelClicked={(e) => handleCancelClicked('schedulesgrid')}
                          handleRowEditingStarted={handleRowEditingStarted} handleRowEditingStopped={handleRowEditingStopped}
                          schedulesGridColDef={schedulesGridColDef}
                          tabInfo={tabInfo} tabIndex={value} handleSaveCancelDisabled={saveCancelDisabled}
                          handleCellEditingStopped={handleCellEditingStopped}
                          selectedPlanId={selectedPlanId} deleteSelectedRows={(e) => deleteSelectedRows('schedulesgrid')}
                          bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                          handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                          scheduleTransactions={schedulesGridReducer.transactions}
                          currentData={schedulesGridReducer.currentData}
                          filteredData={schedulesFilterData}
                          filterParams={SchedulesFilterParams}
                          downloadCSV={downloadCSV}                          
                          isFiltered={isFiltered}
                          setIsFiltered={setIsFiltered}
                          isSearchBarValueExists={isSearchBarValueExists}
                          setIsSearchBarValueExists={setIsSearchBarValueExists}
                          userMessage={userMessage}
                        >
                        </SchedulesTabPanel>
                      </div>) :
                      (index === 2) ?
                        (<div>
                          <AllocatedTabPanel
                            allocatedData={allocatedData} handleSwitchView={handleSwitchView}
                            showSaveOption={showSaveOption} refreshCells={refreshCells} downloadCSV={downloadCSV}
                            currentView={currentView} gridReducer={allocatedGridReducer}
                            handleAddNewRow={(e) => handleAddNewRow('allocatedgrid')}
                            handleSaveClicked={(e) => handleSaveClicked('allocatedgrid')} handleCancelClicked={(e) => handleCancelClicked('allocatedgrid')}
                            handleRowEditingStarted={handleRowEditingStarted} handleRowEditingStopped={handleRowEditingStopped}
                            allocatedGridColDef={allocatedGridColDef}
                            tabInfo={tabInfo} tabIndex={value} handleSaveCancelDisabled={saveCancelDisabled}
                            handleCellEditingStopped={handleCellEditingStopped}
                            selectedPlanId={selectedPlanId} deleteSelectedRows={(e) => deleteSelectedRows('allocatedgrid')}
                            bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                            handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                            filterData={allocatedFilterData}
                            filterParams={allocatedFilterParams}
                            transactions={allocatedGridReducer.transactions}                            
                            isFiltered={isFiltered}
                            setIsFiltered={setIsFiltered}
                            isSearchBarValueExists={isSearchBarValueExists}
                            setIsSearchBarValueExists={setIsSearchBarValueExists}
                            userMessage={userMessage}
                          >
                          </AllocatedTabPanel>
                        </div>) :
                         (index === 6) ?
                        (<div>
                          <PlanAssumptionsTabPanel 
                            ageMileageData={ageMileageData} 
                            additionalCostData={additionalCostData}
                            mileageDefaultData={mileageDefaultData}
                            addsInvalidPoolsData={addsInvalidPoolsData}
                            salvageRateData={salvageRateData}
                            targetZonePoolData={targetZonePoolData}
                            // zoneMapData={zoneMapData} //Moved to Metadata
                            mileageTableData={mileageTableData}
                            handleAddNewRow={handleAddNewRow}
                            showSaveOption={showSaveOption} 
                            refreshCells={refreshCells}
                            currentView={currentView}
                            movecostFilterDisplayData={movecostFilterDisplayData}
                            gridReducer={ageMileageGridReducer}
                            additionCostgridReducer={additionalCostGridReducer}
                            mileageDefaultgridReducer={mileageDefaultGridReducer}
                            salvageRategridReducer={salvageRateGridReducer}
                            targetZonePoolgridReducer={targetZonePoolGridReducer}
                            // zoneMapgridReducer={zoneMapGridReducer} //Moved to Metadata
                            mileageTableGridReducer={mileageTableGridReducer}
                            handleRowEditingStarted={handleRowEditingStarted} 
                            handleRowEditingStopped={handleRowEditingStopped}
                            ageMileageGridColDef={ageMileageGridColDef}
                            additionalCostGridColDef={additionalCostGridColDef}
                            mileageDefaultGridColDef={mileageDefaultGridColDef}
                            salvageRateGridColDef={salvageRateGridColDef}
                            targetZonePoolGridColDef={targetZonePoolGridColDef}
                            // zoneMapGridColDef={zoneMapGridColDef} //Moved to Metadata
                            mileageTableGridColDef={mileageTableGridColDef}
                            handleSaveCancelDisabled={saveCancelDisabled}
                            handleCellEditingStopped={handleCellEditingStopped}
                            selectedPlanId={selectedPlanId}
                            bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                            handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                            ageMileageTransactions={ageMileageGridReducer.transactions}
                            additionalCostTransactions={additionalCostGridReducer.transactions}
                            mileageDefaultTransactions={mileageDefaultGridReducer.transactions}
                            salvageRateTransactions={salvageRateGridReducer.transactions}
                            targetZonePoolTransactions={targetZonePoolGridReducer.transactions}
                            // zoneMapTransactions={zoneMapGridReducer.transactions} //Moved to Metadata
                            mileageTableTransactions={mileageTableGridReducer.transactions}
                            currentData={ageMileageGridReducer.currentData}
                            additionalCostCurrentData={additionalCostGridReducer.currentData}
                            mileageDefaultCurrentData={mileageDefaultGridReducer.currentData}
                            salvageRateCurrentData={salvageRateGridReducer.currentData}
                            targetZonePoolCurrentData={targetZonePoolGridReducer.currentData}
                            // zoneMapCurrentData={zoneMapGridReducer.currentData} //Moved to Metadata
                            mileageTableCurrentData={mileageTableGridReducer.currentData}
                            manualVinDeletesData={manualVinDeletesData}
                            manualVinDeletesGridReducer={manualVinDeletesGridReducer}
                            manualVinDeletesCurrentData={manualVinDeletesGridReducer.currentData}
                            manualVinDeletesGridColumnDef={manualVinDeletesGridColumnDefReducer}
                            manualVinDeletesTransactions={manualVinDeletesGridReducer.transactions}
                            manualVinDeletesRowDataInvalid={manualVinDeletesRowDataInvalid}
                            manualVinDeletesFilterDisplayData={manualVinDeletesFilterDisplayData}
                            //filteredData={ageMileageFilterData}
                            //filterParams={ageMileageFilterParams}
                            //filterSearchKey={ageMileageFilterSearchKey}
                            downloadCSV={downloadCSV}
                            pending={pending}
                            userMessage={userMessage}
                            fetchValidTransactions={fetchValidTransactions}
                            fetchInvalidTransactions={fetchInvalidTransactions}
                            isSaveClicked={isSaveClicked}
                            setIsSaveClicked={setIsSaveClicked}
                            setSnackBarOpen={setSnackBarOpen}
                            setShowSaveOption={setShowSaveOption}
                            setSnackBarIfError={setSnackBarIfError}
                            setRefreshCells={setRefreshCells}
                            setIsCancelClicked={setIsCancelClicked}
                            snackBarIfError={snackBarIfError}
                            setWarningSnackBarOpen={setWarningSnackBarOpen}
                            selectedurlHasMainInputs={selectedurlHasMainInputs}
                             >
                          </PlanAssumptionsTabPanel>
                        </div>) :
                        ((moveConstraintsData !== undefined) && index == 4) ?
                          <div>
                          <MoveConstraintsTabPanel selectedPlanId={selectedPlanId} moveConstraintsData={moveConstraintsData} handleSwitchView={handleSwitchView}
                            currentView={'grid'} showSaveOption={showSaveOption} refreshCells={refreshCells} downloadCSV={downloadCSV}
                            gridReducer={moveConstraintsGridReducer} handleAddNewRow={(e) => handleAddNewRow('moveconstraintsgrid')}
                            gridColumnDef={moveConstraintsGridColDef}
                            handleRowEditingStarted={handleRowEditingStarted}
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleCellEditingStopped={handleCellEditingStopped}
                            handleSaveCancelDisabled={saveCancelDisabled}
                            tabInfo={tabInfo} selectedval={selectedval}
                            tabIndex={value} handleSaveClicked={(e) => handleSaveClicked('moveconstraintsgrid')}
                            handleCancelClicked={(e) => handleCancelClicked('moveconstraintsgrid')}
                            deleteSelectedRows={(e) => deleteSelectedRows('moveconstraintsgrid')}
                            bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                            handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                            scrollToTop={scrollToTop}
                            filterSearchKey={moveConstraintFilterSearchKey}
                            //filterParams={constraintsFilterParams}
                            transactions={moveConstraintsGridReducer.transactions}
                            currentData={moveConstraintsGridReducer.currentData}                            
                            isFiltered={isFiltered}
                            setIsFiltered={setIsFiltered}
                            isSearchBarValueExists={isSearchBarValueExists}
                            setIsSearchBarValueExists={setIsSearchBarValueExists}
                            userMessage={userMessage}
                            >
                          </MoveConstraintsTabPanel>
                        </div>: ""}
            </div>)
          }
        </TabPanel >
      ))
      }
      <div>
        <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleCloseSnackBar}>
          <Alert
            onClose={handleCloseSnackBar}
            severity="error">
            Cannot save. Please review if any row is invalid.
          </Alert>
        </Snackbar>
      </div>
      <div>
        <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
          <Alert
            onClose={handleCloseWarningSnackBar}
            severity={severity}>{warningMessage}
          </Alert>
        </Snackbar>
      </div>
      {/* <div>
        <DialogBox open={errorDialogOpen} handleClose={handleDialogClose} handleYes={handleYes}
          dialogMessage={message} title={title} action={oktext}
          canceltext={canceltext}>
        </DialogBox>
      </div> */}
    </div >
  );
}