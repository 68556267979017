import { ADD_ROW, COPY_ROW, EDIT_ROW } from "../MultiGrid/MultiGrid.Actions";
import { MAIN_AND_PLANINPUTS_UPDATEGRID_ONVALIDATION, NEW_ADD_ROW, NEW_CLEAN_TRASACTIONS, NEW_COPY_ROW, NEW_DELETE_ROW, NEW_DELETE_ROW_EDIT,
    NEW_EDIT_ROW, NEW_EDIT_ROW_MULTIPLE, NEW_POPULATE_GRID, NEW_POPULATE_GRID2, NEW_REFRESH_GRID, NEW_RESET_MULTI_GRID_STATE, 
    NEW_REVERT_ROW, NEW_UPDATE_IS_ROW_INVALID, NEW_VALIDATE_GRID, NEW_VALIDATE_ROW } 
    from "./MainPlanInputsMultiGrid.Actions";

const initialState = {
   originalData: [],
   currentData: [],
   colFieldParams: [],
   errors: [],
   transactions: [],
   copyData:[],
   isAnyRowInvalid: false
}

const addRowData = (newrowindex, currentData, newRow, status) => {
   let deeplyCopiedNewRow = { ...newRow };

   //This will nullify the updated_by , updated_on & row_validation_status in new row for all grids
   if(deeplyCopiedNewRow.updated_on || deeplyCopiedNewRow.updated_by || deeplyCopiedNewRow.row_validation_status ){
       deeplyCopiedNewRow.updated_by='';
       deeplyCopiedNewRow.updated_on='';
       deeplyCopiedNewRow.row_validation_status="";
   }

   deeplyCopiedNewRow.status = status;
   deeplyCopiedNewRow.ID = (new Date()).getTime();
   currentData.splice(newrowindex, 0, deeplyCopiedNewRow);
   return [...currentData]; //this is important
}

const deleteRowData = (id, currentData) => {
   currentData.map((value, index) => {
       let res = []
       if (value.ID == id) {
           res = currentData.splice(index, 1);
       }
       return [...res];
   });

   return [...currentData];
}

const getTransactionData = (transactionType, transactionId, transactions, data, gridName,originalData,currentData,updatedRowIndex) => {
   let newTransaction = { type: transactionType, id: transactionId, data: data };
   let updateTransactions = [...transactions];
   //let isArrayNotEmpty = true;
   //Look for transaction
   var index = updateTransactions.findIndex(function (item, i) {
       return (item.id == transactionId)
   });
    if (index === -1) {//If not found, insert transaction
        updateTransactions.push(newTransaction);
    }
    else { //If found, 
        if (transactionType == 'delete') {
            //and previous transaction was local (new/add)
            let rowTransactionType=updateTransactions[index].type // type of the transaction perfomed on a row

            if (rowTransactionType == 'new' || rowTransactionType == 'add' || rowTransactionType == 'copy') {//this will be true when user tries to delete the new,add or copy row
                updateTransactions.splice(index, 1);
            }
            else if (rowTransactionType != 'delete') {// this else will be true only when we delete the edited row
                updateTransactions[index] = { ...updateTransactions[index], type: transactionType, data: newTransaction.data };
            }
        }
        else if (transactionType == 'revert') {
            let revertrow = originalData.find(o => o.ID === transactionId)
            if (revertrow) {
                updateTransactions.splice(index, 1);//just remove
            }
            else {
                transactions.map((itm, key) => {
                    if (itm.id === transactionId) {
                        transactions[key].data = currentData[updatedRowIndex]
                    }
                })
            }
        }
        //    else if (transactionType == 'delete' || updateTransactions.length == 0) {
        //        isArrayNotEmpty = updateTransactions.some(item => item.hasOwnProperty('type'));
        //        if (!isArrayNotEmpty) {
        //            updateTransactions = [];
        //        }
        //    } 
        else { //update the data object
            updateTransactions[index] = { ...updateTransactions[index], data: newTransaction.data };
        }
    }
   return updateTransactions.map(o => { return { ...o }; });

}

const getMultipleTransactionData = (transactionType, newSetOfTransactions, transactions,  gridName,originalData,currentData,updatedRowIndex) => {

   if(transactionType==="edit"){
       let updateTransactions = [...transactions];
       if(updatedRowIndex && updatedRowIndex.length){
           updatedRowIndex.forEach((index)=>{
               let newTransaction={type:transactionType, id: newSetOfTransactions.validateData[index].ID, data:newSetOfTransactions.validateData[index]};
               let updateTransactionsIndex = updateTransactions.findIndex((updatedTransaction) =>{
                   return (updatedTransaction.id === newTransaction.id);
               });
               
               if (updateTransactionsIndex === -1) //If not found, insert transaction
                   updateTransactions.push(newTransaction);
               else { //If found, 
                   updateTransactions[updateTransactionsIndex] = { ...updateTransactions[updateTransactionsIndex], data: newTransaction.data };
               }
           });
       }
       
       let updatedTransactions= updateTransactions.map(o => { return { ...o }; });
          return updatedTransactions;
   }else{
       return transactions;
   }
}

const refreshGrid = (refreshData) => {
   refreshData.forEach((element) => {
       delete element["validations"];
       delete element["status"];
       delete element["colDef"]
       delete element["isEdited"]
   });
   //return deep copy
   return refreshData.map(o => { return { ...o }; });
}

const revertRowData = (revertedrowindex, revertedrowid, currentData, originalData, colFieldParams,copyData) => {
   let originalrow = originalData.find(o => o.ID === revertedrowid)
   var index = colFieldParams.findIndex(function (item, i) {
       return item.id === currentData[revertedrowindex].ID
   });
   if(originalrow != undefined){
   currentData[revertedrowindex].validations = [];
   currentData[revertedrowindex].status = "";
   } 
   if (index > -1)
       currentData[revertedrowindex].colDef = [...colFieldParams.splice(index, 1)];
       if(originalrow != undefined){
   currentData[revertedrowindex] = { ...originalrow };
       }else{
           let cData=copyData.find(c=>c.ID===revertedrowid)
           if(cData!= undefined){
           currentData[revertedrowindex]=cData
           }
       }
   return currentData.map(o => { return { ...o }; }); //return deep copy
}

const fetchUpdatedRowIndex = (currentData, updatedrow) => {
   let updatedrowindex = currentData.map(function (e) {
       return e.ID;
   }).indexOf(updatedrow.ID);
   return updatedrowindex;
}

//Mass change : Get row index numbers for each updated rows
const fetchMultipleUpdatedRowIndexs = (currentData, colDef) => {
   let updatedDataIds=[];
   if(colDef.id && colDef.id.length){
       currentData.forEach((data,index)=>{
           if(colDef.id.includes(data.ID)){
               updatedDataIds.push(index);
           }
       })
   }
   return updatedDataIds;
}

const editRowDataMultiple = (gridName, currentData, updatedrowActual, validationfn, colFieldParams, colDef) => {
    let rowIds=colDef.id;

    for(let i=0;i<rowIds.length;i++){
        let updatedrowindex = currentData.map(function (e) {
            return e.ID;
        }).indexOf(rowIds[i]); 
        let updatedrow=currentData[updatedrowindex];
        if (updatedrow.status !== ADD_ROW && updatedrow.status !== COPY_ROW){
            updatedrow.status = EDIT_ROW;
        }
        currentData[updatedrowindex] = { ...updatedrow };
        let validationResult = validationfn(currentData[updatedrowindex]);
        currentData[updatedrowindex].validations = { ...validationResult };
        const id = currentData[updatedrowindex].ID;
        if (!colFieldParams || colFieldParams.length === 0) {
            colFieldParams.push({ id: rowIds[i], value: colDef.value });
        } else if (colFieldParams && colFieldParams.length > 0 &&
            (rowIds[i] === id && colDef.value !== colFieldParams[colDef.value])) {
            colFieldParams.push({ id: rowIds[i], value: colDef.value });
        }
        currentData[updatedrowindex].colDef = colFieldParams; 
    }
    return currentData.map(o => { return { ...o }; }); //return deep copy
}

const editRowData = (gridName, currentData, updatedrow, validationfn, colFieldParams, colDef) => {
    let updatedrowindex = currentData.map(function (e) {
        return e.ID;
    }).indexOf(updatedrow.ID); 
    if (updatedrow.status !== ADD_ROW && updatedrow.status !== COPY_ROW)
        updatedrow.status = EDIT_ROW;
    currentData[updatedrowindex] = { ...updatedrow };
    let validationResult = validationfn(currentData[updatedrowindex]);
    currentData[updatedrowindex].validations = { ...validationResult };
    const id = currentData[updatedrowindex].ID;
    if (!colFieldParams || colFieldParams.length === 0) {
        colFieldParams.push(colDef);
    } else if (colFieldParams && colFieldParams.length > 0 &&
        (colDef.id === id && colDef.value !== colFieldParams[colDef.value])) {
        colFieldParams.push(colDef);
    }
    currentData[updatedrowindex].colDef = colFieldParams; 
    return currentData.map(o => { return { ...o }; }); //return deep copy
}

const assignErrorStatus = (validateData, duplicateRowEntries) => {
   let isAnyRowInvalid = false;
   validateData.forEach((data, index) => {
       if (data.status === "ADD_ROW" && Object.keys(data).length <= 2) {
           validateData[index].status = "ERROR_NEW_ROW";
           isAnyRowInvalid = true;
       } else {
           if (duplicateRowEntries) {
               isAnyRowInvalid = true;
           }
           if (data.validations) {
               var keys = Object.keys(data.validations);
               var counter = -1;
               keys.forEach(element => {
                   if (element !== 'ID') {
                       if (data.validations[element].error === null || data.validations[element].error === undefined) {
                       } else {
                           counter++;
                       }
                   }
               });
               if (counter >= 0) {
                   if(validateData[index].status == "ADD_ROW" || validateData[index].status == "COPY_ROW") {
                       validateData[index].status = "INVALID_NEW_ROW";
                       isAnyRowInvalid = true;
                   } else {
                       validateData[index].status = "INVALID_ROW";
                       isAnyRowInvalid = true;
                   }
               }
           }
       }
   });

   return { isAnyRowInvalid: isAnyRowInvalid, validateData: validateData.map(o => { return { ...o }; }) } //return deep copy
}


const validateGridOnLoad = (validateData, duplicateRowEntries, validationFn,transactions,uploadState) => {
   let isRowDataInvalid = false;
   validateData.forEach((data, index) => {
       var inValidRowCounter = -1;
       if (duplicateRowEntries && duplicateRowEntries.length > 0) {
           isRowDataInvalid = true;
       }
       let validationResult = validationFn(data,transactions,uploadState);
       data.validations = { ...validationResult };
       if (data.validations) {
           var keys = Object.keys(data.validations);
           var inValidCellCounter = -1;
           keys.forEach(element => {
               if (data.validations[element].error === null || data.validations[element].error === undefined) {
                   } else {
                   inValidCellCounter++;
               }
           });
           if (inValidCellCounter >= 0) {
               if(validateData[index].status == "ADD_ROW") {
                   validateData[index].status = "INVALID_NEW_ROW";
                   isRowDataInvalid = true;
               } else if (validateData[index].status !== "ERROR_ROW" && validateData[index].status !== "ERROR_NEW_ROW" && validateData[index].status !== "ERROR_COPY_ROW") {
                   validateData[index].status = "INVALID_ROW";
                   isRowDataInvalid = true;
               }
           }
           // else
           //     validateData1[index].status = "VALID_ROW";
       }
   });
   return { isAnyRowInvalid: isRowDataInvalid, validateData: validateData.map(o => { return { ...o }; }) }; //return deep copy
}

const businessValidation = (currentData, businessValidationFn) => {
   let duplicateRowEntries = businessValidationFn(currentData);
   const { isAnyRowInvalid, validateData } = assignErrorStatus(currentData, duplicateRowEntries);
   
   return { isAnyRowInvalid: isAnyRowInvalid, validateData: validateData.map(o => { return { ...o }; }) }; //return deep copy
}

const primitiveValidation = (currentData, validationFn, businessValidationFn, transactions,validate,uploadState) => {
   let duplicateRowEntries = businessValidationFn(currentData,transactions,uploadState);
   let { isAnyRowInvalid, validateData } = validateGridOnLoad(currentData, duplicateRowEntries, validationFn,transactions,uploadState);
   var sortedArray = [];
   if(validate){
       validateData.forEach(element => {
       if (element.status && (element.status == "INVALID_ROW" || element.status == "INVALID_NEW_ROW" || element.status == "ERROR_ROW" || element.status == "ERROR_NEW_ROW" || element.status == "ERROR_COPY_ROW")) {
           sortedArray.push(element);
       }
   });
   validateData.forEach(element => {
       if (element.status != "INVALID_ROW" && element.status != "ERROR_ROW" && element.status != "ERROR_COPY_ROW" 
           && element.status != "INVALID_NEW_ROW" && element.status != "ERROR_NEW_ROW") {
           sortedArray.push(element);
       }
   });
   } else {
       validateData.forEach(element => {
           sortedArray.push(element);
   });
   }   
   return { isAnyRowInvalid: isAnyRowInvalid, validateData: sortedArray.map(o => { return { ...o }; }) }; //return deep copy
}


const callFilterSearchFunction = (searchKey, data, checkType, checkValue) => {
   let result = getSearchResults(searchKey, data, checkType, checkValue);
   return result;
}

function getSearchResults(searchkey, data, checkType, checkValue) {
   return data.filter((item, i) => {
       let entries = Object.entries(data[i]);
       let checkvalue = true;
       return entries.find((val, ind) => {
           if (checkValue !== undefined) {
               let res = checkValue(val[0]);
               checkvalue = res.value;
           }
           if (checkvalue) {
               if (!searchkey.includes('$') && !searchkey.includes(',') && !searchkey.includes('%') && val[1] !== null && val[1] != '' && val[1] !== undefined) {
                   return (val[1].toString().toLowerCase().includes(searchkey.toLowerCase()))
               }
               else if (searchkey.includes('$') && checkType !== undefined && val[1] !== null && val[1] != '' && val[1] !== undefined) {
                   let checktype = checkType(val[0]);
                   if (checktype[val[0]] !== undefined && checktype[val[0]].type == 'currency') {
                       if (searchkey.split('$')[0] == '') {
                           let searchkey1 = searchkey.split('$')[1];
                           if (searchkey1.includes(',')) {
                               return (val[1].toString().toLowerCase().startsWith(searchkey1.toString().replace(/,/g, '')))
                           }
                           else {
                               return (val[1].toString().toLowerCase().startsWith(searchkey1.toLowerCase()))
                           }
                       }
                   }
               }
               else if (searchkey.includes(',') && !searchkey.includes('$') && val[1] !== null && val[1] != '' && val[1] !== undefined) {
                   return (val[1].toString().toLowerCase().startsWith(searchkey.toString().replace(/,/g, '')))
               }
               else if (searchkey.includes('%') && checkType !== undefined && val[1] !== null && val[1] != '' && val[1] !== undefined) {
                   let checktype = checkType(val[0]);
                   if (checktype[val[0]] !== undefined && checktype[val[0]].type == 'percentage') {
                       if (searchkey.split('%')[1] == '') {
                           let searchkey1 = searchkey.split('%')[0];
                           if (searchkey1.includes(',')) {
                               return (val[1].toString().toLowerCase().startsWith(searchkey1.toString().replace(/,/g, '')))
                           }
                           else {
                               return (val[1].toString().toLowerCase().startsWith(searchkey1.toLowerCase()))
                           }
                       }
                   }
               }
           }
           else if(searchkey.includes(',') && !searchkey.includes('$') && val[1]!==null && val[1]!='' && val[1]!==undefined){
               return (val[1].toString().toLowerCase().startsWith(searchkey.toString().replace(/,/g, '')))
           }
           else if (searchkey.includes('%') && checkType !== undefined && val[1] !== null && val[1] != '' && val[1] !== undefined) {
               let checktype = checkType(val[0]);
               if (checktype[val[0]] !== undefined && checktype[val[0]].type == 'percentage') {
                   if (searchkey.split('%')[1] == '') {
                       let searchkey1 = searchkey.split('%')[0];
                       if (searchkey1.includes(',')) {
                           return (val[1].toString().toLowerCase().startsWith(searchkey1.toString().replace(/,/g, '')))
                       }
                       else {
                           return (val[1].toString().toLowerCase().startsWith(searchkey1.toLowerCase()))
                       }
                   }
               }
           }
       })
   })
}

function updateDataOnValidation(currentdata,updatedTransactionsList){
    currentdata.map((data1,index)=>{
        updatedTransactionsList.map((data2,ind)=>{
            if(data1.ID==data2.data.ID){
              currentdata[index]=data2.data;
            }
        })
    })
    return currentdata.map(o => { return { ...o }; }); //return deep copy
}

export default function multiGridUIReducer(state = initialState, action) {
   let validate,updatedtransactionsMultipleAfterValidations;
   switch (action.type) {
       case NEW_REFRESH_GRID:
           state.copyData=[]
           return { ...state, currentData: refreshGrid(state.currentData), colFieldParams: [], transactions: [] };
       case NEW_VALIDATE_ROW:
           const { isAnyRowInvalid, validateData } = businessValidation(state.currentData, action.data.businessValidationFn)
           return { ...state, currentData: validateData, isAnyRowInvalid: isAnyRowInvalid };
       case NEW_VALIDATE_GRID:
           var validation = primitiveValidation(action.data.data, action.data.validationFn, action.data.businessValidationFn, state.transactions,validate=true);
           //THis validateTransactions will be true only when there are transactions and master cache data has been updated by admin 
           //Only then revalidation of existing transaction with new master cache is done here. 
           if(state.transactions.length>0){
           var rowIds={'id':[]}
           state.transactions.map((val)=>{
               if(val.id){
                   rowIds['id'].push(val.id)
               }
           })
            let updatedTransactionIndexMultiple = fetchMultipleUpdatedRowIndexs(validation.validateData, rowIds);
            updatedtransactionsMultipleAfterValidations =getMultipleTransactionData("edit", validation, state.transactions, action.name,state.originalData,state.currentData,updatedTransactionIndexMultiple); 
           } else {
               updatedtransactionsMultipleAfterValidations=state.transactions
           }
            return { ...state, currentData: validation.validateData, isAnyRowInvalid: validation.isAnyRowInvalid,transactions:updatedtransactionsMultipleAfterValidations };
       case NEW_POPULATE_GRID:
           if (action.checkIfRequiredFn) {
               action.data.reduce((i, o) => { o.required = action.checkIfRequiredFn ? action.checkIfRequiredFn(o) : false }, {});
           }
           let deepCopyOfDataOne = action.data.map(o => { return { ...o }; });
           let deepCopyOfDataTwo = action.filterSearchKey ? callFilterSearchFunction(action.filterSearchKey, action.data, action.checkType, action.checkValue) : action.data.map(o => { return { ...o }; });
           return { ...state, originalData: deepCopyOfDataOne, currentData: deepCopyOfDataTwo, transactions: []};
       case NEW_POPULATE_GRID2:
           if (action.checkIfRequiredFn) {
               action.currentData.reduce((i, o) => { o.required = action.checkIfRequiredFn ? action.checkIfRequiredFn(o) : false }, {});
           }
           let deepCopyOfDataOne2 = action.originalData.map(o => { return { ...o }; });
           var validation = primitiveValidation(action.currentData, action.data.validationFn, action.data.businessValidationFn, state.transactions,validate=true,action.uploadState); //added this to do validation on apply or clear filter 
           let deepCopyOfDataTwo2 = action.filterSearchKey ? callFilterSearchFunction(action.filterSearchKey, validation.validateData, action.checkType, action.checkValue) : validation.validateData; //TO REVIEW
           return { ...state,  originalData: deepCopyOfDataOne2,currentData: deepCopyOfDataTwo2};
       case NEW_EDIT_ROW:
           state.currentData=action.data.sortedData.length>0?action.data.sortedData:state.currentData
           let updatedData1 = editRowData(action.name, state.currentData, action.data.data,
               action.data.validationfn, state.colFieldParams, action.data.colDef);
           updatedData1 = primitiveValidation(updatedData1, action.data.validationfn, action.data.businessValidationFn, state.transactions,validate=false);
           let updatedRowIndex = fetchUpdatedRowIndex(updatedData1.validateData, action.data.data);
           let transactions3 = getTransactionData("edit", updatedData1.validateData[updatedRowIndex].ID, state.transactions, updatedData1.validateData[updatedRowIndex], action.name,state.originalData,state.currentData)
           return { ...state,currentData:updatedData1.validateData, transactions: transactions3 };
       case NEW_EDIT_ROW_MULTIPLE:
           state.currentData=action.data.sortedData.length>0?action.data.sortedData:state.currentData
           let updatedData1Multiple = editRowDataMultiple(action.name, state.currentData, action.data.data,
               action.data.validationfn, state.colFieldParams, action.data.colDef);
           // console.log("updatedData1Multiple==",updatedData1Multiple);
           updatedData1Multiple = primitiveValidation(updatedData1Multiple, action.data.validationfn, action.data.businessValidationFn, state.transactions,validate=false);
           // console.log("updatedData1Multiple==",updatedData1Multiple);
           let updatedRowIndexMultiple = fetchMultipleUpdatedRowIndexs(updatedData1Multiple.validateData, action.data.colDef);
           // console.log("updatedRowIndexMultiple==",updatedRowIndexMultiple);
           let transactions3Multiple =getMultipleTransactionData("edit", updatedData1Multiple, state.transactions, action.name,state.originalData,state.currentData,updatedRowIndexMultiple);
           
           // console.log("transactions3Multiple==",transactions3Multiple);
           // let t=Object.values(transactions3Multiple);
           // console.log("t==",t);
           return { ...state,currentData:updatedData1Multiple.validateData, transactions: transactions3Multiple };
       case NEW_ADD_ROW:
           const updatedData2 = addRowData(action.data.rowindex, state.currentData, action.data.data, ADD_ROW);
           const transactions4 = getTransactionData("add", state.currentData[action.data.rowindex].ID, state.transactions, state.currentData[updatedRowIndex],action.name,state.originalData,state.currentData)
           return { ...state, currentData: updatedData2, transactions: transactions4 };
       case NEW_COPY_ROW:
           updatedRowIndex = fetchUpdatedRowIndex(state.currentData, action.data.data);
           const updatedData3 = addRowData(updatedRowIndex + 1, state.currentData, action.data.data, COPY_ROW);
           state.copyData.push({...updatedData3[updatedRowIndex],'ID':updatedData3[updatedRowIndex + 1].ID});
           const transactions5 = getTransactionData("copy", updatedData3[updatedRowIndex + 1].ID, state.transactions, updatedData3[updatedRowIndex + 1], action.name,state.originalData,state.currentData)
           return { ...state, currentData: updatedData3, transactions: transactions5 };
       case NEW_DELETE_ROW:
           const transactions6 = getTransactionData("delete", action.data.id, state.transactions,action.data,action.name,state.originalData,state.currentData)
           const updatedData4 = deleteRowData(action.data.id, state.currentData);
           return { ...state, currentData: updatedData4, transactions: transactions6 };
       case NEW_DELETE_ROW_EDIT:
           const transactions7 = getTransactionData("delete_save", action.data.id, state.transactions,action.data,action.name,state.originalData,state.currentData)
           const updatedData5 = deleteRowData(action.data.id, state.currentData);
           return { ...state, currentData: updatedData5, transactions: transactions7 };
       case NEW_REVERT_ROW:
           updatedRowIndex = fetchUpdatedRowIndex(state.currentData, action.data.data);
           let updatedData6 = revertRowData(updatedRowIndex, action.data.rowID, state.currentData, state.originalData, state.colFieldParams,state.copyData);
           updatedData6 = primitiveValidation(updatedData6, action.validationFn, action.businessValidationFn, state.transactions,validate=false);
           let transactions8 = getTransactionData("revert", updatedData6.validateData[updatedRowIndex].ID, state.transactions,action.data,null,state.originalData,state.currentData,updatedRowIndex)
           return { ...state, currentData: updatedData6.validateData, transactions: transactions8 };
       case NEW_UPDATE_IS_ROW_INVALID:
           return { ...state, isAnyRowInvalid:action.data.isAnyRowInvalid };
       case NEW_CLEAN_TRASACTIONS:
           return { ...state, transactions: [] };
       case NEW_RESET_MULTI_GRID_STATE:
           return initialState;
        case MAIN_AND_PLANINPUTS_UPDATEGRID_ONVALIDATION:
            const updatedData7 = updateDataOnValidation(state.currentData,action.data)
            return {...state,  currentData:updatedData7,transactions:action.data};
       default:
           return state;
   }
}