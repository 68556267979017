import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const SalvageRateTabPanel = (props)=>{
    const{showSaveOption,salvageRategridReducer,salvageRateTransactions,salvageRateGridColDef, ageMileageData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        ageMileageGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions, currentData,filteredData, filterParams,tabIndex, downloadCSV,
        userMessage,newArchitecture,gridName,loadGridData} = props;

 return(
     <div>
        {newArchitecture ?(<MainPlanInputsMultiGridUI reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={salvageRateGridColDef}
            rowEditType={'single'}
            suppressclickEdit={!salvageRateGridColDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            loadGridData={loadGridData}
            isPaginationRequired={true}/>)
            :(<MultiGridUI 
            reducer={salvageRategridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'salvagerategrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={salvageRateGridColDef}
            suppressclickEdit={!salvageRateGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            salvageRateTransactions={salvageRateTransactions}
            userMessage={userMessage}
            />
        )}
     </div>
 )
}

export default SalvageRateTabPanel;