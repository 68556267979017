import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { checkAndLoadDimention, minifyTransaction } from '../../../utils/utils';
import { getRoleInfo } from '../../topbar/AllPlansService';
import { ADMIN_ACCESS_REVOKED_ERROR_CODE } from '../../../Constants';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function getCarGroupData(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promisecarGroupResponse =  client.apis.dimensions.getAllCarsGroup({"isAdmin":true});

    const responsesArray = await Promise.all([promisecarGroupResponse]);

    const [carGroupResponse] = responsesArray.map(o => o.body.data);

    return ({
        "carGroupData": carGroupResponse
    });
}

export async function saveCarGroupTransactions(transactionList){
    console.log("transactionList===>",transactionList);
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
    if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let promiseArray = [];
    await client.apis.admin.bulkTransactCarGroup(null, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
    } else {
        throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
    }
}

export async function fetchCarGroupDropdownService(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const carGroupResponseData =  client.apis.plans.getAllCarGroup({ "planid": planId ? planId : 'master' });
    const promiseGetAllCarsGroup =  client.apis.dimensions.getAllCarsGroup({"isAdmin":true});

    const responsesArray = await Promise.all([
        //  carGroupResponseData,
          promiseGetAllCarsGroup,
    ]);

    const [
          //carGroupResponse,
          carsGroupResponse
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "carGroupData":carGroupResponse,
        "car_group_name": carsGroupResponse
    })  
}