export const ADMIN_EDIT_ROW = 'ADMIN_EDIT_ROW';
export const ADMIN_ADD_ROW = 'ADMIN_ADD_ROW';
export const ADMIN_COPY_ROW = 'ADMIN_COPY_ROW';
export const ADMIN_DELETE_ROW = 'ADMIN_DELETE_ROW';
export const ADMIN_DELETE_ROW_EDIT = 'ADMIN_DELETE_ROW_EDIT';
export const ADMIN_REVERT_ROW = 'ADMIN_REVERT_ROW';
export const ADMIN_POPULATE_GRID = 'ADMIN_POPULATE_GRID';
export const ADMIN_VALIDATE_ROW = 'ADMIN_VALIDATE_ROW';
export const ADMIN_REFRESH_GRID = 'ADMIN_REFRESH_GRID';
export const ADMIN_VALIDATE_GRID = 'ADMIN_VALIDATE_GRID';
export const ADMIN_CLEAN_TRASACTIONS = 'ADMIN_CLEAN_TRASACTIONS';
export const ADMIN_POPULATE_GRID2 = 'ADMIN_POPULATE_GRID2';

export const validateRow = (gridname, data, businessValidationFn)=>{
    return {
        name:gridname,
        type:ADMIN_VALIDATE_ROW,
        data:{
            data,
            businessValidationFn
        }
    }
}

export const validateGrid = (gridname, data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:ADMIN_VALIDATE_GRID,
        data:{
            data,
            validationFn,
            businessValidationFn
        }
    }
}

export const populateGrid = (gridname, data, filterSearchKey, checkType, checkValue, checkIfRequiredFn)=>{
    return {
        name:gridname,
        type:ADMIN_POPULATE_GRID,
        data:data,
        filterSearchKey:filterSearchKey,
        checkType:checkType,
        checkValue:checkValue,
        checkIfRequiredFn:checkIfRequiredFn,
    }
}

export const populateGrid2 = (gridname, originalDta,currentData,filterSearchKey,checkValue,validationFn, businessValidationFn,uploadState)=>{
    return {
        name:gridname,
        type:ADMIN_POPULATE_GRID2,
        originalData:originalDta,
        currentData:currentData,
        filterSearchKey:filterSearchKey,
        checkValue:checkValue,
        data:{
            validationFn,
            businessValidationFn
        },
        uploadState:uploadState
        }
}

export const completeEditRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:ADMIN_EDIT_ROW,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}

export const addNewRow = (gridname,rowindex,data)=>{
    return {
        name:gridname,
        type:ADMIN_ADD_ROW,
        data:{rowindex,data}
    }
}

export const copyNewRow = (gridname,rowindex,data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:ADMIN_COPY_ROW,
        data:{rowindex,data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}


export const deleteRow = (gridname,id)=>{
    return {
        name:gridname,
        type:ADMIN_DELETE_ROW,
        data:{id}
    }
}

export const deleteRowEditMode = (gridname,id)=>{
    return {
        name:gridname,
        type:ADMIN_DELETE_ROW_EDIT,
        data:{id}
    }
}

export const revertRow = (gridname,rowindex,rowID, data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:ADMIN_REVERT_ROW,
        data:{rowindex,rowID, data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}

export const refreshGridData = (gridName) => {
    return {
        name: gridName,
        type: ADMIN_REFRESH_GRID
    }
}

export const cleanTransactions = (gridName)=>{
    return {
        name: gridName,
        type: ADMIN_CLEAN_TRASACTIONS
    }
}