import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import './ParametersAutoCompleteEditor.css';
import { TextField, Tooltip} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

export default forwardRef((props, ref) => {
  const inputRef = useRef();
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  let rfOptions = (props.zoneRfoptions && props.poolRfoptions && props.tncRfoptions) ? (props.data.key === 'RF_CODE_ZONE' ? props.zoneRfoptions : (props.data.key === 'RF_CODE_TNC' ? props.tncRfoptions : props.poolRfoptions))  : [];

  function onChangeHandler(e, value) {
    setValue(value);
  }

  useEffect(o => {
    setTimeout(function () {
      if (inputRef && inputRef.current
        && inputRef.current.children.length > 0
        && inputRef.current.children[0].children.length > 0) {
        inputRef.current.children[0].children[0].select();
        inputRef.current.children[0].children[0].focus();
      }
    }, 100);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        if(inputValue){
            return inputValue.trim();
        }
        return inputValue;
    },
      afterGuiAttached: () => {
        setValue(props.value);
      }
    };
  });

  function onHighlightChangeHandler(e, option, reason) {
    if (option !== null && reason === 'keyboard') {
      setValue(option);
      setInputValue(option);
    }
  }

  function onInputChangeHandler(e, inputValue) {
    setInputValue(inputValue);
}

  return (
    <Autocomplete
      options={rfOptions && rfOptions.map(option=>option.name)}
      value={value}
      onChange={onChangeHandler}
      inputValue={inputValue}
      onInputChange={onInputChangeHandler}
      onHighlightChange={onHighlightChangeHandler}
      closeIcon=''
      className="rfParameters"
      renderInput={(params) => (
          <TextField
              ref={inputRef}
              {...params}
              style={{ padding: '5px 0'}} />
      )}
      renderOption={(  props ) => {
        return (
          <div>
            <Tooltip title={rfOptions.map(option=>{
              if(props===option.name){
                return option.description
              }
            })} placement="bottom" arrow="up">
              <div>
                <span component="li"  fullWidth>
                  {props}
                </span>
              </div>
            </Tooltip>
          </div>
        );
      }}
    />
  );
})