import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';

export async function getZoneMapData(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisezoneMapResponse =  client.apis.admin.getAllZoneMap();
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseAcceptableZonesResponse =  checkAndLoadDimention("acceptablezones", client);
    const responsesArray = await Promise.all([promisezoneMapResponse,promiseZoneResponse,promiseAcceptableZonesResponse]);

    const [zoneMapResponse,zones,acceptableZones] = responsesArray.map(o => o.body.data);
    // console.log("acceptableZones==>",acceptableZones);
    // console.log("zoneMapResponse==>",zoneMapResponse);
    return ({
        "zoneMapData": zoneMapResponse,"zones": zones
    });
}

export  async function uploadZoneMapCSV (file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.admin.uploadZoneMap(null,{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'uploadZoneMapCSV failed'}
    }
}

export async function saveZoneMapTransactions(transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    const responsesArray = await client.apis.admin.bulktransactzonemap(null, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });
    
    return responsesArray;
}

export async function fetchZoneMapDropdownService(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

  //  const ZoneMapResponseData =  client.apis.plans.getAllZoneMap({ "planid": planId ? planId : 'master' });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promisegetAcceptableZones =  checkAndLoadDimention("acceptablezones", client);

    const responsesArray = await Promise.all([
       // ZoneMapResponseData,
        promiseZoneResponse,
        promisegetAcceptableZones
    ]);

    const [
         // zoneMapResponse,
          ZoneResponse,
          acceptableZones
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "zoneMapData":zoneMapResponse,
        "zones": ZoneResponse,
        "acceptablezones": acceptableZones,
    })  
}