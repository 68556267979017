import { ConstraintsColumns, cellEditors } from "../../../Constants";
import { dateFormatter, dateFormatterConstraints, localeFormatter, updatedOnDateFormatter, valueFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customNumericSortComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: ConstraintsColumns.ConstraintTable.field, 
        headerName: ConstraintsColumns.ConstraintTable.headerName, 
        unSortIcon: true,editable:false,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.ConstraintTable.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.ConstraintTable.name)
        }
    },
    {
        field: ConstraintsColumns.ConstraintType.field, 
        headerName: ConstraintsColumns.ConstraintType.headerName, 
        unSortIcon: true,cellEditor: cellEditors.autoCompleteEditor,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.ConstraintType.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.ConstraintType.name)
        }
    },
    {
        field: ConstraintsColumns.ZoneName.field, 
        headerName: ConstraintsColumns.ZoneName.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.ZoneName.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.ZoneName.name)
        }
    },
    {
        field: ConstraintsColumns.Pool.field, 
        headerName: ConstraintsColumns.Pool.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.Pool.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.Pool.name)
        }
    },
    {
        field: ConstraintsColumns.FromDate.field, 
        headerName: ConstraintsColumns.FromDate.headerName, 
        cellEditor: cellEditors.dateEditor, unSortIcon: true,
        valueFormatter: params => dateFormatter(params.data.from_date),
        filterType:"datepicker",
        cellClassRules: cellClassRules(ConstraintsColumns.FromDate.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.FromDate.name)
        }
    },
    {
        field: ConstraintsColumns.ToDate.field, 
        headerName: ConstraintsColumns.ToDate.headerName, 
        cellEditor: cellEditors.dateEditor, unSortIcon: true,
        valueFormatter: params => dateFormatter(params.data.to_date),
        filterType:"datepicker",
        cellClassRules: cellClassRules(ConstraintsColumns.ToDate.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.ToDate.name)
        }
    },
    {
        field: ConstraintsColumns.Min.field, 
        headerName: ConstraintsColumns.Min.headerName, 
        unSortIcon: true, cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator,
        valueFormatter: params => {
            return localeFormatter(params.data.min);
        },
        filterType:"range",
        cellClassRules: cellClassRules(ConstraintsColumns.Min.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.Min.name)
        }
    },
    {
        field: ConstraintsColumns.Max.field, 
        headerName: ConstraintsColumns.Max.headerName, unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator,
        valueFormatter: params => {
            return localeFormatter(params.data.max);
        },
        filterType:"range",
        cellClassRules: cellClassRules(ConstraintsColumns.Max.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.Max.name)
        }
    },
    {
        field: ConstraintsColumns.BU.field, 
        headerName: ConstraintsColumns.BU.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.BU.field);
        // },
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.BU.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.BU.name)
        }
    },
    {
        field: ConstraintsColumns.ProgramType.field, 
        headerName: ConstraintsColumns.ProgramType.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.ProgramType.field);
        // },
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.ProgramType.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.ProgramType.name)
        }
    },
    {
        field: ConstraintsColumns.SpiCode.field, 
        headerName: ConstraintsColumns.SpiCode.headerName, 
        cellEditor: cellEditors.freeSoloAutoCompleteEditor, unSortIcon: true,
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.SpiCode.field);
        // },
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.SpiCode.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.SpiCode.name)
        }
    },
    {
        field: ConstraintsColumns.ManufacturerGroupCode.field, 
        headerName: ConstraintsColumns.ManufacturerGroupCode.headerName, 
        cellEditor: cellEditors.autoCompleteEditor,
        unSortIcon: true, 
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.ManufacturerGroupCode.field);
        // },
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.ManufacturerGroupCode.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.ManufacturerGroupCode.name)
        }
    },
    {
        field: ConstraintsColumns.CarGroupName.field, 
        headerName: ConstraintsColumns.CarGroupName.headerName, 
        cellEditor: cellEditors.autoCompleteEditor,
        unSortIcon: true, 
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.CarGroupName.field);
        // },
         filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.CarGroupName.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.CarGroupName.name)
        }
    },
    {
        field: ConstraintsColumns.MileageStart.field, 
        headerName: ConstraintsColumns.MileageStart.headerName, 
        minWidth: 110, unSortIcon: true,
        cellEditor: cellEditors.numericEditor, 
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.MileageStart.field);
        // },
        type: 'numericColumn',comparator: customNumericSortComparator,
        valueFormatter: params => {
            return localeFormatter(params.data.mileage_start);
        },
        filterType:"range",
        cellClassRules: cellClassRules(ConstraintsColumns.MileageStart.name),
         tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.MileageStart.name)
        }
    },
    {
        field: ConstraintsColumns.MileageEnd.field, 
        headerName: ConstraintsColumns.MileageEnd.headerName, 
        minWidth: 110, unSortIcon: true,
        cellEditor: cellEditors.numericEditor, 
        // editable: params => {
        //     return isColumnEditable(params, ConstraintsColumns.MileageEnd.field);
        // },
        type: 'numericColumn',comparator: customNumericSortComparator,
        valueFormatter: params => {
            return localeFormatter(params.data.mileage_end);
        },
        filterType:"range",
        cellClassRules: cellClassRules(ConstraintsColumns.MileageEnd.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.MileageEnd.name)
        }
    },
    {
        field: ConstraintsColumns.Notes.field, 
        headerName: ConstraintsColumns.Notes.headerName, 
        cellEditor: cellEditors.TextFieldEditor, unSortIcon: true,
        cellClassRules: cellClassRules(ConstraintsColumns.Notes.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.Notes.name)
        }
    },
    {
        field:  ConstraintsColumns.UpdatedBy.field, 
        headerName: ConstraintsColumns.UpdatedBy.headerName, 
        editable: false, 
        unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(ConstraintsColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.UpdatedBy.name)
        }
    },
    {
        field: ConstraintsColumns.UpdatedOn.field,
        headerName: ConstraintsColumns.UpdatedOn.headerName,
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, 
        cellClassRules: cellClassRules(ConstraintsColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ConstraintsColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "constraintsActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function isColumnEditable(params, field) {
    if(params.data.validations && params.data.validations.hasOwnProperty(field)
        && params.data.validations[field].error !== undefined
        && params.data.validations[field].error !== null)
        return true;  
        if ((params.data.constraint_table === 'ADD_DEL_ZM' || params.data.constraint_table === 'ADD_DEL_PM') && params.data.constraint_type === 'CORE_TO_TNC'){
            if (field === ConstraintsColumns.BU.field)
            return false;
            else
            return true;
            
        } 
    if (params.data.constraint_table === 'ADD_DEL_ZM' || params.data.constraint_table === 'ADD_DEL_PM' ||
        params.data.constraint_table === 'ADD_M' || params.data.constraint_table === 'ADD_OEM') {
        if (field === ConstraintsColumns.CarGroupName.field)
            return false;
        if (field === ConstraintsColumns.MileageStart.field)
            return false;
        if (field === ConstraintsColumns.MileageEnd.field)
            return false;
        else
            return true;
    }
    if (params.data.constraint_table === 'EF_M' || params.data.constraint_table === 'EF_PM' ||
        params.data.constraint_table === 'EF_PMC' || params.data.constraint_table === 'EF_ZM' ||
        params.data.constraint_table === 'EF_ZMC') {
        if (field === ConstraintsColumns.ProgramType.field)
            return false;
        if (field === ConstraintsColumns.SpiCode.field)
            return false;
        if (field === ConstraintsColumns.ManufacturerGroupCode.field)
            return false;
        else
            return true;
    }   
    if (params.data.constraint_table === 'NETGL_PM' || params.data.constraint_table === 'NETGL_ZM') {
        if (field === ConstraintsColumns.SpiCode.field)
            return false;
        if (field === ConstraintsColumns.ManufacturerGroupCode.field)
            return false;
        if (field === ConstraintsColumns.CarGroupName.field)
            return false;
        if (field === ConstraintsColumns.MileageStart.field)
            return false;
        if (field === ConstraintsColumns.MileageEnd.field)
            return false;
        if (field === ConstraintsColumns.BU.field)
            return false;
        if (field === ConstraintsColumns.ProgramType.field)
            return false;
        else
            return true;
    }
    return true;
    
}

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field] &&
        params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }else if(params.data.notes!==undefined || params.data.notes!==null ){
        return params.data.notes;
    }
}


function cellClassRulesDates(field, headerName) {
    return {
        'errorRow': (params) => {
            if (params.data.validations && params.data.colDef && params.data.validations[field]) {
                return params.data.validations[field].error !== null
                // if (params.colDef.headerName === 'From')

                // if (params.colDef.headerName === 'To')
                //     return params.data.validations['to_date'].error !== null
                // if (params.data.constraint_type === "ADD_OEM") {
                //     if (params.colDef.headerName === 'From')
                //         return params.data.validations['from_date'].error !== null
                //     if (params.colDef.headerName === 'To')
                //         return params.data.validations['to_date'].error !== null
                // } else {
                //     return params.data.validations['month'].error !== null
                // }
            }
        },
        'validRow': (params) => {
            // if (params.data.constraint_type === "ADD_OEM") {
            // if (params.colDef.headerName === 'From')
            //     field = 'from_date';
            // if (params.colDef.headerName === 'To')
            //     field = 'to_date';
            // } else {
            //     field = 'month';
            // }
            //console.log("FIELD AND MONTH : " + field + "VALIDATIONS : " + JSON.stringify(params.data.validations) + " colDef: " + JSON.stringify(params.data.colDef));
            if (params.data.validations && params.data.colDef && params.data.validations[field]) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && (headerName === item.value)
                });
                //console.log("Is the index printed : ")
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef && params.data.validations[field]) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
