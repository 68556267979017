import {
    FETCH_SCHEDULE_DATA_PENDING, FETCH_SCHEDULE_DATA_SUCCESS, FETCH_SCHEDULE_DATA_ERROR,
    FETCH_SCHEDULES_DROPDOWNVALUES_PENDING, FETCH_SCHEDULES_DROPDOWNVALUES_SUCCESS, FETCH_SCHEDULES_DROPDOWNVALUES_ERROR, SAVE_SCHEDULE_TRANSACTION_PENDING, SAVE_SCHEDULE_TRANSACTION_SUCCESS, SAVE_SCHEDULE_TRANSACTION_ERROR,
    FETCH_SCHEDULES_FILTER_CATEGORIES,FETCH_SCHEDULES_FILTER_DATA_PENDING,FETCH_SCHEDULES_FILTER_DATA_SUCCESS,FETCH_SCHEDULES_FILTER_DATA_ERROR,SET_SCHEDULES_FILTER_PARAMS,UPLOAD_SCHEDULES_PENDING,UPLOAD_SCHEDULES_SUCCESS,UPLOAD_SCHEDULES_ERROR,
    RESET_TO_SCHEDULES_INITIALSTATE
} from './Schedules.ActionTypes';
import { getAllSchedulesData, saveScheduleTransactions, fetchSchedulesDropdownService, uploadSchedulesCSV } from '../../services/schedules/SchedulesService';
import filterCategories from '../../data/schedulesFilterCategories';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchSchedulesData = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_SCHEDULE_DATA_PENDING))
            let response = await getAllSchedulesData(planid);
            if (response) {
                dispatch(serviceActionSuccess(FETCH_SCHEDULE_DATA_SUCCESS, response));
            } else {
                dispatch(serviceActionError(FETCH_SCHEDULE_DATA_ERROR, "Unknown error"));
            }
        }
        catch (e) {
            console.error(e)
            dispatch(serviceActionError(FETCH_SCHEDULE_DATA_ERROR, "Unknown error"));
        }
    }
}

export const fetchSchedulesDropdownValues = (data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_SCHEDULES_DROPDOWNVALUES_PENDING));
        let schedulesData = await fetchSchedulesDropdownService();
        if (schedulesData) {
            dispatch(serviceActionSuccess(FETCH_SCHEDULES_DROPDOWNVALUES_SUCCESS, schedulesData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("schedulesGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later
                    dispatch(validateGrid("schedulesgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }  
                
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_SCHEDULES_DROPDOWNVALUES_ERROR, schedulesData));
        }
    }
}

export const saveSchedulesTransactionData = (planid, transactionList) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_SCHEDULE_TRANSACTION_PENDING));
            await saveScheduleTransactions(planid, transactionList);
            let scheduleData = await getAllSchedulesData(planid);
            if (scheduleData) {
                dispatch(serviceActionSuccess(SAVE_SCHEDULE_TRANSACTION_SUCCESS, scheduleData));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_SCHEDULE_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const fetchFilterCategories = () => {
    return async dispatch => {
      dispatch(serviceActionSuccess(FETCH_SCHEDULES_FILTER_CATEGORIES, filterCategories()));
    }
}

export const setFilterParameters = (filterParams) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_SCHEDULES_FILTER_PARAMS,filterParams))
    }
}

export const fetchFilterData = (selectedPlanId) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_SCHEDULES_FILTER_DATA_PENDING));
            let schedulesData = await getAllSchedulesData(selectedPlanId);
            if (schedulesData) {
                dispatch(serviceActionSuccess(FETCH_SCHEDULES_FILTER_DATA_SUCCESS,schedulesData));
            } 
        } catch (_) {
            dispatch(serviceActionError(FETCH_SCHEDULES_FILTER_DATA_ERROR, "Unknown error"));
        }
    }
}

export const uploadSchedulesData = (planId, files) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_SCHEDULES_PENDING));
            let uploadResp = await uploadSchedulesCSV(planId, files);
            let schedulesData = await getAllSchedulesData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500')
            {
                dispatch(serviceActionSuccess(UPLOAD_SCHEDULES_SUCCESS, schedulesData));
            }
            else{
                dispatch(serviceActionError(UPLOAD_SCHEDULES_ERROR, "schedules upload failed"));
            }
           
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_SCHEDULES_ERROR, "schedules upload failed"));
        }
    }
}

export const resetSchedules = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_SCHEDULES_INITIALSTATE))
    }
  }

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}