import * as CONSTANTS from '../../../../../Constants';
import { formatDate, formatDateToOne } from '../../../../../utils/formatters/valueFormatter';

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Pool.field, headerName: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Pool.headerName, unSortIcon: true,
        cellEditor: "freeSoloAutoCompleteEditor",filterType: "dropdown", filterOptions: [], cellClassRules: cellClassRules(CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Pool.name), tooltipValueGetter: params => {
            return toolTipUI(params,CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Pool.name)
        }
    },
    {
        field: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Onrentdate.field, headerName: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Onrentdate.headerName, 
        cellEditor: "fullDateEditor", filterType:"datepicker", isFullDate:true, valueFormatter: params => formatDate(params.data.ONRENT_DATE),unSortIcon: true,cellClassRules: cellClassRules(CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Onrentdate.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Onrentdate.name)
        }
    },
    {
        field: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Carclass.field, headerName: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Carclass.headerName,
        unSortIcon: true,cellEditor: 'freeSoloAutoCompleteEditor',filterType: "dropdown", filterOptions: [],cellClassRules: cellClassRules(CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Carclass.name), tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Carclass.name)
        }
    },
    {
        field: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Month.field, headerName: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Month.headerName,
        unSortIcon: true, cellEditor: "fullDateEditor",filterType:"datepicker", isFullDate:true,valueFormatter: params => formatDate(params.data.MONTH),cellClassRules: cellClassRules(CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Month.name), tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Month.name)
        }
    },
    {
        field: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Minfleetneeded.field, headerName: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Minfleetneeded.headerName,
        unSortIcon: true,type: 'numericColumn',cellEditor: 'numericEditor', filterType: "range",cellClassRules: cellClassRules(CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Minfleetneeded.name), tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Minfleetneeded.name)
        }
    },
    {
        field: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Rpuatminfleet.field, headerName: CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Rpuatminfleet.headerName,
        unSortIcon: true,type: 'numericColumn',cellEditor: 'numericEditor',filterType: "range",cellClassRules: cellClassRules(CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Rpuatminfleet.name), tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputTncColumns.Rpuatminfleet.name)
        }
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if(params.data.validations[field]){
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW") {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

export const defaultColDef = {
    editable: false,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
