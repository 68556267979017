import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import MultiGridUI from '../../../../components/revenuefunction/grid/RevenueFunctionMultiGridUI';
import ActionBar from '../../../../components/revenuefunction/mainrfinputsactionbar/MainRfInputsActionBar';
import {fetchMainRFInputTncData} from '../../../../store/revenuefunction/mainrfinputs/tncice/TncIce.Actions';

const TncParameterTabPanel = (props) => {
    const {showSaveOption,downloadCSV,tncParameterGridColDefReducer,tncParameterGridReducer,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,setIsSearchBarValueExists,isSearchBarValueExists,refreshCells,handleMultipleRowDelete,hideActions,rfid,loadGridData} = props;

    return (
        <div>
        <MultiGridUI
        reducer={tncParameterGridReducer}
        gridColDef={tncParameterGridColDefReducer}
        onRowEditingStarted={handleRowEditingStarted}
        onRowEditingStopped={handleRowEditingStopped}
        onCellEditingStopped={handleCellEditingStopped}
        gridName={'tncparametergrid'}
        rowEditType={'single'}
        suppressclickEdit={!tncParameterGridColDefReducer.defaultColDef.editable}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete} 
        hideActions={hideActions}
        rfid={rfid}
        loadGridData={loadGridData}
        >
        </MultiGridUI>
        </div>
    )
}

export default TncParameterTabPanel;