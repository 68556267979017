import React from 'react';
import ChipCrossIcon from "../../assets/images/crossIconForFilterChips.svg";
import './chips.css';
import { useSelector } from 'react-redux';

const ChipsDisplay = (props) => {
    const {displayText,fieldValue,fieldName,filterType,handleClearChip}=props

    const pending = useSelector((state) => {
        return  state.PaginationGridReducer.pending;
      });

    return (
            <div className='aChip'>
                {displayText}
                <input type="image" src={ChipCrossIcon} className="chipCross" onClick={(e)=>handleClearChip(fieldName,fieldValue,filterType)} disabled={pending}/>
            </div>
    );
};

export default ChipsDisplay;