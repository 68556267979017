import React, { createContext, useState } from 'react'
import Layout from "./hoc/Layout";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";
import { Provider } from "react-redux";
import store from './store/index';
import { Route, Switch, useHistory } from "react-router-dom";
import SwaggerUIPage from './containers/swaggerui/swaggerui';
import './utilityfirst.css';
import './extrastyles.css';
export const userAcs = createContext();

function App() {
  //All Okta related Security feature has now been removed
  //The Security feature managed by ADAL package for connecting to Azure
  //    is implemented in index.js and indexApp.js
  //Additionaly the connectivity management is done in  config.js and authUtils.js

  const history = useHistory();
  const [defuserAcs, setUserAcs] = useState(false); 

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Switch>
              <Route path="/swaggerui" exact component={SwaggerUIPage} />
              <userAcs.Provider value = {{defuserAcs, setUserAcs}}>
              <Layout></Layout>
              </userAcs.Provider>
        </Switch>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
