import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const MoveCostTabPanel = (props)=>{
    const{showSaveOption,moveCostgridReducer,moveCostTransactions,moveCostGridColDef,moveCostGridColumnDef,gridName, ageMileageData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        ageMileageGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions, currentData,totalPage,moveCostPageNo,filteredData, filterParams,tabIndex, downloadCSV,
        userMessage,newArchitecture,loadGridData} = props;

        const [planid,setPlanId]=useState(selectedPlanId)

        return(
     <div>
        {newArchitecture ?(<MainPlanInputsMultiGridUI reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={moveCostGridColumnDef}
            rowEditType={'single'}
            suppressclickEdit={!moveCostGridColumnDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            loadGridData={loadGridData}
            isPaginationRequired={true}/>):
         (<MultiGridUI 
            reducer={moveCostgridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'movecostgrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={moveCostGridColDef}
            suppressclickEdit={!moveCostGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            moveCostTransactions={moveCostTransactions}
            totalPage={totalPage}
            planid={planid}
            gridpageno={moveCostPageNo}            
            userMessage={userMessage}
            selectedPlanId={selectedPlanId}
             />)
        }
     </div>
 )
}

export default MoveCostTabPanel;