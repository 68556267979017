import React, { useCallback, useEffect } from 'react';
import './MainPlanInputsPage.css';
import NttTabs from '../../components/tabs/NttTabs';
import NttTab from '../../components/tabs/NttTab';
import { ADDITIONAL_COST, ADDS_INVALID_POOLS, AGE_MILEAGE, DEP_RATE, DIVERSIONS, MANUAL_VIN_DELETES, MAX_DEL_ZONE, MILEAGE_DEFAULT, MILEAGE_TABLE, MOVE_COST, PlanAssumptionTabStyle, SALVAGE_RATE, TARGET_ZONE, planAssumptionTabsForMainInputs, planAssumptionTabsForPlanInputs } from '../../Constants';
import AgeMileageTabPanel from '../plan_assumptions/agemileage/AgeMileageTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import { UpdateMasterDataCache, resetGridState, setGridName, setGridUserPageNumber } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { ResetMultiGridState } from '../../store/MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { fetchAgeMileageDropdownValues } from '../../store/agemileage/AgeMileage.Actions';
import AdditionalCostTabPanel from '../plan_assumptions/additionalcost/AdditionalCostTabPanel';
import { fetchAdditionalCostDropdownValues } from '../../store/additionalcost/AdditionalCost.Actions';
import { fetchMaxDeletesDropdownValues } from '../../store/maxdeletes/MaxDeletes.Actions';
import MaxDeletesTabPanel from '../plan_assumptions/maxdeletes/MaxDeletesTabPanel';
import { fetchSalvageRateDropdownValues } from '../../store/salvagerate/SalvageRate.Actions';
import SalvageRateTabPanel from '../plan_assumptions/salvagerate/SalvageRateTabPanel';
import AddsInvalidPoolsActionRenderer from '../../components/grid/grid_components/renderers/AddsInvalidPoolsActionRenderer';
import AddsInvalidPoolsTabPanel from '../plan_assumptions/addsinvalidpools/AddsInvalidPoolsTabPanel';
import { fetchAddsInvalidPoolsDropdownValues } from '../../store/addsinvalidpools/AddsInvalidPools.Actions';
import MileageDefaultTabPanel from '../plan_assumptions/mileagedefault/MileageDefaultTabPanel';
import { fetchMileageDefaultDropdownValues } from '../../store/mileagedefault/MileageDefault.Actions';
import DepRateTabPanel from '../plan_assumptions/deprate/DepRateTabPanel';
import { fetchDepRateDropDownValues } from '../../store/deprate/DepRate.Actions';
import TargetZonePoolTabPanel from '../plan_assumptions/targetzonepool/TargetZonePoolTabPanel';
import { fetchTargetZonePoolDropdownValues } from '../../store/targetzonepool/TargetZonePool.Actions';
import DiversionsTabPanel from '../plan_assumptions/diversions/DiversionsTabPanel';
import { fetchDiversionsDropdownValues } from '../../store/diversions/Diversions.Actions';
import { fetchMileageTableDropdownValues } from '../../store/mileagetable/MileageTable.Actions';
import MileageTableTabPanel from '../plan_assumptions/mileagetable/MileageTableTabPanel';
import ManualVinDeletesTabPanel from '../plan_assumptions/ManualVinDeletesTabPanel';
import { fetchManualVinDeletesDropdownValues } from '../../store/manualvindeletes/ManualVinDeletes.Actions';
import MoveCostTabPanel from '../plan_assumptions/movecost/MoveCostTabPanel';
import { fetchMoveCostDropdownValues } from '../../store/movecost/MoveCost.Actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={'simple-tabpanel-${index}'}
        aria-labelledby={'simple-tab-${index}'}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const PlanAssumptionTabPanel = (props) => {
    const {pending,gridName,refreshCells,
        gridReducer,handleRowEditingStarted,handleRowEditingStopped,gridColDefReducer,
        setGridColDefReducer,handleCellEditingStopped,
        selectedPlanId,handleMultipleRowDelete,showSaveOption,planAssumptionTabvalue,loadGridData} = props;

    const selectedurlHasMainInputs = window.location.href.indexOf("maininputs") > -1;

    const tabsData = selectedurlHasMainInputs?planAssumptionTabsForMainInputs:planAssumptionTabsForPlanInputs

    const dispatch = useDispatch();

    const ifPlanEditDisabled = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
    });

    const copyToMainInputsStatus = useSelector((state) => {
        let copyPlanToMainInputsInProgress = state.rootAllPlansReducer.allPlansReducer.copyPlanToMainInputsInProgress;
        let tabsToBeCopiedToMain = state.rootAllPlansReducer.allPlansReducer.tabsToBeCopiedToMain;
        if (copyPlanToMainInputsInProgress) {
          return tabsToBeCopiedToMain;
        } else {
          return [];
        }
    });
    
    function updateGridColDefs(gridname, colDef) {
        if (copyToMainInputsStatus.some(tabName => tabName === gridname) || ifPlanEditDisabled) {
          colDef.defaultColDef.editable = false;
        } else {
          colDef.defaultColDef.editable = true;
        }
        return colDef;
    }

    const highlightAllTabsErrorStatus = useSelector((state) => {
        return state.PaginationGridReducer.gridTabErrorStatus;
    })
    
    const ageMileageGridColDef = useSelector((state) => {
        return updateGridColDefs(AGE_MILEAGE, state.ageMileageColumnDefinitionReducer);
    });
    
    const additionalCostGridColDef = useSelector((state) => {
      return updateGridColDefs(ADDITIONAL_COST, state.additionalCostColumnDefinitionReducer);
  });

    const maxDeletesGridColDef= useSelector((state)=>{
      return updateGridColDefs(MAX_DEL_ZONE, state.maxDeletesColumnDefinitionReducer);
    });

    const salvageRateGridColDef = useSelector((state)=>{
      return updateGridColDefs(SALVAGE_RATE, state.salvageRateColumnDefinitionReducer);
    })

    const addsInvalidPoolsGridColDef = useSelector((state) => {
      return updateGridColDefs(ADDS_INVALID_POOLS, state.addsInvalidPoolsColumnDefinitionReducer);
    });

    const mileageDefaultGridColDef = useSelector((state) => {
      return updateGridColDefs(MILEAGE_DEFAULT, state.mileageDefaultGridColDefinitionReducer);
    });

    const targetZonePoolGridColDef = useSelector((state) => {
      return updateGridColDefs(TARGET_ZONE, state.targetZonePoolColumnDefinitionReducer);
    });

    const  diversionsGridColDef= useSelector((state) => {
      return updateGridColDefs(DIVERSIONS, state.diversionsColumnDefinitionReducer);
    });

    const depRateGridColDef = useSelector((state) => {
      return updateGridColDefs(DEP_RATE, state.depRateColumnDefinitionReducer);
    });

    const mileagetableGridColDef = useSelector((state)=>{
      return updateGridColDefs(MILEAGE_TABLE, state.mileageTableColumnDefinitionReducer);
    });

    const manualVinDeletesGridColDef = useSelector((state) => {
      return updateGridColDefs(MANUAL_VIN_DELETES,state.manualvindeletesColumnDefinitionReducer);
    });

    const moveCostGridColDef= useSelector((state)=>{
      return updateGridColDefs(MOVE_COST, state.movecostColumnDefinitionReducer);
    });

    const getStyle = (isActive, tabKey, index, gridname) => {
        if(!selectedurlHasMainInputs && tabKey.tabTitle===MOVE_COST){
            return PlanAssumptionTabStyle.hideTab
        }
        if(copyToMainInputsStatus.some(tabName=>tabName===tabKey.tabTitle)){//this if condition is to display the yellow animation on the tabs.
          return isActive ? PlanAssumptionTabStyle.copyInProgressIndicator_active :  PlanAssumptionTabStyle.copyInProgressIndicator
        }
        if (highlightAllTabsErrorStatus[tabKey.gridName] === true) {
          return isActive ? PlanAssumptionTabStyle.activeError_tab : PlanAssumptionTabStyle.defaultError_tab
        } else {
          return isActive ? PlanAssumptionTabStyle.active_tab : PlanAssumptionTabStyle.default_tab
        }
    }


    const handleChange = useCallback(async (event, newValue,newName,onInitialLoad) => {
      if(planAssumptionTabvalue.current.tabIndex != newValue||onInitialLoad){
          planAssumptionTabvalue.current = {tabIndex:newValue,tabName:newName}// for remembering of tab by its index and gridName
          dispatch(resetGridState(false));
          dispatch(ResetMultiGridState())
          await dispatch(UpdateMasterDataCache())
          dispatch(setGridUserPageNumber(1))
          switch (newName) {
            case 'agemileageGrid':
              await dispatch(fetchAgeMileageDropdownValues());
              setGridColDefReducer(ageMileageGridColDef);
              dispatch(setGridName('agemileageGrid'));
            break;
            case 'additionalcostGrid':
              await dispatch(fetchAdditionalCostDropdownValues());
              setGridColDefReducer(additionalCostGridColDef);
              dispatch(setGridName('additionalcostGrid'));
            break;
            case 'addsinvalidpoolsGrid':
              await dispatch(fetchAddsInvalidPoolsDropdownValues());
              setGridColDefReducer(addsInvalidPoolsGridColDef);
              dispatch(setGridName('addsinvalidpoolsGrid'));
              break;
            case 'maxdeleteGrid':
              await dispatch(fetchMaxDeletesDropdownValues());
              setGridColDefReducer(maxDeletesGridColDef);
              dispatch(setGridName('maxdeleteGrid'));
            break;
            case 'mileagedefaultGrid':
              await dispatch(fetchMileageDefaultDropdownValues());
              setGridColDefReducer(mileageDefaultGridColDef);
              dispatch(setGridName('mileagedefaultGrid'));
            break; 
            case 'movecostGrid':
              await dispatch(fetchMoveCostDropdownValues());
              setGridColDefReducer(moveCostGridColDef);
              dispatch(setGridName('movecostGrid'));
            break; 
            case 'salvagerateGrid':
              await dispatch(fetchSalvageRateDropdownValues());
              setGridColDefReducer(salvageRateGridColDef);
              dispatch(setGridName('salvagerateGrid'));
            break;
            case 'mileagetableGrid':
              await dispatch (fetchMileageTableDropdownValues());
              setGridColDefReducer(mileagetableGridColDef);
              dispatch(setGridName('mileagetableGrid'));
            break;
            case 'manualvindeletesGrid':
              await dispatch (fetchManualVinDeletesDropdownValues())
              setGridColDefReducer(manualVinDeletesGridColDef);
              dispatch(setGridName('manualvindeletesGrid'));
              break;
            case 'targetzonepoolGrid':
              await dispatch(fetchTargetZonePoolDropdownValues());
              setGridColDefReducer(targetZonePoolGridColDef);
              dispatch(setGridName('targetzonepoolGrid'));
            break;
            case 'deprateGrid':
              await dispatch(fetchDepRateDropDownValues());
              setGridColDefReducer(depRateGridColDef);
              dispatch(setGridName('deprateGrid'));
            break;
            case 'diversionsGrid':
              await dispatch(fetchDiversionsDropdownValues());
              setGridColDefReducer(diversionsGridColDef);
              dispatch(setGridName('diversionsGrid'));
            break;
            default:
            break;
        }
      }
    }, [gridName])

    useEffect(()=>{
      // inital setup of grid, when user enters into planAssumptions tab
      // planAssumptionTabvalue is an Object consisting of tab index and gridName 
      handleChange("", planAssumptionTabvalue.current.tabIndex,planAssumptionTabvalue.current.tabName,true)
    },[])

    return (
        <div className='newplanassumptiontabs'>
            <NttTabs
                value={planAssumptionTabvalue.current.tabIndex}
                orientation="vertical"
                variant="scrollable"
                scrollButtons='on'
                onChange={(e,i)=>{handleChange(e,i,tabsData[i]['gridName'])}}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {tabsData.map((tabInfo, index) => (
                    <NttTab label={tabInfo.tabDisplayTitle}
                        id={`simple-tab-${index}`}
                        key={tabInfo.tabTitle}
                        style={getStyle(planAssumptionTabvalue.current.tabIndex === index,tabInfo, index,gridName)}
                        disabled={showSaveOption || pending || index>11} 
                    />
                ))}
            </NttTabs>
        {tabsData.map((tabInfo, index) => (
            <TabPanel key={index}
            value={planAssumptionTabvalue.current.tabIndex} index={index} className='newplanassumptiontabpanel paddingtarget'>
            {(gridName=='agemileageGrid') ?
                <AgeMileageTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                ageMileageGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
               />
               :( gridName=='additionalcostGrid')?
                <AdditionalCostTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                additionalCostGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
               : (gridName=='addsinvalidpoolsGrid') ?
               <AddsInvalidPoolsTabPanel
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                addsInvalidPoolsGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete} 
                loadGridData={loadGridData}
               />
               :(gridName=='maxdeleteGrid')?
                <MaxDeletesTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                maxDeletesGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
               : (gridName=='mileagedefaultGrid') ?
               <MileageDefaultTabPanel
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                mileageDefaultGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
               />                                                       
                :(gridName=='salvagerateGrid')?
                <SalvageRateTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                salvageRateGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='mileagetableGrid')?
                <MileageTableTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                mileageTableGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='manualvindeletesGrid')?
                <ManualVinDeletesTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                manualVinDeletesGridColumnDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='targetzonepoolGrid')?
                <TargetZonePoolTabPanel
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                targetZonePoolGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='deprateGrid')?
                <DepRateTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                depRateGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='diversionsGrid')?
                <DiversionsTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                diversionsGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='targetzonepoolGrid')?
                <TargetZonePoolTabPanel
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                targetZonePoolGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='deprateGrid')?
                <DepRateTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                depRateGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='diversionsGrid')?
                <DiversionsTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                diversionsGridColDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />
                :(gridName=='movecostGrid')?
                <MoveCostTabPanel 
                newArchitecture={true}
                pending={pending}
                gridName={gridName}
                refreshCells={refreshCells}
                currentView={"grid"}
                gridReducer={gridReducer}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                moveCostGridColumnDef={gridColDefReducer}
                tabInfo={tabInfo} tabIndex={planAssumptionTabvalue.current.tabIndex}
                handleCellEditingStopped={handleCellEditingStopped}
                selectedPlanId={selectedPlanId}
                handleMultipleRowDelete={handleMultipleRowDelete}
                loadGridData={loadGridData}
                />:''}
        </TabPanel>
    ))}
    </div>
    );
};

export default React.memo(PlanAssumptionTabPanel);