import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch,useSelector } from "react-redux";
import ActionBar from '../../../components/revenuefunction/mainrfinputsactionbar/MainRfInputsActionBar';
import {MAX_ROWS_ALLOWED_FOR_EACH_GRID, MessageNotificationValues, rfids} from "../../../Constants";
import {fetchTncEvDropDownValues} from "../../../store/revenuefunction/mainrfinputs/tncev/TncEv.Actions";
import {fetchTncIceDropDownValues} from '../../../store/revenuefunction/mainrfinputs/tncice/TncIce.Actions';
import { fetchCoreDemandDropDownValues} from "../../../store/revenuefunction/mainrfinputs/coredemand/CoreDemand.Actions";
import {fetchCoreAdjDropDownValues} from "../../../store/revenuefunction/mainrfinputs/coreadjustments/CoreAdjustments.Actions";
import {ResetRFMultiGridState, cleanTransactions, deleteRow, deleteRowEditMode, refreshGridData, validateGrid } from "../../../store/revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions";
import TncEvTabPanel from "./tncev/TncEvTabPanel";
import TncIceTabPanel from "./tncice/TncIceTabPanel";
import TncParameterTabPanel from "./tncparameter/TncParameterTabPanel";
import CoreDemandTabPanel from "./coredemand/CoreDemandTabPanel";
import {saveGridTransactionData, setGridName, setGridUserPageNumber } from "../../../store/MultiGridPaginationModel/PaginationGrid.Actions";
import CoreAdjustmentsTabPanel from "./coreAdjustments/CoreAdjustmentsTabPanel";
import CoreParameterTabPanel from "./coreparameter/CoreParameterTabPanel";
import { addNotificationMessage } from "../../../store/topbar/AllPlans.Actions";

const MainRfInputsTabPanel = (props) => {
   //loadGridDataForFilterParams,loadGridDataForErrorView ,loadGridDataForSearchParam  ====>we might delete this later
    const {showSaveOption,downloadCSV,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,setIsSearchBarValueExists,isSearchBarValueExists,handleSaveCancelDisabled,handleAddNewRow,
        bulkRowDeleteIconDisabled,handleMultipleRowDelete,selectedRows,pending,setRefreshCells,refreshCells,setIsSaveClicked,setShowSaveOption,
        isSaveClicked,setSnackBarOpen,userMessage,setSnackBarIfError,snackBarIfError,setGridColDefReducer,setWarningSnackBarOpen,typeName,setTypeName,setGenerateRfProcessClicked,selectedurlHasRfinputs,rfIdFromUrl,hideActions,loadGridData,initializeFilterParamsAndViewStatus} = props;
    const dispatch = useDispatch();

    const gridUserPageno=useSelector((state)=>{
      return state.PaginationGridReducer.gridUserPageno;
    })
    
    const gridName=useSelector((state)=>{
      return state.PaginationGridReducer.gridName
    })
    
    const GridData = useSelector((state)=>{
      return state.PaginationGridReducer.gridData;
    });

    const GridReducer = useSelector((state) => {
      return state.RfMultiGridUIReducer;
    });

    const tncIceGridColDefReducer = useSelector((state)=>{
      return state.RFTncIcecolumnDefinitionReducer;
    });
    
    const tncEvGridColDefReducer = useSelector((state)=>{
      return state.RFTncEvcolumnDefinitionReducer;
    });

    const tncParameterGridColDefReducer = useSelector((state)=>{
        return state.RFParameterscolumnDefinitionReducer;
    });

    const coreDemandGridColDefReducer = useSelector((state)=>{
      return state.RFCoreDemandcolumnDefinitionReducer;
    });

    const coreAdjustmstsGridColDefReducer = useSelector((state)=>{
      return state.RFCoreAdjColumnDefinitionReducer;
    });

    const RFParametercolumnDefinitionReducer = useSelector((state)=>{
      return state.RFParametercolumnDefinitionReducer;
    })

    const masterDataUpdateState = useSelector((reduxstate)=>{
        return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
    });

    const gridFilterUserSelectedParams = useSelector((state) => {
      return {...state.PaginationGridReducer.gridFilterUserSelectedParams};
    },shallowEqual);
   
    const gridFilterViewStatus = useSelector((state) => {
      return {...state.PaginationGridReducer.gridFilterView};
    },shallowEqual);

    const gridSearchViewStatus = useSelector((state)=>{
      return state.PaginationGridReducer.gridSearchView;
    },shallowEqual)

    const gridUserSearchParams = useSelector((state) => {
      return {...state.PaginationGridReducer.gridUserSearchParams};
    },shallowEqual);

    const gridErrorViewStatus = useSelector((state) => {
      return {...state.PaginationGridReducer.gridErrorView};
    },shallowEqual);

    const gridTabErrorStatus = useSelector((state)=>{
      return state.PaginationGridReducer.gridTabErrorStatus;
    });

    const gridDeleteAllDataSuccess = useSelector((state)=>{
      return state.PaginationGridReducer.gridDeleteAllDataSuccess;
    });

    const gridTotalRowCount = useSelector((state) => {
      return state.PaginationGridReducer.gridTotalRowCount;
    });

    const[selectedTableName,setSelectedTableName] = useState("TNC ICE");
    const[mainRfInputsData,setMainRfInputsData] = useState(GridData);
    const[mainRfInputsGridColDef,setMainRfInputsGridColDef] = useState(tncIceGridColDefReducer);
    const[mainRfInputsGrid,setmainRfInputssGrid] = useState('tncicegrid');

    const loadTncIceDropDownValues=()=>{
        dispatch(fetchTncIceDropDownValues())
    }

    const loadTncEvDropDownValues = ()=>{
        dispatch(fetchTncEvDropDownValues())
    }

    const loadCoreDemandDropDownValues = ()=>{
      dispatch(fetchCoreDemandDropDownValues())
  }

  const loadCoreAdjustmentsDropDownValues = ()=>{
    dispatch(fetchCoreAdjDropDownValues())
}

    const onTableNameChange = ((selectedTabName)=>{
        if(selectedTableName!=selectedTabName){
          dispatch(ResetRFMultiGridState())
        setSelectedTableName(selectedTabName);
        dispatch(setGridUserPageNumber(1))
        let rfid=(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName];
        if(selectedTabName==='TNC ICE'){           
            loadTncIceDropDownValues();
            dispatch(setGridName('tncicegrid'));
            setmainRfInputssGrid('tncicegrid');
            setMainRfInputsGridColDef(tncIceGridColDefReducer);
            setGridColDefReducer(tncIceGridColDefReducer)
            // loadTncIceTableData(rfid);
        }else if(selectedTabName==='TNC EV'){
            loadTncEvDropDownValues();
            dispatch(setGridName('tncevgrid'));
            setmainRfInputssGrid('tncevgrid');
            setMainRfInputsGridColDef(tncEvGridColDefReducer);
            setGridColDefReducer(tncEvGridColDefReducer)
            // loadTncEvTableData(rfid);
        }else if(selectedTabName==='TNC PARAMETERS'){
            dispatch(setGridName('tncparametergrid'));
            setmainRfInputssGrid('tncparametergrid');
            setMainRfInputsGridColDef(tncParameterGridColDefReducer);
            setGridColDefReducer(tncParameterGridColDefReducer)
            // loadTncParameterTableData(rfid);
        }else if(selectedTabName==='DEMAND'){
          loadCoreDemandDropDownValues();
          dispatch(setGridName('coredemandgrid'));
          setmainRfInputssGrid('coredemandgrid');
          setMainRfInputsGridColDef(coreDemandGridColDefReducer);
          setGridColDefReducer(coreDemandGridColDefReducer)
          // loadTncParameterTableData(rfid);
        }else if(selectedTabName==='ADJUSTMENTS'){
          loadCoreAdjustmentsDropDownValues();
          dispatch(setGridName('coreadjustmentsgrid'));
          setmainRfInputssGrid('coreadjustmentsgrid');
          setMainRfInputsGridColDef(coreAdjustmstsGridColDefReducer);
          setGridColDefReducer(coreAdjustmstsGridColDefReducer)
          // loadCoreAdjustmentsTableData(rfid);
        }else if(selectedTabName==='CORE PARAMETERS'){
          dispatch(setGridName('coreparametergrid'));
          setmainRfInputssGrid('coreparametergrid');
          setMainRfInputsGridColDef(RFParametercolumnDefinitionReducer);
          setGridColDefReducer(RFParametercolumnDefinitionReducer)
        }else{
          dispatch(setGridName(''));//Impacts an UseEffect
        }
      }
    })

  const onTypeNameChange = (typeName)=>{
    setTypeName(typeName)
  }

    const handleSaveClicked = (gridName) => {
     setSnackBarIfError(!snackBarIfError);
     if(gridName!=''){
      setIsSaveClicked(gridName);
      dispatch(validateGrid(gridName, GridReducer.currentData, mainRfInputsGridColDef.validationFn, mainRfInputsGridColDef.businessValidationFn));
     }
  }

 const handleCancelClicked = (gridName) => {
    setRefreshCells(!refreshCells);
    //dispatch(cleanTransactions(gridName));
    setShowSaveOption(false);
    setIsSaveClicked(null);
    loadGridData();
  }
    
  const deleteSelectedRows = () => {

    if(gridName!=='' && selectedRows.length > 0){
      selectedRows.map((i) => {
        dispatch(deleteRow(gridName, i.ID));
      })
    }

    // let transactions = [];
    // let ID = '';
    // if(gridName!=''){
    //   transactions = GridReducer.transactions;
    // }
    // if (transactions.length > 0) {
    //   transactions.map((i) => {
    //     if (i.type == 'edit') {
    //       dispatch(deleteRowEditMode(gridName, i.data.ID))
    //     } else
    //       if (selectedRows.length > 0) { //in case of copy row if we select both the rows we won't get both in transactions.
    //         selectedRows.map((i) => {
    //           dispatch(deleteRow(gridName, i.ID));
    //         })
    //       }
    //   })
    // } else if (selectedRows.length > 0) {
    //   selectedRows.map((i) => {
    //     dispatch(deleteRow(gridName, i.ID));
    //   })
    // }
  }

    const fetchValidTransactions = (transactions) => {
      const filterValidTransactions = transactions.filter(item => {
        return (((item && item.data) && (item.data.rowStatus != "ERROR_ROW" && item.data.rowStatus != "ERROR_COPY_ROW" &&
          item.data.rowStatus != "ERROR_NEW_ROW" && item.data.rowStatus != "INVALID_ROW" && item.data.rowStatus != "INVALID_NEW_ROW") ||
          (item && !item.data) && item.type == "delete"))
      })
      return filterValidTransactions;
    }
  
    const fetchInvalidTransactions = (transactions) => {
      const filterInValidTransactions = transactions.filter(item => {
        return ((item && item.data) && (item.data.rowStatus == "ERROR_ROW" || item.data.rowStatus == "ERROR_COPY_ROW" ||
          item.data.rowStatus == "ERROR_NEW_ROW" || item.data.rowStatus == "INVALID_ROW" || item.data.rowStatus == "INVALID_NEW_ROW") ||
          (item && !item.data) && item.type == "add")
      })
      return filterInValidTransactions;
    }

    useEffect(()=>{
      setSnackBarOpen(false);
      setWarningSnackBarOpen(false);
    },[])

   useEffect(()=>{
    if(GridReducer.transactions.length>0){
        setShowSaveOption(true)
    }else{
    setShowSaveOption(false);
    }
  },[GridReducer.transactions])

    useEffect(() => {
    if (GridData) {
      // console.log("GridData==>",GridData);
      setMainRfInputsData(GridData)
    }
  }, [GridData]);


  const {uploadGridSuccess, uploadGridErrorMessage}=useSelector((state)=>{
      return {
        uploadGridSuccess: state.PaginationGridReducer.gridUploadStatus,
        uploadGridErrorMessage: state.PaginationGridReducer.gridErrorMessage
      };
  });
  const gridRowDataInvalid = useSelector((state) => {
    return state.RfMultiGridUIReducer.isAnyRowInvalid;
  });
  const gridTransactionSaveSuccess = useSelector((state)=>{
    return state.PaginationGridReducer.gridTransactionSaveSuccess;
  })

    useEffect(() => {
      if (!showSaveOption && uploadGridSuccess == true) {
        if (gridTabErrorStatus[gridName]){
          // userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
          dispatch(addNotificationMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review'));
        }else{
          // userMessage('success', 'Successfully uploaded');
          dispatch(addNotificationMessage('success', 'Successfully uploaded'));
        }
      } else if (uploadGridSuccess == false) {
        // userMessage('error', uploadGridErrorMessage);
        dispatch(addNotificationMessage('error', uploadGridErrorMessage));
      }
    }, [uploadGridSuccess])
  
    useEffect(() => {
      if(!pending && !masterDataUpdateState && isSaveClicked===gridName){
      const filterValidTransactions = fetchValidTransactions(GridReducer.transactions);
      const filterInValidTransactions = fetchInvalidTransactions(GridReducer.transactions);
      if ((!GridReducer.transactions || GridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == gridName)) {
        setSnackBarOpen(false);
        // userMessage('warning', 'Nothing to be saved');
        dispatch(addNotificationMessage('warning', 'Nothing to be saved'));
        setShowSaveOption(false);
        setIsSaveClicked(null);
      }
  
      if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == gridName)) {
        if ((!filterInValidTransactions || filterInValidTransactions.length <= 0)) {
          let pageNo=gridUserPageno;
          if(gridUserPageno!=1 && gridUserPageno==Math.ceil(gridTotalRowCount / MAX_ROWS_ALLOWED_FOR_EACH_GRID) && GridReducer.currentData.length==0){//this is to check if user selected and deleted all the existing rows in the last page of the grid
            pageNo=gridUserPageno-1;//updating page number by minus one
          }
          dispatch(saveGridTransactionData(userMessage,rfids[0][typeName],filterValidTransactions,gridName,selectedTableName,typeName,pageNo,loadGridData)); 
          setIsSaveClicked(null);
        }
        else if ((filterInValidTransactions && filterInValidTransactions.length > 0)) {
          // userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
          dispatch(addNotificationMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.'));
        }
      }
  
      if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == gridName)) {
        setSnackBarOpen(true);
      }
    }
    }, [gridRowDataInvalid, isSaveClicked, snackBarIfError,masterDataUpdateState,pending]);

    useEffect(() => {
    if (gridTransactionSaveSuccess || gridDeleteAllDataSuccess) {
      // const filterValidTransactions = fetchValidTransactions(GridReducer.transactions);
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);
      // dispatch(cleanTransactions(gridName));
      // dispatch(refreshGridData(gridName));

        if (gridTabErrorStatus[gridName] && GridData.length > 0) {
          dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Successfully saved rows. But some rows are still invalid, please review'));
        } else {
          dispatch(addNotificationMessage(MessageNotificationValues.Severity.success, 'Successfully saved rows'));
        }
      // }
      // dispatch(cleanTransactions(gridName));
      // dispatch(refreshGridData(gridName));
    }
    else if (gridTransactionSaveSuccess === false) {
      alert('Unable to save transactions. Please try again.');
    }
  }, [gridTransactionSaveSuccess,gridDeleteAllDataSuccess]);

    return (
        <div>
        <ActionBar
         mainRfInputsData={mainRfInputsData?mainRfInputsData:GridData}
         mainRfInputsGridColDefReducer={mainRfInputsGridColDef?mainRfInputsGridColDef:tncIceGridColDefReducer}
         mainRfInputsGrid={mainRfInputsGrid?mainRfInputsGrid:'tncicegrid'}
         showOption={showSaveOption}
         downloadCSV={downloadCSV}
         onTableNameChange={onTableNameChange}
         handleSaveCancelDisabled={handleSaveCancelDisabled}
        onSaveClicked={(e) => handleSaveClicked(mainRfInputsGrid)}
        onCancelClicked={(e) => handleCancelClicked(mainRfInputsGrid)}
        onAddNewRowClick={(e) => handleAddNewRow(mainRfInputsGrid,GridReducer.currentData.length)}
        bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
        deleteSelectedRows={(e) => deleteSelectedRows(mainRfInputsGrid)}
        onTypeNameChange={onTypeNameChange}
        typeName={typeName}
        pending={pending}
        setGenerateRfProcessClicked={setGenerateRfProcessClicked}
        selectedTableName={selectedTableName}
        hideActions={hideActions}
        GridData={GridData}
        userMessage={userMessage}
        loadGridData={loadGridData}
        //loadGridDataForFilterParams={loadGridDataForFilterParams} 
        //loadGridDataForErrorView={loadGridDataForErrorView}
        initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}
        gridFilterUserSelectedParams={gridFilterUserSelectedParams}
        //loadGridDataForSearchParam={loadGridDataForSearchParam}
        ></ActionBar>
        {selectedTableName=='TNC ICE'?
        <TncIceTabPanel
        tncIceGridReducer={GridReducer}
        tncIceGridColDefReducer={mainRfInputsGridColDef}
        handleRowEditingStarted={handleRowEditingStarted}
        handleRowEditingStopped={handleRowEditingStopped}
        handleCellEditingStopped={handleCellEditingStopped}
        isSearchBarValueExists={isSearchBarValueExists}
        setIsSearchBarValueExists={setIsSearchBarValueExists}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete}
        hideActions={hideActions} 
        rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
        loadGridData={loadGridData}
        >
        </TncIceTabPanel>
        :(selectedTableName=='TNC EV')?
        <TncEvTabPanel
        tncEvGridReducer={GridReducer}
        tncEvGridColDefReducer={mainRfInputsGridColDef}
        handleRowEditingStarted={handleRowEditingStarted}
        handleRowEditingStopped={handleRowEditingStopped}
        handleCellEditingStopped={handleCellEditingStopped}
        isSearchBarValueExists={isSearchBarValueExists}
        setIsSearchBarValueExists={setIsSearchBarValueExists}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete}
        hideActions={hideActions} 
        rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
        loadGridData={loadGridData}
        >
        </TncEvTabPanel>
        :(selectedTableName=='TNC PARAMETERS')? 
        <TncParameterTabPanel
        tncParameterGridReducer={GridReducer}
        tncParameterGridColDefReducer={mainRfInputsGridColDef}
        handleRowEditingStarted={handleRowEditingStarted}
        handleRowEditingStopped={handleRowEditingStopped}
        handleCellEditingStopped={handleCellEditingStopped}
        isSearchBarValueExists={isSearchBarValueExists}
        setIsSearchBarValueExists={setIsSearchBarValueExists}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete} 
        hideActions={hideActions}
        rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
        loadGridData={loadGridData}
        >
        </TncParameterTabPanel>
         :(selectedTableName=='DEMAND')? 
         <CoreDemandTabPanel
         coreDemandGridReducer={GridReducer}
         coreDemandGridColDefReducer={mainRfInputsGridColDef}
         handleRowEditingStarted={handleRowEditingStarted}
         handleRowEditingStopped={handleRowEditingStopped}
         handleCellEditingStopped={handleCellEditingStopped}
         isSearchBarValueExists={isSearchBarValueExists}
         setIsSearchBarValueExists={setIsSearchBarValueExists}
         refreshCells={refreshCells}
         handleMultipleRowDelete={handleMultipleRowDelete} 
         hideActions={hideActions}
         rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
         loadGridData={loadGridData}
         >
         </CoreDemandTabPanel>
        :(selectedTableName=='ADJUSTMENTS')? 
        <CoreAdjustmentsTabPanel
        coreAdjustmentsGridReducer={GridReducer}
        coreAdjustmentsGridColDefReducer={mainRfInputsGridColDef}
        handleRowEditingStarted={handleRowEditingStarted}
        handleRowEditingStopped={handleRowEditingStopped}
        handleCellEditingStopped={handleCellEditingStopped}
        isSearchBarValueExists={isSearchBarValueExists}
        setIsSearchBarValueExists={setIsSearchBarValueExists}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete} 
        hideActions={hideActions}
        rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
        loadGridData={loadGridData}
        >
        </CoreAdjustmentsTabPanel>
        :(selectedTableName=='CORE PARAMETERS')? 
        <CoreParameterTabPanel
        coreParameterGridReducer={GridReducer}
        coreParameterGridColDefReducer={mainRfInputsGridColDef}
        handleRowEditingStarted={handleRowEditingStarted}
        handleRowEditingStopped={handleRowEditingStopped}
        handleCellEditingStopped={handleCellEditingStopped}
        isSearchBarValueExists={isSearchBarValueExists}
        setIsSearchBarValueExists={setIsSearchBarValueExists}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete} 
        hideActions={hideActions}
        rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
        loadGridData={loadGridData}
        >
        </CoreParameterTabPanel>
        :<div></div>}
        </div>
    )
}

export default MainRfInputsTabPanel;