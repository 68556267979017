import { combineReducers } from 'redux';
import PlanAssumptionsReducer from './PlanAssumptions.Reducer';
import rootAgeMileageReducer from '../agemileage/rootAgeMileageReducer';
import rootAdditionalCostReducer from '../additionalcost/rootAdditionalCostReducer';
import rootMileageDefaultReducer from '../mileagedefault/rootMileageDefaultReducer';
import rootMaxDeletesReducer from '../maxdeletes/rootMaxDeletesReducer';
import rootSalvageRateReducer from '../salvagerate/rootSalvageRateReducer';
import rootTargetZonePoolReducer from '../targetzonepool/rootTargetZonePoolReducer';
// import rootZoneMapReducer from '../zonemap/rootZoneMapReducer';
import rootAddsInvalidPoolsReducer from '../addsinvalidpools/rootAddsInvalidPoolsReducer';
import rootMoveCostReducer from '../movecost/rootMoveCostReducer';
import rootDiversionsReducer from '../diversions/rootDiversionsReducer';
import rootDepRateReducer from '../deprate/rootDepRateReducer';
import rootMileageTableReducer from '../mileagetable/rootMileageTableReducer';
import rootManualVinDeletesReducer from '../manualvindeletes/rootManualVinDeletesReducer';

const rootPlanAssumptionsReducer = combineReducers({
    PlanAssumptionsReducer,
    rootAgeMileageReducer,
    rootAdditionalCostReducer,
    rootMaxDeletesReducer,
    rootMileageDefaultReducer,
    rootSalvageRateReducer,
    rootTargetZonePoolReducer,
    // rootZoneMapReducer,
    rootMileageTableReducer,
    rootDiversionsReducer,
    rootAddsInvalidPoolsReducer,
    rootMoveCostReducer,
    rootDepRateReducer,
    rootManualVinDeletesReducer

})

export default rootPlanAssumptionsReducer;