import {
    FETCH_TEMPLATES_DATA_PENDING, FETCH_TEMPLATES_DATA_SUCCESS, FETCH_TEMPLATES_DATA_ERROR,
    FILTER_SEARCH_TEMPLATE_DATA,SAVE_TEMPLATE_TRANSACTION_PENDING, SAVE_TEMPLATE_TRANSACTION_SUCCESS, SAVE_TEMPLATE_TRANSACTION_ERROR
}
    from "../../store/upgradepaths/UpgradeTemplates.ActionTypes";
import { fetchUpgradeTemplatesData, fetchUpgradePathsData,  saveUpgradeTemplateTransactions } from "../../services/upgradepaths/UpgradeTemplatesService";

export const fetchUpgradeTemplates = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_TEMPLATES_DATA_PENDING));
            let templatesData = await fetchUpgradeTemplatesData(planid);
            if (templatesData) {
                dispatch(serviceActionSuccess(FETCH_TEMPLATES_DATA_SUCCESS, templatesData));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_TEMPLATES_DATA_ERROR, "Unknown error"));
        }
    }
}

export const searchTemplateRecord = (searchParameters) => {
    return serviceActionSuccess(FILTER_SEARCH_TEMPLATE_DATA, searchParameters);
}

export const saveTemplateTranctions = (planid, transactionList) =>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_TEMPLATE_TRANSACTION_PENDING));
            await saveUpgradeTemplateTransactions(planid, transactionList);
            let templatesData = await fetchUpgradeTemplatesData(planid);
            if (templatesData) {
                dispatch(serviceActionSuccess(SAVE_TEMPLATE_TRANSACTION_SUCCESS, templatesData));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_TEMPLATE_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, allplans) {
    return {
        type: type,
        data: allplans
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}