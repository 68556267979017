import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';


export async function getS3UploadUrl(gridname){
    try{
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisemoveCostUploadUrlResponse = await client.apis.plans.getS3UploadUrl({"gridName":gridname?gridname:null});
        const responsesArray = await Promise.all([promisemoveCostUploadUrlResponse]);
        const [result] = responsesArray.map(o => o.body.data);
    
        return ({
            "s3Url": result.uploadURL,"uploadedFilename":result.filename
        });
    } catch(e){
        console.log("error",e)
    }
    
}

export async function getS3DownloadUrl(gridname,planid,filename,downloadParams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisemoveCostUploadUrlResponse = await client.apis.plans.getS3DownloadUrl({"gridName":gridname?gridname:null,"planid":planid?planid:1},{requestBody:{"filename":filename?filename:null,"downloadparams":downloadParams}});
        const responsesArray = await Promise.all([promisemoveCostUploadUrlResponse]);
        const [result] = responsesArray.map(o => o.body.data);

        return ({
            "s3Url": result.downloadURL
        });
    }catch(e){
        console.log("error",e)
    }
}