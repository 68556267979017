import {FETCH_METADATA_POOL_DATA_PENDING, FETCH_METADATA_POOL_DATA_SUCCESS, FETCH_METADATA_POOL_DATA_ERROR,
    SAVE_METADATA_POOL_TRANSACTION_PENDING, SAVE_METADATA_POOL_TRANSACTION_SUCCESS,RESET_TO_METADATA_POOL_INITIALSTATE, SAVE_METADATA_POOL_TRANSACTION_ERROR
    } from '../metadata_pool_management/PoolManagement.ActionTypes.js';

const initialState = {
    adminPoolsData:[],
    pending:false,
    error:null,
    // uploadPoolsManagementSuccess:null,
    // transactionSaveSuccess:null
}



export default function PoolsManagementReducer (state=initialState,action){
    switch(action.type){
        case FETCH_METADATA_POOL_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_METADATA_POOL_DATA_SUCCESS:
            return Object.assign({},state,{
                adminPoolsData: action.data.adminPoolsData,
                // uploadPoolsManagementSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_METADATA_POOL_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        // case UPLOAD_METADATA_POOL_PENDING:
        //     return Object.assign({}, state, {
        //         pending: true,
        //         error:null,
        //         uploadPoolsManagementSuccess: null
        //     });
        // case UPLOAD_METADATA_POOL_SUCCESS:
        //     return Object.assign({}, state, {
        //         adminPoolsData: fetchZones(action.data.adminPoolsData, action.data.zones),
        //         pending: false,
        //         error: null,
        //         uploadPoolsManagementSuccess: true
        //     });
        // case UPLOAD_METADATA_POOL_ERROR:
        //     return Object.assign({}, state, {
        //         pending: false,
        //         error: action.error,
        //         uploadPoolsManagementSuccess: false
        //     });
        case SAVE_METADATA_POOL_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_METADATA_POOL_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                adminPoolsData: action.data.adminPoolsData,
                transactionSaveSuccess: true
            });
        case SAVE_METADATA_POOL_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_METADATA_POOL_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}