import React from 'react'
import './constraint_DatePickerFilter.css';
import { NttDropdown } from '../../../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import { NttLabel } from '../../../../core-components/Label/NttLabel';
import Input from "@material-ui/core/Input";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker, DatePicker
} from '@material-ui/pickers';

const DatePickerFilter = (props) => {
    //console.log("props in picker:",props);
    const { displayName, theme, selectedDate, onFromDateChanged, onToDateChanged, onValueChanged } = props;

    const handleFromDateChange = function(e){
        const monthYear =  `${e.getFullYear()}/${e.getMonth()+1}`;
        onFromDateChanged(monthYear);
    }

    const handleToDateChange = function(e){
        const monthYear =  `${e.getFullYear()}/${e.getMonth()+1}`;
        onToDateChanged(monthYear);
    }

    const ITEM_HEIGHT = 48;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: 250,
            },
        },
    };

    return (<div className='pickermarging'><span className="rangeoption"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel> <NttDropdown
        selectClassName="dropdown"
        formControlClassName="dropdownStyle"
        inputLabelId="demo-mutiple-checkbox-label"
        selectLabelId="demo-mutiple-checkbox-label"
        selectId="demo-mutiple-checkbox"
        selectVariant="outlined"
        selectValue={(selectedDate[0] === undefined) ? "between" : selectedDate[0]}
        selectOnchange={onValueChanged}
        selectCustomInput={<Input />}
        selectMenuProps={MenuProps}
    >
            <MenuItem value="between">is between</MenuItem>
            <MenuItem value="greater">is greater than</MenuItem>
            <MenuItem value="less">is less than</MenuItem>
        </NttDropdown></span>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className="muiDatePicker"
                margin="normal"
                id="date-picker-dialog"
                format="MMM-yy"
                value={!selectedDate[1] ? null : selectedDate[1]}
                onChange={handleFromDateChange}
                orientation="landscape"
                views={["year", "month"]}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                variant='inline'
                autoOk={true}
            />
        </MuiPickersUtilsProvider>
        {(selectedDate[0] === "between" || selectedDate[0] === undefined) ? <span><label className='andtext'>and</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className="muiDatePicker"
                    margin="normal"
                    id="date-picker-dialog"
                    format="MMM-yy"
                    value={!selectedDate[2] ? null : selectedDate[2]}
                    onChange={handleToDateChange}
                    orientation="landscape"
                    views={["year", "month"]}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    variant='inline'
                    autoOk={true}
                />
            </MuiPickersUtilsProvider>
        </span> : <div></div>}
    </div>);
}

export default DatePickerFilter;