export const FETCH_MAXDELETES_DATA_PENDING = 'FETCH_MAXDELETES_DATA_PENDING';
export const FETCH_MAXDELETES_DATA_SUCCESS = 'FETCH_MAXDELETES_DATA_SUCCESS';
export const FETCH_MAXDELETES_DATA_ERROR = 'FETCH_MAXDELETES_DATA_ERROR';
export const UPLOAD_MAXDELETES_PENDING = 'UPLOAD_MAXDELETES_PENDING';
export const UPLOAD_MAXDELETES_SUCCESS = 'UPLOAD_MAXDELETES_SUCCESS';
export const UPLOAD_MAXDELETES_ERROR = 'UPLOAD_MAXDELETES_ERROR';
export const SAVE_MAXDELETES_TRANSACTION_PENDING = 'SAVE_MAXDELETES_TRANSACTION_PENDING';
export const SAVE_MAXDELETES_TRANSACTION_SUCCESS = 'SAVE_MAXDELETES_TRANSACTION_SUCCESS';
export const SAVE_MAXDELETES_TRANSACTION_ERROR = 'SAVE_MAXDELETES_TRANSACTION_ERROR';
export const FETCH_MAXDELETES_DROPDOWNVALUES_PENDING = 'FETCH_MAXDELETES_DROPDOWNVALUES_PENDING';
export const FETCH_MAXDELETES_DROPDOWNVALUES_SUCCESS = 'FETCH_MAXDELETES_DROPDOWNVALUES_SUCCESS';
export const FETCH_MAXDELETES_DROPDOWNVALUES_ERROR = 'FETCH_MAXDELETES_DROPDOWNVALUES_ERROR';
export const RESET_TO_MAXDELETES_INITIALSTATE = 'RESET_TO_MAXDELETES_INITIALSTATE';
export const SET_MAXDELETES_EDITABLE='SET_MAXDELETES_EDITABLE';