import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function fetchUpgradeTemplatesData(planid) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const templatesResponse = await client.apis.plans.getUpgradeZoneTemplates({ "planid": planid ? planid : 'master' });       
       // return templatesResponse.body.data;
       // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": templatesResponse.body.data
            });
        }
        else {
            //this else condition executes when it is older version
            return templatesResponse.body.data;
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function fetchUpgradePathsData(planid,pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseupgradePathResponse = await client.apis.plans.getUpgradePathTemplates({ "planid": planid ? planid : 'master' , 'pageno': pageno?pageno:0});
        const responsesArray = await Promise.all([promiseupgradePathResponse]);
        const [upgradePathResponse] = responsesArray.map(o => o.body.data);
        //return upgradePathResponse.body.data;
        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": upgradePathResponse.rows, "rowcount": upgradePathResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
            return upgradePathResponse;
        }
    } catch (error) {
        console.error(error);
        return {'Error':'getupgradepath failed'}
        throw error;
    }
}

export async function getUpgradePathsFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseUpgradePathsFilterResponse = await client.apis.plans.getUpgradePathsFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseUpgradePathsFilterResponse]);
        const [upgradePathResponse] = responsesArray.map(o => o.body.data);

        return ({
            "gridData": upgradePathResponse.rows, "rowcount": upgradePathResponse.rows[0] ? upgradePathResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'UpgradePaths tab has internal server error, please do re-apply the filter'
    }
}

export async function saveUpgradeTemplateTransactions(planid, transactionList) {
    try {
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promiseArray = [];
        transactionList.forEach(element => {
            if (element.type == 'edit') {
                promiseArray.push(client.apis.plans.updateUpgradeZoneTemplates({ "planid": planid ? planid : "master", "zonecode": element.id }, {
                    requestBody: { "updatedBy": userEmail, "data": element.data }
                }));
            }
        });
        const responsesArray = await Promise.all(promiseArray);
        return responsesArray;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function fetchCarClassGroupData() {
  try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const upgradeCarGroupResponse =  checkAndLoadDimention("carclassAndgroup", client);
    const promiseGetAdminCarGroup = checkAndLoadDimention("admincarclassAndgroup", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
            upgradeCarGroupResponse,
            promiseGetAdminCarGroup,
        ]);

    const [
            carGroupResponse,adminCarGroupResponse
        ] = responsesArray.map(o => o.body.data);

        return ({
            "carGroup": carGroupResponse,
            "adminCarGroup": adminCarGroupResponse,
            "users":promiseUserManagementEmailAndIdMap
        })
        //return upgradeCarGroupResponse.body.data;
  }
  catch(error){
    console.error(error);
        throw error;
  }
}

export async function saveUpgradePaths(planid, transactionList) {
try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.updateUpgradePathTemplates({ "planid": planid ? planid : "master", "pathid": 1 }, { requestBody: { "updatedBy": userEmail, "data": minifyTransaction(transactionList) } });
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
    // transactionList.forEach(element => {
    //     if (element.type == 'edit') {
    //         promiseArray.push(client.apis.plans.updateUpgradePathTemplates({ "planid": planid ? planid : "master", "pathid": element.id }, {
    //             requestBody: { "updatedBy": userEmail, "data": element.data }
    //         }));
    //     }
    //     else if (element.type == 'add' || element.type == 'copy') {
    //         promiseArray.push(client.apis.plans.createUpgradePathTemplates({ "planid": planid ? planid : "master" }, {
    //             requestBody: { "updatedBy": userEmail, "data": element.data }
    //         }));
    //     }
    //     else if (element.type == 'delete') {
    //         promiseArray.push(client.apis.plans.deleteUpgradePathTemplates({ "planid": planid ? planid : "master", 'pathid': element.id }));
    //     }
    // });
    }
  catch(error){
    console.error(error);
        throw error;
  }

}

export async function deleteUpgradePath(planid, id) {
  try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = client.apis.plans.deleteUpgradePathTemplates({"planid":planid?planid:"master",'pathid': id});
    return response;
  }
  catch(error){
    console.error(error);
        throw error;
  }
}

export async function deleteAllUpgradePathsData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const upgradePathTemplatesDeleteResponse = await client.apis.plans.deleteAllUpgradePathTemplatesRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });
        if (upgradePathTemplatesDeleteResponse.obj && upgradePathTemplatesDeleteResponse.obj.statusCode !== '500') {
            return upgradePathTemplatesDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Upgrade Paths tab has internal server error, Unable to delete all the records!!Please try again'
    }
}

export async function uploadUpgradePathsCSV(planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadUpgradePaths({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for UpgradePaths tab, Please try again!!');
    }
}

export async function insertS3UploadUpgradePathsCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedUpgradePathsData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for UpgradePaths tab, Please try again!!');
    }
}

export async function upgradePathsTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateUpgradePaths({"planid": planid ? planid : 1}, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        if (response.obj.statusCode === '500') {
            throw new Error((JSON.parse(response.obj.body)).error);
        }
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch upgradePaths Validation status ,Please try again!!';
        //return { 'Error': 'upgradePathsTabValidationService failed' }
    }
}

export async function getUpgradePathsTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseupgradePathResponse = await client.apis.plans.getUpgradePathsTabErrorStatus({ "planid": planid ? planid : 1 });
        
        if (promiseupgradePathResponse.obj && promiseupgradePathResponse.obj.statusCode !== '500') {
        const responsesArray = await Promise.all([promiseupgradePathResponse]);
        const [upgradePathsTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
        return upgradePathsTabErrorStatusResponse;
        }
        else {
            throw Error();
        }        
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Upgrade Paths error status ,Please try again!!';
        // return { 'Error': 'getUpgradePathsTabErrorStatus failed' }
    }
}
export async function getUpgradePathsErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseupgradepathResponse = await client.apis.plans.getUpgradePathErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promiseupgradepathResponse]);
        const [upgradepathResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": upgradepathResponse.rows, "rowcount": upgradepathResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Upgrade Paths tab'
        //return { 'Error': 'fetchAllocated failed' }
    }
}

export async function getUpgradePathSearchData(planid,pageno,searchParams){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseUpgradePathSearchResponse = client.apis.plans.getUpgradePathSearchRecords({"planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseUpgradePathSearchResponse]);

        const [upgradepathSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": upgradepathSearchResponse.rows, "rowcount": upgradepathSearchResponse.rows[0] ? upgradepathSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Upgrade Paths tab'
    }
}