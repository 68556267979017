import {
    FILTER_SEARCH_UPGRADEPATH_DATA, FETCH_UPGRADEPATH_DATA_PENDING, FETCH_UPGRADEPATH_DATA_SUCCESS, FETCH_UPGRADEPATH_DATA_ERROR,
    GET_PATHS_GROUPBY_DATA, PATH_SAVE_TRANSACTION_PENDING, PATH_SAVE_TRANSACTION_SUCCESS, PATH_SAVE_TRANSACTION_ERROR,
    DELET_UPGRADEPATH_DATA_PENDING, DELET_UPGRADEPATH_DATA_SUCCESS, DELET_UPGRADEPATH_DATA_ERROR, UPLOAD_UPGRADEPATH_PENDING, UPLOAD_UPGRADEPATH_SUCCESS, UPLOAD_UPGRADEPATH_ERROR,
}
    from "../../store/upgradepaths/UpgradeTemplates.ActionTypes";

const initialstate = {
    pending: false,
    error: null,
    upgradePathSearchKey: '',
    upgradePathFilterSearchFlag: false,
    upgradePathFilterSearcData: [],
    upgradePathData: [],
    upgradePathGroupedData: [],
    pathTransactionSaveSuccess: null,
    upgradePathUploadSuccess:null
}

function fetchSearchValues(searchparameters, upgradePathData) {
    const { searchkey, upgradeButtonSelected } = searchparameters;
    let results = getSearchValues(searchkey, upgradePathData);
    return results;
}
function getSearchValues(searchkey, upgradePathData) {
    return upgradePathData.filter((item, i) => {
        let itemkeys = Object.keys(upgradePathData[i]);
        let entries = Object.entries(upgradePathData[i]);
        return entries.find((val, ind) => {
          if(val[0]!=='ID'){
            if (!searchkey.includes('$') &&!searchkey.includes(',') && !searchkey.includes('%') && val[1] !== null && val[1]!==null && val[1]!='' && val[1]!==undefined) {
                return (val[1].toString().toLowerCase().includes(searchkey.toLowerCase()))
            }
            else if (searchkey.includes('$') && (val[0] == 'cost') && val[1]!==null && val[1]!='' && val[1]!==undefined) {
                if (searchkey.split('$')[0] == '') {
                    let searchkey1 = searchkey.split('$')[1];
                    if(searchkey1.includes(',')){
                        return (val[1].toString().toLowerCase().startsWith(searchkey1.toString().replace(/,/g, '')))
                    }
                    else {
                        return (val[1].toString().toLowerCase().startsWith(searchkey1.toLowerCase()))
                    }
                }
            }
            else if(searchkey.includes(',') &&!searchkey.includes('$') && val[1]!==null && val[1]!='' && val[1]!==undefined){
                return (val[1].toString().startsWith(searchkey.toString().replace(/,/g, '')))
            }
            else if (searchkey.includes('%') && (val[0] == 'ultimate_choice') && val[1]!==null && val[1]!='' && val[1]!==undefined) {
                if (searchkey.split('%')[1] == '') {
                    let searchkey1 = searchkey.split('%')[0];
                    if(searchkey1.includes(',')){
                        return (val[1].toString().toLowerCase().startsWith(searchkey1.toString().replace(/,/g, '')))
                    }
                    else {
                        return (val[1].toString().toLowerCase().startsWith(searchkey1.toLowerCase()))
                    }
                }
            }
          }
        })
    })
}

function fetchGroupedData(data, sortData) {
    const { sortkey, sortAscending } = sortData ? sortData : '';
    let accordionData = [];
    let accordiongrouping = {};
    accordiongrouping = data.reduce((r, a) => {
        r[a.upgrade_zone_template_id] = r[a.upgrade_zone_template_id] || {};
        r[a.upgrade_zone_template_id][a.from_cc] = r[a.upgrade_zone_template_id][a.from_cc] || [];
        r[a.upgrade_zone_template_id][a.from_cc].push(a);
        //if sorting true
        if (sortkey !== undefined && sortkey == 'cost' && sortAscending == true) {
            r[a.upgrade_zone_template_id][a.from_cc].sort(function (a, b) {
                return a.cost - b.cost;
            });
        }
        if (sortkey !== undefined && sortkey == 'cost' && sortAscending == false) {
            r[a.upgrade_zone_template_id][a.from_cc].sort(function (a, b) {
                return b.cost - a.cost;
            });
        }
        if (sortkey !== undefined && sortkey == 'ultimate_choice' && sortAscending == true) {
            r[a.upgrade_zone_template_id][a.from_cc].sort(function (a, b) {
                return a.ultimate_choice - b.ultimate_choice;
            });
        }
        if (sortkey !== undefined && sortkey == 'ultimate_choice' && sortAscending == false) {
            r[a.upgrade_zone_template_id][a.from_cc].sort(function (a, b) {
                return b.ultimate_choice - a.ultimate_choice;
            });
        }
        //sorting end
        return r;
    }, Object.create(null));
    let groupedData1 = Object.entries(accordiongrouping);

    groupedData1.map((value, index) => {
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]);
        groupedData2.map((value1, ind) => {
            let obj1 = {};
            obj1['header1'] = ''; obj1['header2'] = '';
            obj1['children'] = [];
            obj1['header1'] = value1[0];
            obj1['header2'] = value1[1][0].from_cc_group;
            obj['children'].push(obj1);
            value1[1].map((value2, index) => {
                let obj2 = {};
                obj2['content'] = [];
                obj2['content'].push(value2);
                obj1['children'].push(obj2);
            })
        })
        accordionData.push(obj);
    })
    if (sortkey !== undefined && sortkey == 'upgrade_zone_template_id' && sortAscending == false) {
        accordionData.sort((a, b) => {
            let fa = a.header.toLowerCase(),
                fb = b.header.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    } else if (sortkey !== undefined && sortkey == 'upgrade_zone_template_id' && sortAscending == true) {
        accordionData.sort((a, b) => {
            let fa = a.header.toLowerCase(),
                fb = b.header.toLowerCase();

            if (fa < fb) {
                return 1;
            }
            if (fa > fb) {
                return -1;
            }
            return 0;
        });
    }
    return accordionData;
}

export default function upgradePathReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_UPGRADEPATH_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case FETCH_UPGRADEPATH_DATA_SUCCESS:
            return Object.assign({}, state, {
                upgradePathData: action.data,
                pending: false,
                upgradePathFilterSearchFlag: false
            });
        case FETCH_UPGRADEPATH_DATA_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending:false
            });
        case GET_PATHS_GROUPBY_DATA:
            var groupedData = fetchGroupedData(state.upgradePathFilterSearchFlag == true ? state.upgradePathFilterSearcData : state.upgradePathData, action.data);
            return Object.assign({}, state, {
                upgradePathGroupedData: groupedData,
            });
        case FILTER_SEARCH_UPGRADEPATH_DATA:
            var searchData = fetchSearchValues(action.data, state.upgradePathData);
            return Object.assign({}, state, {
                upgradePathSearchKey: action.data.searchkey,
                upgradePathFilterSearchFlag: true,
                upgradePathFilterSearcData: searchData
            });
        case PATH_SAVE_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                pathTransactionSaveSuccess: null
            });
        case PATH_SAVE_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                upgradePathData: action.data,
                pathTransactionSaveSuccess: true,
                upgradePathFilterSearchFlag:false,
                upgradePathSearchKey:''
            });
        case PATH_SAVE_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                pathTransactionSaveSuccess: false
            });
        case DELET_UPGRADEPATH_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case DELET_UPGRADEPATH_DATA_SUCCESS:
            return Object.assign({}, state, {
                upgradePathData: action.data,
                pending: false,
                upgradePathFilterSearchFlag: false
            });
        case DELET_UPGRADEPATH_DATA_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending:false
            });
        case UPLOAD_UPGRADEPATH_PENDING:
            return Object.assign({}, state, {
                pending: true,
                upgradePathUploadSuccess:null
            });
        case UPLOAD_UPGRADEPATH_SUCCESS:
            return Object.assign({}, state, {
                upgradePathData: action.data.upgradepath,
                pending: false,
                upgradePathFilterSearchFlag: action.data.filterBy,
                upgradePathUploadSuccess:true
            });
        case UPLOAD_UPGRADEPATH_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending:false,
                upgradePathUploadSuccess:false
            });
        default:
            return state;
    }

}