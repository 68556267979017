import { columnDefs, defaultColDef } from "./userManagement_grid_data/UserManagementColumnDefs";
import { FETCH_PROGRAM_DROPDOWN_PENDING, FETCH_PROGRAM_DROPDOWN_SUCCESS, FETCH_PROGRAM_DROPDOWN_ERROR, SET_PROGRAM_EDITABLE, FETCH_ROLE_DROPDOWN_PENDING, FETCH_ROLE_DROPDOWN_SUCCESS, FETCH_ROLE_DROPDOWN_ERROR } from "./UserManagement.ActionTypes";

const initialState = {
    columnDefs,
    defaultColDef,
    validationFn: function (data) {
        return {
            ID: data.ID,
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === "role_name") {
            let roleNameList=[];
            if(data){
                data.forEach((roleObj)=>{
                    roleNameList.push(roleObj.role_name);
                })
            }
            column.cellEditorParams = { options: roleNameList }
        }
    });
    //console.log("Fetch the column degs " + JSON.stringify(columnDefs))
    return columnDefs;
}

export default function columnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ROLE_DROPDOWN_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ROLE_DROPDOWN_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_ROLE_DROPDOWN_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}