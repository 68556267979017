import { columnDefs, defaultColDef } from "./additionalCost_grid_data/AdditionalCostColumnDefs";
import { FETCH_ADDITIONALCOST_DROPDOWNVALUES_PENDING,FETCH_ADDITIONALCOST_DROPDOWNVALUES_SUCCESS,FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR, SET_ADDITIONALCOST_EDITABLE } from '../additionalcost/AdditionalCost.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import { AdditionaCostColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, AdditionaCostColumns.Zone.headerName) },
            MANUFACTURER_GROUP: { error: OptionalValue(data.MANUFACTURER_GROUP, AdditionaCostColumns.ManufacturerGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===AdditionaCostColumns.ManufacturerGroup.field)) },
            MAINTENANCE_COST: { error: checkIfValueEmpty(data.MAINTENANCE_COST, AdditionaCostColumns.MaintenanceCost.headerName) },
            DAMAGE_COST: { error: checkIfValueEmpty(data.DAMAGE_COST, AdditionaCostColumns.DamageCost.headerName) },
            NET_IN_SERVICE_COST: { error: checkIfValueEmpty(data.NET_IN_SERVICE_COST, AdditionaCostColumns.NetInServiceCost.headerName) },
            REPURCHASE_RECONDITIONING_COST: { error: checkIfValueEmpty(data.REPURCHASE_RECONDITIONING_COST, AdditionaCostColumns.RepurchaseReconditioningCost.headerName) },
            RISK_RECONDITIONING_COST: { error: checkIfValueEmpty(data.RISK_RECONDITIONING_COST, AdditionaCostColumns.RiskReconditioningCost.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name) {
    if(name===AdditionaCostColumns.Zone.headerName){
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        } else if(value==='ALL'){
            return name + " Cannot be ALL"
        }
    } else 
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    return null;
}

function OptionalValue(value,name,index) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } else if (value === 'ALL') {
        return name + " Cannot be ALL"
    }
    else if (initialState.columnDefs[index].cellEditorParams !== undefined) {
        let allManufacturerGroup = sessionStorage.getItem("MANUFACTURER") ? (JSON.parse(sessionStorage.getItem("MANUFACTURER"))).body.data : [];
        if (allManufacturerGroup && allManufacturerGroup.length && (allManufacturerGroup.indexOf(value) == -1)) {
            return name + " " + value + " no-longer available";
        }
    }
    return null;
}

function checkTypeCurrency(value1, value2) {
    if (value1 == value2) {
        return 'currency';
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
                        if (safeCaseInsensitiveCompare(itm.MANUFACTURER_GROUP, itm2.MANUFACTURER_GROUP) &&
                (safeCaseInsensitiveCompare(itm.ZONE_NAME , itm2.ZONE_NAME))) {
                unique = false;
                                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData=[];
    zones.forEach(val => {
        zoneData.push(val.name)
        zoneFilterData.push({ "label": val.name, "value": val.name })
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: zoneData };
    element.filterOptions = zoneFilterData;
}

function fetchDropdownForManufacturerGroup(manufacturerGroupName, adminManufacturerGroup,element) {
    let manufacturerFilterData = [];
    adminManufacturerGroup.map((val) => {
                manufacturerFilterData.push({ "label": val.code, "value": val.code })
            })
            manufacturerFilterData.push({"label":"Blanks","value":"Blanks"})

    element.cellEditorParams = { options: manufacturerGroupName };
    element.filterOptions = manufacturerFilterData;
}

function fetchZones(additionalCostData, zones) {
    additionalCostData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return additionalCostData;
}

function fetchFilterValues(manufacturergroupname, zones,adminManufacturerGroup,users, columns) {
    columns.forEach(element => {
        if (element.field === AdditionaCostColumns.Zone.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field === AdditionaCostColumns.ManufacturerGroup.field) {
            fetchDropdownForManufacturerGroup(manufacturergroupname, adminManufacturerGroup,element);
        }else if(element.field === AdditionaCostColumns.UpdatedBy.field){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'AdditionalCostActionRenderer'
            }
        }
    });

    return columns;
}

export default function additionalCostColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ADDITIONALCOST_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ADDITIONALCOST_DROPDOWNVALUES_SUCCESS:
            //const additionalCostData = fetchZones(action.data.additionalCostData, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(
                    action.data.manufacturer_group,
                    action.data.zones,action.data.adminManufacturerGroup,
                    action.data.users, state.columnDefs)
            });
        case FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_ADDITIONALCOST_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}