import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {getRfDetailsByRfId} from '../../../../../store/revenuefunction/allrfs/AllRFs.Actions';
import { useDispatch } from 'react-redux';

export default (params) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rf_id = params.data.ID;
  const status = params.data.STATUS;
  const [selectedRfId, setSelectedRfId] = useState();

  function loadRfDetailsByRfId(rfid){
    dispatch(getRfDetailsByRfId(rfid))
  }

  const handleNavigation = () => {
    if (params && params.data.STATUS && (params.data.STATUS.toLowerCase() === "completed" || params.data.STATUS.toLowerCase() === "optimization complete")) {
      loadRfDetailsByRfId(rf_id);
      let url = "/revenuefunction/" + rf_id + "/rfinputs";
      setSelectedRfId(rf_id);
      history.push({
        pathname: url,
      });
    }
  };

  return (
    <div>
      <label onClick={(e) => handleNavigation()} className={(status.toLowerCase() === 'optimization complete') || (status.toLowerCase() === 'completed') ? 'rfidlink cursorpoint' : ''}>{params.value}</label>
    </div>
  )
}