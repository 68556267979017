import { dateFormatter, currencyFormatter, valueFormatter, localeFormatter,updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { cellEditors, SchedulesColumns} from "../../../Constants";
import { customDateComparator, customNumericSortComparator, customStringComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: SchedulesColumns.SpiCode.field, 
        headerName: SchedulesColumns.SpiCode.headerName, 
        cellEditor: cellEditors.freeSoloAutoCompleteEditor,
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.SpiCode.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.SpiCode.name);
        }
    },
    {
        field: SchedulesColumns.CarClassCode.field, 
        headerName: SchedulesColumns.CarClassCode.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.CarClassCode.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.CarClassCode.name)
        }
    },
    {
        field: SchedulesColumns.ModelGroup.field, 
        headerName: SchedulesColumns.ModelGroup.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.ModelGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.ModelGroup.name)
        }
    },
    {
        field: SchedulesColumns.ModelYear.field, 
        headerName: SchedulesColumns.ModelYear.headerName, 
        minWidth: 120, 
        unSortIcon: true, 
        cellEditor: cellEditors.numericEditor, 
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.ModelYear.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.ModelYear.name)
        }
    },
    //{field:'manufacturer_group',hide:'true'},
    {
        field:SchedulesColumns.ManufacturerGroup.field,
        headerName: SchedulesColumns.ManufacturerGroup.headerName, 
        minWidth: 120, 
        unSortIcon: true,
        cellEditor: cellEditors.autoCompleteEditor,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.ManufacturerGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.ManufacturerGroup.name)
        },
        cellEditorParams:(params)=>{
            let options =  getModelgroupBasedOnManufacturerGroup(params);
            return options;
          },
        // valueGetter:params=>{
        //     params.data.manufacturer_group=getModelgroupBasedOnManufacturerGroup(params);
        //     return params.data.manufacturer_group
        // }
        valueFormatter:params=>{
            // console.log("params.data===",params.data);
            return localeFormatter(params.data.manufacturer_group);
        },
    },
    {
        field: SchedulesColumns.ProgramYear.field, 
        headerName: SchedulesColumns.ProgramYear.headerName,  
        minWidth: 100, 
        unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.ProgramYear.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.ProgramYear.name)
        }
    },
    {
        field: SchedulesColumns.ProgramType.field, 
        headerName: SchedulesColumns.ProgramType.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.ProgramType.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.ProgramType.name)
        }
    },
    {
        field: SchedulesColumns.AcceptableZones.field, 
        headerName: SchedulesColumns.AcceptableZones.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.AcceptableZones.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.AcceptableZones.name)
        }
    },
    {
        field: SchedulesColumns.AllocationMonth.field, 
        headerName: SchedulesColumns.AllocationMonth.headerName, 
        minWidth: 110, 
        unSortIcon: true,
        valueFormatter: params => dateFormatter(params.data.allocation_month),
        cellEditor: cellEditors.dateEditor,
        filterType:"datepicker",
        cellClassRules: cellClassRules(SchedulesColumns.AllocationMonth.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.AllocationMonth.name)
        }
    },
    {
        field: SchedulesColumns.MinSchedule.field, 
        headerName: SchedulesColumns.MinSchedule.headerName, 
        unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',
        comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.min_schedule),
        filterType:"range",
        cellClassRules: cellClassRules(SchedulesColumns.MinSchedule.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.MinSchedule.name)
        }
    },
    {
        field: SchedulesColumns.MaxSchedule.field, 
        headerName: SchedulesColumns.MaxSchedule.headerName, 
        unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',
        comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.max_schedule),
        filterType:"range",
        cellClassRules: cellClassRules(SchedulesColumns.MaxSchedule.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.MaxSchedule.name)
        }
    },
    {
        field:  SchedulesColumns.UpdatedBy.field, 
        headerName: SchedulesColumns.UpdatedBy.headerName, 
        editable: false, 
        unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(SchedulesColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.UpdatedBy.name)
        }
    },
    {
        field: SchedulesColumns.UpdatedOn.field,
        headerName: SchedulesColumns.UpdatedOn.headerName,
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, 
        cellClassRules: cellClassRules(SchedulesColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, SchedulesColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "SchedulesActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field]) {
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

function getModelgroupBasedOnManufacturerGroup(params){   
    let allManufacturergroupModelgroup =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
    let trimmedModalGroup=params.data.model_group?params.data.model_group.trim():'';
    let options=allManufacturergroupModelgroup[trimmedModalGroup]?allManufacturergroupModelgroup[trimmedModalGroup]:[];
    return {'options':options};
  } 


export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
