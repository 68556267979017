/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import "./SearchComponent.css";
import { useDispatch, useSelector } from "react-redux";
import Active from "../../assets/images/icon_search_active.svg";
import { setGridErrorViewStatus, setGridFilterParams, setGridFilterViewStatus, setGridSearchParams, setGridSearchViewStatus } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { isWordWithoutSpecialCharacterSpaceAllowed } from '../../utils/utils';
import { cellEditors } from '../../Constants';
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { CANNOT_CHANGE_DATASET_ERROR } from '../../Constants';

const SearchComponent = (props) => {
    const {onEnterSearch,disabledTextBox,gridColumnDefs,isButtonRequired,hasPendingTransactions} = props;

    const dispatch = useDispatch();

    const gridSearchView = useSelector((state) => {
        return state.PaginationGridReducer.gridSearchView ;
    })

    const gridUserSearchParams = useSelector((state) => {
        return state.PaginationGridReducer.gridUserSearchParams ;
    });

    const gridName = useSelector((state) => {
        return state.PaginationGridReducer.gridName
    })
    
    const SearchTextRef = useRef(gridSearchView[gridName] ? gridUserSearchParams[gridName]["searchText"] : '') // adding reference to the input field

// Note:-
// To make use of this component, you must implement the following two modifications :-
// 1) Update the Column filterOptions with Admin Values, which includes both Archived and Non-Archived options.
// 2) If there are any Non-Editable Columns but still need to be included in the search functionality, then filter options should be created for those columns.
//
//
// How it Works :-
// getSearchParams function takes the search text as parameter and creates an object of search params consist of 
//  1)searchByColumnNames- a Array of list of columns which is helpfull for query to search text in only this list of columns.
//  2)searchText- user entered text to be found in the database table.
//
// Functionality :-
// 1) If the search Text consist of only Numeric Value
// This feature iterates through the filter options of each column and checks if there is a match with the searchText. 
// If a match is found, the fieldName of that column will be added to the searchByColumnNames Array. Additionally, 
// all numeric and textfield columns will also be added to the searchByColumnNames Array.
//
// 2) If the search Text consist of both Text and Numeric or only Text Value
// This feature iterates through the filter options of each column and checks if there is a match with the searchText. 
// If a match is found, the fieldName of that column will be added to the searchByColumnNames Array. Additionally, 
// all textfield columns will also be added to the searchByColumnNames Array.

    const getSearchParams = (searchText) => {
        let searchParams={"searchByColumnNames":[],"searchText":searchText}

        if (gridColumnDefs && gridColumnDefs.columnDefs && gridColumnDefs.columnDefs.length > 0) {
                const matcher = new RegExp(`${searchText}`, 'gi');
                gridColumnDefs.columnDefs.map(cname => {
                    if (cname.filterOptions && cname.filterOptions.length>0 && cname.cellEditor != cellEditors.numericEditor && cname.cellEditor != cellEditors.TextFieldEditor) {
                        if (cname.filterOptions.some(word => word.label.match(matcher))) {
                            searchParams.searchByColumnNames.push(cname.field)
                        }
                    }
                    else if(cname.field && ((cname.cellEditor === cellEditors.numericEditor && !isNaN(searchText)) || cname.cellEditor === cellEditors.TextFieldEditor || cname.cellEditorSelector)){
                        searchParams.searchByColumnNames.push(cname.field)
                    }
                })
        }

        dispatch(setGridSearchParams(gridName, searchParams));
        //loadGridDataForSearchParam(searchParams, true);
    }
    
    const onKeyEnterSearch = (e) => {
        if(hasPendingTransactions){
            dispatch(addNotificationMessage('error',CANNOT_CHANGE_DATASET_ERROR))
        }else if (e.target.value != '' && !isWordWithoutSpecialCharacterSpaceAllowed(e.target.value)) {
            dispatch(setGridFilterParams(gridName, gridColumnDefs, true))
            dispatch(setGridFilterViewStatus(gridName, false));
            dispatch(setGridErrorViewStatus(gridName, false));
            dispatch(setGridSearchViewStatus(gridName, true));
            getSearchParams(e.target.value)
        } else {
            dispatch(addNotificationMessage('error', 'Search Text cannot have special characters, except "-", "_" and "." '))
        }
    }

    const onSearchValueChanged = (e) => {
        if(hasPendingTransactions){
            dispatch(addNotificationMessage('error',CANNOT_CHANGE_DATASET_ERROR))
            SearchTextRef.current.value = gridSearchView[gridName] ? gridUserSearchParams[gridName]["searchText"] : ''
          }
        else if (e.target.value === '' && gridSearchView[gridName]) {
            dispatch(setGridSearchParams(gridName,null))
            dispatch(setGridSearchViewStatus(gridName, false))
           // loadGridDataForSearchParam(e.target.value, false);
        }
        // else if(e.target.value != '' && isWordWithoutSpecialCharacterSpaceAllowed(e.target.value)){
        //     SearchTextRef.current.ariaInvalid = "true"
        // }
        // else{
        //     SearchTextRef.current.ariaInvalid = "false"
        // }
    }

    useEffect(() => {
        if(isButtonRequired){
            SearchTextRef.current.value = gridSearchView[gridName] ? gridUserSearchParams[gridName]["searchText"] : ''
            //SearchTextRef.current.ariaInvalid = "false"
        }
    }, [gridName, gridSearchView])

    return (
        <div>
            {isButtonRequired ?
                <TextField className="newSearchInput" aria-invalid='false' id="input-with-icon-textfield" inputRef={SearchTextRef}
                    placeholder={onEnterSearch ? "Type & Enter To Search" : "Search"} InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" className="inputadorent">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (gridSearchView[gridName]) ? (
                            <InputAdornment position="end" className="inputadorent">
                                <img src={Active} className="imageIcon"></img>
                            </InputAdornment>
                        ) : ''
                    }}
                    disabled={disabledTextBox}
                    // helperText={`Text cannot have any special characters, except "-" and "_"`}
                    type="search" variant="outlined" onChange={(e) => onSearchValueChanged(e)} onKeyPress={(e) => {
                        if (onEnterSearch && e.key === "Enter") {
                            onKeyEnterSearch(e)
                        }
                    }} /> : ''}
        </div>
    );

}

SearchComponent.propTypes = {
    onSearchValueChanged: PropTypes.any
}

export default SearchComponent;