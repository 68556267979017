import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import MultiGridUI from '../../../../components/revenuefunction/grid/RevenueFunctionMultiGridUI';
import ActionBar from '../../../../components/revenuefunction/mainrfinputsactionbar/MainRfInputsActionBar';
import {fetchMainRFInputTncData} from '../../../../store/revenuefunction/mainrfinputs/tncice/TncIce.Actions';

const TncEvTabPanel = (props) => {
    const {showSaveOption,downloadCSV,tncEvGridColDefReducer,tncEvGridReducer,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,setIsSearchBarValueExists,isSearchBarValueExists,refreshCells,handleMultipleRowDelete,hideActions,rfid,loadGridData} = props;
    return (
        <div>
        <MultiGridUI
        reducer={tncEvGridReducer}
        onRowEditingStarted={handleRowEditingStarted}
        onRowEditingStopped={handleRowEditingStopped}
        onCellEditingStopped={handleCellEditingStopped}
        gridName={'tncevgrid'}
        rowEditType={'single'}
        gridColDef={tncEvGridColDefReducer}
        suppressclickEdit={!tncEvGridColDefReducer.defaultColDef.editable}
        refreshCells={refreshCells}
        handleMultipleRowDelete={handleMultipleRowDelete}
        hideActions={hideActions}
        isPaginationRequired={true} 
        rfid={rfid}
        loadGridData={loadGridData}
        >
        </MultiGridUI>
        </div>
    )
}

export default TncEvTabPanel;