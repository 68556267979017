import { SET_ASSUMPTION_ERROR_VALUE, SET_ASSUMPTION_INDEX_VALUE, SET_ASSUMPTION_PENDING_VALUE } from "./PlanAssumptions.ActionTypes";


export const SetAssumptionIndex = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_INDEX_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const SetAssumptionPending = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_PENDING_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const SetAssumptionError = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_ERROR_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}