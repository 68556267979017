import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../assets/images/icon_action_download.svg";
import './PlanAssumptionsActionbar.css';
import {uploadAgeMileageData,fetchAgeMileageData,fetchAgeMileageDropdownValues} from '../../store/agemileage/AgeMileage.Actions';
import {uploadAdditionalCostData,fetchAdditionalCostData,fetchAdditionalCostDropdownValues} from '../../store/additionalcost/AdditionalCost.Actions';
import {fetchMileageDefaultDropdownValues} from '../../store/mileagedefault/MileageDefault.Actions';
import {fetchMaxDeletesDropdownValues} from '../../store/maxdeletes/MaxDeletes.Actions';
import {fetchAddsInvalidPoolsDropdownValues} from '../../store/addsinvalidpools/AddsInvalidPools.Actions';
import {fetchTargetZonePoolDropdownValues} from '../../store/targetzonepool/TargetZonePool.Actions';
import {fetchZoneMapDropdownValues} from '../../store/zonemap/ZoneMap.Actions';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Button,CircularProgress,Tooltip,Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import RangeFilter from '../filter_components/range_filter/RangeFilter';
import DropdownFilter from '../filter_components/dropdown_filter/DropdownFilter';
import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';
import DialogBox from "../dialog_component/CustomDialog";
import IconFilter from "../../assets/images/icon_filter.svg";
import IconFilterActive from "../../assets/images/icon_filter_active.svg";
import SearchBar from "../searchbar/SearchBarComponent";
import InputFilter from '../filter_components/input_filter/InputFilter';
import DatePickerFilter from '../filter_components/datepicker_filter/DatePickerFilter';
import { populateGrid2, validateGrid } from '../../store/MultiGrid/MultiGrid.Actions';
import { safeExcelValue, safeExcelDate } from '../../utils/utils';
import { uploadMaxDeletesData } from '../../store/maxdeletes/MaxDeletes.Actions';
import { uploadMileageDefaultData } from '../../store/mileagedefault/MileageDefault.Actions';
import { uploadAddsInvalidPoolsData } from '../../store/addsinvalidpools/AddsInvalidPools.Actions';
import { uploadSalvageRateData } from '../../store/salvagerate/SalvageRate.Actions';
import { fetchMoveCostData, fetchMoveCostErrorData, fetchMoveCostFilterData, fetchMoveCostSearchData, InsertuploadMoveCostData, SetAssumptionMoveCostErrorViewStatus, SetAssumptionMoveCostPageValue, SetAssumptionMoveCostUploadStatus, setMoveCostFilterParameters, setMoveCostFilterSearchView, setMoveCostFilterView, setMoveCostFilterSearchText, uploadMoveCostData,deleteMoveCostRecords ,fetchMoveCostDropdownValues} from '../../store/movecost/MoveCost.Actions';
import { fetchSalvageRateDropdownValues} from '../../store/salvagerate/SalvageRate.Actions';
import { fetchDepRateDropDownValues} from '../../store/deprate/DepRate.Actions';
import { fetchMileageTableDropdownValues} from '../../store/mileagetable/MileageTable.Actions';
import { getDownloadPreSignedUrl, getMoveCostData, getMoveCostErrorData, getUploadPreSignedUrl, insertUploadData } from '../../services/movecost/movecostservice';
import { FetchUploadInsertStatus } from '../../services/uploadinsertstatus/insertstatus';
import { setinsertStatusCheckFlag, SetUploadFileInsert } from '../../store/topbar/AllPlans.Actions';
import { uploadTargetZonePoolData } from '../../store/targetzonepool/TargetZonePool.Actions';
import { uploadZoneMapData } from '../../store/zonemap/ZoneMap.Actions';
import {uploadDepRateData} from '../../store/deprate/DepRate.Actions';
import { uploadMileageTableData } from '../../store/mileagetable/MileageTable.Actions';
import SingleButtonDialogComponent from '../Dialogcomponent/SingleButtonDialogComponent';
import { ManualVinDeletesfilterCategory, MoveCostfilterCategory } from '../../Constants';
import { uploadDiversionsData } from '../../store/diversions/Diversions.Actions';
import deleteIcon from "../../assets/images/icon_trash.svg";
import { SetAssumptionManualVinDeletesErrorViewStatus, SetAssumptionManualVinDeletesPageValue, fetchManualVinDeletesData, setManualVinDeletesFilterParameters, setManualVinDeletesFilterSearchText, setManualVinDeletesFilterSearchView, setManualVinDeletesFilterView, uploadManualVinDeletesData,fetchManualVinDeletesSearchData,fetchManualVinDeletesFilterData, fetchManualVinDeletesErrorData,deleteManualVinDeletesRecords} from '../../store/manualvindeletes/ManualVinDeletes.Actions';
import { getManualVinDeletesData } from '../../services/manualvindeletes/ManualVinDeletesService';
import S3UploadDownloadComponent from '../s3_upload_download_component/S3UploadDownloadComponent';

const PlanAssumptionsActionbar = (props) => {
    const { planAssumptionsGrid, options, onAddNewRowClick, handleSaveCancelDisabled, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled,movecostFilterParams,
        transactions, currentData,planAssumptionData,planAssumptionGridColDef, ageMileageData, ageMileageGridColDef, additionalCostData,moveCostTransactions, additionalCostGridColDef,addsInvalidPoolsData, addsInvalidPoolsGridColDef,filterData,movecostFilterDisplayData,moveCostCurrentdata, filterParams, data, downloadCSV,manualVinDeletesFilterDisplayData,manualVinDeletesFilterParams,manualVinDeletesTransactions,userMessage,mileageDefaultCurrentdata,additionalCostCurrentdata,maxDeletesCurrentdata,addsInvalidPoolsCurrentdata,salvageRateCurrentdata,targetZonePoolCurrentdata,zoneMapCurrentdata,depRateCurrentdata,mileageTableCurrentdata } = props;
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [filterArray, setFilterArray] = useState();
    const [tempFilterArray, setTempFilterArray] = useState(filterArray);
    const theme = createMuiTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterSearchKey,setiFlterSearchKey] = useState('');
    const [showProgress,setShowProgress]=useState(false);
    const[progress,setProgress]=useState(0)
    const[insertProgress,setinsertProgress]=useState(null)
    const[openDeleteRecordsDialog,setOpenDeleteRecordsDialog]=useState(false);
    const [deleteRowmessage,setDeleteRowmessage] = useState('');
    const [filterDisplayData,setFilterDisplayData] = useState();
    const [showDialog,setShowDialog]=useState(false)
    const popOverOpen = Boolean(anchorEl);
    const id = popOverOpen ? 'simple-popover' : undefined;
    let message = "Are you sure that you want to delete selected rows?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";

    useEffect(()=>{
     if(planAssumptionsGrid==='movecostgrid'){
        setiFlterSearchKey(moveCostFilterSearchText)
        setFilterDisplayData(movecostFilterDisplayData);
        setFilterArray(movecostFilterParams==null?MoveCostfilterCategory:movecostFilterParams)
        setTempFilterArray(movecostFilterParams==null?MoveCostfilterCategory:movecostFilterParams)
        setShowErrorRecords(moveCostErrorView);
     }else if(planAssumptionsGrid==='manualvindeletesgrid'){
        setiFlterSearchKey(manualVinDeletesFilterSearchText)
        setFilterDisplayData(manualVinDeletesFilterDisplayData);
        setFilterArray(manualVinDeletesFilterParams==null?ManualVinDeletesfilterCategory:manualVinDeletesFilterParams)
        setTempFilterArray(manualVinDeletesFilterParams==null?ManualVinDeletesfilterCategory:manualVinDeletesFilterParams)
        setShowErrorRecords(manualVinDeletesErrorView);
     }
    },[planAssumptionsGrid])

    //Plan Edit Disabled useSelector
    let ifPlanEditDisabled = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
      });
    // console.log("ifPlanEditDisabled, editable===", ifPlanEditDisabled,planAssumptionGridColDef.defaultColDef.editable);
    if(!ifPlanEditDisabled){
        ifPlanEditDisabled=!planAssumptionGridColDef.defaultColDef.editable;
    }

    //TOREVIEW: needs review. Font size shouldn't set arbitrarily like this
    theme.typography.body2 = {
        fontSize: '14px'
    };
    //TOREVIEW: these states are required to render the componenets. It is not clear why they are required, I cannot find an referenes anywhere.
    const [selectedDropdown, setSelectedDropdown] = React.useState("between");
    const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
    const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
    const [selectedFromDate, setSelectedFromDate] = React.useState();
    const [selectedToDate, setSelectedToDate] = React.useState();
    const[FileToUpload,SetFileToUpload]=useState(null);
    const [fileNameToDownload,setFileNameToDownload]=useState(null);
    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));

    const moveCostErrorView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostErrorView;
      });

      var uploadedFileInsertStatus = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.UploadedFiles;
      });

      const masterDataUpdateState = useSelector((state)=>{
        return state.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
      })

      const moveCostDisableButton = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostUploadStatus;
            });

      var insertStatusCheckFlag = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.insertStatusCheckFlag;
      });

      const moveCostFilterView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterView;
      });

      const moveCostFilterSearchView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterSearchView;
      });

      const moveCostPageNo = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostpageno;
        });

    const moveCostColumnsConstantData = useSelector((state) => {
            return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostColumnsConstantData;
         });

    const moveCostFilterSearchText = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterSearchText;
    });

    const moveCostTotData = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostTotalRowCount;
    });
    
    const movecostGrandTotalRowCount = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostGrandTotalRowCount;
    });

    const uniqueErrorIDs = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs;
    });

    const manualVinDeletesFilterView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterView;
      });
    const manualVinDeletesErrorView = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesErrorView;
    });
    const manualVinDeletesFilterSearchText = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterSearchText;
    });
    const manualVinDeletesFilterSearchView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesFilterSearchView;
    });
    const manualVinDeletesColumnsConstantData = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesColumnsConstantData;
     });
    const manualVinDeletesPageNo = useSelector((state) => {
    return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesPageNo;
    });
    const manualVinDeletesTotalRowCount = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesTotalRowCount;
    })
    const manualVinDeletesUniqueErrorIDs = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.uniqueErrorIDs;
    });
    const manualVinDeletesData = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesData;
    });
    const manualVinDeletesErrorData = useSelector((state)=>{
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesErrorData
    })

    const[showErrorRecords,setShowErrorRecords]=useState(planAssumptionsGrid==='movecostgrid'?moveCostErrorView:planAssumptionsGrid==='manualvindeletesgrid'?manualVinDeletesErrorView:false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClickOpen = (props) => {
        if (!bulkRowDeleteIconDisabled) {
            setOpenDeleteDialog(true);
        }
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteSelectedRows = () => {
        setOpenDeleteDialog(false);
        deleteSelectedRows()
    }


    const SetMoveCostFilterParams = useCallback(async (tempFilterArray) => {
        try {
           await dispatch(setMoveCostFilterParameters(tempFilterArray))
        } catch (err) {
            console.error('got error while loading filterparams');
        }
    }, [dispatch]);
    
    const SetManualVinDeletesFilterParams = useCallback(async (tempFilterArray) => {
        try {
           await dispatch(setManualVinDeletesFilterParameters(tempFilterArray))
        } catch (err) {
            console.error('got error while loading filterparams');
        }
    }, [dispatch]);

    const handleFilterClick = (event) => {
        setOpen(true);   
    };

    const clearFilter = (event) => {
     if(planAssumptionsGrid==='movecostgrid'){
        //setOpen(false);    
        SetMoveCostFilterParams(null);
        setFilterArray({...MoveCostfilterCategory,"move_cost":["between"]});
        setTempFilterArray({...MoveCostfilterCategory,"move_cost":["between"]});
        if(moveCostFilterView){
            dispatch(SetAssumptionMoveCostPageValue(1))
        }
        if(moveCostErrorView && moveCostFilterView){
            dispatch(SetAssumptionMoveCostErrorViewStatus(!showErrorRecords))
            setShowErrorRecords(!showErrorRecords)
        }
        dispatch(setMoveCostFilterView(false))
    }
    else if(planAssumptionsGrid==='manualvindeletesgrid'){
        // setOpen(false);    
        SetManualVinDeletesFilterParams(null);
        setFilterArray({...ManualVinDeletesfilterCategory,"MONTH": ["between"]});
        setTempFilterArray({...ManualVinDeletesfilterCategory,"MONTH": ["between"]});
        if(manualVinDeletesFilterView){
            dispatch(SetAssumptionManualVinDeletesPageValue(1))
        }
        if(manualVinDeletesErrorView){
            setShowErrorRecords(manualVinDeletesErrorView)
        }
        dispatch(setManualVinDeletesFilterView(false))
    }
        
    }

    const applyFilter = (event) => {
        setiFlterSearchKey('')
        setOpen(false);
        setFilterArray({...tempFilterArray});
        if(planAssumptionsGrid==='movecostgrid'){
            dispatch(setMoveCostFilterSearchView(false))
            dispatch(setMoveCostFilterSearchText(''))
            dispatch(SetAssumptionMoveCostPageValue(1))
            SetMoveCostFilterParams({...tempFilterArray});
            dispatch(setMoveCostFilterView(true));  
            setShowErrorRecords(false)
            dispatch(SetAssumptionMoveCostErrorViewStatus(false))   
        }else if(planAssumptionsGrid==='manualvindeletesgrid'){
            dispatch(setManualVinDeletesFilterSearchView(false))
            dispatch(setManualVinDeletesFilterSearchText(''))
            dispatch(SetAssumptionManualVinDeletesPageValue(1))
            SetManualVinDeletesFilterParams({...tempFilterArray});
            dispatch(setManualVinDeletesFilterView(true));  
            setShowErrorRecords(false)
            dispatch(SetAssumptionManualVinDeletesErrorViewStatus(false));
        }
    }

    const handleClose = (e) => {
        setOpen(false);
        setTempFilterArray(filterArray);
    };


    const getAllErrorRecords=async(e)=>{
        if(planAssumptionsGrid==='movecostgrid'){
            setiFlterSearchKey('')
            dispatch(setMoveCostFilterSearchText(''))
            dispatch(setMoveCostFilterSearchView(false))
            dispatch(setMoveCostFilterView(false))  
            dispatch(SetAssumptionMoveCostErrorViewStatus(!showErrorRecords))
            setShowErrorRecords(!showErrorRecords)
            dispatch(SetAssumptionMoveCostPageValue(1));
        }else if(planAssumptionsGrid==='manualvindeletesgrid'){
            dispatch(setManualVinDeletesFilterSearchView(false))
            dispatch(setManualVinDeletesFilterView(false))  
            dispatch(SetAssumptionManualVinDeletesErrorViewStatus(!showErrorRecords))
            setShowErrorRecords(!showErrorRecords)
            dispatch(SetAssumptionManualVinDeletesPageValue(1))
        }
    }

    useEffect(() => {
        if (masterDataUpdateState) {
            //This dispatch and useEffect is triggered only if master cache data is been updated by admin 
            //masterDataUpdateState is at redux state for handling true/false if master cache data has been updated
            //Calling of DropDownService is used to push last updated cache master data to there respective dropdowns
            setShowDialog(true)
            switch (planAssumptionsGrid) {
                case 'agemileagegrid':
                    dispatch(fetchAgeMileageDropdownValues(selectedPlanId, data, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'additionalcostgrid':
                    dispatch(fetchAdditionalCostDropdownValues(selectedPlanId, additionalCostCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'maxdeletegrid':
                    dispatch(fetchMaxDeletesDropdownValues(selectedPlanId, maxDeletesCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'addsinvalidpoolsgrid':
                    dispatch(fetchAddsInvalidPoolsDropdownValues(selectedPlanId, addsInvalidPoolsCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'mileagedefaultgrid':
                    dispatch(fetchMileageDefaultDropdownValues(selectedPlanId, mileageDefaultCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'movecostgrid':
                    dispatch(fetchMoveCostDropdownValues(selectedPlanId, moveCostCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'salvagerategrid':
                    dispatch(fetchSalvageRateDropdownValues(selectedPlanId, salvageRateCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'targetzonepoolgrid':
                    dispatch(fetchTargetZonePoolDropdownValues(selectedPlanId, targetZonePoolCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'zonemapgrid':
                    dispatch(fetchZoneMapDropdownValues(selectedPlanId, zoneMapCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'deprategrid':
                    dispatch(fetchDepRateDropDownValues(selectedPlanId, depRateCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
                case 'mileagetablegrid':
                    dispatch(fetchMileageTableDropdownValues(selectedPlanId, mileageTableCurrentdata, planAssumptionGridColDef, masterDataUpdateState));
                    break;
            }
        }
    }, [masterDataUpdateState])

    useEffect(()=>{
        if(moveCostFilterSearchText!=null && planAssumptionsGrid==='movecostgrid'){
            setiFlterSearchKey(moveCostFilterSearchText)
        }else if(manualVinDeletesFilterSearchText!=null && planAssumptionsGrid==='manualvindeletesgrid'){
            setiFlterSearchKey(manualVinDeletesFilterSearchText)
        }
    },[])

    useEffect(()=>{
        if(planAssumptionsGrid==='movecostgrid'){
            // console.log("MOVE COST grid update");
            let paramsLength=Object.keys(movecostFilterParams?movecostFilterParams:{})
            if(paramsLength.length==0 || moveCostFilterView==false){
                if(moveCostFilterSearchView && filterSearchKey!=''){
                clearFilter()
                if(movecostGrandTotalRowCount>0){
                    dispatch(fetchMoveCostSearchData(selectedPlanId,moveCostColumnsConstantData,filterSearchKey,moveCostPageNo))
                }
                } else if(!moveCostFilterSearchView){
                    if(moveCostErrorView){ 
                        clearFilter()
                        //console.log("Calling fetchMoveCostErrorData from useEffect in PlanAssumptionActionBar.js")
                        if(movecostGrandTotalRowCount>0){
                            dispatch(fetchMoveCostErrorData(selectedPlanId,moveCostTotData,moveCostPageNo,uniqueErrorIDs))
                        }
                } else {
                clearFilter()
                dispatch(fetchMoveCostData(selectedPlanId,moveCostPageNo));
                }
                }
            }else if(moveCostFilterView){
                if(movecostGrandTotalRowCount>0){
                    dispatch(fetchMoveCostFilterData(selectedPlanId,movecostFilterParams,moveCostPageNo))
                }
            }
        }
      },[moveCostErrorView,movecostFilterParams,moveCostFilterView,moveCostFilterSearchView,filterSearchKey,moveCostPageNo])

    useEffect(()=>{
        if(planAssumptionsGrid==='manualvindeletesgrid'){
            // console.log("MANUAL VIN DELETES grid update");
            let paramsLength=Object.keys(manualVinDeletesFilterParams?manualVinDeletesFilterParams:{})
            if(paramsLength.length==0 || manualVinDeletesFilterView==false){
                if(manualVinDeletesFilterSearchView && filterSearchKey!=''){
                    clearFilter()
                    dispatch(fetchManualVinDeletesSearchData(selectedPlanId,manualVinDeletesColumnsConstantData,filterSearchKey,manualVinDeletesPageNo))
                } else if(!manualVinDeletesFilterSearchView){
                    if(manualVinDeletesErrorView){ 
                        clearFilter()
                        // console.log("Calling fetchManualVinDeletesErrorData from useEffect in PlanAssumptionActionBar.js")
                        dispatch(fetchManualVinDeletesErrorData(selectedPlanId,manualVinDeletesTotalRowCount,manualVinDeletesPageNo,manualVinDeletesUniqueErrorIDs))
                    } else {
                        clearFilter()
                        dispatch(fetchManualVinDeletesData(selectedPlanId,manualVinDeletesPageNo));
                    }
                }
            }else if(manualVinDeletesFilterView){
                dispatch(fetchManualVinDeletesFilterData(selectedPlanId,manualVinDeletesFilterParams,manualVinDeletesPageNo));
            }
        }
    },[manualVinDeletesErrorView,manualVinDeletesFilterParams,manualVinDeletesFilterView,manualVinDeletesFilterSearchView,filterSearchKey,manualVinDeletesPageNo])

    // function filterRecords(array, filterparams) {
    //     if (filterparams == null) {
    //         return array;
    //     } else {
    //         const filterKeys = Object.keys(filterparams);
    //         return array.filter(item => {
    //             // validates all filter criteria
    //             return filterKeys.every(key => {
    //                 // ignores an empty filter
    //                 if (!filterparams[key].length) return true;
    //                 if (typeof filterparams[key] === 'object' && key !== "min_schedule" && key !== "max_schedule" && key!=="allocation_month") {
    //                     if (filterparams[key].length == 0) {
    //                         return true;
    //                     }
    //                     else {
    //                         return (filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) == undefined ? filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) : filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])).value);
    //                     }
    //                 }
    //                 if (key === "allocation_month") {
    //                     if (filterparams[key].length === 1) {
    //                         return true;
    //                     }
    //                     else {
    //                         let d1 = new Date(filterparams[key][1]);
    //                         let d2 = new Date(item[key].split('T')[0]);
    //                         let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
    //                         let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
    //                         let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
    //                         let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
    //                         if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
    //                             let d3 = new Date(filterparams[key][2]);
    //                             let NoTimeDate2 = d3.getFullYear() + '/' + (d3.getMonth() + 1);
    //                             let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
    //                             return itemValue >= startDate && itemValue <= endDate;
    //                         } else if (filterparams[key][0] === "less") {
    //                             return itemValue <= startDate;
    //                         } else if (filterparams[key][0] === "greater") {
    //                             return itemValue >= startDate;
    //                         }
    //                     }
    //                 }
    //                 if ((key === "min_schedule" || key === "max_schedule")) {
    //                     if (filterparams[key].length === 1) {
    //                         return true;
    //                     } else {
    //                         let startValue = parseInt(filterparams[key][1], 0);
    //                         let itemValue = parseInt(item[key], 0);
    //                         if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
    //                             let endValue = parseInt(filterparams[key][2], 0);
    //                             if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
    //                                 return item[key] >= startValue && itemValue <= endValue;
    //                             } else {
    //                                 return true;
    //                             }
    //                         } else if (filterparams[key][0] === "less") {
    //                             if (filterparams[key][1] !== "") {
    //                                 return itemValue <= startValue;;
    //                             } else {
    //                                 return true;
    //                             }
    //                         } else if (filterparams[key][0] === "greater") {
    //                             if (filterparams[key][1] !== "") {
    //                                 return itemValue >= startValue;
    //                             } else {
    //                                 return true;
    //                             }
    //                         }
    //                     }
    //                 } if (typeof filterparams[key] === 'string') {
    //                     return getValue(filterparams[key]) == getValue(item[key]);
    //                 }
    //             });
    //         });
    //     }
    // }

    // const applyTransactions = (schedulesData, transactions) => {
    //     let deepcopyschedulesData = schedulesData.map(o => { return { ...o }; })
    //     transactions.map((value1) => {
    //         deepcopyschedulesData.map((value2, index) => {
    //             if (value1.type !== 'delete' && value1.type !== 'add' && value1.type !== 'copy' && (value2.ID == value1.data.ID)) {
    //                 deepcopyschedulesData[index] = value1.data;
    //             } else if (value1.type == 'delete' && (value2.ID == value1.id)) {
    //                 deepcopyschedulesData.splice(index, 1)
    //             }
    //         });
    //         if (value1.type == 'add' || value1.type == 'copy') { 
    //             if(value1.data!==undefined){ 
    //                 deepcopyschedulesData.push(value1.data);
    //             }
    //         }
    //     });
    //     return deepcopyschedulesData;
    // }

    // useEffect(() => {
    //     if (transactions.length == 0) {
    //         dispatch(fetchAgeMileageData(selectedPlanId));
    //     } else if (transactions.length > 0) {
    //         var currentdata = applyTransactions(ageMileageData, transactions);  //TO REVIEW
    //         var filteredCurrentData = filterRecords(currentdata, filterParams);
    //         var filteredData = filterRecords(ageMileageData, filterParams);
    //         dispatch(populateGrid2("schedulesgrid", filteredData, filteredCurrentData, filterSearchKey, ageMileageGridColDef.checkValue,ageMileageGridColDef.validationFn, ageMileageGridColDef.businessValidationFn));
    //     }
    // }, [filterParams, filterSearchKey])

    useEffect(() => {
        // var filteredData = filterRecords(planAssumptionData, filterParams);
        dispatch(populateGrid2(planAssumptionsGrid, planAssumptionData, planAssumptionData, filterSearchKey, planAssumptionGridColDef.checkValue,planAssumptionGridColDef.validationFn, planAssumptionGridColDef.businessValidationFn));
        dispatch(validateGrid(planAssumptionsGrid, planAssumptionData, planAssumptionGridColDef.validationFn, planAssumptionGridColDef.businessValidationFn));
    }, [planAssumptionData])

    // useEffect(() => {
    //     var filteredData = filterRecords(additionalCostData, filterParams);
    //     dispatch(populateGrid2(planAssumptionsGrid, additionalCostData, additionalCostData, filterSearchKey, additionalCostGridColDef.checkValue,additionalCostGridColDef.validationFn, additionalCostGridColDef.businessValidationFn));
    // }, [additionalCostData])
    
    const handleDropDownValueChange = (event, displayName, colDef) => {
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (Array.isArray(filterData[key])) {
                    filterData[key] = event;
                } else {
                    if (event[0])
                        filterData[key] = event[0].value;
                    else
                        filterData[key] = event.target.value;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const onDateDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onDateRangeChanged = (e, colDef, dateField) => {
        var date = new Date(e);
        date.setDate(4)// date is going 1 day back so setting it as 4 
        if (dateField == "from"){
            setSelectedFromDate(date);
        }
        else if (dateField == "to"){
            setSelectedToDate(date);
        }
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (filterData[colDef][0] === "between") {
                    if (filterData[key].length === 3) {
                        if (dateField === "from") {
                            filterData[key][1] = date;
                        }
                        if (dateField === "to") {
                            filterData[key][2] = date;
                        }
                    } else {
                        if (filterData[key].length == 2 && dateField == "from")
                            filterData[key][1] = date;
                        else if (filterData[key].length == 2 && dateField == "to")
                            filterData[key][2] = date;
                        else
                            filterData[key].push(date);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(date);
                    else
                        filterData[key][1] = date;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const onRangeDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onValueRangeChanged = (e, colDef, rangeField) => {
        var range = e.target.value;
        if (rangeField == "from")
            setSelectedFromRangeValue(range);
        else if (rangeField == "to")
            setSelectedToRangeValue(range);
            var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key == colDef) {
                if (filterData[colDef][0] == "between") {
                    if (filterData[key].length === 3) {
                        if (rangeField === "from") {
                            filterData[key][1] = range;
                        }
                        if (rangeField === "to") {
                            filterData[key][2] = range;
                        }
                    } else {
                        // if (filterData[key].length == 1 && rangeField == "to") {
                        //     filterData[key].push("0");
                        // }
                        // filterData[key].push(range);
                        if (filterData[key].length == 1 && rangeField == "to") {
                            filterData[key][1] = 0;
                            filterData[key][2] = range;
                          }
                          else if (rangeField == "from") {
                            filterData[key][1] = range;
                          }
                          else {
                            filterData[key][2] = range;
                          }
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(range);
                    else
                        filterData[key][1] = range;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const getSelectedValue = (colDef) => {
        if (tempFilterArray !== undefined) {
            return tempFilterArray[colDef];
        }
    }

    const fetchDialogContent = () => {
        if (filterDisplayData !== undefined && filterDisplayData !== null && filterDisplayData.length > 0) {
            return (<div className="flexContainer">
                {filterDisplayData.map((fields, index) => {
                    return (<div className="filterMain" key={index}>
                        {(fields.type === "dropdown") ?
                            (<DropdownFilter displayName={fields.displayName} options={fields.options}
                                theme={theme} selectedValue={getSelectedValue(fields.colDef)}
                                onValueChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}>
                            </DropdownFilter>) :
                             (fields.type === "range") ?
                             (<RangeFilter displayName={fields.displayName} theme={theme} showIsEqual={true}
                                 selectedRangeValue={tempFilterArray[fields.colDef]}
                                 onFromValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "from")}
                                 onToValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "to")}
                                 onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.colDef)}
                             >
                             </RangeFilter>) :
                             (fields.type === "datepicker") ?
                               (<DatePickerFilter displayName={fields.displayName} theme={theme}
                                 blankOption={true} equalOption={true}
                                 selectedDate={tempFilterArray[fields.colDef]}
                                 onFromDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "from")}
                                 onToDateChanged={(e) => onDateRangeChanged(e, fields.colDef, "to")}
                                 onValueChanged={(e) => onDateDropDownValueChanged(e, fields.colDef)}
                                 >
                               </DatePickerFilter>) :
                                        <div></div>}</div>);
                }
                )}</div>);
        }
    }
const [uploadedFileName,setUploadedFileName]=useState('')
    const validateCsv=async(files)=>{
        let csvHeader,copyText,cpcsvHeader=[];
        copyText=files 
        csvHeader=files.split('\n').join(' ')
        csvHeader=csvHeader.split(' ')
        csvHeader= csvHeader[0].replace('\n','')
        csvHeader=csvHeader.split(',')
        csvHeader.map((val)=>{
            let p=val.replace('\r','')
            cpcsvHeader.push(p)
        })
        let originalGridCsvHeader= getCsvColumnNames()
        if(JSON.stringify(cpcsvHeader)==JSON.stringify(originalGridCsvHeader.csvFields)){
               let responseUrl=await getUploadPreSignedUrl()
              if(responseUrl.moveCostUploadUrl.uploadURL){
            upload(responseUrl.moveCostUploadUrl.uploadURL,copyText,responseUrl.moveCostUploadUrl.filename)    
            setUploadedFileName(responseUrl.moveCostUploadUrl.filename)     
        }
        } else {
            dispatch(SetAssumptionMoveCostUploadStatus('error'))   
        }   
    }

    
    const upload=(url,files,filename)=>{
        dispatch(setinsertStatusCheckFlag(null))
    setShowProgress(true)
    dispatch(SetAssumptionMoveCostUploadStatus(true))
        let client = new XMLHttpRequest()
        client.open("PUT", url,true)
        client.setRequestHeader("Content-Type", "text/csv");
        client.upload.onprogress = function(pe) {
          let percentComplete = Math.ceil((pe.loaded / pe.total) * 100);
          setProgress(percentComplete)
        }
        client.send(files)
    }



  useEffect(()=>{
if(uploadedFileInsertStatus.length>0){
    uploadedFileInsertStatus.map((val)=>{
       if(val.planid==selectedPlanId && val.status==='completed'){
        setinsertProgress(false)
       } else if(val.planid==selectedPlanId && val.status!=='completed'){
        setinsertProgress(true)
       }if(val.planid==selectedPlanId && val.status==='error'){
        setinsertProgress(false)
       }
    })
}else if(insertStatusCheckFlag==='done' && insertProgress==true){
    setinsertProgress(false) 
    dispatch(fetchMoveCostData(selectedPlanId,1))
}
  },[uploadedFileInsertStatus,insertProgress,insertStatusCheckFlag])

useEffect(()=>{
    if(insertStatusCheckFlag==true){
    setinsertProgress(null) 
    }
},[insertProgress,insertStatusCheckFlag])

useEffect(()=>{
    setUploadDone(null)
},[])


useEffect(() =>{
  if (moveCostDisableButton == 'insert failed'){
     setinsertProgress(null)
     dispatch(SetAssumptionMoveCostUploadStatus(null))
}
}, [moveCostDisableButton]);

const [uploadDone,setUploadDone]=useState(null)

    useEffect(()=>{
        if(progress==100){
            dispatch(SetAssumptionMoveCostUploadStatus(false))
            const timer = setInterval(() => {
                setShowProgress(false)
                setUploadDone(true)
              }, 2000);
              return () => {
                setProgress(0)
                dispatch(SetAssumptionMoveCostUploadStatus(null))
                setinsertProgress(true)
                clearInterval(timer);
              };
        }
        },[progress,showProgress])

        useEffect(()=>{
            if(uploadDone==true){
                let p=uploadedFileName.split('/')
                dispatch(InsertuploadMoveCostData(selectedPlanId,p[1],'movecostgrid'))
                //dispatch(SetUploadFileInsert(selectedPlanId,p[1].split('.')[0]))
                setUploadDone(null)
            }
        },[uploadDone])
    //THis function is used to get the payload body to be sent as a parameter 
    //for download and delete api of records(For now used Only in Movecost grid)
    //Note:-TO be used in all grids for download and delete api
    const getPayloadParameters =(isDownloadCalled)=>{
        let operationApplied='';
        var data={};
        if(moveCostFilterView){
        operationApplied='filter';
        data={movecostFilterParams,moveCostPageNo,operationApplied}
        //clearFilter();
        }
        else if(manualVinDeletesFilterView){
        operationApplied='filter';
        data={manualVinDeletesFilterParams,manualVinDeletesPageNo,operationApplied}
        //clearFilter();
        }
        else if(moveCostFilterSearchView && filterSearchKey!='' && filterSearchKey!='$'){
        operationApplied='search';
        data={moveCostColumnsConstantData,filterSearchKey,moveCostPageNo,operationApplied}
        // setiFlterSearchKey('')
        }
        else if(manualVinDeletesFilterSearchView && filterSearchKey!='' && filterSearchKey!='$'){
        operationApplied='search';
        data={moveCostColumnsConstantData,filterSearchKey,manualVinDeletesPageNo,operationApplied}
        // setiFlterSearchKey('')
        }
        else if(moveCostErrorView){
        operationApplied='errordata';
        data={operationApplied,uniqueErrorIDs};
        }else if(manualVinDeletesErrorView){
        operationApplied='errordata';
        data={operationApplied,manualVinDeletesUniqueErrorIDs};
        }else{
        operationApplied=isDownloadCalled!=undefined?'downloadall':'deleteall';
        data={moveCostPageNo,operationApplied}
        }
        if(isDownloadCalled==undefined && planAssumptionsGrid==='movecostgrid'){
        dispatch(deleteMoveCostRecords(selectedPlanId,data));
        closeDeleteRecordsDialog();
        }else if(isDownloadCalled==undefined && planAssumptionsGrid==='manualvindeletesgrid'){
        dispatch(deleteManualVinDeletesRecords(selectedPlanId,data));
        closeDeleteRecordsDialog();
        }else{
        return data;
        }
    }
    const changeHandler = async (event) => {
        if (event.target.value !== '') {
            const fileToUpload = event.target.files[0];
            event.target.value = '';
            if(planAssumptionsGrid==='agemileagegrid'){
            dispatch(uploadAgeMileageData(selectedPlanId, fileToUpload));
            }
         else if(planAssumptionsGrid==='additionalcostgrid'){
                dispatch(uploadAdditionalCostData(selectedPlanId, fileToUpload));
                }
         else if(planAssumptionsGrid==='maxdeletegrid'){
                    dispatch(uploadMaxDeletesData(selectedPlanId, fileToUpload));
                    }
        else if(planAssumptionsGrid==='diversionsgrid'){
            dispatch(uploadDiversionsData(selectedPlanId, fileToUpload));
            }           
         else if(planAssumptionsGrid==='mileagedefaultgrid'){
                        dispatch(uploadMileageDefaultData(selectedPlanId, fileToUpload));
                        }
        else if(planAssumptionsGrid==='addsinvalidpoolsgrid'){
                            dispatch(uploadAddsInvalidPoolsData(selectedPlanId, fileToUpload));
                        }
        else if(planAssumptionsGrid==='salvagerategrid'){
                            dispatch(uploadSalvageRateData(selectedPlanId, fileToUpload));
        }
        else if(planAssumptionsGrid==='targetzonepoolgrid'){
            dispatch(uploadTargetZonePoolData(selectedPlanId, fileToUpload));
          }
          else if(planAssumptionsGrid==='zonemapgrid'){
            dispatch(uploadZoneMapData(selectedPlanId, fileToUpload));
          }
        else if(planAssumptionsGrid==='deprategrid'){
            dispatch(uploadDepRateData(selectedPlanId, fileToUpload));
        }
        else if(planAssumptionsGrid==='mileagetablegrid'){
            dispatch(uploadMileageTableData(selectedPlanId, fileToUpload));
        }
        else if(planAssumptionsGrid==='movecostgrid'){
                const reader = new FileReader();  
                let text,validateResult;
                reader.onload = (event) => {
                 text =event.target.result;
                 validateResult= validateCsv(text)
                };
                reader.readAsText(fileToUpload);
         }
         else if(planAssumptionsGrid==='manualvindeletesgrid'){
            //dispatch(uploadManualVinDeletesData(selectedPlanId, fileToUpload));
           // setShowProgress(true)
            SetFileToUpload(fileToUpload)
            setShowErrorRecords(false)
        }
        }
    };


    let csvFields=[];
    let csvStr;
    let d;
     const getCsvColumnNames=()=>{
         let fileName=planAssumptionsGrid.replace('grid','');
         planAssumptionGridColDef.columnDefs.map(i=>{
            if(i.field && i.field.toLowerCase()!=="updated_by" && i.field.toLowerCase()!=="updated_on"){
            csvFields.push(i.field)
            }
         })
         csvFields = csvFields.filter(function (val) {
            return val != null && val !='';
            });
     return {
             csvFields:csvFields,
             fileName:fileName
             };
     }

    
     function printValue(data){
        return safeExcelValue(data)
      }

     function getCsvData(data){
        let temp=[];
        for(var i=0;i<csvFields.length;i++){
          d=Object.getOwnPropertyDescriptor(data,csvFields[i]).value;
          if(csvFields[i]=='MONTH'){
            d=safeExcelDate(d,'DD-MMM-YY') 
          }
          temp.push(d)
        }
        temp = temp.join(',');
        csvStr+=printValue(temp)+'\n'
        return csvStr
    }

    const downloadHandler = async (event) => {
        let gridData=getCsvColumnNames()
        let movecostdownloaddata;
        if(planAssumptionsGrid==='movecostgrid'){
            // console.log("movecostGrandTotalRowCount==",movecostGrandTotalRowCount);
            // console.log("moveCostTotData==",moveCostTotData);
            
            if(moveCostTotData==0){
                //When there is no data at all, Download should not work
                return;
            }
            dispatch(SetAssumptionMoveCostUploadStatus('download'))
            let currentdate = new Date();
            let payloadParams=getPayloadParameters(true)
            let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
            let filename= `${gridData.fileName}-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`
            let responseUrl=await getDownloadPreSignedUrl(selectedPlanId,filename,payloadParams)
            if(responseUrl.moveCostDownloadUrl.downloadurl){
                dispatch(SetAssumptionMoveCostUploadStatus(null))
                let hiddenElement = document.createElement('a');
                hiddenElement.href = responseUrl.moveCostDownloadUrl.downloadurl;
                hiddenElement.target = '_self';
                hiddenElement.download = filename;
                hiddenElement.click();
                 hiddenElement.remove()
            }
        } else if(planAssumptionsGrid==='manualvindeletesgrid'){
            // const vinDeletesData=await getManualVinDeletesData(selectedPlanId,0)
            // data1 = JsonToCSV(vinDeletesData.gridData,gridData.csvFields);
            let currentdate = new Date();
            let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
            let downloadFilename=`${gridData.fileName}-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`
            setFileNameToDownload(downloadFilename)
        } else {
             let data1 = JsonToCSV(planAssumptionData,gridData.csvFields);
         //let data1 = JsonToCSV(planAssumptionData,gridData.csvFields);
         let currentdate = new Date();
         let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
         downloadCSV(data1, `${gridData.fileName}-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`);
         csvFields=[]
         csvStr=null
        }
        };

    function JsonToCSV(JsonArray, JsonFields) {
            csvStr = JsonFields.join(",") + "\n";
            JsonArray = JsonArray.filter(function (key,val) {
                return   key !== "ID" && key!=="status" && key!=="validations" && key!="version"
                });
            JsonArray.map(o=>{
            getCsvData(o)
            })
    //     if(gridname==='agemileagegrid'){
    //      JsonArray.forEach(element => {
    //         let CAR_GROUP = safeExcelValue(element.CAR_GROUP);
    //         let ZONE_NAME = safeExcelValue(element.ZONE_NAME);
    //         let PROGRAM_TYPE = safeExcelValue(element.PROGRAM_TYPE);
    //         let MIN_AGE = safeExcelValue(element.MIN_AGE);
    //         let MAX_AGE = safeExcelValue(element.MAX_AGE);
    //         let MIN_MILEAGE = safeExcelValue(element.MIN_MILEAGE);
    //         let MAX_MILEAGE = safeExcelValue(element.MAX_MILEAGE);
    //         csvStr += CAR_GROUP + ',' + ZONE_NAME + ',' + PROGRAM_TYPE + ',' + MIN_AGE + ',' + MAX_AGE + ',' + MIN_MILEAGE +',' + MAX_MILEAGE + "\n";
    //     })
    // }else if(gridname==='additionalcostgrid'){
    //     JsonArray.forEach(element => {
    //         let ZONE_NAME = safeExcelValue(element.ZONE_NAME);
    //         let MANUFACTURER_GROUP = safeExcelValue(element.MANUFACTURER_GROUP);
    //         let MAINTENANCE_COST = safeExcelValue(element.MAINTENANCE_COST);
    //         let DAMAGE_COST = safeExcelValue(element.DAMAGE_COST);
    //         let NET_IN_SERVICE_COST = safeExcelValue(element.NET_IN_SERVICE_COST);
    //         let REPURCHASE_RECONDITIONING_COST = safeExcelValue(element.REPURCHASE_RECONDITIONING_COST);
    //         let RISK_RECONDITIONING_COST = safeExcelValue(element.RISK_RECONDITIONING_COST);
    //         csvStr += ZONE_NAME + ',' + MANUFACTURER_GROUP + ',' + MAINTENANCE_COST + ',' + DAMAGE_COST + ',' + NET_IN_SERVICE_COST + ',' + REPURCHASE_RECONDITIONING_COST +',' + RISK_RECONDITIONING_COST + "\n";
    //        })
    //  }
     return csvStr;
    }

    const onSearchValueChanged =(e)=>{
        if(e.target.value==='' && moveCostFilterSearchView && planAssumptionsGrid==='movecostgrid'){
            setiFlterSearchKey('')
            dispatch(SetAssumptionMoveCostPageValue(1))
            dispatch(setMoveCostFilterSearchText(''))
            dispatch(setMoveCostFilterSearchView(false))
           
        }else if(e.target.value==='' && manualVinDeletesFilterSearchView && planAssumptionsGrid==='manualvindeletesgrid'){
            setiFlterSearchKey('')
            dispatch(SetAssumptionManualVinDeletesPageValue(1))
            dispatch(setManualVinDeletesFilterSearchText(''))
            dispatch(setManualVinDeletesFilterSearchView(false))
           
        }
    }


    const onKeyEnterSearch =(e)=>{
         if(e.target.value!='' && e.target.value!='$' && planAssumptionsGrid==='movecostgrid'){
            clearFilter();
            dispatch(SetAssumptionMoveCostPageValue(1))
            setiFlterSearchKey(e.target.value);
            dispatch(setMoveCostFilterSearchText(e.target.value))
            dispatch(setMoveCostFilterSearchView(true))
            dispatch(SetAssumptionMoveCostErrorViewStatus(false))
            setShowErrorRecords(false);
        }else if(e.target.value!='' && e.target.value!='$' && planAssumptionsGrid==='manualvindeletesgrid'){
            clearFilter();
            dispatch(SetAssumptionManualVinDeletesPageValue(1))
            setiFlterSearchKey(e.target.value);
            dispatch(setManualVinDeletesFilterSearchText(e.target.value))
            dispatch(setManualVinDeletesFilterSearchView(true))
        }
    }

    const handleFilterClickDoNothing=()=>{}
    
function LinearProgressWithLabel(props) {
    return (
        <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="textPrimary">{progress}%</Typography>
        </Box>
      </Box>    
      </>
    );
  }

const showDeleteRecordsDialog = ()=>{
    if(moveCostFilterView){
        setDeleteRowmessage('Are you sure that you want to delete filtered records?');
    }else if((moveCostFilterSearchView && filterSearchKey!='' && filterSearchKey!='$')){
        setDeleteRowmessage('Are you sure that you want to delete search records?');
    }else if(planAssumptionsGrid==='movecostgrid'){
        setDeleteRowmessage('Are you sure that you want to delete all the move cost records?');
    }else if(planAssumptionsGrid==='manualvindeletesgrid'){
        if(manualVinDeletesErrorView){
            setDeleteRowmessage('Are you sure that you want to delete all the error records for manual vin deletes?');
        }else if(manualVinDeletesFilterView){
            setDeleteRowmessage('Are you sure that you want to delete all the filtered records for manual vin deletes?');
        }else if(manualVinDeletesFilterSearchView){
            setDeleteRowmessage('Are you sure that you want to delete all the searched records for manual vin deletes?');
        }else{
            setDeleteRowmessage('Are you sure that you want to delete all the manual vin deletes records?');
        }
    }
    setOpenDeleteRecordsDialog(true);
}


const closeDeleteRecordsDialog = ()=>{
    setOpenDeleteRecordsDialog(false);
}

const handleDialogClose=()=>{
    setShowDialog(false);
  }

/*S3UploadDownloadComponent required props 
    selectedId = Either it can be planid or rfid 
    showProgress = its used for saying wheter to display progress bar or not 
    setShowProgress = its used to set true or false for showing progress bar or not
    fileNameToDownload = The generated filename for which the file which is getting uploaded  
    setFileNameToDownload = Its used to set the filename for the file which is getting uploaded  
    FileToUpload = The File details which needs to be uploaded to s3
    SetFileToUpload = Setting the File which needs to be uploaded to s3 
    getCsvColumnNames = This function is used to get all grid columns 
    TO DO :- getCsvColumnNames need to move somewhere genric place in future
    gridName = The current gridname on which users is working
    userMessageFunction = This function is used to display notification messages 
*/
    return (
        <div className="planassumptionmainHeader">        
         
            <div className="planassumptionfilterStyle">
            <S3UploadDownloadComponent 
                selectedId={selectedPlanId}
                showProgress={showProgress} 
                setShowProgress={setShowProgress}
                fileNameToDownload={fileNameToDownload}
                setFileNameToDownload={setFileNameToDownload} 
                FileToUpload={FileToUpload} 
                SetFileToUpload={SetFileToUpload}
                getPayloadParameters={getPayloadParameters} //In future we need to make this function genric
                gridName={planAssumptionsGrid} 
                gridColumnDefs={planAssumptionGridColDef.columnDefs}
                userMessageFunction={userMessage}
                isNewArchitecture={false}
            />

                {planAssumptionsGrid==='movecostgrid' || planAssumptionsGrid==='manualvindeletesgrid'?
                  <>
                <img src={((moveCostFilterView && planAssumptionsGrid==='movecostgrid') || (manualVinDeletesFilterView && planAssumptionsGrid==='manualvindeletesgrid'))?IconFilterActive:IconFilter} className="imageIcon" onClick={((moveCostTransactions.length>0 && planAssumptionsGrid==='movecostgrid') || (manualVinDeletesTransactions.length>0 &&planAssumptionsGrid==='manualvindeletesgrid'))?handleFilterClickDoNothing:handleFilterClick} ></img>
                <div className="planassumptionsearchbar">
                {(!showProgress && planAssumptionsGrid==='movecostgrid') || (!showProgress && planAssumptionsGrid==='manualvindeletesgrid')?
                <SearchBar onSearchValueChanged={onSearchValueChanged} onEnterSearch={true} onKeyEnterSearch={onKeyEnterSearch} filterSearchText={filterSearchKey} disabledTextBox={((moveCostTransactions.length>0 && planAssumptionsGrid==='movecostgrid') ||(manualVinDeletesTransactions.length>0 && planAssumptionsGrid==='manualvindeletesgrid') )?true:false} showActiveIconOnEnter={planAssumptionsGrid==='movecostgrid'?moveCostFilterSearchView:planAssumptionsGrid==='manualvindeletesgrid'?manualVinDeletesFilterSearchView:''}
                key={filterSearchKey} //to rerender the search component we need key prop,it will unmount and mount the component once the search value change.Based on grid selction search key also updates on UI.
                ></SearchBar>
                :''}
                </div>
                {(showProgress && planAssumptionsGrid!=='manualvindeletesgrid')?
                <Box sx={{ width: '50%' }}>
                     <LinearProgressWithLabel value={progress} />
                  </Box>
              :  planAssumptionsGrid ==='movecostgrid'?<div className="primaryButton">
              <Button onClick={(e) => getAllErrorRecords(e)} disabled={((moveCostTransactions.length>0||movecostGrandTotalRowCount.length==0) && moveCostErrorView===false)?true:false}>{showErrorRecords?'Show All Records':'Show Error Records'}
               </Button>
               </div> 
              :  planAssumptionsGrid ==='manualvindeletesgrid'?<div className="primaryButton">
              <Button onClick={(e) => getAllErrorRecords(e)} disabled={(manualVinDeletesTotalRowCount===0 && manualVinDeletesErrorView===false)?true:false}>{showErrorRecords?'Show All Records':'Show Error Records'}
               </Button>
               </div> :'' }     
          {
          planAssumptionsGrid ==='movecostgrid' && insertProgress==true?
            <Box sx={{ display: 'flex' }}>
               <CircularProgress size={30}/>
               <Typography variant="body2" color="error">Insert in progress</Typography>
            </Box>:'' 
          }
            </>
                :
                ''}
        
            </div>   
            {/* Plan status is running should disable cancel save changes add +   */}
            {options && !ifPlanEditDisabled?
             <div className="planassumptionactionButtons">            
                    <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
                    </Button></div>
                    <div className="primaryButton">
                        <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
                         </Button></div>
                    <div className="primaryButton">
                        <Button disabled={ifPlanEditDisabled} onClick={(e) => onAddNewRowClick(e)}>Add +
                         </Button>
                    </div>
                    <div className="primaryButton">
                        <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ifPlanEditDisabled}>
                            Delete
                        </Button>
                    </div>
                    {planAssumptionsGrid==="movecostgrid" || planAssumptionsGrid==="manualvindeletesgrid"?
                    <div className='deleterecords'>
                        <img src={deleteIcon} onClick={showDeleteRecordsDialog}></img>
                    </div>:<div></div>}
                </div> :
                (<div className="planassumptionactionButtons">
                    {planAssumptionsGrid ==="movecostgrid" && moveCostErrorView?'':
                        <label>
                            <input type="file" accept=".csv" name="file" disabled={ifPlanEditDisabled || insertProgress || showProgress?true:false} onChange={changeHandler} style={{ "display": "none" }}/>
                            <img src={IconActionUpload} className="imageIcon"></img>
                        </label>
                    }
                    <div className='imageIcon'>
                        <img src={IconActionDownload} onClick={() => downloadHandler()} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="primaryButton">
                            <Button disabled={ifPlanEditDisabled||(planAssumptionsGrid ==="movecostgrid" && moveCostErrorView)} onClick={(e) => onAddNewRowClick(e)}>
                                Add +
                                </Button></div>
                        <div className="primaryButton">
                        <Tooltip title="Delete selected data only" placement="bottom">
                            <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled ||ifPlanEditDisabled}>
                                Delete
                                 </Button>
                        </Tooltip>
                      </div>
                      {planAssumptionsGrid ==="movecostgrid" || planAssumptionsGrid ==="manualvindeletesgrid"?
                      <div className='primaryButton'>
                        <Tooltip title="Delete data of all visible pages" placement="bottom">
                        <Button onClick={() => showDeleteRecordsDialog()} disabled={ifPlanEditDisabled || (planAssumptionsGrid ==="movecostgrid" && ( (!moveCostErrorView  && moveCostTotData===0) || (moveCostErrorView  && uniqueErrorIDs && uniqueErrorIDs.length===0))) || (planAssumptionsGrid ==="manualvindeletesgrid" && ( (!manualVinDeletesErrorView  && manualVinDeletesTotalRowCount===0) || (manualVinDeletesErrorView  && manualVinDeletesUniqueErrorIDs.length===0)))}>
                                Delete All
                        </Button>
                        </Tooltip>
                        {/* <img src={deleteIcon} onClick={showDeleteRecordsDialog}></img> */}
                        </div>:<div></div>}
                    </div>
                    {/* : <div></div>} */}
                </div>)}
            <div>
                <DialogBox classes={{ paper: classes.dialogPaper }}
                    open={open} handleClose={(e) => handleClose(e)} handlePositiveBtnClick={(e) => applyFilter(e)} handleNegativeBtnClick={(e) => clearFilter(e)}
                    dialogContent={fetchDialogContent()} title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter">
                </DialogBox>
                <DeleteDialogBox open={openDeleteDialog} handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
                    dialogMessage={message} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DeleteDialogBox>
                <DeleteDialogBox open={openDeleteRecordsDialog} handleClose={closeDeleteRecordsDialog} handleYes={getPayloadParameters}
                    dialogMessage={deleteRowmessage} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DeleteDialogBox>
                <SingleButtonDialogComponent open={showDialog} handleOk={handleDialogClose}
                    dialogMessage={"Master Data Has Been Updated!!"} title={"Alert!!"} action="Ok">
                </SingleButtonDialogComponent>
            </div>
        </div>
    )
}

export default PlanAssumptionsActionbar;