import { getCoreRevenueDropdownService } from "../../../../services/revenuefunction/revenue/corerevenue/CoreRevenueServices";
import { FETCH_COREREVENUE_DROPDOWNVALUES_ERROR, FETCH_COREREVENUE_DROPDOWNVALUES_PENDING, FETCH_COREREVENUE_DROPDOWNVALUES_SUCCESS } from "../../RevenueFunction.ActionTypes";



export const fetchCoreRevenueDropDownValues = ()=>{
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_COREREVENUE_DROPDOWNVALUES_PENDING));
        let coreRevenueDropdownData = await getCoreRevenueDropdownService();
        if (coreRevenueDropdownData) {
            dispatch(serviceActionSuccess(FETCH_COREREVENUE_DROPDOWNVALUES_SUCCESS, coreRevenueDropdownData));
        } else {
            dispatch(serviceActionError(FETCH_COREREVENUE_DROPDOWNVALUES_ERROR, coreRevenueDropdownData));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}
function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}