import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';
import { minifyTransaction } from '../../../utils/utils';


export async function getRFCoreTabsErrorStatus(rfid){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
        const promiseGetCoreDemandTabErrorStatus =  client.apis.revenuefunction.getRFCoreDemandTabErrorStatus({'rfid':rfid});
        const promiseGetCoreAdjustmentsTabErrorStatus =  client.apis.revenuefunction.getRFCoreAdjTabErrorStatus({'rfid':rfid});
        const promiseGetCoreParametersTabErrorStatus =  client.apis.revenuefunction.getRFParametersTabErrorStatus({'rfid':rfid});

        const responsesArray = await Promise.all([
            promiseGetCoreDemandTabErrorStatus,
            promiseGetCoreAdjustmentsTabErrorStatus,
            promiseGetCoreParametersTabErrorStatus
        ]);

        const [
            coreDemandStatusResponse,
            coreAdjustmentsStatusResponse,
            coreParametersStatusResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "coredemandgrid": coreDemandStatusResponse,
            "coreadjustmentsgrid": coreAdjustmentsStatusResponse,
            "coreparametergrid": coreParametersStatusResponse
        }) 
    }
    catch (error) {
       console.error(error);
       return {'Error':'getRfCoreTabsErrorStatus failed'}
   }
   }


   export async function getRFCoreAllTabsValidationStatus(rfid,transactionList){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
        const promiseGetCoreDemandTabValidationStatus =  client.apis.revenuefunction.validateRFCoreDemand({"rfid":rfid},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetCoreAdjustmentsTabValidationStatus =  client.apis.revenuefunction.validateRFCoreAdj({"rfid":rfid},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});

        const responsesArray = await Promise.all([
            promiseGetCoreDemandTabValidationStatus,
            promiseGetCoreAdjustmentsTabValidationStatus
        ]);

        const [
            coreDemandValidationResponse,
            coreAdjustmentsValidationResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "coredemandgrid": coreDemandValidationResponse.rows[0].validation_result,
            "coreadjustmentsgrid": coreAdjustmentsValidationResponse.rows[0].validation_result
        }) 
    }
    catch (error) {
       console.error(error);
       return {'Error':'getRfCoreTabsErrorStatus failed'}
   }
   }
   