import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdjustmentsActionBar from '../../../components/adjustments/actionbar/AdjustmentsActionBar';
import CoreBUAccordionContainer from '../../accordionview/CoreBUAccordionContainer';

const CoreTabPanel = (props) => {
    const { accordionData, tabIndex, planId, onViewChanged, handleGridSaveBehavior, showCancelButton, handleCancel, allZones, planStatus, filterData, filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue} = props;
    let selectedVal = useSelector((state) => state.rootAdjustmentsReducer.adjustmentsReducer.selectedViewType[tabIndex]);
    return (
        <div>
            <AdjustmentsActionBar onViewChanged={onViewChanged} showCancelButton={showCancelButton} handleCancel={handleCancel} selectedVal={selectedVal} filterData={filterData}
            tabIndex={tabIndex} planStatus = {planStatus}/>
            <CoreBUAccordionContainer accordionData={accordionData} tabIndex={tabIndex} planStatus={planStatus}
                planId={planId} selectedVal={selectedVal} handleGridSaveBehavior={handleGridSaveBehavior} allZones={allZones}
                filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} filterData={filterData} />
        </div>
    )

}

export default CoreTabPanel;