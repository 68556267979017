import { combineReducers } from 'redux';
import TargetZonePoolReducer from './TargetZonePool.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import targetZonePoolColumnDefinitionReducer from "./TargetZonePool.ColumnDef.Reducer";

const rootTargetZonePoolReducer = combineReducers({
    TargetZonePoolReducer
    , targetZonePoolGridReducer: createNamedWrapperReducer(multiGridUIReducer, "targetzonepoolgrid")
    , targetZonePoolColumnDefinitionReducer
})

export default rootTargetZonePoolReducer;