import React, { useRef } from 'react';
import './CopyInputsToMainPage.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import icon_close from "../../assets/images/close.svg";
import { planInputSubTablesData, planInputTabData, planAssumptionTabsForPlanInputs } from '../../Constants';
import { activateCopyToMainInputs, addNotificationMessage, markTabsToBeCopiedToMain } from '../../store/topbar/AllPlans.Actions';


const CopyInputsToMainPage = (props) => {
    const { open, setCopyToMainDialog } = props;
    const { planid } = useParams();
    const dispatch = useDispatch();
    const tabsToBeCopiedToMain = useRef([]);
    const initiateCopyButtonRef = useRef();//this ref is created to disable the initiate copy button before checking the checkbox.

    const gridTabErrorStatus = useSelector((state) => {
        return state.PaginationGridReducer.gridTabErrorStatus;
    }, shallowEqual);

    const initiateCopyToMain = (event) => {
        dispatch(markTabsToBeCopiedToMain(tabsToBeCopiedToMain.current));
        dispatch(activateCopyToMainInputs(planid, tabsToBeCopiedToMain.current));
        closeDialogBox();
    }

    function registerTableSelect(e, value) {
        try{
            if (e.target.checked == false) {//this if executes when user uncheck the checkbox
                tabsToBeCopiedToMain.current.splice(tabsToBeCopiedToMain.current.indexOf(value), 1)
    
                if (tabsToBeCopiedToMain.current.length == 0 && !initiateCopyButtonRef.current.disabled) {
                    initiateCopyButtonRef.current.disabled = true;
                }
            }
            else {//this elseif executes when user check the checkbox.
                initiateCopyButtonRef.current.disabled = false;
                initiateCopyButtonRef.current.onclick = initiateCopyToMain;
                tabsToBeCopiedToMain.current.push(value);
            }
        }catch(e){
            dispatch(addNotificationMessage('error','Cannot select tab to copy due to some internal issue!'))
        }
        
    }

    const closeDialogBox = () => {
        tabsToBeCopiedToMain.current = [];
        setCopyToMainDialog(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                className="copyPlanDialogBox">

                <DialogTitle id="draggable-dialog-title" className="copyPlandialogtitle">
                    <span>Initiate Copy</span><img src={icon_close} onClick={closeDialogBox} className="copyPlanCloseIcon" alt="iconclose"></img>
                </DialogTitle>

                <DialogContent className="copyPlanDialogContent">

                    <div className="copyPlanMainTabsFlexContainer">
                        {planInputTabData.map((tabs, index) => (
                            (tabs.tabKey != 6) ?
                                <div key={"tabs" + index} className={(gridTabErrorStatus[tabs.gridName] == false) ? "copyPlanDialogContent_normal" : "copyPlanDialogContent_disabled"}>
                                    <input type="checkbox" id={'listCheckBox_' + index} disabled={gridTabErrorStatus[tabs.gridName]} onClick={(e) => registerTableSelect(e, planInputSubTablesData[tabs.tabKey][0])} />
                                    <span>{tabs.tabTitle}</span>
                                </div>
                                : '')
                        )}
                    </div>
                    <div className="copyPlanAssumptionTabsFlexContainer" >
                        <span className='copyPlanAssumptionTabTitle'>Plan Assumption Tabs</span>
                        {planAssumptionTabsForPlanInputs.map((tabs, index) => (
                            (tabs.tabKey != 5) ?
                                <div key={"subtableName_" + index} className={(gridTabErrorStatus[tabs.gridName] == false) ? "copyPlanDialogContent_normal" : "copyPlanDialogContent_disabled"}>
                                    <input type="checkbox" id={'subtableName_' + index} disabled={gridTabErrorStatus[tabs.gridName]} onClick={(e) => registerTableSelect(e, tabs.tabTitle)} />
                                    <span>{tabs.tabDisplayTitle}</span>
                                </div>
                                : '')
                        )}
                    </div>
                </DialogContent>

                <DialogActions className="copytoMainactionbuttons">
                    <Button onClick={closeDialogBox} color="primary">
                        Cancel Copy
                    </Button>
                    <button
                        ref={initiateCopyButtonRef}
                        disabled={true}
                        color="primary" >
                        Initiate Copy
                    </button>

                </DialogActions>
            </Dialog>
        </div>
    )
}
export default CopyInputsToMainPage;

