import {getCoreAdjData,getCoreAdjDropdownService,saveTransactions,uploadCoreAdjCSV} from '../../../../services/revenuefunction/mainrfinputs/coreAdjustments/CoreAdjustmentService';
import { SetRFError, SetRFPending } from '../../RevenueFunction.Actions';
import {FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_ERROR, FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_PENDING, FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_SUCCESS } from './CoreAdj.ActionTypes';

export const fetchCoreAdjDropDownValues = ()=>{
    // console.log("Inside fetchCoreAdjDropDownValues....");
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_PENDING));
            // console.log("Getting coreAdjDropdownData....");
            let coreAdjDropdownData = await getCoreAdjDropdownService();
            // console.log("coreAdjDropdownData===>",coreAdjDropdownData);
            if (coreAdjDropdownData) {
                dispatch(serviceActionSuccess(FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_SUCCESS, coreAdjDropdownData));
            } else {
                dispatch(serviceActionError(FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_ERROR, coreAdjDropdownData));
            }
        } catch (e) {
            console.log("Failed to get Core Adj drop down values due to : ", e.message);
            // console.log("ERROR Stack Trace : ", e.stack);
            dispatch(serviceActionError(FETCH_MAINRFINPUTS_COREADJ_DROPDOWNVALUES_ERROR,e.message));
            dispatch(SetRFError("Unknown error"));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}