import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAddsInvalidPoolsData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseaddsInvalidPoolsResponse = await client.apis.plans.getAllAddsInvalidPools({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });
        const responsesArray = await Promise.all([promiseaddsInvalidPoolsResponse]);
        const [addsInvalidPoolsResponse] = responsesArray.map(o => o.body.data);
    
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": addsInvalidPoolsResponse.rows, "rowcount": addsInvalidPoolsResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
              return ({
            "addsInvalidPoolsData": addsInvalidPoolsResponse
        });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Invalid Pools by OEM tab'
    }

}

export  async function uploadAddsInvalidPoolsCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadAddsInvalidPools({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Invalid Pools by OEM tab, Please try again!!');
    }
}

export async function saveAddsInvalidPoolsTransactions(planid, transactionList){

    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactaddsinvalidpools({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchAddsInvalidPoolsDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const addsInvalidPoolsResponseData =  client.apis.plans.getAllAddsInvalidPools({ "planid": planId ? planId : 'master' });
    const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseGetAdminManufacturerGroup =  checkAndLoadDimention("adminManufacturer", client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
         // addsInvalidPoolsResponseData,
          promiseGetManufacturerGroup, 
          promisePoolResponse,
          promiseGetAdminManufacturerGroup,
          promiseAdminPoolResponse
    ]);

    const [
       //   addsInvalidPoolsResponse,
          manufacturerGroupResponse,
          poolResponse,adminManufacturerGroups, adminPoolResponse     
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "addsInvalidPoolsData":addsInvalidPoolsResponse,
        "manufacturer_group": manufacturerGroupResponse,
        "pool": poolResponse,
        "adminManufacturerGroup":adminManufacturerGroups,
        "adminpool":adminPoolResponse,
        "users":promiseUserManagementEmailAndIdMap

    })  
}

export async function getAddsInvalidPoolsFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAddsInvalidPoolsFilterResponse = await client.apis.plans.getAddsInvalidPoolsFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseAddsInvalidPoolsFilterResponse]);
        const [addsinvalidpoolsResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": addsinvalidpoolsResponse.rows, "rowcount": addsinvalidpoolsResponse.rows[0] ? addsinvalidpoolsResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'Invalid Pools by OEM tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllAddsInvalidPoolsData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promiseaddsinvalidpoolsDeleteResponse = await client.apis.plans.deleteAllAddsInvalidPoolsRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });

        if (promiseaddsinvalidpoolsDeleteResponse.obj && promiseaddsinvalidpoolsDeleteResponse.obj.statusCode !== '500') {
            return promiseaddsinvalidpoolsDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Invalid Pools by OEM tab has internal server error, Unable to delete all the records!! Please try again'
    }
}

export async function insertS3UploadAddsInvalidPoolsCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.insertS3UploadedAddsInvalidPoolsData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi, ''),
                "fileName": filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Invalid Pools by OEM tab, Please try again!!');
    }
}

export async function addsinvalidpoolsTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateAddsInvalidPools({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });

        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Invalid Pools by OEM Validation status ,Please try again!!';
    }
}

export async function getAddsInvalidPoolsTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAddsInvalidPoolsResponse = await client.apis.plans.getAddsInvalidPoolsTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseAddsInvalidPoolsResponse.obj && promiseAddsInvalidPoolsResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseAddsInvalidPoolsResponse]);
            const [addsinvalidpoolsTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return addsinvalidpoolsTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Invalid Pools by OEM error status ,Please try again!!';
    }
}

export async function getAddsInvalidPoolsErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseaddsinvalidpoolsResponse = await client.apis.plans.getAddsInvalidPoolsErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promiseaddsinvalidpoolsResponse]);
        const [addsinvalidpoolsResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": addsinvalidpoolsResponse.rows, "rowcount": addsinvalidpoolsResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Invalid Pools by OEM tab'
    }
}

export async function getAddsInvalidPoolsSearchData(planid, pageno, searchParams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAddsInvalidPoolsSearchResponse = await client.apis.plans.getAddsInvalidPoolsSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseAddsInvalidPoolsSearchResponse]);

        const [addsinvalidpoolsSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": addsinvalidpoolsSearchResponse.rows, "rowcount": addsinvalidpoolsSearchResponse.rows[0] ? addsinvalidpoolsSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Invalid Pools by OEM tab'
    }
}