import React, { useEffect, useState, useCallback, useRef } from 'react';
import ChipCrossIcon from "../../assets/images/crossIconForFilterChips.svg";
import { CHIP_LIMIT_PER_PARAMETER, CHIP_OVER_LIMIT_FLAG } from '../../Constants';
import './chips.css';

export default function ChipsSection(props){

    if(props.chipList && props.chipList.length){
        return <div className='chipsSection'>
                    <div className='chipsSectionInternal'>
                        {props.chipList.map(chip=>chip.render)}
                    </div>
                    <div className='chipsCrossSection tooltip' onClick={()=>props.handleClearFilter()}>
                        <img src={ChipCrossIcon} className="chipCrossMain"/>
                        <span className="tooltiptext">Clear Filter</span>
                    </div>
        </div>
    }else{
        return '';
    }
}

export function prepareChipList(filterData, colDef,handleChipRemove){
    let chipList=[];  
    
    //Map Hearders
    //console.log("colDef==",colDef);
    const paramHeaderMapping=mapFieldWithHeader(colDef);
    //console.log("paramHeaderMapping==",paramHeaderMapping);

    if(filterData){
        let keys = Object.keys(filterData);
        keys.forEach(key => {
          if (filterData[key]) {
            if(typeof filterData[key]==="string"){
                //console.log(`${key} is a String`);
                //console.log(`${key} value is ${filterData[key]}`);
                let chip=new Chip(key,filterData[key],`${paramHeaderMapping[key]}: ${filterData[key]}`,handleChipRemove);
                chipList.push(chip);
            }else{
                //console.log(`${key} is a Array`);
                if(typeof filterData[key][0]==="string" ){ //typeof can return a lot of things, We are interested only in Arrays or Objects
                    //console.log(`${key} is a String Array`);
                    if(filterData[key].length>1){
                        let chip=null;
                        if(key.indexOf("DATE")!==-1){
                            let date1=new Date(filterData[key][1]);
                            if(filterData[key][0]==="between"){
                                let date2=new Date(filterData[key][2]);
                                chip=new Chip(key,filterData[key],`${paramHeaderMapping[key]}: ${filterData[key][0]} ${formatDate(filterData[key][1])} and ${formatDate(filterData[key][2])}`,handleChipRemove);
                            }else{
                                chip=new Chip(key,filterData[key],`${paramHeaderMapping[key]}: ${filterData[key][0]} than ${formatDate(filterData[key][1])}`,handleChipRemove);
                            }
                        }else{
                            if(filterData[key][0]==="between"){
                                chip=new Chip(key,filterData[key],`${paramHeaderMapping[key]}: ${filterData[key][0]} ${filterData[key][1]} and ${filterData[key][2]}`,handleChipRemove);
                            }else{
                                chip=new Chip(key,filterData[key],`${paramHeaderMapping[key]}: ${filterData[key][0]} than ${filterData[key][1]}`,handleChipRemove);
                            }
                        }
                        chipList.push(chip);
                    }else if(filterData[key].length===1 && filterData[key][0]==="blanks"){
                        let chip=new Chip(key,filterData[key],`${paramHeaderMapping[key]}: Blanks`,handleChipRemove);
                        chipList.push(chip);
                    }else{
                        // console.log(`Unexpected data filterData[${key}]===`,filterData[key]);
                    }
                }else{
                    //console.log(`${key} is a Object Array`);
                    if(filterData[key].length>CHIP_LIMIT_PER_PARAMETER){
                        let chip=new Chip(key,CHIP_OVER_LIMIT_FLAG,`${paramHeaderMapping[key]}: Multiple Selected`,handleChipRemove);
                        chipList.push(chip);
                    }else{
                        filterData[key].forEach((obj)=>{
                            //console.log(`${key}:${obj.value}`);
                            let chip=new Chip(key,obj.value,`${paramHeaderMapping[key]}: ${obj.label}`,handleChipRemove);
                            chipList.push(chip);
                        })
                    }
                }
            }
          }
          
        });
    }

    return chipList;
}

//Internal Chip component
function Chip(paramName, paramValue,displayText,handleChipRemove){
    this.paramName=paramName;
    this.paramValue=paramValue;
    this.displayText=displayText;

    this.render=<div className='aChip' key={this.displayText}>
                    {this.displayText}
                    <img src={ChipCrossIcon} className="chipCross" onClick={()=>handleChipRemove(this.paramName,this.paramValue)}/>
                </div>
}

function mapFieldWithHeader(coldef){
    let fieldConfigMap=[];
    if(coldef && Array.isArray(coldef)){
        coldef.forEach(fieldConfig=>{
            if(fieldConfig.field && fieldConfig.field!==""){
                fieldConfigMap[fieldConfig.field]=fieldConfig.headerName;
            }
        })
    }
    return fieldConfigMap;
}

function formatDate(date){
    if(date){
        // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthsShort = ["Jan", "Febr", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let day=date.getDate();
        let month=date.getMonth();
        let monthStr=monthsShort[month];
        let fullYear=date.getFullYear();

        return `${day}-${monthStr}-${fullYear}`;
    }else{
        return "";
    }
}

