import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localeFormatAndRounding, assignSign } from "../../../utils/formatters/valueFormatter";
import './ReadOnlyCellComponent.css';
// import EditableFieldComponent from './EditableFieldComponent';
// import { checkFilterParameterValue, checkType } from "../../../../utils/AdjustmentsUtils";
import { IS_ADJUSTMENT_COMPLETE_ARRAY } from "../../../Constants";
import EditableFieldComponent from "./EditableFieldComponent";


const EditableCellComponent = (props) => {
    const {type, adjustmentNode,accordionSummary,plansDataById,index,activity,zone} = props;

    let isAdjComplete = (plansDataById[0].status && IS_ADJUSTMENT_COMPLETE_ARRAY.includes(plansDataById[0].status.toLowerCase()));
    
    if(accordionSummary){
        return <div className={type === 'summaryStack' ? 'applygreybackgroundforSF' :'cellComponentForSF'}>
            <span className='salesandfinanceValuesWithMargin'>{localeFormatAndRounding(adjustmentNode.value)}</span>
        </div>
    }else if(!isAdjComplete && adjustmentNode.total){
        return <div className={type == 'summaryStack' ? (adjustmentNode.highlighted ? 'applygreybackgroundforSF selectedForFilter' : 'applygreybackgroundforSF') : ((adjustmentNode.highlighted && adjustmentNode.isFreezMonth) ? ' applyFreezMonthColor cellComponentForSF selectedForFilter':(adjustmentNode.highlighted ? 'cellComponentForSF selectedForFilter' : 'cellComponentForSF'))}>
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinanceValuesWithMargin":'salesandfinanceValuesWithMargin'}>{assignSign(adjustmentNode.requestedAdjustment)}</span>
            <span className={adjustmentNode.isFreezMonth ? 'applyFreezMonthColor salesandfinancevaluesblue': "salesandfinancevalues"}>{localeFormatAndRounding(adjustmentNode.requestedValue)}</span>
        </div>
    }else if(isAdjComplete && adjustmentNode.total){
        return <div className={type == 'summaryStack' ? (adjustmentNode.highlighted ? 'applygreybackgroundforSF selectedForFilter' : 'applygreybackgroundforSF') : ((adjustmentNode.highlighted && adjustmentNode.isFreezMonth) ? ' applyFreezMonthColor cellComponentForSF selectedForFilter':(adjustmentNode.highlighted ? 'cellComponentForSF selectedForFilter' : 'cellComponentForSF'))}>
            <span className={((adjustmentNode.requestedAdjustment > 0) && adjustmentNode.isFreezMonth) ? "applyFreezMonthColor salesandfinancevaluesgreen"
                                :(adjustmentNode.requestedAdjustment > 0) ? 'salesandfinancevaluesgreen' 
                                :((adjustmentNode.requestedAdjustment < 0) && adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevaluesred' 
                                :(adjustmentNode.requestedAdjustment < 0) ? 'salesandfinancevaluesred'
                                :(adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevalues'
                                : 'salesandfinancevalues'}
            >
                {assignSign(adjustmentNode.requestedAdjustment)}
            </span>
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinancevalues": 'salesandfinancevalues'}>{localeFormatAndRounding(adjustmentNode.adjustmentVariance)}</span>
            <span className={adjustmentNode.isFreezMonth ? 'applyFreezMonthColor salesandfinancevaluesblue': "salesandfinancevalues"}>{localeFormatAndRounding(adjustmentNode.requestedValue)}</span>
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinancevalues":'salesandfinancevalues'}>{localeFormatAndRounding(adjustmentNode.originalValue)}</span>
        </div>
    }else if(isAdjComplete && !adjustmentNode.total){
        return <div className={type == 'summaryStack' ? (adjustmentNode.highlighted ? 'applygreybackgroundforSF selectedForFilter' : 'applygreybackgroundforSF') : ((adjustmentNode.highlighted && adjustmentNode.isFreezMonth) ? ' applyFreezMonthColor cellComponentForSF selectedForFilter':(adjustmentNode.highlighted ? 'cellComponentForSF selectedForFilter' : 'cellComponentForSF'))}>
            {adjustmentNode.isFreezMonth?
                <span className={((adjustmentNode.requestedAdjustment > 0) && adjustmentNode.isFreezMonth) ? "applyFreezMonthColor salesandfinancevaluesgreen"
                                    :(adjustmentNode.requestedAdjustment > 0) ? 'salesandfinancevaluesgreen' 
                                    :((adjustmentNode.requestedAdjustment < 0) && adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevaluesred' 
                                    :(adjustmentNode.requestedAdjustment < 0) ? 'salesandfinancevaluesred'
                                    :(adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevalues'
                                    : 'salesandfinancevalues'}
                >
                    {assignSign(adjustmentNode.requestedAdjustment)}
                </span>
                :
                <div className='editablefield'>
                    <EditableFieldComponent adjustmentNode={adjustmentNode} index={index} activity={activity} zone={zone} />
                </div>
            }
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinancevalues": 'salesandfinancevalues'}>{localeFormatAndRounding(adjustmentNode.adjustmentVariance)}</span>
            <span className={adjustmentNode.isFreezMonth ? 'applyFreezMonthColor salesandfinancevaluesblue': "salesandfinancevalues"}>{localeFormatAndRounding(adjustmentNode.requestedValue)}</span>
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinancevalues":'salesandfinancevalues'}>{localeFormatAndRounding(adjustmentNode.originalValue)}</span>
        </div>
    }else{
        return <div className={type == 'summaryStack' ? (adjustmentNode.highlighted ? 'applygreybackgroundforSF selectedForFilter' : 'applygreybackgroundforSF') : ((adjustmentNode.highlighted && adjustmentNode.isFreezMonth) ? ' applyFreezMonthColor cellComponentForSF selectedForFilter':(adjustmentNode.highlighted ? 'cellComponentForSF selectedForFilter' : 'cellComponentForSF'))}>
            <div className='editablefield'>
                 <EditableFieldComponent adjustmentNode={adjustmentNode} index={index} activity={activity} zone={zone} />
            </div>
            <span className={adjustmentNode.isFreezMonth ? 'applyFreezMonthColor salesandfinancevaluesblue': "salesandfinancevalues"}>{localeFormatAndRounding(adjustmentNode.requestedValue)}</span>
        </div>
    }
}
export default EditableCellComponent;;


