import * as CONSTANTS from '../../../../../src/Constants';

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.VIN.field, headerName: CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.VIN.headerName, unSortIcon: true,
        cellClassRules: cellClassRules(CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.VIN.name),
        //cellEditor:CONSTANTS.cellEditors.TextFieldEditor,
        filterType:"input",filterOptions:[],
        tooltipValueGetter: params => {
            return toolTipUI(params,CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.VIN.name)
        },comparator: customComparator,
    },
    {
        field: CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.NEWDDEPAMT.field, headerName: CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.NEWDDEPAMT.headerName, unSortIcon: true,
        cellClassRules: cellClassRules(CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.NEWDDEPAMT.name),
        //cellEditor:CONSTANTS.cellEditors.TextFieldEditor,
        filterType:"input",filterOptions:[],
        tooltipValueGetter: params => {
            return toolTipUI(params,CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.NEWDDEPAMT.name)
        },comparator: customComparator,
    },
    {
        field: CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.STARTINGBV.field, headerName: CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.STARTINGBV.headerName,
        unSortIcon: true,type: 'numericColumn',
        //cellEditor: 'numericEditor',
        filterType:"input",cellClassRules: cellClassRules(CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.STARTINGBV.name), tooltipValueGetter: params => {
            return toolTipUI(params, CONSTANTS.SalesandFinanceColumns.SalesandFinanceBvDepOverrideColumns.STARTINGBV.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        //cellRenderer:'SalesAndFinanceActionRenderer',
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW" || (params.data.row_validation_status && params.data.row_validation_status.includes("D"))) {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations  && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

export const defaultColDef = {
    editable: false, //make it true while implementing edit functionalities
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};

function customComparator(valueA, valueB) {
    if(valueA===null && valueB===null){
        return true;
    }else if(valueA===null || valueB===null){
        return false;
    }else{
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
}
