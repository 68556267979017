import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { getRoleInfo } from '../../topbar/AllPlansService';
import { ADMIN_ACCESS_REVOKED_ERROR_CODE } from '../../../Constants';
import { minifyTransaction } from '../../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function getUserManagementData(){
 const accessToken = await getAccessTokenAfterAuth();

 const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

 const userManagementResponse = await client.apis.admin.getUserManagement({});

 if(userManagementResponse.body.statusCode==="500"){
  return {error:userManagementResponse.body};
}else{
    return userManagementResponse.body.data;
}
}

export async function saveUserManagementTransactions(transactionList){
  // console.log("saveUserManagementTransactions=", transactionList);
  const userNToken=await getAccessTokenNUserDetailsAfterAuth();

  const accessToken=userNToken.accessToken;
  const userEmail = userNToken.email;

  const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

  
  let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
  if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let response=await client.apis.admin.bulkTransactUsers( null,{ requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });
    // console.log(`response=${response}`);
    // console.log(`response=${JSON.stringify(response)}`);
    // console.log(`response.body=${response.body}`);
    if(response.body && response.body.statusCode==='500' && response.body.body){
      let body=JSON.parse(response.body.body);
      throw new Error(body.error);
    }
    
    return response;
  } else {
    throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
  }
}