import React from "react";
import PropTypes from "prop-types";
import { UseStyles, ValidationTextField } from "./InputStyles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";


export const TextFieldInput = ({
  type,
  inputRef,
  id,
  value,
  label,
  variant,
  readOnlyValue,
  shrinkValue,
  inputDisabled,
  isError,
  ErrorInputWithHelperText,
  withIcon,
  iconSrc,
  position,
  success,
  onTextChanged,
  handleKeyDown,
  handleOnBlur,
  className,
  autoFocus,
  inputProps,
  ...props
}) => {
  const classes = UseStyles();

  const withStartIconValue =
    withIcon === true && position === "start" ? (
      <InputAdornment position={position}>
        <img alt="Text" src={iconSrc} />
      </InputAdornment>
    ) : (
      ""
    );
  const withEndIconValue =
    withIcon === true && position === "end" ? (
      <InputAdornment position={position}>
        <img alt="Text" src={iconSrc} />
      </InputAdornment>
    ) : (
      ""
    );

  const InputText = (
    <TextField
      className={className}
      type={type}
      id={id}
      label={label}
      value={value}
      {...props}
      InputProps={{
        readOnly: readOnlyValue,
        shrink: shrinkValue,
        startAdornment: withStartIconValue,
        endAdornment: withEndIconValue,
        className: className
      }}
      inputRef={inputRef}
      variant={variant}
      disabled={inputDisabled}
      error={isError}
      helperText={ErrorInputWithHelperText}
      onChange={(e)=> onTextChanged(e)}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
      autoFocus={autoFocus}
      inputProps={inputProps}
    />
  );

  const validationTextField = (
    <ValidationTextField
      required={success}
      variant={variant}
      defaultValue={value}
      label={label}
      id={id}
    />
  );
  const SuccessWrapper = success === true ? validationTextField : InputText;

  return <div className={classes.root}>{SuccessWrapper}</div>;
};

TextFieldInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  readOnlyValue: PropTypes.bool,
  shrinkValue: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  ErrorInputWithHelperText: PropTypes.string,
  withIcon: PropTypes.bool,
  iconSrc: PropTypes.string,
  position: PropTypes.string,
  success: PropTypes.bool,
};

TextFieldInput.defaultProps = {
  type: "",
  id: "",
  value: "",
  label: "",
  variant: "",
  readOnlyValue: false,
  shrinkValue: false,
  inputDisabled: false,
  isError: false,
  ErrorInputWithHelperText: "",
  withIcon: false,
  iconSrc: "",
  position: "start",
  success: false,
};
