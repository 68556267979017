import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const DiversionsTabPanel = (props)=>{
    const{showSaveOption,diversionsgridReducer,diversionsTransactions,diversionsGridColDef, ageMileageData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        ageMileageGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions, currentData,filteredData, filterParams,tabIndex, downloadCSV,userMessage,newArchitecture,gridName,loadGridData} = props;

 return(
     <div>
        {newArchitecture ? (<MainPlanInputsMultiGridUI 
                reducer={gridReducer}
                gridName={gridName}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={diversionsGridColDef}
                rowEditType={'single'}
                suppressclickEdit={!diversionsGridColDef.defaultColDef.editable||pending}
                deleteSelectedRows={deleteSelectedRows}
                handleMultipleRowDelete={handleMultipleRowDelete}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={true} />) 
            :
         (<MultiGridUI 
            reducer={diversionsgridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'diversionsgrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={diversionsGridColDef}
            suppressclickEdit={!diversionsGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            diversionsTransactions={diversionsTransactions}
            userMessage={userMessage}
             />)
         }
     </div>
 )
}

export default DiversionsTabPanel;