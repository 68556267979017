import { AllocatedColumns, cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter, valueFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customNumericSortComparator,customUpdatedByComparator, customDateComparator,getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: AllocatedColumns.SpiCode.field, 
        headerName: AllocatedColumns.SpiCode.headerName, 
        cellEditor: cellEditors.freeSoloAutoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AllocatedColumns.SpiCode.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.SpiCode.name)
        }
    },
    {
        field: AllocatedColumns.ManufacturerGroup.field, 
        headerName: AllocatedColumns.ManufacturerGroup.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AllocatedColumns.ManufacturerGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.ManufacturerGroup.name)
        },
        cellEditorParams:(params)=>{
            let options =  getModelgroupBasedOnManufacturerGroup(params);
                return options;
            }
    },
    {
        field: AllocatedColumns.CarClassCode.field, 
        headerName: AllocatedColumns.CarClassCode.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AllocatedColumns.CarClassCode.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.CarClassCode.name)
        }
    },
    {
        field: AllocatedColumns.ModelGroup.field, 
        headerName: AllocatedColumns.ModelGroup.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AllocatedColumns.ModelGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.ModelGroup.name)
        }
    },
    {
        field: AllocatedColumns.Pool.field, 
        headerName: AllocatedColumns.Pool.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AllocatedColumns.Pool.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.Pool.name)
        }
    },
    {
        field: AllocatedColumns.ModelYear.field, 
        headerName: AllocatedColumns.ModelYear.headerName, 
        minWidth: 120, 
        unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(AllocatedColumns.ModelYear.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.ModelYear.name)
        }
    },
    {
        field: AllocatedColumns.RequestedDeliveryMonth.field, 
        headerName: AllocatedColumns.RequestedDeliveryMonth.headerName, 
        minWidth: 150, unSortIcon: true,
        filterType:"datepicker",
        valueFormatter: params => dateFormatter(params.data.requested_delivery_month),
        cellEditor: cellEditors.dateEditor,
        cellClassRules: cellClassRules(AllocatedColumns.RequestedDeliveryMonth.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.RequestedDeliveryMonth.name)
        }
    },
    {
        field: AllocatedColumns.Count.field, 
        headerName: AllocatedColumns.Count.headerName, 
        unSortIcon: true, 
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',
        comparator: customNumericSortComparator, 
        valueFormatter: params => valueFormatter(params.data.count),
        filterType:"input",
        cellClassRules: cellClassRules(AllocatedColumns.Count.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.Count.name)
        }
    },
    {
        field: AllocatedColumns.UpdatedBy.field, 
        headerName: AllocatedColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,
        cellClassRules: cellClassRules(AllocatedColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.UpdatedBy.name)
        }
    },
    {
        field: AllocatedColumns.UpdatedOn.field, 
        headerName: AllocatedColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(AllocatedColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, AllocatedColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "AllocatedActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
function getModelgroupBasedOnManufacturerGroup(params){   
    let allManufacturergroupModelgroup =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
    let trimmedModalGroup=params.data.model_group?params.data.model_group.trim():'';
    let options=allManufacturergroupModelgroup[trimmedModalGroup]?allManufacturergroupModelgroup[trimmedModalGroup]:[];
        return {'options':options};
  }
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
