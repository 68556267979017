import { createNewRfPlanService, getAllRFsData, saveRfDescription, fetchRfDetailsByRfId } from '../../../services/revenuefunction/allrfs/AllRFsService';
import {
    FETCH_ALLRFS_DATA_PENDING, FETCH_ALLRFS_DATA_SUCCESS, FETCH_ALLRFS_DATA_ERROR, FETCH_CREATERF_INPUTS_PENDING, FETCH_CREATERF_INPUTS_SUCCESS, FETCH_CREATERF_INPUTS_ERROR, SET_GENERATE_RF_PROCESS_STATUS,
    SAVE_RF_DESCRIPTION_PENDING, SAVE_RF_DESCRIPTION_SUCCESS, SAVE_RF_DESCRIPTION_ERROR, RF_DETAILS_BYID_PENDING, RF_DETAILS_BYID_SUCCESS, RF_DETAILS_BYID_ERROR
} from "../../revenuefunction/allrfs/AllRFs.ActionTypes";

export const fetchAllRFsData = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_ALLRFS_DATA_PENDING))
            let allRFsData = await getAllRFsData();
            if (allRFsData) {
                dispatch(serviceActionSuccess(FETCH_ALLRFS_DATA_SUCCESS, allRFsData))
            }
        }
        catch (e) {
            dispatch(serviceActionError(FETCH_ALLRFS_DATA_ERROR))
        }
    }
}

export const activateCreateRfPlan = (rfid, description, rftype) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_CREATERF_INPUTS_PENDING))
            let newRfId = await createNewRfPlanService(rfid, description, rftype);
            if (newRfId) {
                dispatch(serviceActionSuccess(FETCH_CREATERF_INPUTS_SUCCESS, newRfId))
            }
        }
        catch (e) {
            dispatch(serviceActionError(FETCH_CREATERF_INPUTS_ERROR))
        }
    }
}

export const setGenerateRfProcessStatus = () => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_GENERATE_RF_PROCESS_STATUS))
    }
}

export const saveDescription = (rfid, description) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_RF_DESCRIPTION_PENDING));
            let resp = await saveRfDescription(rfid, description);
            if (resp) {
                dispatch(serviceActionPending(FETCH_ALLRFS_DATA_PENDING));
                let allRfsData = await getAllRFsData();
                if (allRfsData) {
                    dispatch(serviceActionSuccess(FETCH_ALLRFS_DATA_SUCCESS, allRfsData));
                    dispatch(serviceActionSuccess(SAVE_RF_DESCRIPTION_SUCCESS, 'success'));
                }
                else {
                    dispatch(serviceActionError(FETCH_ALLRFS_DATA_ERROR, allRfsData));
                }
            }
        }
        catch (e) {
            dispatch(serviceActionError(SAVE_RF_DESCRIPTION_ERROR));
        }
    }
}

export const getRfDetailsByRfId = (rfid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(RF_DETAILS_BYID_PENDING))
            const response = await fetchRfDetailsByRfId(rfid);
            if (response)
                dispatch(serviceActionSuccess(RF_DETAILS_BYID_SUCCESS, response))
        } catch (e) {
            dispatch(serviceActionError(RF_DETAILS_BYID_ERROR))
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}