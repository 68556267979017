import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import GridComponents from "./grid_components";
import { useDispatch, useSelector } from "react-redux";
import { completeEditMultipleRow, completeEditRow, validateGrid, validateRow } from '../../store/MultiGrid/MultiGrid.Actions';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import './MultiGridUI.css'; 
import Pagination from '@material-ui/lab/Pagination';
import { fetchMoveCostData, SetAssumptionMoveCostPageValue } from '../../store/movecost/MoveCost.Actions';
import { GridApi } from 'ag-grid-community';
import { Button } from '@material-ui/core';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import { getManufacturerBasedOnModel, isPlanningStatusRunning} from '../../utils/utils';
import { MAX_ROWS_ALLOWED_FOR_EACH_GRID, MAX_ROWS_ALLOWED_FOR_MASS_CHANGE, MAX_ROWS_ALLOWED_FOR_MASS_CHANGE_CONSTRAINTS } from '../../Constants';
import { UpdateMasterDataCache } from '../../store/topbar/AllPlans.Actions';
import { SetAssumptionManualVinDeletesPageValue } from '../../store/manualvindeletes/ManualVinDeletes.Actions';

const MultiGridUI = (props) => {
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const dispatch = useDispatch();
    const { reducer, gridName, gridColDef,planid,gridpageno,onRowEditingStarted, onRowEditingStopped, onCellEditingStopped, currentView, refreshCells, rowEditType, suppressclickEdit, handleMultipleRowDelete, scrollToTop,userMessage,paginationRequired,selectedPlanId} = { ...props };
    const { currentData, transactions, validations } = reducer;
      const[pageno,setPageNo]=useState(gridpageno)
    const [pagesize,setPageSize]=useState()
    const moveCostTotData = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostTotalRowCount;
      });
      const moveCostFilterView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.moveCostFilterView;
      });
      const manualVinDeletesTotalRowCount = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesTotalRowCount;
      });
      const manualVinDeletesErrorView = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesErrorView;
      });
      const manualVinDeletesUniqueErrorIDs = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.uniqueErrorIDs;
      });
    // if (currentView == 'grid' || gridName=='upgradetemplatesgrid') {
    //     window.parent.document.body.style.overflow = "hidden";
    // } //TODO: disabling for now. Need to check 
    function onGridReady(params) {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();

        window.onresize = () => {
            params.api.sizeColumnsToFit();
        }
    }
useEffect(()=>{
    if(gridName==='movecostgrid'){
        setPageNo(gridpageno)
    let totalPage=Math.floor(moveCostTotData/MAX_ROWS_ALLOWED_FOR_EACH_GRID)
    let reminder=moveCostTotData%MAX_ROWS_ALLOWED_FOR_EACH_GRID;
    setPageSize(reminder>=1?totalPage+1:totalPage)
    }
},[moveCostTotData,moveCostFilterView])

useEffect(()=>{
    if(gridName==='manualvindeletesgrid'){
        if(manualVinDeletesErrorView){
            // console.log("manualVinDeletesUniqueErrorIDs.length===>",manualVinDeletesUniqueErrorIDs?manualVinDeletesUniqueErrorIDs.length:0);
            setPageNo(gridpageno)
            if(manualVinDeletesUniqueErrorIDs){
                if(manualVinDeletesUniqueErrorIDs.length===0){
                    setPageSize(1);
                }else{
                    let totalPage=Math.floor(manualVinDeletesUniqueErrorIDs.length/MAX_ROWS_ALLOWED_FOR_EACH_GRID)
                    let reminder=manualVinDeletesUniqueErrorIDs.length%MAX_ROWS_ALLOWED_FOR_EACH_GRID;
                    setPageSize(reminder>=1?totalPage+1:totalPage);
                }
            }            
        }else{
            // console.log("manualVinDeletesTotalRowCount===>",manualVinDeletesTotalRowCount);
            setPageNo(gridpageno)
            let totalPage=Math.floor(manualVinDeletesTotalRowCount/MAX_ROWS_ALLOWED_FOR_EACH_GRID)
            let reminder=manualVinDeletesTotalRowCount%MAX_ROWS_ALLOWED_FOR_EACH_GRID;
            setPageSize(reminder>=1?totalPage+1:totalPage);
        }
    }
},[manualVinDeletesErrorView,manualVinDeletesTotalRowCount,manualVinDeletesUniqueErrorIDs])

    const frameworkComponents = {
        numericEditor: GridComponents.NumericEditor,
        autoCompleteEditor: GridComponents.AutoCompleteEditor,
        freeSoloAutoCompleteEditor: GridComponents.FreeSoloAutoCompleteEditor,
        dateEditor: GridComponents.DateEditor,
        actionsRenderer: GridComponents.ActionsRenderer,// For programs
        toggleButton: GridComponents.ToggleButtonComponent,
        templateActionsRenderer: GridComponents.TemplateActionsRenderer,//For Upgrade Template
        constraintsActionRenderer: GridComponents.ConstraintsActionRenderer,//For Constraints
        parametersActionsRenderer: GridComponents.ParametersActionsRenderer, //For parameters
        UpgradePathActionsRenderer: GridComponents.UpgradePathActionsRenderer, //For Upgrade Paths
        FromClassRenderer:GridComponents.FromClassRenderer,
        UpgradeToClassRenderer:GridComponents.UpgradeToClassRenderer,
        upgradePathAutocompleteEditor:GridComponents.UpgradePathAutocompleteEditor,
        UpgradePathZoneTempRenderer: GridComponents.UpgradePathZoneTempRenderer,
        SchedulesActionRenderer: GridComponents.SchedulesActionRenderer, //For Schedules
        AllocatedActionRenderer: GridComponents.AllocatedActionRenderer, //For Allocated
        AgeMileageActionRenderer: GridComponents.AgeMileageActionRenderer, //For AgeMileage
        AdditionalCostActionRenderer: GridComponents.AdditionalCostActionRenderer, //For Additionalcost
        TextFieldEditor: GridComponents.TextFieldEditor,
        MaxDeletesActionRenderer: GridComponents.MaxDeletesActionRenderer, //For MaxDeletes
        DiversionsActionRenderer: GridComponents.DiversionsActionRenderer, //For Diversions
        MoveConstraintActionRenderer: GridComponents.MoveConstraintActionRenderer, //For MoveConstraint
        MileageDefaultActionRenderer: GridComponents.MileageDefaultActionRenderer, //For MileageDefault
        AddsInvalidPoolsActionRenderer: GridComponents.AddsInvalidPoolsActionRenderer, //For AddsInvalidPools
        SalvageRateActionRenderer: GridComponents.SalvageRateActionRenderer, //For SalvageRate
        TargetZonePoolActionRenderer: GridComponents.TargetZonePoolActionRenderer, //For TargetZonePool
        // ZoneMapActionRenderer: GridComponents.ZoneMapActionRenderer, //For ZoneMap //Moved to Metadata
        MileageTableActionRenderer: GridComponents.MileageTableActionRenderer, //For MileageTable
        MoveCostActionRenderer: GridComponents.MoveCostActionRenderer,
        ToggleSwitchEditor:GridComponents.ToggleSwitchEditor,//For MoveCost
        DepRateActionRenderer: GridComponents.DepRateActionRenderer,// For Dep Rate
        ParametersAutoCompleteEditor:GridComponents.ParametersAutoCompleteEditor,
        ManualVinDeletesActionRenderer:GridComponents.ManualVinDeletesActionRenderer,
        MainPlanInputsActionRenderer:GridComponents.ActionsRenderer//This particular line will be removed once we move completely to new architecture
    };

    const handleCellEditingStopped = (params) => {
        return onCellEditingStopped();
    }

    const handleCellEditingStarted = (params) => {
        if(transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            return onRowEditingStarted(true, isOfTypeCopyOrDelete,transactions); 
        }
    }
const [changeCurrentData,setChangeCurrentData]=useState(false)
    const onCellValueChanged = (params) => {
        // console.log("gridApi==",gridApi);
        let allowMassChange=true;
        if(gridApi && gridApi.getSelectedRows().length>MAX_ROWS_ALLOWED_FOR_MASS_CHANGE){
            userMessage('error',`Please select up-to ${MAX_ROWS_ALLOWED_FOR_MASS_CHANGE} records only. Only 1 row was updated.`);
            allowMassChange=false;
        }

        if (params.oldValue !== params.newValue) {
            var updatedRowData = { ...params.data };
            params.data.isEdited = true;
            updatedRowData[params.column.colId] = params.newValue;
            let sortedData=[];
            let selectedRows = [];
            let isSelectedRowModified=false;
            if (gridApi && gridApi.getSelectedRows().length > 0) {
                selectedRows = gridApi.getSelectedRows();
                // console.log("selectedRows===",selectedRows.length);
                isSelectedRowModified=selectedRows.length>0 && selectedRows.length<=MAX_ROWS_ALLOWED_FOR_MASS_CHANGE && selectedRows.findIndex(aRow=>aRow.ID===updatedRowData.ID)>-1;
                if(isSelectedRowModified && allowMassChange){
                        selectedRows.forEach(rowData=>{
                            rowData[params.column.colId] = params.newValue;
                        });
                }
            }
            if(gridApi.sortController.getSortModel().length>0){
                 gridApi.forEachNodeAfterFilterAndSort((node, index)=>{
                    sortedData.push(node.data)
                 });  
                 columnApi.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
                setChangeCurrentData(true)  
            }
            if(isSelectedRowModified && allowMassChange){
                let rowIds=[];
                selectedRows.forEach(rowData=>{
                    // console.log(`Row data 2= ${JSON.stringify(rowData)}`);
                    // console.log(`Row data 2 ${rowData}`);
                    rowIds.push(rowData.ID);
                });
                
                // console.log("Firing completeEditMultipleRow===");
                dispatch(completeEditMultipleRow(gridName, updatedRowData, gridColDef.validationFn, { id: rowIds, value: params.colDef.field },gridColDef.businessValidationFn,sortedData));
            }else{
                dispatch(completeEditRow(gridName, updatedRowData, gridColDef.validationFn, { id: params.data.ID, value: params.colDef.field },gridColDef.businessValidationFn,sortedData));
            }
            
                
               
            
            // gridApi.sortController.getSortModel([])
            // dispatch(validateRow(gridName, reducer.currentData, gridColDef.businessValidationFn));
        }
        return onCellEditingStopped();
    }

const setpageNumber=(event, value)=>{
    if(gridName==='movecostgrid'){
        dispatch(SetAssumptionMoveCostPageValue(value))
    } else if(gridName==='manualvindeletesgrid'){
        dispatch(SetAssumptionManualVinDeletesPageValue(value))
    }
   //dispatch(fetchMoveCostData(planid,value))
   setPageNo(value);
}


const [newCurrentData,setNewCurrentData]=useState()

useEffect(()=>{
if(changeCurrentData){
    setNewCurrentData(currentData)
}
setNewCurrentData([])
setChangeCurrentData(false)
},[changeCurrentData])


    const handleRowEditMode = (params) => {
        // // Handle click event for action cells  
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });
        // gridApi.setImmutableData(true);
        if (isCurrentRowEditing == true)
            return onRowEditingStarted(isCurrentRowEditing,null,transactions);
    }

    const rowClassRules = function () {
        return {
            'errorCellBorder': (params) => {
                return (params.data.status === 'INVALID_ROW' || params.data.status === 'INVALID_NEW_ROW')
            },
            'errorRow': (params) => {
                return (params.data.status === 'ERROR_ROW' || params.data.status === 'ERROR_NEW_ROW' || params.data.status === 'ERROR_COPY_ROW')
            },
            'copyRow': (params) => {
                return (params.data.status === 'COPY_ROW')
            },

        }
    }

    // const onBodyScroll = (event) => {
    //     if (event.direction == 'vertical' && event.top > 6) {
    //         window.scrollTo(0, 200);
    //     } else if (event.direction == 'vertical') {
    //         window.scrollTo(0, 0);
    //     }
    // }

    useEffect(() => {
        if (gridApi !== null) {
            gridApi.ensureIndexVisible(0);
        }
        
    },[scrollToTop]);

    useEffect(() => {
        if(transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            if(isOfTypeCopyOrDelete)
                return onRowEditingStarted(true, isOfTypeCopyOrDelete,transactions); 
        }
    },[transactions]);

    useEffect(() => {
        if (gridApi !== null) {
            // console.log("Refreshing Grid Cells...");
            //The refreshcells and redrawrows os not working properly if directly called
            //SetTimeout, helps in this process
            setTimeout(()=>{
                gridApi.refreshCells();
                gridApi.redrawRows();
            },100)
            // console.log("Successfully refreshed Grid Cells...");
        }
    }, [refreshCells]);

    useEffect(()=>{
        if(transactions.length>0 && transactions[transactions.length-1].type!==undefined && currentData.length>1 && transactions[transactions.length-1].type=='add' && transactions[transactions.length-1].data==undefined){  //if the latest action is Add then scroll
            if(gridApi.sortController.getSortModel().length>0 ){
                columnApi.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
            }
            let node = gridApi.getRowNode(currentData[currentData.length-1].ID);
            if(node && node.rowIndex!==undefined){
                gridApi.ensureIndexVisible(currentData.length-2);
            }
        }
    },[currentData])

    const onSelectionChanged = () => {
        let selectedRows = [];
        if (gridApi && gridApi.getSelectedRows().length > 0) {
            selectedRows = gridApi.getSelectedRows();
        }
        if(handleMultipleRowDelete!==undefined){
            handleMultipleRowDelete(selectedRows);
        }
    }
    const refresh = () => {
        if (gridName !== 'moveconstraintsgrid' && gridName !== 'upgradetemplatesgrid' && gridName !== 'upgradepathsgrid' && gridName !== 'parametersgrid') {
            dispatch(UpdateMasterDataCache())
        }
        setChangeCurrentData(true)
        dispatch(validateGrid(gridName, currentData, gridColDef.validationFn, gridColDef.businessValidationFn))
    }
    useEffect(()=>{
        // console.log("gridColDef changed.....");
        if(gridColDef && gridColDef.defaultColDef && gridApi && 
            !gridColDef.defaultColDef.editable && gridApi.getEditingCells().length>0){

                // console.log("Stopping grid edit.....");
                gridApi.stopEditing();
        }
    },[gridColDef,gridApi]);

    return (
        <div id="grid-theme-wrapper" className="app-theme">
  <div style={{'backgroundColor':'white'}}>
  <Button onClick={refresh}><RefreshSharpIcon fontSize='large'/></Button>
  Selected rows={gridApi?gridApi.getSelectedRows().length:0},Pending Transactions={transactions?transactions.length:0}
  {gridName && gridName==='movecostgrid' && selectedPlanId? 
    <div style={{'backgroundColor':'red','color':'white', 'alignItems':'center','fontWeight':'bold'}}>Main Inputs Data !!</div>
    :''
  }
  </div>
            <div style={{
                width: '100%',
                height: '100%'
            }}
                className="ag-theme-alpine">
                {(currentData !== null) ? <AgGridReact
                    propsTest={props}
                    overlayNoRowsTemplate='No Records To Show'
                    reactNext={true}
                    onGridReady={onGridReady}
                    columnDefs={gridColDef.columnDefs}
                    // Disable editing on grid if plan status is running 
                    defaultColDef={gridColDef.defaultColDef}
                    rowData={changeCurrentData?newCurrentData:currentData}
                    getRowNodeId={data => data.ID}
                    checkboxSelection={true}
                    headerCheckboxSelection={true}
                    enterMovesDownAfterEdit={false}
                    enterMovesDown={false}
                    frameworkComponents={frameworkComponents}
                    suppressHorizontalScroll={false}
                    rowSelection={'multiple'}
                    editType={rowEditType}
                    immutableData={true}
                    onCellEditingStarted={handleCellEditingStarted}
                    onCellValueChanged={onCellValueChanged}
                    onRowClicked={handleRowEditMode}
                    onCellEditingStopped={handleCellEditingStopped}
                    rowClassRules={rowClassRules()}
                    suppressPropertyNamesCheck={true}
                    // onBodyScroll={onBodyScroll}
                    gridName={gridName}
                    stopEditingWhenGridLosesFocus={false}
                    tooltipShowDelay={100}
                    tooltipMouseTrack={true}
                   // gridName={gridName}
                    suppressClickEdit={suppressclickEdit}
                    suppressMaintainUnsortedOrder={transactions.length>0 && (transactions[0].type!==undefined)&&(transactions[0].type=='copy') ?false:true}
                    onSelectionChanged={onSelectionChanged}
                    suppressRowClickSelection={true}                    
                /> : <div></div>}
                  {gridName==='movecostgrid' || paginationRequired?
                    <Pagination 
                        style={{marginLeft:'30%'}}
                        count={currentData.length==0?0:(pagesize<=0?1:pagesize)} 
                        page={pageno} 
                        showFirstButton 
                        showLastButton size="large"
                        onChange={setpageNumber}/>
                     :''}
                
            </div>
          
        </div>);
}
export default MultiGridUI;