/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import "./SearchBar.css";
import { useSelector } from 'react-redux';
import Active from "../../assets/images/icon_search_active.svg";
const SearchBarComponent = (props) => {
    const {onSearchValueChanged,onEnterSearch,onKeyEnterSearch,filterSearchText,disabledTextBox,showActiveIconOnEnter,controlled=false} = props;

    if(controlled){
        return (
            <TextField className="inputRounded" id="input-with-icon-textfield" value={onEnterSearch?filterSearchText:null}
                placeholder={onEnterSearch?"Type&Enter To Search":"Search"} InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" className="inputadorent">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment:(showActiveIconOnEnter && onEnterSearch)?(
                        <InputAdornment position="end" className="inputadorent">                  
                     <img src={Active} className="imageIcon"></img>
                    </InputAdornment>
                    ):''
                }}
                disabled={disabledTextBox}
            type="search" variant="outlined" onChange={(e)=>onSearchValueChanged(e)} onKeyPress={(e) => {
                if (onEnterSearch && e.key === "Enter") {
                    onKeyEnterSearch(e)
                }
              }} />     
        );
    }else{
        return (
            <TextField className="inputRounded" id="input-with-icon-textfield" defaultValue={onEnterSearch?filterSearchText:null}
                placeholder={onEnterSearch?"Type&Enter To Search":"Search"} InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" className="inputadorent">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment:(showActiveIconOnEnter && onEnterSearch)?(
                        <InputAdornment position="end" className="inputadorent">                  
                     <img src={Active} className="imageIcon"></img>
                    </InputAdornment>
                    ):''
                }}
                disabled={disabledTextBox}
            type="search" variant="outlined" onChange={(e)=>onSearchValueChanged(e)} onKeyPress={(e) => {
                if (onEnterSearch && e.key === "Enter") {
                    onKeyEnterSearch(e)
                }
              }} />     
        );
    }
    
}

SearchBarComponent.propTypes = {
    onSearchValueChanged: PropTypes.any
}

export default SearchBarComponent;