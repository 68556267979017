import {getManualVinDeletesData,saveManualVinDeletesTransactions, uploadManualVinDeletesCSV,fetchManualVinDeletesFilterRecords,fetchManualVinDeletesSearchRecords,getManualVinDeletesErrorData,getManualVinDeletesErrorIds,InsertS3uploadedManualVinDeletesCSV, deleteManualVinDeletesRecordsService, fetchManualVinDeletesDropdownService} from "../../services/manualvindeletes/ManualVinDeletesService";
import { UPDATE_IS_ROW_INVALID } from "../MultiGrid/MultiGrid.Actions";
import { SetAssumptionError, SetAssumptionPending } from "../planassumptions/PlanAssumptions.Actions";
import { DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY, FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS} from "../topbar/AllPlans.ActionTypes";
import{FETCH_MANUAL_VIN_DELETES_DATA_PENDING,FETCH_MANUAL_VIN_DELETES_DATA_SUCCESS,FETCH_MANUAL_VIN_DELETES_DATA_ERROR,SAVE_MANUALVINDELETES_TRANSACTION_PENDING,SAVE_MANUALVINDELETES_TRANSACTION_SUCCESS,SAVE_MANUALVINDELETES_TRANSACTION_ERROR, SET_ASSUMPTION_MANUAL_VIN_DELETES_PAGE_VALUE, UPLOAD_MANUALVINDELETES_PENDING, UPLOAD_MANUALVINDELETES_SUCCESS, UPLOAD_MANUALVINDELETES_ERROR, FETCH_MANUAL_VIN_DELETES_DELETE_PENDING, FETCH_MANUAL_VIN_DELETES_DELETE_SUCCESS, FETCH_MANUAL_VIN_DELETES_DELETE_ERROR,FETCH_MANUALVINDELETES_FILTER_DISPLAY_DATA,
    SET_MANUALVINDELETES_FILTER_SEARCH_VIEW,SET_MANUALVINDELETES_SEARCH_TEXT,SET_MANUALVINDELETES_FILTER_PARAMS,SET_MANUALVINDELETES_FILTER_VIEW,SET_ASSUMPTION_MANUALVINDELETES_ERRORVIEW_STATUS,SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING,SET_MANUAL_VIN_DELETES_FILTER_RECORDS_SUCCESS,FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS, FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR,SET_MANUAL_VIN_DELETES_SEARCH_RECORDS_SUCCESS,RESET_TO_MANUALVINDELETES_INITIALSTATE,INSERT_UPLOADED_MANUALVINDELETES_PENDING, INSERT_UPLOADED_MANUALVINDELETES_SUCCESS, INSERT_UPLOADED_MANUALVINDELETES_ERROR, FETCH_MANUALVINDELETES_DROPDOWNVALUES_PENDING, FETCH_MANUALVINDELETES_DROPDOWNVALUES_SUCCESS, FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR} from "./ManualVinDeletes.ActionTypes";


export const fetchManualVinDeletesData = (planid,pageno) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MANUAL_VIN_DELETES_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getManualVinDeletesData(planid,pageno);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MANUAL_VIN_DELETES_DATA_SUCCESS,response));              

                let manualVinDeletesDataCount=response.rowcount;
                // console.log("manualVinDeletesDataCount==>",manualVinDeletesDataCount);
                let uniqueIDs = await getManualVinDeletesErrorIds(planid,manualVinDeletesDataCount, true);
                // console.log("fetchMoveCostData :: uniqueIDs===>",uniqueIDs);
                dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                dispatch(serviceActionSuccess(UPDATE_IS_ROW_INVALID,uniqueIDs.length>0));
                // console.log("uniqueIDs==>",uniqueIDs);

                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const saveManualVinDeletesTransactionData = (planid,transactionList,pageNo,columnConstantData,filterParams,searchText,filterView,searchView,errorView)=>{
    return async dispatch => {
        try {
           dispatch(serviceActionPending(SAVE_MANUALVINDELETES_TRANSACTION_PENDING));
           dispatch(SetAssumptionPending(true))
           await saveManualVinDeletesTransactions(planid, transactionList);
   
           let manualVinDeletesData;
           if(searchView){   
               manualVinDeletesData = await fetchManualVinDeletesSearchRecords(planid,pageNo,columnConstantData,searchText);   
           }   
           if(filterView){   
               manualVinDeletesData = await fetchManualVinDeletesFilterRecords(planid,pageNo,filterParams);   
           } else if(!searchView) {   
                let output=await getManualVinDeletesData(planid,1);   
                let manualVinDeletesTotData=output.rowcount;   
                let uniqueIDs = await getManualVinDeletesErrorIds(planid,manualVinDeletesTotData);   
                dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                dispatch(serviceActionSuccess(UPDATE_IS_ROW_INVALID,uniqueIDs.length>0));
                
               if(errorView){   
                   manualVinDeletesData=await getManualVinDeletesErrorData(planid, 1,uniqueIDs);   
                   if(manualVinDeletesData){   
                       dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS,manualVinDeletesData));   
                   }   
               }else{   
                   manualVinDeletesData = await getManualVinDeletesData(planid,pageNo);   
               }   
           }   
           if (manualVinDeletesData) {   
               dispatch(serviceActionSuccess(SAVE_MANUALVINDELETES_TRANSACTION_SUCCESS,manualVinDeletesData));   
               dispatch(SetAssumptionPending(false))   
           }   
       } catch (e) {   
           // console.log("Exception",e);   
           console.log("Exception: ",e.message);   
           dispatch(serviceActionError(SAVE_MANUALVINDELETES_TRANSACTION_ERROR, "Unknown error"));   
           dispatch(SetAssumptionError("Unknown error"))   
       }   
   }}

export const uploadManualVinDeletesData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_MANUALVINDELETES_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadManualVinDeletesCSV(planId, files);
            let ManualVinDeletesData = await getManualVinDeletesData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_MANUALVINDELETES_SUCCESS, ManualVinDeletesData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_MANUALVINDELETES_ERROR, "ManualVinDeletes upload failed"));
                dispatch(SetAssumptionError("ManualVinDeletes upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_MANUALVINDELETES_ERROR, "ManualVinDeletes upload failed"));
            dispatch(SetAssumptionError("ManualVinDeletes upload failed"))
        }
    }
}

export const insertUploadedManualVinDeletesData = (planId, filename,gridname,userMessage,setInsertProgress)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(INSERT_UPLOADED_MANUALVINDELETES_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await InsertS3uploadedManualVinDeletesCSV(planId,filename,gridname.replace('grid',''));
            if(!response.error || response.body.statusCode!="500"){
                let ManualVinDeletesData = await getManualVinDeletesData(planId);
                dispatch(serviceActionSuccess(INSERT_UPLOADED_MANUALVINDELETES_SUCCESS, ManualVinDeletesData));
                dispatch(serviceActionSuccess(DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY,gridname))
                userMessage('success','Succesfully Inserted!!')
                setInsertProgress(false)
                let manualVinDeletesDataCount=response.rowcount;
                // console.log("manualVinDeletesDataCount==>",manualVinDeletesDataCount);
                let uniqueIDs = await getManualVinDeletesErrorIds(planId,manualVinDeletesDataCount, true);
                // console.log("fetchMoveCostData :: uniqueIDs===>",uniqueIDs);
                dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                dispatch(serviceActionSuccess(UPDATE_IS_ROW_INVALID,uniqueIDs.length>0));
               
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionSuccess(DROP_GRIDNAME_FROM_INSERT_PROGRESS_ARRAY,gridname))
                dispatch(serviceActionError(INSERT_UPLOADED_MANUALVINDELETES_ERROR, "ManualVinDeletes uploaded file insert failed"));
                dispatch(SetAssumptionError("ManualVinDeletes uploaded file insert failed"))
            }
        } catch (e) {
            console.error("Exception", e.message);
            dispatch(serviceActionError(INSERT_UPLOADED_MANUALVINDELETES_ERROR, "ManualVinDeletes uploaded file insert failed"));
            dispatch(SetAssumptionError("ManualVinDeletes uploaded file insert failed"))
        }
    }
}

export const fetchManualVinDeletesErrorData = (planid,manualVinDeletesTotData,manualVinDeletesPageNo,uniqueIDsTemp) =>{    
    return async dispatch =>{
        // console.log(`fetchManualVinDeletesErrorData: planid=${planid},manualVinDeletesTotData=${manualVinDeletesTotData},manualVinDeletesPageNo=${manualVinDeletesPageNo}`);
        try{
            dispatch (serviceActionPending(FETCH_MANUAL_VIN_DELETES_DATA_PENDING));
            dispatch(SetAssumptionPending(true));
            // console.log("manualVinDeletesTotData==>",manualVinDeletesTotData);
            if(!manualVinDeletesTotData){
                let output=await getManualVinDeletesData(planid,1);
                manualVinDeletesTotData=output.rowcount;
            }
            // console.log("uniqueIDs==>",uniqueIDs);
            let uniqueIDs = await getManualVinDeletesErrorIds(planid,manualVinDeletesTotData, false);
            dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
            
            // console.log("uniqueIDs==>",uniqueIDs);
            let response=await getManualVinDeletesErrorData(planid,manualVinDeletesPageNo?manualVinDeletesPageNo:1,uniqueIDs);
            // console.log('error data IN fetchManualVinDeletesErrorData()->',response);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error("Error while getting move cost error data: ", e.message);
            dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DATA_ERROR,`ERROR: ${e.message}`));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const fetchManualVinDeletesDropdownValues = (planid) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_MANUALVINDELETES_DROPDOWNVALUES_PENDING));
        let manualVinDeletesData = await fetchManualVinDeletesDropdownService(planid);
        if (manualVinDeletesData) {
            dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_DROPDOWNVALUES_SUCCESS, manualVinDeletesData));
        } else {
            dispatch(serviceActionError(FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR, manualVinDeletesData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const SetAssumptionManualVinDeletesPageValue = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_MANUAL_VIN_DELETES_PAGE_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

//To Get and Display all Filter parameters and required  constant Values for Manual vin deletes
export const fetchManualVinDeletesFilterDisplayData = ()=>{
    return async dispatch => {
       try {
           // dispatch(serviceActionPending(FETCH_ALLOCATED_FILTER_DATA_PENDING));
               dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_FILTER_DISPLAY_DATA));
       } catch (_) {
           //dispatch(serviceActionError(FETCH_ALLOCATED_FILTER_DATA_ERROR, "Unknown error"));
       }
        }
   }

export const setManualVinDeletesFilterSearchView = (value) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MANUALVINDELETES_FILTER_SEARCH_VIEW,value))
    }
}

export const setManualVinDeletesFilterSearchText = (value)=>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MANUALVINDELETES_SEARCH_TEXT,value))
    }
}

export const setManualVinDeletesFilterParameters = (filterParams)=>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MANUALVINDELETES_FILTER_PARAMS,filterParams))
    }
}

export const setManualVinDeletesFilterView = (value) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MANUALVINDELETES_FILTER_VIEW,value))
    }
}

export const SetAssumptionManualVinDeletesErrorViewStatus = (value)=>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_MANUALVINDELETES_ERRORVIEW_STATUS,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }
}

export const fetchManualVinDeletesSearchData = (planid,searchInColumns,searchText,pageNo)=>{
 return async dispatch =>{
    try{
        dispatch (serviceActionPending(SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING));
        dispatch(SetAssumptionPending(true))
        let response = await fetchManualVinDeletesSearchRecords(planid,pageNo,searchInColumns,searchText);
        if(response){
            dispatch(serviceActionSuccess(SET_MANUAL_VIN_DELETES_SEARCH_RECORDS_SUCCESS,response));
            dispatch(SetAssumptionPending(false))
        }else{
            dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    } 
    catch(e){
        console.error(e);
        dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DATA_ERROR,"Unknown error"));
        dispatch(SetAssumptionError("Unknown error"))
    }
 }
}

export const fetchManualVinDeletesFilterData = (planid,filterparameters,pageNo)=>{
    return async dispatch=>{
        try{
            dispatch (serviceActionPending(SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await fetchManualVinDeletesFilterRecords(planid,pageNo,filterparameters);
            if(response){
                dispatch(serviceActionSuccess(SET_MANUAL_VIN_DELETES_FILTER_RECORDS_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        }
        catch(e){
            console.error(e);
            dispatch(serviceActionError(SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const resetManualVinDeletes = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MANUALVINDELETES_INITIALSTATE))
    }
  }

export const deleteManualVinDeletesRecords = (planId,data) =>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_MANUAL_VIN_DELETES_DELETE_PENDING));
            dispatch(SetAssumptionPending(true))
            await deleteManualVinDeletesRecordsService(planId,data);
            let {manualVinDeletesColumnsConstantData,filterSearchKey,manualvindeletesFilterParams,manualVinDeletesPageNo,operationApplied} = data;
            let manualVinDeletesData= await getManualVinDeletesData(planId,manualVinDeletesPageNo);
            let uniqueIDs = await getManualVinDeletesErrorIds(planId,manualVinDeletesData.rowcount);   
            dispatch(serviceActionSuccess(FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
            if(manualVinDeletesData){
                dispatch(serviceActionSuccess(FETCH_MANUAL_VIN_DELETES_DELETE_SUCCESS,{manualVinDeletesData,'operationApplied':operationApplied}));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(FETCH_MANUAL_VIN_DELETES_DELETE_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}
