import {fetchDiversionsDropdownService, getDiversionsData, saveDiversionsTransactions,uploadDiversionsCSV} from '../../services/diversions/DiversionsService';
import {FETCH_DIVERSIONS_DATA_PENDING, FETCH_DIVERSIONS_DATA_SUCCESS, FETCH_DIVERSIONS_DATA_ERROR, UPLOAD_DIVERSIONS_PENDING, UPLOAD_DIVERSIONS_SUCCESS, UPLOAD_DIVERSIONS_ERROR,
    SAVE_DIVERSIONS_TRANSACTION_PENDING, SAVE_DIVERSIONS_TRANSACTION_SUCCESS, SAVE_DIVERSIONS_TRANSACTION_ERROR,
     RESET_TO_DIVERSIONS_INITIALSTATE,
     FETCH_DIVERSIONS_DROPDOWNVALUES_ERROR,
     FETCH_DIVERSIONS_DROPDOWNVALUES_PENDING,
     FETCH_DIVERSIONS_DROPDOWNVALUES_SUCCESS} from '../../store/diversions/Diversions.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';

export const fetchDiversionsData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_DIVERSIONS_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getDiversionsData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_DIVERSIONS_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_DIVERSIONS_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_DIVERSIONS_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadDiversionsData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_DIVERSIONS_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadDiversionsCSV(planId, files);
            let diversionsData = await getDiversionsData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_DIVERSIONS_SUCCESS, diversionsData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_DIVERSIONS_ERROR, "diversions upload failed"));
                dispatch(SetAssumptionError("diversions upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_DIVERSIONS_ERROR, "diversions upload failed"));
            dispatch(SetAssumptionError("diversions upload failed"))
        }
    }
}

export const saveDiversionsTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_DIVERSIONS_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveDiversionsTransactions(planid, transactionList);
            let diversionsData = await getDiversionsData(planid);
            if (diversionsData) {
                dispatch(serviceActionSuccess(SAVE_DIVERSIONS_TRANSACTION_SUCCESS, diversionsData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_DIVERSIONS_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchDiversionsDropdownValues = (planId) => {
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_DIVERSIONS_DROPDOWNVALUES_PENDING));
        let diversionsData = await fetchDiversionsDropdownService();
        if (diversionsData) {
            dispatch(serviceActionSuccess(FETCH_DIVERSIONS_DROPDOWNVALUES_SUCCESS, diversionsData));
            } else {
            dispatch(serviceActionError(FETCH_DIVERSIONS_DROPDOWNVALUES_ERROR, diversionsData));
        }
    }   
}
export const resetDiversions = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_DIVERSIONS_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}