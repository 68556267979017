import React, { useState } from 'react';
import './UpgradeFromClassRenderer.css';

function FromClassRenderer(params) {
    const { from_cc, from_cc_group } = params.data;
    const {value} = params;
    
    return (
        (params.data.from_cc && params.data.from_cc.car_class!==undefined)?
        <div className='textalignment'>
            <span>{params.data.from_cc.car_class}</span>
            {(params.data.from_cc.car_class=='' || params.data.from_cc.car_class==null)?'':<span className='fromccgroup'>{params.data.from_cc.car_class_car_group}</span>}
        </div>
        :
        <div className='textalignment'>
            <span>{from_cc}</span>
            {(from_cc=='' || from_cc==null)?'':<span className='fromccgroup'>{from_cc_group}</span>}
        </div>
    );
}

export default FromClassRenderer;