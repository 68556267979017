import {checkStatusOfDeletePlanJob, initiateDeletePlanService, savePlnaManagementTransactions} from '../../../services/admin/planmanagement/PlanManagementService';
import {fetchAllPlansWithDimensions}from '../../../services/topbar/AllPlansService';
import {FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS, FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR,GET_ALLPLANS_TABLE_DATA} from '../../topbar/AllPlans.ActionTypes';
import {SAVE_PLAN_MANAGEMENT_TRANSACTION_PENDING, SAVE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS, SAVE_PLAN_MANAGEMENT_TRANSACTION_ERROR, FETCH_PLAN_MANAGEMENT_DROPDOWN_SUCCESS, FETCH_PLAN_MANAGEMENT_DROPDOWN_ERROR, DELETE_PLAN_MANAGEMENT_TRANSACTION_PENDING, DELETE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS, DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR, DELETE_PLAN_MANAGEMENT_TRANSACTION_IN_PROGRESS} from "./PlanManagement.ActionTypes";

export const loadPlanManagementDropdownData = ()=>{
    return async dispatch=>{
        try{
        dispatch(serviceActionSuccess(FETCH_PLAN_MANAGEMENT_DROPDOWN_SUCCESS));
        }
        catch(e){
            dispatch(serviceActionError(FETCH_PLAN_MANAGEMENT_DROPDOWN_ERROR, "Unknown error"));
        }
    }
}

export const savePlanManagement= (transactionList)=>{
  return async dispatch => {
    try {
        dispatch(serviceActionPending(SAVE_PLAN_MANAGEMENT_TRANSACTION_PENDING));
        await savePlnaManagementTransactions(transactionList);
        let allPlansData = await fetchAllPlansWithDimensions();
        // console.log("Output===",allPlansData);
        if (allPlansData) {
            dispatch(serviceActionSuccess(SAVE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS));
            dispatch(serviceActionSuccess(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS, allPlansData));
            dispatch(serviceActionSuccess(GET_ALLPLANS_TABLE_DATA))         
        }
    } catch (e) {
        console.log("Exception",e.message);
        dispatch(serviceActionError(SAVE_PLAN_MANAGEMENT_TRANSACTION_ERROR, e.message));
        dispatch(serviceActionError(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_ERROR, e.message));
    }
  }
}
function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionUpdate(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}

export const initiateDeletePlan= (planId)=>{
    // console.log("Delete plan for plan-Id=",planId);
    return async dispatch => {
      try {
          dispatch(serviceActionPending(DELETE_PLAN_MANAGEMENT_TRANSACTION_PENDING));
          let jobId=await initiateDeletePlanService(planId);
          dispatch(serviceActionUpdate(DELETE_PLAN_MANAGEMENT_TRANSACTION_IN_PROGRESS,jobId));
      } catch (e) {
          console.log("Exception",e);
          dispatch(serviceActionError(DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR,e.message));
      }
    }
}
export const checkDeletePlanStatus= (jobId,planId)=>{
    // console.log("Checking status for Delete plan for plan-Id, job-id=",planId,jobId);
    return async dispatch => {
      try {
          
          let status=await checkStatusOfDeletePlanJob(jobId,planId);
          if("SUCCESS"===status){
            // console.log("Delete complete without errors....");
            let allPlansData = await fetchAllPlansWithDimensions();
            // console.log("Output===",allPlansData);
            if (allPlansData) {
                dispatch(serviceActionSuccess(DELETE_PLAN_MANAGEMENT_TRANSACTION_SUCCESS));
                dispatch(serviceActionSuccess(FETCH_ALLPLANS_WITH_DIMENSIONS_DATA_SUCCESS, allPlansData));
                dispatch(serviceActionSuccess(GET_ALLPLANS_TABLE_DATA))         
            }
          }else{
            // console.log("Job status=",status);
          }
      } catch (e) {
          console.log("Exception",e.message);
          let errorMessage=e.message;
          if(errorMessage && errorMessage.includes("plan-copied-from-plan")){
            dispatch(serviceActionError(DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR, `Child plans exists for Plan ${planId}. Delete aborted.`));
          }else{
            dispatch(serviceActionError(DELETE_PLAN_MANAGEMENT_TRANSACTION_ERROR, `Failed to delete Plan ${planId} `));
          }
          
      }
    }
  }