import React, { useContext, createContext, useState, useEffect, useRef} from "react";
import Aux from "./_Aux";

import TopBar from "../components/topbar/TopBar";
import SideBar from "../components/sidebar/SideBar";
import LinearProgressIndicator from "./LinearProgressIndicator";
import Routes from "../route/Route";
import './Layout.css';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import metadata from "../metadata.json";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { FetchUploadInsertStatus } from "../services/uploadinsertstatus/insertstatus";
import constraintsColumnDefReducer from "../store/constraints/Constraints.ColumnDef.Reducer";
import { RemoveuploadedFileInsertStatus, toggleMenubarSize } from "../store/topbar/AllPlans.Actions";

const Layout = () => {
  // var uploadedFileInsertStatus = useSelector((state) => {
  //   return state.rootAllPlansReducer.allPlansReducer.UploadedFiles;
  // });
  
  const dispatch = useDispatch();
  // const pending = useSelector((state) => {
  //   // return state.rootProgramsReducer.columnDefinitionReducer.pending;
  //   for (var x in state) {
  //     for (var y in state[x]) {
  //       for (var z in state[x][y]) {
  //         if (z === 'pending' && state[x][y][z]) {
  //           return true;
  //         }
  //       }
  //     }
  //   }
  //   return false;
  // });
  // const GridPending=useSelector((state)=>{
  //   return state.PaginationGridReducer.pending
  // })
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  function handleToggle(){
      dispatch(toggleMenubarSize());
  }
  const menuMinimized = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.menuMinimized
  })
  const errorIsNotNull = useSelector((state) => {
    // return state.rootProgramsReducer.columnDefinitionReducer.pending;
    for (var x in state) {
      for (var y in state[x]) {
        for (var z in state[x][y]) {
          if (z === 'error' && state[x][y][z]) {
            {
              return true;
            }
          }
        }
      }
    }
    return false;
  })
  const [open,setOpen]=useState(false)
  const handleCloseWarningSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
  };
 const fetchStatus = async (planid,filename) => {
        try{    
        let moveCostData = await FetchUploadInsertStatus(planid,filename);
        return moveCostData
      }
      catch(e){
        console.log("Exception",e);
      }

}
const[displayPlan,setDisplayPlan]=useState(null)
useEffect(()=>{
sessionStorage.clear()
},[])

// useEffect(()=>{
//   // console.log(uploadedFileInsertStatus)
//   if(uploadedFileInsertStatus.length>0){
//   let p,data
//   const timer = setInterval(() => {
//    data=async()=>{ 
//      if(uploadedFileInsertStatus[0].planid!=undefined){
//        p=await fetchStatus(uploadedFileInsertStatus[0].planid,uploadedFileInsertStatus[0].filename) 
//        if(p.StatusData!=undefined){
//          if(p.StatusData.insertstatus==='completed'){
//         setOpen(true)
//         setDisplayPlan(p.StatusData.plan_id)
//          }
//       else{
//         if(p.StatusData.insertstatus==='error'){
//           setOpen('failed')
//         }
//       }
//     }
//     }
//     }
//     data()
//     }, 1000);
//     return () => {
//       if(open==true || open=='failed'){
//         setOpen(false)
//         dispatch(RemoveuploadedFileInsertStatus('done'))  
//       }  
//       clearInterval(timer);
//     };  
//   }
  
// },[uploadedFileInsertStatus,open])

  const windowSize = useRef([window.innerWidth, window.innerHeight,window.screen.availWidth,window.screen.availHeight,window.screen.width,window.screen.height]);
  return (
    <Aux>
      {/* TODO: show error dialog box */}
      {errorIsNotNull ? '' : ''} 
      <div className="toplevelcontainerwrapper">
        <div className={menuMinimized?"sidebarcontainer-sidebarminimized":"sidebarcontainer"}>
          <div className="menutoggle" onClick={()=>handleToggle()}>
            <div className="menuiconwrapper">
              <div className="menutoggle-bigline"></div>
              <div className="menutoggle-smallline"></div>
              <div className="menutoggle-bigline"></div>
            </div>
          </div>
          <SideBar menuMinimized={menuMinimized}/>
        </div>
        <div className={menuMinimized?"toplevelcontainer-sidebarminimized":"toplevelcontainer"}>
          
        <div>
          <TopBar />
        </div>
        <main className="row">
        {/* <div className="progressWrapper">{(pending || GridPending) ? <LinearProgress color="secondary"/> : <span></span>}</div> */}
        <LinearProgressIndicator/>
        <div className="navigationAlignment">
          <Routes />
          <Snackbar open={open} autoHideDuration={(open==true||open==false)?3000:0} onClose={handleCloseWarningSnackBar}>
            <Alert
              onClose={handleCloseWarningSnackBar}
              severity='success'>{`File inserted Successfully!!! for plan ${displayPlan}`}
            </Alert>
          </Snackbar>
          </div>
        </main>
        <footer>
          <div>© 2021 Hertz System Inc. All rights reserved. v{metadata.major}.{metadata.minor}.{metadata.patch}+{metadata.build}</div>
          <div>Res: W: {windowSize.current[2]}, H: {windowSize.current[3]}</div>
        </footer>
        </div>
        </div>
    </Aux>
  );
};

export default Layout;
