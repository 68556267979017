import React, { useState, useEffect } from 'react';
import {Button, createMuiTheme, Divider, List, ListItem} from '@material-ui/core';
import './UpgradePathsActionBar.css';
import UpgradePathsSearchBar from './searchbar/UpgradePathsSearchBar';

import IconGrid from "../../assets/images/icon_grid.svg";
import IconGridInActive from "../../assets/images/icon_grid_inactive.svg";
import IconActionEdit from "../../assets/images/icon_action_edit.svg";
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../assets/images/icons-actions-download.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { NttDropdown } from '../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import CustomPopOver from "../../core-components/Popover/CustomPopOver";
import EditIcon from "../../assets/images/icon_edit.svg";
import StatusIcon from "../../assets/images/icons_status_icons_completed.svg";
import { NttLabel } from '../../core-components/Label/NttLabel';
import { searchTemplateRecord } from '../../store/upgradepaths/UpgradeTemplates.Actions';
import { searchUpgradePathRecord, uploadUpgradePathsData } from '../../store/upgradepaths/UpgradePaths.Actions';
import { useDispatch, useSelector } from "react-redux";
import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';
import DeleteIcon from '../../assets/images/icon_trash.svg';
import { downloadData } from '../../utils/utils';

const UpgradePathsActionBar = (props) => {
    const { templatesData, getSelectedUpgradeButton, upgradeButtonSelected, onViewChanged, showSaveOption,
        onSaveClicked, onCancelClicked, onAddNewRowClick, handleSaveCancelDisabled, actionView, upgradePathRowDataInvalid, bulkRowDeleteIconDisabled, deleteSelectedRows,
        isFiltered,setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists,templatesGridColDef,upgradePathColumnDef ,upgradePathData,downloadCSV,selectedPlanId } = props;
    let upgradeButtons = ['UPGRADE TEMPLATES', 'UPGRADE PATHS'];
    const [currentView, setCurrentView] = useState('Zone Temp>From Class>To Class');
    const [activeIndex, setActiveIndex] = useState(0);
    const theme = createMuiTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const popOverOpen = Boolean(anchorEl);
    const id = popOverOpen ? 'simple-popover' : undefined;
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const dispatch = useDispatch();
    let message = "Are you sure that you want to delete selected rows?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";
    const onUpgradeButtonClick = (e, type, index) => {
        setActiveIndex(index);
        getSelectedUpgradeButton(type, index);
        if(index==1){
            setCurrentView('grid');
        }else{
            setCurrentView('accordion');
        }
        const searchkey = '';
        const searchParameters = { searchkey, upgradeButtonSelected };
        if (upgradeButtonSelected == 'UPGRADE TEMPLATES') {
            dispatch(searchTemplateRecord(searchParameters));
        } else if (upgradeButtonSelected == 'UPGRADE PATHS') {
            dispatch(searchUpgradePathRecord(searchParameters));
        }
    }

    const handleChange = (event) => {
        switchView(event.target.value);
        setCurrentView(event.target.value);
    };

    const switchView = (selected) => {
        if (selected !== "grid") {
            setCurrentView("accordion");
            setSelectedIndex(1);
            return onViewChanged("grid", selected);
        } else {
            setCurrentView("grid");
            setSelectedIndex(2);
            return onViewChanged("accordion", selected);
        }
    }

    const handleEditClick = (e, selected) => {
        switchView(selected);
        setCurrentView(selected);
    };

    const changeHandler = async (event) => {
        if (event.target.value !== '') {
          const fileToUpload = event.target.files[0];
          event.target.value = '';
          dispatch(uploadUpgradePathsData(selectedPlanId, fileToUpload));
        }
      };
    
      const downloadHandler = async (event) => {

        // console.log("IN downloadHandler: upgradePathColumnDef===>",upgradePathColumnDef);
        // console.log("upgradePathData===>",upgradePathData);
        downloadData(upgradePathData,'upgradepathsgrid',upgradePathColumnDef,downloadCSV,'upgradepaths');
      };
    
      function JsonToCSV(JsonArray, JsonFields) {
        // var csvStr = JsonFields.join(",") + "\n";
    
        // JsonArray.forEach(element => {
        //   let program_year = safeExcelValue(element.PROGRAM_YEAR);
        //   let manufacturer_group_code = safeExcelValue(element.MANUFACTURER_GROUP);
        //   let spi_code = safeExcelValue(element.SPI_CODE);
        //   let program_type = safeExcelValue(element.PROGRAM_TYPE);
        //   let model_group = safeExcelValue(element.MODEL_GROUP);
        //   let model_year = safeExcelValue(element.MODEL_YEAR);
        //   let car_class_code = safeExcelValue(element.CAR_CLASS_CODE);
        //   let begin_date = safeExcelDate(element.BEGIN_DATE, 'DD-MMM-YY');
        //   let end_date = safeExcelDate(element.END_DATE, 'DD-MMM-YY');
        //   let acceptable_zone = safeExcelValue(element.ACCEPTABLE_ZONES);
        //   let min_age = safeExcelValue(element.MIN_AGE);
        //   let max_age = safeExcelValue(element.MAX_AGE);
        //   let min_milage = safeExcelValue(element.MIN_MILEAGE);
        //   let max_milage = safeExcelValue(element.MAX_MILEAGE);
        //   let net_capital_cost = safeExcelValue(element.NET_CAPITAL_COST);
        //   let depreciation_cost = safeExcelValue(element.DEPRECIATION_COST);
        //   let incentive = safeExcelValue(element.INCENTIVE);
        //   let add_volume_min = safeExcelValue(element.ADD_VOLUME_MIN);
        //   let add_volumn_max = safeExcelValue(element.ADD_VOLUME_MAX);
        //   let start_milage = safeExcelValue(element.START_MILEAGE);
        //   let buy_status = safeExcelValue(element.BUY_STATUS);
        //   csvStr += program_year + ',' + manufacturer_group_code + ',' + spi_code + ',' + program_type + ',' + model_group + ',' + model_year + ',' + car_class_code + ',' + begin_date + ',' + end_date + ',' + acceptable_zone + ',' + min_age + ',' + max_age + ',' + min_milage + ',' + max_milage + ',' + net_capital_cost + ',' + depreciation_cost + ',' + incentive + ',' + add_volume_min + ',' + add_volumn_max + ',' + start_milage + ',' + buy_status + "\n";
        // })
        // return csvStr;
      }

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handlePopOverClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        var selectedValue;
        if (index === 1)
            selectedValue = "Zone Temp>From Class>To Class";
        else if (index === 2)
            selectedValue = "grid";
        return switchView(selectedValue);
    };


    const switchViewComponent = () => {
        return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
            <Button id={id} onClick={(e) => handlePopOverClick(e)} endIcon={<ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon>}>View</Button>
            <CustomPopOver
                className="actiobarPopOver"
                popOverId="popoverId"
                open={popOverOpen}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <List>
                    <ListItem button
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}>
                        <img src={selectedIndex === 1 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Zone Temp {'>'} From Class {'>'} To Class</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}>
                        <img src={selectedIndex === 2 ? StatusIcon : EditIcon} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Table View</NttLabel>
                    </ListItem>
                </List>
            </CustomPopOver>
        </div>);
    }

    const handleClickOpen = (props)=>{
        if(!bulkRowDeleteIconDisabled){
          setOpenDeleteDialog(true);
        }
      }

    const handleCloseDeleteDialog =()=>{
        setOpenDeleteDialog(false);
      }

      const handleDeleteSelectedRows =()=>{
        setOpenDeleteDialog(false);
        deleteSelectedRows()
      }

    return (
        <div className={actionView == "grid" ? "upgradeactionbar" : "fixedUpgradeActionBar"}>
            <div className="upgradebuttons">
                {upgradeButtons.map((type, index) => {
                    return (
                        <div className="upgradebtnstyel">
                            <Button key={index} className={upgradeButtonSelected === type ? 'btnactive' : 'btninactive'} onClick={(e) => onUpgradeButtonClick(e, type, index)} >{type}</Button>
                        </div>
                    )
                })}
            </div>
            <div className="upgradeactions">
                <div className="upgradefilterStyle">
                    <UpgradePathsSearchBar upgradeButtonSelected={upgradeButtonSelected} setIsSearchBarValueExists={setIsSearchBarValueExists}></UpgradePathsSearchBar>
                </div>
                {upgradeButtonSelected == 'UPGRADE PATHS' ?
                    <div>
                        {currentView == 'grid' && showSaveOption  && upgradePathColumnDef.defaultColDef.editable?
                            <div className="saveButtons">
                                <div className="cancelButton">
                                    <Button onClick={(e) => onCancelClicked(e)}>Cancel</Button>
                                </div>
                                <div className="primaryButton">
                                    <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes</Button>
                                </div>
                                <div className="primaryButton">
                                    <Button>+ Add</Button>
                                </div>
                                <div className="primaryButton">
                                    <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled}>Delete</Button>
                                </div>
                            </div>
                            :
                            <div className="actionButtons">
                                {(currentView !== "grid" && actionView !== 'grid') ? <img src={IconActionEdit} className="imageIcon"
                                    onClick={(e) => handleEditClick(e, 'grid')}
                                ></img> :
                                    <img src={IconGrid} className="imageIcon"
                                        onClick={(e) => handleEditClick(e, 'Zone Temp>From Class>To Class')}
                                    ></img>}
                                <label>
                                    {/* Disable upload file button if plan status is running  */}
                                    <input disabled={!upgradePathColumnDef.defaultColDef.editable} type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
                                    <img src={IconActionUpload} className="imageIcon"></img>
                                </label>
                                <div className='imageIcon'>
                                    <img src={IconActionDownload} onClick={() => downloadHandler()} />
                                </div>
                                <div>
                                    {switchViewComponent()}
                                </div>
                                {(currentView === "grid" && actionView == 'grid') ?
                                 <div style={{display:'flex'}}>
                                    <div className="primaryButton">
                                        <Button disabled={ !upgradePathColumnDef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>+ Add</Button>
                                    </div>
                                    <div className="primaryButton">
                                    <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !upgradePathColumnDef.defaultColDef.editable)}>Delete</Button>
                                    </div>
                                    </div>
                                     : <div></div>}
                            </div>}
                            <DeleteDialogBox open={openDeleteDialog}  handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
                                dialogMessage={message} title={title} action={deletetext}
                                canceltext={canceltext}>
                            </DeleteDialogBox>
                    </div>
                    : <div>
                        {upgradeButtonSelected == 'UPGRADE TEMPLATES' && showSaveOption ?
                            <div className="saveButtons">
                                <div className="cancelButton">
                                    <Button onClick={(e) => onCancelClicked(e)}>Cancel</Button>
                                </div>
                                <div className="primaryButton">
                                    <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes</Button>
                                </div>
                                {/* <div className="primaryButton">
                                    <Button>+ Add</Button>
                                </div> */}
                            </div>
                            :
                            <div></div>}
                    </div>}
            </div>
        </div>
    )
}

export default UpgradePathsActionBar;