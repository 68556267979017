import React from "react";
import ReadOnlyCellComponent from "./ReadOnlyCellComponent";
import './ReadOnlyCellComponent.css';

const AllCellsInAYearComponent = (props) => {
    const { adjustmentNode,accordionSummary,plansDataById} = props;
    

    if (adjustmentNode && adjustmentNode.total){
        return <ReadOnlyCellComponent type='summaryStack' adjustmentNode={adjustmentNode} accordionSummary={accordionSummary} plansDataById={plansDataById} />
    }else if (adjustmentNode){
        return <ReadOnlyCellComponent adjustmentNode={adjustmentNode} accordionSummary={accordionSummary} plansDataById={plansDataById} />
    }else{
        return <div></div>
    }
}

export default AllCellsInAYearComponent;


