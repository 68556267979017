import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAgeMileageData(planId,pageno){
try {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseageMileageResponse =  client.apis.plans.getAllAgeMileage({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno:0  });
    
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    // const zones = localStorage.getItem("ZONES");

    const responsesArray = await Promise.all([promiseageMileageResponse,promiseZoneResponse]);

    const [ageMileageResponse,zones] = responsesArray.map(o => o.body.data);
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        return ({
            "gridData": ageMileageResponse.rows, "rowcount": ageMileageResponse.rowCount
        });
    }
    else {
         //this else condition executes when it is older version
        return ({
            "ageMileageData": ageMileageResponse,"zones": zones
        });
    }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Age & Mileage by Car Group by Zone tab'
    }
}

export async function getAgeMileageFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseAllocatedFilterResponse = await client.apis.plans.getAgeMileageFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseAllocatedFilterResponse]);
        const [agemileageResponse] = responsesArray.map(o => o.body.data);
        return({
            "gridData": agemileageResponse.rows,"rowcount":agemileageResponse.rows[0]?agemileageResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'Age & Mileage by Car Group by Zone tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllAgeMileageData(planid,params){
    try{
            const userNToken=await getAccessTokenNUserDetailsAfterAuth();
            const accessToken=userNToken.accessToken;
            const userEmail = userNToken.email;
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });            
            let promiseagemileageDeleteResponse = await client.apis.plans.deleteAllAgeMileageRecords({ "planid": planid ? planid : "master"},{requestBody: {"updatedBy":userEmail,...params}});
            
            if(promiseagemileageDeleteResponse.obj && promiseagemileageDeleteResponse.obj.statusCode !== '500'){
                return promiseagemileageDeleteResponse;
            }
            else{
                throw Error();
            }  
        } 
        catch (error) {
            console.error(error);
            throw 'Age & Mileage by Car Group by Zone tab has internal server error, Unable to delete all the records!! Please try again'
        }
}

export async function insertS3UploadAgeMileageCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedAgeMileageData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Age & Mileage by Car Group by Zone tab, Please try again!!');
    }
}

export async function ageMileageTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateAgeMileage({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Age & Mileage by Car Group by Zone Validation status ,Please try again!!';
    }
}

export async function getAgeMileageTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAgeMileageResponse = await client.apis.plans.getAgeMileageTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseAgeMileageResponse.obj && promiseAgeMileageResponse.obj.statusCode !== '500') {
        const responsesArray = await Promise.all([promiseAgeMileageResponse]);
        const [ageMileageTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
        return ageMileageTabErrorStatusResponse;
        }
        else {
            throw Error();
        }        
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Age & Mileage by Car Group by Zone error status ,Please try again!!';
    }
}

export async function getAgeMileageErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseageMileageResponse = await client.apis.plans.getAgeMileageErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promiseageMileageResponse]);
        const [ageMileageResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": ageMileageResponse.rows, "rowcount": ageMileageResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Age & Mileage by Car Group by Zone tab'
    }
}

export async function getAgeMileageSearchData(planid,pageno,searchParams){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAgeMileageSearchResponse = client.apis.plans.getAgeMileageSearchRecords({"planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseAgeMileageSearchResponse]);

        const [ageMileageSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": ageMileageSearchResponse.rows, "rowcount": ageMileageSearchResponse.rows[0] ? ageMileageSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Age & Mileage by Car Group by Zone tab'
    }
}

export  async function uploadAgeMileageCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadAgemileage({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Age & Mileage by Car Group by Zone tab, Please try again!!');
    }
}

export async function saveAgeMileageTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactagemileage({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchAgeMileageDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const ageMileageResponseData =  client.apis.plans.getAllAgeMileage({ "planid": planId ? planId : 'master' });
    const promiseGetAllCarsGroup =  checkAndLoadDimention("carGroup", client);
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);

    const promiseprogramtypeResponse =  checkAndLoadDimention("programType", client);
    const promiseGetAdminCarGroup =  checkAndLoadDimention("adminCarGroup", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
        //  ageMileageResponseData,
          promiseGetAllCarsGroup,
          promiseprogramtypeResponse,
          promiseZoneResponse,
          promiseGetAdminCarGroup
    ]);

    const [
          //ageMileageResponse,
          carsGroupResponse,
          programtypeResponse,
          zones,
          admincarGroupName
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "ageMileageData":ageMileageResponse,
        "car_group_name": carsGroupResponse,
        "zones": zones,
        "program_type": programtypeResponse,
        "admincarGroupName":admincarGroupName,
        "users":promiseUserManagementEmailAndIdMap
    })  
}