import { SET_ASSUMPTION_ERROR_VALUE, SET_ASSUMPTION_INDEX_VALUE, SET_ASSUMPTION_PENDING_VALUE } from "./PlanAssumptions.ActionTypes";

const initialState = {
    indexvalue:0,
    pending:false,
    error:null
}

export default function PlanAssumptionsReducer (state=initialState,action){
    switch(action.type){

        case SET_ASSUMPTION_INDEX_VALUE:
            return Object.assign({}, state, {
              indexvalue:action.data
            });
        case SET_ASSUMPTION_PENDING_VALUE:
                return Object.assign({}, state, {
                  pending:action.data,
                  error:null
                });
        case SET_ASSUMPTION_ERROR_VALUE:
                    return Object.assign({}, state, {
                        pending:false,
                        error:action.data
                    });
        default:
                return state;
    }
}
