import React from 'react'
import './adjustments_RangeFilter.css';
import { NttDropdown } from '../../../../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import { NttLabel } from '../../../../../core-components/Label/NttLabel';
import Input from "@material-ui/core/Input";
import { TextFieldInput } from '../../../../../core-components/Input/Input';

const RangeFilter = (props) => {
    const { displayName, theme, selectedRangeValue, onFromValueChanged, onToValueChanged, onValueChanged } = props;

    const ITEM_HEIGHT = 48;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: 250,
            },
        },
    };

    return (<div className='rangemargin'><span className="dropdownFilter"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel>
    <div className='rangeinput'>
     <NttDropdown
        selectClassName=""
        formControlClassName="dropdownStyle"
        inputLabelId="demo-mutiple-checkbox-label"
        selectLabelId="demo-mutiple-checkbox-label"
        selectId="demo-mutiple-checkbox"
        selectVariant="outlined"
        selectValue={(selectedRangeValue[0] === undefined) ? "between" : selectedRangeValue[0]}
        selectOnchange={onValueChanged}
        selectCustomInput={<Input />}
        selectMenuProps={MenuProps}
    >
            <MenuItem value="between">is between</MenuItem>
            <MenuItem value="greater">is greater than</MenuItem>
            <MenuItem value="less">is less than</MenuItem>
            <MenuItem value="equal">is equal</MenuItem>
            <MenuItem value="notzero">not zero</MenuItem>
        </NttDropdown>
        {!(selectedRangeValue[0] === "notzero")?<TextFieldInput
            type="text"
            id="BasicOutline"
            value={(selectedRangeValue[1] === undefined) ? '' : selectedRangeValue[1]}
            variant="outlined"
            defaultValue={(selectedRangeValue[1] === undefined) ? '' : selectedRangeValue[1]}
            onTextChanged={(e) => onFromValueChanged(e)}
        />:''}
    {(selectedRangeValue[0] === "between" || selectedRangeValue[0] === undefined) ? <span className='displayflx'>
        <label className='andtext'>and</label>
        <TextFieldInput
            type="text"
            id="BasicOutline"
            value={(selectedRangeValue[2] === undefined) ? '' : selectedRangeValue[2]}
            variant="outlined"
            defaultValue={(selectedRangeValue[2] === undefined) ? '' : selectedRangeValue[2]}
            onTextChanged={(e) => onToValueChanged(e)}
        /></span>:<div></div>}
        </div>
        </span>
        </div>);
}

export default RangeFilter;