import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import './EditableFieldComponent.css';
import { TextFieldInput } from '../../../../core-components/Input/Input';
import { setDataWithAdjustment } from '../../../../store/adjustments/Adjustments.Actions';
import { Tooltip } from '@material-ui/core';
import { assignSign } from "../../../../utils/formatters/valueFormatter";

function EditableFieldComponent(props) {
    const { adjvalue, data, getUpdatedCellMonthANdYear, updatedCellMonthAndYearArr, currentValue, checkForErrors, originalValue,isDisableEdit } = props;
    const [adjustmentValue, setAdjustmentValue] = useState(adjvalue);
    const [showErrorToolTip, setShowErrorToolTip] = useState(false);
    const [showNegativeValueError, setShowNegativeValueError] = useState(null);
    const dispatch = useDispatch();
    let obj = {};
    function onAdjustmentValueChanged(event) {
        let value = 0;
        if (event.target.value !== '' && event.target.value !== '-' && event.target.value !== '+') {
            value = event.target.value;
        } else {
            value = 0;
        }
        setAdjustmentValue(value);
        let val=0;
        if(value!==0){
           val = parseFloat(value.replace(/,/g,""))
        }
        let AdjustmentValue = Math.round(val);
        data.adjustment.value = AdjustmentValue;
        let newArr = [];
        if (updatedCellMonthAndYearArr && updatedCellMonthAndYearArr.length > 0) {
            updatedCellMonthAndYearArr.map((value) => {
                if (((value.month !== data.adjustment.month) && (value.year == data.year)) || ((value.month == data.adjustment.month) && (value.year !== data.year)) || ((value.month !== data.adjustment.month) && (value.year !== data.year))) {
                    newArr.push(value);//pushing existing data
                    obj['month'] = data.adjustment.month;
                    obj['year'] = data.year;
                    newArr.push(obj);//also pusing new data
                    getUpdatedCellMonthANdYear(newArr);
                }
            })
        } else {
            obj['month'] = data.adjustment.month;
            obj['year'] = data.year;
            newArr.push(obj);
            getUpdatedCellMonthANdYear(newArr);
        }
        dispatch(setDataWithAdjustment(data));
        let mathValue = Math.sign(AdjustmentValue);
        if ((mathValue == -1 && (-(AdjustmentValue)) > (currentValue))) {
            setShowNegativeValueError('Adjustment value should not be greater than current value');
            setShowErrorToolTip(true);
            checkForErrors(true);
        } else {
            setShowErrorToolTip(false);
            setShowNegativeValueError(null);
            checkForErrors(false);
        }
    }

    return (
        <Tooltip title={showNegativeValueError!==null?showNegativeValueError:''}
         placement="bottom">
        <div className={showErrorToolTip ? 'errorMsgStyle' :(isDisableEdit?'adjustmentinputfield adjustmentinputdisablefield':'adjustmentinputfield')}>
            <TextFieldInput
            type="number"
            id="BasicOutline"
            value={adjustmentValue}
            variant="outlined"
            defaultValue={adjustmentValue}
            inputDisabled={isDisableEdit}
            onTextChanged={(e) => onAdjustmentValueChanged(e)}
        />
        </div>
       </Tooltip>
    )
}

export default EditableFieldComponent;