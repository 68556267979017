import { MaxDeleteColumns, cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter,valueFormatter,localeFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    }, 
    {
        field: MaxDeleteColumns.ZoneName.field, headerName: MaxDeleteColumns.ZoneName.headerName,minWidth:200, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MaxDeleteColumns.ZoneName.name), tooltipValueGetter: params => {
            return toolTipUI(params, MaxDeleteColumns.ZoneName.name)
        }
    },
    {
        field: MaxDeleteColumns.ModelGroup.field, headerName: MaxDeleteColumns.ModelGroup.headerName,minWidth: 190, 
        cellEditor: cellEditors.autoCompleteEditor, unSortIcon: true,
        filterType:"dropdown",filterOptions:[],
        // editable:params=>{
        //     if(params.data.CAR_GROUP && !params.data.MODEL_GROUP){return false}return true;
        //    },
        cellClassRules: cellClassRules(MaxDeleteColumns.ModelGroup.name), tooltipValueGetter: params => {
            return toolTipUI(params, MaxDeleteColumns.ModelGroup.name)
        }
    },
    {
        field: MaxDeleteColumns.CarGroup.field, headerName: MaxDeleteColumns.CarGroup.headerName, minWidth: 190,
        cellEditor: cellEditors.autoCompleteEditor,
        unSortIcon: true, filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(MaxDeleteColumns.CarGroup.name),
        // editable:params=>{
        // if( params.data.MODEL_GROUP && !params.data.CAR_GROUP){ return false}return true;
        // },
        tooltipValueGetter: params => {
            return toolTipUI(params, MaxDeleteColumns.CarGroup.name)
        }
    },
    {
        field: MaxDeleteColumns.MaxDeletes.field, headerName: MaxDeleteColumns.MaxDeletes.headerName, minWidth: 140,unSortIcon: true,
        cellEditor: cellEditors.numericEditor, 
        type: 'numericColumn',filterType:"input",
        valueFormatter: params => {
                return localeFormatter(params.data.MAX_DELETES);
        },
        cellClassRules: cellClassRules(MaxDeleteColumns.MaxDeletes.name), tooltipValueGetter: params => {
            return toolTipUI(params, MaxDeleteColumns.MaxDeletes.name)
        }
    },
    {
        field: MaxDeleteColumns.UpdatedBy.field, 
        headerName: MaxDeleteColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(MaxDeleteColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, MaxDeleteColumns.UpdatedBy.name)
        }
    },
    {
        field: MaxDeleteColumns.UpdatedOn.field, 
        headerName: MaxDeleteColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(MaxDeleteColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, MaxDeleteColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "MaxDeletesActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
    if (params.data.status === "ERROR_ZONE" || params.data.status === "ERROR_ZONE") {
        return "Duplicate ZONE";
    }
}
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
