import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { minifyTransaction } from '../../utils/utils';

export async function getAllInputTabsErrorStatus(planid,transactionList){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const promiseGetProgramTabValidationStatus =  client.apis.plans.getProgramTabErrorStatus({"planid":planid?planid:1});
        const promiseGetScheduleTabValidationStatus =  client.apis.plans.getScheduleTabErrorStatus({"planid":planid?planid:1});
        const promiseGetConstraintTabValidationStatus =  client.apis.plans.getConstraintsTabErrorStatus({'planid':planid?planid:1});
        const promiseGetAllocatedTabErrorStatus =  client.apis.plans.getAllocatedTabErrorStatus({'planid':planid?planid:1});
        const promiseGetMoveConstraintsTabErrorStatus =  client.apis.plans.getMoveConstraintsTabErrorStatus({'planid':planid?planid:1});
        const promiseGetUpgradePathsTabErrorStatus =  client.apis.plans.getUpgradePathsTabErrorStatus({'planid':planid?planid:1});
        const promiseGetAgeMileageTabErrorStatus =  client.apis.plans.getAgeMileageTabErrorStatus({'planid':planid?planid:1});
        const promiseGetAdditionalCostTabErrorStatus =  client.apis.plans.getAdditionalCostTabErrorStatus({'planid':planid?planid:1});
        const promiseGetMaxDeletesTabErrorStatus =  client.apis.plans.getMaxDeletesTabErrorStatus({'planid':planid?planid:1});
        const promiseGetAddsInvalidPoolsTabErrorStatus =  client.apis.plans.getAddsInvalidPoolsTabErrorStatus({'planid':planid?planid:1});
        const promiseGetMileageDefaultTabErrorStatus =  client.apis.plans.getMileageDefaultTabErrorStatus({'planid':planid?planid:1});
        const promiseGetSalvageRateTabErrorStatus =  client.apis.plans.getSalvageRateTabErrorStatus({'planid':planid?planid:1});
        const promiseGetTargetZonePoolTabErrorStatus =  client.apis.plans.getTargetZonePoolTabErrorStatus({'planid':planid?planid:1});
        const promiseGetDeprateTabErrorStatus =  client.apis.plans.getDepRateTabErrorStatus({'planid':planid?planid:1});
        const promiseGetDiversionsTabErrorStatus =  client.apis.plans.getDiversionsTabErrorStatus({'planid':planid?planid:1});
        const promiseMileageTableTabErrorStatus = client.apis.plans.getMileageTableTabErrorStatus({"planid":planid?planid:1});
        const promiseManualVinDeletesTabErrorStatus = client.apis.plans.getManualVinDeletesTabErrorStatus({"planid":planid?planid:1});
        const promiseMoveCostResponse =  client.apis.plans.getMoveCostTabErrorStatus({"planid":1});//Here planid is always 1 because for now move cost tab is only in main inputs
        const promiseGetParametersTabErrorStatus =  client.apis.plans.getParametersTabErrorStatus({'planid':planid?planid:1});

        const responsesArray = await Promise.all([
            promiseGetProgramTabValidationStatus,
            promiseGetScheduleTabValidationStatus,
            promiseGetConstraintTabValidationStatus,
            promiseGetAllocatedTabErrorStatus,
            promiseGetMoveConstraintsTabErrorStatus,
            promiseGetUpgradePathsTabErrorStatus,
            promiseGetAgeMileageTabErrorStatus,
            promiseGetAdditionalCostTabErrorStatus,
            promiseGetMaxDeletesTabErrorStatus,
            promiseGetAddsInvalidPoolsTabErrorStatus,
            promiseGetMileageDefaultTabErrorStatus,
            promiseGetSalvageRateTabErrorStatus,
            promiseMileageTableTabErrorStatus,
            promiseManualVinDeletesTabErrorStatus,
            promiseMoveCostResponse,
            promiseGetTargetZonePoolTabErrorStatus,
            promiseGetDeprateTabErrorStatus,
            promiseGetDiversionsTabErrorStatus,
            promiseGetParametersTabErrorStatus
        ]);

        const [
            programTabStatusResponse,
            scheduleTabStatusResponse,
            constraintTabStatusResponse,
            AllocatedTabStatusResponse,
            MoveConstraintsTabStatusResponse,
            UpgradePathsTabStatusResponse,
            AgeMileageTabStatusResponse,
            AdditionalCostTabStatusResponse,
            MaxDeletesTabStatusResponse,
            AddsInvalidPoolsTabStatusResponse,
            MileageDefaultTabStatusResponse,
            SalvageRateTabStatusResponse,
            MileageTableTabStatusResponse,
            ManualVinDeletesTabStatusResponse,
            MoveCostTabStatusResponse,
            TargetZonePoolTabStatusResponse,
            DeprateTabStatusResponse,
            DiversionsTabStatusResponse,
            ParametersTabStatusResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "programsGrid": programTabStatusResponse !=undefined ? programTabStatusResponse : true,
            "schedulesGrid": scheduleTabStatusResponse !=undefined ? scheduleTabStatusResponse : true,
            "constraintsGrid": constraintTabStatusResponse !=undefined ? constraintTabStatusResponse : true,
            "allocatedGrid": AllocatedTabStatusResponse !=undefined ? AllocatedTabStatusResponse : true,
            "moveconstraintsGrid": MoveConstraintsTabStatusResponse !=undefined ? MoveConstraintsTabStatusResponse : true,
            "upgradepathsGrid": UpgradePathsTabStatusResponse !=undefined ? UpgradePathsTabStatusResponse : true,
            "agemileageGrid": AgeMileageTabStatusResponse !=undefined ? AgeMileageTabStatusResponse : true,
            "additionalcostGrid": AdditionalCostTabStatusResponse !=undefined ? AdditionalCostTabStatusResponse : true,
            "maxdeleteGrid": MaxDeletesTabStatusResponse !=undefined ? MaxDeletesTabStatusResponse : true,
            "addsinvalidpoolsGrid": AddsInvalidPoolsTabStatusResponse !=undefined ? AddsInvalidPoolsTabStatusResponse : true,
            "mileagedefaultGrid": MileageDefaultTabStatusResponse !=undefined ? MileageDefaultTabStatusResponse : true,
            "salvagerateGrid": SalvageRateTabStatusResponse !=undefined ? SalvageRateTabStatusResponse : true,
            "targetzonepoolGrid": TargetZonePoolTabStatusResponse !=undefined ? TargetZonePoolTabStatusResponse : true,
            "deprateGrid": DeprateTabStatusResponse !=undefined ? DeprateTabStatusResponse : true,
            "diversionsGrid": DiversionsTabStatusResponse !=undefined ? DiversionsTabStatusResponse : true,
            "mileagetableGrid": MileageTableTabStatusResponse !=undefined ? MileageTableTabStatusResponse : true,
            "manualvindeletesGrid": ManualVinDeletesTabStatusResponse !=undefined ? ManualVinDeletesTabStatusResponse : true,
            "movecostGrid": MoveCostTabStatusResponse !=undefined ? MoveCostTabStatusResponse : true,
            "parametersGrid": ParametersTabStatusResponse !=undefined ? ParametersTabStatusResponse : true,
        }) 
    }
    catch (error) {
       console.error(error);
       throw 'Unable to fetch the error status for all input tabs, Please try again!!';
   }
   }
   
   export async function getAllInputTabsValidationStatus(planid,transactionList){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
        const promiseGetProgramsTabValidationStatus =  client.apis.plans.validateProgram({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetSchedulesTabValidationStatus =  client.apis.plans.validateSchedule({"planid":planid?planid:1},{requestBody: { "updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetAllocatedTabValidationStatus = client.apis.plans.validateAllocated({ "planid": planid ? planid : 1 }, {requestBody: {"updatedBy": userEmail,"transactions": minifyTransaction(transactionList)}});
        const promiseGetMoveConstraintsTabValidationStatus = client.apis.plans.validateMoveConstraints({"planid": planid ? planid : 1}, {requestBody: {"updatedBy": userEmail,"transactions": minifyTransaction(transactionList)}});
        const promiseGetUpgradePathsTabValidationStatus = client.apis.plans.validateUpgradePaths({"planid": planid ? planid : 1}, {requestBody: {"updatedBy": userEmail,"transactions": minifyTransaction(transactionList)}});
        const promiseGetAdditionalCostTabValidationStatus = client.apis.plans.validateAdditionalCost({"planid": planid ? planid : 1}, {requestBody: {"updatedBy": userEmail,"transactions": minifyTransaction(transactionList)}});
        const promiseGetMaxDeletesTabValidationStatus = client.apis.plans.validateMaxDeletes({"planid": planid ? planid : 1}, {requestBody: {"updatedBy": userEmail,"transactions": minifyTransaction(transactionList)}});
        const promiseGetAddsInvalidPoolsTabValidationStatus =  client.apis.plans.validateAddsInvalidPools({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseMileageDefaultTabValidationStatus =  client.apis.plans.validateMileageDefault({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetSalvageRateTabValidationStatus =  client.apis.plans.validateSalvageRate({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetAgeMileageTabValidationStatus =  client.apis.plans.validateAgeMileage({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetMileageTableTabValidationStatus = client.apis.plans.validateMileageTable({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}}); 
        const promiseGetManualVinDeletesTabValidationStatus = client.apis.plans.validateManualVinDeletes({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}}); 
        const promiseGetTargetZonePoolTabValidationStatus = client.apis.plans.validateTargetZonePool({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetDeprateTabValidationStatus = client.apis.plans.validateDepRate({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetDiversionsTabValidationStatus = client.apis.plans.validateDiversions({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetMoveCostTabValidationStatus = client.apis.plans.validateMoveCost({"planid":1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});//Here planid is always 1 because for now move cost tab is only in main inputs
        const promiseGetParametersTabValidationStatus = client.apis.plans.validateParameters({ "planid": planid ? planid : 1 }, {requestBody: {"updatedBy": userEmail,"transactions": minifyTransaction(transactionList)}}); 
        const updateConstraintsResponse = await client.apis.plans.updateConstraintTableOnUpload({"planid":planid ? planid : 1 });
        const promiseGetConstraintsTabValidationStatus =  client.apis.plans.validateConstraints({"planid":planid?planid:1},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        
        const responsesArray = await Promise.all([
            promiseGetProgramsTabValidationStatus,
            promiseGetSchedulesTabValidationStatus,
            promiseGetConstraintsTabValidationStatus,
            promiseGetAllocatedTabValidationStatus,
            promiseGetMoveConstraintsTabValidationStatus,
            promiseGetUpgradePathsTabValidationStatus,
            promiseGetAdditionalCostTabValidationStatus,
            promiseGetMaxDeletesTabValidationStatus,
            promiseGetAddsInvalidPoolsTabValidationStatus,
            promiseMileageDefaultTabValidationStatus,
            promiseGetSalvageRateTabValidationStatus,
            promiseGetAgeMileageTabValidationStatus,
            promiseGetMileageTableTabValidationStatus,
            promiseGetManualVinDeletesTabValidationStatus,
            promiseGetTargetZonePoolTabValidationStatus,
            promiseGetDeprateTabValidationStatus,
            promiseGetDiversionsTabValidationStatus,
            promiseGetMoveCostTabValidationStatus,
            promiseGetParametersTabValidationStatus
        ]);

        const [
            programsValidationResponse,
            schedulesValidationResponse,
            constraintsValidationResponse,
            allocatedTabValidationResponse,
            moveConstraintsTabValidationResponse,
            upgradePathsTabValidationResponse,
            additionalCostTabValidationResponse,
            maxDeletesTabValidationResponse,
            addsinvalidpoolsGridTabValidationResponse,
            mileagedefaultGridTabValidationResponse,
            salvagerateGridTabValidationResponse,
            agemileageGridTabValidationResponse,
            mileagetableGridTabValidationResponse,
            manualVinDeletesGridTabValidationResponse,
            targetzonepoolGridTabValidationResponse,
            deprateGridTabValidationResponse,
            diversionsGridTabValidationResponse,
            moveCostGridTabValidationResponse,
            parametersTabValidationResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "programsGrid": programsValidationResponse ? programsValidationResponse.rows[0].validation_result : true,
            "schedulesGrid": schedulesValidationResponse ? schedulesValidationResponse.rows[0].validation_result : true,
            "constraintsGrid": constraintsValidationResponse && updateConstraintsResponse.obj && updateConstraintsResponse.obj.statusCode !== '500' ? constraintsValidationResponse.rows[0].validation_result : true,
            "allocatedGrid": allocatedTabValidationResponse ? allocatedTabValidationResponse.rows[0].validation_result : true,
            "moveconstraintsGrid": moveConstraintsTabValidationResponse ? moveConstraintsTabValidationResponse.rows[0].validation_result : true,
            "upgradepathsGrid": upgradePathsTabValidationResponse ? upgradePathsTabValidationResponse.rows[0].validation_result : true,
            "additionalcostGrid": additionalCostTabValidationResponse ? additionalCostTabValidationResponse.rows[0].validation_result : true,
            "maxdeleteGrid": maxDeletesTabValidationResponse ? maxDeletesTabValidationResponse.rows[0].validation_result : true,
            "addsinvalidpoolsGrid": addsinvalidpoolsGridTabValidationResponse ? addsinvalidpoolsGridTabValidationResponse.rows[0].validation_result : true,
            "mileagedefaultGrid":  mileagedefaultGridTabValidationResponse ? mileagedefaultGridTabValidationResponse.rows[0].validation_result : true,
            "salvagerateGrid":  salvagerateGridTabValidationResponse ? salvagerateGridTabValidationResponse.rows[0].validation_result : true,
            "agemileageGrid": agemileageGridTabValidationResponse ? agemileageGridTabValidationResponse.rows[0].validation_result : true,
            "mileagetableGrid": mileagetableGridTabValidationResponse ? mileagetableGridTabValidationResponse.rows[0].validation_result : true,
            "manualvindeletesGrid": manualVinDeletesGridTabValidationResponse ? manualVinDeletesGridTabValidationResponse.rows[0].validation_result : true,
            "targetzonepoolGrid": targetzonepoolGridTabValidationResponse ? targetzonepoolGridTabValidationResponse.rows[0].validation_result : true,
            "deprateGrid": deprateGridTabValidationResponse ? deprateGridTabValidationResponse.rows[0].validation_result : true,
            "diversionsGrid": diversionsGridTabValidationResponse ? diversionsGridTabValidationResponse.rows[0].validation_result : true,
            "movecostGrid": moveCostGridTabValidationResponse ? moveCostGridTabValidationResponse.rows[0].validation_result : true,
            "parametersGrid": parametersTabValidationResponse ? parametersTabValidationResponse.rows[0].validation_result : true,
        }) 
    }
    catch (error) {
       console.error(error);
       throw 'Unable to validate all input tabs, Please try again!!';
   }
   }