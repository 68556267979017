import React, { useState, useEffect, useContext } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import './SalesAdjustmentView.css';
import '../../../containers/accordionview/SalesAndFinanceAccordionContainer.css';
import { Button, Divider } from '@material-ui/core';
import ReadOnlySectionRowsComponent from './ReadOnlySectionRowsComponent';
import ReadOnlySectionSummaryComponent from './ReadOnlySectionSummaryComponent';
import { ScrollSyncPane } from 'react-scroll-sync';
import { useDispatch, useSelector } from 'react-redux';
import { enableActivityEdit, saveSalesAdjustmentChanges, setSalesAccordianClicked, setSalesZoneAccordianClicked } from '../../../store/salesandfinance/SalesAndFinance.Actions';
import { ADJUSTMENT_LABLE_ADJUSTMENT_VARIANCE, ADJUSTMENT_LABLE_ORIGINAL_VALUE, ADJUSTMENT_LABLE_REQUESTED_ADJUSTMENT, ADJUSTMENT_LABLE_REQUESTED_VALUE, IS_ADJUSTMENT_COMPLETE_ARRAY, PLAN_STATUS_ADJUSTMENT_COMPLETE, SalesTabActivity } from '../../../Constants';
import EditableSectionRowsComponent from './EditableSectionRowsComponent';
import EditIcon from "../../../assets/images/icon_edit.svg";


const SalesAdjustmentView = ({accordionData, plansDataById,zones}) => {
    const dispatch=useDispatch();
    // console.log("plansDataById==>",plansDataById);
    let zoneMap=createZoneMap(zones);

    let transactionMap = useSelector((state) => {
        return state.rootSalesAndFinanceReducer.salesAndFinanceReducer.transactionMap;
    });

    const updateActivityLevelAdjustmentRow=(activityType,zone)=>{
        dispatch(enableActivityEdit(activityType,zone));
    }

    const handleSave=(activityType,zone)=>{
        dispatch(saveSalesAdjustmentChanges(activityType,zone,transactionMap,plansDataById,accordionData));
    }


    return <div className="alignAllBuSalesAndFinance">
        <div className='salesandfinancesort'>
            <div className="salesAndFinanceLabelAlignment">
                <label className="salesandfinancesorttext">Type/Zone</label>
            </div>
            <ScrollSyncPane group="horizontal">
                <div className="applySalesAndFinanceMonthMargin">
                {
                    getColumnTitle(accordionData['MONTH'],accordionData['YEAR'])
                        .map(title=>{
                            return <div className="salesAndFinanceAllBuMinWidth" key={title}>
                                    <span className="adjustmentsorttext">{title}</span>
                                </div>
                        })
                }
                </div>
            </ScrollSyncPane>
        </div>
        {
            Object.keys(SalesTabActivity).filter(activity=>SalesTabActivity[activity].show==="true").map(activity=>{
                return <div className="newadjustmentaccordions" key={activity}>
                    <Accordion
                        expanded={accordionData[activity].expanded}
                        onChange={(e) => {
                            dispatch(setSalesAccordianClicked(activity, !accordionData[activity].expanded));
                        }}
                    >
                        <ReadOnlySectionSummaryComponent activityNode={accordionData[activity]} plansDataById={plansDataById} title={SalesTabActivity[activity].label} topLevel={true}/>
                        <div className="salesOverallAccordiondetails">
                            <AccordionDetails  className='salesOverallAccordiondetailsTitle'>
                                <div className="alignTitleInSales">
                                    <div className="salesBuTitle">
                                        <span className="salesBuContent">{ADJUSTMENT_LABLE_REQUESTED_ADJUSTMENT}</span>
                                        {IS_ADJUSTMENT_COMPLETE_ARRAY.includes(plansDataById[0].status.toLowerCase())?<span className="salesBuContent">{ADJUSTMENT_LABLE_ADJUSTMENT_VARIANCE}</span>:''}
                                        <span className="salesBuContent">{ADJUSTMENT_LABLE_REQUESTED_VALUE}</span>
                                        {IS_ADJUSTMENT_COMPLETE_ARRAY.includes(plansDataById[0].status.toLowerCase())?<span className="salesBuContent">{ADJUSTMENT_LABLE_ORIGINAL_VALUE}</span>:''}
                                    </div>
                                </div>
                            </AccordionDetails>

                            
                            {accordionData[activity].isEditable?
                                <AccordionDetails  className='salesAdjustmentOverallAccordionContent'>
                                    <ScrollSyncPane group="horizontal">
                                        <div className="salesadjustmentcontent">
                                                <EditableSectionRowsComponent activityNode={accordionData[activity]} plansDataById={plansDataById} topLevel={false} activity={activity} zone="ALL"/>
                                        </div>
                                    </ScrollSyncPane>
                                    <div className='editiconmargin'>
                                        {accordionData[activity].isEditEnabled?
                                            <div className="salesAdjustmentsSaveBtn">
                                                <Button className={'adjustmentsSavetext'} onClick={(e)=>handleSave(activity,"ALL", accordionData[activity])}>Save</Button>
                                            </div>
                                        :
                                            <Button onClick={(e) => updateActivityLevelAdjustmentRow(activity,"ALL")}  disabled={accordionData[activity].isEditDisabled ||plansDataById[0].status.toLowerCase()=='sales adjustments ready' || plansDataById[0].status.toLowerCase()=='vin dep process ready'} className={(accordionData[activity].isEditDisabled || plansDataById[0].status.toLowerCase()=='sales adjustments ready' || plansDataById[0].status.toLowerCase()=='vin dep process ready')?'salesActivityediticonDisabled':''}>
                                                <img src={EditIcon} className='salesActivityediticon'></img>
                                            </Button>
                                        }
                                    </div>
                                </AccordionDetails>
                            :
                                <AccordionDetails  className='salesAdjustmentOverallAccordionContent'>
                                    <ScrollSyncPane group="horizontal">
                                        <div className="salesadjustmentcontent">
                                            <ReadOnlySectionRowsComponent activityNode={accordionData[activity]} plansDataById={plansDataById} topLevel={false}/>
                                        </div>
                                    </ScrollSyncPane>
                                </AccordionDetails>
                            }
                            
                        </div>
                        <Divider className="salesOverallDivider" />
                        <div className="salesAccordiondetails">
                            {
                                Object.keys(accordionData[activity].zones).filter(zoneCode=>zoneCode!=='ALL').map(zoneKey=>{
                                    return <Accordion key={zoneKey}
                                                expanded={accordionData[activity].zones[zoneKey].expanded}
                                                onChange={(e) => {
                                                    dispatch(setSalesZoneAccordianClicked(activity,zoneKey, !accordionData[activity].zones[zoneKey].expanded));
                                                }}
                                            >
                                                <ReadOnlySectionSummaryComponent activityNode={accordionData[activity].zones[zoneKey]} plansDataById={plansDataById} title={zoneMap[zoneKey]}  topLevel={false}/>
                                                <div className="salesOverallAccordiondetails">
                                                    <AccordionDetails  className='salesOverallInnerAccordiondetailsTitle'>
                                                        <div className="alignTitleInSales">
                                                            <div className="salesBuTitle">
                                                                <span className="salesBuContent">{ADJUSTMENT_LABLE_REQUESTED_ADJUSTMENT}</span>
                                                                {plansDataById[0].status.toLowerCase()===PLAN_STATUS_ADJUSTMENT_COMPLETE?<span className="salesBuContent">{ADJUSTMENT_LABLE_ADJUSTMENT_VARIANCE}</span>:''}
                                                                <span className="salesBuContent">{ADJUSTMENT_LABLE_REQUESTED_VALUE}</span>
                                                                {plansDataById[0].status.toLowerCase()===PLAN_STATUS_ADJUSTMENT_COMPLETE?<span className="salesBuContent">{ADJUSTMENT_LABLE_ORIGINAL_VALUE}</span>:''}
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                    {accordionData[activity].zones[zoneKey].isEditable?
                                                        <AccordionDetails  className='salesAdjustmentOverallAccordionContent'>
                                                            <ScrollSyncPane group="horizontal">
                                                                <div className="salesadjustmentcontent">
                                                                    <EditableSectionRowsComponent activityNode={accordionData[activity].zones[zoneKey]} plansDataById={plansDataById} topLevel={false} activity={activity} zone={zoneKey}/>
                                                                </div>
                                                            </ScrollSyncPane>
                                                            <div className='editiconmargin'>
                                                                {accordionData[activity].zones[zoneKey].isEditEnabled?
                                                                    <div className="salesAdjustmentsSaveBtn">
                                                                        <Button className={'adjustmentsSavetext'} onClick={(e)=>handleSave(activity,zoneKey, accordionData[activity])}>Save</Button>
                                                                    </div>
                                                                :
                                                                    <Button onClick={(e) => updateActivityLevelAdjustmentRow(activity,zoneKey)} disabled={accordionData[activity].zones[zoneKey].isEditDisabled || plansDataById[0].status.toLowerCase()=='sales adjustments ready' || plansDataById[0].status.toLowerCase()=='vin dep process ready'}  className={(accordionData[activity].zones[zoneKey].isEditDisabled || plansDataById[0].status.toLowerCase()=='sales adjustments ready' || plansDataById[0].status.toLowerCase()=='vin dep process ready')?'salesActivityediticonDisabled':''}>
                                                                        <img src={EditIcon} className='salesActivityediticon'></img>
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </AccordionDetails>
                                                    :
                                                        <AccordionDetails  className='salesAdjustmentOverallAccordionContent'>
                                                            <ScrollSyncPane group="horizontal">
                                                                <div className="salesadjustmentcontent">
                                                                    <ReadOnlySectionRowsComponent activityNode={accordionData[activity].zones[zoneKey]} plansDataById={plansDataById} />
                                                                </div>
                                                            </ScrollSyncPane>
                                                        </AccordionDetails>
                                                    }
                                                </div>
                                                <Divider className="salesOverallDivider" />
                                            </Accordion>
                                })
                            }
                        </div>
                    </Accordion>
                </div>
            })
        }
    </div>
}

function getColumnTitle(monthTitleArray,yearTitleArray){
    let headingArray=[];
    for(let i=0;i<monthTitleArray.length;i++){
        headingArray.push(monthTitleArray[i]+"'"+yearTitleArray[i]);
    }
    return headingArray;
}

function createZoneMap(zones){
    let zoneMap={};
    if(zones){
        zones.forEach(zone=>zoneMap[zone.code]=zone.name);
    }
    return zoneMap;
}

export default SalesAdjustmentView;