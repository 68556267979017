import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReadOnlyRowComponent from "./ReadOnlyRowComponent";
import { checkIfFreezMonth } from "../../../utils/AdjustmentsUtils";

const SectionRowsComponent = (props) => {
    const { activityNode,accordionSummary,plansDataById} = props;
    
    if (activityNode) {
        return <ReadOnlyRowComponent key={activityNode.activity_node} activityNode={activityNode} accordionSummary={accordionSummary} plansDataById={plansDataById} />
    } else {
        return (
            <div key={activityNode.activity_node}></div>
        )
    }
};
export default SectionRowsComponent