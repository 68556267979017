import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import PlanDEtailsStyle from './PlanInputDetails.css';
import { TextFieldInput } from '../../core-components/Input/Input';
import { NttDropdown } from '../../core-components/Dropdown/Dropdown';
import MenuItem from "@material-ui/core/MenuItem";
import InputPage from '../../containers/inputs/InputsPage';
import { saveDescription, saveZoneRf, savePoolRf, getPlanBySelectedPlanId, getZoneRfData, getPoolRfData } from "../../store/topbar/AllPlans.Actions";
import { Tooltip, makeStyles } from '@material-ui/core';
import MainPlanInputsPage from '../../containers/new_inputs/MainPlanInputsPage';
import { escapeHtml, unEscapeHtml } from '../../utils/utils';
import DialogBox from '../dialog_component/CustomDialog';
const CUEMSG_HIT_ENTER_TO_SAVE = 'Hit Enter to save.';
const CUEMSG_ENTER_DESCRIPTION = 'Nothing to save.';
export default function PlanInputDetails(props) {
    const {
        selectedRowData,
        selectedPlanId,
        zoneRfData, poolRfData,
        isMinimized,setIsMinimized
    } = props;
    const location = useLocation();
    const [descriptionvalue, setDescriptionValue] = useState((selectedRowData.description && selectedRowData.description !== "") ? unEscapeHtml(selectedRowData.description) : "");
    const [zonerfname, setZoneRfName] = useState((selectedRowData.zonerf !== undefined && selectedRowData.zonerf != null) ? selectedRowData.zonerf : '')
    const [poolrfname, setPoolRfName] = useState((selectedRowData.poolrf !== undefined && selectedRowData.poolrf != null) ? selectedRowData.poolrf : '');
    const [visible, setVisible] = useState(false);
    const [delay, setDelay] = useState(800);
    const [saveDescriptionMsg, setSaveDescription] = useState(false);
    const [saveZoneMsg, setSaveZone] = useState(false);
    const [savePoolMsg, setSavePool] = useState(false);
    const [disableInput, setDisableInput] = useState(false);
    const dispatch = useDispatch();
    const [descriptionChanging, setDescriptionChanging] = useState(false);
    const [cueMessage, setCueMessage] = useState(CUEMSG_HIT_ENTER_TO_SAVE);
    const [saveUpdatedResponse, setSaveUpdatedResponse] = useState(false);

    var saveResponse = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.saveResponse;
    });

    let ZoneRf = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ZoneRf;
    });

    let poolRf = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.poolRf;
    });

    useEffect(() => {
        if (saveResponse !== undefined && saveResponse == 'success' && (saveDescriptionMsg == true || saveZoneMsg == true || savePoolMsg == true)) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
                setDisableInput(false);
                setSaveDescription(false);
                setSaveZone(false);
                setSavePool(false);
            }, delay);
            setSaveUpdatedResponse(!saveUpdatedResponse);
        }

    }, [saveResponse, saveDescriptionMsg, saveZoneMsg, savePoolMsg])

    const onDescriptionChanged = (e) => {
        setDescriptionValue(e.target.value);
        setDescriptionChanging(true);
    }

    const handleChangeZoneRevenue = (name) => {
    }

    const handleChangePoolRevenue = (name) => {
    }

    const onZoneOptionselect = (name, desc) => {
        setSaveZone(true);
        setSavePool(false);
        setSaveDescription(false);
        setDescriptionChanging(false);
        dispatch(saveZoneRf(selectedPlanId, name));
    }

    const onPoolOptionselect = (name, desc) => {
        setSavePool(true);
        setSaveDescription(false);
        setSaveZone(false);
        setDescriptionChanging(false);
        dispatch(savePoolRf(selectedPlanId, name));
    }

    const handleDescriptionSave = (e) => {
            if (descriptionvalue !== null && descriptionvalue.trim() !== '') {
                setSaveDescription(true);
                setSaveZone(false);
                setSavePool(false);
                dispatch(saveDescription(selectedPlanId, escapeHtml(descriptionvalue)));
                setDescriptionChanging(false);
            }
            setIsMinimized(true);//Closes the popup
            e.preventDefault();
    }

    const handleCancel = (e) => {
        if (descriptionvalue !== null && descriptionvalue.trim() !== '') {
            setSaveDescription(false);
            setSaveZone(false);
            setSavePool(false);
            setDescriptionValue(unEscapeHtml(selectedRowData.description));
            setDescriptionChanging(false);
        }
        setIsMinimized(true);//Closes the popup
    }

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));

    const fetchDialogContent = () => {
        return (
            <div className="flexContainer flexDirectionColumn">
                    <label className='detailslable'>Plan #{selectedPlanId}</label>
                    <label className='detailslable'>Description</label>
                    <TextFieldInput
                        type="text"
                        id="iddescription"
                        value={descriptionvalue}
                        variant="outlined"
                        defaultValue={unEscapeHtml(selectedRowData.description)}
                        onTextChanged={(e) => onDescriptionChanged(e)}
                        inputDisabled={disableInput}
                        className='descriptionNewStyle'
                        multiline
                    />
                    <label className='detailslable'>Zone Revenue Function</label>
                    <div className='rfstyles'>
                        <div className='zonename'>{ZoneRf}</div>
                        {zoneRfData && zoneRfData.map((value, index) => {
                            if (value.name === ZoneRf) {
                                return(<Tooltip title={value.description} placement='bottom' arrow>
                                <div className='revenuedesc'>{value.description}</div>
                                </Tooltip>)
                            }
                        })}
                    </div>
                    <label className='detailslable'>Pool Revenue Function</label>
                    <div className='rfstyles'>
                        <div className='zonename'>{poolRf}</div>
                        {poolRfData && poolRfData.map((value,index) => {
                            if (value.name === poolRf) {
                            return(<Tooltip title={value.description} placement='bottom' arrow>
                                <div className='revenuedesc'>{value.description}</div>
                                </Tooltip>)
                            }
                        })}        
                    </div>
            </div>
        );
        
    }

    return (
        <div className='plandetails'>
            {/* this is to toggle between old and new versions inputs page and will be removed later*/}
            {(JSON.parse(localStorage.getItem("newArchitecture"))) ? 
           <MainPlanInputsPage selectedPlanId={selectedPlanId} saveUpdatedResponse={saveUpdatedResponse} zoneRfData={zoneRfData} poolRfData={poolRfData}/> 
             :  <InputPage selectedPlanId={selectedPlanId} saveUpdatedResponse={saveUpdatedResponse} zoneRfData={zoneRfData} poolRfData={poolRfData}></InputPage>}
               {/* <InputPage selectedPlanId={selectedPlanId} saveUpdatedResponse={saveUpdatedResponse} zoneRfData={zoneRfData} poolRfData={poolRfData}></InputPage>  
            <MainPlanInputsPage selectedPlanId={selectedPlanId} saveUpdatedResponse={saveUpdatedResponse} zoneRfData={zoneRfData} poolRfData={poolRfData}/> */}
            <DialogBox classes={{ paper: useStyles().dialogPaper }}
                open={!isMinimized} handleClose={(e) => handleCancel()} handlePositiveBtnClick={(e) => handleDescriptionSave(e)} handleNegativeBtnClick={(e) => handleCancel()}
                dialogContent={fetchDialogContent()} title="Plan Details" positiveBtnLbl="Save" negativeBtnLbl="Cancel">
            </DialogBox>
        </div>
        
    )
}