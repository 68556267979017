import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useComponentWillMount } from '../utils';
import DeleteIcon from "../../../../assets/images/icon_trash.svg";
import EditIcon from "../../../../assets/images/icon_edit.svg";
import CopyIcon from "../../../../assets/images/icon_copy.svg";
import UndoIcon from "../../../../assets/images/icon_undo.svg";
import { addNewRow, copyNewRow, deleteRow, deleteRowEditMode, revertRow } from '../../../../store/MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions'
import DialogBox from '../../../Dialogcomponent/DialogComponent';

export default (props) => {
    const gridName =props.agGridReact.props.gridName;
    const editable=props.agGridReact.props.defaultColDef.editable;
    const multiGridUiProps = props.agGridReact.props.propsTest;
    let [editing, setEditing] = useState(false);
    let [edited, setEdited] = useState(false);
    let [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    let message = "Are you sure that you want to delete this row?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";
    let isDeleteClicked = false;
    let isEditCLicked = false;
   
   
    const GridReducer=useSelector((state)=>{
        return {...state.inputsMultiGridUIReducer}
      },shallowEqual)

    useEffect(()=>{
        GridReducer.transactions.map((transacData)=>{
            if(transacData.type!='add' && transacData.type!='copy'){
            if(props.data.ID==transacData.data.ID){
               setEdited(true)
            }  
        } 
        })
      },[GridReducer.transactions])


    // custom hook
    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            setDisabled(true);
        }
    });

    useEffect(() => {
        if (props.data.status === "ADD_ROW" && Object.keys(props.data).length <= 2) {
            startEditing();
        }
        props.api.addEventListener('cellValueChanged', onCellValueEditStarted);
        props.api.addEventListener('cellEditingStopped', onCellValueEditStopped);

        return () => {
            props.api.removeEventListener('cellValueChanged', onCellValueEditStarted);
            props.api.removeEventListener('cellEditingStopped', onCellValueEditStopped);
        };
    }, [editing, edited]);

    function onCellValueEditStarted(params) {
        if (props.node === params.node) {
            setEditing(true);
            setEdited(false);
            isEditCLicked = true;
        } else {
            setDisabled(true);
        }
    }

    function onCellValueEditStopped(params) {
        if (isDeleteClicked) {
            setEditing(true);
        }
        else if (props.node===params.node) {
            setEditing(false);
            if (JSON.stringify(params.data) !== JSON.stringify(params.node.data) || isEditCLicked) {
                setEdited(true);
            }
            // else 
            //     setEdited(false);
        } else {
            setDisabled(true);
        }
    }

    function startEditing() {
        let colkeyvalue="";
        if(gridName==='constraintsGrid'){
            colkeyvalue=props.api.getColumnDefs()[2].colId; //in case of parameter name column cannot be editable which is first column and also in constraints first column cannot be editable
        }else if(gridName==='parametersGrid' && props.data.status ==='ADD_ROW'){
            colkeyvalue=props.api.getColumnDefs()[0].colId; //edit the first column of the row
        }
        else{
            colkeyvalue=props.api.getColumnDefs()[1].colId; //edit the first column of the row     
        }
        if(editable){
            props.api.startEditingCell({
            rowIndex: props.node.rowIndex,
            colKey: colkeyvalue
        });
         }
    }

    function revertChanges() {
     props.api.stopEditing(true);
     dispatch(revertRow(gridName, props.node.rowIndex, props.data.ID, props.data, multiGridUiProps.gridColDef.validationFn,  multiGridUiProps.gridColDef.businessValidationFn));
     setEdited(false);
    }


function deleteThisRow() {
    if(editable){
    // if (editing)
    //     dispatch(deleteRowEditMode(gridName, props.data.ID));
    // else
        dispatch(deleteRow(gridName, props.data.ID));
   }
  }

  function copyRow() {
    if(editable){
     dispatch(copyNewRow(gridName, props.node.rowIndex + 1, props.node.data, multiGridUiProps.gridColDef.validationFn,  multiGridUiProps.gridColDef.businessValidationFn));
    }
   }
  function handleClickOpen() {
    if(editable && !props.data.required){
     setOpen(true);
     isDeleteClicked = true;
    }
  };

    function handleClose() {
        setOpen(false);
    };

    return (
        <div>
            <div>
                {editing
                    ? <div>
                        <img src={DeleteIcon} onClick={() => handleClickOpen()} className={props.data.required ? 'deleteIconBlur' : 'deleteIcon'} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        {!(gridName==="parametersGrid")?<img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>:""}
                        {(props.data.status === "ADD_ROW" || props.data.status === "COPY_ROW" || props.data.status === "ERROR_NEW_ROW" || props.data.status === "INVALID_NEW_ROW") ? <div></div> : 
                        <img src={UndoIcon} onClick={() => revertChanges()} style={{ paddingTop: '10px' }}></img>}

                    </div>
                    : <div>
                        <img src={DeleteIcon} onClick={() => handleClickOpen()} className={props.data.required  ? 'deleteIconBlur' : 'deleteIcon'} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        {!(gridName==="parametersGrid") ?<img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>:""}

                        {edited
                            ?
                            (props.data.status === "ADD_ROW" || props.data.status === "COPY_ROW" || props.data.status === "ERROR_NEW_ROW" || props.data.status === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={revertChanges} style={{ paddingTop: '10px' }}></img>
                            :
                            ((props.data.status === "ADD_ROW" || props.data.status === "COPY_ROW" || props.data.status === "ERROR_NEW_ROW" || props.data.status === "INVALID_NEW_ROW") ? <div></div> : <img src={EditIcon} onClick={startEditing} style={{ paddingTop: '10px' }}></img>)
                        }
                    </div>
                }
            </div>
            <div>
                <DialogBox open={open} handleClose={handleClose} handleYes={deleteThisRow}
                    dialogMessage={message} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DialogBox>
            </div>
        </div>
    )
}
