import React from 'react';
import './DeleteAllComponent.css'
import { Button, Tooltip } from '@material-ui/core';
import DeleteDialogBox from '../../components/Dialogcomponent/DialogComponent';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const DeleteAllComponent = (props) => {
    const { isButtonRequired, deleteAllRecords } = props

    const gridFilterViewStatus = useSelector((state) => {
        return { ...state.PaginationGridReducer.gridFilterView };
    },shallowEqual);

    const gridName = useSelector((state) => {
        return state.PaginationGridReducer.gridName
    })

    const gridFilterUserSelectedParams = useSelector((state) => {
        return { ...state.PaginationGridReducer.gridFilterUserSelectedParams };
    },shallowEqual);

    const gridErrorViewStatus = useSelector((state) => {
        return { ...state.PaginationGridReducer.gridErrorView };
    },shallowEqual);

    const gridSearchViewStatus = useSelector((state) => {
        return { ...state.PaginationGridReducer.gridSearchView };
    },shallowEqual)

    const gridUserSearchParams = useSelector((state) => {
        return { ...state.PaginationGridReducer.gridUserSearchParams };
    },shallowEqual);

    const [openDialog, setOpenDialog] = useState(false);

    const closeDeleteRecordsDialog = () => {
        setOpenDialog(false);
    }

    const openDeleteRecordsDialog = () => {
        setOpenDialog(true);
    }

    const createDeleteParams = () => {
        // This function is used to return parameters for deleteing all the ecords based on user operation
        // Here "operation" can be either 'filter','search','errordata','deleteall'
        // if user is in filterview then operation will be 'filter'
        // if user is in searchview then operation will be 'search'
        // if user is in errorview then operation will be 'errordata'
        // if user is wants to delete entire griddata then operation will be 'deleteall'
        // Here "searchInColumns" will be the array of column name where the search text needs to be queried
        // Here "filterparams" is the user selected checkFilterParams

        return {
            "operation": gridFilterViewStatus[gridName] ? 'filter' : gridErrorViewStatus[gridName] ? 'errordata' : gridSearchViewStatus[gridName] ? 'search' : 'deleteall',
            "filterparams": gridFilterUserSelectedParams[gridName],
            "searchparams":gridUserSearchParams[gridName],
        }
    }

    const deleteAllSuccess=()=>{
        deleteAllRecords(createDeleteParams(),gridFilterViewStatus[gridName],gridErrorViewStatus[gridName],gridSearchViewStatus[gridName]);
        setOpenDialog(false);
    }

    const getMessage=()=>{
        if(gridFilterViewStatus[gridName]){
            return 'Do you want to Delete All the Filtered Records?';
        } else if(gridErrorViewStatus[gridName]){
            return 'Do you want to Delete All the Error Records?';
        } else if(gridSearchViewStatus[gridName]){
            return 'Do you want to Delete All the Searched Records?';
        } else {
            return 'Do you want to Delete All the Pages?';
        }
    }


    return (
        <>
            {isButtonRequired ?
                <>
                    <div className='deleteAllButton'>
                        <Tooltip title="Delete data of all visible pages" placement="bottom" >
                            <Button onClick={() => openDeleteRecordsDialog()} >
                                Delete All
                            </Button>
                        </Tooltip>
                    </div>
                    <DeleteDialogBox open={openDialog} handleClose={closeDeleteRecordsDialog} handleYes={deleteAllSuccess}
                        dialogMessage={getMessage()} title={'Delete All Pages'} action={"Delete"}
                        canceltext={"Cancel"}>
                    </DeleteDialogBox>
                </> : ''
            }
        </>
    );
};

export default DeleteAllComponent;