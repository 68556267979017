import { combineReducers } from 'redux';
import upgradePathReducer from './UpgradePaths.Reducer';
import {createNamedWrapperReducer} from '../util';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import upgradePathsColumnDefReducer from './UpgradePaths.ColumnDef.Reducer.js';

const rootUpgradePathsReducer = combineReducers({
    upgradePathReducer,
    upgradePathGridReducer: createNamedWrapperReducer(multiGridUIReducer, "upgradepathsgrid"),
    upgradePathsColumnDefReducer
})

export default rootUpgradePathsReducer;