import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import icon_allplans from '../../assets/images/icon_AllPlans.png';
import icon_Dashboard from '../../assets/images/icon_Dashboard.png';
import icon_inputs from '../../assets/images/inputs.png';
import icon_validations from '../../assets/images/icon_Validations.png';
import icon_forecasts from '../../assets/images/icon_Forecasts.png';
import icon_reports from '../../assets/images/Icon_Reports.png';
import icon_adds from '../../assets/images/icon_VehicleAdds.png';
import icon_deletes from '../../assets/images/icon_VehicleDeletes.png';
import icon_moves from '../../assets/images/icon_moves.svg';
import icons_rf_icon from '../../assets/images/icons_rf_icon.svg';
import icons_admin_icon from '../../assets/images/icons-admin-icon.svg';
import salesfinance from '../../assets/images/sales-finance-1.svg';
import { NavLink, useParams, useRouteMatch,useLocation, useHistory} from 'react-router-dom';
import './SideBar.css';
import { resetGridState } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';

const SideBar = ({menuMinimized}) => {
  const plan = useRouteMatch('/dashboard');
  const allplans = useRouteMatch('/');
  const newplan = useRouteMatch('/newplan');
  const { planid } = useParams();
  const location = useLocation();
  const [planId, setPlanId] = useState();
  const [showAdjustments, setShowAdjustments] = useState(false);
  const [showAdmin,setShowAdmin] = useState(false);
  var url = window.location.href.split("/");
  let initialurl = window.location.href.split("/").pop();
  const history = useHistory();
  const dispatch=useDispatch()
  let selectedplanid = '';
  if(location.state==undefined&&location.pathname!==undefined){
    if(location.pathname.split('/')[1]=='plan'){//Ignoring first element that contains empty String
      selectedplanid=location.pathname.split('/')[2];
    }
  }else{
    selectedplanid=location.state.selectedplan;
  }

  var allPlansTableData = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.allPlansTableData;
  });

  var allPlansData = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.allplans;
  });

  var planOptimizedOutput = useSelector((state) => {
    return state.rootAdjustmentsReducer.adjustmentsReducer.planOptimizationOutputs;
  });

  var userRoleName = useSelector((reduxstate)=>{
    return reduxstate.rootAllPlansReducer.allPlansReducer.userRoleName;
  })

  useEffect(()=>{
    if(selectedplanid!=="" && selectedplanid!==undefined){
      setPlanId(selectedplanid);
    }

    if(planOptimizedOutput && planOptimizedOutput.length > 0) {
      setShowAdjustments(true);
    }
    else {
      setShowAdjustments(false);
    }
  },[selectedplanid, planOptimizedOutput])
  
  useEffect(()=>{
    if(userRoleName && userRoleName.toLowerCase()==='admin'){
      setShowAdmin(true);
    }else{
      setShowAdmin(false);
    }
  },[userRoleName])

  const onAdjustmentsClicked = () => {
      let url = '/plan/' + selectedplanid + '/adjustments';
            history.push({
              pathname: url,
              state: {  // location state
                  selectedplan: selectedplanid,
              },
          });
  }

  const onSalesAndFinanceClicked=()=>{
    let url = '/plan/' + selectedplanid + '/salesandfinance';
            history.push({
              pathname: url,
              state: {  // location state
                  selectedplan: selectedplanid,
              },
          });
  }

  const onMainInputsClicked = () => {
    if (url[4] != 'maininputs'&& url[4] != 'newmaininputs') {
      dispatch(resetGridState(true));
    }
  }

  const onRevenueFunctionClicked = () => {
    if (url[4] != 'revenuefunction') {
      dispatch(resetGridState(true));
    }
  }

  const onAllPlansClicked=()=>{
    localStorage.setItem("newArchitecture",false)
  }

  return (
    <div id="sidebar" className='sidebar'>
    <ul className={!menuMinimized?"navList":" navList-sidebarminimized"}>
       {(initialurl=='' || url[4]=='maininputs' || url[4]=='admin' || url[4]=='revenuefunction'|| url[4]=='newmaininputs' || url[4]=='salesandfinance') ?
        <div className='innerDiv'>
          <li className="liststyle">
            <NavLink onClick={onAllPlansClicked} to={{ pathname: `/`,state:allPlansTableData }} className={(initialurl==''&&url[4]!=='maininputs')?"activeselected":""} activeClassName="active" exact >
              <img src={icon_allplans} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">All Plans</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink onClick={onMainInputsClicked} to={{ pathname: `/${JSON.parse(localStorage.getItem("newArchitecture"))?'newmaininputs':'maininputs'}` }} className={url[4]=='maininputs'?"activeselected":""}  activeClassName="active" exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Main Inputs</label>:''}</NavLink>
          </li>
          {showAdmin ? <li className="liststyle">
            <NavLink to={{ pathname: `/admin` }} className={url[4]=='admin'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_admin_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Admin</label>:''}</NavLink>
          </li> :<span></span>}
          <li className="liststyle">
            <NavLink onClick={onRevenueFunctionClicked} to={{ pathname: `/revenuefunction` }} className={url[4]=='revenuefunction'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_rf_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Rev.Function</label>:''}</NavLink>
          </li>
          </div>:""}
          {(url[6]=='planinputs'||url[6]=='newplaninputs') ?
        <div className='innerDiv'>
          <li className="liststyle">
            <NavLink onClick={onAllPlansClicked} to={{ pathname: `/`,state:allPlansTableData }} className={initialurl==''?"activeselected":""} activeClassName="active" exact >
              <img src={icon_allplans} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">All Plans</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: `/plan/${planId}/${JSON.parse(localStorage.getItem("newArchitecture"))?'newplaninputs':'planinputs'}` }} className={url[6]=='planinputs'?"activeselected":""}  activeClassName="active" exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Plan Inputs</label>:''}</NavLink>
          </li>
          {showAdjustments ? <li className="liststyle">
            <NavLink onClick={onAdjustmentsClicked} to={{ pathname: `/plan/${planId}/adjustments` }} className={url[6]=='adjustments'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_moves} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Adjustments</label>:''}</NavLink>
          </li> : <span></span>}
          {showAdjustments ? <li className="liststyle">
            <NavLink onClick={onSalesAndFinanceClicked} to={{ pathname: `/plan/${planId}/salesandfinance` }} className={url[4]=='salesandfinance'?"activeselected":""}  activeClassName="active" exact>
              <img src={salesfinance} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Sales & Finance</label>:''}</NavLink>
          </li> : <span></span>}
          {showAdmin ? <li className="liststyle">
            <NavLink to={{ pathname: `/admin` }} className={url[4]=='admin'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_admin_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Admin</label>:''}</NavLink>
          </li> : <span></span>}
          <li className="liststyle">
            <NavLink onClick={onRevenueFunctionClicked} to={{ pathname: `/revenuefunction` }} className={url[4]=='revenuefunction'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_rf_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Rev.Function</label>:''}</NavLink>
          </li>
          </div>:""}

          {(url[6]=='adjustments') ?
        <div className='innerDiv'>
          <li className="liststyle">
            <NavLink to={{ pathname: `/`,state:allPlansTableData }} className={initialurl==''?"activeselected":""} activeClassName="active" exact >
              <img src={icon_allplans} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">All Plans</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: `/plan/${planId}/planinputs` }} className={url[6]=='planinputs'?"activeselected":""}  activeClassName="active" exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Plan Inputs</label>:''}</NavLink>
          </li>
          {showAdjustments ? <li className="liststyle">
            <NavLink onClick={onAdjustmentsClicked} to={{ pathname: `/plan/${planId}/adjustments` }} className={url[6]=='adjustments'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_moves} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Adjustments</label>:''}</NavLink>
          </li> : <span></span>}
          {showAdjustments ?<li className="liststyle">
            <NavLink onClick={onSalesAndFinanceClicked} to={{ pathname: `/plan/${planId}/salesandfinance` }} className={url[4]=='salesandfinance'?"activeselected":""}  activeClassName="active" exact>
              <img src={salesfinance} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Sales & Finance</label>:''}</NavLink>
          </li> : <span></span>}
          {showAdmin ? <li className="liststyle">
            <NavLink to={{ pathname: `/admin` }} className={url[4]=='admin'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_admin_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Admin</label>:''}</NavLink>
          </li>:<span></span>}
          <li className="liststyle">
            <NavLink onClick={onRevenueFunctionClicked} to={{ pathname: `/revenuefunction` }} className={url[4]=='revenuefunction'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_rf_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Rev.Function</label>:''}</NavLink>
          </li>
          </div>:""}
          {(url[6]=='salesandfinance') ?
        <div className='innerDiv'>
          <li className="liststyle">
            <NavLink to={{ pathname: `/`,state:allPlansTableData }} className={initialurl==''?"activeselected":""} activeClassName="active" exact >
              <img src={icon_allplans} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">All Plans</label>:''}</NavLink>
          </li>
          {/* <li className="liststyle">
            <NavLink onClick={onMainInputsClicked} to={{ pathname: `/${JSON.parse(localStorage.getItem("newArchitecture"))?'newmaininputs':'maininputs'}` }} className={url[4]=='maininputs'?"activeselected":""}  activeClassName="active" exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Main Inputs</label>:''}</NavLink>
          </li> */}
          <li className="liststyle">
            <NavLink to={{ pathname: `/plan/${planId}/planinputs` }} className={url[6]=='planinputs'?"activeselected":""}  activeClassName="active" exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Plan Inputs</label>:''}</NavLink>
          </li>
          {showAdjustments ? <li className="liststyle">
            <NavLink onClick={onAdjustmentsClicked} to={{ pathname: `/plan/${planId}/adjustments` }} className={url[6]=='adjustments'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_moves} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Adjustments</label>:''}</NavLink>
          </li> : <span></span>}
          {showAdjustments ?<li className="liststyle">
            <NavLink onClick={onSalesAndFinanceClicked} to={{ pathname: `/plan/${planId}/salesandfinance` }} className={url[4]=='salesandfinance'?"activeselected":""}  activeClassName="active" exact>
              <img src={salesfinance} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Sales & Finance</label>:''}</NavLink>
          </li> : <span></span>}
          {showAdmin ? <li className="liststyle">
            <NavLink to={{ pathname: `/admin` }} className={url[4]=='admin'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_admin_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Admin</label>:''}</NavLink>
          </li>:<span></span>}
          <li className="liststyle">
            <NavLink onClick={onRevenueFunctionClicked} to={{ pathname: `/revenuefunction` }} className={url[4]=='revenuefunction'?"activeselected":""}  activeClassName="active" exact >
              <img src={icons_rf_icon} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Rev.Function</label>:''}</NavLink>
          </li>
          </div>:""}
      {/* {(allplans && (url!=='maininputs')) ?
        <div>
          <li className="liststyle">
            <NavLink to={{ pathname: `/allplans` }} className={url=='allplans'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_allplans} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">All Plans</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: `/allplans/inputs` }} className={url=='inputs'?"activeselected":""} activeClassName='active' exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Inputs</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: `/allplans/validations` }} className={url=='validations'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_validations} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Validations</label>:''}</NavLink>
          </li>
        </div>
        : ""} */}
      {newplan ?
        <div className='innerDiv'>
          <li className="liststyle">
            <NavLink to={{ pathname: `/newplan/${planId}/inputs` }} className={url=='inputs'?"activeselected":""} activeClassName='active' exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Inputs</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: `/newplan/${planId}/forecasts` }} className={url=='forecasts'?"activeselected":""} activeClassName='active' exact >
              <img src={icon_forecasts} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Forecasts</label>:''}</NavLink>
          </li>
        </div>
        : ""}
      { plan && url!=='planinputs' ?
        <div className='innerDiv'>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/dashboard' }}  className={url=='dashboard'?"activeselected":""} activeClassName='active' exact >
              <img src={icon_Dashboard} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Dashboard</label>:''}
              </NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/inputs' }} className={url=='inputs'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_inputs} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Inputs</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/forecasts' }} className={url=='forecasts'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_forecasts} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Forecasts</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/reports' }} className={url=='reports'?"activeselected":""} activeClassName="active"exact >
              <img src={icon_reports} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Reports</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/adds' }} className={url=='add'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_adds} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Adds</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/deletes' }} className={url=='deletes'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_deletes} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Deletes</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: `/plan/${planId}/moves` }} className={url=='moves'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_moves} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Moves</label>:''}</NavLink>
          </li>
          <li className="liststyle">
            <NavLink to={{ pathname: '/plan/${planId}/workflows' }} className={url=='workflows'?"activeselected":""} activeClassName="active" exact >
              <img src={icon_validations} className={!menuMinimized?"icon":"icon-sidebarminimized"}></img>{!menuMinimized?<label className="navlabel">Wokflows</label>:''}</NavLink>
          </li>
        </div>
        : ""}
    </ul>
    </div>
  )
}
export default SideBar;
