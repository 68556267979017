import React from 'react';
import './adjustments_DropdownFilter.css';
import { NttLabel } from '../../../../../core-components/Label/NttLabel';
import MultiSelect from "react-multi-select-component";
import { isWordWithoutSpecialCharacterAndSpaces } from '../../../../../utils/utils';


const DropdownFilter = (props) => {
    const { displayName, options, theme, selectedValue, onValueChanged } = props;
    const ITEM_HEIGHT = 48;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.9,
                width: 250,
            },
        },
    };

    const customSearch = (options,filter) =>{
        if (!filter) {
            return options;
        }
        if (!isWordWithoutSpecialCharacterAndSpaces(filter)) {
            return [];
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }

    return (<span className="constraintdropdown"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel>
    <MultiSelect
        options={options}
        value={selectedValue}
        onChange={onValueChanged}
        labelledBy="Select"
        filterOptions={customSearch}
      />  
        </span>)
}

export default DropdownFilter;