export const FETCH_CONSTRAINT_DATA_PENDING = 'FETCH_CONSTRAINT_DATA_PENDING';
export const FETCH_CONSTRAINT_DATA_SUCCESS = 'FETCH_CONSTRAINT_DATA_SUCCESS';
export const FETCH_CONSTRAINT_DATA_ERROR = 'FETCH_CONSTRAINT_DATA_ERROR';
export const FETCH_GROUPBY_CONSTRAINTS = 'FETCH_GROUPBY_CONSTRAINTS';
export const FETCH_CONSTRAINTS_DROPDOWN_PENDING = 'FETCH_CONSTRAINTS_DROPDOWN_PENDING';
export const FETCH_CONSTRAINTS_DROPDOWN_SUCCESS = 'FETCH_CONSTRAINTS_DROPDOWN_SUCCESS';
export const FETCH_CONSTRAINTS_DROPDOWN_ERROR = 'FETCH_CONSTRAINTS_DROPDOWN_ERROR';
export const FETCH_CONSTRAINT_TYPES_PENDING = 'FETCH_CONSTRAINT_TYPES_PENDING';
export const FETCH_CONSTRAINT_TYPES_SUCCESS = 'FETCH_CONSTRAINT_TYPES_SUCCESS';
export const FETCH_CONSTRAINT_TYPES_ERROR = 'FETCH_CONSTRAINT_TYPES_ERROR';
export const FETCH_CONSTRAINT_FIELDS_PENDING = 'FETCH_CONSTRAINT_FIELDS_PENDING';
export const FETCH_CONSTRAINT_FIELDS_SUCCESS = 'FETCH_CONSTRAINT_FIELDS_SUCCESS';
export const FETCH_CONSTRAINT_FIELDS_ERROR = 'FETCH_CONSTRAINT_FIELDS_ERROR';
export const SAVE_CONSTRAINTS_TRANSACTION_PENDING = 'SAVE_CONSTRAINTS_TRANSACTION_PENDING';
export const SAVE_CONSTRAINTS_TRANSACTION_SUCCESS = 'SAVE_CONSTRAINTS_TRANSACTION_SUCCESS';
export const SAVE_CONSTRAINTS_TRANSACTION_ERROR = 'SAVE_CONSTRAINTS_TRANSACTION_ERROR';
export const SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_PENDING = 'SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_PENDING';
export const SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_SUCCESS = 'SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_SUCCESS';
export const SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR = 'SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR';
export const FILTER_SEARCH_CONSTRAINT_DATA = 'FILTER_SEARCH_CONSTRAINT_DATA';
export const FETCH_CONSTRAINT_TYPES = 'FETCH_CONSTRAINT_TYPES';
export const FETCH_CONSTRAINT_FILTER_DATA_PENDING = 'FETCH_CONSTRAINT_FILTER_DATA_PENDING';
export const FETCH_CONSTRAINT_FILTER_DATA_SUCCESS = 'FETCH_CONSTRAINT_FILTER_DATA_SUCCESS';
export const FETCH_CONSTRAINT_FILTER_DATA_ERROR = 'FETCH_CONSTRAINT_FILTER_DATA_ERROR';
export const DELETE_CONSTRAINT_DATA_PENDING = 'DELETE_CONSTRAINT_DATA_PENDING';
export const DELETE_CONSTRAINT_DATA_SUCCESS = 'DELETE_CONSTRAINT_DATA_SUCCESS';
export const DELETE_CONSTRAINT_DATA_ERROR = 'DELETE_CONSTRAINT_DATA_ERROR';
export const UPLOAD_CONSTRAINTS_PENDING = 'UPLOAD_CONSTRAINTS_PENDING';
export const UPLOAD_CONSTRAINTS_SUCCESS = 'UPLOAD_CONSTRAINTS_SUCCESS';
export const UPLOAD_CONSTRAINTS_ERROR = 'UPLOAD_CONSTRAINTS_ERROR';
export const DISABLE_ACTION_BAR_ELEMENTS = 'DISABLE_ACTION_BAR_ELEMENTS';
export const RESET_TO_CONSTRAINTS_INITIALSTATE = 'RESET_TO_CONSTRAINTS_INITIALSTATE';
export const SET_CONSTRAINTS_FILTER_PARAMS = 'SET_CONSTRAINTS_FILTER_PARAMS';
export const SET_CONSTRAINT_EDITABLE='SET_CONSTRAINT_EDITABLE';