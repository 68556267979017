import React, { useEffect } from 'react';
import ConstraintsActionBar from "../../components/constraintsactionbar/ConstraintsActionBar";
import MultiGridUI from "../../components/grid/MultiGridUI";
import ConstraintsAccordionContainer from '../accordionview/ConstraintsAccordionContainer';
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const ConstraintsTabPanel = (props) => {
    //console.log("inside ConstraintsTabPanel:",props);
    const { constraintsData, handleSwitchView, currentView, showSaveOption, gridReducer, 
        gridColumnDef, tabInfo, selectedval, constraintTypeSelected,handleSaveCancelDisabled, downloadCSV,
        refreshCells, handleRowEditingStarted, handleRowEditingStopped, handleCellEditingStopped,
        handleAddNewRow, handleSaveClicked, handleCancelClicked,constraintsWholeData,
        constraintType, tabIndex, constrainttypes,selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete,
        scrollToTop,filterSearchKey,transactions, filterParams, currentData,constraintsUploadState,isFiltered,setIsFiltered,isSearchBarValueExists,setIsSearchBarValueExists,userMessage,newArchitecture,pending,gridName,loadGridData} = props;
    // useEffect(() => {
    //     //console.log("in actionbar*****", constraintsData);
    // }, [constraintType])
    return (
        <div className='constraints-adjust'>
            {newArchitecture ? (<MainPlanInputsMultiGridUI reducer={gridReducer}
                gridName={gridName}
                currentView={currentView}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={gridColumnDef}
                rowEditType={'single'}
                suppressclickEdit={!gridColumnDef.defaultColDef.editable||pending}
                handleMultipleRowDelete={handleMultipleRowDelete}
                scrollToTop={scrollToTop}
                userMessage={userMessage}
                loadGridData={loadGridData}
                isPaginationRequired={true}/>)
                :(<>
                <ConstraintsActionBar constraintsData={constraintsData} 
            onViewChanged={handleSwitchView} actionView={currentView} 
            options={showSaveOption} constraintTypeSelected={constraintTypeSelected}
            handleSaveCancelDisabled={handleSaveCancelDisabled}
            onSaveClicked={handleSaveClicked}
            onCancelClicked={handleCancelClicked} 
            onAddNewRowClick={handleAddNewRow}
            constrainttypes={constrainttypes} 
            constraintType={constraintType}
            selectedPlanId={selectedPlanId}
            bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
            deleteSelectedRows={deleteSelectedRows}
            downloadCSV={downloadCSV}
            data={gridReducer.currentData}
            constraintsGridColDef={gridColumnDef}
            filterSearchKey={filterSearchKey}
            transactions={transactions}
            filterParams={filterParams}
            isFiltered={isFiltered}
            setIsFiltered={setIsFiltered}
            isSearchBarValueExists={isSearchBarValueExists}
            setIsSearchBarValueExists={setIsSearchBarValueExists}
            constraintsWholeData={constraintsWholeData}
            constraintsUploadState={constraintsUploadState}/>
            {(currentView === "grid") ? 
            (
            <MultiGridUI reducer={gridReducer}
                gridName={"constraintsgrid"}
                currentView={currentView}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={gridColumnDef}
                rowEditType={'single'}
                suppressclickEdit={!gridColumnDef.defaultColDef.editable}
                handleMultipleRowDelete={handleMultipleRowDelete}
                scrollToTop={scrollToTop}
                userMessage={userMessage}/>
            ) :
                (<ConstraintsAccordionContainer planid={selectedPlanId} 
                    data={currentData}
                    constraintType={constraintType}  tabIndex={tabIndex}
                    selectedTab={tabInfo.Title} currentView={currentView} selectedval={selectedval} >
                    </ConstraintsAccordionContainer>)}
                </>)}
            
        </div>
    )

}

export default ConstraintsTabPanel;