import { DepRateColumns, cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter,valueFormatter,localeFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: DepRateColumns.CarGroup.field, 
        headerName: DepRateColumns.CarGroup.headerName,
        filterType:"dropdown",
        filterOptions:[], 
        minWidth: 50, cellEditor: cellEditors.autoCompleteEditor,
        unSortIcon: true, 
        cellClassRules: cellClassRules(DepRateColumns.CarGroup.name),
        tooltipValueGetter: params => {
            return toolTipUI(params, DepRateColumns.CarGroup.name)
        }
    }, 
    {
        field: DepRateColumns.DepRate.field, 
        headerName: DepRateColumns.DepRate.headerName, 
        minWidth:200, unSortIcon: true,
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',
        filterType:"input",
        valueFormatter: params => {
            if (params.data.DEP_RATE)
                return localeFormatter(params.data.DEP_RATE);
        },
        cellClassRules: cellClassRules(DepRateColumns.DepRate.name), tooltipValueGetter: params => {
            return toolTipUI(params, DepRateColumns.DepRate.name)
        }
    },
    {
        field: DepRateColumns.DepAdjustment.field, 
        headerName: DepRateColumns.DepAdjustment.headerName, 
        minWidth: 200, unSortIcon: true,
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',
        filterType:"input",
        valueFormatter: params => {
            if (params.DEP_ADJUSTMENT)
                return localeFormatter(params.DEP_ADJUSTMENT);
        },
        cellClassRules: cellClassRules(DepRateColumns.DepAdjustment.name), tooltipValueGetter: params => {
            return toolTipUI(params, DepRateColumns.DepAdjustment.name)
        }
    },
    {
        field: DepRateColumns.UpdatedBy.field, 
        headerName: DepRateColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(DepRateColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, DepRateColumns.UpdatedBy.name)
        }
    },
    {
        field: DepRateColumns.UpdatedOn.field, 
        headerName: DepRateColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(DepRateColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, DepRateColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "DepRateActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
