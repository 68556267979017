import {getZoneMapData, saveZoneMapTransactions, fetchZoneMapDropdownService,uploadZoneMapCSV} from '../../services/zonemap/ZoneMapServices';
import {FETCH_ZONEMAP_DATA_PENDING, FETCH_ZONEMAP_DATA_SUCCESS, FETCH_ZONEMAP_DATA_ERROR, UPLOAD_ZONEMAP_PENDING, UPLOAD_ZONEMAP_SUCCESS, UPLOAD_ZONEMAP_ERROR,
    SAVE_ZONEMAP_TRANSACTION_PENDING, SAVE_ZONEMAP_TRANSACTION_SUCCESS, SAVE_ZONEMAP_TRANSACTION_ERROR,
    FETCH_ZONEMAP_DROPDOWNVALUES_PENDING,FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS,FETCH_ZONEMAP_DROPDOWNVALUES_ERROR, RESET_TO_ZONEMAP_INITIALSTATE} from './ZoneMap.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchZoneMapData = () =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_ZONEMAP_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getZoneMapData();
            if(response){
                dispatch(serviceActionSuccess(FETCH_ZONEMAP_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_ZONEMAP_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_ZONEMAP_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadZoneMapData = (files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_ZONEMAP_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadZoneMapCSV(files);
            let zoneMapData = await getZoneMapData();
            if(uploadResp.body!==null&& uploadResp.name !== 'Error'&& uploadResp.data.length>0){
                dispatch(serviceActionSuccess(UPLOAD_ZONEMAP_SUCCESS, zoneMapData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_ZONEMAP_ERROR, "zone map upload failed"));
                dispatch(SetAssumptionError("zone map upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_ZONEMAP_ERROR, "zone map upload failed"));
            dispatch(SetAssumptionError("zone map upload failed"))
        }
    }
}

export const saveZoneMapTransactionData = (transactionList,userMessage)=>{
    return async dispatch => {
        // console.log("In saveZoneMapTransactionData...transactionList====>",transactionList);
        
            
            if(transactionList.length===1 && transactionList[0].data.newName && transactionList[0].data.newName!==''){
                // console.log("Acceptable Zone Drop Down value has either been added or edited");
                try{
                    dispatch(serviceActionPending(FETCH_ZONEMAP_DROPDOWNVALUES_PENDING));
                    let saveResult=await saveZoneMapTransactions(transactionList);
                    if(saveResult.body.statusCode==="500"){
                        let errorOutput=JSON.parse(saveResult.body.body);
                        throw new Error(errorOutput.error);
                    }
                    sessionStorage.removeItem('ACCEPTABLEZONES');
                    let zoneMapDataDropdownValues = await fetchZoneMapDropdownService();
                    let zoneMapData = await getZoneMapData();
                    if (zoneMapData) {
                        dispatch(serviceActionSuccess(FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS, zoneMapDataDropdownValues));
                        dispatch(serviceActionSuccess(SAVE_ZONEMAP_TRANSACTION_SUCCESS, zoneMapData));
                        userMessage('success','Acceptable Zone Saved Successfully');
                    } else {
                        dispatch(serviceActionError(FETCH_ZONEMAP_DROPDOWNVALUES_ERROR, zoneMapData));
                    }
                  }
                  catch(e){
                    console.log("Exception",e);
                    dispatch(serviceActionError(FETCH_ZONEMAP_DROPDOWNVALUES_ERROR, "Unknown error"));
                    userMessage('error',`Failed to save :: ${e.message}` );
                  }
            }else{
                // console.log("An Acceptable Zone mapping entry has been created");
                try {
                    dispatch(serviceActionPending(SAVE_ZONEMAP_TRANSACTION_PENDING));
                    // console.log("In Marking Transaction in process...");
                    let saveResult=await saveZoneMapTransactions(transactionList);
                    // console.log("Saving Zone Map Transactions...",saveResult);
                    if(saveResult.body.statusCode==="500"){
                        let errorOutput=JSON.parse(saveResult.body.body);
                        throw new Error(errorOutput.error);
                    }

                    let zoneMapData = await getZoneMapData();
                    // console.log("Getting ZoneMap Data...",zoneMapData);
                    if (zoneMapData) {
                        // console.log("ZoneMap Data Received...");
                        dispatch(serviceActionSuccess(SAVE_ZONEMAP_TRANSACTION_SUCCESS, zoneMapData));
                        userMessage('success','Rows Saved Successfully');
                        // console.log("ZoneMap Data SUCCESS...");
                    }
                } catch (e) {
                    console.log("Exception",e.message);
                    console.log("Exception Stack",e.stack);
                    dispatch(serviceActionError(SAVE_ZONEMAP_TRANSACTION_ERROR, "Unknown error"));
                    dispatch(SetAssumptionError("Unknown error"))
                    userMessage('error',`Failed to save :: ${e.message}` );
                }
            }
            
            
        
    }
}

export const fetchZoneMapDropdownValues = (data,coldef,validateTransactionsIfMasterDataChanged) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_ZONEMAP_DROPDOWNVALUES_PENDING));
        let zoneMapData = await fetchZoneMapDropdownService();
        if (zoneMapData) {
            dispatch(serviceActionSuccess(FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS, zoneMapData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                dispatch(validateGrid("zonemapgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false)); 
        } else {
            dispatch(serviceActionError(FETCH_ZONEMAP_DROPDOWNVALUES_ERROR, zoneMapData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_ZONEMAP_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetZoneMap = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_ZONEMAP_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}