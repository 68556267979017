import {FETCH_DIVERSIONS_DATA_PENDING, FETCH_DIVERSIONS_DATA_SUCCESS, FETCH_DIVERSIONS_DATA_ERROR, UPLOAD_DIVERSIONS_PENDING, UPLOAD_DIVERSIONS_SUCCESS, UPLOAD_DIVERSIONS_ERROR,
    SAVE_DIVERSIONS_TRANSACTION_PENDING, SAVE_DIVERSIONS_TRANSACTION_SUCCESS, SAVE_DIVERSIONS_TRANSACTION_ERROR,RESET_TO_DIVERSIONS_INITIALSTATE
    } from '../../store/diversions/Diversions.ActionTypes';

const initialState = {
    diversionsData:[],
    pending:false,
    error:null,
    uploadDiversionsSuccess:null,
    transactionSaveSuccess:null
}


export default function DiversionsReducer (state=initialState,action){
    switch(action.type){
        case FETCH_DIVERSIONS_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_DIVERSIONS_DATA_SUCCESS:
            return Object.assign({},state,{
                diversionsData: action.data.diversionsData,
                uploadDiversionsSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_DIVERSIONS_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_DIVERSIONS_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadDiversionsSuccess: null
            });
        case UPLOAD_DIVERSIONS_SUCCESS:
            return Object.assign({}, state, {
                diversionsData: action.data.diversionsData,
                pending: false,
                error: null,
                uploadDiversionsSuccess: true
            });
        case UPLOAD_DIVERSIONS_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadDiversionsSuccess: false
            });
        case SAVE_DIVERSIONS_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_DIVERSIONS_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                diversionsData: action.data.diversionsData,
                transactionSaveSuccess: true
            });
        case SAVE_DIVERSIONS_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_DIVERSIONS_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}