import { getAllConstraints, fetchAllZones, saveConstraintTransactions, uploadCSV, uploadConstraintsCSV, deleteConstraint, fetchZones1, fetchConstraintsDropDownValues } from "../../services/constraints/constraintsservice";
import {
    FETCH_CONSTRAINT_DATA_SUCCESS, FETCH_CONSTRAINT_DATA_ERROR,
    FETCH_CONSTRAINT_DATA_PENDING, FETCH_CONSTRAINT_TYPES_PENDING,
    FETCH_CONSTRAINT_TYPES_SUCCESS, FETCH_CONSTRAINT_TYPES_ERROR,
    FETCH_GROUPBY_CONSTRAINTS, FETCH_CONSTRAINT_FIELDS_PENDING,
    FETCH_CONSTRAINT_FIELDS_SUCCESS, FETCH_CONSTRAINT_FIELDS_ERROR,
    SAVE_CONSTRAINTS_TRANSACTION_PENDING, SAVE_CONSTRAINTS_TRANSACTION_SUCCESS,
    SAVE_CONSTRAINTS_TRANSACTION_ERROR,
    FETCH_CONSTRAINT_TYPES,
    FILTER_SEARCH_CONSTRAINT_DATA,
    FETCH_CONSTRAINT_FILTER_DATA_SUCCESS,
    DELETE_CONSTRAINT_DATA_PENDING, DELETE_CONSTRAINT_DATA_SUCCESS, DELETE_CONSTRAINT_DATA_ERROR,
    UPLOAD_CONSTRAINTS_PENDING, UPLOAD_CONSTRAINTS_SUCCESS, UPLOAD_CONSTRAINTS_ERROR,
    DISABLE_ACTION_BAR_ELEMENTS, SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_PENDING,
    SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_SUCCESS, SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR,
    RESET_TO_CONSTRAINTS_INITIALSTATE, SET_CONSTRAINTS_FILTER_PARAMS
} from "./Constraints.ActionTypes";
import constraintFilterColumnDefs from '../../data/constraintFilterColumns';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchConstraintData = (planId) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_CONSTRAINT_DATA_PENDING));
            let response = await getAllConstraints(planId);
            if (response) {
                dispatch(serviceActionSuccess(FETCH_CONSTRAINT_DATA_SUCCESS, response));
            }

        } catch (e) {
            dispatch(serviceActionError(FETCH_CONSTRAINT_DATA_ERROR, "Unknown error"));
        }
    }
}

export const getConstraintTypes = (constraintsData) => {
    return async dispatch => {
        dispatch(getConstraintType(FETCH_CONSTRAINT_TYPES, constraintsData));
    }
}

export const groupByConstraints = (constraintdata, type, selectedvalue, sortkey, sortAscending) => {
    return async dispatch => {
        dispatch(fetchGroupByConstraints(FETCH_GROUPBY_CONSTRAINTS, { constraintdata, type, selectedvalue, sortkey, sortAscending }));
    }
}

export const fetchConstraintFilterData = (constraintsWholeData) => {
    return async dispatch => {
        var filterColumns = constraintFilterColumnDefs();
        dispatch(serviceActionSuccess(FETCH_CONSTRAINT_FILTER_DATA_SUCCESS, { "constraintData": constraintsWholeData, "columns": filterColumns }));
    }
}

export const fetchConstrantsFields = () => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_CONSTRAINT_FIELDS_PENDING));
            let response = await getAllConstraints();
            dispatch(serviceActionSuccess(FETCH_CONSTRAINT_FIELDS_SUCCESS, response));
        } catch (_) {
            dispatch(serviceActionError(FETCH_CONSTRAINT_FIELDS_ERROR, "Unknown error"));
        }
    }
}



export const fetchConstraintsOptions = (data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async (dispatch) => {
        try {

            dispatch(serviceActionPending(FETCH_CONSTRAINT_TYPES_PENDING));
            // let constraintsData = {};
            // if (data.constraints.length > 0) {
            //     constraintsData = data;
            // } else {
            //     constraintsData = await getAllConstraints(planid);
            // }
            let response = await fetchConstraintsDropDownValues()
            dispatch(
                serviceActionSuccess(FETCH_CONSTRAINT_TYPES_SUCCESS, response)
            );
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if (validateTransactionsIfMasterDataChanged) {
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("constraintsGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later
                dispatch(validateGrid("constraintsgrid",data,coldef.validationFn,coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));

        } catch (e) {
            dispatch(
                serviceActionError(FETCH_CONSTRAINT_TYPES_ERROR, "Unknown error")
            );
        }
    };
};

export const saveConstraintsTransactions = (planid, transactionList, constraintsUploadState, data, coldef, validateTransactionsIfMasterDataChanged) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_CONSTRAINTS_TRANSACTION_PENDING));
            if (transactionList && transactionList.length > 0) {
                await saveConstraintTransactions(planid, transactionList);
            }
            let constraintsData = await getAllConstraints(planid);
            if (constraintsData) {
                dispatch(serviceActionSuccess(SAVE_CONSTRAINTS_TRANSACTION_SUCCESS, constraintsData));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_CONSTRAINTS_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

//TODO: To be removed in future once the filter issue is fixed in constraints
export const saveConstraintsAccordionData = (planid, transactionList, constraintType) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_PENDING));
            let transactionResponse = await saveConstraintTransactions(planid, transactionList);
            let constraintsData;
            if (transactionResponse) {
                constraintsData = await getAllConstraints(planid);
                if (constraintsData) {
                    dispatch(serviceActionSuccess(SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_SUCCESS, { constraints: constraintsData, type: constraintType }));
                }
            } else {
                dispatch(serviceActionError(SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR, "Unknown error"));
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_ACCORDION_CONSTRAINTS_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const searchConstraintRecord = (searchkey) => {
    return serviceActionSuccess(FILTER_SEARCH_CONSTRAINT_DATA, searchkey);
}
function fetchYearFilteredData(action) {
    const currentYear = new Date().getFullYear();
    const previousYear = new Date().getFullYear() - 1;
    let data = action.constraintData;
    let type = action.filtertype;
    var newData = [];
    if (type == 'Current Year') {
        data.filter(function (e) {
            if (e.from_date !== undefined || e.to_date !== undefined) {
                let obj1 = new Date(e.from_date.split('To')).getFullYear();
                let obj2 = new Date(e.to_date.split('To')).getFullYear();
                if (obj1 == currentYear && obj2 == currentYear) {
                    newData.push(e);
                }
            }
            else if (e.month !== undefined) {
                let obj = new Date(e.month.split('To')).getFullYear();
                if (obj == currentYear) {
                    newData.push(e);
                }
            }
        });
    } else if (type == 'Last Year') {
        data.filter(function (e) {
            if (e.from_date !== undefined || e.to_date !== undefined) {
                let obj1 = new Date(e.from_date.split('To')).getFullYear();
                let obj2 = new Date(e.to_date.split('To')).getFullYear();
                if (obj1 == previousYear && obj2 == previousYear) {
                    newData.push(e);
                }
            }
            else if (e.month != undefined) {
                let obj = new Date(e.month.split('To')).getFullYear();
                if (previousYear == obj) {
                    newData.push(e);
                }
            }
        });
    }
    return newData;
}

export const deleteConstraintRecord = (planid, id) => {
    return async dispatch => {
        dispatch(serviceActionPending(DELETE_CONSTRAINT_DATA_PENDING));
        let res = await deleteConstraint(planid, id);
        if (res.ok) {
            let constraintData = await getAllConstraints(planid)
            if (constraintData) {
                dispatch(serviceActionSuccess(DELETE_CONSTRAINT_DATA_SUCCESS, constraintData));
            }
            else {
                dispatch(serviceActionError(DELETE_CONSTRAINT_DATA_ERROR, res.response));
            }
        } else {
            dispatch(serviceActionError(DELETE_CONSTRAINT_DATA_ERROR, "Unknown error"));
        }
    }
}
export const uploadConstraintsData = (planId, files) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_CONSTRAINTS_PENDING));
            let uploadResp = await uploadConstraintsCSV(planId, files);
            let constraintData = await getAllConstraints(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500')
            {
                dispatch(serviceActionSuccess(UPLOAD_CONSTRAINTS_SUCCESS, constraintData));
            }
            else {
                dispatch(serviceActionError(UPLOAD_CONSTRAINTS_ERROR, "Constraints upload failed"));
            }

        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_CONSTRAINTS_ERROR, "Constraints upload failed"));
        }
    }
}

export const disableActionBarElements = (editing) => {
    return async dispatch => {
        dispatch(disableElements(DISABLE_ACTION_BAR_ELEMENTS, editing));
    }
}

export const resetConstraints = () => {
    return dispatch => {
        dispatch(serviceActionSuccess(RESET_TO_CONSTRAINTS_INITIALSTATE))
    }
}

export const setFilterParameters = (filterParams) => {
    return async dispatch => {
        dispatch(serviceActionSuccess(SET_CONSTRAINTS_FILTER_PARAMS, filterParams))
    }
}

function disableElements(type, editing) {
    return {
        type: type,
        data: editing
    }
}

function getConstraintType(type, constraintsData) {
    return {
        type: type,
        data: constraintsData
    }
}

function fetchGroupByConstraints(type, allconstraints) {
    return {
        type: type,
        data: allconstraints
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}