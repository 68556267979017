export const FETCH_ADJUSTMENT_TYPES_PENDING = 'FETCH_ADJUSTMENT_TYPES_PENDING';
export const FETCH_ADJUSTMENT_TYPES_SUCCESS = 'FETCH_ADJUSTMENT_TYPES_SUCCESS';
export const FETCH_CONSTRAINT_DATA_ERROR = 'FETCH_CONSTRAINT_DATA_ERROR';
export const FETCH_PLAN_OUTPUTS_PENDING = 'FETCH_PLAN_OUTPUTS_PENDING';
export const FETCH_PLAN_OUTPUTS_SUCCESS = 'FETCH_PLAN_OUTPUTS_SUCCESS';
export const FETCH_PLAN_OUTPUTS_ERROR = 'FETCH_PLAN_OUTPUTS_ERROR';
export const FETCH_GROUP_BY_TYPE_ZONE = 'FETCH_GROUP_BY_TYPE_ZONE';
export const FETCH_GROUP_BY_YEAR = 'FETCH_GROUP_BY_YEAR';
export const FETCH_PLAN_OPTIMIZATION_DATA_PENDING = 'FETCH_PLAN_OPTIMIZATION_DATA_PENDING';
export const FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS = 'FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS';
export const FETCH_PLAN_OPTIMIZATION_DATA_ERROR = 'FETCH_PLAN_OPTIMIZATION_DATA_ERROR';
export const AGGREGATED_ACTIVITY_FOR_TYPE = 'AGGREGATED_ACTIVITY_FOR_TYPE';
export const AGGREGATED_ACTIVITY_FOR_ZONE = 'AGGREGATED_ACTIVITY_FOR_ZONE';
export const AGGREGATED_ACTIVITY_FOR_ADDS = 'AGGREGATED_ACTIVITY_FOR_ADDS';
export const AGGREGATED_ACTIVITY_FOR_DELETES = 'AGGREGATED_ACTIVITY_FOR_DELETES';
export const UPDATE_ADJUSTMENTS_PENDING = 'UPDATE_ADJUSTMENTS_PENDING';
export const UPDATE_ADJUSTMENTS_SUCCESS = 'UPDATE_ADJUSTMENTS_SUCCESS';
export const UPDATE_ADJUSTMENTS_ERROR = 'UPDATE_ADJUSTMENTS_ERROR';
export const SET_DATA_WITH_ADJUSTEMENT_VALUE = 'SET_DATA_WITH_ADJUSTEMENT_VALUE';
export const FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_PENDING = 'FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_PENDING';
export const FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_SUCCESS = 'FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_SUCCESS';
export const FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR = 'FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR';
export const FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING = 'FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING';
export const FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS = 'FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS';
export const FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR = 'FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR';
export const ADJUSTMENTS_ROW_ACTION = 'ADJUSTMENTS_ROW_ACTION';
export const ADJUSTMENTS_SET_LEVEL = 'ADJUSTMENTS_SET_LEVEL';
export const FETCH_ADJUSTMENTS_FILTER_DATA = 'FETCH_ADJUSTMENTS_FILTER_DATA';
export const SET_ADJUSTMENTS_EXPANDALL_FLAGS = 'SET_ADJUSTMENTS_EXPANDALL_FLAGS';
export const SET_TABALL_FILTER_PARAMS = 'SET_TABALL_FILTER_PARAMS';
export const SET_CORETAB_FILTER_PARAMS= 'SET_CORETAB_FILTER_PARAMS';
export const SET_COREICETAB_FILTER_PARAMS = 'SET_COREICETAB_FILTER_PARAMS';
export const SET_COREELECTAB_FILTER_PARAMS = 'SET_COREELECTAB_FILTER_PARAMS';
export const SET_TNCTAB_FILTER_PARAMS = 'SET_TNCTAB_FILTER_PARAMS';
export const SET_TNCICETAB_FILTER_PARAMS = 'SET_TNCICETAB_FILTER_PARAMS';
export const SET_TNCELECTAB_FILTER_PARAMS = 'SET_TNCELECTAB_FILTER_PARAMS';
export const SET_CARGOCTAB_FILTER_PARAMS = 'SET_CARGOTAB_FILTER_PARAMS';
export const SET_BU_GROUP_VIEW_TYPE="SET_BU_GROUP_VIEW_TYPE";
export const SET_ACCORDIAN_CLICK="SET_ACCORDIAN_CLICK";

