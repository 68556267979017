import React, { useEffect, useState, useCallback, ugridFilterUserSelectedParamsseRef, useRef  } from "react";
import './SalesAndFinancePage.css';
import TabPanel from "../../core-components/Tabs/TabPanel";
import NttTabs from "../../components/tabs/NttTabs";
import NttTab from "../../components/tabs/NttTab";
import SalesTabPanel from "./salesandfinancetabpanel/SalesTabPanel";
import SalesChannelTabPanel from "./salesandfinancetabpanel/SalesChannelTabPanel";
import BvDepOverrideTabPanel from "./salesandfinancetabpanel/BVDepOverrideTabPanel";
import { Button} from '@material-ui/core';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNotificationMessage, loadPlanDataById } from "../../store/topbar/AllPlans.Actions";
import { fetchAdjustmentPlanOutputData,processSalesAdjustmentsRun, processbvDepOverrideRun} from "../../store/salesandfinance/SalesAndFinance.Actions";
import { Tooltip } from "@material-ui/core";
import { fetchGridData, fetchGridTabErrorStatus, fetchMultiTabErrorStatusBasedOnModule, resetGridState, setGridFilterParams, setGridFilterViewStatus, setGridName, setGridUserPageNumber, validateSingleGridsData } from "../../store/MultiGridPaginationModel/PaginationGrid.Actions";
import { populateGrid2, validateGrid,ResetMultiGridState } from "../../store/Sales_FinanceMultiGrid/SalesAndFinanceMultiGrid.Actions";
import {MessageNotificationValues, PlanInputsTabStyle, safids} from "../../Constants"

export default function SalesAndFinancePage(props) {
    //const [value, setValue] = useState(0)
    const { planid } = useParams();
    const [showSaveOption, setShowSaveOption] = useState(false);
    const [saveCancelDisabled, setSaveCancelDisabled] = useState(false);
    const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [severity, setSeverity] = useState('error');
    const [refreshCells, setRefreshCells] = useState(false);
    let dispatch = useDispatch();
    let sfIdFromUrl = window.location.href.split("/")[5];
    let plansDataById = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.plansDataById;
    });
    
    const value = useRef(0);

    const bvDepOverrideGridColDef = useSelector((state) => {
        return state.bvDepOverrideColumnDefinitionReducer;
    })

    const gridName = useSelector((state) => {
        return state.PaginationGridReducer.gridName
    })

    const gridData = useSelector((state) => {
        return state.PaginationGridReducer.gridData;
    });

    const GridReducer = useSelector((state) => {
        return state.salesfinanceMultiGridReducer;
    });

    const gridUserPageno = useSelector((state) => {
        return state.PaginationGridReducer.gridUserPageno;
    });

    const callGridFetchApi = useSelector((state)=>{
        return state.PaginationGridReducer.callGridFetchApi;
    });

    const gridTabErrorStatus = useSelector((state)=>{
    return state.PaginationGridReducer.gridTabErrorStatus;
    });

    const uploadGridSuccess=useSelector((state) => {
    return state.PaginationGridReducer.gridUploadStatus;
    });
    const  gridErrorMessage = useSelector((state) => {
    return state.PaginationGridReducer.gridErrorMessage
    });
    const pending = useSelector((state) => {
    return state.PaginationGridReducer.pending;
    });
    const gridFilterUserSelectedParams = useSelector((state) => {
        return {...state.PaginationGridReducer.gridFilterUserSelectedParams};
      });
    
      const gridFilterViewStatus = useSelector((state) => {
        return {...state.PaginationGridReducer.gridFilterView};
      });
    
      const gridSearchViewStatus = useSelector((state)=>{
        return {...state.PaginationGridReducer.gridSearchView};
      })
    
      const gridUserSearchParams = useSelector((state) => {
        return {...state.PaginationGridReducer.gridUserSearchParams};
      });
      const gridErrorViewStatus = useSelector((state) => {
        return {...state.PaginationGridReducer.gridErrorView};
      });

      const highlightAllTabsErrorStatus = useSelector((state) => {
        return state.PaginationGridReducer.gridTabErrorStatus;
      })

    const [GridColDefReducer, setGridColDefReducer] = useState(bvDepOverrideGridColDef);
    const [open, setOpen] = useState(false);
    
    const loadGridData = useCallback(async (isValidationRequired, manualPageNo) => {
      try {
        if (isValidationRequired) {// if this condition is true then validateSingleGridsData action will be dispatched instead of fetchGridTabErrorStatus
          await dispatch(validateSingleGridsData(gridName, planid));
          dispatch(fetchGridData(planid, gridName, manualPageNo ? manualPageNo : gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName], gridErrorViewStatus[gridName], gridUserSearchParams[gridName], gridSearchViewStatus[gridName]))
        }
        else {// this condition will be dispatched when isValidationRequired param is not defined or when it is false
          await Promise.all([dispatch(fetchGridTabErrorStatus(planid, gridName)),
          dispatch(fetchGridData(planid, gridName, manualPageNo ? manualPageNo : gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName], gridErrorViewStatus[gridName], gridUserSearchParams[gridName], gridSearchViewStatus[gridName]))])
  
        }
      } catch (err) {
        console.error('got error while loading griddata');
      }
    }, [gridUserPageno, callGridFetchApi]);

      
      useEffect(()=>{
        dispatch(setGridName('salesgrid'));
      },[])

    useEffect(() => {
      dispatch(fetchMultiTabErrorStatusBasedOnModule(planid,gridName,'SALES_AND_FINANCE'))
      handleChange("", 0, true)
      return () => {
        // this is to set newArchitecture as false when unmounting the inputs page and will be removed after acceptance
        localStorage.setItem("newArchitecture",false)
        dispatch(resetGridState(true));
      }
    }, [])

    useEffect(() => {
        if (plansDataById && plansDataById.length > 0) {
            dispatch(fetchAdjustmentPlanOutputData(plansDataById[0].id, []));
        } else if (planid) {
            dispatch(loadPlanDataById(parseInt(planid)));
        } else {
            // console.log("Plan Not loaded yet...");
        }
    }, [plansDataById]);


    const tabsData = [
        { tabTitle: "Sales", tabKey: 0 },
        { tabTitle: "Sales Channels", tabKey: 1 },
        { tabTitle: "BV Dep Override", tabKey: 2, gridName: "bvdepoverridegrid" },
    ]

    const handleChange = useCallback(async (event, newValue,isPlanChanged) => {
        if (value.current != newValue || isPlanChanged) {
          value.current = newValue
          dispatch(resetGridState(false));
          dispatch(ResetMultiGridState())
          await dispatch(setGridUserPageNumber(1))
          switch (newValue) {
            case 0:
                dispatch(setGridName('salesgrid'));
              break;
            case 1:
                dispatch(setGridName('saleschannelsgrid'));
              break;
            case 2:
              setGridColDefReducer(bvDepOverrideGridColDef);
              dispatch(setGridName('bvdepoverridegrid'));   
              break;
            default:
              break;
          }
        }
      }, [gridName])

    useEffect(() => {
        if (gridName != "") {
          loadGridData();
        }
      }, [gridUserPageno])
    
      useEffect(() => {
        if (callGridFetchApi == true) {
         loadGridData();
        }
      }, [callGridFetchApi]) //this useeffect triggers when there is a change in gridName,gridFilterViewStatus,gridErrorViewStatus,gridSearchViewStatus.
    
    useEffect(() => {
        if ((gridData && GridColDefReducer && gridName != '')) {
            dispatch(populateGrid2(gridName, gridData, gridData, '', GridColDefReducer.checkValue, GridColDefReducer.validationFn, GridColDefReducer.businessValidationFn, GridColDefReducer.checkIfRequiredFn));
            dispatch(validateGrid(gridName, gridData, GridColDefReducer.validationFn, GridColDefReducer.businessValidationFn));
        }
    }, [gridData]);

    const handleRowEditingStarted = (isRowEditing, isOfTypeCopyOrDelete, transactions) => {
        if (isRowEditing && transactions.length > 0) {
            setShowSaveOption(true);
            if (!saveCancelDisabled && !isOfTypeCopyOrDelete)
                setSaveCancelDisabled(true);
        }
    }

    const userMessage = (severity, message) => {
        setWarningSnackBarOpen(true);
        setSeverity(severity);
        setWarningMessage(message);
    }

    const handleRowEditingStopped = useCallback((isRowEditing) => {
    if (isRowEditing) {
        setShowSaveOption(false);
    }
    }, [])

    const handleCellEditingStopped = useCallback(() => {
    setSaveCancelDisabled(false);
    }, [])

    useEffect(() => {
        if (!showSaveOption && uploadGridSuccess == true) {
          if (gridTabErrorStatus[gridName]){
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Successfully uploaded. Some rows are invalid. Please review'));
          }else{
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.success,'Successfully uploaded'));
          }
        } else if (uploadGridSuccess == false) {
          dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, gridErrorMessage));
        }
      }, [uploadGridSuccess])
    
    const handleClickOpenDialog = ()=>{
        setOpen(true);
    }
    const handleProcessAdjustmentsRun = () => {
        dispatch(processSalesAdjustmentsRun(planid))
    }

    const handleProcessbvDepOverrideRun = () => {
        dispatch(processbvDepOverrideRun(planid))
    }
    

    let planStatus = (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) ? plansDataById[0].status : 'invalid')
    return (
        <div className="salesfinmargin">
            <div className="adjustmentflexitems">
                <span className='adjustmentheader'>Sales and Finance</span>
                <div className="adjustmentrun">
                    <Button
                    disabled={(plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as optimal') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as sub-optim') && (plansDataById[0].status.toLowerCase() !== 'optimization complete unknown status')) && (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase()!=='adjustments modified'))&& planStatus!=='invalid'} 
                        onClick={handleProcessAdjustmentsRun} className={((plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as optimal') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as sub-optim') && (plansDataById[0].status.toLowerCase() !== 'optimization complete unknown status')) && (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase()!=='adjustments modified'))&& planStatus!=='invalid') ? "runblur" : ""}>
                        Process Sales Adjustments
                    </Button>
                    <Button
                    disabled={(plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as optimal') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as sub-optim') && (plansDataById[0].status.toLowerCase() !== 'optimization complete unknown status')) && (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase()!=='adjustments modified'))&& planStatus!=='invalid'} 
                        onClick={handleProcessbvDepOverrideRun} className={((plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as optimal') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as sub-optim') && (plansDataById[0].status.toLowerCase() !== 'optimization complete unknown status')) && (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase()!=='adjustments modified'))&& planStatus!=='invalid') ? "runblur" : ""}>
                        Process VIN Dep Plan
                    </Button>
                </div>
            </div>
            <SalesAndFinanceTabs handleChange={handleChange} tabsData={tabsData} value={value.current} />
            {tabsData.map((tabInfo, index) => (
              <TabPanel key={index}
                  value={value.current} index={index}>
                  {(index == 0) ?
                      <SalesTabPanel bvDepOverrideGridColDef={GridColDefReducer}/>
                  : (index == 1) ?
                      <SalesChannelTabPanel />
                  : (index == 2) ?
                      <BvDepOverrideTabPanel
                          gridName={gridName}
                          refreshCells={refreshCells}
                          currentView={'grid'}
                          gridReducer={GridReducer}
                          handleRowEditingStarted={handleRowEditingStarted}
                          bvDepOverrideGridColDef={GridColDefReducer}
                          loadGridData={loadGridData}
                          selectedPlanId={planid}
                          userMessage={userMessage}
                          setShowSaveOption={setShowSaveOption}
                          pending={pending}
                          handleRowEditingStopped={handleRowEditingStopped}
                          handleCellEditingStopped={handleCellEditingStopped}
                          gridData={gridData}
                      />
                  : <div></div>}
              </TabPanel >
            ))}
        <div>
      </div>
    </div>

    
    )

    function SalesAndFinanceTabs({ handleChange, tabsData, value }) {
        // const PlanInputsTabStyle = {
        //     default_tab: {
        //         marginLeft: "5px",
        //         borderRadius: "4px 4px 0px 0px",
        //         color: "white",
        //         background: "black",
        //         fontSize: 14,
        //         textTransform: 'none',
        //     },
        //     active_tab: {
        //         background: "white",
        //         marginLeft: "5px",
        //         borderColor: "white",
        //         borderRadius: "4px 4px 0px 0px",
        //         color: "black",
        //         fontSize: 14,
        //         textTransform: 'none'
        //     },
        //     tabItemContainer: {
        //         margin: "50px",
        //         display: "flex",
        //         flexWrap: "wrap",
        //     }
        // };

        const getStyle = (isActive, tabKey, index, gridname) => {
          if (highlightAllTabsErrorStatus[tabKey.gridName] === true) {
            return isActive ? PlanInputsTabStyle.activeError_tab : PlanInputsTabStyle.defaultError_tab
          } else {
            return isActive ? PlanInputsTabStyle.active_tab : PlanInputsTabStyle.default_tab
          }
            //return isActive ? PlanInputsTabStyle.active_tab : PlanInputsTabStyle.default_tab
        }
        
        let editing = useSelector((state) => {
            return state.rootSalesAndFinanceReducer.salesAndFinanceReducer.editing;
        });

        return (<NttTabs onChange={handleChange} style={PlanInputsTabStyle.tabItemContainer}>
            {tabsData.map((tabInfo, index) => (
                <NttTab label={tabInfo.tabTitle}
                    id={`simple-tab-${index}`}
                    key={tabInfo.tabTitle}
                    style={getStyle(value == index, tabInfo, index,gridName)}
                    disabled={(index != 0 && index != 1 && index != 2) || editing } />
            ))}
        </NttTabs>);
    }}