import { combineReducers } from 'redux';
import programsReducer from './Programs.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import columnDefinitionReducer from "./Programs.ColumnDef.Reducer";

const rootProgramsReducer = combineReducers({
      programsReducer
    , programsGridReducer: createNamedWrapperReducer(multiGridUIReducer, "programsgrid")
    , columnDefinitionReducer
})

export default rootProgramsReducer;