import { combineReducers } from 'redux';
import SalvageRateReducer from './SalvageRate.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import salvageRateColumnDefinitionReducer from "./SalvageRate.ColumnDef.Reducer";

const rootSalvageRateReducer = combineReducers({
    SalvageRateReducer
    , salvageRateGridReducer: createNamedWrapperReducer(multiGridUIReducer, "salvagerategrid")
    , salvageRateColumnDefinitionReducer
})

export default rootSalvageRateReducer;