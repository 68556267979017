import { plansDateFormatter,statusFormatter } from "../../../../utils/formatters/valueFormatter";
import * as CONSTANTS from '../../../../Constants';
import { unEscapeHtml } from "../../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: CONSTANTS.AllRFsColumns.Id.field, headerName: CONSTANTS.AllRFsColumns.Id.headerName, unSortIcon: true,maxWidth:100,minWidth:70,
        editable: false
    },
    {
        field: CONSTANTS.AllRFsColumns.RfCode.field, headerName: CONSTANTS.AllRFsColumns.RfCode.headerName, unSortIcon: true,minWidth:200,
        editable: false,
        cellRenderer:"AllRfsNavigationRenderer"
    },
    {
        field: CONSTANTS.AllRFsColumns.Description.field, headerName: CONSTANTS.AllRFsColumns.Description.headerName, unSortIcon: true,minWidth:250,
        editable: false,
        valueFormatter:params=>unEscapeHtml(params && params.data.DESCRIPTION)
    },
    {
        field: CONSTANTS.AllRFsColumns.Type.field, headerName: CONSTANTS.AllRFsColumns.Type.headerName,maxWidth:120,minWidth:80,
        unSortIcon: true,editable: false
    },
    {
        field: CONSTANTS.AllRFsColumns.Pool.field, headerName: CONSTANTS.AllRFsColumns.Pool.headerName,maxWidth:120,minWidth:80,
        editable: false,unSortIcon: true,
        cellRenderer:'RfPoolRenderer'
    },
    {
        field: CONSTANTS.AllRFsColumns.Pieces.field, headerName: CONSTANTS.AllRFsColumns.Pieces.headerName,minWidth:180,
        editable: false,unSortIcon: true,
    },
    {
        field: CONSTANTS.AllRFsColumns.Status.field, headerName: CONSTANTS.AllRFsColumns.Status.headerName,minWidth:180,
        editable: false,unSortIcon: true,cellStyle: params=>{
            return statusStyles(params)
        },
       valueFormatter:params=>statusFormatter(params && params.data.STATUS),
       tooltipValueGetter: (params) => {
            return toolTipUI(params);
       },
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer:'allRFsCellRenderer',
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 40,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function statusStyles(params){
    let borderRadius='12px !important',backgroundColor='',textTransform='capitalize !important';
    if(params && params.value.toLowerCase()==="new"){
        backgroundColor='#ffcc00';
    }else if(params && (params.value.toLowerCase()=== "ready" || params.value.toLowerCase() == "apply adjustments")){
        backgroundColor='#ebe6e7';
    }else if(params && (params.value.toLowerCase() == "building model" || params.value.toLowerCase() == "optimization queued" || params.value.toLowerCase() == "optimizing" ||params.value.toLowerCase() == "pulling data" || params.value.toLowerCase() == "running")){
        backgroundColor='#ece4f4'; 
    }else if(params && params.value.toLowerCase() == "published"){
        backgroundColor='#e2f4ff';    
    }else if(params && (params.value.toLowerCase() == "optimization complete" || params.value.toLowerCase() == "optimization complete as optimal" || params.value.toLowerCase() == "completed")){
        backgroundColor='#e5fdf1';     
    }else if(params && params.value.toLowerCase() == "error" || params.value.toLowerCase() == "prep_error"){
        backgroundColor='#fee6d8';  
    }else if(params && params.value.toLowerCase() == "adjustment complete"){
        backgroundColor='#f0fde5';    
    }else if(params && params.value.toLowerCase() == "optimization complete as sub-optim"){
        backgroundColor='#ff980e73'; 
    }else if(params && params.value.toLowerCase() == "optimization complete unknown status"){
        backgroundColor='#eb200b54';
    }else if(params && params.value.toLowerCase() === "editing adjustments"){
        backgroundColor='#e0908854';   
    }else if(params && params.value.toLowerCase() === "adjustments modified"){
        backgroundColor='#8d8bd054';   
    }
    return {
            borderRadius:borderRadius,
            backgroundColor:backgroundColor,
            textTransform:textTransform
          }
}

function toolTipUI(params) {

    if(params.valueFormatted==="Running"){
        return params.value;
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
