import { combineReducers } from 'redux';
import AgeMileageReducer from './AgeMileage.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import ageMileageColumnDefinitionReducer from "./AgeMileage.ColumnDef.Reducer";

const rootAgeMileageReducer = combineReducers({
      AgeMileageReducer
    , ageMileageGridReducer: createNamedWrapperReducer(multiGridUIReducer, "agemileagegrid")
    , ageMileageColumnDefinitionReducer
})

export default rootAgeMileageReducer;