import React,{useEffect,useState,useCallback} from 'react';
import './AdminPage.css';
import {ADMINPOOLSGRID, adminTabs, ADMIN_ACCESS_REVOKED_ERROR_CODE} from '../../Constants';
import PropTypes from "prop-types";
import NttTab from "../../components/tabs/NttTab"
import NttTabs from "../../components/tabs/NttTabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch,useSelector } from 'react-redux';
import {fetchUserManagementData, saveUserManagement} from '../../store/admin/usermanagement/UserManagement.Actions';
import UserManagementTabPanel from './usermanagement/UserManagementTabPanel';
import { addNewRow, cleanTransactions, completeEditRow, refreshGridData, validateGrid } from '../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions';
import MetadataTabPanel from '../admin_metadata_management/MetadataTabPanel';
import { fetchCarClassGroupDropdownValues } from '../../store/admin/metadata_carclassgroup/CarClassGroup.Actions';
import { fetchAllPlansData, initializeRoleAction } from '../../store/topbar/AllPlans.Actions';
import {savePlanManagement,loadPlanManagementDropdownData} from '../../store/admin/planmanagement/PlanManagement.Actions';
import PlanManagementTabPanel from './planmanagement/PlanManagementTabPanel';
import { fetchModelMfrOemDropdownValues } from '../../store/admin/metadata_modelmfroem/ModelMfrOem.Actions';
import DialogBox from '../../components/Dialogcomponent/DialogComponent';
import SingleButtonDialogComponent from '../../components/Dialogcomponent/SingleButtonDialogComponent';
import { useHistory } from "react-router-dom";
import { getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { fetchPoolDropdownValues } from '../../store/admin/metadata_pool_management/PoolManagement.Actions';
import { fetchZoneMapDropdownValues } from '../../store/zonemap/ZoneMap.Actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={'simple-tabpanel-${index}'}
        aria-labelledby={'simple-tab-${index}'}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  const tabStyle = {
    default_tab: {
      marginLeft: "5px",
      borderRadius: "4px 4px 0px 0px",
      color: "white",
      background: "black",
      fontSize: 14,
      textTransform: 'none',
    },
    active_tab: {
      background: "white",
      marginLeft: "5px",
      borderColor: "white",
      borderRadius: "4px 4px 0px 0px",
      color: "black",
      fontSize: 14,
      textTransform: 'none'
    },
}

const getStyle = (isActive, tabKey) => {    
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
}

export default function AdminPage(props){
  const [value, setValue] = useState(0);
  const [refreshCells, setRefreshCells] = useState(false);
  const [showSaveOption, setShowSaveOption] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [saveCancelDisabled, setSaveCancelDisabled] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [snackBarIfError, setSnackBarIfError] = useState(false);
  const [bulkRowDeleteIconDisabled, setBulkRowDeleteIconDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const dispatch = useDispatch();
  const history =useHistory();
  const pending = useSelector((state) => {
    // return state.rootProgramsReducer.columnDefinitionReducer.pending;
    for (var x in state) {
      for (var y in state[x]) {
        for (var z in state[x][y]) {
          if (z === 'pending' && state[x][y][z]) {
            return true;
          }
        }
      }
    }
    return false;
  });
  const roleName = useSelector((reduxstate) => {
      return reduxstate.rootAllPlansReducer.allPlansReducer.userRoleName;
  })

  var userManagementData = useSelector((reduxState) => {
    return reduxState.rootAdminUserManagementReducer.userManagementReducer.userManagementData;
  });
  var userManagementReducerError = useSelector((reduxState) => {
    return reduxState.rootAdminUserManagementReducer.userManagementReducer.error;
  });
  
  const userManagementGridReducer = useSelector((reduxState) => {
    return reduxState.rootAdminUserManagementReducer.userManagementGridReducer;
  });
  
  const userManagementcolumnDefinitionReducer = useSelector((reduxState) => {
    return reduxState.rootAdminUserManagementReducer.columnDefinitionReducer;
  });

  var allPlansData = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.allplans;
  });

  const planManagementGridReducer = useSelector((reduxState) => {
    return reduxState.rootAdminPlanManagementReducer.planManagementGridReducer;
  });

  const planManagementcolumnDefinitionReducer = useSelector((reduxState) => {
    return reduxState.rootAdminPlanManagementReducer.columnDefinitionReducer;
  });

  const planRowDataInvalid = useSelector((reduxState) => {
    return reduxState.rootAdminPlanManagementReducer.planManagementGridReducer.isAnyRowInvalid;
  });

  const  planTransactionSaveSuccessful = useSelector((reduxState) => {
    return reduxState.rootAdminPlanManagementReducer.planManagementReducer.transactionSaveSuccess;
  })
  const  planDeleteSuccessful = useSelector((reduxState) => {
    return reduxState.rootAdminPlanManagementReducer.planManagementReducer.planDeleteSuccessful;
  })
  const  error = useSelector((reduxState) => {
    return reduxState.rootAdminPlanManagementReducer.planManagementReducer.error;
  })

  const carClassGroupTransactionError = useSelector((state) => {
    return state.rootCarClassGroupReducer.CarClassGroupReducer.error;
  });

  const modelMfrOemTransactionError = useSelector((state) => {
    return state.rootModelMfrOemReducer.ModelMfrOemReducer.error;
  });    

  const poolsTransactionError = useSelector((state) => {
    return state.rootPoolManagementReducer.PoolsManagementReducer.error;
  });  
  
  const fetchValidTransactions = (transactions) => {
    const filterValidTransactions = transactions.filter(item => {
      return (((item && item.data) && (item.data.rowStatus != "ERROR_ROW" && item.data.rowStatus != "ERROR_COPY_ROW" &&
        item.data.rowStatus != "ERROR_NEW_ROW" && item.data.rowStatus != "INVALID_ROW" && item.data.rowStatus != "INVALID_NEW_ROW") ||
        (item && !item.data) && item.type == "delete"))
    })
    return filterValidTransactions;
  }

  const fetchInvalidTransactions = (transactions) => {
    const filterInValidTransactions = transactions.filter(item => {
      return ((item && item.data) && (item.data.rowStatus == "ERROR_ROW" || item.data.rowStatus == "ERROR_COPY_ROW" ||
        item.data.rowStatus == "ERROR_NEW_ROW" || item.data.rowStatus == "INVALID_ROW" || item.data.rowStatus == "INVALID_NEW_ROW") ||
        (item && !item.data) && item.type == "add")
    })
    return filterInValidTransactions;
  }

  const loadUserManagementData = useCallback(async()=>{
      try{
        await dispatch(fetchUserManagementData());
      }
      catch(err){
       console.log("Error while loading User Management data")
      }
  },[dispatch])
  
  useEffect(()=>{
   loadUserManagementData();
   dispatch(fetchCarClassGroupDropdownValues());
   dispatch(fetchModelMfrOemDropdownValues());
   dispatch(fetchPoolDropdownValues());
   dispatch(fetchZoneMapDropdownValues());
  },[])

  const handleChange = (event, tabKey)=>{
    setValue(tabKey)
    setShowSaveOption(false);
    if(tabKey == 0){
      loadUserManagementData();
    }else if(tabKey == 1){
      dispatch(loadPlanManagementDropdownData());
    }
  }

  const handleRowEditingStarted = (isRowEditing, isOfTypeCopyOrDelete,transactions) => {   
      if (isRowEditing && transactions.length>0) {
          setShowSaveOption(true);
          if (!saveCancelDisabled && !isOfTypeCopyOrDelete)
              setSaveCancelDisabled(true);
      }
  }

  const handleRowEditingStopped = (isRowEditing) => {
      if (isRowEditing) {
          setShowSaveOption(false);
      }
  }

  const handleCellEditingStopped = () => {
      setSaveCancelDisabled(false);
  }

  const handleMultipleRowDelete = (selectedRows) => {
    if (selectedRows.length > 0) {
      setBulkRowDeleteIconDisabled(false);
      setSelectedRows(selectedRows);
    } else {
      setBulkRowDeleteIconDisabled(true);
      setSelectedRows(selectedRows)
    }
  }
  
  const userMessage = (severity, message) => {
    setWarningSnackBarOpen(true);
    setSeverity(severity);
    setWarningMessage(message);
  }

  const handleAddNewRow = (gridName) => {
    setShowSaveOption(true);
    dispatch(addNewRow(gridName, 0, []));
  }
  
  const handleCloseWarningSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningSnackBarOpen(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleDialogClose = (event) =>{    
    if(dialogTitle===ADMIN_ACCESS_REVOKED_ERROR_CODE){
      handleAdminAccessRevokactionDialogClose(event);
    }
  }

  const resetAdminAccess=async()=>{
    const user=await getAccessTokenNUserDetailsAfterAuth();
    // console.log("User details==",user);
    dispatch(initializeRoleAction(user.name, user.email, user.preferred_username));
  }

  const handleAdminAccessRevokactionDialogClose=(event)=>{
    // console.log("handleAdminAccessRevokactionDialogClose....");
    resetAdminAccess();
    setWarningMessage(null);
    setErrorDialogOpen(false);
    setDialogTitle("");    

    history.push('/');
  }

  const handleAdminAccessRevocation=(gridName)=>{
    setWarningMessage("Admin Access Revoked. Please click OK to continue.");    
    setDialogTitle(ADMIN_ACCESS_REVOKED_ERROR_CODE);
    setErrorDialogOpen(true);

    switch(gridName){
      case 'usermanagementgrid': 
        dispatch(cleanTransactions('usermanagementgrid'));
        //Not refreshing data because of Redirection to all plans page on OK
        break;
      case 'planmanagementgrid':
        dispatch(cleanTransactions('planmanagementgrid'));
        //Not refreshing data because of Redirection to all plans page on OK
        break;
      default:
        console.log(`gridName ${gridName} not considered for access revokation`);
    }
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSaveClicked = (gridName) => {
    // setSnackBarIfError(!snackBarIfError);
    if (gridName === 'usermanagementgrid') {
      setIsSaveClicked('usermanagementgrid');
      dispatch(validateGrid("usermanagementgrid", userManagementGridReducer.currentData, userManagementcolumnDefinitionReducer.validationFn, userManagementcolumnDefinitionReducer.businessValidationFn));
    }
    if (gridName === 'planmanagementgrid') {
      setIsSaveClicked('planmanagementgrid');
      dispatch(validateGrid("planmanagementgrid", planManagementGridReducer.currentData, planManagementcolumnDefinitionReducer.validationFn, planManagementcolumnDefinitionReducer.businessValidationFn));
    } 
  }

  useEffect(() => {
    if(planManagementGridReducer.transactions.length>0){
    dispatch(savePlanManagement(planManagementGridReducer.transactions));
    }
  }, [planRowDataInvalid, isSaveClicked, snackBarIfError]);

  useEffect(() => {
    //const filterValidTransactions = fetchValidTransactions(gridReducer.transactions);

    if (planTransactionSaveSuccessful && isSaveClicked) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);
      userMessage('success', 'Successfully saved rows')
      // if (filterValidTransactions && filterValidTransactions.length > 0) {
      //   if (programsRowDataInvalid) {
      //     userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
      //   } else {
      //     userMessage('success', 'Successfully saved rows')
      //   }
      // }
      dispatch(cleanTransactions('planmanagementgrid'));
      dispatch(refreshGridData('planmanagementgrid'));
      dispatch(validateGrid("planmanagementgrid", planManagementGridReducer.currentData, planManagementcolumnDefinitionReducer.validationFn, planManagementcolumnDefinitionReducer.businessValidationFn));
    }
    else if (planTransactionSaveSuccessful === false && isSaveClicked) {
      if(error){
        if(ADMIN_ACCESS_REVOKED_ERROR_CODE===error){
          handleAdminAccessRevocation('planmanagementgrid');
        }
        userMessage('error', error);
      }else{
        console.log("Error==",error);
        alert('Unable to save transactions. Please try again.');
      }
    }
  }, [planTransactionSaveSuccessful]);

  useEffect(()=>{
    if(planDeleteSuccessful===true){
      userMessage('success', 'Successfully deleted plan.')
    }
    if(planDeleteSuccessful===false){
      if(error){
        if(ADMIN_ACCESS_REVOKED_ERROR_CODE===error){
          handleAdminAccessRevocation('planmanagementgrid');
        }
        userMessage('error', error);
      }else{
        userMessage('error', 'Failed to delete plan.');
      }
      
    }
  },[planDeleteSuccessful]);

  useEffect(()=>{
    // console.log("carClassGroupTransactionError==",carClassGroupTransactionError);
    if(carClassGroupTransactionError){
      userMessage('error', carClassGroupTransactionError);
      if(ADMIN_ACCESS_REVOKED_ERROR_CODE===carClassGroupTransactionError){
        handleAdminAccessRevocation('carclassgroupgrid');
      }
    }
  },[carClassGroupTransactionError]);

  useEffect(()=>{
   // console.log("modelMfrOemTransactionError==",modelMfrOemTransactionError);
    if(modelMfrOemTransactionError){
      userMessage('error', modelMfrOemTransactionError);
      if(ADMIN_ACCESS_REVOKED_ERROR_CODE=== modelMfrOemTransactionError){
        handleAdminAccessRevocation("modelmfroemgrid");
      }
    }
  },[modelMfrOemTransactionError]);

  useEffect(()=>{
    // console.log("adminPoolsTransactionError==",adminPoolsTransactionError);
     if(poolsTransactionError){
       userMessage('error', poolsTransactionError);
       if(ADMIN_ACCESS_REVOKED_ERROR_CODE=== poolsTransactionError){
         handleAdminAccessRevocation(ADMINPOOLSGRID);
       }
     }
   },[poolsTransactionError]);

  
 
  useEffect(()=>{
   if(userManagementGridReducer.transactions.length>0 || planManagementGridReducer.transactions.length>0 ){
    setShowSaveOption(true);
    }else{
      setShowSaveOption(false);
    }
  },[userManagementGridReducer.transactions,planManagementGridReducer.transactions])

  useEffect(() => {
    if (isSaveClicked == "usermanagementgrid") {    
      // console.log(userManagementGridReducer.transactions)
      dispatch(saveUserManagement(userManagementGridReducer.transactions));
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setIsCancelClicked(true);
      userMessage('success', 'Successfully saved rows')
      dispatch(cleanTransactions('usermanagementgrid'));
      dispatch(refreshGridData('usermanagementgrid'));
  }
  }, [isSaveClicked]);

  const handleCancelClicked = (gridName) => {
    if (gridName === 'usermanagementgrid') {
      dispatch(cleanTransactions('usermanagementgrid'));
      dispatch(refreshGridData('usermanagementgrid'));
      dispatch(fetchUserManagementData());
    }
    if (gridName === 'planmanagementgrid') {
      dispatch(cleanTransactions('planmanagementgrid'));
      dispatch(refreshGridData('planmanagementgrid'));
      dispatch(fetchAllPlansData());
    }
    // if (gridName === 'programsgrid') {
    //   dispatch(cleanTransactions('programsgrid'));
    //   dispatch(refreshGridData('programsgrid'));
    //   dispatch(fetchProgramData(selectedPlanId));
    // } else if (gridName === 'constraintsgrid') {
    //    dispatch(cleanTransactions('constraintsgrid'));
    //    dispatch(refreshGridData('constraintsgrid', constraintsData));
    //    dispatch(fetchConstraintData(selectedPlanId));
    // } else if (gridName === 'parametersgrid') {
    //   dispatch(cleanTransactions('parametersgrid'));
    //   dispatch(refreshGridData('parametersgrid'));
    //   dispatch(populateGrid("parametersgrid", parametersData));
    // }
    // else if (gridName === 'upgradetemplatesgrid') {
    //   dispatch(cleanTransactions('upgradetemplatesgrid'));
    //   dispatch(refreshGridData('upgradetemplatesgrid'));
    //   dispatch(populateGrid("upgradetemplatesgrid", upgradeTemplatesData));
    // }
    // else if (gridName === 'upgradepathsgrid') {
    //   dispatch(cleanTransactions('upgradepathsgrid'));
    //   dispatch(refreshGridData('upgradepathsgrid'));
    //   dispatch(populateGrid("upgradepathsgrid", upgradePathData));
    // }
    // else if (gridName === 'schedulesgrid') {
    //   dispatch(cleanTransactions('schedulesgrid'));
    //   dispatch(refreshGridData('schedulesgrid'));
    //   dispatch(fetchSchedulesData(selectedPlanId));
    // }
    // else if (gridName === 'allocatedgrid') {
    //   dispatch(cleanTransactions('allocatedgrid'));
    //   dispatch(refreshGridData('allocatedgrid'));
    //   dispatch(fetchAllocatedData(selectedPlanId));
    // }
    // else if (gridName === 'moveconstraintsgrid') {
    //   dispatch(cleanTransactions('moveconstraintsgrid'));
    //   dispatch(refreshGridData('moveconstraintsgrid'));
    //   dispatch(fetchMoveConstraintsData(selectedPlanId));
    // }
    // setRefreshCells(!refreshCells);
    setShowSaveOption(false);
    setIsSaveClicked(null);
    setIsCancelClicked(true);
    // dispatch(validateGrid("constraintsgrid", constraintsData, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
    // dispatch(validateGrid("programsgrid", programData.programs, programgridcoldef.validationFn, programgridcoldef.businessValidationFn));
    // dispatch(validateGrid("parametersgrid", parametersData, parametersGridColDef.validationFn, parametersGridColDef.businessValidationFn));
    // dispatch(validateGrid("upgradepathsgrid", upgradePathData, upgradePathColumnDef.validationFn, upgradePathColumnDef.businessValidationFn));
    // dispatch(validateGrid("upgradetemplatesgrid", upgradeTemplatesData, templatesGridColumnDef.validationFn, templatesGridColumnDef.businessValidationFn));
    // dispatch(validateGrid("schedulesgrid", schedulesData, schedulesGridColDef.validationFn, schedulesGridColDef.businessValidationFn));
    // dispatch(validateGrid("allocatedgrid", allocatedData, allocatedGridColDef.validationFn, allocatedGridColDef.businessValidationFn));
    // dispatch(validateGrid("moveconstraintsgrid", moveConstraintsData, moveConstraintsGridColDef.validationFn, moveConstraintsGridColDef.businessValidationFn));
  }

  useEffect(()=>{
    // console.log(`userManagementReducerError was updated....${userManagementReducerError}`);
    if(userManagementReducerError!==null){      
      if(ADMIN_ACCESS_REVOKED_ERROR_CODE===userManagementReducerError){
        handleAdminAccessRevocation('usermanagementgrid');
      }
      userMessage('error',userManagementReducerError);
    }
  },[userManagementReducerError]);

  function downloadCSV(csvStr, filename) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }

  if(roleName==="admin"){
        return(
          <div className='adminpage'>
              <h2 className="headertext">Admin</h2>
              <NttTabs 
              indicatorColor='string'
              value={value} 
              onChange={handleChange} 
              style={tabStyle.tabItemContainer}>
                {adminTabs.map((tabInfo, index) => (
                  <NttTab label={tabInfo.tabTitle}
                    id={`simple-tab-${index}`}
                    key={tabInfo.tabTitle}
                    style={getStyle(value === index,tabInfo.tabKey)}
                    disabled={showSaveOption || pending} 
                    />
                ))}
              </NttTabs>
              {adminTabs.map((tabInfo, index)=>(
                <TabPanel key={index} value={value} index={index}>
                  {index==0 ? 
                      <UserManagementTabPanel
                          userManagementData={userManagementData}
                          refreshCells={refreshCells}
                          gridReducer={userManagementGridReducer}
                          columnDefinitionReducer={userManagementcolumnDefinitionReducer}
                          tabInfo={tabInfo} tabIndex={value} 
                          transactions={userManagementGridReducer.transactions}
                          currentData={userManagementGridReducer.currentData}
                          showSaveOption={showSaveOption} 
                          //handleAddNewRow={(e) => handleAddNewRow('usermanagementgrid')}
                          handleSaveClicked={(e) => handleSaveClicked('usermanagementgrid')} handleCancelClicked={(e) => handleCancelClicked('usermanagementgrid')}
                          handleRowEditingStarted={handleRowEditingStarted} handleRowEditingStopped={handleRowEditingStopped}
                          handleSaveCancelDisabled={saveCancelDisabled}
                          handleCellEditingStopped={handleCellEditingStopped}
                          //selectedPlanId={selectedPlanId}
                          //deleteSelectedRows={(e) => deleteSelectedRows('usermanagementgrid')}
                          //bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                          //handleMultipleRowDelete={handleMultipleRowDelete} selectedRows={selectedRows}
                          //filteredData={schedulesFilterData}
                          //filterParams={SchedulesFilterParams}
                          //downloadCSV={downloadCSV}                          
                          //isFiltered={isFiltered}
                          //setIsFiltered={setIsFiltered}
                          // isSearchBarValueExists={isSearchBarValueExists}
                          // setIsSearchBarValueExists={setIsSearchBarValueExists} 
                          >
                        </UserManagementTabPanel>
                        :index==1 ? 
                        <PlanManagementTabPanel
                        showSaveOption={showSaveOption} 
                        refreshCells={refreshCells}
                        //handleAddNewRow={(e) => handleAddNewRow('usermanagementgrid')}
                        handleSaveClicked={(e) => handleSaveClicked('planmanagementgrid')} handleCancelClicked={(e) => handleCancelClicked('planmanagementgrid')}
                        handleRowEditingStarted={handleRowEditingStarted} handleRowEditingStopped={handleRowEditingStopped}
                        handleSaveCancelDisabled={saveCancelDisabled}
                        handleCellEditingStopped={handleCellEditingStopped}
                        columnDefinitionReducer= {planManagementcolumnDefinitionReducer}
                        gridReducer={planManagementGridReducer}
                        allPlansData={allPlansData}>
                        </PlanManagementTabPanel>
                        :(index === 2) ?
                        (<div>
                          <MetadataTabPanel 
                            handleAddNewRow={handleAddNewRow}
                            showSaveOption={showSaveOption} 
                            refreshCells={refreshCells}
                            handleRowEditingStarted={handleRowEditingStarted} 
                            handleRowEditingStopped={handleRowEditingStopped}
                            handleSaveCancelDisabled={saveCancelDisabled}
                            handleCellEditingStopped={handleCellEditingStopped}
                            bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                            handleMultipleRowDelete={handleMultipleRowDelete} 
                            selectedRows={selectedRows}
                            downloadCSV={downloadCSV}
                            pending={pending}
                            userMessage={userMessage}
                            fetchValidTransactions={fetchValidTransactions}
                            fetchInvalidTransactions={fetchInvalidTransactions}
                            isSaveClicked={isSaveClicked}
                            setIsSaveClicked={setIsSaveClicked}
                            setSnackBarOpen={setSnackBarOpen}
                            setShowSaveOption={setShowSaveOption}
                            setSnackBarIfError={setSnackBarIfError}
                            setRefreshCells={setRefreshCells}
                            setIsCancelClicked={setIsCancelClicked}
                            snackBarIfError={snackBarIfError}
                            setWarningSnackBarOpen={setWarningSnackBarOpen}
                             >
                          </MetadataTabPanel>
                        </div>) 
                   :<div></div>}
                </TabPanel>
              ))}
              <div>
        <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleCloseSnackBar}>
          <Alert
            onClose={handleCloseSnackBar}
            severity="error">
            Cannot save. Please review if any row is invalid.
          </Alert>
        </Snackbar>
      </div>
      <div>
        <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
          <Alert
            onClose={handleCloseWarningSnackBar}
            severity={severity}>{warningMessage}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <SingleButtonDialogComponent open={errorDialogOpen} handleOk={handleDialogClose}
          dialogMessage={warningMessage} title={dialogTitle} action="Ok">
        </SingleButtonDialogComponent>
      </div>
          </div>
        )
  }else if(roleName===""){ 
    return(
      <div className='adminpage'>
          <h2 className="headertext">Admin</h2>
          <TabPanel key={0} value={value} index={0}>
            <div>Validating role...</div>
          </TabPanel>
      </div>
    )
  }else{
    return(
      <div className='adminpage'>
          <h2 className="headertext">Admin</h2>
          <TabPanel key={0} value={value} index={0}>
            <div>No Access. Please contact Admin</div>
          </TabPanel>
      </div>
    )
  }
}