import buyStatusData from './../../data/buyStatus.json';
import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention, cloneAndRemoveUnnecessaryData, minifyTransaction } from '../../utils/utils';
import { customCloneObject } from '../../utils/AdjustmentsUtils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function fetchPrograms(planId,pageno) {
       try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
    const promiseProgramsResponse = await client.apis.plans.getAllPrograms({"planid":planId?planId:'master','pageno':pageno ? pageno:0});
    const responsesArray = await Promise.all([promiseProgramsResponse]);
    const [programsResponse] = responsesArray.map(o => o.body.data);
    //return programsResponse.body.data;
    // this if condition is true when it is new version
    if(JSON.parse(localStorage.getItem("newArchitecture"))){
        return ({
            "gridData": programsResponse.rows,"rowcount":programsResponse.rowCount
        });
    }
    else{
        //this else condition executes when it is older version
        return programsResponse;
    }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for programs tab'
        //return { 'Error': 'fetchPrograms failed' }
    }
}
// export function deleteProgram(id) {
//     programData.data.map((value,index)=>{
//         let res =[]
//         if(value.ID === id){
//             res= programData.data.splice(index,1);
//         }
//         return [...res];
//     })
//     return {"response":'success'};
// }

export async function deleteProgram(planid, id) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = client.apis.plans.deleteProgram({"planid":planid?planid:"master","programid":id});
    return response;
}

export async function deleteAllProgramsData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const programsDeleteResponse = await client.apis.plans.deleteAllProgramRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });
        if(programsDeleteResponse.obj && programsDeleteResponse.obj.statusCode !== '500'){
            return programsDeleteResponse;
        }
        else{
            throw Error();
        }  
    }
    catch (error) {
        console.error(error);
        throw 'Programs tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}

export async function fetchProgramsDropdownService() {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

    const promiseGetAllModelGroups =  checkAndLoadDimention("modelGroup", client);
    const promiseGetCarClasses =  checkAndLoadDimention("carClassCode", client);
    const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    const promisegetAllSPICodes =  client.apis.dimensions.getAllSPICodes();
    const promisegetAcceptableZones =  checkAndLoadDimention("acceptablezones", client);
    const promiseprogramtypeResponse =  checkAndLoadDimention("programType", client);
    const promiseAllManufacturergroupModelgroupResponse =  checkAndLoadDimention("AllManufacturergroupModelgroup", client);
    const promiseGetAllAdminModelGroups =  checkAndLoadDimention("adminModelGroup", client);
    const promiseGetAdminCarClasses =  checkAndLoadDimention("admincarclassAndgroup", client);
    const promiseGetAdminManufacturerGroup =  checkAndLoadDimention("adminManufacturer", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([promiseGetAllModelGroups
                                                , promiseGetCarClasses
                                                , promiseGetManufacturerGroup
                                                , promisegetAllSPICodes
                                                , promisegetAcceptableZones
                                                , promiseprogramtypeResponse
                                                , promiseAllManufacturergroupModelgroupResponse
                                                , promiseGetAllAdminModelGroups
                                                , promiseGetAdminCarClasses
                                                , promiseGetAdminManufacturerGroup
                                            ]);

    const [modelgroups
            , carclasses
            , manufacturerGroups
            , spicodes
            , acceptableZones
            , programtypeResponse
            , allManufacturergroupModelgroupResponse
            , adminModelgroups
            , adminCarClasses
            , adminManufacturerGroups
        ] = responsesArray.map(o=>o.body.data);

    return ({
        "programType": programtypeResponse, "manufacturer": manufacturerGroups,
        "spiCode": spicodes, "carClassCode": carclasses,
        "modelGroup": modelgroups, "zone": acceptableZones,
        "buyStatus": buyStatusData.BUY_STATUS,
        "allManufacturergroup_Modelgroup":allManufacturergroupModelgroupResponse,
        "adminModelGroup":adminModelgroups,
        "adminCarClass":adminCarClasses,
        "adminManufacturerGroup":adminManufacturerGroups,
        "users":promiseUserManagementEmailAndIdMap
    })

}

export async function getProgramsFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseProgramsFilterResponse = await client.apis.plans.getProgramsFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseProgramsFilterResponse]);
        const [programsResponse] = responsesArray.map(o => o.body.data);

        return({
            "gridData": programsResponse.rows,"rowcount":programsResponse.rows[0]?programsResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'Programs tab has internal server error, please do re-apply the filter'
        //return { 'Error': 'fetchPrograms failed' }
    }
}

export async function uploadProgramCSV(planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadPrograms({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Program tab, Please try again!!');
    }
}

export async function insertS3UploadProgramCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedProgramData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Program tab, Please try again!!');
    }
}

export async function saveTransactions(planid, transactionList) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
/*     transactionList.forEach(element => {
        if(element.type == 'edit'){
            promiseArray.push(client.apis.plans.updateProgram({"planid":planid?planid:"master","programid":element.id},{
                requestBody: { "updatedBy": userEmail,"data":element.data }
            }));
        }
        else if(element.type == 'add' || element.type == 'copy'){
            promiseArray.push(client.apis.plans.createProgram({"planid":planid?planid:"master"},{
                requestBody: { "updatedBy": userEmail,"data":element.data }
            }));
        }
        else if(element.type == 'delete'){
            promiseArray.push(client.apis.plans.deleteProgram({"planid":planid?planid:"master",programid:element.id}));
        }
    });
 */
    
    await client.apis.plans.bulktransactprograms({"planid":planid?planid:"master"},  {requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) }});

    const responsesArray = await Promise.all(promiseArray);
    
    return responsesArray;
}

//CRUD operations in the record

export async function getProgramTabErrorStatus(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseProgramResponse = await client.apis.plans.getProgramTabErrorStatus({"planid":planid?planid:1});
    if(promiseProgramResponse.obj && promiseProgramResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseProgramResponse]);
    const [programTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return programTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Program tab error status, Please try again!!';
   }
   }

   export async function programTabValidationService(planid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.plans.validateProgram({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate Program tab, Please try again!!'
    }
   }

   export async function getProgramsErrorData(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseProgramErrorResponse = await client.apis.plans.getProgramErrorRecords({"planid":planid?planid:1 ,'pageno':pageno});
        const responsesArray = await Promise.all([promiseProgramErrorResponse]);
        const [ProgramResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": ProgramResponse.rows, "rowcount": ProgramResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch error data in Programs tab, Please try again!!'
     }
}

export async function getProgramsSearchData(planid, pageno, searchParams) {
    try {
            const accessToken = await getAccessTokenAfterAuth();
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
            const promiseProgramsSearchResponse = await client.apis.plans.getProgramsSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno},{requestBody: {'searchParams':searchParams }});
            const responsesArray = await Promise.all([promiseProgramsSearchResponse]);
            const [programsSearchResponse] = responsesArray.map(o => o.body.data);
            return ({
               "gridData": programsSearchResponse.rows,"rowcount":programsSearchResponse.rows[0]?programsSearchResponse.rows[0].rowcount:0
            });   
    }
    catch (error) {
        console.error(error);
        throw 'Unable to search data in Programs tab, Please try again!!'
    }
}