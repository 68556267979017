import {getAdditionalCostData, saveAdditionalCostTransactions, fetchAdditionalCostDropdownService,uploadAdditionalCostCSV} from '../../services/additionalcost/AdditionalCostService';
import {FETCH_ADDITIONALCOST_DATA_PENDING, FETCH_ADDITIONALCOST_DATA_SUCCESS, FETCH_ADDITIONALCOST_DATA_ERROR, UPLOAD_ADDITIONALCOST_PENDING, UPLOAD_ADDITIONALCOST_SUCCESS, UPLOAD_ADDITIONALCOST_ERROR,
    SAVE_ADDITIONALCOST_TRANSACTION_PENDING, SAVE_ADDITIONALCOST_TRANSACTION_SUCCESS, SAVE_ADDITIONALCOST_TRANSACTION_ERROR,
    FETCH_ADDITIONALCOST_DROPDOWNVALUES_PENDING,FETCH_ADDITIONALCOST_DROPDOWNVALUES_SUCCESS,FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR, RESET_TO_ADDITIONALCOST_INITIALSTATE} from '../../store/additionalcost/AdditionalCost.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchAdditionalCostData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_ADDITIONALCOST_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getAdditionalCostData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_ADDITIONALCOST_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_ADDITIONALCOST_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_ADDITIONALCOST_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadAdditionalCostData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_ADDITIONALCOST_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadAdditionalCostCSV(planId, files);
            let additionalCostData = await getAdditionalCostData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_ADDITIONALCOST_SUCCESS, additionalCostData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_ADDITIONALCOST_ERROR, "additional cost upload failed"));
                dispatch(SetAssumptionError("additional cost upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_ADDITIONALCOST_ERROR, "additional cost upload failed"));
            dispatch(SetAssumptionError("additional cost upload failed"))
        }
    }
}

export const saveAdditionalCostTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_ADDITIONALCOST_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveAdditionalCostTransactions(planid, transactionList);
            let additionalCostData = await getAdditionalCostData(planid);
            if (additionalCostData) {
                dispatch(serviceActionSuccess(SAVE_ADDITIONALCOST_TRANSACTION_SUCCESS, additionalCostData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_ADDITIONALCOST_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchAdditionalCostDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_ADDITIONALCOST_DROPDOWNVALUES_PENDING));
        let additionalCostData = await fetchAdditionalCostDropdownService(planid);
        if (additionalCostData) {
            dispatch(serviceActionSuccess(FETCH_ADDITIONALCOST_DROPDOWNVALUES_SUCCESS, additionalCostData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("additionalcostGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later
                dispatch(validateGrid("additionalcostgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR, additionalCostData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_ADDITIONALCOST_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetAdditionalCost = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_ADDITIONALCOST_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}