import React from 'react';
import axios from 'axios';
import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAllConstraints(planid,pageno) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseConstraintsResponse = await client.apis.plans.getAllConstraints({ "planid": planid ? planid : 'master','pageno':pageno ? pageno:0 });

    // this if condition is true when it is new version
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        const responsesArray = await Promise.all([promiseConstraintsResponse])
        const [constraintsResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": constraintsResponse.rows, "rowcount": constraintsResponse.rowCount
        })
    }
    else {
        //this else condition executes when it is older version
        const promiseZoneResponse = checkAndLoadDimention("zone", client);
        const promisePoolResponse = checkAndLoadDimention("pool", client);
        const promiseBUResponse = checkAndLoadDimention("BU", client);
        const promiseSPICodeResponse = client.apis.dimensions.getAllSPICodes();
        const promiseconstrainttypeResponse = checkAndLoadDimention("constrainttype", client);
        const promiseprogramtypeResponse = checkAndLoadDimention("programType", client);
        const promiseGetManufacturerGroup = checkAndLoadDimention("manufacturer", client);
        const promiseGetAllCarsGroup = checkAndLoadDimention("carGroup", client);

        const responsesArray = await Promise.all([promiseZoneResponse, promiseConstraintsResponse, promisePoolResponse, promiseBUResponse, promiseSPICodeResponse, promiseconstrainttypeResponse, promiseprogramtypeResponse, promiseGetManufacturerGroup, promiseGetAllCarsGroup]);

        const [zones, constraintsResponse, poolResponse, buResponse, spicodeResponse, constrainttypeResponse, programtypeResponse, manufacturerGroupsResponse, carsGroupResponse] = responsesArray.map(o => o.body.data);

        return ({
            "constraints": constraintsResponse, "constraint_type": constrainttypeResponse,
            "program_type": programtypeResponse, "car_group_name": carsGroupResponse, "spi_code": spicodeResponse,
            "bu": buResponse, "manufacturer_group": manufacturerGroupsResponse, "zones": zones, "pools": poolResponse
        }); 
    }
    // const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    // const promisePoolResponse =  checkAndLoadDimention("pool", client);
    // const promiseBUResponse =  checkAndLoadDimention("BU", client);
    // const promiseSPICodeResponse =  client.apis.dimensions.getAllSPICodes();
    // const promiseconstrainttypeResponse =  checkAndLoadDimention("constrainttype", client);
    // const promiseprogramtypeResponse =  checkAndLoadDimention("programType", client);
    // const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    // const promiseGetAllCarsGroup =  checkAndLoadDimention("carGroup", client);
   
    // const responsesArray = await Promise.all([promiseZoneResponse, promiseConstraintsResponse, promisePoolResponse, promiseBUResponse, promiseSPICodeResponse, promiseconstrainttypeResponse, promiseprogramtypeResponse, promiseGetManufacturerGroup, promiseGetAllCarsGroup]);

    // const [zones, constraintsResponse, poolResponse, buResponse, spicodeResponse, constrainttypeResponse, programtypeResponse, manufacturerGroupsResponse, carsGroupResponse] = responsesArray.map(o => o.body.data);

    // return ({
    //     "constraints": constraintsResponse, "constraint_type": constrainttypeResponse,
    //     "program_type": programtypeResponse, "car_group_name": carsGroupResponse, "spi_code": spicodeResponse,
    //     "bu": buResponse, "manufacturer_group": manufacturerGroupsResponse, "zones": zones, "pools": poolResponse
    // });
}

export async function fetchConstraintsDropDownValues(){
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseBUResponse =  checkAndLoadDimention("BU", client);
    const promiseSPICodeResponse =  client.apis.dimensions.getAllSPICodes();
    const promiseconstrainttypeResponse =  checkAndLoadDimention("constrainttype", client);
    const promiseprogramtypeResponse =  checkAndLoadDimention("programType", client);
    const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    const promiseGetAllCarsGroup =  checkAndLoadDimention("carGroup", client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseGetAdminManufacturerGroup =  checkAndLoadDimention("adminManufacturer", client);
    const promiseGetAdminCarGroup =  checkAndLoadDimention("adminCarGroup", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([promiseZoneResponse, promisePoolResponse, promiseBUResponse, promiseSPICodeResponse, promiseconstrainttypeResponse, promiseprogramtypeResponse, promiseGetManufacturerGroup, promiseGetAllCarsGroup
        , promiseAdminPoolResponse
        , promiseGetAdminCarGroup
        , promiseGetAdminManufacturerGroup]);

    const [zones, poolResponse, buResponse, spicodeResponse, constrainttypeResponse, programtypeResponse, manufacturerGroupsResponse, carsGroupResponse, adminPoolResponse, admincarGroupName
        , adminManufacturerGroups] = responsesArray.map(o => o.body.data);
    return ({
        "constraint_type": constrainttypeResponse,
        "program_type": programtypeResponse, "car_group_name": carsGroupResponse, "spi_code": spicodeResponse,
        "bu": buResponse, "manufacturer_group": manufacturerGroupsResponse, "zones": zones, "pools": poolResponse,
        "adminpools":adminPoolResponse,
        "admincarGroupName":admincarGroupName,
        "adminManufacturerGroup":adminManufacturerGroups,
        "users":promiseUserManagementEmailAndIdMap
    });

}

export async function getConstraintsFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseConstraintsFilterResponse= await client.apis.plans.getConstraintsFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseConstraintsFilterResponse]);
        const [constraintsResponse] = responsesArray.map(o => o.body.data);
        return({
            "gridData": constraintsResponse.rows,"rowcount":constraintsResponse.rows[0]?constraintsResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'constraints tab has internal server error, please do re-apply the filter'
    }
}

export async function fetchAllZones(planid) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    //TODO XM-385 avoid calling web service and pull the data from cache/local storage 
    const ZoneResponse = await checkAndLoadDimention("zone", client);
    return ZoneResponse.body.data;
}

export async function deleteConstraint(planid, id) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = client.apis.plans.deleteConstraint({ "planid": planid ? planid : "master", "constraintid": id });

    return response;
}

export async function deleteAllConstraintsData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const constraintsDeleteResponse = await client.apis.plans.deleteAllConstraintRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });
        if (constraintsDeleteResponse.obj && constraintsDeleteResponse.obj.statusCode !== '500') {
            return constraintsDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'constraints tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}

export async function updateConstraintTableOnUpload(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const updateResponse = await client.apis.plans.updateConstraintTableOnUpload({"planid":planid ? planid : 1 });
        if (updateResponse.obj && updateResponse.obj.statusCode !== '500') {
            return updateResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'constraints tab has internal server error, Please try again'
    }
}

export async function uploadConstraintsCSV(planid, file) {
    try {
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.uploadConstraints({ "planid": planid ? planid : 'master' }, {
            requestBody: {
                "updatedBy": userEmail,
                "file": file
            }
        });
        if(response.obj && response.obj.statusCode !== '500'){
        const updateResponse =await client.apis.plans.updateConstraintTableOnUpload({"planid":planid ? planid : 1 });
        if(updateResponse.obj && updateResponse.obj.statusCode !== '500'){
            return response
        }
        else{
            return updateResponse
        }
        }else{
            return response;
        }
        // return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Constraints tab, Please try again!!')
    }
}

export async function insertS3UploadConstraintsCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.insertS3UploadedConstraintsData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi,''),
                "fileName": filename
            }
        });
        return response;

    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Constraints tab, Please try again!!')
    }
}

export async function saveConstraintTransactions(planid, transactionList) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
/*     for (var i = 0; i < transactionList.length; i++) {
        if (transactionList[i].type == 'edit') {
            // promiseArray.push(client.apis.plans.updateConstraint({"planid":planid?planid:"master","constraintid":element.id},{
            //     requestBody: { "updatedBy": userEmail,"data":element.data }
            // }));
            await client.apis.plans.updateConstraint({ "planid": planid ? planid : "master", "constraintid": transactionList[i].id }, {
                requestBody: { "updatedBy": userEmail, "data": transactionList[i].data }
            });
        }
        else if (transactionList[i].type == 'add' || transactionList[i].type == 'copy') {
            // promiseArray.push(client.apis.plans.createConstraint({"planid":planid?planid:"master"},{
            //     requestBody: { "updatedBy": userEmail,"data":element.data }
            // }));
            await client.apis.plans.createConstraint({ "planid": planid ? planid : "master" }, {
                requestBody: { "updatedBy": userEmail, "data": transactionList[i].data }
            })
        }
        else if (transactionList[i].type == 'delete') {
            // promiseArray.push(client.apis.plans.deleteConstraint({"planid":planid?planid:"master","constraintid":element.id}));
            await client.apis.plans.deleteConstraint({ "planid": planid ? planid : "master", "constraintid": transactionList[i].id });
        }
    } */

    // console.log(`${JSON.stringify(transactionList)}`);
    await client.apis.plans.bulktransactconstraints({"planid":planid?planid:"master"},  {requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) }});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
}

export async function constraintsTabValidationService(planid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }}); 
       const response = await client.apis.plans.validateConstraints({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate Constraint tab, Please try again!!'
       //return {'Error':'constraintsvalidationservice failed'}
    }
   }
   
   export async function getConstraintsTabErrorStatus(planid,pageno){
       try{
       const accessToken = await getAccessTokenAfterAuth();
      
       const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
      
       const promiseConstraintsResponse = await client.apis.plans.getConstraintsTabErrorStatus({'planid':planid?planid:1});
           if (promiseConstraintsResponse.obj && promiseConstraintsResponse.obj.statusCode !== '500') {
               const responsesArray = await Promise.all([promiseConstraintsResponse]);
               const [constraintsTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
               return constraintsTabErrorStatusResponse;
           } else {
               throw Error();
           }
        }
       catch (error) {
          console.error(error);
          throw 'Unable to fetch the Constraint tab error status, Please try again!!';
          //return {'Error':'getConstraintsTabErrorStatus failed'}
      }
      }

export async function getConstraintsErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseconstraintResponse = await client.apis.plans.getConstraintErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promiseconstraintResponse]);
        const [constraintResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": constraintResponse.rows, "rowcount": constraintResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for constraint tab'
        //return { 'Error': 'fetchAllocated failed' }
    }
}

export async function getConstraintSearchData(planid,pageno,searchParams){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseConstraintSearchResponse = client.apis.plans.getConstraintSearchRecords({"planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseConstraintSearchResponse]);

        const [constraintSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": constraintSearchResponse.rows, "rowcount": constraintSearchResponse.rows[0] ? constraintSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Constraints tab'
    }
}

export async function fetchConstraintsTopLevelFilterOptions (planid){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        
        const promiseConstraintsTopLevelFilterOptionsResponse = await client.apis.plans.getConstraintsTopLevelFilterOptions({"planid": planid ? planid : 1, 'pageno': 0});
        if(promiseConstraintsTopLevelFilterOptionsResponse.obj && promiseConstraintsTopLevelFilterOptionsResponse.obj.statusCode !== '500'){
        const responsesArray = await Promise.all([promiseConstraintsTopLevelFilterOptionsResponse]);
        const [constraintTopLevelFilterResponse] = responsesArray.map(o => o.body.data);
       
        return constraintTopLevelFilterResponse.rows;
        }
        else{
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the top level filter options for Constraints tab'
    }
}