import { columnDefs, defaultColDef } from "./maxDeletes_grid_data/MaxDeletesColumnDefs";
import { FETCH_MAXDELETES_DROPDOWNVALUES_PENDING,FETCH_MAXDELETES_DROPDOWNVALUES_SUCCESS,FETCH_MAXDELETES_DROPDOWNVALUES_ERROR, SET_MAXDELETES_EDITABLE } from '../maxdeletes/MaxDeletes.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import { MaxDeleteColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, MaxDeleteColumns.ZoneName.headerName) },
            MODEL_GROUP: { error: validateModelGroup(data.MODEL_GROUP,data.CAR_GROUP,MaxDeleteColumns.ModelGroup.headerName,MaxDeleteColumns.CarGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===MaxDeleteColumns.ModelGroup.field)) },
            CAR_GROUP: { error: validateCarGroup(data.MODEL_GROUP,data.CAR_GROUP, MaxDeleteColumns.ModelGroup.headerName,MaxDeleteColumns.CarGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===MaxDeleteColumns.CarGroup.field)) },
            MAX_DELETES: { error: validateMaxDeleteFields(data.MAX_DELETES, MaxDeleteColumns.MaxDeletes.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }if(value==='ALL'){
        return name + " cannot be ALL";
    }
    return null;
}

function validateCarGroup(modelGroupValue,carGroupValue,modelGroupname,carGroupname,index){
    if(modelGroupValue && carGroupValue){
        return `Both ${modelGroupname} and ${carGroupname} cannot have values`;
    }
    else if(carGroupValue){
        if (initialState.columnDefs[index].cellEditorParams !== undefined) {
            if (initialState.columnDefs[index].cellEditorParams.options.indexOf(carGroupValue) == -1) {
                return `${carGroupname} ${carGroupValue} no-longer available`;
            }
        }
    }
    return null;
}

function validateModelGroup(modelGroupValue,carGroupValue,modelGroupname,carGroupname,index){
    if(modelGroupValue && carGroupValue){
        return `Both ${modelGroupname} and ${carGroupname} cannot have values`;
    }
    else if(modelGroupValue){
        if (initialState.columnDefs[index].cellEditorParams !== undefined) {
            if (initialState.columnDefs[index].cellEditorParams.options.indexOf(modelGroupValue) == -1) {
                return `${modelGroupname} ${modelGroupValue} no-longer available`;
            }
        }
    }
    return null;
}

function OptionalValue(value,name,data) {
    if((name == MaxDeleteColumns.ModelGroup.headerName || name == MaxDeleteColumns.CarGroup.headerName) && value){
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
                if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                    return name +" "+value+" no-longer available";
                }
            }
        }
    return null;
}

function validateMaxDeleteFields( max, displayName) {
    if ( max === undefined ||  max === '' ||  max === null) {
        return displayName + ' cannot be empty';
    }
   else if (max < 0) {
            return 'Please enter valid ' + displayName
        }
    return null;
}
function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) {
                if((itm.CAR_GROUP!==null && itm.CAR_GROUP!=="" && itm.CAR_GROUP!==undefined) || (itm.MODEL_GROUP!==null && itm.MODEL_GROUP!=="" && itm.MODEL_GROUP!==undefined)){
                if ((safeCaseInsensitiveCompare(itm.ZONE_NAME , itm2.ZONE_NAME)) && 
                (itm.CAR_GROUP === itm2.CAR_GROUP)&&(itm.MODEL_GROUP === itm2.MODEL_GROUP)){
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
            }else {
            if(
                (safeCaseInsensitiveCompare(itm.ZONE_NAME , itm2.ZONE_NAME)) 
                && ((itm.CAR_GROUP===null || itm.CAR_GROUP==="" || itm.CAR_GROUP===undefined ) && (itm.MODEL_GROUP===null || itm.MODEL_GROUP==="" || itm.MODEL_GROUP===undefined)) 
                && ((itm2.CAR_GROUP===undefined || itm2.CAR_GROUP==="" || itm2.CAR_GROUP===null) 
                    && (itm2.MODEL_GROUP===undefined||itm2.MODEL_GROUP==="" || itm2.MODEL_GROUP===null))
                ){
                unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                
            }
            }});
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            transactions.forEach((itm1,key)=>{
                if(itm1.data){
                  if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                    transactions[key].data.status=itm.status
                  }
                }
              })
    });
    return duplicate;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData=[];
    zones.forEach(val => {
        zoneData.push(val.name)
        zoneFilterData.push({ "label": val.name, "value": val.name })
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"});

    element.cellEditorParams = { options: zoneData };
    element.filterOptions = zoneFilterData;
}

function fetchDropdownForModelGroup(modelGroup,adminModelGroup,element) {
    let modelGroupFilterData = [];
    adminModelGroup.map((val) => {
        modelGroupFilterData.push({ "label": val, "value": val })
    })
    modelGroupFilterData.push({"label":"Blanks","value":"Blanks"});

    element.cellEditorParams = { options: modelGroup };
    element.filterOptions = modelGroupFilterData;
}

function fetchDropdownForcarGroupName(carGroupName, admincarGroupName, element) {
    let carGroupNameFilterData = []
    admincarGroupName.map((val) => {
        carGroupNameFilterData.push({ "label": val, "value": val })
    })
    carGroupNameFilterData.push({ "label": "Blanks", "value": "Blanks" });
    element.cellEditorParams = { options: carGroupName };
    element.filterOptions = carGroupNameFilterData;
}

function fetchZones(maxDeletesData, zones) {
    maxDeletesData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return maxDeletesData;
}

function fetchFilterValues(modelGroup, carGroupName, zones,adminModelGroup,admincarGroupName,users,columns) {
    columns.forEach(element => {
        if (element.field === MaxDeleteColumns.ZoneName.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field === MaxDeleteColumns.CarGroup.field) {
            fetchDropdownForcarGroupName(carGroupName,admincarGroupName,element);
        }else if (element.field === MaxDeleteColumns.ModelGroup.field) {
            fetchDropdownForModelGroup(modelGroup,adminModelGroup,element);
        }else if(element.field === MaxDeleteColumns.UpdatedBy.field){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'MaxDeletesActionRenderer'
            }
        } 
    });

    return columns;
}

export default function maxDeletesColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MAXDELETES_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_MAXDELETES_DROPDOWNVALUES_SUCCESS:
           // const maxDeletesData = fetchZones(action.data.maxDeletesData, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(
                    action.data.model_group, action.data.car_group_name,
                    action.data.zones,action.data.adminModelGroup,action.data.admincarGroupName,action.data.users, state.columnDefs)
            });
        case FETCH_MAXDELETES_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_MAXDELETES_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}