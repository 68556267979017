import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getMileageTableData(planId,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisemileageTableResponse =  await client.apis.plans.getAllMileageTable({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });
    
     // this if condition is true when it is new version
     if (JSON.parse(localStorage.getItem("newArchitecture"))) {

        const responsesArray = await Promise.all([promisemileageTableResponse]);
    
        const [mileageTableResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": mileageTableResponse.rows, "rowcount": mileageTableResponse.rowCount
        })
     }
     else{//this else condition executes when it is older version
        const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    
        const responsesArray = await Promise.all([promisemileageTableResponse,promiseZoneResponse]);
    
        const [mileageTableResponse,zones] = responsesArray.map(o => o.body.data);
        return ({
            "mileageTableData": mileageTableResponse,"zones": zones
        });
     }
    }catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Minimum Risk Delete Mileage tab'
    }
}

export  async function uploadMileageTableCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadMileageTable({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Minimum Risk Delete Mileage tab, Please try again!!');
        //return {'Error':'uploadMileageTableCSV failed'}
    }
}

export async function saveMileageTableTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.plans.bulktransactMileageTable({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchMileageTableDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const MileageTableResponseData =  client.apis.plans.getAllMileageTable({ "planid": planId ? planId : 'master' });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseGetAllCarsGroup =  checkAndLoadDimention("carGroup", client);
    const promiseGetAdminCarGroup =  checkAndLoadDimention("adminCarGroup", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
       // MileageTableResponseData,
        promiseZoneResponse,
        promiseGetAllCarsGroup,
        promiseGetAdminCarGroup
    ]);

    const [
         // mileageTableResponse,
          ZoneResponse,
          carsGroupResponse,
          admincarGroupName
    ] = responsesArray.map(o => o.body.data);

    return ({
      //  "mileageTableData":mileageTableResponse,
        "zone": ZoneResponse,
        "car_group_name": carsGroupResponse,
        "admincarGroupName":admincarGroupName,
        "users":promiseUserManagementEmailAndIdMap
    })  
}

export async function getMileageTableFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseMileageTableFilterResponse = await client.apis.plans.getMileageTableFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseMileageTableFilterResponse]);
        const [mileageTableResponse] = responsesArray.map(o => o.body.data);

        return({
            "gridData": mileageTableResponse.rows,"rowcount":mileageTableResponse.rows[0]?mileageTableResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'Minimum Risk Delete Mileage tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllMileageTableData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const mileageTableDeleteResponse = await client.apis.plans.deleteAllMileageTableRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });
        if(mileageTableDeleteResponse.obj && mileageTableDeleteResponse.obj.statusCode !== '500'){
            return mileageTableDeleteResponse;
        }
        else{
            throw Error();
        }  
    }
    catch (error) {
        console.error(error);
        throw 'Minimum Risk Delete Mileage tab has internal server error, Unable to delete all the records!!, Please try again'
    }
}

export async function insertS3UploadMileageTableCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedMileageTableData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Minimum Risk Delete Mileage tab, Please try again!!');
    }
}

export async function getMileageTableTabErrorStatus(planid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promiseMileageTableResponse = await client.apis.plans.getMileageTableTabErrorStatus({"planid":planid?planid:1});
    if(promiseMileageTableResponse.obj && promiseMileageTableResponse.obj.statusCode !== '500'){
    const responsesArray = await Promise.all([promiseMileageTableResponse]);
    const [mileageTableTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return mileageTableTabErrorStatusResponse;
    }
    else {
        throw Error();
    }
     }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Minimum Risk Delete Mileage tab error status, Please try again!!';
   }
   }

   export async function mileageTableTabValidationService(planid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.plans.validateMileageTable({"planid":planid?planid:1},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       throw 'Unable to validate Minimum Risk Delete Mileage tab, Please try again!!'
    }
   }
   export async function getMileageTableErrorData(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseMileageTableErrorResponse = await client.apis.plans.getMileageTableErrorRecords({"planid":planid?planid:1 ,'pageno':pageno});
        const responsesArray = await Promise.all([promiseMileageTableErrorResponse]);
        const [MileageTableResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": MileageTableResponse.rows, "rowcount": MileageTableResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch error data in Minimum Risk Delete Mileage tab, Please try again!!'
     }
}

export async function getMileageTableSearchData(planid, pageno, searchParams) {
    try {
            const accessToken = await getAccessTokenAfterAuth();
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
            const promiseMileageTableSearchResponse = await client.apis.plans.getMileageTableSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno},{requestBody: {'searchParams':searchParams }});
            const responsesArray = await Promise.all([promiseMileageTableSearchResponse]);
            const [mileageTableSearchResponse] = responsesArray.map(o => o.body.data);
            return ({
               "gridData": mileageTableSearchResponse.rows,"rowcount":mileageTableSearchResponse.rows[0]?mileageTableSearchResponse.rows[0].rowcount:0
            });   
    }
    catch (error) {
        console.error(error);
        throw 'Unable to search data in Minimum Risk Delete Mileage tab, Please try again!!'
    }
}