import { combineReducers } from 'redux';
import MileageTableReducer from './MileageTable.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import mileageTableColumnDefinitionReducer from "./MileageTable.ColumnDef.Reducer";

const rootMileageTableReducer = combineReducers({
    MileageTableReducer
    , mileageTableGridReducer: createNamedWrapperReducer(multiGridUIReducer, "mileagetablegrid")
    , mileageTableColumnDefinitionReducer
})

export default rootMileageTableReducer;