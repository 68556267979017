import { UpgradeTemplatesColumns, cellEditors } from "../../../Constants";

export const columnDefs = [
    {
        field: UpgradeTemplatesColumns.ZoneName.field, 
        headerName: UpgradeTemplatesColumns.ZoneName.headerName, 
        unSortIcon: true,editable: false, minWidth: 120,
    },
    {
        field: UpgradeTemplatesColumns.Jan.field,
        headerName: UpgradeTemplatesColumns.Jan.headerName, 
        unSortIcon: true,cellEditor: cellEditors.toggleButton,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Jan.name),
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Jan.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Feb.field,
        headerName: UpgradeTemplatesColumns.Feb.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Feb.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Feb.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Mar.field,
        headerName: UpgradeTemplatesColumns.Mar.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Mar.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Mar.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Apr.field,
        headerName: UpgradeTemplatesColumns.Apr.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Apr.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Apr.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.May.field,
        headerName: UpgradeTemplatesColumns.May.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.May.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 85,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.May.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Jun.field,
        headerName: UpgradeTemplatesColumns.Jun.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Jun.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Jun.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Jul.field,
        headerName: UpgradeTemplatesColumns.Jul.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Jul.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Jul.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Aug.field,
        headerName: UpgradeTemplatesColumns.Aug.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Aug.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Aug.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Sep.field,
        headerName: UpgradeTemplatesColumns.Sep.headerName, unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Sep.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Sep.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Oct.field,
        headerName: UpgradeTemplatesColumns.Oct.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Oct.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Oct.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Nov.field,
        headerName: UpgradeTemplatesColumns.Nov.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Nov.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Nov.name)
        },
    },
    {
        field: UpgradeTemplatesColumns.Dec.field,
        headerName: UpgradeTemplatesColumns.Dec.headerName, 
        unSortIcon: true,
        cellClassRules: cellClassRules(UpgradeTemplatesColumns.Dec.name),
        cellEditor: cellEditors.toggleButton,
        minWidth: 84,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradeTemplatesColumns.Dec.name)
        },
    },
    {
        headerName: "",
        colId: "templateactions",
        cellRenderer: "templateActionsRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 50,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field]) {
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        }
    }
}


function cellClassRules(field) {
    return {
        'typehotstyle': (params) => {
            if (params.value && params.value == 'Hot') {
                return params.value == 'Hot';
            }
        },
        'typecoldstyle': (params) => {
            if (params.value && params.value == 'Cold') {
                return params.value == 'Cold';
            }
        },
        'blankvaluestyle': (params) => {
            if (params.value == "" || params.value==null) {
                return params.value == null;
            }
        },
        'TogglebtnerrorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'TogglebtnvalidRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                //console.log("Print the templates: " + index);
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

export const defaultColDef = {
    editable: true,
    sortable: true,
    filter: false,
    resizable: false,
    floatingFilter: false,
    flex: 1,
};