import { combineReducers } from 'redux';
import ZoneMapReducer from './ZoneMap.Reducer';
import multiGridUIReducer from '../admin/AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import zoneMapColumnDefinitionReducer from "./ZoneMap.ColumnDef.Reducer";

const rootZoneMapReducer = combineReducers({
     ZoneMapReducer
    , zoneMapGridReducer: createNamedWrapperReducer(multiGridUIReducer, "zonemapgrid")
    , zoneMapColumnDefinitionReducer
})

export default rootZoneMapReducer;