import React, { useState, forwardRef, useImperativeHandle } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import './DateEditor.css';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';

import { getDateISOString } from "../../../../utils/utils";
import moment from "moment";



export default forwardRef((props, ref) => {
    const [selectedDate, setSelectedDate] = useState(props.value);//setting the existing date value as initial state value when component renders (when user double clicks on date column)
    const [open,setopen]=useState(false);
    
    function handleDateChange(d) {
         if (d) {
        //     var newDate = new Date(d);
        //     newDate.setUTCHours(12, 0, 0, 0);
            if(moment(d).format('YYYY-MM-DD')!==selectedDate){//this condition will be true when existing date value is not equal to user selected date value
                setSelectedDate(moment(d).format('YYYY-MM-DD')); // updating the state with new user selected date value
            }
        }else{
    
        // setSelectedDate(getDateISOString(newDate));
        setSelectedDate(null);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null;
                return selectedDate;
            },
            // isCancelAfterEnd: () => {
            //     return !selectedDate;
            // },
            afterGuiAttached: () => {
                if (!props.value) {
                    return;
                }
                // var newD = new Date(props.value);
                // var newDate = new Date(newD.getTime() - newD.getTimezoneOffset() * -60000);
                // newDate.setUTCHours(12,0,0,0);
                // setSelectedDate(getDateISOString(newDate));
                //setSelectedDate(moment(props.value));
                setopen(true);

            }
        };
    });

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                clearable={true}
                className="inputdatepicker"
                style={{ width: '150px', height: '28px', margin: '5px', border: 'solid 1px #babfc7', paddingLeft: '5px', borderRadius: '3px', overflow: 'hidden', backgroundColor: 'white' }}
                margin="normal"
                id="date-picker-dialog"
                format="dd-MM-yy"
                value={selectedDate ? moment(selectedDate).utc() :null }
                onChange={handleDateChange}
                onOpen={(e)=>{
                 setopen(true)
                 }}
                 onClose={(e)=>{
                 setopen(false)
                 }}
                //open={open}
                placeholder="DD-MM-YY"
                orientation="landscape"
                views={["year", "month", "date"]}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                variant='inline'
                autoOk={true}
                InputAdornmentProps={{ position: "start" }}
            />
        </MuiPickersUtilsProvider>
    )
});
