import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import diaglogstyles from './GenerateRfDialogComponent.css';
import icon_close from "../../assets/images/close.svg";
import { CircularProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { unEscapeHtml } from '../../utils/utils';

const GenerateRfDialogBox = (props) => {
    const { open, handleClose, handleYes, action,isError,setIsError, canceltext, noText,newRfPlanDescriptionText,setNewRfPlanDescriptionText } = props;
    
    const GridPending=useSelector((state)=>{
        return state.PaginationGridReducer.pending
      })

    const highlightAllTabsErrorStatus = useSelector((state)=>{
        return state.PaginationGridReducer.gridTabErrorStatus;
    })

    function handleClickYes() {
        if(newRfPlanDescriptionText.length<=0){
            setIsError(true)
        }else{
            handleYes();
        }
       
    }
    function onChangeHandler(e) {
        const { name, value } = e.target;
        if(value.length<=0){
            setIsError(true)
        }else{
            setIsError(false) 
        }
        setNewRfPlanDescriptionText(value)
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="dialogbox"
            >
                {
                (GridPending) ? 
                <>
                    <DialogContent>
                            <CircularProgress size={80}/>
                            <Typography variant="h5" color="error">Validation In Progress!! Please wait....</Typography>
                    </DialogContent>
                </>
                :
                <>
                    <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                        <span>Generate New Revenue Function</span>
                        <span><img src={icon_close} onClick={handleClose} className="closeicon" alt="iconclose"></img></span>
                    </DialogTitle>
                    <DialogContent>
                    <div className="generateRfTextbox" style={{'display':'flex','flexDirection':'column','margin-top':'-27px'}}>
                        <p>Revenue Function Description</p>
                        <TextField 
                        id="outlined-basic"
                        variant="outlined"
                        onChange={(e)=>onChangeHandler(e)}
                        value={newRfPlanDescriptionText}  
                        error={isError}
                        helperText={isError?"Enter description to start new RF run": ""}
                        />
                    </div>
                    </DialogContent>
                    <DialogActions className="actionbuttons">
                        {canceltext ? <Button className='cancelbutton' autoFocus onClick={handleClose} color="primary">
                            Cancel
                        </Button> : <Button className='cancelbutton' autoFocus onClick={handleClose} color="primary">
                            {noText}
                        </Button>}
                        <Tooltip title={Object.values(highlightAllTabsErrorStatus).includes(true)?"There are some issues in the grid!! Please fix them":''} placement="bottom">
                            <span><Button className={Object.values(highlightAllTabsErrorStatus).includes(true) ? 'buttonblur' : 'okbutton'} onClick={handleClickYes} color="primary" 
                            disabled={Object.values(highlightAllTabsErrorStatus).includes(true)}
                            >
                                {action}
                            </Button></span>
                        </Tooltip>
                    </DialogActions>
                </>
            }
            </Dialog>
            
        </div>
    )
}
export default GenerateRfDialogBox;