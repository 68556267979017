import { columnDefs, defaultColDef } from "./moveconstraints_grid_data/MoveConstraintsColumnDefs";
import { checkDate, safeCaseInsensitiveCompare } from "../../utils/utils";
import { FETCH_MOVE_CONSTRAINT_DROPDOWN_PENDING, FETCH_MOVE_CONSTRAINT_DROPDOWN_SUCCESS, FETCH_MOVE_CONSTRAINT_DROPDOWN_ERROR, SET_MOVE_CONSTRAINT_EDITABLE } from "./MoveConstraints.ActionTypes";
import { MoveConstraintsColumns, maximumDate, minimumDate} from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value)
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME,data.POOL, MoveConstraintsColumns.ZoneName.headerName) },
            POOL: { error: checkIfPool(data.POOL,data.ZONE_NAME, MoveConstraintsColumns.Pool.headerName,initialState.columnDefs.findIndex(itm=>itm.field===MoveConstraintsColumns.Pool.field)) },
            MONTH: { error: checkIfDatesMatch(data.MONTH, MoveConstraintsColumns.Month.headerName) },
            MIN_FROM: { error: validateMinMaxFields(data.MIN_FROM, data.MAX_FROM, MoveConstraintsColumns.MinFrom.headerName,MoveConstraintsColumns.MaxFrom.headerName, false) },
            MAX_FROM: { error: validateMinMaxFields(data.MIN_FROM, data.MAX_FROM, MoveConstraintsColumns.MinFrom.headerName,MoveConstraintsColumns.MaxFrom.headerName, false) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = findUniqueData(currentData,transactions);
        return duplicateData;
    }
}

function findUniqueData(data,transactions){
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.ZONE_NAME, itm2.ZONE_NAME) && safeCaseInsensitiveCompare(itm.POOL, itm2.POOL) && checkDate(itm.MONTH,itm2.MONTH)) {
                unique = false;
                if(itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if(itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function checkIfValueEmpty(value, pool, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    if(value==='ALL' && pool==='ALL'){
        return 'Both zone and pool cannot be ALL'
    }
    if(pool!==null && pool!=='ALL' && value!=='ALL'){
        return name + " should be ALL";
    }
    return null;
}
function checkIfPool(pool,zone,name,data) {
    if (pool === undefined || pool === "" || pool === null) {
        return name + " cannot be empty";
    }else
    if(pool==='ALL' && zone==='ALL'){
        return 'Both zone and pool cannot be ALL'
    }else
    if(zone!==null && zone!=='ALL' && pool!==null && pool!=='ALL'){
        return name + " should be ALL";
    }else
    if (initialState.columnDefs[data].cellEditorParams !== undefined) {
        if (initialState.columnDefs[data].cellEditorParams.options.indexOf(pool) == -1) {
            return name +" "+pool+" no-longer available";
        }
    }
    return null;
}

function checkIfDatesMatch(month,name) {
    if (month === undefined || month === '' || month === null) {
        return name+' Field cannot be empty';
    }else if(month == 'Invalid date'){
        return name +" "+"cannot be invalid date";
    }
    else if(!moment(month).isBetween(minimumDate, maximumDate,'year','[]')){
        return name + " should be in +5 or -10 range from the current year"
    }  
        // var itemValue = parseInt(month, 0);
        // if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
        //     return name + " should be in +5 or -10 range from the current year"
        // }
    
        return null;
}

function validateMinMaxFields(min, max, minDisplayName, maxDisplayName, isNegativeAllowed) {

    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Field cannot be empty';
    }
    if (!isNegativeAllowed) {
        if (min < 0 || max < 0) {
            return 'Please enter valid ' + minDisplayName + ' ' + maxDisplayName + ' volume'
        }
    } 
    if (parseInt(min) > parseInt(max)) {
        return minDisplayName + ' volume should be less than ' + maxDisplayName + ' volume'
    }
    return null;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" && value !== "id" &&  value !== 'plan_id' && value !== 'ZONE_CODE' && value !== 'MONTH' && value !== 'updated_by') {
        return true;
    } else {
        return false;
    }
}

function fetchZones(constraintsData, zones) {
    constraintsData.forEach((constraint) => {
        if (constraint.POOL === undefined) {
            constraint.POOL = "ALL";
        }
        zones.forEach((zone) => {
            if (zone.code == constraint.ZONE_CODE) {
                constraint.ZONE_NAME = zone.name;
            }
        });
        if (constraint.ZONE_CODE === undefined) {
            constraint.ZONE_NAME = "ALL";
        }
    })
    return constraintsData;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [], zoneFilterData=[];
    //zoneData.push("ALL")
    if(zones.length>0){
    zones.forEach(val => {
        zoneFilterData.push({"label":val.name,"value":val.name})
        //if (val.name.toUpperCase() != "ALL")
            zoneData.push(val.name)
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"})
}
    element.cellEditorParams = { options: zoneData }
    element.filterOptions=zoneFilterData
}

function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = []
    //poolData.push("ALL")
    if(pools.length>0){
    pools.forEach(val => {
        //if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        //}
    });
  }
  adminpools.forEach(val=>{
    poolFilterData.push({"label":val.code,"value":val.code})
  })
    poolFilterData.push({"label":"Blanks","value":"Blanks"})
   
    element.cellEditorParams = { options: poolData }
    element.filterOptions=poolFilterData
}

function fetchFilterValues(zones, pools,adminpools,users,columns) {       
    columns.forEach(element => {
        if (element.field === MoveConstraintsColumns.ZoneName.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field === MoveConstraintsColumns.Pool.field) {
            fetchDropdownsForPools(pools, adminpools,element);
        }else if (element.field === MoveConstraintsColumns.UpdatedBy.field) {
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
         }else if (element.colId === 'actions') {
              // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'MoveConstraintActionRenderer'
            }
        }
    });

    return columns;
}

export default function moveConstraintsColumnDefReducer(state = initialState, action){
    switch(action.type){
        case FETCH_MOVE_CONSTRAINT_DROPDOWN_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_MOVE_CONSTRAINT_DROPDOWN_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.zones, 
                    action.data.pools,action.data.adminpools, action.data.users,state.columnDefs)
            });
        case FETCH_MOVE_CONSTRAINT_DROPDOWN_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_MOVE_CONSTRAINT_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}