import {
    FETCH_GROUP_BY_TYPE_ZONE, FETCH_GROUP_BY_YEAR, FETCH_PROCESS_SALESADJUSTMENTS_RUN_ERROR, FETCH_PROCESS_SALESADJUSTMENTS_RUN_PENDING, FETCH_PROCESS_SALESADJUSTMENTS_RUN_SUCCESS, ADJUSTMENTS_ROW_ACTION,
    FETCH_SALES_ADJUSTMENT_DATA_PENDING, FETCH_SALES_ADJUSTMENT_DATA_SUCCESS, FETCH_SALES_ADJUSTMENT_DATA_ERROR,SET_DATA_WITH_ADJUSTEMENT_VALUE,UPDATE_ADJUSTMENTS_PENDING, ADJUSTMENTS_SET_LEVEL,
    UPDATE_ADJUSTMENTS_SUCCESS,UPDATE_ADJUSTMENTS_ERROR,FETCH_PLAN_ADJUSTMENT_DATA_ONUPDATE_PENDING,FETCH_PLAN_ADJUSTMENT_DATA_ONUPDATE_SUCCESS,FETCH_PLAN_ADJUSTMENT_DATA_ONUPDATE_ERROR,
    FETCH_ADJUSTMENTS_FILTER_DATA, SET_TABALL_FILTER_PARAMS, SET_CORETAB_FILTER_PARAMS, SET_COREICETAB_FILTER_PARAMS,SET_ADJUSTMENTS_EXPANDALL_FLAGS,SET_COREELECTAB_FILTER_PARAMS,SET_TNCTAB_FILTER_PARAMS,SET_TNCICETAB_FILTER_PARAMS,SET_TNCELECTAB_FILTER_PARAMS,
    SET_CARGOCTAB_FILTER_PARAMS,
    SET_BU_GROUP_VIEW_TYPE,
    SET_SALES_ACCORDIAN_CLICK,
    SET_SALES_ZONE_ACCORDIAN_CLICK,
    TOGGLE_EXAPND_ALL_IN_SALES,
    ENABLE_EDIT_ACTIVITY,
    UPDATE_SALES_ADJUSTMENT_ACTIVITY,
    FETCH_PROCESS_BVDEPOVERRIDE_RUN_PENDING, FETCH_PROCESS_BVDEPOVERRIDE_RUN_SUCCESS,FETCH_PROCESS_BVDEPOVERRIDE_RUN_ERROR,
    CANCEL_EDIT_ACTIVITY
} from "./SalesAndFinance.ActionTypes";
import {FETCH_ALLPLANS_DATA_PENDING, FETCH_ALLPLANS_DATA_SUCCESS, GET_ALLPLANS_TABLE_DATA, FETCH_ALLPLANS_DATA_ERROR, FETCH_PLANBYID_ONREFRESH_SUCCESS} from "../topbar/AllPlans.ActionTypes"
// import { fetchPlanAdjustmentOutput, updateAdjustmentsService, processAdjustmentsService } from '../../services/output/Service.output';
import {CallGetPlanByIdService, fetchAllPlans} from '../../services/topbar/AllPlansService';
import { fetchParameters } from "../../services/parameters/ParametersService";
import { AllTabActivitiy, COLUMN_TOTAL, MONTHS_3_LETTER, SalesTabActivity } from "../../Constants";
import { fetchPlanAdjustmentOutput, updateSalesAdjustmentsService } from "../../services/sales_finance/salesservice";
import { processSalesAdjustmentsService,processBvDepOverrideService } from '../../services/output/Service.output';
import { ActivityTypeNode, AdjustmentNode, ZoneNode } from "./SalesAndFinance.Objects";

import moment from "moment";
import { FETCH_PLAN_OPTIMIZATION_DATA_ERROR, FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS } from "../adjustments/Adjustments.ActionTypes";
import { updateAdjustmentsService } from "../../services/output/Service.output";

export const fetchAdjustmentPlanOutputData = (planId, parametersData) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_SALES_ADJUSTMENT_DATA_PENDING));
            let planAdjustmentData = await fetchPlanAdjustmentOutput(planId);
            let presentationData=applyPresentationLogic(planAdjustmentData);
            let parameters = []

            if (parametersData.length > 0) {
                parameters = parametersData;
            } else {
                parameters = await fetchParameters(planId)
            }
            if (planAdjustmentData && parameters) {
                
                dispatch(serviceActionSuccess(FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS, { "planOptimizationData": planAdjustmentData, "parameters": parameters }));
                dispatch(serviceActionSuccess(FETCH_SALES_ADJUSTMENT_DATA_SUCCESS, { "planAdjustmentData": planAdjustmentData, "parameters": parameters,"presentableData":presentationData }));
            }
            else {
                dispatch(serviceActionError(FETCH_SALES_ADJUSTMENT_DATA_ERROR, "Failed to load Adjustment data & parameters"));
                dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, planAdjustmentData));
            }
        } catch (e) {
            console.log(e.stack);
            dispatch(serviceActionError(FETCH_SALES_ADJUSTMENT_DATA_ERROR, "Unknown error"));
            dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, "Unknown error"));
        }
    }
}
export const processSalesAdjustmentsRun = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PROCESS_SALESADJUSTMENTS_RUN_PENDING));
            let salesadjustmentsRunResponse = await processSalesAdjustmentsService(planid);
            if (salesadjustmentsRunResponse.resp == 'Success') {
                let planByIdResponse = await CallGetPlanByIdService(planid);
            if (planByIdResponse) {
                dispatch(serviceActionSuccess(FETCH_PLANBYID_ONREFRESH_SUCCESS, planByIdResponse));
            }
                dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
                let allPlansData = await fetchAllPlans()
                if (allPlansData) {
                    dispatch(serviceActionSuccess(FETCH_PROCESS_SALESADJUSTMENTS_RUN_SUCCESS, salesadjustmentsRunResponse));
                    dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                    dispatch(getAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                }
                else {
                    dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
                }
            } else {
                dispatch(serviceActionError(FETCH_PROCESS_SALESADJUSTMENTS_RUN_ERROR, salesadjustmentsRunResponse));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_PROCESS_SALESADJUSTMENTS_RUN_ERROR, "Unknown error"));
        }
    }
}

export const processbvDepOverrideRun = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PROCESS_BVDEPOVERRIDE_RUN_PENDING));
            let bvDepOverrideRunResponse = await processBvDepOverrideService(planid);
            if (bvDepOverrideRunResponse.resp == 'Success') {
                let planByIdResponse = await CallGetPlanByIdService(planid);
            if (planByIdResponse) {
                dispatch(serviceActionSuccess(FETCH_PLANBYID_ONREFRESH_SUCCESS, planByIdResponse));
            }
                dispatch(serviceActionPending(FETCH_ALLPLANS_DATA_PENDING));
                let allPlansData = await fetchAllPlans()
                if (allPlansData) {
                    dispatch(serviceActionSuccess(FETCH_PROCESS_BVDEPOVERRIDE_RUN_SUCCESS, bvDepOverrideRunResponse));
                    dispatch(serviceActionSuccess(FETCH_ALLPLANS_DATA_SUCCESS, allPlansData));
                    dispatch(getAllPlansTableData(GET_ALLPLANS_TABLE_DATA))
                }
                else {
                    dispatch(serviceActionError(FETCH_ALLPLANS_DATA_ERROR, allPlansData));
                }
            } else {
                dispatch(serviceActionError(FETCH_PROCESS_BVDEPOVERRIDE_RUN_ERROR, bvDepOverrideRunResponse));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_PROCESS_BVDEPOVERRIDE_RUN_ERROR, "Unknown error"));
        }
    }
}
export const setSalesAccordianClicked = (activity,expanded) =>{
    // console.log("setAccordianClicked with :: ",tabIndex,viewType,header,isTopLevel,parentValHeader);
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_SALES_ACCORDIAN_CLICK,
            {
                activity:activity,
                expanded:expanded
            }));
    }
}

export const setSalesZoneAccordianClicked = (activity,zone,expanded) =>{
    // console.log("setAccordianClicked with :: ",tabIndex,viewType,header,isTopLevel,parentValHeader);
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_SALES_ZONE_ACCORDIAN_CLICK,
            {
                activity:activity,
                zone:zone,
                expanded:expanded
            }));
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, optimizedData) {
    return {
        type: type,
        data: optimizedData
    }
}

function adjustmentActionSuccess(type, data, action) {
    return {
        type: type,
        data: data,
        action: action
    }
}

function adjustmentLevel(type, data) {
    return {
        type: type,
        data: data
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}
function getAllPlansTableData(type) {
    return {
        type: type
    }
}



function applyPresentationLogic(planAdjustmentData){
    // console.log("planAdjustmentData==>",planAdjustmentData);
    try{
        if(planAdjustmentData && planAdjustmentData.planOptimizationOutputs && planAdjustmentData.planOptimizationOutputs.length){
            let presentationData=getBlankPresentationModel(planAdjustmentData.planOptimizationOutputs[0].cpd,planAdjustmentData.planOptimizationOutputs[0].num_plan_months,planAdjustmentData.zones);
            
            // console.log("getMonthYearIndexLocation(presentationData, 2024, 12);==>",getMonthYearIndexLocation(presentationData, 2024, 12));
            populateAdjustmentData(planAdjustmentData, presentationData);

            calculateDerivativeData(presentationData,planAdjustmentData.zones);//Updates original object

            disableEditOfRowBasedOnData(presentationData);

            // console.log("Updated Presentation data:: ",presentationData);
            return presentationData;
        }else{
            console.log("Console.log:: Adjustment data not loaded...");
            return {};
        }
    }catch(e){
        console.log("ERROR: ", e.stack);
        throw e;
    }
    


    return [];
}

function populateAdjustmentData(planAdjustmentData, presentationData) {
    planAdjustmentData.planOptimizationOutputs.forEach(entry => {

        // console.log("entry:==>",entry);
        if (SalesTabActivity[entry.activity_type] === null || SalesTabActivity[entry.activity_type] === undefined) {
            // console.log(`${entry.activity_type} is not supported`);
            return;
        }
        // let selectedActivityNode=presentationData[entry.activity_type];
        let selectYearMonthIndexLocation = getMonthYearIndexLocation(presentationData, entry.year, entry.month);
        // console.log("selectYearMonthIndexLocation==>",selectYearMonthIndexLocation);
        if (selectYearMonthIndexLocation !== -1) {
            // console.log("selectTotalLocation==>",selectTotalLocation);
            // console.log(`presentationData[${entry.activity_type}]==>`,presentationData[entry.activity_type]);

            // console.log(`activity_type=${entry.activity_type}, zone_code=${entry.zone_code}, selectYearMonthIndexLocation=${selectYearMonthIndexLocation}, selectTotalLocation=${selectTotalLocation}`);
            
            //For Month of Zone
            if(entry.zone_code==="ALL"){//In case of Activity type level change
                updateMonthNodeOfZone(presentationData[entry.activity_type].nodes[selectYearMonthIndexLocation], entry);
            }else{
                updateMonthNodeOfZone(presentationData[entry.activity_type].zones[entry.zone_code].nodes[selectYearMonthIndexLocation], entry);
            }
            
            
        }
    });
    
}

function updateMonthNodeOfZone(adjustmentNode, entry) {
    adjustmentNode.value = adjustmentNode.value+parseFloat(entry.value);
    adjustmentNode.originalValue = adjustmentNode.originalValue+parseFloat(entry.originalValue);
    adjustmentNode.requestedAdjustment = adjustmentNode.requestedAdjustment+parseFloat(entry.adjustment);
    adjustmentNode.requestedValue=adjustmentNode.originalValue+adjustmentNode.requestedAdjustment;
    adjustmentNode.adjustmentVariance=adjustmentNode.requestedValue-adjustmentNode.value;
}

function aggregateNodeValues(aggregateNode, zoneNode){
    aggregateNode.value=aggregateNode.value+zoneNode.value;
    aggregateNode.originalValue=aggregateNode.originalValue+zoneNode.originalValue;
    aggregateNode.requestedAdjustment=aggregateNode.requestedAdjustment+zoneNode.requestedAdjustment;
    aggregateNode.requestedValue=aggregateNode.requestedValue+zoneNode.requestedValue;
    aggregateNode.adjustmentVariance=aggregateNode.adjustmentVariance+zoneNode.adjustmentVariance;
}

function getBlankPresentationModel(cpdDateString,numPlanMonthsString,zones) {
    let adjustmentsData = {};
    // console.log("cpdDateString==>", cpdDateString);
    // console.log("numPlanMonthsString==>", numPlanMonthsString);
    let cpdDateObj = moment(`${cpdDateString.substring(0, 4)}-${cpdDateString.substring(4, 6)}-${cpdDateString.substring(6, 8)}`);
    // console.log("cpdDateObj as String==", cpdDateObj);
    let numMonths = parseInt(numPlanMonthsString);
    // console.log("numMonths==", numMonths);

    // console.log("SalesTabActivity==>,",Object.keys(SalesTabActivity));
    let allActivityTypes=Object.keys(SalesTabActivity);

    allActivityTypes.forEach(activityType => {
        adjustmentsData[activityType] = [];
    });

    let presentationData = {};
    presentationData['MONTH'] = [];
    presentationData['YEAR'] = [];
    let startYear = 0;
    for (let i = 1; i <= numMonths; i++) { //Preparing column Headers
        // console.log("presentationData==>",presentationData);
        if (startYear !== cpdDateObj.year()) {
            presentationData['MONTH'].push(COLUMN_TOTAL);
            presentationData['YEAR'].push(getYearInTwoDigits(cpdDateObj));

            allActivityTypes.forEach(activityType => {
                // if(activityType==="CORE_TO_TNC"){
                //     console.log("WHEN Change in Year Detected : CORE_TO_TNC...", i);
                // }
                if (!presentationData[activityType]) {
                    presentationData[activityType] = new ActivityTypeNode();
                    presentationData[activityType].activityType = activityType;
                    zones.map(zone => {
                        presentationData[activityType].zones[zone.code] = new ZoneNode();
                    });
                }
                let tempAdjModel = new AdjustmentNode();
                tempAdjModel.total = true;
                presentationData[activityType].nodes.push(tempAdjModel);

                zones.map(zone => {
                    let tempAdjModel = new AdjustmentNode();
                    tempAdjModel.total = true;
                    presentationData[activityType].zones[zone.code].nodes.push(tempAdjModel);
                });
            });

            startYear = cpdDateObj.year();
        }
        // console.log("cpdDateObj.getMonth()===>",cpdDateObj.getMonth())
        presentationData['MONTH'].push(MONTHS_3_LETTER[cpdDateObj.month()]);
        presentationData['YEAR'].push(getYearInTwoDigits(cpdDateObj));

        cpdDateObj.month(cpdDateObj.month() + 1);


        allActivityTypes.forEach(activityType => {
            presentationData[activityType].nodes.push(new AdjustmentNode());
            if(activityType==="SALES"){
                presentationData[activityType].isEditable=true;
            }
            zones.map(zone => {
                presentationData[activityType].zones[zone.code].nodes.push(new AdjustmentNode());
                if(activityType==="SALES"){
                    presentationData[activityType].zones[zone.code].isEditable=true;
                }
            });
        });

    }
    // console.log("Returning Blank presentationData==>",presentationData);
    return presentationData;
}
function getYearInTwoDigits(cpdDateObj) {
    return cpdDateObj.year() - ((Math.floor(cpdDateObj.year() / 100)) * 100);
}
function getMonthYearIndexLocation(presentationData, year, month){
    let twoDigitYear=year - ((Math.floor(year / 100)) * 100);
    // console.log("presentationData==>",presentationData);
    for(let i=0;i<presentationData.MONTH.length;i++){
        if(presentationData['MONTH'][i]===COLUMN_TOTAL){
            continue;
        }
        // console.log("Not found yet...", presentationData['YEAR'][i],'===',twoDigitYear,' && ',presentationData['MONTH'][i],'===',MONTHS_3_LETTER[month-1]);
        if(presentationData['YEAR'][i]===twoDigitYear && presentationData['MONTH'][i]===MONTHS_3_LETTER[month-1]){
            // console.log("Year Month Match Found...");
            return i;
        // }else{
        //     console.log("Not found yet...", presentationData['YEAR'][i],'===',twoDigitYear,' && ',presentationData['MONTH'][i],'===',MONTHS_3_LETTER[month-1]);
        }
    }
    // console.log(`Not able to find index location of Month (${month}) & Year(${year}).`);
    return -1;
}

function getTotalLocation(presentationData, indexLocation){
    for(let i=indexLocation-1;i>=0;i--){
        if(presentationData['MONTH'][i]===COLUMN_TOTAL){
            return i;
        }
    }
    // console.log("Not able to find location of 'Total' for index location=",indexLocation);
    return -1;
}

function calculateDerivativeData(presentationData,zones){//Updates original array
    let filterdZone=zones.filter(zone=>zone.code!=='ALL');

    let indexOfTotalColumn=-1;
    for(let i=0;i<presentationData["MONTH"].length;i++){
        if(presentationData["MONTH"][i]===COLUMN_TOTAL){
            indexOfTotalColumn=i;
            continue;
        }

        filterdZone.forEach(zone => {
            //Calculating for the Zones for "RISK_DELETES"
            presentationData["RISK_DELETES"].zones[zone.code].nodes[i].value=presentationData["CORE_TO_CS"].zones[zone.code].nodes[i].value+presentationData["CARGO_TO_CS"].zones[zone.code].nodes[i].value+presentationData["TNC_TO_CS"].zones[zone.code].nodes[i].value;
            presentationData["RISK_DELETES"].zones[zone.code].nodes[i].originalValue=presentationData["CORE_TO_CS"].zones[zone.code].nodes[i].originalValue+presentationData["CARGO_TO_CS"].zones[zone.code].nodes[i].originalValue+presentationData["TNC_TO_CS"].zones[zone.code].nodes[i].originalValue;
            presentationData["RISK_DELETES"].zones[zone.code].nodes[i].requestedAdjustment=presentationData["CORE_TO_CS"].zones[zone.code].nodes[i].requestedAdjustment+presentationData["CARGO_TO_CS"].zones[zone.code].nodes[i].requestedAdjustment+presentationData["TNC_TO_CS"].zones[zone.code].nodes[i].requestedAdjustment;
            presentationData["RISK_DELETES"].zones[zone.code].nodes[i].requestedValue=presentationData["CORE_TO_CS"].zones[zone.code].nodes[i].requestedValue+presentationData["CARGO_TO_CS"].zones[zone.code].nodes[i].requestedValue+presentationData["TNC_TO_CS"].zones[zone.code].nodes[i].requestedValue;
            presentationData["RISK_DELETES"].zones[zone.code].nodes[i].adjustmentVariance=presentationData["CORE_TO_CS"].zones[zone.code].nodes[i].adjustmentVariance+presentationData["CARGO_TO_CS"].zones[zone.code].nodes[i].adjustmentVariance+presentationData["TNC_TO_CS"].zones[zone.code].nodes[i].adjustmentVariance;

            //Calculating for the value for "RISK_DELETES" activity
            aggregateNodeValues(presentationData["RISK_DELETES"].nodes[i],presentationData["RISK_DELETES"].zones[zone.code].nodes[i]);
            //Calculating for the total for the zone in "RISK_DELETES" activity
            aggregateNodeValues(presentationData["RISK_DELETES"].zones[zone.code].nodes[indexOfTotalColumn],presentationData["RISK_DELETES"].zones[zone.code].nodes[i]);


            //Calculating for the value for "SALES" activity
            aggregateNodeValues(presentationData["SALES"].nodes[i],presentationData["SALES"].zones[zone.code].nodes[i]);
            //Calculating for the total for the zone in "SALES" activity
            aggregateNodeValues(presentationData["SALES"].zones[zone.code].nodes[indexOfTotalColumn],presentationData["SALES"].zones[zone.code].nodes[i]);


            //Calculating for the value for "SALVAGE" activity
            aggregateNodeValues(presentationData["SALVAGE"].nodes[i],presentationData["SALVAGE"].zones[zone.code].nodes[i]);
            //Calculating for the total for the zone in "SALVAGE" activity
            aggregateNodeValues(presentationData["SALVAGE"].zones[zone.code].nodes[indexOfTotalColumn],presentationData["SALVAGE"].zones[zone.code].nodes[i]);

            //Calculating START_CS_FLEET
            if(i>1){//Not to do for Total & First Month

                if(i===indexOfTotalColumn+1){
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].value=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-2].value;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].originalValue=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-2].originalValue;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].requestedAdjustment=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-2].requestedAdjustment;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].requestedValue=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-2].requestedValue;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].adjustmentVariance=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-2].adjustmentVariance;
                }else{
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].value=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-1].value;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].originalValue=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-1].originalValue;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].requestedAdjustment=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-1].requestedAdjustment;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].requestedValue=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-1].requestedValue;
                    presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].adjustmentVariance=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i-1].adjustmentVariance;
                }
                
            }
            //Calculating for the value for "START_CS_FLEET" activity
            aggregateNodeValues(presentationData["START_CS_FLEET"].nodes[i],presentationData["START_CS_FLEET"].zones[zone.code].nodes[i]);
            //Calculating for the total for the zone in "START_CS_FLEET" activity
            presentationData["START_CS_FLEET"].zones[zone.code].nodes[indexOfTotalColumn]=presentationData["START_CS_FLEET"].zones[zone.code].nodes[indexOfTotalColumn+1].copyIntoNew();
            presentationData["START_CS_FLEET"].zones[zone.code].nodes[indexOfTotalColumn].total=true;


            //Calculating "END_CS_FLEET":"START_CS_FLEET(t)+RISK_DELETES(t)-SALES(t)"
            presentationData["END_CS_FLEET"].zones[zone.code].nodes[i].value=presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].value+presentationData["RISK_DELETES"].zones[zone.code].nodes[i].value-presentationData["SALES"].zones[zone.code].nodes[i].value;
            presentationData["END_CS_FLEET"].zones[zone.code].nodes[i].originalValue=presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].originalValue+presentationData["RISK_DELETES"].zones[zone.code].nodes[i].originalValue-presentationData["SALES"].zones[zone.code].nodes[i].originalValue;
            presentationData["END_CS_FLEET"].zones[zone.code].nodes[i].requestedAdjustment=presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].requestedAdjustment+presentationData["RISK_DELETES"].zones[zone.code].nodes[i].requestedAdjustment-presentationData["SALES"].zones[zone.code].nodes[i].requestedAdjustment;
            presentationData["END_CS_FLEET"].zones[zone.code].nodes[i].requestedValue=presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].requestedValue+presentationData["RISK_DELETES"].zones[zone.code].nodes[i].requestedValue-presentationData["SALES"].zones[zone.code].nodes[i].requestedValue;
            presentationData["END_CS_FLEET"].zones[zone.code].nodes[i].adjustmentVariance=presentationData["START_CS_FLEET"].zones[zone.code].nodes[i].adjustmentVariance+presentationData["RISK_DELETES"].zones[zone.code].nodes[i].adjustmentVariance-presentationData["SALES"].zones[zone.code].nodes[i].adjustmentVariance;
        
            aggregateNodeValues(presentationData["END_CS_FLEET"].nodes[i],presentationData["END_CS_FLEET"].zones[zone.code].nodes[i]);
            if(i+1===presentationData["MONTH"].length || presentationData["MONTH"][i+1]===COLUMN_TOTAL){
                //Calculating for the total for the zone in "END_CS_FLEET" activity
                presentationData["END_CS_FLEET"].zones[zone.code].nodes[indexOfTotalColumn]=presentationData["END_CS_FLEET"].zones[zone.code].nodes[i].copyIntoNew();
                presentationData["END_CS_FLEET"].zones[zone.code].nodes[indexOfTotalColumn].total=true;
            }
        });
        //Calculating for the total for "RISK_DELETES" activity
        aggregateNodeValues(presentationData["RISK_DELETES"].nodes[indexOfTotalColumn],presentationData["RISK_DELETES"].nodes[i]);
        //Calculating for the total for "SALES" activity
        aggregateNodeValues(presentationData["SALES"].nodes[indexOfTotalColumn],presentationData["SALES"].nodes[i]);
        // console.log(`presentationData["SALES"].nodes[${indexOfTotalColumn}]==>`,presentationData["SALES"].nodes[indexOfTotalColumn]);
        //Calculating for the total for "SALVAGE" activity
        aggregateNodeValues(presentationData["SALVAGE"].nodes[indexOfTotalColumn],presentationData["SALVAGE"].nodes[i]);

        if(i+1===presentationData["MONTH"].length || presentationData["MONTH"][i+1]===COLUMN_TOTAL){
            //Calculating for the total for "START_CS_FLEET" activity, Just before the change of indexOfTotalColumn
            presentationData["START_CS_FLEET"].nodes[indexOfTotalColumn]=presentationData["START_CS_FLEET"].nodes[indexOfTotalColumn+1].copyIntoNew();
            presentationData["START_CS_FLEET"].nodes[indexOfTotalColumn].total=true;
            
            //Calculating for the total for "END_CS_FLEET" activity
            presentationData["END_CS_FLEET"].nodes[indexOfTotalColumn]=presentationData["END_CS_FLEET"].nodes[i].copyIntoNew();
            presentationData["END_CS_FLEET"].nodes[indexOfTotalColumn].total=true;
        }
    }
}

export const toggleExpandAll = () =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(TOGGLE_EXAPND_ALL_IN_SALES,{}));
    }
}

export const enableActivityEdit = (activityType,zone) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(ENABLE_EDIT_ACTIVITY,{activityType:activityType,zone:zone}));
    }
}

export const updateSalesAdjustmentChanges = (activity,zone,index,value) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(UPDATE_SALES_ADJUSTMENT_ACTIVITY,{activity:activity, zone:zone, index:index, value:value }));
    }
}

export const cancelSalesAdjustmentChanges = () =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(CANCEL_EDIT_ACTIVITY,{}));
    }
}

export const saveSalesAdjustmentChanges = (activity,zone,transactionMap,plansDataById,accordionData) =>{
    return async dispatch =>{
        try{
            dispatch(serviceActionPending(FETCH_SALES_ADJUSTMENT_DATA_PENDING));
            let planid=plansDataById[0].id;
            let transactionList=createTransactionList(activity,zone,transactionMap,planid,accordionData);
            console.log("IN saveSalesAdjustmentChanges:: transactionList==>",transactionList);
    
            let response = await updateSalesAdjustmentsService(planid,transactionList);

            if (response) {
                let planAdjustmentData = await fetchPlanAdjustmentOutput(planid);
                let presentationData=applyPresentationLogic(planAdjustmentData);
                let updatedPresentationData=updateExpandedStateFromIncumbent(accordionData,presentationData);
                let parameters = await fetchParameters(planid);
                if (planAdjustmentData && parameters) {
                    dispatch(serviceActionSuccess(FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS, { "planOptimizationData": planAdjustmentData, "parameters": parameters }));
                    dispatch(serviceActionSuccess(FETCH_SALES_ADJUSTMENT_DATA_SUCCESS, { "planAdjustmentData": planAdjustmentData, "parameters": parameters,"presentableData":updatedPresentationData }));
                }else{
                    dispatch(serviceActionError(FETCH_SALES_ADJUSTMENT_DATA_ERROR, "Failed to load Adjustment data & parameters"));
                    dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, planAdjustmentData));
                }
            }
            else {
                dispatch(serviceActionError(FETCH_SALES_ADJUSTMENT_DATA_ERROR, "Unknown error"));
                dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, "Unknown error"));
            }
        }catch(e){
            console.log(e.message);
            console.log(e.stack);
            dispatch(serviceActionError(FETCH_SALES_ADJUSTMENT_DATA_ERROR, "Unknown error"));
            dispatch(serviceActionError(FETCH_PLAN_OPTIMIZATION_DATA_ERROR, "Unknown error"));
        }
    }
}

const createTransactionList= (activity,zone,transactionMap,planId,accordionData) =>{
    let keys=Object.keys(transactionMap).filter(key=>key.includes(activity) && key.includes(zone));
    
    let transactionList=[];
    

    if(keys.length>0){
        keys.forEach(key=>{
            let keyParts=key.split("-");
            let monthValue=0;
            let yearValue=0;
            if(accordionData["MONTH"][keyParts[2]]!=="Total"){
                let tempMonthValue=MONTHS_3_LETTER.indexOf(accordionData["MONTH"][keyParts[2]]);
                if (tempMonthValue!==-1){
                    monthValue=tempMonthValue+1;
                }
                yearValue=2000+accordionData["YEAR"][keyParts[2]];
            }


            transactionList.push({
                    "plan_id": planId,
                    "bu": "CS",
                    "zone_code": zone,
                    "pool": "ZONE_LEVEL",
                    "activity_type": activity,
                    "month": monthValue,
                    "year": yearValue,
                    "adjustment": transactionMap[key],
                    "engine_group": "NA"
            })
        })
    }

    return transactionList;
}

const updateExpandedStateFromIncumbent=(oldData,newData)=>{

    Object.keys(newData).forEach(activityType=>{
        if(activityType!=='MONTH' && activityType!=='YEAR'){
            newData[activityType].expanded=oldData[activityType].expanded;
            Object.keys(newData[activityType].zones).forEach(zoneKey=>{
                newData[activityType].zones[zoneKey].expanded=oldData[activityType].zones[zoneKey].expanded;
            })
        }
    });


    return newData;
}

const disableEditOfRowBasedOnData=(presentationData)=>{
    // console.log("IN disableEditOfRowBasedOnData:: presentationData=",presentationData);
    let isActivityTypeEdited=false;
    let isZoneEdited=false;

    for(let i=0;i<presentationData["MONTH"].length;i++){
        if(presentationData["MONTH"][i]==="Total"){
            continue;
        }

        if(presentationData["SALES"].nodes[i].requestedAdjustment!=0){
            let keys=Object.keys(presentationData["SALES"].zones);
            let zoneLevelRequestedAdjustment=0;
            for(let j=0;j<keys.length;j++){
                // console.log(`presentationData["SALES"].zones[${keys[j]}].nodes[${i}].requestedAdjustment=`,presentationData["SALES"].zones[keys[j]].nodes[i].requestedAdjustment);
                zoneLevelRequestedAdjustment+=presentationData["SALES"].zones[keys[j]].nodes[i].requestedAdjustment
            }
            // console.log("activityLevelRequestedAdjustment=",presentationData["SALES"].nodes[i].requestedAdjustment);
            // console.log("zoneLevelRequestedAdjustment=",zoneLevelRequestedAdjustment);

            if(zoneLevelRequestedAdjustment===presentationData["SALES"].nodes[i].requestedAdjustment){
                isZoneEdited=true;
            }else{
                isActivityTypeEdited=true;
            }
            break;
        }
    }

    if(isActivityTypeEdited){
        // console.log("Activity Type was edited...");
        Object.keys(presentationData["SALES"].zones).forEach(zone=>presentationData["SALES"].zones[zone].isEditDisabled=true);
    }
    if(isZoneEdited){
        // console.log("Zone was edited...");
        presentationData["SALES"].isEditDisabled=true;
    }

}
