import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import diaglogstyles from './DialogComponent.css';
import icon_close from "../../assets/images/close.svg";
import { TextField } from '@material-ui/core';
import { TextFieldInput } from '../../core-components/Input/Input';

const AdminModelMfrOemDialogComponent = (props) => {
    const { open, handleClose, handleYes,editClicked,value,rowData } = props;
    const initialValues={
        OEM:"",
        MANUFACTURER_GROUP:"",
        MODEL_GROUP:""
    }
    const [newValue,setNewValue]=useState(initialValues)
    let transactions=[
        {
            'type':editClicked?'edit':'add',
            'data':{'column':rowData.colDef.field,'oldName':value,'newName':newValue}
        }
    ]
    function handleClickYes() {
        handleYes(transactions);
        setNewValue(initialValues);
    }
    function handleClickNo() {
        setNewValue(initialValues);
        handleClose()
    }
    function onChangeHandler(e) {
        const { name, value } = e.target;
        setNewValue({
        ...newValue,
        [name]: value,
        });
    }
    

    let isOEMExists=doesOemExist(newValue,rowData);
    let isMfrExists=doesManufacturerGroupExist(newValue,rowData);
    let isModelExists=doesModelExist(newValue,rowData);

    function isButtonDisabled(){
        if(isOEMExists || isMfrExists || isModelExists){
            return true;
        } else if(rowData.colDef.field==='OEM'){
            if(!editClicked && (newValue.OEM==='' || newValue.MANUFACTURER_GROUP==='')){
                return true;
            } else if(editClicked && newValue.OEM===''){
                return true;
            } 
        } else if(rowData.colDef.field==='MANUFACTURER_GROUP'){
            if(editClicked && newValue.MANUFACTURER_GROUP===''){
                return true;
            }
        } else if(rowData.colDef.field==='MODEL_GROUP'){
            if(!editClicked && newValue.MODEL_GROUP===''){
                return true;
            } else if(editClicked && newValue.MODEL_GROUP===''){
                return true;
            }
        }
        return false;
    }
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    className="modelmfroem_dialogbox"
                >
                    <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                        <span>{editClicked?'EDIT DATA':'ADD DATA'}</span>
                        <span><img src={icon_close} onClick={handleClickNo} className="closeicon" alt="iconclose"></img></span>
                    </DialogTitle>
                {editClicked?
                
                    <DialogContent>
                    <div style={{'display':'flex','flexDirection':'column'}}>
                    <p style={{'display':'flex'}}>Old Name: <b>{value} </b></p>
                        New Name:{rowData.colDef.field==='MANUFACTURER_GROUP'?
                                    <TextFieldInput id="outlined-basic" 
                                                    name={`${rowData.colDef.field}`} 
                                                    variant="outlined" 
                                                    onTextChanged={(e)=>onChangeHandler(e)} 
                                                    value={newValue[`${rowData.colDef.field}`]}
                                                    inputProps={{maxLength: 3}}
                                                    isError={isMfrExists}
                                                    ErrorInputWithHelperText={isMfrExists?"This Value exists! Use a new one": ""}

                                    />
                                    :rowData.colDef.field==='OEM'?
                                        <TextField id="outlined-basic" 
                                                    name={`${rowData.colDef.field}`} 
                                                    variant="outlined" 
                                                    onChange={(e)=>onChangeHandler(e)} 
                                                    value={newValue[`${rowData.colDef.field}`]}
                                                    error={isOEMExists}
                                                    helperText={isOEMExists?"This Value exists! Use a new one": ""}
                                        />
                                    : 
                                        <TextField id="outlined-basic" 
                                            name={`${rowData.colDef.field}`} 
                                            variant="outlined" 
                                            onChange={(e)=>onChangeHandler(e)} 
                                            value={newValue[`${rowData.colDef.field}`]}
                                            error={isModelExists}
                                            helperText={isModelExists?"This Value exists! Use a new one": ""}
                        />
                                }
                        </div>
                    </DialogContent>
                :
                    <DialogContent>
                        {rowData.colDef.field==='MODEL_GROUP'?
                        <>
                            <b>MODEL GROUP:</b>
                            <TextField id="outlined-basic" 
                                       name='MODEL_GROUP' 
                                       variant="outlined" 
                                       onChange={(e)=>onChangeHandler(e)} 
                                       value={newValue.MODEL_GROUP}
                                       error={isModelExists}
                                       helperText={isModelExists?"This Value exists! Use a new one": ""}
                            />
                        </>
                        :    
                        <div style={{'display':'flex','flexDirection':'column'}}>
                                    OEM:<TextField id="outlined-basic" 
                                            name='OEM' 
                                            required 
                                            variant="outlined" 
                                            onChange={(e)=>onChangeHandler(e)} 
                                            value={newValue.OEM}
                                            error={isOEMExists}
                                            helperText={isOEMExists?"This Value exists! Use a new one": ""}/>
                                    MANUFACTURER GROUP:<TextFieldInput id="outlined-basic" 
                                                                required 
                                                                name='MANUFACTURER_GROUP' 
                                                                variant="outlined" 
                                                                onTextChanged={(e)=>onChangeHandler(e)} 
                                                                value={newValue.MANUFACTURER_GROUP}
                                                                inputProps={{maxLength: 3}}
                                                                isError={isMfrExists}
                                                                ErrorInputWithHelperText={isMfrExists?"This Value exists! Use a new one": ""}/>
                        </div>
                    }
                    </DialogContent>          
                }
                    <DialogActions className="actionbuttons">
                        <Button className='cancelbutton' autoFocus onClick={handleClickNo} color="primary">
                            Cancel
                        </Button> 
                        <Button className='okbutton' onClick={handleClickYes} color="primary" 
                                disabled={isButtonDisabled()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
}

const doesOemExist=(newValue,rowData)=>{
    let modelMfrOemList;
        if(rowData!=null && rowData.agGridReact){
            modelMfrOemList=rowData.agGridReact.gridOptions.rowData
        } else {
            modelMfrOemList=rowData.currentData;
        }
        if(newValue.OEM && newValue.OEM!=='' && modelMfrOemList){
            return -1<modelMfrOemList.findIndex(row=>row.rowStatus!=="ADD_ROW" && row.OEM && row.OEM.toLowerCase()===newValue.OEM.toLowerCase());
        }
    return false;
}

const doesManufacturerGroupExist=(newValue,rowData)=>{
    let modelMfrOemList;
        if(rowData!=null && rowData.agGridReact){
            modelMfrOemList=rowData.agGridReact.gridOptions.rowData
        } else {
            modelMfrOemList=rowData.currentData;  
        }
        if(newValue.MANUFACTURER_GROUP && newValue.MANUFACTURER_GROUP!=='' && modelMfrOemList){
            return -1<modelMfrOemList.findIndex(row=>row.rowStatus!=="ADD_ROW" && row.MANUFACTURER_GROUP && row.MANUFACTURER_GROUP.toLowerCase()===newValue.MANUFACTURER_GROUP.toLowerCase());
        }
    return false;
};

const doesModelExist=(newValue,rowData)=>{
    let modelMfrOemList;
    let allManufacturergroupModelgroup =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
    if(newValue.MODEL_GROUP && allManufacturergroupModelgroup[newValue.MODEL_GROUP.toUpperCase()]){
        return true;
    }
    return false;
};

export default AdminModelMfrOemDialogComponent;