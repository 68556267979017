import {
    FETCH_GROUP_BY_TYPE_ZONE, FETCH_PLAN_OPTIMIZATION_DATA_ERROR, FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR, FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_PENDING, FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_SUCCESS, FETCH_PLAN_OPTIMIZATION_DATA_PENDING, FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS, FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING, FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS, FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR, SET_DATA_WITH_ADJUSTEMENT_VALUE, ADJUSTMENTS_ROW_ACTION, ADJUSTMENTS_SET_LEVEL,
    FETCH_ADJUSTMENTS_FILTER_DATA, SET_TABALL_FILTER_PARAMS, SET_CORETAB_FILTER_PARAMS, SET_COREICETAB_FILTER_PARAMS,SET_COREELECTAB_FILTER_PARAMS,SET_TNCTAB_FILTER_PARAMS,SET_TNCICETAB_FILTER_PARAMS,SET_TNCELECTAB_FILTER_PARAMS,
    SET_CARGOCTAB_FILTER_PARAMS,SET_ADJUSTMENTS_EXPANDALL_FLAGS, SET_BU_GROUP_VIEW_TYPE, SET_ACCORDIAN_CLICK} from "./Adjustments.ActionTypes";
import {fillEmptyZones,customCloneObject, getClonedFilterCatObject,checkIfFreezMonth } from "../../utils/AdjustmentsUtils";
import filterColumnDefs from '../../data/adjustmentsFilterColumns';
import {ADJLEVEL, AllTabActivitiy, BuActivityMap,BUEngineNames, OperationalEndFleetActivity, TYPE_ZONE_STR} from "../../Constants";



const initialState = {
    pending: false,
    editing: false,
    isSaveClicked:false,
    freezeCpd:[],
    adjlevel: ADJLEVEL.NONE,
    editableArrayObject:[],
    planOutputs: [],
    planOptimizationOutputs: [],
    zones:[],
    adjustmentsFilterData:[],
    tabAllfilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    coreBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    coreIceBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    coreElecBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    tncBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    tncIceBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    tncElecBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    cargoBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:getClonedFilterCatObject()},
    planOutputFilterFlag: false,
    planOutputFilterSearchData: [],
    planOutputsGroupedData: [],

    copyOfAllOperationalStartFleetData:[], //copy of ALL tab operational start fleet data 
    copyOfAllTotalStartFleetData:[],//copy of ALL tab Total start fleet data  
    copyOfcoreStartFleetData:[],
    copyOfcoreIceStartFleetData:[],
    copyOfcoreElecStartFleetData:[],
    copyOftncStartFleetData:[],
    copyOftncIceStartFleetData:[],
    copyOftncElecStartFleetData:[],
    copyOfcargoStartFleetData:[],

    aggregatedAddsArray: [],
    aggregatedDeletesArray: [],
    aggregatedRiskDeletesArray:[],
    aggregatedOperationalFleetArray:[],
    aggregatedEndfleetArray: [],
    aggregatedStartfleetArray: [],
    aggregatedTotalStartfleetArray: [], //startfleet+Cs_startfleet
    aggregatedSalesArray: [],
    aggregatedSalvageArray: [],
    aggregatedNetonewayArray: [],
    aggregatedNetmovesArray: [],
    aggregatedZoneArray:[],

    corebu_aggregatedAddsArray: [],
    corebu_aggregatedDeletesArray: [],
    corebu_aggregatedEndfleetArray: [],
    corebu_aggregatedStartfleetArray: [],
    corebu_aggregatedCoretoTncArray: [],
    corebu_aggregatedSalvageArray: [],
    corebu_aggregatedNetonewayArray: [],
    corebu_aggregatedNetmovesArray:[],
    corebu_aggregatedZoneArray:[],

    coreice_aggregatedAddsArray: [],
    coreice_aggregatedDeletesArray: [],
    coreice_aggregatedEndfleetArray: [],
    coreice_aggregatedStartfleetArray: [],
    coreice_aggregatedCoretoTncArray: [],
    coreice_aggregatedCoretoCsArray: [],
    coreice_aggregatedSalvageArray: [],
    coreice_aggregatedNetonewayArray: [],
    coreice_aggregatedNetMovesArray:[],
    coreice_aggregatedZoneArray:[],

    coreelectric_aggregatedAddsArray: [],
    coreelectric_aggregatedDeletesArray: [],
    coreelectric_aggregatedEndfleetArray: [],
    coreelectric_aggregatedStartfleetArray: [],
    coreelectric_aggregatedCoretoTncArray: [],
    coreelectric_aggregatedCoretoCsArray: [],
    coreelectric_aggregatedSalvageArray: [],
    coreelectric_aggregatedNetonewayArray: [],
    coreelectric_aggregatedNetMovesArray:[],
    coreelectric_aggregatedZoneArray:[],

    cargobu_aggregatedAddsArray: [],
    cargobu_aggregatedSalvageArray: [],
    cargobu_aggregatedEndfleetArray: [],
    cargobu_aggregatedStartfleetArray: [],
    cargobu_aggregatedCargoToCsArray: [],
    cargobu_aggregatedNetonewayArray: [],
    cargobu_aggregatedNetmovesArray: [],
    cargobu_aggregatedZoneArray:[],

    tncbu_aggregatedAddsArray: [],
    tncbu_aggregatedEndfleetArray: [],
    tncbu_aggregatedStartfleetArray: [],
    tncbu_aggregatedCoretoTncArray: [],
    tncbu_aggregatedTncToCsArray: [],
    tncbu_aggregatedSalvageArray: [],
    tncbu_aggregatedNetonewayArray: [],
    tncbu_aggregatedNetmovesArray: [],
    tncbu_aggregatedZoneArray:[],

    tncice_aggregatedAddsArray: [],
    tncice_aggregatedEndfleetArray: [],
    tncice_aggregatedStartfleetArray: [],
    tncice_aggregatedCoretoTncArray: [],
    tncice_aggregatedTncToCsArray: [],
    tncice_aggregatedSalvageArray: [],
    tncice_aggregatedNetonewayArray: [],
    tncice_aggregatedNetmovesArray: [],
    tncice_aggregatedZoneArray:[],

    tncelectric_aggregatedAddsArray: [],
    tncelectric_aggregatedEndfleetArray: [],
    tncelectric_aggregatedStartfleetArray: [],
    tncelectric_aggregatedCoretoTncArray: [],
    tncelectric_aggregatedTncToCsArray: [],
    tncelectric_aggregatedSalvageArray: [],
    tncelectric_aggregatedNetonewayArray: [],
    tncelectric_aggregatedNetmovesArray: [],
    tncelectric_aggregatedZoneArray:[],

    error: null,
    dataWithAdjustmentValue:[],

        expandAll_type_zone:[
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()}
            ],
        expandAll_zone_type:[
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()},
                {value:1,dateTime: new Date()}
            ],
        selectedViewType:[TYPE_ZONE_STR,TYPE_ZONE_STR,TYPE_ZONE_STR,TYPE_ZONE_STR,TYPE_ZONE_STR,TYPE_ZONE_STR,TYPE_ZONE_STR,TYPE_ZONE_STR],
        accordianClicks:{
            type_zone:[
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()}
            ],
            zone_type:[
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()},
                {headers:[],dateTime:new Date()}
            ]
        }
}

var adjlevel = ADJLEVEL.NONE;
var zones = [];
function enrichWithMonthNames(planOutputs) {
    planOutputs.forEach((output) => {
        output.month_name = fetchMonthName(output.month);
    });
    return planOutputs;
}

function fetchMonthName(month) {
    if (month == 1)
        return "JAN"
    else if (month == 2)
        return "FEB"
    else if (month == 3)
        return "MAR";
    else if (month == 4)
        return "APR";
    else if (month == 5)
        return "MAY";
    else if (month == 6)
        return "JUN";
    else if (month == 7)
        return "JUL";
    else if (month == 8)
        return "AUG";
    else if (month == 9)
        return "SEP"
    else if (month == 10)
        return "OCT"
    else if (month == 11)
        return "NOV"
    else if (month == 12)
        return "DEC"
}

function enrichPlanOutputsWithZoneAndPoolValues(planOutputs, zones, pools) {
    planOutputs.forEach((output) => {
        if (output.pool === undefined) {
            output.pool = "ALL";
            output.Pool_code = "ALL"
        }
        zones.forEach((zone) => {
            if (zone.code == output.zone_code) {
                output.zone_name = zone.name;
            } else if (output.zone_code == "ALL") { //TOREVIEW: this and the check below are identical
                output.zone_name = output.zone_code;
            }
            else if (output.zone_code == "ALL") {
                output.zone_name = output.zone_code;
            }
        });
        if (output.zone_code === undefined) {
            output.zone_name = "ALL"; //TOREVIEW: zone_name cannot be all, are we getting zones as undefined in output data?
        }
    });
    return planOutputs;
}

function fetchBUData(data, tabSelected) {
    /*
        switch case statement
        when BU = ALL -> filter and return the rows based on ADDS, REPURCHASE_DELETES, SALVAGE and NET_MOVES
        when BU = Core -> filter and return the rows based on ADDS, REPURCHASE_DELETES, CORE TO TNC,CORE TO CS and NET_MOVES
        when BU = CS  .... 
     */
    let buData = data;
    //All Bu category. Filter data that has activity types ADDS, REPC DELETES, SALVAGE, NET MOVES
    if (tabSelected == 0) { //If BU is ALL
        buData = data.filter(item => {
            return (item.activity_type == "ADDS" || item.activity_type == "SALES" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" ||
                item.activity_type == 'REPURCHASE_DELETES' || item.activity_type == "SALVAGE" ||
                item.activity_type == "START_FLEET" || item.activity_type == "START_CS_FLEET" || item.activity_type == "CORE_TO_CS" || item.activity_type == "TNC_TO_CS" || item.activity_type == "CARGO_TO_CS");
        });
    } else if (tabSelected == 1) {  // If BU is CORE
        buData = data.filter(item => {
            return ((item.bu === "CORE") && (item.activity_type == "ADDS" || item.activity_type == 'REPURCHASE_DELETES' ||
                item.activity_type == "CORE_TO_CS" || item.activity_type == "CORE_TO_TNC"  ||item.activity_type == "SALVAGE" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" ||
                item.activity_type == "START_FLEET"));
        });
    }  else if (tabSelected == 2) {  // If BU is CORE and ENGINE_GROUP is ICE
        buData = data.filter(item => {
            return ((item.bu === "CORE" && item.engine_group!=undefined && item.engine_group === 'ICE' ) && (item.activity_type == "ADDS" || item.activity_type == "SALVAGE" ||item.activity_type == 'REPURCHASE_DELETES' ||
                item.activity_type == "CORE_TO_CS" || item.activity_type == "CORE_TO_TNC" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" ||
                item.activity_type == "START_FLEET"));
        });
    } else if (tabSelected == 3) {  // If BU is CORE and ENGINE_GROUP is ELECTRIC
        buData = data.filter(item => {
            return ((item.bu === "CORE" && item.engine_group!=undefined && item.engine_group === 'ELECTRIC') && (item.activity_type == "ADDS" || item.activity_type == "SALVAGE" ||item.activity_type == 'REPURCHASE_DELETES' ||
                item.activity_type == "CORE_TO_CS" || item.activity_type == "CORE_TO_TNC" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" ||
                item.activity_type == "START_FLEET"));
        });
    } else if (tabSelected == 4) { // If BU is TNC
        buData = data.filter(item => {
            return ((item.bu === "TNC") && (item.activity_type == "ADDS" || item.activity_type == "TNC_TO_CS" 
                     || item.activity_type == "SALVAGE" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" || item.activity_type == "START_FLEET")
                    || (item.bu === "CORE" && item.activity_type == "CORE_TO_TNC"));
        });
    } else if (tabSelected == 5) { // If BU is TNC And Engine_group is ICE
        buData = data.filter(item => {
            return ((item.bu === "TNC" && item.engine_group!=undefined && item.engine_group=== 'ICE' ) && (item.activity_type == "ADDS" || item.activity_type == "TNC_TO_CS" 
                    || item.activity_type == "SALVAGE" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" || item.activity_type == "START_FLEET")
                    || (item.bu === "CORE" && item.engine_group!=undefined && item.engine_group=== 'ICE' && item.activity_type == "CORE_TO_TNC"));
        });
    } else if (tabSelected == 6) { // If BU is TNC And Engine_group is ELECTRIC
        buData = data.filter(item => {
            return ((item.bu === "TNC" && item.engine_group!=undefined && item.engine_group=== 'ELECTRIC' ) && (item.activity_type == "ADDS" || item.activity_type == "TNC_TO_CS" 
                    || item.activity_type == "SALVAGE" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" || item.activity_type == "START_FLEET")
                    || (item.bu === "CORE" && item.engine_group!=undefined && item.engine_group === 'ELECTRIC' && item.activity_type == "CORE_TO_TNC"));
        });
    }else if (tabSelected == 7) { // If BU is CARGO
        //TODO: For other BUs
        buData = data.filter(item => {
            return ((item.bu === "CARGO") && (item.activity_type == "ADDS" || item.activity_type == "SALVAGE" || item.activity_type == "CARGO_TO_CS" || item.activity_type == "NET_ONEWAY" || item.activity_type == "NET_MOVES" ||
                item.activity_type == "START_FLEET"));
        });
    }
    return buData;
}

/**
 * @typedef OptimizationOutput
 * @type {{
 *   "plan_id": number,
 *   "output_type": string,
 *   "bu": string,
 *   "cpd": string,
 *   "num_plan_months": number,
 *   "zone_code": string,
 *   "pool": string,
 *   "activity_type": string,
 *   "month": number,
 *   "year": number,
 *   "value": number,
 *   "adjustment": ?number
 * }}
 */
/**
 * 
 * @param {[OptimizationOutput]} data 
 * @param {string} groupByCategory 
 * @param {number} tabSelected 
 * @returns 
 */

 function zoneMap(val,tabSelected,zone,zone_code,temp_record){
    let newVal={},a_types;
    if(tabSelected===0){
        a_types=AllTabActivitiy
    }else{
        a_types=Object.getOwnPropertyNames(BuActivityMap[tabSelected])
    }
  
    let data=BUEngineNames[tabSelected]
    a_types.map((vals,key)=>{
       if(zone){
            if(val[vals]){
                newVal[vals]=val[vals]
                }else {
                    newVal[vals]=[{...temp_record,'bu':data.bu,'engine_group':data.engine_group,'zone_code':zone_code,'zone_name':zone,'value':0,'adjustment':0,'originalValue':0,'activity_type':vals}]
                }
        }
    })
    return newVal
}

function fetchGroupedData(data, groupByCategory, tabSelected, freezeCpd) {
    let tabData = fetchBUData(data, tabSelected);
    // console.log("tabData===",tabData);
    let accordiongrouping = tabData.reduce((r, a) => {
        let order = groupByCategory.split('>');
        order.map((val, index) => {
            if (val == 'Type') {
                order.splice(index, 1, 'activity_type');
            } else if (val == 'Zone') {
                order.splice(index, 1, 'zone_name');
            }
            return order;
        })
        r[a[order[0]]] = r[a[order[0]]] || {};
        r[a[order[0]]][a[order[1]]] = r[a[order[0]]][a[order[1]]] || [];
        r[a[order[0]]][a[order[1]]].push(a);
return r;
    }, Object.create(null));
    if(groupByCategory==='Zone>Type'){
        let tempRecord=accordiongrouping[Object.keys(accordiongrouping)[0]]
        tempRecord= tempRecord[Object.keys(tempRecord)[0]][0];
    for(let i=0;i<zones.length;i++){
        if(accordiongrouping[zones[i].name]){
        let dat=accordiongrouping[zones[i].name]
        let tempData=zoneMap(dat,tabSelected,zones[i].name,zones[i].code,tempRecord)
        accordiongrouping[zones[i].name]=tempData
        }
     }
    } 
    return groupedBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd)
}



function groupedBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd) {
    switch (tabSelected) {
        case 0: return getAllBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 1: return getCoreBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 2: return getCoreIceBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 3: return getCoreElectricBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 4: return getTncBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 5: return getTncIceBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 6: return getTncElectricBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
        case 7: return getCargoBUData(tabSelected, groupByCategory, accordiongrouping, freezeCpd);
    }
}

function enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,aggregatedArray,startFleetArray){
    // console.log("aggregatedArray======",aggregatedArray);
    // console.log("groupByCategory====",groupByCategory);
    let updatedAggregatedArray=[];
    let startFleetValue=0;
    let startFleetZoneValues={};

    // if( aggregatedArray && aggregatedArray.length>0){
    //     console.log("aggregatedArray[0].header===",aggregatedArray[0].header);
    // }

    if( aggregatedArray && aggregatedArray.length>0 && 
        (
            aggregatedArray[0].header==="TOTAL END FLEET" ||
            aggregatedArray[0].header==="OPERATIONAL END FLEET" ||
            aggregatedArray[0].header==="END FLEET"
        ) && 
        startFleetArray && startFleetArray.length>0 
    ){
        // console.log("aggregatedArray[0].header===IF===",aggregatedArray[0].header);
        startFleetValue=startFleetArray[0].values[0].currentValues[0].value;
        
    }



    aggregatedArray.forEach((element)=>{
        let updatedElementValues=element.values;
        let updatedChildren=[];

        let isValuesUpdated=false;
        let isChildrenUpdated=false;
        if(element.values && element.values.length>0){
            updatedElementValues=enrichValuesWithFinalValAndAdjVar(element.values,element.header,null,startFleetValue,groupByCategory);
            isValuesUpdated=true;
        }
        if(element.children && element.children.length>0){            
            element.children.forEach((child)=>{
                let updatedChildrenValues=child.values;
                if(child.values && child.values.length>0){
                    updatedChildrenValues=enrichValuesWithFinalValAndAdjVar(child.values,element.header,child.header,0,groupByCategory);
                    // updatedChildrenValues=enrichValuesWithFinalValAndAdjVar(child.values,element.header,child.header,startFleetValue,groupByCategory);
                    let updatedChild={...child,values:updatedChildrenValues};
                    updatedChildren.push(updatedChild);
                    isChildrenUpdated=true;
                }
            })
        }
        let clonedElement=customCloneObject(element);
        if(isValuesUpdated && isChildrenUpdated){
            updatedAggregatedArray.push({...clonedElement,children:updatedChildren,values:updatedElementValues});
        }else if(isValuesUpdated){
            updatedAggregatedArray.push({...clonedElement,values:updatedElementValues});
        }else if(isChildrenUpdated){
            updatedAggregatedArray.push({...clonedElement,children:updatedChildren});
        }else{
            updatedAggregatedArray.push({...clonedElement});
        }
    })

    // console.log("UPDATED aggregatedArray======",updatedAggregatedArray);
    return customCloneObject(updatedAggregatedArray);
}

function enrichValuesWithFinalValAndAdjVar(valuesArrayObject,header,childHeader,startFleetValue,viewType){ //Will update values of children also
    // console.log("valuesArrayObject======",header,childHeader,valuesArrayObject);   
     
    //These values for Next Cell is applicable if Header="END FLEET" only
    let currentValueStartFleetForNextCell = ((startFleetValue) ? startFleetValue: 0);
    let originalValueStartFleetForNextCell = ((startFleetValue) ? startFleetValue: 0);
    let adjustmentValueStartFleetForNextCell = 0;

    if(valuesArrayObject){
        let updatedArray=[];
        valuesArrayObject.forEach(valueElement=>{
            if( valueElement.adjustments && valueElement.originalValues && valueElement.currentValues &&
                valueElement.adjustments.length===valueElement.originalValues.length && 
                valueElement.adjustments.length===valueElement.currentValues.length
              ){

                let adjustments=[];
                let originalValues=[];
                let currentValues=[];
                let fnlValues=[];
                let adjVarValues=[];

                for(let i=0;i<valueElement.adjustments.length;i++){
                    let adjustment=(valueElement.adjustments[i] != null && typeof valueElement.adjustments[i] === 'object'?valueElement.adjustments[i].value:valueElement.adjustments[i]);
                    let originalValue=(valueElement.originalValues[i] != null && typeof valueElement.originalValues[i] === 'object'?valueElement.originalValues[i].value:valueElement.originalValues[i]);
                    let currentValue=(valueElement.currentValues[i] != null && typeof valueElement.currentValues[i] === 'object'?valueElement.currentValues[i].value:valueElement.currentValues[i]);
                    let finalValue = 0;
                    let adjVar = 0;
                    
                    // finalValue = 0+originalValue + adjustment;//0 added to ensure number data type
                    // adjVar=0+finalValue-currentValue;

                    if(viewType==="Type>Zone" && (header==="END FLEET" || header==="OPERATIONAL END FLEET" || header==="TOTAL END FLEET")){
                        adjustmentValueStartFleetForNextCell=0+adjustment+adjustmentValueStartFleetForNextCell;//0 added to ensure number data type
                        originalValueStartFleetForNextCell=0+originalValue+originalValueStartFleetForNextCell;
                        currentValueStartFleetForNextCell=0+currentValue+currentValueStartFleetForNextCell;

                        finalValue = 0+originalValueStartFleetForNextCell + adjustmentValueStartFleetForNextCell;//0 added to ensure number data type
                        adjVar=0+finalValue-currentValueStartFleetForNextCell;

                        adjustments.push({...customCloneObject(valueElement.adjustments[i]),"value":adjustmentValueStartFleetForNextCell});
                        originalValues.push({...customCloneObject(valueElement.originalValues[i]),"value":originalValueStartFleetForNextCell});
                        currentValues.push({...customCloneObject(valueElement.currentValues[i]),"value":currentValueStartFleetForNextCell});
                        fnlValues.push(finalValue);
                        adjVarValues.push(adjVar);
                    }else{
                        finalValue = 0+originalValue + adjustment;//0 added to ensure number data type
                        adjVar=0+finalValue-currentValue;

                        adjustments.push(valueElement.adjustments[i]);
                        originalValues.push(valueElement.originalValues[i] );
                        currentValues.push(valueElement.currentValues[i]);
                        fnlValues.push(finalValue);
                        adjVarValues.push(adjVar);
                    }
                }

                let updatedValuesArrayElement={
                    "adjustments":adjustments,
                    "originalValues":originalValues,
                    "currentValues":currentValues,
                    "fnlValues":fnlValues,
                    "adjVarValues":adjVarValues
                };

                // console.log(",Final valuesArrayObject======",updatedValuesArrayElement);
                updatedArray.push(updatedValuesArrayElement);
            }else{
                updatedArray.push(valueElement);
            }
        })
        return updatedArray;
    }
    
    return valuesArrayObject;
}

function calculateAggregatedValueForAll(data){
    let d=data.reduce((acc,curr)=>{
        var flag=false
        acc.forEach((item)=>{
            if(item.activity_type==curr.activity_type && item.month==curr.month
                && item.year==curr.year && item.pool==curr.pool ){
                item.value=item.value+curr.value
                item.originalValue=item.originalValue+curr.originalValue
                item.adjustment=item.adjustment+curr.adjustment
                item.fnlVal=item.originalValue+item.adjustment
                flag=true
            }
        })
        if(!flag){
            curr.engine_group='NA'
            acc.push(curr)
        }
        return acc
    },[])
return d;
}
//This function is used to calculate aggregated Operational startfleet and aggregated TotalStartFleet for each particular zones
function calculateStartFleetForEachZone(accordionData,aggregatedTotalStartfleetArray){
    let copyOfCurrentValues={},copyOfCurrentValuesForCsStartFleet={},currObj,newValue,startFleetIndex,totalStartFleetIndex,copyOfTotalCurrentValues=[],copyOfTotalCurrentValuesForCsStartFleet=[]
    accordionData.map((adata,aindex)=>{
        if(adata.header==='START_FLEET') //Here START_FLEET would be used for core,tnc,cargo bu but for ALL tab this would be considered as operational start fleet
        {
            startFleetIndex=aindex
        }
        if(adata.header==='START_CS_FLEET') //Here START_CS_FLEET would not be used for any bu but for ALL tab this would be considered as Total start fleet
        {
            totalStartFleetIndex=aindex
        }
        if(adata.header==='END FLEET' || adata.header==='OPERATIONAL END FLEET'){ //For calculating Start fleet(in core,tnc,cargo bu's) and operational start fleet(only in ALL bu) for all the next months
            adata['children'].map((cdata)=>{
              copyOfCurrentValues[cdata.header]=[]
               cdata.values[0]['currentValues'].map((currdata,currIndex)=>{
                    if(currIndex>0){
                        currObj=JSON.parse(JSON.stringify({...currdata}))
                        if(currIndex==1){
                        newValue=cdata.values[0]['currentValues'][currIndex-1]['value']
                        } else{
                         newValue=copyOfCurrentValues[cdata.header][currIndex-2]['value']+cdata.values[0]['currentValues'][currIndex-1]['value']
                        }
                        currObj['value']=newValue
                        currObj['activity_type']='START_FLEET'
                        copyOfCurrentValues[cdata.header].push(currObj)
                    }
               })  
            })
        } else if(adata.header==='TOTAL END FLEET'){ //For calculating Total start fleet(only in ALL bu) for all the next months
            adata['children'].map((cdata)=>{
                copyOfCurrentValuesForCsStartFleet[cdata.header]=[]
               cdata.values[0]['currentValues'].map((currdata,currIndex)=>{
                    if(currIndex>0){
                        currObj=JSON.parse(JSON.stringify({...currdata}))
                        if(currIndex==1){
                        newValue=cdata.values[0]['currentValues'][currIndex-1]['value']
                        } else{
                         newValue=copyOfCurrentValuesForCsStartFleet[cdata.header][currIndex-2]['value']+cdata.values[0]['currentValues'][currIndex-1]['value']
                        }
                        currObj['value']=newValue
                        currObj['activity_type']='START_CS_FLEET'
                        copyOfCurrentValuesForCsStartFleet[cdata.header].push(currObj)
        }
    })
            })
        }
    })

    if(startFleetIndex && Object.keys(copyOfCurrentValues).length>0){
        accordionData[startFleetIndex]['header']=aggregatedTotalStartfleetArray?'OPERATIONAL START FLEET':'START_FLEET' //Checking aggregatedTotalStartfleetArray bcz to match crct header value for particular BU 
        accordionData[startFleetIndex]['children'].map((sfval)=>{
            if(copyOfCurrentValues.hasOwnProperty(sfval.header)){
                copyOfCurrentValues[sfval.header].splice(0,0,sfval['values'][0]['currentValues'][0])
                let data=JSON.parse(JSON.stringify(copyOfCurrentValues[sfval.header]))
                copyOfTotalCurrentValues.push(...data)
            sfval['values'][0]['currentValues']=copyOfCurrentValues[sfval.header]
            sfval['values'][0]['originalValues']=copyOfCurrentValues[sfval.header]
            }
        })
        accordionData[startFleetIndex]['values'][0]['currentValues']=calculateAggregatedValueForAll(copyOfTotalCurrentValues)
        accordionData[startFleetIndex]['values'][0]['originalValues']=accordionData[startFleetIndex]['values'][0]['currentValues']
    }

    if(totalStartFleetIndex==undefined && aggregatedTotalStartfleetArray){//This is used to calculate and push all Total start fleet values and this if is used whenever there is no START_CS_FLEET given(TotalstartFleetIndex would be undefined) 
        let tempaggregatedTotalStartfleetArray={}
        tempaggregatedTotalStartfleetArray['children']=[]
        zones.map((child)=>{
            if(child.name!='ALL'){
                if(copyOfCurrentValuesForCsStartFleet.hasOwnProperty(child.name)){
                    copyOfCurrentValuesForCsStartFleet[child.name].splice(0,0,copyOfCurrentValues[child.name][0])
                    let data=JSON.parse(JSON.stringify(copyOfCurrentValuesForCsStartFleet[child.name]))
                    copyOfTotalCurrentValuesForCsStartFleet.push(...data)
                   let val={
                        'content':copyOfCurrentValuesForCsStartFleet[child.name],
                        'header':child.name,
                        'title':['Requested Adjustment', 'Requested Value','Original Value'],
                        'values':[{
                            "currentValues":copyOfCurrentValuesForCsStartFleet[child.name] ,
                            "adjustments":copyOfCurrentValuesForCsStartFleet[child.name] ,
                            "originalValues":copyOfCurrentValuesForCsStartFleet[child.name]
                        }]
                    }
                    tempaggregatedTotalStartfleetArray['children'].push(val)
                }
            }
        })
        let totalAggregatedStarFleetForEachMonth=calculateAggregatedValueForAll(copyOfTotalCurrentValuesForCsStartFleet)
        const totalAggregatedStarFleetForEachMonthAdjustments = totalAggregatedStarFleetForEachMonth.map(obj => {      
              return {...obj, value: 0};   
          });
        tempaggregatedTotalStartfleetArray['header']='TOTAL START FLEET'
        tempaggregatedTotalStartfleetArray['title']=['Requested Adjustment', 'Requested Value','Original Value']
        tempaggregatedTotalStartfleetArray['values']=[{
                "currentValues":totalAggregatedStarFleetForEachMonth ,
                "adjustments":totalAggregatedStarFleetForEachMonthAdjustments ,
                "originalValues":totalAggregatedStarFleetForEachMonth
            }]
        aggregatedTotalStartfleetArray.push(tempaggregatedTotalStartfleetArray)
        accordionData.push(tempaggregatedTotalStartfleetArray)
    }


    if(totalStartFleetIndex && Object.keys(copyOfCurrentValuesForCsStartFleet).length>0){ //This is used to calculate And Push Total start fleet and this if is executed whenever Start_cs_fleet is given
        accordionData[totalStartFleetIndex]['header']='TOTAL START FLEET'
        accordionData[totalStartFleetIndex]['children'].map((sfval)=>{
            if(copyOfCurrentValuesForCsStartFleet.hasOwnProperty(sfval.header)){
                let tempObj=JSON.parse(JSON.stringify({...sfval['values'][0]['currentValues'][0]}))
                 tempObj['value']= tempObj['value'] + copyOfCurrentValues[sfval.header][0].value
                copyOfCurrentValuesForCsStartFleet[sfval.header].splice(0,0,tempObj)
                let data=JSON.parse(JSON.stringify(copyOfCurrentValuesForCsStartFleet[sfval.header]))
                copyOfTotalCurrentValuesForCsStartFleet.push(...data)
            sfval['values'][0]['currentValues']=copyOfCurrentValuesForCsStartFleet[sfval.header]
            sfval['values'][0]['originalValues']=copyOfCurrentValuesForCsStartFleet[sfval.header]
            }
        })
        accordionData[totalStartFleetIndex]['values'][0]['currentValues']=calculateAggregatedValueForAll(copyOfTotalCurrentValuesForCsStartFleet)
        accordionData[totalStartFleetIndex]['values'][0]['originalValues']=accordionData[totalStartFleetIndex]['values'][0]['currentValues']
    }

    return accordionData
}

function deepCopyObjects(child){
let copyOfCurrentData,copyOfAdjustmentData,copyOfOriginalData;
copyOfCurrentData=JSON.parse(JSON.stringify(child['values'][0]['currentValues']).slice(0));
 copyOfAdjustmentData=JSON.parse(JSON.stringify(child['values'][0]['adjustments']).slice(0));
 copyOfOriginalData=JSON.parse(JSON.stringify(child['values'][0]['originalValues']).slice(0));
 let obj = {};
 obj['currentValues']=[...copyOfCurrentData];
 obj['adjustments']=[...copyOfAdjustmentData];
 obj['originalValues']=[...copyOfOriginalData];
 return obj;

}
//This function is used to calculate  start fleet each particular zones(here utilizing already calculated startfleet value for each zones from aggregatedStartFleetData and replacing in accordion data of Zone view)
function calculateStartFleetForEachZoneInZoneView(accordionData,aggregatedStartFleetData,totStartFleet){
let obj;
 accordionData.map((accData,index)=>{
 aggregatedStartFleetData[0].children.map((child,ind)=>{
  if((child.header===accData.header) || accData.header==='ALL' || accData.header==='TOTAL END FLEET' || accData.header==='OPERATIONAL END FLEET'){
    if(accData.header==='ALL' || accData.header==='TOTAL END FLEET' || accData.header==='OPERATIONAL END FLEET'){
     obj = deepCopyObjects(aggregatedStartFleetData[0]) //in case of header is ALL,aggregation of all zone's start fleet
    }else{
    obj = deepCopyObjects(child)
    }
    accData.children.map((accchild,i)=>{
        if(totStartFleet==undefined && accchild.header==='START_FLEET'){
            accordionData[index]['children'][i]['values'].splice(0,1,obj);
            accordionData[index]['children'].splice(0,0,accordionData[index]['children'].splice(i,1)[0])// START_FLEET to first position
        } else {
            if(totStartFleet && accchild.header==='START_CS_FLEET'){
                accordionData[index]['children'][i]['values'].splice(0,1,obj);
                accordionData[index]['children'].splice(1,0,accordionData[index]['children'].splice(i,1)[0])// START_CS_FLEET to second position    
            }
        }
    })
  }    
 })
  })
  return accordionData;
}

function getAllBUData(tabSelected, groupByCategory, accordiongrouping,freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */

    let aggregatedAddsArray = [];
    let aggregatedDeletesArray = [];
    let aggregatedRiskDeletesArray = [];
    let aggregatedOperationalFleetArray = [];
    let aggregatedEndfleetArray = [];
    let aggregatedStartfleetArray = [];
    let aggregatedTotalStartfleetArray = [];
    let aggregatedSalesArray = [];
    let aggregatedSalvageArray = [];
    let aggregatedNetonewayArray = [];
    let aggregatedNetmovesArray = [];
    let aggregatedZoneArray=[];
    groupedData1.map((value) => {
        let titleCount = 0;
        let totalEndFleetObj={
            'header':'TOTAL END FLEET',
            'content':[],
            'title':['Requested Adjustment','Requested Value','Original Value'],
            'values':[]
        }
        let overAllTotalObj = {};
        overAllTotalObj['adjustments'] = [];
        overAllTotalObj['originalValues'] = [];
        overAllTotalObj['currentValues'] = [];

        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone"){
        fillEmptyZones(groupedData2, zones);
        }
        let currentType = null;
        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        let groupedData3=[];
        groupedData2.forEach((item)=>{
            var aggregatedData=calculateAggregatedValueForAll(item[1])
            var tempGroupedData=[]
            tempGroupedData.push(item[0])
            tempGroupedData.push(aggregatedData)
            groupedData3.push(tempGroupedData)
            })
        obj = calculateBasedOnGrouping(groupedData3, obj, tabSelected);
        let startCsFleetValue=0
        obj.children.map((child) => { //children are each zone
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone"){
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        }else {
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected,'OperationalEndFleet')
                            if(child.header==='START_CS_FLEET' && zoneIndex===0){
                                startCsFleetValue=content.value
                            }
                            overAllTotalObj['currentValues'] = calculateOverallTotalByZone(overAllTotalObj['currentValues'], zoneIndex, customCloneObject(content), child.header, tabSelected,'TotalEndFleet',startCsFleetValue)
                        }
                    }
                })

                //find the index for zone-code ALL,if ALL is found then pass true else false(as isZoneCodeAll)
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');   
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else{
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected,'OperationalEndFleet')
                            if(child.header==='START_CS_FLEET' && monthIndex===0){
                                startCsFleetValue=content.value
                            }
                            overAllTotalObj['adjustments'] = calculateOverallTotalByZone(overAllTotalObj['adjustments'], monthIndex, customCloneObject(content), child.header, tabSelected,'TotalEndFleet',startCsFleetValue)
                        }

                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone"){
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        }else{
                             overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected,'OperationalEndFleet')
                             if(child.header==='START_CS_FLEET' && zoneIndex===0){
                                startCsFleetValue=content.value
                            }
                             overAllTotalObj['originalValues'] = calculateOverallTotalByZone(overAllTotalObj["originalValues"], zoneIndex, customCloneObject(content), child.header, tabSelected,'TotalEndFleet',startCsFleetValue)
                        }
                    }
                 })
            })

            currentType = child.header;
        })

        

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];
        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });

        totalEndFleetObj['values'].push({
            "currentValues": overAllTotalObj['currentValues'],
            "adjustments": overAllTotalObj['adjustments'],
            "originalValues": overAllTotalObj['originalValues']
        });

        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'START_CS_FLEET') {
                aggregatedTotalStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS' ) {
                aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'REPURCHASE_DELETES') {
                aggregatedDeletesArray.push(obj)
            }
            else if (obj.header == 'SALES') {
                aggregatedSalesArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                totalEndFleetObj['content'].push(overAllTotalObj['currentValues'])
                obj['children'].splice(0,0,totalEndFleetObj);
                aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOfAllOperationalStartFleetData.splice(0,initialState.copyOfAllOperationalStartFleetData.length);// setting it to blank array every time switch to Type view
        initialState.copyOfAllTotalStartFleetData.splice(0,initialState.copyOfAllTotalStartFleetData.length);// setting it to blank array every time switch to Type view
        accordionData = aggregatedActivityForAType(accordionData, aggregatedEndfleetArray, tabSelected, freezeCpd);
        getOperationalFleet(accordionData, aggregatedOperationalFleetArray,groupByCategory);
        accordionData=calculateStartFleetForEachZone(accordionData,aggregatedTotalStartfleetArray)
        getRiskDeletesActivity(accordionData, aggregatedRiskDeletesArray,groupByCategory,null);
        initialState.copyOfAllOperationalStartFleetData.push(aggregatedStartfleetArray[0]) //to utilize startfleet calculation for zones in Zone view,creating a copy of it
        initialState.copyOfAllTotalStartFleetData.push(aggregatedTotalStartfleetArray[0]) //to utilize startfleet calculation for zones in Zone view,creating a copy of it
    } 
   else {
        let accData=aggregatedActivityForAZone(accordionData, aggregatedEndfleetArray, tabSelected, freezeCpd);
        calculateStartFleetForEachZoneInZoneView(accordionData,initialState.copyOfAllOperationalStartFleetData)
        calculateStartFleetForEachZoneInZoneView(accordionData,initialState.copyOfAllTotalStartFleetData,true)
        accordionData = accData.accordionData
        let riskDelData= getRiskDeletesActivity(accordionData,null,groupByCategory,aggregatedEndfleetArray);
        accordionData = riskDelData.accordionData
        aggregatedEndfleetArray=riskDelData.aggregatedEndfleetArray
     // getOperationalFleet(accordionData, aggregatedOperationalFleetArray,groupByCategory,accData.totalValues,accData.operationalstartfleet);
    }
    // let sortedAccordionData = accordionData.sort((a, b) => a.year > b.year ? 1 : -1);
    // accordionData = moveItem(accordionData, accordionData.length - 1, 0);

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        aggregatedAddsArray: aggregatedAddsArray,
        aggregatedDeletesArray: aggregatedDeletesArray,
        aggregatedRiskDeletesArray: aggregatedRiskDeletesArray,
        aggregatedOperationalFleetArray: aggregatedOperationalFleetArray,
        aggregatedEndfleetArray: aggregatedEndfleetArray,
        aggregatedStartfleetArray: aggregatedStartfleetArray,
        aggregatedTotalStartfleetArray:aggregatedTotalStartfleetArray,
        aggregatedSalesArray: aggregatedSalesArray,
        aggregatedSalvageArray: aggregatedSalvageArray,
        aggregatedNetonewayArray: aggregatedNetonewayArray,
        aggregatedNetmovesArray: aggregatedNetmovesArray,
        aggregatedZoneArray:aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion ddddd  " + JSON.stringify(accordionData));
    return groupedData;
}


/**
 * function to fetch Core BU Data
 * @param {*} groupedData2 
 * @param {*} obj 
 * @param {*} tabSelected 
 * @returns 
 */

 function getCoreBUData(tabSelected,groupByCategory, accordiongrouping, freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
    
    let corebu_aggregatedAddsArray = [];
    let corebu_aggregatedDeletesArray = [];
    let corebu_aggregatedEndfleetArray = [];
    let corebu_aggregatedStartfleetArray = [];
    let corebu_aggregatedCoretoTncArray = [];
    let corebu_aggregatedCoretoCsArray = [];
    let corebu_aggregatedSalvageArray = [];
    let corebu_aggregatedNetonewayArray = [];
    let corebu_aggregatedNetmovesArray = [];
    let corebu_aggregatedZoneArray=[]
    groupedData1.map((value) => {//Based on Type view, 
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone") {
        fillEmptyZones(groupedData2, zones);
        }
        let currentType = null;
        let groupedData3 = [];

        groupedData2.forEach((item)=>{
            var aggregatedData=calculateAggregatedValue(item[1])
            var tempGroupedData=[]
            tempGroupedData.push(item[0])
            tempGroupedData.push(aggregatedData)
            groupedData3.push(tempGroupedData)
            })  
        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData3, obj, tabSelected);//Calculates based on grouping
        obj.children.map((child) => {
            child.values.map((values) => {
               
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone"){
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        }else{
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }  }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)

                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone"){
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                         } else{
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                     } }
                })
            })
            currentType = child.header;
        })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        }); 
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                corebu_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                corebu_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'REPURCHASE_DELETES') {
                corebu_aggregatedDeletesArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_TNC') {
                corebu_aggregatedCoretoTncArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_CS') {
                corebu_aggregatedCoretoCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                corebu_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                corebu_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                corebu_aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                corebu_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOfcoreStartFleetData.splice(0,initialState.copyOfcoreStartFleetData.length);// setting it to blank array every time switch to Type view
        accordionData = aggregatedActivityForAType(accordionData, corebu_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOfcoreStartFleetData.push(corebu_aggregatedStartfleetArray[0]) //to utilize startfleet calculation for zones in Zone view,creating a copy of it
    }  else {
         accordionData=aggregatedActivityForAZone(accordionData, corebu_aggregatedEndfleetArray, tabSelected, freezeCpd);
         calculateStartFleetForEachZoneInZoneView(accordionData.accordionData,initialState.copyOfcoreStartFleetData)
       }
    // let sortedAccordionData = accordionData.sort((a, b) => a.year > b.year ? 1 : -1);
    // accordionData = moveItem(accordionData, accordionData.length - 1, 0);

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        corebu_aggregatedAddsArray: corebu_aggregatedAddsArray,
        corebu_aggregatedDeletesArray: corebu_aggregatedDeletesArray,
        corebu_aggregatedEndfleetArray: corebu_aggregatedEndfleetArray,
        corebu_aggregatedStartfleetArray: corebu_aggregatedStartfleetArray,
        corebu_aggregatedCoretoTncArray: corebu_aggregatedCoretoTncArray,
        corebu_aggregatedCoretoCsArray: corebu_aggregatedCoretoCsArray,
        corebu_aggregatedSalvageArray: corebu_aggregatedSalvageArray,
        corebu_aggregatedNetonewayArray: corebu_aggregatedNetonewayArray,
        corebu_aggregatedNetmovesArray:corebu_aggregatedNetmovesArray,
        corebu_aggregatedZoneArray:corebu_aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion ddddd  " + JSON.stringify(accordionData));
    return groupedData;
}
//Bu->CORE and engine_group->ICE
function getCoreIceBUData(tabSelected,groupByCategory, accordiongrouping, freezeCpd) {
    let accordionData = [];
    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
   
    let coreice_aggregatedAddsArray = [];
    let coreice_aggregatedDeletesArray = [];
    let coreice_aggregatedEndfleetArray = [];
    let coreice_aggregatedStartfleetArray = [];
    let coreice_aggregatedCoretoTncArray = [];
    let coreice_aggregatedCoretoCsArray = [];
    let coreice_aggregatedSalvageArray = [];
    let coreice_aggregatedNetonewayArray = [];
    let coreice_aggregatedNetmovesArray = [];
    let coreice_aggregatedZoneArray=[];

    groupedData1.map((value) => {
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
         if (groupByCategory == "Type>Zone") {
        fillEmptyZones(groupedData2, zones);
        }

        let currentType = null;
        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData2, obj, tabSelected);

        obj.children.map((child) => {
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        else
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else{
                             let isZoneCodeAll = (obj.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)
                        }
                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        else
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                    }
                })
            })
            currentType = child.header;
        })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                coreice_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                coreice_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'REPURCHASE_DELETES') {
                coreice_aggregatedDeletesArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_TNC') {
                coreice_aggregatedCoretoTncArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_CS') {
                coreice_aggregatedCoretoCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                coreice_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                coreice_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                coreice_aggregatedNetmovesArray.push(obj)
            } 
            else  if (groupByCategory === "Zone>Type") {
                coreice_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOfcoreIceStartFleetData.splice(0,initialState.copyOfcoreIceStartFleetData.length);
        accordionData = aggregatedActivityForAType(accordionData, coreice_aggregatedEndfleetArray, tabSelected, freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOfcoreIceStartFleetData.push(coreice_aggregatedStartfleetArray[0])
    } else {
        let accData=aggregatedActivityForAZone(accordionData, coreice_aggregatedEndfleetArray, tabSelected, freezeCpd);
        accordionData = accData.accordionData 
        calculateStartFleetForEachZoneInZoneView(accordionData,initialState.copyOfcoreIceStartFleetData)
    }
    // let sortedAccordionData = accordionData.sort((a, b) => a.year > b.year ? 1 : -1);
    // accordionData = moveItem(accordionData, accordionData.length - 1, 0);

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        coreice_aggregatedAddsArray: coreice_aggregatedAddsArray,
        coreice_aggregatedDeletesArray: coreice_aggregatedDeletesArray,
        coreice_aggregatedEndfleetArray: coreice_aggregatedEndfleetArray,
        coreice_aggregatedStartfleetArray: coreice_aggregatedStartfleetArray,
        coreice_aggregatedCoretoTncArray: coreice_aggregatedCoretoTncArray,
        coreice_aggregatedCoretoCsArray: coreice_aggregatedCoretoCsArray,
        coreice_aggregatedSalvageArray: coreice_aggregatedSalvageArray,
        coreice_aggregatedNetonewayArray: coreice_aggregatedNetonewayArray,
        coreice_aggregatedNetmovesArray:coreice_aggregatedNetmovesArray,
        coreice_aggregatedZoneArray:coreice_aggregatedZoneArray
 
    };
    accordionData = [];
    // console.log("Print the accordion ddddd  " + JSON.stringify(accordionData));
    return groupedData;
}

//Bu->CORE and engine_group->ELECTRIC
function getCoreElectricBUData(tabSelected,groupByCategory, accordiongrouping, freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
    if(groupByCategory==='Zone>Type'){
    groupedData1.map((val,key)=>{
        if(val[0]==='ALL'){
            let a=groupedData1.splice(key,1)
            groupedData1.splice(0,0,a[0])
        }
    })
}

    let coreelectric_aggregatedAddsArray = [];
    let coreelectric_aggregatedDeletesArray = [];
    let coreelectric_aggregatedEndfleetArray = [];
    let coreelectric_aggregatedStartfleetArray = [];
    let coreelectric_aggregatedCoretoTncArray = [];
    let coreelectric_aggregatedCoretoCsArray = [];
    let coreelectric_aggregatedSalvageArray = [];
    let coreelectric_aggregatedNetonewayArray = [];
    let coreelectric_aggregatedNetmovesArray = [];
    let coreelectric_aggregatedZoneArray=[]
    groupedData1.map((value) => {
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone") {
            fillEmptyZones(groupedData2, zones);
            }

        let currentType = null;
        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData2, obj, tabSelected);

        obj.children.map((child) => {
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        else
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        let isZoneCodeAll
                        if (groupByCategory == "Type>Zone") {
                             isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else{
                            let isZoneCodeAll = (obj.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)
                        }
                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        else
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                    }
                })
            })
            currentType = child.header;
        })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                coreelectric_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                coreelectric_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'REPURCHASE_DELETES') {
                coreelectric_aggregatedDeletesArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_TNC') {
                coreelectric_aggregatedCoretoTncArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_CS') {
                coreelectric_aggregatedCoretoCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                coreelectric_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                coreelectric_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                coreelectric_aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                coreelectric_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOfcoreElecStartFleetData.splice(0,initialState.copyOfcoreElecStartFleetData.length);
        accordionData = aggregatedActivityForAType(accordionData, coreelectric_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOfcoreElecStartFleetData.push(coreelectric_aggregatedStartfleetArray[0])
    } else {
        accordionData = aggregatedActivityForAZone(accordionData, coreelectric_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData = accordionData.accordionData 
        calculateStartFleetForEachZoneInZoneView(accordionData,initialState.copyOfcoreElecStartFleetData)
    }
    // let sortedAccordionData = accordionData.sort((a, b) => a.year > b.year ? 1 : -1);
    // accordionData = moveItem(accordionData, accordionData.length - 1, 0);

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        coreelectric_aggregatedAddsArray: coreelectric_aggregatedAddsArray,
        coreelectric_aggregatedDeletesArray: coreelectric_aggregatedDeletesArray,
        coreelectric_aggregatedEndfleetArray: coreelectric_aggregatedEndfleetArray,
        coreelectric_aggregatedStartfleetArray: coreelectric_aggregatedStartfleetArray,
        coreelectric_aggregatedCoretoTncArray: coreelectric_aggregatedCoretoTncArray,
        coreelectric_aggregatedCoretoCsArray: coreelectric_aggregatedCoretoCsArray,
        coreelectric_aggregatedSalvageArray: coreelectric_aggregatedSalvageArray,
        coreelectric_aggregatedNetonewayArray: coreelectric_aggregatedNetonewayArray,
        coreelectric_aggregatedNetmovesArray:coreelectric_aggregatedNetmovesArray,
        coreelectric_aggregatedZoneArray:coreelectric_aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion ddddd  " + JSON.stringify(accordionData));
    return groupedData;
}

function getCargoBUData(tabSelected,groupByCategory, accordiongrouping,freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
   
    let cargobu_aggregatedAddsArray = [];
    let cargobu_aggregatedEndfleetArray = [];
    let cargobu_aggregatedStartfleetArray = [];
    let cargobu_aggregatedCargoToCsArray = [];
    let cargobu_aggregatedSalvageArray = [];
    let cargobu_aggregatedNetonewayArray = [];
    let cargobu_aggregatedNetmovesArray = [];
    let cargobu_aggregatedZoneArray=[];

    groupedData1.map((value) => {
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone") {
            fillEmptyZones(groupedData2, zones);
            }

        let groupedData3=[]
        groupedData2.forEach((item)=>{
            var aggregatedData=calculateAggregatedValue(item[1])
            var tempGroupedData=[]
            tempGroupedData.push(item[0])
            tempGroupedData.push(aggregatedData)
            groupedData3.push(tempGroupedData)
            })  
        let currentType = null;

        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData3, obj, tabSelected);
        obj.children.map((child) => {
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        else
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else{
                            let isZoneCodeAll = (obj.header == 'ALL');
                           setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)
                        }
                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        else
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                    }
                })
            })
            currentType = child.header;
        })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                cargobu_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                cargobu_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'CARGO_TO_CS') {
                cargobu_aggregatedCargoToCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                cargobu_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                cargobu_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                cargobu_aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                cargobu_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOfcargoStartFleetData.splice(0,initialState.copyOfcargoStartFleetData.length);
        accordionData = aggregatedActivityForAType(accordionData, cargobu_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOfcargoStartFleetData.push(cargobu_aggregatedStartfleetArray[0])
    } else {
        accordionData = aggregatedActivityForAZone(accordionData, cargobu_aggregatedEndfleetArray, tabSelected,freezeCpd);
        calculateStartFleetForEachZoneInZoneView(accordionData.accordionData,initialState.copyOfcargoStartFleetData)
    }

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        cargobu_aggregatedAddsArray: cargobu_aggregatedAddsArray,
        cargobu_aggregatedSalvageArray: cargobu_aggregatedSalvageArray,
        cargobu_aggregatedEndfleetArray: cargobu_aggregatedEndfleetArray,
        cargobu_aggregatedStartfleetArray: cargobu_aggregatedStartfleetArray,
        cargobu_aggregatedCargoToCsArray: cargobu_aggregatedCargoToCsArray,
        cargobu_aggregatedNetonewayArray: cargobu_aggregatedNetonewayArray,
        cargobu_aggregatedNetmovesArray: cargobu_aggregatedNetmovesArray,
        cargobu_aggregatedZoneArray:cargobu_aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion data for TNC:" + JSON.stringify(groupedData));
    return groupedData;
}

/**
 * @typedef dataModel
 * @type {{
 *   "header": string,
 *   "title": string,
 *   "children": [],
 *   "values": [],
 * }}
 */
/**
 * 
 * @param {[dataModel]} groupedData2 
 * @param {[dataModel]} obj 
 * @param {number} tabSelected 
 * @returns 
 */

 //For TNC BU

 function getTncBUData(tabSelected,groupByCategory, accordiongrouping,freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
   
    let tncbu_aggregatedAddsArray = [];
    let tncbu_aggregatedEndfleetArray = [];
    let tncbu_aggregatedStartfleetArray = [];
    let tncbu_aggregatedCoretoTncArray = [];
    let tncbu_aggregatedTncToCsArray = [];
    let tncbu_aggregatedSalvageArray = [];
    let tncbu_aggregatedNetonewayArray = [];
    let tncbu_aggregatedNetmovesArray = [];
    let tncbu_aggregatedZoneArray=[];
    groupedData1.map((value) => {
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone") {
            fillEmptyZones(groupedData2, zones);
            }

        let groupedData3=[]
        groupedData2.forEach((item)=>{
            var aggregatedData=calculateAggregatedValue(item[1])
            var tempGroupedData=[]
            tempGroupedData.push(item[0])
            tempGroupedData.push(aggregatedData)
            groupedData3.push(tempGroupedData)
            })  
        let currentType = null;

        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData3, obj, tabSelected);
        obj.children.map((child) => {
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        else
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)

                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        else
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                    }
                })
            })
            currentType = child.header;
        })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                tncbu_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                tncbu_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_TNC') {
               tncbu_aggregatedCoretoTncArray.push(obj)
            }
            else if (obj.header == 'TNC_TO_CS') {
                tncbu_aggregatedTncToCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                tncbu_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                tncbu_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                tncbu_aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                tncbu_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOftncStartFleetData.splice(0,initialState.copyOftncStartFleetData.length);
        accordionData = aggregatedActivityForAType(accordionData, tncbu_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOftncStartFleetData.push(tncbu_aggregatedStartfleetArray[0])
    } else {
        accordionData = aggregatedActivityForAZone(accordionData, tncbu_aggregatedEndfleetArray, tabSelected,freezeCpd);
        calculateStartFleetForEachZoneInZoneView(accordionData.accordionData,initialState.copyOftncStartFleetData)
    }

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        tncbu_aggregatedAddsArray: tncbu_aggregatedAddsArray,
        tncbu_aggregatedSalvageArray: tncbu_aggregatedSalvageArray,
        tncbu_aggregatedEndfleetArray: tncbu_aggregatedEndfleetArray,
        tncbu_aggregatedStartfleetArray: tncbu_aggregatedStartfleetArray,
        tncbu_aggregatedCoretoTncArray: tncbu_aggregatedCoretoTncArray,
        tncbu_aggregatedTncToCsArray: tncbu_aggregatedTncToCsArray,
        tncbu_aggregatedNetonewayArray: tncbu_aggregatedNetonewayArray,
        tncbu_aggregatedNetmovesArray: tncbu_aggregatedNetmovesArray,
        tncbu_aggregatedZoneArray:tncbu_aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion data for TNC:" + JSON.stringify(groupedData));
    return groupedData;
}
//Bu->TNC and engine_group->ice
function getTncIceBUData(tabSelected,groupByCategory, accordiongrouping,freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
   
    let tncice_aggregatedAddsArray = [];
    let tncice_aggregatedEndfleetArray = [];
    let tncice_aggregatedStartfleetArray = [];
    let tncice_aggregatedCoretoTncArray = [];
    let tncice_aggregatedTncToCsArray = [];
    let tncice_aggregatedSalvageArray = [];
    let tncice_aggregatedNetonewayArray = [];
    let tncice_aggregatedNetmovesArray = [];
    let tncice_aggregatedZoneArray=[];

    groupedData1.map((value) => {
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone") {
            fillEmptyZones(groupedData2, zones);
            }

        let currentType = null;
        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData2, obj, tabSelected);
        obj.children.map((child) => {
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        else
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else{
                            let isZoneCodeAll = (obj.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)
                    }
                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        else
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                    }
                })
            })
            currentType = child.header;
        })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                tncice_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                tncice_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_TNC') {
               tncice_aggregatedCoretoTncArray.push(obj)
            }
            else if (obj.header == 'TNC_TO_CS') {
                tncice_aggregatedTncToCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                tncice_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                tncice_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                tncice_aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                tncice_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOftncIceStartFleetData.splice(0,initialState.copyOftncIceStartFleetData.length);
        accordionData = aggregatedActivityForAType(accordionData, tncice_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOftncIceStartFleetData.push(tncice_aggregatedStartfleetArray[0])
    } else {
        accordionData = aggregatedActivityForAZone(accordionData, tncice_aggregatedEndfleetArray, tabSelected,freezeCpd);
        calculateStartFleetForEachZoneInZoneView(accordionData.accordionData,initialState.copyOftncIceStartFleetData)
        
    }

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        tncice_aggregatedAddsArray: tncice_aggregatedAddsArray,
        tncice_aggregatedSalvageArray: tncice_aggregatedSalvageArray,
        tncice_aggregatedEndfleetArray: tncice_aggregatedEndfleetArray,
        tncice_aggregatedStartfleetArray: tncice_aggregatedStartfleetArray,
        tncice_aggregatedCoretoTncArray: tncice_aggregatedCoretoTncArray,
        tncice_aggregatedTncToCsArray: tncice_aggregatedTncToCsArray,
        tncice_aggregatedNetonewayArray: tncice_aggregatedNetonewayArray,
        tncice_aggregatedNetmovesArray:tncice_aggregatedNetmovesArray,
        tncice_aggregatedZoneArray:tncice_aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion data for TNC:" + JSON.stringify(groupedData));
    return groupedData;
}

//Bu->TNC and engine_group->electric
function getTncElectricBUData(tabSelected,groupByCategory, accordiongrouping,freezeCpd) {
    let accordionData = [];

    let groupedData1 = Object.entries(accordiongrouping);/** Loop through all the activities */
   
    let tncelectric_aggregatedAddsArray = [];
    let tncelectric_aggregatedEndfleetArray = [];
    let tncelectric_aggregatedStartfleetArray = [];
    let tncelectric_aggregatedCoretoTncArray = [];
    let tncelectric_aggregatedTncToCsArray = [];
    let tncelectric_aggregatedSalvageArray = [];
    let tncelectric_aggregatedNetonewayArray = [];
    let tncelectric_aggregatedNetmovesArray = [];
    let tncelectric_aggregatedZoneArray=[];
    groupedData1.map((value) => {
        let titleCount = 0;
        let obj = {};
        obj['header'] = '';
        obj['children'] = [];
        obj['header'] = value[0];
        let groupedData2 = Object.entries(value[1]); //Get all the zones in the activity
        if (groupByCategory == "Type>Zone") {
            fillEmptyZones(groupedData2, zones);
            }

        let currentType = null;
        let overallObj = {};
        overallObj['adjustments'] = [];
        overallObj['originalValues'] = [];
        overallObj['currentValues'] = [];

        obj['values'] = [];
        obj['title'] = [];

        if (titleCount == 0) {
            obj['title'].push('Requested Adjustment');
            obj['title'].push('Requested Value');
            obj['title'].push('Original Value');
            titleCount++;
        }
        obj = calculateBasedOnGrouping(groupedData2, obj, tabSelected);
        obj.children.map((child) => {
            child.values.map((values) => {
                values.currentValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['currentValues'] = calculateOverallTotalByType(overallObj['currentValues'], zoneIndex, content)
                        else
                            overallObj['currentValues'] = calculateOverallTotalByZone(overallObj['currentValues'], zoneIndex, content, child.header, tabSelected)
                    }
                })
                values.adjustments.map((content, monthIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone") {
                            let isZoneCodeAll = (child.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByType(overallObj['adjustments'], monthIndex, content, isZoneCodeAll)
                        }
                        else{
                            let isZoneCodeAll = (obj.header == 'ALL');
                            setAdjLevel(isZoneCodeAll, content.value);
                            overallObj['adjustments'] = calculateOverallTotalByZone(overallObj['adjustments'], monthIndex, content, child.header, tabSelected)
                        }
                    }
                })
                values.originalValues.map((content, zoneIndex) => {
                    if (currentType == null || currentType != child.header) {
                        if (groupByCategory == "Type>Zone")
                            overallObj['originalValues'] = calculateOverallTotalByType(overallObj["originalValues"], zoneIndex, content)
                        else
                            overallObj['originalValues'] = calculateOverallTotalByZone(overallObj["originalValues"], zoneIndex, content, child.header, tabSelected)
                    }
                })
            })
            currentType = child.header;
           })

        var overallBUCurrentValues = overallObj['currentValues'];
        var overallBUAdjustments = overallObj['adjustments'];
        var overallBUOriginalValues = overallObj['originalValues'];

        obj['values'].push({
            "currentValues": overallBUCurrentValues,
            "adjustments": overallBUAdjustments,
            "originalValues": overallBUOriginalValues
        });
        if (obj.children && obj.children.length > 0)
            if (obj.header == 'START_FLEET') {
                tncelectric_aggregatedStartfleetArray.push(obj)
            }
            else if (obj.header == 'ADDS') {
                tncelectric_aggregatedAddsArray.push(obj)
            }
            else if (obj.header == 'CORE_TO_TNC') {
               tncelectric_aggregatedCoretoTncArray.push(obj)
            }
            else if (obj.header == 'TNC_TO_CS') {
                tncelectric_aggregatedTncToCsArray.push(obj)
            }
            else if (obj.header == 'SALVAGE') {
                tncelectric_aggregatedSalvageArray.push(obj)
            }
            else if (obj.header == 'NET_ONEWAY') {
                tncelectric_aggregatedNetonewayArray.push(obj)
            }
            else if (obj.header == 'NET_MOVES') {
                tncelectric_aggregatedNetmovesArray.push(obj)
            }
            else  if (groupByCategory === "Zone>Type") {
                tncelectric_aggregatedZoneArray.push(obj)
            }
        accordionData.push(obj);
    });

    if (groupByCategory == 'Type>Zone') {
        initialState.copyOftncElecStartFleetData.splice(0,initialState.copyOftncElecStartFleetData.length);
        accordionData = aggregatedActivityForAType(accordionData, tncelectric_aggregatedEndfleetArray, tabSelected,freezeCpd);
        accordionData=calculateStartFleetForEachZone(accordionData)
        initialState.copyOftncElecStartFleetData.push(tncelectric_aggregatedStartfleetArray[0])
    } else {
        accordionData = aggregatedActivityForAZone(accordionData, tncelectric_aggregatedEndfleetArray, tabSelected,freezeCpd);
        calculateStartFleetForEachZoneInZoneView(accordionData.accordionData,initialState.copyOftncElecStartFleetData)
    }

    // This will deepcopy the arrays
    let groupedData = {
        //TODO deepcopy and accordionData
        tncelectric_aggregatedAddsArray: tncelectric_aggregatedAddsArray,
        tncelectric_aggregatedSalvageArray: tncelectric_aggregatedSalvageArray,
        tncelectric_aggregatedEndfleetArray: tncelectric_aggregatedEndfleetArray,
        tncelectric_aggregatedStartfleetArray: tncelectric_aggregatedStartfleetArray,
        tncelectric_aggregatedCoretoTncArray: tncelectric_aggregatedCoretoTncArray,
        tncelectric_aggregatedTncToCsArray: tncelectric_aggregatedTncToCsArray,
        tncelectric_aggregatedNetonewayArray: tncelectric_aggregatedNetonewayArray,
        tncelectric_aggregatedNetmovesArray: tncelectric_aggregatedNetmovesArray,
        tncelectric_aggregatedZoneArray:tncelectric_aggregatedZoneArray
    };
    accordionData = [];
    // console.log("Print the accordion data for TNC Electric:",groupedData);
    return groupedData;
}

function  createFinalValues(overallBUAdjustments,overallBUOriginalValues){
    let finalValues=[];

    if(overallBUAdjustments.length===overallBUOriginalValues.length){
        for(let i=0;i<overallBUAdjustments.length;i++){
            let finalValue = 0;
            let adjustment = 0;

            if (overallBUAdjustments[i] != null) {
                if (typeof overallBUAdjustments[i] === 'object')
                    adjustment = 0+overallBUAdjustments[i].value;//0 added to ensure number data type
                else
                    adjustment = 0+overallBUAdjustments[i];//0 added to ensure number data type
            }

            if (typeof overallBUOriginalValues[i] === 'object')
                finalValue = 0+overallBUOriginalValues[i].value + adjustment;//0 added to ensure number data type
            else
                finalValue = 0+overallBUOriginalValues[i] + adjustment;//0 added to ensure number data type

            finalValues.push(finalValue);
        }
    }else{
        console.log("WARNING :: Final values not calculated due to different input array length");
    }

    return finalValues;
}
function createAdjVar(overallBUFinalValues, overallBUCurrentValues){
    let adjustmentVariances=[];

    if(overallBUFinalValues.length===overallBUCurrentValues.length){
        for(let i=0;i<overallBUFinalValues.length;i++){
            adjustmentVariances.push(overallBUFinalValues[i]-overallBUCurrentValues[i].value);
        }
    }else{
        console.log("WARNING :: Final values not calculated due to different input array length");
    }

    return adjustmentVariances;
}


function calculateAggregatedValue(data){
    let d=data.reduce((acc,curr)=>{
        var flag=false
        acc.forEach((item)=>{
            if(item.activity_type==curr.activity_type && item.bu==curr.bu && item.month==curr.month 
                && item.year==curr.year && item.pool==curr.pool ){
                item.value=item.value+curr.value
                item.originalValue=item.originalValue+curr.originalValue
                item.adjustment=item.adjustment+curr.adjustment
                item.fnlVal=item.originalValue+item.adjustment
                flag=true
            }
        })
        if(!flag){
            curr.engine_group='NA'
            acc.push(curr)
        }
        return acc
    },[])
return d;
}

function calculateBasedOnGrouping(groupedData2, obj, tabSelected) {
    groupedData2.map((value1) => { //loop through all the zones in the activity
        let obj1 = {}
        let count = 0;
          obj1['header'] = value1[0];
        obj1['title'] = [];
        obj1['content'] = [];
        obj1['values'] = [];

        let buObj = {};
        buObj['content'] = [];
        buObj['adjustments'] = [];
        buObj['originalValues'] = [];
        buObj['currentValues'] = [];
        obj1['title'] = [];

        var valuesByYear = Object.values(value1[1]); //get the all the months data in the zone

        var startCPDMonth = (valuesByYear[0].cpd).substring(4, 6); //TOREVIEW: Ideally you should get this from parameters table. Low priority.
        startCPDMonth = parseInt(startCPDMonth, 0);
        let startYear = (valuesByYear[0].cpd).substring(0, 4);
        startYear = parseInt(startYear, 0);
        let cpd = { "month": startCPDMonth, "year": startYear }
        var sortDataByBU;
        if (valuesByYear && valuesByYear.length > 0) {
            sortDataByBU = fetchSortedData(valuesByYear, cpd); // categorize data by BUs. Each bu data will loop through num of plan month times.
        }
        if (sortDataByBU && sortDataByBU.length > 0) {
            sortDataByBU.map((dataByBU) => { //Looping through each BU
                let buData = dataByBU.data;
                buData.forEach(val => {
                 
                    if (count == 0) {
                        obj1['title'].push('Requested Adjustment');
                        obj1['title'].push('Requested Value');
                        obj1['title'].push('Original Value');
                        count++;
                    }

                    //TOREVIEW: is following block is used for calculations. Can it be omitted?
                    // summation of all bu s for all Bu tab
                    let contentIndex = checkIfBuExists(buObj['content'], val.bu, val.year, val.month, val.activity_type, val.zone_code);
                    if (contentIndex >= 0) {
                        if (tabSelected == 0) {
                            if (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALES" || val.activity_type == 'SALVAGE' || val.activity_type == 'REPURCHASE_DELETES')
                                buObj['content'][contentIndex].value = val.value;
                        } else if (tabSelected == 1) {
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                                val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                                val.activity_type == "CORE_TO_TNC" ||val.activity_type == "SALVAGE" )) {
                                    buObj['content'].push(val);
                            }
                        } else if (tabSelected == 2) {
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALVAGE" ||
                                val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                                val.activity_type == "CORE_TO_TNC")) {
                                    buObj['content'].push(val);
                            }
                        }else if (tabSelected == 3) {
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALVAGE" ||
                                val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                                val.activity_type == "CORE_TO_TNC")) {
                                    buObj['content'].push(val);
                            }
                        }else if (tabSelected == 4){
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                                val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                                val.activity_type == "CORE_TO_TNC")) {
                                    buObj['content'].push(val);
                            }
                        }
                        else if (tabSelected == 5){
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                                val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                                val.activity_type == "CORE_TO_TNC")) {
                                    buObj['content'].push(val);
                            }
                        }
                        else if (tabSelected == 6){
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                                val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                                val.activity_type == "CORE_TO_TNC")) {
                                    buObj['content'].push(val);
                            }
                        }
                        else if (tabSelected == 7) {
                            if ((val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                                val.activity_type == 'SALVAGE' || val.activity_type == "CARGO_TO_CS")) {
                                    buObj['content'].push(val);
                            }                            
                        }

                    } else
                        buObj['content'].push(val);

                    let adjustmentsIndex = checkIfBuExists(buObj['adjustments'], val.bu, val.year, val.month, val.activity_type, val.zone_code)
                    if (adjustmentsIndex >= 0) {
                        // summation of all bu s for all Bu tab
                        if (tabSelected == 0 && val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALES" || val.activity_type == 'SALVAGE' || val.activity_type == 'REPURCHASE_DELETES')
                            buObj['adjustments'][adjustmentsIndex] = {
                                "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (buObj['adjustments'][adjustmentsIndex].value + val.adjustment), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group
                            };

                        else if (tabSelected == 1 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC" || val.activity_type == "SALVAGE")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                        else if (tabSelected == 2 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALVAGE" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                        else if (tabSelected == 3 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||  val.activity_type == "SALVAGE" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                        else if (tabSelected == 4 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                        else if (tabSelected == 5 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                        else if (tabSelected == 6 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                        else if (tabSelected == 7 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "CARGO_TO_CS")) {
                            buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                        }

                    } else {
                        buObj['adjustments'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (val.adjustment != null ? val.adjustment : 0), "activity_type": val.activity_type, "zone_code": val.zone_code, "bu": val.bu, "pool": val.pool, "engine_group": val.engine_group });
                    }


                    let originalValuesIndex = checkIfBuExists(buObj['originalValues'], val.bu, val.year, val.month, val.activity_type, val.zone_code)
                    if (originalValuesIndex >= 0) {
                        // summation of all bu s for all Bu tab
                        if (tabSelected == 0 && val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALES" || val.activity_type == 'SALVAGE' || val.activity_type == 'REPURCHASE_DELETES')
                            buObj['originalValues'][originalValuesIndex] = { "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (buObj['originalValues'][originalValuesIndex].value + val.originalValue), "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group
                        };

                        else if (tabSelected == 1 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC"||val.activity_type == "SALVAGE")) {
                            buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                        }

                        else if (tabSelected == 2 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALVAGE" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                        }

                        else if (tabSelected == 3 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||  val.activity_type == "SALVAGE" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                        }

                        else if (tabSelected == 4 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||val.activity_type == "CORE_TO_TNC")) {
                            buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                         } 

                        else if (tabSelected == 5 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                         val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||val.activity_type == "CORE_TO_TNC")) {
                         buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                         }   

                        else if (tabSelected == 6 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                         val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||val.activity_type == "CORE_TO_TNC")) {
                         buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                         }
 
                        else if (tabSelected == 7 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                         val.activity_type == 'SALVAGE' || val.activity_type == "CARGO_TO_CS" )) {
                         buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                      }
                         
                    } else {
                        buObj['originalValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.originalValue, "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool,"engine_group":val.engine_group });

                    }
                    let currentValuesIndex = checkIfBuExists(buObj['currentValues'], val.bu, val.year, val.month, val.activity_type, val.zone_code)

                    if (currentValuesIndex >= 0) {
                        // summation of all bu s for all Bu tab
                        if (tabSelected == 0 && val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALES" || val.activity_type == 'SALVAGE' || val.activity_type == 'REPURCHASE_DELETES') {
                            buObj['currentValues'][currentValuesIndex] = { "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": (buObj['currentValues'][currentValuesIndex].value + val.value), "activity_type": val.activity_type, "zone_code": val.zone_code,"bu":val.bu, "pool":val.pool,"engine_group":val.engine_group};
                        } 
                        
                        else if (tabSelected == 1 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC"||val.activity_type == "SALVAGE")) {
                            buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool,"engine_group":val.engine_group });
                        }

                        else if (tabSelected == 2 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALVAGE" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                        }

                        else if (tabSelected == 3 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" || val.activity_type == "SALVAGE" ||
                            val.activity_type == 'REPURCHASE_DELETES' || val.activity_type == "CORE_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool,"engine_group":val.engine_group });
                        }

                        else if (tabSelected == 4 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool,"engine_group":val.engine_group });
                        }
 
                        else if (tabSelected == 5 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                        }

                        else if (tabSelected == 6 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                            val.activity_type == 'SALVAGE' || val.activity_type == "TNC_TO_CS" ||
                            val.activity_type == "CORE_TO_TNC")) {
                            buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                        }

                        else if (tabSelected == 7 && (val.activity_type == "ADDS" || val.activity_type == "NET_ONEWAY" || val.activity_type == "NET_MOVES" ||
                        val.activity_type == 'SALVAGE' || val.activity_type == "CARGO_TO_CS")) {
                        buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                    }
                    }
                    else {
                        buObj['currentValues'].push({ "year": val.year, "cpd": cpd, "month": val.month, "num_plan_months": val.num_plan_months, "value": val.value, "activity_type": val.activity_type, "zone_code": val.zone_code, "bu":val.bu, "pool":val.pool ,"engine_group":val.engine_group});
                    }
                });
            });

            let zoneObj = {};
            zoneObj['currentValues'] = [];
            obj1['content'] = buObj['content'];
            obj1['values'].push({
                "adjustments": buObj['adjustments'],
                "originalValues": buObj['originalValues'],
                "currentValues": buObj['currentValues']
            });
            
            obj['children'].push(obj1);
        }
    });
    return obj;
}

/**
 * Segregate the zone level data into each BU. 
 * @param {*} data 
 * @returns plansArray - [{bu:CORE, "data":[{month,month_name,year,value}]}]
 */
function fetchSortedData(data, cpd) {
    var startCPDMonth = cpd.month;
    let numPlanMonths = data[0].num_plan_months;
    let startMonth = startCPDMonth;
    let startYear = cpd.year;
    let currentBU = data[0].bu;
    let sortedArray = [];
    let maxMonth = 12;
    let planMonthsForEachZone = [];
    let plansArray = [];


    for (let j = 0; j < numPlanMonths; j++) {
        planMonthsForEachZone.push({ "month": startMonth, "year": startYear });
        if (startMonth == maxMonth) {
            startMonth = 0;
            startYear++;
        }
        if (planMonthsForEachZone.length < numPlanMonths)
            startMonth++;
    }
    let index = 0;
    for (let i = 0; i < data.length; i++) { //looping through (number of months in the plan x number of BUs)
        if (data[i] && data[i].bu != "ALL") {
            if (currentBU != data[i].bu) { // bu changed
                // collecting previous bu sorted array
                sortedArray = fillDataIfWithEmpty(sortedArray, numPlanMonths, planMonthsForEachZone, index, data, i);
                plansArray.push({ "bu": currentBU, "data": sortedArray });
                //reset currentBU to new BU
                currentBU = data[i].bu;
                sortedArray = [];
                index = 0;
            }

            if (planMonthsForEachZone[index].month == data[i].month && planMonthsForEachZone[index].year == data[i].year) {
                sortedArray.push(data[i]);
                if (index < numPlanMonths - 1)
                    index++;
            } else {
                if (planMonthsForEachZone[index].year == data[i].year && planMonthsForEachZone[index].month != data[i].month) {
                    const diffMonth = data[i].month - planMonthsForEachZone[index].month;
                    for (let x = 0; x <= diffMonth; x++) {
                        let diff = { ...data[i] }
                        if (x < diffMonth) { //x < diffMonth
                            diff.month = planMonthsForEachZone[index].month;
                            diff.month_name = fetchMonthName(planMonthsForEachZone[index].month);
                            diff.year = planMonthsForEachZone[index].year;
                            diff.value = 0;
                            diff.originalValue = 0;
                            diff.adjustment = 0;
                        }
                        sortedArray.push({ ...diff });
                        if (index < numPlanMonths - 1)
                            index++;
                    }
                } else if (planMonthsForEachZone[index].year != data[i].year) {
                    const diffYear = data[i].year - planMonthsForEachZone[index].year;
                    let k = planMonthsForEachZone[index].month;
                    for (; k <= ((diffYear * maxMonth) + data[i].month); k++) {
                        let diff1 = { ...data[i] }
                        if (k < ((diffYear *maxMonth) + data[i].month)) {
                            diff1.month = planMonthsForEachZone[index].month;
                            diff1.month_name = fetchMonthName(planMonthsForEachZone[index].month);
                            diff1.year = planMonthsForEachZone[index].year;
                            diff1.value = 0;
                            diff1.originalValue = 0;
                            diff1.adjustment = 0;
                        }
                        sortedArray.push({ ...diff1 });
                        if (index < numPlanMonths - 1)
                            index++;
                    }
            }
            }

// checking for last item in the data set to collect current bu's sorted array
            if (i == data.length - 1) {
                sortedArray = fillDataIfWithEmpty(sortedArray, numPlanMonths, planMonthsForEachZone, index, data, i);
                plansArray.push({ "bu": currentBU, "data": sortedArray }); //{bu:CORE, "data":[{month,month_name,year,value}]}
            }
        }
    }
    return plansArray;
}

function fillDataIfWithEmpty(sortedArray, numPlanMonths, planMonthsForEachZone, index, data, i) {
    // Fills the data . Checks for lapsed data at the end.
    if (sortedArray.length < numPlanMonths) {
        let diffLength = numPlanMonths - sortedArray.length;
        for (let p = 0; p < diffLength; p++) {
            // no need to use data[i] to fill zero values, we can use sortedArray's any item of the same bu
            let diffData = { ...sortedArray[0] }; // sortedArray will atleast have 1 item
            diffData.month = planMonthsForEachZone[index].month;
            diffData.month_name = fetchMonthName(planMonthsForEachZone[index].month);
            diffData.year = planMonthsForEachZone[index].year;
            diffData.value = 0; // send adjustment, value and originalValue as 0 for empty months
            diffData.originalValue = 0;
            diffData.adjustment = 0;
            sortedArray.push({ ...diffData });
            if (index < numPlanMonths)
                index++;
        }
    }
    return sortedArray;
}

/**
 * TOREVIEW: checks if the row exists in array: for given year, month, activitypt and zonecode
 * @param {*} array 
 * @param {*} type 
 * @param {*} year 
 * @param {*} month 
 * @param {*} activityType 
 * @param {*} zoneCode 
 * @returns 
 */
function checkIfBuExists(array, bu, year, month, activityType, zoneCode) {
    let jsonArray = [...array];
    let index = jsonArray.findIndex(element => element.month == month && element.year == year &&
        element.activity_type == activityType && element.zone_code == zoneCode && element.bu != bu);
    return index;
}

//Calculate Risk Deletes individual values by Type.
function getRiskDeletesActivity(accordionData, aggregatedRiskDeletesArray,groupByCategory,aggregatedEndfleetArray) {
    let riskDeletesObj = {};
    riskDeletesObj.header = "RISK DELETES";
    riskDeletesObj['title'] = [];
    riskDeletesObj['children'] = [];
    riskDeletesObj['total'] = [];
    riskDeletesObj['title'].push('Requested Adjustment');
    riskDeletesObj['title'].push('Requested Value');
    riskDeletesObj['title'].push('Original Value');
    riskDeletesObj['values'] = [];
    let totalValues = {},zoneMap={};
    //To calculate risk deletes in Zone>Type for each zone 
    if(groupByCategory==='Zone>Type'){
        let data = [],currentdata=[],adjustmentdata=[],originaldata=[],val={};
        let copyOfData,copyOfCurrentData,copyOfAdjustmentData,copyOfOriginalData
        let riskDetesActivityTypes=[AllTabActivitiy[1],AllTabActivitiy[2],AllTabActivitiy[3]]//----> //['CARGO_TO_CS','CORE_TO_CS','TNC_TO_CS']
        accordionData.map((dat,akey)=>{
         data = [];currentdata=[];adjustmentdata=[];originaldata=[];
         riskDetesActivityTypes.map((rval,key)=>{
             dat.children.map((val,ckey)=>{
                 if(val.header===rval){
                     copyOfData = JSON.parse(JSON.stringify(val['content'].slice(0)));
                     data.push(...copyOfData)
     
                     copyOfCurrentData=JSON.parse(JSON.stringify(val['values'][0]['currentValues'].slice(0)));
                     currentdata.push(...copyOfCurrentData)
     
                     copyOfAdjustmentData=JSON.parse(JSON.stringify(val['values'][0]['adjustments'].slice(0)));
                     adjustmentdata.push(...copyOfAdjustmentData)
     
                     copyOfOriginalData=JSON.parse(JSON.stringify(val['values'][0]['originalValues'].slice(0)));
                     originaldata.push(...copyOfOriginalData)
     
                     delete accordionData[akey]['children'][ckey]
                  }
             })
         })
     val={
             'content':data,
             'header':'RISK DELETES',
             'title':['Requested Adjustment', 'Requested Value','Original Value'],
             'values':[{
                 "currentValues":calculateZoneActivityAggregated(currentdata,true) ,
                 "adjustments":calculateZoneActivityAggregated(adjustmentdata,true) ,
                 "originalValues":calculateZoneActivityAggregated(originaldata,true)
             }]
         }
     accordionData[akey]['children'].splice(AllTabActivitiy.indexOf('REPURCHASE_DELETES')+1,0,val)//Pushing RISK DELETES to each zone after repurchase_deletes
         })
         delete aggregatedEndfleetArray[0]['children'][AllTabActivitiy[1]];//delete cargo_to_cs
         delete aggregatedEndfleetArray[0]['children'][AllTabActivitiy[2]];//delete core_to_cs
         delete aggregatedEndfleetArray[0]['children'][AllTabActivitiy[3]];//delete tnc_to_cs
        } else {
    accordionData.map((data) => {
        let header = data.header;

        totalValues[header] = [];
        data['values'].map((values) => {
            totalValues[header] = values;
        })
        data.children.map((child) => {
            if (getZonesForBU(data.header)) {
                let existingarray = zoneMap[child.header]
                if(existingarray){
                    existingarray.push(JSON.parse(JSON.stringify(child)))
                    zoneMap[child.header] = existingarray;
                }
                else {
                    let zoneArray = [JSON.parse(JSON.stringify(child))];
                    zoneMap[child.header] = zoneArray;
            }
         }
        })
    });
    let zoneData = [];
    zones.map((child) => {
        if (child.name != 'ALL') {
            let endfleetZone = zoneMap[child.name].reduce((acc, cur) => {
                if (!acc) {
                    acc = JSON.parse(JSON.stringify(cur));
                }
                else {
                    acc.values[0].adjustments.forEach((element, index, curarray) => {
                        calculateRiskDeletesZoneValues(curarray[index], cur.values[0].adjustments[index]);
                    });
                    acc.values[0].currentValues.forEach((element, index, curarray) => {
                        calculateRiskDeletesZoneValues(curarray[index], cur.values[0].currentValues[index]);
                    });
                    acc.values[0].originalValues.forEach((element, index, curarray) => {
                        calculateRiskDeletesZoneValues(curarray[index], cur.values[0].originalValues[index]);

                    });
                }
                return acc;
            });
            zoneData.push(endfleetZone);
        }
    });
    riskDeletesObj['children'] = zoneData;
    var calculatedValuesObj = [];
    let riskDeletesCurrentValues = calculateRiskDeletesValues(totalValues, calculatedValuesObj, "currentValues", accordionData)
    let riskDeletesAdjustmentValues = calculateRiskDeletesValues(totalValues, calculatedValuesObj, "adjustments", accordionData)
    let riskDeletesOriginalValues = calculateRiskDeletesValues(totalValues, calculatedValuesObj, "originalValues", accordionData)
    
    riskDeletesObj['values'].push({
        "currentValues": riskDeletesCurrentValues, "adjustments": riskDeletesAdjustmentValues,
        "originalValues": riskDeletesOriginalValues
    });
    accordionData.push(riskDeletesObj);
    aggregatedRiskDeletesArray.push(riskDeletesObj)
}
    return {accordionData,aggregatedRiskDeletesArray,aggregatedEndfleetArray};

}

//Calculate Operational type values by Type.
function getOperationalFleet(accordionData, aggregatedOperationalFleetArray,groupByCategory,totalNewValues,operationalStartFleet) {
    let riskDeletesObj = {};
    riskDeletesObj.header = "OPERATIONAL END FLEET";
    riskDeletesObj['title'] = [];
    riskDeletesObj['children'] = [];
    riskDeletesObj['total'] = [];
    riskDeletesObj['title'].push('Requested Adjustment');
    riskDeletesObj['title'].push('Requested Value');
    riskDeletesObj['title'].push('Original Value');
    riskDeletesObj['values'] = [];
    let totalValues = {};
    let zoneMap = {};
    if(groupByCategory==='Zone>Type'){
        //Only for Zone>Type View
        //Returned data from aggregatedActivityForAZone function for calculating OperationalFleet 
        totalValues=totalNewValues
    }else{
    //For Type>Zone View 
    accordionData.map((data) => {
        let header = data.header;

        totalValues[header] = [];
        data['values'].map((values) => {
            totalValues[header] = values;
        })

        data.children.map((child) => {
            if (data.header!='TOTAL END FLEET' && data.header!='RISK DELETES' && data.header!='OPERATIONAL END FLEET') {
                let existingarray = zoneMap[child.header]
                if(existingarray){
                    existingarray.push(JSON.parse(JSON.stringify(child)))
                    zoneMap[child.header] = existingarray;
                }
                else {
                    let zoneArray = [JSON.parse(JSON.stringify(child))];
                    zoneMap[child.header] = zoneArray;
                }
            }
        })
    });
    let zoneData = [];
    
    zones.map((child) => {
        if (child.name!='ALL') {
            let endfleetZone = zoneMap[child.name].reduce((acc, cur) => {
                if (!acc) {
                    acc = JSON.parse(JSON.stringify(cur));
                }
                else {
                    acc.values[0].adjustments.forEach((element, index, curarray) => {
                        calculateOperationalEndFleet(curarray[index], cur.values[0].adjustments[index],index);
                    });
                    acc.values[0].currentValues.forEach((element, index, curarray) => {
                        calculateOperationalEndFleet(curarray[index], cur.values[0].currentValues[index],index);
                    });
                    acc.values[0].originalValues.forEach((element, index, curarray) => {
                        calculateOperationalEndFleet(curarray[index], cur.values[0].originalValues[index],index);

                    });
                }
                return acc;
            });
            zoneData.push(endfleetZone);
        }
    });
    riskDeletesObj['children'] = zoneData;
}
    var calculatedValuesObj = [];
    let riskDeletesCurrentValues = calculateOperatonalFleetValues(totalValues, calculatedValuesObj, "currentValues", accordionData,groupByCategory,operationalStartFleet)
    let riskDeletesAdjustmentValues = calculateOperatonalFleetValues(totalValues, calculatedValuesObj, "adjustments", accordionData,groupByCategory)
    let riskDeletesOriginalValues = calculateOperatonalFleetValues(totalValues, calculatedValuesObj, "originalValues", accordionData,groupByCategory,operationalStartFleet)
    
    riskDeletesObj['values'].push({
        "currentValues": riskDeletesCurrentValues, "adjustments": riskDeletesAdjustmentValues,
        "originalValues": riskDeletesOriginalValues
    });
    accordionData.push(riskDeletesObj);
    aggregatedOperationalFleetArray.push(riskDeletesObj)
    return accordionData;
}

//Calculate End fleet individual values by Type.
function aggregatedActivityForAType(accordionData, aggregatedEndfleetArray, tabSelected, freezeCpd) {
    let endFleetObj = {};
    if(tabSelected == 0 ){
        endFleetObj.header = "TOTAL END FLEET";
    }
    else {
        endFleetObj.header = "END FLEET";
    }
    endFleetObj['title'] = [];
    endFleetObj['children'] = [];
    endFleetObj['total'] = [];
    endFleetObj['title'].push('Requested Adjustment');
    endFleetObj['title'].push('Requested Value');
    endFleetObj['title'].push('Original Value');
    endFleetObj['values'] = [];
    let totalValues = {},totalstartfleetindex;
    let zoneMap = {};
    accordionData.map((data,index) => {
        let header = data.header;
        if(header==='START_CS_FLEET'){
            totalstartfleetindex=index
        }
        totalValues[header] = [];
        data['values'].map((values) => {
            totalValues[header] = values;
        })

        data.children.map((child) => {
            if (calculateZoneEndFleetForBU(data.header, tabSelected)) {
                let existingarray = zoneMap[child.header]
                if(existingarray){
                    existingarray.push(JSON.parse(JSON.stringify(child)))
                    zoneMap[child.header] = existingarray;
                }
                else {
                    let zoneArray = [JSON.parse(JSON.stringify(child))];
                    zoneMap[child.header] = zoneArray;
                }
            }
        })
    });
    let zoneData = [];
    zones.map((child) => {
        if (child.name != 'ALL') {
            let endfleetZone = zoneMap[child.name].reduce((acc, cur) => {
                if (!acc) {
                    acc = JSON.parse(JSON.stringify(cur));
                }
                else {
                    acc.values[0].adjustments.forEach((element, index, curarray) => {
                        calculateZoneEndFleet(curarray[index], cur.values[0].adjustments[index], tabSelected);
                    });
                    acc.values[0].currentValues.forEach((element, index, curarray) => {
                        calculateZoneEndFleet(curarray[index], cur.values[0].currentValues[index], tabSelected);
                    });
                    acc.values[0].originalValues.forEach((element, index, curarray) => {
                        calculateZoneEndFleet(curarray[index], cur.values[0].originalValues[index], tabSelected);

                    });
                }
                return acc;
            });
            zoneData.push(endfleetZone);
        }
    });
    endFleetObj['children'] = zoneData;
    var calculatedValuesObj = [];
    let endFleetCurrentValues = calculateEndFleetValues(totalValues, tabSelected, calculatedValuesObj, "currentValues", accordionData, freezeCpd,totalstartfleetindex)
    let endFleetAdjustmentValues = calculateEndFleetValues(totalValues, tabSelected, calculatedValuesObj, "adjustments", accordionData, freezeCpd,totalstartfleetindex)
    let endFleetOriginalValues = calculateEndFleetValues(totalValues, tabSelected, calculatedValuesObj, "originalValues", accordionData, freezeCpd,totalstartfleetindex)
    endFleetObj['values'].push({
        "currentValues": endFleetCurrentValues, 
        "adjustments": endFleetAdjustmentValues,
        "originalValues": endFleetOriginalValues
    });
    accordionData.push(endFleetObj);
    aggregatedEndfleetArray.push(endFleetObj)
    return accordionData;
}

function calculateZoneEndFleetForBU(header, tabSelected) {
    switch (tabSelected) {
        case 0: //ALL
            return (header == 'ADDS' || header == 'REPURCHASE_DELETES' || header == 'SALES' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET' || header=='START_CS_FLEET')
        case 1: //core
            return (header == 'ADDS' || header == 'REPURCHASE_DELETES' || header == 'CORE_TO_TNC' || header == 'CORE_TO_CS' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
        case 2://core ice
            return (header == 'ADDS' || header == 'REPURCHASE_DELETES' || header == 'CORE_TO_TNC' || header == 'CORE_TO_CS' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
        case 3: //core elec
            return (header == 'ADDS' || header == 'REPURCHASE_DELETES' || header == 'CORE_TO_TNC' || header == 'CORE_TO_CS' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
        case 4://tnc
            return (header == 'ADDS' || header == 'TNC_TO_CS' || header == 'CORE_TO_TNC' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
        case 5: //tnc ice
            return (header == 'ADDS' || header == 'TNC_TO_CS' || header == 'CORE_TO_TNC' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
        case 6: //tnc elec
            return (header == 'ADDS' || header == 'TNC_TO_CS' || header == 'CORE_TO_TNC' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
        case 7: //cargo
            return (header == 'ADDS' || header == 'CARGO_TO_CS' || header == 'SALVAGE' || header == 'NET_ONEWAY' || header == 'NET_MOVES' || header == 'START_FLEET')
    }
}

function calculateZoneEndFleet(aggregateObj, currentVal, tabSelected) { 
 aggregateObj.value=aggregateObj.value+((BuActivityMap[tabSelected][currentVal.activity_type])*currentVal.value);
}

function calculateOperationalEndFleet(aggregateObj, currentVal,index) { 
    if(currentVal.activity_type!=='SALES'){
            aggregateObj.value=aggregateObj.value+((OperationalEndFleetActivity[0][currentVal.activity_type])*currentVal.value);       
    }
   }

function getZonesForBU(header){
    return (header == 'CORE_TO_CS' || header == 'TNC_TO_CS' || header == 'CARGO_TO_CS')
}

function calculateRiskDeletesZoneValues(aggregateObj, currentVal){
    aggregateObj.value=(aggregateObj.value+currentVal.value);

}

//This function is used for Zone>type view for calculating Aggregated activity values for particular zone 
//And also this function is used to calculate the total Aggregated activity for endFleet(Children object)
function calculateZoneActivityAggregated(dat,matchActivityType){
    let d=dat.reduce((acc,curr)=>{
          var flag=false
          acc.forEach((item)=>{
              if((matchActivityType===true?item.activity_type:item.activity_type==curr.activity_type) 
                && item.month==curr.month
                  && item.year==curr.year){
                  item.value=item.value+curr.value
                  item.adjustment=item.adjustment+curr.adjustment
                  flag=true
              }
          })
          if(!flag){
              acc.push({...curr,'zone_code':'ALL'})
          }
          return acc
      },[])
    return d
  }

//End fleet calculation by Zone.
function aggregatedActivityForAZone(accordionData, aggregatedEndfleetArray, tabSelected, freezeCpd) {
    let endFleetObj = {};
    if(tabSelected == 0 ){
        endFleetObj.header = "TOTAL END FLEET";
    }
    else {
        endFleetObj.header = "ALL";
    }
    endFleetObj['title'] = [];
    endFleetObj['children'] = [];
    endFleetObj['total'] = [];
    endFleetObj['title'].push('Requested Adjustment');
    endFleetObj['title'].push('Requested Value');
    endFleetObj['title'].push('Original Value');
    endFleetObj['values'] = [];
    let fleetObj = {};
    let operationalstartfleet=0;
    fleetObj['currentValues'] = [];
    fleetObj['adjustments'] = [];
    fleetObj['originalValues'] = [];
    let totalValues = {};
    let data = [], currentdata=[],adjustmentdata=[],originaldata=[],val={},pass=0
    let a_types 

    if(tabSelected===0){
         a_types=[...AllTabActivitiy,'TOTAL END FLEET']
    } else {
        a_types=Object.getOwnPropertyNames(BuActivityMap[tabSelected])
    }
 
    let copyOfData,copyOfCurrentData,copyOfAdjustmentData,copyOfOriginalData

    a_types.map(activitydata=>{
    data = [];currentdata=[];adjustmentdata=[];originaldata=[];
    accordionData.map((dat) => {

    if(pass<accordionData.length){
        dat.values.map((values) => {
            fleetObj['currentValues'] = calculateEndFleetValuesByZone(values.currentValues, fleetObj['currentValues'],freezeCpd);
            fleetObj['adjustments'] = calculateEndFleetValuesByZone(values.adjustments, fleetObj['adjustments'],freezeCpd);
            fleetObj['originalValues'] = calculateEndFleetValuesByZone(values.originalValues, fleetObj['originalValues'],freezeCpd);
        }); 
        pass=pass+1
        
    }

    //To calculate aggregated activity values and display them under EndFleet or ALL section in ZOne>Type view  
    dat.children.map(val=>{
        if(val.header===activitydata){
        copyOfData = JSON.parse(JSON.stringify(val['content'].slice(0)));
        data.push(...copyOfData)
        copyOfCurrentData=JSON.parse(JSON.stringify(val['values'][0]['currentValues'].slice(0)));
        currentdata.push(...copyOfCurrentData)
        copyOfAdjustmentData=JSON.parse(JSON.stringify(val['values'][0]['adjustments'].slice(0)));
        adjustmentdata.push(...copyOfAdjustmentData)
        copyOfOriginalData=JSON.parse(JSON.stringify(val['values'][0]['originalValues'].slice(0)));
        originaldata.push(...copyOfOriginalData)
        } 
    }) 
  
});

val={
    'content':data,
    'header':activitydata,
    'title':['Requested Adjustment', 'Requested Value','Original Value'],
    'values':[{
        "currentValues":calculateZoneActivityAggregated(currentdata,false) ,
        "adjustments":calculateZoneActivityAggregated(adjustmentdata,false) ,
        "originalValues":calculateZoneActivityAggregated(originaldata,false)
    }]
 }
//Pushing all the aggregated activity values to children of endfleet array 
if(activitydata==='TOTAL END FLEET'){
    endFleetObj['children'].splice(0,0,val);
} else {
endFleetObj['children'].push(val);
}
//Gathering data for Calculatinf OPERATIONAL END FLEET in Zone>Type view  and returning startfleet and totalValues to getOperationalFleet function 
// if(tabSelected===0){
// totalValues[activitydata]={ 
//         "currentValues":val['values'][0]['currentValues'],
//         "adjustments":val['values'][0]['adjustments']  ,
//         "originalValues":val['values'][0]['originalValues']
//        }
// if(val.header==='START_FLEET'){
//     operationalstartfleet=val['values'][0]['currentValues'][0].value
// }

// }
}) 
    endFleetObj['values'].push({
        "currentValues": fleetObj['currentValues'],
        "adjustments": fleetObj['adjustments'],
        "originalValues": fleetObj['originalValues']
    })
    accordionData.push(endFleetObj);
    aggregatedEndfleetArray.push(endFleetObj)

    return {accordionData,totalValues,operationalstartfleet};
 }

//Calculate End fleet individual values by Zone.//edit todo
function calculateEndFleetValuesByZone(values, fleetObj,freezeCpd) {
    values.map((fleet, fleetIndex) => {
        var findIndex = fleetObj.findIndex(item => item.index == fleet.index)
        if (findIndex <= -1) {
            fleetObj.push({
                "index": fleetIndex, "year": fleet.year, "cpd": fleet.cpd, "month": fleet.month, "num_plan_months": fleet.num_plan_months,
                "value":fleet.value, "activity_type": fleet.activity_type, "zone_code": fleet.zone_code, "engine_group": fleet.engine_group,
                "isFreezMonth":checkIfFreezMonth(fleet.year,fleet.month,freezeCpd)
            });
        } else {
            fleetObj[findIndex] = {
                "index": fleetIndex, "year": fleet.year, "cpd": fleet.cpd, "month": fleet.month, "num_plan_months": fleet.num_plan_months,
                "value": fleetObj[findIndex].value+fleet.value, "activity_type": fleet.activity_type, "zone_code": fleet.zone_code, "engine_group": fleet.engine_group,
                "isFreezMonth":checkIfFreezMonth(fleet.year,fleet.month,freezeCpd)
            }
        }
    })
    return fleetObj;
}

//Calculate aggregation  total for type groupby
function calculateOverallTotalByType(overallObjType, monthIndex, content, isZoneCodeAll) {
    if (overallObjType.findIndex(item => item.index == monthIndex) > -1) {
        overallObjType[monthIndex] = {
            "index": monthIndex, "year": content.year, "cpd": content.cpd, "month": content.month, "num_plan_months": content.num_plan_months,
            "value": (isZoneCodeAll ? content.value : overallObjType[monthIndex].value + content.value),
            "activity_type": content.activity_type, "zone_code": content.zone_code,"bu":content.bu,"pool":content.pool, "engine_group": content.engine_group
        }
    } else {
        overallObjType.push({
            "index": monthIndex, "year": content.year, "cpd": content.cpd,
            "month": content.month, "num_plan_months": content.num_plan_months, "value": content.value,
            "activity_type": content.activity_type, "zone_code": content.zone_code,"bu":content.bu,"pool":content.pool, "engine_group": content.engine_group
        })
    }
    return overallObjType;
}


function calculateOverallTotalByZone(overallObjType, zoneIndex, content, currentType, tabSelected,calculationFor,startCsFleetValue) {
    //according to BU and activity type
    if (overallObjType.findIndex(item => item.index == zoneIndex) > -1) {
        let a,startvalue;
        if(currentType && currentType!='START_FLEET' && ((calculationFor==='OperationalEndFleet')?(currentType!='START_CS_FLEET' && currentType!='SALES'):(currentType!='START_CS_FLEET'))){
            a= (calculationFor==='OperationalEndFleet') ?
                            OperationalEndFleetActivity[tabSelected][currentType]
                            : 
                            BuActivityMap[tabSelected][currentType];
         a=a!=undefined?a:0
        overallObjType[zoneIndex] = {
            "index": zoneIndex, "year": content.year, "cpd": content.cpd, "month": content.month, "num_plan_months": content.num_plan_months,
            "value": (!isNaN(overallObjType[zoneIndex].value)?overallObjType[zoneIndex].value + (a*content.value):(a*content.value)), //TODO: don't do any rounding in reducer. Only round the values when showing data in the UI - round them to nearest integer
            "activity_type": content.activity_type, "zone_code": content.zone_code,"bu":content.bu,"pool":content.pool, "engine_group": content.engine_group
        }
     } else if(currentType=='START_FLEET'){
        a= (calculationFor==='OperationalEndFleet') ?
                            OperationalEndFleetActivity[tabSelected][currentType]
                            : 
                            BuActivityMap[tabSelected][currentType];       
        let startvalue=(zoneIndex===0)?content.value:overallObjType[zoneIndex-1].value
        overallObjType[zoneIndex] = {
            "index": zoneIndex, "year": content.year, "cpd": content.cpd, "month": content.month, "num_plan_months": content.num_plan_months,
            "value": (!isNaN(overallObjType[zoneIndex].value)?overallObjType[zoneIndex].value + (a*startvalue):0), //TODO: don't do any rounding in reducer. Only round the values when showing data in the UI - round them to nearest integer
            "activity_type": content.activity_type, "zone_code": content.zone_code,"bu":content.bu,"pool":content.pool, "engine_group": content.engine_group
        }
     }else if(tabSelected==0 && calculationFor==='TotalEndFleet' && currentType=='START_CS_FLEET'){
        a=BuActivityMap[tabSelected][currentType];       
        let startvalue=(zoneIndex===0)?content.value:startCsFleetValue
        overallObjType[zoneIndex] = {
            "index": zoneIndex, "year": content.year, "cpd": content.cpd, "month": content.month, "num_plan_months": content.num_plan_months,
            "value": (!isNaN(overallObjType[zoneIndex].value)?overallObjType[zoneIndex].value + (a*startvalue):0), //TODO: don't do any rounding in reducer. Only round the values when showing data in the UI - round them to nearest integer
            "activity_type": content.activity_type, "zone_code": content.zone_code,"bu":content.bu,"pool":content.pool, "engine_group": content.engine_group
        }
     }
    } else {
        overallObjType.push({
            "index": zoneIndex, "year": content.year, "cpd": content.cpd,
            "month": content.month, "num_plan_months": content.num_plan_months, "value":content.value,
            "activity_type": content.activity_type, "zone_code": content.zone_code,"bu":content.bu,"pool":content.pool, "engine_group": content.engine_group
        })
    }
    return overallObjType;
}

function moveItem(data, from, to) {
    // remove `from` item and store it
    var moveData = [...data];
    var f = moveData.splice(from, 1)[0];
    // insert stored item into position `to`
    moveData.splice(to, 0, f);
    return moveData;
}

function calculateRiskDeletes(addsIndex, valueEndFleetObj, valueType) {
    let calculatedValue = 0;
    if(valueType == "currentValues") {
        calculatedValue = (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.currentValues[addsIndex].value : 0) +
        (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.currentValues[addsIndex].value : 0) +
        (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.currentValues[addsIndex].value : 0);
    }
    else if (valueType == "adjustments") {
        calculatedValue = (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.adjustments[addsIndex].value : 0) +
        (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.adjustments[addsIndex].value : 0) +
        (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.adjustments[addsIndex].value : 0);
    }
    else if (valueType == "originalValues") {
        calculatedValue = (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.originalValues[addsIndex].value : 0) +
        (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.originalValues[addsIndex].value : 0) +
        (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.originalValues[addsIndex].value : 0);
    }
    return calculatedValue;
}

function calculateOperationalFleet(addsIndex, valueEndFleetObj, valueType) {
    let calculatedValue = 0;
    if(valueType == "currentValues") {
        calculatedValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
        (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
        (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0) -
        (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.currentValues[addsIndex].value : 0) -
        (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) - 
        ((valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.currentValues[addsIndex].value : 0) +
        (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.currentValues[addsIndex].value : 0) +
        (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.currentValues[addsIndex].value : 0));
    }
    else if (valueType == "adjustments") {
        calculatedValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
        (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0)+
        (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
        (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.adjustments[addsIndex].value : 0) -
        (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) - 
        ((valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.adjustments[addsIndex].value : 0) +
        (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.adjustments[addsIndex].value : 0) +
        (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.adjustments[addsIndex].value : 0));
    }
    else if (valueType == "originalValues") {
        calculatedValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
        (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0)+
        (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
        (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.originalValues[addsIndex].value : 0) -
        (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) - 
        ((valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.originalValues[addsIndex].value : 0) +
        (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.originalValues[addsIndex].value : 0) +
        (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.originalValues[addsIndex].value : 0));
    }
    return calculatedValue;
}
//sales=100 
//csinv=riskdel-sales
// totfleet=opfleet+(csinv)+startcsfleet
function calculateCurrentValues(adds, addsIndex, tabSelected, valueEndFleetObj) {
    let calculatedCurrentValue = 0;
    if (tabSelected == 0) {
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALES ? valueEndFleetObj.SALES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0);
    } else if (tabSelected == 1) {
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0)  -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.currentValues[addsIndex].value : 0);
    } else if (tabSelected == 2) {
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.currentValues[addsIndex].value : 0);
    }else if (tabSelected == 3) {
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.currentValues[addsIndex].value : 0);
    }
    else if (tabSelected == 7) {
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0)  -
            (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0)
    } else if(tabSelected == 4){
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0)  -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.currentValues[addsIndex].value : 0)
    } else if(tabSelected == 5){
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0)  -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.currentValues[addsIndex].value : 0);
    }else if(tabSelected == 6){
        calculatedCurrentValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.currentValues[addsIndex].value : 0)  -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.currentValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.currentValues[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.currentValues[addsIndex].value : 0);
    }
    else {
        //TODO: calculate adjustments for other BU types
    } 
    return calculatedCurrentValue;
}

function calculateOriginalValues(adds, addsIndex, tabSelected, valueEndFleetObj) {
    //TODO: Original values calc logic need to be changed based on start run plan
    let calculatedOriginalValue = 0;
    if (tabSelected == 0) {
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALES ? valueEndFleetObj.SALES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0);
    } else if (tabSelected == 1) {
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0)+
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.originalValues[addsIndex].value : 0);
    } else if (tabSelected == 2) {
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.originalValues[addsIndex].value : 0);
    }else if (tabSelected == 3) {
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.originalValues[addsIndex].value : 0);
    }else if (tabSelected == 7) {
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0)+
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0)
    } else if(tabSelected == 4){
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0)+
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.originalValues[addsIndex].value : 0)
    } else if(tabSelected == 5){
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0)+
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.originalValues[addsIndex].value : 0);
    } else if(tabSelected == 6){
        calculatedOriginalValue = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.originalValues[addsIndex].value : 0)+
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.originalValues[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.originalValues[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.originalValues[addsIndex].value : 0);
    }else {
        //TODO: calculate adjustments for other BU types
    } 
    return calculatedOriginalValue;
}

function calculateAdjustments(addsIndex, tabSelected, valueEndFleetObj) {
    let calculatedAdjustments = 0;
    if (tabSelected == 0) {
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALES ? valueEndFleetObj.SALES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0);
    } else if (tabSelected == 1) {
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.adjustments[addsIndex].value : 0);
    } else if (tabSelected == 2) {
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.adjustments[addsIndex].value : 0);
    }  else if (tabSelected == 3) {
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_CS ? valueEndFleetObj.CORE_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.REPURCHASE_DELETES ? valueEndFleetObj.REPURCHASE_DELETES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.adjustments[addsIndex].value : 0);
    }else if (tabSelected == 7) {
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.CARGO_TO_CS ? valueEndFleetObj.CARGO_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0)
    } else if(tabSelected == 4){
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.adjustments[addsIndex].value : 0)
    } else if(tabSelected == 5){
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.adjustments[addsIndex].value : 0);
    } else if(tabSelected == 6){
        calculatedAdjustments = (valueEndFleetObj.ADDS ? valueEndFleetObj.ADDS.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_ONEWAY ? valueEndFleetObj.NET_ONEWAY.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.NET_MOVES ? valueEndFleetObj.NET_MOVES.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.TNC_TO_CS ? valueEndFleetObj.TNC_TO_CS.adjustments[addsIndex].value : 0) -
            (valueEndFleetObj.SALVAGE ? valueEndFleetObj.SALVAGE.adjustments[addsIndex].value : 0) +
            (valueEndFleetObj.CORE_TO_TNC ? valueEndFleetObj.CORE_TO_TNC.adjustments[addsIndex].value : 0);
    }else {
        //TODO: calculate adjustments for other BU types
    }
    return calculatedAdjustments;
}
//edit todo
function calculateEndFleetValues(valueEndFleetObj, tabSelected, endFleetCalc, valueType, accordionData, freezeCpd,totalstartfleetindex) {
    let mapObj = [];
    if(accordionData && accordionData.length>0 &&valueEndFleetObj ) {
    if (valueType == "currentValues") {
        mapObj = accordionData[0].values[0].currentValues;
    } if (valueType == "adjustments") {
        mapObj = accordionData[0].values[0].adjustments;
    } if (valueType == "originalValues") {
        mapObj = accordionData[0].values[0].originalValues;
    }
 }
 let totalCsStartFleet=0 //To add startcsfleet given for the first month and for other minths(it would be previous months endfleet value)
    endFleetCalc[valueType] = []
    if (valueEndFleetObj && accordionData && accordionData.length>0 && mapObj && mapObj.length > 0) {
        if (valueType == "currentValues") {
            mapObj.map((adds, addsIndex) => {
                if(tabSelected==0 && addsIndex==0 && totalstartfleetindex){
                totalCsStartFleet=accordionData[totalstartfleetindex]['values'][0]['currentValues'][0]['value'];
                } else {totalCsStartFleet=0}
                let currentValuesCal = calculateCurrentValues(adds, addsIndex, tabSelected, valueEndFleetObj);
                endFleetCalc[valueType].push({
                    "index": adds.index, "year": adds.year,
                    "cpd": adds.cpd,
                    "pool": adds.pool,
                    "month": adds.month,
                    "num_plan_months": adds.num_plan_months,
                    "value": currentValuesCal+totalCsStartFleet,
                    "activity_type": adds.activity_type, "zone_code": adds.zone_code,"engine_group": adds.engine_group,
                    "isFreezMonth":checkIfFreezMonth(adds.year,adds.month,freezeCpd)
                });
            })
        }

            if (valueType == "adjustments") {
                
                mapObj.map((adds, addsIndex) => {
                    let adjusmentsCal = calculateAdjustments(addsIndex, tabSelected, valueEndFleetObj);
                    endFleetCalc[valueType].push({
                        "index": adds.index, "year": adds.year,
                        "cpd": adds.cpd,
                        "pool": adds.pool,
                        "month": adds.month,
                        "num_plan_months": adds.num_plan_months,
                        "value": adjusmentsCal,
                        "activity_type": adds.activity_type, "zone_code": adds.zone_code,"engine_group": adds.engine_group

                    });
                })
            }

        if (valueType == "originalValues") {
            mapObj.map((adds, addsIndex) => {
                if(tabSelected==0 && addsIndex==0 && totalstartfleetindex){
                    totalCsStartFleet=accordionData[totalstartfleetindex]['values'][0]['originalValues'][0]['value'];
                    } else {totalCsStartFleet=0}
                let originalValuesCal = calculateOriginalValues(adds, addsIndex, tabSelected, valueEndFleetObj);
                endFleetCalc[valueType].push({
                    "index": adds.index, "year": adds.year,
                    "cpd": adds.cpd,
                    "pool": adds.pool,
                    "month": adds.month,
                    "num_plan_months": adds.num_plan_months,
                    "value": originalValuesCal+totalCsStartFleet,
                    "activity_type": adds.activity_type, "zone_code": adds.zone_code,"engine_group": adds.engine_group
                });
            })
        }
    }
        return endFleetCalc[valueType];
    }

function calculateRiskDeletesValues(valueEndFleetObj, endFleetCalc, valueType, accordionData) {
    let mapObj = [];
    if (accordionData && accordionData.length > 0 && valueEndFleetObj) {
        if (valueType == "currentValues") {
            mapObj = accordionData[0].values[0].currentValues;
        } if (valueType == "adjustments") {
            mapObj = accordionData[0].values[0].adjustments;
        } if (valueType == "originalValues") {
            mapObj = accordionData[0].values[0].originalValues;
        }
    }
    endFleetCalc[valueType] = []
    if (valueEndFleetObj && accordionData && accordionData.length > 0 && mapObj && mapObj.length > 0) {
        mapObj.map((adds, addsIndex) => {

            let currentValuesCal = calculateRiskDeletes(addsIndex, valueEndFleetObj, valueType);
            endFleetCalc[valueType].push({
                "index": adds.index, "year": adds.year,
                "cpd": adds.cpd,
                "pool": adds.pool,
                "month": adds.month,
                "num_plan_months": adds.num_plan_months,
                "value": currentValuesCal,
                "activity_type": adds.activity_type, "zone_code": adds.zone_code, "engine_group": adds.engine_group
            });
        })
    }
    return endFleetCalc[valueType];
}

function calculateOperatonalFleetValues(valueEndFleetObj, endFleetCalc, valueType, accordionData,groupByCategory,operationalStartFleet) {
    let mapObj = [];
    if (accordionData && accordionData.length > 0 && valueEndFleetObj) {
        if (valueType == "currentValues") {
            mapObj = accordionData[0].values[0].currentValues;
        } if (valueType == "adjustments") {
            mapObj = accordionData[0].values[0].adjustments;
        } if (valueType == "originalValues") {
            mapObj = accordionData[0].values[0].originalValues;
        }
    }
    endFleetCalc[valueType] = []
    if (valueEndFleetObj && accordionData && accordionData.length > 0 && mapObj && mapObj.length > 0) {
        mapObj.map((adds, addsIndex) => {
            let opstart=(groupByCategory==='Zone>Type' && (valueType == "currentValues"||valueType == "originalValues"))?((addsIndex===0) ? operationalStartFleet : endFleetCalc['currentValues'][addsIndex-1].value):0
            let adjnextcell=(groupByCategory==='Zone>Type' && valueType == "adjustments")?((addsIndex===0) ?0 : endFleetCalc['adjustments'][addsIndex-1].value):0
            let currentValuesCal = calculateOperationalFleet(addsIndex, valueEndFleetObj, valueType);
            endFleetCalc[valueType].push({
                "index": adds.index, "year": adds.year,
                "cpd": adds.cpd,
                "pool": adds.pool,
                "month": adds.month,
                "num_plan_months": adds.num_plan_months,
                "value": currentValuesCal+ ((valueType == "currentValues"||valueType == "originalValues")?opstart:adjnextcell),
                "activity_type": adds.activity_type, "zone_code": adds.zone_code, "engine_group": adds.engine_group
            });
        })
    }
    return endFleetCalc[valueType];
}

function setAdjLevel(isZoneCodeAll, adjValue) {
    if (isZoneCodeAll) {
        adjlevel = ADJLEVEL.BU;
    } else if (adjValue > 0) {
        adjlevel = ADJLEVEL.ZONE;
    }

}

function fetchDropDownValues(activityTypes, element) {
    if (element.colDef === "activity_type") {
        activityTypes.map((val) => {
            let obj = {};
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    } else if (element.colDef === "zones") {
        zones.map((val) => {
          if(val.name!=='ALL'){
            let obj = {};
            obj['label'] = val.name;
            obj['value'] = val.name;
            element.options.push(obj);
            }
        });
    } 

    return element;
}

function fetchFilterValues(filterData,data){
    var filterData = filterColumnDefs().data;
    filterData.forEach(element => {
        if (element.type == "dropdown")
            fetchDropDownValues(data, element);
    });
    // console.log("Prepared filter data ===",filterData);
    return filterData;
}

function clearExpandAllFlag(state,tabIndex,viewType,isFiltered){
   

    //Choose dataset based on viewType
    let expandAllNewArray;
    if(viewType==="Type>Zone"){
        expandAllNewArray=customCloneObject(state.expandAll_type_zone);
    }else{
        expandAllNewArray=customCloneObject(state.expandAll_zone_type);
    }

    //Clear expand all filter
    if(isFiltered ){
        expandAllNewArray[tabIndex]= {value: 0,dateTime: new Date()};
    }else{
        expandAllNewArray[tabIndex]= {value: 1,dateTime: new Date()};
    }

    if(viewType==="Type>Zone"){
        return {expandAll_type_zone:[...expandAllNewArray]};        
    }else{
        return {expandAll_zone_type:[...expandAllNewArray]}; 
    }
}

function handleExpandAllToggle(state,action){
    let tabIndex=action.data.tabIndex;
    let viewType=action.data.viewType;

    //Choose dataset based on viewType
    let expandAllNewArray;
    if(viewType==="Type>Zone"){
        expandAllNewArray=customCloneObject(state.expandAll_type_zone);
    }else{
        expandAllNewArray=customCloneObject(state.expandAll_zone_type);
    }

    //Clear filtered data
    let filterToNullyfy=getClonedFilterCatObject();
    switch(parseInt(tabIndex,10)){
        case 0: filterToNullyfy=(viewType==="Type>Zone"? {tabAllfilterParams:{zone_type:customCloneObject(state.tabAllfilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{tabAllfilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.tabAllfilterParams.type_zone)}});break;
        case 1: filterToNullyfy=(viewType==="Type>Zone"? {coreBuFilterParams:{zone_type:customCloneObject(state.coreBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{coreBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.coreBuFilterParams.type_zone)}});break;
        case 2: filterToNullyfy=(viewType==="Type>Zone"? {coreIceBuFilterParams:{zone_type:customCloneObject(state.coreIceBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{coreIceBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.coreIceBuFilterParams.type_zone)}});break;
        case 3: filterToNullyfy=(viewType==="Type>Zone"? {coreElecBuFilterParams:{zone_type:customCloneObject(state.coreElecBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{coreElecBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.coreElecBuFilterParams.type_zone)}});break;
        case 4: filterToNullyfy=(viewType==="Type>Zone"? {tncBuFilterParams:{zone_type:customCloneObject(state.tncBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{tncBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.tncBuFilterParams.type_zone)}});break;
        case 5: filterToNullyfy=(viewType==="Type>Zone"? {tncIceBuFilterParams:{zone_type:customCloneObject(state.tncIceBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{tncIceBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.tncIceBuFilterParams.type_zone)}});break;
        case 6: filterToNullyfy=(viewType==="Type>Zone"? {tncElecBuFilterParams:{zone_type:customCloneObject(state.tncElecBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{tncElecBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.tncElecBuFilterParams.type_zone)}});break;
        case 7: filterToNullyfy=(viewType==="Type>Zone"? {cargoBuFilterParams:{zone_type:customCloneObject(state.cargoBuFilterParams.zone_type),type_zone:getClonedFilterCatObject()}}:{cargoBuFilterParams:{zone_type:getClonedFilterCatObject(),type_zone:customCloneObject(state.cargoBuFilterParams.type_zone)}});break;
        default: filterToNullyfy=getClonedFilterCatObject();
    }

    if(viewType==="Type>Zone"){
        if(action.data.expandAll===false){
            // console.log("Updating Expand All flag to=",{tabIndex:tabIndex,viewType:viewType})
            expandAllNewArray[tabIndex]= {value: 0,dateTime: new Date()};
            return Object.assign({}, state, {
                expandAll_type_zone: expandAllNewArray,
                ...filterToNullyfy
            });
        }else{
            if(expandAllNewArray[tabIndex].value===1){//Collapsed to Expand activities
                expandAllNewArray[tabIndex]= {value: 2,dateTime: new Date()};
                return Object.assign({}, state, {
                    expandAll_type_zone: expandAllNewArray,
                    ...filterToNullyfy
                });
            }else{
                expandAllNewArray[tabIndex]= {value: 1,dateTime: new Date()};
                return Object.assign({}, state, {
                    expandAll_type_zone: expandAllNewArray,
                    ...filterToNullyfy
                });
            }
        }
    }else{//For "Zone>Type"
        if(action.data.expandAll===false){
            expandAllNewArray[tabIndex]= {value: 0,dateTime: new Date()};
            return Object.assign({}, state, {
                expandAll_zone_type: expandAllNewArray,
                ...filterToNullyfy
            });
        }else{
            if(expandAllNewArray[tabIndex].value===1){//Collapsed to Expand activities
                expandAllNewArray[tabIndex]= {value: 2,dateTime: new Date()};
                return Object.assign({}, state, {
                    expandAll_zone_type: expandAllNewArray,
                    ...filterToNullyfy
                });
            }else{
                expandAllNewArray[tabIndex]= {value: 1,dateTime: new Date()};
                return Object.assign({}, state, {
                    expandAll_zone_type: expandAllNewArray,
                    ...filterToNullyfy
                });
            }
        }
    }
}

function handleFilterParams(state,action){
    let dateTimeOfFilterAction=new Date();
    let viewType=action.data.viewType;
    switch(action.type){
        case SET_TABALL_FILTER_PARAMS:
            //console.log("Executing SET_TABALL_FILTER_PARAMS with action.data.filterParams=",action.data.filterParams);
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    tabAllfilterParams:{
                        zone_type:{...state.tabAllfilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,0,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,0,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    tabAllfilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.tabAllfilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,0,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,0,viewType)
                });
            }
        case SET_CORETAB_FILTER_PARAMS:
            // console.log("Executing SET_CORETAB_FILTER_PARAMS with action.data.filterParams=",action.data.filterParams);
            // return Object.assign({}, state,{
            //     coreBuFilterParams:action.data,
            //     ...clearExpandAllFlag(state,1,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    coreBuFilterParams:{
                        zone_type:{...state.coreBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,1,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,1,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    coreBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.coreBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,1,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,1,viewType)
                });
            }
        case SET_COREICETAB_FILTER_PARAMS:
            // console.log("Executing SET_COREICETAB_FILTER_PARAMS");
            // return Object.assign({}, state,{
            // coreIceBuFilterParams:action.data,
            // ...clearExpandAllFlag(state,2,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    coreIceBuFilterParams:{
                        zone_type:{...state.coreIceBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,2,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,2,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    coreIceBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.coreIceBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,2,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,2,viewType)
                });
            }
        case SET_COREELECTAB_FILTER_PARAMS:
            // console.log("Executing SET_COREELECTAB_FILTER_PARAMS");
            // return Object.assign({}, state,{
            // coreElecBuFilterParams:action.data,
            // ...clearExpandAllFlag(state,3,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    coreElecBuFilterParams:{
                        zone_type:{...state.coreElecBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,3,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,3,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    coreElecBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.coreElecBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,3,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,3,viewType)
                });
            }
        case SET_TNCTAB_FILTER_PARAMS:
            // console.log("Executing SET_TNCTAB_FILTER_PARAMS");
            // return Object.assign({}, state,{
            // tncBuFilterParams:action.data,
            // ...clearExpandAllFlag(state,4,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    tncBuFilterParams:{
                        zone_type:{...state.tncBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,4,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,4,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    tncBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.tncBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,4,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,4,viewType)
                });
            }
        case SET_TNCICETAB_FILTER_PARAMS:
            // console.log("Executing SET_TNCICETAB_FILTER_PARAMS");
            // return Object.assign({}, state,{
            // tncIceBuFilterParams:action.data,
            // ...clearExpandAllFlag(state,5,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    tncIceBuFilterParams:{
                        zone_type:{...state.tncIceBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,5,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,5,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    tncIceBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.tncIceBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,5,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,5,viewType)
                });
            }
        case SET_TNCELECTAB_FILTER_PARAMS:
            // console.log("Executing SET_TNCELECTAB_FILTER_PARAMS");
            // return Object.assign({}, state,{
            // tncElecBuFilterParams:action.data,
            // ...clearExpandAllFlag(state,6,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    tncElecBuFilterParams:{
                        zone_type:{...state.tncElecBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,6,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,6,viewType)
                    
                });
            }else{
                return Object.assign({}, state,{
                    tncElecBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.tncElecBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,6,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,6,viewType)
                    
                });
            }
        case SET_CARGOCTAB_FILTER_PARAMS:
            // console.log("Executing SET_CARGOCTAB_FILTER_PARAMS");
            // return Object.assign({}, state,{
            // cargoBuFilterParams:action.data,
            // ...clearExpandAllFlag(state,7,action.data)
            // });
            if(action.data.viewType==="Type>Zone"){
                return Object.assign({}, state,{
                    cargoBuFilterParams:{
                        zone_type:{...state.cargoBuFilterParams.zone_type},
                        type_zone:{...action.data.filterParams,dateTime:dateTimeOfFilterAction}
                    },
                    ...clearExpandAllFlag(state,7,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,7,viewType)
                });
            }else{
                return Object.assign({}, state,{
                    cargoBuFilterParams:{
                        zone_type:{...action.data.filterParams,dateTime:dateTimeOfFilterAction},
                        type_zone:{...state.cargoBuFilterParams.type_zone}
                    },
                    ...clearExpandAllFlag(state,7,action.data.viewType,action.data.filterParams.isFiltered),
                    ...clearAccordianClick(state,7,viewType)
                });
            }
    }
}

function handleGroupViewType(state,action){
    // console.log("In handleGroupViewType ",action.data.tabIndex, action.data.viewType);
    let newSelectedViewTypeArray=[...state.selectedViewType];
    newSelectedViewTypeArray[action.data.tabIndex]=action.data.viewType;
    
    return Object.assign({}, state, {
        selectedViewType: [...newSelectedViewTypeArray]
    });
}

function handleAccordianClick(state,action){
    // console.log("In handleAccordianClick",action.data);
    let newAccordianClicks=customCloneObject(state.accordianClicks);

    let hdrString=action.data.header+(action.data.parentHeader?"-"+action.data.parentHeader:'');

    if(action.data.viewType===TYPE_ZONE_STR) {
        if(newAccordianClicks.type_zone[action.data.tabIndex].headers.filter((hdr)=>hdr===hdrString).length===0){
            newAccordianClicks.type_zone[action.data.tabIndex].headers.push(hdrString);
        }else{
            newAccordianClicks.type_zone[action.data.tabIndex].headers=
            newAccordianClicks.type_zone[action.data.tabIndex].headers.filter((hdr)=>hdr!==hdrString)
        }
        newAccordianClicks.type_zone[action.data.tabIndex].dateTime=new Date();
    }else{
        if(newAccordianClicks.zone_type[action.data.tabIndex].headers.filter((hdr)=>hdr===hdrString).length===0){
            newAccordianClicks.zone_type[action.data.tabIndex].headers.push(hdrString);
        }else{
            newAccordianClicks.zone_type[action.data.tabIndex].headers=
            newAccordianClicks.zone_type[action.data.tabIndex].headers.filter((hdr)=>hdr!==hdrString)
        }
        newAccordianClicks.zone_type[action.data.tabIndex].dateTime=new Date();
    }

    // console.log("newAccordianClicks==========",newAccordianClicks);
    return Object.assign({}, state, {
        accordianClicks: {...newAccordianClicks}
    });
}

function clearAccordianClick(state,tabIndex,viewType){
    // console.log("In clearAccordianClick",tabIndex,viewType);
    let newAccordianClicks={...state.accordianClicks};

    if(viewType===TYPE_ZONE_STR) {
        newAccordianClicks.type_zone[tabIndex].headers=[];
    }else{
        newAccordianClicks.zone_type[tabIndex].headers=[];
    }
    
    return {
        accordianClicks: {...newAccordianClicks}
    };
}

export default function adjustmentsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GROUP_BY_TYPE_ZONE:
            var enrichedData = enrichWithMonthNames(state.planOptimizationOutputs.map(o => { return { ...o }; }));
            var groupedData = fetchGroupedData(enrichedData, action.data.groupByCategory, action.data.tabSelected, state.freezeCpd);
            let groupByCategory=action.data.groupByCategory;
            switch (action.data.tabSelected) {
                case 0: //ALL BU
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedAddsArray),
                        aggregatedDeletesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedDeletesArray),
                        aggregatedRiskDeletesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedRiskDeletesArray),
                        aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedEndfleetArray,groupedData.aggregatedStartfleetArray?groupedData.aggregatedStartfleetArray:state.aggregatedStartfleetArray),
                        aggregatedOperationalFleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedOperationalFleetArray,groupedData.aggregatedStartfleetArray?groupedData.aggregatedStartfleetArray:state.aggregatedStartfleetArray),
                        aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedStartfleetArray?groupedData.aggregatedStartfleetArray:state.aggregatedStartfleetArray),
                        aggregatedTotalStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedTotalStartfleetArray),
                        aggregatedSalesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedSalesArray),
                        aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedSalvageArray),
                        aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedNetonewayArray),
                        aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedNetmovesArray),
                        aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.aggregatedZoneArray)
                    });
                case 1: //CORE BU
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        corebu_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedAddsArray),
                        corebu_aggregatedDeletesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedDeletesArray),
                        corebu_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedEndfleetArray,groupedData.corebu_aggregatedStartfleetArray),
                        corebu_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedStartfleetArray),
                        corebu_aggregatedCoretoTncArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedCoretoTncArray),
                        corebu_aggregatedCoretoCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedCoretoCsArray),
                        corebu_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedSalvageArray),
                        corebu_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedNetonewayArray),
                        corebu_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedNetmovesArray),
                        corebu_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.corebu_aggregatedZoneArray)
                    });
                case 2: //CORE BU AND engine_group ICE
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        coreice_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedAddsArray),
                        coreice_aggregatedDeletesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedDeletesArray),
                        coreice_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedEndfleetArray,groupedData.coreice_aggregatedStartfleetArray),
                        coreice_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedStartfleetArray),
                        coreice_aggregatedCoretoTncArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedCoretoTncArray),
                        coreice_aggregatedCoretoCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedCoretoCsArray),
                        coreice_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedSalvageArray),
                        coreice_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedNetonewayArray),
                        coreice_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedNetmovesArray),
                        coreice_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreice_aggregatedZoneArray)

                    });
                case 3: //CORE BU AND engine_group ELECTRIC
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        coreelectric_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedAddsArray),
                        coreelectric_aggregatedDeletesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedDeletesArray),
                        coreelectric_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedEndfleetArray,groupedData.coreelectric_aggregatedStartfleetArray),
                        coreelectric_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedStartfleetArray),
                        coreelectric_aggregatedCoretoTncArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedCoretoTncArray),
                        coreelectric_aggregatedCoretoCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedCoretoCsArray),
                        coreelectric_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedSalvageArray),
                        coreelectric_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedNetonewayArray),
                        coreelectric_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedNetmovesArray),
                        coreelectric_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.coreelectric_aggregatedZoneArray)

                    });
                case 4: //TNC BU
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        tncbu_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedAddsArray),
                        tncbu_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedSalvageArray),
                        tncbu_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedEndfleetArray,groupedData.tncbu_aggregatedStartfleetArray),
                        tncbu_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedStartfleetArray),
                        tncbu_aggregatedCoretoTncArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedCoretoTncArray),
                        tncbu_aggregatedTncToCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedTncToCsArray),
                        tncbu_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedNetonewayArray),
                        tncbu_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedNetmovesArray),
                        tncbu_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncbu_aggregatedZoneArray)
                    });
                case 5: //TNC BU and engine_group ice
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        tncice_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedAddsArray),
                        tncice_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedSalvageArray),
                        tncice_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedEndfleetArray,groupedData.tncice_aggregatedStartfleetArray),
                        tncice_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedStartfleetArray),
                        tncice_aggregatedCoretoTncArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedCoretoTncArray),
                        tncice_aggregatedTncToCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedTncToCsArray),
                        tncice_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedNetonewayArray),
                        tncice_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedNetmovesArray),
                        tncice_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncice_aggregatedZoneArray)
                    });
                case 6: //TNC BU and engine_group electric
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        tncelectric_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedAddsArray),
                        tncelectric_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedSalvageArray),
                        tncelectric_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedEndfleetArray,groupedData.tncelectric_aggregatedStartfleetArray),
                        tncelectric_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedStartfleetArray),
                        tncelectric_aggregatedCoretoTncArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedCoretoTncArray),
                        tncelectric_aggregatedTncToCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedTncToCsArray),
                        tncelectric_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedNetonewayArray),
                        tncelectric_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedNetmovesArray),
                        tncelectric_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.tncelectric_aggregatedZoneArray)
                    })
                case 7: //CARGO BU
                    return Object.assign({}, state, {
                        pending: false,
                        adjlevel: adjlevel,
                        cargobu_aggregatedAddsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedAddsArray),
                        cargobu_aggregatedSalvageArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedSalvageArray),
                        cargobu_aggregatedEndfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedEndfleetArray,groupedData.cargobu_aggregatedStartfleetArray),
                        cargobu_aggregatedStartfleetArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedStartfleetArray),
                        cargobu_aggregatedCargoToCsArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedCargoToCsArray),
                        cargobu_aggregatedNetonewayArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedNetonewayArray),
                        cargobu_aggregatedNetmovesArray: enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedNetmovesArray),
                        cargobu_aggregatedZoneArray:enrichAggregatedArrayWithFinalValAndAdjVar(groupByCategory,groupedData.cargobu_aggregatedZoneArray)
                    });

            }
        case FETCH_PLAN_OPTIMIZATION_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_PLAN_OPTIMIZATION_DATA_SUCCESS:
            var planOutputData = enrichPlanOutputsWithZoneAndPoolValues(action.data.planOptimizationData.planOptimizationOutputs,
                action.data.planOptimizationData.zones, action.data.planOptimizationData.pools);
            zones = action.data.planOptimizationData.zones;
            // state.freezeCpd = action.data.planOptimizationData.parameters;
           
            return Object.assign({}, state, {
                pending: false,
                planOptimizationOutputs: planOutputData,
                zones: action.data.planOptimizationData.zones.filter(v => v.name != 'ALL'),
                coreBuFilterParams:initialState.coreBuFilterParams,
                coreIceBuFilterParams:initialState.coreIceBuFilterParams,
                coreElecBuFilterParams:initialState.coreElecBuFilterParams,
                tncBuFilterParams:initialState.tncBuFilterParams,
                tncIceBuFilterParams:initialState.tncIceBuFilterParams,
                tncElecBuFilterParams:initialState.tncElecBuFilterParams,
                cargoBuFilterParams:initialState.cargoBuFilterParams,
                expandAll_type_zone:initialState.expandAll_type_zone,
                expandAll_zone_type:initialState.expandAll_zone_type,
                selectedViewType:initialState.selectedViewType,
                accordianClicks:initialState.accordianClicks,
                freezeCpd:action.data.parameters
            });
        case FETCH_PLAN_OPTIMIZATION_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case SET_DATA_WITH_ADJUSTEMENT_VALUE:
            return Object.assign({},state, {
                dataWithAdjustmentValue:action.data
            });
        case FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_SUCCESS:
            var planOutputData = enrichPlanOutputsWithZoneAndPoolValues(action.data.planOptimizationOutputs,
                action.data.zones, action.data.pools);
            zones = action.data.zones;
            return Object.assign({}, state, {
                pending: false,
                planOptimizationOutputs: planOutputData,
                zones: action.data.zones.filter(v => v.name != 'ALL')
            });
        case FETCH_PLAN_OPTIMIZATION_DATA_ONUPDATE_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error
            });
        case FETCH_PROCESS_ADJUSTMENTS_RUN_PENDING:
             return Object.assign({}, state, {
                 pending: true,
             });
         case FETCH_PROCESS_ADJUSTMENTS_RUN_SUCCESS:
             return Object.assign({}, state, {
                 pending: false,
                 isSaveClicked:false
             });
         case FETCH_PROCESS_ADJUSTMENTS_RUN_ERROR:
             return Object.assign({}, state, {
                 pending: false,
                 error: action.error
             });
        case ADJUSTMENTS_ROW_ACTION:
            return Object.assign({}, state, {
                editing: action.action == "edit_clicked" ? true : false,
                editableArrayObject:action.data,
                isSaveClicked:action.action=='save_clicked'?true:false
            });
        case ADJUSTMENTS_SET_LEVEL:
            adjlevel = action.data
            return Object.assign({}, state, {
                adjlevel: action.data
            });
        case FETCH_ADJUSTMENTS_FILTER_DATA:
            var actionData = fetchFilterValues(filterColumnDefs,action.data);
            // console.log("IN FETCH_ADJUSTMENTS_FILTER_DATA actionData==",actionData);
            return Object.assign({}, state, {
                adjustmentsFilterData: actionData,
            });
        case SET_ADJUSTMENTS_EXPANDALL_FLAGS:
            // console.log("Executing SET_ADJUSTMENTS_EXPANDALL_FLAGS");
            return handleExpandAllToggle(state,action);
        case SET_TABALL_FILTER_PARAMS:
            // console.log("Executing SET_TABALL_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_CORETAB_FILTER_PARAMS:
            // console.log("Executing SET_CORETAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_COREICETAB_FILTER_PARAMS:
            // console.log("Executing SET_COREICETAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_COREELECTAB_FILTER_PARAMS:
            // console.log("Executing SET_COREELECTAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_TNCTAB_FILTER_PARAMS:
            // console.log("Executing SET_TNCTAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_TNCICETAB_FILTER_PARAMS:
            // console.log("Executing SET_TNCICETAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_TNCELECTAB_FILTER_PARAMS:
            // console.log("Executing SET_TNCELECTAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_CARGOCTAB_FILTER_PARAMS:
            // console.log("Executing SET_CARGOCTAB_FILTER_PARAMS");
            return handleFilterParams(state,action);
        case SET_BU_GROUP_VIEW_TYPE:
            return handleGroupViewType(state,action);
        case SET_ACCORDIAN_CLICK:
            return handleAccordianClick(state,action);
        default:
            return state;
    }
}



