import React, { useState, useEffect } from 'react';
import AccordionView from '../../components/accordionview/AccordionView';
import { useDispatch, useSelector } from "react-redux";
import icon_sortdown from "../../assets/images/icon-arrow-sortdown.svg";
import icon_default_sortdown from "../../assets/images/icon-arrow-sort-default.svg";
import { Pool } from '@material-ui/icons';
import './UpgradepathAccordionContainer.css';
import { groupByPaths } from '../../store/upgradepaths/UpgradePaths.Actions';

function UpgradepathAccordionContainer(props) {
    const { data, currentView, selectedval, buttonSelected, tabIndex } = props;
    const [sortAscending, setSortAscending] = useState(true);
    const dispatch = useDispatch();
    let sortformat = 'Zone Temp' + ' / ' + 'From Class' + ' / ' + 'To Class';

    if (currentView == 'accordion') {
        window.parent.document.body.style.overflow = "auto";
    }

    var groupedUpgradePathsData = useSelector((state) => {
        return state.rootUpgradePathsReducer.upgradePathReducer.upgradePathGroupedData;
    });

    const loadgroupByUpgradePaths = (sortData) => {
        dispatch(groupByPaths(sortData));
    }

    const sortPathAccordion = (sortkey) => {
        let sortData = { sortkey, sortAscending }
        loadgroupByUpgradePaths(sortData);
        setSortAscending(!sortAscending);
    }

    const sortPathAccordionColumns = (sortkey) => {
        let sortData = { sortkey, sortAscending }
        loadgroupByUpgradePaths(sortData);
        setSortAscending(!sortAscending);
    }

    useEffect(() => {
        if (buttonSelected != '' && data.length > 0 && data !== undefined) {
            loadgroupByUpgradePaths();
        }
    }, [data, buttonSelected])

    return (
        <div className={currentView=="grid" ? "": "upgradepathsortFixed"}>
            <div className="upgradepathsort">
                <span className='pathgroupsort' onClick={(e) => sortPathAccordion('upgrade_zone_template_id')}>
                    <label className="pathsorttext">{sortformat}</label><img src={icon_sortdown} className="pathsorticon"></img>
                </span>
                <span className='costmargin' onClick={(e) => sortPathAccordionColumns('cost')}><label className="pathsorttext">{"Cost"}</label><img src={icon_default_sortdown} className="pathsorticon"></img></span>
                <span className='ultimatemargin' onClick={(e) => sortPathAccordionColumns('ultimate_choice')}><label className="pathsorttext">{"Ultimate Choice %"}</label><img src={icon_default_sortdown} className="pathsorticon"></img></span>
                <span className='pathattributemargin'><label className="pathsorttext pathattheader">{"Attributes"}</label></span>
            </div>
            {data != undefined && data.length > 0 ?
                <AccordionView accordionData={data.length > 0 ? groupedUpgradePathsData : data} tabIndex={tabIndex}>
                </AccordionView> :
                <div className="norecords">No Rows To Show</div>}
        </div>
    )

}

export default UpgradepathAccordionContainer;