import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AdjustmentsActionBar from '../../../components/adjustments/actionbar/AdjustmentsActionBar';
import AdjustmentsAccordionContainer from '../../accordionview/AdjustmentsAccordionContainer';

const AllTabPanel = (props) => {
    const { accordionData, tabIndex, planId, onViewChanged, allZones, planStatus, filterData, filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue} = props;
    let selectedVal = useSelector((state) => state.rootAdjustmentsReducer.adjustmentsReducer.selectedViewType[tabIndex]);

    return (
        <div>
            <AdjustmentsActionBar onViewChanged={onViewChanged} tabIndex={tabIndex} selectedVal={selectedVal} filterData={filterData} planStatus = {planStatus}/>
            <AdjustmentsAccordionContainer accordionData={accordionData} tabIndex={tabIndex} planStatus = {planStatus}
            planId={planId} selectedVal={selectedVal} allZones={allZones} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue}
            filterData={filterData}/>
        </div>
    )

}

export default AllTabPanel;