import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import diaglogstyles from './DialogComponent.css';
import icon_close from "../../assets/images/close.svg";
import { TextField } from '@material-ui/core';
import { isWordWithoutSpecialCharacterSpaceAllowed} from '../../utils/utils'

const DialogBox = (props) => {
    const { open, handleClose, handleYes,editClicked,value,rowData } = props;

    const [newValue,setNewValue]=useState('')
    let transactions=[
        {
            'type':editClicked?'edit':'add',
            'data':{'oldName':value,'newName':newValue}
        }
    ]
    function handleClickYes() {
        handleYes(transactions);
    }
    function handleClickNo() {
        setNewValue('');
        handleClose()
    }
    function onChangeHandler(e) {
        setNewValue(e.target.value);
    }

    let isError=isWordWithoutSpecialCharacterSpaceAllowed(newValue);
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="dialogbox"
            >
                <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                    <span>{editClicked?'EDIT CARGROUP':'ADD CARGROUP'}</span>
                    <span><img src={icon_close} onClick={handleClose} className="closeicon" alt="iconclose"></img></span>
                </DialogTitle>
                <DialogContent>
                    {editClicked
                    ?<div style={{'display':'flex','flexDirection':'column','height':'50%'}}>
                   <span style={{'display':'flex'}}>Old Name: <b>{value} </b></span>
                    New Name:
                    <TextField 
                    id="outlined-basic" 
                    variant="outlined" 
                    onChange={(e)=>onChangeHandler(e)} 
                    value={newValue}
                    error={isError}
                    helperText={isError?"Can not have a special character!": ""}/>
                    </div>
                    :    
                    <TextField 
                    id="outlined-basic" 
                    variant="outlined" 
                    onChange={(e)=>onChangeHandler(e)} 
                    value={newValue} 
                    error={isError}
                    helperText={isError ?"Can not have a special character!": ""}
                    />
                }
                </DialogContent>
                <DialogActions className="actionbuttons">
                    <Button className='cancelbutton' autoFocus onClick={handleClickNo} color="primary">
                        Cancel
                    </Button> 
                    <Button className='okbutton' onClick={handleClickYes} color="primary" disabled={newValue==='' || isError?true:false}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default DialogBox;