import { UpgradePathsColumns, cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter, valueFormatter, percentageFormatter, updatedOnDateFormatter} from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customNumericSortComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";
export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: UpgradePathsColumns.ZoneTemp.field ,
        headerName: UpgradePathsColumns.ZoneTemp.headerName, 
        unSortIcon: true,
        minWidth: 124,
        filterType:"dropdown",
        filterOptions:[],
        width:124,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.ZoneTemp.name)
        },
        cellRenderer: "UpgradePathZoneTempRenderer",
        cellEditor: cellEditors.autoCompleteEditor,
        cellClassRules: cellClassRules(UpgradePathsColumns.ZoneTemp.name)
    },
    {
        field: UpgradePathsColumns.FromClass.field,
        cellRenderer: 'FromClassRenderer',
        headerName: UpgradePathsColumns.FromClass.headerName,
        minWidth: 200,
        filterType:"dropdown",
        filterOptions:[],
        unSortIcon: true,
        cellEditor: cellEditors.upgradePathAutocompleteEditor ,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.FromClass.name)
        },
        cellClassRules: cellClassRules(UpgradePathsColumns.FromClass.name)
    },
    {
        field: UpgradePathsColumns.ToClass.field,
        headerName: UpgradePathsColumns.ToClass.headerName,
        filterType:"dropdown",
        filterOptions:[],
        cellRenderer: 'UpgradeToClassRenderer',
        minWidth: 200,
        unSortIcon: true,
        cellEditor: cellEditors.upgradePathAutocompleteEditor,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.ToClass.name)
        },
        cellClassRules: cellClassRules(UpgradePathsColumns.ToClass.name)
    },
    {
        field: UpgradePathsColumns.Cost.field,
        headerName: UpgradePathsColumns.Cost.headerName, unSortIcon: true,
        cellEditor: cellEditors.numericEditor ,
        minWidth: 110,
        filterType:"input",
        left:563,
        type: 'numericColumn',comparator: customNumericSortComparator,
        valueFormatter: params => {
            if (params.data.cost)
                return currencyFormatter(params.data.cost.toString(), "$");
        },
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.Cost.name)
        },
        cellClassRules: cellClassRules(UpgradePathsColumns.Cost.name)
    },
    {
        field: UpgradePathsColumns.UltimateChoice.field,
        headerName: UpgradePathsColumns.UltimateChoice.headerName, unSortIcon: true,
        minWidth: 150,
        left:691,
        filterType:"input",
        cellEditor: cellEditors.numericEditor,
        type: 'numericColumn',comparator: customNumericSortComparator,
        valueFormatter: params => {
            return params.data.ultimate_choice
        },
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.UltimateChoice.name)
        },
        cellClassRules: cellClassRules(UpgradePathsColumns.UltimateChoice.name)
    },
    {
        headerName: UpgradePathsColumns.Attributes.headerName, unSortIcon: true,
        editable: false
    },
    {
        field:  UpgradePathsColumns.UpdatedBy.field, 
        headerName: UpgradePathsColumns.UpdatedBy.headerName, 
        editable: false, 
        unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(UpgradePathsColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.UpdatedBy.name)
        }
    },
    {
        field: UpgradePathsColumns.UpdatedOn.field,
        headerName: UpgradePathsColumns.UpdatedOn.headerName,
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, 
        cellClassRules: cellClassRules(UpgradePathsColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, UpgradePathsColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "pathactions",
        cellRenderer: "UpgradePathActionsRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 50,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field] && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};