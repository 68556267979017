import {getAgeMileageData, uploadAgeMileageCSV, saveAgeMileageTransactions, fetchAgeMileageDropdownService} from '../../services/agemileage/AgeMileageService';
import {FETCH_AGEMILEAGE_DATA_PENDING, FETCH_AGEMILEAGE_DATA_SUCCESS, FETCH_AGEMILEAGE_DATA_ERROR, UPLOAD_AGEMILEAGE_PENDING, UPLOAD_AGEMILEAGE_SUCCESS, UPLOAD_AGEMILEAGE_ERROR,
    SAVE_AGEMILEAGE_TRANSACTION_PENDING, SAVE_AGEMILEAGE_TRANSACTION_SUCCESS, SAVE_AGEMILEAGE_TRANSACTION_ERROR,
    FETCH_AGEMILEAGE_DROPDOWNVALUES_PENDING,FETCH_AGEMILEAGE_DROPDOWNVALUES_SUCCESS,FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR, RESET_TO_AGEMILEAGE_INITIALSTATE} from '../../store/agemileage/AgeMileage.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';

export const fetchAgeMileageData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_AGEMILEAGE_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getAgeMileageData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_AGEMILEAGE_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false)) 
            }else{
                dispatch(serviceActionError(FETCH_AGEMILEAGE_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_AGEMILEAGE_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadAgeMileageData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_AGEMILEAGE_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadAgeMileageCSV(planId, files);
            let ageMileageData = await getAgeMileageData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_AGEMILEAGE_SUCCESS, ageMileageData));
                dispatch(SetAssumptionPending(false))  
             } else{
                dispatch(serviceActionError(UPLOAD_AGEMILEAGE_ERROR, "agemileage upload failed"));
                dispatch(SetAssumptionError("agemileage upload failed"))                
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_AGEMILEAGE_ERROR, "agemileage upload failed"));
            dispatch(SetAssumptionError("agemileage upload failed"))                
        }
    }
}

export const saveAgeMileageTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_AGEMILEAGE_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveAgeMileageTransactions(planid, transactionList);
            let ageMileageData = await getAgeMileageData(planid);
            if (ageMileageData) {
                dispatch(serviceActionSuccess(SAVE_AGEMILEAGE_TRANSACTION_SUCCESS, ageMileageData));
                 dispatch(SetAssumptionPending(false))               
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_AGEMILEAGE_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchAgeMileageDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_AGEMILEAGE_DROPDOWNVALUES_PENDING));
        let ageMileageData = await fetchAgeMileageDropdownService(planid);
        if (ageMileageData) {
            dispatch(serviceActionSuccess(FETCH_AGEMILEAGE_DROPDOWNVALUES_SUCCESS, ageMileageData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("agemileageGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later
                    dispatch(validateGrid("agemileagegrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR, ageMileageData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetAgeMileage = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_AGEMILEAGE_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}