export const FETCH_ZONEMAP_DATA_PENDING = 'FETCH_ZONEMAP_DATA_PENDING';
export const FETCH_ZONEMAP_DATA_SUCCESS = 'FETCH_ZONEMAP_DATA_SUCCESS';
export const FETCH_ZONEMAP_DATA_ERROR = 'FETCH_ZONEMAP_DATA_ERROR';
export const UPLOAD_ZONEMAP_PENDING = 'UPLOAD_ZONEMAP_PENDING';
export const UPLOAD_ZONEMAP_SUCCESS = 'UPLOAD_ZONEMAP_SUCCESS';
export const UPLOAD_ZONEMAP_ERROR = 'UPLOAD_ZONEMAP_ERROR';
export const SAVE_ZONEMAP_TRANSACTION_PENDING = 'SAVE_ZONEMAP_TRANSACTION_PENDING';
export const SAVE_ZONEMAP_TRANSACTION_SUCCESS = 'SAVE_ZONEMAP_TRANSACTION_SUCCESS';
export const SAVE_ZONEMAP_TRANSACTION_ERROR = 'SAVE_ZONEMAP_TRANSACTION_ERROR';
export const FETCH_ZONEMAP_DROPDOWNVALUES_PENDING = 'FETCH_ZONEMAP_DROPDOWNVALUES_PENDING';
export const FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS = 'FETCH_ZONEMAP_DROPDOWNVALUES_SUCCESS';
export const FETCH_ZONEMAP_DROPDOWNVALUES_ERROR = 'FETCH_ZONEMAP_DROPDOWNVALUES_ERROR';
export const RESET_TO_ZONEMAP_INITIALSTATE = 'RESET_TO_ZONEMAP_INITIALSTATE';