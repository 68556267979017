import { columnDefs, defaultColDef } from "./roleManagement_grid_data/RoleManagementColumnDefs";
import { FETCH_PROGRAM_DROPDOWN_PENDING, FETCH_PROGRAM_DROPDOWN_SUCCESS, FETCH_PROGRAM_DROPDOWN_ERROR, SET_PROGRAM_EDITABLE } from "./RoleManagement.ActionTypes";

const initialState = {
    columnDefs,
    defaultColDef,
    validationFn: function (data) {
        return {
            ID: data.id,
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === "PROGRAM_TYPE") {
            column.cellEditorParams = { options: data.programType }
        }
    });
    //console.log("Fetch the column degs " + JSON.stringify(columnDefs))
    return columnDefs;
}

export default function columnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        // case FETCH_PROGRAM_DROPDOWN_PENDING:
        //     return Object.assign({}, state, {
        //         pending: true,
        //     });
        // case FETCH_PROGRAM_DROPDOWN_SUCCESS:
        //     return Object.assign({}, state, {
        //         pending: false,
        //         dropdownValues: setColumnDefParams(state.columnDefs, action.data)
        //     });
        // case FETCH_PROGRAM_DROPDOWN_ERROR:
        //     return Object.assign({}, state, {
        //         pending: false,
        //         dropdownValues: action.error
        //     });
        default:
            return state;
    }
}