import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import './ParametersActionBar.css';
import { Button } from '@material-ui/core';


const ParametersActionBar = (props) => {

  const { options, onAddNewRowClick, onSaveClicked, onCancelClicked,gridColDef, handleSaveCancelDisabled } = props;

  const dispatch = useDispatch();

  return (
    <div className="mainActionBar">
      {/* Plan status is running should disable cancel save changes add +  */}
      {options && gridColDef.defaultColDef.editable?
        <div className="saveButtons">
          <div className="secondaryButtonParameters"><Button onClick={(e) => onCancelClicked(e)}>Cancel
        </Button></div>
          <div className="primaryButtonParameters">
            <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes
          </Button></div>
          <div className="primaryButtonParameters">
            <Button onClick={(e) => onAddNewRowClick(e)}>Add +
        </Button>
          </div>

        </div> :
        (<div className="actionButtons">
          <div className="primaryButtonParameters">
            <Button disabled={ !gridColDef.defaultColDef.editable} onClick={(e) => onAddNewRowClick(e)}>
              Add +
                    </Button></div> : <div></div>
        </div>)}
      <div>
      </div>
    </div>

  )
}

export default ParametersActionBar;