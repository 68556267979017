import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import ActionBar from '../../../components/planassumptionsactionbar/PlanAssumptionsActionbar';
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const AdditionalCostTabPanel = (props)=>{
    const{showSaveOption, additionalCostData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        additionalCostGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        additionalCostTransactions, additionCostgridReducer,currentData,filteredData, filterParams,tabIndex, downloadCSV,userMessage,newArchitecture,gridName,loadGridData} = props;
 return(
     <div>
        {newArchitecture ?(<MainPlanInputsMultiGridUI reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={additionalCostGridColDef}
            rowEditType={'single'}
            suppressclickEdit={!additionalCostGridColDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            loadGridData={loadGridData}
            isPaginationRequired={true}/>)
            :(
            <MultiGridUI 
            reducer={additionCostgridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'additionalcostgrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={additionalCostGridColDef}
            suppressclickEdit={!additionalCostGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            additionalCostTransactions={additionalCostTransactions}
            userMessage={userMessage}/>
        )}
         
     </div>
 )
}

export default AdditionalCostTabPanel;