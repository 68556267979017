import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const UseStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
        marginTop: '4px',
       
    //   margin: theme.spacing(1),
    //   width: "60%",
    },
    "& .MuiInputBase-root": {
      background: "#ffffff",
       minWidth: '167px',
        minHeight: '32px',
    },

  },
}));

export const LoginStlyes = makeStyles((theme) => ({
  // background: {
  //   background: "#000000",
  // },
}));

export const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "blue",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px", // override inline-style
    },
  },
})(TextField);
