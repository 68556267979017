import planOutputsJSON from '../../data/planOutputs.json';
import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention } from '../../utils/utils';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';

export async function fetchPlanOptimizationOutput(planid) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

 
    const promiseZoneResponse = await checkAndLoadDimention("zone", client);
    const promisePoolResponse = await checkAndLoadDimention("pool", client);
    const planOptimizationOutput = await client.apis.adjustments.getOutput({ "planid": planid });
    const responsesArray = await Promise.all([planOptimizationOutput, promisePoolResponse,promiseZoneResponse]);
    const [planAdjustmentOutputResponse, poolResponse,zones] = responsesArray.map(o => o.body.data);
    return ({ "planOptimizationOutputs": planAdjustmentOutputResponse, "zones": zones, "pools": poolResponse });
}

export async function updateAdjustmentsService(planid,transactionList){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const response = await client.apis.adjustments.updateAdjustment({"planid":planid?planid:'master'},{requestBody: {
                    "updatedBy":userEmail,
                    "transactions": transactionList
                }
            });
        return response;
    } 
    catch (error) {
        console.error(error);
        return {'Error':'update adjustments failed'}
    }
}


export async function processAdjustmentsService(planid){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const applyAdjustmentRunServiceResponse = await client.apis.adjustments.applyadjustmentrun({ "planid": planid });
    const responsesArray = await Promise.all([applyAdjustmentRunServiceResponse]);
    let [resp] = responsesArray.map(o => o.body);
    return ({
        "resp": resp.message,
    });
}

export async function processSalesAdjustmentsService(planid){
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const applySalesAdjustmentRunServiceResponse = await client.apis.adjustments.applysalesadjustmentrun({ "planid": planid });
    
    const responsesArray = await Promise.all([applySalesAdjustmentRunServiceResponse]);
    let [resp] = responsesArray.map(o => o.body);
    return ({
        "resp": resp.message,
    });
}

export async function processBvDepOverrideService(planid){
    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const applyBvDepOverrideServiceResponse = await client.apis.adjustments.applybvdepoverriderun({ "planid": planid });

    const responsesArray = await Promise.all([applyBvDepOverrideServiceResponse]);
    let [resp] = responsesArray.map(o => o.body);
    return ({
        "resp": resp.message,
    });
}
