export const FETCH_PROGRAM_DATA_PENDING = 'FETCH_PROGRAM_DATA_PENDING';
export const FETCH_PROGRAM_DATA_SUCCESS = 'FETCH_PROGRAM_DATA_SUCCESS';
export const FETCH_PROGRAM_DATA_ERROR = 'FETCH_PROGRAM_DATA_ERROR';
export const UPDATE_VALUE_SUCCESS = 'UPDATE_VALUE_SUCCESS';
export const FETCH_FILTER_DATA = 'FETCH_FILTER_DATA';
export const FETCH_FILTER_CATEGORIES_PENDING = 'FETCH_FILTER_CATEGORIES_PENDING';
export const FETCH_FILTER_CATEGORIES_SUCCESS = 'FETCH_FILTER_CATEGORIES_SUCCESS';
export const FETCH_FILTER_CATEGORIES_ERROR = 'FETCH_FILTER_CATEGORIES_ERROR';
export const DELETE_PROGRAM_DATA_PENDING = 'DELETE_PROGRAM_DATA_PENDING';
export const DELETE_PROGRAM_DATA_SUCCESS = 'DELETE_PROGRAM_DATA_SUCCESS';
export const DELETE_PROGRAM_DATA_ERROR = 'DELETE_PROGRAM_DATA_ERROR';
export const APPLY_FILTER_PENDING = 'APPLY_FILTER_PENDING';
export const APPLY_FILTER_SUCCESS = 'APPLY_FILTER_SUCCESS';
export const APPLY_FILTER_ERROR = 'APPLY_FILTER_ERROR';
export const FETCH_PROGRAM_DROPDOWN_PENDING = 'FETCH_PROGRAM_DROPDOWN_PENDING';
export const FETCH_PROGRAM_DROPDOWN_SUCCESS = 'FETCH_PROGRAM_DROPDOWN_SUCCESS';
export const FETCH_PROGRAM_DROPDOWN_ERROR = 'FETCH_PROGRAM_DROPDOWN_ERROR';
export const FILTER_SEARCH_PROGRAM_DATA = 'FILTER_SEARCH_PROGRAM_DATA';
export const SAVE_TRANSACTION_PENDING = 'SAVE_TRANSACTION_PENDING';
export const SAVE_TRANSACTION_SUCCESS = 'SAVE_TRANSACTION_SUCCESS';
export const SAVE_TRANSACTION_ERROR = 'SAVE_TRANSACTION_ERROR';
export const UPLOAD_PROGRAMS_PENDING = 'UPLOAD_PROGRAMS_PENDING';
export const UPLOAD_PROGRAMS_SUCCESS = 'UPLOAD_PROGRAMS_SUCCESS';
export const UPLOAD_PROGRAMS_ERROR = 'UPLOAD_PROGRAMS_ERROR';
export const RESET_TO_PROGRSMS_INITIALSTATE = 'RESET_TO_PROGRSMS_INITIALSTATE';
export const SET_PROGRAMS_FILTER_PARAMS = 'SET_PROGRAMS_FILTER_PARAMS';
export const SET_PROGRAM_EDITABLE='SET_PROGRAM_EDITABLE';
