const filterColumnDefs = () => {
    return ({
        "data": [
            {
                "colDef": "activity_type",
                "displayName": "Activity Type",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "zones",
                "displayName": "Zones",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "requestedAdjustment",
                "displayName": "Requested Adjustment",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "variance",
                "displayName": "Adjustment Variance",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "requestedValue",
                "displayName": "Requested Value",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "originalValue",
                "displayName": "Original Value",
                "type": "range",
                "options": [],
            },
        ]
    });
}

export default filterColumnDefs;