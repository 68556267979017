import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import './metaDataActionBar.css';
import DeleteDialogBox from '../../Dialogcomponent/DialogComponent';
import DialogBox from "../../dialog_component/CustomDialog";
import { populateGrid2, validateGrid } from '../../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions';
import { Button, createTheme, makeStyles } from '@material-ui/core';
//import IconActionUpload from "../../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../../assets/images/icon_action_download.svg";
import { safeExcelValue } from '../../../utils/utils';
import { downloadData } from '../../../utils/utils';
import IconFilter from "../../../assets/images/icon_filter.svg";
import IconFilterActive from "../../../assets/images/icon_filter_active.svg";
import { applyCarClassGroupFilter, applyCarClassGroupSearchView, applyDeleteModeOnlyOnCarClassGroup, changeDeleteFilterMode, clearCarClassGroupFilter, clearCarClassGroupSearchView, clearSearchView, closeCarClassGroupFilterPopup, fetchCarClassGroupData, openCarClassGroupFilterPopup, setCarClassGroupFilterParams, setCarClassGroupSearchText } from '../../../store/admin/metadata_carclassgroup/CarClassGroup.Actions';
import DropdownFilter from '../../filter_components/dropdown_filter/DropdownFilter';
import RangeFilter from '../../filter_components/range_filter/RangeFilter';
import SearchBarComponent from '../../searchbar/SearchBarComponent';
import AdminModelMfrOemDialogComponent from '../../Dialogcomponent/AdminModelMfrOemDialogComponent';
import { CARCLASSGROUP, MODELMFROEM, ZONEMAPGRID } from '../../../Constants';
import { applyModelMfrOemFilter, applyModelMfrOemSearchView, changeDeleteFilterModeModelMfrOem, clearModelMfrOemFilter, clearModelMfrOemSearchView, closeModelMfrOemFilterPopup, fetchModelMfrOemData, openModelMfrOemFilterPopup, setModelMfrOemFilterParams, setModelMfrOemSearchText } from '../../../store/admin/metadata_modelmfroem/ModelMfrOem.Actions';
import AdminZoneMapDialogComponent from '../../Dialogcomponent/AdminZoneMapDialogComponent';

export default function MetaDataActionBar(props) {
    const { options, onAddNewRowClick, onSaveClicked, onCancelClicked, selectedPlanId, deleteSelectedRows, bulkRowDeleteIconDisabled,metaDataTabData,metaDataGridColDef,metaDataGrid,metaDataGridColDefReducer,downloadCSV,handleAddNewDropdownValue}=props;
    const dispatch = useDispatch();

    const [filterSearchKey,setiFlterSearchKey] = useState('');
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showProgress,setShowProgress]=useState(false);
    const [insertProgress,setinsertProgress]=useState(null);    
    const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
    const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
    const [tempFilterArray, setTempFilterArray] = useState([]);
    const [selectedDropdown, setSelectedDropdown] = useState("between");

   const popOverOpen = Boolean(anchorEl);
    const id = popOverOpen ? 'simple-popover' : undefined;
    let message = "Are you sure that you want to delete selected rows?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";

    const theme = createTheme();

    const carClassGroupFilterData = useSelector((state) => {
        return {
            filterParameters: state.rootCarClassGroupReducer.CarClassGroupReducer.filterParameters,
            filterView: state.rootCarClassGroupReducer.CarClassGroupReducer.filterView,
            searchView: state.rootCarClassGroupReducer.CarClassGroupReducer.searchView,
            deleteFilter: state.rootCarClassGroupReducer.CarClassGroupReducer.deleteFilter,
            filterPopupOpen: state.rootCarClassGroupReducer.CarClassGroupReducer.filterPopupOpen,
        };
    });

    const modelMfrOemFilterData = useSelector((state) => {
        return {
            filterParameters: {...state.rootModelMfrOemReducer.ModelMfrOemReducer.filterParameters},
            filterView: state.rootModelMfrOemReducer.ModelMfrOemReducer.filterView,
            searchView: state.rootModelMfrOemReducer.ModelMfrOemReducer.searchView,
            deleteFilter: state.rootModelMfrOemReducer.ModelMfrOemReducer.deleteFilter,
            filterPopupOpen: state.rootModelMfrOemReducer.ModelMfrOemReducer.filterPopupOpen,
        };
    });

    const initializeFilter=()=>{
        let filterParams={};
        if (metaDataGridColDef && metaDataGridColDef.columnDefs && metaDataGridColDef.columnDefs.length > 0) {
            metaDataGridColDef.columnDefs.forEach((fields, index) => {
                // console.log("fields==>",fields);
                if(fields.field){
                    filterParams[fields.field]=[];
                }
            });
            // if(metaDataGrid==='carclassgroupgrid'){                
            //     dispatch(setCarClassGroupFilterParams(filterParams));
            // }
        }
        return filterParams;
    }

    const handleFilterClick = (event) => {
        if(metaDataGrid===MODELMFROEM){
            // console.log("modelMfrOemFilterData.filterPopupOpen",modelMfrOemFilterData.filterPopupOpen);
            if(!modelMfrOemFilterData.filterPopupOpen){
                // console.log("Opening filter...");
                dispatch(openModelMfrOemFilterPopup());
            }
        }else
        // console.log("carClassGroupFilterData.filterPopupOpen",carClassGroupFilterData.filterPopupOpen);
        if(!carClassGroupFilterData.filterPopupOpen){
            // console.log("Opening filter...");
            dispatch(openCarClassGroupFilterPopup());
        }


    };

    const handleClose = (e) => {
        if(metaDataGrid===MODELMFROEM){
            dispatch(closeModelMfrOemFilterPopup());
        }else
        dispatch(closeCarClassGroupFilterPopup());
    };

    const clearFilter = (event) => {
        if(metaDataGrid===MODELMFROEM){
            dispatch(clearModelMfrOemFilter(initializeFilter(),modelMfrOemFilterData.deleteFilter));        
            dispatch(fetchModelMfrOemData(initializeFilter(),modelMfrOemFilterData.deleteFilter,false));
        }else
        dispatch(clearCarClassGroupFilter(initializeFilter(),carClassGroupFilterData.deleteFilter));        
    }

    const applyFilter = (event) => {
        if(metaDataGrid===MODELMFROEM){
            if(typeof modelMfrOemFilterData.filterParameters === 'object' && Object.keys(modelMfrOemFilterData.filterParameters).length===0){
                return;
            }
            dispatch(applyModelMfrOemFilter(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter));  
            dispatch(fetchModelMfrOemData(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,false));
        }else{
            if(typeof carClassGroupFilterData.filterParameters === 'object' && Object.keys(carClassGroupFilterData.filterParameters).length===0){
                return;
            }
            dispatch(applyCarClassGroupFilter(carClassGroupFilterData.filterParameters,carClassGroupFilterData.deleteFilter));  
        }
    }

    const handleDropDownValueChange = (event, displayName, colDef) => {
        // console.log("DropDownValueChange event==>",event , displayName, colDef);
        switch(metaDataGrid){
            case MODELMFROEM:
                return handleDropDownValueChangeModelMfrOem(event, displayName, colDef);
            case CARCLASSGROUP:
                return handleDropDownValueChangeCarClassGroup(event, displayName, colDef);
            default:
                console.log(`Grid ${metaDataGrid} is not supported for this operation`);
                return;
        }

    }
    const handleDropDownValueChangeModelMfrOem = (event, displayName, colDef) => {
        // console.log("In handleDropDownValueChangeModelMfrOem...");
        // console.log("coldef==>",colDef);
        // console.log("modelMfrOemFilterData==>",modelMfrOemFilterData);
        if(typeof modelMfrOemFilterData.filterParameters === 'object' && Object.keys(modelMfrOemFilterData.filterParameters).length===0){
            return;
        }
        let filterData = { ...modelMfrOemFilterData.filterParameters };
        // console.log("filterData in handleDropDownValueChange=====: ",filterData);
        let keys = Object.keys(filterData);
        // console.log("keys===>",keys);
        keys.map(key => {
            // console.log("key===>",key);
            if (key === colDef) {
                if (Array.isArray(filterData[key])) {
                    filterData[key] = event;
                } else {
                    if (event[0])
                        filterData[key] = event[0].value;
                    else
                        filterData[key] = event.target.value;
                }
            }
            return filterData;
        });
        dispatch(setModelMfrOemFilterParams(filterData));
    }
    const handleDropDownValueChangeCarClassGroup = (event, displayName, colDef) => {
        // console.log("coldef==>",colDef);
        // console.log("carClassGroupFilterData==>",carClassGroupFilterData);
        if(typeof carClassGroupFilterData.filterParameters === 'object' && Object.keys(carClassGroupFilterData.filterParameters).length===0){
            return;
        }
        let filterData = { ...carClassGroupFilterData.filterParameters };
        // console.log("filterData in handleDropDownValueChange=====: ",filterData);
        let keys = Object.keys(filterData);
        // console.log("keys===>",keys);
        keys.map(key => {
            // console.log("key===>",key);
            if (key === colDef) {
                if (Array.isArray(filterData[key])) {
                    filterData[key] = event;
                } else {
                    if (event[0])
                        filterData[key] = event[0].value;
                    else
                        filterData[key] = event.target.value;
                }
            }
            return filterData;
        });
        dispatch(setCarClassGroupFilterParams(filterData));
    }

    const onRangeDropDownValueChanged = (e, colDef) => {
        switch(metaDataGrid){
            case MODELMFROEM:
                onRangeDropDownValueChangedModelMfrOem(e, colDef);
                return;
            case CARCLASSGROUP:
                onRangeDropDownValueChangedCarClassGroup(e, colDef);
                return;            
            default:
                console.log(`Grid ${metaDataGrid} is not supported for this operation`);
                return;
        }
    }

    const onRangeDropDownValueChangedModelMfrOem = (e, colDef) => {
        if(Object.keys(modelMfrOemFilterData.filterParameters).length===0){
            return;
        }
        let filterData = { ...modelMfrOemFilterData.filterParameters };
        filterData[colDef].push(e.target.value);
        dispatch(setModelMfrOemFilterParams(filterData));
    }
    const onRangeDropDownValueChangedCarClassGroup = (e, colDef) => {
        if(Object.keys(carClassGroupFilterData.filterParameters).length===0){
            return;
        }
        let filterData = { ...carClassGroupFilterData.filterParameters };
        filterData[colDef].push(e.target.value);
        dispatch(setCarClassGroupFilterParams(filterData));
    }

    const onValueRangeChanged = (e, colDef, rangeField) => {
        var range = e.target.value;
        if (rangeField == "from")
            setSelectedFromRangeValue(range);
        else if (rangeField == "to")
            setSelectedToRangeValue(range);
        var filterData = metaDataGrid===CARCLASSGROUP? { ...carClassGroupFilterData.filterParameters }:metaDataGrid===MODELMFROEM?{ ...modelMfrOemFilterData.filterParameters }:{};
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key == colDef) {
                if (filterData[colDef][0] == "between") {
                    if (filterData[key].length === 3) {
                        if (rangeField === "from") {
                            filterData[key][1] = range;
                        }
                        if (rangeField === "to") {
                            filterData[key][2] = range;
                        }
                    } else {
                        if (filterData[key].length == 1 && rangeField == "to") {
                            filterData[key].push("0");
                        }
                        filterData[key].push(range);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(range);
                    else
                        filterData[key][1] = range;
                }
            }
            return filterData;
        });        
        switch(metaDataGrid){
            case MODELMFROEM:
                dispatch(setModelMfrOemFilterParams(filterData));
                return;
            case CARCLASSGROUP:
                dispatch(setCarClassGroupFilterParams(filterData));
                return;            
            default:
                console.log(`Grid ${metaDataGrid} is not supported for this operation`);
                return;
        }
    }

    const getSelectedValue = (colDef) => {
        if (metaDataGrid===MODELMFROEM && modelMfrOemFilterData.filterParameters && modelMfrOemFilterData.filterParameters[colDef]) {
            return modelMfrOemFilterData.filterParameters[colDef];
        }else if (metaDataGrid===CARCLASSGROUP && carClassGroupFilterData.filterParameters && carClassGroupFilterData.filterParameters[colDef]) {
            return carClassGroupFilterData.filterParameters[colDef];
        }else{
            return [];
        }
    }

    const getOptionsDifferently=()=>{
        if(metaDataGrid===MODELMFROEM){
            // console.log("In getOptionsDifferently......",metaDataGrid);
            let oemMfrMap=JSON.parse(sessionStorage.getItem("ADMIN_OEM_MFR_MAP"));
            // console.log("In getOptionsDifferently......oemMfrMap===-->",oemMfrMap);
            if(oemMfrMap){
                let options =  Object.values(oemMfrMap).map(value=>{return {"label":value,"value":value}});
                // console.log("In getOptionsDifferently.....options===>",options);
                return options;
            }            
        }

        return [];
    }

    const fetchDialogContent = () => {
        // console.log("Opening Filter Dialog...");
        // console.log("metaDataGrid==",metaDataGrid);
        // console.log("metaDataGridColDef==",metaDataGridColDef);
        // console.log("metaDataTabData==",metaDataTabData);

        if (metaDataGridColDef && metaDataGridColDef.columnDefs && metaDataGridColDef.columnDefs.length > 0) {
            return (<div className="flexContainer">
                {metaDataGridColDef.columnDefs.map((fields, index) => {
                    // console.log("fields==>",fields);
                    if(fields.field==='deleted' || fields.field==='archived'){
                        return '';
                    }
                    return (<div className="filterMain" key={index}> 
                        {/* {console.log("fields.headerName===>",fields.headerName)} */}
                        {(fields.type === "dropdown") ?
                            (<DropdownFilter displayName={fields.headerName} theme={theme} options={fields && fields.cellEditorParams && fields.cellEditorParams.options?fields.cellEditorParams.options.map(value=>{return {"label":value,"value":value}}):getOptionsDifferently()}
                                selectedValue={getSelectedValue(fields.field)}
                                onValueChanged={(e) => handleDropDownValueChange(e, fields.headerName, fields.field)}>
                            </DropdownFilter>) :
                             (fields.type === "range") ?
                             (<RangeFilter displayName={fields.headerName} theme={theme}  showIsEqual={true}
                                 selectedRangeValue={tempFilterArray[fields.field]}
                                 onFromValueChanged={(e) => onValueRangeChanged(e, fields.field, "from")}
                                 onToValueChanged={(e) => onValueRangeChanged(e, fields.field, "to")}
                                 onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.field)}
                             >
                             </RangeFilter>) :''}
                        </div>);
                }
                )}</div>);
        }
    }

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));
    const handleClickOpen = (props) => {
        if (!bulkRowDeleteIconDisabled) {
            setOpenDeleteDialog(true);
        }
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteSelectedRows = () => {
        setOpenDeleteDialog(false);
    }

    const onSearchValueChanged =(e)=>{
        // console.log("e.target.value==>",e.target.value);
        if(metaDataGrid==='carclassgroupgrid'){
            dispatch(setCarClassGroupSearchText(e.target.value));
            if(e.target.value===''){
                dispatch(clearCarClassGroupSearchView());
            }
            if(carClassGroupFilterData.filterView){
                dispatch(clearCarClassGroupFilter(initializeFilter(),carClassGroupFilterData.deleteFilter));
            }
        }else if(metaDataGrid===MODELMFROEM){
            
            if(e.target.value===''){
                dispatch(clearModelMfrOemSearchView());
                dispatch(fetchModelMfrOemData(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,false));
            }
            if(modelMfrOemFilterData.filterView){
                dispatch(clearModelMfrOemFilter(initializeFilter(),modelMfrOemFilterData.deleteFilter));
            }
            dispatch(setModelMfrOemSearchText(e.target.value));
        }
    }

    const onKeyEnterSearch=(e)=>{
        // console.log("Enter pressed : Executing onKeyEnterSearch... : ",e.target.value);
        if(metaDataGrid==='carclassgroupgrid'){
            if(carClassGroupFilterData.filterParameters.search!==''){
                dispatch(applyCarClassGroupSearchView(carClassGroupFilterData.filterParameters.search,carClassGroupFilterData.deleteFilter));
            }else{
                dispatch(clearCarClassGroupSearchView());
            }
        }else if(e.target.value!=='' && e.target.value!=='$' && metaDataGrid===MODELMFROEM){
            if(modelMfrOemFilterData.filterParameters.search!==''){
                dispatch(applyModelMfrOemSearchView(modelMfrOemFilterData.filterParameters.search,modelMfrOemFilterData.deleteFilter));
                dispatch(fetchModelMfrOemData(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,true));
            }else{
                dispatch(clearModelMfrOemSearchView());
            }
        }
    }

    const onDeleteModeChanged=()=>{
        switch(metaDataGrid){
            case MODELMFROEM:
                onDeleteModeChangedModelMfrOem();
                return;
            case CARCLASSGROUP:
                onDeleteModeChangedCarClassGroup();
                return;            
            default:
                console.log(`Grid ${metaDataGrid} is not supported for this operation`);
                return;
        }
    }
    const onDeleteModeChangedModelMfrOem=()=>{
        if(modelMfrOemFilterData.deleteFilter===0){
            dispatch(changeDeleteFilterModeModelMfrOem(1));
        }else if(modelMfrOemFilterData.deleteFilter===1){
            dispatch(changeDeleteFilterModeModelMfrOem(2));
        }else{
            dispatch(changeDeleteFilterModeModelMfrOem(0));
        }
    }
    const onDeleteModeChangedCarClassGroup=()=>{
        if(carClassGroupFilterData.deleteFilter===0){
            dispatch(changeDeleteFilterMode(1));
        }else if(carClassGroupFilterData.deleteFilter===1){
            dispatch(changeDeleteFilterMode(2));
        }else{
            dispatch(changeDeleteFilterMode(0));
        }
    }

    useEffect(() => {
        // console.log("modelMfrOemFilterData===>",modelMfrOemFilterData);
        if(metaDataGrid===MODELMFROEM && typeof modelMfrOemFilterData.filterParameters === 'object' && Object.keys(modelMfrOemFilterData.filterParameters).length===0){
            // console.log("Initializing filter...");
            dispatch(setModelMfrOemFilterParams(initializeFilter()));
        }
    }, [modelMfrOemFilterData,metaDataGrid]);

    useEffect(()=>{
        if(metaDataGridColDef){
            switch(metaDataGrid){
                case CARCLASSGROUP:
                    if(typeof carClassGroupFilterData.filterParameters === 'object' && Object.keys(carClassGroupFilterData.filterParameters).length===0){
                        // console.log("Initializing filter...");
                        dispatch(setCarClassGroupFilterParams({}));
                    }
                    break;
                case MODELMFROEM:
                    if(typeof modelMfrOemFilterData.filterParameters === 'object' && Object.keys(modelMfrOemFilterData.filterParameters).length===0){
                        // console.log("Initializing filter...");
                        dispatch(setModelMfrOemFilterParams({}));
                    }
                    break;
                default:
                    return;
            }
        }
    },[metaDataGridColDef,metaDataGrid]);

    useEffect(()=>{
        dispatch(clearModelMfrOemSearchView());
        dispatch(clearModelMfrOemFilter(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter));
        dispatch(fetchModelMfrOemData(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,false));
    },[modelMfrOemFilterData.deleteFilter])

    useEffect(() => {
        // console.log("carClassGroupFilterData===>",carClassGroupFilterData);
        if(metaDataGrid===CARCLASSGROUP && typeof carClassGroupFilterData.filterParameters === 'object' && Object.keys(carClassGroupFilterData.filterParameters).length===0){
            // console.log("Initializing filter...");
            dispatch(setCarClassGroupFilterParams(initializeFilter()));
        }
    }, [carClassGroupFilterData,metaDataGrid]);

    

    

    useEffect(() => {
        dispatch(populateGrid2(metaDataGrid, metaDataTabData, metaDataTabData, filterSearchKey, metaDataGridColDef.checkValue,metaDataGridColDef.validationFn, metaDataGridColDef.businessValidationFn));
        dispatch(validateGrid(metaDataGrid, metaDataTabData, metaDataGridColDef.validationFn, metaDataGridColDef.businessValidationFn));
    }, [metaDataTabData])

    function printValue(data){
        return safeExcelValue(data)
      }

    const downloadHandler = async (event) => {
     downloadData(metaDataTabData,metaDataGrid,metaDataGridColDef,downloadCSV,'meta')
    }

    // console.log("carClassGroupFilterData.deleteFilter==>",carClassGroupFilterData.deleteFilter);
    // console.log("modelMfrOemFilterData.deleteFilter==>",modelMfrOemFilterData.deleteFilter);
  let mode=metaDataGrid==='carclassgroupgrid'?carClassGroupFilterData.deleteFilter:metaDataGrid===MODELMFROEM?modelMfrOemFilterData.deleteFilter:false;

  //NOTE:- We have a seperate dialog component as AdminModelMfrOemDialogCOmponent which is used to 
  // add or edit OEM,MANUFACTURER_GROUP & MODEL_GROUP column of ModelMfrOem Admin grid
  //These functions :-  handleOpenModelMfrOpenDialog,handleCloseMdlMfrDialog,handleSaveMdlMfrChanges completely belongs to ModelMfrOem
    const [openModelMfrOemDialog,setOpenModelMfrOemDialog]=useState(false);
    const [ModelMfrOemProps,setModelMfrOemProps]=useState({"colDef":{"field":""},"currentData":[]});

    const handleOpenModelMfrOpenDialog=(e,optionClicked)=>{
        setOpenModelMfrOemDialog(true);
        let tempProps={
            "colDef":{
                "field":(optionClicked==='MfrOem')?"OEM":"MODEL_GROUP"
            },
            "currentData":metaDataTabData
        }
        setModelMfrOemProps(tempProps);
    }

    const handleCloseMdlMfrDialog=()=>{
        setOpenModelMfrOemDialog(false);
    }

    const handleSaveMdlMfrChanges=(transactionsList)=>{
        handleAddNewDropdownValue(transactionsList);
        setOpenModelMfrOemDialog(false);
    }

  //NOTE:- We have a seperate dialog component as AdminZoneMapDialogCOmponent which is used to 
  // add or edit ACCEPTABLE_ZONES column of ZoneMap Admin grid
  //These functions :-  handleOpenZoneMapOpenDialog,handleCloseZoneMapDialog,handleSaveZoneMapChanges completely belongs to ZoneMap
  const [openZoneMapDialog,setOpenZoneMapDialog]=useState(false);
  const [ZoneMapProps,setZoneMapProps]=useState({"colDef":{"field":"ACCEPTABLE_ZONES"},"currentData":[]});

  const handleOpenZoneMapOpenDialog=(e,optionClicked)=>{
      setOpenZoneMapDialog(true);
      let tempProps={
          "colDef":{
              "field":"ACCEPTABLE_ZONES"
          },
          "currentData":metaDataTabData
      }
      setZoneMapProps(tempProps);
  }

  const handleCloseZoneMapDialog=()=>{
      setOpenZoneMapDialog(false);
  }

  const handleSaveZoneMapChanges=(transactionsList)=>{
      console.log("transactionList==>",transactionsList);
      handleAddNewDropdownValue(transactionsList);
      setOpenZoneMapDialog(false);
  }

  return (
    <div className="metadatamainHeader">    
    
    <div className="metadatafilterStyle">    
        {metaDataGrid==='carclassgroupgrid'?
            <img src={carClassGroupFilterData.filterView?IconFilterActive:IconFilter} className="imageIcon" onClick={handleFilterClick} ></img>            
        :''}
        {metaDataGrid===MODELMFROEM?
            <img src={modelMfrOemFilterData.filterView?IconFilterActive:IconFilter} className="imageIcon" onClick={handleFilterClick} ></img>            
        :''}
        {metaDataGrid==='carclassgroupgrid'?
            <div className="metadatasearchbar">
                {/* {console.log("IN ACTION BAR: carClassGroupFilterData.filterParameters.search===>",carClassGroupFilterData.filterParameters.search)} */}
                <SearchBarComponent onSearchValueChanged={onSearchValueChanged} onEnterSearch={true} onKeyEnterSearch={onKeyEnterSearch} filterSearchText={carClassGroupFilterData.filterParameters.search?carClassGroupFilterData.filterParameters.search:''} disabledTextBox={false} showActiveIconOnEnter={carClassGroupFilterData.searchView} controlled={true}/>
            </div>
        :''}
        {metaDataGrid===MODELMFROEM?
            <div className="metadatasearchbar">
                {/* {console.log("IN ACTION BAR: modelMfrOemFilterData.filterParameters.search===>",modelMfrOemFilterData.filterParameters.search)} */}
                <SearchBarComponent onSearchValueChanged={onSearchValueChanged} onEnterSearch={true} onKeyEnterSearch={onKeyEnterSearch} filterSearchText={modelMfrOemFilterData.filterParameters.search?modelMfrOemFilterData.filterParameters.search:''} disabledTextBox={false} showActiveIconOnEnter={modelMfrOemFilterData.searchView} controlled={true}/>
            </div>
        :''}
        {metaDataGrid==='carclassgroupgrid' || metaDataGrid===MODELMFROEM?
            <div className="primaryButton">
                <Button  onClick={() => onDeleteModeChanged()}>{mode===0?'Show Deleted Records':mode===1?'Show All Records':'Show Valid Records'}</Button>
            </div>
        :''}
    </div>  
    {options ?
     <div className="metadataactionButtons">            
            <div className="cancelButton"><Button onClick={(e) => onCancelClicked(e)}>Cancel
            </Button></div>
            <div className="primaryButton">
                <Button  onClick={(e) => onSaveClicked(e)}>Save changes
                 </Button></div>
            <div className="primaryButton">
                <Button  onClick={(e) => onAddNewRowClick(e)} 
                         disabled={carClassGroupFilterData.filterView || carClassGroupFilterData.searchView}
                >Add +
                 </Button>
            </div>
            {/* <div className="primaryButton">
                <Button onClick={() => handleClickOpen()} >
                    Delete
                </Button>
            </div> */}
        </div> :
        (<div className="metadataactionButtons">
            <label>
                <input type="file" accept=".csv" name="file" disabled={insertProgress || showProgress?true:false} style={{ "display": "none" }}/>
                {/* <img src={IconActionUpload} className="imageIcon"></img> */}
            </label>
            <div className='imageIcon'>
                <img src={IconActionDownload} onClick={() => downloadHandler()} />
            </div>
            {metaDataGrid==='modelmfroemgrid'?
                <>
                    <div className="primaryButton">
                        <Button onClick={(e) => handleOpenModelMfrOpenDialog(e,'MfrOem')}>Add Oem & Manufacturer</Button>
                    </div>
                    <div className="primaryButton">
                        <Button onClick={(e) => handleOpenModelMfrOpenDialog(e,'Model')}>Add Model</Button>
                    </div>
                </>
            :metaDataGrid===ZONEMAPGRID?
                <>
                    <div className="primaryButton">
                        <Button onClick={(e) => handleOpenZoneMapOpenDialog(e)}>Add Acceptable Zone</Button>
                    </div>
                </>
            :<></>   
            }
            <div style={{ display: 'flex' }}>
                <div className="primaryButton">
                    <Button  onClick={(e) => onAddNewRowClick(e)}
                             disabled={carClassGroupFilterData.filterView || carClassGroupFilterData.searchView}
                    >
                        Add +
                        </Button></div>
                {/* <div className="primaryButton">
                    <Button onClick={() => handleClickOpen()} >
                        Delete
                         </Button>
                </div> */}
            </div>
            {/* : <div></div>} */}
        </div>)}
    <div>
        <DialogBox classes={{ paper: useStyles().dialogPaper }}
            open={carClassGroupFilterData.filterPopupOpen||modelMfrOemFilterData.filterPopupOpen} handleClose={(e) => handleClose(e)} handlePositiveBtnClick={(e) => applyFilter(e)} handleNegativeBtnClick={(e) => clearFilter(e)}
            dialogContent={fetchDialogContent()} title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter">
        </DialogBox>
        <DeleteDialogBox open={openDeleteDialog} handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
            dialogMessage={message} title={title} action={deletetext}
            canceltext={canceltext}>
        </DeleteDialogBox>
        <AdminModelMfrOemDialogComponent open={openModelMfrOemDialog} handleClose={handleCloseMdlMfrDialog} handleYes={handleSaveMdlMfrChanges} editClicked={false} value={''} rowData={ModelMfrOemProps}/>
        <AdminZoneMapDialogComponent open={openZoneMapDialog} handleClose={handleCloseZoneMapDialog} handleYes={handleSaveZoneMapChanges} editClicked={false} value={''} rowData={ZoneMapProps}/>
    </div>
</div>
  )
}
