import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootProgramsReducer from './programs/rootProgramsReducer';
import rootConstraintsReducer from './constraints/rootConstraintsReducer';
import rootParametersReducer from './parameters/rootParametersReducer';
import rootAllPlansReducer from './topbar/rootAllPlansReducer';
import rootUpgradeTemplatesReducer from './upgradepaths/rootUpgradeTemplatesReducer';
import rootUpgradePathsReducer from './upgradepaths/rootUpgradePathsReducer';
import rootAdjustmentsReducer from './adjustments/rootAdjustmentsReducer';
import rootSchedulesReducer from './schedules/rootSchedulesReducer';
import rootAllocatedReducer from './allocated/rootAllocatedReducer';
import rootPlanAssumptionsReducer from './planassumptions/rootPlanAssumptionsReducer';
import rootMoveConstraintsReducer from './moveconstraints/rootMoveConstraintsReducer';
import rootAddsInvalidPoolsReducer from './addsinvalidpools/rootAddsInvalidPoolsReducer';
import rootAgeMileageReducer from './agemileage/rootAgeMileageReducer';
import rootAdditionalCostReducer from './additionalcost/rootAdditionalCostReducer';
import rootMaxDeletesReducer from './maxdeletes/rootMaxDeletesReducer';
import rootMileageDefaultReducer from './mileagedefault/rootMileageDefaultReducer';
import rootMoveCostReducer from './movecost/rootMoveCostReducer';
import rootDiversionsReducer from './diversions/rootDiversionsReducer';
import rootSalvageRateReducer from'./salvagerate/rootSalvageRateReducer';
import rootTargetZonePoolReducer from './targetzonepool/rootTargetZonePoolReducer';
import rootZoneMapReducer from './zonemap/rootZoneMapReducer';
import DeprateReducer from './deprate/DepRate.Reducer';
import rootAdminUserManagementReducer from './admin/usermanagement/rootAdminUserManagementReducer';
import rootAdminRoleManagementReducer from './admin/rolemanagement/rootAdminRoleManagementReducer';
import rootCarClassGroupReducer from './admin/metadata_carclassgroup/rootCarClassGroupReducer';
import rootModelMfrOemReducer from './admin/metadata_modelmfroem/rootModelMfrOemReducer';
import rootAdminPlanManagementReducer from './admin/planmanagement/rootAdminPlanManagementReducer';
import rootAllRFsReducer from './revenuefunction/allrfs/rootAllRFsReducer';
import rootPoolManagementReducer from './admin/metadata_pool_management/rootPoolManagementReducer';
import rootTnciceReducer from "./revenuefunction/mainrfinputs/tncice/rootTncIceReducer";
import rootTncEvReducer from './revenuefunction/mainrfinputs/tncev/rootTncEvReducer';
import rootTncParameterReducer from './revenuefunction/mainrfinputs/tncparameters/rootTncParameterReducer';
import rootRevenueFunctionReducer from "./revenuefunction/rootRevenueFunctionReducer";
import RFTncRevenuecolumnDefinitionReducer from './revenuefunction/planrevenueinputs/tnc_revenue/TncRevenue.ColumnDef.Reducer'
import multiGridUIReducer from './revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Reducer';
import PaginationGridReducer from './MultiGridPaginationModel/PaginationGrid.Reducer'
import RFTncIcecolumnDefinitionReducer from "./revenuefunction/mainrfinputs/tncice/TncIce.ColumnDef.Reducer";
import RFTncEvcolumnDefinitionReducer from "./revenuefunction/mainrfinputs/tncev/TncEv.ColumnDef.Reducer";
import RFParameterscolumnDefinitionReducer from "./revenuefunction/mainrfinputs/tncparameters/TncParameter.ColumnDef.Reducer";
import rootManualVinDeletesReducer from "./manualvindeletes/rootManualVinDeletesReducer";
import RFCoreDemandcolumnDefinitionReducer from './revenuefunction/mainrfinputs/coredemand/CoreDemand.ColumnDef.Reducer';
import RFCoreAdjColumnDefinitionReducer from "./revenuefunction/mainrfinputs/coreadjustments/CoreAdjustments.ColumnDef.Reducer";
import RFParametercolumnDefinitionReducer from './revenuefunction/mainrfinputs/coreparameters/CoreParameter.ColumnDef.Reducer';
import RFCoreRevenuecolumnDefinitionReducer from './revenuefunction/planrevenueinputs/core_revenue/CoreRevenue.ColumnDef.Reducer';

import ProgramscolumnDefinitionReducer from "./programs/Programs.ColumnDef.Reducer";
import schedulesColumnDefinitionReducer from "./schedules/Schedules.ColumnDef.Reducer";
import allocatedColumnDefinitionReducer from './allocated/Allocated.ColumnDef.Reducer';
import constraintsColumnDefReducer from './constraints/Constraints.ColumnDef.Reducer'
import moveConstraintsColumnDefReducer from './moveconstraints/MoveConstraints.ColumnDef.Reducer'
import upgradePathsColumnDefReducer from './upgradepaths/UpgradePaths.ColumnDef.Reducer';
import upgradetemplateColumnDefReducer from './upgradepaths/UpgradeTemplates.ColumnDef.Reducer';
import ageMileageColumnDefinitionReducer from './agemileage/AgeMileage.ColumnDef.Reducer';   
import additionalCostColumnDefinitionReducer from './additionalcost/AdditionalCost.ColumnDef.Reducer';
import maxDeletesColumnDefinitionReducer from './maxdeletes/MaxDeletes.ColumnDef.Reducer';
import salvageRateColumnDefinitionReducer from './salvagerate/SalvageRate.ColumnDef.Reducer';
import addsInvalidPoolsColumnDefinitionReducer from './addsinvalidpools/AddsInvalidPools.ColumnDef.Reducer';
import mileageDefaultColumnDefinitionReducer from './mileagedefault/MileageDefault.ColumnDef.Reducer';
import depRateColumnDefinitionReducer from './deprate/DepRate.ColumnDef.Reducer';
import targetZonePoolColumnDefinitionReducer from './targetzonepool/TargetZonePool.ColumnDef.Reducer';
import diversionsColumnDefinitionReducer from './diversions/Diversions.ColumnDef.Reducer';
import parametersColumnDefReducer from './parameters/Parameters.ColumnDef.Reducer';
import mileageTableColumnDefinitionReducer from './mileagetable/MileageTable.ColumnDef.Reducer';
import manualvindeletesColumnDefinitionReducer from './manualvindeletes/ManualVinDeletes.ColumnDef.Reducer';
import mileageDefaultGridColDefinitionReducer from './mileagedefault/MileageDefault.ColumnDef.Reducer';
import movecostColumnDefinitionReducer from './movecost/MoveCost.ColumnDef.Reducer';
import inputsMultiGridUIReducer from './MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Reducer';
import rootSalesAndFinanceReducer from './salesandfinance/rootSalesAndFinanceReducer';
import bvDepOverrideColumnDefinitionReducer from './Sales_FinanceMultiGrid/bvdepoverride/BvDepOverride.ColumnDef.Reducer';
import salesfinanceMultiGridReducer from './Sales_FinanceMultiGrid/SalesAndFinanceMultiGrid.Reducer';

const AppReducers = combineReducers({
    rootProgramsReducer,
    rootConstraintsReducer,
    rootParametersReducer,
    rootAllPlansReducer,
    rootUpgradeTemplatesReducer,
    rootUpgradePathsReducer,
    rootAdjustmentsReducer,
    rootSalesAndFinanceReducer,
    rootSchedulesReducer,
    rootAllocatedReducer,
    rootPlanAssumptionsReducer,
    rootAgeMileageReducer,
    rootAdditionalCostReducer,
    rootMaxDeletesReducer,
    rootMileageDefaultReducer,
    rootAddsInvalidPoolsReducer,
    rootMoveConstraintsReducer,
    rootMoveCostReducer,
    rootDiversionsReducer,
    rootSalvageRateReducer,
    rootTargetZonePoolReducer,
    rootZoneMapReducer,
    DeprateReducer,
    rootAdminUserManagementReducer,
    rootAdminRoleManagementReducer,
    rootAdminPlanManagementReducer,
    rootCarClassGroupReducer,
    rootModelMfrOemReducer,
    rootAllRFsReducer,
    rootPoolManagementReducer,
    rootRevenueFunctionReducer,
    // rootTnciceReducer,
    // rootTncEvReducer,
    // rootTncParameterReducer,
    RFTncRevenuecolumnDefinitionReducer,
    RFCoreRevenuecolumnDefinitionReducer,
    RfMultiGridUIReducer:multiGridUIReducer,
    PaginationGridReducer,
    RFTncIcecolumnDefinitionReducer,
    RFTncEvcolumnDefinitionReducer,
    RFParameterscolumnDefinitionReducer,
    RFCoreDemandcolumnDefinitionReducer,
    rootManualVinDeletesReducer,
    RFCoreAdjColumnDefinitionReducer,
    RFParametercolumnDefinitionReducer,

    ProgramscolumnDefinitionReducer,
    schedulesColumnDefinitionReducer,
    allocatedColumnDefinitionReducer,
    constraintsColumnDefReducer,
    moveConstraintsColumnDefReducer,
    upgradePathsColumnDefReducer,
    upgradetemplateColumnDefReducer,
    ageMileageColumnDefinitionReducer,
    additionalCostColumnDefinitionReducer,
    maxDeletesColumnDefinitionReducer,
    salvageRateColumnDefinitionReducer,
    addsInvalidPoolsColumnDefinitionReducer,
    mileageDefaultColumnDefinitionReducer,
    depRateColumnDefinitionReducer,
    targetZonePoolColumnDefinitionReducer,
    diversionsColumnDefinitionReducer,
    parametersColumnDefReducer,
    mileageTableColumnDefinitionReducer,
    manualvindeletesColumnDefinitionReducer,
    mileageDefaultGridColDefinitionReducer,
    movecostColumnDefinitionReducer,
    inputsMultiGridUIReducer,
    bvDepOverrideColumnDefinitionReducer,
    salesfinanceMultiGridReducer
});

const rootReducer = (state, action) => {
    return AppReducers(state, action)
}

const store = createStore(rootReducer, {}, compose(
    applyMiddleware(
        thunk
    ),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
))
export default store;
