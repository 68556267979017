import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { checkAndLoadDimention, cleanFilterParams, minifyTransaction } from '../../../utils/utils';
import { getRoleInfo } from '../../topbar/AllPlansService';
import { ADMIN_ACCESS_REVOKED_ERROR_CODE } from '../../../Constants';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';

export async function getCarClassGroupData(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisecarClassGroupResponse =  client.apis.dimensions.getAllCarclassAndgroup({"isAdmin":true});

    const responsesArray = await Promise.all([promisecarClassGroupResponse]);

    const [carClassGroupResponse] = responsesArray.map(o => o.body.data);

    return ({
        "carClassGroupData": carClassGroupResponse
    });
}

export async function saveCarClassGroupTransactions(transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let rolesDataResponse=await getRoleInfo(userNToken.name, userNToken.email, userNToken.preferred_username)
    if(rolesDataResponse[0].role_name.toLowerCase()==='admin'){
    let promiseArray = [];
    await client.apis.admin.bulkTransactCarClassGroup(null, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
    } else {
        throw new Error(ADMIN_ACCESS_REVOKED_ERROR_CODE);
    }
}

export async function fetchCarClassGroupDropdownService(carClassGroupData){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const carClassGroupResponseData =  client.apis.plans.getAllCarClassGroup({ "planid": planId ? planId : 'master' });
    const promiseGetAllCarsGroup =  client.apis.dimensions.getAllCarsGroup({"isAdmin":true});
    const promiseCarClassAndGroup= carClassGroupData==undefined?client.apis.dimensions.getAllCarclassAndgroup({"isAdmin":true}):checkAndLoadDimention("carclassAndgroup",client);;

    const responsesArray = await Promise.all([
        //  carClassGroupResponseData,
          promiseGetAllCarsGroup,
          promiseCarClassAndGroup
    ]);

    const [
          //carClassGroupResponse,
          carsGroupResponse,
          carClassGroupResponse
    ] = responsesArray.map(o => o.body.data);

    let carClassCodes=[];
    if(carClassGroupData==undefined){
    carClassGroupResponse.map((val)=>{
        carClassCodes.push(val.car_class_code);
    })
    } else {
        carClassGroupData.map((val)=>{
            carClassCodes.push(val.car_class_code);
        })
    }

    return ({
       // "carClassGroupData":carClassGroupResponse,
        "car_group_name": carsGroupResponse,
        "car_class_code": carClassCodes
    })  
}

export async function getCarClassGroupFilteredData(filterParams,deleteMode){

    // console.log("filterParams===>", filterParams);    

    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisecarClassGroupResponse =  client.apis.admin.filterCarClassGroup(null, { requestBody: { "filterParams": cleanFilterParams(filterParams),"deleteMode":deleteMode } });

    const responsesArray = await Promise.all([promisecarClassGroupResponse]);

    const [carClassGroupResponse] = responsesArray.map(o => o.body.data);

    return ({
        "carClassGroupData": carClassGroupResponse
    });
}

export async function getCarClassGroupSearchData(searchText,deleteMode){

    // console.log("searchText===>", searchText);    

    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promisecarClassGroupResponse =  client.apis.admin.searchCarClassGroup(null, { requestBody: { "searchText": searchText,"deleteMode":deleteMode } });

    const responsesArray = await Promise.all([promisecarClassGroupResponse]);

    const [carClassGroupResponse] = responsesArray.map(o => o.body.data);

    return ({
        "carClassGroupData": carClassGroupResponse
    });
}