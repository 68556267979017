
export class AdjustmentNode{
    constructor() {
        this._value = 0.0;
        this._requested_adjustment = 0.0;
        this._requested_value = 0.0;
        this._adjustment_variance = 0.0;
        this._original_value = 0.0;

        this._highlighted=false;
        this._total=false;
        this._isFreezMonth=false;

    }

    copyIntoNew(){
        let newAdjNode=new AdjustmentNode();
        newAdjNode._value                           =  this._value;
        newAdjNode._requested_adjustment            =  this._requested_adjustment;
        newAdjNode._requested_value                 =  this._requested_value;
        newAdjNode._adjustment_variance             =  this._adjustment_variance;
        newAdjNode._original_value                  =  this._original_value;
    
        newAdjNode._highlighted                     =  this._highlighted;
        newAdjNode._total                           =  this._total;
        newAdjNode._isFreezMonth                    =  this._isFreezMonth;
    
        return newAdjNode;
    }

    set value(value) {
        this._value = value;
    }
    get value() {
        return this._value;
    }

    set requestedAdjustment(value) {
        if(value===NaN)console.log("Setting NaN....");
        this._requested_adjustment = value;
    }
    get requestedAdjustment() {
        return this._requested_adjustment;
    }

    set requestedValue(value) {
        this._requested_value = value;
    }
    get requestedValue() {
        return this._requested_value;
    }

    
    set adjustmentVariance(value) {
        this._adjustment_variance = value;
    }
    get adjustmentVariance() {
        return this._adjustment_variance;
    }

    set originalValue(value) {
        this._original_value = value;
    }
    get originalValue() {
        return this._original_value;
    }

    set highlighted(value) {
        this._highlighted = value;
    }
    get highlighted() {
        return this._highlighted;
    }

    set total(value) {
        this._total = value;
    }
    get total() {
        return this._total;
    }
    set isFreezMonth(value){
        this._isFreezMonth=value;
    }
    get isFreezMonth(){
        return this._isFreezMonth;
    }
}



export class ActivityTypeNode{
    constructor(){
        this._activity_type="";
        this._nodes=[];
        this._zones={};
        this._expanded=false;
        this._isEditable=false;
        this._isEditEnabled=false;
        this._isEditDisabled=false;//For Situation Where An Pencil icon is to be shown but faintly
    }
    copyIntoNew(){
        // console.log("Activity Type Clone Method called on this : ", this);
        let newActivityTypeNode=new ActivityTypeNode();
    
        newActivityTypeNode._activity_type=this._activity_type;
        newActivityTypeNode._expanded=this._expanded;
        newActivityTypeNode._isEditable=this._isEditable;
        newActivityTypeNode._isEditEnabled=this._isEditEnabled;
        newActivityTypeNode._isEditDisabled=this._isEditDisabled;
        newActivityTypeNode._nodes=this._nodes.map(node=>node.copyIntoNew());
        Object.keys(this._zones)
              .forEach(aZone=>newActivityTypeNode._zones[aZone]=this._zones[aZone].copyIntoNew());
    
        return newActivityTypeNode;
    }

    set activityType(value){
        this._activity_type=value;
    }

    get activityType(){
        return this._activity_type;
    }

    set nodes(value){
        this._nodes=value;
    }

    get nodes(){
        return this._nodes;
    }

    set zones(value){
        this._zones=value;
    }

    get zones(){
        return this._zones;
    }

    set expanded(value) {
        this._expanded = value;
    }
    get expanded() {
        return this._expanded;
    }

    
    set isEditable(value){
        this._isEditable=value;
    }
    get isEditable(){
        return this._isEditable;
    }
    set isEditEnabled(value){
        this._isEditEnabled=value;
    }
    get isEditEnabled(){
        return this._isEditEnabled;
    }
    set isEditDisabled(value){
        this._isEditDisabled=value;
    }
    get isEditDisabled(){
        return this._isEditDisabled;
    }
}



export class ZoneNode{
    constructor(){
        this._nodes=[];
        this._expanded=false;
        this._isEditable=false;
        this._isEditEnabled=false;
        this._isEditDisabled=false;
    }

    copyIntoNew(){
        let newZone=new ZoneNode();
    
        newZone._expanded=this._expanded;
        newZone._isEditable=this._isEditable;
        newZone._isEditEnabled=this._isEditEnabled;
        newZone._isEditDisabled=this._isEditDisabled;
        newZone._nodes=this._nodes.map(node=>node.copyIntoNew());
    
        return newZone;
    }

    set nodes(value){
        this._nodes=value;
    }

    get nodes(){
        return this._nodes;
    }

    set expanded(value) {
        this._expanded = value;
    }
    get expanded() {
        return this._expanded;
    }
    
    set isEditable(value){
        this._isEditable=value;
    }
    get isEditable(){
        return this._isEditable;
    }
    set isEditEnabled(value){
        this._isEditEnabled=value;
    }
    get isEditEnabled(){
        return this._isEditEnabled;
    }
    set isEditDisabled(value){
        this._isEditDisabled=value;
    }
    get isEditDisabled(){
        return this._isEditDisabled;
    }
}

