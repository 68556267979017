import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal'; 
import { SESSION_TIME_OUT_LIMIT_IN_MINUTES } from './Constants';

const adalConfig = {
  tenant: process.env.REACT_APP_AZURE_TENANT_ID,
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  endpoints: {
      api: process.env.REACT_APP_AZURE_CLIENT_ID
  },
  cacheLocation: 'localStorage'
  // expireOffsetSeconds: SESSION_TIME_OUT_LIMIT_IN_MINUTES*60
}; 
// console.log("adalConfig==",adalConfig);

export const authContext = new AuthenticationContext(adalConfig);
// authContext.handleWindowCallback();

export const adalApiFetch = (fetch, url, options) =>{
  // console.log("fetch, url, options==",fetch, url, options);
  return adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options)
};

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const getUser=()=>authContext.getCachedUser();

export const getExpirationTime=()=>{
  // console.log("Cached Expiry Time being returned");
  // const expirationTimeString=localStorage.getItem(`adal.expiration.key${adalConfig.clientId}`);
  // const expirationTimeInSeconds=parseInt(localStorage.getItem(`adal.expiration.key${adalConfig.clientId}`));

  return parseInt(localStorage.getItem(`adal.expiration.key${adalConfig.clientId}`));
}

export const handleSessionLogout=(logMsg)=>{
  // authContext.logOut();
  window.location.reload();
}

export const handleManualLogout=(logMsg)=>{
  console.log("LOGGING OUT AT THIS POINT : ",logMsg);
  authContext.logOut();
}

// export const handleSessionRefresh=()=>{
//   // authContext.clearCache();
//   // authContext.acquireTokenPopup(adalConfig.clientId,);
  

//   authContext.acquireToken(adalConfig.clientId, function (error, token,somethingElse) {
//     console.log("authContext.acquireToken error==",error);
//     console.log("authContext.acquireToken token==",token);
//     console.log("authContext.acquireToken somethingElse==",somethingElse);
//   })

//   // let output=authContext.acquireToken(adalConfig.clientId);
//   // console.log("output==",output);
  
// }

export const getTokenRenewalStatus=()=>{
  const tokenRenewStatus=localStorage.getItem(`adal.token.renew.status${adalConfig.clientId}`);
  // console.log("Token Renewal =",tokenRenewStatus);

  return tokenRenewStatus;
}