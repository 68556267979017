import {getTncEvData,getTncEvDropdownService,saveTransactions,uploadTncEvCSV} from '../../../../services/revenuefunction/mainrfinputs/tncev/TncEvService';
import { SetRFError, SetRFPending } from '../../RevenueFunction.Actions';
import {FETCH_MAINRFINPUTS_TNCEV_DATA_PENDING,FETCH_MAINRFINPUTS_TNCEV_DATA_SUCCESS,FETCH_MAINRFINPUTS_TNCEV_DATA_ERROR,FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_PENDING,FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_SUCCESS,FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_ERROR,
        SAVE_TNCEV_TRANSACTION_PENDING,SAVE_TNCEV_TRANSACTION_SUCCESS,SAVE_TNCEV_TRANSACTION_ERROR,RESET_TO_TNCEV_INITIALSTATE,UPLOAD_TNCEV_PENDING,UPLOAD_TNCEV_SUCCESS,UPLOAD_TNCEV_ERROR} from "./TncEv.ActionTypes";

export const fetchTncEvData = (rfid)=>{
    return async dispatch=>{
        try{
            dispatch(serviceActionPending(FETCH_MAINRFINPUTS_TNCEV_DATA_PENDING))
            dispatch(SetRFPending(true))
            let tncEvData=await getTncEvData(rfid);
            if(tncEvData){
                dispatch(serviceActionSuccess(FETCH_MAINRFINPUTS_TNCEV_DATA_SUCCESS,tncEvData))
                dispatch(SetRFPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MAINRFINPUTS_TNCEV_DATA_ERROR))
                dispatch(SetRFError("Unknown error"))
            }
        }
        catch(e){
            dispatch(serviceActionError(FETCH_MAINRFINPUTS_TNCEV_DATA_ERROR))
            dispatch(SetRFError("Unknown error"))
        }
    }
}

export const fetchTncEvDropDownValues = ()=>{
    return async dispatch => {
        dispatch(serviceActionPending(FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_PENDING));
        let tncIceDropdownData = await getTncEvDropdownService();
        if (tncIceDropdownData) {
            dispatch(serviceActionSuccess(FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_SUCCESS, tncIceDropdownData));
        } else {
            dispatch(serviceActionError(FETCH_MAINRFINPUTS_TNCEV_DROPDOWNVALUES_ERROR, tncIceDropdownData));
        }
    }
}

export const tncEvsaveTransactionData = (rfid,tabname,transactionList) =>{
    return async dispatch =>{
        try{
          dispatch(serviceActionPending(SAVE_TNCEV_TRANSACTION_PENDING));
         // await saveTransactions(rfid,tabname,transactionList);
          let tncEvData = await getTncEvData(rfid);
          if (tncEvData) {
            dispatch(serviceActionSuccess(SAVE_TNCEV_TRANSACTION_SUCCESS, {"tncEvData": tncEvData, "filterBy": false}));
          }
        }catch(e){
            dispatch(serviceActionError(SAVE_TNCEV_TRANSACTION_ERROR, "Unknown error"));
        }
    }
}

export const uploadTncEvData=(rfid,tabName,file)=>{
 return async dispatch => {
    try {
        dispatch(serviceActionPending(UPLOAD_TNCEV_PENDING));
        let uploadResp = await uploadTncEvCSV(rfid,tabName,file);
        let tncEvData = await getTncEvData(rfid);
        if(uploadResp.body!==null && uploadResp.name !== 'Error' && uploadResp.data.length>0){
            dispatch(serviceActionSuccess(UPLOAD_TNCEV_SUCCESS, {"tncEvData": tncEvData, "filterBy": false}));
        }
        else{
            dispatch(serviceActionError(UPLOAD_TNCEV_ERROR, "TNCEV DATA upload failed"));
        }
        
    } catch (e) {
        console.error("Exception", e);
        dispatch(serviceActionError(UPLOAD_TNCEV_ERROR, "TNCEV DATA upload failed"));
    }
 }
}

export const resetTncEv = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_TNCEV_INITIALSTATE))
    }
  }

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}