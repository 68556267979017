import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { getTokenRenewalInProgress, getTokenRenewalStatus, handleSessionLogout, handleSessionRefresh } from "../../config";
import { SESSION_TIME_OUT_LIMIT_IN_MINUTES, WARNING_TIME_LIMIT_IN_MINUTES } from "../../Constants";
import DialogBox from "../Dialogcomponent/DialogComponent";
import JobInProgressDialog from "../Dialogcomponent/JobInProgressDialogComponent";
import JobInProgressSingleButton from "../Dialogcomponent/JobInProgressSingleButton";
import SingleButtonDialogComponent from "../Dialogcomponent/SingleButtonDialogComponent";
import './ExpiryTimeTicker.css'; 

export default function ExpiryTimeTicker({timeReaderFunction,isPlanInputs}) {
    
    const [timeToExpiry,setTimeToExpiry]=useState(timeReaderFunction(moment));
    const [warningWindow,setWarningWindow]=useState(false);
    const [open, setOpen]=useState(false);

    useEffect(()=>setInterval(resetTimeToExpiry,1000),[]);

    useEffect(()=>{
        if(warningWindow && !open){
            // console.log("Opening window==",timeToExpiry);
            setOpen(true);
        }
    },[warningWindow])

    

    function resetTimeToExpiry(){
        let timeData=timeReaderFunction(moment);
        setTimeToExpiry(timeData);

        if((WARNING_TIME_LIMIT_IN_MINUTES)>parseInt(timeData.minutes)){
            setWarningWindow(true);
        }
        
        if(parseInt(timeData.minutes)<=0 && parseInt(timeData.seconds)<=0){
            handleSessionLogout();
        }
    }

    function handleYes(){
        setOpen(false);
        // console.log("Closing Warning popup open in handleYes()"); 
    }

    function handleNo(){
        handleSessionLogout();
    }


    

    return <div className="ticker" style={warningWindow?{color:'red',backgroundColor:'pink'}:isPlanInputs?{color:'black'}:{color:'#ffcc00'}}>
        {timeToExpiry.minutes}:{timeToExpiry.seconds}  {getTokenRenewalStatus()==="In Progress"?"Renewal In Progress":''}
        <SingleButtonDialogComponent
            open={open} handleOk={handleYes}
            dialogMessage="Session is Expiring in 5 minutes. The Page will reload automatically !" 
            title="WARNING: Session Expiring" 
            action="Continue Working"
        ></SingleButtonDialogComponent>
    </div>
}