import { combineReducers } from 'redux';
import AddsInvalidPoolsReducer from './AddsInvalidPools.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import addsInvalidPoolsColumnDefinitionReducer from "./AddsInvalidPools.ColumnDef.Reducer";

const rootAddsInvalidPoolsReducer = combineReducers({
    AddsInvalidPoolsReducer
    , addsInvalidPoolsGridReducer: createNamedWrapperReducer(multiGridUIReducer, "addsinvalidpoolsgrid")
    , addsInvalidPoolsColumnDefinitionReducer
})

export default rootAddsInvalidPoolsReducer;