import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import IconFilter from "../../assets/images/icon_filter.svg";
import IconGrid from "../../assets/images/icon_grid.svg";
import IconGridInActive from "../../assets/images/icon_grid_inactive.svg";
import IconActionEdit from "../../assets/images/icon_action_edit.svg";
import IconActionUpload from "../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../assets/images/icon_action_download.svg";
import ConstraintsSearchBar from "../searchbar/ConstraintsSearchBar";
import './MoveConstraintsActionBar.css';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { NttLabel } from '../../core-components/Label/NttLabel';
import InputFilter from '../actionbar/filter_components/input_filter/InputFilter';
import { Divider } from '@material-ui/core';
import {uploadMoveConstraintsData } from '../../store/moveconstraints/MoveConstraints.Actions';
import ConstraintCustomDialog from '../dialog_component/ConstraintCustomDialog';
import CustomPopOver from "../../core-components/Popover/CustomPopOver";
import EditIcon from "../../assets/images/icon_edit.svg";
import StatusIcon from "../../assets/images/icons_status_icons_completed.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteDialogBox from '../Dialogcomponent/DialogComponent';
import { safeExcelValue, safeExcelDate,stringFormatter } from '../../utils/utils';
import { populateGrid2, validateGrid } from '../../store/MultiGrid/MultiGrid.Actions';

const MoveConstraintsActionBar = (props) => {
    const { options, constraintsData, actionView, onViewChanged, constraintTypeSelected, downloadCSV, data,
        constraintType, onAddNewRowClick, onSaveClicked, onCancelClicked, constrainttypes,
        selectedPlanId, handleSaveCancelDisabled, bulkRowDeleteIconDisabled, deleteSelectedRows, constraintsGridColDef,
        transactions, filterSearchKey, filterParams, constraintsWholeData } = props;
    const [currentView, setCurrentView] = useState('Type>Zone');
    const theme = createMuiTheme();
    const [activeIndex, setActiveIndex] = useState(0);
    const [filterType, setFilterType] = useState('');
    const [open, setOpen] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const currentYear = new Date().getFullYear();
    const previousYear = new Date().getFullYear() - 1;
    var filterCat = { "constraint_type": [], "zone_name": [], "pool": [], "from_date": ['between'], "to_date": ['between'], "min": ['between'], "max": ['between'], "mileage_start": ['between'], "mileage_end": ['between'], "bu": [], "car_group_name": [], "program_type": [], "spi_code": [], "manufacturer_group_code": [],
                      "Current_Year":'',"Last_Year":'' };
    const [filterArray, setFilterArray] = useState(filterCat);
    const [tempFilterArray, setTempFilterArray] = useState(filterArray);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = React.useState();
    const [selectedToDate, setSelectedToDate] = React.useState();
    const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
    const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
    const [selectedDropdown, setSelectedDropdown] = React.useState("between");
    const [anchorEl, setAnchorEl] = useState(null);
    const popOverOpen = Boolean(anchorEl);
    const id = popOverOpen ? 'simple-popover' : undefined;
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopOverOpen = Boolean(filterAnchorEl);
    const filterId = filterPopOverOpen ? 'simple-popover' : undefined;
    const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(-1);
    const [filterClassName, setFilterClassName] = useState('filterCustomButton');
    let message = "Are you sure that you want to delete selected rows?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";
    const dispatch = useDispatch();
    var selectedType;

    theme.typography.body2 = {
        fontSize: '14px'
    };

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));
    const classes = useStyles();

    var addConstraintsData = useSelector((state, shallowEqual) => {
        return state.rootConstraintsReducer.constraintsReducer.dropdownData;
    });

    var isConstraintsAccordionInEditMode = useSelector((state, shallowEqual) => {
        return state.rootConstraintsReducer.constraintsReducer.isAccordionDataInEditMode;
    });

    const switchView = (selected) => {
        if (selected !== "grid") {
            setCurrentView("accordion");
            return onViewChanged("grid", selected);
        } else {
            setCurrentView("grid");
            return onViewChanged("accordion", selected);
        }
    }

    const handleChange = (event) => {
        switchView(event.target.value);
        setCurrentView(event.target.value);
    };

    const handleEditClick = (e, selected) => {
        if (selected === "grid")
            setSelectedIndex(4);
        else
            setSelectedIndex(1);
        setAnchorEl(null);
        switchView(selected);
        setCurrentView(selected);
    };

    const handleClose = (e) => {
        setOpen(false);
        setFilterType('');
        setTempFilterArray(filterArray);
    };

    const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value);

    function filterRecords(array, filterparams) {
        if (filterparams == null) {
            return array;
        } else {
            const filterKeys = Object.keys(filterparams);
            return array.filter(item => {
                // validates all filter criteria
                return filterKeys.every(key => {
                    // ignores an empty filter
                    if(key === 'Current_Year'){
                        if(filterparams[key]!=='' && item.to_date!==null && item.from_date!==null){
                            let d1 = new Date(item.from_date.split('T')[0]);
                            let d2 = new Date(item.to_date.split('T')[0]);
                        return(filterparams[key]==d1.getFullYear() || filterparams[key]==d2.getFullYear());
                        }
                    }
                    else if(key === 'Last_Year' && item.to_date!==null && item.from_date!==null){
                        if(filterparams[key]!==''){
                            let d1 = new Date(item.from_date.split('T')[0]);
                            let d2 = new Date(item.to_date.split('T')[0]);
                        return((filterparams[key]==d1.getFullYear()) || (filterparams[key]==d2.getFullYear()));
                        }
                    }
                    if (!filterparams[key].length) return true;
                    if (key === "from_date" || key === "to_date") {
                        if (filterparams[key].length === 1) {
                            return true;
                        }
                        else {
                            if (item[key] == undefined && item.month) {
                                let d1 = new Date(filterparams[key][1]);
                                let d2 = new Date(item.month.split('T')[0]);
                                let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                                let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1);
                                let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                                let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                                if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                    let d3 = new Date(filterparams[key][2]);
                                    let NoTimeDate2 = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                                    let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                                    return itemValue >= startDate && itemValue <= endDate;
                                } else if (filterparams[key][0] === "less") {
                                    return itemValue <= startDate;
                                } else if (filterparams[key][0] === "greater") {
                                    return itemValue >= startDate;
                                }
                            }
                            else if(item[key]!==null){
                                let d1 = new Date(filterparams[key][1]);
                                let d2 = new Date(item[key].split('T')[0]);
                                let NoTimeDate = d1.getFullYear() + '/' + (d1.getMonth() + 1);
                                let NoTimeDate1 = d2.getUTCFullYear() + '/' + (d2.getUTCMonth() + 1); //Date received from database is always in UTC
                                let startDate = new Date(NoTimeDate).setHours(0, 0, 0, 0);
                                let itemValue = new Date(NoTimeDate1).setHours(0, 0, 0, 0);
                                if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                    let d3 = new Date(filterparams[key][2]);
                                    let NoTimeDate2 = d3.getFullYear() + '/' + (d3.getMonth() + 1);
                                    let endDate = new Date(NoTimeDate2).setHours(0, 0, 0, 0);
                                    return itemValue >= startDate && itemValue <= endDate;
                                } else if (filterparams[key][0] === "less") {
                                    return itemValue <= startDate;
                                } else if (filterparams[key][0] === "greater") {
                                    return itemValue >= startDate;
                                }
                            }
                        }
                    }
                    if (typeof filterparams[key] === 'object' && (key !== "from_date" && key !== "to_date" &&
                        key !== "min" && key !== "max" && key !== 'mileage_start' && key !== 'mileage_end')) {
                        if (filterparams[key].length == 0) {
                            return true;
                        }
                        else {
                            if(key==='car_group_name' && filterparams[key][0].value==='Blanks'){
                                return ((item[key]===null) || (item[key]===undefined)) 
                            }else{
                                return (filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) == undefined ? filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])) : filterparams[key].find(filter => getValue(filter.value) === getValue(item[key])).value);
                            }
                        }
                    }
                    if (key === 'mileage_start' || key === 'mileage_end') {
                        if (filterparams[key].length === 1) {  
                            if(filterparams[key][0] === 'blanks'){
                              let itemValue = item[key];
                              return (itemValue===null || itemValue===undefined || itemValue==='')
                            }else {
                                return true;
                             } 
                        } else {
                            let startValue = parseInt(filterparams[key][1], 0);
                            let itemValue = parseInt(item[key], 0);
                            if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                let endValue = parseInt(filterparams[key][2], 0);
                                if (filterparams[key][1] !== "" || filterparams[key][2] !== "") {
                                    return item[key] >= startValue && itemValue <= endValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "less") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue <= startValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "greater") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue >= startValue;
                                } else {
                                    return true;
                                }
                            }
                        }
                    }
                    if (key === "min" || key === "max") {
                        if (filterparams[key].length === 1) {
                            return true;
                        } else {
                            let startValue = parseInt(filterparams[key][1], 0);
                            let itemValue = parseInt(item[key], 0);
                            if (filterparams[key][0] === "between" || filterparams[key][0] === undefined) {
                                let endValue = parseInt(filterparams[key][2], 0);
                                if (filterparams[key][1] !== "" && filterparams[key][2] !== "") {
                                    return item[key] >= startValue && itemValue <= endValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "less") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue <= startValue;
                                } else {
                                    return true;
                                }
                            } else if (filterparams[key][0] === "greater") {
                                if (filterparams[key][1] !== "") {
                                    return itemValue >= startValue;
                                } else {
                                    return true;
                                }
                            }
                        }
                    }
                });
            });
        }
    }

    const applyTransactions = (constraintsData, transactions) => {
        let deepcopyConstraintsData = constraintsData.map(o => { return { ...o }; })
        transactions.map((value1) => {
            deepcopyConstraintsData.map((value2, index) => {
                if (value1.type !== 'delete' && value1.type !== 'add' && value1.type !== 'copy' && (value2.ID == value1.data.ID)) {
                    deepcopyConstraintsData[index] = value1.data;
                } else if (value1.type == 'delete' && (value2.ID == value1.id)) {
                    deepcopyConstraintsData.splice(index, 1)
                }
            });
            if (value1.type == 'add' || value1.type == 'copy') { 
                if (value1.data !== undefined) {  // If data is undefined,app is braking while filter and clear filter.That's why adding condition.But here we loose added row on filter if it is blank.
                    deepcopyConstraintsData.push(value1.data);
                }
            }
        });
        return deepcopyConstraintsData;
    }

    // useEffect(() => {
    //     if (transactions.length == 0) {
    //         dispatch(fetchConstraintData(selectedPlanId));
    //     } else if (transactions.length > 0) {
    //         var currentdata = applyTransactions(constraintsData, transactions);
    //         var filteredCurrentData = filterRecords(currentdata, filterParams);
    //         var filteredData = filterRecords(constraintsData, filterParams);
    //         dispatch(populateGrid2("moveconstraintsgrid", filteredData, filteredCurrentData, filterSearchKey, constraintsGridColDef.checkValue, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
    //     }
    // }, [filterParams, filterSearchKey])

    useEffect(() => {
        if (constraintsData.length > 0) {
            //var filteredData = filterRecords(constraintsData, filterParams);
            dispatch(populateGrid2("moveconstraintsgrid", constraintsData, constraintsData, filterSearchKey, constraintsGridColDef.checkValue, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
            dispatch(validateGrid("moveconstraintsgrid", constraintsData, constraintsGridColDef.validationFn, constraintsGridColDef.businessValidationFn));
        }
    }, [constraintsData])

    useEffect(() => {
        if (actionView === "grid")
            setSelectedIndex(4);
        else
            setSelectedIndex(1);
    }, [actionView]);

    const handleAddClick = (e) => {
        return onAddNewRowClick();
    }

    const getSelectedValue = (colDef) => {
        if (tempFilterArray !== undefined) {
            return tempFilterArray[colDef];
        }
    }

    const handleDropDownValueChange = (event, displayName, colDef) => {
        setSelectedValue(event);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (Array.isArray(filterData[key])) {
                    filterData[key] = event;
                } else {
                    filterData[key] = event[0].value;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

    const onDateRangeChanged = (value1, colDef, dateField,value2,type) => {
        if(type && type=='Current Year' || type=='Last Year'){
            let dateValue1 = new Date(value1);
            let dateValue2 = new Date(value2);
            var filterData = { ...tempFilterArray };
            filterData['from_date'].push(dateValue1);
            filterData['from_date'].push(dateValue2);
            filterData['to_date'].push(dateValue1);
            filterData['to_date'].push(dateValue2);
            // if(dateField=='from'){
                setSelectedFromDate(dateValue1);
            // }else{
                setSelectedToDate(dateValue2);
            //}
        }else{
            var date = new Date(value1);
        if (dateField == "from")
            setSelectedFromDate(date);
        else if (dateField == "to")
            setSelectedToDate(date);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key === colDef) {
                if (filterData[colDef][0] === "between") {
                    if (filterData[key].length === 3) {
                        if (dateField === "from") {
                            filterData[key][1] = date;
                        }
                        if (dateField === "to") {
                            filterData[key][2] = date;
                        }
                    } else {
                        if (filterData[key].length == 2 && dateField == "from")
                            filterData[key][1] = date;
                        else if (filterData[key].length == 2 && dateField == "to")
                            filterData[key][2] = date;
                        else
                            filterData[key].push(date);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(date);
                    else
                        filterData[key][1] = date;
                }
            }
            return filterData;
        });
        }
        setTempFilterArray(filterData);
    }

    const onDateDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onRangeDropDownValueChanged = (e, colDef) => {
        tempFilterArray[colDef][0] = e.target.value;
        setSelectedDropdown(e.target.value);
    }

    const onValueRangeChanged = (e, colDef, rangeField) => {
        var range = e.target.value;
        if (rangeField == "from")
            setSelectedFromRangeValue(range);
        else if (rangeField == "to")
            setSelectedToRangeValue(range);
        var filterData = { ...tempFilterArray };
        var keys = Object.keys(filterData);
        keys.map(key => {
            if (key == colDef) {
                if (filterData[colDef][0] == "between") {
                    if (filterData[key].length === 3) {
                        if (rangeField === "from") {
                            filterData[key][1] = range;
                        }
                        if (rangeField === "to") {
                            filterData[key][2] = range;
                        }
                    } else {
                        if (filterData[key].length == 1 && rangeField == "to") {
                            filterData[key].push("0");
                        }
                        filterData[key].push(range);
                    }
                } else {
                    if (filterData[key].length == 1)
                        filterData[key].push(range);
                    else
                        filterData[key][1] = range;
                }
            }
            return filterData;
        });
        setTempFilterArray(filterData);
    }

  const checkIfTempFilterArrayIsEmpty= (tempArray)=>{
    if(tempArray.bu.length==0 && tempArray.car_group_name.length==0 && tempArray.constraint_type.length==0 && tempArray.from_date.length==1 && tempArray.manufacturer_group_code.length==0 && tempArray.max.length==1 
         && (tempArray.mileage_end.length==1 && tempArray.mileage_end[0]!=='blanks') && (tempArray.mileage_start.length==1 && tempArray.mileage_start[0]!=='blanks') && tempArray.min.length==1 && tempArray.pool.length==0 && tempArray.program_type.length==0 && tempArray.spi_code.length==0 
         && tempArray.to_date.length==1 && tempArray.zone_name.length==0){
             return 0;
         }else{
             return 1;
         }
  }

    const applyFilter = (event) => {
        setOpen(false);
        setFilterArray({ ...tempFilterArray });
        let isTempFilterArrayEmpty = checkIfTempFilterArrayIsEmpty(tempFilterArray);
        if(isTempFilterArrayEmpty==0){
            //loadFilterParams(null);
            setSelectedFilterIndex(-1);
        }else{
            //loadFilterParams({ ...tempFilterArray });
        }
        setFilterType('');
        setActiveIndex(0);
    }

    const clearFilter = (event) => {
        //setOpen(false);
        setFilterArray(filterCat);
        setTempFilterArray(filterCat);
        setFilterType('');
        setSelectedFilterIndex(-1);
        //loadFilterParams(null);
        setActiveIndex(0);
    }

    const changeHandler = async (event) => {
        if (event.target.value !== '') {
            const fileToUpload = event.target.files[0];
            event.target.value = '';
            dispatch(uploadMoveConstraintsData(selectedPlanId, fileToUpload));
        }
    };

    const downloadHandler = async (event) => {
        let JsonFields = ["ZONE_NAME", "POOL", "MONTH", "MIN_FROM", "MAX_FROM"]
        let downloadData = JsonToCSV(data, JsonFields);
        let currentdate = new Date();
        let dateStr = `${currentdate.getFullYear()}${currentdate.getMonth() + 1}${currentdate.getDate()}_${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}`;
        downloadCSV(downloadData, `moveconstraints-${selectedPlanId ? selectedPlanId : 'main'}_${dateStr}.csv`);
    };

    function JsonToCSV(JsonArray, JsonFields) {
        var csvStr = JsonFields.join(",") + "\n";

        JsonArray.forEach(element => {
            let zone = safeExcelValue(element.ZONE_NAME);
            let Pool_code = safeExcelValue(element.POOL);
            let month = safeExcelDate(element.MONTH, 'DD-MMM-YY');
            let min_from = safeExcelValue(element.MIN_FROM);
            let max_from = safeExcelValue(element.MAX_FROM);
            csvStr += zone + ',' + Pool_code + ',' + month + ',' + min_from + ',' + max_from + "\n";
        })
        return csvStr;
    }

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handlePopOverClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        var selectedValue;
        if (index === 1)
            selectedValue = "Type>Zone";
        else if (index === 2)
            selectedValue = "Type>BU>Zone";
        else if (index === 3)
            selectedValue = "Zone>Type";
        else if (index === 4)
            selectedValue = "grid";
        return switchView(selectedValue);
    };

    const switchViewComponent = () => {
        return (<div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
            <Button id={id} onClick={(e) => handlePopOverClick(e)} endIcon={<ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon>}>View</Button>
            <CustomPopOver
                className="actiobarPopOver"
                popOverId="popoverId"
                open={popOverOpen}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <List>
                    <ListItem button
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}>
                        <img src={selectedIndex === 1 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Type {'>'} Zone</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}>
                        <img src={selectedIndex === 2 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Type {'>'} BU {'>'} Zone</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}>
                        <img src={selectedIndex === 3 ? StatusIcon : IconGridInActive} className="imageSpacing"></img>
                        <NttLabel theme={theme} labelVariant="body2">
                            Group by Zone {'>'} Type</NttLabel>
                    </ListItem>
                    <Divider />
                    <ListItem button
                        selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}>
                        <img src={selectedIndex === 4 ? StatusIcon : EditIcon} className="imageSpacing"></img>

                        <NttLabel theme={theme} labelVariant="body2">
                            Table View</NttLabel>
                    </ListItem>
                </List>
            </CustomPopOver>
        </div>);
    }

    const handleClickOpen = (props) => {
        if (!bulkRowDeleteIconDisabled) {
            setOpenDeleteDialog(true);
        }
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteSelectedRows = () => {
        setOpenDeleteDialog(false);
        deleteSelectedRows()
    }

    return (
        <div aria-disabled={isConstraintsAccordionInEditMode} className={(isConstraintsAccordionInEditMode) ? 'is-disabled' : ''} style={{ marginTop: '-36px' }}>
            <div className={actionView == 'grid' ? "constraintactionbar" : "fixedConstraintActionBar "}>
                <div className="constraintactions">
                    <div className="constraintfilterStyle">
                        {/* <div className="filterMargin">{filterComponent()}</div>
                        <ConstraintsSearchBar></ConstraintsSearchBar> */}
                    </div>
                    {/* Plan status is running should disable cancel save changes add + and delete  */}
                    {options  && constraintsGridColDef.defaultColDef.editable?
                        <div className="moveConstraintsaveButtons">
                            <div className="cancelButton">
                                <Button onClick={(e) => onCancelClicked(e)}>Cancel</Button>
                            </div>
                            <div className="primaryButton">
                                <Button disabled={handleSaveCancelDisabled} onClick={(e) => onSaveClicked(e)}>Save changes</Button>
                            </div>
                            <div className="primaryButton">
                                <Button disabled={ !constraintsGridColDef.defaultColDef.editable} id={id} onClick={(e) => handleAddClick(e)}>+ Add</Button>
                            </div>
                            <div className="primaryButton">
                                <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !constraintsGridColDef.defaultColDef.editable)}>
                                    Delete
                                </Button>
                            </div>
                        </div> :
                        <div className="moveConstraintactionButtons">
                            {/* {(currentView !== "grid" && actionView !== "grid") ? <img src={IconActionEdit} className="imageIcon" onClick={(e) => handleEditClick(e, 'grid')}></img> :
                                <img src={IconGrid} className="imageIcon" onClick={(e) => handleEditClick(e, 'Type>Zone')}></img>} */}
                            <label>
                                <input disabled={ !constraintsGridColDef.defaultColDef.editable} type="file" accept=".csv" name="file" onChange={changeHandler} style={{ "display": "none" }} />
                                <img src={IconActionUpload} className="imageIcon"></img>
                            </label>
                            <div className='imageIcon'>
                                <img src={IconActionDownload} onClick={() => downloadHandler()} />
                            </div>
                            <div>
                                {/* {switchViewComponent()} */}
                            </div>
                            {(currentView === "grid" || actionView === "grid") ?
                                <div style={{ display: 'flex' }}>
                                    <div className="primaryButton">
                                        <Button disabled={ !constraintsGridColDef.defaultColDef.editable} id={id} onClick={(e) => handleAddClick(e)}>+ Add</Button>
                                    </div>
                                    <div className="primaryButton">
                                        <Button onClick={() => handleClickOpen()} disabled={bulkRowDeleteIconDisabled || ( !constraintsGridColDef.defaultColDef.editable)}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                                : <div></div>}
                        </div>}
                        <div>
                        <DeleteDialogBox open={openDeleteDialog}  handleClose={handleCloseDeleteDialog} handleYes={handleDeleteSelectedRows}
                            dialogMessage={message} title={title} action={deletetext}
                            canceltext={canceltext}>
                        </DeleteDialogBox>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default MoveConstraintsActionBar;