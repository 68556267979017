import { ParametersColumns, cellEditors } from "../../../Constants";
import { updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        field: ParametersColumns.key.field, 
        headerName: ParametersColumns.key.headerName, 
        unSortIcon: true,
        flex: 5,
        cellClassRules: cellClassRules(ParametersColumns.key.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ParametersColumns.key.name)
        }, 
        editable: function (params) {
            if (params.data.status && (params.data.status === 'ADD_ROW' || params.data.status == "INVALID_NEW_ROW" || params.data.status == "ERROR_NEW_ROW"))
                return true;
            else
                return false; // true/false based on params (or some other criteria) value
        },
        cellEditor: cellEditors.TextFieldEditor
    },
    {
        field: ParametersColumns.value.field, 
        headerName: ParametersColumns.value.headerName, 
        unSortIcon: true, 
        type: 'numericColumn',
        width: 120,
        // flex:3,
        cellEditorSelector: (params) => {
            if (params.data && (params.data.key === 'RF_CODE_ZONE' || params.data.key === 'RF_CODE_POOL'|| params.data.key === 'RF_CODE_TNC')) {
                return {
                    component: cellEditors.ParametersAutoCompleteEditor
                };
            } else {
                //return params.data.value;
                return{
                    component: cellEditors.TextFieldEditor
                }
            }
        },
        cellClassRules: cellClassRules(ParametersColumns.value.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, ParametersColumns.value.name)
        }
    },
    {
        field: ParametersColumns.UpdatedBy.field, 
        headerName: ParametersColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],width: 90,
        cellClassRules: cellClassRules(ParametersColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ParametersColumns.UpdatedBy.name)
        }
      },
      {
        field: ParametersColumns.UpdatedOn.field, 
        headerName: ParametersColumns.UpdatedOn.headerName, 
        editable: false,width: 90,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(ParametersColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, ParametersColumns.UpdatedOn.name)
        }
      },
    {
        headerName: "",
        colId: "actions",
        // flex: 1,
        width: 60,
        cellRenderer: "parametersActionsRenderer",
        editable: false,
        filter: false,
        sortable: false,
        resizable: false,
        type: 'numericColumn',
    }
];

// function toolTipUI(params, field) {
//     // if (params.data.validations && params.data.validations.length > 0 && params.data.validations[field].error !== null) {
//     //     return params.data.validations[field].error
//     // } if (params.data.status === "ERROR_ROW") 
//     //     return "Duplicate row";
//     // }


// }
function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field] &&
        params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef && params.data.validations[field]) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    // minWidth: 215,
    filter: false,
    resizable: true,
    floatingFilter: false,
};
