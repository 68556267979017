import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from "../../utils/authUtils";
import { checkAndLoadDimention } from "../../utils/utils";
import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';

export async function fetchPlanAdjustmentOutput(planid) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

 
    const promiseZoneResponse = await checkAndLoadDimention("zone", client);
    const promisePoolResponse = await checkAndLoadDimention("pool", client);
    const planOptimizationOutput = await client.apis.adjustments.getOutput({ "planid": planid });
    const responsesArray = await Promise.all([planOptimizationOutput, promisePoolResponse,promiseZoneResponse]);
    const [planAdjustmentOutputResponse, poolResponse,zones] = responsesArray.map(o => o.body.data);
    return ({ "planOptimizationOutputs": planAdjustmentOutputResponse, "zones": zones, "pools": poolResponse });
}

export async function updateSalesAdjustmentsService(planid,transactionList){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const response = await client.apis.adjustments.updateAdjustment({"planid":planid?planid:'master'},{requestBody: {
                    "updatedBy":userEmail,
                    "transactions": transactionList
                }
            });
        return response;
    } 
    catch (error) {
        console.error(error);
        return {'Error':'update adjustments failed'}
    }
}