export const FETCH_METADATA_POOL_DATA_PENDING = 'FETCH_METADATA_POOL_DATA_PENDING';
export const FETCH_METADATA_POOL_DATA_SUCCESS = 'FETCH_METADATA_POOL_DATA_SUCCESS';
export const FETCH_METADATA_POOL_DATA_ERROR = 'FETCH_METADATA_POOL_DATA_ERROR';

// export const UPLOAD_METADATA_POOL_PENDING = 'UPLOAD_METADATA_POOL_PENDING';
// export const UPLOAD_METADATA_POOL_SUCCESS = 'UPLOAD_METADATA_POOL_SUCCESS';
// export const UPLOAD_METADATA_POOL_ERROR = 'UPLOAD_METADATA_POOL_ERROR';

export const SAVE_METADATA_POOL_TRANSACTION_PENDING = 'SAVE_METADATA_POOL_TRANSACTION_PENDING';
export const SAVE_METADATA_POOL_TRANSACTION_SUCCESS = 'SAVE_METADATA_POOL_TRANSACTION_SUCCESS';
export const SAVE_METADATA_POOL_TRANSACTION_ERROR = 'SAVE_METADATA_POOL_TRANSACTION_ERROR';

export const FETCH_METADATA_POOL_DROPDOWNVALUES_PENDING = 'FETCH_METADATA_POOL_DROPDOWNVALUES_PENDING';
export const FETCH_METADATA_POOL_DROPDOWNVALUES_SUCCESS = 'FETCH_METADATA_POOL_DROPDOWNVALUES_SUCCESS';
export const FETCH_METADATA_POOL_DROPDOWNVALUES_ERROR = 'FETCH_METADATA_POOL_DROPDOWNVALUES_ERROR';

export const RESET_TO_METADATA_POOL_INITIALSTATE = 'RESET_TO_METADATA_POOL_INITIALSTATE';