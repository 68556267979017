import React, { useContext } from "react";
import { adjustmentFilters } from "../../../containers/adjustments/AdjustmentsPage";
import { checkFilterParameterValue, checkType } from "../../../utils/AdjustmentsUtils";
import { localeFormatAndRounding, assignSign } from "../../../utils/formatters/valueFormatter";
import './ReadOnlyCellComponent.css';
import {IS_ADJUSTMENT_COMPLETE_ARRAY } from "../../../Constants";


const ReadOnlyCellComponent = (props) => {
    const {type, adjustmentNode,accordionSummary,plansDataById} = props;

    let isAdjComplete = (plansDataById[0].status && IS_ADJUSTMENT_COMPLETE_ARRAY.includes(plansDataById[0].status.toLowerCase()));
    
    if(accordionSummary){//if(!adjustmentNode.expanded){
        return <div className={type === 'summaryStack' ? 'applygreybackgroundforSF' :'cellComponentForSF'}>
            <span className='salesandfinanceValuesWithMargin'>{localeFormatAndRounding(adjustmentNode.value)}</span>
        </div>
    }else if(isAdjComplete){
        return <div className={type == 'summaryStack' ? (adjustmentNode.highlighted ? 'applygreybackgroundforSF selectedForFilter' : 'applygreybackgroundforSF') : ((adjustmentNode.highlighted && adjustmentNode.isFreezMonth) ? ' applyFreezMonthColor cellComponentForSF selectedForFilter':(adjustmentNode.highlighted ? 'cellComponentForSF selectedForFilter' : 'cellComponentForSF'))}>
            <span className={((adjustmentNode.requestedAdjustment > 0) && adjustmentNode.isFreezMonth) ? "applyFreezMonthColor salesandfinancevaluesgreen"
                                :(adjustmentNode.requestedAdjustment > 0) ? 'salesandfinancevaluesgreen' 
                                :((adjustmentNode.requestedAdjustment < 0) && adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevaluesred' 
                                :(adjustmentNode.requestedAdjustment < 0) ? 'salesandfinancevaluesred'
                                :(adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevalues'
                                : 'salesandfinancevalues'}
            >
                {assignSign(adjustmentNode.requestedAdjustment)}
            </span>
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinancevalues": 'salesandfinancevalues'}>{localeFormatAndRounding(adjustmentNode.adjustmentVariance)}</span>
            <span className={adjustmentNode.isFreezMonth &&  adjustmentNode.requestedValue!=0? 'applyFreezMonthColor salesandfinancevaluesblue': adjustmentNode.requestedValue!=0?"salesandfinancevaluesblue":"salesandfinancevalues"}>{localeFormatAndRounding(adjustmentNode.requestedValue)}</span>
            <span className={adjustmentNode.isFreezMonth ? "applyFreezMonthColor salesandfinancevalues":'salesandfinancevalues'}>{localeFormatAndRounding(adjustmentNode.originalValue)}</span>
        </div>
    }else{
        return <div className={type == 'summaryStack' ? (adjustmentNode.highlighted ? 'applygreybackgroundforSF selectedForFilter' : 'applygreybackgroundforSF') : ((adjustmentNode.highlighted && adjustmentNode.isFreezMonth) ? ' applyFreezMonthColor cellComponentForSF selectedForFilter':(adjustmentNode.highlighted ? 'cellComponentForSF selectedForFilter' : 'cellComponentForSF'))}>
            <span className={((adjustmentNode.requestedAdjustment > 0) && adjustmentNode.isFreezMonth) ? "applyFreezMonthColor salesandfinancevaluesgreen"
                                :(adjustmentNode.requestedAdjustment > 0) ? 'salesandfinancevaluesgreen' 
                                :((adjustmentNode.requestedAdjustment < 0) && adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevaluesred' 
                                :(adjustmentNode.requestedAdjustment < 0) ? 'salesandfinancevaluesred'
                                :(adjustmentNode.isFreezMonth) ? 'applyFreezMonthColor salesandfinancevalues'
                                : 'salesandfinancevalues'}
            >
                {assignSign(adjustmentNode.requestedAdjustment)}
            </span>
            <span className={adjustmentNode.isFreezMonth ? 'applyFreezMonthColor salesandfinancevaluesblue': "salesandfinancevalues"}>{localeFormatAndRounding(adjustmentNode.requestedValue)}</span>
        </div>
    }
}
export default ReadOnlyCellComponent;


