import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { getAccessTokenAfterAuth } from '../../../utils/authUtils';

export async function getAllRoles(){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const allRolesResponse = await client.apis.admin.getAllRoles({});

    if(allRolesResponse.body.statusCode==="500"){
        return {error:allRolesResponse.body};
    }else{
        return allRolesResponse.body.data;
    }
}