export const FETCH_MANUAL_VIN_DELETES_DATA_PENDING = "FETCH_MANUAL_VIN_DELETES_DATA_PENDING";
export const FETCH_MANUAL_VIN_DELETES_DATA_SUCCESS = "FETCH_MANUAL_VIN_DELETES_DATA_SUCCESS";
export const FETCH_MANUAL_VIN_DELETES_DATA_ERROR = "FETCH_MANUAL_VIN_DELETES_DATA_ERROR";
export const SAVE_MANUALVINDELETES_TRANSACTION_PENDING ="SAVE_MANUALVINDELETES_TRANSACTION_PENDING";
export const SAVE_MANUALVINDELETES_TRANSACTION_SUCCESS ="SAVE_MANUALVINDELETES_TRANSACTION_SUCCESS";
export const SAVE_MANUALVINDELETES_TRANSACTION_ERROR ="SAVE_MANUALVINDELETES_TRANSACTION_ERROR";
export const SET_ASSUMPTION_MANUAL_VIN_DELETES_PAGE_VALUE="SET_ASSUMPTION_MANUAL_VIN_DELETES_PAGE_VALUE"
export const UPLOAD_MANUALVINDELETES_PENDING = 'UPLOAD_MANUALVINDELETES_PENDING';
export const UPLOAD_MANUALVINDELETES_SUCCESS = 'UPLOAD_MANUALVINDELETES_SUCCESS';
export const UPLOAD_MANUALVINDELETES_ERROR = 'UPLOAD_MANUALVINDELETES_ERROR';
export const FETCH_MANUAL_VIN_DELETES_DELETE_PENDING = 'FETCH_MANUAL_VIN_DELETES_DELETE_PENDING';
export const FETCH_MANUAL_VIN_DELETES_DELETE_SUCCESS = 'FETCH_MANUAL_VIN_DELETES_DELETE_SUCCESS';
export const FETCH_MANUAL_VIN_DELETES_DELETE_ERROR = 'FETCH_MANUAL_VIN_DELETES_DELETE_ERROR';
export const INSERT_UPLOADED_MANUALVINDELETES_PENDING = 'INSERT_UPLOADED_MANUALVINDELETES_PENDING';
export const INSERT_UPLOADED_MANUALVINDELETES_SUCCESS = 'INSERT_UPLOADED_MANUALVINDELETES_SUCCESS';
export const INSERT_UPLOADED_MANUALVINDELETES_ERROR = 'INSERT_UPLOADED_MANUALVINDELETES_ERROR';
export const FETCH_MANUALVINDELETES_FILTER_DISPLAY_DATA = "FETCH_MANUALVINDELETES_FILTER_DISPLAY_DATA";
export const SET_MANUALVINDELETES_FILTER_SEARCH_VIEW = "SET_MANUALVINDELETES_FILTER_SEARCH_VIEW";
export const SET_MANUALVINDELETES_SEARCH_TEXT = "SET_MANUALVINDELETES_SEARCH_TEXT";
export const SET_MANUALVINDELETES_FILTER_PARAMS = "SET_MANUALVINDELETES_FILTER_PARAMS";
export const SET_MANUALVINDELETES_FILTER_VIEW = "SET_MANUALVINDELETES_FILTER_VIEW";
export const SET_ASSUMPTION_MANUALVINDELETES_ERRORVIEW_STATUS = 'SET_ASSUMPTION_MANUALVINDELETES_ERRORVIEW_STATUS';
export const FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS="FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS";
export const FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS="FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS";
export const SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING = "SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING";
export const SET_MANUAL_VIN_DELETES_FILTER_RECORDS_SUCCESS = "SET_MANUAL_VIN_DELETES_FILTER_RECORDS_SUCCESS";
export const SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR = "SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR";
export const SET_MANUAL_VIN_DELETES_SEARCH_RECORDS_SUCCESS = "SET_MANUAL_VIN_DELETES_SEARCH_RECORDS_SUCCESS";
export const RESET_TO_MANUALVINDELETES_INITIALSTATE = "RESET_TO_MANUALVINDELETES_INITIALSTATE";
export const FETCH_MANUALVINDELETES_DROPDOWNVALUES_PENDING = 'FETCH_MANUALVINDELETES_DROPDOWNVALUES_PENDING';
export const FETCH_MANUALVINDELETES_DROPDOWNVALUES_SUCCESS = 'FETCH_MANUALVINDELETES_DROPDOWNVALUES_SUCCESS';
export const FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR = 'FETCH_MANUALVINDELETES_DROPDOWNVALUES_ERROR';
