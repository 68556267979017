import React, { useState, useEffect} from 'react';
import { AgGridReact } from 'ag-grid-react';
import GridComponents from "./grid_components";
import { useDispatch, useSelector} from "react-redux";
import { completeEditMultipleRow, completeEditRow, validateGrid } from '../../../store/revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import './RevenueFunctionMultiGridUI.css';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { fetchGridData, setGridUserPageNumber, validateSingleGridsData } from '../../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { MAX_ROWS_ALLOWED_FOR_EACH_GRID, MAX_ROWS_ALLOWED_FOR_MASS_CHANGE, MessageNotificationValues } from '../../../Constants';
import { Button } from '@material-ui/core';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import { addNotificationMessage } from '../../../store/topbar/AllPlans.Actions';

const RevenueFunctionMultiGridUI = (props) => {
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const dispatch = useDispatch();
    const [changeCurrentData,setChangeCurrentData]=useState(false)
    const { reducer, gridName, gridColDef,planid,gridpageno,onRowEditingStarted, onRowEditingStopped, onCellEditingStopped, currentView, refreshCells, rowEditType, suppressclickEdit, handleMultipleRowDelete, scrollToTop,handleAddNewDropdownValue,hideActions,isPaginationRequired,rfid,loadGridData} = { ...props };
    const { currentData, transactions, validations } = reducer;
    const [newCurrentData,setNewCurrentData]=useState()

    const gridTotalRowCount = useSelector((state) => {
        return state.PaginationGridReducer.gridTotalRowCount;
      });
    
    const gridUserPageno=useSelector((state)=>{
        return state.PaginationGridReducer.gridUserPageno;
      })
    //const[pageno,setPageNo]=useState(gridUserPageno)
    const [pagesize,setPageSize]=useState()
    
    function onGridReady(params) {
       
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();

        window.onresize = () => {
            params.api.sizeColumnsToFit();
        }
    }

useEffect(()=>{
    //setPageNo(gridUserPageno)
    let totalPage=Math.ceil(gridTotalRowCount / MAX_ROWS_ALLOWED_FOR_EACH_GRID)
    //let reminder=gridTotalRowCount%MAX_ROWS_ALLOWED_FOR_EACH_GRID;
    setPageSize(isNaN(totalPage)?1:totalPage)
},[gridTotalRowCount])

    const frameworkComponents = {
        autoCompleteEditor: GridComponents.AutoCompleteEditor,
        autoCompletePopupEditor: GridComponents.AutoCompletePopupEditor,
        numericEditor: GridComponents.NumericEditor,
        freeSoloAutoCompleteEditor: GridComponents.FreeSoloAutoCompleteEditor,
        allRFsCellRenderer:GridComponents.AllRFsActionRenderer,
        RfPoolRenderer:GridComponents.RfPoolRenderer,
        MainRFInputsActionRenderer:GridComponents.MainRFInputsActionRenderer,
        dateEditor:GridComponents.DateEditor,
        fullDateEditor:GridComponents.FullDateEditor,
        AllRfsNavigationRenderer: GridComponents.AllRfsNavigationRenderer
    };

    const handleCellEditingStopped = (params) => {
        return onCellEditingStopped();
    }

    const handleCellEditingStarted = (params) => {
        
        if(transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            return onRowEditingStarted(true, isOfTypeCopyOrDelete,transactions); 
        }
    }
 
    const onCellValueChanged = (params) => {
        params.data.row_validation_status="";
        let allowMassChange=true;
        if(gridApi && gridApi.getSelectedRows().length>MAX_ROWS_ALLOWED_FOR_MASS_CHANGE){
            // userMessage('error',`Please select up-to ${MAX_ROWS_ALLOWED_FOR_MASS_CHANGE} records only. Only 1 row was updated.`);
            allowMassChange=false;
        }
        if (params.oldValue !== params.newValue) {
            var updatedRowData = { ...params.data };
            params.data.isEdited = true;
            updatedRowData[params.column.colId] = params.newValue;
            let sortedData=[];
            let selectedRows = [];
            let isSelectedRowModified=false;
            if (gridApi && gridApi.getSelectedRows().length > 0) {
                selectedRows = gridApi.getSelectedRows();
                // console.log("selectedRows===",selectedRows.length);
                isSelectedRowModified=selectedRows.length>0 && selectedRows.length<=MAX_ROWS_ALLOWED_FOR_MASS_CHANGE && selectedRows.findIndex(aRow=>aRow.ID===updatedRowData.ID)>-1;
                if(isSelectedRowModified && allowMassChange){
                        selectedRows.forEach(rowData=>{
                            rowData[params.column.colId] = params.newValue;
                        });
                }
            }
            if(gridApi.sortController.getSortModel().length>0){
                 gridApi.forEachNodeAfterFilterAndSort((node, index)=>{
                    sortedData.push(node.data)
                 });  
                 columnApi.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
                setChangeCurrentData(true)  
            }
            if(isSelectedRowModified && allowMassChange){
                let rowIds=[];
                selectedRows.forEach(rowData=>{
                    // console.log(`Row data 2= ${JSON.stringify(rowData)}`);
                    // console.log(`Row data 2 ${rowData}`);
                    rowIds.push(rowData.ID);
                });
                
                // console.log("Firing completeEditMultipleRow===");
                dispatch(completeEditMultipleRow(gridName, updatedRowData, gridColDef.validationFn, { id: rowIds, value: params.colDef.field },gridColDef.businessValidationFn,sortedData));
            }else{
                dispatch(completeEditRow(gridName, updatedRowData, gridColDef.validationFn, { id: params.data.ID, value: params.colDef.field },gridColDef.businessValidationFn,sortedData));
            }
            //dispatch(completeEditRow(gridName, updatedRowData, gridColDef.validationFn, { id: params.data.ID, value: params.colDef.field },gridColDef.businessValidationFn,sortedData));
        }
    return onCellEditingStopped();  
 }


    useEffect(()=>{
    if(changeCurrentData){
        setNewCurrentData(currentData)
    }
    setNewCurrentData([])
    setChangeCurrentData(false)
    },[changeCurrentData])


    const handleRowEditMode = (params) => {
     
        // // Handle click event for action cells  
        let editingCells = params.api.getEditingCells();
        // checks if the rowIndex matches in at least one of the editing cells
        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });
        // gridApi.setImmutableData(true);
        if (isCurrentRowEditing == true)
            return onRowEditingStarted(isCurrentRowEditing,null,transactions);
    }

    const rowClassRules = function () {
        return {
            'errorCellBorder': (params) => {
                return (params.data.rowStatus === 'INVALID_ROW' || params.data.rowStatus === 'INVALID_NEW_ROW')
            },
            'errorRow': (params) => {
                return (params.data.rowStatus === 'ERROR_ROW' || params.data.rowStatus === 'ERROR_NEW_ROW' || params.data.rowStatus === 'ERROR_COPY_ROW' || (params.data.row_validation_status && params.data.row_validation_status.includes('D')))//use includes
            },
            'copyRow': (params) => {
                return (params.data.rowStatus === 'COPY_ROW')
            },

        }
    }

    useEffect(() => {
        if (gridApi !== null) {
            gridApi.ensureIndexVisible(0);
        }
        
    },[scrollToTop]);

    useEffect(() => {
        if(transactions && transactions.length > 0) {
            let isOfTypeCopyOrDelete = transactions.some((item) => {
                return (item.type == "copy" || item.type == "delete" || !item.data);
            });
            if(isOfTypeCopyOrDelete)
                return onRowEditingStarted(true, isOfTypeCopyOrDelete,transactions); 
        }
    },[transactions]);

    useEffect(() => {
        if (gridApi !== null) {
            gridApi.refreshCells();
            gridApi.redrawRows();
            var errorFound = false;
            currentData.forEach((element) => {
                if (element.rowStatus === "ERROR_ROW" || element.rowStatus === "INVALID_ROW" || element.rowStatus === "ERROR_COPY_ROW") {
                    if (!errorFound) {
                        let node = gridApi.getRowNode(element.ID);
                        // gridApi.ensureIndexVisible(node.rowIndex);
                        // gridApi.refreshCells();
                        errorFound = true;
                    }
                }
            });
        }
    }, [refreshCells]);

    useEffect(()=>{
        if(transactions.length>0 && transactions[transactions.length-1].type!==undefined && currentData.length>1 && transactions[transactions.length-1].type=='add' && transactions[transactions.length-1].data==undefined){  //if the latest action is Add then scroll
            if(gridApi.sortController.getSortModel().length>0 ){
                columnApi.applyColumnState({
                    defaultState: {
                        sort: null
                    }
                });
            }
            let node = gridApi.getRowNode(currentData[currentData.length-2].ID);
            if(node && node.rowIndex!==undefined){
                gridApi.ensureIndexVisible(currentData.length-2);
            }
        }
    },[currentData])

    const onSelectionChanged = () => {
        let selectedRows = [];
        if (gridApi && gridApi.getSelectedRows().length > 0) {
            selectedRows = gridApi.getSelectedRows();
        }
        if(handleMultipleRowDelete!==undefined){
            handleMultipleRowDelete(selectedRows);
        }
    }
    const gridCurrentData= useSelector((state)=>{
        return state.RfMultiGridUIReducer.currentData;
    })

    const refresh = () => {
        try {
            setChangeCurrentData(true)
            if (transactions && transactions.length === 0) {
                loadGridData(true) // true is sent as parameter to validate the data and also to fetch data for user page number
            }
            dispatch(validateGrid(gridName, currentData, gridColDef.validationFn, gridColDef.businessValidationFn))
        } catch (e) {
            console.error(e)
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Refreshing the tab have been failed, Please try again'))
        }
    }

    useEffect(()=>{
      
        if(gridColDef && gridColDef.defaultColDef && gridApi && 
            !gridColDef.defaultColDef.editable && gridApi.getEditingCells().length>0){

              
                gridApi.stopEditing();
        }
    },[gridColDef,gridApi]);

    const setpageNumber = (event, value) => {
        try {
            if (gridUserPageno != value) {//this will be true when user clicks on different page than his existing page
                dispatch(setGridUserPageNumber(value))
                loadGridData(false, value)
                //setPageNo(value);
            }
        } catch (e) {
            console.error(e)
            dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Changing the page number have been failed, Please try again'))
        }
    }

    return (
        <div id="grid-theme-wrapper" className="app-theme">
            {gridName!=='allrfsgrid'?
        <div style={{'backgroundColor':'white'}}>
            <Button onClick={refresh}><RefreshSharpIcon fontSize='large'/></Button>
            Selected rows={gridApi?gridApi.getSelectedRows().length:0},Pending Transactions={transactions?transactions.length:0}
        </div>:''
            }
            <div style={{ 
                width: '100%',
                height: '100%'
            }}
                className="ag-theme-alpine">
                {(currentData !== null) ? <AgGridReact
                    overlayNoRowsTemplate='No Records To Show'
                    propsTest={props}
                    reactNext={true}
                    onGridReady={onGridReady}
                    columnDefs={gridColDef.columnDefs}
                    defaultColDef={gridColDef.defaultColDef}
                    rowData={changeCurrentData?newCurrentData:currentData}
                    getRowNodeId={data => data.ID}
                    checkboxSelection={true}
                    headerCheckboxSelection={true}
                    enterMovesDownAfterEdit={false}
                    enterMovesDown={false}
                    frameworkComponents={frameworkComponents}
                    suppressHorizontalScroll={false}
                    rowSelection={'multiple'}
                    editType={rowEditType}
                    immutableData={true}
                    suppressPropertyNamesCheck={true}
                    onCellEditingStarted={handleCellEditingStarted}
                    onCellValueChanged={onCellValueChanged}
                    onRowClicked={handleRowEditMode}
                    onCellEditingStopped={handleCellEditingStopped}
                    rowClassRules={rowClassRules()}
                    gridName={gridName}
                    stopEditingWhenGridLosesFocus={false}
                    tooltipShowDelay={100}
                    tooltipMouseTrack={true}
                    suppressClickEdit={suppressclickEdit || hideActions}
                    suppressMaintainUnsortedOrder={false}
                     onSelectionChanged={onSelectionChanged}
                    suppressRowClickSelection={true}
                    applyColumnDefOrder={true}                  
                /> : <div></div>}
                {(gridName==='tncrevenuegrid' || gridName==='corerevenuegrid' ||isPaginationRequired)?
                    <Pagination 
                        disabled={transactions.length>0?true:false}
                        style={{marginLeft:'30%'}}
                        count={(pagesize<=0?1:pagesize)} 
                        page={gridUserPageno} 
                        showFirstButton 
                        showLastButton size="large"
                        onChange={setpageNumber}/>
                   :''}
            </div>
          
        </div>);
}
export default RevenueFunctionMultiGridUI;