import { FETCH_COREREVENUE_DROPDOWNVALUES_PENDING, FETCH_COREREVENUE_DROPDOWNVALUES_ERROR, FETCH_COREREVENUE_DROPDOWNVALUES_SUCCESS } from "../../RevenueFunction.ActionTypes";
import { columnDefs, defaultColDef } from "../core_revenue/core_revenue_grid_data/CoreRevenueColumnDefs";
import * as CONSTANTS from "../../../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues:[],
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputCoreColumns.Zone.field, data) },
            POOL: { error: checkIfValueEmpty(data.POOL, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputCoreColumns.Pool.field, data) },
            ONRENT_MONTH: { error: checkIfValueEmpty(data.ONRENT_MONTH, "On Rent Date") },
            CAR_CLASS: { error: checkIfValueEmpty(data.CAR_CLASS, CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputCoreColumns.Carclass.field, data) },
            PIECE_INDEX: { error: checkIfValueEmpty(data.PIECE_INDEX, "PIECE INDEX") },
            INC_FLEET: { error: checkIfValueEmpty(data.INC_FLEET, "INC FLEET") },
            INC_REV: { error: checkIfValueEmpty(data.INC_REV, "INC REV") },
            PER_UNIT: { error: checkIfValueEmpty(data.PER_UNIT, "PER UNIT") }
        }
    },
    businessValidationFn: function (currentData,transactions) {
        return currentData;
    }
}

function checkIfValueEmpty(value, fiedName) {
    if (value === undefined || value === "" || value === null) {
        return fiedName +" "+"cannot be empty";
    }
    return null;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}
function fetchDropdownsForPools(pools, element) {
    var poolData = [],poolFilterData = [];
    if(pools && pools.length>0){
    pools.forEach(val => {
        poolFilterData.push({"label":val.code,"value":val.code})
        if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        }
    });
    poolFilterData.push({"label":"Blanks","value":"Blanks"})
  }
    element.cellEditorParams = { options: poolData }
    element.filterOptions = poolFilterData;
}
function fetchDropdownsForCarClass(carClasses, element) {
    var carClassData = [],carClassFilterDropdownOptions=[]
    if(carClasses && carClasses.length>0){
        carClassData.push("ALL");
        carClassFilterDropdownOptions.push({"label":"ALL","value":"ALL"})
        carClasses.forEach(val => {
            carClassData.push(val);
            carClassFilterDropdownOptions.push({"label":val,"value":val})
        });
    }
    element.cellEditorParams = { options: carClassData }
    carClassFilterDropdownOptions.push({"label":"Blanks","value":"Blanks"})
    element.filterOptions = carClassFilterDropdownOptions;
}
function fetchDropdownsForZones(zones, element) {
    var poolData = [],zoneFilterData = [];
    if(zones && zones.length>0){
        zones.forEach(val => {
        zoneFilterData.push({"label":val.code,"value":val.code})
        if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        }
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"})
  }
    element.cellEditorParams = { options: poolData }
    element.filterOptions = zoneFilterData;
}
function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputCoreColumns.Pool.field) {
            fetchDropdownsForPools(data.pools, column);
        }else if(column.field === CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputCoreColumns.Carclass.field){
            fetchDropdownsForCarClass(data.carClasses, column);
        }else if(column.field === CONSTANTS.RFPlanRevenueInputColumns.RFPlanRevenueInputCoreColumns.Zone.field){
            fetchDropdownsForZones(data.zones, column);
        }
    });
    return columnDefs;
}

export default function RFCoreRevenuecolumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_COREREVENUE_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues: setColumnDefParams(state.columnDefs, [])
            });
        case FETCH_COREREVENUE_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_COREREVENUE_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}