import { columnDefs, defaultColDef } from "./grid_data/Columns";
import { FETCH_PROGRAM_DROPDOWN_PENDING, FETCH_PROGRAM_DROPDOWN_SUCCESS, FETCH_PROGRAM_DROPDOWN_ERROR, SET_PROGRAM_EDITABLE } from "./Programs.ActionTypes";
import { getDropdownValuesForYear, safeCaseInsensitiveCompare } from "../../utils/utils";
import { ProgramsColumns, maximumDate, minimumDate } from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkType: function (value) {
        return {
            NET_CAPITAL_COST: { type: checkTypeCurrency(value,ProgramsColumns.NetCapitalCost.field) },
            DEPRECIATION_COST: { type: checkTypeCurrency(value, ProgramsColumns.DepreciationCost.field) },
            INCENTIVE: { type: checkTypeCurrency(value, ProgramsColumns.Incentive.field) }
        }
    },
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            PROGRAM_YEAR: { error: checkIfValidYear(data.PROGRAM_YEAR, ProgramsColumns.ProgramYear.headerName) },
            MODEL_YEAR: { error: checkIfValidYear(data.MODEL_YEAR, ProgramsColumns.ModelYear.headerName) },
            MANUFACTURER_GROUP: { error: checkIfValueEmpty(data.MANUFACTURER_GROUP, ProgramsColumns.ManufacturerGroup.headerName,data.MODEL_GROUP) },
            SPI_CODE: { error: checkIfValueEmpty(data.SPI_CODE, ProgramsColumns.SpiCode.headerName) },
            PROGRAM_TYPE: { error: checkIfValueEmpty(data.PROGRAM_TYPE, ProgramsColumns.ProgramType.headerName) },
            MODEL_GROUP: { error: checkIfValueEmpty(data.MODEL_GROUP, ProgramsColumns.ModelGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===ProgramsColumns.ModelGroup.field)) },
            CAR_CLASS_CODE: { error: checkIfValueEmpty(data.CAR_CLASS_CODE, ProgramsColumns.CarClassCode.headerName,initialState.columnDefs.findIndex(itm=>itm.field===ProgramsColumns.CarClassCode.field)) },
            DEPRECIATION_COST: { error: checkIfValueEmpty(data.DEPRECIATION_COST, ProgramsColumns.DepreciationCost.headerName) },
            NET_CAPITAL_COST: { error: checkIfValueEmpty(data.NET_CAPITAL_COST, ProgramsColumns.NetCapitalCost.headerName) },
            START_MILEAGE: { error: null },
            INCENTIVE: { error: null },
            MIN_MILEAGE: { error: validateMinMaxFields(data.MIN_MILEAGE, data.MAX_MILEAGE, ProgramsColumns.MinMileage.headerName,ProgramsColumns.MaxMileage.headerName) },
            MAX_MILEAGE: { error: validateMinMaxFields(data.MIN_MILEAGE, data.MAX_MILEAGE, ProgramsColumns.MinMileage.headerName,ProgramsColumns.MaxMileage.headerName) },
            MIN_AGE: { error: validateMinMaxFields(data.MIN_AGE, data.MAX_AGE, ProgramsColumns.MinAge.headerName,ProgramsColumns.MaxAge.headerName) },
            MAX_AGE: { error: validateMinMaxFields(data.MIN_AGE, data.MAX_AGE, ProgramsColumns.MinAge.headerName,ProgramsColumns.MaxAge.headerName) },
            ADD_VOLUME_MIN: { error: validateMinMaxFields(data.ADD_VOLUME_MIN, data.ADD_VOLUME_MAX,ProgramsColumns.AddVolumeMin.headerName,ProgramsColumns.AddVolumeMax.headerName) },
            ADD_VOLUME_MAX: { error: validateMinMaxFields(data.ADD_VOLUME_MIN, data.ADD_VOLUME_MAX,ProgramsColumns.AddVolumeMin.headerName,ProgramsColumns.AddVolumeMax.headerName) },
            BEGIN_DATE: { error: checkIfDateIsValid(data.BEGIN_DATE, data.END_DATE,ProgramsColumns.BeginDate.headerName,ProgramsColumns.EndDate.headerName) },
            END_DATE: { error: checkIfDateIsValid(data.BEGIN_DATE, data.END_DATE,ProgramsColumns.BeginDate.headerName,ProgramsColumns.EndDate.headerName) },
            BUY_STATUS: { error: checkIfBuyStatusMatches(data.BUY_STATUS, ProgramsColumns.BuyStatus.headerName) },
            ACCEPTABLE_ZONES: { error: checkIfValueEmpty(data.ACCEPTABLE_ZONES, ProgramsColumns.AcceptableZones.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function checkIfBuyStatusMatches(value, name) {
    let error = null;
    initialState.columnDefs.map((item, index) => {
        if (item.field == ProgramsColumns.BuyStatus.field) {
            if (item.cellEditorParams !== undefined) {
                if (item.cellEditorParams.options.indexOf(value) == -1) {
                    error = 'Invalid' + ' ' + name;
                    return error;
                } else {
                    error = null;
                    return error;
                }
            }
        }
    })
    return error;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" && value !== "ADD_VOLUME_MAX" && value !== "id" && value !== "constraint_table" && value !== 'plan_id' && value !== 'zone_code' && value !== 'BEGIN_DATE' && value !== 'END_DATE' && value !== 'updated_by' && value != 'updated_by_name') {
        return true;
    } else {
        return false;
    }
}

function checkTypeCurrency(value1, value2) {
    if (value1 == value2) {
        return 'currency';
    }
}

function checkTypePercentage(value1, value2) {
    if (value1 == value2) {
        return 'percentage';
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.SPI_CODE, itm2.SPI_CODE) && safeCaseInsensitiveCompare(itm.CAR_CLASS_CODE, itm2.CAR_CLASS_CODE) &&
            safeCaseInsensitiveCompare(itm.MODEL_GROUP, itm2.MODEL_GROUP) && safeCaseInsensitiveCompare(itm.PROGRAM_TYPE, itm2.PROGRAM_TYPE) &&
                itm.MODEL_YEAR === itm2.MODEL_YEAR) {
                unique = false;
                if(itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if(itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function checkIfValueEmpty(value, name,data) {
    if(name===ProgramsColumns.CarClassCode.headerName || name===ProgramsColumns.ModelGroup.headerName){
        if (value === undefined || value === "" || value === null) {
            return name +" "+"cannot be empty";
        }else
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
                if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                    return name +" "+value+" no-longer available";
                }
            }
    }
     if(name===ProgramsColumns.ManufacturerGroup.headerName){
       if(!data){
             return "Please select a Model group"; //First Model group" should be selected before selecting a manufacturer group
          }else if (value === undefined || value === "" || value === null) {
             return name +" "+"cannot be empty";
          }
          else{
            let message=null;
            let allManufacturergroupModelgroup =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
            let trimmedModalGroup=data.trim();
            let options=allManufacturergroupModelgroup[trimmedModalGroup]?allManufacturergroupModelgroup[trimmedModalGroup]:[];
            
            if(options.indexOf(value)==-1){
                message = name+" "+'does not belong to the Model group';
            }
            return message;
        }
    }
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    return null;
}

function checkIfValidYear(value, name) {
    if (value === undefined || value === '' || value === null)
        return name + " cannot be empty";
    else {
        var itemValue = parseInt(value, 0);
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10))
            return name + " should be in +5 or -10 range from the current year"
    }
    return null;
}

function checkIfDateIsValid(min, max, minDisplayName, maxDisplayName) {
    // var itemValue1 = parseInt(min,0);
    // var itemValue2 = parseInt(max,0);
    // if ((itemValue1 > (new Date().getFullYear() + 5) || itemValue1 < (new Date().getFullYear() - 10)) || (itemValue2 > (new Date().getFullYear() + 5) || itemValue2 < (new Date().getFullYear() - 10))){
    //     return minDisplayName +" date" +" and " +  maxDisplayName +  " date should be in +5 or -10 range from the current year"
    // }
    // var d1 = new Date(min);
    // var d2 = new Date(max);
    // if (((d1.getMonth() <= d2.getMonth()) && (d1.getFullYear() > d2.getFullYear())) || ((d1.getMonth() > d2.getMonth()) && (d1.getFullYear() >= d2.getFullYear()))) {
    //     return minDisplayName +" date should be less than "+ maxDisplayName+" date";
    // } else
    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Fields cannot be empty';
    }
    else if(min == 'Invalid date' || max == 'Invalid date'){
        return minDisplayName +" date" +" and " +  maxDisplayName + ' date cannot be invalid date';
    }
    else if (!moment(min).isBetween(minimumDate, maximumDate,'year','[]') || (!moment(max).isBetween(minimumDate, maximumDate,'year','[]'))){
        return minDisplayName +" date" +" and " +  maxDisplayName +  " date should be in +5 or -10 range from the current year"
    }
    else if(moment(min).isAfter(moment(max),'month')){
       return minDisplayName +" date should be less than "+ maxDisplayName+" date";
    }
        return null;
}

function validateMinMaxFields(min, max, minDisplayName,maxDisplayName) {

    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return 'Fields cannot be empty';
    }
    if (parseInt(min) > parseInt(max)) {
        return  minDisplayName + ' should be less than ' + maxDisplayName
    }
    return null;
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === ProgramsColumns.ProgramYear.field) {
            column.filterOptions = getDropdownValuesForYear() // this function is used to get dropdown values for year columns from current year (-10 years to +5 years)
        }
        if( column.field===ProgramsColumns.ModelYear.field){
            column.filterOptions = getDropdownValuesForYear() // this function is used to get dropdown values for year columns from current year (-10 years to +5 years)
        }
        if (column.field === ProgramsColumns.ProgramType.field) {
            let programTypeFilterData = [];
            data.programType.map((val) => {
                programTypeFilterData.push({ "label": val, "value": val })
            })
            programTypeFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.programType }
            column.filterOptions = programTypeFilterData
        }
        if (column.field === ProgramsColumns.SpiCode.field) {
            let spiCodeFilterData = [];
            data.spiCode.map((val) => {
                spiCodeFilterData.push({ "label": val, "value": val })
            }) 
            spiCodeFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.spiCode }
            column.filterOptions = spiCodeFilterData;
        }
        if (column.field === ProgramsColumns.ModelGroup.field) {
            let modelGroupFilterData = [];
            data.adminModelGroup.map((val) => {
                modelGroupFilterData.push({ "label": val, "value": val })
            })
            modelGroupFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.modelGroup }
            column.filterOptions = modelGroupFilterData;
        }
        if (column.field === ProgramsColumns.CarClassCode.field) {
            let carClassCodeFilterData = []
            data.adminCarClass.map((val) => {
                carClassCodeFilterData.push({ "label": val.car_class, "value": val.car_class })
            })
            carClassCodeFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.carClassCode }
            column.filterOptions = carClassCodeFilterData;
        }
        if (column.field === ProgramsColumns.AcceptableZones.field) {
            let zonesFilterData = [];
            data.zone.map((val) => {
                zonesFilterData.push({ "label": val, "value": val })
            })
            zonesFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.zone }
            column.filterOptions = zonesFilterData;
        }
        if (column.field === ProgramsColumns.BuyStatus.field) {
            let buyStatusFilterData = [];
            data.buyStatus.map((val) => {
                buyStatusFilterData.push({ "label": val, "value": val })
            })
            buyStatusFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.buyStatus }
            column.filterOptions = buyStatusFilterData;
        }
        if(column.field === ProgramsColumns.ManufacturerGroup.field){
            let manufacturerFilterData = [];
            data.adminManufacturerGroup.map((val) => {
                manufacturerFilterData.push({ "label": val.code, "value": val.code })
            })
            manufacturerFilterData.push({"label":"Blanks","value":"Blanks"})
            column.filterOptions = manufacturerFilterData;
        }
        if(column.field === ProgramsColumns.UpdatedByName.field){
            let userKeys = Object.keys(data.users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": data.users[userData], "value": data.users[userData] })
            })
            column.filterOptions = usersFilterData
        }
        if (column.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                column.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                column.cellRenderer = 'actionsRenderer'
            }
        }
    });
    //console.log("Fetch the column degs " + JSON.stringify(columnDefs))
    return columnDefs;
}

export default function columnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PROGRAM_DROPDOWN_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_PROGRAM_DROPDOWN_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                columnDefs: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_PROGRAM_DROPDOWN_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_PROGRAM_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,'editable':action.data}
            }); 
        default:
            return state;
    }
}