import {
    dateFormatter,
    currencyFormatter,
    valueFormatter,
    localeFormatter,
    updatedOnDateFormatter,
  } from "../../../utils/formatters/valueFormatter";
  import {AddsInvalidPoolsColumns, cellEditors} from "../../../Constants";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";
  
  export const columnDefs = [
    {
      headerName: "",
      field: "",
      minWidth: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      editable: false,
      sortable: false,
      resizable: false,
      suppressColumnsToolPanel: true,
      flex: 0,
    },
    {
        field: AddsInvalidPoolsColumns.ManufacturerGroup.field, 
        headerName: AddsInvalidPoolsColumns.ManufacturerGroup.headerName, 
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AddsInvalidPoolsColumns.ManufacturerGroup.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AddsInvalidPoolsColumns.ManufacturerGroup.name)
        }
    },
    {
        field: AddsInvalidPoolsColumns.Pool.field, 
        headerName: AddsInvalidPoolsColumns.Pool.headerName,
        cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,
        filterType:"dropdown",
        filterOptions:[],
        cellClassRules: cellClassRules(AddsInvalidPoolsColumns.Pool.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, AddsInvalidPoolsColumns.Pool.name)
        }
    },
    {
      field: AddsInvalidPoolsColumns.UpdatedBy.field, 
      headerName: AddsInvalidPoolsColumns.UpdatedBy.headerName, 
      editable: false, unSortIcon: true,filterOptions:[],
      cellClassRules: cellClassRules(AddsInvalidPoolsColumns.UpdatedBy.name), 
      valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
      comparator: customUpdatedByComparator,
      tooltipValueGetter: params => {
          return toolTipUI(params, AddsInvalidPoolsColumns.UpdatedBy.name)
      }
    },
    {
      field: AddsInvalidPoolsColumns.UpdatedOn.field, 
      headerName: AddsInvalidPoolsColumns.UpdatedOn.headerName, 
      editable: false,
      valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
      unSortIcon: true, cellClassRules: cellClassRules(AddsInvalidPoolsColumns.UpdatedOn.name), 
      comparator: customDateComparator,
      tooltipValueGetter: params => {
          return toolTipUI(params, AddsInvalidPoolsColumns.UpdatedOn.name)
      }
    },
    {
      headerName: "",
      colId: "actions",
      cellRenderer: "AddsInvalidPoolsActionRenderer",
      editable: false,
      filter: false,
      sortable: false,
      minWidth: 120,
      pinned: "right",
    },
  ];
  
  function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field]){
    if(params.data.validations && params.data.validations[field].error !== null)
    {
      return params.data.validations[field].error;
    }
    if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
      return "Duplicate row";
    }
  }
}
  function cellClassRules(field) {
    return {
      'errorCell': (params) => {
        if (params.data.validations && params.data.validations[field]) {
          return params.data.validations[field].error !== null;
        }
      },
      'validRow': (params) => {
        if (params.data.validations && params.data.colDef) {
          var index = params.data.colDef.findIndex(function (item, i) {
            return item.id === params.data.ID && item.value === field;
          });
          return index > -1 && params.data.validations[field].error === null;
        }
      },
    };
  }
  export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
  };
  