import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import NttTab from "../../components/tabs/NttTab"
import NttTabs from "../../components/tabs/NttTabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import './MetadataTabPanel.css';
import { useDispatch, useSelector } from "react-redux";
import MetaDataActionBar from '../../components/admin/metadataactionbar/MetaDataActionBar';
import { cleanTransactions, deleteRow, deleteRowEditMode, refreshGridData, validateGrid } from '../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions';
import CarClassGroupTabPanel from './carclassgroup/CarClassGroupTabPanel';
import { fetchCarClassGroupData, fetchCarClassGroupDataSearchData, fetchCarClassGroupDropdownValues, saveCarClassGroupTransactionData, saveCarGroupTransactionsData} from '../../store/admin/metadata_carclassgroup/CarClassGroup.Actions';
import ModelMfrOemTabPanel from './modelmfroem/ModelMfrOemTabPanel';
import { fetchModelMfrOemData,fetchModelMfrOemDataSearchData,saveManufacturerModelGroupTransactionsData,saveModelMfrOemTransactionsData} from '../../store/admin/metadata_modelmfroem/ModelMfrOem.Actions';
import {ADMINPOOLSGRID, MODELMFROEM, ZONEMAPGRID} from '../../../src/Constants';
import { fetchAdminPoolData, savePoolTransactionsData } from '../../store/admin/metadata_pool_management/PoolManagement.Actions';
import PoolsTabPanel from './pools/PoolsTabPanel';
import ZoneMapTabPanel from './zonemap/ZoneMapTabPanel';
import { fetchZoneMapData, saveZoneMapTransactionData } from '../../store/zonemap/ZoneMap.Actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={'simple-tabpanel-${index}'}
            aria-labelledby={'simple-tab-${index}'}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default function MetadataTabPanel(props) {
    const {handleAddNewRow, showSaveOption, refreshCells, handleRowEditingStarted, handleRowEditingStopped, handleSaveCancelDisabled, handleCellEditingStopped,
        selectedPlanId, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows, downloadCSV, pending, userMessage, fetchValidTransactions, fetchInvalidTransactions, 
        isSaveClicked, setIsSaveClicked, setSnackBarOpen, setShowSaveOption, setSnackBarIfError, setRefreshCells, setIsCancelClicked, snackBarIfError, setWarningSnackBarOpen}=props;

    const dispatch = useDispatch();

    const [value, setValue] = useState(0);
    const [metaDataGrid,setmetaDataGrid] = useState('');
    const [metaDataTabData,setmetaDataTabData]=useState(null);
    const [metaDataGridColDef,setmetaDataGridColDef]=useState(null);


    const tabStyle = {
        default_tab: {
            borderRadius: "4px",
            color: "#000000",
            background: "#ffff",
            fontSize: 14,
            textTransform: 'none',
        },
        active_tab: {
            background: "#ffed9b",
            borderColor: "#ffff",
            borderRadius: "4px",
            color: "#000000",
            fontSize: 14,
            textTransform: 'none'
        },
        tabItemContainer: {
            margin: "50px",
            display: "flex",
            flexWrap: "wrap",
        }
    };

    const getStyle = (isActive) => { 
              return isActive ? tabStyle.active_tab : tabStyle.default_tab        
      }

    const tabsData =[
        { tabTitle: "CAR CLASS GROUP", tabKey: 0},
        { tabTitle: "MODEL MFR OEM", tabKey: 1},
        { tabTitle: "POOLS", tabKey: 2},
        { tabTitle: "ZONE MAP", tabKey: 3},
    ]

    //CAR CLASS GROUP redux state 
      const carClassGroupGridReducer = useSelector((state) => {
        return { ...state.rootCarClassGroupReducer.carClassGroupGridReducer };
      });
    
      const carClassGroupGridColDef = useSelector((state) => {
        return state.rootCarClassGroupReducer.carClassGroupColumnDefinitionReducer;
      });
    
      const carClassGroupData = useSelector((state) => {
        return (state.rootCarClassGroupReducer.CarClassGroupReducer.carClassGroupData===undefined?[]:state.rootCarClassGroupReducer.CarClassGroupReducer.carClassGroupData);
      });
      const carClassGroupRowDataInvalid = useSelector((state) => {
        return state.rootCarClassGroupReducer.carClassGroupGridReducer.isAnyRowInvalid;
      });
      const carClassGroupTransactionSaveSuccessful = useSelector((state) => {
        return state.rootCarClassGroupReducer.CarClassGroupReducer.transactionSaveSuccess;
      });

    //MODEL MFR OEM redux state 
    const modelMfrOemGridReducer = useSelector((state) => {
      return { ...state.rootModelMfrOemReducer.modelMfrOemGridReducer };
    });
  
    const modelMfrOemGridColDef = useSelector((state) => {
      return state.rootModelMfrOemReducer.modelMfrOemColumnDefinitionReducer;
    });
  
    const modelMfrOemData = useSelector((state) => {
      return (state.rootModelMfrOemReducer.ModelMfrOemReducer.modelMfrOemData===undefined?[]:state.rootModelMfrOemReducer.ModelMfrOemReducer.modelMfrOemData);
    });
    const modelMfrOemRowDataInvalid = useSelector((state) => {
      return state.rootModelMfrOemReducer.modelMfrOemGridReducer.isAnyRowInvalid;
    });
    const modelMfrOemTransactionSaveSuccessful = useSelector((state) => {
      return state.rootModelMfrOemReducer.ModelMfrOemReducer.transactionSaveSuccess;
    });    

    //POOLS MANAGEMENT REDUX STATE
    const poolsGridReducer = useSelector((state) => {
      return { ...state.rootPoolManagementReducer.PoolsManagementGridReducer };
    });
  
    const poolsGridColDef = useSelector((state) => {
      return state.rootPoolManagementReducer.PoolsManagementColumnDefinitionReducer;
    });
  
    const poolsData = useSelector((state) => {
      return (state.rootPoolManagementReducer.PoolsManagementReducer.adminPoolsData===undefined?[]:state.rootPoolManagementReducer.PoolsManagementReducer.adminPoolsData);
    });
    const poolsRowDataInvalid = useSelector((state) => {
      return state.rootPoolManagementReducer.PoolsManagementGridReducer.isAnyRowInvalid;
    });
    const poolsTransactionSaveSuccessful = useSelector((state) => {
      return state.rootPoolManagementReducer.PoolsManagementReducer.transactionSaveSuccess;
    }); 

    const carClassGroupFilterData = useSelector((state) => {
        return {
            filterParameters: state.rootCarClassGroupReducer.CarClassGroupReducer.filterParameters,
            filterView: state.rootCarClassGroupReducer.CarClassGroupReducer.filterView,
            searchView: state.rootCarClassGroupReducer.CarClassGroupReducer.searchView,
            deleteFilter: state.rootCarClassGroupReducer.CarClassGroupReducer.deleteFilter,
            filterPopupOpen: state.rootCarClassGroupReducer.CarClassGroupReducer.filterPopupOpen,
        };
    });

    const modelMfrOemFilterData = useSelector((state) => {
        return {
            filterParameters: {...state.rootModelMfrOemReducer.ModelMfrOemReducer.filterParameters},
            filterView: state.rootModelMfrOemReducer.ModelMfrOemReducer.filterView,
            searchView: state.rootModelMfrOemReducer.ModelMfrOemReducer.searchView,
            deleteFilter: state.rootModelMfrOemReducer.ModelMfrOemReducer.deleteFilter,
            filterPopupOpen: state.rootModelMfrOemReducer.ModelMfrOemReducer.filterPopupOpen,
        };
    });

    const zoneMapGridReducer = useSelector((state) => {
      // console.log("zoneMapGridReducer==>",{ ...state.rootZoneMapReducer.zoneMapGridReducer });
      return { ...state.rootZoneMapReducer.zoneMapGridReducer };
    });
  
    const zoneMapGridColDef = useSelector((state) => {
      // console.log("zoneMapGridColDef==>",state.rootZoneMapReducer.zoneMapColumnDefinitionReducer);
      return state.rootZoneMapReducer.zoneMapColumnDefinitionReducer;
    });

    const zoneMapData = useSelector((state) => {
      // console.log("zoneMapData==>",state.rootZoneMapReducer.ZoneMapReducer.zoneMapData);
      return state.rootZoneMapReducer.ZoneMapReducer.zoneMapData;
    });
    
    const zoneMapUploadSuccess = useSelector((state) => {
      // console.log("zoneMapUploadSuccess==>",state.rootZoneMapReducer.ZoneMapReducer.uploadZoneMapSuccess);
      return state.rootZoneMapReducer.ZoneMapReducer.uploadZoneMapSuccess;
    });
  
    const zoneMapRowDataInvalid = useSelector((state) => {
      // console.log("zoneMapRowDataInvalid==>",state.rootZoneMapReducer.zoneMapGridReducer.isAnyRowInvalid);
      return state.rootZoneMapReducer.zoneMapGridReducer.isAnyRowInvalid;
    });
  
    const zoneMapTransactionSaveSuccessful = useSelector((state) => {
      // console.log("zoneMapTransactionSaveSuccessful==>",state.rootZoneMapReducer.ZoneMapReducer.transactionSaveSuccess);
      return state.rootZoneMapReducer.ZoneMapReducer.transactionSaveSuccess;
    });

    const handleSaveClicked = (gridName) => {
        // console.log("gridName===>",gridName);
        setSnackBarIfError(!snackBarIfError);
        if (gridName === 'carclassgroupgrid') {
        setIsSaveClicked('carclassgroupgrid');
        dispatch(validateGrid("carclassgroupgrid", carClassGroupGridReducer.currentData, carClassGroupGridColDef.validationFn, carClassGroupGridColDef.businessValidationFn));
        }
        else if (gridName === MODELMFROEM) {
          setIsSaveClicked(MODELMFROEM);
          dispatch(validateGrid("modelmfroemgrid", modelMfrOemGridReducer.currentData, modelMfrOemGridColDef.validationFn, modelMfrOemGridColDef.businessValidationFn));
          }
        else if (gridName === ADMINPOOLSGRID) {
          setIsSaveClicked(ADMINPOOLSGRID);
          dispatch(validateGrid(ADMINPOOLSGRID, poolsGridReducer.currentData, poolsGridColDef.validationFn, poolsGridColDef.businessValidationFn));
          }
        else if (gridName === ZONEMAPGRID) {
            setIsSaveClicked(ZONEMAPGRID);
            dispatch(validateGrid(ZONEMAPGRID, zoneMapGridReducer.currentData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));
        }
    }

    const deleteSelectedRows = (gridName) => {
      let transactions = [];
      let ID = '';
      if (gridName == ZONEMAPGRID) {
        transactions = zoneMapGridReducer.transactions;
      }
      if (transactions.length > 0) {
        transactions.map((i) => {
          if (i.type == 'edit') {
            dispatch(deleteRowEditMode(gridName, i.data.ID))
          } else
            if (selectedRows.length > 0) { //in case of copy row if we select both the rows we won't get both in transactions.
              selectedRows.map((i) => {
                dispatch(deleteRow(gridName, i.ID));
              })
            }
        })
      } else if (selectedRows.length > 0) {
        selectedRows.map((i) => {
          dispatch(deleteRow(gridName, i.ID));
        })
      }
    }

    const handleCancelClicked = (gridName) => {
        if (gridName === 'carclassgroupgrid') {
        dispatch(cleanTransactions('carclassgroupgrid'));
        dispatch(refreshGridData('carclassgroupgrid'));
        dispatch(fetchCarClassGroupData(carClassGroupFilterData.filterParameters,carClassGroupFilterData.deleteFilter));
        }
        else if (gridName === MODELMFROEM) {
          dispatch(cleanTransactions(MODELMFROEM));
          dispatch(refreshGridData(MODELMFROEM));
          dispatch(fetchModelMfrOemData(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,modelMfrOemFilterData.searchView));
          }
        else if (gridName === ADMINPOOLSGRID) {
          dispatch(cleanTransactions(ADMINPOOLSGRID));
          dispatch(refreshGridData(ADMINPOOLSGRID));
          dispatch(fetchAdminPoolData());
          }
        else if (gridName === ZONEMAPGRID) {
          dispatch(cleanTransactions(ZONEMAPGRID));
          dispatch(refreshGridData(ZONEMAPGRID));
          dispatch(fetchZoneMapData());
          }
      setRefreshCells(!refreshCells);
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setIsCancelClicked(true);
      dispatch(validateGrid("carclassgroupgrid", carClassGroupData, carClassGroupGridColDef.validationFn, carClassGroupGridColDef.businessValidationFn));
      dispatch(validateGrid("modelmfroemgrid", modelMfrOemData, modelMfrOemGridColDef.validationFn, modelMfrOemGridColDef.businessValidationFn));
      dispatch(validateGrid(ADMINPOOLSGRID, poolsData, poolsGridColDef.validationFn, poolsGridColDef.businessValidationFn));
      dispatch(validateGrid(ZONEMAPGRID, zoneMapData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));
    }

    const handleChangeTab = (e, index) => {
        setValue(index)
        if(index==0){
        dispatch(fetchCarClassGroupData(carClassGroupFilterData.filterParameters,carClassGroupFilterData.deleteFilter));
        setmetaDataGrid('carclassgroupgrid')
        setmetaDataTabData(carClassGroupData)
        setmetaDataGridColDef(carClassGroupGridColDef)
        }
        else if(index==1){
          dispatch(fetchModelMfrOemData(modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,modelMfrOemFilterData.searchView));
          setmetaDataGrid(MODELMFROEM)
          setmetaDataTabData(modelMfrOemData)
          setmetaDataGridColDef(modelMfrOemGridColDef)
        }
        else if(index==2){
          dispatch(fetchAdminPoolData());
          setmetaDataGrid(ADMINPOOLSGRID)
          setmetaDataTabData(poolsData)
          setmetaDataGridColDef(poolsGridColDef)
          }
        else if(index==3){
          dispatch(fetchZoneMapData());
          setmetaDataGrid(ZONEMAPGRID)
          setmetaDataTabData(zoneMapData)
          setmetaDataGridColDef(zoneMapGridColDef)
          }
    }

    const handleAddNewDropdownValue=(transactionList,gridname)=>{
      if(metaDataGrid==='carclassgroupgrid'){
        dispatch(saveCarGroupTransactionsData(transactionList));
        setRefreshCells(!refreshCells);
        dispatch(cleanTransactions('carclassgroupgrid'));
        dispatch(refreshGridData('carclassgroupgrid'));

      }
      else if(metaDataGrid===MODELMFROEM){
        dispatch(saveManufacturerModelGroupTransactionsData(transactionList,userMessage,modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,modelMfrOemFilterData.searchView));
        setRefreshCells(!refreshCells);
        dispatch(cleanTransactions(MODELMFROEM));
        dispatch(refreshGridData(MODELMFROEM));

      }else if(metaDataGrid===ZONEMAPGRID){
        // console.log("transactionList==>",transactionList);
        dispatch(saveZoneMapTransactionData(transactionList,userMessage));//The Plan ID is now dummy to avoid sweeping changes
        setRefreshCells(!refreshCells);
        dispatch(cleanTransactions(ZONEMAPGRID));
        dispatch(refreshGridData(ZONEMAPGRID));

      }else{
        console.log("Incorrect GRID : ", metaDataGrid);
      }
    }

    useEffect(()=>{
        setSnackBarOpen(false);
        setWarningSnackBarOpen(false)
        dispatch(fetchCarClassGroupData(carClassGroupFilterData.filterParameters,carClassGroupFilterData.deleteFilter));
      },[])


      useEffect(()=>{
        if( carClassGroupGridReducer.transactions.length>0 || modelMfrOemGridReducer.transactions.length>0 || poolsGridReducer.transactions.length>0 || zoneMapGridReducer.transactions.length>0){
          setShowSaveOption(true);
        }else{
          setShowSaveOption(false);
          }
      },[carClassGroupGridReducer.transactions,modelMfrOemGridReducer.transactions,poolsGridReducer.transactions,zoneMapGridReducer.transactions])




      // useEffect(() => {
      //   if (isSaveClicked == "carclassgroupgrid") {    
      //     dispatch(saveCarClassGroupTransactionData(carClassGroupGridReducer.transactions));
      //     setShowSaveOption(false);
      //     setIsSaveClicked(null);
      //     setRefreshCells(!refreshCells);
      //     userMessage('success', 'Successfully saved rows')
      //     dispatch(cleanTransactions('carclassgroupgrid'));
      //     dispatch(refreshGridData('carclassgroupgrid'));
      // }
      // }, [isSaveClicked]);       

    //CAR CLASS GROUP
    useEffect(() => {
        if (carClassGroupData) {
          setmetaDataGrid('carclassgroupgrid')
          setmetaDataTabData(carClassGroupData)
          setmetaDataGridColDef(carClassGroupGridColDef)
          }
      }, [carClassGroupData]);

    useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(carClassGroupGridReducer.transactions);
        const filterInValidTransactions = fetchInvalidTransactions(carClassGroupGridReducer.transactions);

        if ((!carClassGroupGridReducer.transactions || carClassGroupGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "carclassgroupgrid")) {
            setSnackBarOpen(false);
            userMessage('warning', 'Nothing to be saved');
            setShowSaveOption(false);
            setIsSaveClicked(null);
        }
    
        if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "carclassgroupgrid")) {
            if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
          dispatch(saveCarClassGroupTransactionData(filterValidTransactions));
            }
            else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
            userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
            }
        }
    
        if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "carclassgroupgrid")) {
            setSnackBarOpen(true);
        }
    }, [carClassGroupRowDataInvalid, isSaveClicked, snackBarIfError]);

    useEffect(() => {
        const filterValidTransactions = fetchValidTransactions(carClassGroupGridReducer.transactions);
        if (carClassGroupTransactionSaveSuccessful) {
        setShowSaveOption(false);
        setIsSaveClicked(null);
        setRefreshCells(!refreshCells);
    
        if (filterValidTransactions && filterValidTransactions.length > 0) {
            if (carClassGroupRowDataInvalid) {
            userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
            } else {
            userMessage('success', 'Successfully saved rows')
            }
        }
        dispatch(cleanTransactions('carclassgroupgrid'));
        dispatch(refreshGridData('carclassgroupgrid'));
        dispatch(validateGrid("carclassgroupgrid", carClassGroupGridReducer.currentData, carClassGroupGridColDef.validationFn, carClassGroupGridColDef.businessValidationFn));
        }
        // else if (carClassGroupTransactionSaveSuccessful === false) {
        //   console.log("Failed to save carClassGroup...");
        // alert('Unable to save transactions. Please try again.');
        // }
    }, [carClassGroupTransactionSaveSuccessful]);

//Model MfrOem
useEffect(() => {
  if (modelMfrOemData) {
    setmetaDataGrid(MODELMFROEM)
    setmetaDataTabData(modelMfrOemData)
    setmetaDataGridColDef(modelMfrOemGridColDef)
    }
}, [modelMfrOemData]);

useEffect(() => {
  const filterValidTransactions = fetchValidTransactions(modelMfrOemGridReducer.transactions);
  const filterInValidTransactions = fetchInvalidTransactions(modelMfrOemGridReducer.transactions);

  if ((!modelMfrOemGridReducer.transactions || modelMfrOemGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == "modelmfroemgrid")) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
  }

  if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == "modelmfroemgrid")) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
          dispatch(saveModelMfrOemTransactionsData(filterValidTransactions,modelMfrOemFilterData.filterParameters,modelMfrOemFilterData.deleteFilter,modelMfrOemFilterData.searchView));
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
          userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
  }

  if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == "modelmfroemgrid")) {
      setSnackBarOpen(true);
  }
}, [modelMfrOemRowDataInvalid, isSaveClicked, snackBarIfError]);

useEffect(() => {
  const filterValidTransactions = fetchValidTransactions(modelMfrOemGridReducer.transactions);
  if (modelMfrOemTransactionSaveSuccessful) {
  setShowSaveOption(false);
  setIsSaveClicked(null);
  setRefreshCells(!refreshCells);

  if (filterValidTransactions && filterValidTransactions.length > 0) {
      if (modelMfrOemRowDataInvalid) {
      userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
      } else {
      userMessage('success', 'Successfully saved rows')
      }
  }
  dispatch(cleanTransactions(MODELMFROEM));
  dispatch(refreshGridData(MODELMFROEM));
  dispatch(validateGrid("modelmfroemgrid", modelMfrOemGridReducer.currentData, modelMfrOemGridColDef.validationFn, modelMfrOemGridColDef.businessValidationFn));
  }
  // else if (modelMfrOemTransactionSaveSuccessful === false) {
  //   console.log("Failed to save modelMfrOem...");
  // alert('Unable to save transactions. Please try again.');
  // }
}, [modelMfrOemTransactionSaveSuccessful]);

//POOLS MANAGEMENT
useEffect(() => {
  if (poolsData) {
    dispatch(validateGrid(ADMINPOOLSGRID, poolsData, poolsGridColDef.validationFn, poolsGridColDef.businessValidationFn));
    setmetaDataGrid(ADMINPOOLSGRID)
    setmetaDataTabData(poolsData)
    setmetaDataGridColDef(poolsGridColDef)
    }
}, [poolsData]);

useEffect(() => {
  const filterValidTransactions = fetchValidTransactions(poolsGridReducer.transactions);
  const filterInValidTransactions = fetchInvalidTransactions(poolsGridReducer.transactions);

  if ((!poolsGridReducer.transactions || poolsGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == ADMINPOOLSGRID)) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
  }

  if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == ADMINPOOLSGRID)) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
    dispatch(savePoolTransactionsData(filterValidTransactions));
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
      userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
  }

  if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0 && isSaveClicked == ADMINPOOLSGRID)) {
      setSnackBarOpen(true);
  }
}, [poolsRowDataInvalid, isSaveClicked, snackBarIfError]);

useEffect(() => {
  const filterValidTransactions = fetchValidTransactions(poolsGridReducer.transactions);
  if (poolsTransactionSaveSuccessful) {
  setShowSaveOption(false);
  setIsSaveClicked(null);
  setRefreshCells(!refreshCells);

  if (filterValidTransactions && filterValidTransactions.length > 0) {
      if (poolsRowDataInvalid) {
      userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
      } else {
      userMessage('success', 'Successfully saved rows')
      }
  }
  dispatch(cleanTransactions(ADMINPOOLSGRID));
  dispatch(refreshGridData(ADMINPOOLSGRID));
  dispatch(validateGrid(ADMINPOOLSGRID, poolsGridReducer.currentData, poolsGridColDef.validationFn, poolsGridColDef.businessValidationFn));
  }
  // else if (poolsTransactionSaveSuccessful === false) {
  //   console.log("Failed to save pools...");
  // alert('Unable to save transactions. Please try again.');
  // }
}, [poolsTransactionSaveSuccessful]);

useEffect(() => {
  // console.log("carClassGroupFilterData In TAB Panel===>",carClassGroupFilterData);
  if(Object.keys(carClassGroupFilterData.filterParameters).length>0){
      if(!carClassGroupFilterData.searchView && carClassGroupFilterData.filterView){
          // console.log("Filtering Car Class Group Data...");
          dispatch(fetchCarClassGroupData(carClassGroupFilterData.filterParameters,carClassGroupFilterData.deleteFilter));
      }else if(carClassGroupFilterData.searchView && !carClassGroupFilterData.filterView){
          // console.log("Searching Car Class Group Data...");
          dispatch(fetchCarClassGroupDataSearchData(carClassGroupFilterData.filterParameters.search,carClassGroupFilterData.deleteFilter));
      }else{
          // console.log("Not Filter View & Not Search View :===> ",carClassGroupFilterData.filterParameters);
          dispatch(fetchCarClassGroupData(carClassGroupFilterData.filterParameters,carClassGroupFilterData.deleteFilter));
      }
  }
}, [carClassGroupFilterData.deleteFilter,carClassGroupFilterData.filterView,carClassGroupFilterData.searchView,carClassGroupFilterData.filterParameters]);

  //Zone Map
  useEffect(() => {
    if (zoneMapData) {
      dispatch(validateGrid(ZONEMAPGRID, zoneMapData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));
      setmetaDataGrid(ZONEMAPGRID)
      setmetaDataTabData(zoneMapData)
      setmetaDataGridColDef(zoneMapGridColDef)
    }
  }, [zoneMapData]);


  useEffect(() =>{
    if (!showSaveOption && zoneMapUploadSuccess == true) {
      if (zoneMapRowDataInvalid == true)
        userMessage('warning', 'Successfully uploaded. Some rows are invalid. Please review')
      else
        userMessage('success', 'Successfully uploaded');
    } else if (zoneMapUploadSuccess == false) {
      userMessage('error', 'Upload failed');
    }
  }, [zoneMapUploadSuccess, zoneMapRowDataInvalid]);
        
      
  useEffect(() => {
    if(!pending && isSaveClicked===ZONEMAPGRID){
    const filterValidTransactions = fetchValidTransactions(zoneMapGridReducer.transactions);
    const filterInValidTransactions = fetchInvalidTransactions(zoneMapGridReducer.transactions);
    if ((!zoneMapGridReducer.transactions || zoneMapGridReducer.transactions.length == 0) && (isSaveClicked != null && isSaveClicked == ZONEMAPGRID)) {
      setSnackBarOpen(false);
      userMessage('warning', 'Nothing to be saved');
      setShowSaveOption(false);
      setIsSaveClicked(null);
    }

    if ((filterValidTransactions && filterValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == ZONEMAPGRID)) {
      if (!filterInValidTransactions || filterInValidTransactions.length <= 0) {
        dispatch(saveZoneMapTransactionData(filterValidTransactions,userMessage));
      }
      else if (filterInValidTransactions && filterInValidTransactions.length > 0) {
        userMessage('error', 'Some modified rows are invalid. Please fix them before trying to save again.');
      }
    }

    if ((!filterValidTransactions || filterValidTransactions.length == 0) && (filterInValidTransactions && filterInValidTransactions.length > 0) && (isSaveClicked != null && isSaveClicked == ZONEMAPGRID)) {
      setSnackBarOpen(true);
    }
  }
  }, [zoneMapRowDataInvalid, isSaveClicked, snackBarIfError]);
      
  useEffect(() => {
    const filterValidTransactions = fetchValidTransactions(zoneMapGridReducer.transactions);

    if (zoneMapTransactionSaveSuccessful) {
      setShowSaveOption(false);
      setIsSaveClicked(null);
      setRefreshCells(!refreshCells);

      if (filterValidTransactions && filterValidTransactions.length > 0) {
        if (zoneMapRowDataInvalid) {
          userMessage('warning', 'Successfully saved rows. But some rows are still invalid, please review')
        } else {
          userMessage('success', 'Successfully saved rows')
        }
      }
      dispatch(cleanTransactions(ZONEMAPGRID));
      dispatch(refreshGridData(ZONEMAPGRID));
      dispatch(validateGrid(ZONEMAPGRID, zoneMapGridReducer.currentData, zoneMapGridColDef.validationFn, zoneMapGridColDef.businessValidationFn));  
    }
    else if (zoneMapTransactionSaveSuccessful === false) {
      userMessage('error', 'Failed to save');
    }
  }, [zoneMapTransactionSaveSuccessful]);

  return (
    <div className='metadatabgcolor'>
    <MetaDataActionBar
     metaDataTabData={metaDataTabData?metaDataTabData:carClassGroupData}
     metaDataGridColDef={metaDataGridColDef?metaDataGridColDef:carClassGroupGridColDef}
     metaDataGrid={metaDataGrid?metaDataGrid:'carclassgroupgrid'}
     options={showSaveOption}
     handleSaveCancelDisabled={handleSaveCancelDisabled}
     onSaveClicked={(e) => handleSaveClicked(metaDataGrid)}
     onCancelClicked={(e) => handleCancelClicked(metaDataGrid)}
     onAddNewRowClick={(e) => handleAddNewRow(metaDataGrid)}
     selectedPlanId={selectedPlanId}
     bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
     deleteSelectedRows={(e) => deleteSelectedRows(metaDataGrid)}
     downloadCSV={downloadCSV}
     //filterSearchKey={filterSearchKey}
     //filterParams={filterParams}
     handleAddNewDropdownValue={handleAddNewDropdownValue}
    >
    </MetaDataActionBar>
    <div className='metadatatabs'>
            <NttTabs
                value={value}
                orientation="vertical"
                variant="scrollable"
                scrollButtons='on'
                onChange={(e,i)=>{handleChangeTab(e,i)}}
                sx={{ borderRight: 1, borderColor: 'divider' }}
                indicatorColor='string'
            >
                {tabsData.map((tabInfo, index) => (
                    <NttTab label={tabInfo.tabTitle}
                        id={`simple-tab-${index}`}
                        key={tabInfo.tabTitle}
                        style={getStyle(value === index,tabInfo.tabTitle,index)}
                        disabled={showSaveOption} 
                    />
                ))}
            </NttTabs>
        {tabsData.map((tabInfo, index) => (
            <TabPanel key={index}
                value={value} index={index} className='metadatatabpanel'>
                {         
                    ( carClassGroupData && value == 0 ) ?
                        <CarClassGroupTabPanel 
                        carClassGroupGridReducer={carClassGroupGridReducer}
                        refreshCells={refreshCells}
                        handleRowEditingStarted={handleRowEditingStarted}
                        handleRowEditingStopped={handleRowEditingStopped}
                        handleCellEditingStopped={handleCellEditingStopped}
                        carClassGroupGridColDef={carClassGroupGridColDef}
                        suppressclickEdit={true}
                        deleteSelectedRows={(e) => deleteSelectedRows('carclassgroupgrid')}
                        handleMultipleRowDelete={handleMultipleRowDelete}
                        handleAddNewDropdownValue={handleAddNewDropdownValue} 
                        // metaDataGrid={metaDataGrid}
                        carClassGroupTransactions={carClassGroupGridReducer.transactions}
                        carClassGroupData={carClassGroupData}
                       />: ''}
              {         
                    ( modelMfrOemData && value == 1 ) ?
                        <ModelMfrOemTabPanel 
                        modelMfrOemGridReducer={modelMfrOemGridReducer}
                        refreshCells={refreshCells}
                        handleRowEditingStarted={handleRowEditingStarted}
                        handleRowEditingStopped={handleRowEditingStopped}
                        handleCellEditingStopped={handleCellEditingStopped}
                        modelMfrOemGridColDef={modelMfrOemGridColDef}
                        suppressclickEdit={true}
                        deleteSelectedRows={(e) => deleteSelectedRows(MODELMFROEM)}
                        handleMultipleRowDelete={handleMultipleRowDelete}
                        handleAddNewDropdownValue={handleAddNewDropdownValue} 
                        // metaDataGrid={metaDataGrid}
                        modelMfrOemTransactions={modelMfrOemGridReducer.transactions}
                        modelMfrOemData={modelMfrOemData}
                       />: ''}
              {         
                  ( poolsData && value == 2 ) ?
                      <PoolsTabPanel 
                      poolsGridReducer={poolsGridReducer}
                      refreshCells={refreshCells}
                      handleRowEditingStarted={handleRowEditingStarted}
                      handleRowEditingStopped={handleRowEditingStopped}
                      handleCellEditingStopped={handleCellEditingStopped}
                      poolsGridColDef={poolsGridColDef}
                      suppressclickEdit={true}
                      deleteSelectedRows={(e) => deleteSelectedRows(ADMINPOOLSGRID)}
                      handleMultipleRowDelete={handleMultipleRowDelete}
                      handleAddNewDropdownValue={handleAddNewDropdownValue} 
                      // metaDataGrid={metaDataGrid}
                     poolsTransactions={poolsGridReducer.transactions}
                      poolsData={poolsData}
                      />
                      : (zoneMapData && value == 3) ?
                       <ZoneMapTabPanel 
                       zoneMapgridReducer={zoneMapGridReducer}
                      //  currentView={currentView}
                       refreshCells={refreshCells}
                       handleRowEditingStarted={handleRowEditingStarted}
                       handleRowEditingStopped={handleRowEditingStopped}
                       handleCellEditingStopped={handleCellEditingStopped}
                       zoneMapGridColDef={zoneMapGridColDef}
                       suppressclickEdit={false}
                       deleteSelectedRows={(e) => deleteSelectedRows(ZONEMAPGRID)}
                       handleMultipleRowDelete={handleMultipleRowDelete} 
                       // planAssumptionsGrid={planAssumptionsGrid}
                       zoneMapTransactions={zoneMapGridReducer.transactions}
                       zoneMapData={zoneMapData}
                       userMessage={userMessage}
                       handleAddNewDropdownValue={handleAddNewDropdownValue}
                      />
                      : ''}   
            </TabPanel>
        ))}
        </div>
        </div>
)
}
