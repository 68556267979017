import { combineReducers } from 'redux';
import CarClassGroupReducer from './CarClassGroup.Reducer';
import multiGridUIReducer from '../AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import carClassGroupColumnDefinitionReducer from "./CarClassGroup.ColumnDef.Reducer";

const rootCarClassGroupReducer = combineReducers({
      CarClassGroupReducer
    , carClassGroupGridReducer: createNamedWrapperReducer(multiGridUIReducer, "carclassgroupgrid")
    , carClassGroupColumnDefinitionReducer
})

export default rootCarClassGroupReducer;