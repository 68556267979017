import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { checkAndLoadDimention, createParameterMap, minifyTransaction } from '../../../../utils/utils';
import { PARAMETERS_SESSION_STORAGE_NAME, pollTimeoutInterval } from '../../../../Constants';

export async function getCoreAdjData(rfid,pageno){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
        const promisecoreAdjResponse = client.apis.revenuefunction.getRFCoreAdj({'pageno':pageno},{requestBody:{'rfid':rfid}});
        const responsesArray = await Promise.all([promisecoreAdjResponse]);
        const [coreAdjResponse] = responsesArray.map(o => o.body.data);
        
        //NOTE:-Based on clients requirement removing the DEMAND_BY_CAR_CLASS validation 
        //Keeping below code for future reference incase if they want this again 
        // const promisecoreParameterResponse = client.apis.revenuefunction.getRFParameters({},{requestBody:{'rfid':rfid}});
       
        // const responsesArray = await Promise.all([promisecoreAdjResponse,promisecoreParameterResponse]);
       
        // const [coreAdjResponse,coreParameterResponse] = responsesArray.map(o => o.body.data);
        
        //Setting the Core Parameters in session so that it is available from vaidatiuon reducers
        //sessionStorage.setItem(PARAMETERS_SESSION_STORAGE_NAME,JSON.stringify(createParameterMap(coreParameterResponse)));

        return ({
            "gridData": coreAdjResponse.rows,"rowcount":coreAdjResponse.rowCount
        });
    }
    catch (error) {
        console.log("Failed to getCoreAdjData: ",error.message);
        return {'Error':'getCoreAdjData failed'}
   }
}

export async function getCoreAdjDropdownService(){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisePoolResponse =  await checkAndLoadDimention("pool", client);
        const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
        const promiseZoneResponse =  await checkAndLoadDimention("zone", client);
        const responsesArray = await Promise.all([ 
            promisePoolResponse,
            promiseAdminPoolResponse,
            promiseZoneResponse
        ]);
    
        const [
            poolResponse ,
            adminPoolResponse,
            zoneResponse
        ] = responsesArray.map(o => o.body.data);
        return ({
            "pools": poolResponse,
            "zones": zoneResponse,
            "adminPools": adminPoolResponse,
        }) 
    }catch(error){
        console.log("Failed to Get Dropdown values: ",error.message);
    }
}

export async function saveRfCoreAdjTransactions(rfid,transactionList,tabname){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promiseArray = [];
        await client.apis.revenuefunction.bulktransactrfcoreadj({"rfid":rfid},  {requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) ,"tabName":tabname}});
        const responsesArray = await Promise.all(promiseArray);
        return responsesArray;
    }catch(error){
        console.log("Failed to Save: ",error.message);
    }
}

export async function uploadCoreAdjCSV(rfid,tabName,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.revenuefunction.uploadRfCoreAdjustments({"rfid":rfid},{
            requestBody: {
                "updatedBy":userEmail,
                "tabName":tabName,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'uploadProgramCSV failed'}
    }
}


export async function coreAdjTabValidationService(rfid,transactionList){
    try{
       const userNToken=await getAccessTokenNUserDetailsAfterAuth();
   
       const accessToken=userNToken.accessToken;
       const userEmail = userNToken.email;
   
       const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
   
       const response = await client.apis.revenuefunction.validateRFCoreAdj({"rfid":rfid},{
           requestBody: {
               "updatedBy":userEmail,
               "transactions":minifyTransaction(transactionList)
           }
       });
       //console.log("coreAdjTabValidationService response==>",response);
       if(response.obj.statusCode==='500'){
            throw new Error((JSON.parse(response.obj.body)).error);
       }
       return {"validation_result":response.body.data.rows[0].validation_result,"isInvalid":response.body.data.rows[0].isinvalid!==undefined?response.body.data.rows[0].isinvalid:undefined};
    }
    catch(error){
       console.error(error);
       return {'Error':'coreadjvalidationservice failed'}
    }
   }

export async function getCoreAdjFilterData(rfid,pageno,filterparams){
    
    try{
    const accessToken = await getAccessTokenAfterAuth();
    
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    const promisecoreadjFilterResponse = await client.apis.revenuefunction.getRFCoreAdjustmentsFilterRecords({'pageno':pageno},{requestBody:{'rfid':rfid,'filterparams':filterparams}});
    const responsesArray = await Promise.all([promisecoreadjFilterResponse]);
    const [coreAdjResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": coreAdjResponse.rows,"rowcount":coreAdjResponse.rows[0]?coreAdjResponse.rows[0].rowcount:0
    });
    }
    catch (error) {
        console.error(error);
        return {'Error':'getcoreAdjFilterData failed'}
    }
}

export async function getCoreAdjTabErrorStatus(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisecoreAdjResponse = await client.apis.revenuefunction.getRFCoreAdjTabErrorStatus({'rfid':rfid});
    const responsesArray = await Promise.all([promisecoreAdjResponse]);
    const [coreAdjTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return coreAdjTabErrorStatusResponse;
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreAdjTabErrorStatus failed'}
   }
}

export async function deleteAllCoreAdjData(rfid,params){
 try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();
    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    let promiseRfCoreAdjDeleteResponse = await client.apis.revenuefunction.deleteAllRfCoreAdjRecords({ "rfid": rfid },{requestBody: {"updatedBy":userEmail,...params}});
    return promiseRfCoreAdjDeleteResponse;
 } 
 catch (error) {
    console.error(error);
    return {'Error':'deleteAllCoreAdjData failed'}
 }
}

export async function insertS3UploadCoreAdjCSV(rfid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        // console.log("Before getting new ID....");
        const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
        // console.log("After getting new ID....",responseWithJobId);
        const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
        // console.log("New Job ID....",jobId);
        let response = null;
        try{
            response = await client.apis.revenuefunction.insertS3UploadedRfCoreAdjData({"rfid":rfid},{
                requestBody: {
                    "updatedBy":userEmail,
                    "gridName":gridname.replace('grid',''),
                    "fileName":filename,
                    "jobId":jobId
                }
            });
        }catch(e){
            // console.log("e.message==>", e.message);
            let tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
            let status=tempResponse.body.data.rows[0].status;
            let operationStarted=tempResponse.body.data.rows[0].created_on;
            // let startTime=
            while(status!=='SUCCESS' && status!=='ERROR'){
                if((new Date().getMilliseconds()-(new Date(operationStarted)).getMilliseconds())>900000){
                    status='TIMEOUT';
                    break;
                }
                // console.log(`Waiting for ${pollTimeoutInterval} seconds...`);
                setTimeout(()=>{},pollTimeoutInterval);//Pause the time
                // console.log(`Getting the status....`);
                tempResponse=await client.apis.plans.getStatusOfXfleetJob({"jobid":jobId}, null);
                // console.log(`tempResponse===`,tempResponse);
                status=tempResponse.body.data.rows[0].status;
                // console.log(`status===`,status);
            }
            response = {
                obj:{
                    statusCode: status==="SUCCESS"?'200':'500',
                    body:status==="ERROR"?JSON.stringify({error:tempResponse.obj.data.rows[0].message}):status==="TIMEOUT"?JSON.stringify({error:"Timed Out"}):''
                }
            }
            // console.log("Operation completed...");
        }
        // console.log("insertS3UploadCoreAdjCSV===>",response);
        return response;
    } catch (error) {
        console.error(error);
        return {'Error':'insert of uploaded core adjustments CSV failed'}
    }
}

export async function getCoreAdjustmentsErrorData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisecoreAdjustmentsErrorResponse = await client.apis.revenuefunction.getRFCoreAdjustmentsErrorRecords({'pageno':pageno,'rfid':rfid},null);
    
    const responsesArray = await Promise.all([promisecoreAdjustmentsErrorResponse]);
    
    const [coreAdjustmentsResponse] = responsesArray.map(o => o.body.data);
    
    //return coreAdjustmentsResponse.obj.data;
    return ({
        "gridData": coreAdjustmentsResponse.rows,"rowcount":coreAdjustmentsResponse.rows[0]?coreAdjustmentsResponse.rows[0].rowcount:0
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getCoreAjustmentsErrorData failed'}
   }
}

export async function getCoreAdjustmentsSearchData(rfid,pageno,searchParams){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const promiseCoreAdjustmentsSearchResponse =  client.apis.revenuefunction.getRFCoreAdjustmentsSearchRecords({ 'rfid':rfid,'pageno':pageno==0 || pageno==undefined?0:pageno},{requestBody: {"searchParams": searchParams } });
    const responsesArray = await Promise.all([promiseCoreAdjustmentsSearchResponse]);

    const [coreAdjustmentsSearchResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": coreAdjustmentsSearchResponse.rows,"rowcount":coreAdjustmentsSearchResponse.rows[0]?coreAdjustmentsSearchResponse.rows[0].rowcount:0
    });
}