import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../utils/authUtils';
import { minifyTransaction } from '../../../utils/utils';


export async function getRFTncTabsErrorStatus(rfid){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
        const promiseGetTncIceTabErrorStatus =  client.apis.revenuefunction.getRFTncIceTabErrorStatus({'rfid':rfid});
        const promiseGetTncEvTabErrorStatus =  client.apis.revenuefunction.getRFTncEvTabErrorStatus({'rfid':rfid});
        const promiseGetTncParametersTabErrorStatus =  client.apis.revenuefunction.getRFParametersTabErrorStatus({'rfid':rfid});

        const responsesArray = await Promise.all([
            promiseGetTncIceTabErrorStatus,
            promiseGetTncEvTabErrorStatus,
            promiseGetTncParametersTabErrorStatus
        ]);

        const [
            tncIceStatusResponse,
            tncEvStatusResponse,
            tncParametersStatusResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "tncicegrid": tncIceStatusResponse,
            "tncevgrid": tncEvStatusResponse,
            "tncparametergrid": tncParametersStatusResponse
        }) 
    }
    catch (error) {
       console.error(error);
       return {'Error':'getRfTncTabsErrorStatus failed'}
   }
   }


   export async function getRFTncAllTabsValidationStatus(rfid,transactionList){
    try{
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
        const promiseGetTncIceTabValidationStatus =  client.apis.revenuefunction.validateRFTncIce({"rfid":rfid},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});
        const promiseGetTncEvTabValidationStatus =  client.apis.revenuefunction.validateRFTncEv({"rfid":rfid},{requestBody: {"updatedBy":userEmail,"transactions":minifyTransaction(transactionList)}});

        const responsesArray = await Promise.all([
            promiseGetTncIceTabValidationStatus,
            promiseGetTncEvTabValidationStatus
        ]);

        const [
            tncIceValidationResponse,
            tncEvValidationResponse
        ] = responsesArray.map(o => o.body.data);

        return({
            "tncicegrid": tncIceValidationResponse.rows[0].validation_result,
            "tncevgrid": tncEvValidationResponse.rows[0].validation_result
        }) 
    }
    catch (error) {
       console.error(error);
       return {'Error':'getRfTncTabsErrorStatus failed'}
   }
   }
   