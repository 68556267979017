const filterColumnDefs = () => {
        return ({
            "data": [
                {
                    "colDef": "PROGRAM_YEAR",
                    "displayName": "Program Year",
                    "type": "dropdown",
                    "options": [],
                },
                {
                    "colDef": "PROGRAM_TYPE",
                    "displayName": "Types",
                    "type": "dropdown",
                    "options": [],
                },          
                {
                    "colDef": "ACCEPTABLE_ZONES",
                    "displayName": "Zones",
                    "type": "dropdown",
                    "options": [],
                },
            
            {
                "colDef": "CAR_CLASS_CODE",
                "displayName": "Class",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "MODEL_GROUP",
                "displayName": "Model",
                "type": "dropdown",
                 "options": [],
            },
            {
                "colDef": "MODEL_YEAR",
                "displayName": "Model Year",
                "type": "dropdown",
                "options": [],

            },
                {
                    "colDef": "NET_CAPITAL_COST",
                    "displayName": "Capital",
                    "type": "input",
                     "options": "",
                },
            
            {
                "colDef": "BEGIN_DATE",
                "displayName": "From",
                "type": "datepicker",
                 "options": [],
            },
            {
                "colDef": "END_DATE",
                "displayName": "To",
                "type": "datepicker",
                 "options": [],
            },
            {
                "colDef": "MIN_AGE",
                "displayName": "Min Age",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "MAX_AGE",
                "displayName": "Max Age",
                "type": "range",
                "options": [],
            },
                {
                    "colDef": "DEPRECIATION_COST",
                    "displayName": "Depreciation Cost",
                    "type": "input",
                     "options": "",
                },
            {
                "colDef": "MIN_MILEAGE",
                "displayName": "Min Mileage",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "MAX_MILEAGE",
                "displayName": "Max Mileage",
                "type": "range",
                "options": [],
            },   
            {
                "colDef": "INCENTIVE",
                "displayName": "Incentive",
                "type": "input",
                "options": "",
            },
            {
                "colDef": "ADD_VOLUME_MIN",
                "displayName": "Min Additional Volume",
                "type": "range",
                "options": [],
            },
            {
                "colDef": "ADD_VOLUME_MAX",
                "displayName": "Max Additional Volume",
                "type": "range",
                "options": [],
            },
                {
                    "colDef": "SPI_CODE",
                    "displayName": "SPI Code",
                    "type": "dropdown",
                    "options": [],
                },
                {
                    "colDef": "MANUFACTURER_GROUP",
                    "displayName": "Manufacturer",
                    "type": "dropdown",
                    "options": [],
                },
                {
                    "colDef": "START_MILEAGE",
                    "displayName": "Start Mileage",
                    "type": "input",
                    "options": "",
                },
                {
                    "colDef": "BUY_STATUS",
                    "displayName": "Buy Status",
                    "type": "dropdown",
                    "options": []
                }
            ]
        });
    }

export default filterColumnDefs;