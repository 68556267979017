import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import jsonspec from "../../swagger.json"
import React from 'react';
import { authContext, getToken } from "../../config";
import { getAccessTokenAfterAuth } from "../../utils/authUtils";

export default function SwaggerUIPage(){
    return (<SwaggerUI displayOperationId={true} onComplete={(swaggerUi) => {
        let bearerTokenString = 'Bearer ' + getToken();
        swaggerUi.preauthorizeApiKey('SwaggerAccessToken', bearerTokenString);
      }} spec={jsonspec} />)
}