import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../../../utils/utils';

export async function getTncRevenueData(rfid,pageno){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
     const promisetncRevenueResponse=  client.apis.revenuefunction.getAllRFTncRevenue({ "rfid": rfid,'pageno':pageno });
     const responsesArray = await Promise.all([promisetncRevenueResponse]);
     const [tncRevenueResponse] = responsesArray.map(o => o.body.data);
    return ({
        "gridData": tncRevenueResponse.rows,"rowcount":tncRevenueResponse.rowCount
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncRevenueData failed'}
   }
   }
export async function getTncRevenueFilterData(rfid,pageno,filterparams){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncRevenueFilterResponse = await client.apis.revenuefunction.getRFTncRevenueFilterRecords({'pageno':pageno},{requestBody:{'rfid':rfid,'filterparams':filterparams}});
    const responsesArray = await Promise.all([promisetncRevenueFilterResponse]);
    const [tncRevenueResponse] = responsesArray.map(o => o.body.data);
    //return tncRevenueResponse.obj.data;
    return ({
        "gridData": tncRevenueResponse.rows,"rowcount":tncRevenueResponse.rows[0]?tncRevenueResponse.rows[0].rowcount:0
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncRevenueFilterData failed'}
   }
}
export async function getTncRevenueDropdownService(){
    try{
        const accessToken = await getAccessTokenAfterAuth();
    
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisePoolResponse =  checkAndLoadDimention("pool", client);
        const promiseCarClassCodeResponse =  checkAndLoadDimention("carClassCode", client);
        const responsesArray = await Promise.all([
                promiseCarClassCodeResponse, 
                promisePoolResponse,
        ]);

        const [
                carClassCodeResponse,
                poolResponse 
        ] = responsesArray.map(o => o.body.data);
        return ({
            "carClasses": carClassCodeResponse,
            "pools": poolResponse,
        })  
    }catch(error){

    }
}
