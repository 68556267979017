import { cellEditors } from "../../../Constants";
import { dateFormatter, currencyFormatter,valueFormatter,localeFormatter } from "../../../utils/formatters/valueFormatter";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    }, 
    {
        field: 'ACCEPTABLE_ZONES', headerName: 'Acceptable Zones', cellEditor: cellEditors.autoCompletePopupEditor, unSortIcon: true,
        cellClassRules: cellClassRules("ACCEPTABLE_ZONES"), tooltipValueGetter: params => {
            return toolTipUI(params, "ACCEPTABLE_ZONES")
        }
    },
    {
        field: 'ZONE_NAME', headerName: 'Zones',  minWidth: 150, cellEditor: "autoCompleteEditor", unSortIcon: true,
        cellClassRules: cellClassRules("ZONE_NAME"), 
        tooltipValueGetter: params => {
            return toolTipUI(params, "ZONE_NAME")
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "ZoneMapActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
