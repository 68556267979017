export const FETCH_MODELMFROEM_DATA_PENDING = 'FETCH_MODELMFROEM_DATA_PENDING';
export const FETCH_MODELMFROEM_DATA_SUCCESS = 'FETCH_MODELMFROEM_DATA_SUCCESS';
export const FETCH_MODELMFROEM_DATA_ERROR = 'FETCH_MODELMFROEM_DATA_ERROR';

// export const UPLOAD_MODELMFROEM_PENDING = 'UPLOAD_MODELMFROEM_PENDING';
// export const UPLOAD_MODELMFROEM_SUCCESS = 'UPLOAD_MODELMFROEM_SUCCESS';
// export const UPLOAD_MODELMFROEM_ERROR = 'UPLOAD_MODELMFROEM_ERROR';

export const SAVE_MODELMFROEM_TRANSACTION_PENDING = 'SAVE_MODELMFROEM_TRANSACTION_PENDING';
export const SAVE_MODELMFROEM_TRANSACTION_SUCCESS = 'SAVE_MODELMFROEM_TRANSACTION_SUCCESS';
export const SAVE_MODELMFROEM_TRANSACTION_ERROR = 'SAVE_MODELMFROEM_TRANSACTION_ERROR';

export const SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_PENDING = 'SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_PENDING';
export const SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_SUCCESS = 'SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_SUCCESS';
export const SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_ERROR = 'SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_ERROR';

export const FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING = 'FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING';
export const FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS = 'FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS';
export const FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR = 'FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR';

export const RESET_TO_MODELMFROEM_INITIALSTATE = 'RESET_TO_MODELMFROEM_INITIALSTATE';

export const SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN='SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN';
export const SET_ADMIN_MODELMFROEM_FILTER_PARAMS='SET_ADMIN_MODELMFROEM_FILTER_PARAMS';
export const SET_ADMIN_MODELMFROEM_FILTER_VIEW='SET_ADMIN_MODELMFROEM_FILTER_VIEW';
export const SET_ADMIN_MODELMFROEM_FILTER_RECORDS='SET_ADMIN_MODELMFROEM_FILTER_RECORDS';
export const SET_ADMIN_MODELMFROEM_FILTER_APPLY='SET_ADMIN_MODELMFROEM_FILTER_APPLY';
export const SET_ADMIN_MODELMFROEM_FILTER_CLEAR='SET_ADMIN_MODELMFROEM_FILTER_CLEAR';
export const SET_ADMIN_MODELMFROEM_SEARCH_VIEW='SET_ADMIN_MODELMFROEM_SEARCH_VIEW';
export const SET_ADMIN_MODELMFROEM_SEARCH_TEXT='SET_ADMIN_MODELMFROEM_SEARCH_TEXT';
export const SET_ADMIN_MODELMFROEM_SEARCH_RECORDS='SET_ADMIN_MODELMFROEM_SEARCH_RECORDS';
export const SET_ADMIN_MODELMFROEM_DELETE_FILTER='SET_ADMIN_MODELMFROEM_DELETE_FILTER';
export const SET_ADMIN_MODELMFROEM_DELETE_FILTER_RECORDS='SET_ADMIN_MODELMFROEM_DELETE_FILTER_RECORDS';

// export const SET_MODELMFROEM_EDITABLE='SET_MODELMFROEM_EDITABLE';