import { deleteParametersById, fetchParameterDropdownService, fetchParameters, saveParameterTransactions } from "../../services/parameters/ParametersService";
import {
    FETCH_PARAMETERS_DATA_PENDING, FETCH_PARAMETERS_DATA_ERROR, FETCH_PARAMETERS_DATA_SUCCESS,
    SAVE_PARAMETERS_TRANSACIONS_PENDING, SAVE_PARAMETERS_TRANSACIONS_ERROR, SAVE_PARAMETERS_TRANSACIONS_SUCCESS, DELETE_PARAMETERS_DATA_SUCCESS,
    DELETE_PARAMETERS_DATA_ERROR, DELETE_PARAMETERS_DATA_PENDING, FETCH_PARAMETERS_DROPDOWN_PENDING, FETCH_PARAMETERS_DROPDOWN_SUCCESS, FETCH_PARAMETERS_DROPDOWN_ERROR
} from "./Parameters.ActionTypes";
import {SET_ZONERF_POOLRF} from '../topbar/AllPlans.ActionTypes';
import {CallGetPlanByIdService } from '../../services/topbar/AllPlansService';

export const getParameters = (planId) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PARAMETERS_DATA_PENDING));
            let response = await fetchParameters(planId);
            if (response) {
                dispatch(serviceActionSuccess(FETCH_PARAMETERS_DATA_SUCCESS, response));
            }
        } catch (_) {
            dispatch(serviceActionError(FETCH_PARAMETERS_DATA_ERROR, "Unknown error"));
        }
    }
}

export const saveParametersTransactions = (planid, transactionList) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_PARAMETERS_TRANSACIONS_PENDING));
            await saveParameterTransactions(planid, transactionList);
            let parametersData = await fetchParameters(planid);
            if (parametersData) {
                dispatch(serviceActionSuccess(SAVE_PARAMETERS_TRANSACIONS_SUCCESS, parametersData));
                if (planid !== undefined) {
                    let planByIdResponse = await CallGetPlanByIdService(planid);
                    if (planByIdResponse) {
                        dispatch(serviceActionSuccess(SET_ZONERF_POOLRF, planByIdResponse));
                    }
                }
            }
        } catch (_) {
            dispatch(serviceActionError(SAVE_PARAMETERS_TRANSACIONS_ERROR, "Unknown error"));
        }
    }
}

export const deleteParameters = (id, planId) => {
    return async dispatch => {
        dispatch(serviceActionPending(DELETE_PARAMETERS_DATA_PENDING));
        let res;
        if (id) {
            res = await deleteParametersById(planId, id)
        } else
            res = '1';
        if (res == '1') {   // Indicates number of records deleted 
            let parametersData = await fetchParameters(planId)
            if (parametersData) {
                dispatch(serviceActionSuccess(DELETE_PARAMETERS_DATA_SUCCESS, parametersData));
            }
            else {
                dispatch(serviceActionError(DELETE_PARAMETERS_DATA_ERROR, "Delete parameters failed"));
            }
        } else {
            dispatch(serviceActionError(DELETE_PARAMETERS_DATA_ERROR, "Delete parameters failed"));
        }
    }
}

// export const fetchParametersDropDownData = (planid) => {
//     return async dispatch => {
//         try {
//             dispatch(serviceActionPending(FETCH_PARAMETERS_DROPDOWN_PENDING))
//             let zoneRfData = await fetchZoneRfData(planid);
//             let poolRfDataTemp = await fetchPoolRfData(planid);
//             let poolRfData = [];
//             let tncRfData = [];
//             poolRfDataTemp.forEach(element => {
//                 if(element.name.includes('PM_TNC')){
//                     tncRfData.push(element);
//                 }
//                 else{
//                     poolRfData.push(element);
//                 }
//             });
//             if (zoneRfData && poolRfData && tncRfData) {
//                 dispatch(serviceActionSuccess(FETCH_PARAMETERS_DROPDOWN_SUCCESS, { zoneRfData, poolRfData,tncRfData}));
//             }
//         }
//         catch (e) {
//             console.log("exception message = " , e.message);
//             console.log("exception stack = " , e.stack);
//             dispatch(serviceActionError(FETCH_PARAMETERS_DROPDOWN_ERROR, "Unknown error"));
//         }
//     }
// }

export const fetchParametersDropDownData = (planid) => {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(FETCH_PARAMETERS_DROPDOWN_PENDING));
            let response = await  fetchParameterDropdownService(planid);
            //Below codes are moved to parameterservice
             //let zoneRfData=[];
             //let poolRfDataTemp=[];
            //  if (zoneRFData.length>0) {
            //    zoneRfData = zoneRFData;
            //  } 
            //  else {
            //    zoneRfData = await fetchZoneRfData(planid);
            //  }
            //  if (poolRFData.length>0) {
            //    poolRfDataTemp = poolRFData;
            //  } else {
            //    poolRfDataTemp = await fetchPoolRfData(planid);
            //  }
            // let poolRfData = [];
            // let tncRfData = [];
            // poolRfDataTemp.forEach(element => {
            //     if(element.name.includes('PM_TNC')){
            //         tncRfData.push(element);
            //     }
            //     else{
            //         poolRfData.push(element);
            //     }
            // });
               // if (zoneRfData && poolRfData && tncRfData) {
                dispatch(serviceActionSuccess(FETCH_PARAMETERS_DROPDOWN_SUCCESS,response));
             //}
        }
        catch (e) {
            console.log("exception message = " , e.message);
            console.log("exception stack = " , e.stack);
            dispatch(serviceActionError(FETCH_PARAMETERS_DROPDOWN_ERROR, "Unknown error"));
        }
    }
}

function serviceActionPending(type) {
    return {
        type: type
    }
}

function serviceActionSuccess(type, products) {
    return {
        type: type,
        data: products
    }
}

function serviceActionError(type, error) {
    return {
        type: type,
        error: error
    }
}