import {
  dateFormatter,
  currencyFormatter,
  valueFormatter,
  localeFormatter,
  updatedOnDateFormatter
} from "../../../utils/formatters/valueFormatter";
import { MileageDefaultColumns, cellEditors } from "../../../Constants";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";


export const columnDefs = [
  {
    headerName: "",
    field: "",
    minWidth: 40,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    editable: false,
    sortable: false,
    resizable: false,
    suppressColumnsToolPanel: true,
    flex: 0,
  },
  {
    field: MileageDefaultColumns.Zone.field,
    headerName: MileageDefaultColumns.Zone.headerName,
    unSortIcon: true,
    minWidth: 150,filterType:"dropdown",filterOptions:[],
    cellEditor: cellEditors.autoCompleteEditor,
    cellClassRules: cellClassRules(MileageDefaultColumns.Zone.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, MileageDefaultColumns.Zone.name);
    },
  },
  { 
    field: MileageDefaultColumns.Year.field,
    headerName:MileageDefaultColumns.Year.headerName,
    cellEditor: cellEditors.autoCompleteEditor,
    minWidth: 150,
    filterType:"dropdown",filterOptions:[],
    unSortIcon: true,
    cellEditor: cellEditors.autoCompleteEditor,
    cellClassRules: cellClassRules(MileageDefaultColumns.Year.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, MileageDefaultColumns.Year.name);
    },
  },
  {    
    field: MileageDefaultColumns.MONTH_NAME.field,
    headerName:MileageDefaultColumns.MONTH_NAME.headerName,
    minWidth: 150,filterType:"dropdown",filterOptions:[],
    unSortIcon: true,
    cellEditor: cellEditors.autoCompleteEditor,
    cellClassRules: cellClassRules(MileageDefaultColumns.MONTH_NAME.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, MileageDefaultColumns.MONTH_NAME.name);
    }
  },
  {
    field: MileageDefaultColumns.CAR_CLASS.field,
    headerName: MileageDefaultColumns.CAR_CLASS.headerName,
    cellEditor: cellEditors.autoCompleteEditor,
    minWidth: 110,filterType:"dropdown",filterOptions:[],
    unSortIcon: true,
    cellClassRules: cellClassRules(MileageDefaultColumns.CAR_CLASS.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, MileageDefaultColumns.CAR_CLASS.name);
    },
  },
  {
    field: MileageDefaultColumns.MONTHLY_MILEAGE.field,
    headerName: MileageDefaultColumns.MONTHLY_MILEAGE.headerName,
    cellEditor: cellEditors.numericEditor,
    type: 'numericColumn',filterType:"input", 
    minWidth: 150,
    unSortIcon: true,
    valueFormatter:params=>localeFormatter(params.data.MONTHLY_MILEAGE),
    cellClassRules: cellClassRules(MileageDefaultColumns.MONTHLY_MILEAGE.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params,MileageDefaultColumns.MONTHLY_MILEAGE.name);
    }
  },
  {
    field: MileageDefaultColumns.PROGRAM_TYPE.field,
    headerName: MileageDefaultColumns.PROGRAM_TYPE.headerName,
    cellEditor: cellEditors.autoCompleteEditor,
    minWidth: 150,filterType:"dropdown",filterOptions:[],
    unSortIcon: true,
    cellClassRules: cellClassRules(MileageDefaultColumns.PROGRAM_TYPE.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, MileageDefaultColumns.PROGRAM_TYPE.name);
    },
  },
  {
    field: MileageDefaultColumns.UpdatedBy.field, 
    headerName: MileageDefaultColumns.UpdatedBy.headerName, 
    editable: false, unSortIcon: true,filterOptions:[],
    cellClassRules: cellClassRules(MileageDefaultColumns.UpdatedBy.name), 
    valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
    comparator: customUpdatedByComparator,
    tooltipValueGetter: params => {
        return toolTipUI(params, MileageDefaultColumns.UpdatedBy.name)
    }
},
{
    field: MileageDefaultColumns.UpdatedOn.field, 
    headerName: MileageDefaultColumns.UpdatedOn.headerName, 
    editable: false,
    valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
    unSortIcon: true, cellClassRules: cellClassRules(MileageDefaultColumns.UpdatedOn.name), 
    comparator: customDateComparator,
    tooltipValueGetter: params => {
        return toolTipUI(params, MileageDefaultColumns.UpdatedOn.name)
    }
},
  {
    headerName: "",
    colId: "actions",
    cellRenderer: "MileageDefaultActionRenderer",
    editable: false,
    filter: false,
    sortable: false,
    minWidth: 120,
    pinned: "right",
  },
];

function toolTipUI(params, field) {
  if(params.data.validations && params.data.validations[field]){
  if (
    params.data.validations &&
    params.data.validations[field].error !== null
  ) {
    return params.data.validations[field].error;
  }
  if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
    return "Duplicate row";
  }
}
}

function cellClassRules(field) {
  return {
    errorCell: (params) => {
      if (params.data.validations && params.data.validations[field]) {
        return params.data.validations[field].error !== null;
      }
    },
    validRow: (params) => {
      if (params.data.validations && params.data.colDef) {
        var index = params.data.colDef.findIndex(function (item, i) {
          return item.id === params.data.ID && item.value === field;
        });
        return index > -1 && params.data.validations[field].error === null;
      }
    },
  };
}

export const defaultColDef = {
  editable: true,
  sortable: true,
  minWidth: 120,
  filter: false,
  resizable: true,
  floatingFilter: false,
  flex: 1,
};
