import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { fetchPoolRfData, fetchZoneRfData } from '../topbar/AllPlansService';


export async function fetchParameters(planId,pageno) {
    try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

    const promiseparametersResponse = await client.apis.plans.getAllParameters({"planid":planId?planId:'master'});
    const responsesArray = await Promise.all([promiseparametersResponse]);
    const [parametersResponse] = responsesArray.map(o => o.body.data);

    if(parametersResponse && parametersResponse.length>0){
        parametersResponse.map((paramData,index)=>{
            if(paramData.key.toLowerCase()==="cpd"){
                sessionStorage.setItem("CPD",JSON.stringify(paramData.value));
            }
        })
    }
    if (JSON.parse(localStorage.getItem("newArchitecture"))) {
        return ({
            "gridData": parametersResponse, "rowcount": 2000
        });
    }
    else {
        //this else condition executes when it is older version       
        return parametersResponse;
    }
}
catch (error) {
    console.error(error);
    throw 'Unable to fetch the data for Parameters tab'
    //return parametersResponse.body.data;
}
}

export async function deleteParametersById(planId, id) {
    try{
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

    const deleteParametersResponse = await client.apis.plans.deleteParameter({"planid":planId?planId:'master', "id": id});
    return deleteParametersResponse.body.data;
    }
    catch (error) {
        console.error(error);
        throw 'Parameters tab has internal server error, Unable to delete the records!! Please try again'
    }
}

export async function saveParameterTransactions(planid, transactionList) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    transactionList.forEach(item => {
       
        if(item.type == 'edit' || item.type == 'add'){
            
            let element={
                ...item,
                data:{
                    ...item.data,
                    validations:{
                        ...item.data.validations,
                        key:{...item.data.validations.key},
                        value:{...item.data.validations.value},
                    },
                    colDef:item.data.colDef.map(item=>{return {...item}}),
                    value:(item.data.value && (item.data.key==="RF_CODE_POOL"||item.data.key==="RF_CODE_ZONE"||item.data.key==="RF_CODE_TNC"))?item.data.value.toUpperCase():item.data.value
                },
    
            };

            promiseArray.push(client.apis.plans.createParameter({"planid":planid?planid:"master","key":element.data.key},{
                requestBody: { "updatedBy": userEmail,"data":element.data }
            }));
        }
        else if(item.type == 'delete'){
            promiseArray.push(client.apis.plans.deleteParameter({"planid":planid?planid:"master","id":item.data.id}));
        }
    });

    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
}

export async function getParametersSearchData(planid,pageno,searchParams){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseParametersSearchResponse = client.apis.plans.getParametersSearchRecords({"planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseParametersSearchResponse]);

        const [parametersSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": parametersSearchResponse.rows, "rowcount": parametersSearchResponse.rows[0] ? parametersSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Parameters tab'
    }
}

export async function fetchParameterDropdownService(planid){

    const accessToken = await getAccessTokenAfterAuth();
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let zoneRfData = await fetchZoneRfData(planid);
    let poolRfDataTemp = await fetchPoolRfData(planid);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);
    let poolRfData = [];
    let tncRfData = [];

    poolRfDataTemp.forEach(element => {
        if(element.name.includes('PM_TNC')){
            tncRfData.push(element);
        }
        else{
            poolRfData.push(element);
        }
    });
    return  ({
        "zoneRfData":zoneRfData.sort((a, b) => a['name'].localeCompare(b['name'])),
        "poolRfData":poolRfData.sort((a, b) => a['name'].localeCompare(b['name'])),
        "tncRfData":tncRfData.sort((a, b) => a['name'].localeCompare(b['name'])),
        "users":promiseUserManagementEmailAndIdMap
    });
}

export async function getParameterTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const promiseParameterResponse = await client.apis.plans.getParametersTabErrorStatus({ "planid": planid ? planid : 1 });
        if (promiseParameterResponse.obj && promiseParameterResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseParameterResponse]);
            const [parameterTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return parameterTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the Parameters tab error status, Please try again!!';
    }
}

export async function parameterTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.validateParameters({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to validate Parameters tab, Please try again!!'
    }
}