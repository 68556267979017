import React, { useEffect, useMemo, useRef, useState } from 'react';
import DialogBox from '../dialog_component/CustomDialog';
import { createTheme, makeStyles } from '@material-ui/core';
import IconFilter from "../../assets/images/icon_filter.svg"
import IconFilterActive from "../../assets/images/icon_filter_active.svg";
import "./FilterComponent.css"
import DropdownFilter from './dropdown_filter/DropdownFilter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGridErrorViewStatus, setGridFilterParams, setGridFilterViewStatus, setGridSearchParams, setGridSearchViewStatus } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import DatePickerFilter from './datepicker_filter/DatePickerFilter';
import InputFilter from './input_filter/InputFilter';
import RangeFilter from './range_filter/RangeFilter';
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { CANNOT_CHANGE_DATASET_ERROR } from '../../Constants';

const FilterComponent = (props) => {
    const {gridColumnDefs,initializeFilterParamsAndViewStatus,hasPendingTransactions}=props;

    const dispatch = useDispatch();
    let filterKeys=useRef(null)
    
    const gridFilterUserSelectedParams = useSelector((state) => {
        return state.PaginationGridReducer.gridFilterUserSelectedParams;
    },shallowEqual); 

    const gridFilterViewStatus = useSelector((state) => {
        return state.PaginationGridReducer.gridFilterView;
    },shallowEqual); 

    const gridName=useSelector((state)=>{
        return state.PaginationGridReducer.gridName
    });


    const [openFilter,setOpenFilter]=useState(false)
    const selectedFilterParams=useRef(null);
    const [clearFilterValues,setClearFilterValues]=useState(false)
    const theme = createTheme();
    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            minHeight: '180vh',
            maxHeight: '180vh',
        }
    }));

// NOTE :- JSON.parse and JSON.stringify is been done below for handleClose & handleOpen function 
// because to deep copy the object and set the value for state SelectedFilterParams
// If we wont do this then whenever we update the filterParams object the redux object also gets updated without any dispatch!  

    const handleClose=()=>{        
        selectedFilterParams.current=JSON.parse(JSON.stringify(gridFilterUserSelectedParams[gridName]));
        setOpenFilter(false);
    }

    useEffect(()=>{
     filterKeys.current=null;
     if(gridName!='' && !gridFilterUserSelectedParams[gridName] && gridName!=='bvdepoverridegrid'){  //delete gridName!=='bvdepoverridegrid' condition while implementing filter for bvdepoverride  
        initializeFilterParamsAndViewStatus(gridName,gridColumnDefs)
        }
    },[gridName])

    useEffect(()=>{
        if(selectedFilterParams.current==null && gridFilterUserSelectedParams[gridName]!=undefined){
            selectedFilterParams.current=JSON.parse(JSON.stringify(gridFilterUserSelectedParams[gridName]));
        }
    },[gridFilterUserSelectedParams])

   const getFilterKeys=(selectedfilterParams)=>{
    let filterkeysOnset=Object.keys(selectedfilterParams);
    //note:initially set all the filter keys to false.
    //using ternary operator to set the filterkeys while switching between the tabs.
    filterkeysOnset = filterkeysOnset.reduce((a, v) => ({ ...a, [v]: (selectedfilterParams[v].length>0)?true:false}),{});
    filterKeys.current=filterkeysOnset;
  }
  
    const handleOpen=()=>{
    if(gridName!=='bvdepoverridegrid'){  //delete gridName!=='bvdepoverridegrid' condition while implementing filter for bvdepoverride
        if(hasPendingTransactions){
            dispatch(addNotificationMessage('error',CANNOT_CHANGE_DATASET_ERROR))
        }else{
            if(filterKeys.current==null){
                getFilterKeys(gridFilterUserSelectedParams[gridName])
            }        
            if(gridFilterUserSelectedParams[gridName]!=undefined && Object.keys(gridFilterUserSelectedParams[gridName]).length>0){
                selectedFilterParams.current=JSON.parse(JSON.stringify(gridFilterUserSelectedParams[gridName]));
                setOpenFilter(true);
            }
        }
    }
  }

    const applyFilter=async()=>{
        if(JSON.stringify(gridFilterUserSelectedParams[gridName])!=JSON.stringify(selectedFilterParams.current) && Object.values(filterKeys.current).includes(true)){
            dispatch(setGridFilterParams(gridName,null,false,selectedFilterParams.current));
            dispatch(setGridFilterViewStatus(gridName,true));
            dispatch(setGridErrorViewStatus(gridName,false));
            dispatch(setGridSearchParams(gridName,null));
            dispatch(setGridSearchViewStatus(gridName,false));
            //loadGridDataForFilterParams(selectedFilterParams,true);             
        }
        if(!(Object.values(filterKeys.current).includes(true))){ 
            if(gridFilterViewStatus[gridName]){  
            dispatch(setGridFilterParams(gridName,gridColumnDefs,true))
            dispatch(setGridFilterViewStatus(gridName,false));
           // loadGridDataForFilterParams(selectedFilterParams,false);
            }
        }
        setOpenFilter(false);
    }

    const clearFilter=()=>{
        if(gridFilterViewStatus[gridName]){ 
        dispatch(setGridFilterParams(gridName,gridColumnDefs,true))
        dispatch(setGridFilterViewStatus(gridName,false));
        selectedFilterParams.current=null;
        //loadGridDataForFilterParams(selectedFilterParams,false);
        }
        else {
        setClearFilterValues(prevState=>!prevState) //its just a toggle, when ever there is change in the state, all the filter fields will be cleared, nothing to do with its value      
        selectedFilterParams.current=JSON.parse(JSON.stringify(gridFilterUserSelectedParams[gridName]));
        }
    }

    const getSelectedValue = (field) => {
        if (selectedFilterParams.current && selectedFilterParams.current[field] !== undefined) {
          return selectedFilterParams.current[field];
        } else {
            return []
        }
    }

/**
 * 
 * @param {string} name -refers to the name of the field
 * @param {*} value - referes to the selected value of the field in filter pop up
 * @param {boolean} isRangeField -to understand whether field is datepicker,range filter or not
 */
    const updateFilterParams = (name, value, isRangeField) => {
        if (selectedFilterParams.current[name] != undefined) {
            selectedFilterParams.current[name] = value
            if (isRangeField && value[0] === 'between' && value[1] != '' && value[2] != '' && value.length == 3) {
                filterKeys.current[name] = true;
            } else if (isRangeField && value[0] != 'between' && value[0] != 'blanks' && value[1] != undefined && value[1] != '') {
                filterKeys.current[name] = true;
            }
            else if (isRangeField && value[0] === 'blanks') {
                filterKeys.current[name] = true;
            }
            else if (!isRangeField && value && value.length > 0) {
                filterKeys.current[name] = true;
            } else {
                filterKeys.current[name] = false;
            }
        }
    }

    const fetchDialogContent=()=>{
        if (gridColumnDefs && gridColumnDefs.columnDefs && gridColumnDefs.columnDefs.length > 0) {
            return (<div className="flexContainer">
                {gridColumnDefs.columnDefs.map((fields, index) => {
                    return (<div className="filterMain" key={index}>
                        {(fields.filterType === "dropdown") ?
                            (<DropdownFilter 
                                displayName={fields.headerName} 
                                options={fields.filterOptions}
                                theme={theme}
                                fieldName={fields.field}
                                userSelectedValue={getSelectedValue(fields.field)}
                                isFilterCleared={clearFilterValues}
                                onValueChanged={(field,value) => updateFilterParams(field,value)}>
                            </DropdownFilter>):
                            (fields.filterType === "datepicker") ?
                            (<DatePickerFilter 
                                displayName={fields.headerName} 
                                theme={theme}
                                fieldName={fields.field}
                                isFullDate={fields.isFullDate} //set isFullDate as true in columndefs to use editor with date also by default it will be false/undefined
                                userSelectedValue={getSelectedValue(fields.field)} 
                                isFilterCleared={clearFilterValues}
                                onValueChanged={(field,value,isRangeField) => updateFilterParams(field,value,isRangeField)}
                                >
                            </DatePickerFilter>):
                            (fields.filterType === "input") ?
                            (<InputFilter 
                                userSelectedValue={getSelectedValue(fields.field)}
                                displayName={fields.headerName} 
                                theme={theme}
                                fieldName={fields.field}
                                isFilterCleared={clearFilterValues}
                                onValueChanged={(field,value) => updateFilterParams(field,value)}
                                >
                            </InputFilter>) :
                            (fields.filterType === "range")?
                            (<RangeFilter
                                displayName={fields.headerName} 
                                theme={theme}
                                fieldName={fields.field}
                                isFilterCleared={clearFilterValues}
                                userSelectedValue={getSelectedValue(fields.field)} 
                                onValueChanged={(field,value,isRangeField) => updateFilterParams(field,value,isRangeField)}
                            >
                            </RangeFilter>):""}
                        </div>);
                }
                )}</div>);
        }
    }
    return (
        <>
            <div className="filterMargin">
            <img src={gridFilterViewStatus[gridName]?IconFilterActive:IconFilter} className="imageIcon" onClick={handleOpen} ></img>
            </div>
            <DialogBox classes={{ paper: useStyles().dialogPaper }}
            open={openFilter} handleClose={(e) => handleClose(e)} handlePositiveBtnClick={(e) => applyFilter(e)} handleNegativeBtnClick={(e) => clearFilter(e)}
            dialogContent={fetchDialogContent()} title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter">
            </DialogBox>
        </>
    );
};

export default FilterComponent;