import React from 'react';
import AdminMultiGridUI from '../../../components/admin/grid/AdminMultiGridUI';
import {MODELMFROEM} from '../../../../src/Constants';


const ModelMfrOemTabPanel = (props)=>{
    const{handleRowEditingStarted, handleRowEditingStopped,refreshCells,  handleCellEditingStopped,modelMfrOemGridColDef,  handleMultipleRowDelete, 
        modelMfrOemTransactions, modelMfrOemGridReducer,handleAddNewDropdownValue} = props;

 return(
     <div>
         <AdminMultiGridUI 
            reducer={modelMfrOemGridReducer}
            refreshCells={refreshCells}
            gridName={MODELMFROEM}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={modelMfrOemGridColDef}
            suppressclickEdit={false}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            modelMfrOemTransactions={modelMfrOemTransactions}
            handleAddNewDropdownValue={handleAddNewDropdownValue}
             />
     </div>
 )
}

export default ModelMfrOemTabPanel;