import React from "react";
import AdminMultiGridUI from "../../../components/admin/grid/AdminMultiGridUI";
import PlanManagementActionBar from "../../../components/admin/planmanagementactionbar/PlanManagementActionbar";

const  PlanManagementTabPanel = (props)=>{
 const{columnDefinitionReducer,gridReducer,allPlansData,refreshCells,showSaveOption,handleSaveClicked,handleCancelClicked,handleRowEditingStarted,handleRowEditingStopped,handleSaveCancelDisabled,handleCellEditingStopped} = props;

  return(
    <div>
     <PlanManagementActionBar
      data={gridReducer.currentData}
      allPlansData={allPlansData}
      columnDefinitionReducer={columnDefinitionReducer}
      transactions={gridReducer.transactions}
      options={showSaveOption}
      handleSaveCancelDisabled={handleSaveCancelDisabled}
      onSaveClicked={handleSaveClicked}
      onCancelClicked={handleCancelClicked} >
     </PlanManagementActionBar>
     <AdminMultiGridUI
     reducer={gridReducer}
     gridName={"planmanagementgrid"}
     refreshCells={refreshCells}
     gridColDef={columnDefinitionReducer}
     rowEditType={'single'}
     suppressclickEdit={!columnDefinitionReducer.defaultColDef.editable}
     onRowEditingStarted={handleRowEditingStarted}
     onRowEditingStopped={handleRowEditingStopped}
     onCellEditingStopped={handleCellEditingStopped}>
     </AdminMultiGridUI>
    </div>
 )

}

export default PlanManagementTabPanel;