import React from "react";
import { useSelector } from "react-redux";
import { checkIfFreezMonth } from "../../../utils/AdjustmentsUtils";
import EndFleetRowComponent from "./EndFleetRowComponent";

const EndFleetSectionRowsComponent = (props) => {
    const { selectedVal,accordionContent,startFleetValue, isExpanded,planStatus, addStartFleet, filterParams, zonename,filterRecord,isOfTypeEndFleet,currentHeader } = props;
    if (accordionContent) {
        var arrayObject = accordionContent.currentValues.map((obj, ix) => {
            return {
                "activity_type": obj.activity_type,
                "year": obj.year,
                "current": obj,
                "adjustment": accordionContent.adjustments[ix],
                "original": accordionContent.originalValues[ix],
                "fnlVal": accordionContent.fnlValues?accordionContent.fnlValues[ix]:null,
                "adjVar": accordionContent.adjVarValues?accordionContent.adjVarValues[ix]:null,
                "month":obj.month
            }
        });
        return <EndFleetRowComponent startFleetValue={startFleetValue} selectedVal={selectedVal} arrayObject={arrayObject} isExpanded={isExpanded} planStatus={planStatus} addStartFleet={addStartFleet} filterParams={filterParams} zonename={zonename} filterRecord={filterRecord} isOfTypeEndFleet={isOfTypeEndFleet} currentHeader={currentHeader}></EndFleetRowComponent>
    } else {
        return (
            <div></div>
        )
    }
};
export default EndFleetSectionRowsComponent;