import React from 'react';
import MultiGridUI from "../../../components/grid/MultiGridUI";
import MainPlanInputsMultiGridUI from '../../../components/grid/MainPlanInputsMultiGridUI';

const MaxDeletesTabPanel = (props)=>{
    const{showSaveOption,maxDeletesgridReducer,maxDeletesTransactions,maxDeletesGridColDef, ageMileageData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        ageMileageGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions, currentData,filteredData, filterParams,tabIndex, downloadCSV,userMessage,newArchitecture,gridName,loadGridData} = props;

 return(
     <div>
         {newArchitecture ?(<MainPlanInputsMultiGridUI reducer={gridReducer}
            gridName={gridName}
            selectedPlanId={selectedPlanId}
            refreshCells={refreshCells}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={maxDeletesGridColDef}
            rowEditType={'single'}
            suppressclickEdit={!maxDeletesGridColDef.defaultColDef.editable||pending}
            deleteSelectedRows={deleteSelectedRows}
            handleMultipleRowDelete={handleMultipleRowDelete}
            userMessage={userMessage}
            loadGridData={loadGridData}
            isPaginationRequired={true}/>)
            :(<MultiGridUI 
            reducer={maxDeletesgridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={'maxdeletegrid'}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={maxDeletesGridColDef}
            suppressclickEdit={!maxDeletesGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            maxDeletesTransactions={maxDeletesTransactions}            
            userMessage={userMessage}/>
        )}
     </div>
 )
}

export default MaxDeletesTabPanel;