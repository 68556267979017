import { combineReducers } from 'redux';
import DeprateReducer from './DepRate.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import depRateColumnDefinitionReducer from "./DepRate.ColumnDef.Reducer";

const rootDepRateReducer = combineReducers({
      DeprateReducer
    , depRateGridReducer: createNamedWrapperReducer(multiGridUIReducer, "deprategrid")
    , depRateColumnDefinitionReducer
})

export default rootDepRateReducer;