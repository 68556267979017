import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminMultiGridUI from '../../../components/admin/grid/AdminMultiGridUI';
import UserManagementActionbar from '../../../components/admin/usermanagementactionbar/UserManagementActionbar';
import { fetchRoleManagementData } from '../../../store/admin/rolemanagement/RoleManagement.Actions';
import { fetchUserManagementData } from '../../../store/admin/usermanagement/UserManagement.Actions';

const UserManagementTabPanel = (props)=>{
    const{userManagementData,refreshCells,gridReducer,columnDefinitionReducer,tabInfo,tabIndex,transactions,currentData,showSaveOption,handleSaveClicked,handleCancelClicked,handleRowEditingStarted,handleRowEditingStopped,handleSaveCancelDisabled,handleCellEditingStopped} = props;

    return(
       <div>
        <UserManagementActionbar
            data={gridReducer.currentData}
            userManagementData={userManagementData}
            userManagementGridColDef={columnDefinitionReducer}
            transactions={transactions}
            //selectedPlanId={selectedPlanId}
            options={showSaveOption}
            //bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
            // onAddNewRowClick={handleAddNewRow}
            handleSaveCancelDisabled={handleSaveCancelDisabled}
            onSaveClicked={handleSaveClicked}
            onCancelClicked={handleCancelClicked}
            // deleteSelectedRows={deleteSelectedRows}
            // selectedRows={selectedRows}
            // downloadCSV={downloadCSV}
            // filterParams={filterParams}
            // filterData={filterData}
            // isFiltered={isFiltered}
            // setIsFiltered={setIsFiltered}
            // isSearchBarValueExists={isSearchBarValueExists}
            // setIsSearchBarValueExists={setIsSearchBarValueExists}
        />
        <AdminMultiGridUI
        reducer={gridReducer}
        gridName={"usermanagementgrid"}
        refreshCells={refreshCells}
        gridColDef={columnDefinitionReducer}
        rowEditType={'single'}
        suppressclickEdit={!columnDefinitionReducer.defaultColDef.editable}
        onRowEditingStarted={handleRowEditingStarted}
        onRowEditingStopped={handleRowEditingStopped}
        onCellEditingStopped={handleCellEditingStopped}
        // deleteSelectedRows={deleteSelectedRows}
        // handleMultipleRowDelete={handleMultipleRowDelete}
        ></AdminMultiGridUI>
       </div> 
    )
}
export default UserManagementTabPanel;