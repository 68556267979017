import {getMileageDefaultData, saveMileageDefaultTransactions, fetchMileageDefaultDropdownService,uploadMileageDefaultCSV} from '../../services/mileagedefault/MileageDefaultService';
import {FETCH_MILEAGEDEFAULT_DATA_PENDING, FETCH_MILEAGEDEFAULT_DATA_SUCCESS, FETCH_MILEAGEDEFAULT_DATA_ERROR, UPLOAD_MILEAGEDEFAULT_PENDING, UPLOAD_MILEAGEDEFAULT_SUCCESS, UPLOAD_MILEAGEDEFAULT_ERROR,
    SAVE_MILEAGEDEFAULT_TRANSACTION_PENDING, SAVE_MILEAGEDEFAULT_TRANSACTION_SUCCESS, SAVE_MILEAGEDEFAULT_TRANSACTION_ERROR,
    FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_PENDING,FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_SUCCESS,FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR, RESET_TO_MILEAGEDEFAULT_INITIALSTATE} from '../../store/mileagedefault/MileageDefault.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchMileageDefaultData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MILEAGEDEFAULT_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getMileageDefaultData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MILEAGEDEFAULT_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MILEAGEDEFAULT_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MILEAGEDEFAULT_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadMileageDefaultData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_MILEAGEDEFAULT_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadMileageDefaultCSV(planId, files);
            let mileageDefaultData = await getMileageDefaultData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_MILEAGEDEFAULT_SUCCESS, mileageDefaultData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_MILEAGEDEFAULT_ERROR, "mileage default upload failed"));
                dispatch(SetAssumptionError("mileage default upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_MILEAGEDEFAULT_ERROR, "mileage default upload failed"));
            dispatch(SetAssumptionError("mileage default upload failed"))
        }
    }
}

export const saveMileageDefaultTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MILEAGEDEFAULT_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveMileageDefaultTransactions(planid, transactionList);
            let mileageDefaultData = await getMileageDefaultData(planid);
            if (mileageDefaultData) {
                dispatch(serviceActionSuccess(SAVE_MILEAGEDEFAULT_TRANSACTION_SUCCESS, mileageDefaultData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_MILEAGEDEFAULT_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchMileageDefaultDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_PENDING));
        let mileageDefaultData = await fetchMileageDefaultDropdownService(planid);
        if (mileageDefaultData) {
            dispatch(serviceActionSuccess(FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_SUCCESS, mileageDefaultData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("mileagedefaultGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{ // this else is for old architecture and will be removed later               
                dispatch(validateGrid("mileagedefaultgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
            }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR, mileageDefaultData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetMileageDefault = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MILEAGEDEFAULT_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}