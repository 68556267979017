import { SET_RF_ERROR_VALUE, SET_RF_INDEX_VALUE, SET_RF_PENDING_VALUE } from "./RevenueFunction.ActionTypes";


export const setRFIndex = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_RF_INDEX_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const SetRFPending = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_RF_PENDING_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const SetRFError = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_RF_ERROR_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}