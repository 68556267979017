import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "./TopBar.css";
import icon_copy from "../../assets/images/icon_copy.svg";
import icon_add from "../../assets/images/btn-add.svg";
import btn_AllPlan from "../../assets/images/btn-AllPlan.svg";
import icon_ChevronDown from "../../assets/images/icon_ChevronDown.png";
import Popover from "@material-ui/core/Popover";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import PlansDropdown from "./PlansDropdown.css";
import {
  getPlanBySelectedPlanId,
  getZoneRfData,
  getPoolRfData,
  startPlanRun,
  activateCreatePlan,
  updateCreatePlanStatus,
} from "../../store/topbar/AllPlans.Actions";
import { plansDateFormatter } from "../../utils/formatters/valueFormatter";
import { setDate } from "date-fns";
import { userAcs } from "../../App";
import { pollTimeoutInterval } from "../../Constants";
import JobInProgressDialog from "../Dialogcomponent/JobInProgressDialogComponent";
import { Snackbar, Tooltip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const PlansList = (props) => {
  const [Allplans] = useState("All Plans");
  const [Copyplan] = useState("Copy Plan");
  const [Newplan] = useState("New Plan");
  const [dropdownPopUp, setdropdownPopUp] = useState(null);
  const [newplan, setNewPlan] = useState(false);
  const [isCopyPlan, setCopyPlan] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(Allplans);
  const [timerVariable,setTimerVariable]=useState(null);
  const [jobInProgressDialogOpen, setJobInProgressDialogOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [showMessage,setShowMessage] = useState(false);
  const creationInProgressTitle = "Create plan";
  const creationInProgress = "Creating a new plan,please wait";
  const dispatch = useDispatch();
  const { state } = useLocation();
  let stateDateTime =
    state !== undefined && state.datetime !== undefined ? state.datetime : "";
  const { planid } = useParams();
  const selectedPlanId =
    state !== undefined && state.selectedplan !== undefined
      ? state.selectedplan
      : "";
  const [planId, setPlanId] = useState();
  const history = useHistory();
  let pathname = history.location.pathname;
  const [planDate, setPlanDate] = useState("");
  // const [allPlansData,setAllPlansData]=useState([]);

  var allPlansData = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.allplans;
  });

  const newPlanId = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.newPlanId;
  });

  const plansDataById = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.plansDataById;
  });

  const zoneRfData = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.zoneRfData;
  });

  const poolRfData = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.poolRfData;
  });

  const createPlanJobId = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.createPlanJobId;
  })
  
  let planCreateSuccessful=useSelector((reduxstate)=>{
  return reduxstate.rootAllPlansReducer.allPlansReducer.planCreateSuccessful;
  });

  const  error = useSelector((reduxState) => {
    return reduxState.rootAllPlansReducer.allPlansReducer.error;
  })

  const LoadCopyPlanDetails = (plansDataById) => {
    let dateTime = plansDateFormatter(plansDataById[0].date);
    setSelectedPlan("Plan" + " " + "#" + newPlanId + " " + dateTime);
    setPlanId(newPlanId);
    setPlanDate(dateTime);
    loadZoneRfData(newPlanId);
    loadPoolRfData(newPlanId);
    let url = "/plan/" + newPlanId + "/planinputs";
    history.replace({
      pathname: url,
      state: {
        // location state
        selectedplan: newPlanId,
      },
    });
    return history;
  };

  const userMessage = (severity, message) => {
    setWarningSnackBarOpen(true);
    setSeverity(severity);
    setWarningMessage(message);
  }
  
  const handleCloseWarningSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningSnackBarOpen(false);
  };

  useEffect(() => {
    if(planCreateSuccessful!==null){
      if( showMessage===false && planCreateSuccessful===true){
        userMessage('success', 'Successfully created plan.')
        setShowMessage(true)
      } else if(showMessage===false && planCreateSuccessful===false){
        if(error){
          userMessage('error', error);
          setShowMessage(true)
        }else{
          userMessage('error', 'Failed to create plan.');
          setShowMessage(true)
        }
        
      }
        setJobInProgressDialogOpen(false);
        clearInterval(timerVariable);
        setTimerVariable(null);
    }
    if (history.location.pathname.toString().indexOf("adjustments") > -1) {
      let url = "/plan/" + selectedPlanId + "/adjustments";
      history.push({
        pathname: url,
        state: {
          // location state
          selectedplan: selectedPlanId,
        },
      });
    }
    if (selectedPlanId != "" && selectedPlanId !== undefined) {
      if (stateDateTime !== undefined && stateDateTime !== "") {
        setSelectedPlan(
          "Plan" + " " + "#" + selectedPlanId + " " + stateDateTime
        );
      } else {
        setSelectedPlan("Plan" + " " + "#" + selectedPlanId + " " + planDate);
      }
    }
    if (isCopyPlan == true && newPlanId !== "") {
      loadPlanDataById(newPlanId);
    }
    if (
      isCopyPlan == true &&
      plansDataById !== undefined &&
      plansDataById.length > 0 &&
      plansDataById[0].id == newPlanId
    ) {
      setCopyPlan(false);
      LoadCopyPlanDetails(plansDataById);
    }
    if (newplan == true && newPlanId !== "") {
      loadPlanDataById(newPlanId);
    }
    if (
      newplan == true &&
      plansDataById !== undefined &&
      plansDataById.length > 0 &&
      plansDataById[0].id == newPlanId
    ) {
      setNewPlan(false);
      LoadCopyPlanDetails(plansDataById);
    }
  }, [selectedPlanId, plansDataById, newPlanId,planCreateSuccessful]);

  let links = [
    {
      name: "All Plans",
      icon: btn_AllPlan,
    },
    {
      name: "New Plan",
      icon: icon_add,
    },
  ];

  const handleClick = (event) => {
    setdropdownPopUp(event.currentTarget);
  };

  const handleClose = () => {
    setdropdownPopUp(null);
  };
  const open = Boolean(dropdownPopUp);
  const id = open ? "" : undefined;

  const loadPlanDataById = (planid) => {
    dispatch(getPlanBySelectedPlanId(planid));
  };

  const loadZoneRfData = (planid) => {
    dispatch(getZoneRfData(planid));
  };

  const loadPoolRfData = (planid) => {
    dispatch(getPoolRfData(planid));
  };

  const getSelectedOptn = (planid, desc, datetime, status, copylink) => {
    if (copylink !== undefined) {
      dispatch(activateCreatePlan(planid));
      setJobInProgressDialogOpen(true);
      setCopyPlan(true);
      handleClose();
    } else {
      setCopyPlan(false);
      loadPlanDataById(planid);
      loadZoneRfData(planid);
      loadPoolRfData(planid);

      let url = "/plan/" + planid + "/planinputs";
      setNewPlan(false);
      setSelectedPlan("Plan" + " " + "#" + planid + " " + datetime);
      setPlanId(planid);
      setPlanDate(datetime);
      history.push({
        pathname: url,
        state: {
          // location state
          selectedplan: planid,
        },
      });
      handleClose();
    }
  };

  const getSelectedPlanLink = (linkname) => {
    setCopyPlan(false);
    if (linkname == "New Plan") {
      setNewPlan(true);
      dispatch(activateCreatePlan());
      setJobInProgressDialogOpen(true);
    } else if (linkname == "All Plans") {
      setNewPlan(false);
      setSelectedPlan(linkname);
    }
    handleClose();
  };
  useEffect(()=>{
    if(jobInProgressDialogOpen && createPlanJobId>0){
        setTimerVariable(setInterval(()=>dispatch(updateCreatePlanStatus(createPlanJobId)),pollTimeoutInterval));
    }        
  },[createPlanJobId]);

  // useEffect(()=>{
  //   if(originalAllPlansData && originalAllPlansData.length){
  //     let filtereddata = originalAllPlansData.filter(element=>{        
  //       return (element.deleted==='No' && element.hidden==='No');// if any one of them is No plan should be hidden in UI
  //     });
  //     // console.log("filtereddata==>",filtereddata);
  //     setAllPlansData(filtereddata);
  //   }
  // },[originalAllPlansData])

  const Dropdownval =  useContext(userAcs);
  
  const popoverId = "popoverId";
  return (
    <div>
      <div
        className={dropdownPopUp ? "popupopen" : "popupclose"}
        aria-describedby={id}
        color="primary"
        //onClick will allow the user to access the drop down if not editing
        onClick = {(Dropdownval.defuserAcs === true) ? handleClose : handleClick}
      >
        {isCopyPlan || newplan ? (
          <div className="planwidth">
            <label className="selectedplan">{selectedPlan}</label>
          </div>
        ) : (
          // <div className="planwidth"><label className="selectedplan" >Plan #{planId}</label>
          //     <span>
          //         <label className="newtext">{"new"}</label>
          //     </span>
          //     <span>{planDate}</span>
          // </div>
          <div className="planwidth">
            <label className="selectedplan">
              {pathname === "/"
                ? "All Plans"
                : pathname == "/plan/" + selectedPlanId + "/planinputs"
                ? selectedPlan
                : pathname == "/plan/" + selectedPlanId + "/adjustments"
                ? selectedPlan
                : "All Plans"}
            </label>
          </div>
        )}
        <div className="iconwidth">
          <img src={icon_ChevronDown} className="chevrondown"></img>
        </div>
      </div>
      {allPlansData !== undefined && allPlansData.length > 0 ? (
        <Popover
          className="popover"
          id={popoverId}
          open={open}
          anchorEl={dropdownPopUp}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div className="plansdropdown">
            <ul className="planlist">
              {allPlansData.length > 0 ? (
                allPlansData
                  .sort((a, b) => b.id - a.id)
                  .map((plan, index) =>
                    index < 10 ? (
                      <li key={index} className="planliststyle">
                        <span
                          onClick={() =>
                            getSelectedOptn(
                              plan.id,
                              plan.description,
                              plansDateFormatter(plan.date),
                              plan.status
                            )
                          }
                        >
                          {plan.id}
                        </span>
                        {plan.description == " " ||
                        plan.description == undefined ||
                        plan.description == null ? (
                          <span>No description</span>
                        ) : (
                          <span>{plan.description}</span>
                        )}
                        {plan.pool_month == 0 || plan.pool_month == "" ? (
                          <span>-</span>
                        ) : (
                          <span>{plan.pool_month}&nbsp;PM</span>
                        )}
                        <span>{plansDateFormatter(plan.date)}</span>
                        <Tooltip title={plan.status} disableHoverListener={!(plan.status.toLowerCase() === "building model" || plan.status.toLowerCase() === "optimization queued" || plan.status.toLowerCase() === "optimizing" || plan.status.toLowerCase() === "pulling data")} placement="bottom-end">
                        <span
                          className={
                            plan.status.toLowerCase() == "new"
                              ? "plannew statusstyle"
                              : plan.status.toLowerCase() == "ready"
                              ? "planready statusstyle"
                              : plan.status.toLowerCase() == "building model"
                              ? "planrunning statusstyle"
                              : plan.status.toLowerCase() == "optimization queued"
                              ? "planrunning statusstyle"
                              : plan.status.toLowerCase() == "optimizing"
                              ? "planrunning statusstyle"
                              : plan.status.toLowerCase() == "published"
                              ? "planpublish statusstyle"
                              : plan.status.toLowerCase() == "optimization complete"
                              ? "plancomplete statusstyle"
                              : plan.status.toLowerCase() == "error"
                              ? "planfail statusstyle"
                              : plan.status.toLowerCase() == "adjustment complete"
                              ? "planadjustmentcomplete"
                              : plan.status.toLowerCase() == "apply adjustments"
                              ? "planready statusstyle"
                              : plan.status.toLowerCase() == "sales adjustments ready"
                              ? "planready statusstyle"
                              : plan.status.toLowerCase() == "vin dep process ready"
                              ? "planready statusstyle"
                              : plan.status.toLowerCase() == "sales adjustments running"
                              ? "planrunning statusstyle"
                              : plan.status.toLowerCase() == "vin dep process running"
                              ? "planrunning statusstyle"
                              : plan.status.toLowerCase() == "sales adjustments completed"
                              ? "planadjustmentcomplete"
                              : plan.status.toLowerCase() == "vin dep process completed"
                              ? "planadjustmentcomplete"
                              : plan.status.toLowerCase() == "pulling data"
                              ? "planrunning statusstyle"
                              : plan.status.toLowerCase() === "optimization complete as sub-optim"
                              ? "pdSupOptm statusstyle"
                              : plan.status.toLowerCase() === "optimization complete as optimal"
                              ? "plancomplete statusstyle"
                              : plan.status.toLowerCase() === "optimization complete unknown status"
                              ? "runUnknown statusstyle"
                              : plan.status.toLowerCase() === "editing adjustments"
                              ? "EditingAdjustments statusstyle"
                              : plan.status.toLowerCase() === "adjustments modified"
                              ? "AdjustmentsModified statusstyle"
                              : "planrunning statusstyle"
                          }
                        >
                          {plan.status.toLowerCase() === "new"
                            ? "New"
                            : plan.status.toLowerCase() === "ready"
                            ? "Ready"
                            : plan.status.toLowerCase() === "building model"
                            ? "Running"
                            : plan.status.toLowerCase() === "optimization queued"
                            ? "Running"
                            : plan.status.toLowerCase() === "optimizing"
                            ? "Running"
                            : plan.status.toLowerCase() === "published"
                            ? "Published"
                            : plan.status.toLowerCase() === "optimization complete"
                            ? "Completed"
                            : plan.status.toLowerCase() === "error"
                            ? "Failed"
                            : plan.status.toLowerCase() === "adjustment complete"
                            ? "Adjustment Complete"
                            : plan.status.toLowerCase() === "apply adjustments"
                            ? "Adjustment In Process"
                            : plan.status.toLowerCase() === "sales adjustments ready"
                            ? "Sales Adjustments Ready"
                            : plan.status.toLowerCase() === "vin dep process ready"
                            ? "VIN Dep Process Ready"
                            : plan.status.toLowerCase() == "sales adjustments running"
                            ? "Running"
                            : plan.status.toLowerCase() == "vin dep process running"
                            ? "Running"
                            : plan.status.toLowerCase() == "sales adjustments completed"
                            ? "Sales Adjustments Completed"
                            : plan.status.toLowerCase() == "vin dep process completed"
                            ? "VIN Dep Process Completed"
                            : plan.status.toLowerCase() === "pulling data"
                            ? "Running"
                            : plan.status.toLowerCase() === "optimization complete as sub-optim"
                            ? "Completed - Sub Optimal"
                            : plan.status.toLowerCase() === "optimization complete as optimal"
                            ? "Completed - Optimal"
                            : plan.status.toLowerCase() === "optimization complete unknown status"
                            ? "Completed - Unknown"
                            : plan.status.toLowerCase() === "editing adjustments"
                            ? "Editing Adjustments"
                            : plan.status.toLowerCase() === "adjustments modified"
                            ? "Adjustments Modified"
                            : "Running"}
                        </span>
                        </Tooltip>
                        <Link
                          onClick={() =>
                            getSelectedOptn(
                              plan.id,
                              plan.description,
                              plansDateFormatter(plan.date),
                              plan.status,
                              "copylink"
                            )
                          }
                          className="copylink"
                        >
                          <img src={icon_copy} className="copyimg"></img>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )
                  )
              ) : (
                <div></div>
              )}
            </ul>
          </div>
          <div className="planlinks">
            {links.map((link, index) => (
              <Link
                onClick={() => getSelectedPlanLink(link.name)}
                to={
                  link.name == "All Plans"
                    ? { pathname: `/`, state: allPlansData }
                    : ""
                }
                key={index}
              >
                <img src={link.icon} className="linksimg"></img>
                <label className="linkname">{link.name}</label>
              </Link>
            ))}
          </div>
        </Popover>
      ) : (
        <div></div>
      )}
      <JobInProgressDialog open={jobInProgressDialogOpen} 
        dialogMessage={creationInProgress} title={creationInProgressTitle}>
    </JobInProgressDialog>
    <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
      <Alert
        onClose={handleCloseWarningSnackBar}
        severity={severity}>{warningMessage}
      </Alert>
    </Snackbar>
    </div>
  );
};
export default PlansList;
