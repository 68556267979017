import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getTargetZonePoolData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisetargetZonePoolResponse = await client.apis.plans.getAllTargetZonePool({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno : 0 });
        
        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            const responsesArray = await Promise.all([promisetargetZonePoolResponse])
            const [targetZonePoolResponse] = responsesArray.map(o => o.body.data);
  
            return ({
                "gridData": targetZonePoolResponse.rows, "rowcount": targetZonePoolResponse.rowCount
            })
        }
        else {
            //this else condition executes when it is older version
            const promiseZoneResponse = checkAndLoadDimention("zone", client);
            const responsesArray = await Promise.all([promisetargetZonePoolResponse, promiseZoneResponse]);
            const [targetZonePoolResponse, zoneResponse] = responsesArray.map(o => o.body.data);
            return ({
                "targetZonePoolData": targetZonePoolResponse, "zones": zoneResponse
            });
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Demand Target by Zone tab'
    }
}

export  async function uploadTargetZonePoolCSV (planid,file){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadTargetZonePool({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Demand Target by Zone tab, Please try again!!');
    }
}

export async function saveTargetZonePoolTransactions(planid, transactionList){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.plans.bulktransactTargetZonePool({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": transactionList } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function fetchTargetZonePoolDropdownService(planId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

   // const TargetZonePoolResponseData =  client.apis.plans.getAllTargetZonePool({ "planid": planId ? planId : 'master' });
    const promiseZoneResponse =  checkAndLoadDimention("zone", client);
    const promiseBuResponse =  checkAndLoadDimention("BU", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([
     //   TargetZonePoolResponseData,
        promiseZoneResponse,
        promiseBuResponse,
        promisePoolResponse,
        promiseAdminPoolResponse
    ]);

    const [
        //  targetZonePoolResponse,
          ZoneResponse,
          buResponse,
          poolResponse,
          adminPoolResponse
    ] = responsesArray.map(o => o.body.data);

    return ({
       // "targetZonePoolData":targetZonePoolResponse,
        "zone": ZoneResponse,
        "bu": buResponse,
        "pool": poolResponse,
        "adminpools":adminPoolResponse,
        "users":promiseUserManagementEmailAndIdMap
    })  
}

export async function getTargetZonePoolFilterData(planId, pageno, filterparams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseTargetZonePoolFilterResponse = await client.apis.plans.getTargetZonePoolFilterRecords({ 'pageno': pageno }, { requestBody: { "planid": planId ? planId : 'master', 'filterparams': filterparams } });
        const responsesArray = await Promise.all([promiseTargetZonePoolFilterResponse]);
        const [targetzonepoolResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": targetzonepoolResponse.rows, "rowcount": targetzonepoolResponse.rows[0] ? targetzonepoolResponse.rows[0].rowcount : 0
        })
    }
    catch (error) {
        console.error(error);
        throw 'Demand Target by Zone tab has internal server error, please do re-apply the filter'
    }
}

export async function deleteAllTargetZonePoolData(planid, params) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        let promisetargetzonepoolDeleteResponse = await client.apis.plans.deleteAllTargetZonePoolRecords({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, ...params } });

        if (promisetargetzonepoolDeleteResponse.obj && promisetargetzonepoolDeleteResponse.obj.statusCode !== '500') {
            return promisetargetzonepoolDeleteResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Demand Target by Zone tab has internal server error, Unable to delete all the records!! Please try again'
    }
}

export async function insertS3UploadTargetZonePoolCSV(planid, filename, gridname) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();

        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

        const response = await client.apis.plans.insertS3UploadedTargetZonePoolData({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "gridName": gridname.replace(/grid/gi, ''),
                "fileName": filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Demand Target by Zone tab, Please try again!!');
    }
}

export async function targetzonepoolTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateTargetZonePool({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });

        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Demand Target by Zone Validation status ,Please try again!!';
    }
}

export async function getTargetZonePoolTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseTargetZonePoolResponse = await client.apis.plans.getTargetZonePoolTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseTargetZonePoolResponse.obj && promiseTargetZonePoolResponse.obj.statusCode !== '500') {
            const responsesArray = await Promise.all([promiseTargetZonePoolResponse]);
            const [targetzonepoolTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
            return targetzonepoolTabErrorStatusResponse;
        }
        else {
            throw Error();
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Demand Target by Zone error status ,Please try again!!';
    }
}

export async function getTargetZonePoolErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promisetargetzonepoolResponse = await client.apis.plans.getTargetZonePoolErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promisetargetzonepoolResponse]);
        const [targetzonepoolResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": targetzonepoolResponse.rows, "rowcount": targetzonepoolResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Demand Target by Zone tab'
    }
}

export async function getTargetZonePoolSearchData(planid, pageno, searchParams) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseTargetZonePoolSearchResponse = await client.apis.plans.getTargetZonePoolSearchRecords({ "planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseTargetZonePoolSearchResponse]);

        const [targetzonepoolSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": targetzonepoolSearchResponse.rows, "rowcount": targetzonepoolSearchResponse.rows[0] ? targetzonepoolSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Demand Target by Zone tab'
    }
}