import { columnDefs, defaultColDef } from "./planManagement_grid_data/PlanManagementColumnDefs";
import { FETCH_PLAN_MANAGEMENT_DROPDOWN_PENDING, FETCH_PLAN_MANAGEMENT_DROPDOWN_SUCCESS, FETCH_PLAN_MANAGEMENT_DROPDOWN_ERROR} from "./PlanManagement.ActionTypes";

const initialState = {
    columnDefs,
    defaultColDef,
    validationFn: function (data) {
        return {
            ID: data.ID,
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    
}

function setColumnDefParams(columnDefs) {
    columnDefs.map((column) => {
        if (column.field === "deleted" || column.field === "hidden") {
            column.cellEditorParams = { options: ['Yes','No'] }
        }
    });
    return columnDefs;
}

export default function columnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PLAN_MANAGEMENT_DROPDOWN_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_PLAN_MANAGEMENT_DROPDOWN_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs)
            });
        case FETCH_PLAN_MANAGEMENT_DROPDOWN_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}