import React from "react";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SectionRowsComponent from './ReadOnlySectionRowsComponent';
import { ScrollSyncPane } from 'react-scroll-sync';
import EndFleetSectionRowsComponent from "./EndFleetSectionRowsComponent";



const EditableSectionSummaryComponent = (props) => {
    const { startFleetValue, accordionContent, isExpanded, applyMargin,selectedVal, isOfTypeEndFleet, content, planStatus, addStartFleet } = props;
    return (<AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel-header"
        TransitionProps={{ unmountOnExit: true }}>

        <div className="rowFlex">
            <label className='adjustmentheading'>{(accordionContent.header == 'REPURCHASE_DELETES' ? 'REPURCHASE DELETES' : accordionContent.header == 'ALL' ? 'DIVISION' : accordionContent.header)}</label>
            {((accordionContent.header=='START_FLEET')?true:isExpanded) ?
                <ScrollSyncPane group="horizontal"><span 
                className={applyMargin == true ? (content==true ? "marginOverall": "acivitymarginOverall") : "noMarginOverall"}>
                    {isOfTypeEndFleet ? <EndFleetSectionRowsComponent startFleetValue={startFleetValue} selectedVal={selectedVal} accordionContent={accordionContent.values[0]} isExpanded={isExpanded} planStatus={planStatus} addStartFleet={addStartFleet}/> : 
                     <SectionRowsComponent startFleetValue={startFleetValue} accordionContent={accordionContent.values[0]} isExpanded={isExpanded} content={content} planStatus={planStatus}/>}
                </span></ScrollSyncPane> : ''}
        </div>
    </AccordionSummary>)
}

export default EditableSectionSummaryComponent;


