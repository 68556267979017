import { columnDefs, defaultColDef } from "./depRate_grid_data/depRateColumnDefs";
import { FETCH_DEPRATE_DROPDOWNVALUES_SUCCESS, SET_DEPRATE_EDITABLE} from '../deprate/DepRate.ActionTypes';
import { DepRateColumns } from "../../Constants";
import { safeCaseInsensitiveCompare } from "../../utils/utils";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            CAR_GROUP: { error: checkIfValueEmpty(data.CAR_GROUP, DepRateColumns.CarGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===DepRateColumns.CarGroup.field)) },
            DEP_RATE: { error: checkForDepValue(data.DEP_RATE, DepRateColumns.DepRate.headerName) },
            DEP_ADJUSTMENT: { error: checkForDepValue(data.DEP_ADJUSTMENT, DepRateColumns.DepAdjustment.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}
function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) { 
                if (safeCaseInsensitiveCompare(itm.CAR_GROUP,itm2.CAR_GROUP)){
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                } 
            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            transactions.forEach((itm1,key)=>{
                if(itm1.data){
                  if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                    transactions[key].data.status=itm.status
                  }
                }
              })
    });
    return duplicate;
}
function checkIfValueEmpty(value, name,index) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(initialState.columnDefs[index].cellEditorParams !== undefined){
        let allCarGroup =sessionStorage.getItem("CARGROUP") ? (JSON.parse(sessionStorage.getItem("CARGROUP"))).body.data:[];
        if (allCarGroup && allCarGroup.length && (allCarGroup.indexOf(value) == -1)) {
            return name + " " + value + " no-longer available";
        }
    }
    return null;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkForDepValue(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } 
    return null;
}

function fetchDropdownsForCarGroups(element,carGroups,admincarGroup) {
    let carGroupFilterData = []
    admincarGroup.map((val) => {
        carGroupFilterData.push({ "label": val, "value": val })
    })
    carGroupFilterData.push({ "label": "Blanks", "value": "Blanks" });
    element.cellEditorParams = { options: carGroups };
    element.filterOptions = carGroupFilterData;
}

function fetchDropDownValues(columns,carGroups,admincarGroup,users) {
    columns.forEach(element => {
        if (element.field === DepRateColumns.CarGroup.field) {
            fetchDropdownsForCarGroups(element,carGroups,admincarGroup);
        }else if(element.field === DepRateColumns.UpdatedBy.field){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'DepRateActionRenderer'
            }
        }
    });

    return columns;
}

export default function depRateColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_DEPRATE_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchDropDownValues(state.columnDefs,action.data.carGroups,action.data.admincarGroup,action.data.users)
            });
        case SET_DEPRATE_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}