import React from 'react';
import MultiGridUI from '../../../components/revenuefunction/grid/RevenueFunctionMultiGridUI';
import ActionBar from '../../../components/revenuefunction/allrfsactionbar/AllRFsActionBar';

const AllRfsTabPanel = (props)=>{
    const{allRFsData,allRFsGridReducer,allRFscolumnDefinitionReducer,allRFsFilterSearchKey,handleRowEditingStarted,handleRowEditingStopped,handleCellEditingStopped,refreshCells,setIsFiltered,isFiltered,transactions,setIsSearchBarValueExists,isSearchBarValueExists} = props;
    return(
        <div>
        <ActionBar
         allRFsData={allRFsData}
         allRFsGridReducer={allRFsGridReducer}
         allRFscolumnDefinitionReducer={allRFscolumnDefinitionReducer}
         filterSearchKey={allRFsFilterSearchKey}
         setIsFiltered={setIsFiltered}
         isFiltered={isFiltered}
         transactions={transactions}
         isSearchBarValueExists={isSearchBarValueExists}
         setIsSearchBarValueExists={setIsSearchBarValueExists}
         >
        </ActionBar>
        <MultiGridUI
         gridName='allrfsgrid'
         reducer={allRFsGridReducer}
         gridColDef={allRFscolumnDefinitionReducer}
         refreshCells={refreshCells}
         onRowEditingStarted={handleRowEditingStarted}
         onRowEditingStopped={handleRowEditingStopped}
         onCellEditingStopped={handleCellEditingStopped}
         >
        </MultiGridUI>
        </div>
    )
}
export default AllRfsTabPanel;