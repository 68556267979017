import React, { useState } from "react";
import { TextFieldInput } from '../../core-components/Input/Input';
import { Tooltip } from '@material-ui/core';
import './ConstraintAccordionContent.css';


const MinMaxEditableComponent = (props) => {
    const { tooltipTitle, textFieldStyle, textFieldValue, content, onValueChanged, focusible } = props;


    return (<Tooltip title={tooltipTitle}
        placement="top">
        <TextFieldInput
            className={textFieldStyle}
            type="number"
            id="BasicOutline"
            value={textFieldValue}
            variant="outlined"
            defaultValue={textFieldValue}
            onTextChanged={(e) => onValueChanged(e, content)}
            autoFocus={focusible} /></Tooltip>)

}
export default MinMaxEditableComponent;