import React, { useState, useEffect } from "react";
import icon_delete from "../../assets/images/icon_trash.svg";
import icon_edit from "../../assets/images/icon_edit.svg";
import { dateFormatter, localeFormatter } from "../../utils/formatters/valueFormatter";
import { deleteConstraintRecord, saveConstraintsAccordionData } from "../../store/constraints/Constraints.Actions";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../Dialogcomponent/DialogComponent";
import { Button } from '@material-ui/core';
import MinMaxEditableComponent from './MinMaxEditableComponent';
import './ConstraintAccordionContent.css';


const ConstraintAccordionContent = (props) => {
    const { data, planid, handleEditEvent, editedContent, constraintType } = props;
    const [selectedRowData, setSelectedRowData] = useState({});
    const [minValueCellStyle, setMinValueCellStyle] = useState('minFieldUI');
    const [maxValueCellStyle, setMaxValueCellStyle] = useState('minFieldUI');
    const [open, setOpen] = useState(false);
    const [minValueError, setMinValueError] = useState();
    const [maxValueError, setMaxValueError] = useState();
    const [content, setContent] = useState();
    const dispatch = useDispatch();
    let message = "Are you sure that you want to delete this row?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";

    // const useStyles = makeStyles(theme => ({
    //     tooltip: {
    //         backgroundColor: '#f6f6f6',
    //         border: "0.5px solid #939393",
    //         fontSize: '13px !important',
    //         fontFamily: 'customFontFamily !important',
    //         color: "#3f3f3f"
    //     }
    // }));
    // let classes = useStyles();

    var isConstraintsAccordionInEditMode = useSelector((state, shallowEqual) => {
        return state.rootConstraintsReducer.constraintsReducer.isAccordionDataInEditMode;
    });

    const constraintTransactionSaveSuccessful = useSelector((state) => {
        return state.rootConstraintsReducer.constraintsReducer.accordionTransactionSaveSuccess;
    });

    function handleClickOpen(content) {
        setSelectedRowData(content);
        setOpen(true);
    };

    function handleClickEdit(content) {
        if (!content.min || content.min == null) {
            setMinValueCellStyle('minFieldUIError');
            setMinValueError('Min volume cannot be empty')
        }

        if (!content.max || content.max == null) {
            setMinValueCellStyle('minFieldUIError');
            setMinValueError('Max volume cannot be empty')
        }
        selectedRowData.min = content.min;
        selectedRowData.max = content.max;
        return handleEditEvent(content, "edit");
    }

    function onCancelClicked(content) {
        setSelectedRowData(data);
        setMinValueCellStyle('minFieldUI');
        setMaxValueCellStyle('minFieldUI');
        return handleEditEvent(content, "cancel");
    }

    function onSaveClicked(content) {
        let transactions = [];
        content.min = selectedRowData.min;
        content.max = selectedRowData.max;
        transactions.push({ type: 'edit', id: content.ID, data: content })
        dispatch(saveConstraintsAccordionData(planid, transactions, constraintType));
        setContent(content);
        setMinValueCellStyle('minFieldUI');
        setMaxValueCellStyle('minFieldUI');
    }

    function validateMinMaxFields(value, constraintType, type) {
        if (value === '') {
            if (type === 'min') {
                return 'Min volume cannot be empty'
            }
            if (type === 'max') {
                return 'Max volume cannot be empty'
            }
        }
        if (constraintType === 'NETGL_PM' || constraintType === 'NETGL_ZM') {
            if (value > 99999) {
                return 'Please enter valid ' + type + ' volume';
            }
        } else {
            if (value < 0 || value > 99999) {
                return 'Please enter valid ' + type + ' volume';
            }
        }
        return null;
    }

    function onMinValueChanged(e, content) {
        if (e.target.value == null || e.target.value == '') {
            setMinValueCellStyle('minFieldUIError');
            setMinValueError("Min value cannot be empty")
        } else {
            selectedRowData.min = parseInt(e.target.value, 0);
            let minMaxFields = validateMinMaxFields(selectedRowData.min, data.constraint_type, 'min');
            if (minMaxFields == null) {
                if (selectedRowData.min > selectedRowData.max) {
                    setMinValueCellStyle('minFieldUIError');
                    setMinValueError('Min volume should be less than max volume')
                } else if (selectedRowData.min <= selectedRowData.max) {
                    let maxField = validateMinMaxFields(selectedRowData.max, data.constraint_type, 'max');
                    if (maxField == null) {
                        if (maxValueCellStyle == 'minFieldUIError') {
                            setMaxValueCellStyle('minFieldUISuccess');
                            setMaxValueError(null)
                        }
                        setMinValueCellStyle('minFieldUISuccess');
                        setMinValueError(null)
                    } else {
                        setMaxValueCellStyle('minFieldUIError');
                        setMaxValueError('Min volume should be less than max volume')
                    }
                }
            } else {
                setMinValueCellStyle('minFieldUIError');
                setMinValueError(minMaxFields)
            }
        }
    }

    function onMaxValueChanged(e, content) {
        if (e.target.value == null || e.target.value == '') {
            setMaxValueCellStyle('minFieldUIError');
            setMaxValueError("Max value cannot be empty")
        } else {
            selectedRowData.max = parseInt(e.target.value, 0);
            let minMaxFields = validateMinMaxFields(selectedRowData.max, data.constraint_type, 'max');
            if (minMaxFields == null) {
                if (selectedRowData.min > selectedRowData.max) {
                    setMaxValueCellStyle('minFieldUIError');
                    setMaxValueError('Min volume should be less than max volume')
                } else if (selectedRowData.min <= selectedRowData.max) {
                    let minField = validateMinMaxFields(selectedRowData.min, data.constraint_type, 'min');
                    if (minField == null) {
                        if (minValueCellStyle == 'minFieldUIError') {
                            setMinValueCellStyle('minFieldUISuccess');
                            setMinValueError(null)
                        }
                        setMaxValueCellStyle('minFieldUISuccess');
                        setMaxValueError(null)
                    } else {
                        setMinValueCellStyle('minFieldUIError');
                        setMinValueError('Min volume should be less than max volume')
                    }
                } else {
                    setMaxValueCellStyle('minFieldUISuccess');
                    setMaxValueError(null)
                }
            } else {
                setMaxValueCellStyle('minFieldUIError');
                setMaxValueError(minMaxFields)
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    function deleteSelectedRow() {
        try {
            dispatch(deleteConstraintRecord(planid, selectedRowData.ID));
            handleClose();
        } catch (err) {
            console.error('got error while deleting data');
        }
    };

    useEffect(() => {
        if (constraintTransactionSaveSuccessful)
            return handleEditEvent(content, "save");
    }, [constraintTransactionSaveSuccessful])

    if (data != undefined) {
        return (
            <div>
                {data.map((content) => {
                    let monthyear = '', fromdate = '', todate = '';
                    if (content.from_date !== undefined && content.to_date !== undefined) {
                        let splitdate1 = content.from_date ? content.from_date.split('T')[0] : '';
                        fromdate = splitdate1;
                        let splitdate2 = content.to_date ? content.to_date.split('T')[0] : '';
                        todate = splitdate2;
                    } else {
                        let month = content.month.split('T')[0];
                        monthyear = month;
                    }
                    return (
                        <div className="constraintcontents">
                            <div className="constraintpanel">
                                <div className='constraintcontentpanel'>
                                    {content.pool != undefined ? <span className="pooltext">{content.pool}</span> : <span className="pooltext">{"All Pools"}</span>}
                                    <span className='constraintdatate'>{monthyear ? <span className="contentdatefield">{dateFormatter(monthyear)}</span> : <span>{fromdate ? <span className="contentdatefield">{dateFormatter(fromdate)}&nbsp;-&nbsp;{dateFormatter(todate)}</span> : <span className="contentdatefield">-</span>}</span>}</span>
                                    <span className="minMaxFields">{(editedContent.indexOf(content) >= 0) ?
                                        <MinMaxEditableComponent tooltipTitle={(minValueError != null) ? minValueError : ''}
                                            textFieldStyle={minValueCellStyle}
                                            textFieldValue={content.min} defaultTextFieldValue={content.min}
                                            onValueChanged={(e, content) => onMinValueChanged(e, content)}
                                            focusible={true} />
                                        :
                                        <span className="minval">{localeFormatter(content.min)}</span>}
                                        {(editedContent.indexOf(content) >= 0) ?

                                            <MinMaxEditableComponent tooltipTitle={(maxValueError != null) ? maxValueError : ''}
                                                textFieldStyle={maxValueCellStyle}
                                                textFieldValue={content.max} defaultTextFieldValue={content.max}
                                                onValueChanged={(e, content) => onMaxValueChanged(e, content)}
                                                focusible={false} />
                                            :
                                            <span className="maxvalue">{localeFormatter(content.max)}</span>}</span>
                                    <span className="attributes">{content.program_type ? <span className="programattribute">{content.program_type}</span> : <span className="blnkprogramattribute"></span>}
                                        {content.spi_code ? <span className="spittribute">{content.spi_code}</span> : <span className="blnkspittribute"></span>}
                                        {content.manufacturer_group_code ? <span className="manattribute">{content.manufacturer_group_code}</span> : <span className="nomanattributeattribut"></span>}
                                        {content.mileage_start ? <span className="milesattribute">{localeFormatter(content.mileage_start)}-{localeFormatter(content.mileage_end)}&nbsp;Miles</span> : <span className="nomilesattribute"></span>}
                                    </span>
                                </div>
                                {/* {(editedContent.indexOf(content) >= 0) ?
                                    <div className="saveConstraints">
                                        <div className="saveButtonStyle">
                                            <Button disabled={(minValueCellStyle == 'minFieldUIError' || maxValueCellStyle == 'minFieldUIError')} onClick={() => onSaveClicked(content)}>Save
                                            </Button></div><div className="cancelButtonStyle"><Button onClick={() => onCancelClicked(content)}>Cancel
                                            </Button></div>
                                    </div>
                                    : <span className="constraintedit" onClick={(e) => handleClickEdit(content)}><img src={icon_edit}></img>
                                    </span>} */}
                                {!(editedContent.indexOf(content) >= 0) ? <span className="constraintdelete" onClick={() => handleClickOpen(content)}><img src={icon_delete}></img>
                                </span> : ''}
                            </div>
                            <div>
                                <DialogBox open={open} handleClose={handleClose} handleYes={deleteSelectedRow}
                                    dialogMessage={message} title={title} action={deletetext} styles={"styles"}
                                    canceltext={canceltext}>
                                </DialogBox>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        )
    } else {
        return (
            <div></div>
        )

    }
}
export default ConstraintAccordionContent;