import { Box, CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getS3DownloadUrl, getS3UploadUrl } from '../../services/s3upload_download_services/S3Services';
import axios from 'axios';
import {AddGridNameToInsertProgress, addNotificationMessage } from '../../store/topbar/AllPlans.Actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { insertUploadedManualVinDeletesData } from '../../store/manualvindeletes/ManualVinDeletes.Actions';
import { uploadGridData } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { MAX_FILESIZE_ALLOWED_FOR_UPLOAD_WITHOUT_S3 } from '../../Constants';

const S3UploadDownloadComponent=(props) =>{
    const {selectedId,fileNameToDownload,setFileNameToDownload,FileToUpload,SetFileToUpload,getPayloadParameters,gridName,userMessageFunction,gridColumnDefs,selectedTabName,isNewArchitecture,loadGridData}=props
    
    let insertProgressGridNames = useSelector((state) => {
      return state.rootAllPlansReducer.allPlansReducer.uploadedDataInsertProgressGrids;
    });

    const gridFilterUserSelectedParams = useSelector((state) => {
      return {...state.PaginationGridReducer.gridFilterUserSelectedParams};
    },shallowEqual); 

    const gridFilterViewStatus = useSelector((state) => {
        return {...state.PaginationGridReducer.gridFilterView};
    },shallowEqual); 

    const gridErrorViewStatus = useSelector((state) => {
      return {...state.PaginationGridReducer.gridErrorView};
    },shallowEqual);

    const gridSearchViewStatus = useSelector((state) => {
      return {...state.PaginationGridReducer.gridSearchView};
  },shallowEqual);

  const gridUserSearchParams = useSelector((state) => {
      return {...state.PaginationGridReducer.gridUserSearchParams};
  },shallowEqual);


    const dispatch = useDispatch();
    const [progressCompleted,setProgressCompleted]=useState(0);
    const[insertProgress,setinsertProgress]=useState(false)
    const[showProgress,setShowProgress]=useState(false)

    const getCsvColumnNames=()=>{
        if(gridColumnDefs){
            let csvFields=[]
            gridColumnDefs.map(i=>{
                    if(i.field && i.field.toLowerCase()!='updated_by' && i.field.toLowerCase()!='updated_on'){
                      csvFields.push(i.field)
                    }
              })
            return {
                    csvFields:csvFields
                  };
        } else {
          return null;
        }
        
    }

   const userDisplayMessageFunction=(severity,message)=>{// this function will removed after
    if(userMessageFunction){
      userMessageFunction(severity,message)
    }
    else{
      dispatch(addNotificationMessage(severity,message));
    }
   }

    const createDownloadParams=()=>{
      // This function is used to return parameters for downloading all the records based on user operation
      // Here "operation" can be either 'filter','search','errordata','downloadall'
      // if user is in filterview then operation will be 'filter'
      // if user is in searchview then operation will be 'search'
      // if user is in errorview then operation will be 'errordata'
      // if user is wants to download entire griddata then operation will be 'downloadall'
      // Here "searchInColumns" will be the array of column name where the search text needs to be queried
      // Here "filterparams" is the user selected checkFilterParams

      return {
          "operation":gridFilterViewStatus[gridName]?'filter':gridErrorViewStatus[gridName]?'errordata':gridSearchViewStatus[gridName]?'search':'downloadall',
          "filterparams":gridFilterUserSelectedParams[gridName],
          "searchparams":gridUserSearchParams[gridName],
     }
    }

    /**
     * @param {Boolean} isS3InsertUpload - It will be true if upload has happened through s3 url. If its false then it will happen with normal upload api
     */
    const insertUploadFunction=async(isS3InsertUpload,s3UploadedFileName)=>{
      await dispatch(uploadGridData(isS3InsertUpload,s3UploadedFileName,selectedId,FileToUpload,gridName,selectedTabName,loadGridData));
      setinsertProgress(false)
    }

    useEffect(async()=>{
      if(fileNameToDownload){
        userDisplayMessageFunction('success','Please Wait! Your File Is Getting Ready!')
        let downloadParams;
        if(gridName==='manualvindeletesgrid' && !isNewArchitecture){//this if condition for manualvindeletesgrid will be removed once planinputs grid cpmpletely moves to new design
          downloadParams = getPayloadParameters(true)
        } else if(gridName==='tncicegrid' || gridName==='coredemandgrid' || gridName==='coreadjustmentsgrid' || gridName==='tncrevenuegrid' || gridName==='corerevenuegrid' || gridName==='tncevgrid' || isNewArchitecture){//this if condition for tncicegrid will be removed once we develop download api for all core demand,adjustments and tncev tab
          downloadParams = createDownloadParams();
        }
        let downloadUrl=await getS3DownloadUrl(gridName.replace(/grid/gi,''),selectedId,fileNameToDownload,downloadParams) // gridName is replaced with capital 'G'(Grid) this is for new planInputs 2.0 and will be removed after completion
          if(downloadUrl && downloadUrl.s3Url!=undefined){
                let hiddenElement = document.createElement('a');
                hiddenElement.href = downloadUrl.s3Url;
                hiddenElement.target = '_self';
                hiddenElement.download = fileNameToDownload;
                hiddenElement.click();
                 hiddenElement.remove()
            }else{
              userDisplayMessageFunction('error','Failed To Download File!')
            }
        setFileNameToDownload(null);
      }
    },[fileNameToDownload])

    useEffect(()=>{
      if(gridName){
        setinsertProgress(gridName?insertProgressGridNames.includes(gridName):false)
      }
    },[gridName,insertProgressGridNames])

    useEffect(async()=>{
      if((FileToUpload!=null || FileToUpload!=undefined) && (gridName!=null || gridName!=undefined)){
          let headers,headerString;
          const reader = new FileReader();
          reader.readAsText(FileToUpload);

          reader.onload = async function (evt) {

              let fileData = evt.target.result;

              headerString = fileData.split('\n')[0];

              headers=headerString.replace('\r','').split(',');
             
              let originalGridCsvHeader= getCsvColumnNames();

              if(JSON.stringify(headers)==JSON.stringify(originalGridCsvHeader.csvFields)){

                if(isNewArchitecture && evt.total<=MAX_FILESIZE_ALLOWED_FOR_UPLOAD_WITHOUT_S3){
                  insertUploadFunction(false);
                  return;
                }
                  let responseUrl=await getS3UploadUrl(gridName.replace(/grid/gi,'')); // gridName is replaced with capital 'G'(Grid) this is for new planInputs 2.0 and will be removed after completion

                    if(responseUrl && responseUrl.s3Url!=undefined){
                            setShowProgress(true)
                            userDisplayMessageFunction('warning','Please wait! Your File Is Getting Uploaded!')
                            const config = {
                              onUploadProgress: function(progressEvent) {
                                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                setProgressCompleted(percentCompleted)
                              }
                            }   
                         
                            axios.put(responseUrl.s3Url, FileToUpload, config)
                              .then(()=>{
                                setinsertProgress(true)
                                dispatch(AddGridNameToInsertProgress(gridName));
                                //Need to move this dispatch functionalit to paginationgrid.action file in upcoming sprint
                                if(gridName==='manualvindeletesgrid' && !isNewArchitecture){
                                  dispatch(insertUploadedManualVinDeletesData(selectedId,responseUrl.uploadedFilename,gridName,userMessageFunction,setinsertProgress))
                                } else {
                                  insertUploadFunction(true,responseUrl.uploadedFilename);
                                }
                                userDisplayMessageFunction('success','Your File Uploaded Succesfully! Insert Is In Progress!')
                                setShowProgress(false)
                                SetFileToUpload(null)
                                setProgressCompleted(0)
                              })
                              .catch((err) => {
                                console.log(err)
                                userDisplayMessageFunction('error', 'Upload Failed!! Please Re-Upload The File!');
                                setinsertProgress(false)
                                setShowProgress(false)
                                SetFileToUpload(null)
                              })
                          }
                    } else {
                      userDisplayMessageFunction('error', 'Wrong File!! Upload failed');
                    }
            }
        }
    },[FileToUpload])



    const LinearProgressWithLabel=(props)=> {
        return (
            <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="textPrimary">{progressCompleted}%</Typography>
            </Box>
          </Box>    
          </>
        );
      }
    return (
        <>
        {(showProgress && isNewArchitecture)?
            <Box sx={{ width: '50%' }}>
                     <LinearProgressWithLabel value={progressCompleted} />
            </Box>
            : (insertProgress) ? 
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30}/>
                <Typography variant="body2" color="error">Insert in progress</Typography>
            </Box>
            :''
        }
        </>
    );
}

export default S3UploadDownloadComponent;