import React, { useEffect, useState, useCallback, useContext } from 'react'
import IconFilter from "../../../assets/images/icon_filter.svg";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Divider } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconInfo from "../../../assets/images/icons_info.svg";
import IconTable from "../../../assets/images/icons_table.svg";
import IconActionUpload from "../../../assets/images/icon_action_upload.svg";
import IconActionDownload from "../../../assets/images/icon_action_download.svg";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import CustomPopOver from "../../../core-components/Popover/CustomPopOver";
import StatusIcon from "../../../assets/images/icons_status_icons_completed.svg";
import SearchBar from "../../searchbar/SearchBar";
import { NttLabel } from '../../../core-components/Label/NttLabel';
import { setAdjustmentAction, loadFilterData, setTabAllFilterParameters, setCoreTabFilterParameters, setCoreIceFilterParameters, setCoreElecFilterParameters,
       setTncFilterParameters,setTncIceFilterParameters,setTncElecFilterParameters,setCargoFilterParameters,toggleExpandAll, setGroupViewType } from '../../../store/adjustments/Adjustments.Actions';
import DialogBox from "../../dialog_component/CustomDialog";
import { Button } from '@material-ui/core';
import "./AdjustmentsActionBar.css";
import { useDispatch, useSelector } from "react-redux";
import { filterCat, FilterDropdownOptions, TYPE_ZONE_STR, ZONE_TYPE_STR } from '../../../Constants';
import RangeFilter from './filter_components/range_filter/Adjustments_RangeFilter';
import DropdownFilter from './filter_components/dropdown_filter/Adjustments_DropdownFilter';
import icon_collapse_all from "../../../assets/images/icon_collapse_all.png";
import icon_expand_all from "../../../assets/images/icon_expand_all.png";
import { adjustmentFilters } from '../../../containers/adjustments/AdjustmentsPage';
import { customCloneObject, selectFilterDataSectionFromStore } from '../../../utils/AdjustmentsUtils';

const AdjustmentsActionBar = (props) => {
  const {selectedVal, showCancelButton, handleCancel, tabIndex, filterData,  planStatus } = props;
  let adjustmentActivities = useContext(adjustmentFilters);
  const [anchorEl, setAnchorEl] = useState(null);
  const popOverOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const id = popOverOpen ? 'simple-popover' : undefined;
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const theme = createMuiTheme();
  const [open, setOpen] = useState(false);
// let tempFilterArray = selectedVal==='Type>Zone' ? adjustmentActivities.tempFilterArray : adjustmentActivities.tempFilterArrayForZoneView;
// let setTempFilterArray  = selectedVal==='Type>Zone' ? adjustmentActivities.setTempFilterArray:adjustmentActivities.setTempFilterArrayForZoneView;
  
  const [selectedFromRangeValue, setSelectedFromRangeValue] = useState(null);
  const [selectedToRangeValue, setSelectedToRangeValue] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = React.useState("between");

  const [tempFilterArray, setTempFilterArray] = useState(null);

  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minHeight: '180vh',
      maxHeight: '180vh',
    }
  }));
  const classes = useStyles();

  theme.typography.body2 = {
    fontSize: '14px'
  };

  // let tempFilterArray = useSelector((state)=>{
  //   console.log("Getting tempFilterArray==",tabIndex,selectedVal);
  //   return state.rootAdjustmentsReducer.adjustmentsReducer.
  // });

  let filterArray=selectFilterDataSectionFromStore(tabIndex,selectedVal);
  let setFilterArray  = (ipFilterArray,tabIndex,viewType)=>{
    // console.log("Saving filterArray=",filterArray);
    let filterArray=customCloneObject(ipFilterArray);
    switch(parseInt(tabIndex,10)){
      case 0: dispatch(setTabAllFilterParameters(filterArray,viewType));break;
      case 1: dispatch(setCoreTabFilterParameters(filterArray,viewType));break;
      case 2: dispatch(setCoreIceFilterParameters(filterArray,viewType));break;
      case 3: dispatch(setCoreElecFilterParameters(filterArray,viewType));break;
      case 4: dispatch(setTncFilterParameters(filterArray,viewType));break;
      case 5: dispatch(setTncIceFilterParameters(filterArray,viewType));break;
      case 6: dispatch(setTncElecFilterParameters(filterArray,viewType));break;
      case 7: dispatch(setCargoFilterParameters(filterArray,viewType));break;
      default: 
          console.log("Invalid Tab ! tabIndex=",tabIndex);
          return null;
    };
  };

  let editing = useSelector((state) => {
    return state.rootAdjustmentsReducer.adjustmentsReducer.editing;
  });

  const switchView = (index) => {
    let selectedValue;
    if (index === 1){   
      selectedValue = TYPE_ZONE_STR;
    }
    else if (index === 2){
      selectedValue = ZONE_TYPE_STR;
    }else{
      selectedValue = TYPE_ZONE_STR;
    }    
    // console.log("View SWITCHED to ",selectedValue,"on tab ",tabIndex);
    dispatch(setGroupViewType(tabIndex,selectedValue));
  }

  const handleListItemClick = (event, index) => {
    // console.log("setSelectedIndex ",index);
    setSelectedIndex(index);
    setAnchorEl(null);
    switchView(index);
  };

  // useEffect(()=>{
    // console.log("selectedVal=",selectedVal);
    // adjustmentActivities.setFilterActivityForAdjVar([]);
    // adjustmentActivities.setFilterActivityForReqValue([]);
    // adjustmentActivities.setFilterActivityForReqAdjVal([]);
    // adjustmentActivities.setFilterActivityForOriginalValue([]);
    // setOpen(false);
    //setFilterArray({ ...tempFilterArray });
    // console.log("In action Bar tempFilterArray=",tempFilterArray);
    // loadFilterParams({ ...tempFilterArray,isViewChanged:true }, tabIndex);
    // switchView(selectedIndex);
  // },[selectedVal])

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handlePopOverClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const cancelClicked = (e) => {
    dispatch(setAdjustmentAction([], "cancel_clicked"))
    handleCancel(e);
  }

  const switchExpandallComponent = (tabIndex,selectedVal) =>{
    let expandSection = false;

    let expandAllBean=useSelector((state)=>{
        if(selectedVal==="Type>Zone"){
            return state.rootAdjustmentsReducer.adjustmentsReducer.expandAll_type_zone[tabIndex];
        }else{
            return state.rootAdjustmentsReducer.adjustmentsReducer.expandAll_zone_type[tabIndex];
        }
        
    }) ;

    // console.log("expandAllBean==",expandAllBean);

    if(expandAllBean.value===1){
      return (
        <button className="expandAllCollapseAllButton"
             onClick={(e) => handleExpandAll(e)}>
            <img src={icon_expand_all}></img>
            <span>Expand All</span>
        </button>
      );
    }else{
      return (
        <button className="expandAllCollapseAllButton"
             onClick={(e) => handleExpandAll(e)}>
            <img src={icon_collapse_all} ></img>
            <span>Collapse All</span>
        </button>
      );
    }



    
  }

  const switchViewComponent = () => {
    return (
      <div style={{ display: 'flex' }}>
        {(editing || showCancelButton) ? <div className="cancelButton"><Button onClick={(e) => cancelClicked(e)}>Cancel
            </Button></div> : ''}
        <div className={anchorEl ? 'secondaryButton' : 'primaryButton'} aria-describedby={id}>
          <Button id={id} onClick={(e) => handlePopOverClick(e)} endIcon={<ArrowDropDownIcon fontSize="13px"></ArrowDropDownIcon>}>View</Button>
          <CustomPopOver
            className="actiobarPopOver"
            popOverId="popoverId"
            open={popOverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <List>
              <ListItem button
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}>
                <img src={selectedVal === 'Type>Zone' ? StatusIcon : ''} className="imageSpacing"></img>
                <NttLabel theme={theme} labelVariant="body2">
                  Group by Type {'>'} Zone</NttLabel>
              </ListItem>
              <Divider />
              <ListItem button
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}>
                <img src={selectedVal === 'Zone>Type' ? StatusIcon : ''} className="imageSpacing"></img>
                <NttLabel theme={theme} labelVariant="body2">
                  Group by Zone {'>'} Type</NttLabel>
              </ListItem>
            </List>
          </CustomPopOver>
        </div>
      </div>);
  }

  // const loadFilterParams = useCallback(async (tempFilterArray, tabindex) => {
  //   // console.log("Loading Filter Params.......")
  //   // console.log("In loadFilterParams tempFilterArray=",tempFilterArray);
  //   try {
  //     if (tabindex === 0) {
  //       dispatch(setTabAllFilterParameters(tempFilterArray,selectedVal))
  //     } else if (tabindex === 1) {
  //       dispatch(setCoreTabFilterParameters(tempFilterArray,selectedVal))
  //     }else if (tabindex === 2) {
  //       dispatch(setCoreIceFilterParameters(tempFilterArray,selectedVal))
  //     }else if (tabindex === 3) {
  //       dispatch(setCoreElecFilterParameters(tempFilterArray,selectedVal))
  //     }else if (tabindex === 4) {
  //       dispatch(setTncFilterParameters(tempFilterArray,selectedVal))
  //     }else if (tabindex === 5) {
  //       dispatch(setTncIceFilterParameters(tempFilterArray,selectedVal))
  //     }else if (tabindex === 6) {
  //       dispatch(setTncElecFilterParameters(tempFilterArray,selectedVal))
  //     }else if (tabindex === 7) {
  //       dispatch(setCargoFilterParameters(tempFilterArray,selectedVal))
  //     }
  //   } catch (err) {
  //     console.error('got error while loading filterparams');
  //   }
  // }, [dispatch]);

  const handleFilterClick = (event) => {
    if (tabIndex === 0) {
      dispatch(loadFilterData(FilterDropdownOptions[0]));
    } else if (tabIndex === 1 || tabIndex === 2 || tabIndex === 3) {
      if(selectedVal===ZONE_TYPE_STR){
        let filterDropOptions=[...FilterDropdownOptions[1]];
        filterDropOptions.pop();
        filterDropOptions.push("ALL");
        dispatch(loadFilterData(filterDropOptions));
      }else{
        dispatch(loadFilterData(FilterDropdownOptions[1]));
      }
    } else if (tabIndex === 4 || tabIndex === 5 || tabIndex === 6) {
      dispatch(loadFilterData(FilterDropdownOptions[2]));
    } else if (tabIndex === 7) {
      dispatch(loadFilterData(FilterDropdownOptions[3]));
    }
    setTempFilterArray(filterArray);
    setOpen(true);
  }
  const handleExpandAll = (event) => {
    dispatch(toggleExpandAll(tabIndex,selectedVal));
  }
  const handleClose = (e) => {
    setOpen(false);
  };
  const applyFilter = () => {
    let localFilterArray=cleanUpFilter(customCloneObject(tempFilterArray));
    let filterOK=validateFilter(localFilterArray);
    if(filterOK==="OK"){
      setOpen(false);
      setFilterArray({...localFilterArray,errorMsg:'',isFiltered:true},tabIndex,selectedVal);//Deep clone is happening inside setFilterArray method
    }else{
      setTempFilterArray({...localFilterArray,errorMsg:filterOK});
    }
    
  }

  const validateFilter=(tempFilterValues)=>{
    // console.log("tempFilterArray===",tempFilterValues);
    let message="OK";
    let filterKeys=Object.keys(tempFilterValues);
    // console.log("filterData==",filterData);
    filterKeys.forEach(key=>{
      if(key==="variance" || key==="requestedValue" || key==="originalValue" || key==="requestedAdjustment"){
        let displayName=filterData.filter(obj=>obj.colDef===key)[0].displayName;
        let params=tempFilterValues[key];
        switch(params[0]){ //NOTE: == is used so that datatype check does not result in a false
          case 'between':
              if((params.length!==1 && params.length!==3) ||  params.some(val=>val===''))
                message= "All Filter Values of "+displayName+" not provided";

              if(params.length===3 && (isNaN(params[1].replace(',','')) || isNaN(params[2].replace(',',''))) ) 
                message=  "Filter Value of range "+displayName+" must be a number";
              
              break;
          case 'equal':
          case 'less':
          case 'greater':
              if(params.length===2 && isNaN(params[1].replace(',',''))) 
                message= "Filter Value of "+displayName+" must be a number";
              
              break;
          case 'notzero':
              //No action required
              break;
          default:
            console.log("params[0]===",params[0]);
            message= "Invalid validation condtion. Please talk to administrator.";
        };
      }
    });

    return message;
  }

  const cleanUpFilter=(tempLocalFilterArray)=>{//Removes empty strings from the back of the filter arrays
    let localFilterArray={};
    // console.log("cleanUpFilter==tempFilterArray===",tempLocalFilterArray);
    let filterKeys=Object.keys(tempLocalFilterArray);
    // console.log("filterData==",filterData);
    filterKeys.forEach(key=>{
      let params=tempLocalFilterArray[key];
      if(params!==null && params!==undefined && typeof params === "object" && params.constructor === Array){
        while(params.length>0 && params[params.length-1]===''){
          params.pop();
        }
        localFilterArray[key]=[...params];
      }else{
        localFilterArray[key]=params;
      }
      
    });
    // console.log("cleanUpFilter==localFilterArray===",localFilterArray);
    return localFilterArray;
  }

  const clearFilter = () => {
    setOpen(false);
    //setTempFilterArray(filterCat);
    // loadFilterParams({...filterCat}, tabIndex);
    setFilterArray(filterCat,tabIndex,selectedVal);
  }

  const getSelectedValue = (colDef) => {
    if (tempFilterArray !== undefined && tempFilterArray !== null) {
      return tempFilterArray[colDef];
    }
  }
  const handleDropDownValueChange = (event, displayName, colDef) => {
    var filterData = tempFilterArray===null?{ ...filterArray}:{ ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key === colDef) {
        if (Array.isArray(filterData[key])) {
          filterData[key] = event;
        } else {
          if (event[0])
            filterData[key] = event[0].value;
          else
            filterData[key] = event.target.value;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const onValueRangeChanged = (e, colDef, rangeField) => {
    var range = e.target.value;
    if (rangeField == "from")
      setSelectedFromRangeValue(range);
    else if (rangeField == "to")
      setSelectedToRangeValue(range);
    var filterData = tempFilterArray===null?{ ...filterArray}:{ ...tempFilterArray };
    var keys = Object.keys(filterData);
    keys.map(key => {
      if (key == colDef) {
        if (filterData[colDef][0] == "between") {
          if (filterData[key].length === 3) {
            if (rangeField === "from") {
              filterData[key][1] = range;
            }
            if (rangeField === "to") {
              filterData[key][2] = range;
            }
          } else {
            if (filterData[key].length == 1 && rangeField == "to") {
              filterData[key].push("0");
            }else if(filterData[key].length>1 && rangeField == "from"){
              filterData[key][1] = range;
            }else{
              filterData[key].push(range);
            }
          }
        } else {
          if (filterData[key].length == 1)
            filterData[key].push(range);
          else
            filterData[key][1] = range;
        }
      }
      return filterData;
    });
    setTempFilterArray(filterData);
  }

  const onRangeDropDownValueChanged = (e, colDef) => {
    // console.log("Called onRangeDropDownValueChanged..........");
    var filterData = tempFilterArray===null?{ ...filterArray}:{ ...tempFilterArray };
    filterData[colDef][0] = e.target.value;
    // setSelectedDropdown(e.target.value);
    setTempFilterArray(filterData);
  }

  const fetchDialogContent = () => {
    if (filterData !== undefined && filterData !== null && filterData.length > 0 ) {
      // console.log("Opening Filter Dialog with filterArray==",filterArray);
      // console.log("Opening Filter Dialog with filterData==",filterData);

      let isAdjComplete = (planStatus && planStatus.toLowerCase() == 'adjustment complete');
      // console.log("isAdjComplete====",isAdjComplete);
      return (<div className="flexContainer">
        {tempFilterArray && tempFilterArray.errorMsg?<div className="errorMessage">{tempFilterArray.errorMsg}</div>:""}
        {filterData.map((fields, index) => {
          // console.log("fields.colDef====isAdjComplete=======",fields.colDef,isAdjComplete);
          return (
            (fields.type === "dropdown") ?
              (<div className="filterMain" key={"filterMain_"+index}>
                  <DropdownFilter displayName={fields.displayName} options={fields.options}
                    theme={theme} selectedValue={getSelectedValue(fields.colDef)}
                    onValueChanged={(e) => handleDropDownValueChange(e, fields.displayName, fields.colDef)}>
                  </DropdownFilter>
              </div>) :
              (fields.type === "range" && tempFilterArray!==null && 
                    ((fields.colDef==="variance" && isAdjComplete)||
                    (fields.colDef!=="variance")) 
                ) ?
                (<div className="filterMainForRange" key={"filterMain_"+index}>
                    <RangeFilter displayName={fields.displayName} theme={theme}
                      selectedRangeValue={tempFilterArray[fields.colDef]}
                      onFromValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "from")}
                      onToValueChanged={(e) => onValueRangeChanged(e, fields.colDef, "to")}
                      onValueChanged={(e) => onRangeDropDownValueChanged(e, fields.colDef)}
                    >
                    </RangeFilter>
                 </div>) :
                <div></div>);
        }
        )}</div>);
    }
  }

  return (
    <div className={tabIndex == 0 ? 'AllBumainAdjustmentHeader' : "mainAdjustmentHeader"}>
      <div className="adjustmentsFilterStyle">
        <img src={IconFilter} onClick={(e) => handleFilterClick(e)} className='adjFilterIcon' />
        {/* <SearchBar></SearchBar>
                TODO: Future implementation */}
                {switchExpandallComponent(tabIndex,selectedVal)}
      </div>
      <div className="adjustmentsActionButtons">
              {switchViewComponent()}
      </div>
      <div>
         <DialogBox classes={{ paper: classes.dialogPaper }}
          open={open} handleClose={(e) => handleClose(e)} handlePositiveBtnClick={(e) => applyFilter(e)} handleNegativeBtnClick={(e) => clearFilter(e)}
          dialogContent={fetchDialogContent()} title="Filter" positiveBtnLbl="Apply Filter" negativeBtnLbl="Clear Filter">
        </DialogBox>
      </div>
    </div>
  )
}

export default AdjustmentsActionBar;