import {FETCH_DEPRATE_DATA_PENDING, FETCH_DEPRATE_DATA_SUCCESS, FETCH_DEPRATE_DATA_ERROR, UPLOAD_DEPRATE_PENDING, UPLOAD_DEPRATE_SUCCESS, UPLOAD_DEPRATE_ERROR,
    SAVE_DEPRATE_TRANSACTION_PENDING, SAVE_DEPRATE_TRANSACTION_SUCCESS, SAVE_DEPRATE_TRANSACTION_ERROR, RESET_TO_DEPRATE_INITIALSTATE
    } from '../deprate/DepRate.ActionTypes';

const initialState = {
    depRateData:[],
    pending:false,
    error:null,
    uploadDepRateSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(targetZonePoolData, zones) {
    targetZonePoolData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
        if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
            value['ZONE_NAME'] = "";
        }
    })
    return targetZonePoolData;
}

export default function DeprateReducer (state=initialState,action){
    switch(action.type){
        case FETCH_DEPRATE_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_DEPRATE_DATA_SUCCESS:
            return Object.assign({},state,{
                depRateData: action.data,
                uploadDepRateSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_DEPRATE_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_DEPRATE_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadDepRateSuccess: null
            });
        case UPLOAD_DEPRATE_SUCCESS:
            return Object.assign({}, state, {
                depRateData: action.data,
                pending: false,
                error: null,
                uploadDepRateSuccess: true
            });
        case UPLOAD_DEPRATE_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadDepRateSuccess: false
            });
        case SAVE_DEPRATE_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_DEPRATE_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                depRateData: action.data,
                transactionSaveSuccess: true
            });
        case SAVE_DEPRATE_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_DEPRATE_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}