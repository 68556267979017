import { columnDefs, defaultColDef } from "./diversions_grid_data/DiversionsColumnDefs";
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import { FETCH_DIVERSIONS_DROPDOWNVALUES_ERROR, FETCH_DIVERSIONS_DROPDOWNVALUES_PENDING, FETCH_DIVERSIONS_DROPDOWNVALUES_SUCCESS, SET_DIVERSIONS_EDITABLE } from "./Diversions.ActionTypes";
import { DiversionsColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            PO: { error: checkValue(data.PO, DiversionsColumns.PO.headerName) },
            ITEM: { error: checkValue(data.ITEM, DiversionsColumns.Item.headerName) },
            DROP: { error: checkValue(data.DROP, DiversionsColumns.Drop.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkValue(value, name) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    var itemValue = parseInt(value, 0);
    if(name===DiversionsColumns.PO.headerName){
         if(itemValue<0){
            return name + " cannot be negative"
         }
         if(value.length<8){
            return name + " cannot be less than 8 digits"
         } 
         if(value.length>8){
            return name + " cannot be more than 8 digits"
         }
    }
    if(name===DiversionsColumns.Item.headerName){
        if(itemValue<0){
           return name + " cannot be negative"
        }
        if(value.length>4){
           return name + " cannot be more than 4 digits"
        }
   }
   if(name===DiversionsColumns.Drop.headerName){
    if(itemValue<0){
       return name + " cannot be negative"
    }
    if(value.length>4){
       return name + " cannot be more than 4 digits"
    }
}

    return null;
}



function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
            cleaned.forEach(function (itm2) {
                if (safeCaseInsensitiveCompare(itm.PO,itm2.PO) && 
                safeCaseInsensitiveCompare(itm.ITEM,itm2.ITEM) && safeCaseInsensitiveCompare(itm.DROP,itm2.DROP)){
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }

            });
            if (unique && itm.status != "ADD_ROW") {
                itm.status = "";
                cleaned.push(itm);
            }
            transactions.forEach((itm1,key)=>{
                if(itm1.data){
                  if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                    transactions[key].data.status=itm.status
                  }
                }
              })
    });
    return duplicate;
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field == DiversionsColumns.UpdatedBy.field) {
            let userKeys = Object.keys(data.users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": data.users[userData], "value": data.users[userData] })
            })
            column.filterOptions = usersFilterData
        }
        if (column.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                column.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                column.cellRenderer = 'DiversionsActionRenderer'
            }
        }
    });
    return columnDefs;
}

export default function diversionsColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DIVERSIONS_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            });
        case FETCH_DIVERSIONS_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_DIVERSIONS_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_DIVERSIONS_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}