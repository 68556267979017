import { combineReducers } from 'redux';
import PoolsManagementReducer from './PoolManagement.Reducer';
import multiGridUIReducer from '../AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import PoolsManagementColumnDefinitionReducer from "./PoolManagement.ColumnDef.Reducer";
import {ADMINPOOLSGRID} from '../../../../src/Constants';

const rootPoolsManagementReducer = combineReducers({
      PoolsManagementReducer
    , PoolsManagementGridReducer: createNamedWrapperReducer(multiGridUIReducer, ADMINPOOLSGRID)
    , PoolsManagementColumnDefinitionReducer
})

export default rootPoolsManagementReducer;