import { fetchMoveCostDropdownService } from "../../services/movecost/movecostservice";
import { fetchAdditionalCostDropdownValues } from "../../store/additionalcost/AdditionalCost.Actions";
import { fetchAddsInvalidPoolsDropdownValues } from "../../store/addsinvalidpools/AddsInvalidPools.Actions";
import { fetchAgeMileageDropdownValues } from "../../store/agemileage/AgeMileage.Actions";
import { fetchAllocatedDropdownValues } from "../../store/allocated/Allocated.Actions";
import { fetchConstraintsOptions } from "../../store/constraints/Constraints.Actions";
import { fetchDepRateDropDownValues } from "../../store/deprate/DepRate.Actions";
import { fetchMaxDeletesDropdownValues } from "../../store/maxdeletes/MaxDeletes.Actions";
import { fetchMileageDefaultDropdownValues } from "../../store/mileagedefault/MileageDefault.Actions";
import { fetchMileageTableDropdownValues } from "../../store/mileagetable/MileageTable.Actions";
import { fetchMoveConstraintDropdownValues } from "../../store/moveconstraints/MoveConstraints.Actions";
import { fetchProgramDropdownValues } from "../../store/programs/Programs.Actions";
import { fetchSalvageRateDropdownValues } from "../../store/salvagerate/SalvageRate.Actions";
import { fetchSchedulesDropdownValues } from "../../store/schedules/Schedules.Actions";
import { fetchTargetZonePoolDropdownValues } from "../../store/targetzonepool/TargetZonePool.Actions";
import { fetchUpgradeCarClassgroup } from "../../store/upgradepaths/UpgradePaths.Actions";

export const fetchDrownValuesByGridName = {
    'programsGrid': fetchProgramDropdownValues,
    'schedulesGrid': fetchSchedulesDropdownValues,
    'allocatedGrid': fetchAllocatedDropdownValues,
    'upgradepathsGrid': fetchUpgradeCarClassgroup,
    'constraintsGrid': fetchConstraintsOptions,
    'moveconstraintsGrid': fetchMoveConstraintDropdownValues,
    'agemileageGrid':fetchAgeMileageDropdownValues,
    'additionalcostGrid':fetchAdditionalCostDropdownValues,
    'maxdeleteGrid':fetchMaxDeletesDropdownValues,
    'salvagerateGrid':fetchSalvageRateDropdownValues,
    'addsinvalidpoolsGrid':fetchAddsInvalidPoolsDropdownValues,
    'mileagedefaultGrid':fetchMileageDefaultDropdownValues,
    'targetzonepoolGrid':fetchTargetZonePoolDropdownValues,
    'deprateGrid':fetchDepRateDropDownValues,
    'mileagetableGrid':fetchMileageTableDropdownValues,
    'movecostGrid':fetchMoveCostDropdownService
}