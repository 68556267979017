import React from "react";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReadOnlySectionRowsComponent from './ReadOnlySectionRowsComponent';
import { ScrollSyncPane } from 'react-scroll-sync';
import EndFleetSectionRowsComponent from "./EndFleetSectionRowsComponent";



const SectionSummaryComponent = (props) => {
    const { activityNode,plansDataById,title,topLevel} = props;

    // console.log("activityNode==>",activityNode);
    return (<AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel-header"
        TransitionProps={{ unmountOnExit: true }}>

        <div className="allBurowFlex">
            {topLevel?
                <label className='salesAdjustmentHeading'>{title}</label>
            :
                <label className='salesAdjustmentInnerheading'>{title}</label>
            }
            
            
            <ScrollSyncPane group="horizontal">
                <div className="readOnlynoMarginOverall">
                    <ReadOnlySectionRowsComponent activityNode={activityNode} accordionSummary={true} plansDataById={plansDataById}/>
                </div>
            </ScrollSyncPane>
        </div>
    </AccordionSummary>)
}

export default SectionSummaryComponent;


