import {FETCH_ALLRFS_DATA_PENDING,FETCH_ALLRFS_DATA_SUCCESS,FETCH_ALLRFS_DATA_ERROR, FETCH_CREATERF_INPUTS_PENDING, FETCH_CREATERF_INPUTS_ERROR, FETCH_CREATERF_INPUTS_SUCCESS, SET_GENERATE_RF_PROCESS_STATUS,
    SAVE_RF_DESCRIPTION_PENDING,SAVE_RF_DESCRIPTION_SUCCESS,SAVE_RF_DESCRIPTION_ERROR,RF_DETAILS_BYID_PENDING,RF_DETAILS_BYID_SUCCESS,RF_DETAILS_BYID_ERROR} from "./AllRFs.ActionTypes";

const initialState = {
    pending: false,
    error: null,
    allRFsData:[],
    allRFsFilterSearchKey:"",
    filterParams: null,
    isGenerateRfButtonClicked:null,
    saveResponse: '',
    rfDetailsById:[]
}

export default function planManagementReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALLRFS_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ALLRFS_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                allRFsData: action.data,
                error:null
            });
        case FETCH_ALLRFS_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error:action.error
            });
        case FETCH_CREATERF_INPUTS_PENDING:
            return Object.assign({},state,{
                pending:true,
                allRFsData:[],
                isGenerateRfButtonClicked:null
            })
        case FETCH_CREATERF_INPUTS_SUCCESS:
            return Object.assign({},state,{
                pending:false,
                isGenerateRfButtonClicked:true
            })
        case FETCH_CREATERF_INPUTS_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:'Unable to create new rf plan'
            })
        case SET_GENERATE_RF_PROCESS_STATUS:
            return Object.assign({},state,{
                isGenerateRfButtonClicked:null
            });
        case SAVE_RF_DESCRIPTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case SAVE_RF_DESCRIPTION_SUCCESS:
            return Object.assign({}, state, {
                saveResponse: action.data,
                pending: false
            });
        case SAVE_RF_DESCRIPTION_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending: false
            });
        case RF_DETAILS_BYID_PENDING:
            return Object.assign({},state,{
                pending:true
            });
        case RF_DETAILS_BYID_SUCCESS:
            return Object.assign({},state,{
            rfDetailsById:action.data.length>0?action.data[0]:action.data,
            pending:false
            });
        case RF_DETAILS_BYID_ERROR:
            return Object.assign({},state,{
             error:action.error,
             pending:false
            })
        default:
            return state;
    }
}

