import { columnDefs, defaultColDef } from "../tncparameters/tncparameter_grid_data/TncParameterColumnDefs";
import {} from "../tncev/TncEv.ActionTypes";
import { safeCaseInsensitiveCompare } from "../../../../utils/utils";
import { defaultNumericParameters } from "../RfParametersConstants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            //value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            PARAMETER_NAME: { error: checkIfValueEmpty(data.PARAMETER_NAME, "Parameter Name") },
            PARAMETER_VALUE: { error: checkIfValueEmpty(data.PARAMETER_VALUE, "Parameter Value",data.PARAMETER_NAME) }
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.PARAMETER_NAME, itm2.PARAMETER_NAME)){
                unique = false;
                if(itm.rowStatus == "ADD_ROW"){
                    itm.rowStatus = "ERROR_NEW_ROW";
                }
                else if(itm2.rowStatus == "ADD_ROW"){
                    itm2.rowStatus = "ERROR_NEW_ROW"
                }
                if(itm.rowStatus == "COPY_ROW"){
                    itm.rowStatus = "ERROR_COPY_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                 else if(itm2.rowStatus == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.rowStatus = "ERROR_COPY_ROW"
                }
                else {
                    itm.rowStatus = "ERROR_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.rowStatus == "ERROR_COPY_ROW" || transactions[key].data.rowStatus == "ERROR_ROW") && itm.rowStatus==''){
                transactions[key].data.rowStatus=itm.rowStatus
              }
            }
          })
        if (unique && itm.rowStatus != "ADD_ROW") {
            itm.rowStatus = "";
            cleaned.push(itm);
        }
    });
    return duplicate;
    
}

function checkIfValueEmpty(value, name,parametername) {
    if (value === undefined || value === "" || value === null) {
        return name +" "+"cannot be empty";
    }else if(name==="Parameter Value" && parametername && defaultNumericParameters.includes(parametername)){ //decimal value validation is only for default numeric parameters,as per hertz team's suggestion.
        if(value<0 || value>10){
            return "value should be greater than 0 and less than 10 for default parameters";
        }
        const numberAsString = value.toString();
        // check if String Contains Decimal
        if (numberAsString.includes('.')) {
            // using regex to remove if there are any signs like + or - to count the length
            if ((numberAsString.split('.')[1].replace(/[^\d]/g, '')).length>2 || (numberAsString.split('.')[0].replace(/[^\d]/g, '')).length>1 || (numberAsString.split('.')[1].replace(/[^\d]/g, '')).length<2 ||(numberAsString.split('.')[0].replace(/[^\d]/g, ''))===""){
                return "value should contain only one digit before decimal and two digits after decimal point"; 
            }else {
                return null
            };
        }
        else{
            return null;
        }
    }else{
        return null;
    }
}

export default function RFParameterscolumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}