import { combineReducers } from 'redux';
import roleManagementReducer from './RoleManagement.Reducer';
import multiGridUIReducer from '../AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import columnDefinitionReducer from "./RoleManagement.ColumnDef.Reducer";

const rootAdminRoleManagementReducer = combineReducers({
      roleManagementReducer
    , roleManagementGridReducer: createNamedWrapperReducer(multiGridUIReducer, "rolemanagementgrid")
    , columnDefinitionReducer
})

export default rootAdminRoleManagementReducer;