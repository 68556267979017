import { combineReducers } from 'redux';
import moveConstraintsReducer from './MoveConstraints.Reducer';
import {createNamedWrapperReducer} from '../util';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import moveConstraintsColumnDefReducer from './MoveConstraints.ColumnDef.Reducer';

const rootMoveConstraintsReducer = combineReducers({
    moveConstraintsReducer,
    moveConstraintsGridReducer: createNamedWrapperReducer(multiGridUIReducer, "moveconstraintsgrid"),
    moveConstraintsColumnDefReducer
})

export default rootMoveConstraintsReducer;