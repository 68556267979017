export const FETCH_MOVE_CONSTRAINT_DATA_PENDING = 'FETCH_MOVE_CONSTRAINT_DATA_PENDING';
export const FETCH_MOVE_CONSTRAINT_DATA_SUCCESS = 'FETCH_MOVE_CONSTRAINT_DATA_SUCCESS';
export const FETCH_MOVE_CONSTRAINT_DATA_ERROR = 'FETCH_MOVE_CONSTRAINT_DATA_ERROR';
export const FETCH_MOVE_CONSTRAINT_DROPDOWN_PENDING = 'FETCH_MOVE_CONSTRAINT_DROPDOWN_PENDING';
export const FETCH_MOVE_CONSTRAINT_DROPDOWN_SUCCESS = 'FETCH_MOVE_CONSTRAINT_DROPDOWN_SUCCESS';
export const FETCH_MOVE_CONSTRAINT_DROPDOWN_ERROR = 'FETCH_MOVE_CONSTRAINT_DROPDOWN_ERROR';
export const SAVE_MOVE_CONSTRAINTS_TRANSACTION_PENDING = 'SAVE_MOVE_CONSTRAINTS_TRANSACTION_PENDING';
export const SAVE_MOVE_CONSTRAINTS_TRANSACTION_SUCCESS = 'SAVE_MOVE_CONSTRAINTS_TRANSACTION_SUCCESS';
export const SAVE_MOVE_CONSTRAINTS_TRANSACTION_ERROR = 'SAVE_MOVE_CONSTRAINTS_TRANSACTION_ERROR';
export const UPLOAD_MOVE_CONSTRAINTS_PENDING = 'UPLOAD_MOVE_CONSTRAINTS_PENDING';
export const UPLOAD_MOVE_CONSTRAINTS_SUCCESS = 'UPLOAD_MOVE_CONSTRAINTS_SUCCESS';
export const UPLOAD_MOVE_CONSTRAINTS_ERROR = 'UPLOAD_MOVE_CONSTRAINTS_ERROR';
export const RESET_TO_MOVE_CONSTRAINTS_INITIALSTATE = 'RESET_TO_MOVE_CONSTRAINTS_INITIALSTATE';
export const SET_MOVE_CONSTRAINT_EDITABLE='SET_MOVE_CONSTRAINT_EDITABLE';