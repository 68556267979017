import React from 'react';
import axios from 'axios';
import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { checkAndLoadDimention } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP, USER } from '../../Constants';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';


export async function fetchAllPlansWithDimensions(isApiDisable) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    //Implementing of isApiDisable is used for not calling plan and spicode api's always as because 
    // This service(fetchAllPlansWithDimensions) is utilized always and continously on change of tab/or on click of save or cancel/or on click of refresh

    //Todo:- TO move all SPICODE to cache(checkAndLoadDimention) as the backend system for checking is master cache has been updated or not is ready. 
    let promiseAllPlansResponse,promiseSpicodeResponse;
    if(!isApiDisable){
         promiseAllPlansResponse = client.apis.plans.getAllPlans();
         promiseSpicodeResponse =  client.apis.dimensions.getAllSPICodes();
    }
    
    const promiseZoneResponse = checkAndLoadDimention("zone", client);
    const promiseModelgroups =  checkAndLoadDimention("modelGroup", client);
    const promiseCarclasses =  checkAndLoadDimention("carClassCode", client);
    const promiseCarGroups =  checkAndLoadDimention("carGroup", client);
    const promiseCarClassAndGroup= checkAndLoadDimention("carclassAndgroup",client);
    const promiseManufacturerGroups =  checkAndLoadDimention("manufacturer", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseAcceptableZones =  checkAndLoadDimention("acceptablezones", client);
    const promiseProgramtypeResponse =  checkAndLoadDimention("programType", client);
    const promiseBuResponse =  checkAndLoadDimention("BU", client);
    const promiseConstrainttypeResponse =  checkAndLoadDimention("constrainttype", client);
    const promiseAllCargroupCarClassResponse =  checkAndLoadDimention("AllCargroupCarClass", client);
    const promiseAllManufacturergroupModelgroupResponse =  checkAndLoadDimention("AllManufacturergroupModelgroup", client);
    const promiseLastCacheUpdatedAt = checkAndLoadDimention("LastCacheUpdatedAt",client);
    const promiseEmailNameMap = checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseAdminModelgroups = checkAndLoadDimention("adminModelGroup",client);
    const promiseAdminManufacturerGroups = checkAndLoadDimention("adminManufacturer",client);
    const promiseAdminCarClassAndGroup= checkAndLoadDimention("admincarclassAndgroup",client);
    const promiseAdminCarGroups = checkAndLoadDimention("adminCarGroup",client);

    const responses = await Promise.all([promiseZoneResponse,promiseModelgroups,
        promiseCarclasses,
        promiseCarGroups,
        promiseCarClassAndGroup,
        promiseManufacturerGroups,
        promisePoolResponse,
        promiseAcceptableZones,
        promiseProgramtypeResponse,
        promiseBuResponse,
        promiseConstrainttypeResponse,
        promiseAllCargroupCarClassResponse,
        promiseAllManufacturergroupModelgroupResponse,
        promiseLastCacheUpdatedAt,
        promiseEmailNameMap,
        promiseAdminPoolResponse,
        promiseAdminModelgroups,
        promiseAdminManufacturerGroups,
        promiseAdminCarClassAndGroup,
        promiseAdminCarGroups
    ]);

    if(!isApiDisable){
        const responsesArray = await Promise.all([promiseAllPlansResponse, promiseZoneResponse,promiseSpicodeResponse,])
        const [allPlansResponse, zoneResponse] = responsesArray.map(o => o.body.data);
        return ({
            "allPlans": allPlansResponse,"zones": zoneResponse
        });
    } else {
        return [{}]
    }
}

export async function fetchAllPlans() {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const getAllPlansResponse = await client.apis.plans.getAllPlans();

    return getAllPlansResponse.body.data;
}

export async function fetchLastUpdatedCacheTime(curentTime) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const getAllLogsResponse = await client.apis.admin.getAllMasterDataUpdateTrackers(null,{requestBody: { "currentTime": curentTime}});
    
    return getAllLogsResponse.body.data;
}

export async function fetchZoneRfData(planid) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const getZoneRfData = await client.apis.revenuefunction.getZoneRf();
    return getZoneRfData.body.data;
}

export async function fetchPoolRfData() {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const getPoolRfData = await client.apis.revenuefunction.getPoolRf();
    return getPoolRfData.body.data;
}

export async function deletePlanRow(planid) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const deletePlanByIdResponse = await client.apis.plans.deletePlan({ "planid": planid });
    return deletePlanByIdResponse.body.data.rowCount;
}
export async function callSaveDescription(planid, description) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = await client.apis.plans.updatePlan({"planid": planid}, {
        requestBody: { "updatedBy": userEmail,"data":{"description":description} }
    });
    return response.body.data;
}

export async function callSaveZoneRf(planid, name) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = await client.apis.plans.createParameter({"planid": planid, "key": 'RF_CODE_ZONE'}, {
        requestBody: { "updatedBy": userEmail,"data":{"value":name} }
    });
    return response.body.data;
}

export async function callSavePoolRf(planid, name) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = await client.apis.plans.createParameter({"planid": planid,"key": 'RF_CODE_POOL'}, {
        requestBody: { "updatedBy": userEmail,"data":{"value":name} }
    });
    return response.body.data;
}

export async function AddNewPlanService(planId) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
    const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId

    const response = await client.apis.plans.createNewPlan({}, {
        requestBody: { "updatedBy": userEmail,"data":{"copied_from":planId,"jobId":jobId} }
    });
    if(!response.body.data || !response.body.data.MessageId 
        || !response.body.data.ResponseMetadata || !response.body.data.ResponseMetadata.RequestId){

            if(response.body.statusCode==="500" && response.body.body){
                let body=JSON.parse(response.body.body);
                throw new Error(body.error);
            }else{
                throw new Error("Failed to initiate process");
            }

            
    }
    let resp = response.body.data;
    resp.planId=resp.planid;
    resp.jobId=jobId;
    return resp;
}

export async function CallGetPlanByIdService(planid) {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const getPlanByIdResponse = await client.apis.plans.getPlanById({ "planid": planid});
    return getPlanByIdResponse.body.data;
}

export async function startPlanRunService(planid){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const startPlanRunServiceResponse = await client.apis.adjustments.startRun({ "planid": planid });
    return startPlanRunServiceResponse.body.message;
}

export async function CallCopyPlanWithOutput(copiedFrom){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const response = await client.apis.plans.copyWithOutput({}, {
        requestBody: { "updatedBy": userEmail,"data":{"copied_from":copiedFrom} }
    });

    const planid = response.body.data;

    return planid;
}

export async function CallCopyPlanToMainInputs(planid,selectedTabsForCopy){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    // console.log("client.apis==",client.apis);
    const responseWithJobId = await client.apis.plans.getNewJobIdForXFleetJob();
    // console.log("responseWithJobId=",responseWithJobId);
    const jobId=responseWithJobId.body.data.rows[0].nextval;//Getting the JobId
    // console.log("Job_id=",jobId);
    let response = await client.apis.plans.startCopyPlanToMainInputs({ "planid": planid}, {
        requestBody: { "updatedBy": userEmail,"jobId":jobId,"tabsSelected":selectedTabsForCopy }
    });
    // console.log("startCopyPlanToMainInputs Resonse=====",response);

    if(!response.body.data || !response.body.data.MessageId 
        || !response.body.data.ResponseMetadata || !response.body.data.ResponseMetadata.RequestId){
            // console.log("ERROR startCopyPlanToMainInputs Resonse=====",response);


            if(response.body.statusCode==="500" && response.body.body){
                let body=JSON.parse(response.body.body);
                throw new Error(body.error);
            }else{
                throw new Error("Failed to initiate process");
            }

            
    }

    //LOCAL DEBUGGING START
    // let dummyInput={Records:[{Sns:{Message:JSON.stringify({ "fromplanid": planid, "updatedBy": userEmail,"forJobId":jobId,"tabsSelected":selectedTabsForCopy })}}]};
    // response = await client.apis.plans.copyPlanToMainInputs({ "planid": planid}, {
    //     requestBody: {dummyInput}});
    // console.log("copyPlanToMainInputs Resonse=====",response);
    //LOCAL DEBUGGING END

    // response = await client.apis.plans.getStatusOfCopyToMainInputJob({ "planid": planid,"jobid":jobId}, null);
    // console.log("getStatusOfCopyToMainInputJob Resonse=====",response);


    // if(response.body.statusCode==="500" && response.body.body){
    //     let body=JSON.parse(response.body.body);
    //     throw new Error(body.error);
    // }
    // console.log("startCopyPlanToMainInputs Resonse=====",response);
    let resp = response.body.data;
    resp.planId=planid;
    resp.jobId=jobId;
    // console.log("startCopyPlanToMainInputs response.body.data=====",resp);
    return resp;
}

export async function getCopyPlanToMainInputsStatus(planId,jobId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
  

    let response = await client.apis.plans.getStatusOfCopyToMainInputJob({ "planid": planId,"jobid":jobId}, null);
    // console.log("getStatusOfCopyToMainInputJob Resonse=====",response);

    if(response.body.statusCode==="500" && response.body.body){
        let body=JSON.parse(response.body.body);
        throw new Error(body.error);
    }
    // console.log("copyPlanToMainInputs Resonse=====",response);
    const resp = response.body.data;
    // console.log("copyPlanToMainInputs response.body.data=====",resp);
    return resp;
}

export async function getCreatePlanStatus(jobId){
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
  
    let response = await client.apis.plans.getStatusOfCreatePlanJob({"jobid":jobId}, null);
    let rows=response.body.data.rows;
        if(rows && Array.isArray(rows)){
            for(let i=0;i<rows.length;i++){//Expected rows only 1 due to query, so only 1-round loop
            if(rows[i].status==="ERROR"){
                throw new Error(rows[i].message); //Exit the function with Exception
                //return rows[i];
            }else{
                //let resp={'status':rows[i].status,'newPlanid':JSON.parse(rows[i].message).planid};
                return rows[i];
            }
         }
        }
}

export async function updateAdjustmentsEditStatus(planid,planStatus){
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    const response = await client.apis.plans.updatePlan({"planid": planid}, {
        requestBody: { "updatedBy": userEmail,"data":{'planStatus':planStatus} }
    });
    return response.body.data;
}


export async function getRoleInfo(name,email,preferred_username){
    // console.log("INPUT=",name,email,preferred_username);
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    
    const response = await client.apis.admin.getRoleInfo(null,{
        requestBody: { "name": name,"email":email,"prefered_username":preferred_username }
    });
    // const response = {
    //     body:{
    //         data:{
    //             name:USER,
    //             accessList:{
    //                 "All_PLans":"read-only",
    //                 "plan_inputs":"read-write",
    //                 "main_inputs":"read-write",
    //                 "adjustments":"read-write"
    //             }
    //         }
    //     }
    // }
    // console.log("API Response getRoleInfo==", response);
    
    if(response.body.statusCode==="500"){
        // console.log("response.body===",response.body);
        return {error:response.body};
    }else{
        return response.body.data;
    }
    
}
