import {
  dateFormatter,
  currencyFormatter,
  valueFormatter,
  localeFormatter,
  updatedOnDateFormatter,
} from "../../../utils/formatters/valueFormatter";
import {AdditionaCostColumns,cellEditors} from "../../../Constants";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
  {
    headerName: "",
    field: "",
    minWidth: 40,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    editable: false,
    sortable: false,
    resizable: false,
    suppressColumnsToolPanel: true,
    flex: 0,
  },
  {
    field: AdditionaCostColumns.Zone.field,
    headerName: AdditionaCostColumns.Zone.headerName,
    cellEditor: cellEditors.autoCompleteEditor,
    unSortIcon: true,filterType:"dropdown",filterOptions:[],
    cellClassRules: cellClassRules(AdditionaCostColumns.Zone.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, AdditionaCostColumns.Zone.name);
    },
  },
  {
    field: AdditionaCostColumns.ManufacturerGroup.field,
    headerName: AdditionaCostColumns.ManufacturerGroup.headerName,
    cellEditor: cellEditors.autoCompleteEditor,
    unSortIcon: true,filterType:"dropdown",filterOptions:[],
    cellClassRules: cellClassRules(
      AdditionaCostColumns.ManufacturerGroup.name
    ),
    tooltipValueGetter: (params) => {
      return toolTipUI(params,AdditionaCostColumns.ManufacturerGroup.name);
    },
  },
  {
    field: AdditionaCostColumns.MaintenanceCost.field,
    headerName: AdditionaCostColumns.MaintenanceCost.headerName,
    unSortIcon: true,filterType:"input",
    cellEditor: cellEditors.numericEditor,
    type: "numericColumn",
    valueFormatter: (params) =>
      currencyFormatter(params.data.MAINTENANCE_COST, "$"),
    cellClassRules: cellClassRules(AdditionaCostColumns.MaintenanceCost.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, AdditionaCostColumns.MaintenanceCost.name);
    },
  },
  {
    field: AdditionaCostColumns.DamageCost.field,
    headerName: AdditionaCostColumns.DamageCost.headerName,
    unSortIcon: true,filterType:"input",
    cellEditor: cellEditors.numericEditor,
    type: "numericColumn",
    valueFormatter: (params) => currencyFormatter(params.data.DAMAGE_COST, "$"),
    cellClassRules: cellClassRules(AdditionaCostColumns.DamageCost.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, AdditionaCostColumns.DamageCost.name);
    },
  },
  {
    field: AdditionaCostColumns.NetInServiceCost.field,
    headerName: AdditionaCostColumns.NetInServiceCost.headerName,
    unSortIcon: true,filterType:"input",
    cellEditor: cellEditors.numericEditor,
    type: "numericColumn",
    valueFormatter: (params) =>
      currencyFormatter(params.data.NET_IN_SERVICE_COST, "$"),
    cellClassRules: cellClassRules(AdditionaCostColumns.NetInServiceCost.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, AdditionaCostColumns.NetInServiceCost.name);
    },
  },
  {
    field: AdditionaCostColumns.RepurchaseReconditioningCost.field,
    headerName: AdditionaCostColumns.RepurchaseReconditioningCost.headerName,
    unSortIcon: true,filterType:"input",
    cellEditor: cellEditors.numericEditor,
    type: "numericColumn",
    valueFormatter: (params) =>
      currencyFormatter(params.data.REPURCHASE_RECONDITIONING_COST, "$"),
    cellClassRules: cellClassRules(AdditionaCostColumns.RepurchaseReconditioningCost.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, AdditionaCostColumns.RepurchaseReconditioningCost.name);
    },
  },
  {
    field: AdditionaCostColumns.RiskReconditioningCost.field,
    headerName: AdditionaCostColumns.RiskReconditioningCost.headerName,
    unSortIcon: true,filterType:"input",
    cellEditor: cellEditors.numericEditor,
    type: "numericColumn",
    valueFormatter: (params) =>
      currencyFormatter(params.data.RISK_RECONDITIONING_COST, "$"),
    cellClassRules: cellClassRules(AdditionaCostColumns.RiskReconditioningCost.name),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, AdditionaCostColumns.RiskReconditioningCost.name);
    },
  },
  {
    field: AdditionaCostColumns.UpdatedBy.field, 
    headerName: AdditionaCostColumns.UpdatedBy.headerName, 
    editable: false, unSortIcon: true,filterOptions:[],
    cellClassRules: cellClassRules(AdditionaCostColumns.UpdatedBy.name), 
    valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
    comparator: customUpdatedByComparator,
    tooltipValueGetter: params => {
        return toolTipUI(params, AdditionaCostColumns.UpdatedBy.name)
    }
},
{
    field: AdditionaCostColumns.UpdatedOn.field, 
    headerName: AdditionaCostColumns.UpdatedOn.headerName, 
    editable: false,
    valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
    unSortIcon: true, cellClassRules: cellClassRules(AdditionaCostColumns.UpdatedOn.name), 
    comparator: customDateComparator,
    tooltipValueGetter: params => {
        return toolTipUI(params, AdditionaCostColumns.UpdatedOn.name)
    }
},
  {
    headerName: "",
    colId: "actions",
    cellRenderer: "AdditionalCostActionRenderer",
    editable: false,
    filter: false,
    sortable: false,
    minWidth: 120,
    pinned: "right",
  },
];

function toolTipUI(params, field) {
  if(params.data.validations && params.data.validations[field]){
  if (
    params.data.validations &&
    params.data.validations[field].error !== null
  ) {
    return params.data.validations[field].error;
  }
  if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
    return "Duplicate row";
  }
}
}

function cellClassRules(field) {
  return {
    errorCell: (params) => {
      if (params.data.validations && params.data.validations[field]) {
        return params.data.validations[field].error !== null;
      }
    },
    validRow: (params) => {
      if (params.data.validations && params.data.colDef) {
        var index = params.data.colDef.findIndex(function (item, i) {
          return item.id === params.data.ID && item.value === field;
        });
        return index > -1 && params.data.validations[field].error === null;
      }
    },
  };
}
export const defaultColDef = {
  editable: true,
  sortable: true,
  minWidth: 120,
  filter: false,
  resizable: true,
  floatingFilter: false,
  flex: 1,
};
