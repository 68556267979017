import {FETCH_USER_MANAGEMENT_DATA_PENDING, FETCH_USER_MANAGEMENT_DATA_SUCCESS,FETCH_USER_MANAGEMENT_DATA_ERROR, SAVE_ROLE_MANAGEMENT_TRANSACTION_PENDING, SAVE_ROLE_MANAGEMENT_TRANSACTION_SUCCESS, SAVE_ROLE_MANAGEMENT_TRANSACTION_ERROR} from "./UserManagement.ActionTypes";

const initialState = {
    pending: false,
    error: null,
    userManagementData:[],
    transactionSaveSuccess:null
}

export default function userManagementReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_MANAGEMENT_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error: null
            });
        case FETCH_USER_MANAGEMENT_DATA_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                userManagementData: action.data,
                error: null
            });
        case FETCH_USER_MANAGEMENT_DATA_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.data.error
            });
        case SAVE_ROLE_MANAGEMENT_TRANSACTION_PENDING:
                return Object.assign({}, state, {
                    pending: true,
                    transactionSaveSuccess: null
                    });
        case SAVE_ROLE_MANAGEMENT_TRANSACTION_SUCCESS:
                return Object.assign({}, state, {
                    pending: false,
                    roleManagementData: action.data,
                    transactionSaveSuccess: true
                    });
        case SAVE_ROLE_MANAGEMENT_TRANSACTION_ERROR:
                return Object.assign({}, state, {
                    pending: false,
                    error: action.error,
                    transactionSaveSuccess: false
                    });
        default:
            return state;
    }
}

