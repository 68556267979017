import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { TextFieldInput } from '../../../../core-components/Input/Input';
import './TextFieldEditor.css';

export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState('');

    function inputHandler(e) {
        setValue(e.target.value);
    }
    
    useEffect(o => {
        setTimeout(function () {
            if(inputRef && inputRef.current) {
                inputRef.current.select();
                inputRef.current.focus();
            }
        }, 100);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            }
        };
    });

    return (
        <div className='textfieldStyles'>
        <TextFieldInput
            className="ag-input-field-input ag-text-field-input"
            type="text"
            id="iddescription"
            value={value}
            inputRef={inputRef}
            variant="standard"
            defaultValue={props.value}
            onTextChanged={inputHandler}
            inputProps={{
                maxLength: 256
              }}
        />
        </div>
    )
})