import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import diaglogstyles from './DialogComponent.css';
import icon_close from "../../assets/images/close.svg";

const DialogBox = (props) => {
    const { open, handleClose, handleYes, dialogMessage, title, action, canceltext, noText } = props;

    function handleClickYes() {
        handleYes();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="dialogbox"
            >
                <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                    <span>{title}</span>
                    <span><img src={icon_close} onClick={handleClose} className="closeicon" alt="iconclose"></img></span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="dialogcontent">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="actionbuttons">
                    {canceltext ? <Button className='cancelbutton' autoFocus onClick={handleClose} color="primary">
                        Cancel
                    </Button> : <Button className='cancelbutton' autoFocus onClick={handleClose} color="primary">
                        {noText}
                    </Button>}
                    <Button className='okbutton' onClick={handleClickYes} color="primary">
                        {action}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default DialogBox;