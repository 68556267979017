import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from "prop-types";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useDispatch } from "react-redux";
import UpgradePathsSearchBarstyel from './UpgradePathsSearchBar.css';
import { searchTemplateRecord } from '../../../store/upgradepaths/UpgradeTemplates.Actions';
import { searchUpgradePathRecord } from '../../../store/upgradepaths/UpgradePaths.Actions';

const UpgradePathsSearchBar = (props) => {
    const { upgradeButtonSelected,setIsSearchBarValueExists } = props;
    const dispatch = useDispatch();
    const [searchKey, setSearchKey] = useState();

    const onSearchValueChanged = (e) => {
        const searchkey = e.target.value;
        if(e.target.value===''){
            setIsSearchBarValueExists(false);
        }else{
            setIsSearchBarValueExists(true);
        }
        setSearchKey(searchkey);
        const searchParameters = { searchkey, upgradeButtonSelected };
        if (upgradeButtonSelected == 'UPGRADE TEMPLATES') {
            dispatch(searchTemplateRecord(searchParameters));
        } else if (upgradeButtonSelected == 'UPGRADE PATHS') {
            dispatch(searchUpgradePathRecord(searchParameters));
        }
    }

    useEffect(() => {

        setSearchKey('');

    }, [upgradeButtonSelected])

    return (
        <TextField className="searchinputRounded" id="input-with-icon-textfield"
            placeholder="Search" InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            type="search" variant="outlined" onChange={(e) => onSearchValueChanged(e)} value={searchKey} />
    );
}

UpgradePathsSearchBar.propTypes = {
    onSearchValueChanged: PropTypes.any
}

export default UpgradePathsSearchBar;