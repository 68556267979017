import React, { Component, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './AccordionView.css';
import icon_sortdown from "../../assets/images/icon-arrow-sortdown.svg";
import ProgramsAccordionContent from '../../components/accordionview/ProgramsAccordionContent';
import ConstraintAccordionContent from '../../components/accordionview/ConstraintAccordionContent';
import UpgradePathAccordionContent from '../../components/accordionview/UpgradePathAccordionContent';
import icon_hot from '../../assets/images/icon_son.svg';
import icon_cold from '../../assets/images/icon_cold.svg';
import { disableActionBarElements } from "../../store/constraints/Constraints.Actions";
import { useDispatch, useSelector } from "react-redux";



const AccordionView = (props) => {
    let { accordionData, tabIndex, planid, constraintType } = props;
    const [sortAscending, setSortAscending] = useState(true)
    const [expanded, setExpanded] = React.useState(false);
    const [editedContent, setEditedContent] = useState([]);
    const location = useLocation();
    let locatioName = location.pathname;
    let childData = [];
    const dispatch = useDispatch();

    useEffect(() => {
        setExpanded(true);
    }, [])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const sortAccordion = () => {
        if (sortAscending == false) {
            setSortAscending(true);
            accordionData = accordionData.sort(function (a, b) { return a.header - b.header });
        } else {
            setSortAscending(false);
            accordionData = accordionData.sort(function (a, b) { return b.header - a.header });
        }
    }

    const NewAccordionView = (val, headerValue) => {
        let templateValue = headerValue !== undefined ? headerValue.header : val.header;
        return (
            <div className="accordions">
                <Accordion
                    defaultExpanded={false}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel-header"
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        {val.header == undefined && val.header1 !== undefined && val.header2 !== undefined ?
                            <div className='headerstyle'><label className='heading smooth-goto' id={locatioName + '/' + templateValue + '_' + val.header1} >{val.header1}
                                <span className='cargroupstyle'>{val.header2}</span></label></div>
                            : <div className='headerstyle'>{val.header == 'HOT' ? <span className='typeicons'><img src={icon_hot} className='typeiconstyle'></img><label className='heading'>{val.header}</label></span>
                                : val.header == 'COLD' ? <span className='typeicons'><img src={icon_cold} className='typeiconstyle'></img><label className='heading'>{val.header}</label></span>
                                    : <label className='heading'>{val.header}</label>}
                            </div>
                        }
                    </AccordionSummary>
                    <AccordionDetails className='accordiondetails'>
                        {val.children && val.children.map((child, index) => {
                            if (child.content != undefined)
                                return (
                                    <div>
                                        { tabIndex == 0 ? <ProgramsAccordionContent data={child.content} planid={planid}></ProgramsAccordionContent>
                                            : tabIndex == 3 ?
                                                <ConstraintAccordionContent data={child.content} planid={planid} handleEditEvent={(content, eventType) => handleEditEvent(content, eventType)} editedContent={editedContent} constraintType={constraintType}></ConstraintAccordionContent>
                                                : tabIndex == 5 ?
                                                    <UpgradePathAccordionContent planid={planid} data={child.content} templateValue={templateValue} mainData={childData}></UpgradePathAccordionContent>
                                                    : ""}
                                    </div>
                                )
                            else if (child.children != undefined && child.children.length > 0 || child.header != undefined)
                                return (
                                    NewAccordionView(child, val)
                                )
                        })}
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }

    function handleEditEvent(content, eventType) {
        let index = editedContent.indexOf(content);
        if(eventType == "edit") {       
            if(index < 0) 
                editedContent.push(content);
        } else {
            if(index >= 0) 
                editedContent.splice(index, 1);
        }
        setEditedContent(editedContent);
        if(editedContent.length == 0)
            dispatch(disableActionBarElements(false));
        else
            dispatch(disableActionBarElements(true)); 
    }

    return (
        <div style={{width: '1104px'}}>
            { tabIndex == 0 ? <div onClick={sortAccordion} className="accordionsort"><label className="sorttext">Year/Manufacturer/Program</label><img src={icon_sortdown} className="sorticon"></img></div> : <div></div>}
            {accordionData.map((value, index) => {
                if (value.children.content != undefined)
                    return (
                        <div>
                            { tabIndex == 0 ? <ProgramsAccordionContent planid={planid} data={value.children.content}></ProgramsAccordionContent>
                                : tabIndex == 3 ?
                                    <ConstraintAccordionContent planid={planid} data={value.children.content} handleEditEvent={(content, eventType) => handleEditEvent(content, eventType)} editedContent={editedContent}>
                                    </ConstraintAccordionContent>
                                    : tabIndex == 5 ?
                                        <UpgradePathAccordionContent planid={planid} data={value.children.content} mainData={childData}></UpgradePathAccordionContent>
                                        : ""}
                        </div>
                    )
                else if (value.children != undefined && value.children.length > 0)
                    childData = value.children;
                return (
                    NewAccordionView(value)
                )
            })}
        </div>
    )
}

export default AccordionView;