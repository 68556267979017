import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../../../utils/authUtils';
import { minifyTransaction } from '../../../../utils/utils';

export async function getTncParameterData(rfid){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   //Note:using same api,getRFParameters for tncparameters and coreparameters
    const tncParameterResponse = await client.apis.revenuefunction.getRFParameters({},{requestBody:{'rfid':rfid}});
   // return tncParameterResponse.obj.data;
    return ({
        "gridData": tncParameterResponse.obj.data,"rowcount":tncParameterResponse.obj.data.length
    });
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncParameterData failed'}
   }
   }

export async function saveRfTncParameterTransactions(rfid,transactionList,tabname,typeName){
    try{
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
    let promiseArray = [];
    await client.apis.revenuefunction.bulktransactrfparameters({"rfid":rfid},  {requestBody: { "updatedBy": userEmail,"tabName":tabname,"typeName":typeName,"transactions": minifyTransaction(transactionList)}});
    const responsesArray = await Promise.all(promiseArray);
    return responsesArray;
    }catch(error){

    }
}

export async function getTncParametersTabErrorStatus(rfid){
    try{
    const accessToken = await getAccessTokenAfterAuth();
   
    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
   
    const promisetncParametersResponse = await client.apis.revenuefunction.getRFParametersTabErrorStatus({'rfid':rfid});
    const responsesArray = await Promise.all([promisetncParametersResponse]);
    const [tncParametersTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
    return tncParametersTabErrorStatusResponse;
     }
    catch (error) {
       console.error(error);
       return {'Error':'getTncParametersTabErrorStatus failed'}
   }
   }