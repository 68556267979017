import React, { useContext, useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from 'react-router-dom';
import NttTab from "../../components/tabs/NttTab"
import NttTabs from "../../components/tabs/NttTabs";
import TabPanel from '../../core-components/Tabs/TabPanel';
import AllTabPanel from './adjustmentstabpanel/AllTabPanel';
import CoreTabPanel from './adjustmentstabpanel/CoreTabPanel';
import CargoTabPanel from './adjustmentstabpanel/CargoTabPanel';
import { fetchOptimizedPlanOutputData, processAdjustmentsRun} from '../../store/adjustments/Adjustments.Actions';
import {setEditAdjustmentsStatus} from '../../store/topbar/AllPlans.Actions';
import TncTabPanel from './adjustmentstabpanel/TncTabpanel';
import { Button} from '@material-ui/core';
import PlanInputsPagestyle from './AdjustmentsPage.css';
import Tab from "@material-ui/core/Tab";
import CoreElectricTabPanel from './adjustmentstabpanel/CoreElectricTabPanel';
import TncICETabPanel from './adjustmentstabpanel/TncICETabPanel';
import TncElectricTabPanel from './adjustmentstabpanel/TncElectricTabPanel';
import CoreIceTabPanel from './adjustmentstabpanel/CoreIceTabPanel';
import { userAcs } from '../../App';
import { filterCat } from '../../Constants';
import DialogBox from "../../components/Dialogcomponent/DialogComponent";
import {loadPlanDataById} from '../../store/topbar/AllPlans.Actions';
export const adjustmentFilters = createContext();
let message = "Are you sure? This will change the validation for the Adjustments"
let title = "Edit Adjustments";
let deletetext = "Yes";
let notext = "No";

export default function AdjustmentsPage() {
    const [value, setValue] = useState(0);
    const [selectedVal, setSelectedVal] = useState('Type>Zone');
    const [showCancelButton, setShowCancelButton] = useState(false);
    const { planid } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const defuserAcs =  useContext(userAcs);
    const[filterActivityForAdjVar,setFilterActivityForAdjVar] = useState([]);
    const[filterActivityForReqValue,setFilterActivityForReqValue] = useState([]);
    const[filterActivityForReqAdjVal,setFilterActivityForReqAdjVal] = useState([]);
    const[filterActivityForOriginalValue,setFilterActivityForOriginalValue] = useState([]);
    let[filterCatForTypeZoneView,setFilterCatForTypeZoneView] = useState({ "activity_type": [], "zones": [], "variance": ["between"], "requestedValue":["between"], "originalValue":["between"], "requestedAdjustment":["between"] });
    let [tempFilterArray, setTempFilterArray] = useState(filterCat);
    let[filterCatForZoneTypeView,setFilterCatForZoneTypeView] = useState({ "activity_type": [], "zones": [], "variance": ["between"], "requestedValue":["between"], "originalValue":["between"], "requestedAdjustment":["between"] });
    let [tempFilterArrayForZoneView, setTempFilterArrayForZoneView] = useState(filterCat);
    const [open, setOpen] = useState(false);
    useEffect(()=>{
        defuserAcs.setUserAcs(false)
    },[])
    const handleChange = (event, newValue) => {
        setValue(newValue)
        setFilterActivityForAdjVar([]);
        setFilterActivityForOriginalValue([]);
        setFilterActivityForReqAdjVal([]);
        setFilterActivityForReqValue([]);
    }

    const handleViewChanged = (selected) => {
        setSelectedVal(selected);
    }

    const tabsData = [
        {tabTitle: "All", tabKey: 0 },
        { tabTitle: "Core", tabKey: 1 },
        { tabTitle: "Core ICE", tabKey: 2 },
        { tabTitle: "Core Electric", tabKey: 3 },
        { tabTitle: "TNC", tabKey: 4},
        { tabTitle: "TNC ICE", tabKey: 5},
        { tabTitle: "TNC Electric", tabKey: 6},
        { tabTitle: "Cargo", tabKey: 7 },
        { tabTitle: "Sales", tabKey: 8 },
        { tabTitle: "Sales Channels", tabKey: 9 }]

    var planOutputData = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.planOptimizationOutputs;
    });

    var allZones = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.zones;
    });

    let plansDataById = useSelector((state) => {
     return state.rootAllPlansReducer.allPlansReducer.plansDataById;
    });
    let parametersData = useSelector((state) => {
        return state.rootParametersReducer.parametersReducer.parameters;
    });
    const filterData = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.adjustmentsFilterData;
      });

    const loadOptimizationPlanOutputData = (planid,parametersData) => {
        dispatch(fetchOptimizedPlanOutputData(planid,parametersData));
    } 

    useEffect(() => {
        loadOptimizationPlanOutputData(planid,parametersData);
        dispatch(loadPlanDataById(planid));
        let url = '/plan/' + planid + '/adjustments';
        history.push({
            pathname: url,
            state: {  // location state
                selectedplan: planid,
            },
        });
    }, [planid]);

    function handleCancel() {
        loadOptimizationPlanOutputData(planid,parametersData);
    }

    function handleGridSaveBehavior() {
        setShowCancelButton(false);
    }

    const handleProcessAdjustmentsRun = () => {
        dispatch(processAdjustmentsRun(planid))
    }

    const handleClickOpenDialog = ()=>{
        setOpen(true);
    }
    const handleCloseDialog = () => {
        setOpen(false);
    };
    const handleSetEditAdjustmentsStatus = ()=>{
     dispatch(setEditAdjustmentsStatus(planid)) //update plan status
     setOpen(false);
    }
    let planStatus = (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) ? plansDataById[0].status : 'invalid')
    return (
        <adjustmentFilters.Provider value = {{filterActivityForAdjVar,setFilterActivityForAdjVar,filterActivityForReqValue,setFilterActivityForReqValue,filterActivityForReqAdjVal,setFilterActivityForReqAdjVal,filterActivityForOriginalValue,setFilterActivityForOriginalValue, 
            filterCatForTypeZoneView,setFilterCatForTypeZoneView,tempFilterArray, setTempFilterArray,
            filterCatForZoneTypeView,setFilterCatForZoneTypeView,tempFilterArrayForZoneView, setTempFilterArrayForZoneView}}>
        <div className='adjustmentmargin'>
            <span className="adjustmentflexitems">
                <h2 className='adjustmentheader'>Adjustments</h2>
                <div className="adjustmentrun">
                {planStatus && planStatus==='ADJUSTMENT COMPLETE' ?<Button 
                    onClick={handleClickOpenDialog} className={(plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete')) ? "runblur" : ""}>
                    Edit Adjustments
                    </Button>:''}
                    <Button
                    disabled={(plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as optimal') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as sub-optim') && (plansDataById[0].status.toLowerCase() !== 'optimization complete unknown status')) && (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase()!=='adjustments modified'))&& planStatus!=='invalid'} 
                        onClick={handleProcessAdjustmentsRun} className={((plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error') && (plansDataById[0].status.toLowerCase() !== 'optimization complete') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as optimal') && (plansDataById[0].status.toLowerCase() !== 'optimization complete as sub-optim') && (plansDataById[0].status.toLowerCase() !== 'optimization complete unknown status')) && (plansDataById && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase()!=='adjustments modified'))&& planStatus!=='invalid') ? "runblur" : ""}>
                        Process Adjustments
                    </Button>
                </div>
            </span>
            <AdjustmentsBuTabs handleChange={handleChange} tabsData={tabsData} value={value}/>
            {tabsData.map((tabInfo, index) => (
                <TabPanel key={index}
                    value={value} index={index}>
                    {(index == 0) ? <AllTabPanel accordionData={planOutputData} tabIndex={index} planId={planid} planStatus={planStatus}
                        onViewChanged={handleViewChanged} selectedVal={selectedVal} allZones={allZones} filterData={filterData}  filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> : (index == 1) ?
                            <CoreTabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue}/> : (index == 2)?
                            <CoreIceTabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> : (index == 3)?
                            <CoreElectricTabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> : (index == 4)?
                            <TncTabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> : (index == 5) ?
                            <TncICETabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> : (index == 6) ?
                            <TncElectricTabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> : (index == 7) ?
                            <CargoTabPanel accordionData={planOutputData} selectedVal={selectedVal} tabIndex={index} planId={planid} planStatus={planStatus}
                            onViewChanged={handleViewChanged} handleGridSaveBehavior={handleGridSaveBehavior}
                            showCancelButton={showCancelButton} handleCancel={handleCancel} allZones={allZones} filterData={filterData} filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} filterActivityForOriginalValue={filterActivityForOriginalValue} /> :
                                <div></div>}
                </TabPanel >
            ))}
        <div>
        <DialogBox open={open} handleClose={handleCloseDialog} handleYes={handleSetEditAdjustmentsStatus}
        dialogMessage={message} title={title} action={deletetext} styles={"styles"}
        noText={notext}>
    </DialogBox>
      </div>
        </div>
        </adjustmentFilters.Provider>

    )
}

function AdjustmentsBuTabs({handleChange, tabsData, value}){
    const tabStyle = {
        default_tab: {
            marginLeft: "5px",
            borderRadius: "4px 4px 0px 0px",
            color: "white",
            background: "black",
            fontSize: 14,
            textTransform: 'none',
        },
        active_tab: {
            background: "white",
            marginLeft: "5px",
            borderColor: "white",
            borderRadius: "4px 4px 0px 0px",
            color: "black",
            fontSize: 14,
            textTransform: 'none'
        },
        tabItemContainer: {
            margin: "50px",
            display: "flex",
            flexWrap: "wrap",
        }
    };

    const getStyle = (isActive) => {
        return isActive ? tabStyle.active_tab : tabStyle.default_tab
    }
    let editing = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.editing;
    });

return(<NttTabs onChange={handleChange} style={tabStyle.tabItemContainer}>
        {tabsData.map((tabInfo, index) => (
            <NttTab label={tabInfo.tabTitle}
                id={`simple-tab-${index}`}
                key={tabInfo.tabTitle}
                style={getStyle(value === index)}
                disabled={(index != 0 && index != 1 && index != 2 && index != 3 && index != 4 && index != 5 && index != 6) && index != 7 || editing } />
        ))}
    </NttTabs>);
}