import { combineReducers } from 'redux';
import userManagementReducer from './UserManagement.Reducer';
import multiGridUIReducer from '../AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import columnDefinitionReducer from "./UserManagement.ColumnDef.Reducer";

const rootAdminUserManagementReducer = combineReducers({
      userManagementReducer
    , userManagementGridReducer: createNamedWrapperReducer(multiGridUIReducer, "usermanagementgrid")
    , columnDefinitionReducer
})

export default rootAdminUserManagementReducer;