import { cellEditors, SalvageRateColumns } from "../../../Constants";
import { dateFormatter, currencyFormatter,valueFormatter,localeFormatter, updatedOnDateFormatter } from "../../../utils/formatters/valueFormatter";
import { customDateComparator, customUpdatedByComparator, getNameBasedOnEmail } from "../../../utils/utils";

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    }, 
    {
        field: SalvageRateColumns.ZoneName.field, headerName: SalvageRateColumns.ZoneName.headerName,  
        minWidth: 150, cellEditor: cellEditors.autoCompleteEditor, 
        unSortIcon: true,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(SalvageRateColumns.ZoneName.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.ZoneName.name)
        }
    },
    {
        field: SalvageRateColumns.BU.field, headerName: SalvageRateColumns.BU.headerName,
        cellEditor: cellEditors.autoCompleteEditor,  minWidth: 110,
        unSortIcon: true,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(SalvageRateColumns.BU.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.BU.name)
        }
    },
    // {  // As per NEW REQUIREMENT : This column is not required, but kept for reference and for undoing
    //     field: 'QUARTER', headerName: 'Quarter',cellEditor: "autoCompleteEditor",  minWidth: 110,unSortIcon: true,
    //     cellClassRules: cellClassRules("QUARTER"), tooltipValueGetter: params => {
    //         return toolTipUI(params, "QUARTER")
    //     }
    // },
    {
        field: SalvageRateColumns.SalvageMinMileage.field, headerName:  SalvageRateColumns.SalvageMinMileage.headerName,  
        minWidth: 110,unSortIcon: true,filterType:"input",
        cellEditor: cellEditors.numericEditor, 
        type: 'numericColumn',
        valueFormatter: params => {
                return localeFormatter(params.data.SALVAGE_MIN_MILEAGE);
        },
        cellClassRules: cellClassRules(SalvageRateColumns.SalvageMinMileage.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.SalvageMinMileage.name)
        } 
    },
    {
        field: SalvageRateColumns.SalvagePercentage.field, headerName: SalvageRateColumns.SalvagePercentage.headerName,  
        minWidth: 200,unSortIcon: true,filterType:"input",
        cellEditor: cellEditors.numericEditor, 
        type: 'numericColumn',
        valueFormatter: params => {
                return params.data.SALVAGE_PERCENTAGE;
        },
        cellClassRules: cellClassRules(SalvageRateColumns.SalvagePercentage.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.SalvagePercentage.name)
        }
        
    },
    {
        field: SalvageRateColumns.Month.field, headerName: SalvageRateColumns.Month.headerName, 
        minWidth: 110, unSortIcon: true,filterType:"datepicker",
        valueFormatter: params => dateFormatter(params.data.MONTH),
        cellEditor: cellEditors.dateEditor,
        cellClassRules: cellClassRules(SalvageRateColumns.Month.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.Month.name)
        }
    },
    {
        field: SalvageRateColumns.EngineType.field, headerName: SalvageRateColumns.EngineType.headerName,
        cellEditor: cellEditors.autoCompleteEditor, 
        minWidth: 110,unSortIcon: true,filterType:"dropdown",filterOptions:[],
        cellClassRules: cellClassRules(SalvageRateColumns.EngineType.name), 
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.EngineType.name)
        }
    },
    {
        field: SalvageRateColumns.UpdatedBy.field, 
        headerName: SalvageRateColumns.UpdatedBy.headerName, 
        editable: false, unSortIcon: true,filterOptions:[],
        cellClassRules: cellClassRules(SalvageRateColumns.UpdatedBy.name), 
        valueFormatter: params => getNameBasedOnEmail(params.data.updated_by),
        comparator: customUpdatedByComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.UpdatedBy.name)
        }
    },
    {
        field: SalvageRateColumns.UpdatedOn.field, 
        headerName: SalvageRateColumns.UpdatedOn.headerName, 
        editable: false,
        valueFormatter: params => updatedOnDateFormatter(params.data.updated_on),
        unSortIcon: true, cellClassRules: cellClassRules(SalvageRateColumns.UpdatedOn.name), 
        comparator: customDateComparator,
        tooltipValueGetter: params => {
            return toolTipUI(params, SalvageRateColumns.UpdatedOn.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer: "SalvageRateActionRenderer",
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
    }
];

function toolTipUI(params, field) {
 if(params.data.validations && params.data.validations[field]){
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.status === "ERROR_ROW" || params.data.status === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
 }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
