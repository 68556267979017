import { columnDefs, defaultColDef } from "./allocated_grid_data/AllocatedColumnDefs";
import { FETCH_ALLOCATED_DROPDOWNVALUES_PENDING, FETCH_ALLOCATED_DROPDOWNVALUES_SUCCESS, FETCH_ALLOCATED_DROPDOWNVALUES_ERROR, SET_ALLOCATED_EDITABLE } from '../allocated/Allocated.ActionTypes';
import { checkDate, getDropdownValuesForYear, safeCaseInsensitiveCompare } from "../../utils/utils";
import { AllocatedColumns, maximumDate, minimumDate } from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues : [],
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            spi_code: { error: checkIfValueEmpty(data.spi_code, AllocatedColumns.SpiCode.headerName) },
            manufacturer_group: { error: checkIfValueEmpty(data.manufacturer_group,AllocatedColumns.ManufacturerGroup.headerName,data.model_group) },
            model_year: { error: checkIfValidYear(data.model_year, AllocatedColumns.ModelYear.headerName) },
            model_group: { error: checkIfValueEmpty(data.model_group, AllocatedColumns.ModelGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field===AllocatedColumns.ModelGroup.field)) },
            pool: { error: checkIfValidPool(data.pool, AllocatedColumns.Pool.headerName,initialState.columnDefs.findIndex(itm=>itm.field===AllocatedColumns.Pool.field)) },
            car_class_code: { error: checkIfValueEmpty(data.car_class_code, AllocatedColumns.CarClassCode.headerName,initialState.columnDefs.findIndex(itm=>itm.field===AllocatedColumns.CarClassCode.field)) },
            count: { error: checkIfValueEmpty(data.count, AllocatedColumns.Count.headerName) },
            requested_delivery_month: { error: checkIfValueEmpty(data.requested_delivery_month, AllocatedColumns.RequestedDeliveryMonth.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkIfValueEmpty(value, name,data) {
    if(name===AllocatedColumns.CarClassCode.headerName || name===AllocatedColumns.ModelGroup.headerName){
        if (value === undefined || value === "" || value === null) {
            return name +" "+"cannot be empty";
        }else 
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
                if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                    return name +" "+value+" no-longer available";
                }
            }
        }
         if(name===AllocatedColumns.ManufacturerGroup.headerName){
           if(!data){
                 return "Please select a Model group"; //First Modal group" should be selected before selecting a manufacturer group
              }else if (value === undefined || value === "" || value === null) {
                 return name +" "+"cannot be empty";
              }
              else{
                let message=null;
                let allManufacturergroupModelgroup =sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLMANUFACTURERGROUPMODELGROUP_CLEAN_MAP"))):[];
                let trimmedModalGroup=data.trim();
                let options=allManufacturergroupModelgroup[trimmedModalGroup]?allManufacturergroupModelgroup[trimmedModalGroup]:[];
                
                if(options.indexOf(value)==-1){
                    message = name+" "+'does not belongs to the Model group';
                }
                return message;
            }
    }
    if (name === AllocatedColumns.RequestedDeliveryMonth.headerName) {
        // var itemValue = new Date(value).getFullYear();
        // if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)) {
        //     return name + " should be in +5 or -10 range from the current year"
        // }
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        }
        else if(value == 'Invalid date'){
            return name +" "+"cannot be invalid date";
        }
        else if(!moment(value).isBetween(minimumDate, maximumDate,'year','[]')){
            return name + " should be in +5 or -10 range from the current year"
        }
    }   
    return null;
}
/*
 * Method to check if pool is valid. ALL is not applicable for pool in allocated.
 * This method is applicable for only pool in Allocated
 * @param {*} value of the row
 * @param {*} name of the row
 * @returns null if valid else error string
 */
function checkIfValidPool(value, name, data) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } else
    if (value.toUpperCase() === "ALL"){
        return `${name} cannot be ${value}`;
    } else
    if (initialState.columnDefs[data].cellEditorParams !== undefined) {
        if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
            return name +" "+value+" no-longer available";
        }
    }
    return null;
}

function checkIfValidYear(value, name) {
    if (value === undefined || value === '' || value === null)
        return name + " cannot be empty";
    else {
        var itemValue = parseInt(value, 0);
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10))
            return name + " should be in +5 or -10 range from the current year"
    }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.spi_code, itm2.spi_code) && safeCaseInsensitiveCompare(itm.car_class_code, itm2.car_class_code) &&
            safeCaseInsensitiveCompare(itm.model_group, itm2.model_group) && safeCaseInsensitiveCompare(itm.manufacturer_group, itm2.manufacturer_group) &&
            safeCaseInsensitiveCompare(itm.pool, itm2.pool) && (itm.model_year === itm2.model_year) && checkDate(itm.requested_delivery_month, itm2.requested_delivery_month)) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
                //console.log("Duplicate Found==>",itm);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if( column.field===AllocatedColumns.ModelYear.field){
            column.filterOptions = getDropdownValuesForYear() // this function is used to get dropdown values for year columns from current year (-10 years to +5 years)
        }
        if (column.field === AllocatedColumns.SpiCode.field) {
            let spiCodeFilterData = [];
            data.spiCode.map((val) => {
                spiCodeFilterData.push({ "label": val, "value": val })
            })
            spiCodeFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.spiCode }
            column.filterOptions = spiCodeFilterData;
        }
        if (column.field === AllocatedColumns.ModelGroup.field) {
            let modelGroupFilterData = [];
            data.adminModelGroup.map((val) => {
                modelGroupFilterData.push({ "label": val, "value": val })
            })
            modelGroupFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.modelGroup }
            column.filterOptions = modelGroupFilterData;
        }
        if (column.field === AllocatedColumns.CarClassCode.field) {
            let carClassCodeFilterData = []
            data.adminCarClass.map((val) => {
                carClassCodeFilterData.push({ "label": val.car_class, "value": val.car_class })
            })
            carClassCodeFilterData.push({"label":"Blanks","value":"Blanks"})
            column.cellEditorParams = { options: data.carClassCode }
            column.filterOptions = carClassCodeFilterData;
        }
        if(column.field === AllocatedColumns.ManufacturerGroup.field){
            let manufacturerFilterData = [];
            data.adminManufacturerGroup.map((val) => {
            manufacturerFilterData.push({ "label": val.code, "value": val.code })
            })
            manufacturerFilterData.push({"label":"Blanks","value":"Blanks"})
            column.filterOptions = manufacturerFilterData;
        }
        if (column.field === AllocatedColumns.Pool.field) {
            fetchDropdownsForPools(data.pool,data.adminpools, column)
        }
        if (column.field == AllocatedColumns.UpdatedBy.field) {
            let userKeys = Object.keys(data.users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": data.users[userData], "value": data.users[userData] })
            })
            column.filterOptions = usersFilterData
        }
        if(column.colId==='actions'){
            // this if condition changes cellrenderer when new version
            if(JSON.parse(localStorage.getItem("newArchitecture"))){
                column.cellRenderer='MainPlanInputsActionRenderer'
            }
            else{
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                column.cellRenderer='AllocatedActionRenderer'
            }
        }
    });
    return columnDefs;
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id'  && value !== 'requested_delivery_month' && value !== 'updated_by') {
        return true;
    } else {
        return false;
    }
}

function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = [];
    if(pools.length>0){
    pools.forEach(val => {
        if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        }
    });
   
  }
  adminpools.forEach(val=>{
    poolFilterData.push({"label":val.code,"value":val.code})
  })
  poolFilterData.push({"label":"Blanks","value":"Blanks"})

    element.cellEditorParams = { options: poolData }
    element.filterOptions=poolFilterData
}


export default function allocatedColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALLOCATED_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_ALLOCATED_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_ALLOCATED_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_ALLOCATED_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}