import React, { useEffect } from 'react';
import SalesAndFinancesActionBar from '../../../components/salesandfinance/salesandfinanceactionbar/SalesAndFinanceActionBar';
import SalesAndFinanceAccordionContainer from '../../accordionview/SalesAndFinanceAccordionContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdjustmentPlanOutputData } from '../../../store/salesandfinance/SalesAndFinance.Actions';
import { loadPlanDataById } from '../../../store/topbar/AllPlans.Actions';
import BvDepOverrideTabPanel from '../salesandfinancetabpanel/BVDepOverrideTabPanel'

export default function SalesTabPanel({bvDepOverrideGridColDef}) {
  

  let plansDataById = useSelector((state) => {
    return state.rootAllPlansReducer.allPlansReducer.plansDataById;
  });
  let presentableData = useSelector((state) => {
    return state.rootSalesAndFinanceReducer.salesAndFinanceReducer.presentableData;
  });

  let zones = useSelector((state) => {
    return state.rootSalesAndFinanceReducer.salesAndFinanceReducer.zones;
  });

  return (
    <div className='width100percent'>
      <SalesAndFinancesActionBar 
      plansDataById={plansDataById}
      salesAndFinanceInputsGridColDefReducer={bvDepOverrideGridColDef}
      />
      <SalesAndFinanceAccordionContainer 
      plansDataById={plansDataById} 
      accordionData={presentableData} 
      zones={zones}/>
    </div>
  )
}