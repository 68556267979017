import React, { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NttTabs from '../../components/tabs/NttTabs';
import { mainRfInputsDropdownValues, revenuefunctionRfInputsTabs, revenuefunctionTabs, rfids } from '../../Constants';
import { Box, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import NttTab from '../../components/tabs/NttTab';
import { useHistory } from 'react-router-dom';
import AllRfsTabPanel from './allrfs/AllRfsTabPanel';
import { activateCreateRfPlan, fetchAllRFsData, getRfDetailsByRfId, setGenerateRfProcessStatus } from '../../store/revenuefunction/allrfs/AllRFs.Actions';
import MainRfInputsTabPanel from './mainrfinputs/MainRfInputsTabPanel';
import { fetchMainRFInputTncData,fetchTncIceDropDownValues } from '../../store/revenuefunction/mainrfinputs/tncice/TncIce.Actions';
import { fetchTncRevenueDropDownValues } from '../../store/revenuefunction/planrevenueinputs/tnc_revenue/TncRevenue.Actions'; 
import {fetchCoreRevenueDropDownValues} from '../../store/revenuefunction/planrevenueinputs/core_revenue/CoreRevenue.Actions'; 
import {ResetRFMultiGridState, addNewRow, refreshGridData, validateGrid } from '../../store/revenuefunction/RevenueFunctionMultiGrid/RevenueFunctionMultiGrid.Actions';
import GenerateRfDialogBox from '../../components/Dialogcomponent/GenerateRfDialogComponent';
import RfInputsRevenueTabPanel from './rfinputs/RfInputsRevenueTabPanel';
import RfInputsHeaderSection from './rfinputs/RfInputsHeaderSection';
import { fetchGridData, fetchGridTabErrorStatus, fetchMultiTabErrorStatusBasedOnModule, resetGridState, setGridFilterParams, setGridFilterViewStatus, setGridName, setGridUserPageNumber, validateSingleGridsData } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { escapeHtml } from '../../utils/utils';
import { fetchCoreDemandDropDownValues } from '../../store/revenuefunction/mainrfinputs/coredemand/CoreDemand.Actions';
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const tabStyle = {
  default_tab: {
    marginLeft: "5px",
    borderRadius: "4px 4px 0px 0px",
    color: "white",
    background: "black",
    fontSize: 14,
    textTransform: 'none',
  },
  active_tab: {
    background: "white",
    marginLeft: "5px",
    borderColor: "white",
    borderRadius: "4px 4px 0px 0px",
    color: "black",
    fontSize: 14,
    textTransform: 'none'
  },
}

const getStyle = (isActive, tabKey) => {
  return isActive ? tabStyle.active_tab : tabStyle.default_tab;
}

export default function RevenueFunction(props) {
  const [value, setValue] = useState(0);
  const [showSaveOption, setShowSaveOption] = useState(false);
  const [saveCancelDisabled, setSaveCancelDisabled] = useState(false);
  const [refreshCells, setRefreshCells] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isSearchBarValueExists, setIsSearchBarValueExists] = useState(false);
  const [bulkRowDeleteIconDisabled, setBulkRowDeleteIconDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  let [isSaveClicked, setIsSaveClicked] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [snackBarIfError, setSnackBarIfError] = useState(false);
  const [generateRfProcessClicked,setGenerateRfProcessClicked]= useState(false);
  const [newRfPlanDescriptionText,setNewRfPlanDescriptionText]=useState('');
  const [typeName, setTypeName] = useState(null);
  const [hideActions,setHideActions] = useState(false);
  const [isError,setIsError]=useState(false)
  let selectedurlHasRfinputs = window.location.href.indexOf("rfinputs") > -1;
  let rfIdFromUrl = window.location.href.split("/")[5];
  const revenuefunctionTablist = selectedurlHasRfinputs?revenuefunctionRfInputsTabs:revenuefunctionTabs
  let oktext = "Start RF Run";
  let canceltext = "Cancel";

  const dispatch = useDispatch();
  const history = useHistory();
  const pending = useSelector((state) => {
    return state.rootAllRFsReducer.allRFsReducer.pending || state.PaginationGridReducer.pending;
  });

const loadTncRevenueDropDownValues=()=>{
    dispatch(fetchTncRevenueDropDownValues())
}
const loadCoreRevenueDropDownValues=()=>{
  dispatch(fetchCoreRevenueDropDownValues())
}

 const allRFsData = useSelector((state) => {
    return state.rootAllRFsReducer.allRFsReducer.allRFsData;
  });

  const allRFsGridReducer = useSelector((reduxState) => {
    return reduxState.rootAllRFsReducer.rootAllRFsGridReducer;
  });

  const allRFscolumnDefinitionReducer = useSelector((reduxState) => {
    return reduxState.rootAllRFsReducer.columnDefinitionReducer;
  });
  const allRFsFilterSearchKey = useSelector((state) => {
    return state.rootAllRFsReducer.allRFsReducer.allRFsFilterSearchKey;
  });

const generateNewRfProcessCompleted=useSelector((state)=>{
  return state.rootAllRFsReducer.allRFsReducer.isGenerateRfButtonClicked;
});

const gridUserPageno=useSelector((state)=>{
  return state.PaginationGridReducer.gridUserPageno;
})

const gridName=useSelector((state)=>{
  return state.PaginationGridReducer.gridName
})

const gridData = useSelector((state)=>{
  return state.PaginationGridReducer.gridData;
});

const gridReducer = useSelector((state) => {
  return {...state.RfMultiGridUIReducer};
},shallowEqual);

const gridFilterUserSelectedParams = useSelector((state) => {
  return {...state.PaginationGridReducer.gridFilterUserSelectedParams};
},shallowEqual);

const gridFilterViewStatus = useSelector((state) => {
  return {...state.PaginationGridReducer.gridFilterView};
},shallowEqual);

const gridSearchViewStatus = useSelector((state)=>{
  return {...state.PaginationGridReducer.gridSearchView};
},shallowEqual)
const gridUserSearchParams = useSelector((state) => {
  return {...state.PaginationGridReducer.gridUserSearchParams};
},shallowEqual);

const gridErrorViewStatus = useSelector((state) => {
  return {...state.PaginationGridReducer.gridErrorView};
},shallowEqual);

const callGridFetchApi = useSelector((state)=>{
  return state.PaginationGridReducer.callGridFetchApi;
})

const tncIceGridColDefReducer = useSelector((state)=>{
  return state.RFTncIcecolumnDefinitionReducer;
});

const tncEvGridColDefReducer = useSelector((state)=>{
  return state.RFTncEvcolumnDefinitionReducer;
});

const tncParameterGridColDefReducer = useSelector((state)=>{
  return state.RFParameterscolumnDefinitionReducer;
})

const TncRevenueGridColDefReducer = useSelector((state)=>{
  return state.RFTncRevenuecolumnDefinitionReducer;
});

const CoreRevenueGridColDefReducer = useSelector((state)=>{
  return state.RFCoreRevenuecolumnDefinitionReducer;
});

const coreDemandGridColDefReducer = useSelector((state)=>{
  return state.RFCoreDemandcolumnDefinitionReducer;
});

const coreAdjustmstsGridColDefReducer = useSelector((state)=>{
  return state.RFCoreAdjColumnDefinitionReducer;
});

const RFParametercolumnDefinitionReducer = useSelector((state)=>{
  return state.RFParametercolumnDefinitionReducer;
})

const rfDetailsById = useSelector((state) => {
  return state.rootAllRFsReducer.allRFsReducer.rfDetailsById;
});

const [GridColDefReducer,setGridColDefReducer]=useState(null)

  const loadAllRFsData = () => {
    dispatch(fetchAllRFsData())
  }

  const handleSetTabValue = ()=>{
    setValue(0)
    //resetGridState--->refers to resetting of all grid Pagination state data
    dispatch(resetGridState(true));
    //ResetRFMultiGridState ---> Refers to resetting the multigrid state like currentData,originalDtaa,transactions,ColumnDefs etc to initialstate
    dispatch(ResetRFMultiGridState())
  }

  const loadRfInputsBasedOnType = (type)=>{
    if(type==='TNC'){
      setTypeName(mainRfInputsDropdownValues[0])
      dispatch(resetGridState(false));
      dispatch(ResetRFMultiGridState()) 
      setTypeName(mainRfInputsDropdownValues[0])
      dispatch(fetchTncIceDropDownValues())
      dispatch(setGridName('tncicegrid'));
      setGridColDefReducer(tncIceGridColDefReducer)
      if(rfIdFromUrl && selectedurlHasRfinputs){
        //last column of column definitition,where we are displaying actions will be hidden
         tncIceGridColDefReducer.columnDefs[tncIceGridColDefReducer.columnDefs.length-1]['hide']=true
         tncEvGridColDefReducer.columnDefs[tncEvGridColDefReducer.columnDefs.length-1]['hide']=true
         tncParameterGridColDefReducer.columnDefs[tncParameterGridColDefReducer.columnDefs.length-1]['hide']=true
         setHideActions(true);
      }else{
        //while navigating back from rfinput to main rf page,set hide back to false to make actions visible
        tncIceGridColDefReducer.columnDefs[tncIceGridColDefReducer.columnDefs.length-1]['hide']=false
        tncEvGridColDefReducer.columnDefs[tncEvGridColDefReducer.columnDefs.length-1]['hide']=false
        tncParameterGridColDefReducer.columnDefs[tncParameterGridColDefReducer.columnDefs.length-1]['hide']=false
        setHideActions(false);
      }
    }
    else if(type==='CORE'){
      setTypeName(mainRfInputsDropdownValues[1])
      dispatch(resetGridState(false));
      dispatch(ResetRFMultiGridState()) 
      setTypeName(mainRfInputsDropdownValues[1])
      dispatch(fetchCoreDemandDropDownValues())
      dispatch(setGridName('coredemandgrid'));
      setGridColDefReducer(coreDemandGridColDefReducer)
      if(rfIdFromUrl && selectedurlHasRfinputs){
        //last column of column definitition,where we are displaying actions will be hidden
        coreDemandGridColDefReducer.columnDefs[coreDemandGridColDefReducer.columnDefs.length-1]['hide']=true
        coreAdjustmstsGridColDefReducer.columnDefs[coreAdjustmstsGridColDefReducer.columnDefs.length-1]['hide']=true
        RFParametercolumnDefinitionReducer.columnDefs[RFParametercolumnDefinitionReducer.columnDefs.length-1]['hide']=true
         setHideActions(true);
      }else{
        //while navigating back from rfinput to main rf page,set hide back to false to make actions visible
        coreDemandGridColDefReducer.columnDefs[coreDemandGridColDefReducer.columnDefs.length-1]['hide']=false
        coreAdjustmstsGridColDefReducer.columnDefs[coreAdjustmstsGridColDefReducer.columnDefs.length-1]['hide']=false
        RFParametercolumnDefinitionReducer.columnDefs[RFParametercolumnDefinitionReducer.columnDefs.length-1]['hide']=false
        setHideActions(false);
      }
    }
     }

  const handleChange = (event, tabKey) => {
    setValue(tabKey)
    setShowSaveOption(false);
    dispatch(setGridUserPageNumber(1))
    if (tabKey == 0) {
      setTypeName(null)
      if(rfIdFromUrl && selectedurlHasRfinputs){
        if(rfDetailsById && rfDetailsById.TYPE && rfDetailsById.TYPE==='TNC'){
          dispatch(resetGridState(false));
          dispatch(ResetRFMultiGridState())
          dispatch(fetchTncRevenueDropDownValues()) 
          dispatch(setGridName('tncrevenuegrid'));
          setGridColDefReducer(TncRevenueGridColDefReducer);  
        }else if(rfDetailsById && rfDetailsById.TYPE && rfDetailsById.TYPE==='CORE'){
          dispatch(resetGridState(false));
          dispatch(ResetRFMultiGridState())
          dispatch(fetchCoreRevenueDropDownValues()) 
          dispatch(setGridName('corerevenuegrid'));
          setGridColDefReducer(CoreRevenueGridColDefReducer); 
        }     
      }else{
        dispatch(setGridName(''));
        loadAllRFsData();
      }
    }
    else if (tabKey == 1) {
      if(rfIdFromUrl && selectedurlHasRfinputs && rfDetailsById && rfDetailsById.TYPE){
        loadRfInputsBasedOnType(rfDetailsById.TYPE)
      }else{
        setTypeName(mainRfInputsDropdownValues[0])
        dispatch(resetGridState(false));
        dispatch(ResetRFMultiGridState()) 
        setTypeName(mainRfInputsDropdownValues[0])
        dispatch(fetchTncIceDropDownValues())
        dispatch(setGridName('tncicegrid'));
      setGridColDefReducer(tncIceGridColDefReducer)
      if(rfIdFromUrl && selectedurlHasRfinputs){
        //last column of column definitition,where we are displaying actions will be hidden
         tncIceGridColDefReducer.columnDefs[tncIceGridColDefReducer.columnDefs.length-1]['hide']=true
         tncEvGridColDefReducer.columnDefs[tncEvGridColDefReducer.columnDefs.length-1]['hide']=true
         tncParameterGridColDefReducer.columnDefs[tncParameterGridColDefReducer.columnDefs.length-1]['hide']=true
         setHideActions(true);
      }else{
        //while navigating back from rfinput to main rf page,set hide back to false to make actions visible
        tncIceGridColDefReducer.columnDefs[tncIceGridColDefReducer.columnDefs.length-1]['hide']=false
        tncEvGridColDefReducer.columnDefs[tncEvGridColDefReducer.columnDefs.length-1]['hide']=false
        tncParameterGridColDefReducer.columnDefs[tncParameterGridColDefReducer.columnDefs.length-1]['hide']=false
        setHideActions(false);
      }
      }
  }
 }
  const handleRowEditingStarted = (isRowEditing, isOfTypeCopyOrDelete, transactions) => {
    if (isRowEditing && transactions.length > 0) {
      setShowSaveOption(true);
      if (!saveCancelDisabled && !isOfTypeCopyOrDelete)
        setSaveCancelDisabled(true);
    }
  }

  const handleRowEditingStopped = (isRowEditing) => {
    if (isRowEditing) {
      setShowSaveOption(false);
    }
  }

  const handleCellEditingStopped = () => {
    setSaveCancelDisabled(false);
  }

  function downloadCSV(csvStr, filename) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }

  const handleAddNewRow = (gridName,rowIndex) => {
    setShowSaveOption(true);
    dispatch(addNewRow(gridName, rowIndex, []));
  }

  const handleMultipleRowDelete = (selectedRows) => {
    if (selectedRows.length > 0) {
      setBulkRowDeleteIconDisabled(false);
      setSelectedRows(selectedRows);
    } else {
      setBulkRowDeleteIconDisabled(true);
      setSelectedRows(selectedRows)
    }
  }

  const userMessage = (severity, message) => {
    setWarningSnackBarOpen(true);
    setSeverity(severity);
    setWarningMessage(message);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseWarningSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningSnackBarOpen(false);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleGenerateRfDialogClose=()=>{
    setNewRfPlanDescriptionText('')
    setIsError(false)
    setGenerateRfProcessClicked(false)
  }

  const handleGenerateRfProcessStart=()=>{
    dispatch(activateCreateRfPlan(rfids[0][typeName],escapeHtml(newRfPlanDescriptionText),typeName))
    setNewRfPlanDescriptionText('')
    setGenerateRfProcessClicked(false)
  }

  const initializeFilterParamsAndViewStatus = useCallback((gridname,GridColDefReducer) => {
    try {
      if(gridname!='' && !gridFilterUserSelectedParams[gridname]){
        dispatch(setGridFilterParams(gridname,GridColDefReducer,true))
        dispatch(setGridFilterViewStatus(gridname,false));
      }
    } catch (err) {
        console.error('got error while setting filterparams & filterview status');
    }
  }, [dispatch]);

  // const loadGridDataForFilterParams = useCallback(async(filterParams,filterStatus) => {
  //   try {
  //     dispatch(fetchGridData((selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName],gridName,gridUserPageno,filterParams,filterStatus,false));
  //     dispatch(fetchGridTabErrorStatus(rfids[0][typeName],gridName))
  //   } catch (err) {
  //       console.error('got error while loading filtered griddata');
  //   }
  // }, [gridFilterViewStatus]);

  // const loadGridDataForSearchParam = useCallback(async(searchParams,serachStatus)=>{
  //   try{
  //     dispatch(fetchGridData((selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName],gridName,gridUserPageno,undefined,undefined,undefined,searchParams,serachStatus));
  //     dispatch(fetchGridTabErrorStatus(rfids[0][typeName],gridName))
  //   }
  //   catch(err){
  //     console.error('got error while loading searched griddata');
  //   }
  // },[gridSearchViewStatus])

  // const loadGridDataForErrorView = useCallback(async(errorView) => {
  //   try {
  //     dispatch(fetchGridData((selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName],gridName,gridUserPageno,{},false,errorView));
  //     dispatch(fetchGridTabErrorStatus(rfids[0][typeName],gridName))
  //   } catch (err) {
  //       console.error('got error while loading grid errors');
  //   }
  // }, [gridErrorViewStatus]);

  // const loadGridData = useCallback(async() => {
  //   try {
  //     dispatch(fetchGridData((selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName],gridName,gridUserPageno,gridFilterUserSelectedParams[gridName],gridFilterViewStatus[gridName],gridErrorViewStatus[gridName],gridUserSearchParams[gridName],gridSearchViewStatus[gridName]));
  //     dispatch(fetchGridTabErrorStatus(rfids[0][typeName],gridName))
  //   } catch (err) {
  //       console.error('got error while loading filtered griddata');
  //   }
  // },[gridName,gridUserPageno,gridFilterViewStatus,gridErrorViewStatus,gridSearchViewStatus]);

  const loadGridData = useCallback(async(isValidationRequired, manualPageNo) => {
    try {
      if (isValidationRequired) {// if this condition is true then validateSingleGridsData action will be dispatched instead of fetchGridTabErrorStatus
        await dispatch(validateSingleGridsData(gridName, rfids[0][typeName]));
        dispatch(fetchGridData((selectedurlHasRfinputs && rfIdFromUrl) ? rfIdFromUrl : rfids[0][typeName], gridName, manualPageNo ? manualPageNo : gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName], gridErrorViewStatus[gridName], gridUserSearchParams[gridName], gridSearchViewStatus[gridName]));
      }
      else {// this condition will be dispatched when isValidationRequired param is not defined or when it is false
        await Promise.all([dispatch(fetchGridTabErrorStatus(rfids[0][typeName], gridName)),
        dispatch(fetchGridData((selectedurlHasRfinputs && rfIdFromUrl) ? rfIdFromUrl : rfids[0][typeName], gridName, manualPageNo ? manualPageNo : gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName], gridErrorViewStatus[gridName], gridUserSearchParams[gridName], gridSearchViewStatus[gridName]))
        ])
      }

    } catch (err) {
      console.error('got error while loading griddata');
    }
    });

  // useEffect(()=>{
  //   if(gridName!=''){
  //     loadGridData();
  //   }
  // },[gridUserPageno])

  useEffect(() => {
    if (callGridFetchApi == true){
     loadGridData();
    }
  }, [callGridFetchApi])//this useeffect triggers when there is a change in gridName,gridFilterViewStatus,gridErrorViewStatus,gridSearchViewStatus.

  useEffect(() => {
    if ((gridData && GridColDefReducer)) {
      dispatch(refreshGridData(gridName));
      dispatch(validateGrid(gridName, gridData, GridColDefReducer.validationFn, GridColDefReducer.businessValidationFn));
    }
  }, [gridData]);


  useEffect(()=>{
    dispatch(fetchMultiTabErrorStatusBasedOnModule((selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName],typeName,'RF'))
  },[typeName])


  useEffect(() => {
    loadAllRFsData();
  }, [])

  useEffect(()=>{
    return ()=>{
      dispatch(resetGridState(true));
    }
  },[])

  useEffect(()=>{
    if(generateNewRfProcessCompleted){     
      setShowSaveOption(false);
      setNewRfPlanDescriptionText('')
      setGenerateRfProcessClicked(false)
      dispatch(setGenerateRfProcessStatus())
      setValue(0)
      // userMessage('success', 'Successfully created new rf plan');
      dispatch(addNotificationMessage('success', 'Successfully created new rf plan'));
    }
  },[generateNewRfProcessCompleted])

  useEffect(() => {
  if (allRFsData) {
    dispatch(validateGrid("allrfsgrid", allRFsData, allRFscolumnDefinitionReducer.validationFn, allRFscolumnDefinitionReducer.businessValidationFn));
  }
}, [allRFsData]);

useEffect(()=>{
  if(rfIdFromUrl){
    //dispatch(resetGridState(true));
    dispatch(ResetRFMultiGridState()); 
    //dispatch(getRfDetailsByRfId(rfIdFromUrl))
    if(rfDetailsById && rfDetailsById.TYPE && rfDetailsById.TYPE==='TNC' && gridName!='tncrevenuegrid'){
     setGridColDefReducer(TncRevenueGridColDefReducer);
     loadTncRevenueDropDownValues()
     dispatch(setGridName('tncrevenuegrid'));
    }else if(rfDetailsById && rfDetailsById.TYPE && rfDetailsById.TYPE==='CORE' && gridName!='corerevenuegrid'){
     setGridColDefReducer(CoreRevenueGridColDefReducer);
     loadCoreRevenueDropDownValues()
     dispatch(setGridName('corerevenuegrid'));
    }
    
  }
},[rfDetailsById])

useEffect(()=>{
 if(rfIdFromUrl){
  dispatch(ResetRFMultiGridState());
  dispatch(getRfDetailsByRfId(rfIdFromUrl))
  loadGridData();
  }
 },[rfIdFromUrl])

  return (
    <div className='mt-5 '>
      {(selectedurlHasRfinputs)?
      <RfInputsHeaderSection handleSetTabValue={handleSetTabValue} rfIdFromUrl={rfIdFromUrl}></RfInputsHeaderSection>
      :<h2 className="revenueheadertext ml-6 -mt-3">Revenue Functions</h2>
      }
      <div className='mt-3'><NttTabs
        value={value}
        onChange={handleChange}
        style={tabStyle.tabItemContainer}>
        {revenuefunctionTablist.map((tabInfo, index) => (
          <NttTab label={tabInfo.tabTitle}
            id={`simple-tab-${index}`}
            key={tabInfo.tabKey}
            style={getStyle(value === index, tabInfo.tabKey)}
            disabled={showSaveOption || pending}
          />
        ))}
      </NttTabs></div>
      {revenuefunctionTablist.map((tabInfo, index) => (
        <TabPanel index={index} key={tabInfo.tabKey} value={value} className='revenue-adjust'>
          {(index == 0 && !selectedurlHasRfinputs) ?
            <div>
              <AllRfsTabPanel
                allRFsData={allRFsData}
                allRFsGridReducer={allRFsGridReducer}
                allRFscolumnDefinitionReducer={allRFscolumnDefinitionReducer}
                allRFsFilterSearchKey={allRFsFilterSearchKey}
                handleCellEditingStopped={handleCellEditingStopped}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                transactions={allRFsGridReducer.transactions}
                refreshCells={refreshCells}
                setIsFiltered={setIsFiltered}
                isFiltered={isFiltered}
                isSearchBarValueExists={isSearchBarValueExists}
                setIsSearchBarValueExists={setIsSearchBarValueExists}
              >
              </AllRfsTabPanel>
            </div>
            :(index==0 && selectedurlHasRfinputs)?
              <RfInputsRevenueTabPanel
                gridName={gridName}
                RFsData={gridData}
                RFsGridReducer={gridReducer}
                RFscolumnDefinitionReducer={GridColDefReducer}
                handleCellEditingStopped={handleCellEditingStopped}
                handleRowEditingStarted={handleRowEditingStarted}
                handleRowEditingStopped={handleRowEditingStopped}
                transactions={gridReducer.transactions}
                refreshCells={refreshCells}
                setIsFiltered={setIsFiltered}
                isFiltered={isFiltered}
                isSearchBarValueExists={isSearchBarValueExists}
                setIsSearchBarValueExists={setIsSearchBarValueExists}
                loadGridData={loadGridData}
                //loadGridDataForFilterParams={loadGridDataForFilterParams} 
                initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}
                handleMultipleRowDelete={handleMultipleRowDelete} 
                userMessage={userMessage}
                downloadCSV={downloadCSV}
                rfid={(selectedurlHasRfinputs && rfIdFromUrl)?rfIdFromUrl:rfids[0][typeName]}
                rfIdFromUrl={rfIdFromUrl}
                typeName={typeName}
                setGridColDefReducer={setGridColDefReducer}>
              </RfInputsRevenueTabPanel>
            : (index == 1) ?
              <MainRfInputsTabPanel
              setGridColDefReducer={setGridColDefReducer}
              showSaveOption={showSaveOption}
              downloadCSV={downloadCSV}
              handleCellEditingStopped={handleCellEditingStopped}
              handleRowEditingStarted={handleRowEditingStarted}
              handleRowEditingStopped={handleRowEditingStopped}
              isSearchBarValueExists={isSearchBarValueExists}
              setIsSearchBarValueExists={setIsSearchBarValueExists}
              handleSaveCancelDisabled={saveCancelDisabled}
              handleAddNewRow={handleAddNewRow}
              bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
              handleMultipleRowDelete={handleMultipleRowDelete} 
              selectedRows={selectedRows}
              pending={pending}
              setSnackBarIfError={setSnackBarIfError}
              setRefreshCells={setRefreshCells}
              snackBarIfError={snackBarIfError}
              setIsSaveClicked={setIsSaveClicked}
              setShowSaveOption={setShowSaveOption}
              refreshCells={refreshCells}
              isSaveClicked={isSaveClicked}
              setSnackBarOpen={setSnackBarOpen}
              userMessage={userMessage}
              setWarningSnackBarOpen={setWarningSnackBarOpen}
              typeName={typeName}
              setTypeName={setTypeName}
              setGenerateRfProcessClicked={setGenerateRfProcessClicked}
              rfIdFromUrl={rfIdFromUrl}
              selectedurlHasRfinputs={selectedurlHasRfinputs}
              hideActions={hideActions}
              loadGridData={loadGridData}
              //loadGridDataForFilterParams={loadGridDataForFilterParams} 
              //loadGridDataForErrorView={loadGridDataForErrorView} 
              initializeFilterParamsAndViewStatus={initializeFilterParamsAndViewStatus}
              //loadGridDataForSearchParam={loadGridDataForSearchParam}
              >
              </MainRfInputsTabPanel>
              : <div></div>}
        </TabPanel>
      ))}

    <div>
        <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleCloseSnackBar}>
          <Alert
            onClose={handleCloseSnackBar}
            severity="error">
            Cannot save. Please review if any row is invalid.
          </Alert>
        </Snackbar>
      </div>
      <div>
        <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
          <Alert
            onClose={handleCloseWarningSnackBar}
            severity={severity}>{warningMessage}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <GenerateRfDialogBox open={generateRfProcessClicked} handleClose={handleGenerateRfDialogClose} handleYes={handleGenerateRfProcessStart}
          action={oktext} canceltext={canceltext} isError={isError} setIsError={setIsError} newRfPlanDescriptionText={newRfPlanDescriptionText} setNewRfPlanDescriptionText={setNewRfPlanDescriptionText}>
        </GenerateRfDialogBox>
      </div>
    </div>
  )
}