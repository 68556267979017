import {getMoveCostData, saveMoveCostTransactions, fetchMoveCostDropdownService,uploadMoveCostCSV, getMoveCostErrorData, insertUploadData, fetchMoveCostFilterService, fetchMoveCostFilterRecords, fetchMoveCostSearchRecords,deleteMoveCostRecordsService, getMoveCostErrorIds} from '../../services/movecost/movecostservice';
import {FETCH_MOVECOST_DATA_PENDING, FETCH_MOVECOST_DATA_SUCCESS, FETCH_MOVECOST_DATA_ERROR, UPLOAD_MOVECOST_PENDING, UPLOAD_MOVECOST_SUCCESS, UPLOAD_MOVECOST_ERROR,
    SAVE_MOVECOST_TRANSACTION_PENDING, SAVE_MOVECOST_TRANSACTION_SUCCESS, SAVE_MOVECOST_TRANSACTION_ERROR,
    FETCH_MOVECOST_DROPDOWNVALUES_PENDING,FETCH_MOVECOST_DROPDOWNVALUES_SUCCESS,FETCH_MOVECOST_DROPDOWNVALUES_ERROR, RESET_TO_MOVECOST_INITIALSTATE, FETCH_MOVECOST_ERROR_DATA_SUCCESS, SET_ASSUMPTION_MOVE_COST_PAGE_VALUE, SET_ASSUMPTION_MOVE_COST_UPLOAD_STATUS, SET_ASSUMPTION_MOVE_COST_ERRORVIEW_STATUS, FETCH_MOVECOST_FILTER_DISPLAY_DATA, SET_MOVE_COST_FILTER_PARAMS, SET_MOVE_COST_FILTER_RECORDS, SET_MOVE_COST_FILTER_VIEW, SET_MOVE_COST_FILTER_SEARCH_VIEW, SET_MOVE_COST_SEARCH_RECORDS, SET_MOVE_COST_SEARCH_TEXT,
    FETCH_MOVECOST_DELETE_PENDING,FETCH_MOVECOST_DELETE_SUCCESS,FETCH_MOVECOST_DELETE_ERROR, FETCH_MOVECOST_ERROR_IDS_SUCCESS} from '../../store/movecost/MoveCost.ActionTypes.js';
import { UPDATE_IS_ROW_INVALID } from '../MultiGrid/MultiGrid.Actions';
import { SetAssumptionError, SetAssumptionPending} from '../planassumptions/PlanAssumptions.Actions';
import { RemoveuploadedFileInsertStatus, setinsertStatusCheckFlag } from '../topbar/AllPlans.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchMoveCostData = (planid,moveCostPageNo) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MOVECOST_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getMoveCostData(planid,moveCostPageNo);
            if(response){
                
                let moveCostTotData=response.rowcount;
                // console.log("moveCostTotData==>",moveCostTotData);
                let uniqueIDs = await getMoveCostErrorIds(planid,moveCostTotData, true);
                // console.log("fetchMoveCostData :: uniqueIDs===>",uniqueIDs);
                dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                dispatch(serviceActionSuccess(UPDATE_IS_ROW_INVALID,uniqueIDs.length>0));
                // console.log("uniqueIDs==>",uniqueIDs);
                dispatch(serviceActionSuccess(FETCH_MOVECOST_DATA_SUCCESS,response));  
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}


export const fetchMoveCostErrorData = (planid,moveCostTotData,moveCostPageNo,uniqueIDsTemp) =>{    
    return async dispatch =>{
        // console.log(`fetchMoveCostErrorData: planid=${planid},moveCostTotData=${moveCostTotData},moveCostPageNo=${moveCostPageNo}`);
        try{
            dispatch (serviceActionPending(FETCH_MOVECOST_DATA_PENDING));
            dispatch(SetAssumptionPending(true));
            // console.log("moveCostTotData==>",moveCostTotData);
            if(!moveCostTotData){
                let output=await getMoveCostData(planid,1);
                moveCostTotData=output.rowcount;
            }
            // console.log("uniqueIDs==>",uniqueIDs);
            let uniqueIDs = await getMoveCostErrorIds(planid,moveCostTotData, false);
            dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
            
            // console.log("uniqueIDs==>",uniqueIDs);
            let response=await getMoveCostErrorData(planid,uniqueIDs, moveCostPageNo?moveCostPageNo:1);
            // console.log('error data->',response);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error("Error while getting move cost error data: ", e.message);
            dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,`ERROR: ${e.message}`));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const fetchMoveCostFilterData = (planid,filterparameters,moveCostPageNo) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MOVECOST_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await fetchMoveCostFilterRecords(planid,filterparameters,moveCostPageNo);
            if(response){
                dispatch(serviceActionSuccess(SET_MOVE_COST_FILTER_RECORDS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const fetchMoveCostSearchData = (planid,searchInColumns,searchText,moveCostPageNo) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MOVECOST_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await fetchMoveCostSearchRecords(planid,searchInColumns,searchText,moveCostPageNo);
            if(response){
                dispatch(serviceActionSuccess(SET_MOVE_COST_SEARCH_RECORDS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadMoveCostData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_MOVECOST_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadMoveCostCSV(planId, files);
            let moveCostData = await getMoveCostData(planId);
            if(uploadResp.body!==null && uploadResp.name !== 'Error'&& uploadResp.data.length>0){
                dispatch(serviceActionSuccess(UPLOAD_MOVECOST_SUCCESS, moveCostData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_MOVECOST_ERROR, "move cost upload failed"));
                dispatch(SetAssumptionError("move cost upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_MOVECOST_ERROR, "move cost upload failed"));
            dispatch(SetAssumptionError("move cost upload failed"))
        }
    }
}

export const InsertuploadMoveCostData = (planId, files,gridname)=> {
    return async dispatch => {
        try {
            let uploadResp = await insertUploadData(planId, files,gridname);
            console.log(uploadResp)
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch (serviceActionPending(FETCH_MOVECOST_DATA_PENDING));
                dispatch(SetAssumptionPending(true))
            let moveCostData = await getMoveCostData(planId,1);
            if(moveCostData){
                let moveCostTotData=moveCostData.rowcount;
                // console.log("moveCostTotData==>",moveCostTotData);
                let uniqueIDs = await getMoveCostErrorIds(planId,moveCostTotData, true);
                // console.log("fetchMoveCostData :: uniqueIDs===>",uniqueIDs);
                dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                dispatch(serviceActionSuccess(UPDATE_IS_ROW_INVALID,uniqueIDs.length>0));
                // console.log("uniqueIDs==>",uniqueIDs);

                dispatch(setinsertStatusCheckFlag(true))
                dispatch(serviceActionSuccess(FETCH_MOVECOST_DATA_SUCCESS,moveCostData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MOVECOST_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        }else{
            dispatch(SetAssumptionMoveCostUploadStatus('insert failed'))
        }
        } catch (e) {
            console.error("Exception", e);
        }
    }
}

export const saveMoveCostTransactionData = (planid,transactionList,moveCostPageNo,moveCostErrorView,moveCostFilterView,movecostFilterParams,moveCostSearchView,moveCostColumnsConstantData,filterSearchText)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MOVECOST_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveMoveCostTransactions(planid, transactionList);
            let moveCostData
            if(moveCostSearchView){
                moveCostData = await fetchMoveCostSearchRecords(planid,moveCostColumnsConstantData,filterSearchText,moveCostPageNo);
            }
            if(moveCostFilterView){
                moveCostData = await fetchMoveCostFilterRecords(planid,movecostFilterParams,moveCostPageNo);
            } else if(!moveCostSearchView) {
                let output=await getMoveCostData(planid,1);
                let moveCostTotData=output.rowcount;
                let uniqueIDs = await getMoveCostErrorIds(planid,moveCostTotData);
                dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                dispatch(serviceActionSuccess(UPDATE_IS_ROW_INVALID,uniqueIDs.length>0));
                
                if(moveCostErrorView){                          
                    moveCostData=await getMoveCostErrorData(planid,uniqueIDs, 1);
                    if(moveCostData){
                        dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_DATA_SUCCESS,moveCostData));
                    }
                }else{
                    moveCostData = await getMoveCostData(planid,moveCostPageNo);
                }
            }
            if (moveCostData) {
                dispatch(serviceActionSuccess(SAVE_MOVECOST_TRANSACTION_SUCCESS, {moveCostData,'movecostview':moveCostErrorView,'movecostfilterview':moveCostFilterView,'movecostfiltersearchview':moveCostSearchView}));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            console.log("Exception",e.message);
            dispatch(serviceActionError(SAVE_MOVECOST_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchMoveCostDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_MOVECOST_DROPDOWNVALUES_PENDING));
        let moveCostData = await fetchMoveCostDropdownService(planid);
        if (moveCostData) {
            dispatch(serviceActionSuccess(FETCH_MOVECOST_DROPDOWNVALUES_SUCCESS, moveCostData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if (isNewArchitecture) {// this if is for new architecture
                    dispatch(newValidateGrid("movecostGrid", data, coldef.validationFn, coldef.businessValidationFn, validateTransactionsIfMasterDataChanged));
                }else{// this else is for old architecture and will be removed later
                    dispatch(validateGrid("movecostgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_MOVECOST_DROPDOWNVALUES_ERROR, moveCostData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MOVECOST_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}
//To Get and Display all Filter dropdown constant Values for MoveCost  
export const fetchFilterDisplayData = () => {
    return async dispatch => {
        try {
            // dispatch(serviceActionPending(FETCH_ALLOCATED_FILTER_DATA_PENDING));
            let filterData = await fetchMoveCostFilterService();
            if (filterData) {
                dispatch(serviceActionSuccess(FETCH_MOVECOST_FILTER_DISPLAY_DATA,filterData));
            } 
        } catch (_) {
            //dispatch(serviceActionError(FETCH_ALLOCATED_FILTER_DATA_ERROR, "Unknown error"));
        }
    }
}

export const setMoveCostFilterParameters = (filterParams) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MOVE_COST_FILTER_PARAMS,filterParams))
    }
}

export const setMoveCostFilterView = (value) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MOVE_COST_FILTER_VIEW,value))
    }
}

export const setMoveCostFilterSearchView = (value) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MOVE_COST_FILTER_SEARCH_VIEW,value))
    }
}

export const setMoveCostFilterSearchText = (value) =>{
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_MOVE_COST_SEARCH_TEXT,value))
    }
}

export const resetMoveCost = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MOVECOST_INITIALSTATE))
    }
  }

export const SetAssumptionMoveCostPageValue = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_MOVE_COST_PAGE_VALUE,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const SetAssumptionMoveCostUploadStatus = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_MOVE_COST_UPLOAD_STATUS,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const SetAssumptionMoveCostErrorViewStatus = (value) =>{
    return async dispatch =>{
        try{
            dispatch ({type:SET_ASSUMPTION_MOVE_COST_ERRORVIEW_STATUS,data:value});
        } 
        catch(e){
            console.error(e);
        }
    }

}

export const deleteMoveCostRecords = (planId,data) =>{
    return async dispatch => {
        try {
        dispatch(serviceActionPending(FETCH_MOVECOST_DELETE_PENDING));
        dispatch(SetAssumptionPending(true))
        await deleteMoveCostRecordsService(planId,data);
        let {moveCostColumnsConstantData,filterSearchKey,movecostFilterParams,moveCostPageNo,operationApplied} = data;
        let moveCostData;
            if(operationApplied==='search'){
                moveCostData = await fetchMoveCostSearchRecords(planId,moveCostColumnsConstantData,filterSearchKey,moveCostPageNo);
            }
            else if(operationApplied==='filter'){
                moveCostData = await fetchMoveCostFilterRecords(planId,movecostFilterParams,moveCostPageNo);
            }
            else if(operationApplied==='errordata'){
                let output=await getMoveCostData(planId,1);
                let moveCostTotData=output.rowcount;
                let uniqueIDs = await getMoveCostErrorIds(planId,moveCostTotData);
                dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_IDS_SUCCESS,{uniqueErrorIDs:uniqueIDs}));
                        
                moveCostData=await getMoveCostErrorData(planId,uniqueIDs, 1);
                if(moveCostData){
                    dispatch(serviceActionSuccess(FETCH_MOVECOST_ERROR_DATA_SUCCESS,moveCostData));
                }
            } else {
                moveCostData = await getMoveCostData(planId,moveCostPageNo?moveCostPageNo:1);
            }
        if(moveCostData){
            dispatch(serviceActionSuccess(FETCH_MOVECOST_DELETE_SUCCESS,{moveCostData,'operationApplied':operationApplied}));
            dispatch(SetAssumptionPending(false))
        }
    } catch (e) {
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MOVECOST_DELETE_ERROR, "Unknown error"));
        dispatch(SetAssumptionError("Unknown error"))
    }
    }
}

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}