export const FETCH_MOVECOST_DATA_PENDING = 'FETCH_MOVECOST_DATA_PENDING';
export const FETCH_MOVECOST_DATA_SUCCESS = 'FETCH_MOVECOST_DATA_SUCCESS';
export const FETCH_MOVECOST_ERROR_DATA_SUCCESS = 'FETCH_MOVECOST_ERROR_DATA_SUCCESS';
export const FETCH_MOVECOST_DATA_ERROR = 'FETCH_MOVECOST_DATA_ERROR';
export const UPLOAD_MOVECOST_PENDING = 'UPLOAD_MOVECOST_PENDING';
export const UPLOAD_MOVECOST_SUCCESS = 'UPLOAD_MOVECOST_SUCCESS';
export const UPLOAD_MOVECOST_ERROR = 'UPLOAD_MOVECOST_ERROR';
export const SAVE_MOVECOST_TRANSACTION_PENDING = 'SAVE_MOVECOST_TRANSACTION_PENDING';
export const SAVE_MOVECOST_TRANSACTION_SUCCESS = 'SAVE_MOVECOST_TRANSACTION_SUCCESS';
export const SAVE_MOVECOST_TRANSACTION_ERROR = 'SAVE_MOVECOST_TRANSACTION_ERROR';
export const FETCH_MOVECOST_DROPDOWNVALUES_PENDING = 'FETCH_MOVECOST_DROPDOWNVALUES_PENDING';
export const FETCH_MOVECOST_DROPDOWNVALUES_SUCCESS = 'FETCH_MOVECOST_DROPDOWNVALUES_SUCCESS';
export const FETCH_MOVECOST_DROPDOWNVALUES_ERROR = 'FETCH_MOVECOST_DROPDOWNVALUES_ERROR';
export const RESET_TO_MOVECOST_INITIALSTATE = 'RESET_TO_MOVECOST_INITIALSTATE';
export const SET_ASSUMPTION_MOVE_COST_PAGE_VALUE='SET_ASSUMPTION_MOVE_COST_PAGE_VALUE';
export const SET_ASSUMPTION_MOVE_COST_UPLOAD_STATUS='SET_ASSUMPTION_MOVE_COST_UPLOAD_STATUS';
export const SET_ASSUMPTION_MOVE_COST_ERRORVIEW_STATUS='SET_ASSUMPTION_MOVE_COST_ERRORVIEW_STATUS';
export const FETCH_MOVECOST_FILTER_DISPLAY_DATA='FETCH_MOVECOST_FILTER_DISPLAY_DATA';
export const SET_MOVE_COST_FILTER_PARAMS='SET_MOVE_COST_FILTER_PARAMS';
export const SET_MOVE_COST_FILTER_RECORDS='SET_MOVE_COST_FILTER_RECORDS';
export const SET_MOVE_COST_FILTER_VIEW='SET_MOVE_COST_FILTER_VIEW'
export const SET_MOVE_COST_FILTER_SEARCH_VIEW='SET_MOVE_COST_FILTER_SEARCH_VIEW';
export const SET_MOVE_COST_SEARCH_RECORDS='SET_MOVE_COST_SEARCH_RECORDS';
export const SET_MOVE_COST_SEARCH_TEXT='SET_MOVE_COST_SEARCH_TEXT';
export const SET_MOVE_COST_EDITABLE='SET_MOVE_COST_EDITABLE';
export const FETCH_MOVECOST_DELETE_PENDING = 'FETCH_MOVECOST_DELETE_PENDING';
export const FETCH_MOVECOST_DELETE_SUCCESS = 'FETCH_MOVECOST_DELETE_SUCCESS';
export const FETCH_MOVECOST_DELETE_ERROR = 'FETCH_MOVECOST_DELETE_ERROR';
export const FETCH_MOVECOST_ERROR_IDS_SUCCESS='FETCH_MOVECOST_ERROR_IDS_SUCCESS';