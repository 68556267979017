import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PlanInputsPagestyle from './PlanInputsPage.css';
import { Button, Divider, List, ListItem, Snackbar, Tooltip } from '@material-ui/core';
import PlanInputDetails from '../../components/planinputs/PlanInputDetails';
import PlanInputDetailsMinimized from '../../components/planinputs/PlanInputDetailsMinimized';
import { useParams, useLocation } from 'react-router-dom';
import { getZoneRfData, getPoolRfData, getPlanBySelectedPlanId, startPlanRun, deletePlan, copyPlanWithOutput, markTabsToBeCopiedToMain, activateCopyToMainInputs, setStatusCopyPlanToMainInputsInProgress, updateTabsToBeCopiedToMainInputs, updateCreatePlanStatus, activateCreatePlan } from '../../store/topbar/AllPlans.Actions';
import { fetchOptimizedPlanOutputData } from '../../store/adjustments/Adjustments.Actions';
import icon_plandetailsview from '../../assets/images/buttons-detailed-view.svg';
import icons_actions_copy from '../../assets/images/icons-actions-copy.svg';
import icons_actionsmore_dots from '../../assets/images/icons-actions-more-dots-stacked.svg';
import { useHistory } from 'react-router-dom';
import icon_trash from '../../assets/images/icons-actions-trash.svg';
import DialogBox from "../../components/Dialogcomponent/DialogComponent";
import { plansDateFormatter } from "../../utils/formatters/valueFormatter";
import { NttLabel } from '../../core-components/Label/NttLabel';
import CustomPopOver from "../../core-components/Popover/CustomPopOver";
import { createMuiTheme } from '@material-ui/core/styles';
import CustomDialog from '../../components/dialog_component/CustomDialog';
import { AllTabTableList } from './AllTabTableList';
import Alert from '@material-ui/lab/Alert';
import { ADDITIONAL_COST, ADDS_INVALID_POOLS, AGE_MILEAGE, ALLOCATED, CONSTRAINT, DEP_RATE, DIVERSIONS, MANUAL_VIN_DELETES, MAX_DEL_ZONE, MILEAGE_DEFAULT, MILEAGE_TABLE, MOVE_CONSTRAINT, MOVE_COST, PARAMETERS, PLAN_ASSUMPTIONS, pollTimeoutInterval, PROGRAM, SALVAGE_RATE, SCHEDULE, TARGET_ZONE, UPGRADE_PATH, ZONE_MAP } from '../../Constants';
import JobInProgressDialog from '../../components/Dialogcomponent/JobInProgressDialogComponent';
import { validateAllGridsData } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import InputsStartRunDialogBox from '../../components/Dialogcomponent/InputsStartRunDialogComponent';
import CopyInputsToMainPage from './CopyInputsToMainPage';

export default function PlanInputsPage() {
    const { planid } = useParams(); //get planid from url
    let location = useLocation();
    const { state } = location;
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedPlan, setSelectedPlan] = useState();
    const [copyPlan, setCopyPlan] = useState(false);
    const [popUp, setPopUp] = useState(null);
    const [openDeleteDialog, setOpen] = useState(false);
    const [isdeletePlan, setDeletePlan] = useState(false);
    const [copiedFrom, setCopiedFrom] = useState();
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [planInputslockedCopy, setPlanInputsLockedCopy] = useState(false);
    const [isCopyToMainInputsDialogOpen, setCopyToMainInputsDialogOpen] = useState(false);
    const [isCopyToMainInputsConfirmationDialogOpen, setCopyToMainInputsConfirmationDialogOpen] = useState(false);
    const [isCopyToMainInputsInitiated, setCopyToMainInputsInitiated] = useState(false);
    const [tabsToBeCopiedToMain, setTabsToBeCopiedToMain] = useState([]);
    const [timerVariable,setTimerVariable]=useState(null);
    const [copyToMainInputsStatus,setCopyToMainInputsStatus]=useState();
    const [jobInProgressDialogOpen, setJobInProgressDialogOpen] = useState(false);
    const [warningSnackBarOpen, setWarningSnackBarOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [severity, setSeverity] = useState('error');
    const [showMessage,setShowMessage] = useState(false);
    const [isMinimized,setIsMinimized] = useState(true);//By default, it should be minized
    const [startRunProcessClicked,setStartRunProcessClicked]= useState(false);
    const [NewCopyToMainClicked,setNewCopyToMainClicked]=useState(false);//for new copytomaininputs component this useState has be utilized .
    const creationInProgressTitle = "Create plan";
    const creationInProgress = "Creating a new plan,please wait";
    let message = "Are you sure that you want to delete the plan?"
    let title = "Delete Plan";
    let deletetext = "Delete";
    let canceltext = "Cancel";
    const theme = createMuiTheme();

    let errorMessage= useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.error;
    });

    //Plan Edit Disabled useSelector
    let ifPlanEditDisabled = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
      });

    let plansDataById = useSelector((reduxstate) => {
        return reduxstate.rootAllPlansReducer.allPlansReducer.plansDataById;
    });

    const zoneRfData = useSelector((reduxstate) => {
        return reduxstate.rootAllPlansReducer.allPlansReducer.zoneRfData;
    })

    const poolRfData = useSelector((reduxstate) => {
        return reduxstate.rootAllPlansReducer.allPlansReducer.poolRfData;
    })
    let parametersData = useSelector((state) => {
        return state.rootParametersReducer.parametersReducer.parameters;
    });
    const newPlanId = useSelector((reduxstate) => {
        return reduxstate.rootAllPlansReducer.allPlansReducer.newPlanId;
    })

    const jobId = useSelector((reduxstate) => {
        return reduxstate.rootAllPlansReducer.allPlansReducer.jobId;
    })

    let copyPlanToMainInputsInProgress=useSelector((state)=>{
        return state.rootAllPlansReducer.allPlansReducer.copyPlanToMainInputsInProgress;
    });

    const createPlanJobId = useSelector((reduxstate) => {
        return reduxstate.rootAllPlansReducer.allPlansReducer.createPlanJobId;
      })
      
    let planCreateSuccessful=useSelector((reduxstate)=>{
    return reduxstate.rootAllPlansReducer.allPlansReducer.planCreateSuccessful;
    });

    const  error = useSelector((reduxState) => {
    return reduxState.rootAllPlansReducer.allPlansReducer.error;
    })

    const tempTabStatuses=[];
    const isDataInvalid = 
    useSelector((state) => {

        let errorIDs=state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.uniqueErrorIDs;
        let errorIdPresent=errorIDs && errorIDs.length>0;

        let moveCostErrorIDs=state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.uniqueErrorIDs;
        let moveCostErrorIDPresent=moveCostErrorIDs && moveCostErrorIDs.length>0;

        // console.log("moveCostErrorIDs==>",moveCostErrorIDs);

        tempTabStatuses[PROGRAM]=false || state.rootProgramsReducer.programsReducer.programs.programs.length<1 || state.rootProgramsReducer.programsGridReducer.isAnyRowInvalid;
        tempTabStatuses[SCHEDULE]=false || state.rootSchedulesReducer.schedulesReducer.schedules.length<1 || state.rootSchedulesReducer.schedulesGridReducer.isAnyRowInvalid;
        tempTabStatuses[ALLOCATED]=false || state.rootAllocatedReducer.allocatedReducer.allocated.length<1 || state.rootAllocatedReducer.allocatedGridReducer.isAnyRowInvalid;
        tempTabStatuses[CONSTRAINT]=false || state.rootConstraintsReducer.constraintsReducer.constraints.length<1 || state.rootConstraintsReducer.constraintsGridReducer.isAnyRowInvalid;
        tempTabStatuses[MOVE_CONSTRAINT]=false || state.rootMoveConstraintsReducer.moveConstraintsReducer.moveconstraints.length<1 || state.rootMoveConstraintsReducer.moveConstraintsGridReducer.isAnyRowInvalid;
        tempTabStatuses[UPGRADE_PATH]=false || state.rootUpgradePathsReducer.upgradePathReducer.upgradePathData.length<1 || state.rootUpgradePathsReducer.upgradePathGridReducer.isAnyRowInvalid;
        tempTabStatuses[AGE_MILEAGE]=false || state.rootPlanAssumptionsReducer.rootAgeMileageReducer.AgeMileageReducer.ageMileageData.length<1 || state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer.isAnyRowInvalid;
        tempTabStatuses[ADDITIONAL_COST]=false || state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.AdditionalCostReducer.additionalCostData.length<1 || state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer.isAnyRowInvalid;
        tempTabStatuses[ADDS_INVALID_POOLS]=false || state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.AddsInvalidPoolsReducer.addsInvalidPoolsData.length<1 || state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer.isAnyRowInvalid;
        tempTabStatuses[MAX_DEL_ZONE]=false || state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.MaxDeletesReducer.maxDeletesData.length<1 || state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer.isAnyRowInvalid;
        tempTabStatuses[MILEAGE_DEFAULT]=false || state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.MileageDefaultReducer.mileageDefaultData.length<1 || state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer.isAnyRowInvalid;
        tempTabStatuses[MOVE_COST]=false || moveCostErrorIDPresent|| state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostGrandTotalRowCount==0 || state.rootPlanAssumptionsReducer.rootMoveCostReducer.moveCostGridReducer.isAnyRowInvalid;
        tempTabStatuses[SALVAGE_RATE]=false || state.rootPlanAssumptionsReducer.rootSalvageRateReducer.SalvageRateReducer.salvageRateData.length<1 || state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer.isAnyRowInvalid;
        tempTabStatuses[TARGET_ZONE]=false || state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.TargetZonePoolReducer.targetZonePoolData.length<1 || state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer.isAnyRowInvalid;
        tempTabStatuses[DEP_RATE]=false || state.rootPlanAssumptionsReducer.rootDepRateReducer.DeprateReducer.depRateData.length<1 || state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer.isAnyRowInvalid;
        tempTabStatuses[MILEAGE_TABLE]=false || state.rootPlanAssumptionsReducer.rootMileageTableReducer.MileageTableReducer.mileageTableData.length<1 || state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer.isAnyRowInvalid;
        tempTabStatuses[DIVERSIONS]=false || state.rootPlanAssumptionsReducer.rootDiversionsReducer.DiversionsReducer.diversionsData.length<1 || state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer.isAnyRowInvalid;
        tempTabStatuses[PARAMETERS]=false || state.rootParametersReducer.parametersReducer.parameters.length<1 || state.rootParametersReducer.parametersGridReducer.isAnyRowInvalid;
        tempTabStatuses[MANUAL_VIN_DELETES]=false || errorIdPresent || state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.ManualVinDeletesReducer.manualVinDeletesData.length<1 || state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.manualVinDeletesGridReducer.isAnyRowInvalid;

        return  state.rootProgramsReducer.programsReducer.programs.programs.length<1 || state.rootProgramsReducer.programsGridReducer.isAnyRowInvalid
        || state.rootSchedulesReducer.schedulesReducer.schedules.length<1 || state.rootSchedulesReducer.schedulesGridReducer.isAnyRowInvalid
        || state.rootAllocatedReducer.allocatedReducer.allocated.length<1 || state.rootAllocatedReducer.allocatedGridReducer.isAnyRowInvalid
        || state.rootConstraintsReducer.constraintsReducer.constraints.length<1 || state.rootConstraintsReducer.constraintsGridReducer.isAnyRowInvalid
        || state.rootMoveConstraintsReducer.moveConstraintsReducer.moveconstraints.length<1 || state.rootMoveConstraintsReducer.moveConstraintsGridReducer.isAnyRowInvalid
        || state.rootUpgradePathsReducer.upgradePathReducer.upgradePathData.length<1 || state.rootUpgradePathsReducer.upgradePathGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootAgeMileageReducer.AgeMileageReducer.ageMileageData.length<1 || state.rootPlanAssumptionsReducer.rootAgeMileageReducer.ageMileageGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.AdditionalCostReducer.additionalCostData.length<1 || state.rootPlanAssumptionsReducer.rootAdditionalCostReducer.additionalCostGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.AddsInvalidPoolsReducer.addsInvalidPoolsData.length<1 || state.rootPlanAssumptionsReducer.rootAddsInvalidPoolsReducer.addsInvalidPoolsGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.MaxDeletesReducer.maxDeletesData.length<1 || state.rootPlanAssumptionsReducer.rootMaxDeletesReducer.maxDeletesGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.MileageDefaultReducer.mileageDefaultData.length<1 || state.rootPlanAssumptionsReducer.rootMileageDefaultReducer.mileageDefaultGridReducer.isAnyRowInvalid
        || moveCostErrorIDPresent || state.rootPlanAssumptionsReducer.rootMoveCostReducer.MoveCostReducer.movecostGrandTotalRowCount==0 
        || state.rootPlanAssumptionsReducer.rootSalvageRateReducer.SalvageRateReducer.salvageRateData.length<1 || state.rootPlanAssumptionsReducer.rootSalvageRateReducer.salvageRateGridReducer.isAnyRowInvalid
        ||  state.rootPlanAssumptionsReducer.rootTargetZonePoolReducer.targetZonePoolGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootDepRateReducer.DeprateReducer.depRateData.length<1 || state.rootPlanAssumptionsReducer.rootDepRateReducer.depRateGridReducer.isAnyRowInvalid
        || state.rootPlanAssumptionsReducer.rootMileageTableReducer.MileageTableReducer.mileageTableData.length<1 || state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer.isAnyRowInvalid
        ||  state.rootPlanAssumptionsReducer.rootDiversionsReducer.diversionsGridReducer.isAnyRowInvalid
        || state.rootParametersReducer.parametersReducer.parameters.length<1 || state.rootParametersReducer.parametersGridReducer.isAnyRowInvalid
        ||  errorIdPresent || state.rootPlanAssumptionsReducer.rootManualVinDeletesReducer.manualVinDeletesGridReducer.isAnyRowInvalid;
    });

    // console.log("isDataInvalid===>",isDataInvalid);

    // const gridReducer = useSelector((state) => {
    //     return { ...state.rootProgramsReducer.programsGridReducer };
    // });

    // const contraintsGridReducer = useSelector((state) => {
    //     return { ...state.rootConstraintsReducer.constraintsGridReducer };
    // });

    const loadPlanDataById = (planid) => {
        dispatch(getPlanBySelectedPlanId(planid));
    }

    const loadZoneRfData = (planid) => {
        dispatch(getZoneRfData(planid));
    }

    const loadPoolRfData = (planid) => {
        dispatch(getPoolRfData(planid));
    }

    const startRun = () => {
        dispatch(startPlanRun(planid))
    }

    const getPlanDetailsByMasterId = (planid) => {
        loadPlanDataById(planid);
        let url = '/plan/' + planid + '/planinputs';
        setSelectedPlan(planid);
        history.push({
            pathname: url,
            state: {  // location state
                selectedplan: planid,
            },
        });
    }

    const LoadCopyPlanDetails = (plansDataById) => {
        if (newPlanId == plansDataById[0].id) {
            let dateTime = plansDateFormatter(plansDataById[0].date);
            let url = '/plan/' + newPlanId + '/planinputs';
            history.replace({
                pathname: url,
                state: {  // location state
                    selectedplan: newPlanId,
                    datetime: dateTime,
                },
            });
            setCopyPlan(false);
            return history;
        }
        else {
            loadPlanDataById(newPlanId);
            loadZoneRfData(newPlanId);
            loadPoolRfData(newPlanId);
        }
    }

    const callCopyPlan = (planId) => {
        if(!copyPlanToMainInputsInProgress || copyPlanToMainInputsInProgress==='completed'){
            dispatch(activateCreatePlan(planId));
            setCopyPlan(true);
            setJobInProgressDialogOpen(true);
        }
    }

 useEffect(()=>{
    if(jobInProgressDialogOpen && createPlanJobId>0){
        setTimerVariable(setInterval(()=>dispatch(updateCreatePlanStatus(createPlanJobId)),pollTimeoutInterval));
    }        
    },[createPlanJobId]);

    const handleClickMore = (e, planid) => {
        if(!ifPlanEditDisabled && !copyPlanToMainInputsInProgress){
            setSelectedPlan(planid);
            setPopUp(e.currentTarget);
            setCopiedFrom(planid);
        }
    };
    const handleClose = () => {
        setPopUp(null);
    };
    const open = Boolean(popUp);
    const planspopoverId = "popoverId";

    const openConfirmationDialog = (planid) => {
        handleClose();
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };
    
    let tabsToBeCopiedToMainLocal = useSelector((state) => {
        return [...state.rootAllPlansReducer.allPlansReducer.tabsToBeCopiedToMain];
    });   

    useEffect(()=>{
        if(copyPlanToMainInputsInProgress==='failed'){
            setCopyToMainInputsStatus('error') 
            setCopyToMainInputsInitiated(true);
        } else if(copyPlanToMainInputsInProgress==='completed'){
            // console.log("Copy to main inputs completed");
            setCopyToMainInputsStatus('success') 
            setCopyToMainInputsInitiated(true);
        } else{
            setCopyToMainInputsInitiated(false);
        }
        if(timerVariable && jobId===0){
            clearInterval(timerVariable); //See useEffect on Job Id
            setTimerVariable(null);
        }
    },[copyPlanToMainInputsInProgress]);

    useEffect(()=>{
        if(jobId>0){
            setTimerVariable(setInterval(()=>dispatch(updateTabsToBeCopiedToMainInputs(planid,jobId,tabsToBeCopiedToMainLocal)),pollTimeoutInterval));
        }        
    },[jobId]);

    const openCopyToMainInputsDialog = () => {
        // console.log("state.rootAllPlansReducer.allPlansReducer.tabsToBeCopiedToMain==",tabsToBeCopiedToMainLocal);
        setTabsToBeCopiedToMain(tabsToBeCopiedToMainLocal);
        setCopyToMainInputsDialogOpen(true);
    };

    const selectTabsForCopyToMainInputsDialogContent = () => {
        return (
            <AllTabTableList tabsToBeCopiedToMain={tabsToBeCopiedToMain} setTabsToBeCopiedToMain={setTabsToBeCopiedToMain} tabStatuses={tempTabStatuses}/>
        );
    }

    const initiateConfirmationBeforeCopyCopyToMainInputs = (event) => {
        setCopyToMainInputsStatus() 
        setCopyToMainInputsConfirmationDialogOpen(true);
        // console.log("tabsToBeCopiedToMain====",tabsToBeCopiedToMain);
    }

    const initiateActualCopyToMainInputs = (event) => {
        dispatch(markTabsToBeCopiedToMain([...tabsToBeCopiedToMain]));
        setCopyToMainInputsDialogOpen(false);
        setCopyToMainInputsConfirmationDialogOpen(false);
        dispatch(activateCopyToMainInputs(planid,[...tabsToBeCopiedToMain]));
        setCopyToMainInputsInitiated(true);
    }

    const cancelCopyToMainInputs = (event) => {
        setTabsToBeCopiedToMain([]);
        setCopyToMainInputsDialogOpen(false);
        setCopyToMainInputsConfirmationDialogOpen(false);
    }

    const handleCloseError = (event) => {
        setCopyToMainInputsConfirmationDialogOpen(false);
    }

    function deleteSelectedPlan() {
        try {
            dispatch(deletePlan(selectedPlan));
            handleClose();
            handleCloseDialog();
            setDeletePlan(true);
        } catch (err) {
            console.error('got error while deleting plan');
        }
    };

    const handleCopyPlanWithOutput = (index) => {
        setSelectedIndex(index);
        setPopUp(null);
        setPlanInputsLockedCopy(true);
        dispatch(copyPlanWithOutput(copiedFrom));
    };

    const LoadLockedCopyPlanDetails = (plansDataById) => {
        let dateTime = plansDateFormatter(plansDataById[0].date);
        loadZoneRfData(newPlanId);
        loadPoolRfData(newPlanId);
        let url = '/plan/' + newPlanId + '/planinputs';
        history.replace({
            pathname: url,
            state: {  // location state
                selectedplan: newPlanId,
                datetime: dateTime,
                lockedCopy: planInputslockedCopy
            },
        });
    }

    // const loadOptimizationPlanOutputData = (planid) => {
    //     dispatch(fetchOptimizedPlanOutputData(planid));
    // }
    const loadOptimizationPlanOutputData = (planid,parametersData) => {
        dispatch(fetchOptimizedPlanOutputData(planid,parametersData));
    }

    const shouldCopyPlanInputButtonBeDisabled=(plansDataById)=>{
        let planRunningStatuses=["optimization complete","optimization complete as sub-optim","optimization complete as optimal","optimization complete unknown status","adjustment complete","editing adjustments","adjustments modified"],status=false;
        if(plansDataById !== undefined && plansDataById.length > 0){
            if(planRunningStatuses.includes(plansDataById[0].status.toLowerCase())){
                status=false
            }else {
                status=ifPlanEditDisabled
            }
        }
        let result= (copyPlanToMainInputsInProgress===true) || status; //According to reducer, it can be true,'failed','completed' only
        // console.log("shouldCopyPlanInputButtonBeDisabled==",result);
        return result;
    }

    const activateStartRunProcess = () => {
        setStartRunProcessClicked(true)
        dispatch(validateAllGridsData(planid, '', 'MAIN_AND_PLAN-INPUTS'))
    }

      const handleStartRunDialogClose=()=>{
        setStartRunProcessClicked(false)
      }
    
      const handleStartRunProcess=()=>{
        //dispatch(startPlanRun(planid))
        setStartRunProcessClicked(false)
      }

    const openNewCopyToMainInputsDialog = () => {
        setNewCopyToMainClicked(true);
    }

    const CopyToMainInputsComponentDisplay = useMemo(() => {
        return <CopyInputsToMainPage open={NewCopyToMainClicked} setCopyToMainDialog={setNewCopyToMainClicked}/>
    },[NewCopyToMainClicked])

    useEffect(() => {
        if(parametersData.length >0){
            loadOptimizationPlanOutputData(planid,parametersData);
        }
      
    }, [planid,parametersData])

    const userMessage = (severity, message) => {
        setWarningSnackBarOpen(true);
        setSeverity(severity);
        setWarningMessage(message);
      }
      
      const handleCloseWarningSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setWarningSnackBarOpen(false);
      };

    useEffect(() => {
        if(planCreateSuccessful!==null){
            if(showMessage===false && planCreateSuccessful===true){
                userMessage('success', 'Successfully created plan.');
                setShowMessage(true);
              } else if(showMessage===false && planCreateSuccessful===false){
                if(error){
                  userMessage('error', error);
                  setShowMessage(true);
                }else{
                  userMessage('error', 'Failed to create plan.');
                  setShowMessage(true);
                } 
              }
            setJobInProgressDialogOpen(false);
            clearInterval(timerVariable);
            setTimerVariable(null);
        }
        //loadZoneRfData(planid);
        //loadPoolRfData(planid);
        if (copyPlan == true && newPlanId !== "") {
            LoadCopyPlanDetails(plansDataById);
        }
        if (isdeletePlan == true) {
            setDeletePlan(false);
            let url = '/';
            setSelectedPlan(planid);
            history.push({
                pathname: url,
                state: {  // location state
                    selectedplan: selectedPlan,
                },
            });
        }
        let addPlan = false;
        if (state !== undefined && state.addPlan !== undefined) {
            addPlan = state.addPlan;
        }
        if (planInputslockedCopy == true && newPlanId !== "") {
            loadPlanDataById(newPlanId);
        }
        if (planInputslockedCopy == true && plansDataById !== undefined && plansDataById.length > 0 && plansDataById[0].id == newPlanId) {
            setPlanInputsLockedCopy(false);
            LoadLockedCopyPlanDetails(plansDataById);
        }
        let lockedCopy = false;
        if ((state !== undefined && state.lockedCopy !== undefined)) {
            lockedCopy = state.lockedCopy;
        }
        if (isdeletePlan !== true && copyPlan !== true && (addPlan !== true) && lockedCopy !== true && planInputslockedCopy == false && plansDataById.length > 0 && planid == plansDataById[0].id) {
            let dateTime = plansDateFormatter(plansDataById[0].date);
            let url = '/plan/' + planid + '/planinputs';
            history.replace({
                pathname: url,
                state: {  // location state
                    selectedplan: planid,
                    datetime: dateTime
                },
            });
        }
    }, [plansDataById, newPlanId, isdeletePlan,planCreateSuccessful]);

    useEffect(() => {
        let addPlan = false;
        if (state !== undefined && state.addPlan !== undefined) {
            addPlan = state.addPlan;
        }
        let lockedCopy = false;
        if (state !== undefined && state.lockedCopy !== undefined) {
            lockedCopy = state.lockedCopy;
        }
        if (isdeletePlan !== true && copyPlan !== true && (addPlan !== true) && lockedCopy==false && planInputslockedCopy==false) {
            loadPlanDataById(planid);
            loadZoneRfData(planid);
            loadPoolRfData(planid);
        }

    }, [])

    return (
        <div className='planinputsmargin'>
            <div className='planflexitems'>
                <h2 className='planinputsheader'>Plan #{planid} Inputs</h2>
                {plansDataById && plansDataById.length && (plansDataById[0].id == planid)?
                    <PlanInputDetailsMinimized 
                        selectedRowData={plansDataById[0]}
                        selectedPlanId={planid}
                        zoneRfData={zoneRfData}
                        poolRfData={poolRfData}
                        isMinimized={isMinimized}
                        setIsMinimized={setIsMinimized}
                    >
                    </PlanInputDetailsMinimized>
                :<div></div>}
                <div className="planstartrun">
                    {((plansDataById !== undefined && plansDataById.length > 0) && (plansDataById[0].copied_from !== 'master') && (plansDataById[0].id == planid)) ?
                        <label className='copiedfrom'>Copied from #{plansDataById[0].copied_from}
                            <img src={icon_plandetailsview} className='masterplanimg' onClick={(e) => getPlanDetailsByMasterId(parseInt(plansDataById[0].copied_from))}></img> |</label>
                        : <div></div>}
                        {/* this condition should be removed after completing new version */}
                    {JSON.parse(localStorage.getItem("newArchitecture")) ?
                        <>
                            <Tooltip title="Copy Plan Inputs to Main Inputs" placement="bottom"> 
                                <Button disabled={shouldCopyPlanInputButtonBeDisabled(plansDataById)} placement="bottom" onClick={openNewCopyToMainInputsDialog}>
                                    Copy Inputs
                                </Button>
                            </Tooltip>
                            <Button
                                onClick={activateStartRunProcess} >
                                Start Run
                            </Button></> : 
                    <><Tooltip title="Copy Plan Inputs to Main Inputs" placement="bottom">
                        <Button disabled={shouldCopyPlanInputButtonBeDisabled(plansDataById)} className=""
                            onClick={openCopyToMainInputsDialog}>
                            Copy Inputs
                        </Button>
                    </Tooltip>
                    <Button disabled={(plansDataById !== undefined && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error')) || isDataInvalid}
                        onClick={startRun} className={(plansDataById !== undefined && plansDataById.length > 0 && (plansDataById[0].id == planid) && (plansDataById[0].status.toLowerCase() !== 'new') && (plansDataById[0].status.toLowerCase() !== 'error')) ? "runblur" : ""}>
                        Start Run
                    </Button>
                    <img src={icons_actions_copy}  className={(!copyPlanToMainInputsInProgress || copyPlanToMainInputsInProgress==='completed')?'plandetailcopyimg':'plandetailcopyimgdisable'} onClick={() => callCopyPlan(plansDataById[0].id)}></img>
                    <img src={icons_actionsmore_dots} className='plandetailmoreimg' onClick={(e) => handleClickMore(e, plansDataById[0].id)}></img> </>}
                    
                    <CustomPopOver
                        className="lockedcopyPopOver"
                        popOverId="popoverId"
                        open={popUp}
                        anchorEl={popUp}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <List>
                            <ListItem button
                                selected={selectedIndex === 1}
                                onClick={(event) => openConfirmationDialog(event, 1, plansDataById[0].id)}>
                                <img src={icon_trash} className="showmorepopup"></img>
                                <NttLabel theme={theme} labelVariant="body2" className='showmoretext'>
                                    Remove</NttLabel>
                            </ListItem>
                            <Divider />
                            <ListItem button
                                selected={selectedIndex === 2}
                                disabled = {true}
                                onClick={(event) => handleCopyPlanWithOutput(2)}>
                                <img src={icons_actions_copy} className="showmorepopup"></img>
                                {/* Disable locked copy if plan status is running  */}
                                <NttLabel disabled={ifPlanEditDisabled} theme={theme} labelVariant="body2" className='showmoretext'>
                                    Locked Copy</NttLabel>
                            </ListItem>
                        </List>
                    </CustomPopOver>
                    <div>
                        <DialogBox open={openDeleteDialog} handleClose={handleCloseDialog} handleYes={deleteSelectedPlan}
                            dialogMessage={message} title={title} action={deletetext} styles={"styles"}
                            canceltext={canceltext}
                        >
                        </DialogBox>
                        <CustomDialog 
                            open={isCopyToMainInputsDialogOpen} handleClose={(e) => cancelCopyToMainInputs(e)} handlePositiveBtnClick={(e) => initiateConfirmationBeforeCopyCopyToMainInputs(e)} handleNegativeBtnClick={(e) => cancelCopyToMainInputs(e)}
                            dialogContent={selectTabsForCopyToMainInputsDialogContent()} title="Initiate Copy" positiveBtnLbl="Initiate Copy" negativeBtnLbl="Cancel Copy">
                        </CustomDialog>
                        {(tabsToBeCopiedToMain && tabsToBeCopiedToMain.length)?
                                <DialogBox open={isCopyToMainInputsConfirmationDialogOpen} handleClose={(e) => cancelCopyToMainInputs(e)} handleYes={(e)=>initiateActualCopyToMainInputs(e)}
                                    dialogMessage="Are you sure of Copying of Plan Inputs to Main Inputs?" title="Request Confirmation" action="Start Copy" styles={"styles"}
                                    canceltext={canceltext}
                                ></DialogBox>
                            :
                                <DialogBox open={isCopyToMainInputsConfirmationDialogOpen} handleClose={(e) => cancelCopyToMainInputs(e)} handleYes={(e)=>handleCloseError(e)}
                                    dialogMessage="Please select an option." title="Input missing" action="Close" styles={"styles"}
                                    canceltext={canceltext}
                                ></DialogBox>
                        }
                        
                        
                        <Snackbar open={isCopyToMainInputsInitiated} autoHideDuration={5000} onClose={()=>setCopyToMainInputsInitiated(false)}>
                            <Alert
                                onClose={()=>setCopyToMainInputsInitiated(false)}
                                 severity={copyToMainInputsStatus?copyToMainInputsStatus:'success'}>{copyToMainInputsStatus==='error'?(errorMessage?errorMessage:`Failed to copy to Main Inputs...`):copyToMainInputsStatus==='success'?`Copy completed...`:`Initiated copy to Main-Inputs...`}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>
            {((plansDataById !== undefined && plansDataById.length > 0) && (plansDataById[0].id == planid)) ?
                <PlanInputDetails
                    selectedRowData={plansDataById[0]}
                    selectedPlanId={planid}
                    zoneRfData={zoneRfData}
                    poolRfData={poolRfData}
                    isMinimized={isMinimized}
                    setIsMinimized={setIsMinimized}
                >
                </PlanInputDetails> : <div></div>}
            <JobInProgressDialog open={jobInProgressDialogOpen} 
                dialogMessage={creationInProgress} title={creationInProgressTitle}>
            </JobInProgressDialog>
            <Snackbar open={warningSnackBarOpen} autoHideDuration={5000} onClose={handleCloseWarningSnackBar}>
            <Alert
                onClose={handleCloseWarningSnackBar}
                severity={severity}>{warningMessage}
            </Alert>
            </Snackbar>
            <InputsStartRunDialogBox open={startRunProcessClicked} handleClose={handleStartRunDialogClose} action={'Start Run'} canceltext={'Cancel'} handleYes={handleStartRunProcess}/>
            {CopyToMainInputsComponentDisplay}
        </div>
    )
}