import React, { useState } from 'react';
import './UpgradeFromClassRenderer.css';

function UpgradeToClassRenderer(params) {
    const { to_cc, to_cc_group } = params.data;
    
    return (
        (params.data.to_cc && params.data.to_cc.car_class!==undefined)?
        <div className='textalignment'>
            <span>{params.data.to_cc.car_class}</span>
           {(params.data.to_cc.car_class=='' ||params.data.to_cc.car_class==null)?'':<span className='fromccgroup'> {params.data.to_cc.car_class_car_group}</span>}
        </div>
        :
        <div className='textalignment'>
            <span>{to_cc}</span>
            {(to_cc=='' || to_cc==null)?'':<span className='fromccgroup'>{to_cc_group}</span>}
        </div>
    );
}

export default UpgradeToClassRenderer;