const filterColumnDefs = () => {
    return ({
        "data": [
            {
                "colDef": "manufacturer_group",
                "displayName": "Manufacturer",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "car_class_code",
                "displayName": "Class",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "model_group",
                "displayName": "Model",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "model_year",
                "displayName": "Model Year",
                "type": "dropdown",
                "options": [],

            },
            {
                "colDef": "spi_code",
                "displayName": "SPI Code",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "pool",
                "displayName": "Pools",
                "type": "dropdown",
                "options": [],
            },
            {
                "colDef": "count",
                "displayName": "Count",
                "type": "input",
                "options": "",
            },
            {
                "colDef": "requested_delivery_month",
                "displayName": "Requested Delivery Month",
                "type": "datepicker",
                 "options": [],
            },
        ]
    });
}

export default filterColumnDefs;