export const columnDefs = [
  {
    headerName: "",
    field: "",
    minWidth: 40,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    editable: false,
    sortable: false,
    resizable: false,
    suppressColumnsToolPanel: true,
    flex: 0,
  },
  {
    field: "OEM",
    headerName: "OEM",
    cellEditor: "autoCompletePopupEditor",
    type: "dropdown",
    unSortIcon: true,
    minWidth: 250,
    cellClassRules: cellClassRules("OEM"),
    valueFormatter: (params) => {
      return params.data.OEM;
    },
    tooltipValueGetter: (params) => {
      return toolTipUI(params, "OEM");
    },
  },
  {
    field: "MANUFACTURER_GROUP",
    headerName: "Manufacturer Group",
    minWidth: 250,
    cellEditor: "autoCompletePopupEditor",
    type: "dropdown",
    unSortIcon: true,
    cellClassRules: cellClassRules("MANUFACTURER_GROUP"),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, "MANUFACTURER_GROUP");
    },
    cellEditorParams:(params)=>{
      // console.log("params in MANUFACTURER_GROUP: ", params);
      let oemMfrMap=JSON.parse(sessionStorage.getItem("ADMIN_OEM_MFR_MAP"));

      let options =  [];
      if(oemMfrMap && params && params.data && params.data.OEM && oemMfrMap[params.data.OEM.trim()]){
        options.push(oemMfrMap[params.data.OEM.trim()]);
      }
      return {'options':options};
    },
  },
  {
    field: "MODEL_GROUP",
    headerName: "Model group",
    minWidth: 250,
    cellEditor: "autoCompletePopupEditor",
    type: "dropdown",
    unSortIcon: true,
    cellClassRules: cellClassRules("MODEL_GROUP"),
    tooltipValueGetter: (params) => {
      return toolTipUI(params, "MODEL_GROUP");
    },
  },
  {
    field:'ARCHIVED',
         headerName:'Archived',
         minWidth:110,
         type: "dropdown",
         unSortIcon:true,
         cellEditor: 'autoCompleteEditor',
         cellClassRules: cellClassRules("ARCHIVED"),
         tooltipValueGetter: (params) => {
           return toolTipUI(params, "ARCHIVED");
         },
  },
  {
    headerName: "",
    colId: "actions",
    cellRenderer: "ModelMfrOemActionRenderer",
    editable: false,
    filter: false,
    sortable: false,
    minWidth: 120,
    pinned: "right",
  },
];

function toolTipUI(params, field) {
    if (params.data.validations && params.data.validations[field].error !== null) {
        return params.data.validations[field].error
    } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW") {
        return "Duplicate row";
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}
export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
