import { combineReducers } from 'redux';
import planManagementReducer from './PlanManagement.Reducer';
import multiGridUIReducer from '../AdminMultiGrid/AdminMultiGrid.Reducer';
import {createNamedWrapperReducer} from '../../util';
import columnDefinitionReducer from "./PlanManagement.ColumnDef.Reducer";

const rootAdminPlanManagementReducer = combineReducers({
      planManagementReducer
    , planManagementGridReducer: createNamedWrapperReducer(multiGridUIReducer, "planmanagementgrid")
    , columnDefinitionReducer
})

export default rootAdminPlanManagementReducer;