import * as CONSTANTS from '../../../../../Constants';
import { defaultNumericParameters } from '../../RfParametersConstants';

export const columnDefs = [
    {
        headerName: '',
        field: '',
        minWidth: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        editable: false,
        sortable: false,
        resizable: false,
        suppressColumnsToolPanel: true,
        flex: 0
    },
    {
        field: CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Name.field, headerName: CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Name.headerName, unSortIcon: true,
        cellClassRules: cellClassRules(CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Name.name), tooltipValueGetter: params => {
            return toolTipUI(params,CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Name.name)
        },
        editable: function (params) {
         if(params.data.ISDEFAULT==undefined){
          return true;  //while adding new row,edit should be true 
          }
         else{
          return false;
          }
        },
        cellEditorSelector: (params) => {
            if (params.data && (defaultNumericParameters.includes(params.data.PARAMETER_NAME))) {
                return {
                    component: 'numericEditor'
                };
            }
        },
    },
    {
        field: CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Value.field, headerName: CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Value.headerName, unSortIcon: true,
        cellClassRules: cellClassRules(CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Value.name), tooltipValueGetter: params => {
            return toolTipUI(params,CONSTANTS.MainRFInputTncColumns.MainRFInputTncParameterColumns.Value.name)
        }
    },
    {
        headerName: "",
        colId: "actions",
        cellRenderer:'MainRFInputsActionRenderer',
        editable: false,
        filter: false,
        sortable: false,
        minWidth: 120,
        pinned: 'right',
        cellClassRules: pinnedRow()
    }
];

function pinnedRow() {
    return {
        'noCssRow': (params) => {
            return true;
        },
    }
}

function toolTipUI(params, field) {
    if(params.data.validations && params.data.validations[field]){
        if (params.data.validations && params.data.validations[field].error !== null) {
            return params.data.validations[field].error
        } if (params.data.rowStatus === "ERROR_ROW" || params.data.rowStatus === "ERROR_COPY_ROW") {
            return "Duplicate row";
        }
    }
}

function cellClassRules(field) {
    return {
        'errorCell': (params) => {
            if (params.data.validations && params.data.validations[field]) {
                return params.data.validations[field].error !== null
            }
        },
        'validRow': (params) => {
            if (params.data.validations && params.data.colDef) {
                var index = params.data.colDef.findIndex(function (item, i) {
                    return item.id === params.data.ID && item.value === field
                });
                return ((index > -1) && params.data.validations[field].error === null)
            }
        },

    }
}

export const defaultColDef = {
    editable: true,
    sortable: true,
    minWidth: 120,
    filter: false,
    resizable: true,
    floatingFilter: false,
    flex: 1,
};
