import { columnDefs, defaultColDef } from "./carClassGroup_grid_data/CarClassGroupColumnDefs";
import { FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING,FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS,FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR } from '../metadata_carclassgroup/CarClassGroup.ActionTypes';
import { isWordWithoutSpecialCharacterSpaceAllowed} from '../../../utils/utils';

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,                          
            DIVISIONS: { error: checkIfValueEmpty(data.DIVISIONS, "Divisions") },
            car_class_car_group: { error: checkIfValueEmpty(data.car_class_car_group, "Car Group") },
            car_class_code: { error: checkIfValueEmpty(data.car_class_code, "Car Class") },
            tnc_eligible: {error: validateTNCEligibleFields(data.tnc_eligible, "TNC Eligible")},
            archived: {error: checkIfValueEmpty(data.archived, "Archived")}
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
} 
function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name,filter) {
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    if(isWordWithoutSpecialCharacterSpaceAllowed(value) === true){
        return name + " cannot have this special character";
    }
    return null;
}
function validateTNCEligibleFields(value, name) {
    if (value === undefined ||  value === '' ||  value === null) {
        return name + ' cannot be empty';
    }
   else if(name==='TNC Eligible'){
       if(value >=2 || value <0){
           return  'Should be either 0 or 1'
       }
   } else if (value < 0) {
            return 'Please enter valid ' + name
        }
    return null;
}
function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if ((itm.car_class_code === itm2.car_class_code)) {
                unique = false;
                if (itm.rowStatus == "ADD_ROW"){
                    itm.rowStatus = "ERROR_NEW_ROW";
                }
                else if (itm2.rowStatus == "ADD_ROW"){
                    itm2.rowStatus = "ERROR_NEW_ROW"
                }
                if(itm.rowStatus == "COPY_ROW"){
                    itm.rowStatus = "ERROR_COPY_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                 else if(itm2.rowStatus == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.rowStatus = "ERROR_COPY_ROW"
                }
                else {
                    itm.rowStatus = "ERROR_ROW";
                    itm2.rowStatus = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.rowStatus != "ADD_ROW") {
            itm.rowStatus = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.rowStatus == "ERROR_COPY_ROW" || transactions[key].data.rowStatus == "ERROR_ROW") && itm.rowStatus==''){
                transactions[key].data.rowStatus=itm.rowStatus
              }
            }
          })
    });
    return duplicate;
}

// function fetchDropdownsForColumns(colVal, element) {
//     var colData = [];
//     colVal.forEach(val => {
//         colData.push(val.name)
//     });

//     element.cellEditorParams = { options: colData }
// }

function fetchDropdownValueOthers(data, element) {
    element.cellEditorParams = { options: data }
}

function fetchFilterValues(carClassGroupName,carclasscode,columns) {
    columns.forEach(element => {
        if (element.field == "car_class_car_group") {
            fetchDropdownValueOthers(carClassGroupName, element);
        } else if(element.field == "car_class_code"){
            fetchDropdownValueOthers(carclasscode,element);
        }
        else if (element.field == "tnc_eligible"){
            fetchDropdownValueOthers(['0','1'],element)
        }
        else if (element.field === "archived") {
            element.cellEditorParams = { options: ['true','false'] }
        }
    });

    return columns;
}

export default function carClassGroupColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CARCLASSGROUP_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues: fetchFilterValues([],[],state.columnDefs)
            });
        case FETCH_CARCLASSGROUP_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(action.data.car_group_name,action.data.car_class_code,state.columnDefs)
            });
        case FETCH_CARCLASSGROUP_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}