import React, { useCallback, useRef } from 'react';
import ChipCrossIcon from "../../assets/images/crossIconForFilterChips.svg";
import { CANNOT_CHANGE_DATASET_ERROR, CHIP_LIMIT_PER_PARAMETER, CHIP_OVER_LIMIT_FLAG } from '../../Constants';
import './chips.css';
import ChipsDisplay from './ChipsDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { setGridFilterParams, setGridFilterViewStatus } from '../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import moment from "moment";
import { addNotificationMessage } from '../../store/topbar/AllPlans.Actions';

const FilterChips = (props) => {
    const {gridColumnDefs,gridName,hasPendingTransactions}=props;
    const dispatch = useDispatch();
    const filterParams = useRef(null);
    const noOfFilterChipsApplied=useRef(0)// to store number of filter chips applied 

    const gridFilterUserSelectedParams = useSelector((state) => {
        return state.PaginationGridReducer.gridFilterUserSelectedParams;
    }); 

    const gridFilterViewStatus = useSelector((state) => {
        return state.PaginationGridReducer.gridFilterView;
    }); 

    const handleChipRemove = useCallback((fieldName, value, filterType) => { // to remove chip after clicking on cross icon
        if(hasPendingTransactions){
            dispatch(addNotificationMessage('error',CANNOT_CHANGE_DATASET_ERROR));
            return;
        }
        else if (filterType === 'dropdown') {// removing chip if the filter type is dropdown
            if (value === CHIP_OVER_LIMIT_FLAG) { // this if condition when user selects more than five dropdown option
                filterParams.current[fieldName] = []
            }
            else {
                filterParams.current[fieldName] = filterParams.current[fieldName].filter(item => item.value !== value);
            }
        }
        else if(filterType === 'range'){// removing chip if the filter type is range
            filterParams.current[fieldName] =["between"];
        }
        else if(filterType === 'datepicker'){// removing chip if the filter type is datepicker
            filterParams.current[fieldName] =["between"];
        }
        else if (filterType === 'input') {// removing chip if the filter type is input
            filterParams.current[fieldName] = "";
        }

        filterParamsUpdate(filterParams.current);// updating filter params

    },[gridFilterViewStatus,gridName,hasPendingTransactions])

    const filterParamsUpdate = (filterParmas, clearFilter) => {// this is to either clear filter or apply filter
        if(hasPendingTransactions){
            dispatch(addNotificationMessage('error',CANNOT_CHANGE_DATASET_ERROR))
        }
        else if (noOfFilterChipsApplied.current == 1 || clearFilter) {// clears filter
            dispatch(setGridFilterParams(gridName, gridColumnDefs, true))
            dispatch(setGridFilterViewStatus(gridName, false));
           // loadGridDataForFilterParams(filterParmas, false);
        }
        else {//applies filter
            dispatch(setGridFilterViewStatus(gridName, true));
            dispatch(setGridFilterParams(gridName, null, false, filterParmas));
           // loadGridDataForFilterParams(filterParmas, true);
        }
    }

    
    const getDropdownFilterChips = (fields, fieldValue) => { // this is to generate dropdown filter chips
        if (fieldValue.length > CHIP_LIMIT_PER_PARAMETER) { // this if condition when user selects more than five dropdown option
            noOfFilterChipsApplied.current += 1;
            return (<ChipsDisplay key={`${fields.headerName}:Multiple Selected`} displayText={`${fields.headerName}:Multiple Selected`} fieldValue={CHIP_OVER_LIMIT_FLAG} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
        else {
            noOfFilterChipsApplied.current += fieldValue.length
            return (fieldValue.map((val) => (
                <ChipsDisplay key={`${fields.headerName}: ${val.label}`} displayText={`${fields.headerName}: ${val.label}`} fieldValue={val.value} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />
            )))
        }
    }

    const getDateFilterChips = (fields, fieldValue) => { // this is to generate date filter chips
        let dateFormatPattern = fields.isFullDate ? "DD-MMM-YYYY" : "MMM-YYYY"  //formating date whether its a full date(YYYY-MM-DD) or default date(YYYY-MM)
        if (fieldValue[0] === "blanks") {
            noOfFilterChipsApplied.current += 1
            return (<ChipsDisplay key={`${fields.headerName} is Blanks`} displayText={`${fields.headerName} is Blanks`} fieldValue={"blanks"} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
        else if (fieldValue[0] === "between" && fieldValue.length == 3 && fieldValue[1] != "" && fieldValue[2] != "") {
            noOfFilterChipsApplied.current += 1
            return (<ChipsDisplay key={`${fields.headerName}: ${fieldValue[0]} ${moment(fieldValue[1]).format(dateFormatPattern)} and ${moment(fieldValue[2]).format(dateFormatPattern)}`} displayText={`${fields.headerName}: ${fieldValue[0]} ${moment(fieldValue[1]).format(dateFormatPattern)} and ${moment(fieldValue[2]).format(dateFormatPattern)}`} 
            fieldValue={fieldValue} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
        else if (fieldValue[0] != "between" && fieldValue[0] != "blanks" && fieldValue[1] != undefined && fieldValue[1] != "") {
            noOfFilterChipsApplied.current += 1
            return (<ChipsDisplay key={`${fields.headerName}: ${fieldValue[0]} than ${moment(fieldValue[1]).format(dateFormatPattern)} `} displayText={`${fields.headerName}: ${fieldValue[0]} than ${moment(fieldValue[1]).format(dateFormatPattern)} `} 
            fieldValue={fieldValue} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
    }

    const getRangeFilterChips = (fields, fieldValue) => { // this is to generate range filter chips
        if (fieldValue[0] === "blanks") {
            noOfFilterChipsApplied.current += 1
            return (<ChipsDisplay key={`${fields.headerName} is Blanks`} displayText={`${fields.headerName} is Blanks`} fieldValue={"blanks"} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
        else if (fieldValue[0] === "between" && fieldValue.length == 3 && fieldValue[1] != "" && fieldValue[2] != "") {
            noOfFilterChipsApplied.current += 1
            return (<ChipsDisplay key={`${fields.headerName}: ${fieldValue[0]} ${fieldValue[1]} and ${fieldValue[2]}`} displayText={`${fields.headerName}: ${fieldValue[0]} ${fieldValue[1]} and ${fieldValue[2]}`} 
            fieldValue={fieldValue} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
        else if (fieldValue[0] != "between" && fieldValue[0] != "blanks" && fieldValue[1] != undefined && fieldValue[1] != "") {
            noOfFilterChipsApplied.current += 1
            return (<ChipsDisplay key={`${fields.headerName}: ${fieldValue[0]} than ${fieldValue[1]}`} displayText={`${fields.headerName}: ${fieldValue[0]} than ${fieldValue[1]}`} 
            fieldValue={fieldValue} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
        }
    }

    const getInputFilterChips = (fields, fieldValue) => { // this is to generate input filter chips
        noOfFilterChipsApplied.current += 1
        return (<ChipsDisplay key={`${fields.headerName}: ${fieldValue}`} displayText={`${fields.headerName}: ${fieldValue}`} fieldValue={fieldValue} fieldName={fields.field} filterType={fields.filterType} handleClearChip={(fieldName,value,filterType)=>{handleChipRemove(fieldName,value,filterType)}} />)
    }

    const prepareChipList = () => { // this prepares filter chips to display according to the gridFilterUserSelectedParams 
        if (gridColumnDefs && gridColumnDefs.columnDefs && gridColumnDefs.columnDefs.length > 0) {
            filterParams.current = JSON.parse(JSON.stringify(gridFilterUserSelectedParams[gridName]));
            noOfFilterChipsApplied.current = 0;
            return (
                <>
                    {gridColumnDefs.columnDefs.map((fields, index) => (
                        <>
                            {
                              (fields.filterType) 
                                ? (
                                    (fields["filterType"] === 'dropdown' && gridFilterUserSelectedParams[gridName][fields.field].length > 0) ? 
                                        (
                                            getDropdownFilterChips(fields, gridFilterUserSelectedParams[gridName][fields.field])
                                        ) : 
                                    (fields["filterType"] === "datepicker") ? 
                                        (
                                            getDateFilterChips(fields, gridFilterUserSelectedParams[gridName][fields.field])
                                        ) : 
                                    (fields["filterType"] === 'range') ? 
                                        (
                                            getRangeFilterChips(fields, gridFilterUserSelectedParams[gridName][fields.field])
                                        ) : 
                                    (fields["filterType"] === 'input' && gridFilterUserSelectedParams[gridName][fields.field].length > 0) ? 
                                        (
                                            getInputFilterChips(fields, gridFilterUserSelectedParams[gridName][fields.field])
                                        ) : ""
                                  ) 
                                : ""
                            }
                        </>
                    )
                    )}
                </>
            )

        }
    }

    return (
        <>
            {gridFilterViewStatus[gridName]?(
            <div className='chipsSection'>
            <div className='chipsSectionInternal' id='parentContainer'>
                 {prepareChipList()}
            </div>
            <div className='chipsCrossSection tooltip' onClick={() => filterParamsUpdate("", true)}>
                <img src={ChipCrossIcon} className="chipCrossMain"/>
                <span className="tooltiptext">Clear Filter</span>
            </div>
            </div>
            ):''
            }

        </>
    );
};

export default FilterChips;