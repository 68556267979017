import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import diaglogstyles from './DialogComponent.css';
import icon_close from "../../assets/images/close.svg";
import { CircularProgress } from '@material-ui/core';

const JobInProgressDialog = (props) => {
    const { open, dialogMessage, title} = props;

    return (
        <div>
            <Dialog
                open={open}
                className="dialogbox"
            >
                <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                    <span>{title}</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="dialogcontent">
                        {dialogMessage}
                        <br /><br />
                        <CircularProgress size={30}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default JobInProgressDialog;