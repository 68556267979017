import { columnDefs, defaultColDef } from "./mileageDefault_grid_data/MileageDefaultColumnDefs";
import { FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_PENDING,FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_SUCCESS,FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR, SET_MILEAGEDEFAULT_EDITABLE } from '../mileagedefault/MileageDefault.ActionTypes';
import { getDropdownValuesForYear, safeCaseInsensitiveCompare } from "../../utils/utils";
import { MileageDefaultColumns} from "../../Constants";
import moment from "moment";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, MileageDefaultColumns.Zone.headerName) },
            YEAR: {error: checkIfYearsMatch(data.YEAR,MileageDefaultColumns.Year.headerName)},
            MONTH_NAME: { error:  checkIfValueEmpty(data.MONTH_NAME,MileageDefaultColumns.MONTH_NAME.headerName) },
            CAR_CLASS: { error: checkIfValueEmpty(data.CAR_CLASS, MileageDefaultColumns.CAR_CLASS.headerName,initialState.columnDefs.findIndex(itm=>itm.field===MileageDefaultColumns.CAR_CLASS.field)) },
            MONTHLY_MILEAGE: { error: validateMonthlyMileageFields(data.MONTHLY_MILEAGE, MileageDefaultColumns.MONTHLY_MILEAGE.headerName) },
            PROGRAM_TYPE: { error: checkIfValueEmpty(data.PROGRAM_TYPE, MileageDefaultColumns.PROGRAM_TYPE.headerName) },
            updated_by: { error: null },
            updated_on: { error: null },
           
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfYearsMatch(year,name) {
    if (year === undefined || year === '' || year === null) {
        return name+' Field cannot be empty';
    }else {
        var itemValue = year
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
            return name + " should be in +5 or -10 range from the current year"
        }
    }
        return null;
}

function checkIfValueEmpty(value, name,data) {
    if(name===MileageDefaultColumns.Zone.headerName){
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        } else if(value==='ALL'){
            return name + " Cannot be ALL"
        }
    } else 
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }else if(name===MileageDefaultColumns.CAR_CLASS.headerName){
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
            if (initialState.columnDefs[data].cellEditorParams.options.indexOf(value) == -1) {
                return name +" "+value+" no-longer available";
            }
        }
    }
    return null;
}

function validateMonthlyMileageFields( max, displayName) {
    if ( max === undefined ||  max === '' ||  max === null) {
        return displayName + ' cannot be empty';
    }
   else if (max < 0) {
            return 'Please enter valid ' + displayName
        }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.CAR_CLASS, itm2.CAR_CLASS) && safeCaseInsensitiveCompare(itm.PROGRAM_TYPE, itm2.PROGRAM_TYPE) &&
            safeCaseInsensitiveCompare(itm.YEAR,itm2.YEAR) && safeCaseInsensitiveCompare(itm.MONTH_NAME,itm2.MONTH_NAME) && safeCaseInsensitiveCompare(itm.ZONE_NAME,itm2.ZONE_NAME)) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [], zoneFilterData=[];
    //zoneData.push("ALL")
    if(zones.length>0){
    zones.forEach(val => {
        zoneFilterData.push({"label":val.name,"value":val.name})
        //if (val.name.toUpperCase() != "ALL")
            zoneData.push(val.name)
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"})
}
    element.cellEditorParams = { options: zoneData }
    element.filterOptions=zoneFilterData
}

function fetchDropdownForprogramType(programType, element) {
    let programTypeFilterData = [];
    programType.map((val) => {
        programTypeFilterData.push({ "label": val, "value": val })
    })
    programTypeFilterData.push({ "label": "Blanks", "value": "Blanks" })
    element.cellEditorParams = { options: programType }
    element.filterOptions = programTypeFilterData
}

function fetchDropdownForcarClass(carClass,adminCarClass, element) {
    let carClassFilterData = []
    adminCarClass.map((val) => {
        carClassFilterData.push({ "label": val.car_class, "value": val.car_class })
    })
    carClassFilterData.push({ "label": "Blanks", "value": "Blanks" })
    element.cellEditorParams = { options: carClass }
    element.filterOptions = carClassFilterData;
}

function fetchZones(mileageDefaultData, zones) {
    mileageDefaultData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return mileageDefaultData;
}

function fetchDropdownYears(element){
    let years,yearFilterData=getDropdownValuesForYear();
    let year=new Date().getFullYear()
    years=Array.apply(0, Array(10)).map(function(index,key) { return `${(year-1)-key}`;})
    years=[...years,...Array.apply(0, Array(5)).map(function(index,key) { return `${year+key}`;})]
    years=['ALL',...years].sort();
    //yearFilterData.unshift({"label":"ALL","value":"ALL"})
    element.cellEditorParams = { options: years }
    element.filterOptions= yearFilterData;
}

function fetchDropdownForMonths(element){
    let monthsShortData = moment.monthsShort(), monthsShortFilterData = [];
        monthsShortData.forEach(month => {
        monthsShortFilterData.push({ "label": month.toUpperCase(), "value": month.toUpperCase() })
    })
        monthsShortFilterData.push({ "label": "Blanks", "value": "Blanks" })
        element.filterOptions = monthsShortFilterData;
        element.cellEditorParams = { options: monthsShortData}
}

function fetchFilterValues(carClass,adminCarClass, zones,programType,users,columns) {
    columns.forEach(element => {
        if (element.field === MileageDefaultColumns.Zone.field) {
            fetchDropdownsForZones(zones, element);
        }else if (element.field === MileageDefaultColumns.CAR_CLASS.field) {
            fetchDropdownForcarClass(carClass,adminCarClass, element);
        }else if (element.field === MileageDefaultColumns.PROGRAM_TYPE.field) {
            fetchDropdownForprogramType(programType, element);
        } else if(element.field === MileageDefaultColumns.Year.field){
           fetchDropdownYears(element);
        } else if(element.field === MileageDefaultColumns.MONTH_NAME.field){
            fetchDropdownForMonths(element);
        }
        else if(element.field == MileageDefaultColumns.UpdatedBy.field) {
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData
        }
        if(element.colId==='actions'){
            // this if condition changes cellrenderer when new version
            if(JSON.parse(localStorage.getItem("newArchitecture"))){
                element.cellRenderer='MainPlanInputsActionRenderer'
            }
            else{
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer='MileageDefaultActionRenderer'
            }
        }
    });

    return columns;
}

export default function mileageDefaultColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_SUCCESS:
           // const mileageDefaultData = fetchZones(action.data.mileageDefaultData, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(
                    action.data.car_class,action.data.adminCarClass,
                    action.data.zones,action.data.program_type,action.data.users,state.columnDefs)
            });
        case FETCH_MILEAGEDEFAULT_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_MILEAGEDEFAULT_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}