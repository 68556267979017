import {getMaxDeletesData, saveMaxDeletesTransactions, fetchMaxDeletesDropdownService,uploadMaxDeletesCSV} from '../../services/maxdeletes/MaxDeletesService';
import {FETCH_MAXDELETES_DATA_PENDING, FETCH_MAXDELETES_DATA_SUCCESS, FETCH_MAXDELETES_DATA_ERROR, UPLOAD_MAXDELETES_PENDING, UPLOAD_MAXDELETES_SUCCESS, UPLOAD_MAXDELETES_ERROR,
    SAVE_MAXDELETES_TRANSACTION_PENDING, SAVE_MAXDELETES_TRANSACTION_SUCCESS, SAVE_MAXDELETES_TRANSACTION_ERROR,
    FETCH_MAXDELETES_DROPDOWNVALUES_PENDING,FETCH_MAXDELETES_DROPDOWNVALUES_SUCCESS,FETCH_MAXDELETES_DROPDOWNVALUES_ERROR, RESET_TO_MAXDELETES_INITIALSTATE} from '../../store/maxdeletes/MaxDeletes.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchMaxDeletesData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MAXDELETES_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getMaxDeletesData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MAXDELETES_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_MAXDELETES_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MAXDELETES_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadMaxDeletesData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_MAXDELETES_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadMaxDeletesCSV(planId, files);
            let maxDeletesData = await getMaxDeletesData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_MAXDELETES_SUCCESS, maxDeletesData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_MAXDELETES_ERROR, "max deletes upload failed"));
                dispatch(SetAssumptionError("max deletes upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_MAXDELETES_ERROR, "max deletes upload failed"));
            dispatch(SetAssumptionError("max deletes upload failed"))
        }
    }
}

export const saveMaxDeletesTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MAXDELETES_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveMaxDeletesTransactions(planid, transactionList);
            let maxDeletesData = await getMaxDeletesData(planid);
            if (maxDeletesData) {
                dispatch(serviceActionSuccess(SAVE_MAXDELETES_TRANSACTION_SUCCESS, maxDeletesData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_MAXDELETES_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchMaxDeletesDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_MAXDELETES_DROPDOWNVALUES_PENDING));
        let maxDeletesData = await fetchMaxDeletesDropdownService(planid);
        if (maxDeletesData) {
            dispatch(serviceActionSuccess(FETCH_MAXDELETES_DROPDOWNVALUES_SUCCESS, maxDeletesData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){// this if is for new architecture
                    dispatch(newValidateGrid("maxdeleteGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{// this else is for old architecture and will be removed later
                    dispatch(validateGrid("maxdeletegrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                } 
            }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_MAXDELETES_DROPDOWNVALUES_ERROR, maxDeletesData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MAXDELETES_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetMaxDeletes = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MAXDELETES_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}