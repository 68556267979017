import React, { useEffect, useState } from 'react';
import './InputFilter.css';
import { NttLabel } from '../../../core-components/Label/NttLabel';
import { TextFieldInput } from '../../../core-components/Input/Input';
import { TextField } from '@material-ui/core';


const InputFilter = (props) => {
    const { userSelectedValue, displayName, theme,fieldName, onValueChanged,isFilterCleared } = props;

     const [textvalue,setTextValue]=useState(userSelectedValue)

    const onTextValueChanged=(e)=>{
        setTextValue(e.target.value)
    }

    useEffect(()=>{
        if(textvalue!=undefined){
            onValueChanged(fieldName,textvalue)
        }
    },[textvalue])

    useEffect(()=>{
        if(userSelectedValue.length==0){
            setTextValue('')
        }
    },[userSelectedValue,isFilterCleared])

    return (<span className="custominputFields"><NttLabel theme={theme} labelVariant="body2">
        {displayName}
    </NttLabel>
        <TextField
            type="text"
            id="BasicOutline"
            value={(textvalue === undefined) ? "" : textvalue}
            variant="outlined"
            defaultValue={(textvalue === undefined) ? "" : textvalue}
            onChange={(e) => onTextValueChanged(e)}
        /></span>);
}

export default InputFilter;