import { columnDefs, defaultColDef } from "./ageMileage_grid_data/AgeMileageColumnDefs";
import { FETCH_AGEMILEAGE_DROPDOWNVALUES_PENDING,FETCH_AGEMILEAGE_DROPDOWNVALUES_SUCCESS,FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR, SET_AGEMILEAGE_EDITABLE } from '../agemileage/AgeMileage.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import { AgeMileageColumns } from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            CAR_GROUP: { error: checkIfValueEmpty(data.CAR_GROUP, AgeMileageColumns.CarGroup.headerName,initialState.columnDefs.findIndex(itm=>itm.field==="CAR_GROUP")) },
            ZONE_NAME: { error: checkIfValueEmpty(data.ZONE_NAME, AgeMileageColumns.ZoneName.headerName) },
            PROGRAM_TYPE: { error: checkIfValueEmpty(data.PROGRAM_TYPE, AgeMileageColumns.ProgramType.headerName) },
            MIN_AGE: { error: validateMinMaxFields(data.MIN_AGE, data.MAX_AGE, AgeMileageColumns.MinAge.headerName, false) },
            MAX_AGE: { error: validateMinMaxFields(data.MIN_AGE, data.MAX_AGE, AgeMileageColumns.MaxAge.headerName, false) },
            MIN_MILEAGE: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.MIN_MILEAGE, data.MAX_MILEAGE, AgeMileageColumns.MinMileage.headerName, false) },
            MAX_MILEAGE: { error: checkIfValueIsNotUndefinedNullOrEmpty(data.MIN_MILEAGE, data.MAX_MILEAGE, AgeMileageColumns.MaxMileage.headerName, false) },
            updated_by: { error: null },
            updated_on: { error: null }
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}

function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}

function checkIfValueEmpty(value, name,index) {
    if(name===AgeMileageColumns.ZoneName.headerName || name===AgeMileageColumns.ProgramType.headerName){
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        } else if(value==='ALL'){
            return name + " Cannot be ALL"
        }
    } else 
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    else if(initialState.columnDefs[index].cellEditorParams !== undefined){
        let allCarGroup =sessionStorage.getItem("CARGROUP") ? (JSON.parse(sessionStorage.getItem("CARGROUP"))).body.data:[];
        if (allCarGroup && allCarGroup.length && (allCarGroup.indexOf(value) == -1)) {
            return name + " " + value + " no-longer available";
        }
    }
    return null;
}

function validateMinMaxFields(min, max, displayName, isNegativeAllowed) {
    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return displayName + ' Field cannot be empty';
    }
    if (!isNegativeAllowed) {
        if (min < 0 || max < 0 || min > 5000 || max > 5000) {
            return 'Please enter valid ' + displayName
        }
    } else {
        if (min > 5000 || max > 5000) {
            return 'Please enter valid ' + displayName
        }
    }
    if (parseInt(min) > parseInt(max)) {
        return 'Min Age should be less than Max Age'
    }
    return null;
}

function checkIfValueIsNotUndefinedNullOrEmpty(min, max, displayName, isNegativeAllowed) {
    if (min === undefined || max === undefined || min === '' || max === '' || min === null || max === null) {
        return displayName + ' Field cannot be empty';
    }
    if (!isNegativeAllowed) {
        if (min < 0 || max < 0 || min > 150000 || max > 150000) {
            return 'Please enter valid ' + displayName
        }
    } else {
        if (min > 150000 || max > 150000) {
            return 'Please enter valid ' + displayName
        }
    }
    if (parseInt(min) > parseInt(max)) {
        return 'Min Mileage should be less than Max Mileage'
    }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (safeCaseInsensitiveCompare(itm.CAR_GROUP,itm2.CAR_GROUP) && safeCaseInsensitiveCompare(itm.PROGRAM_TYPE,itm2.PROGRAM_TYPE) &&
            safeCaseInsensitiveCompare(itm.ZONE_NAME,itm2.ZONE_NAME)) {
                unique = false;
                if (itm.status == "ADD_ROW"){
                    itm.status = "ERROR_NEW_ROW";
                }
                else if (itm2.status == "ADD_ROW"){
                    itm2.status = "ERROR_NEW_ROW"
                }
                if(itm.status == "COPY_ROW"){
                    itm.status = "ERROR_COPY_ROW";
                    itm2.status = "ERROR_ROW";
                }
                 else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                    itm2.status = "ERROR_COPY_ROW"
                }
                else {
                    itm.status = "ERROR_ROW";
                    itm2.status = "ERROR_ROW";
                }
                duplicate.push(itm);
                duplicate.push(itm2);
            }
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [],zoneFilterData=[];
    //zoneData.push("ALL")
    zones.forEach(val => {
        zoneFilterData.push({ "label": val.name, "value": val.name })
        //if (val.name != "ALL")
            zoneData.push(val.name)
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: zoneData };
    element.filterOptions = zoneFilterData;
}

function fetchDropdownValueOthers(data, element) {
    let filterData=[]
    data.map((val)=>{
        filterData.push({"label":val,"value":val})
    })
    filterData.push({"label":"Blanks","value":"Blanks"});
    element.cellEditorParams = { options: data };
    element.filterOptions = filterData;
}
function fetchDropdownForcarGroupName(carGroupName,admincarGroupName,element) {
    let carGroupNameFilterData = []
    admincarGroupName.map((val) => {
        carGroupNameFilterData.push({ "label": val, "value": val })
    })
    carGroupNameFilterData.push({ "label": "Blanks", "value": "Blanks" });
    element.cellEditorParams = { options: carGroupName };
    element.filterOptions = carGroupNameFilterData;
}

function fetchZones(ageMileageData, zones) {
    ageMileageData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
    })
    return ageMileageData;
}

function fetchFilterValues(programType,carGroupName,zones,admincarGroupName,users,columns) {
    columns.forEach(element => {
        if (element.field == AgeMileageColumns.ZoneName.field) {
            fetchDropdownsForZones(zones, element);
        } else if (element.field == AgeMileageColumns.CarGroup.field) {
            fetchDropdownForcarGroupName(carGroupName,admincarGroupName,element);
        }else if (element.field == AgeMileageColumns.ProgramType.field) {
            fetchDropdownValueOthers(programType, element);
        }else if (element.field == AgeMileageColumns.UpdatedBy.field) {
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData
        } else if(element.colId==='actions'){
            // this if condition changes cellrenderer when new version
            if(JSON.parse(localStorage.getItem("newArchitecture"))){
                element.cellRenderer='MainPlanInputsActionRenderer'
            }
            else{
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer='AgeMileageActionRenderer'
            }
        }
    });

    return columns;
}

export default function ageMileageColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_AGEMILEAGE_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_AGEMILEAGE_DROPDOWNVALUES_SUCCESS:
           // const ageMileageData = fetchZones(action.data.ageMileageData, action.data.zones)
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(
                    action.data.program_type, action.data.car_group_name,
                    action.data.zones,action.data.admincarGroupName,action.data.users,state.columnDefs)
            });
        case FETCH_AGEMILEAGE_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_AGEMILEAGE_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}