import {FETCH_MAXDELETES_DATA_PENDING, FETCH_MAXDELETES_DATA_SUCCESS, FETCH_MAXDELETES_DATA_ERROR, UPLOAD_MAXDELETES_PENDING, UPLOAD_MAXDELETES_SUCCESS, UPLOAD_MAXDELETES_ERROR,
    SAVE_MAXDELETES_TRANSACTION_PENDING, SAVE_MAXDELETES_TRANSACTION_SUCCESS, SAVE_MAXDELETES_TRANSACTION_ERROR,RESET_TO_MAXDELETES_INITIALSTATE
    } from '../../store/maxdeletes/MaxDeletes.ActionTypes';

const initialState = {
    maxDeletesData:[],
    pending:false,
    error:null,
    uploadMaxDeletesSuccess:null,
    transactionSaveSuccess:null
}

function fetchZones(maxDeletesData, zones) {
    maxDeletesData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.ZONE_CODE) {
                value['ZONE_NAME'] = zone.name;
            } 
        });
        if (value.ZONE_CODE === undefined || value.ZONE_CODE === null) {
            value['ZONE_NAME'] = "";
        }
    })
    return maxDeletesData;
}

export default function MaxDeletesReducer (state=initialState,action){
    switch(action.type){
        case FETCH_MAXDELETES_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_MAXDELETES_DATA_SUCCESS:
            return Object.assign({},state,{
                maxDeletesData: fetchZones(action.data.maxDeletesData, action.data.zones),
                uploadMaxDeletesSuccess: null,
                pending:false,
                error:null
            });
        case FETCH_MAXDELETES_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_MAXDELETES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadMaxDeletesSuccess: null
            });
        case UPLOAD_MAXDELETES_SUCCESS:
            return Object.assign({}, state, {
                maxDeletesData: fetchZones(action.data.maxDeletesData, action.data.zones),
                pending: false,
                error: null,
                uploadMaxDeletesSuccess: true
            });
        case UPLOAD_MAXDELETES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadMaxDeletesSuccess: false
            });
        case SAVE_MAXDELETES_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null
            });
        case SAVE_MAXDELETES_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                maxDeletesData: fetchZones(action.data.maxDeletesData, action.data.zones),
                transactionSaveSuccess: true
            });
        case SAVE_MAXDELETES_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false
            });
        case RESET_TO_MAXDELETES_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}