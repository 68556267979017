import { combineReducers } from 'redux';
import DiversionsReducer from './Diversions.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import diversionsColumnDefinitionReducer from "./Diversions.ColumnDef.Reducer";

const rootDiversionsReducer = combineReducers({
    DiversionsReducer
    , diversionsGridReducer: createNamedWrapperReducer(multiGridUIReducer, "diversionsgrid")
    , diversionsColumnDefinitionReducer
})

export default rootDiversionsReducer;