import {getModelMfrOemData, saveModelMfrOemTransactions, fetchModelMfrOemDropdownService, saveManufacturerModelGroupTransactions, getModelMfrOemFilteredData, getModelMfrOemSearchData} from '../../../services/admin/metadata_modelmfroem/ModelMfrOemService';
import { SET_ADMIN_CARCLASSGROUP_DELETE_FILTER, SET_ADMIN_CARCLASSGROUP_DELETE_FILTER_RECORDS } from '../metadata_carclassgroup/CarClassGroup.ActionTypes';
import {FETCH_MODELMFROEM_DATA_PENDING, FETCH_MODELMFROEM_DATA_SUCCESS, FETCH_MODELMFROEM_DATA_ERROR, SAVE_MODELMFROEM_TRANSACTION_SUCCESS, SAVE_MODELMFROEM_TRANSACTION_ERROR,
    FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING,FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS,FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR, RESET_TO_MODELMFROEM_INITIALSTATE, SAVE_MODELMFROEM_TRANSACTION_PENDING, SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_PENDING, SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_SUCCESS, SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_ERROR, SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN, SET_ADMIN_MODELMFROEM_FILTER_PARAMS, SET_ADMIN_MODELMFROEM_FILTER_CLEAR, SET_ADMIN_MODELMFROEM_SEARCH_TEXT, SET_ADMIN_MODELMFROEM_FILTER_VIEW, SET_ADMIN_MODELMFROEM_SEARCH_VIEW, SET_ADMIN_MODELMFROEM_DELETE_FILTER} from '../metadata_modelmfroem/ModelMfrOem.ActionTypes';


export const fetchModelMfrOemData = (filterParams,deleteMode,searchView) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MODELMFROEM_DATA_PENDING));
            let response =null;  
            if(searchView){
                response =await getModelMfrOemSearchData(filterParams.search,deleteMode);
            }  else {
                response =await getModelMfrOemFilteredData(filterParams,deleteMode); 
            }
             
            if(response){
                dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DATA_SUCCESS,response));
            }else{
                dispatch(serviceActionError(FETCH_MODELMFROEM_DATA_ERROR,"Unknown error"));
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_MODELMFROEM_DATA_ERROR,e.message));
        }
    }

}

export const saveManufacturerModelGroupTransactionsData = (transactionList,userMessage,filterParams,deleteMode,searchView)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_PENDING));

            await saveManufacturerModelGroupTransactions(transactionList);

            dispatch(serviceActionPending(FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING));

            // let response = await getModelMfrOemData();
            let responsePromise =null;
            let modelMfrOemDataPromise =  fetchModelMfrOemDropdownService();
            if(searchView){
                responsePromise = getModelMfrOemSearchData(filterParams.search,deleteMode);
            }  else {
                responsePromise = getModelMfrOemFilteredData(filterParams,deleteMode); 
            }

            const responsesArray = await Promise.all([
                responsePromise,
                modelMfrOemDataPromise,
            ]);

            const response=responsesArray[0];
            const modelMfrOemData=responsesArray[1];
            if(response && modelMfrOemData){
                dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DATA_SUCCESS,response));
                dispatch(serviceActionSuccess(SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_SUCCESS));
                userMessage('success', 'Successfully saved rows')
                dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS, modelMfrOemData));
            }else{
                dispatch(serviceActionError(FETCH_MODELMFROEM_DATA_ERROR,"Unknown error"));
                dispatch(serviceActionError(FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR, modelMfrOemData));
            }        
        } catch (e) {
            console.log("Exception",e);
            console.log("Exception",e.message);
            dispatch(serviceActionError(SAVE_MANUFACTURER_MODEL_GROUP_TRANSACTION_ERROR, e.message));

        }
    }
}

export const saveModelMfrOemTransactionsData = (transactionList,filterParams,deleteMode,searchView)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_MODELMFROEM_TRANSACTION_PENDING));

            await saveModelMfrOemTransactions(transactionList);

            dispatch(serviceActionPending(FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING));

            // let response = await getModelMfrOemData();
            let responsePromise =null;
            let modelMfrOemDataPromise =  fetchModelMfrOemDropdownService();
            if(searchView){
                responsePromise = getModelMfrOemSearchData(filterParams.search,deleteMode);
            }  else {
                responsePromise = getModelMfrOemFilteredData(filterParams,deleteMode); 
            }

            const responsesArray = await Promise.all([
                responsePromise,
                modelMfrOemDataPromise,
            ]);
        
            const response=responsesArray[0];
            const modelMfrOemData=responsesArray[1];
            
            if(response && modelMfrOemData){
                dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DATA_SUCCESS,response));
                dispatch(serviceActionSuccess(SAVE_MODELMFROEM_TRANSACTION_SUCCESS,response));
                dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS, modelMfrOemData));
            }else{
                dispatch(serviceActionError(FETCH_MODELMFROEM_DATA_ERROR,"Unknown error"));
                dispatch(serviceActionError(FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR, modelMfrOemData));
            }        
        } catch (e) {
            console.log("Exception",e);
            console.log("Exception",e.message);
            dispatch(serviceActionError(SAVE_MODELMFROEM_TRANSACTION_ERROR, e.message));

        }
    }
}
export const fetchModelMfrOemDropdownValues = () => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_MODELMFROEM_DROPDOWNVALUES_PENDING));
        let modelMfrOemData = await fetchModelMfrOemDropdownService();
        if (modelMfrOemData) {
            dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DROPDOWNVALUES_SUCCESS, modelMfrOemData));
        } else {
            dispatch(serviceActionError(FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR, modelMfrOemData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_MODELMFROEM_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetModelMfrOem = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_MODELMFROEM_INITIALSTATE))
    }
  }

export const openModelMfrOemFilterPopup = ()=>{
    // console.log("Inside openModelMfrOemFilterPopup...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN,true));
    }
}

export const closeModelMfrOemFilterPopup = ()=>{
    // console.log("Inside closeModelMfrOemFilterPopup...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_FILTER_POPUP_OPEN,false));
    }
}

export const setModelMfrOemFilterParams = (filterParams)=>{
    // console.log("Inside setModelMfrOemFilterParams...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_FILTER_PARAMS,filterParams));
    }
}

export const clearModelMfrOemFilter = (filterParams,deleteFilter)=>{
    // console.log("Inside clearModelMfrOemFilter... ", filterParams);
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_FILTER_CLEAR,filterParams));
    }
}

export const applyModelMfrOemFilter = (filterParams,deleteMode)=>{
    // console.log("Inside applyModelMfrOemFilter...");
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_SEARCH_TEXT,''));
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_FILTER_VIEW,true));
    }
    
}

export const clearModelMfrOemSearchView=()=>{
    // console.log("Inside clearModelMfrOemSearchView...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_SEARCH_VIEW,false));
    }
}

export const applyModelMfrOemSearchView=(searchText,deleteMode)=>{
    // console.log("Inside applyModelMfrOemSearchView...");
    return async dispatch =>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_SEARCH_VIEW,true));
    }
}

export const fetchModelMfrOemDataSearchData=(searchText,deleteMode)=>{
    // console.log("Inside fetchModelMfrOemDataSearchData...");
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_MODELMFROEM_DATA_PENDING));    
            let response = await getModelMfrOemSearchData(searchText,deleteMode);
            // console.log("applyModelMfrOemSearchView response ===>", response);
            if(response){
                dispatch(serviceActionSuccess(FETCH_MODELMFROEM_DATA_SUCCESS,response));
            }else{
                dispatch(serviceActionError(FETCH_MODELMFROEM_DATA_ERROR,"Unknown error"));
            }
        } 
        catch(e){
            console.error("ERROR : ",e.message);
            console.error(e);
            dispatch(serviceActionError(FETCH_MODELMFROEM_DATA_ERROR,e.message));
        }
    }
}

export const setModelMfrOemSearchText=(searchText)=>{
    // console.log("Inside setModelMfrOemSearchText...");
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_SEARCH_TEXT,searchText));
    }
}

export const changeDeleteFilterModeModelMfrOem=(mode)=>{
    // console.log("Inside changeDeleteFilterMode...");
    if(mode===undefined){
        return;
    }
    return async dispatch=>{
        dispatch(serviceActionSuccess(SET_ADMIN_MODELMFROEM_DELETE_FILTER,mode));
    }
}

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}