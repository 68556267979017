import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useComponentWillMount } from './../utils';
import DeleteIcon from "../../../../../assets/images/icon_trash.svg";
import EditIcon from "../../../../../assets/images/icon_edit.svg";
import CopyIcon from "../../../../../assets/images/icon_copy.svg";
import UndoIcon from "../../../../../assets/images/icon_undo.svg";
import { addNewRow, copyNewRow, deleteRow, deleteRowEditMode, refreshGridData, revertRow } from '../../../../../store/admin/AdminMultiGrid/AdminMultiGrid.Actions'
import DialogBox from '../../../../Dialogcomponent/DialogComponent';
import { ADD_ROW, BASIC_CANCEL_TEXT, BASIC_DELETE_MESSAGE, BASIC_DELETE_ROW_TITLE, BASIC_DELETE_TEXT, EVENT_CELL_EDITING_STOPPED, EVENT_CELL_VALUE_CHANGED, pollTimeoutInterval, STATUS_DELETE_IN_PROGRESS, STATUS_DELETE_IN_PROGRESS_TITLE } from '../../../../../Constants';
import { checkDeletePlanStatus, initiateDeletePlan } from '../../../../../store/admin/planmanagement/PlanManagement.Actions';
import JobInProgressDialog from '../../../../Dialogcomponent/JobInProgressDialogComponent';
import { isColumnEditable } from '../../../../../utils/utils';

export default (props)=>{
    let [editing, setEditing] = useState(false);
    let [edited, setEdited] = useState(false);
    let [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [jobInProgressDialogOpen, setJobInProgressDialogOpen] = useState(false);
    const [jobStatusTimer, setJobStatusTimer]=useState(null);
    let message = BASIC_DELETE_MESSAGE;
    let title = BASIC_DELETE_ROW_TITLE;
    let deletetext = BASIC_DELETE_TEXT;
    let canceltext = BASIC_CANCEL_TEXT;
    const deletionInProgress=STATUS_DELETE_IN_PROGRESS;
    const deletionInProgressTitle=STATUS_DELETE_IN_PROGRESS_TITLE;
    let isDeleteClicked = false;
    let isEditCLicked = false;

    const carGroupGridColDef = useSelector((state) => {
        return state.rootAdminPlanManagementReducer.columnDefinitionReducer;
    });
    
    const  planDeleteSuccessful = useSelector((reduxState) => {
        return reduxState.rootAdminPlanManagementReducer.planManagementReducer.planDeleteSuccessful;
    })

    const jobId = useSelector((reduxstate) => {
        return reduxstate.rootAdminPlanManagementReducer.planManagementReducer.jobId;
    })

    // custom hook
    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            setDisabled(true);
        }
    });

    useEffect(() => {
        if (props.data.rowStatus === ADD_ROW && Object.keys(props.data).length <= 2) {
            startEditing();
        }
        props.api.addEventListener(EVENT_CELL_VALUE_CHANGED, onCellValueEditStarted);
        props.api.addEventListener(EVENT_CELL_EDITING_STOPPED, onCellValueEditStopped);

        return () => {
            props.api.removeEventListener(EVENT_CELL_VALUE_CHANGED, onCellValueEditStarted);
            props.api.removeEventListener(EVENT_CELL_EDITING_STOPPED, onCellValueEditStopped);
        };
    }, [editing, edited]);

    function onCellValueEditStarted(params) {
        // Plan rowStatus is running should disable editing   
        if (props.node === params.node) {
            setEditing(true);
            setEdited(false);
            isEditCLicked = true;
        } else {
            setDisabled(true);
        }
    
}

    function onCellValueEditStopped(params) {
        if (isDeleteClicked) {
            setEditing(true);
        }
        else if (props.node === params.node) {
            setEditing(false);
            if (JSON.stringify(params.data) !== JSON.stringify(params.node.data) || isEditCLicked) {
                setEdited(true);
            }
            else
                setEdited(false);
        } else {
            setDisabled(true);
        }
    }

    function startEditing() {
        // Plan rowStatus is running should disable editing        
                props.api.startEditingCell({
                rowIndex: props.node.rowIndex,
                colKey: props.api.getColumnDefs()[1].colId //edit the first column of the row
            });
    }

    function revertChanges() {
        props.api.stopEditing(true);
        dispatch(revertRow("planmanagementgrid", props.node.rowIndex, props.data.ID, props.data, carGroupGridColDef.validationFn,  carGroupGridColDef.businessValidationFn));
        setEdited(false);
    }


    function deleteThisRow() {
        // Plan rowStatus is running should disable editing      
        if (editing){
            revertChanges();
        }
        // console.log("props==",props);
        handleClose();
        setJobInProgressDialogOpen(true);
        dispatch(initiateDeletePlan(props.data.ID));
    }

    useEffect(()=>{
        if(jobInProgressDialogOpen && jobId>0){//jobInProgressDialogOpen for only one row. So if the dialog is open, only then set
            setJobStatusTimer(setInterval(()=>dispatch(checkDeletePlanStatus(jobId,props.data.ID)),pollTimeoutInterval));
        }        
    },[jobId]);

    useEffect(()=>{
        if(jobInProgressDialogOpen && planDeleteSuccessful!==null){
            // console.log("Closing progress dialog.....");
            setJobInProgressDialogOpen(false);
            clearInterval(jobStatusTimer);
            setJobStatusTimer(null);
            dispatch(refreshGridData("planmanagementgrid"));
        }
    },[planDeleteSuccessful]);


    function copyRow() {
        // Plan rowStatus is running should disable editing         
            dispatch(copyNewRow("planmanagementgrid", props.node.rowIndex + 1, props.data, carGroupGridColDef.validationFn,  carGroupGridColDef.businessValidationFn));
    }

    function handleClickOpen() {
        // Plan rowStatus is running should disable editing       
        setOpen(true);
        isDeleteClicked = true;
    };

    function handleClose() {
        setOpen(false);
    };

    //If is running, Edit & Delete are not possible
    if( !isColumnEditable(props)){
            return (
                <div>
                    <div>
                    </div>
                </div>
            )
    }else{
    return (
        <div>
            <div>
                {editing
                    ? <div>
                        <img src={DeleteIcon} onClick={() => handleClickOpen()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        {/* <img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img> */}
                        {(props.data.rowStatus === "ADD_ROW" || props.data.rowStatus === "COPY_ROW" || props.data.rowStatus === "ERROR_NEW_ROW" || props.data.rowStatus === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={() => revertChanges()} style={{ paddingTop: '10px' }}></img>}
                    </div>
                    : <div>
                        <img src={DeleteIcon} onClick={() => handleClickOpen()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        {/* <img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img> */}

                        {edited
                            ?
                            (props.data.rowStatus === "ADD_ROW" || props.data.rowStatus === "COPY_ROW" || props.data.rowStatus === "ERROR_NEW_ROW" || props.data.rowStatus === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={revertChanges} style={{ paddingTop: '10px' }}></img>
                            :
                            ((props.data.rowStatus === "ADD_ROW" || props.data.rowStatus === "COPY_ROW" || props.data.rowStatus === "ERROR_NEW_ROW" || props.data.rowStatus === "INVALID_NEW_ROW") ? <div></div> : <img src={EditIcon} onClick={startEditing} style={{ paddingTop: '10px' }}></img>)
                        }
                    </div>
                }
            </div>
            <div> 
                <DialogBox open={open} handleClose={handleClose} handleYes={deleteThisRow}
                    dialogMessage={message} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DialogBox>
                
                <JobInProgressDialog open={jobInProgressDialogOpen} 
                    dialogMessage={deletionInProgress} title={deletionInProgressTitle}>
                </JobInProgressDialog>
            </div>
        </div>
    )
    }
}