import {FETCH_MANUAL_VIN_DELETES_DATA_PENDING,FETCH_MANUAL_VIN_DELETES_DATA_SUCCESS,FETCH_MANUAL_VIN_DELETES_DATA_ERROR,SAVE_MANUALVINDELETES_TRANSACTION_PENDING,SAVE_MANUALVINDELETES_TRANSACTION_SUCCESS,SAVE_MANUALVINDELETES_TRANSACTION_ERROR, SET_ASSUMPTION_MANUAL_VIN_DELETES_PAGE_VALUE, UPLOAD_MANUALVINDELETES_ERROR, UPLOAD_MANUALVINDELETES_SUCCESS, UPLOAD_MANUALVINDELETES_PENDING,FETCH_MANUALVINDELETES_FILTER_DISPLAY_DATA,
    SET_MANUALVINDELETES_FILTER_SEARCH_VIEW,SET_MANUALVINDELETES_SEARCH_TEXT,SET_MANUALVINDELETES_FILTER_PARAMS,SET_MANUALVINDELETES_FILTER_VIEW,SET_ASSUMPTION_MANUALVINDELETES_ERRORVIEW_STATUS,FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS,FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS,SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING,SET_MANUAL_VIN_DELETES_FILTER_RECORDS_SUCCESS,SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR,SET_MANUAL_VIN_DELETES_SEARCH_RECORDS_SUCCESS,RESET_TO_MANUALVINDELETES_INITIALSTATE,INSERT_UPLOADED_MANUALVINDELETES_PENDING, INSERT_UPLOADED_MANUALVINDELETES_SUCCESS, INSERT_UPLOADED_MANUALVINDELETES_ERROR,
    FETCH_MANUAL_VIN_DELETES_DELETE_PENDING,FETCH_MANUAL_VIN_DELETES_DELETE_SUCCESS,FETCH_MANUAL_VIN_DELETES_DELETE_ERROR} from "./ManualVinDeletes.ActionTypes";
import manualVinDeletesfilterColumns from "../../data/filterManualVinDeletesColumns";

const initialState={
    manualVinDeletesPageNo:1,
    manualVinDeletesTotalRowCount:0,
    manualVinDeletesData:[],
    transactionSaveSuccess:null,
    manualVinDeletesUploadSuccess:null,
    filterDisplayData:[],
    manualVinDeletesFilterSearchView:false,
    manualVinDeletesFilterSearchText:null,
    filterSelectedData:null,
    manualVinDeletesFilterView:false,
    manualVinDeletesErrorData:[],
    manualVinDeletesErrorView:false,
    manualVinDeletesColumnsConstantData:null,
    deleteManualVinDeletesRecordsSuccess:null,
    uniqueErrorIDs:null,
    manualVinDeletesFilterData:[]
}

function fetchDropDownValues(element) {
    if (element.colDef === "VIN") {
        let obj = {};
        obj['label'] = 'Blanks';
        obj['value'] = 'Blanks';
        element.options.push(obj);
    }
    return element;
}
function fetchFilterValues(columns) {
    var filterData = columns().data;
    filterData.forEach(element => {
        if (element.type == "dropdown")
            fetchDropDownValues(element);
    });
    return filterData;
}

export default function ManualVinDeletesReducer(state=initialState,action){
 switch(action.type){
    case FETCH_MANUAL_VIN_DELETES_DATA_PENDING:
     return Object.assign({},state,{
        pending:true,
        error:null
     });
     case FETCH_MANUAL_VIN_DELETES_DATA_SUCCESS:
        return Object.assign({},state,{
            pending:false,
            manualVinDeletesData:action.data.gridData,
            manualVinDeletesTotalRowCount:action.data.rowcount,
            uniqueErrorIDs:null
        });
    case FETCH_MANUAL_VIN_DELETES_DATA_ERROR:
        return Object.assign({},state,{
           pending:false,
           error:action.error,
           uploadManualVinDeletesSuccess: null
        });
    case SAVE_MANUALVINDELETES_TRANSACTION_PENDING:
        return Object.assign({}, state, {
            pending: true,
            transactionSaveSuccess: null,
            deleteManualVinDeletesRecordsSuccess:null,
        });
    case SAVE_MANUALVINDELETES_TRANSACTION_SUCCESS:
        let saveManualVinDeletesData=[],saveManualVinDeletesErrorData=[]
        return Object.assign({}, state, {
            pending: false,
            manualVinDeletesData:action.data.gridData,
            manualVinDeletesTotalRowCount:action.data.rowcount,
            transactionSaveSuccess: true,
            deleteManualVinDeletesRecordsSuccess:null,
            manualVinDeletesErrorData:action.data.gridData,
             manualVinDeletesFilterData:action.data.gridData,
        });
    case SAVE_MANUALVINDELETES_TRANSACTION_ERROR:
        return Object.assign({}, state, {
            pending: false,
            error: action.error,
            transactionSaveSuccess: false,
            deleteManualVinDeletesRecordsSuccess:null
        });
    case UPLOAD_MANUALVINDELETES_PENDING:
        return Object.assign({}, state, {
            pending: true,
            error:null,
            manualVinDeletesUploadSuccess: null,
            uploadManualVinDeletesSuccess: null,
            deleteManualVinDeletesRecordsSuccess:null
        });
    case UPLOAD_MANUALVINDELETES_SUCCESS:
        return Object.assign({}, state, {
            manualVinDeletesData:action.data.gridData,
            manualVinDeletesTotalRowCount:action.data.rowcount,
            manualVinDeletesPageNo:1,
            pending: false,
            error: null,
            manualVinDeletesUploadSuccess: true,
            deleteManualVinDeletesRecordsSuccess:null
        });
    case UPLOAD_MANUALVINDELETES_ERROR:
        return Object.assign({}, state, {
            pending: false,
            error: action.error,
            manualVinDeletesUploadSuccess: false
        });
    case INSERT_UPLOADED_MANUALVINDELETES_PENDING:
        return Object.assign({}, state, {
            manualVinDeletesFilterSearchView:false,
            manualVinDeletesFilterSearchText:null,
            manualVinDeletesFilterView:false,
            manualVinDeletesErrorView:false,
            pending: true,
            error:null
        });
    case INSERT_UPLOADED_MANUALVINDELETES_SUCCESS:
        return Object.assign({}, state, {
            manualVinDeletesData:action.data.gridData,
            manualVinDeletesTotalRowCount:action.data.rowcount,
            manualVinDeletesPageNo:1,
            pending: false,
            error: null
        });
    case INSERT_UPLOADED_MANUALVINDELETES_ERROR:
        return Object.assign({}, state, {
            pending: false,
            error: action.error
        });
    case SET_ASSUMPTION_MANUAL_VIN_DELETES_PAGE_VALUE:
        return Object.assign({}, state, {
            manualVinDeletesPageNo:action.data
        });
        case FETCH_MANUAL_VIN_DELETES_DELETE_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_MANUAL_VIN_DELETES_DELETE_SUCCESS:
            let deletedManualVinDeletesData=[],deletedManualVinDeletesFilterData=[];
            return Object.assign({},state,{
                pending: false,
                manualVinDeletesData:deletedManualVinDeletesData,
                deleteManualVinDeletesRecordsSuccess:true,
                manualvindeletesTotalRowCount:action.data.rowcount,
                uploadManualVinDeletesSuccess: null,
                manualvindeletespageno:1,
                pending:false,
                error:null
            });
        case FETCH_MANUAL_VIN_DELETES_DELETE_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error,
                deleteManualVinDeletesRecordsSuccess:false
                });
    case FETCH_MANUALVINDELETES_FILTER_DISPLAY_DATA:
        var actionData = fetchFilterValues(manualVinDeletesfilterColumns);
        return Object.assign({}, state, {
            filterDisplayData: actionData,
            manualVinDeletesColumnsConstantData:action.data,
            deleteManualVinDeletesRecordsSuccess:null
        });
    case SET_MANUALVINDELETES_FILTER_SEARCH_VIEW:
        return Object.assign({}, state, {
            manualVinDeletesFilterSearchView: action.data
            });
    case SET_MANUALVINDELETES_SEARCH_TEXT:
        return Object.assign({}, state, {
            manualVinDeletesFilterSearchText: action.data
            });
    case SET_MANUALVINDELETES_FILTER_PARAMS:
        return Object.assign({}, state, {
            filterSelectedData: action.data
        });
    case SET_MANUALVINDELETES_FILTER_VIEW:
        return Object.assign({}, state, {
            manualVinDeletesFilterView: action.data
        });
    case SET_ASSUMPTION_MANUALVINDELETES_ERRORVIEW_STATUS:
        return Object.assign({}, state, {
            manualVinDeletesErrorView:action.data
        });
    case FETCH_MANUALVINDELETES_ERROR_DATA_SUCCESS:
        // console.log("FETCH_MOVECOST_ERROR_DATA_SUCCESS >> ", action);
        return Object.assign({},state,{
            manualVinDeletesTotalRowCount: action.data.manualVinDeletesErrorData?action.data.manualVinDeletesErrorData.length:0,
            manualVinDeletesData:[],
            manualVinDeletesErrorData: action.data.manualVinDeletesErrorData,
            uploadManualVinDeletesSuccess: null,
            pending:false,
            error:null
        });
    case FETCH_MANUALVINDELETES_ERROR_IDS_SUCCESS:
        return Object.assign({},state,{
            uniqueErrorIDs:action.data.uniqueErrorIDs
        });
    case SET_MANUAL_VIN_DELETES_FILTER_RECORDS_PENDING:
        return Object.assign({},state,{
            pending:true,
            error:null
        });
    case SET_MANUAL_VIN_DELETES_FILTER_RECORDS_SUCCESS:
        return Object.assign({}, state, {
            manualVinDeletesTotalRowCount:action.data.rowcount,
            manualVinDeletesFilterData:action.data.gridData,
            manualVinDeletesUploadSuccess: null,
            deleteManualVinDeletesRecordsSuccess:null,
            pending:false,
            error:null
        });
    case SET_MANUAL_VIN_DELETES_SEARCH_RECORDS_SUCCESS:
    return Object.assign({}, state, {
        manualVinDeletesTotalRowCount:action.data.rowcount,
        //manualVinDeletesFilterData:[],
        manualVinDeletesFilterData:action.data.gridData,
        manualVinDeletesUploadSuccess: null,
        deleteManualVinDeletesRecordsSuccess:null,
        pending:false,
        error:null
    });
    case SET_MANUAL_VIN_DELETES_FILTER_RECORDS_ERROR:
        return Object.assign({},state,{
            pending:false,
            error:action.error
        });
    case RESET_TO_MANUALVINDELETES_INITIALSTATE:
        return initialState;
    default:
     return state;
 }
}