import {getTargetZonePoolData, saveTargetZonePoolTransactions, fetchTargetZonePoolDropdownService,uploadTargetZonePoolCSV} from '../../services/targetzonepool/TargetZonePoolService';
import {FETCH_TARGETZONEPOOL_DATA_PENDING, FETCH_TARGETZONEPOOL_DATA_SUCCESS, FETCH_TARGETZONEPOOL_DATA_ERROR, UPLOAD_TARGETZONEPOOL_PENDING, UPLOAD_TARGETZONEPOOL_SUCCESS, UPLOAD_TARGETZONEPOOL_ERROR,
    SAVE_TARGETZONEPOOL_TRANSACTION_PENDING, SAVE_TARGETZONEPOOL_TRANSACTION_SUCCESS, SAVE_TARGETZONEPOOL_TRANSACTION_ERROR,
    FETCH_TARGETZONEPOOL_DROPDOWNVALUES_PENDING,FETCH_TARGETZONEPOOL_DROPDOWNVALUES_SUCCESS,FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR, RESET_TO_TARGETZONEPOOL_INITIALSTATE} from './TargetZonePool.ActionTypes';
import { SetAssumptionError, SetAssumptionPending } from '../planassumptions/PlanAssumptions.Actions';
import { validateGrid } from '../MultiGrid/MultiGrid.Actions';
import { FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS } from '../topbar/AllPlans.ActionTypes';
import { validateGrid as newValidateGrid} from '../MainPlanInputsMultiGrid/MainPlanInputsMultiGrid.Actions';

export const fetchTargetZonePoolData = (planid) =>{
    return async dispatch =>{
        try{
            dispatch (serviceActionPending(FETCH_TARGETZONEPOOL_DATA_PENDING));
            dispatch(SetAssumptionPending(true))
            let response = await getTargetZonePoolData(planid);
            if(response){
                dispatch(serviceActionSuccess(FETCH_TARGETZONEPOOL_DATA_SUCCESS,response));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(FETCH_TARGETZONEPOOL_DATA_ERROR,"Unknown error"));
                dispatch(SetAssumptionError("Unknown error"))
            }
        } 
        catch(e){
            console.error(e);
            dispatch(serviceActionError(FETCH_TARGETZONEPOOL_DATA_ERROR,"Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }

}

export const uploadTargetZonePoolData = (planId, files)=> {
    return async dispatch => {
        try {
            dispatch(serviceActionPending(UPLOAD_TARGETZONEPOOL_PENDING));
            dispatch(SetAssumptionPending(true))
            let uploadResp = await uploadTargetZonePoolCSV(planId, files);
            let targetZonePoolData = await getTargetZonePoolData(planId);
            if(uploadResp.obj && uploadResp.obj.statusCode !== '500'){
                dispatch(serviceActionSuccess(UPLOAD_TARGETZONEPOOL_SUCCESS, targetZonePoolData));
                dispatch(SetAssumptionPending(false))
            }else{
                dispatch(serviceActionError(UPLOAD_TARGETZONEPOOL_ERROR, "target zone pool upload failed"));
                dispatch(SetAssumptionError("target zone pool upload failed"))
            }
        } catch (e) {
            console.error("Exception", e);
            dispatch(serviceActionError(UPLOAD_TARGETZONEPOOL_ERROR, "target zone pool upload failed"));
            dispatch(SetAssumptionError("target zone pool upload failed"))
        }
    }
}

export const saveTargetZonePoolTransactionData = (planid,transactionList)=>{
    return async dispatch => {
        try {
            dispatch(serviceActionPending(SAVE_TARGETZONEPOOL_TRANSACTION_PENDING));
            dispatch(SetAssumptionPending(true))
            await saveTargetZonePoolTransactions(planid, transactionList);
            let targetZonePoolData = await getTargetZonePoolData(planid);
            if (targetZonePoolData) {
                dispatch(serviceActionSuccess(SAVE_TARGETZONEPOOL_TRANSACTION_SUCCESS, targetZonePoolData));
                dispatch(SetAssumptionPending(false))
            }
        } catch (e) {
            console.log("Exception",e);
            dispatch(serviceActionError(SAVE_TARGETZONEPOOL_TRANSACTION_ERROR, "Unknown error"));
            dispatch(SetAssumptionError("Unknown error"))
        }
    }
}

export const fetchTargetZonePoolDropdownValues = (planid,data,coldef,validateTransactionsIfMasterDataChanged,isNewArchitecture) => {
    return async dispatch => {
        try{
           dispatch(serviceActionPending(FETCH_TARGETZONEPOOL_DROPDOWNVALUES_PENDING));
        let targetZonePoolData = await fetchTargetZonePoolDropdownService(planid);
        if (targetZonePoolData) {
            dispatch(serviceActionSuccess(FETCH_TARGETZONEPOOL_DROPDOWNVALUES_SUCCESS, targetZonePoolData));
            //THis validateTransactionsIfMasterDataChanged will be true only if master cache data is been updated and then validation of grid is to be done for existing transactions and grid with new dropdown values
            if(validateTransactionsIfMasterDataChanged){
                if(isNewArchitecture){ // this if is for new architecture
                    dispatch(newValidateGrid("targetzonepoolGrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
                }
                else{// this else is for old architecture and will be removed later
                dispatch(validateGrid("targetzonepoolgrid", data, coldef.validationFn, coldef.businessValidationFn,validateTransactionsIfMasterDataChanged));
            }
        }
            dispatch(serviceActionSuccess(FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_SUCCESS,false));
        } else {
            dispatch(serviceActionError(FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR, targetZonePoolData));
        }
      }
      catch(e){
        console.log("Exception",e);
        dispatch(serviceActionError(FETCH_TARGETZONEPOOL_DROPDOWNVALUES_ERROR, "Unknown error"));
      }
    }
}

export const resetTargetZonePool = ()=>{
    return  dispatch=>{
        dispatch(serviceActionSuccess(RESET_TO_TARGETZONEPOOL_INITIALSTATE))
    }
  }

function serviceActionPending(type){
    return {
        type:type
    }
}

function serviceActionSuccess(type,data){
    return{
        type:type,
        data:data
    }
}

function serviceActionError(type,error){
   return{
    type:type,
    error:error
   } 
}