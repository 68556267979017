import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './AdjustmentsAccordionContainer.css';
import { fetchGroupByTypeOrZone, fetchGroupByYear } from '../../store/adjustments/Adjustments.Actions';
import AllBUAdjustmentView from '../../components/adjustments/accordion_view/AllBUAdjustmentView';
import icon_default_sortdown from "../../assets/images/icon-arrow-sort-default.svg";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import TncIceBUAdjustmentView from '../../components/adjustments/accordion_view/tncicebu/TncIceBUAdjustmentView';


function TncIceBUAccordionContainer(props) {
    const { accordionData, tabIndex, planId,selectedVal, handleGridSaveBehavior, allZones,  planStatus, filterActivityForAdjVar, filterActivityForReqValue,filterActivityForReqAdjVal,filterActivityForOriginalValue,filterData} = props;
    const dispatch = useDispatch();
    let currentYear = null;
    let showTotal = false;
    let currentZone = null;
    let showCurrentZone = false;
    let counter = -1;
    const [groupByLabel, setGroupByLabel] = useState("Type/Zone");
    window.parent.document.body.style.overflow = "auto";

    const tncIceBuFilterParams = useSelector((state) =>{
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncIceBuFilterParams;
    })

    var aggregatedAddsArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedAddsArray;
    });

    var aggregatedSalvageArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedSalvageArray;
    });

    var aggregatedEndfleetArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedEndfleetArray;
    });

    var aggregatedStartfleetArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedStartfleetArray;
    });

    var aggregatedCoretoTncArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedCoretoTncArray;
    });

    var aggregatedTncToCsArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedTncToCsArray;
    });

    var aggregatedNetonewayArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedNetonewayArray;
    });

    var aggregatedNetmovesArray = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.tncice_aggregatedNetmovesArray;
    });

    const loadGroupByTypeZone = (groupBy) => {
        dispatch(fetchGroupByTypeOrZone(tabIndex, groupBy));
    }

    useEffect(() => {
        if (accordionData && accordionData.length > 0) {
            loadGroupByTypeZone(selectedVal);
        }
        setGroupByLabel(selectedVal.replace(">", "/"));
    }, [accordionData,selectedVal])

    return (
        <ScrollSync>
            <div>
                {aggregatedEndfleetArray && aggregatedEndfleetArray.length > 0 ?
                    (
                        <div className="alignAdjustments">
                            <div className="coreBUadjustmentsort">
                                <span className="labelAlignment">
                                    <label className="adjustmentsorttext">{groupByLabel}</label>
                                </span>
                                <ScrollSyncPane group="horizontal">
                                    <div className="applyMonthMargin" style={{ overflow: 'auto' }}>
                                        {aggregatedEndfleetArray.map((value) => {
                                            return value.children.map((val) => {
                                                return val.content && val.content.map((c,index) => {
                                                    if (currentZone == null) {
                                                        currentZone = c.zone_name;
                                                        showCurrentZone = true;
                                                    } else if (currentZone != c.zone_name) {
                                                        showCurrentZone = false;
                                                        counter = -1;
                                                    }

                                                    if (currentYear == null || currentYear != c.year) {
                                                        currentYear = c.year;
                                                        showTotal = true;
                                                    }
                                                    else {
                                                        showTotal = false;
                                                    }
                                                    counter++;
                                                    return (showCurrentZone && counter < c.num_plan_months) ? <span className="adjustmentApplyMargin">
                                                        {showTotal ?
                                                            (<span className="adjustmentMinWidth">
                                                                <span className="adjustmentsorttext">Total</span>
                                                                <span className="adjustmentsorttext">'</span>
                                                                <span className="adjustmentsorttext">{c.year.toString().substring(c.year.toString().length - 2)}</span>
                                                            </span>) :
                                                            ''}
                                                        <span className="monthYearWidth">
                                                            <span className={(value.values[0].currentValues[index].isFreezMonth==true) ? 'adjustmentsorttext applyFreezMonthColor' : "adjustmentsorttext"}>{c.month_name.toLowerCase()}</span>
                                                            <span className={(value.values[0].currentValues[index].isFreezMonth==true) ? 'adjustmentsorttext applyFreezMonthColor' : "adjustmentsorttext"}>'</span>
                                                            <span className={(value.values[0].currentValues[index].isFreezMonth==true) ? 'adjustmentsorttext applyFreezMonthColor' : "adjustmentsorttext"}>{c.year.toString().substring(c.year.toString().length - 2)}</span>
                                                        </span>
                                                    </span> : ''
                                                })
                                            })
                                        })}
                                    </div></ScrollSyncPane></div>
                            <div className="adjustmentdataalignment">
                                <TncIceBUAdjustmentView planid={planId}
                                   aggregatedAddsArray={aggregatedAddsArray}
                                   aggregatedSalvageArray={aggregatedSalvageArray}
                                   aggregatedEndfleetArray={aggregatedEndfleetArray}
                                   aggregatedStartfleetArray={aggregatedStartfleetArray}
                                   aggregatedCoretoTncArray={aggregatedCoretoTncArray}
                                   aggregatedTncToCsArray={aggregatedTncToCsArray}
                                   aggregatedNetonewayArray={aggregatedNetonewayArray}
                                   aggregatedNetmovesArray={aggregatedNetmovesArray}
                                   planStatus={planStatus}
                                   allZones={allZones}
                                   selectedVal={selectedVal}
                                    tabIndex={tabIndex}
                                    handleGridSaveBehavior={handleGridSaveBehavior}
                                    filterActivityForAdjVar={filterActivityForAdjVar} filterActivityForReqValue={filterActivityForReqValue} filterActivityForReqAdjVal={filterActivityForReqAdjVal} 
                                    filterActivityForOriginalValue={filterActivityForOriginalValue}
                                    filterParams={tncIceBuFilterParams} filterData={filterData}></TncIceBUAdjustmentView>
                            </div>
                        </div>
                    ) :
                    <div className="norecords">No Rows To Show</div>}
            </div>
        </ScrollSync>
    )
}
export default TncIceBUAccordionContainer;