import { columnDefs, defaultColDef } from "./moveCost_grid_data/MoveCostColumnDefs";
import { FETCH_MOVECOST_DROPDOWNVALUES_PENDING,FETCH_MOVECOST_DROPDOWNVALUES_SUCCESS,FETCH_MOVECOST_DROPDOWNVALUES_ERROR, SET_MOVE_COST_EDITABLE } from '../movecost/MoveCost.ActionTypes';
import { safeCaseInsensitiveCompare } from "../../utils/utils";
import * as CONSTANTS from "../../Constants";

const initialState = {
    columnDefs,
    defaultColDef,
    checkValue: function (value) {
        return {
            value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            FROM_ZONE: { error: checkForZone(data.FROM_ZONE,data.TO_ZONE,data.FROM_POOL,data.TO_POOL, CONSTANTS.MoveCostColumns.FROM_ZONE.headerName) },
            TO_ZONE: { error: checkForZone(data.FROM_ZONE,data.TO_ZONE,data.FROM_POOL,data.TO_POOL, CONSTANTS.MoveCostColumns.TO_ZONE.headerName) },
            FROM_POOL: { error: checkForPool(data.FROM_ZONE,data.TO_ZONE,data.FROM_POOL,data.TO_POOL, CONSTANTS.MoveCostColumns.FROM_POOL.headerName,initialState.columnDefs.findIndex(itm=>itm.field==='FROM_POOL')) },
            TO_POOL: { error: checkForPool(data.FROM_ZONE,data.TO_ZONE,data.FROM_POOL,data.TO_POOL,CONSTANTS.MoveCostColumns.TO_POOL.headerName,initialState.columnDefs.findIndex(itm=>itm.field==='TO_POOL')) },
            // CAR_CLASS: { error: checkIfValueEmpty(data.CAR_CLASS, CONSTANTS.MoveCostColumns.CAR_CLASS.headerName,data.CAR_GROUP,initialState.columnDefs.findIndex(itm=>itm.field==='CAR_CLASS')) },
            // CAR_GROUP: { error:  checkIfValueEmpty(data.CAR_GROUP,CONSTANTS.MoveCostColumns.CAR_GROUP.headerName) },
            CAR_CLASS: { error: checkIfValueEmpty(data.CAR_CLASS, CONSTANTS.MoveCostColumns.CAR_CLASS.headerName,initialState.columnDefs.findIndex(itm=>itm.field==="CAR_CLASS")) },
            CAR_GROUP: { error:  checkIfValueEmpty(data.CAR_GROUP,CONSTANTS.MoveCostColumns.CAR_GROUP.headerName,initialState.columnDefs.findIndex(itm=>itm.field==='CAR_GROUP'),data.CAR_CLASS) },
            MOVE_COST: { error: validateMoveCostAllowedFields(data.MOVE_COST, CONSTANTS.MoveCostColumns.MOVE_COST.headerName) },
            ALLOWED: { error: validateMoveCostAllowedFields(data.ALLOWED, CONSTANTS.MoveCostColumns.ALLOWED.headerName) },
            BU: { error: checkIfValueEmpty(data.BU, CONSTANTS.MoveCostColumns.BU.headerName) },
            updated_by: { error: null },
            updated_on: { error: null }
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData,transactions);
        return duplicateData;
    }
}
function checkForZone(fzone,tzone,fpool,tpool,name){
    if(fzone!='ALL' && tzone!='ALL' && fzone!=null && tzone!=null && fzone===tzone){
       return 'Both From_zone and To_zone cannot be same'
    }
    if(fzone==='ALL' && tzone!='ALL' ){
        return 'From_zone cannot be ALL' 
    } else if(tzone==='ALL' && fzone!='ALL'){
        return 'To_zone cannot be ALL' 
    }
    if(((fpool!='ALL' && fpool!=null) || (tpool!='ALL' && tpool!=null)) && (fzone==null || tzone ==null)){
        return 'Zone should be ALL'
    }
    if(fzone===undefined || fzone==='' || fzone===null){
        return name + ' cannot be empty'
    } else
    if(tzone===undefined || tzone==='' || tzone===null){
        return name + ' cannot be empty'
    } else 
    if((fzone==='ALL' && tzone==='ALL') && (fpool==='ALL' && tpool==='ALL')){
         return 'Both Zone and  Pool cannot be  ALL'
    } 
    if((fpool!='ALL' && fpool!=null) || (tpool!='ALL' && tpool!=null)){
        if(fzone==null || fzone!='ALL' || tzone==null || tzone!='ALL'){
            return 'Zone should be ALL'
        } else {
            return null
        }
        
    }else return null
}
function checkForPool(fzone,tzone,fpool,tpool,name,data){
    if(fpool!='ALL' && tpool!='ALL' && fpool!=null && tpool!=null && fpool===tpool){
        return 'Both From_Pool and To_Pool cannot be same'
     }
    if(fpool==='ALL' && tpool!='ALL' ){
        return 'From_Pool cannot be ALL' 
    } else if(tpool==='ALL' && fpool!='ALL'){
        return 'To_Pool cannot be ALL' 
    }
    if(((fzone!='ALL' && fzone!=null) || (tzone!='ALL' && tzone!=null)) && (fpool==null || tpool ==null)){
        return 'Pool should be ALL'
    }
    if(fpool===undefined || fpool==='' || fpool===null){
        return name + ' cannot be empty'
    } else
    if(tpool===undefined || tpool==='' || tpool===null){
        return name + ' cannot be empty'
    } else 
    if((fzone==='ALL' && tzone==='ALL') && (fpool==='ALL' && tpool==='ALL')){
         return 'Both Zone and  Pool cannot be  ALL'
    } 
    if((fzone!='ALL' && fzone!=null) || (tzone!='ALL' && tzone!=null)){
        if(fpool===null || fpool!=='ALL' || tpool===null || tpool!=='ALL'){
            return 'Pool should be ALL'
        } else {
            return null
        }
        
    }else 
    if(name===CONSTANTS.MoveCostColumns.FROM_POOL.headerName){
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
                if (initialState.columnDefs[data].cellEditorParams.options.indexOf(fpool) == -1) {
                    return name +" "+fpool+" no-longer available";
                } else return null
            }
    } 
    else if(name===CONSTANTS.MoveCostColumns.TO_POOL.headerName){
        if (initialState.columnDefs[data].cellEditorParams !== undefined) {
                if (initialState.columnDefs[data].cellEditorParams.options.indexOf(tpool) == -1) {
                    return name +" "+tpool+" no-longer available";
                } else return null
            }
    } else return null
}
function checkValueType(value) {
    if (value !== "ID" && value !== "version" && value !== "updated_on" &&  value !== "id" && value !== 'plan_id' && value!=='updated_by') {
        return true;
    } else {
        return false;
    }
}



function checkIfValueEmpty(value,name,index,data) {
    let allCargroupCarClasses =sessionStorage.getItem("ALLCARGROUPCARCLASS_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLCARGROUPCARCLASS_CLEAN_MAP"))):[];
    // if(name===CONSTANTS.MoveCostColumns.CAR_GROUP.headerName){
    //  if (value === undefined || value === "" || value === null) {
    //     return name +" "+"cannot be empty";
    //  }
    // }
    // if(name===CONSTANTS.MoveCostColumns.CAR_CLASS.headerName){
    //     if(!data){
    //         return "Please select a car_group"; //First car_group should be selected before selecting a car_class
    //     }else if (value === undefined || value === "" || value === null) {
    //         return name +" "+"cannot be empty";
    //     }else if (initialState.columnDefs[index].cellEditorParams !== undefined) {
    //         let allCarClasses =sessionStorage.getItem("CARCLASSCODE") ? (JSON.parse(sessionStorage.getItem("CARCLASSCODE"))).body.data:[];  
    //         if(allCarClasses && allCarClasses.length && (allCarClasses.indexOf(value) == -1)){
    //             return name +" "+value+" no-longer available";
    //             }else{
    //                 let message=null;
    //                 let allCargroupCarClasses =sessionStorage.getItem("ALLCARGROUPCARCLASS_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("ALLCARGROUPCARCLASS_CLEAN_MAP"))):[];
    //                 let trimmedCarGroup=data.trim();
    //                 let options=allCargroupCarClasses[trimmedCarGroup]?allCargroupCarClasses[trimmedCarGroup]:[];
                    
    //                 if(options.indexOf(value)==-1){
    //                     message = name+" "+'not belongs to the car group';
    //                 }
    //                 return message;
    //             }
    //     }
    // }
    if (name === CONSTANTS.MoveCostColumns.CAR_CLASS.headerName) {
        if (value === undefined || value === "" || value === null) {
            return name + " " + "cannot be empty";
        }
        else if (initialState.columnDefs[index].cellEditorParams !== undefined) {
            let allCarClasses = sessionStorage.getItem("CARCLASSCODE") ? (JSON.parse(sessionStorage.getItem("CARCLASSCODE"))).body.data : [];
            if (allCarClasses && allCarClasses.length && (allCarClasses.indexOf(value) == -1)) {
                return name + " " + value + " no-longer available";
            }
        }
    }
    if(name===CONSTANTS.MoveCostColumns.CAR_GROUP.headerName){
        if(!data){
            return "Please select a car_class"; //First car_group should be selected before selecting a car_class
        }else if (value === undefined || value === "" || value === null) {
            return name +" "+"cannot be empty";
        }else if (initialState.columnDefs[index].cellEditorParams !== undefined) {
            let allCarGroup =sessionStorage.getItem("CARGROUP") ? (JSON.parse(sessionStorage.getItem("CARGROUP"))).body.data:[];  
            if(allCarGroup && allCarGroup.length && (allCarGroup.indexOf(value) == -1)){
                return name +" "+value+" no-longer available";
                }else{
                    let message=null;
                    let allCarClassesCargroup =sessionStorage.getItem("CARCLASSANDGROUP_CLEAN_MAP") ? (JSON.parse(sessionStorage.getItem("CARCLASSANDGROUP_CLEAN_MAP"))):[];
                    let trimmedCarGroup=data.trim();
                    let options=allCarClassesCargroup[trimmedCarGroup]?allCarClassesCargroup[trimmedCarGroup]:"";
                    
                    if(!options.includes(value)){
                        message = name+" "+'not belongs to the car class';
                    }
                    return message;
                }
        }
    }
    if(name===CONSTANTS.MoveCostColumns.BU.headerName){
        if (value === undefined || value === "" || value === null) {
            return name + " cannot be empty";
        } else if(value==='ALL'){
            return name + " Cannot be ALL"
        }
    } else 
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    }
    return null;
}

function validateMoveCostAllowedFields( max, displayName) {
    if ( max === undefined ||  max === '' ||  max === null) {
        return displayName + ' cannot be empty';
    }
   else if(displayName==='Allowed'){
       if(max>=2 || max<0){
           return  'Should be either 0 or 1'
       }
   } else if (max < 0) {
            return 'Please enter valid ' + displayName
        }
    return null;
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
        var unique = true;
        if(itm.FROM_POOL==='ALL' && itm.TO_POOL==='ALL' && itm.FROM_ZONE!='ALL' && itm.TO_ZONE!='ALL'){
        cleaned.forEach(function (itm2) {
                if (safeCaseInsensitiveCompare(itm.FROM_ZONE,itm2.FROM_ZONE) && safeCaseInsensitiveCompare(itm.TO_ZONE,itm2.TO_ZONE) && safeCaseInsensitiveCompare(itm.CAR_CLASS, itm2.CAR_CLASS) && safeCaseInsensitiveCompare(itm.CAR_GROUP, itm2.CAR_GROUP) && safeCaseInsensitiveCompare(itm.BU, itm2.BU)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
           
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
    }
    if(itm.FROM_ZONE==='ALL' && itm.TO_ZONE==='ALL' && itm.FROM_POOL!=='ALL' && itm.TO_POOL!=='ALL'){
        cleaned.forEach(function (itm2) {
                if (safeCaseInsensitiveCompare(itm.FROM_POOL,itm2.FROM_POOL) && safeCaseInsensitiveCompare(itm.TO_POOL,itm2.TO_POOL) && safeCaseInsensitiveCompare(itm.CAR_CLASS, itm2.CAR_CLASS) && safeCaseInsensitiveCompare(itm.CAR_GROUP, itm2.CAR_GROUP) && safeCaseInsensitiveCompare(itm.BU, itm2.BU)) {
                    unique = false;
                    if (itm.status == "ADD_ROW"){
                        itm.status = "ERROR_NEW_ROW";
                    }
                    else if (itm2.status == "ADD_ROW"){
                        itm2.status = "ERROR_NEW_ROW"
                    }
                    if(itm.status == "COPY_ROW"){
                        itm.status = "ERROR_COPY_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                     else if(itm2.status == "COPY_ROW"){ //in case of multiple copy of rows
                        itm2.status = "ERROR_COPY_ROW"
                    }
                    else {
                        itm.status = "ERROR_ROW";
                        itm2.status = "ERROR_ROW";
                    }
                    duplicate.push(itm);
                    duplicate.push(itm2);
                }
           
        });
        if (unique && itm.status != "ADD_ROW") {
            itm.status = "";
            cleaned.push(itm);
        }
    }
        transactions.forEach((itm1,key)=>{
            if(itm1.data){
              if((itm.ID===itm1.data.ID) && (transactions[key].data.status == "ERROR_COPY_ROW" || transactions[key].data.status == "ERROR_ROW") && itm.status==''){
                transactions[key].data.status=itm.status
              }
            }
          })
    });
    return duplicate;
}

function fetchDropdownsForZones(zones, element) {
    var zoneData = [], zoneFilterData=[];
    zones.forEach(val => {
        zoneData.push(val.name)
        zoneFilterData.push({"label":val.name,"value":val.name})
    });
    zoneFilterData.push({"label":"Blanks","value":"Blanks"})
    element.filterOptions=zoneFilterData
    element.cellEditorParams = { options: zoneData }
}

function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = [];
    if(pools.length>0){
    pools.forEach(val => {
        //if (val.code.toUpperCase() != 'ALL'){
            poolData.push(val.code)
        //}
    });
   // poolData.push('ALL')
  }
  if(adminpools.length>0){
  adminpools.forEach(val=>{
    poolFilterData.push({"label":val.code,"value":val.code})
  })
  poolFilterData.push({"label":"Blanks","value":"Blanks"})
  }
    element.cellEditorParams = { options: poolData }
    element.filterOptions=poolFilterData
}

function fetchDropdownValueOthers(data, element) {
    element.cellEditorParams = { options: data }
}


function fetchFilterValues(carClass,carGroup,zones,pool,bu,adminCarClass,admincarGroupName,adminpools,users,columns) {
    columns.forEach(element => {
        if (element.field == "FROM_ZONE" || element.field == "TO_ZONE") {
            fetchDropdownsForZones(zones, element);
        }else if (element.field == "FROM_POOL" || element.field == "TO_POOL") {
            fetchDropdownsForPools(pool,adminpools, element);
        }
        else if (element.field == "CAR_CLASS") {
            let carClassCodeFilterData = []
            adminCarClass.map((val) => {
                carClassCodeFilterData.push({ "label": val.car_class, "value": val.car_class })
            })
            carClassCodeFilterData.push({"label":"Blanks","value":"Blanks"})
            element.cellEditorParams = { options: carClass }
            element.filterOptions = carClassCodeFilterData;
        }
        else if (element.field == "CAR_GROUP") {
            let carGroupNameFilterData = []
            admincarGroupName.map((val) => {
                carGroupNameFilterData.push({ "label": val, "value": val })
            })
            carGroupNameFilterData.push({ "label": "Blanks", "value": "Blanks" });
            element.filterOptions = carGroupNameFilterData;
        }
        else if (element.field == "ALLOWED"){

            element.cellEditorParams = { options: ['0','1'] }
            element.filterOptions = [{ "label": '0', "value": '0' },{ "label": '1', "value": '1' },{"label":"Blanks","value":"Blanks"}];

        }else if (element.field == "BU") {
            let buValues = []
            bu.map((val) => {
                buValues.push({ "label": val, "value": val })
            })
            buValues.push({ "label": "Blanks", "value": "Blanks" });
            element.cellEditorParams = { options: bu }
            element.filterOptions = buValues;
        }else if(element.field === "updated_by"){
            let userKeys = Object.keys(users), usersFilterData = [];
            userKeys.forEach(userData => {
                usersFilterData.push({ "label": users[userData], "value": users[userData] })
            })
            element.filterOptions = usersFilterData;
        }else if (element.colId === 'actions') {
            // this if condition changes cellrenderer when new version
            if (JSON.parse(localStorage.getItem("newArchitecture"))) {
                element.cellRenderer = 'MainPlanInputsActionRenderer'
            }
            else {
                // this else condition changes cellrenderer when old version and will be removed after acceptance
                element.cellRenderer = 'MoveCostActionRenderer'
            }
        } 
    });

    return columns;
}

export default function moveCostColumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MOVECOST_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_MOVECOST_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: fetchFilterValues(
                    action.data.car_class,action.data.car_group_name,
                    action.data.zones,action.data.pools,action.data.bu,action.data.adminCarClass,
                    action.data.admincarGroupName,action.data.adminpools,action.data.users,state.columnDefs)
            });
        case FETCH_MOVECOST_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        case SET_MOVE_COST_EDITABLE:
            return Object.assign({}, state, {
                defaultColDef:{...defaultColDef,editable:action.data}
            }); 
        default:
            return state;
    }
}