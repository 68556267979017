import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import diaglogstyles from './DialogComponent.css';
import icon_close from "../../assets/images/close.svg";
import { TextField } from '@material-ui/core';
import { TextFieldInput } from '../../core-components/Input/Input';
import { checkAndLoadDimention } from '../../utils/utils';

const AdminZoneMapDialogComponent = (props) => {
    const { open, handleClose, handleYes,editClicked,value,rowData } = props;
    // console.log("rowData==>",rowData);
    const initialValues={
        ACCEPTABLE_ZONES:""
    }
    const [newValue,setNewValue]=useState(initialValues)
    let transactions=[
        {
            'type':editClicked?'edit':'add',
            'data':{'column':rowData.colDef.field,'oldName':value,'newName':newValue}
        }
    ]
    function handleClickYes() {
        console.log(transactions);
        handleYes(transactions);
        setNewValue(initialValues);
    }
    function handleClickNo() {
        setNewValue(initialValues);
        handleClose()
    }
    function onChangeHandler(e) {
        const { name, value } = e.target;
        setNewValue({
        ...newValue,
        [name]: value,
        });
    }
    

    let isAZExists=doesAzExist(newValue,rowData);

    function isButtonDisabled(){
        if(isAZExists){
            return true;
        } else if(rowData.colDef.field==='ACCEPTABLE_ZONES'){
            if(editClicked && newValue.ACCEPTABLE_ZONES===''){
                return true;
            }
        }
        return false;
    }
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    className="modelmfroem_dialogbox"
                >
                    <DialogTitle id="draggable-dialog-title" className="dialogtitle">
                        <span>{editClicked?'EDIT DATA':'ADD DATA'}</span>
                        <span><img src={icon_close} onClick={handleClickNo} className="closeicon" alt="iconclose"></img></span>
                    </DialogTitle>
                {editClicked?
                
                    <DialogContent>
                    <div style={{'display':'flex','flexDirection':'column'}}>
                    <p style={{'display':'flex'}}>Old Name: <b>{value} </b></p>
                        New Name:{rowData.colDef.field==='ACCEPTABLE_ZONES'?
                                    <TextFieldInput id="outlined-basic" 
                                                    name={`${rowData.colDef.field}`} 
                                                    variant="outlined" 
                                                    onTextChanged={(e)=>onChangeHandler(e)} 
                                                    value={newValue[`${rowData.colDef.field}`]}
                                                    isError={isAZExists}
                                                    ErrorInputWithHelperText={isAZExists?"This Value exists! Use a new one": ""}

                                    />
                                    :''}
                    </div>
                    </DialogContent>
                :
                    <DialogContent>
                        {rowData.colDef.field==='ACCEPTABLE_ZONES'?
                        <>
                            <b>ACCEPTABLE ZONE:</b>
                            <TextField id="outlined-basic" 
                                       name='ACCEPTABLE_ZONES' 
                                       variant="outlined" 
                                       onChange={(e)=>onChangeHandler(e)} 
                                       value={newValue.ACCEPTABLE_ZONES}
                                       error={isAZExists}
                                       helperText={isAZExists?"This Value exists! Use a new one": ""}
                            />
                        </>
                        :    
                        ''
                        }
                    </DialogContent>          
                }
                    <DialogActions className="actionbuttons">
                        <Button className='cancelbutton' autoFocus onClick={handleClickNo} color="primary">
                            Cancel
                        </Button> 
                        <Button className='okbutton' onClick={handleClickYes} color="primary" 
                                disabled={isButtonDisabled()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
}

const doesAzExist=(newValue,rowData)=>{
    // console.log("IN doesAzExist: newValue==>",newValue);
    // console.log("IN doesAzExist: rowData==>",rowData);

    let zoneMapList;

    if(rowData!=null && rowData.agGridReact){
        zoneMapList=rowData.agGridReact.gridOptions.rowData
    } else {
        zoneMapList=rowData.currentData;
    }

    if(newValue.ACCEPTABLE_ZONES && newValue.ACCEPTABLE_ZONES!=='' && zoneMapList){
        let cachedAcceptableZoneRequest=sessionStorage.getItem('ACCEPTABLEZONES');
        // console.log("sessionStorage.getItem('acceptablezones')==>",JSON.parse(cachedAcceptableZoneRequest));
        let result=false;
        if(cachedAcceptableZoneRequest){
            result=-1<JSON.parse(cachedAcceptableZoneRequest).body.data.findIndex(anAz=>anAz.toLowerCase()===newValue.ACCEPTABLE_ZONES.toLowerCase());
        }
        return result;
    }

    return false;
}

export default AdminZoneMapDialogComponent;