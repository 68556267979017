import { combineReducers } from 'redux';
import MaxDeletesReducer from './MaxDeletes.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import maxDeletesColumnDefinitionReducer from "./MaxDeletes.ColumnDef.Reducer";

const rootMaxDeletesReducer = combineReducers({
    MaxDeletesReducer
    , maxDeletesGridReducer: createNamedWrapperReducer(multiGridUIReducer, "maxdeletegrid")
    , maxDeletesColumnDefinitionReducer
})

export default rootMaxDeletesReducer;