import React from "react";
import AllCellsInAYearComponent from './AllCellsInAYearComponent';

const ReadOnlyRowComponent = (props) => {
    const{activityNode,accordionSummary,plansDataById}=props;

    return activityNode.nodes.map((node, ix) => {
        return <AllCellsInAYearComponent adjustmentNode={node} index={ix} accordionSummary={accordionSummary} plansDataById={plansDataById} />
    });
}

export default ReadOnlyRowComponent;


   