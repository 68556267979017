import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector} from "react-redux";
import { useComponentWillMount } from '../utils';
import DeleteIcon from "../../../../assets/images/icon_trash.svg";
import EditIcon from "../../../../assets/images/icon_edit.svg";
import CopyIcon from "../../../../assets/images/icon_copy.svg";
import UndoIcon from "../../../../assets/images/icon_undo.svg";
import { addNewRow, copyNewRow, deleteRow, deleteRowEditMode, revertRow } from '../../../../store/MultiGrid/MultiGrid.Actions'
import DialogBox from '../../../Dialogcomponent/DialogComponent';

export default (props) => {
    let [editing, setEditing] = useState(false);
    let [edited, setEdited] = useState(false);
    let [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    let message = "Are you sure that you want to delete this row?"
    let title = "Delete Row";
    let deletetext = "Delete";
    let canceltext = "Cancel";
    let isDeleteClicked = false;
    let isEditCLicked = false;
     //Plan Edit Disabled useSelector
    let ifPlanEditDisabled = useSelector((state) => {
        return state.rootAllPlansReducer.allPlansReducer.ifPlanEditDisabled;
      });
    const mileageTableGridColDef = useSelector((state) => {
        return state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableColumnDefinitionReducer;
      });

    const mileageTableGridReducer = useSelector((state) => {
        return { ...state.rootPlanAssumptionsReducer.rootMileageTableReducer.mileageTableGridReducer };
      });

      useEffect(()=>{
        mileageTableGridReducer.transactions.map((transacData)=>{
            if(transacData.type!='add' && transacData.type!='copy'){
            if(props.data.ID==transacData.data.ID){
               setEdited(true)
            }  
        } 
        // else if(transacData.type=='copy'){
        //  if(props.data.ID==transacData.data.ID && transacData.data.status===''){
        //     // console.log(transacData)
        //         setEdited(true)
        //         setEditing(false);
        //      }
        // }
        })
  },[mileageTableGridReducer.transactions])
    // custom hook
    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            setDisabled(true);
        }
    });

    useEffect(() => {
        if (props.data.status === "ADD_ROW" && Object.keys(props.data).length <= 2) {
            startEditing();
        }
        props.api.addEventListener('cellValueChanged', onCellValueEditStarted);
        props.api.addEventListener('cellEditingStopped', onCellValueEditStopped);

        return () => {
            props.api.removeEventListener('cellValueChanged', onCellValueEditStarted);
            props.api.removeEventListener('cellEditingStopped', onCellValueEditStopped);
        };
    }, [editing, edited]);

    function onCellValueEditStarted(params) {
        // Plan status is running should disable editing 
        if(!ifPlanEditDisabled && mileageTableGridColDef.defaultColDef.editable){
        if (props.node === params.node) {
            setEditing(true);
            setEdited(false);
            isEditCLicked = true;
        } else {
            setDisabled(true);
        }
    }
}

    function onCellValueEditStopped(params) {
        if (isDeleteClicked) {
            setEditing(true);
        }
        else if (props.node === params.node) {
            setEditing(false);
            if (JSON.stringify(params.data) !== JSON.stringify(params.node.data) || isEditCLicked) {
                setEdited(true);
            }
            else
                setEdited(false);
        } else {
            setDisabled(true);
        }
    }

    function startEditing() {
        // Plan status is running should disable editing 
        if(!ifPlanEditDisabled && mileageTableGridColDef.defaultColDef.editable){
        props.api.startEditingCell({
            rowIndex: props.node.rowIndex,
            colKey: props.api.getColumnDefs()[1].colId //edit the first column of the row
        });
    }
    }

    function revertChanges() {
        props.api.stopEditing(true);
        dispatch(revertRow("mileagetablegrid", props.node.rowIndex, props.data.ID, props.data, mileageTableGridColDef.validationFn,  mileageTableGridColDef.businessValidationFn));
        setEdited(false);
    }


    function deleteThisRow() {
        // Plan status is running should disable editing 
        if(!ifPlanEditDisabled && mileageTableGridColDef.defaultColDef.editable){
        if (editing)
            dispatch(deleteRowEditMode("mileagetablegrid", props.data.ID));
        else
            dispatch(deleteRow("mileagetablegrid", props.data.ID));
    }
}

    function copyRow() {
        // Plan status is running should disable editing 
        if(!ifPlanEditDisabled && mileageTableGridColDef.defaultColDef.editable){
        dispatch(copyNewRow("mileagetablegrid", props.node.rowIndex + 1, props.data, mileageTableGridColDef.validationFn,  mileageTableGridColDef.businessValidationFn));
    }
}

    function handleClickOpen() {
        // Plan status is running should disable editing 
        if(!ifPlanEditDisabled && mileageTableGridColDef.defaultColDef.editable){
        setOpen(true);
        isDeleteClicked = true;
        }
    };

    function handleClose() {
        setOpen(false);
    };

    return (
        <div>
            <div>
                {editing
                    ? <div>
                        <img src={DeleteIcon} onClick={() => handleClickOpen()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        <img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        {(props.data.status === "ADD_ROW" || props.data.status === "COPY_ROW" || props.data.status === "ERROR_NEW_ROW" || props.data.status === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={() => revertChanges()} style={{ paddingTop: '10px' }}></img>}
                    </div>
                    : <div>
                        <img src={DeleteIcon} onClick={() => handleClickOpen()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>
                        <img src={CopyIcon} onClick={() => copyRow()} style={{ paddingRight: '8px', paddingTop: '10px' }}></img>

                        {edited
                            ?
                            (props.data.status === "ADD_ROW" || props.data.status === "COPY_ROW" || props.data.status === "ERROR_NEW_ROW" || props.data.status === "INVALID_NEW_ROW") ? <div></div> : <img src={UndoIcon} onClick={revertChanges} style={{ paddingTop: '10px' }}></img>
                            :
                            ((props.data.status === "ADD_ROW" || props.data.status === "COPY_ROW" || props.data.status === "ERROR_NEW_ROW" || props.data.status === "INVALID_NEW_ROW") ? <div></div> : <img src={EditIcon} onClick={startEditing} style={{ paddingTop: '10px' }}></img>)
                        }
                    </div>
                }
            </div>
            <div>
                <DialogBox open={open} handleClose={handleClose} handleYes={deleteThisRow}
                    dialogMessage={message} title={title} action={deletetext}
                    canceltext={canceltext}>
                </DialogBox>
            </div>
        </div>
    )
}
