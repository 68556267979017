import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './AutoCompletePopupEditor.css';
import { IconButton } from "@material-ui/core";
import EditorDialogBox from '../../../../Dialogcomponent/EditorDialogComponent';
import TempEditorDialogBox from '../../../../Dialogcomponent/TempEditorDialogComponent';
import EditIcon from "../../../../../assets/images/icon_edit.svg";
import { MODELMFROEM, ZONEMAPGRID } from "../../../../../Constants";
import AdminModelMfrOemDialogComponent from "../../../../Dialogcomponent/AdminModelMfrOemDialogComponent";
import AdminZoneMapDialogComponent from "../../../../Dialogcomponent/AdminZoneMapDialogComponent";
export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [editClicked, setEditClicked] = useState(false);

    const handleCloseDialog = () => {
        setOpenDeleteDialog(false);
        setEditClicked(false);
    }

    const handleSaveChanges = (transactionList) => {
        props.agGridReact.props.propsTest.handleAddNewDropdownValue(transactionList)            
        setOpenDeleteDialog(false);
        setEditClicked(false);
    }

    function onChangeHandler(e, value) {
        setValue(value);
    }

    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
    }

    useEffect(o => {
        setTimeout(function () {
            if (inputRef && inputRef.current
                && inputRef.current.children.length > 0
                && inputRef.current.children[0].children.length > 0) {
                inputRef.current.children[0].children[0].select();
                inputRef.current.children[0].children[0].focus();
            }
        }, 100);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
            }
        };
    });

    function onHighlightChangeHandler(e, option, reason) {
        if (option !== null && reason === 'keyboard') {
            setValue(option);
            setInputValue(option);
        }
    }

    function getOptions(props){
        if(props.column.colId){
            for(let i=0;i<props.column.gridOptionsWrapper.gridOptions.columnDefs.length;i++){
                if(props.column.gridOptionsWrapper.gridOptions.columnDefs[i].field===props.column.colId){
                    return props.column.gridOptionsWrapper.gridOptions.columnDefs[i].cellEditorParams.options?props.column.gridOptionsWrapper.gridOptions.columnDefs[i].cellEditorParams.options:props.options;
                }
            }
        }

        return [];
    }
    
  const AddValue=()=>{
    setEditClicked(false);
    setOpenDeleteDialog(true);

  }

  const EditValue=()=>{
    setOpenDeleteDialog(true);
    setEditClicked(true);
  }

    return (    
        <div>
        <Autocomplete
            // options={props.options ? props.options : getOptions(props)}
            options={getOptions(props)}
            value={value}
            onChange={onChangeHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            onHighlightChange={onHighlightChangeHandler}
            closeIcon=''
            renderInput={(params) => (
                <div>
                <TextField
                    ref={inputRef}
                    {...params}
                    style={{ padding: '5px 0',width:'48%' }} />
                {/* {props.colDef.field==='MANUFACTURER_GROUP'?'':<IconButton onClick={()=>AddValue()}>+</IconButton>} */}
                {(props.agGridReact.props.gridName==='carclassgroupgrid' && props.colDef.field==='car_class_car_group') ?<IconButton onClick={()=>AddValue()}>+</IconButton>:''}
                {value?<IconButton onClick={()=>EditValue()}><img src={EditIcon} style={{ paddingTop: '10px' }}></img></IconButton>:''}
                </div>
            )}
        />
        {(props.agGridReact.props.gridName===MODELMFROEM && editClicked)?
        // <TempEditorDialogBox open={openDeleteDialog} handleClose={handleCloseDialog} handleYes={handleSaveChanges} editClicked={editClicked} value={value} rowData={props}/>
        <AdminModelMfrOemDialogComponent open={openDeleteDialog} handleClose={handleCloseDialog} handleYes={handleSaveChanges} editClicked={editClicked} value={value} rowData={props}/>
        :
        (props.agGridReact.props.gridName===ZONEMAPGRID && editClicked)?
        // <TempEditorDialogBox open={openDeleteDialog} handleClose={handleCloseDialog} handleYes={handleSaveChanges} editClicked={editClicked} value={value} rowData={props}/>
        <AdminZoneMapDialogComponent open={openDeleteDialog} handleClose={handleCloseDialog} handleYes={handleSaveChanges} editClicked={editClicked} value={value} rowData={props}/>
        :
        <EditorDialogBox open={openDeleteDialog} handleClose={handleCloseDialog} handleYes={handleSaveChanges} editClicked={editClicked} value={value} rowData={props}/>
        }
        </div>
    );
})
