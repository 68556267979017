import moment from 'moment';
import * as CONSTANTS from '../../../../Constants';
import { checkDate, checkFullDate, safeCaseInsensitiveCompare } from "../../../../utils/utils";
import { columnDefs, defaultColDef } from "../../mainrfinputs/coredemand/coredemand_grid_data/CoreDemandColumnDefs";
import {FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_PENDING,FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_SUCCESS,FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_ERROR} from "../coredemand/CoreDemand.ActionTypes";

const initialState = {
    columnDefs,
    defaultColDef,
    dropdownValues:[],
    checkValue: function (value) {
        return {
            //value: checkValueType(value),
        }
    },
    validationFn: function (data) {
        return {
            ID: data.ID,
            POOL: { error: checkPool(data.POOL,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Pool.field, data) },
            BUSINESS_SEGMENT: { error: checkBusinessSegment(data.BUSINESS_SEGMENT,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.BusinessSegment.field, data) },
            START_DATE: { error: checkStartDate(data.START_DATE,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.StartDate.field, data) },
            ONRENT_TY: { error: checkOnRentTy(data.ONRENT_TY,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.OnRentTy.field, data)  },
            CAR_CLASS: { error: checkCarClass(data.CAR_CLASS,CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Carclass.field, data)  },
        }
    },
    businessValidationFn: function (currentData,transactions) {
        var duplicateData = arrUnique(currentData, transactions);
        return duplicateData;
    }
}

function arrUnique(data,transactions) {
    var cleaned = [];
    var duplicate = [];
    data.forEach(function (itm) {
    var unique = true;
    cleaned.forEach(function (itm2) {
        if (safeCaseInsensitiveCompare(itm.POOL, itm2.POOL) && checkFullDate(itm.START_DATE, itm2.START_DATE) && safeCaseInsensitiveCompare(itm.BUSINESS_SEGMENT, itm2.BUSINESS_SEGMENT)) {
            unique = false;
            if(itm.rowStatus == "ADD_ROW"){
                itm.rowStatus = "ERROR_NEW_ROW";
            }
            else if(itm2.rowStatus == "ADD_ROW"){
                itm2.rowStatus = "ERROR_NEW_ROW"
            }
            if(itm.rowStatus == "COPY_ROW"){
                itm.rowStatus = "ERROR_COPY_ROW";
                itm2.rowStatus = "ERROR_ROW";
            }
                else if(itm2.rowStatus == "COPY_ROW"){ //in case of multiple copy of rows
                itm2.rowStatus = "ERROR_COPY_ROW"
            }
            else {
                itm.rowStatus = "ERROR_ROW";
                itm2.rowStatus = "ERROR_ROW";
            }
            duplicate.push(itm);
            duplicate.push(itm2);
        }
    });
    transactions.forEach((itm1,key)=>{
        if(itm1.data){
            if((itm.ID===itm1.data.ID) && (transactions[key].data.rowStatus == "ERROR_COPY_ROW" || transactions[key].data.rowStatus == "ERROR_ROW") && itm.rowStatus==''){
            transactions[key].data.rowStatus=itm.rowStatus
            }
        }
        })
    if (unique && itm.rowStatus != "ADD_ROW") {
        itm.rowStatus = "";
        cleaned.push(itm);
    }
});
return duplicate;   
}

function checkStartDate(value,fiedName,data){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.StartDate.field;

    if (value === undefined || value === "" || value === null) {

        return fiedName +" "+"cannot be empty";
    } 
    else if(value == 'Invalid date'){
        return fiedName +" "+"cannot be invalid date";
    }
    else if(!moment(value).isBetween(CONSTANTS.minimumDate, CONSTANTS.maximumDate,'year','[]')){
        return fiedName + " should be in +5 or -10 range from the current year"
    
        // var itemValue = new Date(value).getFullYear();
        // if(isNaN(itemValue) || itemValue === 'Invalid Date'){
        //     return fiedName +" "+"cannot be invalid date";
        // }
        // if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
        //     return fiedName + " should be in +5 or -10 range from the current year"
        // }
        
    }
    // console.log("All OKKKKK....");
    return null;
}

function checkPool(value,fiedName,data){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Pool.field;
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }
    
    // console.log("All OKKKKK....");
    return null;
}

function checkOnRentTy(value,name,data){
    //const name=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.OnRentTy.field;
    if (value === undefined || value === "" || value === null) {
        return name + " cannot be empty";
    } 
    if(isNaN(value)){
        return name + " must be a valid number";
    }
    if(value<0){
        return `${name} cannot be less than 0.`;
    }
    
    // console.log("All OKKKKK....");
    return null;
}

function checkBusinessSegment(value,fiedName,data){
    //const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.BusinessSegment.field;
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }else if(!CONSTANTS.BUSINESS_SEGMENT_OPTIONS.includes(value)){
        return "Please choose from dropdown options";
    }
    
    // console.log("All OKKKKK....");
    return null;
}

function checkCarClass(value,fiedName,data){
   // const fiedName=CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Carclass.field;
    if (value === undefined || value === "" || value === null) {
        // console.log("cannot be empty");
        return fiedName +" "+"cannot be empty";
    }
    
    //Based on clients requirement removing the DEMAND_BY_CAR_CLASS validation 
    //Keeping below code for reference incase if they want this again 
    // let parameters=sessionStorage.getItem(CONSTANTS.PARAMETERS_SESSION_STORAGE_NAME);
    // // console.log("Parsed parameters list==>",JSON.parse(parameters));
    // let parametersMap=JSON.parse(parameters);
    // if(parametersMap && parametersMap["DEMAND_BY_CAR_CLASS"]){
    //     if(parametersMap["DEMAND_BY_CAR_CLASS"].toUpperCase()==="TRUE" && value==="ALL"){
    //         return `Since DEMAND_BY_CAR_CLASS is set to TRUE, so value cannot be ALL.`;
    //     }else if(parametersMap["DEMAND_BY_CAR_CLASS"].toUpperCase()==="FALSE" && value!=="ALL"){
    //         return `Since DEMAND_BY_CAR_CLASS is not set to TRUE, so value must be ALL.`;
    //     }            
    // }
    
    // console.log("All OKKKKK....");
    return null;
}

//Not To be used but kept for reference as of Sprint 50
function checkIfValueEmpty(value, fiedName) {
    // console.log(`value=${value}, fiedName=${fiedName}`);
    if (value === undefined || value === "" || value === null) {
        return fiedName +" "+"cannot be empty";
    }else if(fiedName===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.StartDate.field){
        var itemValue = new Date(value).getFullYear();
        if (itemValue > (new Date().getFullYear() + 5) || itemValue < (new Date().getFullYear() - 10)){
            return fiedName + " should be in +5 or -10 range from the current year"
        }
    }else if(fiedName===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Carclass.field){
        let parameters=sessionStorage.getItem(CONSTANTS.PARAMETERS_SESSION_STORAGE_NAME);
        // console.log("Parsed parameters list==>",JSON.parse(parameters));
        let parametersMap=JSON.parse(parameters);
        if(parametersMap && parametersMap["DEMAND_BY_CAR_CLASS"]){
            if(parametersMap["DEMAND_BY_CAR_CLASS"].toUpperCase()==="TRUE" && value==="ALL"){
                return `Since DEMAND_BY_CAR_CLASS is set to TRUE, so value cannot be ALL.`;
            }else if(parametersMap["DEMAND_BY_CAR_CLASS"].toUpperCase()==="FALSE" && value!=="ALL"){
                return `Since DEMAND_BY_CAR_CLASS is not set to TRUE, so value must be ALL.`;
            }            
        }
    }else if(fiedName===CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.OnRentTy.field){
        if(value<0){
            return `${fiedName} cannot be less than 0.`;
        }
    }

    return null;
}

function fetchDropdownsForPools(pools,adminpools, element) {
    var poolData = [],poolFilterData = [];
    if(pools && pools.length>0){
        pools.forEach(val => {
            if (val.code.toUpperCase() != 'ALL'){
                poolData.push(val.code)
            }
        });
    }

    if(adminpools && adminpools.length>0){
        adminpools.forEach(val=>{
            poolFilterData.push({"label":val.code,"value":val.code})
        })
        poolFilterData.push({"label":"Blanks","value":"Blanks"})
    }
    element.cellEditorParams = { options: poolData }
    element.filterOptions = poolFilterData;
}

function fetchDropdownsForBusinessSegment(element) {
 let businessSegFilterDropdownOptions=[];
 CONSTANTS.BUSINESS_SEGMENT_OPTIONS.map((val)=>{
    businessSegFilterDropdownOptions.push({"label":val,"value":val})
 })
 element.cellEditorParams = { options: CONSTANTS.BUSINESS_SEGMENT_OPTIONS }
 businessSegFilterDropdownOptions.push({"label":"Blanks","value":"Blanks"})
 element.filterOptions = businessSegFilterDropdownOptions;
}

function fetchDropdownsForCarClass(carClasses,adminCarClasses, element) {
    var carClassData = [],carClassFilterDropdownOptions=[]
    if(carClasses && carClasses.length>0){
        carClassData.push("ALL");
        carClasses.forEach(val => {
            carClassData.push(val)
        });
    }

    if(adminCarClasses && adminCarClasses.length>0){
        carClassFilterDropdownOptions.push({"label":"ALL","value":"ALL"})
        adminCarClasses.forEach(val => {
            carClassFilterDropdownOptions.push({"label":val.car_class,"value":val.car_class})
        });
        carClassFilterDropdownOptions.push({"label":"Blanks","value":"Blanks"})
    }

    element.cellEditorParams = { options: carClassData }    
    element.filterOptions = carClassFilterDropdownOptions;
}


function setColumnDefParams(columnDefs, data) {
    columnDefs.map((column) => {
        if (column.field === CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Pool.field) {
            fetchDropdownsForPools(data.pools,data.adminPools, column);
        }else if(column.field === CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.BusinessSegment.field) {
            fetchDropdownsForBusinessSegment(column) ;
        }else if(column.field === CONSTANTS.MainRFInputCoreColumns.MainRFInputCoreDemandColumns.Carclass.field){
            fetchDropdownsForCarClass(data.carClasses,data.adminCarClasses, column);
        }else{
            //NO Need
        }
    });
    return columnDefs;
}

export default function RFCoreDemandcolumnDefinitionReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_PENDING:
            return Object.assign({}, state, {
                pending: true,
                dropdownValues:setColumnDefParams(state.columnDefs, {})
            });
        case FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_SUCCESS:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: setColumnDefParams(state.columnDefs, action.data)
            });
        case FETCH_MAINRFINPUTS_COREDEMAND_DROPDOWNVALUES_ERROR:
            return Object.assign({}, state, {
                pending: false,
                dropdownValues: action.error
            });
        default:
            return state;
    }
}