
export const NEW_EDIT_ROW = 'NEW_EDIT_ROW';
export const NEW_EDIT_ROW_MULTIPLE = 'NEW_EDIT_ROW_MULTIPLE';
export const NEW_ADD_ROW = 'NEW_ADD_ROW';
export const NEW_COPY_ROW = 'NEW_COPY_ROW';
export const NEW_DELETE_ROW = 'NEW_DELETE_ROW';
export const NEW_DELETE_ROW_EDIT = 'NEW_DELETE_ROW_EDIT';
export const NEW_REVERT_ROW = 'NEW_REVERT_ROW';
export const NEW_POPULATE_GRID = 'NEW_POPULATE_GRID';
export const NEW_VALIDATE_ROW = 'NEW_VALIDATE_ROW';
export const NEW_REFRESH_GRID = 'NEW_REFRESH_GRID';
export const NEW_VALIDATE_GRID = 'NEW_VALIDATE_GRID';
export const NEW_CLEAN_TRASACTIONS = 'NEW_CLEAN_TRASACTIONS';
export const NEW_POPULATE_GRID2 = 'NEW_POPULATE_GRID2';
export const NEW_UPDATE_IS_ROW_INVALID = 'NEW_UPDATE_IS_ROW_INVALID';
export const NEW_RESET_MULTI_GRID_STATE = 'NEW_RESET_MULTI_GRID_STATE';
export const MAIN_AND_PLANINPUTS_UPDATEGRID_ONVALIDATION = 'MAIN_AND_PLANINPUTS_UPDATEGRID_ONVALIDATION';

export const validateRow = (gridname, data, businessValidationFn)=>{
    return {
        name:gridname,
        type:NEW_VALIDATE_ROW,
        data:{
            data,
            businessValidationFn
        }
    }
}

export const validateGrid = (gridname, data, validationFn, businessValidationFn,validateTransactions)=>{
    return {
        name:gridname,
        type:NEW_VALIDATE_GRID,
        data:{
            data,
            validationFn,
            businessValidationFn,
            validateTransactions
        }
    }
}

export const populateGrid = (gridname, data, filterSearchKey, checkType, checkValue, checkIfRequiredFn)=>{
    return {
        name:gridname,
        type:NEW_POPULATE_GRID,
        data:data,
        filterSearchKey:filterSearchKey,
        checkType:checkType,
        checkValue:checkValue,
        checkIfRequiredFn:checkIfRequiredFn,
    }
}

export const populateGrid2 = (gridname, originalDta,currentData,filterSearchKey,checkValue,validationFn, businessValidationFn,checkIfRequiredFn,uploadState)=>{
    return {
        name:gridname,
        type:NEW_POPULATE_GRID2,
        originalData:originalDta,
        currentData:currentData,
        filterSearchKey:filterSearchKey,
        checkValue:checkValue,
        checkIfRequiredFn:checkIfRequiredFn,
        data:{
            validationFn,
            businessValidationFn
        },
        uploadState:uploadState
        }
}

export const completeEditRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:NEW_EDIT_ROW,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}

export const completeEditMultipleRow = (gridname,data,validationfn, colDef, businessValidationFn,sortedData)=>{
    return {
        name:gridname,
        type:NEW_EDIT_ROW_MULTIPLE,
        data:{data,validationfn, colDef, businessValidationFn,sortedData}
    }
}

export const addNewRow = (gridname,rowindex,data)=>{
    return {
        name:gridname,
        type:NEW_ADD_ROW,
        data:{rowindex,data}
    }
}

export const copyNewRow = (gridname,rowindex,data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:NEW_COPY_ROW,
        data:{rowindex,data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}


export const deleteRow = (gridname,id)=>{
    return {
        name:gridname,
        type:NEW_DELETE_ROW,
        data:{id}
    }
}

export const deleteRowEditMode = (gridname,id)=>{
    return {
        name:gridname,
        type:NEW_DELETE_ROW_EDIT,
        data:{id}
    }
}

export const revertRow = (gridname,rowindex,rowID, data, validationFn, businessValidationFn)=>{
    return {
        name:gridname,
        type:NEW_REVERT_ROW,
        data:{rowindex,rowID, data},
        validationFn:validationFn,
        businessValidationFn:businessValidationFn
    }
}

export const refreshGridData = (gridName) => {
    return {
        name: gridName,
        type: NEW_REFRESH_GRID
    }
}

export const cleanTransactions = (gridName)=>{
    return {
        name: gridName,
        type: NEW_CLEAN_TRASACTIONS
    }
}

export const ResetMultiGridState = ()=>{
    return {
        type: NEW_RESET_MULTI_GRID_STATE
    }
}