import React, { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import EditableAllCellsInAYearComponent from './corebu/EditableAllCellsInAYearComponent';
import EditIcon from "../../../assets/images/icon_edit.svg";
import { Divider, Button } from '@material-ui/core';
import { ScrollSyncPane } from 'react-scroll-sync';
import { setAdjustmentAction, setAdjustmentLevel, updateAdjustments } from '../../../store/adjustments/Adjustments.Actions';
import { isDisableEditing } from "../../../utils/AdjustmentsUtils";
import { ADJLEVEL } from "../../../utils/constants";
import {setEditAdjustmentsStatus,loadPlanDataById} from '../../../store/topbar/AllPlans.Actions';
const EditableRowComponent = (props) => {
    const { arrayObject, isExpanded,selectedVal,adjDisable, planid, handleGridSaveBehavior, content, planStatus, filterParams, zonename, accordionContent,filterRecord,currentHeader} = props;
    const [errorExist,setErrorExist] = useState(false);
    const dispatch = useDispatch();
    let updatedCellMonthAndYear = [];
    const [updatedCellMonthAndYearArr, setUpdatedCellMonthAndYearArr] = useState();

    function allDataFilteredByYear(year) {
        let filterByYear = arrayObject.filter((o) => o.current.year == year)
        return filterByYear;
    }

    function calculateNumberOfMonthInEachYear(arr) {
        const yearsInNumberOfPlanMonths = [];
        let remainingMonths = 0;
        let currentYear;
        return arr.map(element => {
            let yearsIndex = yearsInNumberOfPlanMonths.map(function (e) {
                return e.year;
            }).indexOf(element.current.year);
            if (element.current.cpd.year == element.current.year) {
                currentYear = element.current.cpd.year;
                if ((element.current.cpd.month + element.current.num_plan_months) > 12) {
                    remainingMonths = 12 - element.current.cpd.month + 1;
                } else {
                    remainingMonths = element.current.num_plan_months;
                }
                if (yearsIndex < 0) {
                    yearsInNumberOfPlanMonths.push({
                        "year": currentYear,
                        "startMonth": element.current.cpd.month,
                        "numOfMonthsInYear": remainingMonths,
                    })
                } else {
                    yearsInNumberOfPlanMonths[yearsIndex] = {
                        "year": currentYear,
                        "startMonth": element.current.cpd.month,
                        "numOfMonthsInYear": remainingMonths,
                    }
                }
            } else if (element.current.year > element.current.cpd.year) {
                if (element.current.cpd.month + element.current.num_plan_months > 12) {
                    let planMonths = element.current.num_plan_months - remainingMonths;
                    if (planMonths > 12) {
                        planMonths = 12;
                        remainingMonths = element.current.num_plan_months - planMonths - remainingMonths;
                    }
                    if (yearsIndex < 0) {
                        yearsInNumberOfPlanMonths.push({
                            "year": element.current.year,
                            "startMonth": 1,
                            "numOfMonthsInYear": planMonths,
                        });
                    } else {
                        yearsInNumberOfPlanMonths[yearsIndex] = {
                            "year": element.current.year,
                            "startMonth": 1,
                            "numOfMonthsInYear": remainingMonths,
                        }
                    }
                }
            }
            return yearsInNumberOfPlanMonths;
        });
    }

    let editableArray  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.editableArrayObject;
    });

    let pending  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.pending;
    });

    let editing  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.editing;
    });

    let adjlevel  = useSelector((state) => {
        return state.rootAdjustmentsReducer.adjustmentsReducer.adjlevel;
    });

    function updateActivityLevelAdjustmentRow(e, arrayObject) {
        dispatch(setAdjustmentAction(arrayObject,"edit_clicked"))
    }

    function handleSaveAdjustments() {
        let transactions = [];
        dispatch(setAdjustmentAction([],"save_clicked"))
        handleGridSaveBehavior();
        editableArray.map((value) => {

            let obj = {};
            updatedCellMonthAndYearArr.map((val) => {
               
                if ((val.month == value.adjustment.month) && (val.year == value.adjustment.year)) {
                    obj['plan_id'] = planid;
                    obj['bu'] = value.adjustment.bu;
                    if (content) {
                        obj['zone_code'] = value.adjustment.zone_code;
                    } else {
                        obj['zone_code'] = 'ALL';
                    }
                    obj['pool'] = value.adjustment.pool;
                    obj['activity_type'] = value.adjustment.activity_type;
                    obj['month'] = value.adjustment.month;
                    obj['year'] = value.adjustment.year;
                    obj['adjustment'] = value.adjustment.value;
                    obj['engine_group'] = value.adjustment.engine_group;
                    transactions.push(obj);
                }
            })
        })
        dispatch(updateAdjustments(planid, transactions));
        dispatch(setAdjustmentLevel(ADJLEVEL.NONE));
        if(planStatus==='EDITING ADJUSTMENTS'){
         dispatch(setEditAdjustmentsStatus(planid,planStatus))
        }
    }

    function handleUpdatedCellMonthANdYear(monthAndYear) {
        updatedCellMonthAndYear = monthAndYear;
        setUpdatedCellMonthAndYearArr(updatedCellMonthAndYear);
        return updatedCellMonthAndYear;
    }
    function checkForErrors(isErrorExist){
        setErrorExist(isErrorExist);
    }
    //if condition is true then edit icon is disable 
    let isDisable = (planStatus && planStatus.toLowerCase() == 'adjustment complete' || planStatus.toLowerCase()=='sales adjustments ready' || planStatus.toLowerCase()=='vin dep process ready') || isDisableEditing(arrayObject[0].adjustment.bu, arrayObject[0].adjustment.activity_type, arrayObject[0].adjustment.engine_group);
    let isDisableAdjLevel = (selectedVal==='Zone>Type'?adjDisable:((!content && adjlevel == ADJLEVEL.ZONE) || (content && adjlevel == ADJLEVEL.BU)))
    let yearsInPlanMonths = calculateNumberOfMonthInEachYear(arrayObject);
    return <span className='displayflexstyle yearcomponent'>
        <ScrollSyncPane group="horizontal">
            <span className='displayflexstyle yearcomponent componentWidth'>
                {yearsInPlanMonths[0].map((obj, ix) => {
                    return <EditableAllCellsInAYearComponent year={obj.year}
                        startMonth={obj.startMonth}
                        numPlanMonths={obj.numOfMonthsInYear}
                        data={allDataFilteredByYear(obj.year)}
                        isExpanded={isExpanded}
                        editing={editing}
                        editableArrayObject={editableArray}
                        arrayObject={arrayObject}
                        content={content}
                        planStatus={planStatus}
                        handleUpdatedCellMonthANdYear={handleUpdatedCellMonthANdYear}
                        updatedCellMonthAndYearArr={updatedCellMonthAndYearArr}
                        checkForErrors={checkForErrors}
                        filterParams={filterParams}
                        zonename={zonename}
                        accordionContent={accordionContent}
                        filterRecord={filterRecord}
                        currentHeader={currentHeader}>
                    </EditableAllCellsInAYearComponent>
                })}
            </span>
        </ScrollSyncPane>
        <span className='editiconmargin'>
            {((JSON.stringify(editableArray) == JSON.stringify(arrayObject)) && editing) ?
                <div className="adjustmentsSaveBtn">
                    <Button className={errorExist?'disableSave':'adjustmentsSavetext'} onClick={handleSaveAdjustments} disabled={errorExist}>Save</Button>
                </div>:
                <Button onClick={(e) => updateActivityLevelAdjustmentRow(e,arrayObject)} disabled={(((isDisableAdjLevel) || (editableArray.length != 0 && (JSON.stringify(editableArray) != JSON.stringify(arrayObject)))) || isDisable || pending)}
                 className={((isDisableAdjLevel) || (editableArray.length != 0 && (JSON.stringify(editableArray) !== JSON.stringify(arrayObject)))) || isDisable ? 'disableEdit' : ''}>
                <img src={EditIcon} className='activityediticon'></img>
                </Button>
            }
        </span>
    </span>
}

export default EditableRowComponent;
