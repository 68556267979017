import addMoveCostFields from '../../data/movecostColumns';
import {FETCH_MOVECOST_DATA_PENDING, FETCH_MOVECOST_DATA_SUCCESS, FETCH_MOVECOST_DATA_ERROR, UPLOAD_MOVECOST_PENDING, UPLOAD_MOVECOST_SUCCESS, UPLOAD_MOVECOST_ERROR,
    SAVE_MOVECOST_TRANSACTION_PENDING, SAVE_MOVECOST_TRANSACTION_SUCCESS, SAVE_MOVECOST_TRANSACTION_ERROR,RESET_TO_MOVECOST_INITIALSTATE, FETCH_MOVECOST_ERROR_DATA_SUCCESS, SET_ASSUMPTION_MOVE_COST_PAGE_VALUE, SET_ASSUMPTION_MOVE_COST_UPLOAD_STATUS, SET_ASSUMPTION_MOVE_COST_ERRORVIEW_STATUS,FETCH_MOVECOST_FILTER_DISPLAY_DATA, SET_MOVE_COST_FILTER_PARAMS, SET_MOVE_COST_FILTER_RECORDS, SET_MOVE_COST_FILTER_VIEW, SET_MOVE_COST_FILTER_SEARCH_VIEW, SET_MOVE_COST_SEARCH_RECORDS, SET_MOVE_COST_SEARCH_TEXT,
    FETCH_MOVECOST_DELETE_PENDING,FETCH_MOVECOST_DELETE_SUCCESS,FETCH_MOVECOST_DELETE_ERROR, FETCH_MOVECOST_ERROR_IDS_SUCCESS
    } from '../../store/movecost/MoveCost.ActionTypes';

const initialState = {
    moveCostData:[],   
    moveCostErrorData:[],
    moveCostFilterData:[],
    movecostTotalRowCount:0,
    movecostGrandTotalRowCount:0,//All rows for the plan ID
    movecostpageno:1,
    moveCostUploadStatus:null,
    moveCostErrorView:false,
    moveCostFilterView:false,
    moveCostFilterSearchView:false,
    moveCostFilterSearchText:null,
    pending:false,
    error:null,
    uploadMoveCostSuccess:null,
    transactionSaveSuccess:null,
    filterDisplayData:[],
    filterSelectedData:null,
    moveCostColumnsConstantData:null,
    deleteMoveCostRecordsSuccess:null,
    uniqueErrorIDs:null
}

function fetchZones(moveCostData, zones) {
    if(moveCostData && moveCostData.length>0){
    moveCostData.forEach((value) => {
        zones.forEach((zone) => {
            if (zone.code == value.FROM_ZONE) {
                value['FROM_ZONE'] = zone.name;
            }else if (zone.code == value.TO_ZONE) {
                value['TO_ZONE'] = zone.name;
            } 
        });
    })
}
    return moveCostData;
}



function fetchDropDownValues(dispFilterData, element) {
    if (element.colDef === "from_zone") {
        dispFilterData.zones.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            let zcode=dispFilterData.zoneCodes.filter(zname=>zname.name==val)
            obj['value'] = zcode[0].code;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "to_zone") {
        dispFilterData.zones.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            let zcode=dispFilterData.zoneCodes.filter(zname=>zname.name==val)
            obj['value'] =zcode[0].code;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "from_pool") {
        dispFilterData.pools.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    }else if (element.colDef === "to_pool") {
        dispFilterData.pools.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "car_class") {
        dispFilterData.car_class.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "car_group") {
        dispFilterData.car_group_name.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef === "allowed") {
        let values=[0,1]
        values.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    } else if (element.colDef == "bu") {
        dispFilterData.bu.map((val) => {
            let obj = {};
            if (val !== undefined && val!==null) {
            obj['label'] = val;
            obj['value'] = val;
            element.options.push(obj);
            }
        });
        element.options = element.options.sort(function (a, b) {
            if (a.label > b.label) { return -1; }
            if (a.label < b.label) { return 1; }
            return 0;
        })
        let objForNullValue={};
        objForNullValue['label'] = 'Blanks';
        objForNullValue['value'] = 'Blanks';
        element.options.push(objForNullValue);
    }
    return element;
}


function fetchFilterValues(addMoveCostFields, data) {
    var movecostfilterdisplayData = data;
    var filterData = addMoveCostFields().data;
    filterData.forEach(element => {
        if (element.type == "dropdown")
            fetchDropDownValues(movecostfilterdisplayData, element);
    });
    return filterData;
}

export default function MoveCostReducer (state=initialState,action){
    switch(action.type){
        case FETCH_MOVECOST_DATA_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_MOVECOST_DATA_SUCCESS:
            return Object.assign({},state,{
                movecostTotalRowCount:action.data.rowcount,
                movecostGrandTotalRowCount: action.data.totrowcount,
                moveCostData: fetchZones(action.data.moveCostData, action.data.zones),
                uploadMoveCostSuccess: null,
                deleteMoveCostRecordsSuccess:null,
                pending:false,
                error:null
            });
        case FETCH_MOVECOST_ERROR_DATA_SUCCESS:
                return Object.assign({},state,{
                    movecostTotalRowCount:state.uniqueErrorIDs.length,
                    movecostGrandTotalRowCount: action.data.totrowcount,
                    moveCostData:[],
                    moveCostErrorData: fetchZones(action.data.moveCostErrorData, action.data.zones),
                    uploadMoveCostSuccess: null,
                    deleteMoveCostRecordsSuccess:null,
                    pending:false,
                    error:null
                });
        case FETCH_MOVECOST_ERROR_IDS_SUCCESS:
            return Object.assign({},state,{
                uniqueErrorIDs:action.data.uniqueErrorIDs
            });
        case FETCH_MOVECOST_DATA_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error
            });
        case UPLOAD_MOVECOST_PENDING:
            return Object.assign({}, state, {
                pending: true,
                error:null,
                uploadMoveCostSuccess: null,
                deleteMoveCostRecordsSuccess:null
            });
        case UPLOAD_MOVECOST_SUCCESS:
            return Object.assign({}, state, {
                moveCostData: fetchZones(action.data.moveCostData, action.data.zones),
                pending: false,
                error: null,
                uploadMoveCostSuccess: true,
                deleteMoveCostRecordsSuccess:null
            });
        case UPLOAD_MOVECOST_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                uploadMoveCostSuccess: false
            });
        case SET_ASSUMPTION_MOVE_COST_PAGE_VALUE:
                    return Object.assign({}, state, {
                        movecostpageno:action.data
                    });
        case SET_ASSUMPTION_MOVE_COST_UPLOAD_STATUS:
                        return Object.assign({}, state, {
                        moveCostUploadStatus:action.data
                        });
        case SET_ASSUMPTION_MOVE_COST_ERRORVIEW_STATUS:
                        return Object.assign({}, state, {
                            moveCostErrorView:action.data
                        });
        case SAVE_MOVECOST_TRANSACTION_PENDING:
            return Object.assign({}, state, {
                pending: true,
                transactionSaveSuccess: null,
                deleteMoveCostRecordsSuccess:null,
            });
        case SAVE_MOVECOST_TRANSACTION_SUCCESS:
            let saveMoveCostData=[],saveMoveCostErrorData=[],saveMoveCostFilterData=[]
            if(action.data.movecostfilterview || action.data.movecostfiltersearchview ){
                saveMoveCostFilterData=fetchZones(action.data.moveCostData.moveCostFilterData,action.data.moveCostData.zones)
            } else{
            if(action.data.movecostview){
                saveMoveCostErrorData=fetchZones(action.data.moveCostData.moveCostErrorData, action.data.moveCostData.zones)
            } else{
                saveMoveCostData =fetchZones(action.data.moveCostData.moveCostData, action.data.moveCostData.zones)
            }
             }
            return Object.assign({}, state, {
                pending: false,
                moveCostData:saveMoveCostData,
                moveCostErrorData:saveMoveCostErrorData,
                moveCostFilterData:saveMoveCostFilterData,
                transactionSaveSuccess: true,
                deleteMoveCostRecordsSuccess:null
            });
        case SAVE_MOVECOST_TRANSACTION_ERROR:
            return Object.assign({}, state, {
                pending: false,
                error: action.error,
                transactionSaveSuccess: false,
                deleteMoveCostRecordsSuccess:null
            });
        case FETCH_MOVECOST_FILTER_DISPLAY_DATA:
                var actionData = fetchFilterValues(addMoveCostFields, action.data);
                return Object.assign({}, state, {
                    filterDisplayData: actionData,
                    moveCostColumnsConstantData:action.data,
                    deleteMoveCostRecordsSuccess:null
                });
        case SET_MOVE_COST_FILTER_PARAMS:
            return Object.assign({}, state, {
                filterSelectedData: action.data
            });
        case SET_MOVE_COST_FILTER_VIEW:
                return Object.assign({}, state, {
                    moveCostFilterView: action.data
                });
         case SET_MOVE_COST_FILTER_SEARCH_VIEW:
            return Object.assign({}, state, {
                    moveCostFilterSearchView: action.data
                });
        case SET_MOVE_COST_SEARCH_TEXT:
            return Object.assign({}, state, {
                    moveCostFilterSearchText: action.data
                });
        case SET_MOVE_COST_FILTER_RECORDS:
                return Object.assign({}, state, {
                    movecostTotalRowCount:action.data.rowcount,
                    movecostGrandTotalRowCount: action.data.totrowcount,
                    moveCostFilterData: fetchZones(action.data.moveCostFilterData, action.data.zones),
                    uploadMoveCostSuccess: null,
                    deleteMoveCostRecordsSuccess:null,
                    pending:false,
                    error:null
                });
        case SET_MOVE_COST_SEARCH_RECORDS:
            return Object.assign({}, state, {
                movecostTotalRowCount:action.data.rowcount,
                movecostGrandTotalRowCount: action.data.totrowcount,
                moveCostFilterData:[],
                moveCostFilterData: fetchZones(action.data.moveCostFilterData, action.data.zones),
                uploadMoveCostSuccess: null,
                deleteMoveCostRecordsSuccess:null,
                pending:false,
                error:null
            });
        case FETCH_MOVECOST_DELETE_PENDING:
            return Object.assign({},state,{
                pending:true,
                error:null
            });
        case FETCH_MOVECOST_DELETE_SUCCESS:
            let deletedMoveCostData=[],deletedMoveCostFilterData=[];
            if(action.data.operationApplied==='filter' || action.data.operationApplied==='search' ){
                deletedMoveCostFilterData=fetchZones(action.data.moveCostData.moveCostFilterData,action.data.moveCostData.zones)
            }else if(action.data.operationApplied==='errordata') {
                deletedMoveCostData=[];
            }
            else{
                deletedMoveCostData =fetchZones(action.data.moveCostData.moveCostData, action.data.moveCostData.zones)
            }
            return Object.assign({},state,{
                pending: false,
                moveCostData:deletedMoveCostData,
                moveCostFilterData:deletedMoveCostFilterData,
                deleteMoveCostRecordsSuccess:true,
                movecostTotalRowCount:action.data.rowcount,
                uploadMoveCostSuccess: null,
                movecostpageno:1,
                pending:false,
                error:null
            });
        case FETCH_MOVECOST_DELETE_ERROR:
            return Object.assign({},state,{
                pending:false,
                error:action.error,
                deleteMoveCostRecordsSuccess:false
            });
        case RESET_TO_MOVECOST_INITIALSTATE:
            return initialState;
            default:
                return state;
    }
}