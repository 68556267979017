import React from 'react';
import ActionBar from '../../components/moveconstraintsactionbar/MoveConstraintsActionBar';
import MultiGridUI from '../../components/grid/MultiGridUI';
import MainPlanInputsMultiGridUI from '../../components/grid/MainPlanInputsMultiGridUI';

const MoveConstraintsTabPanel = (props) => {
    const { moveConstraintsData, handleSwitchView, currentView, showSaveOption, gridReducer,
        gridColumnDef, tabInfo, selectedval, handleSaveCancelDisabled, downloadCSV,
        refreshCells, handleRowEditingStarted, handleRowEditingStopped, handleCellEditingStopped,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, tabIndex, selectedPlanId, deleteSelectedRows,
        bulkRowDeleteIconDisabled, handleMultipleRowDelete, scrollToTop, transactions, currentData, filterSearchKey, userMessage, newArchitecture, gridName, pending,loadGridData} = props;

    return (
        <div>
            {newArchitecture ? (<MainPlanInputsMultiGridUI 
               reducer={gridReducer}
                gridName={gridName}
                //currentView={currentView}
                selectedPlanId={selectedPlanId}
                refreshCells={refreshCells}
                onRowEditingStarted={handleRowEditingStarted}
                onRowEditingStopped={handleRowEditingStopped}
                onCellEditingStopped={handleCellEditingStopped}
                gridColDef={gridColumnDef}
                rowEditType={'single'}
                suppressclickEdit={!gridColumnDef.defaultColDef.editable||pending}
                handleMultipleRowDelete={handleMultipleRowDelete}
                scrollToTop={scrollToTop}
                userMessage={userMessage} 
                loadGridData={loadGridData}
                isPaginationRequired={true}/>) :
                (<><ActionBar
                    constraintsData={moveConstraintsData}
                    onViewChanged={handleSwitchView} actionView={currentView}
                    options={showSaveOption}
                    handleSaveCancelDisabled={handleSaveCancelDisabled}
                    onSaveClicked={handleSaveClicked}
                    onCancelClicked={handleCancelClicked}
                    onAddNewRowClick={handleAddNewRow}
                    selectedPlanId={selectedPlanId}
                    bulkRowDeleteIconDisabled={bulkRowDeleteIconDisabled}
                    deleteSelectedRows={deleteSelectedRows}
                    downloadCSV={downloadCSV}
                    data={gridReducer.currentData}
                    constraintsGridColDef={gridColumnDef}
                    filterSearchKey={filterSearchKey}
                    //filterParams={filterParams}
                    transactions={transactions}
                />
                    {(currentView === "grid") ?
                        (
                            <MultiGridUI reducer={gridReducer}
                                gridName={"moveconstraintsgrid"}
                                currentView={currentView}
                                refreshCells={refreshCells}
                                onRowEditingStarted={handleRowEditingStarted}
                                onRowEditingStopped={handleRowEditingStopped}
                                onCellEditingStopped={handleCellEditingStopped}
                                gridColDef={gridColumnDef}
                                rowEditType={'single'}
                                suppressclickEdit={!gridColumnDef.defaultColDef.editable}
                                handleMultipleRowDelete={handleMultipleRowDelete}
                                scrollToTop={scrollToTop}
                                userMessage={userMessage} />
                        ) : ""}
                </>)}

        </div>
    )
}

export default MoveConstraintsTabPanel;