import React from 'react';
import AdminMultiGridUI from '../../../components/admin/grid/AdminMultiGridUI';
import { ZONEMAPGRID } from '../../../Constants';

const ZoneMapTabPanel = (props)=>{
    const{showSaveOption,zoneMapgridReducer,zoneMapTransactions,zoneMapGridColDef, ageMileageData, pending, gridReducer, planAssumptionsGrid, handleSwitchView, currentView,
        handleAddNewRow, handleSaveClicked, handleCancelClicked, handleRowEditingStarted, handleRowEditingStopped, 
        selectedPlanId, refreshCells, handleSaveCancelDisabled, handleCellEditingStopped,
        ageMileageGridColDef, deleteSelectedRows, bulkRowDeleteIconDisabled, handleMultipleRowDelete, selectedRows,
        ageMileageTransactions, currentData,filteredData, filterParams,tabIndex, downloadCSV,
        userMessage,handleAddNewDropdownValue} = props;

 return(
     <div>
         <AdminMultiGridUI 
            reducer={zoneMapgridReducer}
            currentView={currentView}
            refreshCells={refreshCells}
            gridName={ZONEMAPGRID}
            rowEditType={'single'}
            onRowEditingStarted={handleRowEditingStarted}
            onRowEditingStopped={handleRowEditingStopped}
            onCellEditingStopped={handleCellEditingStopped}
            gridColDef={zoneMapGridColDef}
            suppressclickEdit={!zoneMapGridColDef.defaultColDef.editable}
            handleMultipleRowDelete={handleMultipleRowDelete} 
            zoneMapTransactions={zoneMapTransactions}
            userMessage={userMessage}
            handleAddNewDropdownValue={handleAddNewDropdownValue}
             />
     </div>
 )
}

export default ZoneMapTabPanel;