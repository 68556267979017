import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

export const NttDropdown = ({
  selectAutoWidth,
  children,
  selectLabelId,
  selectId,
  selectValue,
  selectOnchange,
  selectVariant,
  selectInputProps,
  selectDisplayDefaultValue,
  selectDisplayEmpty,
  selectCustomInput,
  selectRenderValue,
  selectClassName,
  inputLabel,
  inputLabelId,
  formControlClassName,
  selectDisabled,
  selectMultiple,
  selectMenuProps,
  selectNative,
}) => {
  const InputLabelValue =
    inputLabel !== "" ? (
      <InputLabel id={inputLabelId}>{inputLabel}</InputLabel>
    ) : null;
  return (
    <FormControl className={formControlClassName} disabled={selectDisabled}>
      {InputLabelValue}
      <Select 
        autoWidth={selectAutoWidth}
        labelId={selectLabelId}
        id={selectId}
        value={selectValue}
        onChange={selectOnchange}
        variant={selectVariant}
        inputProps={selectInputProps}
        displayEmpty={selectDisplayEmpty}
        defaultValue={selectDisplayDefaultValue}
        renderValue={selectRenderValue}
        input={selectCustomInput}
        multiple={selectMultiple}
        MenuProps={selectMenuProps}
        className={selectClassName}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

NttDropdown.propTypes = {
  selectAutoWidth: PropTypes.bool,
  selectLabelId: PropTypes.string,
  selectId: PropTypes.string,
  selectValue: PropTypes.any,
  selectOnchange: PropTypes.func,
  selectVariant: PropTypes.string,
  selectInputProps: PropTypes.any,
  selectDisplayEmpty: PropTypes.bool,
  inputLabel: PropTypes.string,
  formControlClassName: PropTypes.any,
  selectDisabled: PropTypes.bool,
  selectNative: PropTypes.bool,
  selectCustomInput: PropTypes.any,
  selectMultiple: PropTypes.bool,
  selectMenuProps: PropTypes.object,
};

NttDropdown.defaultProps = {
  selectAutoWidth: false,
  selectDisplayEmpty: false,
  inputLabel: "",
  selectDisabled: false,
  selectNative: false,
  selectMultiple: false,
};

export const NttDropdownMenuItem = React.forwardRef(
  ({ selectValue, children }, ref) => {
    return (
      <MenuItem ref={ref} value={selectValue}>
        {children}
      </MenuItem>
    );
  }
);

export const NttDropdownNativeOption = React.forwardRef(
  ({ selectValue, children }, ref) => {
    return (
      <option ref={ref} value={selectValue}>
        {children}
      </option>
    );
  }
);
