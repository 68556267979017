export const SET_GRID_NAME='SET_GRID_NAME';

export const FETCH_GRID_DATA_PENDING = 'FETCH_GRID_DATA_PENDING';
export const FETCH_GRID_DATA_SUCCESS = 'FETCH_GRID_DATA_SUCCESS';
export const FETCH_GRID_DATA_ERROR = 'FETCH_GRID_DATA_ERROR';

export const SET_GRID_DATA_UPLOAD_STATUS='SET_GRID_DATA_UPLOAD_STATUS';
export const UPLOAD_GRID_DATA_PENDING = 'UPLOAD_GRID_DATA_PENDING';
export const UPLOAD_GRID_DATA_SUCCESS = 'UPLOAD_GRID_DATA_SUCCESS';
export const UPLOAD_GRID_DATA_ERROR = 'UPLOAD_GRID_DATA_ERROR';

export const SAVE_GRID_DATA_TRANSACTION_PENDING = 'SAVE_GRID_DATA_TRANSACTION_PENDING';
export const SAVE_GRID_DATA_TRANSACTION_SUCCESS = 'SAVE_GRID_DATA_TRANSACTION_SUCCESS';
export const SAVE_GRID_DATA_TRANSACTION_ERROR = 'SAVE_GRID_DATA_TRANSACTION_ERROR';

export const DELETE_ALL_GRID_DATA_PENDING = 'DELETE_ALL_GRID_DATA_PENDING';
export const DELETE_ALL_GRID_DATA_SUCCESS = 'DELETE_ALL_GRID_DATA_SUCCESS';
export const DELETE_ALL_GRID_DATA_ERROR = 'DELETE_ALL_GRID_DATA_ERROR';

export const SET_GRID_TOTAL_ROW_VALUE='SET_GRID_TOTAL_ROW_VALUE';
export const SET_GRID_DATA_USER_PAGE_VALUE='SET_GRID_DATA_USER_PAGE_VALUE';
export const SET_GRID_DATA_ERRORVIEW_STATUS='SET_GRID_DATA_ERRORVIEW_STATUS';
export const SET_GRID_DATA_FILTER_VIEW_STATUS='SET_GRID_DATA_FILTER_VIEW_STATUS'


export const FETCH_GRID_DATA_FILTER_DISPLAY_DATA='FETCH_GRID_DATA_FILTER_DISPLAY_DATA';
export const FETCH_GRID_DATA_USER_FILTER_PARAMS='FETCH_GRID_DATA_USER_FILTER_PARAMS';
export const FETCH_GRID_FILTER_DATA_SUCCESS='FETCH_GRID_FILTER_DATA_SUCCESS';

export const FETCH_GRID_SEARCH_DATA_SUCCESS='FETCH_GRID_SEARCH_DATA_SUCCESS';
export const SET_GRID_DATA_USER_SEARCH_TEXT='SET_GRID_DATA_USER_SEARCH_TEXT';

export const FETCH_GRID_DATA_DELETE_PENDING = 'FETCH_GRID_DATA_DELETE_PENDING';
export const FETCH_GRID_DATA_DELETE_SUCCESS = 'FETCH_GRID_DATA_DELETE_SUCCESS';
export const FETCH_GRID_DATA_DELETE_ERROR = 'FETCH_GRID_DATA_DELETE_ERROR';

export const RESET_TO_GRID_DATA_INITIALSTATE = 'RESET_TO_GRID_INITIALSTATE';

export const SET_TABS_VALIDATION_STATUS = 'SET_TABS_VALIDATION_STATUS';
export const RESET_TABS_VALIDATION_STATUS = 'RESET_TABS_VALIDATION_STATUS';

export const SET_VALIDATE_GRIDDATA_PENDING = "SET_VALIDATE_GRIDDATA_PENDING";
export const SET_VALIDATE_GRIDDATA_ERROR = "SET_VALIDATE_GRIDDATA_ERROR";
export const SET_VALIDATE_GRIDDATA_SUCCESS ="SET_VALIDATE_GRIDDATA_SUCCESS";

export const FETCH_GRID_TAB_STATUS_PENDING = "FETCH_GRID_TAB_STATUS_PENDING"
export const FETCH_GRID_TAB_STATUS_SUCCESS = "FETCH_GRID_TAB_STATUS_SUCCESS"
export const FETCH_GRID_TAB_STATUS_ERROR = "FETCH_GRID_TAB_STATUS_ERROR"

export const SET_GRID_FILTER_PARAMS_OPTIONS_PENDING = "SET_GRID_FILTER_PARAMS_OPTIONS_PENDING"
export const SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS = "SET_GRID_FILTER_PARAMS_OPTIONS_SUCCESS"
export const SET_GRID_FILTER_PARAMS_OPTIONS_ERROR = "SET_GRID_FILTER_PARAMS_OPTIONS_ERROR"

export const FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_PENDING = "FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_PENDING"
export const FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_SUCCESS = "FETCH_ALL_DIMESNSIONS_IF_MASTERDATA_UPDATED_GRID_SUCCESS"
export const FETCH_ALL_DIMENSIONS_DATA_ERROR = "FETCH_ALL_DIMENSIONS_DATA_ERROR"

export const SET_GRID_DATA_ERROR_VIEW_STATUS = "SET_GRID_DATA_ERROR_VIEW_STATUS"

export const SET_GRID_SEARCH_TEXT_SUCCESS = "SET_GRID_SEARCH_TEXT_SUCCESS";
export const SET_GRID_DATA_SEARCH_VIEW_STATUS = "SET_GRID_DATA_SEARCH_VIEW_STATUS";

export const FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_PENDING = "FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_PENDING";
export const FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_SUCCESS = "FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_SUCCESS";
export const FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_ERROR = "FETCH_GRID_TOP_LEVEL_FILTER_OPTIONS_ERROR";