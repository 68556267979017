import { combineReducers } from 'redux';
import schedulesReducer from './Schedules.Reducer';
import multiGridUIReducer from '../MultiGrid/MultiGrid.Reducer';
import {createNamedWrapperReducer} from '../util';
import schedulesColumnDefinitionReducer from "./Schedules.ColumnDef.Reducer";

const rootSchedulesReducer = combineReducers({
      schedulesReducer
     , schedulesGridReducer: createNamedWrapperReducer(multiGridUIReducer, "schedulesgrid")
    , schedulesColumnDefinitionReducer
})

export default rootSchedulesReducer;