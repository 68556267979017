import {
    FETCH_TEMPLATES_DATA_PENDING, FETCH_TEMPLATES_DATA_SUCCESS, FETCH_TEMPLATES_DATA_ERROR,
    FILTER_SEARCH_TEMPLATE_DATA,SAVE_TEMPLATE_TRANSACTION_PENDING, SAVE_TEMPLATE_TRANSACTION_SUCCESS, SAVE_TEMPLATE_TRANSACTION_ERROR
}
    from "../../store/upgradepaths/UpgradeTemplates.ActionTypes";

const initialstate = {
    pending: false,
    templates: [],
    error: null,
    templateFilterSearchKey: '',
    upgradePathSearchKey: '',
    templateFilterSearchFlag: false,
    upgradePathFilterSearchFlag: false,
    templateFilterSearchData: [],
    upgradePathFilterSearcData: [],
    upgradePathData: [],
    upgradePathGroupedData: [],
    transactionSaveSuccess:null

}

function fetchSearchValues(searchparameters, templateData) {
    const { searchkey, upgradeButtonSelected } = searchparameters;
    let results = getSearchValues(searchkey, templateData);
    return results;
}
function getSearchValues(searchkey, templateData) {
    return templateData.filter((item, i) => {
        let itemkeys = Object.keys(templateData[i]);
        let entries = Object.entries(templateData[i]);
        return entries.find((val, ind) => {
            if (val[1] !== null && val[1] != '' && val[1] !== undefined) {
                return (val[1].toString().toLowerCase().includes(searchkey.toLowerCase()))
            }
        })
    })
}

export default function templatesReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_TEMPLATES_DATA_PENDING:
            return Object.assign({}, state, {
                pending: true
            });
        case FETCH_TEMPLATES_DATA_SUCCESS:
            return Object.assign({}, state, {
                templates: action.data,
                templateFilterSearchKey:"",
                templateFilterSearchFlag: false,
                pending: false
            });
        case FETCH_TEMPLATES_DATA_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                pending:false
            });
        case FILTER_SEARCH_TEMPLATE_DATA:
            var searchData = fetchSearchValues(action.data,state.templates);
            return Object.assign({}, state, {
                templateFilterSearchKey: action.data.searchkey,
                templateFilterSearchFlag: true,
                templateFilterSearchData: searchData
            });
            case SAVE_TEMPLATE_TRANSACTION_PENDING:
                return Object.assign({}, state, {
                    pending: true,
                    transactionSaveSuccess: null
                });
            case SAVE_TEMPLATE_TRANSACTION_SUCCESS:
                return Object.assign({}, state, {
                    pending: false,
                    templates: action.data,
                    transactionSaveSuccess: true
                });
            case SAVE_TEMPLATE_TRANSACTION_ERROR:
                return Object.assign({}, state, {
                    pending: false,
                    error: action.error,
                    transactionSaveSuccess: false
                });
        default:
            return state;
    }

}