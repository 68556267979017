import SwaggerClient from 'swagger-client';
import SwaggerSpec from '../../swagger.json';
import { getAccessTokenAfterAuth, getAccessTokenNUserDetailsAfterAuth } from '../../utils/authUtils';
import { checkAndLoadDimention, minifyTransaction } from '../../utils/utils';
import { SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP } from '../../Constants';

export async function getAllAllocatedData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseallocatedResponse = await client.apis.plans.getAllocated({ "planid": planId ? planId : 'master', 'pageno': pageno ? pageno:0 });
        const responsesArray = await Promise.all([promiseallocatedResponse]);
        const [allocatedResponse] = responsesArray.map(o => o.body.data);
        // return allocatedResponse.body.data;
        // this if condition is true when it is new version
        if (JSON.parse(localStorage.getItem("newArchitecture"))) {
            return ({
                "gridData": allocatedResponse.rows, "rowcount": allocatedResponse.rowCount
            });
        }
        else {
            //this else condition executes when it is older version
            return allocatedResponse;
        }
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the data for Allocated tab'
        //return { 'Error': 'fetchAllocated failed' }
    }
}

export async function getAllocatedFilterData(planId,pageno,filterparams){
    try{
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});
        const promiseAllocatedFilterResponse = await client.apis.plans.getAllocatedFilterRecords({'pageno':pageno},{requestBody:{"planid":planId?planId:'master','filterparams':filterparams}});
        const responsesArray = await Promise.all([promiseAllocatedFilterResponse]);
        const [allocatedResponse] = responsesArray.map(o => o.body.data);
        return({
            "gridData": allocatedResponse.rows,"rowcount":allocatedResponse.rows[0]?allocatedResponse.rows[0].rowcount:0
        })
    }
    catch(error){
        console.error(error);
        throw 'Allocated tab has internal server error, please do re-apply the filter'
        //return { 'Error': 'getAllocatedFilterData failed' }
    }
}

export async function saveAllocatedTransactions(planid, transactionList) {
    const userNToken=await getAccessTokenNUserDetailsAfterAuth();

    const accessToken=userNToken.accessToken;
    const userEmail = userNToken.email;

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    let promiseArray = [];
    await client.apis.plans.bulktransactallocated({ "planid": planid ? planid : "master" }, { requestBody: { "updatedBy": userEmail, "transactions": minifyTransaction(transactionList) } });

    const responsesArray = await Promise.all(promiseArray);

    return responsesArray;
}

export async function deleteAllAllocatedData(planid,params){
    try{
            const userNToken=await getAccessTokenNUserDetailsAfterAuth();
            const accessToken=userNToken.accessToken;
            const userEmail = userNToken.email;
            const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });            
            let promiseallocatedDeleteResponse = await client.apis.plans.deleteAllAllocatedRecords({ "planid": planid ? planid : "master"},{requestBody: {"updatedBy":userEmail,...params}});
            
            if(promiseallocatedDeleteResponse.obj && promiseallocatedDeleteResponse.obj.statusCode !== '500'){
                return promiseallocatedDeleteResponse;
            }
            else{
                throw Error();
            }  
        } 
        catch (error) {
            console.error(error);
            throw 'Allocated tab has internal server error, Unable to delete all the records!! Please try again'
        }
}

export async function fetchAllocatedDropdownService() {
    const accessToken = await getAccessTokenAfterAuth();

    const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });

    const promiseGetAllModelGroups =  checkAndLoadDimention("modelGroup", client);
    const promiseGetCarClasses =  checkAndLoadDimention("carClassCode", client);
    const promisePoolResponse =  checkAndLoadDimention("pool", client);
    const promiseGetManufacturerGroup =  checkAndLoadDimention("manufacturer", client);
    const promiseSPICodeResponse = client.apis.dimensions.getAllSPICodes();
    const promiseAllManufacturergroupModelgroupResponse =  checkAndLoadDimention("AllManufacturergroupModelgroup", client);
    const promiseAdminPoolResponse =  checkAndLoadDimention("adminpool", client);
    const promiseGetAllAdminModelGroups =  checkAndLoadDimention("adminModelGroup", client);
    const promiseGetAdminCarClasses =  checkAndLoadDimention("admincarclassAndgroup", client);
    const promiseGetAdminManufacturerGroup =  checkAndLoadDimention("adminManufacturer", client);
    const promiseUserManagementEmailAndIdMap = await checkAndLoadDimention(SESSION_STORAGE_ITEM_NAME_FOR_EMAIL_NAME_MAP,client);

    const responsesArray = await Promise.all([promiseGetAllModelGroups
        , promiseGetCarClasses
        , promisePoolResponse
        , promiseGetManufacturerGroup
        , promiseSPICodeResponse
        , promiseAllManufacturergroupModelgroupResponse
        , promiseAdminPoolResponse
        , promiseGetAllAdminModelGroups
        , promiseGetAdminCarClasses
        , promiseGetAdminManufacturerGroup
    ]);

    const [modelgroups
        , carclasses
        , pool
        , manufacturerGroups
        , spicodes
        , allManufacturergroupModelgroupResponse
        , adminPoolResponse
        , adminModelgroups
        , adminCarClasses
        , adminManufacturerGroups
    ] = responsesArray.map(o => o.body.data);

    return ({
        "manufacturer_group": manufacturerGroups,
        "spiCode": spicodes, "carClassCode": carclasses,
        "modelGroup": modelgroups, "pool": pool,
        "allManufacturergroup_Modelgroup":allManufacturergroupModelgroupResponse,
        "adminpools":adminPoolResponse,
        "adminModelGroup":adminModelgroups,
        "adminCarClass":adminCarClasses,
        "adminManufacturerGroup":adminManufacturerGroups,
        "users":promiseUserManagementEmailAndIdMap
    })

}


export async function uploadAllocatedCSV(planid,file){
    try {
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.uploadAllocated({"planid":planid?planid:'master'},{
            requestBody: {
                "updatedBy":userEmail,
                "file":file
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Allocated tab, Please try again!!');
    }
}

export async function insertS3UploadAllocatedCSV(planid,filename,gridname){
    try {   
        const userNToken=await getAccessTokenNUserDetailsAfterAuth();

        const accessToken=userNToken.accessToken;
        const userEmail = userNToken.email;

        const client = await SwaggerClient({spec: SwaggerSpec,authorizations: { xfleet_auth: { token: { access_token: accessToken } } }});

        const response = await client.apis.plans.insertS3UploadedAllocatedData({"planid":planid?planid:1},{
            requestBody: {
                "updatedBy":userEmail,
                "gridName":gridname.replace(/grid/gi,''),
                "fileName":filename
            }
        });

        return response;
    } catch (error) {
        console.error(error);
        throw Error('Unable to upload the data for Allocated tab, Please try again!!');
    }
}

export async function allocatedTabValidationService(planid, transactionList) {
    try {
        const userNToken = await getAccessTokenNUserDetailsAfterAuth();
        const accessToken = userNToken.accessToken;
        const userEmail = userNToken.email;
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const response = await client.apis.plans.validateAllocated({ "planid": planid ? planid : 1 }, {
            requestBody: {
                "updatedBy": userEmail,
                "transactions": minifyTransaction(transactionList)
            }
        });
        if (response.obj.statusCode === '500') {
            throw new Error((JSON.parse(response.obj.body)).error);
        }
        return { "validation_result": response.body.data.rows[0].validation_result, "isInvalid": response.body.data.rows[0].isinvalid !== undefined ? response.body.data.rows[0].isinvalid : undefined };
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Allocated Validation status ,Please try again!!';
        //return { 'Error': 'allocatedTabValidationService failed' }
    }
}

export async function getAllocatedTabErrorStatus(planid, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();
        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAllocatedResponse = await client.apis.plans.getAllocatedTabErrorStatus({ "planid": planid ? planid : 1 });

        if (promiseAllocatedResponse.obj && promiseAllocatedResponse.obj.statusCode !== '500') {
        const responsesArray = await Promise.all([promiseAllocatedResponse]);
        const [allocatedTabErrorStatusResponse] = responsesArray.map(o => o.body.data);
        return allocatedTabErrorStatusResponse;
        }
        else {
            throw Error();
        }        
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch Allocated error status ,Please try again!!';
        // return { 'Error': 'getAllocatedTabErrorStatus failed' }
    }
}

export async function getAllocatedErrorData(planId, pageno) {
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseallocatedResponse = await client.apis.plans.getAllocatedErrorRecords({ "planid": planId ? planId : 1, 'pageno': pageno });
        const responsesArray = await Promise.all([promiseallocatedResponse]);
        const [allocatedResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": allocatedResponse.rows, "rowcount": allocatedResponse.rowCount
        });
    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the error data for Allocated tab'
        //return { 'Error': 'fetchAllocated failed' }
    }
}

export async function getAllocatedSearchData(planid,pageno,searchParams){
    try {
        const accessToken = await getAccessTokenAfterAuth();

        const client = await SwaggerClient({ spec: SwaggerSpec, authorizations: { xfleet_auth: { token: { access_token: accessToken } } } });
        const promiseAllocatedSearchResponse = client.apis.plans.getAllocatedSearchRecords({"planid": planid ? planid : 1, 'pageno': pageno }, { requestBody: { "searchParams": searchParams } });
        const responsesArray = await Promise.all([promiseAllocatedSearchResponse]);

        const [allocatedSearchResponse] = responsesArray.map(o => o.body.data);
        return ({
            "gridData": allocatedSearchResponse.rows, "rowcount": allocatedSearchResponse.rows[0] ? allocatedSearchResponse.rows[0].rowcount : 0
        });

    }
    catch (error) {
        console.error(error);
        throw 'Unable to fetch the search data for Allocated tab'
    }
}