import React, { useCallback, useState,useEffect,useRef } from 'react';
import SalesAndFinanceActionBar from '../../../components/salesandfinance/salesandfinanceactionbar/SalesAndFinanceActionBar';
import SalesAndFinanceMultiGridUI from '../../../components/salesandfinance/grid/SalesAndFinanceMultiGridUI';
import { useDispatch, useSelector } from 'react-redux';
import { addNotificationMessage } from '../../../store/topbar/AllPlans.Actions';
import { saveGridTransactionData } from '../../../store/MultiGridPaginationModel/PaginationGrid.Actions';
import { MessageNotificationValues } from '../../../Constants';

const BvDepOverrideTabPanel = (props) => {
   const{gridReducer,showSaveOption,pending,gridData,selectedPlanId,gridUserPageno,currentView,refreshCells,handleRowEditingStarted,handleRowEditingStopped,
      handleCellEditingStopped, handleMultipleRowDelete,bvDepOverrideTransactions,bvDepOverrideGridColDef,scrollToTop,userMessage,loadGridData,setShowSaveOption
   } = props;

   const isSaveClicked = useRef(null)
   const dispatch = useDispatch();

  const gridName = useSelector((state) => {
    return state.PaginationGridReducer.gridName
  })

  const masterDataUpdateState = useSelector((reduxstate) => {
    return reduxstate.rootAllPlansReducer.allPlansReducer.isMasterDataUpdated;
  });

  const gridFilterUserSelectedParams = useSelector((state) => {
    return {...state.PaginationGridReducer.gridFilterUserSelectedParams};
  });

  const gridFilterViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridFilterView};
  });

  const gridSearchViewStatus = useSelector((state)=>{
    return {...state.PaginationGridReducer.gridSearchView};
  })

  const gridUserSearchParams = useSelector((state) => {
    return {...state.PaginationGridReducer.gridUserSearchParams};
  });
  const gridErrorViewStatus = useSelector((state) => {
    return {...state.PaginationGridReducer.gridErrorView};
  });

  const downloadCSV = useCallback((csvStr, filename) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }, [])

const fetchInvalidTransactions = (transactions) => {
  return !!transactions.find(item => {
    return ((item && item.data) && (item.data.status == "ERROR_ROW" || item.data.status == "ERROR_COPY_ROW" ||
      item.data.status == "ERROR_NEW_ROW" || item.data.status == "INVALID_ROW" || item.data.status == "INVALID_NEW_ROW") ||
      (item && !item.data) && item.type == "add")
  })
}

useEffect(() => {
  if (!pending && !masterDataUpdateState && isSaveClicked.current === gridName) {
    const filterInValidTransactions = fetchInvalidTransactions(gridReducer.transactions);
    if ((!gridReducer.transactions || gridReducer.transactions.length == 0) && (isSaveClicked.current != null && isSaveClicked.current == gridName)) {
      dispatch(addNotificationMessage(MessageNotificationValues.Severity.warning, 'Nothing to be saved'))
      setShowSaveOption(false);
      isSaveClicked.current = null
    }
    if (!filterInValidTransactions && (isSaveClicked.current != null && isSaveClicked.current == gridName)) {
      dispatch(saveGridTransactionData(undefined, selectedPlanId, gridReducer.transactions, gridName, gridName, undefined, gridUserPageno, gridFilterUserSelectedParams[gridName], gridFilterViewStatus[gridName],gridErrorViewStatus[gridName],gridSearchViewStatus[gridName],gridUserSearchParams[gridName]));
      isSaveClicked.current = null
    }
    else if ((filterInValidTransactions && isSaveClicked.current == gridName)) {
      dispatch(addNotificationMessage(MessageNotificationValues.Severity.error, 'Cannot save. Please review if any row is invalid.'))
    }
  }
}, [masterDataUpdateState, pending]);

 return(
    <div>
    <SalesAndFinanceActionBar
      showOption={showSaveOption}
      pending={pending}
      selectedPlanId={selectedPlanId}
      gridUserPageno={gridUserPageno}
      gridReducer={gridReducer}
      salesAndFinanceInputsGridColDefReducer={bvDepOverrideGridColDef}
      gridData={gridData} 
      gridName={gridName}
      downloadCSV={downloadCSV}
      userMessage={userMessage}
      loadGridData={loadGridData}>
    </SalesAndFinanceActionBar>
    <SalesAndFinanceMultiGridUI
      reducer={gridReducer}
      gridName={gridName}
      selectedPlanId={selectedPlanId}
      refreshCells={refreshCells}
      onRowEditingStarted={handleRowEditingStarted}
      onRowEditingStopped={handleRowEditingStopped}
      onCellEditingStopped={handleCellEditingStopped}
      gridColDef={bvDepOverrideGridColDef} 
      rowEditType={'single'}
      suppressclickEdit={!bvDepOverrideGridColDef.defaultColDef.editable}
      handleMultipleRowDelete={null}
      loadGridData = {loadGridData}
      isPaginationRequired={true}>
      </SalesAndFinanceMultiGridUI>
    </div>
 )
}

export default BvDepOverrideTabPanel;